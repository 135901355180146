import { geocodeByAddress } from "react-places-autocomplete";

const geocodeStringForFullAddress = (payload: {
  Direccion?: string;
  Numero?: string;
  Codigo_postal?: string;
  Ciudad?: string;
  Provincia?: string;
  Pais?: string;
}): string => {
  let completeAddress = "";
  if (payload.Direccion) {
    completeAddress += `${payload.Direccion}`;
  }
  if (payload.Numero) {
    completeAddress += `, ${payload.Numero}`;
  }
  if (payload.Codigo_postal) {
    completeAddress += `, ${payload.Codigo_postal}`;
  }
  if (payload.Ciudad) {
    completeAddress += ` ${payload.Ciudad}`;
  }
  if (payload.Provincia) {
    completeAddress += `, ${payload.Provincia}`;
  }
  if (payload.Pais) {
    completeAddress += `, ${payload.Pais}`;
  } else {
    completeAddress += ", España";
  }

  return completeAddress;
};

const geocodeStringForPostalCode = (payload: {
  Direccion?: string;
  Numero?: string;
  Codigo_postal?: string;
  Ciudad?: string;
  Provincia?: string;
  Pais?: string;
}): string => {
  let completeAddress = "";
  if (payload.Codigo_postal) {
    completeAddress += `${payload.Codigo_postal}`;
  }
  if (payload.Ciudad) {
    completeAddress += ` ${payload.Ciudad}`;
  }
  if (payload.Provincia) {
    completeAddress += `, ${payload.Provincia}`;
  }
  if (payload.Pais) {
    completeAddress += `, ${payload.Pais}`;
  } else {
    completeAddress += ", España";
  }

  return completeAddress;
};

export type TGeocodeResult = {
  lat: number;
  lng: number;
  addressComponents: google.maps.GeocoderAddressComponent[];
};

const convertToSimpleGeocodeResult = (
  result: google.maps.GeocoderResult[]
): TGeocodeResult | null => {
  if (!result || !result.length) {
    return null;
  }

  if (result.length !== 1) {
    return null;
  }

  if (result[0] && result[0].geometry && result[0].geometry.location) {
    return {
      lat: result[0].geometry.location.lat(),
      lng: result[0].geometry.location.lng(),
      addressComponents: result[0].address_components,
    };
  }

  return null;
};

const geocodeAddress = async (payload: {
  Direccion?: string;
  Numero?: string;
  Codigo_postal?: string;
  Ciudad?: string;
  Provincia?: string;
  Pais?: string;
}): Promise<null | TGeocodeResult> => {
  const completeAddress = geocodeStringForFullAddress(payload);
  try {
    const geocode = await geocodeByAddress(completeAddress);
    return convertToSimpleGeocodeResult(geocode);
  } catch (e) {
    if (typeof e === "string" && e === "ZERO_RESULTS") {
      const addressString = geocodeStringForPostalCode(payload);
      try {
        const geocode = await geocodeByAddress(addressString);
        return convertToSimpleGeocodeResult(geocode);
      } catch (e) {
        console.error("Error trying to geocode address", addressString, e);
        throw e;
      }
    } else {
      console.error("Error trying to geocode address", completeAddress, e);
      throw e;
    }
  }
};

const deg2rad = (value: number) => {
  const pi = Math.PI;
  return value * (pi / 180);
};

const rad2deg = (value: number) => {
  const pi = Math.PI;
  return value * (180 / pi);
};

const distanceBetweenCoordinates = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
  unit: string
) => {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    const theta = lon1 - lon2;
    let dist =
      Math.sin(deg2rad(lat1)) * Math.sin(deg2rad(lat2)) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.cos(deg2rad(theta));
    dist = Math.acos(dist);
    dist = rad2deg(dist);
    const miles = dist * 60 * 1.1515;

    if (unit === "K") {
      return miles * 1.609344;
    } else {
      return miles;
    }
  }
};

export { geocodeAddress, distanceBetweenCoordinates };
