import { ModalInterface } from "../reducers/Modal";
import { MODAL_SHOW, MODAL_CLOSE, MODAL_RESET } from "../constants/ActionTypes";

export const modalShow = (modal: ModalInterface) => {
  return {
    type: MODAL_SHOW,
    payload: modal
  };
};

export const modalClose = () => {
  return {
    type: MODAL_CLOSE
  };
};

export const modalReset = () => {
  return {
    type: MODAL_RESET
  };
};
