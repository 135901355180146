import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import reducers from "../reducers/index";
import rootSaga from "../sagas/index";

const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASE_URL
    ? process.env.REACT_APP_BASE_URL + "/"
    : "",
});
const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, routeMiddleware];
// const composeEnhancers = compose;
const composeEnhancers =
  process.env.REACT_APP_ENV === "prod"
    ? compose
    : (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState: any) {
  const store = createStore(
    reducers(history),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  (sagaMiddleware as any).run(rootSaga);

  if ((module as any).hot) {
    // Enable Webpack hot module replacement for reducers
    (module as any).hot.accept("../reducers/index", () => {
      const nextRootReducer = require("../reducers/index");
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}

export const store = configureStore({});

export { history };
