import { useEffect } from "react";
import { CONTRACT_NOTIFICATION_TYPE } from "../../../../../api/contract/contract.types";
import { apiUpdateContractNotification } from "../../../../../api/contractNotification/apiUpdateContractNotification";
import { TAuthenticatedUser } from "../../../../../reducers/Auth";
import { TContractFull } from "../../../features/contract";
import { userIsPromoter, userIsRecruiter } from "../../../models/Staff/Staff";

export default function useNotificationsDiscard(
  contract: TContractFull | null,
  authUser: TAuthenticatedUser
) {
  useEffect(() => {
    if (!contract) {
      return;
    }

    if (userIsRecruiter(authUser)) {
      const toRecruitNotification = contract.notifications?.filter(
        (notification) =>
          notification.type ===
          CONTRACT_NOTIFICATION_TYPE.REQUIRES_RECRUITER_WORK
      )[0];

      if (!toRecruitNotification) {
        return;
      }

      if (!toRecruitNotification.viewed) {
        apiUpdateContractNotification(toRecruitNotification.id, {
          viewed: true,
        });
      }
    }

    if (userIsPromoter(authUser)) {
      const returnedNotification = contract.notifications?.filter(
        (notification) =>
          notification.type === CONTRACT_NOTIFICATION_TYPE.RETURNED_BY_RECRUITER
      )[0];

      if (!returnedNotification) {
        return;
      }

      if (!returnedNotification.viewed) {
        apiUpdateContractNotification(returnedNotification.id, {
          viewed: true,
        });
      }
    }
  }, [contract, authUser]);
}
