import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import GoogleMapReact from "google-map-react";
import { fitBounds } from "google-map-react";
import React, { useLayoutEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import cuideoTheme from "../../../containers/themes/defaultTheme";
import { CandidateMarker } from "../features/candidate";
import { TOnCandidateSelectFn } from "../features/candidate/types/candidate.types";
import MapGeoSearchField from "./MapGeoSearchField";
import MapMarker from "./MapMarker";

const StyledMaxWarning = styled.div`
  z-index: 1;
  padding: 0.5rem;
  background-color: ${cuideoTheme.palette.primary.main};
  top: 0.75rem;
  left: 0.75rem;
  max-width: 20rem;
  position: absolute;
  border-radius: ${cuideoTheme.shape.borderRadius}px;
  box-shadow: 0 2px 4px 0 #a2c4d8;

  .MuiSvgIcon-root {
    color: ${cuideoTheme.palette.common.white};
  }
`;

const StyledWarningTypography = styled(Typography)`
  color: ${cuideoTheme.palette.common.white};
  font-size: 0.875rem;
  line-height: 1.15;
`;

const StyledLoading = styled.div`
  z-index: 1;
  padding: 0.5rem;
  background-color: ${cuideoTheme.palette.primary.main};
  top: 0.75rem;
  left: 0.75rem;
  max-width: 20rem;
  position: absolute;
  border-radius: ${cuideoTheme.shape.borderRadius}px;
  box-shadow: 0 2px 4px 0 #a2c4d8;

  .MuiCircularProgress-svg {
    color: ${cuideoTheme.palette.common.white};
  }
`;

const StyledSearchWrapper = styled(Box)`
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  max-width: 18rem;
  z-index: 1;
`;

export interface EmployeesMapProps {
  candidatos: any[];
  selectedCandidato?: any;
  hoveredCandidato?: any;
  map: any;
  onCandidatoHover?: (valoracion: any) => void;
  onCandidatoSelect: TOnCandidateSelectFn;
  onMapChange?: (mapChanged: any) => void;
  showMaxWarning?: boolean;
  showLoading?: boolean;
  allowMapSearch?: boolean;
}

const EmployeesMap = (props: EmployeesMapProps) => {
  const {
    candidatos,
    selectedCandidato,
    hoveredCandidato,
    onCandidatoHover,
    onCandidatoSelect,
    onMapChange,
    map,
    showMaxWarning,
    showLoading,
    allowMapSearch,
  } = props;
  const divRef = useRef(null);
  const [mouseOver, setMouseOver] = useState(false);
  const [marker, setMarker] = useState<{
    lat?: number;
    lng?: number;
    label?: string;
  }>({});

  useLayoutEffect(() => {
    if (mouseOver) {
      return;
    }

    const mapbounding = (divRef.current as any).getBoundingClientRect();

    let left = null as any;
    let top = null as any;
    let right = null as any;
    let bottom = null as any;

    candidatos.forEach((candidato: any) => {
      if (
        !left ||
        (candidato.Longitud && parseFloat(candidato.Longitud) < left)
      ) {
        left = parseFloat(candidato.Longitud) - 0.1;
      }
      if (
        !right ||
        (candidato.Longitud && parseFloat(candidato.Longitud) > right)
      ) {
        right = parseFloat(candidato.Longitud) + 0.1;
      }
      if (!top || (candidato.Latitud && parseFloat(candidato.Latitud) > top)) {
        top = parseFloat(candidato.Latitud) + 0.1;
      }
      if (
        !bottom ||
        (candidato.Latitud && parseFloat(candidato.Latitud) < bottom)
      ) {
        bottom = parseFloat(candidato.Latitud) - 0.1;
      }
    });
    if (!top || !left || !right || !bottom) {
      return;
    }
    const bounds = {
      nw: {
        lat: top,
        lng: left,
      },
      se: {
        lat: bottom,
        lng: right,
      },
      ne: {
        lat: top,
        lng: right,
      },
      sw: {
        lat: bottom,
        lng: left,
      },
    };
    const size = {
      width: mapbounding.width,
      height: mapbounding.height,
    };
    if (size.width < 50 || size.height < 50) {
      return;
    }

    const newBounds = fitBounds(bounds, size);
    if (map.autoFocus && !showLoading) {
      onMapChange &&
        onMapChange({
          ...map,
          center: newBounds.center,
          zoom: newBounds.zoom,
          autoFocus: true,
        });
    }
    // eslint-disable-next-line
  }, [candidatos, map, showLoading]);

  const handlePlaceSelected = (
    lat: number,
    lng: number,
    zoom: number,
    label: string
  ) => {
    onMapChange &&
      onMapChange({
        ...map,
        center: {
          lat: lat,
          lng: lng,
        },
        zoom: zoom,
        autoFocus: true,
        _setInMap: true,
      });
    setMarker({
      lat: lat,
      lng: lng,
      label: label,
    });
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        position: "relative",
      }}
      ref={divRef}
      onMouseEnter={() => {
        setMouseOver(true);
      }}
      onMouseLeave={() => {
        setMouseOver(false);
      }}
    >
      {showLoading ? (
        <StyledLoading>
          <Grid container spacing={4} alignItems="center" wrap="nowrap">
            <Grid item>
              <CircularProgress size="1rem" />
            </Grid>
            <Grid item>
              <StyledWarningTypography>
                <FormattedMessage
                  id="EmployeesMap.Loading"
                  defaultMessage="Cargando..."
                />
              </StyledWarningTypography>
            </Grid>
          </Grid>
        </StyledLoading>
      ) : (
        showMaxWarning && (
          <StyledMaxWarning>
            <Grid container spacing={4} alignItems="flex-start" wrap="nowrap">
              <Grid item>
                <WarningIcon fontSize="small" />
              </Grid>
              <Grid item>
                <StyledWarningTypography>
                  <FormattedMessage
                    id="EmployeesMap.MaxWarning"
                    defaultMessage="No se muestran todos los candidatos en el mapa. Restrinja la búsqueda si quiere verlos todos"
                  />
                </StyledWarningTypography>
              </Grid>
            </Grid>
          </StyledMaxWarning>
        )
      )}
      {allowMapSearch && (
        <StyledSearchWrapper
          style={{
            visibility: !!selectedCandidato ? "hidden" : "visible",
          }}
        >
          <MapGeoSearchField
            visible={!selectedCandidato}
            mapRef={divRef}
            onPlaceSelected={handlePlaceSelected}
          />
        </StyledSearchWrapper>
      )}
      <GoogleMapReact
        // Google Maps key already included
        center={map.center}
        zoom={map.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          map.data.setStyle({ clickable: false });
        }}
        onChange={(changeValue: any) => {
          onMapChange && onMapChange({ ...changeValue, autoFocus: false });
        }}
        options={(maps) => {
          return {
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
          };
        }}
      >
        {candidatos.map((candidato: any, index: number) => {
          if (candidato.Latitud && candidato.Longitud) {
            return (
              <CandidateMarker
                key={`c${candidato.id}`}
                candidate={candidato}
                lat={candidato.Latitud}
                lng={candidato.Longitud}
                onSelect={onCandidatoSelect}
                isHovered={
                  !!hoveredCandidato && hoveredCandidato.id === candidato.id
                }
                onMouseEnter={() => {
                  onCandidatoHover && onCandidatoHover(candidato);
                }}
                onMouseLeave={() => {
                  onCandidatoHover && onCandidatoHover(null);
                }}
                appearDelay={Math.ceil(1000 / (candidatos.length + 1)) * index}
              />
            );
          }
          return null;
        })}
        {marker.lat && marker.lng && (
          <MapMarker
            key={`map-Marker`}
            lat={marker.lat}
            lng={marker.lng}
            label={marker.label ? marker.label : ""}
          />
        )}
      </GoogleMapReact>
    </div>
  );
};

export default EmployeesMap;
