import { Button, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { setBackground } from "../../actions/Layout";
import cuideoTheme from "../../containers/themes/defaultTheme";
import usePageTitle from "../../util/usePageTitle";

const StyledWrapper = styled.div`
  height: calc(100vh - 6rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledErrorHeader = styled(Typography)`
  text-align: center;
  font-size: 1.75rem;
  font-family: "Lora", Georgia, serif;
  font-weight: 600;
  max-width: 20rem;
  margin: 0 auto 2rem;
  color: ${cuideoTheme.palette.primary.main};
`;

const StyledErrorText = styled(Typography)`
  text-align: center;
  font-size: 1.1rem;
  line-height: 1.35;
  max-width: 20rem;
  margin: 0 auto 1.5rem;
  color: ${cuideoTheme.palette.primary.main};
`;

interface Error404Props {
  title?: any;
  message?: any;
}

const Error404 = (props: Error404Props) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const title = props.title || (
    <FormattedMessage
      id="Error404.Title"
      defaultMessage="La página que buscas no existe"
    />
  );
  const message = props.message || (
    <FormattedMessage
      id="Error404.Message"
      defaultMessage="La página a la que estás intentando acceder parece que no existe"
    />
  );

  useEffect(() => {
    dispatch(setBackground("cuideo"));
    // eslint-disable-next-line
  }, []);
  usePageTitle(
    formatMessage({ id: "Error404Page.Error404", defaultMessage: "Error" })
  );

  return (
    <StyledWrapper>
      <StyledErrorHeader>{title}</StyledErrorHeader>
      <StyledErrorText>{message}</StyledErrorText>
      <StyledErrorText>
        <NavLink to="/" style={{ textDecoration: "none" }}>
          <Button color="primary" variant="contained">
            <FormattedMessage
              id="Error404.GoHome"
              defaultMessage="Ir a inicio"
            />
          </Button>
        </NavLink>
      </StyledErrorText>
    </StyledWrapper>
  );
};

export default Error404;
