import { Alert } from "@material-ui/lab";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import ContractEvent from "./ContractEvent";

const StyledWrapper = styled.div`
  padding: 0 0.5rem;
`;

const StyledEventWrapper = styled.div`
  margin: 0.5rem 0;
`;

const StyledAlert = styled(Alert)`
  font-size: 0.8125rem;
`;

interface IContractEventsTimelineProps {
  status: "idle" | "error" | "success" | "loading";
  events: any[];
}

const ContractEventsTimeline: React.FC<IContractEventsTimelineProps> = (
  props
) => {
  const { status, events } = props;

  return (
    <StyledWrapper>
      {status === "loading" ? (
        <></>
      ) : status === "error" ? (
        <StyledAlert severity="error">
          <FormattedMessage
            id="ContractEventsTimeline.Ha habido un error"
            defaultMessage="Ha habido un error"
          />
        </StyledAlert>
      ) : status === "success" && events.length === 0 ? (
        <StyledAlert severity="info">
          <FormattedMessage
            id="ContractEventsTimeline.No se han registrado eventos para este Contrato"
            defaultMessage="No se han registrado eventos para este Contrato"
          />
        </StyledAlert>
      ) : (
        events.map((event) => (
          <StyledEventWrapper key={event.id}>
            <ContractEvent event={event} />
          </StyledEventWrapper>
        ))
      )}
      {/* {events.map((event) => (
        <StyledEventWrapper key={event.id}>
          <ContractEvent event={event} />
        </StyledEventWrapper>
      ))} */}
    </StyledWrapper>
  );
};

export default ContractEventsTimeline;
