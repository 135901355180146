import { TContractState } from "../contract/contract.types";

export const VALUATION_STATUS = {
  NOT_VALUED: "01_Sin Valorar",
  DISCARDED: "02_Descartado",
  PHONE: "03_Telefónica",
  FACE_TO_FACE: "04_Presencial",
  CV_CLIENT: "04_CV a cliente",
  FACE_TO_FACE_CLIENT: "05_Presencial Cliente",
  CONTRACTED: "06_Contratado",
  SEARCH: "00_Busqueda",
  RECOMMENDATION: "00_Recomendado",
  SUGGESTION: "00_Suggestion",
  OUTSTANDING: "00_Outstanding"
} as const;

export type TValuationStatus =
  typeof VALUATION_STATUS[keyof typeof VALUATION_STATUS];

export type TGetValuationsCollectionItem = {
  id: string;
  zohoId?: string;
  Name?: string;
  Estado_en_proceso?: TValuationStatus;
  Descripci_n?: string;
  Candidato_Web?: boolean;
  fecha_inicio_contrato?: string;
  fecha_fin_contrato?: string;
  createdAt: string;
  updatedAt: string;
};

export type TGetCandidateValuationsCollectionItem = {
  id: string;
  Estado_en_proceso?: TValuationStatus;
  Name?: string;
  Notas_para_correo_electronico?: string;
  contrato?: {
    id: string;
    A_ade_nota_del_candidato_a?: string;
    Ciudad_oferta?: string;
    Estado?: TContractState;
    Horario_Servicio?: string;
    Name?: string;
    Parolog_as_tratadas_previamente?: string[];
    Que_buscamos?: string;
    Requisitos?: string;
    Salario_Bruto_Mensual?: number;
    Tipo_fin_contrato?: string;
    Valoracion_Cuideo_Candidata?: string;
    _Nombre_del_cliente?: string;
    ownerName?: string;
    Compromiso: number;
    Experiencia: number;
    Comunicaci_n: number;
    Paciencia_y_Empatia: number;
    Car_cter: number;
  };
  fecha_inicio_contrato?: string;
  fecha_fin_contrato?: string;
};
