import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import uuidv4 from "uuid/v4";
import { fetchStart, fetchSuccess } from "../actions/Common";
import {
  getValoracionesSuccess,
  getValoracionSuccess,
  updateMisValoraciones,
} from "../actions/Valoraciones";
import {
  GET_MIS_VALORACIONES,
  GET_VALORACION,
  GET_VALORACIONES,
} from "../constants/ActionTypes";
import axios from "../util/ApiEmpleo";

const getValoracionesRequest = async (payload: any) => {
  const params = payload;
  let res = await axios.get("valoraciones", {
    params: params,
  });
  if (res.data) {
    return res.data;
  }
};

function* getValoraciones({ payload }: { payload: any }) {
  try {
    const opId = uuidv4();
    yield put(fetchStart(opId, "getValoraciones"));
    // @ts-ignore
    const valoracionesRequest = yield call(getValoracionesRequest, payload);
    if (valoracionesRequest) {
      const partialValoraciones = valoracionesRequest["hydra:member"];
      const numValoraciones = valoracionesRequest["hydra:totalItems"];
      yield put(
        getValoracionesSuccess({
          partialValoraciones,
          numValoraciones,
        })
      );
      yield put(fetchSuccess(opId));
    }
  } catch (error) {
    console.log("Error", error);
  }
}

const getValoracionRequest = async (payload: any) => {
  let res = await axios.get("valoraciones/" + payload);
  if (res.data) {
    return [res.data];
  }
};

function* getValoracion({ payload }: { payload: any }) {
  try {
    const opId = uuidv4();
    yield put(fetchStart(opId, "getValoracion"));
    // @ts-ignore
    const valoracionRequest = yield call(getValoracionRequest, payload);
    if (valoracionRequest) {
      const partialValoraciones = valoracionRequest;
      yield put(
        getValoracionSuccess({
          partialValoraciones,
        })
      );
      yield put(fetchSuccess(opId));
    }
  } catch (error) {
    console.log("Error", error);
  }
}

const getMisValoracionesRequest = async (payload: any) => {
  let res = await axios.get("candidatos/" + payload + "/valoraciones");
  if (res.data) {
    return res.data;
  }
};

function* getMisValoraciones({ payload }: { payload: any }) {
  try {
    const opId = uuidv4();
    yield put(fetchStart(opId, "getMisValoraciones"));
    // @ts-ignore
    const misValoracionesRequest = yield call(
      getMisValoracionesRequest,
      payload
    );
    if (misValoracionesRequest) {
      const partialValoraciones = misValoracionesRequest["hydra:member"];
      yield put(updateMisValoraciones(partialValoraciones));
      yield put(fetchSuccess(opId));
    }
  } catch (error) {
    console.log("Error", error);
  }
}

export function* getValoracionsSaga() {
  yield takeEvery(GET_VALORACIONES as any, getValoraciones);
}

export function* getValoracionSaga() {
  yield takeEvery(GET_VALORACION as any, getValoracion);
}

export function* getMisValoracionesSaga() {
  yield takeEvery(GET_MIS_VALORACIONES as any, getMisValoraciones);
}

export default function* rootSaga() {
  yield all([
    fork(getValoracionsSaga),
    fork(getValoracionSaga),
    fork(getMisValoracionesSaga),
  ]);
}
