import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TSendMessageRequestContext } from "../../../../apiCoreV3/message/apiSendMessage";
import Conversation from "../../features/conversation/components/Conversation";
import { useCandidateAndConversationQuery } from "./hooks/useCandidateAndConversationQuery";

const REFETCH_INTERVAL_IN_MS = 5000;

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

interface IMessagesTabProps {
  candidato: any;
  context?: TSendMessageRequestContext;
}

const MessagesTab = (props: IMessagesTabProps) => {
  const { candidato, context } = props;

  const [state, setState] = useState({
    isAwaitingConversation: false,
  });
  const candidateWithConversationQuery = useCandidateAndConversationQuery({
    candidateCrmId: candidato.zohoId,
    config: {
      refetchInterval: state.isAwaitingConversation
        ? REFETCH_INTERVAL_IN_MS
        : false,
    },
  });

  useEffect(() => {
    if (state.isAwaitingConversation) {
      if (candidateWithConversationQuery.data?.conversation) {
        setState({ isAwaitingConversation: false });
      }
    }
  }, [state.isAwaitingConversation, candidateWithConversationQuery.data]);

  if (candidateWithConversationQuery.isLoading) {
    return null;
  }

  if (!candidateWithConversationQuery.data?.candidate) {
    console.error(`Candidate with id <${candidato.zohoId}> not found`);
    return null;
  }

  const handleMessageSend = () => {
    setState({
      isAwaitingConversation: true,
    });
  };

  return (
    <StyledWrapper>
      <Conversation
        conversationId={
          candidateWithConversationQuery.data?.conversation?.id ?? null
        }
        remoteContactId={candidateWithConversationQuery.data.candidate.id}
        onMessageSend={handleMessageSend}
        context={context}
      />
    </StyledWrapper>
  );
};

export default MessagesTab;
