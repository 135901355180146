import {
  Button,
  CircularProgress,
  Dialog,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useState } from "react";
import toast from "react-hot-toast";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { getCandidato, modalShow } from "../../../../../../actions";
import {
  EMPLOYEE_STATUS,
  TGetEmployeeItem,
} from "../../../../../../api/employee/employee.types";
import cuideoTheme from "../../../../../../containers/themes/defaultTheme";
import { apiEmpleo } from "../../../../../../util/ApiEmpleo";

const StyledDiscardCandidateButton = styled(Button)`
  text-transform: none;
  color: ${cuideoTheme.palette.primary.main};
  font-size: 0.875rem;
  font-weight: 400;
`;

const StyledDescartarDialog = styled(Dialog)``;

const StyledHeader = styled.div`
  background-color: #cdd5d8;
  color: ${cuideoTheme.palette.primary.main};
  padding: 0.75rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledTitle = styled(Typography)`
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
`;

const StyledActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 0.5rem 0.5rem;
`;

const StyledCancelButton = styled(Button)``;

const StyledConfirmButton = styled(Button)`
  min-height: 2.5rem;
  min-width: 7rem;

  .MuiCircularProgress-root {
    color: #999;
  }
`;

const StyledDialogContent = styled(Typography)`
  padding: 1rem;
  font-size: 0.9375rem;
  color: ${cuideoTheme.palette.primary.main};
`;

export interface IDiscardCandidateButtonProps {
  candidate: TGetEmployeeItem;
  onDiscard?: (candidate: TGetEmployeeItem) => void;
}

const DiscardCandidateButton = (props: IDiscardCandidateButtonProps) => {
  const { candidate, onDiscard } = props;

  const [state, setState] = useState({
    open: false,
    status: "idle" as "loading" | "idle" | "success" | "error",
  });
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const handleButtonClick = (e: React.MouseEvent) => {
    setState((prevState) => ({
      ...prevState,
      open: true,
    }));
  };

  const handleCloseDialog = () => {
    setState((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const handleConfirm = (e: React.MouseEvent) => {
    const sendData = async () => {
      const data = {
        Estado_Cuideo: EMPLOYEE_STATUS.DISCARDED,
      };

      try {
        setState((prevState) => ({
          ...prevState,
          status: "loading",
        }));
        await apiEmpleo.patch(`candidatos/${candidate.id}`, data, {
          headers: {
            "Content-Type": "application/merge-patch+json",
          },
        });
        onDiscard?.(candidate);
        setState((prevState) => ({
          ...prevState,
          status: "success",
          open: false,
        }));
        toast.success(
          formatMessage({
            id: "DiscardCandidateButton.Candidato descartado correctamente",
            defaultMessage: "Candidato descartado correctamente",
          })
        );
        dispatch(getCandidato(candidate.id, true));
      } catch (e) {
        dispatch(
          modalShow({
            open: true,
            type: "error",
            message: "Ha habido algún error al intentar descartar al candidato",
          })
        );
      }
    };

    e.stopPropagation();
    sendData();
  };

  if (candidate.Estado_Cuideo === EMPLOYEE_STATUS.DISCARDED) {
    return null;
  }

  return (
    <Fragment>
      <StyledDiscardCandidateButton onClick={handleButtonClick}>
        <FormattedMessage
          id="DiscardButton.Descartar"
          defaultMessage="Descartar"
        />
      </StyledDiscardCandidateButton>
      <StyledDescartarDialog
        open={state.open}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        <StyledHeader>
          <StyledTitle color="primary">
            <FormattedMessage
              id="DiscardButton.Descartar Candidato"
              defaultMessage="Descartar Candidato"
            />
          </StyledTitle>
        </StyledHeader>
        <StyledDialogContent>
          <FormattedMessage
            id="DiscardButton.¿Estás seguro que deseas descartar al candidato {nombre}?"
            defaultMessage="¿Estás seguro que deseas descartar al candidato {nombre}?"
            values={{ nombre: candidate.Nombre }}
          />
        </StyledDialogContent>
        <StyledDialogContent>
          <FormattedMessage
            id="DiscardButton.El candidato cambiará su estado y aparecerá como Descartado al valorarlo para todos los demás contratos"
            defaultMessage="El candidato cambiará su estado y aparecerá como Descartado al valorarlo para todos los demás contratos"
            values={{ nombre: candidate.Nombre }}
          />
        </StyledDialogContent>
        <StyledDialogContent>
          <FormattedMessage
            id="DiscardButton.Por favor, recuerda incluir en las notas el motivo para descartar al candidato"
            defaultMessage="Por favor, recuerda incluir en las notas el motivo para descartar al candidato"
            values={{ nombre: candidate.Nombre }}
          />
        </StyledDialogContent>
        <StyledActions>
          <StyledCancelButton
            color="primary"
            onClick={handleCloseDialog}
            disabled={state.status === "loading"}
          >
            <FormattedMessage
              id="DiscardButton.Cancelar"
              defaultMessage="Cancelar"
            />
          </StyledCancelButton>
          <StyledConfirmButton
            color="primary"
            variant="contained"
            onClick={handleConfirm}
            disabled={state.status === "loading"}
          >
            {state.status === "loading" ? (
              <CircularProgress size={20} thickness={5} />
            ) : (
              <FormattedMessage
                id="DiscardButton.Confirmar"
                defaultMessage="Confirmar"
              />
            )}
          </StyledConfirmButton>
        </StyledActions>
      </StyledDescartarDialog>
    </Fragment>
  );
};

export default DiscardCandidateButton;
