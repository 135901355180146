import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useHistory } from "react-router";
import {
  updateURLParams,
  urlParamsToObject,
} from "../../../../../util/urlManager";
import _ from "lodash";

const initialState = {
  selectedConversationId: null as null | string,
  ready: false,
};

export type TCommunicationsFilters = typeof initialState;

type TCommunicationsContextValue = {
  filters: TCommunicationsFilters;
};

type TCommunicationsContextType = [
  TCommunicationsContextValue,
  Dispatch<SetStateAction<TCommunicationsContextValue>>
];

const CommunicationsContext =
  createContext<TCommunicationsContextType | null>(null);

export const CommunicationsProvider: React.FC = ({ children }) => {
  return (
    <CommunicationsContext.Provider value={useState({ filters: initialState })}>
      <CommunicationsFilters>{children}</CommunicationsFilters>
    </CommunicationsContext.Provider>
  );
};

export const CommunicationsFilters: React.FC = ({ children }) => {
  const history = useHistory();
  const communicationsContext = useCommunicationsContext();

  useEffect(
    function updateFiltersFromUrl() {
      if (history.action !== "REPLACE") {
        const params = history.location.search
          ? urlParamsToObject(history.location.search)
          : {};

        communicationsContext.setFilters({
          ...communicationsContext.filters,
          ...params,
          ready: true,
        });
      }
    },
    [communicationsContext, history]
  );

  if (!communicationsContext.filters.ready) {
    return null;
  }

  return <>{children}</>;
};

export const useCommunicationsContext = () => {
  const value = useContext(CommunicationsContext);
  if (value === null) {
    throw new Error("Missing CommunicationsContext provider");
  }
  const [state, setState] = value;

  const setFilters = useCallback(
    (newFilters: TCommunicationsFilters) => {
      const params = {
        ...newFilters,
      } as any;

      if (_.isEqual(state.filters, params)) {
        return;
      }

      setState((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          ...newFilters,
        },
      }));

      // Parse a bit wich parameters to set on url
      delete params.ready;

      // Clean not needed parameters
      Object.keys(initialState).forEach((key) => {
        if (Array.isArray((initialState as any)[key])) {
          if (key === "createdBy")
            if (
              _.isEqual(
                _.sortBy((initialState as any)[key]),
                _.sortBy(params[key])
              )
            ) {
              delete params[key];
            }
        } else {
          if (params[key] === (initialState as any)[key]) {
            delete params[key];
          }
        }
      });

      updateURLParams(params);
    },
    [setState, state.filters]
  );

  const selectConversationId = useCallback(
    (conversationId: string | null) => {
      setFilters({
        ...state.filters,
        selectedConversationId: conversationId,
      });
    },
    [setFilters, state.filters]
  );

  return {
    filters: state.filters,
    setFilters,
    selectConversationId,
  };
};
