import { apiEmpleo } from "../../util/ApiEmpleo";
import { TGetContractsItemValuation } from "../contract/contract.types";

export const apiUpdateValuationState = async (
  valuation: TGetContractsItemValuation
) => {
  const data = {
    Estado_en_proceso: valuation.Estado_en_proceso
  };
  const res = await apiEmpleo.patch(`valoraciones/${valuation.id}`, data, {
    headers: {
      "Content-Type": "application/merge-patch+json",
    },
  });

  return res;
}
