import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Form, Formik, FormikProps, setIn } from "formik";
import React, { Fragment, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as Yup from "yup";
import { FormikTextField } from "../../../../components/FormikFields";
import FormikAttachCif from "../../../../components/FormikFields/FormikAttachCif";
import {
  employeeSingUpValidator,
  validateDNI,
  validateEmail,
  validatePhone,
} from "../../../../util/validators/yupValidators";
import {
  StyledRegistroSection,
  StyledRegistroTitle,
} from "../../EmployeeSignUpSC";
import { TStepSubmitFn } from "../../models/CandidateSignUp.model";
import ErrorsOnFormDialog from "../ErrorsOnFormDialog/ErrorsOnFormDialog";

interface Props {
  onSubmit: TStepSubmitFn;
}

const SignUpStep1: React.FC<Props> = (props) => {
  const { onSubmit } = props;
  const { formatMessage } = useIntl();

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errorsOnDialog, setErrorsOnDialog] = useState(false);

  const initialValues = {
    Nombre: "",
    Apellidos: "",
    Apellidos_2: "",
    Phone: "",
    DNI_NIE: "",
    Email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    Nombre: Yup.string()
      .matches(
        /^[a-zA-Z\s\u00C0-\u017F]*$/,
        formatMessage({
          id: "EmployeeSignUp.InvalidName",
          defaultMessage: "Nombre no válido",
        })
      )
      .required(
        formatMessage({
          id: "EmployeeSignUp.RequiredName",
          defaultMessage: "El nombre es obligatorio",
        })
      ),
    Apellidos: Yup.string()
      .matches(
        /^[a-zA-Z\s\u00C0-\u017F]*$/,
        formatMessage({
          id: "EmployeeSignUp.InvalidSurname",
          defaultMessage: "Apellido no válido",
        })
      )
      .required(
        formatMessage({
          id: "EmployeeSignUp.RequiredSurname",
          defaultMessage: "El apellido es obligatorio",
        })
      ),
    Phone: Yup.string()
      .required(
        formatMessage({
          id: "EmployeeSignUp.phoneRequired",
          defaultMessage: "El teléfono es obligatorio",
        })
      )
      .test(
        "valid-phone",
        formatMessage({
          id: "EmployeeSignUp.phoneMustBeValid",
          defaultMessage: "El teléfono no es válido",
        }),
        validatePhone
      ),
    DNI_NIE: Yup.string()
      .required(
        formatMessage({
          id: "EmployeeSignUp.dniRequired",
          defaultMessage: "El DNI es obligatorio",
        })
      )
      .test(
        "valid-dni",
        formatMessage({
          id: "EmployeeSignUp.dniMustBeValid",
          defaultMessage: "El DNI no es válido",
        }),
        validateDNI
      ),
    Email: Yup.string()
      .required(
        formatMessage({
          id: "EmployeeSignUp.emailRequired",
          defaultMessage: "El correo electrónico es obligatorio",
        })
      )
      .test(
        "valid-email",
        formatMessage({
          id: "EmployeeSignUp.emailMustBeValid",
          defaultMessage: "El correo electrónico no es válido",
        }),
        validateEmail
      ),
    password: Yup.string()
      .required(
        formatMessage({
          id: "EmployeeSignUp.PasswordRequired",
          defaultMessage: "Debes introducir una contraseña",
        })
      )
      .min(
        6,
        formatMessage({
          id: "EmployeeSignUp.MinPassword",
          defaultMessage: "La contraseña debe tener al menos 6 caracteres",
        })
      ),
  });

  const handleFormSubmit = (values: any, actions: any) => {
    window.scrollTo(0, 0);
    onSubmit({
      step: 2,
      data: {
        Nombre: values.Nombre,
        Apellidos: values.Apellidos,
        Apellidos_2: values.Apellidos_2,
        Phone: values.Phone,
        DNI_NIE: values.DNI_NIE,
        Email: values.Email,
        password: values.password,
        cif_back: values.cif_back,
        cif_obverse: values.cif_obverse,
      },
    });
  };

  const handlePasswordVisibilityChange = (e: any) => {
    setPasswordVisible(!passwordVisible);
  };

  const handleErrorDialogClose = () => {
    setErrorsOnDialog(false);
  };

  const validateForm = (values: any) => {
    return new Promise((resolve) => {
      // First pass the Yup validation for the object
      // eslint-disable-next-line
      const validation = validationSchema
        .validate(values, { abortEarly: false })
        .then(function (vals) {
          // Do a request to validate fields

          employeeSingUpValidator
            .validate(vals.DNI_NIE, vals.Email, vals.Phone)
            .then((ret: any) => {
              let errors: any = {};
              if (ret.violations) {
                for (let e of ret.violations) {
                  let path = e.propertyPath;
                  if (path === "username") path = "DNI_NIE";
                  if (path === "email") path = "Email";
                  if (path === "phone") path = "Phone";
                  let msg = e.message;
                  if (msg === "existing_user")
                    msg = formatMessage({
                      id: "EmployeeSignUp.existingUser",
                      defaultMessage: "El DNI introducido ya está como usuario",
                    });
                  if (msg === "email_in_use")
                    msg = formatMessage({
                      id: "EmployeeSignUp.emailInUse",
                      defaultMessage: "El Email introducido ya está en uso",
                    });
                  if (msg === "phone_in_use")
                    msg = formatMessage({
                      id: "EmployeeSignUp.phoneInUse",
                      defaultMessage: "El Teléfono introducido ya está en uso",
                    });

                  errors = setIn(errors, path, msg);
                }
                resolve(errors);
              } else {
                resolve({});
              }
            });
        })
        .catch(function (err) {
          if (err.name === "ValidationError") {
            let errors: any = {};
            if (err.inner) {
              for (let e of err.inner) {
                errors = setIn(errors, e.path, e.message);
              }
            }
            resolve(errors);
          }
        });
    });
  };

  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        validate={validateForm}
        onSubmit={(values: any, actions: any) => {
          handleFormSubmit(values, actions);
        }}
      >
        {(props: FormikProps<any>) => {
          const handleSubmit = async (e: React.FormEvent) => {
            e.stopPropagation();
            e.preventDefault();

            const errors = await props.validateForm();
            if (Object.keys(errors).length === 0) {
              props.submitForm();
            } else {
              Object.keys(errors).forEach((key) => {
                props.setFieldTouched(key, true, false);
              });
              setErrorsOnDialog(true);
            }
          };

          return (
            <Form>
              <StyledRegistroSection>
                <StyledRegistroTitle>
                  <FormattedMessage
                    id="SignUp.RegisterNew"
                    defaultMessage="Registro de nuevo cuidador"
                  />
                </StyledRegistroTitle>
                <Typography className="CMuiEmployeeSignUpSubtitle">
                  <FormattedMessage
                    id="EmployeeSignUp.Subtitle"
                    defaultMessage="Si rellenas todos los campos agilizarás el proceso de selección. Para ello necesitamos tu DNI o NIE y tu currículum vitae."
                  />
                </Typography>
                <FormikTextField
                  name="Nombre"
                  label={
                    <FormattedMessage
                      id="SignUp.Name"
                      defaultMessage="Nombre"
                    />
                  }
                  fullWidth
                  size="medium"
                  required
                  placeholder={formatMessage({
                    id: "SignUp.Name",
                    defaultMessage: "Nombre",
                  })}
                  uppercase
                />
                <FormikTextField
                  name="Apellidos"
                  label={
                    <FormattedMessage
                      id="SignUp.FirstSurname"
                      defaultMessage="Primer Apellido"
                    />
                  }
                  fullWidth
                  size="medium"
                  required
                  placeholder={formatMessage({
                    id: "SignUp.FirstSurname",
                    defaultMessage: "Primer Apellido",
                  })}
                  uppercase
                />
                <FormikTextField
                  name="Apellidos_2"
                  label={
                    <FormattedMessage
                      id="SignUp.SecondSurname"
                      defaultMessage="Segundo Apellido"
                    />
                  }
                  fullWidth
                  size="medium"
                  placeholder={formatMessage({
                    id: "SignUp.SecondSurname",
                    defaultMessage: "Segundo Apellido",
                  })}
                  uppercase
                />
                <FormikTextField
                  name="Phone"
                  type="tel"
                  label={
                    <FormattedMessage
                      id="SignUp.Phone"
                      defaultMessage="Teléfono"
                    />
                  }
                  fullWidth
                  size="medium"
                  placeholder={formatMessage({
                    id: "SignUp.PhonePlaceholder",
                    defaultMessage: "Introduce tu teléfono",
                  })}
                  telformat
                  required
                  // validate={validatePhoneField}
                />
                <FormikTextField
                  name="DNI_NIE"
                  label={
                    <FormattedMessage
                      id="SignUp.DNI"
                      defaultMessage="DNI/NIE"
                    />
                  }
                  fullWidth
                  required
                  placeholder={formatMessage({
                    id: "SignUp.DNIPlaceholder",
                    defaultMessage: "Introduce tu DNI",
                  })}
                  size="medium"
                  uppercase
                />
                <Box style={{ marginTop: "1.75rem" }}>
                  <FormikAttachCif
                    obverseFieldName="cif_obverse"
                    backFieldName="cif_back"
                    fullWidth
                  />
                </Box>
                <FormikTextField
                  name="Email"
                  label={
                    <FormattedMessage
                      id="SignUp.Email"
                      defaultMessage="Correo electrónico"
                    />
                  }
                  fullWidth
                  required
                  placeholder={formatMessage({
                    id: "SignUp.EmailPlaceholder",
                    defaultMessage: "Introduce tu correo electrónico",
                  })}
                  size="medium"
                />
                <FormikTextField
                  name="password"
                  label={
                    <FormattedMessage
                      id="SignUp.Password"
                      defaultMessage="Contraseña"
                    />
                  }
                  type={passwordVisible ? "text" : "password"}
                  fullWidth
                  forceComponentUpdate={true}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="cambia visibilidad contraseña"
                        onClick={handlePasswordVisibilityChange}
                      >
                        {passwordVisible ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  required
                  placeholder={formatMessage({
                    id: "SignUp.Password",
                    defaultMessage: "Contraseña",
                  })}
                  size="medium"
                />
              </StyledRegistroSection>
              <Box mt={8}>
                <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  fullWidth
                  type="submit"
                  onClick={handleSubmit}
                >
                  <FormattedMessage
                    id="SignUp.Next"
                    defaultMessage="Siguiente"
                  />
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
      <ErrorsOnFormDialog
        open={errorsOnDialog}
        onClose={handleErrorDialogClose}
      />
    </Fragment>
  );
};

export default SignUpStep1;
