import React, { useEffect, useState } from "react";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { motion } from "framer-motion";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import styled from "styled-components";
import NoTienesCuenta from "../../../components/FixedPanels/NoTienesCuenta";
import InscribeteOfertas from "../../../components/Highlights/InscribeteOfertas";
import StyledPageContainer from "../../../components/PageContainers/StyledPageContainer";
import cuideoTheme from "../../../containers/themes/defaultTheme";
import useFooter from "../../../util/useFooter";
import useHeader from "../../../util/useHeader";
import usePageTitle from "../../../util/usePageTitle";
import useWindowWidth from "../../../util/useWindowWidth";
import OfertaCardList from "../components/OfertaCardList";
import OfertasFilter from "../components/OffersFilter";
import WelcomePopup from "../components/WelcomePopup";
import { useOffersQuery } from "../hooks/useOffersQuery";
import { OffersFilter } from "../../../api/offers/apiOffer.types";
import { useProvincesQuery } from "../hooks/useProvincesQuery";
import { useCitiesQuery } from "../hooks/useCitiesQuery";

const ITEMS_PER_PAGE = 10;

const StyledTypography = styled(Typography)`
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 0.9rem;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 1rem;
  }
`;

const StyledPaper = styled(Paper)`
  padding: 1rem;
  box-shadow: 0 2px 4px 0 #a2c4d8;
`;

const StyledMoreButton = styled(Button)`
  margin-bottom: 1rem;
`;

const filterVariants = {
  enter: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.25, delay: 0.25 },
  },
  exit: {
    opacity: 0,
    x: -15,
  },
};

const PaginaOfertas = ({
  filtrosBusqueda,
  appMarginTop,
  authUser,
  showInscribeteHighlight,
}: any) => {
  // eslint-disable-next-line
  const [interesaPosition, setInteresaPosition] = useState(
    Math.floor(Math.random() * 8 + 1)
  );
  const width = useWindowWidth();
  const [welcomeOpen, setWelcomeOpen] = useState(
    width < 960 || authUser || filtrosBusqueda.provincia ? false : true
  );
  const [isScrollEnd, setScrollEnd] = useState(false);
  const intl = useIntl();

  const [currentFiltrosBusqueda, setCurrentFiltrosBusqueda] =
    useState(filtrosBusqueda);

  useHeader({
    type: "normal",
  });
  useFooter({
    type: "normal",
  });
  usePageTitle(
    intl.formatMessage({
      id: "OffersPage.Offers",
      defaultMessage: "Ofertas de Empleo",
    })
  );

  const {
    offers,
    totalItems,
    isLoading: isLoadingOffers,
    displayLoadMore,
    addNextPage,
    resetPage,
  } = useOffersQuery({
    filters: {
      ...currentFiltrosBusqueda,
      publicar: "Si",
      itemsPerPage: ITEMS_PER_PAGE,
      order: {
        fechaPublicacionOferta: "desc",
      },
    },
  });

  const { provinces, isLoading: isLoadingProvinces } = useProvincesQuery();

  const { cities, isLoading: isLoadingCities } = useCitiesQuery(
    filtrosBusqueda.provincia ?? ""
  );

  useEffect(() => {
    if (
      currentFiltrosBusqueda.provinciaOferta !== filtrosBusqueda.provincia ||
      currentFiltrosBusqueda.ciudadOferta !== filtrosBusqueda.ciudades ||
      currentFiltrosBusqueda.tipoDeTrato !== filtrosBusqueda.disponibilidad
    ) {
      setCurrentFiltrosBusqueda({
        provinciaOferta: filtrosBusqueda.provincia,
        ciudadOferta: filtrosBusqueda.ciudades,
        tipoDeTrato: filtrosBusqueda.disponibilidad,
      });
      resetPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtrosBusqueda, resetPage]);

  function getBodyScrollTop() {
    const el = document.scrollingElement || document.documentElement;
    return el.scrollTop;
  }

  useEffect(() => {
    function handleScroll() {
      const endScreen = window.innerHeight + getBodyScrollTop();
      if (endScreen < document.documentElement.offsetHeight - 80) {
        if (isScrollEnd) {
          setScrollEnd(false);
        }
        return;
      }
      // setNumOfertasPagina(numOfertasPagina + 3);
      if (!isScrollEnd) {
        setScrollEnd(true);
      }
    }

    // Se pone el listener cada vez que se dispara
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Se quita el listener cada vez que se llega
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isScrollEnd]);

  const closeWelcomePopup = () => {
    setWelcomeOpen(false);
  };

  const inscribeteOpen = showInscribeteHighlight;

  return (
    <StyledPageContainer appMarginTop={appMarginTop}>
      <Grid container>
        <Grid item xs={12}>
          <InscribeteOfertas open={inscribeteOpen} />
        </Grid>
      </Grid>
      <Grid container spacing={8}>
        {width > cuideoTheme.breakpoints.values.sm && (
          <Grid item xs={12} md={3}></Grid>
        )}
        <Grid item xs={12} md={9}>
          <ListHeader
            totalItems={totalItems}
            isLoading={isLoadingOffers}
            filters={{
              provinciaOferta: filtrosBusqueda.provincia,
              ciudadOferta: filtrosBusqueda.cuidades,
              tipoDeTrato: filtrosBusqueda.disponibilidad,
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={8}>
        {width > cuideoTheme.breakpoints.values.md && (
          <Grid item xs={12} md={3}>
            <motion.div
              initial="exit"
              animate="enter"
              variants={filterVariants}
              style={{ position: "sticky", top: "120px", marginBottom: "1rem" }}
            >
              <StyledPaper>
                <OfertasFilter provinces={provinces} cities={cities} />
              </StyledPaper>
            </motion.div>
          </Grid>
        )}
        {!(isLoadingOffers || isLoadingProvinces || isLoadingCities) && (
          <Grid item xs={12} md={9}>
            <OfertaCardList
              ofertas={offers}
              includeInteresaBox={
                !authUser && width < cuideoTheme.breakpoints.values.md
                  ? interesaPosition
                  : undefined
              }
            />
            {displayLoadMore && (
              <StyledMoreButton
                variant="outlined"
                fullWidth
                color="primary"
                onClick={addNextPage}
              >
                <FormattedMessage
                  id="OffersPage.Ver más ofertas"
                  defaultMessage="Ver más ofertas"
                />
              </StyledMoreButton>
            )}
          </Grid>
        )}
      </Grid>
      {width < cuideoTheme.breakpoints.values.sm &&
        !authUser &&
        !isScrollEnd && <NoTienesCuenta />}
      <WelcomePopup open={welcomeOpen} onClose={closeWelcomePopup} />
    </StyledPageContainer>
  );
};

const mapStateToProps = ({
  empleo,
  layout,
  auth,
}: {
  empleo: {
    filtrosBusqueda: any;
  };
  layout: { appMarginTop: number; footer: any };
  auth: { authUser: any; showInscribeteHighlight: boolean };
}) => {
  const { filtrosBusqueda } = empleo;
  const { authUser, showInscribeteHighlight } = auth;
  const { appMarginTop } = layout;

  return {
    filtrosBusqueda,
    authUser,
    appMarginTop,
    showInscribeteHighlight,
  };
};

export default connect(mapStateToProps, {})(PaginaOfertas);

type ListHeaderProps = {
  totalItems: number;
  isLoading: boolean;
  filters: OffersFilter;
};

const ListHeader = ({
  totalItems,
  isLoading,
  filters: { provinciaOferta, tipoDeTrato, ciudadOferta },
}: ListHeaderProps) => {
  if (isLoading) {
    return (
      <StyledTypography color="primary">
        <FormattedMessage
          id="OffersPage.Cargando ofertas..."
          defaultMessage="Cargando ofertas..."
        />
      </StyledTypography>
    );
  }
  if (totalItems === 0) {
    return (
      <StyledTypography color="primary">
        <FormattedMessage
          id="OffersPage.No hay ofertas de trabajo con los criterios seleccionados"
          defaultMessage="No hay ofertas de trabajo con los criterios seleccionados"
        />
      </StyledTypography>
    );
  }
  if (provinciaOferta || tipoDeTrato?.length || ciudadOferta?.length) {
    if (provinciaOferta) {
      if (tipoDeTrato?.length || ciudadOferta?.length) {
        return (
          <StyledTypography color="primary">
            <FormattedMessage
              id="OffersPage.{numOfertas} ofertas en {provincia} con los criterios seleccionados"
              defaultMessage="{numOfertas} ofertas en {provincia} con los criterios seleccionados"
              values={{
                numOfertas: <strong>{totalItems}</strong>,
                provincia: provinciaOferta,
              }}
            />
          </StyledTypography>
        );
      } else {
        return (
          <StyledTypography color="primary">
            <FormattedMessage
              id="OffersPage.{numOfertas} ofertas en {provincia}"
              defaultMessage="{numOfertas} ofertas en {provincia}"
              values={{
                numOfertas: <strong>{totalItems}</strong>,
                provincia: provinciaOferta,
              }}
            />
          </StyledTypography>
        );
      }
    } else {
      if (ciudadOferta?.length === 1) {
        return (
          <StyledTypography color="primary">
            <FormattedMessage
              id="OffersPage.{numOfertas} ofertas en {ciudad}"
              defaultMessage="{numOfertas} ofertas en {ciudad}"
              values={{
                numOfertas: <strong>{totalItems}</strong>,
                ciudad: ciudadOferta[0],
              }}
            />
          </StyledTypography>
        );
      } else {
        return (
          <StyledTypography color="primary">
            <strong>
              {totalItems}{" "}
              <FormattedMessage
                id="OffersPage.ofertas"
                defaultMessage="ofertas"
              />
            </strong>{" "}
            <FormattedMessage
              id="OffersPage.de trabajo con los criterios seleccionados"
              defaultMessage="de trabajo con los criterios seleccionados"
            />
          </StyledTypography>
        );
      }
    }
  } else {
    return (
      <StyledTypography color="primary">
        <strong>
          {totalItems}{" "}
          <FormattedMessage id="OffersPage.ofertas" defaultMessage="ofertas" />
        </strong>{" "}
        <FormattedMessage
          id="OffersPage.de trabajo"
          defaultMessage="de trabajo"
        />
      </StyledTypography>
    );
  }
};
