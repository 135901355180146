const tasks = [
  {
    id: "50c96a73-538b-4906-a107-4bc86999efd9",
    code: "aih",
    value: "Administrar insulina/heparina",
    label: "Administrar insulina/heparina",
    slug: "administrar-insulina-heparina",
    businessCountry: "es",
  },
  {
    id: "c8fe70cb-e556-49a7-b342-eecc53f60ccb",
    code: "aps",
    value: "Alimentación por sonda",
    label: "Alimentación por sonda",
    slug: "alimentacion-por-sonda",
    businessCountry: "es",
  },
  {
    id: "81812491-0173-49ad-977c-12a5f3f1248e",
    code: "behdu",
    value: "Baño e higiene del usuario",
    label: "Baño e higiene del usuario",
    slug: "bano-e-higiene-del-usuario",
    businessCountry: "es",
  },
  {
    id: "935712fd-72e4-413f-ae05-7c6b006827f2",
    code: "ccadc",
    value: "Convivir con animales de compañía",
    label: "Convivir con animales de compañía",
    slug: "convivir-con-animales-de-compania",
    businessCountry: "es",
  },
  {
    id: "f9e1db0f-9e73-43b0-adc3-4d9bcc76c37d",
    code: "cdbuc",
    value: "Cambio de bolsas (urinaria/colostomia)",
    label: "Cambio de bolsas (urinaria/colostomia)",
    slug: "cambio-de-bolsas-urinaria-colostomia",
    businessCountry: "es",
  },
  {
    id: "e9421a61-d79f-433a-8b9a-dbfe35554df2",
    code: "capa",
    value: "Cambio de pañal",
    label: "Cambio de pañal",
    slug: "cambio-de-panal",
    businessCountry: "es",
  },
  {
    id: "9e115eed-cb00-4dc7-acdf-0e40a41c72ac",
    code: "cdm",
    value: "Control de medicación",
    label: "Control de medicación",
    slug: "control-de-medicacion",
    businessCountry: "es",
  },
  {
    id: "81854277-8d2e-4bf9-b106-f63f2ec2b7bb",
    code: "cdmd1p",
    value: "Cuidar de mas de 1 persona",
    label: "Cuidar de más de 1 persona",
    slug: "cuidar-de-mas-de-1-persona",
    businessCountry: "es",
  },
  {
    id: "49132b49-3ba8-4038-a733-2928b293c211",
    code: "cdndaes",
    value: "Control de nivel de azúcar en sangre",
    label: "Control de nivel de azúcar en sangre",
    slug: "control-de-nivel-de-azucar-en-sangre",
    businessCountry: "es",
  },
  {
    id: "5a80341d-a5ce-4a0e-8115-eedf301a7d3c",
    code: "cb",
    value: "Curas básicas",
    label: "Curas básicas",
    slug: "curas-basicas",
    businessCountry: "es",
  },
  {
    id: "f17a8883-a696-47a4-98bb-a6bc5709486a",
    code: "ec",
    value: "Ejercicios cognitivos",
    label: "Ejercicios cognitivos",
    slug: "ejercicios-cognitivos",
    businessCountry: "es",
  },
  {
    id: "3e74689f-2bab-43e9-8179-fefcbe39333d",
    code: "hica",
    value: "Higiene encamado/a",
    label: "Higiene encamado/a",
    slug: "higiene-encamado",
    businessCountry: "es",
  },
  {
    id: "19019c53-13b4-4536-aa0a-95b7a921dc5d",
    code: "mg",
    value: "Manejo de grúa",
    label: "Manejo de grúa",
    slug: "manejo-grua",
    businessCountry: "es",
  },
  {
    id: "c0e0ecde-24a4-4a6d-b5c9-e8d649a2f238",
    code: "mcv",
    value: "Monitorización constantes vitales",
    label: "Monitorización constantes vitales",
    slug: "monitorizaci�n-constantes-vitales",
    businessCountry: "es",
  },
  {
    id: "78fb7ae6-5fd2-4313-9184-245d1a43b074",
    code: "myt",
    value: "Movilizaciones y transferencias",
    label: "Movilizaciones y transferencias",
    slug: "movilizaciones-y-transferencias",
    businessCountry: "es",
  },
  {
    id: "61cb637a-0a04-4b06-a84a-656ebb6f8188",
    code: "n",
    value: "Nebulizaciones",
    label: "Nebulizaciones",
    slug: "nebulizaciones",
    businessCountry: "es",
  },
  {
    id: "98a4f74e-649c-4119-a2cd-b2c123a5abe3",
    code: "pdc",
    value: "Preparación de comidas",
    label: "Preparación de comidas",
    slug: "preparacion-de-comidas",
    businessCountry: "es",
  },
  {
    id: "c423381d-9bc9-422f-82eb-58834a0bf30d",
    code: "pdef",
    value: "Programa de ejercicios físicos",
    label: "Programa de ejercicios físicos",
    slug: "programa-de-ejercicios-fisicos",
    businessCountry: "es",
  },
  {
    id: "96266073-0821-42e7-a5a5-43df1bf2f55e",
    code: "pyr",
    value: "Paseos y recados",
    label: "Paseos y recados",
    slug: "paseos-y-recados",
    businessCountry: "es",
  },
  {
    id: "6a561db8-7a4f-4c35-ac08-68c53ef38f91",
    code: "se",
    value: "Soporte emocional",
    label: "Soporte emocional",
    slug: "soporte-emocional",
    businessCountry: "es",
  },
  {
    id: "3d9ca1cb-00a7-4564-b6fb-5570f435bc67",
    code: "sa",
    value: "Soporte en la alimentación",
    label: "Soporte en la alimentación",
    slug: "soporte-en-la-alimentacion",
    businessCountry: "es",
  },
  {
    id: "7a6e5c89-b6d2-4cbd-b93c-20ed89e29201",
    code: "sacd",
    value: "Supervisión de las actividades diarias",
    label: "Supervisión de las actividades diarias",
    slug: "supervision-actividades-diarias",
    businessCountry: "es",
  },
  {
    id: "af6f486c-5505-4670-a760-a8fbbcd0af72",
    code: "ldh",
    value: "Limpieza del hogar",
    label: "Tareas básicas del hogar",
    slug: "limpieza-del-hogar",
    businessCountry: "es",
  },
  {
    id: "196a5134-e21a-4060-b7c2-4d1a39e77fb2",
    code: "tyc",
    value: "Transporte y conducción",
    label: "Transporte y conducción",
    slug: "transporte-y-conduccion",
    businessCountry: "es",
  },
  {
    id: "e92891d1-81a2-4ea1-aca6-3e202e3cb113",
    code: "vn",
    value: "Vigilia nocturna",
    label: "Vigilia nocturna",
    slug: "vigilia-nocturna",
    businessCountry: "es",
  },
  {
    id: "50c96a73-538b-4906-a107-4bc86999efd9",
    code: "aih",
    value: "Administrar insulina/heparina",
    label: "Administrer de l'insuline / de l'héparine",
    slug: "administrar-insulina-heparina",
    businessCountry: "fr",
  },
  {
    id: "c8fe70cb-e556-49a7-b342-eecc53f60ccb",
    code: "aps",
    value: "Alimentación por sonda",
    label: "Alimentation par sonde",
    slug: "alimentacion-por-sonda",
    businessCountry: "fr",
  },
  {
    id: "733ceb7b-4e90-4b98-832b-e55452aaf718",
    code: "aet",
    value: "Aspiración endo-traqueal",
    label: "Aspiration Endo-Tracheal",
    slug: "aspiracion-endo-traqueal",
    businessCountry: "fr",
  },
  {
    id: "81812491-0173-49ad-977c-12a5f3f1248e",
    code: "behdu",
    value: "Baño e higiene del usuario",
    label: "Aide à la toilette et hygiène personnelle",
    slug: "bano-e-higiene-del-usuario",
    businessCountry: "fr",
  },
  {
    id: "935712fd-72e4-413f-ae05-7c6b006827f2",
    code: "ccadc",
    value: "Convivir con animales de compañía",
    label: "Cohabiter avec des animaux de compagnie",
    slug: "convivir-con-animales-de-compania",
    businessCountry: "fr",
  },
  {
    id: "f9e1db0f-9e73-43b0-adc3-4d9bcc76c37d",
    code: "cdbuc",
    value: "Cambio de bolsas (urinaria/colostomia)",
    label: "Changement de poches (urinaire/colostomie)",
    slug: "cambio-de-bolsas-urinaria-colostomia",
    businessCountry: "fr",
  },
  {
    id: "e9421a61-d79f-433a-8b9a-dbfe35554df2",
    code: "capa",
    value: "Cambio de pañal",
    label: "Changement de couche",
    slug: "cambio-de-panal",
    businessCountry: "fr",
  },
  {
    id: "9e115eed-cb00-4dc7-acdf-0e40a41c72ac",
    code: "cdm",
    value: "Control de medicación",
    label: "Contrôle de la prise des médicaments",
    slug: "control-de-medicacion",
    businessCountry: "fr",
  },
  {
    id: "81854277-8d2e-4bf9-b106-f63f2ec2b7bb",
    code: "cdmd1p",
    value: "Cuidar de mas de 1 persona",
    label: "Prendre soin de plus d'une personne à la fois",
    slug: "cuidar-de-mas-de-1-persona",
    businessCountry: "fr",
  },
  {
    id: "49132b49-3ba8-4038-a733-2928b293c211",
    code: "cdndaes",
    value: "Control de nivel de azúcar en sangre",
    label: "Contrôle du niveau de sucre dans le sang",
    slug: "control-de-nivel-de-azucar-en-sangre",
    businessCountry: "fr",
  },
  {
    id: "5a80341d-a5ce-4a0e-8115-eedf301a7d3c",
    code: "cb",
    value: "Curas básicas",
    label: "Cures de base",
    slug: "curas-basicas",
    businessCountry: "fr",
  },
  {
    id: "f17a8883-a696-47a4-98bb-a6bc5709486a",
    code: "ec",
    value: "Ejercicios cognitivos",
    label: "Exercices cognitifs",
    slug: "ejercicios-cognitivos",
    businessCountry: "fr",
  },
  {
    id: "3e74689f-2bab-43e9-8179-fefcbe39333d",
    code: "hica",
    value: "Higiene encamado/a",
    label: "Hygiène alitée",
    slug: "higiene-encamado",
    businessCountry: "fr",
  },
  {
    id: "af6f486c-5505-4670-a760-a8fbbcd0af72",
    code: "ldh",
    value: "Limpieza del hogar",
    label: "Entretien du domicile",
    slug: "limpieza-del-hogar",
    businessCountry: "fr",
  },
  {
    id: "19019c53-13b4-4536-aa0a-95b7a921dc5d",
    code: "mg",
    value: "Manejo de grúa",
    label: "Manutention de grue",
    slug: "manejo-grua",
    businessCountry: "fr",
  },
  {
    id: "c0e0ecde-24a4-4a6d-b5c9-e8d649a2f238",
    code: "mcv",
    value: "Monitorización constantes vitales",
    label: "Surveillance des signes vitaux",
    slug: "monitorizacion-constantes-vitales",
    businessCountry: "fr",
  },
  {
    id: "78fb7ae6-5fd2-4313-9184-245d1a43b074",
    code: "myt",
    value: "Movilizaciones y transferencias",
    label: "Transferts et déplacements de l'usager",
    slug: "movilizaciones-y-transferencias",
    businessCountry: "fr",
  },
  {
    id: "61cb637a-0a04-4b06-a84a-656ebb6f8188",
    code: "n",
    value: "Nebulizaciones",
    label: "Nébulisations",
    slug: "nebulizaciones",
    businessCountry: "fr",
  },
  {
    id: "98a4f74e-649c-4119-a2cd-b2c123a5abe3",
    code: "pdc",
    value: "Preparación de comidas",
    label: "Préparation des repas",
    slug: "preparacion-de-comidas",
    businessCountry: "fr",
  },
  {
    id: "c423381d-9bc9-422f-82eb-58834a0bf30d",
    code: "pdef",
    value: "Programa de ejercicios físicos",
    label: "Programme d'exercices physiques",
    slug: "programa-de-ejercicios-fisicos",
    businessCountry: "fr",
  },
  {
    id: "96266073-0821-42e7-a5a5-43df1bf2f55e",
    code: "pyr",
    value: "Paseos y recados",
    label: "Promenades et courses",
    slug: "paseos-y-recados",
    businessCountry: "fr",
  },
  {
    id: "6a561db8-7a4f-4c35-ac08-68c53ef38f91",
    code: "se",
    value: "Soporte emocional",
    label: "Soutien affectif",
    slug: "soporte-emocional",
    businessCountry: "fr",
  },
  {
    id: "3d9ca1cb-00a7-4564-b6fb-5570f435bc67",
    code: "sa",
    value: "Soporte en la alimentación",
    label: "Aide à l'alimentation",
    slug: "soporte-en-la-alimentacion",
    businessCountry: "fr",
  },
  {
    id: "7a6e5c89-b6d2-4cbd-b93c-20ed89e29201",
    code: "sacd",
    value: "Supervisión de las actividades diarias",
    label: "Suivi des activités quotidiennes",
    slug: "supervision-actividades-diarias",
    businessCountry: "fr",
  },
  {
    id: "196a5134-e21a-4060-b7c2-4d1a39e77fb2",
    code: "tyc",
    value: "Transporte y conducción",
    label: "Transport et conduite",
    slug: "transporte-y-conduccion",
    businessCountry: "fr",
  },
  {
    id: "e92891d1-81a2-4ea1-aca6-3e202e3cb113",
    code: "vn",
    value: "Vigilia nocturna",
    label: "Veille de nuit",
    slug: "vigilia-nocturna",
    businessCountry: "fr",
  },
];

export function tasksList(country = "es") {
  return tasks.filter((task) => task.businessCountry === country);
}

export default tasks;
