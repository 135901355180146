import { AxiosResponse } from "axios";
import { TApiPaginatedResponse } from "../api-corev3.types";
import { apiCoreV3 } from "../apiCoreV3";
import { TCandidateResponse } from "./apiCandidate.types";

const defaultParameters = {
  page: 1,
  pageSize: 10,
};

interface IListCandidatesParams {
  page?: number;
  pageSize?: number;
  id?: string[];
}

const apiListCandidates = async (params: IListCandidatesParams = {}) => {
  const res: AxiosResponse<TApiPaginatedResponse<TCandidateResponse>> =
    await apiCoreV3.get(`/employees`, {
      params: {
        ...defaultParameters,
        ...params,
      },
    });

  return res.data.data;
};

export { apiListCandidates };
