import React from "react";
import { TSendMessageRequestContext } from "../../../../../../apiCoreV3/message/apiSendMessage";
import { useAuthUser } from "../../../../../../reducers/hooks/useAuthUser";
import { THsm } from "../../types/templateAction.types";
import { createMessageContentWithAuthor } from "../../util/message.util";
import Conversation from "./Conversation";
import useConversationState from "./hooks/useConversationState";

interface IConversationContainerProps {
  /**
   * We pass the conversationId. If the conversation is not created
   * yet it can be null
   */
  conversationId: string | null;
  /**
   * Can pass the Conversation object. If it is not passed then
   * the component will fetch it.
   */
  // conversation: TConversation | null;
  /**
   * Remote Contact Id used to send the message-to. We need this in
   * case that we don't have any conversation yet
   */
  remoteContactId?: string;
  /**
   * Called when a message is sent
   */
  onMessageSend?: () => void;
  currentTime?: Date;
  context?: TSendMessageRequestContext;
}

const ConversationContainer: React.VFC<IConversationContainerProps> = (
  props
) => {
  const { conversationId, remoteContactId, onMessageSend, context } = props;
  const currentTime = props.currentTime || new Date();
  const { authUser } = useAuthUser();

  const { state, actions } = useConversationState(
    conversationId,
    // conversation,
    remoteContactId,
    currentTime,
    context
  );

  const handleSendMessage = async (message: string) => {
    const content = createMessageContentWithAuthor(
      message,
      authUser.userFirstName
    );
    const encodedContent = JSON.stringify(content);
    await actions.asyncSendMessage(encodedContent);
    onMessageSend?.();
  };

  const handleSendHsm = async (hsm: THsm) => {
    const encodedContent = JSON.stringify(hsm);
    if (!conversationId) {
      await actions.asyncSendStartConversationMessage(encodedContent);
    } else {
      await actions.asyncSendMessage(encodedContent);
    }
    onMessageSend?.();
  };

  return (
    <Conversation
      conversationId={conversationId}
      conversation={state.conversation}
      messages={state.messages}
      isLoading={state.isLoading}
      onSendMessage={handleSendMessage}
      onSendHsm={handleSendHsm}
      showTemplateAlreadySent={
        !state.canSendMessage && state.hasSentTemplatesInLast24Hours
      }
      show24HoursNotification={
        !state.hasSentTemplatesInLast24Hours &&
        !state.hasReceivedMessagesInLast24Hours
      }
      canSendMessage={state.canSendMessage}
      canSendTemplate={state.canSendTemplate}
      isConversationBlocked={state.isConversationBlocked}
    />
  );
};

export default ConversationContainer;
