import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  getCandidato,
  snackErrorMessage,
  snackSuccessMessage,
} from "../../actions";
import cuideoTheme from "../../containers/themes/defaultTheme";
import { apiEmpleo } from "../../util/ApiEmpleo";
import useFormatters from "../FormattedFields/useFormatters";
import { EMPLOYEE_STATUS } from "../../api/employee/employee.types";

const StyledFormControl = styled(FormControl)<{ estado: string }>`
  .MuiFilledInput-root {
    background-color: ${cuideoTheme.palette.primary.light};
    border-radius: ${cuideoTheme.shape.borderRadius}px;
    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
  }
  .MuiFormLabel-root {
    color: #ddd;
    font-size: 0.875rem;
  }
  .MuiSelect-select {
    color: ${cuideoTheme.palette.common.white};
    font-weight: 700;
    font-size: 1.25rem;

    &:before {
      display: none;
    }
  }
  .MuiSvgIcon-root {
    color: ${cuideoTheme.palette.common.white};
  }

  ${({ estado }) => {
    switch (estado) {
      case "Descartado":
        return `
        .MuiFilledInput-root {
          background-color: orange;
        }
        .MuiSelect-select, .MuiSvgIcon-root, .MuiFormLabel-root {
          color: ${cuideoTheme.palette.primary.main};
        }
        `;
      case "Sin Validar":
        return `
          .MuiFilledInput-root {
            background-color: ${cuideoTheme.palette.grey[300]};
          }
          .MuiSelect-select, .MuiSvgIcon-root, .MuiFormLabel-root {
            color: ${cuideoTheme.palette.primary.main};
          }
            `;
      case "Validado Plus":
        return `
            .MuiFilledInput-root {
              background-color: #ebc200;
            }
            .MuiSelect-select, .MuiSvgIcon-root, .MuiFormLabel-root {
              color:  ${cuideoTheme.palette.primary.main};
            }
          `;
      case "Activo":
        return `
            .MuiFilledInput-root {
              background-color: green;
            }
            .MuiSelect-select, .MuiSvgIcon-root, .MuiFormLabel-root {
              color: ${cuideoTheme.palette.common.white};
            }
          `;
      default:
        return `
          .MuiFilledInput-root {
            background-color: ${cuideoTheme.palette.primary.light};
          }
          .MuiSelect-select, .MuiSvgIcon-root, .MuiFormLabel-root {
            color: ${cuideoTheme.palette.primary.contrastText};
          }
        `;
    }
  }}
`;

interface IEmployeeStatusButtonProps {
  candidato: any;
  getCandidato: Function;
  snackErrorMessage: Function;
  snackSuccessMessage: Function;
  disabled?: boolean;
  allowedStatus?: string[];
}

const EmployeeStatusButton = (props: IEmployeeStatusButtonProps) => {
  const {
    candidato,
    getCandidato,
    snackErrorMessage,
    snackSuccessMessage,
    disabled,
  } = props;
  const { formatMessage } = useIntl();
  const { formatEmployeeStatus } = useFormatters();

  const handleChange = (event: any) => {
    const sendData = async (nuevoEstadoCuideo: string) => {
      let res = await apiEmpleo.patch(
        `candidatos/${candidato.id}`,
        {
          Estado_Cuideo: nuevoEstadoCuideo,
        },
        {
          headers: {
            "Content-Type": "application/merge-patch+json",
          },
        }
      );
      if (res && res.status && res.status === 200) {
        // Set true to force update
        getCandidato(candidato.id, true);
        snackSuccessMessage(
          formatMessage({ id: "Has cambiado el estado del candidato" })
        );
      } else {
        snackErrorMessage(
          formatMessage({
            id: "Ha habido algún error al intentar cambiar el estado del candidato",
          })
        );
      }
    };

    sendData(event.target.value);
  };

  let filteredEstado = candidato.Estado_Cuideo;
  if (filteredEstado === "Validado T") {
    filteredEstado = "Validado";
  }
  if (filteredEstado === "Validado E") {
    filteredEstado = "Validado";
  }

  const allowedStatus = props.allowedStatus || [
    EMPLOYEE_STATUS.NOT_VALIDATED,
    EMPLOYEE_STATUS.ACTIVE,
    EMPLOYEE_STATUS.VALIDATED,
    EMPLOYEE_STATUS.VALIDATED_PLUS,
    EMPLOYEE_STATUS.DISCARDED,
  ];

  return (
    <StyledFormControl variant="filled" fullWidth estado={filteredEstado}>
      <InputLabel id="estado-cuidador">
        <FormattedMessage
          id="EmployeeStatusButton.Status"
          defaultMessage="Estado"
        />
      </InputLabel>
      <Select
        id="estado-cuidador"
        value={filteredEstado}
        onChange={handleChange}
        variant="filled"
        disabled={disabled}
      >
        {(candidato.Estado_Cuideo === EMPLOYEE_STATUS.NOT_VALIDATED ||
          allowedStatus.includes(EMPLOYEE_STATUS.NOT_VALIDATED)) && (
          <MenuItem value={EMPLOYEE_STATUS.NOT_VALIDATED}>
            {formatEmployeeStatus(EMPLOYEE_STATUS.NOT_VALIDATED)}
          </MenuItem>
        )}
        {(candidato.Estado_Cuideo === EMPLOYEE_STATUS.VALIDATED ||
          allowedStatus.includes(EMPLOYEE_STATUS.VALIDATED)) && (
          <MenuItem value={EMPLOYEE_STATUS.VALIDATED}>
            {formatEmployeeStatus(EMPLOYEE_STATUS.VALIDATED)}
          </MenuItem>
        )}
        {(candidato.Estado_Cuideo === EMPLOYEE_STATUS.VALIDATED_PLUS ||
          allowedStatus.includes(EMPLOYEE_STATUS.VALIDATED_PLUS)) && (
          <MenuItem value={EMPLOYEE_STATUS.VALIDATED_PLUS}>
            {formatEmployeeStatus(EMPLOYEE_STATUS.VALIDATED_PLUS)}
          </MenuItem>
        )}
        {(candidato.Estado_Cuideo === EMPLOYEE_STATUS.ACTIVE ||
          allowedStatus.includes(EMPLOYEE_STATUS.ACTIVE)) && (
          <MenuItem value={EMPLOYEE_STATUS.ACTIVE}>
            {formatEmployeeStatus(EMPLOYEE_STATUS.ACTIVE)}
          </MenuItem>
        )}
        {(candidato.Estado_Cuideo === EMPLOYEE_STATUS.DISCARDED ||
          allowedStatus.includes(EMPLOYEE_STATUS.DISCARDED)) && (
          <MenuItem value={EMPLOYEE_STATUS.DISCARDED}>
            {formatEmployeeStatus(EMPLOYEE_STATUS.DISCARDED)}
          </MenuItem>
        )}
      </Select>
    </StyledFormControl>
  );
};

const mapStateToProps = ({ auth }: { auth: any }) => {
  const { authUser } = auth;
  return { authUser };
};

export default connect(mapStateToProps, {
  snackSuccessMessage,
  snackErrorMessage,
  getCandidato,
})(EmployeeStatusButton);
