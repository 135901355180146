import { useQuery } from "react-query";
import { apiListContractsManagerInfo } from "../../../../../../../../../api/contract/apiListAllContractsManagerInfo";
import { CONTRACT_PHASE } from "../../../../../../../../../api/contract/contract.types";
import {
  CONTRATO_ESTADO_ALTA_PENDIENTE,
  CONTRATO_ESTADO_CV_CLIENTE,
  CONTRATO_ESTADO_PRESENCIAL_CLIENTE,
} from "../../../../../../../../../util/defaults/contrato";

export default function useContractsManagerInfoQuery() {
  return useQuery(
    ["contracts_manager_info"],
    async () => {
      const res = await apiListContractsManagerInfo({
        estado: [
          // CONTRATO_ESTADO_EN_ESPERA,
          CONTRATO_ESTADO_ALTA_PENDIENTE,
          CONTRATO_ESTADO_CV_CLIENTE,
          // CONTRATO_ESTADO_PAGA_Y_RESERVA,
          CONTRATO_ESTADO_PRESENCIAL_CLIENTE,
        ],
        phase: [CONTRACT_PHASE.PROMOTE],
      });

      return {
        contracts: res["hydra:member"],
        totalContracts: res["hydra:totalItems"],
      };
    },
    {
      enabled: true,
      cacheTime: 1000 * 60 * 5,
    }
  );
}
