import { apiEmpleo } from "../../util/ApiEmpleo";

export type TConnectedUserResponse = {
  id: string;
  userId?: string | null;
  fullName?: string | null;
  location?: string | null;
  app?: string | null;
  appVersion?: string | null;
  firstSeen?: string | null;
  lastSeen?: string | null;
  ipAddress?: string | null;
  userAgent?: string | null;
  user?: {
    id: string;
    candidato?: string | null;
    employer?: string | null;
    fullname?: string | null;
  };
};

const apiListConnectedUsers = async () => {
  let res: {
    "hydra:member": TConnectedUserResponse[];
    "hydra:totalItems": number;
  } = await apiEmpleo.get("connected_users", {
    params: {
      itemsPerPage: 200,
      page: 1,
      doNotLog: 1,
    },
  });

  return res;
};

export { apiListConnectedUsers };
