import { apiEmpleo } from "../../util/ApiEmpleo";
import { USER_ROLES } from "../security";

export type TApiUpdateUserRolesRequest = {
  role:
    | typeof USER_ROLES.ROLE_ES_PROMOTER
    | typeof USER_ROLES.ROLE_ES_RECRUITER
    | typeof USER_ROLES.ROLE_COORDINATION;
};

const apiUpdateUserRole = async (
  userId: string,
  payload: TApiUpdateUserRolesRequest
) => {
  return apiEmpleo.patch(`users/${userId}/update-role`, payload, {
    headers: {
      "Content-Type": "application/merge-patch+json",
    },
  });
};

export { apiUpdateUserRole };
