import moment from "moment";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import { validateNumericFloatOrEmptyString, validateNumericOrEmptyString } from "../../../../../util/validators/yupValidators";
import { CONTRACT_OVERNIGHT_HOURS, CONTRACT_PRESENCE_HOURS } from "../../../../../api/contract/contract.types";

export default function useContractToSelectedStateDialogValidationSchema() {
  const { formatMessage } = useIntl();

  const validationSchema = Yup.object({
    Tipo_de_contrato: Yup.object()
      .nullable()
      .required(
        formatMessage({
          id: "ContractToSelectedStateDialog.Debes introducir el tipo de contrato",
          defaultMessage: "Debes introducir el tipo de contrato",
        })
      )
      .shape({
        value: Yup.string()
          .required(
            formatMessage({
              id: "ContractToSelectedStateDialog.Debes introducir el tipo de contrato",
              defaultMessage: "Debes introducir el tipo de contrato",
            })
          )
        }),
    Horario_Servicio: Yup.string()
      .required(
        formatMessage({
          id: "ContractToSelectedStateDialog.Debes introducir el horario de servicio",
          defaultMessage: "Debes introducir el horario de servicio",
        })
      ),
    Horas_jornada_semanal: Yup.string()
      .required(
        formatMessage({
          id: "ContractToSelectedStateDialog.Debes introducir las horas de jornada semanal",
          defaultMessage: "Debes introducir las horas de jornada semanal",
        })
      ).test(
        "numericString",
        formatMessage({
          id: "ContractToSelectedStateDialog.Las horas de jornada semanal deben ser un número entero",
          defaultMessage:
            "Las horas de jornada semanal deben ser un número entero",
        }),
        validateNumericOrEmptyString
      ),
    Horas_de_pernocta: Yup.object()
      .nullable()
      .required(
        formatMessage({
          id: "ContractToSelectedStateDialog.Debes introducir las horas de pernocta",
          defaultMessage: "Debes introducir las horas de pernocta",
        })
      )
      .shape({
        value: Yup.string()
          .required(
            formatMessage({
              id: "ContractToSelectedStateDialog.Debes introducir las horas de pernocta",
              defaultMessage: "Debes introducir las horas de pernocta",
            })
          ),
        }),
    Noches_de_pernocta: Yup.mixed()
      .when("Horas_de_pernocta", {
        is: (horasDePernocta) => {
          return horasDePernocta?.value === CONTRACT_OVERNIGHT_HOURS.YES;
        },
        then: Yup.object()
          .nullable()
          .required(
            formatMessage({
              id: "ContractToSelectedStateDialog.Debes introducir el número de noches de pernocta",
              defaultMessage: "Debes introducir el número de noches de pernocta",
            })
          )
          .shape({
            value: Yup.string()
              .required(
                formatMessage({
                  id: "ContractToSelectedStateDialog.Debes introducir el número de noches de pernocta",
                  defaultMessage: "Debes introducir el número de noches de pernocta",
                })
              ),
            }),
        otherwise: Yup.object().nullable(),
      }),
    Horas_de_presencia: Yup.object()
      .nullable()
      .required(
        formatMessage({
          id: "ContractToSelectedStateDialog.Debes introducir las horas de presencia",
          defaultMessage: "Debes introducir las horas de presencia",
        })
      )
      .shape({
        value: Yup.string()
          .required(
            formatMessage({
              id: "ContractToSelectedStateDialog.Debes introducir las horas de presencia",
              defaultMessage: "Debes introducir las horas de presencia",
            })
          ),
        }),
    N_de_horas_de_presencia_a_la_semana: Yup.mixed()
      .when("Horas_de_presencia", {
        is: (horasDePresencia) => {
          return horasDePresencia?.value === CONTRACT_PRESENCE_HOURS.YES;
        },
        then: Yup.string()
          .required(
            formatMessage({
              id: "ContractToSelectedStateDialog.Debes introducir el número de horas de presencia a la semana",
              defaultMessage: "Debes introducir el número de horas de presencia a la semana",
            })
          ).test(
            "numericString",
            formatMessage({
              id: "ContractToSelectedStateDialog.El número de horas de presencia a la semana debe ser un número entero",
              defaultMessage: "El número de horas de presencia a la semana debe ser un número entero",
            }),
            validateNumericOrEmptyString
          ),
        otherwise: Yup.string().nullable(),
      }),
    Fecha_de_inicio_real: Yup.mixed()
      .required(
        formatMessage({
          id: "ContractToSelectedStateDialog.Debes introducir la fecha de inicio real",
          defaultMessage: "Debes introducir la fecha de inicio real",
        })
      )
      .test(
        "valid_date",
        formatMessage({
          id: "ContractToSelectedStateDialog.La fecha de inicio real ha de ser válida",
          defaultMessage: "La fecha de inicio real ha de ser válida",
        }),
        (value) => {
          const momDate = moment(value);
          if (momDate.isValid()) {
            return true;
          }
          return false;
        }
      ),
    Fecha_fin_contrato: Yup.mixed()
      .test(
        "valid_date",
        formatMessage({
          id: "ContractToSelectedStateDialog.La fecha fin contrato ha de ser válida",
          defaultMessage: "La fecha fin contrato ha de ser válida",
        }),
        (value) => {
          const momDate = moment(value);
          if (momDate.isValid()) {
            return true;
          }
          return false;
        }
      ),
    Per_odo_de_prueba: Yup.string()
      .required(
        formatMessage({
          id: "ContractToSelectedStateDialog.Debes introducir el período de prueba",
          defaultMessage: "Debes introducir el período de prueba",
        })
      ),
    Tipo_de_salario: Yup.object()
      .nullable()
      .required(
        formatMessage({
          id: "ContractToSelectedStateDialog.Debes introducir el tipo de salario",
          defaultMessage: "Debes introducir el tipo de salario",
        })
      )
      .shape({
        value: Yup.string()
          .required(
            formatMessage({
              id: "ContractToSelectedStateDialog.Debes introducir el tipo de salario",
              defaultMessage: "Debes introducir el tipo de salario",
            })
          ),
        }),
    Numero_de_pagas: Yup.object()
      .nullable()
      .required(
        formatMessage({
          id: "ContractToSelectedStateDialog.Debes introducir el número de pagas",
          defaultMessage: "Debes introducir el número de pagas",
        })
      )
      .shape({
        value: Yup.string()
          .required(
            formatMessage({
              id: "ContractToSelectedStateDialog.Debes introducir el número de pagas",
              defaultMessage: "Debes introducir el número de pagas",
            })
          ),
        }),
    Salario_Bruto_Mensual: Yup.string()
      .required(
        formatMessage({
          id: "ContractToSelectedStateDialog.Debes introducir el salario bruto mensual",
          defaultMessage: "Debes introducir el salario bruto mensual",
        })
      ).test(
        "numericFloatString",
        formatMessage({
          id: "ContractToSelectedStateDialog.El salario bruto debe ser un número entero o decimal",
          defaultMessage:
            "El salario bruto debe ser un número entero o decimal",
        }),
        validateNumericFloatOrEmptyString
      ),
    Cl_usulas_especiales: Yup.string()
      .required(
        formatMessage({
          id: "ContractToSelectedStateDialog.Debes introducir las cláusulas especiales",
          defaultMessage: "Debes introducir las cláusulas especiales",
        })
      ),
  });

  return validationSchema;
}
