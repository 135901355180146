import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import uuidv4 from "uuid/v4";
import * as Yup from "yup";
import cuideoTheme from "../../../../containers/themes/defaultTheme";
import { getPathologyLabelForCountry } from "../../../../util/defaults/patologias";
import { formatAsPhoneNumber } from "../../../../util/formatters";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import {
  FormikSelect,
  FormikTextField,
} from "../../../../components/FormikFields";
import VisibleFieldOnProfile from "../../../../components/FormikFields/VisibleFieldOnProfile";
import useCountryPathologies from "../../../../components/Candidato/useCountryPathologies";
import { validatePhoneOrEmpty } from "../../../../util/validators/yupValidators";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import moveElementInArray from "../../../../util/moveElementInArray";

const StyledEmptyMessage = styled(Typography)`
  text-align: center;
  font-size: 1rem;
`;

const StyledSectionHeader = styled(Typography)`
  font-size: 1.25rem;
  color: ${cuideoTheme.palette.primary.main};
  border-bottom: 1px solid ${cuideoTheme.palette.grey[300]};
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
`;

const StyledFormWrapper = styled.div`
  margin: 0 1rem;
`;

const StyledHelpText = styled(Typography)`
  color: ${cuideoTheme.palette.grey[600]};
  font-size: 0.8rem;
  margin: 1rem 0;
`;

const StyledTableBodyCell = styled(TableCell)`
  font-size: 0.875rem;
  color: ${cuideoTheme.palette.primary.main};
  line-height: 1.25;
  padding: 0.5rem 0.75rem;
`;

const StyledPatologia = styled.span`
  &:after {
    content: ", ";
  }
  &:last-child:after {
    content: "";
  }
`;

const StyledHeadCell = styled(TableCell)`
  background-color: rgba(0, 72, 120, 0.1);
  color: ${cuideoTheme.palette.primary.light};
  font-size: 0.75rem;
  line-height: normal;
  font-weight: 700;
  padding: 0.75rem 0.75rem;
`;

const StyledBodyRow = styled(TableRow)`
  background-color: #fff;
`;

const StyledTable = styled(Table)`
  table-layout: fixed;

  .MuiTableCell-head:nth-child(1) {
    width: 15%;
  }
  .MuiTableCell-head:nth-child(2) {
    width: 20%;
  }
  .MuiTableCell-head:nth-child(3) {
    width: 20%;
  }
  .MuiTableCell-head:nth-child(4) {
    width: 15%;
  }
  .MuiTableCell-head:nth-child(5) {
    width: 20%;
  }
  .MuiTableCell-head:nth-child(6) {
    width: 10%;
  }
`;

const StyledIconButton = styled(IconButton)`
  border: 1px solid #c2d1da;
  margin: 0.125rem;
  color: ${cuideoTheme.palette.primary.light};

  &.CMui-showable {
    color: ${cuideoTheme.palette.common.white};
    background-color: ${cuideoTheme.palette.primary.main};
  }
`;

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: "#fff",
    innerWidth: "100%",
    outerWidth: "100%",
    display: "table",
  }),
});

const DraggableComponent = (id: string, index: number) => (props: any) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided: any, snapshot: any) => (
        <StyledBodyRow
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
          {...props}
        >
          {props.children}
        </StyledBodyRow>
      )}
    </Draggable>
  );
};

const DroppableComponent =
  (onDragEnd: (result: any, provided: any) => void) => (props: any) => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={"1"} direction="vertical">
          {(provided: any) => {
            return (
              <TableBody
                ref={provided.innerRef}
                {...provided.droppableProps}
                {...props}
              >
                {props.children}
                {provided.placeholder}
              </TableBody>
            );
          }}
        </Droppable>
      </DragDropContext>
    );
  };

interface Experiencia {
  id: string;
  Comentarios_referencias: string;
  Empresa_o_famila: string;
  Funciones_y_tareas_realizadas: string;
  Nombre_del_contacto: string;
  Patolog_as_tratadas: any;
  Telefono_del_contacto: string;
  Mostrar_Ref_Ficha?: boolean | null;
}

interface IExperienciasTableProps {
  Experiencia_y_referencias: any;
  onChange: (data: any) => void;
  candidato: any;
}

const ExperienciasTable = (props: IExperienciasTableProps) => {
  const { Experiencia_y_referencias, onChange, candidato } = props;
  const { formatMessage } = useIntl();

  const patologiasOptions = useCountryPathologies(candidato.Business_Country);

  const [experiencias, setExperiencias] = useState([] as any);
  const [formInitialValues, setFormInitialValues] = useState({
    id: "",
    Comentarios_referencias: "",
    Empresa_o_famila: "",
    Funciones_y_tareas_realizadas: "",
    Nombre_del_contacto: "",
    Patolog_as_tratadas: [] as any,
    Telefono_del_contacto: "",
    Mostrar_Ref_Ficha: false,
  });
  const [deleteId, setDeleteId] = useState<string | null>(null);

  const [state, setState] = useState({
    open: false,
    mode: "add",
  });

  useEffect(() => {
    if (Experiencia_y_referencias) {
      setExperiencias(Experiencia_y_referencias);
    } else {
      setExperiencias([]);
    }
  }, [Experiencia_y_referencias]);

  const validationSchema = Yup.object({
    Empresa_o_famila: Yup.string().required(
      formatMessage({
        id: "ExperiencesTable.Required",
        defaultMessage: "Obligatorio",
      })
    ),
    Funciones_y_tareas_realizadas: Yup.string()
      .required(
        formatMessage({
          id: "ExperiencesTable.Required",
          defaultMessage: "Obligatorio",
        })
      )
      .max(
        1995,
        formatMessage({
          id: "ExperiencesTable.TooBig",
          defaultMessage: "Demasiado largo",
        })
      ),
    Comentarios_referencias: Yup.string().max(
      1995,
      formatMessage({
        id: "ExperiencesTable.TooBig",
        defaultMessage: "Demasiado largo",
      })
    ),
    Telefono_del_contacto: Yup.string()
      .max(
        30,
        formatMessage({
          id: "ExperiencesTable.TooBig",
          defaultMessage: "Demasiado largo",
        })
      )
      .test(
        "valid-phone",
        formatMessage({
          id: "ExperiencesTable.El teléfono no es válido",
          defaultMessage: "El teléfono no es válido",
        }),
        validatePhoneOrEmpty
      ),
  });

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const handleClickAdd = () => {
    setFormInitialValues({
      id: uuidv4(),
      Comentarios_referencias: "",
      Empresa_o_famila: "",
      Funciones_y_tareas_realizadas: "",
      Nombre_del_contacto: "",
      Patolog_as_tratadas: "",
      Telefono_del_contacto: "",
      Mostrar_Ref_Ficha: false,
    });
    setState({
      open: true,
      mode: "add",
    });
  };

  const handleDelete = (id: string) => {
    setDeleteId(id);
  };

  const handleDeleteConfirm = (id: string) => {
    const newExperiencias = experiencias.filter((item: any) => item.id !== id);
    setExperiencias(newExperiencias);
    onChange(newExperiencias);
    setDeleteId(null);
  };

  const handleToggleVisibility = (id: string) => {
    const newExperiences = experiencias.map((experience: any) => {
      return {
        ...experience,
        Mostrar_Ref_Ficha:
          experience.id === id
            ? !!!experience.Mostrar_Ref_Ficha
            : !!experience.Mosrar_Ref_Ficha,
      };
    });
    setExperiencias(newExperiences);
    onChange(newExperiences);
  };

  const handleEdit = (experiencia: Experiencia) => {
    setFormInitialValues({
      id: experiencia.id,
      Comentarios_referencias: experiencia.Comentarios_referencias
        ? experiencia.Comentarios_referencias
        : "",
      Empresa_o_famila: experiencia.Empresa_o_famila
        ? experiencia.Empresa_o_famila
        : "",
      Funciones_y_tareas_realizadas: experiencia.Funciones_y_tareas_realizadas
        ? experiencia.Funciones_y_tareas_realizadas
        : "",
      Nombre_del_contacto: experiencia.Nombre_del_contacto
        ? experiencia.Nombre_del_contacto
        : "",
      Patolog_as_tratadas: experiencia.Patolog_as_tratadas.map((item: any) => {
        return {
          value: item,
          label: getPathologyLabelForCountry(item, candidato.Business_Country),
        };
      }),
      Telefono_del_contacto: experiencia.Telefono_del_contacto
        ? formatAsPhoneNumber(experiencia.Telefono_del_contacto)
        : "",
      Mostrar_Ref_Ficha: experiencia.Mostrar_Ref_Ficha ?? false,
    });
    setState({
      open: true,
      mode: "edit",
    });
  };

  const handleFormSubmit = (values: any, actions: any) => {
    // Values must correct patologias
    values.Patolog_as_tratadas = values.Patolog_as_tratadas.length
      ? values.Patolog_as_tratadas.map((item: any) => item.value)
      : [];
    values.Telefono_del_contacto = values.Telefono_del_contacto.replace(
      /\s+/g,
      ""
    );
    if (state.mode === "add") {
      setExperiencias([...experiencias, { ...values }]);
      onChange([...experiencias, { ...values }]);
    } else {
      // Edit mode
      const newExperiencias = [...experiencias];
      const pos = newExperiencias.map((i: any) => i.id).indexOf(values.id);
      newExperiencias[pos] = { ...values };
      setExperiencias(newExperiencias);
      onChange(newExperiencias);
    }
    handleClose();
  };

  const handleDragEnd = (result: DropResult) => {
    if (
      !!result.destination &&
      result.source.index !== result.destination.index
    ) {
      const newExperiences = moveElementInArray(
        experiencias,
        result.source.index,
        result.destination.index
      );
      setExperiencias(newExperiences);
      onChange(newExperiences);
    }
  };

  return (
    <Fragment>
      <Grid container spacing={4}>
        <Grid item style={{ flexGrow: 1 }}>
          <StyledSectionHeader variant="h4">
            <FormattedMessage
              id="ExperiencesTable.Experiences"
              defaultMessage="Experiencias"
            />{" "}
            <VisibleFieldOnProfile />
          </StyledSectionHeader>
        </Grid>
        <Grid item>
          <Fab
            variant="extended"
            size="small"
            color="primary"
            onClick={handleClickAdd}
          >
            <AddIcon />
          </Fab>
        </Grid>
      </Grid>
      <StyledTable>
        <TableHead>
          <TableRow>
            <StyledHeadCell>
              <FormattedMessage
                id="ExperiencesTable.ExperiencePeriod"
                defaultMessage="Experiencia/Periodo"
              />
            </StyledHeadCell>
            <StyledHeadCell>
              <FormattedMessage
                id="ExperiencesTable.Descripción del Servicio"
                defaultMessage="Descripción del Servicio"
              />
            </StyledHeadCell>
            <StyledHeadCell>
              <FormattedMessage
                id="ExperiencesTable.Pathologies"
                defaultMessage="Patologías"
              />
            </StyledHeadCell>
            <StyledHeadCell>
              <FormattedMessage
                id="ExperiencesTable.Contact"
                defaultMessage="Contacto"
              />
            </StyledHeadCell>
            <StyledHeadCell>
              <FormattedMessage
                id="ExperiencesTable.Referencias"
                defaultMessage="Referencias"
              />
            </StyledHeadCell>
            <StyledHeadCell>
              <FormattedMessage
                id="ExperiencesTable.Actions"
                defaultMessage="Acciones"
              />
            </StyledHeadCell>
          </TableRow>
        </TableHead>
        <TableBody component={DroppableComponent(handleDragEnd)}>
          {experiencias && experiencias.length > 0 ? (
            experiencias.map((item: any, index: number) => {
              const showOnProfile = !!item.Mostrar_Ref_Ficha;
              const tooltipText = showOnProfile
                ? formatMessage({
                    id: "ExperiencesTable.La referencia se muestra en perfil público",
                    defaultMessage:
                      "La referencia se muestra en perfil público",
                  })
                : formatMessage({
                    id: "ExperiencesTable.La referencia no se muestra en perfil público",
                    defaultMessage:
                      "La referencia no se muestra en perfil público",
                  });
              const showOnProfileIcon = showOnProfile ? (
                <VisibilityIcon fontSize="small" />
              ) : (
                <VisibilityOffIcon fontSize="small" />
              );

              return (
                <TableRow
                  component={DraggableComponent(item.id, index)}
                  key={item.id}
                >
                  <StyledTableBodyCell>
                    {item.Empresa_o_famila}
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    {item.Funciones_y_tareas_realizadas}
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    {item.Patolog_as_tratadas &&
                      item.Patolog_as_tratadas.length > 0 &&
                      item.Patolog_as_tratadas.map((item: any) => (
                        <StyledPatologia key={item}>
                          {getPathologyLabelForCountry(
                            item,
                            candidato.Business_Country
                          )}
                        </StyledPatologia>
                      ))}
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    {item.Nombre_del_contacto}
                    <br />
                    {formatAsPhoneNumber(item.Telefono_del_contacto)}
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    {item.Comentarios_referencias}
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    <Tooltip title={tooltipText}>
                      <StyledIconButton
                        aria-label={tooltipText}
                        color="primary"
                        onClick={() => {
                          handleToggleVisibility(item.id);
                        }}
                        className={showOnProfile ? "CMui-showable" : undefined}
                      >
                        {showOnProfileIcon}
                      </StyledIconButton>
                    </Tooltip>
                    <Tooltip title="Editar">
                      <StyledIconButton
                        aria-label="editar"
                        color="primary"
                        onClick={() => {
                          handleEdit(item);
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </StyledIconButton>
                    </Tooltip>
                    <Tooltip title="Eliminar">
                      <StyledIconButton
                        aria-label="eliminar"
                        color="primary"
                        onClick={() => {
                          handleDelete(item.id);
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </StyledIconButton>
                    </Tooltip>
                  </StyledTableBodyCell>
                </TableRow>
              );
            })
          ) : (
            <StyledBodyRow>
              <TableCell colSpan={6}>
                <StyledEmptyMessage>
                  <FormattedMessage
                    id="ExperiencesTable.NoPrevious"
                    defaultMessage="No hay recogidas experiencias previas"
                  />
                </StyledEmptyMessage>
              </TableCell>
            </StyledBodyRow>
          )}
        </TableBody>
      </StyledTable>
      <Dialog open={state.open} onClose={handleClose}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={validationSchema}
          onSubmit={(values: any, actions: any) => {
            handleFormSubmit(values, actions);
          }}
        >
          {(props) => {
            return (
              <Form>
                <DialogTitle>
                  <FormattedMessage
                    id="ExperiencesTable.Add"
                    defaultMessage="Añade experiencia"
                  />
                </DialogTitle>
                <DialogContent>
                  <StyledFormWrapper>
                    <Grid container spacing={8}>
                      <Grid item xs={12}>
                        <FormikTextField
                          name="Empresa_o_famila"
                          variant="outlined"
                          label={
                            <FormattedMessage
                              id="ExperiencesTable.ExperiencePeriod"
                              defaultMessage="Experiencia/Periodo"
                            />
                          }
                          margin="normal"
                          fullWidth
                        />
                        <FormikTextField
                          label={
                            <FormattedMessage
                              id="ExperiencesTable.Descripción del Servicio"
                              defaultMessage="Descripción del Servicio"
                            />
                          }
                          name="Funciones_y_tareas_realizadas"
                          variant="outlined"
                          multiline
                          minRows="3"
                          maxRows="6"
                          fullWidth
                        />
                        <FormikSelect
                          name="Patolog_as_tratadas"
                          label={
                            <FormattedMessage
                              id="ExperiencesTable.Pathologies"
                              defaultMessage="Patologías"
                            />
                          }
                          required
                          isMulti
                          variant="compact"
                          size="small"
                          closeMenuOnSelect={false}
                          placeholder={formatMessage({
                            id: "ExperiencesTable.Selecciona",
                            defaultMessage: "Selecciona",
                          })}
                          options={patologiasOptions}
                        />
                        <StyledHelpText>
                          <FormattedMessage
                            id="ExperiencesTable.Validating"
                            defaultMessage="Para validar el anterior trabajo y obtener información necesitamos hablar con alguna persona que pueda explicarnos y darnos más detalles sobre la anterior experiencia. Por favor, escribe el nombre, así como el teléfono de contacto de la persona que nos pueda dar referencias:"
                          />
                        </StyledHelpText>
                        <FormikTextField
                          label={
                            <FormattedMessage
                              id="ExperiencesTable.Name"
                              defaultMessage="Nombre"
                            />
                          }
                          name="Nombre_del_contacto"
                          variant="outlined"
                          fullWidth
                        />
                        <FormikTextField
                          label={
                            <FormattedMessage
                              id="ExperiencesTable.Phone"
                              defaultMessage="Teléfono"
                            />
                          }
                          name="Telefono_del_contacto"
                          variant="outlined"
                          fullWidth
                          telformat
                          defaultPhonePrefix={
                            candidato.Business_Country === "fr"
                              ? "+33"
                              : undefined
                          }
                        />
                        <FormikTextField
                          label={
                            <FormattedMessage
                              id="ExperiencesTable.Referencias"
                              defaultMessage="Referencias"
                            />
                          }
                          name="Comentarios_referencias"
                          variant="outlined"
                          multiline
                          minRows="3"
                          maxRows="6"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </StyledFormWrapper>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    <FormattedMessage
                      id="ExperiencesTable.Cancel"
                      defaultMessage="Cancelar"
                    />
                  </Button>
                  <Button color="primary" variant="contained" type="submit">
                    <FormattedMessage
                      id="ExperiencesTable.Save"
                      defaultMessage="Guardar"
                    />
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
      <ConfirmDialog
        open={!!deleteId}
        title={
          <FormattedMessage
            id="ExperiencesTable.DeleteTitle"
            defaultMessage="Eliminar experiencia"
          />
        }
        label={
          <FormattedMessage
            id="ExperiencesTable.DeleteLabel"
            defaultMessage="¿Estás seguro que deseas eliminar la experiencia indicada?"
          />
        }
        onConfirm={() => {
          handleDeleteConfirm(deleteId ? deleteId : "");
        }}
        onCancel={() => {
          setDeleteId(null);
        }}
      />
    </Fragment>
  );
};

export default React.memo(
  (props: IExperienciasTableProps) => {
    return <ExperienciasTable {...props} />;
  },
  (prevProps, nextProps) => {
    if (
      prevProps.Experiencia_y_referencias !==
        nextProps.Experiencia_y_referencias ||
      prevProps.candidato !== nextProps.candidato
    ) {
      return false;
    }
    return true;
  }
);
