import { Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { cuideoTheme } from "../../../../../../containers/themes/defaultTheme";
import { EMPLOYEE_FELIZ_VITA_STATUS } from "../../../../../../api/employee/employee.types";
import FelizVitaLogo from "../../../../../../assets/images/feliz_vita_logo.svg";

const StyledTag = styled.div<{ status: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ccc;
  padding: 0.125rem 0.5rem;
  border-radius: ${cuideoTheme.shape.borderRadius}px;
  min-height: 1.875rem;

  ${(props) => {
    switch (props.status) {
      case EMPLOYEE_FELIZ_VITA_STATUS.DISCARDED:
        return `
          background-color: orange;
          color: ${cuideoTheme.palette.primary.main};
        `;
      case EMPLOYEE_FELIZ_VITA_STATUS.NOT_VALIDATED:
        return `
          background-color: ${cuideoTheme.palette.grey[300]};
          color: ${cuideoTheme.palette.primary.main};
          `;
      case EMPLOYEE_FELIZ_VITA_STATUS.VALIDATED_PLUS:
        return `
          background-color: #ebc200;
          color: ${cuideoTheme.palette.primary.main};
        `;
      case EMPLOYEE_FELIZ_VITA_STATUS.ACTIVE:
        return `
          background-color: green;
          color: ${cuideoTheme.palette.common.white};
        `;
      default:
        return `
          background-color: ${cuideoTheme.palette.primary.light};
          color: ${cuideoTheme.palette.primary.contrastText};
        `;
    }
  }}
`;

const StyledTagTop = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledTagLabel = styled(Typography)`
  font-size: 0.8125rem;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
`;

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledFelizVitaLogoWrapper = styled.div`
  background-color: #fff;
  padding: 0.25rem;
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 50%;
  box-sizing: border-box;
  position: absolute;
  left: -0.6125rem;
  bottom: 0.375rem;
  z-index: 1;
`;

const StyledFelizVitaLogo = styled.img`
  width: 100%;
  height: 100%;
`;

interface ICandidateFvStatusTagProps {
  status: string;
}

const CandidateFvStatusTag: React.FC<ICandidateFvStatusTagProps> = ({
  status,
}) => {
  return (
    <StyledWrapper>
      <StyledFelizVitaLogoWrapper className="CMuiCandidateFvStatusTag-felizVitaLogo">
        <StyledFelizVitaLogo src={FelizVitaLogo} />
      </StyledFelizVitaLogoWrapper>
      <StyledTag className="CMuiCandidateFvStatus" status={status}>
        <StyledTagTop>
          <StyledTagLabel>{status}</StyledTagLabel>
        </StyledTagTop>
      </StyledTag>
    </StyledWrapper>
  );
};

export default CandidateFvStatusTag;
