import styled from "styled-components";
import {
  Typography,
  Button,
  Card,
  Dialog,
  IconButton,
  Chip,
  Box
} from "@material-ui/core";
import cuideoTheme from "../../containers/themes/defaultTheme";

const StyledCard = styled(Card)`
  color: ${cuideoTheme.palette.primary.main};
  margin-bottom: 1rem;
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    box-shadow: none;
    border-bottom: 1px solid #e5edf1;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    box-shadow: 0 2px 4px 0 #a2c4d8;
  }
`;
const StyledProfileCard = styled(Card)`
  color: ${cuideoTheme.palette.primary.main};
  background-color: ${cuideoTheme.palette.grey[100]};
  margin-bottom: 1rem;
  padding-top: 1rem;
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    box-shadow: none;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    box-shadow: 0 2px 4px 0 #a2c4d8;
  }
`;

const StyledCardHeader = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: row;
`;

const StyledCardTitle = styled(Typography)`
  font-size: 1.25rem;
  font-weight: 700;
`;

const StyledCardActions = styled.div`
  margin-left: auto;
`;
const StyledCardAction = styled(Button)`
  display: block;
  font-size: 0.875rem;
  padding: 0.25rem 1rem;
  min-height: unset;
  line-height: 1.33;
  margin: 0.25rem 0 0.75rem 0;
`;

const StyledCardContent = styled.div`
  padding: 0 0.75rem 0.75rem;
`;

const StyledLabel = styled(Typography)`
  font-size: 0.875rem;
`;

const StyledData = styled(Box)`
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
  font-family: "Source sans pro", Helvetica, Arial, sans-serif;
`;

const StyledDialog = styled(Dialog)``;

const StyledDialogHead = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledDialogCloseButton = styled(IconButton)`
  border-radius: 50%;
  margin: 0.25rem;
  width: 2.5rem;
`;

const StyledDialogTitle = styled(Typography)`
  font-weight: 700;
  line-height: 3rem;
  margin: 0 1rem;
`;

const StyledDialogActions = styled.div`
  margin: 0.25rem;
`;

const StyledDialogAction = styled(Button)``;

const StyledDialogContent = styled.div`
  padding: 1rem 1rem 2rem;
`;

const StyledField = styled.div`
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;

  &:last-child {
    border-bottom: none;
  }
`;

const StyledFormLabel = styled(Typography)`
  color: ${cuideoTheme.palette.primary.light};
  font-size: 0.8rem;
  white-space: wrap;
  padding: 0.2rem 0 0.2rem 0;

  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 0.8rem;
  }
`;

const StyledChip = styled(Chip)`
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
  background-color: rgba(0, 72, 120, 0.25);
  color: ${cuideoTheme.palette.primary.main};
`;

const StyledTaskChip = styled(Chip)`
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
  background-color: rgba(0, 72, 120, 0.25);
  color: ${cuideoTheme.palette.primary.main};
`;

export {
  StyledCard,
  StyledProfileCard,
  StyledCardHeader,
  StyledCardAction,
  StyledCardActions,
  StyledCardContent,
  StyledCardTitle,
  StyledLabel,
  StyledData,
  StyledDialog,
  StyledDialogHead,
  StyledDialogCloseButton,
  StyledDialogTitle,
  StyledDialogActions,
  StyledDialogAction,
  StyledDialogContent,
  StyledField,
  StyledFormLabel,
  StyledChip,
  StyledTaskChip
};
