import { useQuery } from "react-query";
import { apiListBotWorkers } from "../../../../../../../apiCoreV3/worker/api-list-bot-workers";

export const useBotWorkers = (contractCrmId: string[]) => {
  return useQuery(
    ["bot-workers", "list", contractCrmId],
    async () => {
      const res = await apiListBotWorkers({
        contractCrmId,
      });

      return res;
    },
    {
      enabled: true,
      retry: 0,
    }
  );
};
