import React from "react";
import { FormattedMessage } from "react-intl";

interface IFormattedPathologyFieldProps {
  pathology: string;
}

const FormattedPathologyField = (props: IFormattedPathologyFieldProps) => {
  const { pathology } = props;

  switch (pathology) {
    case "Anemia":
      return <FormattedMessage id="Pathology.Anemia" defaultMessage="Anemia" />;
    case "Artrosis":
      return (
        <FormattedMessage id="Pathology.Artrosis" defaultMessage="Artrosis" />
      );
    case "Autismo":
      return (
        <FormattedMessage id="Pathology.Autismo" defaultMessage="Autismo" />
      );
    case "Trastorno Bipolar":
      return (
        <FormattedMessage
          id="Pathology.Trastorno Bipolar"
          defaultMessage="Trastorno Bipolar"
        />
      );
    case "Cáncer":
      return <FormattedMessage id="Pathology.Cáncer" defaultMessage="Cáncer" />;
    case "Cuidados paliativos":
      return (
        <FormattedMessage
          id="Pathology.Cuidados paliativos"
          defaultMessage="Cuidados paliativos"
        />
      );
    case "Déficit de memoria y atención":
      return (
        <FormattedMessage
          id="Pathology.Déficit de memoria y atención"
          defaultMessage="Déficit de memoria y atención"
        />
      );
    case "Enfermedad de Charcot-Marie-Tooth":
      return (
        <FormattedMessage
          id="Pathology.Enfermedad de Charcot-Marie-Tooth"
          defaultMessage="Enfermedad de Charcot-Marie-Tooth"
        />
      );
    case "Paraplegia":
      return (
        <FormattedMessage
          id="Pathology.Paraplegia"
          defaultMessage="Paraplegia"
        />
      );
    case "Cuerpos de Lewy":
      return (
        <FormattedMessage
          id="Pathology.Cuerpos de Lewy"
          defaultMessage="Cuerpos de Lewy"
        />
      );
    case "Patologías intestinales":
      return (
        <FormattedMessage
          id="Pathology.Patologías intestinales"
          defaultMessage="Patologías intestinales"
        />
      );
    case "Síndrome de RETT":
      return (
        <FormattedMessage
          id="Pathology.Síndrome de RETT"
          defaultMessage="Síndrome de RETT"
        />
      );
    case "Depresión":
      return (
        <FormattedMessage id="Pathology.Depresión" defaultMessage="Depresión" />
      );
    case "Diabetes":
      return (
        <FormattedMessage id="Pathology.Diabetes" defaultMessage="Diabetes" />
      );
    case "Diversos tipos de demencia":
      return (
        <FormattedMessage
          id="Pathology.Diversos tipos de demencia"
          defaultMessage="Diversos tipos de demencia"
        />
      );
    case "Enfermedad de Alzheimer":
      return (
        <FormattedMessage
          id="Pathology.Enfermedad de Alzheimer"
          defaultMessage="Enfermedad de Alzheimer"
        />
      );
    case "Enfermedad de EPOC":
      return (
        <FormattedMessage
          id="Pathology.Enfermedad de EPOC"
          defaultMessage="Enfermedad de EPOC"
        />
      );
    case "Enfermedad de Parkinson":
      return (
        <FormattedMessage
          id="Pathology.Enfermedad de Parkinson"
          defaultMessage="Enfermedad de Parkinson"
        />
      );
    case "Enfermedad de TOC":
      return (
        <FormattedMessage
          id="Pathology.Enfermedad de TOC"
          defaultMessage="Enfermedad de TOC"
        />
      );
    case "ELA / EM":
      return (
        <FormattedMessage id="Pathology.ELA / EM" defaultMessage="ELA / EM" />
      );
    case "Epilepsia":
      return (
        <FormattedMessage id="Pathology.Epilepsia" defaultMessage="Epilepsia" />
      );
    case "Esquizofrenia":
      return (
        <FormattedMessage
          id="Pathology.Esquizofrenia"
          defaultMessage="Esquizofrenia"
        />
      );
    case "Fibromialgia":
      return (
        <FormattedMessage
          id="Pathology.Fibromialgia"
          defaultMessage="Fibromialgia"
        />
      );
    case "Fracturas (cadera) y traumatismos":
      return (
        <FormattedMessage
          id="Pathology.Fracturas (cadera) y traumatismos"
          defaultMessage="Fracturas (cadera) y traumatismos"
        />
      );
    case "Hipertensión":
      return (
        <FormattedMessage
          id="Pathology.Hipertensión"
          defaultMessage="Hipertensión"
        />
      );
    case "Ictus":
      return <FormattedMessage id="Pathology.Ictus" defaultMessage="Ictus" />;
    case "Inmovilidad":
      return (
        <FormattedMessage
          id="Pathology.Inmovilidad"
          defaultMessage="Inmovilidad"
        />
      );
    case "Insuficiencia renal":
      return (
        <FormattedMessage
          id="Pathology.Insuficiencia renal"
          defaultMessage="Insuficiencia renal"
        />
      );
    case "Insuficiencia respiratoria/cardíaca":
      return (
        <FormattedMessage
          id="Pathology.Insuficiencia respiratoria/cardíaca"
          defaultMessage="IInsuficiencia respiratoria/cardíacactus"
        />
      );
    case "Movilidad reducida":
      return (
        <FormattedMessage
          id="Pathology.Movilidad reducida"
          defaultMessage="Movilidad reducida"
        />
      );
    case "Obesidad mórbida":
      return (
        <FormattedMessage
          id="Pathology.Obesidad mórbida"
          defaultMessage="Obesidad mórbida"
        />
      );
    case "Osteoporosis":
      return (
        <FormattedMessage
          id="Pathology.Osteoporosis"
          defaultMessage="Osteoporosis"
        />
      );
    case "Otras patologías":
      return (
        <FormattedMessage
          id="Pathology.Otras patologías"
          defaultMessage="Otras patologías"
        />
      );
    case "Otras patologías psiquiátricas":
      return (
        <FormattedMessage
          id="Pathology.Otras patologías psiquiátricas"
          defaultMessage="Otras patologías psiquiátricas"
        />
      );
    case "Patologías sensoriales y auditivas":
      return (
        <FormattedMessage
          id="Pathology.Patologías sensoriales y auditivas"
          defaultMessage="Patologías sensoriales y auditivas"
        />
      );
    case "Problemas de la memoria y la atención":
      return (
        <FormattedMessage
          id="Pathology.Problemas de la memoria y la atención"
          defaultMessage="Problemas de la memoria y la atención"
        />
      );
    case "Sobrepeso":
      return (
        <FormattedMessage id="Pathology.Sobrepeso" defaultMessage="Sobrepeso" />
      );
    case "Sin patologías":
      return (
        <FormattedMessage
          id="Pathology.Sin patologías"
          defaultMessage="Sin patologías"
        />
      );
    case "Síndrome de Down":
      return (
        <FormattedMessage
          id="Pathology.Síndrome de Down"
          defaultMessage="Síndrome de Down"
        />
      );
    case "VIH":
      return <FormattedMessage id="Pathology.VIH" defaultMessage="VIH" />;
  }
  return null;
};

export default FormattedPathologyField;
