import { apiEmpleo } from "../../util/ApiEmpleo";

const apiListContractEvents = async (contractId: string) => {
  const res: {
    "hydra:member": any[];
    "hydra:totalItems": number;
  } = await apiEmpleo.get(`contratos/${contractId}/events`);

  return res;
};

export { apiListContractEvents };
