import { apiEmpleo } from "../../util/ApiEmpleo";

export type TCreateUserFavoriteRequest = {
  user: string;
  candidate: string;
  note: string | null;
};

export const apiCreateUserFavorite = async (
  payload: TCreateUserFavoriteRequest
) => {
  if (payload.user) {
    payload.user = `/api/users/${payload.user}`;
  }
  if (payload.candidate) {
    payload.candidate = `/api/candidatos/${payload.candidate}`;
  }

  let res = await apiEmpleo.post(`user-favorite-candidates`, payload, {
    headers: {
      "Content-Type": "application/ld+json",
    },
  });

  return res;
};
