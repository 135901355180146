import { Tooltip } from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import "moment/locale/es";
import { Fragment, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  formatPhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { CONTRACT_PHASE } from "../../../../../../api/contract/contract.types";
import AvatarIcon from "../../../../../../assets/images/iconos/avatar.png";
import EditIcon from "../../../../../../assets/images/iconos/edit.png";
import ListIcon from "../../../../../../assets/images/iconos/list.png";
import OpenNewIcon from "../../../../../../assets/images/iconos/open-new.png";
import TrashIcon from "../../../../../../assets/images/iconos/trash-can.png";
import ZohoIcon from "../../../../../../assets/images/iconos/zoho.png";
import ContractPopupViewButton from "../../../../../../components/Contrato/ContractPopupViewButton";
import HorarioIcon from "../../../../../../components/CuideoIcons/HorarioIcon.svg";
import MoneyIcon from "../../../../../../components/CuideoIcons/MoneyIcon.svg";
import FormattedValuationStatus from "../../../../../../components/Valoraciones/FormattedValuationStatus";
import { TAuthenticatedUser } from "../../../../../../reducers/Auth";
import { formatCurrency } from "../../../../../../util/formatNumbers";
import PublicadoStatus from "../../../../components/PublicadoStatus";
import StyledContratoTipoDeTrato from "../../../../components/StyledContratoTipoDeTrato";
import { TUpdateContractImportantNotes } from "../../api/updateContractImportantNotes";
import { TUpdateContractPhase } from "../../routes/ContractsPage/hooks/useContractPhaseMutation";
import { TUpdateContractPartial } from "../../routes/ContractsPage/hooks/useOffersMutation";
import { TContractPartialWithNumbers } from "../../types/contract.types";
import {
  contractHasCvWarning,
  contractHasFaceToFaceWarning,
  contractHasPastCvClientDate,
  contractHasPastTargetDate,
} from "../../util/contract.util";
import ContractEditModal from "../ContractEditModal";
import BotInfo from "./BotInfo";
import {
  StyledActionButton,
  StyledActionIcon,
  StyledActionLink,
  StyledActions,
  StyledBottomInfoLine,
  StyledButtonIcon,
  StyledCard,
  StyledCardDemoteActions,
  StyledClickablePhoneNumber,
  StyledContractName,
  StyledContratoDetails,
  StyledContratoEstatus,
  StyledContratoFakeFV,
  StyledContratoFakeFVText,
  StyledContratoInfo,
  StyledContratoTitleDetails,
  StyledContratoTitulo,
  StyledContratoValoraciones,
  StyledCreated,
  StyledFirstRow,
  StyledFullHeightGrid,
  StyledIcon,
  StyledItem,
  StyledItemBuscamosDetail,
  StyledItemDetail,
  StyledItemMoneyDetail,
  StyledNuevoServicio,
  StyledNuevoServicioText,
  StyledPublishInfo,
  StyledReplacementContract,
  StyledReplacementContractText,
  StyledSecondRow,
  StyledServicioExistente,
  StyledThirdRow,
  StyledValoracionButton,
  StyledValoracionesButtons,
  StyledValoracionesInfo,
  StyledValoracionesInfoWrapper,
  StyledValoracionesWrapper
} from "./ContractCardSC";
import ContractDates from "./ContractDates";
import ContractDemoteActions from "./ContractDemoteActions";
import ContractLastNotification from "./ContractLastNotification";
import ContractLocation from "./ContractLocation";
import ContractOwnership from "./ContractOwnership";
import EditableTextOverflowWithPopup from "./EditableTextOverflowWithPopup";
import ExpressTag from "./ExpressTag";
import PlanTag from "./PlanTag";
import ValuationsItem from "./ValuationsItem";
import WarningIcon from "../../../../../../assets/images/iconos/yellow-warning.png";
import { CONTRACT_ORIGIN_SALE, CONTRACT_ORIGIN_WARRANTY_ZERO } from "../../../../../../util/defaults/contrato";
import { IChangeServiceOwnerRequest, apiChangeServiceOwner } from "../../../../../../api/service/apiChangeServiceOwner";
import { VALUATION_STATUS } from "../../../../../../api/valuation/valuation.types";

export interface IContractCardProps {
  contract: TContractPartialWithNumbers;
  onChange: (payload: TUpdateContractPartial) => void;
  onContractPhaseUpdate: (payload: TUpdateContractPhase) => void;
  onContractImportantNotesUpdate: (
    payload: TUpdateContractImportantNotes
  ) => void;
  currentTime?: Date;
}

const ContractCard = (props: IContractCardProps) => {
  const {
    contract,
    onChange,
    onContractPhaseUpdate,
    onContractImportantNotesUpdate,
    currentTime = new Date(),
  } = props;
  const { formatMessage } = useIntl();
  const history = useHistory();
  const authUser = useSelector(
    (state: any) => state.auth.authUser as TAuthenticatedUser
  );

  const [state, setState] = useState({
    editDialogOpen: false,
  });

  const handleEditClose = () => {
    setState((prevState) => ({
      ...prevState,
      editDialogOpen: false,
    }));
  };

  const handleEditClick = () => {
    setState((prevState) => ({
      ...prevState,
      editDialogOpen: true,
    }));
  };

  const handleObjectiveDateChange = (
    date: MaterialUiPickersDate,
    value?: string | null | undefined
  ) => {
    if (date) {
      const newData = {
        Fecha_objetivo: moment(date.toISOString()).format("YYYY-MM-DD"),
        id: contract.id,
      };
      onChange(newData);
    }
  };

  const handleCvClientTargetDateChange = (
    date: MaterialUiPickersDate,
    value?: string | null | undefined
  ) => {
    if (date) {
      const newData = {
        Fecha_cv_cliente: moment(date.toISOString()).format("YYYY-MM-DD"),
        id: contract.id,
      };
      onChange(newData);
    }
  };

  const handleDemote = () => {
    onContractPhaseUpdate({
      id: contract.id,
      contractName: contract.Name,
      phase: CONTRACT_PHASE.RECRUIT,
    });
  };

  const handleContractOwnerChange = (
    newOwnerId: string,
    newOwnerName: string
  ) => {
    const newData = {
      id: contract.id,
      Owner: newOwnerId,
      ownerName: newOwnerName,
    };
    onChange(newData);
  };

  const handleContractServiceOwnerChange = async (
    newOwnerId: string,
    newOwnerName: string
  ) => {
    if (!contract.Servicio || !newOwnerId || !newOwnerName) {
      return;
    }
    const request: IChangeServiceOwnerRequest = {
      Owner: newOwnerId,
      ownerAssignedAt: moment().toISOString(),
      ownerName: newOwnerName
    };
    await apiChangeServiceOwner(contract.Servicio?.id, request);
  };

  const handleBotClick = () => {
    // history.push(encodeURI(`/communications?contractId=${contract.id}`));
  };

  const handleChangeImportantNotes = (newText?: string) => {
    onContractImportantNotesUpdate({
      contractId: contract.id,
      Notas_importantes: newText,
    });
  };

  const faceToFaceWarning = contractHasFaceToFaceWarning(
    contract,
    authUser,
    currentTime
  );
  const cv24hWarning = contractHasCvWarning(contract, authUser);
  const hasPastTargetDate = contractHasPastTargetDate(contract, currentTime);
  const hasPastCvClientDate = contractHasPastCvClientDate(
    contract,
    currentTime
  );

  return (
    <StyledCard>
      {true === contract.Oferta_FV || true === contract.Oferta_Fake ? (
        <StyledContratoFakeFV>
          <StyledContratoFakeFVText>
            <FormattedMessage
              id="ContractCard.Oferta {tipoOferta}"
              defaultMessage="Oferta {tipoOferta}"
              values={{
                tipoOferta:
                  contract.Oferta_FV && contract.Oferta_Fake
                    ? "Cuideo / Felizvita"
                    : contract.Oferta_Fake
                    ? "Cuideo"
                    : contract.Oferta_FV
                    ? "Felizvita"
                    : "",
              }}
            />
          </StyledContratoFakeFVText>
        </StyledContratoFakeFV>
      ) : contract.Origen === CONTRACT_ORIGIN_SALE ? (
        <StyledNuevoServicio>
          <StyledNuevoServicioText>
            <FormattedMessage
              id="ContractCard.NewService"
              defaultMessage="Nuevo Servicio"
            />
          </StyledNuevoServicioText>
        </StyledNuevoServicio>
      ) : contract.Origen === CONTRACT_ORIGIN_WARRANTY_ZERO ? (
        <StyledReplacementContract>
          <StyledReplacementContractText>
            <FormattedMessage
              id="ContractCard.ReplacementContract"
              defaultMessage="Contrato sustitución"
            />
          </StyledReplacementContractText>
        </StyledReplacementContract>
      ) : (
        <StyledServicioExistente></StyledServicioExistente>
      )}
      <StyledContratoInfo>
        <StyledFirstRow>
          <StyledPublishInfo>
            <PublicadoStatus contrato={contract} />
            <StyledCreated>
              <FormattedMessage
                id="ContractCard.Created"
                defaultMessage="Contrato creado el {fechaCreated}"
                values={{
                  fechaCreated: moment(contract.createdAt).format("DD/MM/YYYY"),
                }}
              />
            </StyledCreated>
          </StyledPublishInfo>
          <StyledActions>
            <Tooltip
              title={
                <FormattedMessage
                  id="ContractCard.ZohoTooltip"
                  defaultMessage="Ver en Zoho"
                />
              }
            >
              <StyledActionButton
                color="primary"
                onClick={() => {
                  if (contract.zohoId) {
                    window.open(
                      `https://crm.zoho.com/crm/org638326163/tab/CustomModule4/${contract.zohoId}`
                    );
                  }
                }}
              >
                <StyledActionIcon src={ZohoIcon} />
              </StyledActionButton>
            </Tooltip>
            <Tooltip
              title={
                <FormattedMessage
                  id="ContractCard.Ver en ventana nueva"
                  defaultMessage="Ver en ventana nueva"
                />
              }
            >
              <StyledActionLink
                href={`/contrato/${contract.id}?estadoEnProceso=${VALUATION_STATUS.OUTSTANDING}`}
                target="_blank"
              >
                <StyledActionIcon src={OpenNewIcon} />
              </StyledActionLink>
            </Tooltip>
            <Tooltip
              title={
                <FormattedMessage
                  id="ContractCard.Editar oferta"
                  defaultMessage="Editar oferta"
                />
              }
            >
              <StyledActionButton
                color="primary"
                onClick={handleEditClick}
                aria-label={formatMessage({
                  id: "ContractCard.Editar oferta",
                  defaultMessage: "Editar oferta",
                })}
              >
                <StyledActionIcon src={EditIcon} />
              </StyledActionButton>
            </Tooltip>
            <ContractPopupViewButton contrato={contract} />
            <ContractEditModal
              contractId={contract.id}
              open={state.editDialogOpen}
              onClose={handleEditClose}
              onChange={onChange}
            />
          </StyledActions>
        </StyledFirstRow>
        <StyledSecondRow>
          <StyledContratoTitleDetails>
            {!!contract.Tipo_de_Trato && (
              <StyledContratoTipoDeTrato
                tipo={contract.Tipo_de_Trato}
                punctual={contract.Servicio_corta_duraci_n === "Si"}
              />
            )}
            <StyledContratoTitulo>{contract.T_tulo}</StyledContratoTitulo>
          </StyledContratoTitleDetails>
          <StyledContratoDetails>
            <StyledContractName>{contract.Name}</StyledContractName>
            <StyledContratoEstatus>
              <FormattedMessage
                id="ContractCard.Status"
                defaultMessage="Estado:"
              />
              &nbsp;{contract.Estado}
            </StyledContratoEstatus>
            <ContractLastNotification
              contract={contract}
              currentTime={currentTime}
            />
          </StyledContratoDetails>
        </StyledSecondRow>
        <StyledThirdRow>
          <StyledFullHeightGrid container spacing={8}>
            <StyledFullHeightGrid item xs={12} sm={6}>
              <StyledItem>
                <StyledIcon src={MoneyIcon}></StyledIcon>
                <StyledItemMoneyDetail>
                  {contract.Salario_Bruto_Mensual ? (
                    <Fragment>
                      {formatCurrency(contract.Salario_Bruto_Mensual)}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <FormattedMessage
                        id="ContractCard.NotReported"
                        defaultMessage="No indicado"
                      />
                    </Fragment>
                  )}
                  {contract.Tipo_de_salario &&
                    contract.Tipo_de_salario === "Mensual" && (
                      <FormattedMessage
                        id="ContractCard.MonthSuffix"
                        defaultMessage="/mes"
                      />
                    )}
                  {contract.Tipo_de_salario &&
                    contract.Tipo_de_salario === "Diario" && (
                      <FormattedMessage
                        id="ContractCard.DaySuffix"
                        defaultMessage="/día"
                      />
                    )}
                </StyledItemMoneyDetail>
              </StyledItem>
              <StyledItem>
                <ContractLocation contract={contract} />
              </StyledItem>
              {contract.Horario_Servicio && (
                <StyledItem>
                  <StyledIcon src={HorarioIcon}></StyledIcon>
                  <StyledItemDetail>
                    {contract.Horario_Servicio}
                  </StyledItemDetail>
                </StyledItem>
              )}
            </StyledFullHeightGrid>
            <StyledFullHeightGrid item xs={12} sm={6}>
              <StyledItem>
                <StyledIcon src={AvatarIcon}></StyledIcon>
                <StyledItemDetail>
                  {contract._Nombre_del_cliente},
                  {typeof contract._Tel_fono_cliente === "string" &&
                  isValidPhoneNumber(contract._Tel_fono_cliente) ? (
                    <StyledClickablePhoneNumber
                      href={`tel:${contract._Tel_fono_cliente}`}
                    >
                      {contract._Tel_fono_cliente}
                    </StyledClickablePhoneNumber>
                  ) : (
                    <Fragment>
                      {formatPhoneNumber(contract._Tel_fono_cliente)}
                    </Fragment>
                  )}
                </StyledItemDetail>
              </StyledItem>
              <StyledItem>
                <StyledIcon src={ListIcon}></StyledIcon>
                <StyledItemBuscamosDetail>
                  <EditableTextOverflowWithPopup
                    text={contract.Notas_importantes}
                    onTextChange={handleChangeImportantNotes}
                  />
                </StyledItemBuscamosDetail>
              </StyledItem>
              {contract.Servicio?.Zona_sin_base && (
                <StyledItem>
                  <StyledIcon src={WarningIcon}></StyledIcon>
                  <StyledItemDetail>
                    <FormattedMessage
                      id="ContractCard.Zona sin base"
                      defaultMessage="Zona sin base"
                    />
                  </StyledItemDetail>
                </StyledItem>
              )}
              <StyledCardDemoteActions>
                <ContractDemoteActions
                  canDemote={contract.phase !== "recruit"}
                  onContractDemote={handleDemote}
                  updatingAction={contract._uiMutation}
                />
              </StyledCardDemoteActions>
            </StyledFullHeightGrid>
          </StyledFullHeightGrid>
        </StyledThirdRow>
        <StyledBottomInfoLine>
          <PlanTag contract={contract} />
          <ExpressTag contract={contract} />
        </StyledBottomInfoLine>
      </StyledContratoInfo>
      <StyledContratoValoraciones>
        <StyledValoracionesInfo>
          <StyledValoracionesButtons>
            <StyledValoracionButton
              color="primary"
              variant="outlined"
              disabled={contract.numDiscarded === 0}
              onClick={() => {
                history.push(
                  encodeURI(
                    `/contrato/${contract.id}?estadoEnProceso=${VALUATION_STATUS.DISCARDED}`
                  )
                );
              }}
            >
              <StyledButtonIcon src={TrashIcon} />
              {contract.numDiscarded}
            </StyledValoracionButton>
            <ContractDates
              objectiveDate={contract.Fecha_objetivo}
              cvClientTargetDate={contract.Fecha_cv_cliente}
              onObjectiveDateChange={handleObjectiveDateChange}
              onCvClientTargetDateChange={handleCvClientTargetDateChange}
              hasPastTargetDate={hasPastTargetDate}
              hasPastCvClientDate={hasPastCvClientDate}
            />
            <BotInfo
              status={contract.bot_worker?.status}
              errorCode={contract.bot_worker?.error_code}
              errorMessage={contract.bot_worker?.error_message}
              onClick={handleBotClick}
              numCommunicationIntents={
                contract.bot_worker?.num_bot_intents ?? 0
              }
              numSuccessfulCommunicationIntents={
                contract.bot_worker?.num_bot_inscriptions ?? 0
              }
            />
          </StyledValoracionesButtons>
          <StyledValoracionesInfoWrapper>
            <ContractOwnership
              contractOwnerId={contract.ownerId}
              contractOwnerName={contract.ownerName}
              contractCrmIdName={contract.Name}
              serviceOwnerName={contract.Servicio?.ownerName}
              serviceName={contract.Servicio?.Name}
              onChangeContractOwner={handleContractOwnerChange}
              onChangeContractServiceOwner={handleContractServiceOwnerChange}
            />
          </StyledValoracionesInfoWrapper>
        </StyledValoracionesInfo>
        <StyledValoracionesWrapper>
          <ValuationsItem
            label={<FormattedValuationStatus status={VALUATION_STATUS.NOT_VALUED} />}
            number={contract.numWithoutVal}
            newItems={0}
            onClick={() => {
              history.push(
                encodeURI(
                  `/contrato/${contract.id}?estadoEnProceso=${VALUATION_STATUS.OUTSTANDING}`
                )
              );
            }}
          />
          <ValuationsItem
            label={<FormattedValuationStatus status={VALUATION_STATUS.PHONE} />}
            number={contract.numPhone}
            newItems={0}
            onClick={() => {
              history.push(
                encodeURI(
                  `/contrato/${contract.id}?estadoEnProceso=${VALUATION_STATUS.PHONE}`
                )
              );
            }}
          />
          <ValuationsItem
            label={<FormattedValuationStatus status={VALUATION_STATUS.FACE_TO_FACE} />}
            number={contract.numFaceToFace}
            variant={faceToFaceWarning ? "warning" : undefined}
            showFaceToFaceWarning={faceToFaceWarning}
            newItems={0}
            onClick={() => {
              history.push(
                encodeURI(
                  `/contrato/${contract.id}?estadoEnProceso=${VALUATION_STATUS.FACE_TO_FACE}`
                )
              );
            }}
          />
          <ValuationsItem
            label={<FormattedValuationStatus status={VALUATION_STATUS.CV_CLIENT} />}
            number={contract.numCvClient}
            variant={cv24hWarning ? "error" : undefined}
            show24hCvWarning={cv24hWarning}
            newItems={0}
            onClick={() => {
              history.push(
                encodeURI(
                  `/contrato/${contract.id}?estadoEnProceso=${VALUATION_STATUS.CV_CLIENT}`
                )
              );
            }}
          />
          <ValuationsItem
            label={
              <FormattedValuationStatus
                status={VALUATION_STATUS.FACE_TO_FACE_CLIENT}
              />
            }
            number={contract.numFaceToFaceClient}
            newItems={0}
            onClick={() => {
              history.push(
                encodeURI(
                  `/contrato/${contract.id}?estadoEnProceso=${VALUATION_STATUS.FACE_TO_FACE_CLIENT}`
                )
              );
            }}
          />
          <ValuationsItem
            label={
              <FormattedMessage
                id="ContractCard.Contracted"
                defaultMessage="Seleccionad@"
              />
            }
            number={contract.numContracted}
            newItems={0}
            onClick={() => {
              history.push(
                encodeURI(
                  `/contrato/${contract.id}?estadoEnProceso=${VALUATION_STATUS.CONTRACTED}`
                )
              );
            }}
          />
        </StyledValoracionesWrapper>
      </StyledContratoValoraciones>
    </StyledCard>
  );
};

export default ContractCard;
