import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Typography,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import moment from "moment";
import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import cuideoTheme from "../../../../containers/themes/defaultTheme";
import { OfertaType } from "../../../../types/OfertaType";
import { formatCurrency } from "../../../../util/formatNumbers";
import useWindowWidth from "../../../../util/useWindowWidth";
import LocationIcon from "../../../../assets/images/iconos/location.svg";
import ApplyOfferButton from "../ApplyOfferButton/ApplyOfferButton";
import HorarioIcon from "../../../../components/CuideoIcons/HorarioIcon.svg";
import MoneyIcon from "../../../../components/CuideoIcons/MoneyIcon.svg";
import useFormatters from "../../../../components/FormattedFields/useFormatters";

const StyledCard = styled(Card)`
  color: ${cuideoTheme.palette.primary.main};
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px 0 #a2c4d8;

  .MuiCardHeader-title {
    font-weight: 700;
  }
`;

const StyledCardContentHeadDetails = styled(CardContent)`
  color: ${cuideoTheme.palette.primary.main};
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  padding-bottom: 0.25rem;
`;

const StyledCardHeader = styled.h5`
  padding: 0 1rem 0.5rem 1rem;
  margin: 0;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-weight: 700;
`;

const StyledHeaderTitle = styled.span`
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 1.375rem;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 1.625rem;
    line-height: 2rem;
    margin-right: 0.5rem;
  }
`;

const StyledServicioPuntual = styled.span`
  color: ${cuideoTheme.palette.primary.main};
  font-weight: 400;
  background-color: rgba(235, 194, 0, 0.3);
  border: 1px solid #ebc200;
  border-radius: ${cuideoTheme.shape.borderRadius}px;
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 1rem;
    padding: 0.375rem 0.5625rem;
    display: block;
    width: fit-content;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 1rem;
    line-height: 2rem;
    padding: 0rem 0.5625rem;
    vertical-align: top;
    display: inline-block;
  }
`;

const StyledCardContent = styled(CardContent)`
  padding-top: 0;
`;

const StyledChip = styled(Chip)`
  border-radius: ${cuideoTheme.shape.borderRadius}px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  padding: 1.2rem 0.25rem;
`;

const StyledIcon = styled.img`
  max-height: 1rem;
  max-width: 1rem;
  opacity: 0.5;
  margin-right: 0.5rem;
`;

const StyledDetailBox = styled.div`
  margin-right: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media all and (min-width: 992px) {
    border-right: 1px solid ${cuideoTheme.palette.secondary.main};
  }
`;

const StyledLastDetailBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledButton = styled(Button)`
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 0.875rem;
    letter-spacing: 0.035rem;
    padding: 0.5rem 1rem;
    border: 1px solid ${cuideoTheme.palette.primary.main};
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 1.1rem;
    letter-spacing: 0.035rem;
    padding: 0.5rem 1rem;
    min-width: 11rem;
  }
`;

const StyledTypography = styled(Typography)`
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 1rem;
    line-height: 1.25;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 1.1rem;
    line-height: 1.25;
  }
`;

const StyledTasks = styled.div`
  margin: 1rem 0 0.25rem;
`;

interface Props {
  oferta: OfertaType;
}

const OfferCard = (props: Props) => {
  const { oferta } = props;
  const { formatTask } = useFormatters();
  const authUser = useSelector((state: any) => state.auth.authUser);
  const width = useWindowWidth();

  return (
    <StyledCard>
      <StyledCardContentHeadDetails>
        <Grid container spacing={2}>
          <Grid item xs={4} sm={2}>
            {moment(oferta.Fecha_publicacion_oferta).fromNow()}
          </Grid>
          <Grid item xs={8} sm={2}>
            ID: {oferta.Name}
          </Grid>
        </Grid>
      </StyledCardContentHeadDetails>

      <StyledCardHeader>
        <StyledHeaderTitle>{oferta.T_tulo}</StyledHeaderTitle>
        {oferta.Servicio_corta_duraci_n === "Si" && (
          <StyledServicioPuntual>
            <FormattedMessage
              id="OfferCard.Puntual"
              defaultMessage="Servicio Puntual"
            />
          </StyledServicioPuntual>
        )}
      </StyledCardHeader>

      <StyledCardContent>
        <Box mb={2}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              <StyledDetailBox>
                <StyledIcon src={LocationIcon} />
                <StyledTypography>{oferta.Ciudad_oferta}</StyledTypography>
              </StyledDetailBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledDetailBox>
                <StyledIcon src={HorarioIcon} />
                <StyledTypography>{oferta.Horario_Servicio}</StyledTypography>
              </StyledDetailBox>
            </Grid>
            <Grid item xs={12} md={3}>
              <StyledLastDetailBox>
                <StyledIcon src={MoneyIcon} />
                <StyledTypography color="secondary">
                  <strong>
                    <FormattedMessage
                      id="OfferCard.Salary"
                      defaultMessage="Salario:"
                    />
                    &nbsp;
                    {oferta.Salario_Bruto_Mensual ? (
                      <Fragment>
                        {formatCurrency(oferta.Salario_Bruto_Mensual)}
                      </Fragment>
                    ) : (
                      <Fragment>
                        <FormattedMessage
                          id="OfferCard.NotProvided"
                          defaultMessage="No indicado"
                        />
                      </Fragment>
                    )}
                    {oferta.Tipo_de_salario &&
                      oferta.Tipo_de_salario === "Mensual" && (
                        <FormattedMessage
                          id="OfferCard.PerMonth"
                          defaultMessage="/mes"
                        />
                      )}
                    {oferta.Tipo_de_salario &&
                      oferta.Tipo_de_salario === "Diario" && (
                        <FormattedMessage
                          id="OfferCard.PerDay"
                          defaultMessage="/día"
                        />
                      )}
                  </strong>
                </StyledTypography>
              </StyledLastDetailBox>
            </Grid>
          </Grid>
        </Box>
        {width > cuideoTheme.breakpoints.values.sm && (
          <StyledTasks>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {oferta.Tareas_solicitadas.map((item: string) => (
                  <StyledChip
                    icon={
                      authUser &&
                      authUser.candidato &&
                      authUser.candidato.Qu_tareas_quieres_o_puedes_realizar.includes(
                        item
                      ) ? (
                        <CheckIcon />
                      ) : undefined
                    }
                    label={formatTask(item)}
                    key={item}
                    variant="outlined"
                    color="secondary"
                  />
                ))}
              </Grid>
            </Grid>
          </StyledTasks>
        )}
      </StyledCardContent>
      <StyledCardContent>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12} md={6}>
            <Box mb={2}>
              <Grid
                container
                spacing={4}
                wrap="nowrap"
                justifyContent="flex-start"
              >
                {authUser && authUser.userRoles.includes("ROLE_EMPLOYEE") && (
                  <Grid item xs={12} sm>
                    <ApplyOfferButton size="medium" fullWidth offer={oferta}>
                      {width < cuideoTheme.breakpoints.values.md ? (
                        <FormattedMessage
                          id="OfferCard.Apply"
                          defaultMessage="Aplicar"
                        />
                      ) : (
                        <FormattedMessage
                          id="OfferCard.ApplyOffer"
                          defaultMessage="Aplicar a la Oferta"
                        />
                      )}
                    </ApplyOfferButton>
                  </Grid>
                )}
                {!authUser && (
                  <Grid item xs={12} sm>
                    <ApplyOfferButton size="medium" fullWidth offer={oferta}>
                      {width < cuideoTheme.breakpoints.values.md ? (
                        <FormattedMessage
                          id="OfferCard.Apply"
                          defaultMessage="Aplicar"
                        />
                      ) : (
                        <FormattedMessage
                          id="OfferCard.ApplyOffer"
                          defaultMessage="Aplicar a la Oferta"
                        />
                      )}
                    </ApplyOfferButton>
                  </Grid>
                )}
                <Grid item xs={12} sm>
                  <Link
                    to={`/empleo/oferta/${oferta.id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <StyledButton
                      color="primary"
                      variant="outlined"
                      size="medium"
                      fullWidth
                    >
                      <FormattedMessage
                        id="OfferCard.ViewOffer"
                        defaultMessage="Ver Oferta"
                      />
                    </StyledButton>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </StyledCardContent>
    </StyledCard>
  );
};

export default OfferCard;
