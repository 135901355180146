import { useCallback } from "react";
import { useIntl } from "react-intl";
import { TGetContractsCollectionItem } from "../../../../../api/contract/contract.types";
import { formatCurrency } from "../../../../../util/formatNumbers";

export const useContractSalaryFormatter = () => {
  const { formatMessage } = useIntl();

  const formatSalary = useCallback(
    (
      grossSalary: TGetContractsCollectionItem["Salario_Bruto_Mensual"],
      salaryType: TGetContractsCollectionItem["Tipo_de_salario"]
    ) => {
      if (!grossSalary) {
        return "-";
      }

      return formatMessage(
        {
          id: "SalaryFormatter.{grossSalary}/{type}",
          defaultMessage: "{grossSalary}/{type}",
        },
        {
          grossSalary: formatCurrency(grossSalary),
          type:
            salaryType === "Diario"
              ? formatMessage({
                  id: "SalaryFormatter.día",
                  defaultMessage: "día",
                })
              : formatMessage({
                  id: "SalaryFormatter.mes",
                  defaultMessage: "mes",
                }),
        }
      );
    },
    [formatMessage]
  );

  return formatSalary;
};
