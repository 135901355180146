import moment from "moment";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { TGetEmployeeItemUserFavorite } from "../../../../../api/employee/employee.types";

export const useFavoriteFormatter = () => {
  const { formatMessage } = useIntl();

  const formatFavoriteTooltipContent = useCallback(
    (favorite?: TGetEmployeeItemUserFavorite) => {
      const favoriteContent = !!favorite ? (
        !!favorite.note ? (
          <>
            {formatMessage(
              {
                id: "FavButton.Favorito añadido el {favDate}",
                defaultMessage: "Favorito añadido el {favDate}",
              },
              { favDate: moment(favorite.createdAt).format("DD/MM/YYYY") }
            )}
            <br />
            {favorite.note}
          </>
        ) : (
          formatMessage({
            id: "FavButton.Quitar de favoritos",
            defaultMessage: "Quitar de favoritos",
          })
        )
      ) : (
        formatMessage({
          id: "FavButton.Añadir a favoritos",
          defaultMessage: "Añadir a favoritos",
        })
      );

      return favoriteContent;
    },
    [formatMessage]
  );

  return {
    formatFavoriteTooltipContent,
  };
};
