import { Grid, IconButton, Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { connect } from "react-redux";
import styled from "styled-components";
import { userSignIn } from "../../actions";
import { userSignInResetStatus } from "../../actions/Auth";
import { setBackground } from "../../actions/Layout";
import ZohoIcon from "../../assets/images/iconos/zoho.png";
import CuideoCheckbox from "../../components/CuideoFields/CuideoCheckbox";
import cuideoTheme from "../themes/defaultTheme";
import useFooter from "../../util/useFooter";
import useHeader from "../../util/useHeader";
import { FormattedMessage, useIntl } from "react-intl";
import usePageTitle from "../../util/usePageTitle";
import AffinitySignInBackground from "./AffinitySignInBackground";

const StyledPageContainer = styled.div<{ appMarginTop: number }>`
  ${({ appMarginTop }) => {
    return `
    margin: ${appMarginTop}rem auto 0;
  `;
  }}

  @media all and (max-width: ${cuideoTheme.breakpoints.values.sm}px) {
    padding: 0;
    background-color: ${cuideoTheme.palette.common.white};
    margin: ${({ appMarginTop }) => appMarginTop}rem 0 0;
  }

  @media all and (min-width: ${cuideoTheme.breakpoints.values
      .md}px) and (max-width: ${cuideoTheme.breakpoints.values.lg}px) {
    margin: ${({ appMarginTop }) => appMarginTop + 3}rem 1rem 1rem;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.lg}px) {
    margin: ${({ appMarginTop }) => appMarginTop + 3}rem 0 1rem;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  min-height: calc(100vh - 10rem);
  width: 100%;

  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    min-height: 38rem;
  }
`;

const LoginBoxesWrapper = styled.div`
  @media all and (max-width: ${cuideoTheme.breakpoints.values.sm}px) {
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values
      .sm}px) and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    max-width: 30rem;
    margin: 2rem auto;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`;

const StyledBoxTitle = styled.h2`
  font-family: "Lora", Georgia, serif;
  color: #004b7b;
  font-weight: 700;
  margin: 0;

  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 1.25rem;
    line-height: 1.35;
    text-align: center;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 1.5rem;
    line-height: 1.4;
  }
`;

const StyledRememberWrapper = styled.div`
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    margin: 0 auto;
    width: fit-content;
  }
`;

const existingBoxVariants = {
  shown: {
    opacity: 1,
    x: 0,
    scale: 1,
    transition: { duration: 0.25, delay: 0.25 },
  },
  hidden: {
    opacity: 0,
    x: 0,
    scale: 1.15,
    transition: { duration: 0.1 },
  },
};

const LoginExistingUserBox = styled(motion.div)`
  background-color: #fff;
  z-index: 1;
  padding: 2rem;
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    width: 22rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    padding: 2.8rem 2.375rem;
  }
`;

const StyledRememberLabel = styled.div`
  padding: 0.75rem 0;
`;

const StyledBoxesContent = styled.div`
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    min-height: 10rem;
    padding: 1rem 0 0;
  }
`;

const StyledTextField = styled(TextField)`
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    box-shadow: none;
  }
`;

const StyledErrorBox = styled.div`
  color: ${cuideoTheme.palette.error.main};
  background-color: ${(cuideoTheme.palette.error as any)[100]};
  padding: 1rem;
  margin-top: 1rem;
  border: 1px solid ${cuideoTheme.palette.error.main};
  border-radius: ${cuideoTheme.shape.borderRadius}px;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
`;

const StyledZohoLink = styled.a``;

const StyledZohoButton = styled(IconButton)``;
const StyledZohoIcon = styled.img``;

const EmpleoSignIn = ({
  authUser,
  history,
  loggingStatus,
  loggingMessage,
  userSignIn,
  setBackground,
  // changeHeaderType,
  userSignInResetStatus,
  appMarginTop,
}: {
  authUser: any;
  history: any;
  loggingStatus: string | null;
  loggingMessage: string | null;
  userSignIn: Function;
  setBackground: Function;
  // changeHeaderType: Function;
  userSignInResetStatus: Function;
  appMarginTop: any;
}) => {
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
    rememberMe: true,
  });
  const [formError, setFormError] = useState("");
  const intl = useIntl();

  useFooter({
    type: "hidden",
  });
  useHeader({
    type: "hidden",
  });
  usePageTitle(
    intl.formatMessage({
      id: "AffinitySignIn.Access",
      defaultMessage: "Acceso",
    })
  );

  useEffect(() => {
    setBackground("cuideo");
    userSignInResetStatus();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (authUser !== null && authUser !== undefined) {
      // Redirect from this page to another one
      history.push("/contratos");
    }
  }, [history, authUser]);

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (
      credentials.username.length === 0 ||
      credentials.password.length === 0
    ) {
      setFormError("Debes introducir unas credenciales válidas");
    } else {
      setFormError("");
      userSignIn({
        username: credentials.username,
        password: credentials.password,
        rememberMe: credentials.rememberMe,
      });
    }
  };

  const hasError = !!formError || loggingStatus === "loggingError";
  const errorMessage = formError || loggingMessage;

  return (
    <StyledPageContainer appMarginTop={appMarginTop}>
      <AffinitySignInBackground />
      <LoginContainer>
        <LoginBoxesWrapper>
          <LoginExistingUserBox
            initial="hidden"
            animate="shown"
            variants={existingBoxVariants}
          >
            <StyledBoxTitle>
              <FormattedMessage
                id="AffinitySignIn.Affinity"
                defaultMessage="Affinity"
              />
            </StyledBoxTitle>
            {hasError && <StyledErrorBox>{errorMessage}</StyledErrorBox>}
            <Grid container spacing={4}>
              <Grid item xs={12} md={12}>
                <form onSubmit={handleFormSubmit}>
                  <StyledBoxesContent>
                    <StyledTextField
                      label={
                        <FormattedMessage
                          id="AffinitySignIn.Email"
                          defaultMessage="Email"
                        />
                      }
                      fullWidth
                      onChange={(event) =>
                        setCredentials({
                          ...credentials,
                          username: event.target.value,
                        })
                      }
                      error={hasError}
                      value={credentials.username}
                      margin="normal"
                      variant="outlined"
                    />
                    <StyledTextField
                      type="password"
                      label={
                        <FormattedMessage
                          id="AffinitySignIn.Password"
                          defaultMessage="Contraseña"
                        />
                      }
                      fullWidth
                      error={hasError}
                      onChange={(event) =>
                        setCredentials({
                          ...credentials,
                          password: event.target.value,
                        })
                      }
                      value={credentials.password}
                      margin="normal"
                      variant="outlined"
                    />
                  </StyledBoxesContent>

                  <Grid
                    container
                    spacing={4}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <StyledRememberWrapper>
                        <CuideoCheckbox
                          label={
                            <StyledRememberLabel>
                              <FormattedMessage
                                id="AffinitySignIn.RememberMe"
                                defaultMessage="Recuérdame"
                              />
                            </StyledRememberLabel>
                          }
                          checked={credentials.rememberMe}
                          onChange={() => {
                            setCredentials({
                              ...credentials,
                              rememberMe: !credentials.rememberMe,
                            });
                          }}
                        />
                      </StyledRememberWrapper>
                    </Grid>
                    <Grid item>
                      <StyledZohoLink
                        onClick={(e: any) => {
                          e.preventDefault();
                          window.open(
                            `${process.env.REACT_APP_FILES_URL}/auth/zoho_login`,
                            "Access",
                            "height=640,width=520"
                          );
                        }}
                        href={"/affinitySignIn?autoZohoLogin=1"}
                      >
                        <Tooltip
                          title={
                            <FormattedMessage
                              id="AffinitySignIn.zoho"
                              defaultMessage="Acceder vía Zoho CRM"
                            />
                          }
                        >
                          <StyledZohoButton color="primary">
                            <StyledZohoIcon src={ZohoIcon} />
                          </StyledZohoButton>
                        </Tooltip>
                      </StyledZohoLink>
                    </Grid>
                  </Grid>

                  <Grid container spacing={4}>
                    <Grid item xs={12} md={12}>
                      <Button
                        type="submit"
                        color="primary"
                        size="large"
                        disabled={loggingStatus === "loggingIn"}
                        variant="contained"
                        fullWidth
                      >
                        {loggingStatus === "loggingIn" ? (
                          <FormattedMessage
                            id="AffinitySignIn.LoggingIn"
                            defaultMessage="Iniciando sesión"
                          />
                        ) : (
                          <FormattedMessage
                            id="AffinitySignIn.LogIn"
                            defaultMessage="Iniciar sesión"
                          />
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </LoginExistingUserBox>
        </LoginBoxesWrapper>
      </LoginContainer>
    </StyledPageContainer>
  );
};

const mapStateToProps = ({
  auth,
  layout,
}: {
  auth: {
    authUser: any;
    loggingStatus: string | null;
    loggingMessage: string | null;
  };
  layout: any;
}) => {
  const { authUser, loggingStatus, loggingMessage } = auth;
  const { appMarginTop } = layout;
  return { authUser, loggingStatus, loggingMessage, appMarginTop };
};

export default connect(mapStateToProps, {
  userSignIn,
  setBackground,
  // changeHeaderType,
  userSignInResetStatus,
})(EmpleoSignIn);
