import { Box, Chip, Tooltip } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import LocationIcon from "../../../../../../assets/images/iconos/location.svg";
import { TContractPartialWithNumbers } from "../../types/contract.types";
import {
  StyledIcon,
  StyledItemDetail,
  StyledLocationAlert,
} from "./ContractCardSC";

const StyledChip = styled(Chip)`
  font-size: 0.75rem;
  height: 1.25rem;

  &.CMuiContractLocationOptimal {
    background-color: rgb(237, 247, 237);
    color: rgb(30, 70, 32);
  }
  &.CMuiContractLocationLess1 {
    background-color: rgb(237, 247, 237);
    color: rgb(30, 70, 32);
  }
  &.CMuiContractLocationLess2 {
    background-color: rgb(255, 244, 229);
    color: rgb(102, 60, 0);
  }
  &.CMuiContractLocationLess5 {
    background-color: rgb(253, 236, 234);
    color: rgb(97, 26, 21);
  }
  &.CMuiContractLocationWorst {
    background-color: rgb(253, 236, 234);
    color: rgb(97, 26, 21);
  }

  .MuiSvgIcon-root {
    width: 1rem;
    height: 1rem;
  }
`;

interface IContractLocationProps {
  contract: TContractPartialWithNumbers;
  disableTag?: boolean;
}

const ContractLocation = (props: IContractLocationProps) => {
  const { contract, disableTag = false } = props;

  const LocationTag =
    contract.location_error_margin < 0.25 ? (
      <Tooltip
        title={
          <FormattedMessage
            id="ContractLocation.Optimal.Tooltip"
            defaultMessage="El contrato está ubicado con mucha precisión (margen de error de 0.1Km). Esto hace que se pueda evaluar perfectamente la distancia a los candidatos"
          />
        }
      >
        <StyledChip
          icon={<DoneIcon />}
          className="CMuiContractLocationOptimal"
          label={
            <FormattedMessage
              id="ContractLocation.Optimal"
              defaultMessage="Geolocalización óptima"
            />
          }
        />
      </Tooltip>
    ) : contract.location_error_margin < 1 ? (
      <Tooltip
        title={
          <FormattedMessage
            id="ContractLocation.Less1.Tooltip"
            defaultMessage="El contrato está bien ubicado (menos de 1Km de error). Esto hace que se puedan obtener sugerencias evaluando bien la distancia"
          />
        }
      >
        <StyledChip
          icon={<DoneIcon />}
          className="CMuiContractLocationLess1"
          label={
            <FormattedMessage
              id="ContractLocation.Less1"
              defaultMessage="Buena geolocalización"
            />
          }
        />
      </Tooltip>
    ) : contract.location_error_margin < 2 ? (
      <Tooltip
        title={
          <FormattedMessage
            id="ContractLocation.Less2.Tooltip"
            defaultMessage="El contrato no está bien ubicado (margen de 2 Km). Esto hace que sea dificil valorar la distancia a los diferentes candidatos"
          />
        }
      >
        <StyledChip
          icon={<ReportProblemOutlinedIcon />}
          className="CMuiContractLocationLess2"
          label={
            <FormattedMessage
              id="ContractLocation.Less2"
              defaultMessage="Geolocalización mejorable"
            />
          }
        />
      </Tooltip>
    ) : contract.location_error_margin < 5 ? (
      <Tooltip
        title={
          <FormattedMessage
            id="ContractLocation.Less5.Tooltip"
            defaultMessage="El contrato está bastante mal ubicado (margen de 5 Km). De esta forma no es posible evaluar correctamente la distancia a los Candidatos"
          />
        }
      >
        <StyledChip
          icon={<ReportProblemOutlinedIcon />}
          className="CMuiContractLocationLess5"
          label={
            <FormattedMessage
              id="ContractLocation.Less5"
              defaultMessage="Mala geolocalización"
            />
          }
        />
      </Tooltip>
    ) : (
      <Tooltip
        title={
          <FormattedMessage
            id="ContractLocation.Worst.Tooltip"
            defaultMessage="El contrato está muy mal ubicado (más de 5 Km de error). No es posible evaluar la distancia a los Candidatos"
          />
        }
      >
        <StyledChip
          icon={<ErrorOutlineOutlinedIcon />}
          className="CMuiContractLocationWorst"
          label={
            <FormattedMessage
              id="ContractLocation.Worst"
              defaultMessage="Muy mala geolocalización"
            />
          }
        />
      </Tooltip>
    );

  return (
    <Fragment>
      {!contract.Ciudad_oferta && !contract.Provincia_oferta ? (
        <StyledLocationAlert severity="warning">
          <FormattedMessage
            id="ContractCard.NoLocationWarning"
            defaultMessage="Sin información de ubicación"
          />
        </StyledLocationAlert>
      ) : !contract.Ciudad_oferta ? (
        <Fragment>
          <StyledIcon src={LocationIcon}></StyledIcon>
          <Box>
            <StyledItemDetail>({contract.Provincia_oferta})</StyledItemDetail>
            {!disableTag && LocationTag}
          </Box>
        </Fragment>
      ) : !contract.Provincia_oferta ? (
        <Fragment>
          <StyledIcon src={LocationIcon}></StyledIcon>
          <Box>
            <StyledItemDetail>{contract.Ciudad_oferta}</StyledItemDetail>
            {!disableTag && LocationTag}
          </Box>
        </Fragment>
      ) : (
        <Fragment>
          <StyledIcon src={LocationIcon}></StyledIcon>
          <Box>
            <StyledItemDetail>
              {contract.Ciudad_oferta} ({contract.Provincia_oferta})
            </StyledItemDetail>
            {!disableTag && LocationTag}
          </Box>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ContractLocation;
