import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";
import { FaRobot } from "react-icons/fa";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import cuideoTheme from "../../../../containers/themes/defaultTheme";
import ContractErrors from "./ContractErrors";
import {
  communicationsButtonProgress,
  CreateCommunicationIntentsState,
} from "./CreateCommunicationIntentsButton.types";
import EmployeesAvailabilityTable from "./EmployeesAvailabilityTable/EmployeesAvailabilityTable";
import useCreateCommunicationIntentsState from "./hooks/useCreateCommunicationIntentsState";
import MessagesPng from "../../../../assets/images/messages.png";
import StatusAlert from "./StatusAlert";

const StyledBotIconButton = styled(IconButton)`
  padding: 0.33rem;
  border: 1px solid #c2d1da;
  .CMuiIcon {
    color: ${cuideoTheme.palette.primary.light};
  }
  &:disabled {
    .MuiSvgIcon-root {
      color: #aaa;
    }
    .CMuiIcon {
      color: #aaa;
    }
  }
  &:hover {
    .MuiSvgIcon-root {
      color: ${cuideoTheme.palette.primary.main};
    }
    .CMuiIcon {
      color: ${cuideoTheme.palette.primary.main};
    }
  }
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 48rem;
    max-width: 75vw;
    position: relative;
  }
`;

const StyledLoadingWrapper = styled.div`
  min-height: 12rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .MuiCircularProgress-root {
    opacity: 0.5;
  }
`;

const StyledTop = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledLeft = styled.div`
  width: 8rem;
  margin-right: 1rem;
`;

const StyledRight = styled.div`
  flex: 1 1 auto;
`;

const StyledImage = styled.img`
  display: block;
  width: 100%;
  height: auto;
`;

const StyledExplanationText = styled(Typography)`
  margin: 0 0 1rem 0;
  font-size: 0.9375rem;
  line-height: 1.35;
  color: #555;
  text-align: center;
`;

const StyledToDoText = styled(Typography)`
  margin: 0 0 1rem 0;
  font-size: 0.9375rem;
  line-height: 1.35;
  color: #555;
  text-align: center;
`;

const StyledButton = styled(Button)`
  .MuiCircularProgress-root {
    color: #999;
  }
`;

const StyledProgressBar = styled(LinearProgress)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

interface ICreateCommunicationIntentsButtonProps {
  contractId: string;
  employeeIds: string[];
  onCommunicationIntentsCreated?: () => void;
}

const CreateCommunicationIntentsButton: React.FC<ICreateCommunicationIntentsButtonProps> =
  ({ contractId, employeeIds, onCommunicationIntentsCreated }) => {
    const { formatMessage } = useIntl();
    const { state, actions } = useCreateCommunicationIntentsState(
      contractId,
      employeeIds
    );

    const handleSend = async () => {
      await actions.send();
      onCommunicationIntentsCreated?.();
    };

    return (
      <>
        <Tooltip
          title={formatMessage({
            id: "CreateCommunicationIntentsButton.Crear Flujo",
            defaultMessage: "Crear flujo",
          })}
        >
          <StyledBotIconButton
            aria-label={formatMessage({
              id: "CreateCommunicationIntentsButton.Crear Flujo",
              defaultMessage: "Crear flujo",
            })}
            onClick={actions.openDialog}
            data-testid="create-intent-button"
          >
            <FaRobot className="CMuiIcon" />
          </StyledBotIconButton>
        </Tooltip>
        <StyledDialog open={state.dialogOpen} onClose={actions.closeDialog}>
          {(state.state === CreateCommunicationIntentsState.PROCESSING ||
            state.state === CreateCommunicationIntentsState.DONE) && (
            <StyledProgressBar
              variant="determinate"
              value={communicationsButtonProgress(state)}
            />
          )}
          <DialogTitle>
            {employeeIds.length === 1 ? (
              <FormattedMessage
                id="CreateCommunicationIntentsButton.Crear flujo"
                defaultMessage="Crear flujo"
              />
            ) : (
              <FormattedMessage
                id="CreateCommunicationIntentsButton.Crear flujos"
                defaultMessage="Crear flujos"
              />
            )}
          </DialogTitle>
          <DialogContent>
            <StyledTop>
              <StyledLeft>
                <StyledImage src={MessagesPng} />
              </StyledLeft>
              <StyledRight>
                {state.state === CreateCommunicationIntentsState.LOADING && (
                  <StyledLoadingWrapper>
                    <CircularProgress size={32} thickness={5} disableShrink />
                  </StyledLoadingWrapper>
                )}
                {state.state ===
                  CreateCommunicationIntentsState.CONTRACT_ERROR && (
                  <ContractErrors errors={state.contract.validationErrors} />
                )}
                {(state.state === CreateCommunicationIntentsState.IDLE ||
                  state.state === CreateCommunicationIntentsState.PROCESSING ||
                  state.state === CreateCommunicationIntentsState.DONE) && (
                  <>
                    <StyledExplanationText>
                      {state.employees.length === 1 ? (
                        <FormattedMessage
                          id="CreateCommunicationIntentsButton.Vas a lanzar un intento de comunicación ofreciendo al siguiente Candidato que se inscriban a {contractName} vía Whatsapp."
                          defaultMessage="Vas a lanzar un intento de comunicación ofreciendo al siguiente Candidato que se inscriban a {contractName} vía Whatsapp."
                          values={{
                            contractName: state.contract.Name,
                          }}
                        />
                      ) : (
                        <FormattedMessage
                          id="CreateCommunicationIntentsButton.Vas a lanzar un intento de comunicación ofreciendo a los siguientes Candidatos que se inscriban al {contractName} vía Whatsapp."
                          defaultMessage="Vas a lanzar un intento de comunicación ofreciendo a los siguientes Candidatos que se inscriban al {contractName} vía Whatsapp."
                          values={{
                            contractName: state.contract.Name,
                          }}
                        />
                      )}
                    </StyledExplanationText>
                    <StyledToDoText>
                      <FormattedMessage
                        id="CreateCommunicationIntentsButton.De los {numSelected} candidato/as seleccionado/as, se enviarán {numToBeSent} intentos de comunicación"
                        defaultMessage="De los {numSelected} candidato/as seleccionado/as, se enviarán {numToBeSent} intentos de comunicación"
                        values={{
                          numSelected: state.employees.length,
                          numToBeSent: state.numToBeSent,
                        }}
                      />
                    </StyledToDoText>
                    {(state.state === CreateCommunicationIntentsState.DONE ||
                      state.state ===
                        CreateCommunicationIntentsState.PROCESSING) && (
                      <StatusAlert
                        numSuccessfulSents={state.numSuccessfulSents}
                        numToBeSent={state.numToBeSent}
                        inProgress={
                          state.state ===
                          CreateCommunicationIntentsState.PROCESSING
                        }
                      />
                    )}
                  </>
                )}
              </StyledRight>
            </StyledTop>
            {(state.state === CreateCommunicationIntentsState.IDLE ||
              state.state === CreateCommunicationIntentsState.PROCESSING ||
              state.state === CreateCommunicationIntentsState.DONE) && (
              <EmployeesAvailabilityTable employees={state.employees} />
            )}
          </DialogContent>
          <DialogActions>
            {(state.state === CreateCommunicationIntentsState.CONTRACT_ERROR ||
              state.state === CreateCommunicationIntentsState.DONE) && (
              <>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={actions.closeDialog}
                >
                  <FormattedMessage
                    id="CreateCommunicationIntentsButton.Entendido"
                    defaultMessage="Entendido"
                  />
                </Button>
              </>
            )}
            {(state.state === CreateCommunicationIntentsState.IDLE ||
              state.state === CreateCommunicationIntentsState.PROCESSING) && (
              <>
                <Button
                  color="primary"
                  onClick={actions.closeDialog}
                  disabled={
                    state.state === CreateCommunicationIntentsState.PROCESSING
                  }
                >
                  <FormattedMessage
                    id="CreateCommunicationIntentsButton.Cancelar"
                    defaultMessage="Cancelar"
                  />
                </Button>
                <StyledButton
                  color="primary"
                  variant="contained"
                  onClick={handleSend}
                  disabled={
                    state.state ===
                      CreateCommunicationIntentsState.PROCESSING ||
                    state.numToBeSent === 0
                  }
                  startIcon={
                    state.state ===
                    CreateCommunicationIntentsState.PROCESSING ? (
                      <CircularProgress size={18} thickness={5} disableShrink />
                    ) : undefined
                  }
                >
                  <FormattedMessage
                    id="CreateCommunicationIntentsButton.Enviar"
                    defaultMessage="Enviar"
                  />
                </StyledButton>
              </>
            )}
          </DialogActions>
        </StyledDialog>
      </>
    );
  };

export default CreateCommunicationIntentsButton;
