import React from "react";
import styled from "styled-components";

const StyledPage = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: row;
`;

const StyledLeft = styled.div`
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  background-color: rgb(250, 251, 252);
  height: 100%;
  width: 26rem;
  min-width: 26rem;
`;

const StyledRight = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-height: 100%;
  overflow: hidden;
`;

const StyledLeftTop = styled.div`
  background-color: rgb(245, 245, 245);
  padding: 0.5rem 1rem;
  height: 4rem;
  min-height: 4rem;
  border-bottom: 2px solid rgb(235, 235, 235);
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledRightTop = styled.div`
  background-color: rgb(245, 245, 245);
  padding: 0.5rem 1rem;
  height: 4rem;
  min-height: 4rem;
  border-bottom: 2px solid rgb(235, 235, 235);
`;

const StyledRightBottom = styled.div`
  background-color: #e5ddd5;
  flex: 1 1 0;
  height: calc(100% - 5.25rem);
`;

const StyledLeftBottom = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 5.875rem);
`;

const StyledLeftBottomTop = styled.div`
  flex: 1 1 0;
  overflow: hidden;
`;

interface IConversationsRouteLayoutProps {
  leftAside: React.ReactNode;
  main: React.ReactNode;
}

const ConversationsRouteLayout: React.VFC<IConversationsRouteLayoutProps> = (
  props
) => {
  const { leftAside, main } = props;

  return (
    <StyledPage>
      <StyledLeft>
        <StyledLeftTop></StyledLeftTop>
        <StyledLeftBottom>
          <StyledLeftBottomTop>{leftAside}</StyledLeftBottomTop>
        </StyledLeftBottom>
      </StyledLeft>
      <StyledRight>
        <StyledRightTop></StyledRightTop>
        <StyledRightBottom>{main}</StyledRightBottom>
      </StyledRight>
    </StyledPage>
  );
};

export default ConversationsRouteLayout;
