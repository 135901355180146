import { useCallback } from "react";
import { useHistory } from "react-router";
import { TGetNotificationsCollectionItem } from "../../../../../api/notification/notification.types";
import {
  ROUTE_ACTIVE_CONTRACT,
  ROUTE_CANDIDATES,
  ROUTE_CONTRACT,
} from "../../../../../constants/routes";
import { CONTRATO_ESTADO_ACTIVO } from "../../../../../util/defaults/contrato";
import { useUpdateNotification } from "../api/updateNotification";

export const useNotificationClick = () => {
  const history = useHistory();
  const updateNotificationMutation = useUpdateNotification();

  const notificationClick = useCallback(
    (notification: TGetNotificationsCollectionItem) => {
      if (!notification.read) {
        updateNotificationMutation.mutate({
          notificationId: notification.id,
          read: true,
        });
      }

      if (notification.type === "unread_contract_message") {
        if (notification.details.contractId) {
          history.push(
            ROUTE_CONTRACT(notification.details.contractId) + "?expandNotes=1"
          );
        }
      }

      if (notification.type === "conversation_message") {
        let params = "?";

        // For active contrats we use Active Contract page
        if (notification.contract?.Estado === CONTRATO_ESTADO_ACTIVO) {
          params += `eTab=6`;
          history.push(
            ROUTE_ACTIVE_CONTRACT(notification.contract.id) + params
          );
        } else if (!!notification.valuation) {
          if (notification.valuation.Estado_en_proceso) {
            params += `estadoEnProceso=${notification.valuation.Estado_en_proceso}&`;
          }
          if (notification.valuation.id) {
            params += `selectedValuationId=${notification.valuation.id}&eTab=6`;
          }
          params = params.replace(/&$/, "");
          if (notification.contract && notification.contract.id) {
            history.push(ROUTE_CONTRACT(notification.contract.id) + params);
          }
        } else if (!!notification.candidate) {
          if (notification.candidate.id) {
            params += `selectedEmployeeId=${notification.candidate.id}&eTab=6`;
            history.push(ROUTE_CANDIDATES + params);
          }
        }
      }
    },
    [history, updateNotificationMutation]
  );

  return notificationClick;
};
