import { useIntl } from "react-intl";
import * as Yup from "yup";
import { validateIBANorEmpty } from "../../../../../../util/validators/yupValidators";
import moment from "moment";

export default function useCoordinatorFileESValidationSchema() {
  const { formatMessage } = useIntl();

  const validationSchema = Yup.object({
    Nombre: Yup.string().required(
      formatMessage({
        id: "CoordinatorFile.RequiredName",
        defaultMessage: "Debes introducir un nombre",
      })
    ),
    Apellidos: Yup.string().required(
      formatMessage({
        id: "CoordinatorFile.RequiredSurname",
        defaultMessage: "Debes introducir el primer apellido",
      })
    ),
    Fecha_de_nacimiento: Yup.mixed().test(
      "valid_date",
      formatMessage({
        id: "CoordinatorFile.La fecha de nacimiento ha de ser válida",
        defaultMessage: "La fecha de nacimiento ha de ser válida",
      }),
      (value) => {
        const momDate = moment(value);
        if (momDate.isValid()) {
          return true;
        }
        return false;
      }
    ),
    N_CUENTA: Yup.string().test(
      "n_cta",
      formatMessage({
        id: "CoordinatorFile.ValidIban",
        defaultMessage: "Número de cuenta no válida",
      }),
      validateIBANorEmpty
    ),
    curriculum: Yup.mixed()
      .test(
        "fileSize",
        formatMessage({
          id: "CoordinatorFile.FileTooBig",
          defaultMessage: "El archivo es demasiado grande",
        }),
        (value) => {
          if (!value) return true;
          if (!value.size) return true;
          if (value.size < 2000000) return true; // 2MB limit
          return false;
        }
      )
      .test(
        "fileType",
        formatMessage({
          id: "CoordinatorFile.CVFileInvalidType",
          defaultMessage: "El archivo debe ser de tipo JPG, PNG o PDF",
        }),
        (value) => {
          if (!value) return true;
          if (!value.type) return true;
          if (
            ["image/png", "image/jpeg", "application/pdf"].includes(value.type)
          )
            return true;
          return false;
        }
      ),
  });

  return validationSchema;
}
