import { Button, CircularProgress } from "@material-ui/core";
import ReplayIcon from "@material-ui/icons/Replay";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { TAuthenticatedUser } from "../../../../../../reducers/Auth";
import { userIsPromoter } from "../../../../models/Staff/Staff";
import { TContractPartialDemoteMutation } from "../../types/contract.types";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledDemoteButton = styled(Button)`
  margin-left: 0.25rem;
  font-size: 0.75rem;
  line-height: 1.05;
  text-transform: none;
  width: 7rem;
  height: 2rem;

  .MuiButton-startIcon {
    margin-right: 0.125rem;
  }

  .MuiCircularProgress-root {
    color: #999;
  }
`;

interface IContractDemoteActionsProps {
  updatingAction: TContractPartialDemoteMutation;
  canDemote: boolean;
  onContractDemote: () => void;
}

const ContractDemoteActions: React.FC<IContractDemoteActionsProps> = (
  props
) => {
  const { updatingAction, canDemote, onContractDemote } = props;

  const authUser = useSelector(
    (state: any) => state.auth.authUser as TAuthenticatedUser
  );

  if (!userIsPromoter(authUser)) {
    return null;
  }

  return (
    <StyledWrapper>
      {canDemote && (
        <StyledDemoteButton
          size="small"
          variant="contained"
          color="primary"
          onClick={onContractDemote}
          disabled={!!updatingAction}
          startIcon={!updatingAction && <ReplayIcon />}
        >
          {updatingAction === "to_recruit" ? (
            <CircularProgress size={18} thickness={5} disableShrink />
          ) : (
            <FormattedMessage
              id="ContractHeader.Asignar a Recruiter"
              defaultMessage="Asignar a Recruiter"
            />
          )}
        </StyledDemoteButton>
      )}
    </StyledWrapper>
  );
};

export default ContractDemoteActions;
