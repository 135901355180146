import React, { useEffect } from "react";
import { useIntersection } from "react-use";

interface IIntersectionPlaceholderProps {
  onVisibleOnScreen: () => void;
}

const IntersectionPlaceholder: React.FC<IIntersectionPlaceholderProps> = (
  props
) => {
  const { onVisibleOnScreen } = props;

  const intersectionRef = React.useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: "1px",
    threshold: 1,
  });

  useEffect(() => {
    if (intersection && intersection.intersectionRatio === 1) {
      onVisibleOnScreen();
    }
  }, [intersection, onVisibleOnScreen]);

  return <div ref={intersectionRef}></div>;
};

export default IntersectionPlaceholder;
