import { useMutation } from "react-query";
import {
  apiUpdateUserRole,
  TApiUpdateUserRolesRequest,
} from "../../../../../api/user/apiUpdateUserRole";
import { MutationConfig, queryClient } from "../../../../../lib/react-query";
import { userKeys } from "../util/user.query-keys";

type TUpdateUserRolePayload = TApiUpdateUserRolesRequest & {
  userId: string;
};

export const updateUserRole = ({
  userId,
  ...payload
}: TUpdateUserRolePayload) => {
  return apiUpdateUserRole(userId, payload);
};

type TUseUpdateUserRole = {
  config?: MutationConfig<typeof updateUserRole>;
};

export const useUpdateUserRole = ({ config }: TUseUpdateUserRole = {}) => {
  return useMutation({
    onMutate: async (payload: TUpdateUserRolePayload) => {
      await queryClient.cancelQueries(userKeys.all);
    },
    onSettled: () => {
      queryClient.invalidateQueries(userKeys.all);
    },
    ...config,
    mutationFn: updateUserRole,
  });
};
