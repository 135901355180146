import React from "react";
import { CONTRACT_STATE } from "../../../../../../api/contract/contract.types";
import ActiveContractCard from "./ActiveContractCard";
import ContractCard, { IContractCardProps } from "./ContractCard";

const ContractCardContainer: React.FC<IContractCardProps> = (props) => {
  if (props.contract.Estado === CONTRACT_STATE.ACTIVE) {
    return <ActiveContractCard {...props} />;
  }
  return <ContractCard {...props} />;
};

export default ContractCardContainer;
