import { connect, getIn } from "formik";
import * as React from "react";
import CuideoMultiSelect from "../CuideoFields/CuideoMultiSelect";
import { StyledError, StyledField, StyledLabel } from "./StyledComponents";

interface Props {
  name: string;
  required?: boolean;
  variant?: "standard" | "outlined" | "filled" | "compact";
  size?: "medium" | "small";
  margin?: "nomargin" | "normal";
  label?: any;
  labelSuffix?: any;
  disabled?: boolean;
  closeMenuOnSelect?: boolean;
  placeholder?: any;
  forceComponentUpdate?: boolean;
  onChange?: any;
  options?: any;
  isMulti?: boolean;
  isClearable?: boolean;
  fullWidth?: boolean;
  useBodyPortal?: boolean;
  isSearchable?: boolean;
}

interface State {}

class FormikSelect extends React.Component<any, State> {
  shouldComponentUpdate(nextProps: any, nextState: any) {
    if (nextProps.forceComponentUpdate) {
      return true;
    }
    const next = nextProps.formik;
    const cur = this.props.formik;
    const name = this.props.name;

    if (this.props.options !== nextProps.options) {
      return true;
    }

    if (this.props.disabled !== nextProps.disabled) {
      return true;
    }

    if (
      getIn(next.values, name) !== getIn(cur.values, name) ||
      getIn(next.errors, name) !== getIn(cur.errors, name) ||
      getIn(next.touched, name) !== getIn(cur.touched, name)
    ) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    this.props.formik.registerField(this.props.name, {
      validate: this.props.validate,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.name !== prevProps.name) {
      this.props.formik.unregisterField(prevProps.name);
      this.props.formik.registerField(this.props.name, {
        validate: this.props.validate,
      });
    }

    if (this.props.validate !== prevProps.validate) {
      this.props.formik.registerField(this.props.name, {
        validate: this.props.validate,
      });
    }
  }

  componentWillUnmount() {
    this.props.formik.unregisterField(this.props.name);
  }

  render() {
    const {
      validate,
      name,
      variant,
      label,
      helperText,
      fullWidth,
      margin,
      size,
      onChange,
      required,
      isMulti,
      isClearable,
      useBodyPortal,
      disabled,
      labelSuffix,
      ...others
    } = this.props;
    const formik = this.props.formik;

    const field = {
      value: getIn(formik.values, name),
      name,
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
    };

    field.onChange = (option: any, action: any) => {
      const touched = !!getIn(formik.touched, name);
      if (!touched) {
        formik.setFieldTouched(name, true, false);
      }
      if (isMulti && option === null) {
        formik.setFieldValue(name, []);
      } else {
        formik.setFieldValue(name, option);
      }
      onChange && onChange(option, action);
    };
    field.onBlur = (e: any) => {
      const touched = !!getIn(formik.touched, name);
      if (!touched) {
        formik.setFieldTouched(name, true, false);
      }
    };

    const error = getIn(formik.errors, name);
    const touched = !!getIn(formik.touched, name);
    const isSearchable =
      typeof this.props.isSearchable === "boolean"
        ? this.props.isSearchable
        : true;

    return (
      <StyledField
        className="CMuiFormikSelect"
        fullWidth
        margin={margin || "normal"}
        size={size || "medium"}
      >
        {label && (
          <StyledLabel
            error={!!error && touched ? "error" : undefined}
            size={size || "medium"}
          >
            {label}
            {required && <span className="required-field"> *</span>}{" "}
            {labelSuffix}
          </StyledLabel>
        )}
        <CuideoMultiSelect
          {...field}
          id={name}
          name={name}
          isMulti={isMulti}
          error={!!error && touched}
          variant={variant}
          helperText={error ? error : helperText}
          useBodyPortal={useBodyPortal}
          isDisabled={disabled}
          isClearable={isClearable}
          isSearchable={isSearchable}
          {...others}
        />
        {touched && error && (
          <StyledError size={size || "medium"}>
            {error.value ? error.value : error}
          </StyledError>
        )}
      </StyledField>
    );
  }
}

export default connect<Props, State>(FormikSelect as any);
