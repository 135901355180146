import { Box, IconButton } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import SearchIcon from "@material-ui/icons/Search";
import { fitBounds } from "google-map-react";
import { distanceBetweenCoordinates } from "../../../util/geocodeAddress";

const StyledSearchWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  background-color: #fff;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.09);
  padding: 0.125rem 0.25rem;
  border-radius: 0.25rem;

  .CMuiMapGeoSearchFieldInput {
    border: none;
    outline: none;
  }

  .CMuiMapGeoSearchFieldSearch,
  .CMuiMapGeoSearchFieldSetCoords {
    .MuiSvgIcon-root {
      width: 1.875rem;
      height: 1.875rem;
    }
  }
`;

interface IMapGeoSearchFieldProps {
  visible?: boolean;
  mapRef: React.MutableRefObject<HTMLElement | null>;
  onPlaceSelected: (
    lat: number,
    lng: number,
    zoom: number,
    label: string,
    errorMargin: number
  ) => void;
}

const MapGeoSearchField = (props: IMapGeoSearchFieldProps) => {
  const { visible, mapRef, onPlaceSelected } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const searchBoxRef = useRef(null as any);
  const [state, setState] = useState({
    open: false,
  });

  useEffect(() => {
    if (inputRef && inputRef.current) {
      const searchBox = new google.maps.places.SearchBox(inputRef.current);
      searchBox.addListener("places_changed", handlePlacesChanged);
      searchBoxRef.current = searchBox;

      return () => {
        google.maps.event.clearInstanceListeners(searchBox);
      };
    }
    // eslint-disable-next-line
  }, []);

  const handlePlacesChanged = () => {
    if (!mapRef.current) {
      return;
    }

    const places = searchBoxRef.current.getPlaces();

    const mapbounding = mapRef.current.getBoundingClientRect();
    const top = places[0].geometry.viewport.getNorthEast().lat();
    const bottom = places[0].geometry.viewport.getSouthWest().lat();
    const left = places[0].geometry.viewport.getSouthWest().lng();
    const right = places[0].geometry.viewport.getNorthEast().lng();
    const bounds = {
      nw: {
        lat: top,
        lng: left,
      },
      se: {
        lat: bottom,
        lng: right,
      },
      ne: {
        lat: top,
        lng: right,
      },
      sw: {
        lat: bottom,
        lng: left,
      },
    };
    const size = {
      width: mapbounding.width,
      height: mapbounding.height,
    };
    const diagonalDistance = distanceBetweenCoordinates(
      bounds.nw.lat,
      bounds.nw.lng,
      bounds.se.lat,
      bounds.se.lng,
      "K"
    );
    const errorMargin = diagonalDistance / 2;

    const newBounds = fitBounds(bounds, size);
    onPlaceSelected(
      places[0].geometry.location.lat(),
      places[0].geometry.location.lng(),
      newBounds.zoom > 14 ? 14 : newBounds.zoom,
      places[0].formatted_address,
      errorMargin
    );
  };

  const handleSearchClick = () => {
    setTimeout(() => {
      if (!inputRef.current) {
        return;
      }
      inputRef.current.focus();
    }, 100);

    setState({
      open: true,
    });
  };

  return (
    <StyledSearchWrapper
      className="CMuiMapGeoSearchField"
      style={{
        visibility: !!visible ? "visible" : "hidden",
      }}
    >
      <input
        ref={inputRef}
        className="CMuiMapGeoSearchFieldInput"
        type="text"
        style={{
          visibility: !!visible && state.open ? "visible" : "hidden",
          width: state.open ? "15rem" : 0,
          transition: "width 0.1s ease-in-out",
        }}
      />
      <IconButton
        className="CMuiMapGeoSearchFieldSearch"
        onClick={handleSearchClick}
      >
        <SearchIcon />
      </IconButton>
    </StyledSearchWrapper>
  );
};

export default MapGeoSearchField;
