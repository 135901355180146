import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import Error404 from "../../components/Error404";
import PaginaMisOfertas from "./pages/MyOffersPage";
import OfferPage from "./pages/OfferPage";
import OffersPage from "./pages/OffersPage";
import PaginaCandidato from "./routes/PaginaCandidato";

const MyProfilePage = React.lazy(
  () => import("./pages/MyProfilePage/MyProfilePage")
);
const PersonalInformationPage = React.lazy(
  () => import("./pages/PersonalInformationPage/PersonalInformationPage")
);
const InterestDataPage = React.lazy(() => import("./pages/InterestDataPage"));
const AvailabilityPage = React.lazy(() => import("./pages/AvailabilityPage"));

interface Props {
  match: any;
  authUser: any;
}

const Empleo = (props: Props) => {
  const { match, authUser } = props;

  useEffect(() => {}, []);

  return (
    <React.Suspense fallback={<div></div>}>
      <Switch>
        <Route path={`${match.url}/ofertas`} component={OffersPage} />
        {/* {authUser &&
          (authUser.userRoles.includes("ROLE_STAFF") ||
            authUser.userRoles.includes("ROLE_ADMIN")) && (
            <Route path={`${match.url}/candidatos`} component={EmployeesPage} />
          )} */}
        {/* {authUser &&
          (authUser.userRoles.includes("ROLE_STAFF") ||
            authUser.userRoles.includes("ROLE_ADMIN")) && (
            <Route
              path={`${match.url}/valoraciones`}
              component={ValuationsPage}
            />
          )} */}
        {authUser && authUser.userRoles.includes("ROLE_EMPLOYEE") && (
          <Route
            path={`${match.url}/misOfertas`}
            component={PaginaMisOfertas}
          />
        )}
        {authUser && authUser.userRoles.includes("ROLE_EMPLOYEE") && (
          <Route
            path={`${match.url}/miPerfil`}
            exact
            component={MyProfilePage}
          />
        )}
        {authUser && authUser.userRoles.includes("ROLE_EMPLOYEE") && (
          <Route
            path={`${match.url}/miPerfil/informacionPersonal`}
            component={PersonalInformationPage}
          />
        )}
        {authUser && authUser.userRoles.includes("ROLE_EMPLOYEE") && (
          <Route
            path={`${match.url}/miPerfil/datosInteres`}
            component={InterestDataPage}
          />
        )}
        {authUser && authUser.userRoles.includes("ROLE_EMPLOYEE") && (
          <Route
            path={`${match.url}/miPerfil/disponibilidad`}
            component={AvailabilityPage}
          />
        )}
        <Route path={`${match.url}/oferta/:ofertaId`} component={OfferPage} />
        {authUser &&
          (authUser.userRoles.includes("ROLE_STAFF") ||
            authUser.userRoles.includes("ROLE_ADMIN")) && (
            <Route
              path={`${match.url}/candidato/:candidatoId`}
              component={PaginaCandidato}
            />
          )}
        <Route path={`${match.url}/miPerfil`} exact component={MyProfilePage} />
        <Route component={Error404} />
      </Switch>
    </React.Suspense>
  );
};

const mapStateToProps = ({
  auth,
}: {
  auth: {
    authUser: any;
  };
}) => {
  const { authUser } = auth;
  return { authUser };
};

export default connect(mapStateToProps, {})(Empleo);
