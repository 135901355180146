const idiomas = [
  "Castellano",
  "Catalán",
  "Vasco",
  "Gallego",
  "Francés",
  "Inglés",
  "Italiano",
  "Portugués",
  "Rumano",
  "Arabe",
  "Ruso",
  "Alemán",
  "Holandés",
];

const languages = [
  {
    value: "Castellano",
    businessCountry: "es",
  },
  {
    value: "Catalán",
    businessCountry: "es",
  },
  {
    value: "Vasco",
    businessCountry: "es",
  },
  {
    value: "Gallego",
    businessCountry: "es",
  },
  {
    value: "Francés",
    businessCountry: "es",
  },
  {
    value: "Inglés",
    businessCountry: "es",
  },
  {
    value: "Italiano",
    businessCountry: "es",
  },
  {
    value: "Portugués",
    businessCountry: "es",
  },
  {
    value: "Rumano",
    businessCountry: "es",
  },
  {
    value: "Arabe",
    businessCountry: "es",
  },
  {
    value: "Ruso",
    businessCountry: "es",
  },
  {
    value: "Alemán",
    businessCountry: "es",
  },
  {
    value: "Holandés",
    businessCountry: "es",
  },
  {
    value: "Francés",
    businessCountry: "fr",
  },
  {
    value: "Holandés",
    businessCountry: "fr",
  },
  {
    value: "Arabe",
    businessCountry: "fr",
  },
  {
    value: "Inglés",
    businessCountry: "fr",
  },
  {
    value: "Castellano",
    businessCountry: "fr",
  },
  {
    value: "Portugués",
    businessCountry: "fr",
  },
  {
    value: "Rumano",
    businessCountry: "fr",
  },
  {
    value: "Ruso",
    businessCountry: "fr",
  },
  {
    value: "Italiano",
    businessCountry: "fr",
  },
  {
    value: "Alemán",
    businessCountry: "fr",
  },
];

export function languagesList(country = "es") {
  return languages.filter((language) => language.businessCountry === country);
}

export default idiomas;
