import { useIntl } from "react-intl";
import {
  EMPLOYEE_YEARS_OF_EXPERIENCE_LESS_1_YEAR,
  EMPLOYEE_YEARS_OF_EXPERIENCE_LESS_2_YEARS,
  EMPLOYEE_YEARS_OF_EXPERIENCE_LESS_3_YEARS,
  EMPLOYEE_YEARS_OF_EXPERIENCE_MORE_3_YEARS,
  EMPLOYEE_YEARS_OF_EXPERIENCE_MORE_5_YEARS,
  EMPLOYEE_YEARS_OF_EXPERIENCE_MORE_10_YEARS,
  EMPLOYEE_YEARS_OF_EXPERIENCE_NO_EXPERIENCE,
} from "../../util/defaults/candidato";
import {
  KINSHIP_BROTHER_SISTER,
  KINSHIP_CAREGIVER,
  KINSHIP_CHILD,
  KINSHIP_FATHER_MOTHER,
  KINSHIP_FRIEND,
  KINSHIP_GRANDSON,
  KINSHIP_NEPHEW,
  KINSHIP_OTHER,
  KINSHIP_SISTER_IN_LAW,
  KINSHIP_SON_IN_LAW,
  KINSHIP_SPOUSE,
  KINSHIP_UNCLE,
  KINSHIP_USER,
} from "../../util/defaults/kinship";
import { getCountryCodeFromCountryName } from "../../util/defaults/paises";
import { SERVICE_PREFERRED_SEX } from "../../api/service/service.types";
import {
  SERVICE_HOLDER_CARETAKER_ADAPT_ALREADY_HAD,
  SERVICE_HOLDER_CARETAKER_ADAPT_NOT_WANT_TO_ACCEPT_IT,
  SERVICE_HOLDER_CARETAKER_ADAPT_RETICENT_BUT_WILL_TRY,
  SERVICE_HOLDER_COMPANION_ALONE,
  SERVICE_HOLDER_COMPANION_OTHER,
  SERVICE_HOLDER_COMPANION_WITH_PARTNER,
  SERVICE_HOLDER_COMPANION_WITH_RELATIVE,
  SERVICE_HOLDER_EMOTIONAL_STATUS_AFFABLE,
  SERVICE_HOLDER_EMOTIONAL_STATUS_APATHETIC,
  SERVICE_HOLDER_EMOTIONAL_STATUS_CALMED,
  SERVICE_HOLDER_EMOTIONAL_STATUS_CONFORMIST,
  SERVICE_HOLDER_EMOTIONAL_STATUS_EXIGENT,
  SERVICE_HOLDER_EMOTIONAL_STATUS_FAMILIAR,
  SERVICE_HOLDER_EMOTIONAL_STATUS_FRIENDLY,
  SERVICE_HOLDER_EMOTIONAL_STATUS_FUNNY,
  SERVICE_HOLDER_EMOTIONAL_STATUS_HOMELIKE,
  SERVICE_HOLDER_EMOTIONAL_STATUS_INSECURE,
  SERVICE_HOLDER_EMOTIONAL_STATUS_LAZY,
  SERVICE_HOLDER_EMOTIONAL_STATUS_MESSY,
  SERVICE_HOLDER_EMOTIONAL_STATUS_NERVOUS,
  SERVICE_HOLDER_EMOTIONAL_STATUS_NICE,
  SERVICE_HOLDER_EMOTIONAL_STATUS_OBSESSIVE,
  SERVICE_HOLDER_EMOTIONAL_STATUS_PERSEVERANT,
  SERVICE_HOLDER_EMOTIONAL_STATUS_QUIET,
  SERVICE_HOLDER_EMOTIONAL_STATUS_SENSIBLE,
  SERVICE_HOLDER_EMOTIONAL_STATUS_SENTIMENTAL,
  SERVICE_HOLDER_EMOTIONAL_STATUS_STRONG_CHARACTER,
  SERVICE_HOLDER_EMOTIONAL_STATUS_SUSPICIOUS,
  SERVICE_HOLDER_EXPRESION_CAN_HAVE_A_CONVERSATION,
  SERVICE_HOLDER_EXPRESION_HAVE_DIFFICULTIES,
  SERVICE_HOLDER_EXPRESION_UNDERSTANDABLE,
  SERVICE_HOLDER_HEADPHONES_NO,
  SERVICE_HOLDER_HEADPHONES_YES,
  SERVICE_HOLDER_HEARING_LEVEL_GOOD,
  SERVICE_HOLDER_HEARING_LEVEL_LOW,
  SERVICE_HOLDER_HEARING_LEVEL_MEDIUM,
  SERVICE_HOLDER_HOME_FEATURE_ADAPTED_BATHROOM,
  SERVICE_HOLDER_HOME_FEATURE_ARTICULATED_BED,
  SERVICE_HOLDER_HOME_FEATURE_CAMERAS,
  SERVICE_HOLDER_HOME_FEATURE_CLEAN_SERVICE,
  SERVICE_HOLDER_HOME_FEATURE_CRANE,
  SERVICE_HOLDER_HOME_FEATURE_ELEVATOR,
  SERVICE_HOLDER_HOME_FEATURE_PETS,
  SERVICE_HOLDER_HOME_FEATURE_TELE_ASSISTANCE,
  SERVICE_HOLDER_HOME_TYPE_FLAT,
  SERVICE_HOLDER_HOME_TYPE_HOUSE,
  SERVICE_HOLDER_MOBILITY_AUTONOMOUS,
  SERVICE_HOLDER_MOBILITY_NEEDS_CANE,
  SERVICE_HOLDER_MOBILITY_NEEDS_CRUTCHES,
  SERVICE_HOLDER_MOBILITY_NEEDS_HELP,
  SERVICE_HOLDER_MOBILITY_NEEDS_SUPERVISION,
  SERVICE_HOLDER_MOBILITY_NEEDS_WALKER,
  SERVICE_HOLDER_MOBILITY_USES_WHEELCHAIR,
  SERVICE_HOLDER_SENSORIAL_TREATMENT_NO,
  SERVICE_HOLDER_SENSORIAL_TREATMENT_YES,
  SERVICE_HOLDER_SLEEP_QUALITY_BAD,
  SERVICE_HOLDER_SLEEP_QUALITY_GOOD,
  SERVICE_HOLDER_SLEEP_QUALITY_MEDIUM,
  SERVICE_HOLDER_SLEEP_QUALITY_VERY_GOOD,
  SERVICE_HOLDER_TOILET_LEVEL_AUTONOMOUS,
  SERVICE_HOLDER_TOILET_LEVEL_BEDRIDDEN,
  SERVICE_HOLDER_TOILET_LEVEL_NEEDS_HELP,
  SERVICE_HOLDER_TOILET_LEVEL_OTHERS,
  SERVICE_HOLDER_TOILET_LEVEL_WEARS_A_DIAPER,
  SERVICE_HOLDER_VISION_LEVEL_DETERIORATED,
  SERVICE_HOLDER_VISION_LEVEL_GLASSES,
  SERVICE_HOLDER_VISION_LEVEL_GOOD,
  SERVICE_HOLDER_VISION_LEVEL_VERY_GOOD,
} from "../../util/defaults/serviceHolder";
import {
  SERVICE_HOLDER_USER_STATUS_ACTIVE,
  SERVICE_HOLDER_USER_STATUS_ADMISSION_TO_RESIDENCE,
  SERVICE_HOLDER_USER_STATUS_DEATH,
  SERVICE_HOLDER_USER_STATUS_HOSPITALIZATION,
  SERVICE_HOLDER_USER_STATUS_INACTIVE,
} from "../../api/serviceHolder/types";
import {
  COMMUNICATION_INTENT_TYPE_EMPLOYEE_INTERESTED_IN_OFFER,
  COMMUNICATION_INTENT_TYPE_EMPLOYEE_INTERESTED_IN_WORK,
} from "../../domain/CommunicationIntent/CommunicationIntent";
import { TValuationStatus } from "../../api/valuation/valuation.types";
import { useCallback } from "react";
import { EMPLOYEE_STATUS, EMPLOYEE_TIME_SLOT } from "../../api/employee/employee.types";
import { CONTRACT_JOB_TYPE, CONTRACT_OVERNIGHT_HOURS, CONTRACT_PRESENCE_HOURS, CONTRACT_SALARY_TYPE, CONTRACT_TYPE } from "../../api/contract/contract.types";

export default function useFormatters() {
  const { formatMessage } = useIntl();

  const formatContractType = (type: string) => {
    switch (type) {
      case CONTRACT_TYPE.FULL_INDEFINITE:
        return formatMessage({
          id: "ContractType.FullIndefinite",
          defaultMessage: "Indefinido completa"
        });
      case CONTRACT_TYPE.PARTIAL_INDEFINITE:
        return formatMessage({
          id: "ContractType.PartialIndefinite",
          defaultMessage: "Indefinido Parcial"
        });
      case CONTRACT_TYPE.FULL_INTERIM:
        return formatMessage({
          id: "ContractType.FullInterim",
          defaultMessage: "Interinidad completa"
        });
      case CONTRACT_TYPE.PARTIAL_INTERIM:
        return formatMessage({
          id: "ContractType.PartialInterim",
          defaultMessage: "Interinidad parcial"
        });
      case CONTRACT_TYPE.FULL_EVENTUAL:
        return formatMessage({
          id: "ContractType.FullEventual",
          defaultMessage: "Eventual-Completa"
        });
      case CONTRACT_TYPE.PARTIAL_EVENTUAL:
        return formatMessage({
          id: "ContractType.PartialEventual",
          defaultMessage: "Eventual-Parcial"
        });
    }
    return type;
  };

  const formatContractOvernightHours = (value: string) => {
    switch (value) {
      case CONTRACT_OVERNIGHT_HOURS.YES:
        return formatMessage({
          id: "ContractOvernightHours.Yes",
          defaultMessage: "Si"
        });
      case CONTRACT_OVERNIGHT_HOURS.NO:
        return formatMessage({
          id: "ContractOvernightHours.No",
          defaultMessage: "No"
        });
    }
    return value;
  };

  const formatContractPresencetHours = (value: string) => {
    switch (value) {
      case CONTRACT_PRESENCE_HOURS.YES:
        return formatMessage({
          id: "ContractPresenceHours.Yes",
          defaultMessage: "Si"
        });
      case CONTRACT_PRESENCE_HOURS.NO:
        return formatMessage({
          id: "ContractPresenceHours.No",
          defaultMessage: "No"
        });
    }
    return value;
  };

  const formatContractSalaryType = (value: string) => {
    switch (value) {
      case CONTRACT_SALARY_TYPE.DAILY:
        return formatMessage({
          id: "ContractSalaryType.Daily",
          defaultMessage: "Diario"
        });
      case CONTRACT_SALARY_TYPE.MONTHLY:
        return formatMessage({
          id: "ContractSalaryType.Monthly",
          defaultMessage: "Mensual"
        });
    }
    return value;
  };

  const formatContractJobType = (type: string) => {
    switch (type) {
      case CONTRACT_JOB_TYPE.INTERNAL_DURING_WEEK:
        return formatMessage({
          id: "type.Interna entre semana",
          defaultMessage: "Interna entre semana",
        });
      case CONTRACT_JOB_TYPE.INTERNAL_DURING_WEEKEND:
        return formatMessage({
          id: "type.Interna fin de semana",
          defaultMessage: "Interna fin de semana",
        });
      case CONTRACT_JOB_TYPE.EXTERNAL_DURING_WEEK:
        return formatMessage({
          id: "type.Externa entre semana",
          defaultMessage: "Externa entre semana",
        });
      case CONTRACT_JOB_TYPE.EXTERNAL_DURING_WEEKEND:
        return formatMessage({
          id: "type.Externa fin de semana",
          defaultMessage: "Externa fin de semana",
        });
    }
    return type;
  };

  const formatTimeSlots = (slot: string, breakline: boolean = false) => {
    switch (slot) {
      case EMPLOYEE_TIME_SLOT.MORNING:
        return (
          <span>
            {formatMessage({
              id: "timeSlot.Mañana",
              defaultMessage: "Mañana"
            })}
            {breakline ? <br /> : " "}
            <span style={{ fontSize: '0.8rem' }}>
            {formatMessage({
              id: "timeSlot.MañanaHours",
              defaultMessage: "(de 7h a 12:30h)"
            })}
            </span>
          </span>
        );
      case EMPLOYEE_TIME_SLOT.NOON:
        return (
          <span>
            {formatMessage({
              id: "timeSlot.Mediodía",
              defaultMessage: "Mediodía"
            })}
            {breakline ? <br /> : " "}
            <span style={{ fontSize: '0.8rem' }}>
            {formatMessage({
              id: "timeSlot.MediodíaHours",
              defaultMessage: "(de 13h a 15h)"
            })}
            </span>
          </span>
        );
      case EMPLOYEE_TIME_SLOT.AFTERNOON:
        return (
          <span>
            {formatMessage({
              id: "timeSlot.Tarde",
              defaultMessage: "Tarde"
            })}
            {breakline ? <br /> : " "}
            <span style={{ fontSize: '0.8rem' }}>
            {formatMessage({
              id: "timeSlot.TardeHours",
              defaultMessage: "(de 16h a 22h)"
            })}
            </span>
          </span>
        );
    }
    return slot;
  }

  const formatCountry = (country: string) => {
    const countryCode = getCountryCodeFromCountryName(country);

    switch (countryCode) {
      case "AD":
        return formatMessage({ id: "country.AD", defaultMessage: "Andorra" });
      case "AE":
        return formatMessage({
          id: "country.AE",
          defaultMessage: "Emiratos Árabes Unidos",
        });
      case "AF":
        return formatMessage({
          id: "country.AF",
          defaultMessage: "Afganistán",
        });
      case "AG":
        return formatMessage({
          id: "country.AG",
          defaultMessage: "Antigua y Barbuda",
        });
      case "AI":
        return formatMessage({ id: "country.AI", defaultMessage: "Anguila" });
      case "AL":
        return formatMessage({ id: "country.AL", defaultMessage: "Albania" });
      case "AM":
        return formatMessage({ id: "country.AM", defaultMessage: "Armenia" });
      case "AO":
        return formatMessage({ id: "country.AO", defaultMessage: "Angola" });
      case "AQ":
        return formatMessage({ id: "country.AQ", defaultMessage: "Antártida" });
      case "AR":
        return formatMessage({ id: "country.AR", defaultMessage: "Argentina" });
      case "AS":
        return formatMessage({
          id: "country.AS",
          defaultMessage: "Samoa Americana",
        });
      case "AT":
        return formatMessage({ id: "country.AT", defaultMessage: "Austria" });
      case "AU":
        return formatMessage({ id: "country.AU", defaultMessage: "Australia" });
      case "AW":
        return formatMessage({ id: "country.AW", defaultMessage: "Aruba" });
      case "AX":
        return formatMessage({
          id: "country.AX",
          defaultMessage: "Islas Åland",
        });
      case "AZ":
        return formatMessage({
          id: "country.AZ",
          defaultMessage: "Azerbaiyán",
        });
      case "BA":
        return formatMessage({
          id: "country.BA",
          defaultMessage: "Bosnia y Herzegovina",
        });
      case "BB":
        return formatMessage({ id: "country.BB", defaultMessage: "Barbados" });
      case "BD":
        return formatMessage({ id: "country.BD", defaultMessage: "Bangladés" });
      case "BE":
        return formatMessage({ id: "country.BE", defaultMessage: "Bélgica" });
      case "BF":
        return formatMessage({
          id: "country.BF",
          defaultMessage: "Burkina Faso",
        });
      case "BG":
        return formatMessage({ id: "country.BG", defaultMessage: "Bulgaria" });
      case "BH":
        return formatMessage({ id: "country.BH", defaultMessage: "Baréin" });
      case "BI":
        return formatMessage({ id: "country.BI", defaultMessage: "Burundi" });
      case "BJ":
        return formatMessage({ id: "country.BJ", defaultMessage: "Benín" });
      case "BL":
        return formatMessage({
          id: "country.BL",
          defaultMessage: "San Bartolomé",
        });
      case "BM":
        return formatMessage({ id: "country.BM", defaultMessage: "Bermudas" });
      case "BN":
        return formatMessage({
          id: "country.BN",
          defaultMessage: "Brunéi Darussalam",
        });
      case "BO":
        return formatMessage({ id: "country.BO", defaultMessage: "Bolivia" });
      case "BQ":
        return formatMessage({
          id: "country.BQ",
          defaultMessage: "Bonaire, San Eustaquio y Saba",
        });
      case "BR":
        return formatMessage({ id: "country.BR", defaultMessage: "Brasil" });
      case "BS":
        return formatMessage({ id: "country.BS", defaultMessage: "Bahamas" });
      case "BT":
        return formatMessage({ id: "country.BT", defaultMessage: "Bután" });
      case "BV":
        return formatMessage({
          id: "country.BV",
          defaultMessage: "Isla Bouvet",
        });
      case "BW":
        return formatMessage({ id: "country.BW", defaultMessage: "Botsuana" });
      case "BY":
        return formatMessage({
          id: "country.BY",
          defaultMessage: "Bielorrusia",
        });
      case "BZ":
        return formatMessage({ id: "country.BZ", defaultMessage: "Belice" });
      case "CA":
        return formatMessage({ id: "country.CA", defaultMessage: "Canadá" });
      case "CC":
        return formatMessage({
          id: "country.CC",
          defaultMessage: "Islas Cocos (Keeling)",
        });
      case "CD":
        return formatMessage({
          id: "country.CD",
          defaultMessage: "Congo, La República Democrática del",
        });
      case "CF":
        return formatMessage({
          id: "country.CF",
          defaultMessage: "República Centroafricana",
        });
      case "CG":
        return formatMessage({ id: "country.CG", defaultMessage: "Congo" });
      case "CH":
        return formatMessage({ id: "country.CH", defaultMessage: "Suiza" });
      case "CI":
        return formatMessage({
          id: "country.CI",
          defaultMessage: "Costa de Marfil",
        });
      case "CK":
        return formatMessage({
          id: "country.CK",
          defaultMessage: "Islas Cook",
        });
      case "CL":
        return formatMessage({ id: "country.CL", defaultMessage: "Chile" });
      case "CM":
        return formatMessage({ id: "country.CM", defaultMessage: "Camerún" });
      case "CN":
        return formatMessage({ id: "country.CN", defaultMessage: "China" });
      case "CO":
        return formatMessage({ id: "country.CO", defaultMessage: "Colombia" });
      case "CR":
        return formatMessage({
          id: "country.CR",
          defaultMessage: "Costa Rica",
        });
      case "CU":
        return formatMessage({ id: "country.CU", defaultMessage: "Cuba" });
      case "CV":
        return formatMessage({
          id: "country.CV",
          defaultMessage: "Cabo Verde",
        });
      case "CW":
        return formatMessage({ id: "country.CW", defaultMessage: "Curazao" });
      case "CX":
        return formatMessage({
          id: "country.CX",
          defaultMessage: "Isla de Navidad",
        });
      case "CY":
        return formatMessage({ id: "country.CY", defaultMessage: "Chipre" });
      case "CZ":
        return formatMessage({
          id: "country.CZ",
          defaultMessage: "República Checa",
        });
      case "DE":
        return formatMessage({ id: "country.DE", defaultMessage: "Alemania" });
      case "DJ":
        return formatMessage({ id: "country.DJ", defaultMessage: "Yibuti" });
      case "DK":
        return formatMessage({ id: "country.DK", defaultMessage: "Dinamarca" });
      case "DM":
        return formatMessage({ id: "country.DM", defaultMessage: "Dominica" });
      case "DO":
        return formatMessage({
          id: "country.DO",
          defaultMessage: "República Dominicana",
        });
      case "DZ":
        return formatMessage({ id: "country.DZ", defaultMessage: "Argelia" });
      case "EC":
        return formatMessage({ id: "country.EC", defaultMessage: "Ecuador" });
      case "EE":
        return formatMessage({ id: "country.EE", defaultMessage: "Estonia" });
      case "EG":
        return formatMessage({ id: "country.EG", defaultMessage: "Egipto" });
      case "EH":
        return formatMessage({
          id: "country.EH",
          defaultMessage: "Sahara Occidental",
        });
      case "ER":
        return formatMessage({ id: "country.ER", defaultMessage: "Eritrea" });
      case "ES":
        return formatMessage({ id: "country.ES", defaultMessage: "España" });
      case "ET":
        return formatMessage({ id: "country.ET", defaultMessage: "Etiopía" });
      case "FI":
        return formatMessage({ id: "country.FI", defaultMessage: "Finlandia" });
      case "FJ":
        return formatMessage({ id: "country.FJ", defaultMessage: "Fiyi" });
      case "FK":
        return formatMessage({
          id: "country.FK",
          defaultMessage: "Islas Falkland (Malvinas)",
        });
      case "FM":
        return formatMessage({
          id: "country.FM",
          defaultMessage: "Micronesia, Estados Federados de",
        });
      case "FO":
        return formatMessage({
          id: "country.FO",
          defaultMessage: "Islas Feroe",
        });
      case "FR":
        return formatMessage({ id: "country.FR", defaultMessage: "Francia" });
      case "GA":
        return formatMessage({ id: "country.GA", defaultMessage: "Gabón" });
      case "GB":
        return formatMessage({
          id: "country.GB",
          defaultMessage: "Reino Unido",
        });
      case "GD":
        return formatMessage({ id: "country.GD", defaultMessage: "Georgia" });
      case "GE":
        return formatMessage({ id: "country.GE", defaultMessage: "Georgia" });
      case "GF":
        return formatMessage({
          id: "country.GF",
          defaultMessage: "Guayana Francesa",
        });
      case "GG":
        return formatMessage({ id: "country.GG", defaultMessage: "Granada" });
      case "GH":
        return formatMessage({ id: "country.GH", defaultMessage: "Ghana" });
      case "GI":
        return formatMessage({ id: "country.GI", defaultMessage: "Gibraltar" });
      case "GL":
        return formatMessage({
          id: "country.GL",
          defaultMessage: "Groenlandia",
        });
      case "GM":
        return formatMessage({ id: "country.GM", defaultMessage: "Gambia" });
      case "GN":
        return formatMessage({ id: "country.GN", defaultMessage: "Guinea" });
      case "GP":
        return formatMessage({ id: "country.GP", defaultMessage: "Guadalupe" });
      case "GQ":
        return formatMessage({
          id: "country.GQ",
          defaultMessage: "Guinea Ecuatorial",
        });
      case "GR":
        return formatMessage({ id: "country.GR", defaultMessage: "Grecia" });
      case "GS":
        return formatMessage({
          id: "country.GS",
          defaultMessage: "Islas Georgias del Sur y Sandwich del Sur",
        });
      case "GT":
        return formatMessage({ id: "country.GT", defaultMessage: "Guatemala" });
      case "GU":
        return formatMessage({ id: "country.GU", defaultMessage: "Guam" });
      case "GW":
        return formatMessage({
          id: "country.GW",
          defaultMessage: "Guinea-Bisáu",
        });
      case "GY":
        return formatMessage({ id: "country.GY", defaultMessage: "Guyana" });
      case "HK":
        return formatMessage({ id: "country.HK", defaultMessage: "Hong Kong" });
      case "HM":
        return formatMessage({
          id: "country.HM",
          defaultMessage: "Islas Heard y Mcdonald",
        });
      case "HN":
        return formatMessage({ id: "country.HN", defaultMessage: "Honduras" });
      case "HR":
        return formatMessage({ id: "country.HR", defaultMessage: "Croacia" });
      case "HT":
        return formatMessage({ id: "country.HT", defaultMessage: "Haití" });
      case "HU":
        return formatMessage({ id: "country.HU", defaultMessage: "Hungría" });
      case "ID":
        return formatMessage({ id: "country.ID", defaultMessage: "Indonesia" });
      case "IE":
        return formatMessage({ id: "country.IE", defaultMessage: "Irlanda" });
      case "IL":
        return formatMessage({ id: "country.IL", defaultMessage: "Israel" });
      case "IM":
        return formatMessage({
          id: "country.IM",
          defaultMessage: "Isla de Man",
        });
      case "IN":
        return formatMessage({ id: "country.IN", defaultMessage: "India" });
      case "IO":
        return formatMessage({
          id: "country.IO",
          defaultMessage: "Territorio Británico del Océano Índico",
        });
      case "IQ":
        return formatMessage({ id: "country.IQ", defaultMessage: "Irak" });
      case "IR":
        return formatMessage({ id: "country.IR", defaultMessage: "Irán" });
      case "IS":
        return formatMessage({ id: "country.IS", defaultMessage: "Islandia" });
      case "IT":
        return formatMessage({ id: "country.IT", defaultMessage: "Italia" });
      case "JE":
        return formatMessage({ id: "country.JE", defaultMessage: "Jersey" });
      case "JM":
        return formatMessage({ id: "country.JM", defaultMessage: "Jamaica" });
      case "JO":
        return formatMessage({ id: "country.JO", defaultMessage: "Jordania" });
      case "JP":
        return formatMessage({ id: "country.JP", defaultMessage: "Japón" });
      case "KE":
        return formatMessage({ id: "country.KE", defaultMessage: "Kenia" });
      case "KG":
        return formatMessage({
          id: "country.KG",
          defaultMessage: "Kirguistán",
        });
      case "KH":
        return formatMessage({ id: "country.KH", defaultMessage: "Camboya" });
      case "KI":
        return formatMessage({ id: "country.KI", defaultMessage: "Kiribati" });
      case "KM":
        return formatMessage({ id: "country.KM", defaultMessage: "Comoras" });
      case "KN":
        return formatMessage({
          id: "country.KN",
          defaultMessage: "San Cristóbal y Nieves",
        });
      case "KP":
        return formatMessage({
          id: "country.KP",
          defaultMessage: "Corea, República Democrática Popular de",
        });
      case "KR":
        return formatMessage({
          id: "country.KR",
          defaultMessage: "Corea, República de",
        });
      case "KW":
        return formatMessage({ id: "country.KW", defaultMessage: "Kuwait" });
      case "KY":
        return formatMessage({
          id: "country.KY",
          defaultMessage: "Islas Caimán",
        });
      case "KZ":
        return formatMessage({
          id: "country.KZ",
          defaultMessage: "Kazajistán",
        });
      case "LA":
        return formatMessage({
          id: "country.LA",
          defaultMessage: "República Democrática Popular Lao",
        });
      case "LB":
        return formatMessage({ id: "country.LB", defaultMessage: "Líbano" });
      case "LC":
        return formatMessage({
          id: "country.LC",
          defaultMessage: "Santa Lucía",
        });
      case "LI":
        return formatMessage({
          id: "country.LI",
          defaultMessage: "Liechtenstein",
        });
      case "LK":
        return formatMessage({ id: "country.LK", defaultMessage: "Sri Lanka" });
      case "LR":
        return formatMessage({ id: "country.LR", defaultMessage: "Liberia" });
      case "LS":
        return formatMessage({ id: "country.LS", defaultMessage: "Lesoto" });
      case "LT":
        return formatMessage({ id: "country.LT", defaultMessage: "Lituania" });
      case "LU":
        return formatMessage({
          id: "country.LU",
          defaultMessage: "Luxemburgo",
        });
      case "LV":
        return formatMessage({ id: "country.LV", defaultMessage: "Letonia" });
      case "LY":
        return formatMessage({ id: "country.LY", defaultMessage: "Libia" });
      case "MA":
        return formatMessage({ id: "country.MA", defaultMessage: "Marruecos" });
      case "MC":
        return formatMessage({ id: "country.MC", defaultMessage: "Mónaco" });
      case "MD":
        return formatMessage({
          id: "country.MD",
          defaultMessage: "Moldavia, República de",
        });
      case "ME":
        return formatMessage({
          id: "country.ME",
          defaultMessage: "Montenegro",
        });
      case "MF":
        return formatMessage({
          id: "country.MF",
          defaultMessage: "San Martín (Parte Francesa)",
        });
      case "MG":
        return formatMessage({
          id: "country.MG",
          defaultMessage: "Madagascar",
        });
      case "MH":
        return formatMessage({
          id: "country.MH",
          defaultMessage: "Islas Marshall",
        });
      case "MK":
        return formatMessage({
          id: "country.MK",
          defaultMessage: "Macedonia, La Antigua República Yugoslava de",
        });
      case "ML":
        return formatMessage({ id: "country.ML", defaultMessage: "Malí" });
      case "MM":
        return formatMessage({ id: "country.MM", defaultMessage: "Myanmar" });
      case "MN":
        return formatMessage({ id: "country.MN", defaultMessage: "Mongolia" });
      case "MO":
        return formatMessage({ id: "country.MO", defaultMessage: "Macao" });
      case "MP":
        return formatMessage({
          id: "country.MP",
          defaultMessage: "Islas Marianas del Norte",
        });
      case "MQ":
        return formatMessage({ id: "country.MQ", defaultMessage: "Martinica" });
      case "MR":
        return formatMessage({
          id: "country.MR",
          defaultMessage: "Mauritania",
        });
      case "MS":
        return formatMessage({
          id: "country.MS",
          defaultMessage: "Montserrat",
        });
      case "MT":
        return formatMessage({ id: "country.MT", defaultMessage: "Malta" });
      case "MU":
        return formatMessage({ id: "country.MU", defaultMessage: "Mauricio" });
      case "MV":
        return formatMessage({ id: "country.MV", defaultMessage: "Maldivas" });
      case "MW":
        return formatMessage({ id: "country.MW", defaultMessage: "Malaui" });
      case "MX":
        return formatMessage({ id: "country.MX", defaultMessage: "México" });
      case "MY":
        return formatMessage({ id: "country.MY", defaultMessage: "Malasia" });
      case "MZ":
        return formatMessage({
          id: "country.MZ",
          defaultMessage: "Mozambique",
        });
      case "NA":
        return formatMessage({ id: "country.NA", defaultMessage: "Nabimia" });
      case "NC":
        return formatMessage({
          id: "country.NC",
          defaultMessage: "Nueva Caledonia",
        });
      case "NE":
        return formatMessage({ id: "country.NE", defaultMessage: "Níger" });
      case "NF":
        return formatMessage({
          id: "country.NF",
          defaultMessage: "Isla Norfolk",
        });
      case "NG":
        return formatMessage({ id: "country.NG", defaultMessage: "Nigeria" });
      case "NI":
        return formatMessage({ id: "country.NI", defaultMessage: "Nicaragua" });
      case "NL":
        return formatMessage({
          id: "country.NL",
          defaultMessage: "Países Bajos",
        });
      case "NO":
        return formatMessage({ id: "country.NO", defaultMessage: "Noruega" });
      case "NP":
        return formatMessage({ id: "country.NP", defaultMessage: "Nepal" });
      case "NR":
        return formatMessage({ id: "country.NR", defaultMessage: "Nauru" });
      case "NU":
        return formatMessage({ id: "country.NU", defaultMessage: "Niue" });
      case "NZ":
        return formatMessage({
          id: "country.NZ",
          defaultMessage: "Nueva Zelanda",
        });
      case "OM":
        return formatMessage({ id: "country.OM", defaultMessage: "Omán" });
      case "PA":
        return formatMessage({ id: "country.PA", defaultMessage: "Panamá" });
      case "PE":
        return formatMessage({ id: "country.PE", defaultMessage: "Perú" });
      case "PF":
        return formatMessage({
          id: "country.PF",
          defaultMessage: "Polinesia Francesa",
        });
      case "PG":
        return formatMessage({
          id: "country.PG",
          defaultMessage: "Papúa Nueva Guinea",
        });
      case "PH":
        return formatMessage({ id: "country.PH", defaultMessage: "Filipinas" });
      case "PK":
        return formatMessage({ id: "country.PK", defaultMessage: "Pakistán" });
      case "PL":
        return formatMessage({ id: "country.PL", defaultMessage: "Polonia" });
      case "PM":
        return formatMessage({
          id: "country.PM",
          defaultMessage: "San Pedro y Miquelón",
        });
      case "PN":
        return formatMessage({ id: "country.PN", defaultMessage: "Pitcairn" });
      case "PR":
        return formatMessage({
          id: "country.PR",
          defaultMessage: "Puerto Rico",
        });
      case "PS":
        return formatMessage({
          id: "country.PS",
          defaultMessage: "Palestina, Estado de",
        });
      case "PT":
        return formatMessage({ id: "country.PT", defaultMessage: "Portugal" });
      case "PW":
        return formatMessage({ id: "country.PW", defaultMessage: "Palaos" });
      case "PY":
        return formatMessage({ id: "country.PY", defaultMessage: "Paraguay" });
      case "QA":
        return formatMessage({ id: "country.QA", defaultMessage: "Qatar" });
      case "RE":
        return formatMessage({ id: "country.RE", defaultMessage: "Reunión" });
      case "RO":
        return formatMessage({ id: "country.RO", defaultMessage: "Rumania" });
      case "RS":
        return formatMessage({ id: "country.RS", defaultMessage: "Serbia" });
      case "RU":
        return formatMessage({
          id: "country.RU",
          defaultMessage: "Federación Rusa",
        });
      case "RW":
        return formatMessage({ id: "country.RW", defaultMessage: "Ruanda" });
      case "SA":
        return formatMessage({
          id: "country.SA",
          defaultMessage: "Arabia Saudita",
        });
      case "SB":
        return formatMessage({
          id: "country.SB",
          defaultMessage: "Islas Salomón",
        });
      case "SC":
        return formatMessage({
          id: "country.SC",
          defaultMessage: "Seychelles",
        });
      case "SD":
        return formatMessage({ id: "country.SD", defaultMessage: "Sudán" });
      case "SE":
        return formatMessage({ id: "country.SE", defaultMessage: "Suecia" });
      case "SG":
        return formatMessage({ id: "country.SG", defaultMessage: "Singapur" });
      case "SH":
        return formatMessage({
          id: "country.SH",
          defaultMessage: "Santa Helena, Ascensión y Tristán de Acuña",
        });
      case "SI":
        return formatMessage({ id: "country.SI", defaultMessage: "Eslovenia" });
      case "SJ":
        return formatMessage({
          id: "country.SJ",
          defaultMessage: "Svalbard y Jan Mayen",
        });
      case "SK":
        return formatMessage({
          id: "country.SK",
          defaultMessage: "Eslovaquia",
        });
      case "SL":
        return formatMessage({
          id: "country.SL",
          defaultMessage: "Sierra Leona",
        });
      case "SM":
        return formatMessage({
          id: "country.SM",
          defaultMessage: "San Marino",
        });
      case "SN":
        return formatMessage({ id: "country.SN", defaultMessage: "Senegal" });
      case "SO":
        return formatMessage({ id: "country.SO", defaultMessage: "Somalia" });
      case "SR":
        return formatMessage({ id: "country.SR", defaultMessage: "Surinam" });
      case "SS":
        return formatMessage({
          id: "country.SS",
          defaultMessage: "Sudán del Sur",
        });
      case "ST":
        return formatMessage({
          id: "country.ST",
          defaultMessage: "Santo Tomé y Principe",
        });
      case "SV":
        return formatMessage({
          id: "country.SV",
          defaultMessage: "El Salvador",
        });
      case "SX":
        return formatMessage({
          id: "country.SX",
          defaultMessage: "Sint Maarten (Parte Neerlandesa)",
        });
      case "SY":
        return formatMessage({
          id: "country.SY",
          defaultMessage: "Siria, República Arabe de",
        });
      case "SZ":
        return formatMessage({
          id: "country.SZ",
          defaultMessage: "Suazilandia",
        });
      case "TC":
        return formatMessage({
          id: "country.TC",
          defaultMessage: "Islas Turcas y Caicos",
        });
      case "TD":
        return formatMessage({ id: "country.TD", defaultMessage: "Chad" });
      case "TF":
        return formatMessage({
          id: "country.TF",
          defaultMessage: "Territorios Australes Franceses",
        });
      case "TG":
        return formatMessage({ id: "country.TG", defaultMessage: "Togo" });
      case "TH":
        return formatMessage({ id: "country.TH", defaultMessage: "Tailandia" });
      case "TJ":
        return formatMessage({
          id: "country.TJ",
          defaultMessage: "Tayikistán",
        });
      case "TK":
        return formatMessage({ id: "country.TK", defaultMessage: "Tokelau" });
      case "TL":
        return formatMessage({
          id: "country.TL",
          defaultMessage: "Timor-Leste",
        });
      case "TM":
        return formatMessage({
          id: "country.TM",
          defaultMessage: "Turkmenistán",
        });
      case "TN":
        return formatMessage({ id: "country.TN", defaultMessage: "Túnez" });
      case "TO":
        return formatMessage({ id: "country.TO", defaultMessage: "Tonga" });
      case "TR":
        return formatMessage({ id: "country.TR", defaultMessage: "Turquía" });
      case "TT":
        return formatMessage({
          id: "country.TT",
          defaultMessage: "Trinidad y Tobago",
        });
      case "TV":
        return formatMessage({ id: "country.TV", defaultMessage: "Tuvalu" });
      case "TW":
        return formatMessage({
          id: "country.TW",
          defaultMessage: "Taiwán, Provincia de China",
        });
      case "TZ":
        return formatMessage({
          id: "country.TZ",
          defaultMessage: "Tanzania, República Unida de",
        });
      case "UA":
        return formatMessage({ id: "country.UA", defaultMessage: "Ucrania" });
      case "UG":
        return formatMessage({ id: "country.UG", defaultMessage: "Uganda" });
      case "UM":
        return formatMessage({
          id: "country.UM",
          defaultMessage: "Islas Ultramarinas Menores de Estados Unidos",
        });
      case "US":
        return formatMessage({
          id: "country.US",
          defaultMessage: "Estados Unidos",
        });
      case "UY":
        return formatMessage({ id: "country.UY", defaultMessage: "Uruguay" });
      case "UZ":
        return formatMessage({
          id: "country.UZ",
          defaultMessage: "Uzbekistán",
        });
      case "VA":
        return formatMessage({
          id: "country.VA",
          defaultMessage: "Santa Sede (Ciudad Estado Vaticano)",
        });
      case "VC":
        return formatMessage({
          id: "country.VC",
          defaultMessage: "San Vicente y Las Granadinas",
        });
      case "VE":
        return formatMessage({ id: "country.VE", defaultMessage: "Venezuela" });
      case "VG":
        return formatMessage({
          id: "country.VG",
          defaultMessage: "Islas Virgenes Británicas",
        });
      case "VI":
        return formatMessage({
          id: "country.VI",
          defaultMessage: "Islas Virgenes de Los Estados Unidos",
        });
      case "VN":
        return formatMessage({ id: "country.VN", defaultMessage: "Viet Nam" });
      case "VU":
        return formatMessage({ id: "country.VU", defaultMessage: "Vanuatu" });
      case "WF":
        return formatMessage({
          id: "country.WF",
          defaultMessage: "Wallis y Futuna",
        });
      case "WS":
        return formatMessage({ id: "country.WS", defaultMessage: "Samoa" });
      case "YE":
        return formatMessage({ id: "country.YE", defaultMessage: "Yemen" });
      case "YT":
        return formatMessage({ id: "country.YT", defaultMessage: "Mayotte" });
      case "ZA":
        return formatMessage({ id: "country.ZA", defaultMessage: "Sudáfrica" });
      case "ZM":
        return formatMessage({ id: "country.ZM", defaultMessage: "Zambia" });
      case "ZW":
        return formatMessage({ id: "country.ZW", defaultMessage: "Zimbabue" });
    }

    return country;
  };

  const formatLanguage = (language: string) => {
    switch (language) {
      case "Castellano":
        return formatMessage({
          id: "lang.Castellano",
          defaultMessage: "Castellano",
        });
      case "Catalán":
        return formatMessage({ id: "lang.Catalán", defaultMessage: "Catalán" });
      case "Vasco":
        return formatMessage({ id: "lang.Vasco", defaultMessage: "Vasco" });
      case "Gallego":
        return formatMessage({ id: "lang.Gallego", defaultMessage: "Gallego" });
      case "Francés":
        return formatMessage({ id: "lang.Francés", defaultMessage: "Francés" });
      case "Inglés":
        return formatMessage({ id: "lang.Inglés", defaultMessage: "Inglés" });
      case "Italiano":
        return formatMessage({
          id: "lang.Italiano",
          defaultMessage: "Italiano",
        });
      case "Portugués":
        return formatMessage({
          id: "lang.Portugués",
          defaultMessage: "Portugués",
        });
      case "Rumano":
        return formatMessage({ id: "lang.Rumano", defaultMessage: "Rumano" });
      case "Arabe":
        return formatMessage({ id: "lang.Arabe", defaultMessage: "Arabe" });
      case "Ruso":
        return formatMessage({ id: "lang.Ruso", defaultMessage: "Ruso" });
      case "Alemán":
        return formatMessage({ id: "lang.Alemán", defaultMessage: "Alemán" });
      case "Holandés":
        return formatMessage({
          id: "lang.Holandés",
          defaultMessage: "Holandés",
        });
      case "Otros":
        return formatMessage({ id: "lang.Otros", defaultMessage: "Otros" });
    }

    return language;
  };

  const formatEducativeLevel = (level: string) => {
    return level;
  };

  const formatExperience = (experience: string) => {
    if (experience === EMPLOYEE_YEARS_OF_EXPERIENCE_LESS_1_YEAR) {
      return formatMessage({
        id: "Experience.Less1Year",
        defaultMessage: "Menos de 1 año",
      });
    }
    if (experience === EMPLOYEE_YEARS_OF_EXPERIENCE_LESS_2_YEARS) {
      return formatMessage({
        id: "Experience.Less2Years",
        defaultMessage: "Menos de 2 años",
      });
    }
    if (experience === EMPLOYEE_YEARS_OF_EXPERIENCE_LESS_3_YEARS) {
      return formatMessage({
        id: "Experience.Less3YearS",
        defaultMessage: "Menos de 3 años",
      });
    }
    if (experience === EMPLOYEE_YEARS_OF_EXPERIENCE_MORE_3_YEARS) {
      return formatMessage({
        id: "Experience.More3Years",
        defaultMessage: "Más de 3 años",
      });
    }
    if (experience === EMPLOYEE_YEARS_OF_EXPERIENCE_MORE_5_YEARS) {
      return formatMessage({
        id: "Experience.More5Years",
        defaultMessage: "Más de 5 años",
      });
    }
    if (experience === EMPLOYEE_YEARS_OF_EXPERIENCE_MORE_10_YEARS) {
      return formatMessage({
        id: "Experience.More10Years",
        defaultMessage: "Más de 10 años",
      });
    }
    if (experience === EMPLOYEE_YEARS_OF_EXPERIENCE_NO_EXPERIENCE) {
      return formatMessage({
        id: "Experience.NoExperience",
        defaultMessage: "Sin experiencia",
      });
    }

    return experience;
  };

  const formatEmployeeStatus = (status: string) => {
    if (status === EMPLOYEE_STATUS.NOT_VALIDATED) {
      return formatMessage({
        id: "EmployeeStatus.WithoutValidation",
        defaultMessage: "Sin Validar",
      });
    }
    if (status === EMPLOYEE_STATUS.VALIDATED) {
      return formatMessage({
        id: "EmployeeStatus.Validated",
        defaultMessage: "Validado",
      });
    }
    if (status === EMPLOYEE_STATUS.VALIDATED_PLUS) {
      return formatMessage({
        id: "EmployeeStatus.ValidatedPlus",
        defaultMessage: "Validado Plus",
      });
    }
    if (status === EMPLOYEE_STATUS.ACTIVE) {
      return formatMessage({
        id: "EmployeeStatus.Active",
        defaultMessage: "Activo",
      });
    }
    if (status === EMPLOYEE_STATUS.DISCARDED) {
      return formatMessage({
        id: "EmployeeStatus.Discarded",
        defaultMessage: "Descartado",
      });
    }

    return status;
  };

  const formatFamiliarUnit = (unit: string) => {
    if (unit === SERVICE_HOLDER_COMPANION_ALONE) {
      return formatMessage({
        id: "ServiceHolderCompanion.Alone",
        defaultMessage: "Vive solo/a",
      });
    }
    if (unit === SERVICE_HOLDER_COMPANION_WITH_PARTNER) {
      return formatMessage({
        id: "ServiceHolderCompanion.WithPartner",
        defaultMessage: "Vive acompañado/a de su pareja",
      });
    }
    if (unit === SERVICE_HOLDER_COMPANION_WITH_RELATIVE) {
      return formatMessage({
        id: "ServiceHolderCompanion.WithRelative",
        defaultMessage: "Vive acompañado/a de un familiar",
      });
    }
    if (unit === SERVICE_HOLDER_COMPANION_OTHER) {
      return formatMessage({
        id: "ServiceHolderCompanion.Other",
        defaultMessage: "Otros",
      });
    }

    return unit;
  };

  const formatSpeechLevel = (speechLevel: string) => {
    if (speechLevel === SERVICE_HOLDER_EXPRESION_CAN_HAVE_A_CONVERSATION) {
      return formatMessage({
        id: "ServiceHolderSpeech.CanHaveAConversation",
        defaultMessage: "Puede mantener una conversación",
      });
    }
    if (speechLevel === SERVICE_HOLDER_EXPRESION_HAVE_DIFFICULTIES) {
      return formatMessage({
        id: "ServiceHolderSpeech.HaveDifficulties",
        defaultMessage: "Tiene dificultades",
      });
    }
    if (speechLevel === SERVICE_HOLDER_EXPRESION_UNDERSTANDABLE) {
      return formatMessage({
        id: "ServiceHolderSpeech.Understandable",
        defaultMessage: "Se entiende",
      });
    }

    return speechLevel;
  };

  const formatMobilityOption = (mobility: string) => {
    if (mobility === SERVICE_HOLDER_MOBILITY_AUTONOMOUS) {
      return formatMessage({
        id: "ServiceHolderMobility.Autonomous",
        defaultMessage: "Es autónomo/a para moverse",
      });
    }
    if (mobility === SERVICE_HOLDER_MOBILITY_NEEDS_CANE) {
      return formatMessage({
        id: "ServiceHolderMobility.NeedsCane",
        defaultMessage: "Necesita bastón",
      });
    }
    if (mobility === SERVICE_HOLDER_MOBILITY_NEEDS_CRUTCHES) {
      return formatMessage({
        id: "ServiceHolderMobility.NeedsCrutches",
        defaultMessage: "Necesita muletas",
      });
    }
    if (mobility === SERVICE_HOLDER_MOBILITY_NEEDS_HELP) {
      return formatMessage({
        id: "ServiceHolderMobility.NeedsHelp",
        defaultMessage: "Necesita ayuda",
      });
    }
    if (mobility === SERVICE_HOLDER_MOBILITY_NEEDS_SUPERVISION) {
      return formatMessage({
        id: "ServiceHolderMobility.NeedsSupervision",
        defaultMessage: "Necesita supervisión",
      });
    }
    if (mobility === SERVICE_HOLDER_MOBILITY_NEEDS_WALKER) {
      return formatMessage({
        id: "ServiceHolderMobility.NeedsWalker",
        defaultMessage: "Necesita andador",
      });
    }
    if (mobility === SERVICE_HOLDER_MOBILITY_USES_WHEELCHAIR) {
      return formatMessage({
        id: "ServiceHolderMobility.UsesWheelchair",
        defaultMessage: "Va en silla de ruedas",
      });
    }

    return mobility;
  };

  const formatServiceHolderHearingLevel = (level: string) => {
    if (level === SERVICE_HOLDER_HEARING_LEVEL_LOW) {
      return formatMessage({
        id: "ServiceHolderHearing.Low",
        defaultMessage: "Bajo",
      });
    }
    if (level === SERVICE_HOLDER_HEARING_LEVEL_MEDIUM) {
      return formatMessage({
        id: "ServiceHolderHearing.Medium",
        defaultMessage: "Medio",
      });
    }
    if (level === SERVICE_HOLDER_HEARING_LEVEL_GOOD) {
      return formatMessage({
        id: "ServiceHolderHearing.Good",
        defaultMessage: "Bueno",
      });
    }

    return level;
  };

  const formatServiceHolderViewLevel = (level: string) => {
    if (level === SERVICE_HOLDER_VISION_LEVEL_DETERIORATED) {
      return formatMessage({
        id: "ServiceHolderVision.Deteriorated",
        defaultMessage: "Deteriorada",
      });
    }
    if (level === SERVICE_HOLDER_VISION_LEVEL_GLASSES) {
      return formatMessage({
        id: "ServiceHolderVision.Glasses",
        defaultMessage: "Usa Gafas",
      });
    }
    if (level === SERVICE_HOLDER_VISION_LEVEL_GOOD) {
      return formatMessage({
        id: "ServiceHolderVision.Good",
        defaultMessage: "Buena",
      });
    }
    if (level === SERVICE_HOLDER_VISION_LEVEL_VERY_GOOD) {
      return formatMessage({
        id: "ServiceHolderVision.VeryGood",
        defaultMessage: "Muy buena",
      });
    }

    return level;
  };

  const formatServiceHolderSensorialTreatment = (treatment: string) => {
    if (treatment === SERVICE_HOLDER_SENSORIAL_TREATMENT_NO) {
      return formatMessage({
        id: "ServiceHolderSensorial.No",
        defaultMessage: "No",
      });
    }
    if (treatment === SERVICE_HOLDER_SENSORIAL_TREATMENT_YES) {
      return formatMessage({
        id: "ServiceHolderSensorial.Yes",
        defaultMessage: "Si",
      });
    }

    return treatment;
  };

  const formatServiceHolderHeadphones = (option: string) => {
    if (option === SERVICE_HOLDER_HEADPHONES_NO) {
      return formatMessage({
        id: "ServiceHolderHeadphones.No",
        defaultMessage: "No",
      });
    }
    if (option === SERVICE_HOLDER_HEADPHONES_YES) {
      return formatMessage({
        id: "ServiceHolderHeadphones.Yes",
        defaultMessage: "Si",
      });
    }

    return option;
  };

  const formatServiceHolderEmotionalStatus = (status: string) => {
    if (status === SERVICE_HOLDER_EMOTIONAL_STATUS_CALMED) {
      return formatMessage({
        id: "ServiceHolderEmotional.Calmed",
        defaultMessage: "Calmado/a",
      });
    }
    if (status === SERVICE_HOLDER_EMOTIONAL_STATUS_PERSEVERANT) {
      return formatMessage({
        id: "ServiceHolderEmotional.Perseverant",
        defaultMessage: "Perseverante",
      });
    }
    if (status === SERVICE_HOLDER_EMOTIONAL_STATUS_FRIENDLY) {
      return formatMessage({
        id: "ServiceHolderEmotional.Friendly",
        defaultMessage: "Amable",
      });
    }
    if (status === SERVICE_HOLDER_EMOTIONAL_STATUS_INSECURE) {
      return formatMessage({
        id: "ServiceHolderEmotional.Insecure",
        defaultMessage: "Inseguro/a",
      });
    }
    if (status === SERVICE_HOLDER_EMOTIONAL_STATUS_OBSESSIVE) {
      return formatMessage({
        id: "ServiceHolderEmotional.Obsessive",
        defaultMessage: "Obsesivo/a",
      });
    }
    if (status === SERVICE_HOLDER_EMOTIONAL_STATUS_SENSIBLE) {
      return formatMessage({
        id: "ServiceHolderEmotional.Sensible",
        defaultMessage: "Sensible",
      });
    }
    if (status === SERVICE_HOLDER_EMOTIONAL_STATUS_NERVOUS) {
      return formatMessage({
        id: "ServiceHolderEmotional.Nervous",
        defaultMessage: "Nervioso/a",
      });
    }
    if (status === SERVICE_HOLDER_EMOTIONAL_STATUS_SENTIMENTAL) {
      return formatMessage({
        id: "ServiceHolderEmotional.Sentimental",
        defaultMessage: "Sentimental",
      });
    }
    if (status === SERVICE_HOLDER_EMOTIONAL_STATUS_APATHETIC) {
      return formatMessage({
        id: "ServiceHolderEmotional.Apathetic",
        defaultMessage: "Apático/a",
      });
    }
    if (status === SERVICE_HOLDER_EMOTIONAL_STATUS_QUIET) {
      return formatMessage({
        id: "ServiceHolderEmotional.Quiet",
        defaultMessage: "Tranquilo/a",
      });
    }
    if (status === SERVICE_HOLDER_EMOTIONAL_STATUS_AFFABLE) {
      return formatMessage({
        id: "ServiceHolderEmotional.Affable",
        defaultMessage: "Afable",
      });
    }
    if (status === SERVICE_HOLDER_EMOTIONAL_STATUS_CONFORMIST) {
      return formatMessage({
        id: "ServiceHolderEmotional.Conformista",
        defaultMessage: "Conformista",
      });
    }
    if (status === SERVICE_HOLDER_EMOTIONAL_STATUS_LAZY) {
      return formatMessage({
        id: "ServiceHolderEmotional.Lazy",
        defaultMessage: "Perezoso/a",
      });
    }
    if (status === SERVICE_HOLDER_EMOTIONAL_STATUS_MESSY) {
      return formatMessage({
        id: "ServiceHolderEmotional.Messy",
        defaultMessage: "Desordenado/a",
      });
    }
    if (status === SERVICE_HOLDER_EMOTIONAL_STATUS_SUSPICIOUS) {
      return formatMessage({
        id: "ServiceHolderEmotional.Suspicious",
        defaultMessage: "Desconfiado/a",
      });
    }
    if (status === SERVICE_HOLDER_EMOTIONAL_STATUS_STRONG_CHARACTER) {
      return formatMessage({
        id: "ServiceHolderEmotional.StrongCharacter",
        defaultMessage: "Carácter fuerte",
      });
    }
    if (status === SERVICE_HOLDER_EMOTIONAL_STATUS_EXIGENT) {
      return formatMessage({
        id: "ServiceHolderEmotional.Exigent",
        defaultMessage: "Exigente",
      });
    }
    if (status === SERVICE_HOLDER_EMOTIONAL_STATUS_FAMILIAR) {
      return formatMessage({
        id: "ServiceHolderEmotional.Familiar",
        defaultMessage: "Familiar",
      });
    }
    if (status === SERVICE_HOLDER_EMOTIONAL_STATUS_FUNNY) {
      return formatMessage({
        id: "ServiceHolderEmotional.Funny",
        defaultMessage: "Divertido/a",
      });
    }
    if (status === SERVICE_HOLDER_EMOTIONAL_STATUS_HOMELIKE) {
      return formatMessage({
        id: "ServiceHolderEmotional.Homelike",
        defaultMessage: "Hogareño",
      });
    }
    if (status === SERVICE_HOLDER_EMOTIONAL_STATUS_NICE) {
      return formatMessage({
        id: "ServiceHolderEmotional.Nice",
        defaultMessage: "Simpático",
      });
    }

    return status;
  };

  const formatServiceHolderAdopt = (adopt: string) => {
    if (adopt === SERVICE_HOLDER_CARETAKER_ADAPT_ALREADY_HAD) {
      return formatMessage({
        id: "ServiceHolderAdopt.AlreadyHad",
        defaultMessage: "Si, ha tenido cuidador/a antes",
      });
    }
    if (adopt === SERVICE_HOLDER_CARETAKER_ADAPT_NOT_WANT_TO_ACCEPT_IT) {
      return formatMessage({
        id: "ServiceHolderAdopt.NotWantToAccept",
        defaultMessage: "No, no lo acepta",
      });
    }
    if (adopt === SERVICE_HOLDER_CARETAKER_ADAPT_RETICENT_BUT_WILL_TRY) {
      return formatMessage({
        id: "ServiceHolderAdopt.WillTry",
        defaultMessage: "Está reticente pero quieren intentarlo",
      });
    }

    return adopt;
  };

  const formatServiceHolderSleepQuality = (quality: string) => {
    if (quality === SERVICE_HOLDER_SLEEP_QUALITY_VERY_GOOD) {
      return formatMessage({
        id: "ServiceHolderSleep.VeryGood",
        defaultMessage: "Muy Buena",
      });
    }
    if (quality === SERVICE_HOLDER_SLEEP_QUALITY_GOOD) {
      return formatMessage({
        id: "ServiceHolderSleep.Good",
        defaultMessage: "Buena",
      });
    }
    if (quality === SERVICE_HOLDER_SLEEP_QUALITY_MEDIUM) {
      return formatMessage({
        id: "ServiceHolderSleep.Medium",
        defaultMessage: "Regular",
      });
    }
    if (quality === SERVICE_HOLDER_SLEEP_QUALITY_BAD) {
      return formatMessage({
        id: "ServiceHolderSleep.Bad",
        defaultMessage: "Mala",
      });
    }

    return quality;
  };

  const formatServiceHolderToiletLevel = (level: string) => {
    if (level === SERVICE_HOLDER_TOILET_LEVEL_AUTONOMOUS) {
      return formatMessage({
        id: "ServiceHolderToilet.Autonomous",
        defaultMessage: "Autónomo",
      });
    }
    if (level === SERVICE_HOLDER_TOILET_LEVEL_NEEDS_HELP) {
      return formatMessage({
        id: "ServiceHolderToilet.NeedsHelp",
        defaultMessage: "Necesita ayuda",
      });
    }
    if (level === SERVICE_HOLDER_TOILET_LEVEL_WEARS_A_DIAPER) {
      return formatMessage({
        id: "ServiceHolderToilet.WearsADiaper",
        defaultMessage: "Lleva pañal",
      });
    }
    if (level === SERVICE_HOLDER_TOILET_LEVEL_BEDRIDDEN) {
      return formatMessage({
        id: "ServiceHolderToilet.Bedridden",
        defaultMessage: "Higiene encamado",
      });
    }
    if (level === SERVICE_HOLDER_TOILET_LEVEL_OTHERS) {
      return formatMessage({
        id: "ServiceHolderToilet.Others",
        defaultMessage: "Otros",
      });
    }

    return level;
  };

  const formatServiceHolderHomeType = (homeType: string) => {
    if (homeType === SERVICE_HOLDER_HOME_TYPE_FLAT) {
      return formatMessage({
        id: "ServiceHolderHomeType.Flat",
        defaultMessage: "Piso",
      });
    }
    if (homeType === SERVICE_HOLDER_HOME_TYPE_HOUSE) {
      return formatMessage({
        id: "ServiceHolderHomeType.House",
        defaultMessage: "Casa",
      });
    }

    return homeType;
  };

  const formatServiceHolderHomeFeature = (feature: string) => {
    if (feature === SERVICE_HOLDER_HOME_FEATURE_ELEVATOR) {
      return formatMessage({
        id: "ServiceHolderHomeFeature.Elevator",
        defaultMessage: "Edificio con ascensor",
      });
    }
    if (feature === SERVICE_HOLDER_HOME_FEATURE_ADAPTED_BATHROOM) {
      return formatMessage({
        id: "ServiceHolderHomeFeature.AdaptedBathroom",
        defaultMessage: "Baño adaptado",
      });
    }
    if (feature === SERVICE_HOLDER_HOME_FEATURE_CRANE) {
      return formatMessage({
        id: "ServiceHolderHomeFeature.Crane",
        defaultMessage: "Grúa para movilizaciones",
      });
    }
    if (feature === SERVICE_HOLDER_HOME_FEATURE_CAMERAS) {
      return formatMessage({
        id: "ServiceHolderHomeFeature.Cameras",
        defaultMessage: "Cámaras de videovigilancia",
      });
    }
    if (feature === SERVICE_HOLDER_HOME_FEATURE_ARTICULATED_BED) {
      return formatMessage({
        id: "ServiceHolderHomeFeature.ArticulatedBed",
        defaultMessage: "Cama articulada",
      });
    }
    if (feature === SERVICE_HOLDER_HOME_FEATURE_PETS) {
      return formatMessage({
        id: "ServiceHolderHomeFeature.Pets",
        defaultMessage: "Mascotas",
      });
    }
    if (feature === SERVICE_HOLDER_HOME_FEATURE_CLEAN_SERVICE) {
      return formatMessage({
        id: "ServiceHolderHomeFeature.CleanService",
        defaultMessage:
          "Servicios externos de limpieza, soporte ley dependencia",
      });
    }
    if (feature === SERVICE_HOLDER_HOME_FEATURE_TELE_ASSISTANCE) {
      return formatMessage({
        id: "ServiceHolderHomeFeature.Teleasistance",
        defaultMessage: "Teleasistencia en el domicilio",
      });
    }

    return feature;
  };

  const formatServiceSex = (sex: string) => {
    if (sex === SERVICE_PREFERRED_SEX.MAN) {
      return formatMessage({
        id: "ServiceSex.Man",
        defaultMessage: "Hombre",
      });
    }
    if (sex === SERVICE_PREFERRED_SEX.WOMAN) {
      return formatMessage({
        id: "ServiceSex.Woman",
        defaultMessage: "Mujer",
      });
    }
    if (sex === SERVICE_PREFERRED_SEX.INDIFFERENT) {
      return formatMessage({
        id: "ServiceSex.Indifferent",
        defaultMessage: "Indiferente",
      });
    }

    return sex;
  };

  const formatKinship = (kinship: string) => {
    if (kinship === KINSHIP_CHILD) {
      return formatMessage({
        id: "Kinship.Child",
        defaultMessage: "Hijo/a",
      });
    }
    if (kinship === KINSHIP_GRANDSON) {
      return formatMessage({
        id: "Kinship.Grandson",
        defaultMessage: "Nieto/a",
      });
    }
    if (kinship === KINSHIP_NEPHEW) {
      return formatMessage({
        id: "Kinship.Nephew",
        defaultMessage: "Sobrino/a",
      });
    }
    if (kinship === KINSHIP_SON_IN_LAW) {
      return formatMessage({
        id: "Kinship.SonInLay",
        defaultMessage: "Yerno/Nuera",
      });
    }
    if (kinship === KINSHIP_SPOUSE) {
      return formatMessage({
        id: "Kinship.Spouse",
        defaultMessage: "Cónyuge",
      });
    }
    if (kinship === KINSHIP_SISTER_IN_LAW) {
      return formatMessage({
        id: "Kinship.SisterInLaw",
        defaultMessage: "Cuñado/a",
      });
    }
    if (kinship === KINSHIP_BROTHER_SISTER) {
      return formatMessage({
        id: "Kinship.Brother",
        defaultMessage: "Hermano/a",
      });
    }
    if (kinship === KINSHIP_UNCLE) {
      return formatMessage({
        id: "Kinship.Uncle",
        defaultMessage: "Tio/a",
      });
    }
    if (kinship === KINSHIP_FATHER_MOTHER) {
      return formatMessage({
        id: "Kinship.FatherMother",
        defaultMessage: "Padre/madre",
      });
    }
    if (kinship === KINSHIP_USER) {
      return formatMessage({
        id: "Kinship.User",
        defaultMessage: "Usuario/a",
      });
    }
    if (kinship === KINSHIP_CAREGIVER) {
      return formatMessage({
        id: "Kinship.Caregiver",
        defaultMessage: "Cuidador/a",
      });
    }
    if (kinship === KINSHIP_FRIEND) {
      return formatMessage({
        id: "Kinship.Friend",
        defaultMessage: "Amigo/a",
      });
    }
    if (kinship === KINSHIP_OTHER) {
      return formatMessage({
        id: "Kinship.Other",
        defaultMessage: "Otro",
      });
    }

    return kinship;
  };

  const formatServiceHolderUserStatus = (status: string) => {
    if (status === SERVICE_HOLDER_USER_STATUS_ACTIVE) {
      return formatMessage({
        id: "ServiceHolderUserStatus.Active",
        defaultMessage: "Activo",
      });
    }
    if (status === SERVICE_HOLDER_USER_STATUS_ADMISSION_TO_RESIDENCE) {
      return formatMessage({
        id: "ServiceHolderUserStatus.AdmissionToResidence",
        defaultMessage: "Ingreso en residencia",
      });
    }
    if (status === SERVICE_HOLDER_USER_STATUS_DEATH) {
      return formatMessage({
        id: "ServiceHolderUserStatus.Death",
        defaultMessage: "Defunción",
      });
    }
    if (status === SERVICE_HOLDER_USER_STATUS_HOSPITALIZATION) {
      return formatMessage({
        id: "ServiceHolderUserStatus.Hospitalization",
        defaultMessage: "Hospitalización",
      });
    }
    if (status === SERVICE_HOLDER_USER_STATUS_INACTIVE) {
      return formatMessage({
        id: "ServiceHolderUserStatus.Inactive",
        defaultMessage: "Baja",
      });
    }

    return status;
  };

  const formatCommunicationIntentFlowType = (type: string) => {
    if (type === COMMUNICATION_INTENT_TYPE_EMPLOYEE_INTERESTED_IN_WORK) {
      return formatMessage({
        id: "CommunicationIntent.TypeEmployeeInterestedInWork",
        defaultMessage: "¿Buscando trabajo en área?",
      });
    }
    if (type === COMMUNICATION_INTENT_TYPE_EMPLOYEE_INTERESTED_IN_OFFER) {
      return formatMessage({
        id: "CommunicationIntent.TypeEmployeeInterestedInOffer",
        defaultMessage: "Oferta a cuidador/a",
      });
    }

    return type;
  };

  const formatTask = (task: string) => {
    switch (task) {
      case "Administrar insulina/heparina":
        return formatMessage({
          id: "Task.Administrar insulina/heparina",
          defaultMessage: "Administrar insulina/heparina",
        });
      case "Alimentación por sonda":
        return formatMessage({
          id: "Task.Alimentación por sonda",
          defaultMessage: "Alimentación por sonda",
        });
      case "Aspiración endo-traqueal":
        return formatMessage({
          id: "Task.Aspiración endo-traqueal",
          defaultMessage: "Aspiración endo-traqueal",
        });
      case "Baño e higiene del usuario":
        return formatMessage({
          id: "Task.Baño e higiene del usuario",
          defaultMessage: "Baño e higiene del usuario",
        });
      case "Cambio de bolsas (urinaria/colostomia)":
        return formatMessage({
          id: "Task.Cambio de bolsas (urinaria/colostomia)",
          defaultMessage: "Cambio de bolsas (urinaria/colostomia)",
        });
      case "Cambio de pañal":
        return formatMessage({
          id: "Task.Cambio de pañal",
          defaultMessage: "Cambio de pañal",
        });
      case "Control de medicación":
        return formatMessage({
          id: "Task.Control de medicación",
          defaultMessage: "Control de medicación",
        });
      case "Control de nivel de azúcar en sangre":
        return formatMessage({
          id: "Task.Control de nivel de azúcar en sangre",
          defaultMessage: "Control de nivel de azúcar en sangre",
        });
      case "Convivir con animales de compañía":
        return formatMessage({
          id: "Task.Convivir con animales de compañía",
          defaultMessage: "Convivir con animales de compañía",
        });
      case "Cuidar de mas de 1 persona":
        return formatMessage({
          id: "Task.Cuidar de mas de 1 persona",
          defaultMessage: "Cuidar de más de 1 persona",
        });
      case "Curas básicas":
        return formatMessage({
          id: "Task.Curas básicas",
          defaultMessage: "Curas básicas",
        });
      case "Ejercicios cognitivos":
        return formatMessage({
          id: "Task.Ejercicios cognitivos",
          defaultMessage: "Ejercicios cognitivos",
        });
      case "Higiene encamado/a":
        return formatMessage({
          id: "Task.Higiene encamado/a",
          defaultMessage: "Higiene encamado/a",
        });
      case "Limpieza del hogar":
        return formatMessage({
          id: "Task.Limpieza del hogar",
          defaultMessage: "Tareas básicas del hogar",
        });
      case "Manejo de grúa":
        return formatMessage({
          id: "Task.Manejo de grúa",
          defaultMessage: "Manejo de grúa",
        });
      case "Monitorización constantes vitales":
        return formatMessage({
          id: "Task.Monitorización constantes vitales",
          defaultMessage: "Monitorización constantes vitales",
        });
      case "Movilizaciones y transferencias":
        return formatMessage({
          id: "Task.Movilizaciones y transferencias",
          defaultMessage: "Movilizaciones y transferencias",
        });
      case "Nebulizaciones":
        return formatMessage({
          id: "Task.Nebulizaciones",
          defaultMessage: "Nebulizaciones",
        });
      case "Paseos y recados":
        return formatMessage({
          id: "Task.Paseos y recados",
          defaultMessage: "Paseos y recados",
        });
      case "Preparación de comidas":
        return formatMessage({
          id: "Task.Preparación de comidas",
          defaultMessage: "Preparación de comidas",
        });
      case "Programa de ejercicios físicos":
        return formatMessage({
          id: "Task.Programa de ejercicios físicos",
          defaultMessage: "Programa de ejercicios físicos",
        });
      case "Soporte emocional":
        return formatMessage({
          id: "Task.Soporte emocional",
          defaultMessage: "Soporte emocional",
        });
      case "Transporte y conducción":
        return formatMessage({
          id: "Task.Transporte y conducción",
          defaultMessage: "Transporte y conducción",
        });
      case "Soporte en la alimentación":
        return formatMessage({
          id: "Task.Soporte en la alimentación",
          defaultMessage: "Soporte en la alimentación",
        });
      case "Supervisión de las actividades diarias":
        return formatMessage({
          id: "Task.Supervisión de las actividades diarias",
          defaultMessage: "Supervisión de las actividades diarias",
        });
      case "Vigilia nocturna":
        return formatMessage({
          id: "Task.Vigilia nocturna",
          defaultMessage: "Vigilia nocturna",
        });
    }

    return task;
  };

  const formatPathology = (pathology: string) => {
    switch (pathology) {
      case "Anemia":
        return formatMessage({
          id: "Pathology.Anemia",
          defaultMessage: "Anemia",
        });
      case "Artrosis":
        return formatMessage({
          id: "Pathology.Artrosis",
          defaultMessage: "Artrosis",
        });
      case "Autismo":
        return formatMessage({
          id: "Pathology.Autismo",
          defaultMessage: "Autismo",
        });
      case "Trastorno Bipolar":
        return formatMessage({
          id: "Pathology.Trastorno Bipolar",
          defaultMessage: "Trastorno Bipolar",
        });
      case "Cáncer":
        return formatMessage({
          id: "Pathology.Cáncer",
          defaultMessage: "Cáncer",
        });
      case "Cuidados paliativos":
        return formatMessage({
          id: "Pathology.Cuidados paliativos",
          defaultMessage: "Cuidados paliativos",
        });
      case "Déficit de memoria y atención":
        return formatMessage({
          id: "Pathology.Déficit de memoria y atención",
          defaultMessage: "Déficit de memoria y atención",
        });
      case "Enfermedad de Charcot-Marie-Tooth":
        return formatMessage({
          id: "Pathology.Enfermedad de Charcot-Marie-Tooth",
          defaultMessage: "Enfermedad de Charcot-Marie-Tooth",
        });
      case "Paraplegia":
        return formatMessage({
          id: "Pathology.Paraplegia",
          defaultMessage: "Paraplegia",
        });
      case "Cuerpos de Lewy":
        return formatMessage({
          id: "Pathology.Cuerpos de Lewy",
          defaultMessage: "Cuerpos de Lewy",
        });
      case "Patologías intestinales":
        return formatMessage({
          id: "Pathology.Patologías intestinales",
          defaultMessage: "Patologías intestinales",
        });
      case "Síndrome de RETT":
        return formatMessage({
          id: "Pathology.Síndrome de RETT",
          defaultMessage: "Síndrome de RETT",
        });
      case "Depresión":
        return formatMessage({
          id: "Pathology.Depresión",
          defaultMessage: "Depresión",
        });
      case "Diabetes":
        return formatMessage({
          id: "Pathology.Diabetes",
          defaultMessage: "Diabetes",
        });
      case "Diversos tipos de demencia":
        return formatMessage({
          id: "Pathology.Diversos tipos de demencia",
          defaultMessage: "Diversos tipos de demencia",
        });
      case "Enfermedad de Alzheimer":
        return formatMessage({
          id: "Pathology.Enfermedad de Alzheimer",
          defaultMessage: "Enfermedad de Alzheimer",
        });
      case "Enfermedad de EPOC":
        return formatMessage({
          id: "Pathology.Enfermedad de EPOC",
          defaultMessage: "Enfermedad de EPOC",
        });
      case "Enfermedad de Parkinson":
        return formatMessage({
          id: "Pathology.Enfermedad de Parkinson",
          defaultMessage: "Enfermedad de Parkinson",
        });
      case "Enfermedad de TOC":
        return formatMessage({
          id: "Pathology.Enfermedad de TOC",
          defaultMessage: "Enfermedad de TOC",
        });
      case "ELA (Esclerosis múltiple)":
        return formatMessage({
          id: "Pathology.ELA (Esclerosis múltiple)",
          defaultMessage: "ELA (Esclerosis múltiple)",
        });
      case "ELA / EM":
        return formatMessage({
          id: "Pathology.ELA / EM",
          defaultMessage: "ELA / EM",
        });
      case "Epilepsia":
        return formatMessage({
          id: "Pathology.Epilepsia",
          defaultMessage: "Epilepsia",
        });
      case "Esquizofrenia":
        return formatMessage({
          id: "Pathology.Esquizofrenia",
          defaultMessage: "Esquizofrenia",
        });
      case "Fibromialgia":
        return formatMessage({
          id: "Pathology.Fibromialgia",
          defaultMessage: "Fibromialgia",
        });
      case "Fracturas (cadera) y traumatismos":
        return formatMessage({
          id: "Pathology.Fracturas (cadera) y traumatismos",
          defaultMessage: "Fracturas (cadera) y traumatismos",
        });
      case "Hipertensión":
        return formatMessage({
          id: "Pathology.Hipertensión",
          defaultMessage: "Hipertensión",
        });
      case "Ictus":
        return formatMessage({
          id: "Pathology.Ictus",
          defaultMessage: "Ictus",
        });
      case "Inmovilidad":
        return formatMessage({
          id: "Pathology.Inmovilidad",
          defaultMessage: "Inmovilidad",
        });
      case "Insuficiencia renal":
        return formatMessage({
          id: "Pathology.Insuficiencia renal",
          defaultMessage: "Insuficiencia renal",
        });
      case "Insuficiencia respiratoria/cardíaca":
        return formatMessage({
          id: "Pathology.Insuficiencia respiratoria/cardíaca",
          defaultMessage: "Insuficiencia respiratoria/cardíaca",
        });
      case "Movilidad reducida":
        return formatMessage({
          id: "Pathology.Movilidad reducida",
          defaultMessage: "Movilidad reducida",
        });
      case "Obesidad mórbida":
        return formatMessage({
          id: "Pathology.Obesidad mórbida",
          defaultMessage: "Obesidad mórbida",
        });
      case "Osteoporosis":
        return formatMessage({
          id: "Pathology.Osteoporosis",
          defaultMessage: "Osteoporosis",
        });
      case "Otras patologías":
        return formatMessage({
          id: "Pathology.Otras patologías",
          defaultMessage: "Otras patologías",
        });
      case "Otras patologías psiquiátricas":
        return formatMessage({
          id: "Pathology.Otras patologías psiquiátricas",
          defaultMessage: "Otras patologías psiquiátricas",
        });
      case "Patologías sensoriales y auditivas":
        return formatMessage({
          id: "Pathology.Patologías sensoriales y auditivas",
          defaultMessage: "Patologías sensoriales y auditivas",
        });
      case "Problemas de la memoria y la atención":
        return formatMessage({
          id: "Pathology.Problemas de la memoria y la atención",
          defaultMessage: "Problemas de la memoria y la atención",
        });
      case "Sobrepeso":
        return formatMessage({
          id: "Pathology.Sobrepeso",
          defaultMessage: "Sobrepeso",
        });
      case "Sin patologías":
        return formatMessage({
          id: "Pathology.Sin patologías",
          defaultMessage: "Sin patologías",
        });
      case "Síndrome de Down":
        return formatMessage({
          id: "Pathology.Síndrome de Down",
          defaultMessage: "Síndrome de Down",
        });
      case "VIH":
        return formatMessage({
          id: "Pathology.VIH",
          defaultMessage: "VIH",
        });
    }

    return pathology;
  };

  const formatValuationStatus = useCallback(
    (status: TValuationStatus) => {
      switch (status) {
        case "01_Sin Valorar":
          return formatMessage({
            id: "ValuationStatus.Sin Valorar",
            defaultMessage: "Sin Valorar",
          });
        case "02_Descartado":
          return formatMessage({
            id: "ValuationStatus.Descartado",
            defaultMessage: "Descartado",
          });
        case "03_Telefónica":
          return formatMessage({
            id: "ValuationStatus.Telefónica",
            defaultMessage: "Telefónica",
          });
        case "04_CV a cliente":
          return formatMessage({
            id: "ValuationStatus.CV a cliente",
            defaultMessage: "CV a cliente",
          });
        case "04_Presencial":
          return formatMessage({
            id: "ValuationStatus.Presencial",
            defaultMessage: "Presencial",
          });
        case "05_Presencial Cliente":
          return formatMessage({
            id: "ValuationStatus.Presencial Cliente",
            defaultMessage: "Presencial Cliente",
          });
        case "06_Contratado":
          return formatMessage({
            id: "ValuationStatus.Contratado",
            defaultMessage: "Contratado",
          });
      }

      return status;
    },
    [formatMessage]
  );

  return {
    formatCountry,
    formatLanguage,
    formatEducativeLevel,
    formatExperience,
    formatEmployeeStatus,
    formatContractType,
    formatContractOvernightHours,
    formatContractPresencetHours,
    formatContractSalaryType,
    formatContractJobType,
    formatFamiliarUnit,
    formatSpeechLevel,
    formatMobilityOption,
    formatServiceHolderHearingLevel,
    formatServiceHolderViewLevel,
    formatServiceHolderSensorialTreatment,
    formatServiceHolderHeadphones,
    formatServiceHolderEmotionalStatus,
    formatServiceHolderAdopt,
    formatServiceHolderSleepQuality,
    formatServiceHolderToiletLevel,
    formatServiceHolderHomeType,
    formatServiceHolderHomeFeature,
    formatServiceSex,
    formatKinship,
    formatServiceHolderUserStatus,
    formatCommunicationIntentFlowType,
    formatTask,
    formatPathology,
    formatValuationStatus,
    formatTimeSlots
  };
}
