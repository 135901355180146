import React from "react";
import cuideoTheme from "../../containers/themes/defaultTheme";
import styled from "styled-components";
import Alert from "@material-ui/lab/Alert";
import { FormattedMessage } from "react-intl";

const StyledAlertEmployeeNotInCrm = styled(Alert)`
  color: ${cuideoTheme.palette.error.main};
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.25;
  background-color: transparent;
  padding: 0;

  .MuiAlert-icon {
    padding: 0;
    margin-right: 0.25rem;
  }
  .MuiAlert-message {
    padding: 0;
  }
`;

const EmployeeNotInZoho = () => {
  return (
    <StyledAlertEmployeeNotInCrm severity="error">
      <FormattedMessage
        id="EmployeeNotInZoho.NotInZoho"
        defaultMessage="Candidato/a no está en Zoho"
      />
    </StyledAlertEmployeeNotInCrm>
  );
};

export default EmployeeNotInZoho;
