import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Popover,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import cuideoTheme from "../../../containers/themes/defaultTheme";

const StyledButton = styled(Button)<{ active?: any }>`
  text-transform: none;
  font-weight: 400;
  height: 2rem;
  padding: 0 0.875rem;
  font-size: 0.9375rem;
  line-height: 1;

  ${({ active }) => {
    if (active) {
      return `
        font-weight: 600;
        background-color: #cdd5d8;
      `;
    }
  }}
`;

const StyledPaper = styled.div`
  min-width: 15rem;
`;

const StyledActions = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StyledCancelButton = styled(Button)`
  text-transform: none;
  opacity: 0.5;
`;

const StyledApplyButton = styled(Button)`
  text-transform: none;
`;

const StyledOptions = styled.div`
  padding: 0.75rem 1rem;
  max-height: 15rem;
  overflow-y: scroll;

  box-sizing: border-box;

  ::-webkit-scrollbar {
    width: 0.375rem;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 72, 120, 0.25);
    width: 0.5rem;
    border-radius: 0.25rem;
  }
`;

const StyledRadioGroup = styled(RadioGroup)`
  .MuiRadio-root {
    color: ${cuideoTheme.palette.primary.main};
    font-size: 1rem;
  }
  .MuiFormControlLabel-label {
    font-size: 1rem;
    color: ${cuideoTheme.palette.primary.main};
  }
`;

const StyledCheckboxesControl = styled(FormGroup)`
  .MuiIconButton-label {
    color: ${cuideoTheme.palette.primary.main};
    font-size: 1rem;
  }
  .MuiFormControlLabel-label {
    font-size: 1rem;
    color: ${cuideoTheme.palette.primary.main};
  }
`;

export interface FilterButtonProps {
  buttonText?: any;
  multi?: boolean;
  filterName: string;
  options: any;
  initialValue: any;
  handleApply: Function;
  handleReset: Function;
  inUse?: boolean;
  defaultButtonText?: any;
  multiButtonText?: any;
  resetOptions?: any;
}

class FilterButton extends React.Component<FilterButtonProps, any> {
  constructor(props: FilterButtonProps) {
    super(props);

    this.state = {
      anchorEl: null as any,
      open: false,
      value: props.initialValue as any,
    };

    this.handlePopoverClose = this.handlePopoverClose.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  shouldComponentUpdate(nextProps: FilterButtonProps, nextState: any) {
    if (
      nextProps.initialValue !== this.props.initialValue ||
      nextProps.inUse !== this.props.inUse
    ) {
      return true;
    }
    if (
      nextState.open !== this.state.open ||
      nextState.value !== this.state.value
    ) {
      return true;
    }

    return false;
  }

  handlePopoverClose() {
    this.setState({ anchorEl: null, open: false });
  }

  handleClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
      open: !this.state.open,
      value: this.props.initialValue,
    });
  }

  handleChange(event: any) {
    this.setState({ ...this.state, value: event.target.value });
  }

  handleCheckboxChange(value: string) {
    const stateVal =
      typeof this.state.value === "string"
        ? [this.state.value]
        : this.state.value;
    const pos = stateVal.indexOf(value);
    const newValue = [...stateVal];
    if (pos === -1) {
      newValue.push(value);
    } else {
      newValue.splice(pos, 1);
    }

    this.setState({ value: newValue });
  }

  render() {
    const {
      inUse,
      multi,
      options,
      resetOptions,
      filterName,
      initialValue,
      defaultButtonText,
      multiButtonText,
    } = this.props;

    let parsedButtonText = defaultButtonText ? defaultButtonText : "";
    if (multi) {
      if (inUse && !this.state.open) {
        if (initialValue.length === 1) {
          const found = options.find(
            (item: any) => item.value === this.state.value[0]
          );
          if (found) {
            parsedButtonText = found.label;
          }
        }
        if (initialValue.length > 1) {
          parsedButtonText = multiButtonText;
        }
      }
      if (inUse && this.state.open) {
        if (initialValue.length === 1) {
          const found = options.find(
            (item: any) => item.value === initialValue[0]
          );
          if (found) {
            parsedButtonText = found.label;
          }
        }
        if (initialValue.length > 1) {
          parsedButtonText = multiButtonText;
        }
      }
    } else {
      if (this.state.value !== "") {
        if (inUse && !this.state.open && undefined !== initialValue && null !== initialValue && "" !== initialValue && this.state.value !== initialValue) {
          this.setState({ value: initialValue });
        }
        if (inUse && !this.state.open) {
          const found = options.find(
            (item: any) => item.value === this.state.value
          );
          if (found) {
            parsedButtonText = found.label;
          }
        }
        if (inUse && this.state.open) {
          const found = options.find(
            (item: any) => item.value === initialValue
          );
          if (found) {
            parsedButtonText = found.label;
          }
        }
      }
    }

    return (
      <Fragment>
        <StyledButton
          color="primary"
          variant="outlined"
          onClick={this.handleClick}
          active={inUse ? "active" : undefined}
        >
          {parsedButtonText}
        </StyledButton>
        <Popover
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          onClose={this.handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          disableRestoreFocus
          disableScrollLock={true}
        >
          <StyledPaper>
            <StyledOptions>
              {multi ? (
                <StyledCheckboxesControl>
                  {options.map((item: any, index: number) => (
                    <Fragment key={item.value}>
                      {item.value && item.value === "divider" ? (
                        <Divider />
                      ) : (
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={this.state.value.includes(item.value)}
                              onChange={(e: any) => {
                                this.handleCheckboxChange(item.value);
                              }}
                              value={item.value}
                            />
                          }
                          label={item.label}
                        />
                      )}
                    </Fragment>
                  ))}
                </StyledCheckboxesControl>
              ) : (
                <StyledRadioGroup
                  value={this.state.value}
                  onChange={this.handleChange}
                >
                  {options.map((item: any, index: number) => (
                    <Fragment key={item.value}>
                      {item.value && item.value === "divider" ? (
                        <Divider />
                      ) : (
                        <FormControlLabel
                          value={item.value}
                          control={<Radio />}
                          label={item.label}
                        />
                      )}
                    </Fragment>
                  ))}
                </StyledRadioGroup>
              )}
            </StyledOptions>
            <StyledActions>
              <StyledCancelButton
                onClick={() => {
                  this.setState({
                    value: multi ? [...resetOptions] : resetOptions,
                  });
                  this.props.handleReset(filterName, resetOptions);
                  this.handlePopoverClose();
                }}
              >
                <FormattedMessage
                  id="FilterButton.Restablecer"
                  defaultMessage="Restablecer"
                />
              </StyledCancelButton>
              <StyledApplyButton
                color="primary"
                onClick={() => {
                  this.props.handleApply(filterName, this.state.value);
                  this.handlePopoverClose();
                }}
              >
                <FormattedMessage
                  id="FilterButton.Aplicar"
                  defaultMessage="Aplicar"
                />
              </StyledApplyButton>
            </StyledActions>
          </StyledPaper>
        </Popover>
      </Fragment>
    );
  }
}

export default FilterButton;
