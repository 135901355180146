import { apiEmpleo } from "../../util/ApiEmpleo";

export type TUpdateUserFavoriteRequest = {
  id: string;
  note: string | null;
};

export const apiUpdateUserFavoriteCandidate = async (
  payload: TUpdateUserFavoriteRequest
) => {
  return await apiEmpleo.patch(
    `user-favorite-candidates/${payload.id}`,
    {
      note: payload.note,
    },
    {
      headers: {
        "Content-Type": "application/merge-patch+json",
      },
    }
  );
};
