import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import "moment/locale/es";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ContratoTooltip from "../../../components/Contrato/ContratoTooltip";
import cuideoTheme from "../../../containers/themes/defaultTheme";
import { apiEmpleo } from "../../../util/ApiEmpleo";
import {
  CONTRATO_ESTADO_ALTA_PENDIENTE,
  CONTRATO_ESTADO_CV_CLIENTE,
  CONTRATO_ESTADO_EN_ESPERA,
  CONTRATO_ESTADO_PAGA_Y_RESERVA,
  CONTRATO_ESTADO_PRESENCIAL_CLIENTE,
} from "../../../util/defaults/contrato";
import { VALORACION_PRESENCIAL_CLIENTE } from "../../../util/defaults/valoracion";
import IntlMessages from "../../../util/IntlMessages";
import { sortValoracionesByFechaEntrevistaReciente } from "../../../util/Valoracion/util";

const StyledWrapper = styled.div`
  margin: 1rem;
  max-width: 58.75rem;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2rem 0 0.5rem;
  justify-content: space-between;
`;

const StyledTitle = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-size: 1.875rem;
  font-weight: 700;
`;

const StyledContent = styled.div``;

const StyledTable = styled(Table)``;

const StyledTableHead = styled(TableHead)``;

const StyledTableRow = styled(TableRow)``;

const StyledBodyRow = styled(TableRow)<{ pastdate?: string }>`
  ${({ pastdate }) => {
    if (pastdate) {
      return `
      background-color: #fff3ba;
`;
    } else {
      return `
      background-color: #fff;
      `;
    }
  }}
`;

const StyledHeadCell = styled(TableCell)`
  background-color: rgba(0, 72, 120, 0.1);
  color: ${cuideoTheme.palette.primary.light};
  font-size: 0.75rem;
  line-height: normal;
  font-weight: 700;
`;

const StyledBodyCell = styled(TableCell)`
  font-size: 0.875rem;
  color: ${cuideoTheme.palette.primary.main};

  .MuiCuideo-ContratoTooltip-label {
    text-align: left;
  }
`;

const StyledTableBody = styled(TableBody)``;

const StyledPagination = styled(Pagination)`
  margin: 1rem 0 0;
  justify-content: center;
`;

const InterviewsTab = (props: any) => {
  const { candidato } = props;
  const [valoraciones, setValoraciones] = useState([] as any);
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);

  const getContratos = async (params: any) => {
    params.itemsPerPage = 10;
    params.estadoEnProceso = [
      // VALORACION_CONTRATADO,
      VALORACION_PRESENCIAL_CLIENTE,
    ];
    params["contrato.estado"] = [
      CONTRATO_ESTADO_ALTA_PENDIENTE,
      CONTRATO_ESTADO_EN_ESPERA,
      CONTRATO_ESTADO_PAGA_Y_RESERVA,
      CONTRATO_ESTADO_CV_CLIENTE,
      CONTRATO_ESTADO_PRESENCIAL_CLIENTE,
    ];
    let res = await apiEmpleo.get(`candidatos/${candidato.id}/valoraciones`, {
      params,
    });
    setValoraciones(
      res["hydra:member"].sort(sortValoracionesByFechaEntrevistaReciente)
    );
    setTotalItems(res["hydra:totalItems"]);
  };

  useEffect(() => {
    getContratos({ page });
    // eslint-disable-next-line
  }, [page, candidato]);

  const handlePageChange = (event: any, value: any) => setPage(value);

  return (
    <StyledWrapper>
      <StyledHeader>
        <StyledTitle>
          <IntlMessages id="Entrevistas" />
        </StyledTitle>
      </StyledHeader>
      <StyledContent>
        <StyledTable>
          <StyledTableHead>
            <StyledTableRow>
              <StyledHeadCell>
                <IntlMessages id="Día" />
              </StyledHeadCell>
              <StyledHeadCell>
                <IntlMessages id="Hora" />
              </StyledHeadCell>
              <StyledHeadCell>
                <IntlMessages id="Lugar Entrevista" />
              </StyledHeadCell>
              <StyledHeadCell>
                <IntlMessages id="Contrato" />
              </StyledHeadCell>
              <StyledHeadCell>
                <IntlMessages id="Owner" />
              </StyledHeadCell>
            </StyledTableRow>
          </StyledTableHead>
          <StyledTableBody>
            {valoraciones.map((valoracion: any) => {
              return (
                <StyledBodyRow
                  key={valoracion.id}
                  pastdate={
                    valoracion.Fecha_y_hora_entrevista &&
                    moment().isAfter(
                      moment(valoracion.Fecha_y_hora_entrevista),
                      "day"
                    )
                      ? "pastdate"
                      : undefined
                  }
                >
                  <StyledBodyCell>
                    {valoracion.Fecha_y_hora_entrevista
                      ? moment(valoracion.Fecha_y_hora_entrevista).format(
                          "DD/MM/YYYY"
                        )
                      : undefined}
                  </StyledBodyCell>
                  <StyledBodyCell>
                    {valoracion.Fecha_y_hora_entrevista
                      ? moment(valoracion.Fecha_y_hora_entrevista).format(
                          "HH:mm"
                        )
                      : undefined}
                  </StyledBodyCell>
                  <StyledBodyCell>
                    {valoracion.Lugar_de_la_entrevista}
                  </StyledBodyCell>
                  <StyledBodyCell>
                    <ContratoTooltip contrato={valoracion.contrato} />
                  </StyledBodyCell>
                  <StyledBodyCell>
                    {valoracion.contrato.ownerName}
                  </StyledBodyCell>
                  {/* <StyledBodyCell>
                    {moment(valoracion.createdAt).format("DD/MM/YYYY HH:mm")}
                  </StyledBodyCell>
                  <StyledBodyCell>
                    <ContratoTooltip contrato={valoracion.contrato} />
                  </StyledBodyCell>
                  <StyledBodyCell>{valoracion.contrato.Estado}</StyledBodyCell>
                  <StyledBodyCell>
                    {valoracion.contrato.Nombre_del_cliente}
                  </StyledBodyCell>
                  <StyledBodyCell>
                    {valoracion.contrato.Horario_Servicio}
                  </StyledBodyCell>
                  <StyledBodyCell>
                    {valoracion.fecha_inicio_contrato &&
                      valoracion.fecha_fin_contrato === undefined && (
                        <Fragment>
                          Desde{" "}
                          {moment(valoracion.fecha_inicio_contrato).format(
                            "DD/MM/YYYY"
                          )}
                        </Fragment>
                      )}
                    {valoracion.fecha_inicio_contrato &&
                      valoracion.fecha_fin_contrato && (
                        <Fragment>
                          De{" "}
                          {moment(valoracion.fecha_inicio_contrato).format(
                            "DD/MM/YYYY"
                          )}{" "}
                          a{" "}
                          {moment(valoracion.fecha_fin_contrato).format(
                            "DD/MM/YYYY"
                          )}
                        </Fragment>
                      )}
                  </StyledBodyCell>
                  <StyledBodyCell>
                    {valoracion.contrato.ownerName}
                  </StyledBodyCell> */}
                </StyledBodyRow>
              );
            })}
          </StyledTableBody>
        </StyledTable>
        {totalItems > 0 && (
          <StyledPagination
            count={Math.floor(totalItems / 10)}
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        )}
      </StyledContent>
    </StyledWrapper>
  );
};

export default InterviewsTab;
