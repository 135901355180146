import { useMutation } from "react-query";
import { apiMarkMineAsDelivered } from "../../../../../api/notification/apiMarkMineAsDelivered";
import { MutationConfig, queryClient } from "../../../../../lib/react-query";
import { badgesKeys } from "../../badges";
import { notificationKeys } from "./notification.query-keys";

type TUseMarkNotificationsAsDelivered = {
  config?: MutationConfig<typeof apiMarkMineAsDelivered>;
};

export const useMarkNotificationsAsDelivered = ({
  config,
}: TUseMarkNotificationsAsDelivered = {}) => {
  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries(notificationKeys.all);
    },
    onSettled: () => {
      queryClient.invalidateQueries(notificationKeys.all);
      queryClient.invalidateQueries(badgesKeys.mine);
    },
    ...config,
    mutationFn: apiMarkMineAsDelivered,
  });
};
