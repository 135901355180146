import { StylesProvider } from "@material-ui/styles";
import { ConnectedRouter } from "connected-react-router";
import React from "react";
import { QueryClientProvider } from "react-query";
// import logo from "./logo.svg";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import App from "./containers/App/App";
import { queryClient } from "./lib/react-query";
import { history, store } from "./store";
import ScrollToTop from "./util/ScrollToTop";

const MainApp: React.FC = (props) => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <QueryClientProvider client={queryClient}>
          <ScrollToTop>
            <StylesProvider injectFirst>
              <Route
                render={({ location }) => (
                  <Switch location={location}>
                    <Route path="/" component={App} />
                  </Switch>
                )}
              />
            </StylesProvider>
          </ScrollToTop>
        </QueryClientProvider>
      </ConnectedRouter>
    </Provider>
  );
};

export default MainApp;
