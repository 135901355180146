import { useEffect } from "react";
import { setBackground } from "../actions";
import { store } from "../store";

export default function useBackground(background: "cuideo" | "white") {
  const storeState = store.getState();
  const stateBackground = storeState.layout.background;

  useEffect(() => {
    if (stateBackground !== background) {
      store.dispatch(setBackground(background));
    }
  }, [stateBackground, background]);
}
