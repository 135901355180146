import { useEffect, useMemo, useState } from "react";
import {
  TGetContractsCollectionItem,
  TGetContractsNumbersItemResponse,
} from "../../../../../api/contract/contract.types";
import {
  TContractPartialWithNumbers,
  useContractsNumbers,
} from "../../contract";
import _ from "lodash";

type TStateValue = Record<string, TGetContractsNumbersItemResponse>;

export const useContractsWithNumbers = (
  contracts: TGetContractsCollectionItem[]
) => {
  const [state, setState] = useState<TStateValue>({});

  const contractsNumbersQuery = useContractsNumbers({
    config: {
      enabled: contracts.length !== 0,
    },
    params: {
      id: contracts
        .filter((contract) => !state[contract.id])
        .map((contract) => contract.id),
      itemsPerPage: 500,
    },
  });

  useEffect(
    function storeDataIntoState() {
      if (!contractsNumbersQuery.data) {
        return;
      }

      const newState: TStateValue = {
        ...state,
      };

      contractsNumbersQuery.data.forEach((cn) => {
        newState[cn.id] = cn;
      });

      if (!_.isEqual(newState, state)) {
        setState(newState);
      }
    },
    [contractsNumbersQuery.data, state]
  );

  const contractsWithNumbers = useMemo(() => {
    const newContracts: TContractPartialWithNumbers[] = [];

    contracts.forEach((contract) => {
      const newContract: TContractPartialWithNumbers = {
        ...contract,
        ...(state[contract.id] ?? undefined),
        _uiMutation: null,
      };

      newContracts.push(newContract);
    });

    return newContracts;
  }, [contracts, state]);

  return {
    contractsWithNumbers,
    isLoading: contractsNumbersQuery.isLoading,
  };
};
