import {
  Modal,
  Backdrop,
  Fade,
  IconButton,
  Box,
  Grid,
} from "@material-ui/core";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import CloseIcon from "@material-ui/icons/Close";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import styled from "styled-components";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import RotateRightIcon from "@material-ui/icons/RotateRight";
// @ts-ignore
import { Document, Page } from "react-pdf/dist/umd/entry.webpack";

const StyledBackdropContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 2rem 4rem;

  .CMuiProtectedImgBackdropClose {
    position: absolute;
    z-index: 1;
    color: #fff;
    right: 1.5rem;
    top: 1.5rem;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    .MuiSvgIcon-root {
      width: 2rem;
      height: 2rem;
    }
  }

  .CMuiProtectedImgBackdropContainer {
    height: calc(100% - 1rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .CMuiProtectedImgBackdropImgWrapper {
      flex-grow: 1;
      width: 100%;
      position: relative;

      .CMuiProtectedImgFullImg {
        display: block;
      }
    }
    .CMuiProtectedImgBackdropImgButtons {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      background-color: rgba(0, 0, 0, 0.25);
      border-radius: 0.5rem;
      border: 1px solid rgba(255, 255, 255, 0.5);
      padding: 0.75rem 1rem;
    }

    .CMuiProtectedImgBackdropButton {
      color: #fff;
      border-radius: 50%;
      padding: 0.75rem;
      border: 1px solid #ddd;
      margin: 0 1rem;

      .MuiSvgIcon-root {
        width: 2rem;
        height: 2rem;
      }
    }
  }
`;

const StyledFullImg = styled.img``;

interface IModalViewerProps {
  open: boolean;
  type: string;
  src: any;
  onClose: () => void;
}

const ModalViewer = (props: IModalViewerProps) => {
  const { open, type, src, onClose } = props;
  const fullContainerRef = useRef<HTMLDivElement>(null);
  const fullRef = useRef<HTMLDivElement>(null);

  const [state, setState] = useState({
    centered: false,
    rotate: 0,
    scale: 1,
    fullWidth: 100,
    fullHeight: 100,
    fullContainerWidth: 100,
    fullContainerHeight: 100,
    fullDragging: false,
    x: 0,
    y: 0,
    fullContainerAR: 1,
    fullAR: 1,
  });

  const getSizes = useCallback(() => {
    let fullBounding = null as null | { width: number; height: number };
    let fullContainerBounding = null as null | {
      width: number;
      height: number;
    };
    if (fullRef && fullRef.current) {
      fullBounding = {
        width: fullRef.current.getBoundingClientRect().width,
        height: fullRef.current.getBoundingClientRect().height,
      };
    }
    if (fullContainerRef && fullContainerRef.current) {
      fullContainerBounding = {
        width: fullContainerRef.current.getBoundingClientRect().width,
        height: fullContainerRef.current.getBoundingClientRect().height,
      };
    }
    const newData = {
      fullWidth:
        fullBounding && fullBounding.width
          ? fullBounding.width
          : state.fullWidth,
      fullHeight:
        fullBounding && fullBounding.height
          ? fullBounding.height
          : state.fullHeight,
      fullContainerWidth:
        fullContainerBounding && fullContainerBounding.width
          ? fullContainerBounding.width
          : state.fullContainerWidth,
      fullContainerHeight:
        fullContainerBounding && fullContainerBounding.height
          ? fullContainerBounding.height
          : state.fullContainerHeight,
      fullContainerAR: fullContainerBounding
        ? fullContainerBounding.width / fullContainerBounding.height
        : state.fullContainerAR,
      fullAR: fullBounding
        ? fullBounding.width / fullBounding.height
        : state.fullAR,
    };
    return newData;
  }, [
    state.fullAR,
    state.fullContainerAR,
    state.fullContainerHeight,
    state.fullContainerWidth,
    state.fullHeight,
    state.fullWidth,
  ]);

  const calculateNewPosition = useCallback(
    (
      containerWidth: number,
      containerHeight: number,
      containerAR: number,
      contentWidth: number,
      contentHeight: number,
      contentAR: number
    ) => {
      const newX = containerWidth / 2 - contentWidth / 2;
      const newY = containerHeight / 2 - contentHeight / 2;
      let newScale = null as null | number;
      if (contentAR > containerAR) {
        if (contentWidth > containerWidth) {
          newScale = containerWidth / contentWidth;
        }
      } else {
        if (contentHeight > containerHeight) {
          newScale = containerHeight / contentHeight;
        }
      }
      return {
        x: newX,
        y: newY,
        scale: !!newScale ? newScale : state.scale,
      };
    },
    [state.scale]
  );

  const updateSizes = useCallback(() => {
    const sizes = getSizes();
    setState((prevState) => ({
      ...prevState,
      ...sizes,
    }));
  }, [getSizes]);

  useLayoutEffect(() => {
    window.addEventListener("resize", updateSizes);

    return () => window.removeEventListener("resize", updateSizes);
  }, [updateSizes]);

  useEffect(() => {
    if (open && !state.centered) {
      setTimeout(() => {
        const sizes = getSizes();
        const newPosition = calculateNewPosition(
          sizes.fullContainerWidth,
          sizes.fullContainerHeight,
          sizes.fullContainerAR,
          sizes.fullWidth,
          sizes.fullHeight,
          sizes.fullAR
        );

        setState((prevState) => ({
          ...prevState,
          ...sizes,
          ...newPosition,
          centered: true,
        }));
      }, 250);
    }
  }, [open, state.centered, getSizes, calculateNewPosition]);

  const handleRotateLeft = (e: React.MouseEvent) => {
    e.stopPropagation();
    setState({
      ...state,
      rotate: state.rotate - 90,
    });
  };

  const handleRotateRight = (e: React.MouseEvent) => {
    e.stopPropagation();
    setState({
      ...state,
      rotate: state.rotate + 90,
    });
  };

  const handleFullContentClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const handleMouseDownCapture = (e: any) => {
    setState((prevState) => ({ ...prevState, fullDragging: true }));
  };

  const handleMouseUpCapture = (e: any) => {
    setState((prevState) => ({ ...prevState, fullDragging: false }));
  };

  const handleMouseMoveCapture = (e: React.MouseEvent<HTMLDivElement>) => {
    if (state.fullDragging) {
      const x = e.movementX;
      const y = e.movementY;
      setState((prevState) => ({
        ...prevState,
        x: prevState.x + x,
        y: prevState.y + y,
      }));
    }
  };

  const handleZoom = (e: React.WheelEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (e.deltaY > 0) {
      setState((prevState) => ({
        ...prevState,
        scale: prevState.scale * 0.95,
      }));
    }
    if (e.deltaY < 0) {
      setState((prevState) => ({
        ...prevState,
        scale: prevState.scale * 1.05,
      }));
    }
  };

  const handleZoomIn = (e: React.MouseEvent) => {
    e.stopPropagation();
    setState((prevState) => ({
      ...prevState,
      scale: prevState.scale * 1.25,
    }));
  };

  const handleZoomOut = (e: React.MouseEvent) => {
    e.stopPropagation();
    setState((prevState) => ({
      ...prevState,
      scale: prevState.scale * 0.75,
    }));
  };

  return (
    <Modal open={open} onClose={onClose} BackdropComponent={Backdrop}>
      <Fade appear in={open} timeout={250}>
        <StyledBackdropContainer className="CMuiProtectedImageBackdropContainerWrapper">
          <IconButton
            onClick={onClose}
            className="CMuiProtectedImgBackdropClose"
          >
            <CloseIcon />
          </IconButton>
          <Box className="CMuiProtectedImgBackdropContainer">
            <div
              className="CMuiProtectedImgBackdropImgWrapper"
              ref={fullContainerRef}
            >
              <div
                ref={fullRef}
                onWheel={handleZoom}
                onClick={handleFullContentClick}
                onMouseDownCapture={handleMouseDownCapture}
                onMouseUpCapture={handleMouseUpCapture}
                onMouseMoveCapture={handleMouseMoveCapture}
                style={{
                  width: "fit-content",
                  height: "fit-content",
                  transform: `scale(${state.scale}) rotate(${state.rotate}deg)`,
                  top: `${state.y}px`,
                  left: `${state.x}px`,
                  position: "absolute",
                  transition: "transform 0.25s ease-in-out",
                  opacity: state.centered ? 1 : 0,
                }}
              >
                {type.match(/^image/g) ? (
                  <StyledFullImg
                    draggable={false}
                    className="CMuiProtectedImgFullImg"
                    src={src}
                    alt="ALT"
                  />
                ) : (
                  <Document file={src}>
                    <Page pageNumber={1} width={970} />
                  </Document>
                )}
              </div>
            </div>
            <Box className="CMuiProtectedImgBackdropImgButtons">
              <Grid
                container
                spacing={8}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  <IconButton
                    className="CMuiProtectedImgBackdropButton"
                    onClick={handleZoomOut}
                  >
                    <ZoomOutIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    className="CMuiProtectedImgBackdropButton"
                    onClick={handleRotateLeft}
                  >
                    <RotateLeftIcon />
                  </IconButton>
                  <IconButton
                    className="CMuiProtectedImgBackdropButton"
                    onClick={handleRotateRight}
                  >
                    <RotateRightIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    className="CMuiProtectedImgBackdropButton"
                    onClick={handleZoomIn}
                  >
                    <ZoomInIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </StyledBackdropContainer>
      </Fade>
    </Modal>
  );
};

export default ModalViewer;
