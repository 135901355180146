import { useSelector } from "react-redux";
import useCountryPathologies from "../../../../../../components/Candidato/useCountryPathologies";
import useEmployeeDegreesOptions from "../../../../../../components/CandidatoCard/useEmployeeDegreesOptions";

export default function useCoordinatorFileESFormOptions() {
  const {
    paisesOptions,
    educacionOptions,
    idiomasOptions,
    tareasOptions,
    experienceYearsOptions,
    provinciasOptions,
    timeSlotOptions
  } = useSelector((state: any) => state.commonData);

  const degreeOptions = useEmployeeDegreesOptions("es");
  const pathologyOptions = useCountryPathologies("es");

  return {
    countryOptions: paisesOptions,
    languageOptions: idiomasOptions,
    educationOptions: educacionOptions,
    degreeOptions,
    taskOptions: tareasOptions,
    experienceYearsOptions,
    provinceOptions: provinciasOptions,
    pathologyOptions,
    timeSlotOptions
  };
}

export type TCoordinatorFileESOptions = ReturnType<
  typeof useCoordinatorFileESFormOptions
>;
