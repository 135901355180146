import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { applyOfferSuccess, unApplyOfferSuccess } from "../actions/Auth";
import { getOffersSuccess, getOfferSuccess } from "../actions/Empleo";
import {
  APPLY_OFFER,
  GET_OFFER,
  GET_OFFERS,
  UNAPPLY_OFFER,
} from "../constants/ActionTypes";
import { apiEmpleo } from "../util/ApiEmpleo";

export function* fetchAvailableOffers({ payload }: { payload: any }) {
  try {
    // @ts-ignore
    const res = yield call(apiEmpleo.get, "contratos", {
      params: {
        publicar: "Si",
        itemsPerPage: 1000,
        partial: 1,
      },
    });
    const offers = res["hydra:member"] ? res["hydra:member"] : [];
    if (offers) {
      yield put(getOffersSuccess(offers, payload.user));
    }
  } catch (error) {
    console.log("Error fetching offers", error);
  }
}

function* fetchAvailableOffer({
  payload,
}: {
  payload: { offerId: string; user: any };
}) {
  try {
    // @ts-ignore
    const offer = yield call(apiEmpleo.get, "contratos/" + payload.offerId);
    if (offer) {
      yield put(getOfferSuccess(offer, payload.user));
    }
  } catch (error) {
    console.log("Error fetching offers");
  }
}

function* userApplyOffer({ payload }: { payload: any }) {
  const offer = payload;
  const offerId = offer.id;
  try {
    // @ts-ignore
    const valoracion = yield call(apiEmpleo.post, "valoraciones", {
      contrato: "/api/contratos/" + offerId,
    });
    if (valoracion) {
      // Bien tenida valoración
      yield put(
        applyOfferSuccess({
          valoracion,
          offer,
        })
      );
      // Refresca el "me" para tener las valoraciones actualizadas
      // yield put(getLoggedUserData());

      // Register event in GA
      if ((window as any).gtag && process.env.REACT_APP_ANALYTICS_ID) {
        (window as any).gtag("event", "Inscripcion", {
          event_label: offer.Name ? offer.Name : undefined,
          event_category: "Cuidador",
        });
      }
    }
  } catch (error) {
    console.log("Error", error);
  }
}

function* userUnApplyOffer({ payload }: { payload: any }) {
  const { valoracionId } = payload;
  try {
    // @ts-ignore
    yield call(apiEmpleo.delete, "valoraciones/" + valoracionId);
    // Si va mal salta excepcion
    yield put(unApplyOfferSuccess(valoracionId));
  } catch (error) {
    console.log("Error", error);
  }
}

export function* getAvailableOffers() {
  yield takeEvery(GET_OFFERS as any, fetchAvailableOffers);
}

export function* getAvailableOffer() {
  yield takeEvery(GET_OFFER as any, fetchAvailableOffer);
}

export function* applyOffer() {
  yield takeEvery(APPLY_OFFER as any, userApplyOffer);
}

export function* unApplyOffer() {
  yield takeEvery(UNAPPLY_OFFER as any, userUnApplyOffer);
}

export default function* rootSaga() {
  yield all([
    fork(getAvailableOffers),
    fork(getAvailableOffer),
    fork(applyOffer),
    fork(unApplyOffer),
  ]);
}
