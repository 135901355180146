import { useEffect, useState } from "react";
import { pathologiesOptionsList } from "../../util/defaults/patologias";

function useCountryPathologies(businessCountry: string) {
  const [pathologyOptions, setPathologyOptions] = useState(
    pathologiesOptionsList(businessCountry)
  );

  useEffect(() => {
    setPathologyOptions(pathologiesOptionsList(businessCountry));
  }, [businessCountry]);

  return pathologyOptions;
}

export default useCountryPathologies;
