import { Box, Typography } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const StyledErrorWrapper = styled(Box)`
  .CMuiWarningBox {
    background-color: rgb(255, 244, 229);
    color: rgb(102, 60, 0);
    padding: 1rem;
    max-width: 16rem;
    margin: 20vh auto;

    .MuiSvgIcon-root {
      display: block;
      margin: 0 auto 1rem;
      width: 2rem;
      height: 2rem;
    }

    .CMuiWarningBoxMessage {
      font-size: 1.25rem;
      line-height: 1.35;
      text-align: center;
    }
  }
`;

const NoContractsFound = () => {
  return (
    <StyledErrorWrapper className="CMuiWarningBoxWrapper">
      <Box className="CMuiWarningBox">
        <ErrorOutlineIcon />
        <Typography className="CMuiWarningBoxMessage">
          <FormattedMessage
            id="ContractsPage.NotFound"
            defaultMessage="No se han encontrado contratos con los criterios de búsqueda establecidos. Pruebe con otros criterios."
          />
        </Typography>
      </Box>
    </StyledErrorWrapper>
  );
};

export default NoContractsFound;
