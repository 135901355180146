import { TableCell, TableSortLabel } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import {
  TChangeOrderFn,
  TRecruiterManagerRouteFilters,
} from "../../routes/RecruiterManagerRoute/hooks/useRecruiterManagerRouteFilters";
import { TContractsTableColumns } from "./ContractsTable.types";

interface IContractsTableHeadProps {
  columns: TContractsTableColumns;
  filters: TRecruiterManagerRouteFilters;
  onChangeTableOrder: TChangeOrderFn;
}

const ContractsTableHead: React.FC<IContractsTableHeadProps> = (props) => {
  const { columns, filters, onChangeTableOrder } = props;

  const createSortHandler =
    (payload: any) => (event: React.MouseEvent<unknown>) => {
      onChangeTableOrder({
        column: payload.column,
        order:
          // @ts-ignore
          filters[payload.column] === "asc"
            ? "desc"
            : // @ts-ignore
            filters[payload.column] === "desc"
            ? "asc"
            : "asc",
      });
    };

  return (
    <>
      {columns.map((column) => {
        if (column === "Name") {
          return (
            <TableCell
              key="ContractsTableHead__contract"
              component="div"
              variant="head"
              style={{ minWidth: "7rem", maxWidth: "7rem", flex: "0 0 0" }}
            >
              <TableSortLabel disabled>
                <FormattedMessage
                  id="ContractsTable.Contrato"
                  defaultMessage="Contrato"
                />
              </TableSortLabel>
            </TableCell>
          );
        }

        if (column === "Estado") {
          return (
            <TableCell
              key="ContractsTableHead__status"
              component="div"
              variant="head"
              style={{ minWidth: "7rem", maxWidth: "7rem", flex: "0 0 0" }}
            >
              <TableSortLabel disabled>
                <FormattedMessage
                  id="ContractsTable.Estado"
                  defaultMessage="Estado"
                />
              </TableSortLabel>
            </TableCell>
          );
        }

        if (column === "pipeline") {
          return (
            <TableCell
              key="ContractsTableHead__pipeline"
              component="div"
              variant="head"
              style={{ minWidth: "5rem", maxWidth: "5rem", flex: "0 0 0" }}
            >
              <TableSortLabel disabled>
                <FormattedMessage
                  id="ContractsTable.Pipeline"
                  defaultMessage="Pipeline"
                />
              </TableSortLabel>
            </TableCell>
          );
        }

        if (column === "createdAt") {
          return (
            <TableCell
              key="ContractsTableHead__createdAt"
              component="div"
              variant="head"
              style={{ minWidth: "6rem", maxWidth: "6rem", flex: "0 0 0" }}
            >
              <TableSortLabel
                active={!!filters["order[createdAt]"]}
                direction={
                  !!filters["order[createdAt]"]
                    ? filters["order[createdAt]"]
                    : undefined
                }
                onClick={createSortHandler({ column: "order[createdAt]" })}
              >
                <FormattedMessage
                  id="ContractsTable.Fecha creación"
                  defaultMessage="Fecha creación"
                />
              </TableSortLabel>
            </TableCell>
          );
        }

        if (column === "Fecha_objetivo") {
          return (
            <TableCell
              key="ContractsTableHead__Fecha_objetivo"
              component="div"
              variant="head"
              style={{ minWidth: "6rem", maxWidth: "6rem", flex: "0 0 0" }}
            >
              <TableSortLabel
                active={!!filters["order[fechaObjetivo]"]}
                direction={
                  !!filters["order[fechaObjetivo]"]
                    ? filters["order[fechaObjetivo]"]
                    : undefined
                }
                onClick={createSortHandler({ column: "order[fechaObjetivo]" })}
              >
                <FormattedMessage
                  id="ContractsTable.Fecha objetivo"
                  defaultMessage="Fecha objetivo"
                />
              </TableSortLabel>
            </TableCell>
          );
        }

        if (column === "Fecha_cv_cliente") {
          return (
            <TableCell
              key="ContractsTableHead__Fecha_cv_cliente"
              component="div"
              variant="head"
              style={{ minWidth: "6rem", maxWidth: "6rem", flex: "0 0 0" }}
            >
              <TableSortLabel
                active={!!filters["order[cvDate]"]}
                direction={
                  !!filters["order[cvDate]"]
                    ? filters["order[cvDate]"]
                    : undefined
                }
                onClick={createSortHandler({ column: "order[cvDate]" })}
              >
                <FormattedMessage
                  id="ContractsTable.Fecha CV cli"
                  defaultMessage="Fecha CV cli"
                />
              </TableSortLabel>
            </TableCell>
          );
        }

        if (column === "Provincia_servicio") {
          return (
            <TableCell
              key="ContractsTableHead__Provincia"
              component="div"
              variant="head"
              style={{ minWidth: "7rem", maxWidth: "7rem", flex: "0 0 0" }}
            >
              <TableSortLabel disabled>
                <FormattedMessage
                  id="ContractsTable.Provincia"
                  defaultMessage="Provincia"
                />
              </TableSortLabel>
            </TableCell>
          );
        }

        if (column === "Serivcio.ownerName") {
          return (
            <TableCell
              key="ContractsTableHead__ServiceOwner"
              component="div"
              variant="head"
              style={{ minWidth: "10rem", flex: "1 0 10rem" }}
            >
              <TableSortLabel disabled>
                <FormattedMessage
                  id="ContractsTable.Gestora"
                  defaultMessage="Gestora"
                />
              </TableSortLabel>
            </TableCell>
          );
        }

        if (column === "reason") {
          return (
            <TableCell
              key="ContractsTableHead__reason"
              component="div"
              variant="head"
              style={{ width: "6rem" }}
            >
              <TableSortLabel disabled>
                <FormattedMessage
                  id="ContractsTable.Motivo selección"
                  defaultMessage="Motivo selección"
                />
              </TableSortLabel>
            </TableCell>
          );
        }

        if (column === "Servicio.ReferenteB2B.Name") {
          return (
            <TableCell
              key="ContractsTableHead__ServicioReferenteB2BName"
              component="div"
              variant="head"
              style={{ minWidth: "10rem", flex: "1 0 10rem" }}
            >
              <TableSortLabel disabled>
                <FormattedMessage
                  id="ContractsTable.B2B"
                  defaultMessage="B2B"
                />
              </TableSortLabel>
            </TableCell>
          );
        }

        if (column === "Squad") {
          return (
            <TableCell
              key="ContractsTableHead__Squad"
              component="div"
              variant="head"
              style={{ minWidth: "7rem", maxWidth: "7rem", flex: "0 0 0" }}
            >
              <TableSortLabel
                active={!!filters["order[squad]"]}
                direction={
                  !!filters["order[squad]"]
                    ? filters["order[squad]"]
                    : undefined
                }
                onClick={createSortHandler({ column: "order[squad]" })}
              >
                <FormattedMessage
                  id="ContractsTable.Squad"
                  defaultMessage="Squad"
                />
              </TableSortLabel>
            </TableCell>
          );
        }

        return <></>;
      })}
    </>
  );
};

export default ContractsTableHead;
