import {
  Card,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import StarIcon from "@material-ui/icons/Star";
import React, { Fragment, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import styled from "styled-components";
import { hideInscribeteHightlight } from "../../actions/Auth";
import cuideoTheme from "../../containers/themes/defaultTheme";

const StyledCard = styled(Card)`
  background-color: #a8f1e1;
  padding: 2rem 1rem;
  margin-bottom: 1rem;
  position: relative;

  @media all and (min-width: ${cuideoTheme.breakpoints.values.sm}px) {
    margin-bottom: 3rem;
  }
`;

const StyledStarIcon = styled(StarIcon)`
  color: #ebc200;
  display: block;
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    width: 3rem;
    height: 3rem;
    margin: 0 auto 0 auto;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    width: 3rem;
    height: 3rem;
    margin: 0 0.5rem 0 auto;
  }
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }
`;

const StyledTypography = styled(Typography)`
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    text-align: center;
    line-height: 1.25;
    font-size: 1.125rem;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 1.25rem;
    line-height: 1.2;
  }
`;

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    background-color: #a8f1e1;
    padding: 2rem 1rem;
    margin-bottom: 1rem;
    position: relative;
  }
`;

const StyledDialogContent = styled(DialogContent)``;

const InscribeteOfertas = (props: any) => {
  const { width, open, hideInscribeteHightlight } = props;

  const [showHighlighted, setShowHighlighted] = useState(open);

  const handleClose = (e: React.MouseEvent) => {
    hideInscribeteHightlight();
    setShowHighlighted(false);
  };

  if (width < cuideoTheme.breakpoints.values.md) {
    return (
      <StyledDialog open={showHighlighted}>
        <StyledIconButton onClick={handleClose}>
          <CloseIcon color="primary" />
        </StyledIconButton>
        <StyledDialogContent>
          <Grid container spacing={8} alignItems="center">
            <Grid item xs={12} md={1}>
              <StyledStarIcon />
            </Grid>
            <Grid item xs={12} md={9}>
              <StyledTypography color="primary">
                <b>
                  <FormattedMessage
                    id="Highlight.ApplyToOffer"
                    defaultMessage="Inscríbete a nuevas ofertas"
                  />
                </b>
              </StyledTypography>
              <StyledTypography color="primary">
                <FormattedMessage
                  id="Highlight.OffersSorted"
                  defaultMessage="Hemos ordenado las ofertas de trabajo según las preferencias que nos has indicado al registrarte. A qué esperas, ¡aplica hoy mismo y consigue el trabajo que deseas!"
                />
              </StyledTypography>
            </Grid>
          </Grid>
        </StyledDialogContent>
      </StyledDialog>
    );
  } else {
    return (
      <Fragment>
        {showHighlighted && (
          <StyledCard elevation={0}>
            <img
              height="1"
              width="1"
              src="//conv.indeed.com/pagead/conv/1755491191511783/?script=0"
              alt=""
            />
            <StyledIconButton onClick={handleClose}>
              <CloseIcon color="primary" />
            </StyledIconButton>
            <Grid container spacing={8} alignItems="center">
              <Grid item xs={12} md={1}>
                <StyledStarIcon />
              </Grid>
              <Grid item xs={12} md={9}>
                <StyledTypography color="primary">
                  <b>
                    <FormattedMessage
                      id="Highlight.ApplyToOffer"
                      defaultMessage="Inscríbete a nuevas ofertas"
                    />
                  </b>
                </StyledTypography>
                <StyledTypography color="primary">
                  <FormattedMessage
                    id="Highlight.OffersSorted"
                    defaultMessage="Hemos ordenado las ofertas de trabajo según las preferencias que nos has indicado al registrarte. A qué esperas, ¡aplica hoy mismo y consigue el trabajo que deseas!"
                  />
                </StyledTypography>
              </Grid>
            </Grid>
          </StyledCard>
        )}
      </Fragment>
    );
  }
};

const mapStateToProps = ({ layout }: { layout: { width: number } }) => {
  const { width } = layout;

  return { width };
};

export default connect(mapStateToProps, { hideInscribeteHightlight })(
  InscribeteOfertas
);
