import { Tooltip } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import SettingsIcon from "@material-ui/icons/Settings";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { setPaddingLeft, userSignOut } from "../../actions";
import { useBadges } from "../../apps/affinity/features/badges";
import {
  UserNotificationsButton,
  UserWaNotificationsButton,
} from "../../apps/affinity/features/notification";
import CuidadoresPng from "../../assets/images/cuidadores.png";
import TramitesSvgIcon from "../../assets/images/sidebar/Tramites.svg";
import {
  ROUTE_AFFINITY_SIGN_IN,
  ROUTE_CANDIDATES,
  ROUTE_CONTRACTS,
  // ROUTE_SERVICES,
  ROUTE_SETTINGS_PAGE,
} from "../../constants/routes";
import { useAuthUser } from "../../reducers/hooks/useAuthUser";
import useWindowWidth from "../../util/useWindowWidth";
import CommunicationsButtonWithBadge from "./components/CommunicationsButtonWithBadge/CommunicationsButtonWithBadge";
// import ServicesButtonWithBadge from "./components/ServicesButtonWithBadge/ServicesButtonWithBadge";
import UnratedServicesButtonWithBadge from "./components/UnratedServicesButtonWithBadge/UnratedServiceButtonWithBadge";
import { LoggedUserButton } from "./LoggedUserButton";
import { StyledIcon, StyledIconButton, StyledIconWrapper } from "./SideNavSC";

const WIDTH_BREAKPOINT = 1200;
const REFETCH_BADGES_IN_MS = 10000;

const StyledDrawer = styled(({ isvisible, ...props }) => <Drawer {...props} />)`
  .MuiDrawer-paper {
    background-color: #002740;
    color: #ffffff;
    padding: 0.25rem 0.25rem;
    z-index: 1500;
    will-change: transform;
    ${(props) => {
      if (props.isvisible) {
        return `
        transform: translateX(0);
        opacity:1;
        transition: all 1000ms ease-in-out;
        `;
      }
      return `
      transform: translateX(-4rem);
      opacity:0;
      `;
    }}
  }
`;

const AppList = styled.div`
  display: flex;
  flex-direction: column;
`;

const SideNavTools = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: 1rem;
`;

const StyledDrawerContent = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;
const StyledDrawerAppContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledUserButtonWrapper = styled.div`
  width: fit-content;
  margin: 0.5rem auto;
`;

interface ISideNavProps {}

const SideNav: React.FC<ISideNavProps> = (props) => {
  const { authUser, permissions } = useAuthUser();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const width = useWindowWidth();

  const history = useHistory();

  const badgesQuery = useBadges({
    config: {
      enabled: !!authUser,
      refetchInterval: REFETCH_BADGES_IN_MS,
    },
  });

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let timeout: null | ReturnType<typeof setTimeout> = null;
    let paddingLeft = 0;
    if (width >= WIDTH_BREAKPOINT) {
      if (authUser) {
        paddingLeft = 4;
        if (!visible) {
          timeout = setTimeout(() => {
            setVisible(true);
          }, 100);
        }
      }
    }
    dispatch(setPaddingLeft(paddingLeft));

    return () => {
      !!timeout && clearTimeout(timeout);
    };
  }, [authUser, dispatch, visible, width]);

  if (width < WIDTH_BREAKPOINT) {
    return null;
  }

  const hasSideNav = authUser && authUser.userRoles.includes("ROLE_STAFF");

  if (!hasSideNav) {
    return null;
  }

  // const showServices =
  //   authUser.userRoles.includes(ROLE_STAFF) && !userIsRecruiter(authUser);

  return (
    <StyledDrawer
      variant="permanent"
      isvisible={visible}
      key="SideNavStyledDrawer"
    >
      <StyledDrawerContent>
        <StyledDrawerAppContent>
          <AppList>
            <StyledUserButtonWrapper>
              <LoggedUserButton
                userFullName={authUser.userFullName}
                userEmail={authUser.userEmail}
                userInitials={authUser.userInitials}
                options={[
                  {
                    label: formatMessage({
                      id: "SideNav.Preferencias",
                      defaultMessage: "Preferencias",
                    }),
                    description: formatMessage({
                      id: "SideNav.Configura tus preferencias",
                      defaultMessage: "Configura tus preferencias",
                    }),
                    icon: <SettingsIcon />,
                    onClick: () => {
                      history.push(ROUTE_SETTINGS_PAGE);
                    },
                  },
                  {
                    label: formatMessage({
                      id: "SideNav.Desconectar",
                      defaultMessage: "Desconectar",
                    }),
                    description: formatMessage({
                      id: "SideNav.Cierra sesión y desconecta",
                      defaultMessage: "Cierra sesión y desconecta",
                    }),
                    icon: <ExitToAppIcon />,
                    onClick: () => {
                      dispatch(userSignOut());
                      history.push(ROUTE_AFFINITY_SIGN_IN);
                    },
                  },
                ]}
              />
            </StyledUserButtonWrapper>
            {permissions.accessToRecruitManager && (
              <NavLink to="/recruitment-manager">
                <Tooltip
                  title={formatMessage({
                    id: "SideNav.Recruitment Manager",
                    defaultMessage: "Recruitment Manager",
                  })}
                  placement="right"
                >
                  <StyledIconButton
                    className={
                      history.location.pathname.indexOf(
                        "/recruitment-manager"
                      ) === 0
                        ? "CMui-active"
                        : undefined
                    }
                    aria-label={formatMessage({
                      id: "SideNav.Recruitment Manager",
                      defaultMessage: "Recruitment Manager",
                    })}
                  >
                    <StyledIconWrapper>
                      <PersonPinIcon />
                    </StyledIconWrapper>
                  </StyledIconButton>
                </Tooltip>
              </NavLink>
            )}
            {permissions.accessToPromoteManager && (
              <NavLink to="/unrated-services">
                <Tooltip
                  title={formatMessage({
                    id: "SideNav.Servicios sin valorar",
                    defaultMessage: "Servicios sin valorar",
                  })}
                  placement="right"
                >
                  <div>
                    <UnratedServicesButtonWithBadge
                      isActive={
                        history.location.pathname.indexOf(
                          "/unrated-services"
                        ) === 0
                      }
                    />
                  </div>
                </Tooltip>
              </NavLink>
            )}
            {permissions.accessToContractsRoute && (
              <NavLink to={ROUTE_CONTRACTS}>
                <Tooltip
                  title={formatMessage({
                    id: "SideNav.Contratos",
                    defaultMessage: "Contratos",
                  })}
                  placement="right"
                >
                  <StyledIconButton
                    className={
                      history.location.pathname.indexOf("/contrato") === 0
                        ? "CMui-active"
                        : undefined
                    }
                    aria-label={formatMessage({
                      id: "SideNav.Contratos",
                      defaultMessage: "Contratos",
                    })}
                  >
                    <StyledIcon
                      src={TramitesSvgIcon}
                      alt={formatMessage({
                        id: "SideNav.Contratos",
                        defaultMessage: "Contratos",
                      })}
                      title={formatMessage({
                        id: "SideNav.Contratos",
                        defaultMessage: "Contratos",
                      })}
                    />
                  </StyledIconButton>
                </Tooltip>
              </NavLink>
            )}
            {/* {permissions.accessToServicesRoute && (
              <NavLink to={ROUTE_SERVICES}>
                <Tooltip
                  title={formatMessage({
                    id: "SideNav.Servicios",
                    defaultMessage: "Servicios",
                  })}
                  placement="right"
                >
                  <div>
                    <ServicesButtonWithBadge
                      isActive={
                        history.location.pathname.indexOf("/service") === 0
                      }
                    />
                  </div>
                </Tooltip>
              </NavLink>
            )} */}
            {permissions.accessToCandidatesRoute && (
              <NavLink to={ROUTE_CANDIDATES}>
                <Tooltip
                  title={formatMessage({
                    id: "SideNav.Candidatos",
                    defaultMessage: "Candidatos",
                  })}
                  placement="right"
                >
                  <StyledIconButton
                    className={
                      history.location.pathname.indexOf("/candidatos") === 0
                        ? "CMui-active"
                        : undefined
                    }
                    aria-label={formatMessage({
                      id: "SideNav.Candidatos",
                      defaultMessage: "Candidatos",
                    })}
                  >
                    <StyledIcon
                      src={CuidadoresPng}
                      alt={formatMessage({
                        id: "SideNav.Candidatos",
                        defaultMessage: "Candidatos",
                      })}
                      title={formatMessage({
                        id: "SideNav.Candidatos",
                        defaultMessage: "Candidatos",
                      })}
                    />
                  </StyledIconButton>
                </Tooltip>
              </NavLink>
            )}
            {permissions.accessToConversationsRoute && (
              <NavLink to="/conversations">
                <Tooltip
                  title={formatMessage({
                    id: "SideNav.Comunicaciones",
                    defaultMessage: "Comunicaciones",
                  })}
                  placement="right"
                >
                  <div>
                    <CommunicationsButtonWithBadge
                      isActive={
                        history.location.pathname.indexOf("/conversations") ===
                        0
                      }
                    />
                  </div>
                </Tooltip>
              </NavLink>
            )}
          </AppList>
          <SideNavTools>
            <UserWaNotificationsButton
              numUndeliveredNotifications={
                badgesQuery.data?.numUndeliveredWaNotifications ?? 0
              }
            />
            <UserNotificationsButton
              numUndeliveredNotifications={
                badgesQuery.data?.numUndeliveredNotifications ?? 0
              }
            />
          </SideNavTools>
        </StyledDrawerAppContent>
      </StyledDrawerContent>
    </StyledDrawer>
  );
};

export default SideNav;
