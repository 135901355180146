import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { EMPLOYEE_WORK_REGIME } from "../../../../api/employee/employee.types";
import { TSelectOption } from "../../../../apps/affinity/pages/ServicePage/components/ServiceValuation/types";
import CuideoCheckbox from "../../../../components/CuideoFields/CuideoCheckbox";
import useFormatters from "../../../../components/FormattedFields/useFormatters";
import HelperDialog from "../../../../components/HelperDialog/HelperDialog";
import LeerInfoLegal from "../../../../components/Legal/LeerInfoLegal";
import {
  TIPO_EXTERNA_ENTRE_SEMANA,
  TIPO_EXTERNA_FIN_DE_SEMANA,
  TIPO_INTERNA_ENTRE_SEMANA,
  TIPO_INTERNA_FIN_DE_SEMANA,
} from "../../../../util/defaults/contrato";
import { cuideoTheme } from "../../../themes/defaultTheme";
import {
  StyledRegistroSection,
  StyledRegistroTitle,
} from "../../EmployeeSignUpSC";
import useSignUpStep2FormContext from "./hooks/useSignUpStep2FormContext";
import {
  StyledFormControl,
  StyledFormControlLabel,
} from "./SignUpStep2InnerForm";
import WorkRegimeHelp from "./WorkRegimeHelp_es_ES";
import { getIn } from "formik";

const StyledLegalSection = styled.div`
  margin-bottom: 3rem;
`;

const StyledFormControlLabelForCheckboxes = styled(FormControlLabel)`
  border: 1px solid #c2d1da;
  border-radius: ${cuideoTheme.shape.borderRadius}px;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  height: ${(cuideoTheme.overrides as any).MuiInputBase.root.height};
  background-color: #ffffff;
  color: ${cuideoTheme.palette.text.secondary};
  white-space: pre-line;

  &.CMui-error {
    background-color: #fef5f7;
    border-color: #f64a69;
  }

  .MuiTypography-body1 {
    font-size: 0.9rem;
    line-height: 1.1;
  }
  .MuiSvgIcon-root {
    height: 1.25rem;
    width: 1.25rem;
  }
  ${({ checked }) => {
    if (checked) {
      return `
      color: ${cuideoTheme.palette.primary.main};
      background-color: #dcf1f2;
      border: 1px solid ${cuideoTheme.palette.secondary.main};
      & .MuiTypography-body1 {
        font-weight: 700;
      }
    `;
    }
  }}

  .MuiTypography-body1 {
    font-size: 1rem;
    line-height: 1.2;
  }

  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    height: 4rem;
  }
`;

export const StyledHelpIconButton = styled(IconButton)`
  .MuiSvgIcon-root {
    width: 1.125rem;
    height: 1.125rem;
  }
`;

const StyledFormDescription = styled(FormHelperText)`
  line-height: 1.35;
  color: ${cuideoTheme.palette.primary.main};
  margin: 0.375rem 0;
`;

const StyledErrorText = styled(Typography)`
  font-size: 0.8rem;
  margin-bottom: 1rem;
  color: #f64a69;
`;

interface ISignUpStep2AvailabilityInnerFormProps {
  tasksOptions: TSelectOption[];
  noBot: boolean;
  toggleNoBot: () => void;
  // acceptTerms: boolean;
  // toggleAcceptTerms: () => void;
}

const SignUpStep2AvailabilityInnerForm = (
  props: ISignUpStep2AvailabilityInnerFormProps
) => {
  const { noBot, toggleNoBot } = props;
  const { formatMessage } = useIntl();
  const { formatContractJobType } = useFormatters();
  const { formikContext, timeSlotOptions, formActions } = useSignUpStep2FormContext();
  const [regimeHelpOpen, setRegimeHelpOpen] = useState(false);
  const [showTimeSlotsOptions, setShowTimeSlotsOptions] = useState(false);

  const handleWorkRegimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formActions.toggleWorkRegime(e.target.name);
  };

  const handleRegimeHelpClick = () => {
    setRegimeHelpOpen(true);
  };

  const handleRegimeHelpClose = () => {
    setRegimeHelpOpen(false);
  };

  const handleToggleAcceptTerms = () => {
    formikContext.setFieldValue(
      "acceptTerms",
      !formikContext.values.acceptTerms
    );
  };

  const handleTimeSlotChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formActions.toggleTimeSlot(e.target.name);
  };

  const isAvailabilityTouched =
    getIn(formikContext.touched, "Externa_entre_semana") ||
    getIn(formikContext.touched, "Externa_fin_de_semana") ||
    getIn(formikContext.touched, "Interna_entre_semana") ||
    getIn(formikContext.touched, "Interna_fin_de_semana");
  const hasAvailabilityError =
    isAvailabilityTouched &&
    !!getIn(formikContext.errors, "atLeastOneAvailability");

  useEffect(() => {
    setShowTimeSlotsOptions(getIn(formikContext.values, "Externa_entre_semana") || getIn(formikContext.values, "Externa_fin_de_semana"));
  }, [formikContext]);

  const hasTimeSlotError =
    !!getIn(formikContext.errors, "atLeastOneTimeSlotIfExternalAvailability");

  return (
    <>
      <StyledRegistroSection>
        <StyledRegistroTitle>
          <FormattedMessage
            id="SignUp.Disponibilidad"
            defaultMessage="Disponibilidad"
          />
        </StyledRegistroTitle>
        <StyledFormControl error={hasAvailabilityError}>
          <StyledFormControlLabel style={{ marginBottom: "12px" }}>
            <FormattedMessage
              id="SignUp.¿Cuál es tu disponibilidad?"
              defaultMessage="¿Cuál es tu disponibilidad?"
            />{" "}
            *
          </StyledFormControlLabel>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <StyledFormControlLabelForCheckboxes
                control={
                  <Checkbox
                    color="primary"
                    name="Externa_entre_semana"
                    onChange={formikContext.handleChange}
                    onBlur={formikContext.handleBlur}
                  />
                }
                label={formatContractJobType(TIPO_EXTERNA_ENTRE_SEMANA)}
                className={hasAvailabilityError ? "CMui-error" : undefined}
                style={{ height: "3rem" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledFormControlLabelForCheckboxes
                control={
                  <Checkbox
                    color="primary"
                    name="Externa_fin_de_semana"
                    onChange={formikContext.handleChange}
                    onBlur={formikContext.handleBlur}
                  />
                }
                label={formatContractJobType(TIPO_EXTERNA_FIN_DE_SEMANA)}
                className={hasAvailabilityError ? "CMui-error" : undefined}
                style={{ height: "3rem" }}
              />
            </Grid>
            {showTimeSlotsOptions && (
              <Grid item xs={12} style={{ marginTop: "12px", marginBottom: "12px" }}>
                <StyledFormControl error={hasTimeSlotError}>
                  <StyledFormControlLabel style={{ marginBottom: "12px" }}>
                    <FormattedMessage
                      id="SignUp.¿En qué franja horaria quieres trabajar?"
                      defaultMessage="¿En qué franja horaria quieres trabajar?  "
                    />{" "}
                    *
                  </StyledFormControlLabel>
                  <Grid container spacing={4}>
                    {timeSlotOptions.map((item: any) => {
                      return (
                        <Grid item xs={12} md={4}>
                          <StyledFormControlLabelForCheckboxes
                            control={
                              <Checkbox
                                checked={
                                  formikContext.values.Franja_horaria.includes(item.value)
                                }
                                color="primary"
                                name={item.value}
                                onChange={handleTimeSlotChange}
                              />
                            }
                            label={item.breaklineLabel}
                            className={hasTimeSlotError ? "CMui-error" : undefined}
                            style={{ height: "4rem" }}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </StyledFormControl>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <StyledFormControlLabelForCheckboxes
                control={
                  <Checkbox
                    color="primary"
                    name="Interna_entre_semana"
                    onChange={formikContext.handleChange}
                    onBlur={formikContext.handleBlur}
                  />
                }
                label={formatContractJobType(TIPO_INTERNA_ENTRE_SEMANA)}
                className={hasAvailabilityError ? "CMui-error" : undefined}
                style={{ height: "3rem" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledFormControlLabelForCheckboxes
                control={
                  <Checkbox
                    color="primary"
                    name="Interna_fin_de_semana"
                    onChange={formikContext.handleChange}
                    onBlur={formikContext.handleBlur}
                  />
                }
                label={formatContractJobType(TIPO_INTERNA_FIN_DE_SEMANA)}
                className={hasAvailabilityError ? "CMui-error" : undefined}
                style={{ height: "3rem" }}
              />
            </Grid>
          </Grid>
        </StyledFormControl>
        <StyledFormControl
          error={
            !!formikContext.errors.R_gimen_de_trabajo &&
            !!formikContext.touched.R_gimen_de_trabajo
          }
        >
          <StyledFormControlLabel>
            <FormattedMessage
              id="SignUp.¿En qué regimen te interesa trabajar?"
              defaultMessage="¿En qué regimen te interesa trabajar?"
            />{" "}
            *
            <StyledHelpIconButton
              onClick={handleRegimeHelpClick}
              color="primary"
            >
              <HelpOutlineIcon />
            </StyledHelpIconButton>
          </StyledFormControlLabel>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <StyledFormControlLabelForCheckboxes
                checked={
                  formikContext.values.R_gimen_de_trabajo.indexOf(
                    EMPLOYEE_WORK_REGIME.REEH
                  ) !== -1
                }
                control={
                  <Checkbox
                    checked={
                      formikContext.values.R_gimen_de_trabajo.indexOf(
                        EMPLOYEE_WORK_REGIME.REEH
                      ) !== -1
                    }
                    color="primary"
                    name={EMPLOYEE_WORK_REGIME.REEH}
                    onChange={handleWorkRegimeChange}
                  />
                }
                label={formatMessage({
                  id: "SignUp.Régimen Especial de Empleados del Hogar (REEH)",
                  defaultMessage:
                    "Régimen Especial de Empleados del Hogar (REEH)",
                })}
                className={
                  !!formikContext.errors.R_gimen_de_trabajo &&
                  !!formikContext.touched.R_gimen_de_trabajo
                    ? "CMui-error"
                    : undefined
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledFormControlLabelForCheckboxes
                color="primary"
                checked={
                  formikContext.values.R_gimen_de_trabajo.indexOf(
                    EMPLOYEE_WORK_REGIME.SAD
                  ) !== -1
                }
                control={
                  <Checkbox
                    checked={
                      formikContext.values.R_gimen_de_trabajo.indexOf(
                        EMPLOYEE_WORK_REGIME.SAD
                      ) !== -1
                    }
                    color="primary"
                    name={EMPLOYEE_WORK_REGIME.SAD}
                    onChange={handleWorkRegimeChange}
                  />
                }
                label={formatMessage({
                  id: "SignUp.Régimen General (SAD)",
                  defaultMessage: "Régimen General (SAD)",
                })}
                className={
                  !!formikContext.errors.R_gimen_de_trabajo &&
                  !!formikContext.touched.R_gimen_de_trabajo
                    ? "CMui-error"
                    : undefined
                }
              />
            </Grid>
          </Grid>
          <StyledFormDescription>
            <FormattedMessage
              id="SignUp.Debes elegir al menos una opción, también puedes seleccionar ambas opciones. Nos intentaremos ajustar al máximo a tus preferencias."
              defaultMessage="Debes elegir al menos una opción, también puedes seleccionar ambas opciones. Nos intentaremos ajustar al máximo a tus preferencias."
            />
          </StyledFormDescription>
        </StyledFormControl>
      </StyledRegistroSection>
      <CuideoCheckbox
        label={
          <FormattedMessage
            id="SignUp.Quiero recibir ofertas por Whatsapp"
            defaultMessage="Quiero recibir ofertas por Whatsapp."
          />
        }
        checked={noBot}
        opaque={true}
        onChange={toggleNoBot}
      />

      <StyledLegalSection>
        <CuideoCheckbox
          label={
            <FormattedMessage
              id="SignUp.Acepto las condiciones de uso, la {ppLink} y el tratamiento de mis datos. En plenitud de mis facultades físicas y mentales declaro no tener antecedentes penales ni policiales. Acepto recibir comunicaciones por parte de Cuideo a través de la aplicación de Whatsapp."
              defaultMessage="Acepto las condiciones de uso, la {ppLink} y el tratamiento de mis datos. En plenitud de mis facultades físicas y mentales declaro no tener antecedentes penales ni policiales. Acepto recibir comunicaciones por parte de Cuideo a través de la aplicación de Whatsapp."
              values={{
                ppLink: (
                  <a
                    target="_blank"
                    href="https://cuideo.com/politica-de-privacidad/"
                    rel="noreferrer"
                  >
                    <FormattedMessage
                      id="SignUp.política de privacidad"
                      defaultMessage="política de privacidad"
                    />
                  </a>
                ),
              }}
            />
          }
          checked={formikContext.values.acceptTerms}
          opaque={true}
          onChange={handleToggleAcceptTerms}
        />
        {!!formikContext.errors.acceptTerms &&
          !!formikContext.touched.acceptTerms && (
            <StyledErrorText>
              {formikContext.errors.acceptTerms}
            </StyledErrorText>
          )}
        <LeerInfoLegal />
        <HelperDialog
          open={regimeHelpOpen}
          onClose={handleRegimeHelpClose}
          text={<WorkRegimeHelp />}
          title={
            <FormattedMessage
              id="SignUp2.Régimen de trabajo"
              defaultMessage="Régimen de trabajo"
            />
          }
          okLabel={
            <FormattedMessage id="SignUp2.Aceptar" defaultMessage="Aceptar" />
          }
        />
      </StyledLegalSection>
    </>
  );
};

export default SignUpStep2AvailabilityInnerForm;
