import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { USER_ROLES } from "../../../../../../api/security";
import { TStaffUserResponse } from "../../../../../../api/user/apiListStaffUsers";
import SelectStyledWrapper from "../../../promotersManager/routes/UnratedServicesPage/components/SelectStyledWrapper";
import PersonalCareImg from "../../../../../../assets/images/personal-care.svg";

const StyledDialog = styled(Dialog)`
  .MuiDialogTitle-root {
    .MuiTypography-h6 {
      font-family: "Poppins", Helvetica, Arial, sans-serif;
      font-size: 1.125rem;
    }
  }
`;

const StyledText = styled(Typography)`
  font-size: 0.9375rem;
  line-height: 1.35;
  margin-bottom: 0.5rem;
`;

const StyledContentWithImage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1rem 0 1rem;
`;

const StyledImage = styled.img`
  height: 5rem;
  width: auto;
`;

const StyledRight = styled.div``;

const StyledConfirmButton = styled(Button)`
  width: 7.25rem;
  height: 2.5rem;

  .MuiCircularProgress-root {
    color: #999;
  }
`;

interface IUserRoleSelectorProps {
  user: TStaffUserResponse;
  onRoleChange: (role: string, ownerId: string) => Promise<void>;
}

const UserRoleSelector: React.FC<IUserRoleSelectorProps> = (props) => {
  const { user, onRoleChange } = props;

  const [state, setState] = useState({
    dialogOpen: false,
    newRole: null as string | null,
    newRoleName: null as string | null,
    inProcess: false,
  });
  const { formatMessage } = useIntl();

  const options = [
    {
      value: USER_ROLES.ROLE_COORDINATION,
      label: formatMessage({
        id: "UserRoleSelector.Coordinador/a",
        defaultMessage: "Coordinador/a",
      }),
    },
    {
      value: USER_ROLES.ROLE_ES_PROMOTER,
      label: formatMessage({
        id: "UserRoleSelector.Gestor/a",
        defaultMessage: "Gestor/a",
      }),
    },
    {
      value: USER_ROLES.ROLE_ES_RECRUITER,
      label: formatMessage({
        id: "UserRoleSelector.Reclutador/a",
        defaultMessage: "Reclutador/a",
      }),
    },
  ];

  const currentRole = user.roles.includes(USER_ROLES.ROLE_ES_PROMOTER)
    ? USER_ROLES.ROLE_ES_PROMOTER
    : user.roles.includes(USER_ROLES.ROLE_ES_RECRUITER)
    ? USER_ROLES.ROLE_ES_RECRUITER
    : user.roles.includes(USER_ROLES.ROLE_COORDINATION)
    ? USER_ROLES.ROLE_COORDINATION
    : null;
  const currentRoleOption = currentRole
    ? options.find((option) => option.value === currentRole)
    : null;

  const handleGroupChange = (option: any) => {
    let role: string = option.value;
    if (option.value === currentRole) {
      return;
    }

    setState((prevState) => ({
      ...prevState,
      dialogOpen: true,
      newRole: role,
      newRoleName: option.label,
    }));
  };

  const handleConfirmGroupChange = async () => {
    if (null === state.newRole) {
      return;
    }
    setState((prevState) => ({ ...prevState, inProcess: true }));
    let newRole = state.newRole;
    await onRoleChange(newRole, user.id);
    setState((prevState) => ({
      ...prevState,
      inProcess: false,
      dialogOpen: false,
    }));
  };

  const handleCancelDialog = () => {
    setState((prevState) => ({
      ...prevState,
      dialogOpen: false,
    }));
  };

  return (
    <>
      <SelectStyledWrapper
        options={options}
        value={currentRoleOption}
        onChange={handleGroupChange}
        classNamePrefix="CMuiUserRoleSelect"
        placeholder={formatMessage({
          id: "UserRoleSelector.Asignar rol...",
          defaultMessage: "Asignar rol...",
        })}
      />
      <StyledDialog open={state.dialogOpen}>
        <DialogTitle>
          <FormattedMessage
            id="UserRoleSelector.Confirmar cambio de grupo"
            defaultMessage="Confirmar cambio de grupo"
          />
        </DialogTitle>
        <StyledContentWithImage>
          <StyledImage src={PersonalCareImg} />
          <StyledRight>
            <DialogContent>
              <StyledText>
                {currentRole ? (
                  <FormattedMessage
                    id="UserRoleSelector.Vas a cambiar el rol de {userName} de {currentRole} a {newRole}"
                    defaultMessage="Vas a cambiar el rol de {userName} de {currentRole} a {newRole}"
                    values={{
                      userName: <b>{user.fullname}</b>,
                      currentRole: <b>{currentRoleOption?.label}</b>,
                      newRole: <b>{state.newRoleName}</b>,
                    }}
                  />
                ) : (
                  <FormattedMessage
                    id="UserRoleSelector.Vas a asignar a {userName} el rol {newRole}"
                    defaultMessage="Vas a asignar a {userName} el rol {newRole}"
                    values={{
                      userName: <b>{user.fullname}</b>,
                      newRole: <b>{state.newRoleName}</b>,
                    }}
                  />
                )}
              </StyledText>
              <StyledText>
                <FormattedMessage
                  id="UserRoleSelector.¿Seguro que quieres continuar?"
                  defaultMessage="¿Seguro que quieres continuar?"
                />
              </StyledText>
            </DialogContent>
          </StyledRight>
        </StyledContentWithImage>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleCancelDialog}
            disabled={state.inProcess}
          >
            <FormattedMessage
              id="UserRoleSelector.Cancelar"
              defaultMessage="Cancelar"
            />
          </Button>
          <StyledConfirmButton
            variant="contained"
            color="primary"
            onClick={handleConfirmGroupChange}
            disabled={state.inProcess}
          >
            {state.inProcess ? (
              <CircularProgress size={19} thickness={5} />
            ) : (
              <FormattedMessage
                id="UserRoleSelector.Confirmar"
                defaultMessage="Confirmar"
              />
            )}
          </StyledConfirmButton>
        </DialogActions>
      </StyledDialog>
    </>
  );
};

export default UserRoleSelector;
