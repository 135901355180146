import { apiEmpleo } from "../../util/ApiEmpleo";
import { TGetContractsNumbersItemResponse } from "./contract.types";

// We limit the number of ids that can be used on each request because we've
// seen that the server is not responding properly when there are too much
const MAX_PER_REQUEST = 100;

export interface IApiListContractsNumbersParams {
  page?: number;
  itemsPerPage?: number;
  doNotLog?: number;
  id: string[];
}

const apiListContractsNumbers = async (
  params: IApiListContractsNumbersParams
) => {
  const res: {
    "hydra:member": TGetContractsNumbersItemResponse[];
    "hydra:totalItems": number;
  } = {
    "hydra:member": [],
    "hydra:totalItems": 0,
  };

  const chunksRequests: Promise<{
    "hydra:member": TGetContractsNumbersItemResponse[];
    "hydra:totalItems": number;
  }>[] = [];
  for (let i = 0; i < params.id.length; i += MAX_PER_REQUEST) {
    const chunk = params.id.slice(i, i + MAX_PER_REQUEST);

    const chunkParams: IApiListContractsNumbersParams = {
      ...params,
      itemsPerPage: MAX_PER_REQUEST,
      id: chunk,
    };

    chunksRequests.push(
      apiEmpleo.get("offers/numbers", {
        params: chunkParams,
      })
    );
  }

  const chunksRes = await Promise.all(chunksRequests);

  for (let i = 0; i < chunksRes.length; i++) {
    res["hydra:member"] = res["hydra:member"].concat(
      chunksRes[i]["hydra:member"]
    );
    if (i === 0) {
      res["hydra:totalItems"] = chunksRes[i]["hydra:totalItems"];
    }
  }

  return res;
};

export { apiListContractsNumbers };
