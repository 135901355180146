import { useQuery } from "react-query";
import { USER_ROLES } from "../../../../../api/security";
import { apiListStaffUsers } from "../../../../../api/user/apiListStaffUsers";
import {
  ExtractFnReturnType,
  QueryConfig,
} from "../../../../../lib/react-query";
import { userKeys } from "../util/user.query-keys";

export const getRecruiterUsers = async () => {
  const response = await apiListStaffUsers();

  const filteredUsers = response["hydra:member"].filter((user) => {
    if (user.roles.indexOf(USER_ROLES.ROLE_COORDINATION) !== -1 || user.roles.indexOf(USER_ROLES.ROLE_ES_RECRUITER) !== -1) {
      return true;
    }

    if (
      user.fullname === "Cuideo Cuideo"
      // user.fullname === "magda@cuideo.com"
    ) {
      return true;
    }

    return false;
  });

  const sortedUsers = filteredUsers.sort((a, b) => {
    if (a.fullname < b.fullname) {
      return -1;
    }
    return 1;
  });

  const superAdminId =
    sortedUsers.find((user) => user.fullname === "Cuideo Cuideo")?.id ?? "-";
  return {
    recruiters: sortedUsers,
    ownerOptions: sortedUsers.filter(
      (user) => !!user.active && user.id !== superAdminId
    ),
    superAdminUserId: superAdminId,
    totalItems: response["hydra:totalItems"],
  };
};

type QueryFnType = typeof getRecruiterUsers;

type UseConversationsOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useRecruiterUsers = ({ config }: UseConversationsOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: userKeys.recruiters,
    queryFn: () => getRecruiterUsers(),
  });
};
