import { useQuery } from "react-query";
import { apiGetStaffUser } from "../../../../../../../api/user/apiGetStaffUser";

export default function useCurrentPromoterQuery(ownerId: string | null) {
  return useQuery(
    ["current_coordinator", ownerId],
    async () => {
      if (null !== ownerId) {
        const res = await apiGetStaffUser(ownerId);
        return res;
      }
      return null;
    },
    {
      enabled: true,
      cacheTime: 1000 * 60 * 5,
    }
  );
}
