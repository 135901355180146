import { Avatar } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { cyrb53 } from "../../util/hash/cybr53";

const BG_COLORS = [
  "#35cd96",
  "#6bcbef",
  "#e542a3",
  "#91ab01",
  "#ffa97a",
  "#1f7aec",
  "#dfb610",
  "#029d00",
  "#8b7add",
  "#fe7c7f",
  "#ba33dc",
  "#59d368",
  "#b04632",
  "#fd85d4",
  "#8393ca",
  "#ff8f2c",
  "#3bdec3",
  "#b4876e",
  "#c90379",
  "#ef4b4f",
];

const FG_COLORS = [
  "#ffffff",
  "#ffffff",
  "#ffffff",
  "#ffffff",
  "#ffffff",
  "#ffffff",
  "#ffffff",
  "#ffffff",
  "#ffffff",
  "#ffffff",
  "#ffffff",
  "#ffffff",
  "#ffffff",
  "#ffffff",
  "#ffffff",
  "#ffffff",
  "#ffffff",
  "#ffffff",
  "#ffffff",
  "#ffffff",
];

const StyledAvatar = styled(Avatar)``;

interface IColoredAvatarProps {
  avatarUrl?: string;
  fullName?: string;
}

const ColoredAvatar: React.FC<IColoredAvatarProps> = (props) => {
  const { avatarUrl, fullName } = props;

  if (avatarUrl) {
    return <StyledAvatar src={avatarUrl} />;
  }

  if (fullName) {
    const initial = fullName.substring(0, 1);
    const hash = cyrb53(fullName, 0);
    const sel = hash % 20;

    return (
      <StyledAvatar
        className="CMuiColoredAvatar"
        style={{
          color: FG_COLORS[sel],
          backgroundColor: BG_COLORS[sel],
        }}
      >
        {initial}
      </StyledAvatar>
    );
  }

  const initial = fullName ? fullName.substring(0, 1) : undefined;

  return <StyledAvatar src={avatarUrl}>{initial}</StyledAvatar>;
};

export default ColoredAvatar;
