import { IconButton } from "@material-ui/core";
import styled from "styled-components";

export const StyledIconButton = styled(IconButton)`
  border-radius: 0.25rem;
  &:hover {
    background-color: #004b7b;
  }
  &.CMui-active {
    background-color: #195d88;
  }
`;

export const StyledIcon = styled.img`
  width: 3rem;
  height: 3rem;
  display: block;
  margin: 0 auto;
`;

export const StyledIconWrapper = styled.div`
  color: #c4d2e4;
  width: 2.375rem;
  height: 2.375rem;
  padding: 0.3125rem 0.25rem 0.3125rem 0.375rem;
  display: block;
  margin: 0 auto;

  .MuiSvgIcon-root {
    width: 2.25rem;
    height: 2.25rem;
  }
`;
