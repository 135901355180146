import {
  EMPLOYEE_YEARS_OF_EXPERIENCE_LESS_1_YEAR,
  EMPLOYEE_YEARS_OF_EXPERIENCE_LESS_2_YEARS,
  EMPLOYEE_YEARS_OF_EXPERIENCE_LESS_3_YEARS,
  EMPLOYEE_YEARS_OF_EXPERIENCE_MORE_3_YEARS,
  EMPLOYEE_YEARS_OF_EXPERIENCE_MORE_5_YEARS,
  EMPLOYEE_YEARS_OF_EXPERIENCE_MORE_10_YEARS,
  EMPLOYEE_YEARS_OF_EXPERIENCE_NO_EXPERIENCE,
} from "./candidato";

const experienceYears = [
  {
    name: EMPLOYEE_YEARS_OF_EXPERIENCE_LESS_1_YEAR,
  },
  {
    name: EMPLOYEE_YEARS_OF_EXPERIENCE_LESS_2_YEARS,
  },
  {
    name: EMPLOYEE_YEARS_OF_EXPERIENCE_LESS_3_YEARS,
  },
  {
    name: EMPLOYEE_YEARS_OF_EXPERIENCE_MORE_3_YEARS,
  },
  {
    name: EMPLOYEE_YEARS_OF_EXPERIENCE_MORE_5_YEARS,
  },
  {
    name: EMPLOYEE_YEARS_OF_EXPERIENCE_MORE_10_YEARS,
  },
  {
    name: EMPLOYEE_YEARS_OF_EXPERIENCE_NO_EXPERIENCE,
  },
];

export default experienceYears;
