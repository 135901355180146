import moment from "moment";
import { useCallback } from "react";
import { useIntl } from "react-intl";

export const useNotificationPeriod = () => {
  const { formatMessage } = useIntl();

  const formatPeriod = useCallback(
    (createdAt: string, currentTime = new Date()) => {
      const momStartOfDay = moment(currentTime).startOf("day");
      const momCreatedAt = moment(createdAt);

      if (momCreatedAt.isAfter(momStartOfDay)) {
        return formatMessage({
          id: "NotificationPeriod.Hoy",
          defaultMessage: "Hoy",
        });
      }

      momStartOfDay.subtract(1, "day");
      if (momCreatedAt.isAfter(momStartOfDay)) {
        return formatMessage({
          id: "NotificationPeriod.Ayer",
          defaultMessage: "Ayer",
        });
      }

      return formatMessage({
        id: "NotificationPeriod.Más antiguas",
        defaultMessage: "Más antiguas",
      });
    },
    [formatMessage]
  );

  return {
    formatPeriod,
  };
};
