import moment from "moment";
import { TGetContractBotAvailabilityResponse } from "../../../../api/contract/apiGetContractBotAvailability";
import { TGetEmployeeBotAvailabilityResponse } from "../../../../api/employee/apiListEmployeesBotAvailability";

export const MAX_COMMUNICATION_INTENTS_PER_CONTRACT = 250;
export const MAX_COMMUNICATION_INTENTS_PER_CONTRACT_RECRUITER = 250;

export enum CommunicationIntentContractValidationError {
  NO_NAME = "no_crm_id_name",
  NO_OWNER = "no_owner",
  NO_CITY = "no_city",
  NO_SCHEDULE = "no_schedule",
  NO_SALARY = "no_salary",
  MAX_PER_CONTRACT = "max_per_contract",
}

export enum CommunicationIntentEmployeeValidationError {
  NO_PHONE = "no_phone",
  NO_FIRST_NAME = "no_first_name",
  NO_SURNAME = "no_surname",
  NO_FULL_NAME = "no_full_name",
  NO_CRM_ID_NAME = "no_crm_id_name",
  NO_EMAIL = "no_email",
  NO_WHATSAPPS = "no_whatsapps",
  NO_WA_REGISTERED = "no_wa_registered",
  LIMIT24H = "limit_24h",
  MAX_PER_CONTRACT = "max_per_contract",
}

export enum CreateCommunicationIntentsState {
  IDLE = "idle",
  CONTRACT_ERROR = "contract_error",
  LOADING = "loading",
  PROCESSING = "processing",
  DONE = "done",
}

export type TCommunicationIntentContract = Pick<
  TGetContractBotAvailabilityResponse,
  | "id"
  | "Ciudad_oferta"
  | "Horario_Servicio"
  | "Name"
  | "formattedGrossSalary"
  | "numCommunicationIntents"
  | "numSuccessfulCommunicationIntents"
  | "ownerName"
> & {
  validationErrors: CommunicationIntentContractValidationError[];
};

export enum CommunicationIntentEmployeeResult {
  SUCCESS = "success",
  ERROR = "error",
  IN_PROGRESS = "in_progress",
}

export type TCommunicationIntentEmployeeResult =
  CommunicationIntentEmployeeResult | null;

export type TCommunicationIntentEmployee = Pick<
  TGetEmployeeBotAvailabilityResponse,
  | "id"
  | "Apellidos"
  | "Email"
  | "Estado_Cuideo"
  | "Name"
  | "No_iniciar_bot_hasta"
  | "Nombre"
  | "Phone"
  | "botAvailable"
  | "numCommunicationIntentsLastDay"
  | "phoneWaStatus"
> & {
  validationErrors: CommunicationIntentEmployeeValidationError[];
  result: TCommunicationIntentEmployeeResult;
  errorMessage: string | null;
};

export type TCreateCommunicationIntentsButtonState = {
  dialogOpen: boolean;
  state: CreateCommunicationIntentsState;
  contract: TCommunicationIntentContract;
  employees: TCommunicationIntentEmployee[];
  numToBeSent: number;
  numAlreadySent: number;
  numSuccessfulSents: number;
};

export const createEmptyCreateCommunicationIntentsButtonState = (
  contractId: string
): TCreateCommunicationIntentsButtonState => {
  return {
    dialogOpen: false,
    state: CreateCommunicationIntentsState.LOADING,
    contract: {
      id: contractId,
      validationErrors: [],
      numCommunicationIntents: 0,
      numSuccessfulCommunicationIntents: 0,
    },
    employees: [],
    numAlreadySent: 0,
    numToBeSent: 0,
    numSuccessfulSents: 0,
  };
};

export const contractValidationErrors = (
  contract: TGetContractBotAvailabilityResponse
): CommunicationIntentContractValidationError[] => {
  const errors = [];

  if (!contract.Ciudad_oferta) {
    errors.push(CommunicationIntentContractValidationError.NO_CITY);
  }

  if (!contract.Horario_Servicio) {
    errors.push(CommunicationIntentContractValidationError.NO_SCHEDULE);
  }

  if (!contract.Name) {
    errors.push(CommunicationIntentContractValidationError.NO_NAME);
  }

  if (!contract.formattedGrossSalary) {
    errors.push(CommunicationIntentContractValidationError.NO_SALARY);
  }

  if (!contract.ownerName) {
    errors.push(CommunicationIntentContractValidationError.NO_OWNER);
  }

  const numCommunicationIntents = contract.numCommunicationIntents ?? 0;
  if (numCommunicationIntents >= MAX_COMMUNICATION_INTENTS_PER_CONTRACT) {
    errors.push(CommunicationIntentContractValidationError.MAX_PER_CONTRACT);
  }

  return errors;
};

export const employeeValidationErrors = (
  employee: TGetEmployeeBotAvailabilityResponse
): CommunicationIntentEmployeeValidationError[] => {
  const errors = [];

  if (!employee.Phone) {
    errors.push(CommunicationIntentEmployeeValidationError.NO_PHONE);
  }

  if (!employee.Name) {
    errors.push(CommunicationIntentEmployeeValidationError.NO_CRM_ID_NAME);
  }

  if (!employee.Nombre) {
    errors.push(CommunicationIntentEmployeeValidationError.NO_FIRST_NAME);
  }

  if (!employee.Apellidos) {
    errors.push(CommunicationIntentEmployeeValidationError.NO_SURNAME);
  }

  if (employee.numCommunicationIntentsLastDay > 0) {
    errors.push(CommunicationIntentEmployeeValidationError.LIMIT24H);
  }

  if (employee.No_iniciar_bot_hasta) {
    const momDate = moment(employee.No_iniciar_bot_hasta);
    if (momDate.isValid()) {
      const now = moment();
      if (now.isBefore(momDate)) {
        errors.push(CommunicationIntentEmployeeValidationError.NO_WHATSAPPS);
      }
    }
  }

  if (
    employee.phoneWaStatus &&
    employee.phoneWaStatus === "error_unregistered"
  ) {
    errors.push(CommunicationIntentEmployeeValidationError.NO_WA_REGISTERED);
  }

  return errors;
};

export const employeeFullName = (
  name?: string | null,
  surname?: string | null
) => {
  let fullName = "";

  if (name) {
    fullName += name + " ";
  }

  if (surname) {
    fullName += surname;
  }

  return fullName.trim();
};

export const updateStateWithEmployeeResult = (
  prevState: TCreateCommunicationIntentsButtonState,
  employeeId: string,
  result: TCommunicationIntentEmployeeResult,
  errorMessage: string | null = null
): TCreateCommunicationIntentsButtonState => {
  const newState: TCreateCommunicationIntentsButtonState = {
    ...prevState,
    employees: prevState.employees.map((employee) => {
      if (employee.id !== employeeId) {
        return employee;
      }

      return {
        ...employee,
        result,
        errorMessage,
      };
    }),
  };

  return updateStateValues(newState);
};

export const updateStateValues = (
  prevState: TCreateCommunicationIntentsButtonState
): TCreateCommunicationIntentsButtonState => {
  const employeesWithoutErrors = prevState.employees.filter(
    (employee) => employee.validationErrors.length === 0
  );
  const numToBeSent = employeesWithoutErrors.length;

  const employeesAlreadySent = prevState.employees.filter(
    (employee) =>
      employee.result === CommunicationIntentEmployeeResult.SUCCESS ||
      employee.result === CommunicationIntentEmployeeResult.ERROR
  );
  const numAlreadySent = employeesAlreadySent.length;

  const employeesSuccessfulSents = prevState.employees.filter(
    (employee) => employee.result === CommunicationIntentEmployeeResult.SUCCESS
  );
  const numSuccessfulSents = employeesSuccessfulSents.length;

  const newState: TCreateCommunicationIntentsButtonState = {
    ...prevState,
    numToBeSent,
    numAlreadySent,
    numSuccessfulSents,
  };

  return newState;
};

export const communicationsButtonProgress = (
  state: TCreateCommunicationIntentsButtonState
): number => {
  const toBeDone = state.numToBeSent === 0 ? 1 : state.numToBeSent;
  const percentage = Math.round((state.numAlreadySent / toBeDone) * 100);
  return percentage;
};

export const stateStartSend = (
  prevState: TCreateCommunicationIntentsButtonState
): TCreateCommunicationIntentsButtonState => {
  const newState: TCreateCommunicationIntentsButtonState = {
    ...prevState,
    state: CreateCommunicationIntentsState.PROCESSING,
    employees: prevState.employees.map((employee) => {
      if (employee.validationErrors.length !== 0) {
        return employee;
      }

      return {
        ...employee,
        result: CommunicationIntentEmployeeResult.IN_PROGRESS,
      };
    }),
  };

  return updateStateValues(newState);
};
