import React from "react";
import { useIntl } from "react-intl";
import useBackground from "../../../../../../util/useBackground";
import useFooter from "../../../../../../util/useFooter";
import useHeader from "../../../../../../util/useHeader";
import usePageTitle from "../../../../../../util/usePageTitle";
import { CommunicationsProvider } from "../../hooks/useCommunicationsContext";
import ConversationsRoute from "./ConversationsRoute";

interface IConversationsRouteContainerProps {}

const ConversationsRouteContainer: React.VFC<IConversationsRouteContainerProps> =
  (props) => {
    const { formatMessage } = useIntl();
    useHeader({
      type: "hidden",
    });
    useFooter({
      type: "hidden",
    });
    useBackground("cuideo");
    usePageTitle(
      formatMessage({
        id: "ConversationsRoute.Comunicaciones",
        defaultMessage: "Comunicaciones",
      })
    );

    return (
      <CommunicationsProvider>
        <ConversationsRoute />
      </CommunicationsProvider>
    );
  };

export default ConversationsRouteContainer;
