import { Box } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import Linkify from "../../../apps/affinity/components/Linkify/Linkify";
import cuideoTheme from "../../../containers/themes/defaultTheme";

const StyledWrapper = styled(Box)`
  font-size: 1rem;
  font-weight: 600;

  .CMuiNoteMention {
    background-color: rgb(206, 228, 229);
  }

  a {
    color: ${cuideoTheme.palette.primary.main};
  }
`;

interface INoteProps {
  noteId: string;
  value: string;
  mentionsData: {
    id: string;
    display: string;
  }[];
}

const Note = (props: INoteProps) => {
  const { noteId, value, mentionsData } = props;

  const parts = value.match(/crm\[(user|group)#([0-9]+#[0-9]+)\]crm/gm);

  let parsed = value;
  if (parts) {
    parts.forEach((part) => {
      const id = part.match(/((user|group)#[0-9]+#[0-9]+)/gm);
      if (!id) {
        return;
      }
      const found = mentionsData.find((item) => item.id === id[0]);
      if (found) {
        parsed = parsed.replace(
          part,
          `<span class="CMuiNoteMention">${found.display}</span>`
        );
      }
    });
  }

  return (
    <StyledWrapper className="CMuiNote">
      <Linkify value={parsed} keyPrefix={`${noteId}_note_`} />
    </StyledWrapper>
  );
};

export default React.memo(
  (props: INoteProps) => {
    return <Note {...props} />;
  },
  (prevProps, nextProps) => {
    if (prevProps.value !== nextProps.value) {
      return false;
    }
    return true;
  }
);
