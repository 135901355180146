import { Button, IconButton, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import { connect, getIn } from "formik";
import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { avatarImgBaseUrl } from "../../config/assetLocations";
import cuideoTheme from "../../containers/themes/defaultTheme";

const StyledField = styled("div")<{
  fullWidth: boolean;
  margin: string;
  size: string;
}>`
  ${({ fullWidth }) => fullWidth && `width: 100%;`}
  padding-top: 0;
  padding-bottom: 0;
  ${({ margin }) => {
    if (margin === "normal") {
      return `
      margin: 1rem 0;
      `;
    }
    if (margin === "nomargin") {
      return ``;
    }
  }}

  &:last-child {
    border-bottom: none;
  }
`;

const StyledLabel = styled(Typography)<{ error?: string; size: string }>`
  white-space: wrap;
  color: ${({ error }) =>
    error ? cuideoTheme.palette.error.main : cuideoTheme.palette.primary.light};

  ${({ size }) => {
    if (size === "medium") {
      return `
        font-size: 1rem;
        padding: 0.2rem 0 0.2rem 0;
      `;
    }
    if (size === "small") {
      return `
        font-size: 0.8rem;
        padding: 0.2rem 0 0.2rem 0;

        @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
          font-size: 0.8rem;
        }
      `;
    }
  }}
`;

class Thumb extends React.Component<any, any> {
  state = {
    loading: false,
    thumb: undefined,
    urlFile: undefined,
  };

  updateState() {
    if (typeof this.props.file === "object") {
      this.setState({ loading: true, urlFile: undefined }, () => {
        let reader = new FileReader();

        reader.onloadend = () => {
          this.setState({ loading: false, thumb: reader.result });
        };

        reader.readAsDataURL(this.props.file);
      });
    } else {
      // The avatar is just a string
      this.setState({ loading: false, urlFile: this.props.file });
    }
  }

  componentDidMount() {
    this.updateState();
  }

  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    if (!this.props.file) {
      return;
    }
    if (this.props.file === prevProps.file) {
      return;
    }
    this.updateState();
  }

  render() {
    const { file, className } = this.props;
    const { loading, thumb, urlFile } = this.state;

    if (!file) {
      return null;
    }

    if (loading) {
      return null;
    }

    if (urlFile) {
      return (
        <div className={className}>
          <img
            src={avatarImgBaseUrl + urlFile}
            className="img-thumbnail"
            alt=""
          />
        </div>
      );
    }

    return (
      <div className={className}>
        <img src={thumb} alt={file.name} className="img-thumbnail" />
      </div>
    );
  }
}

const StyledThumbnail = styled(Thumb)`
  width: 2.5rem;
  height: 2.5rem;
  overflow: hidden;
  border-radius: 50%;

  .img-thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const StyledBigThumbnail = styled(Thumb)`
  display: block;
  overflow: hidden;
  margin: 0.25rem 0;
  border: 1px solid rgba(0, 72, 120, 0.1);
  padding: 0.5rem;

  .img-thumbnail {
    height: 100%;
    width: auto;
    max-height: 12rem;
    display: block;
    margin: 0 auto;
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledInputWrapper = styled.div`
  position: relative;
  width: 9rem;
`;

const StyledFilename = styled.span`
  font-size: 0.9rem;
  font-weight: 700;
  margin-left: 0.5rem;
  font-family: "Source sans pro", Helvetica, arial, sans-serif;
  color: ${cuideoTheme.palette.primary.main};
  text-overflow: ellipsis;
  overflow: hidden;

  @media all and (max-width: ${cuideoTheme.breakpoints.values.sm}px) {
    max-width: calc(100vw - 18rem);
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledError = styled.div`
  color: ${cuideoTheme.palette.error.main};
  font-size: 0.9rem;
  font-family: "Source sans pro", Helvetica, Arial, sans-serif;
  margin: 0.25rem 0 0.5rem;
`;

const StyledFileInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 2.5rem;
  position: absolute;
  left: 0.5rem;
  right: 0.5rem;
`;

const StyledInfo = styled.div`
  background-color: ${cuideoTheme.palette.common.white};
  flex-grow: 1;
  border-radius: ${cuideoTheme.shape.borderRadius}px;
  margin-right: 0.5rem;
  padding: 0.25rem 0.5rem;
  border-color: #c2d1da;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  position: relative;
`;

const StyledInput = styled.input`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
`;

const StyledButton = styled(Button)`
  background-color: rgba(0, 72, 120, 0.25);
  color: ${cuideoTheme.palette.primary.main};
  text-transform: none;
  width: 9rem;
  height: 3rem;
`;

const StyledRemoveButton = styled(IconButton)``;

interface Props {
  name: string;
  type?: "text" | "number" | "password" | "tel";
  size?: "medium" | "small";
  margin?: "nomargin" | "normal";
  variant?: "standard" | "outlined" | "filled";
  label?: any;
  helperText?: any;
  fullWidth?: boolean;
  required?: boolean;
  uploadLabel?: React.ReactNode | string;
  changeLabel?: React.ReactNode | string;
  showMiniThumb?: boolean;
  showBigThumb?: boolean;
}

interface State {}

class FormikUpload extends React.Component<any, State> {
  shouldComponentUpdate(nextProps: any, nextState: any) {
    const next = nextProps.formik;
    const cur = this.props.formik;
    const name = this.props.name;

    if (
      getIn(next.values, name) !== getIn(cur.values, name) ||
      getIn(next.errors, name) !== getIn(cur.errors, name) ||
      getIn(next.touched, name) !== getIn(cur.touched, name)
    ) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    this.props.formik.registerField(this.props.name, {
      validate: this.props.validate,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.name !== prevProps.name) {
      this.props.formik.unregisterField(prevProps.name);
      this.props.formik.registerField(this.props.name, {
        validate: this.props.validate,
      });
    }

    if (this.props.validate !== prevProps.validate) {
      this.props.formik.registerField(this.props.name, {
        validate: this.props.validate,
      });
    }
  }

  componentWillUnmount() {
    this.props.formik.unregisterField(this.props.name);
  }

  handleRemove(e: any) {
    this.props.formik.setFieldValue(this.props.name, null);
  }

  render() {
    const { name, label, margin, size, required, showMiniThumb, showBigThumb } =
      this.props;
    const formik = this.props.formik;

    const field = {
      value: getIn(formik.values, name),
      name,
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
    };

    const error = getIn(formik.errors, name);
    const touched = !!getIn(formik.touched, name);

    let fileName = undefined;
    if (typeof field.value === "string") {
      const pieces = field.value.split(/\//g);
      fileName = pieces.pop();
    }
    if (typeof field.value === "object") {
      if (field.value && field.value.name) {
        fileName = field.value.name;
      }
    }

    const changeLabel = this.props.changeLabel || (
      <FormattedMessage
        id="Upload.ChangeImage"
        defaultMessage="Cambiar imagen"
      />
    );
    const uploadLabel = this.props.uploadLabel || (
      <FormattedMessage id="Upload.UploadImage" defaultMessage="Subir imagen" />
    );

    return (
      <StyledField fullWidth margin={margin || "normal"} size={size || "small"}>
        {label && (
          <StyledLabel
            error={!!error && touched ? "error" : undefined}
            size={size || "small"}
          >
            {label}
            {required && <span className="required-field"> *</span>}{" "}
          </StyledLabel>
        )}
        <StyledWrapper>
          {field.value && (
            <StyledInfo>
              {field.value && (
                <StyledFileInfo>
                  {field.value && showMiniThumb && (
                    <StyledThumbnail file={field.value}></StyledThumbnail>
                  )}
                  {fileName && <StyledFilename>{fileName}</StyledFilename>}
                  <StyledRemoveButton
                    color="primary"
                    onClick={this.handleRemove.bind(this)}
                  >
                    <CloseIcon fontSize="small" />
                  </StyledRemoveButton>
                </StyledFileInfo>
              )}
            </StyledInfo>
          )}
          <StyledInputWrapper>
            <StyledInput
              type="file"
              name={name}
              id={name}
              onChange={(event: any) => {
                formik.setFieldTouched(name, true, false);
                formik.setFieldValue(name, event.currentTarget.files[0], true);
              }}
            />
            <StyledButton>
              {field.value ? (
                <Fragment>{changeLabel}</Fragment>
              ) : (
                <Fragment>
                  <AddIcon />
                  {uploadLabel}
                </Fragment>
              )}
            </StyledButton>
          </StyledInputWrapper>
        </StyledWrapper>
        {showBigThumb && field.value && (
          <StyledBigThumbnail file={field.value}></StyledBigThumbnail>
        )}
        {touched && error && <StyledError>{error}</StyledError>}
      </StyledField>
    );
  }
}

export default connect<Props, State>(FormikUpload as any);
