import { Box, Button, Grid } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import useSignUpStep2FormContext from "./hooks/useSignUpStep2FormContext";

interface ISignUpStep2ActionsInnerFormProps {
  onSubmitClick: (e: React.FormEvent) => void;
  onBack: (e: React.MouseEvent) => void;
}

const SignUpStep2ActionsInnerForm = (
  props: ISignUpStep2ActionsInnerFormProps
) => {
  const { onSubmitClick, onBack } = props;
  const { formikContext } = useSignUpStep2FormContext();

  return (
    <Box mt={8}>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Button
            color="secondary"
            size="large"
            variant="outlined"
            fullWidth
            onClick={onBack}
          >
            <FormattedMessage id="SignUp.Back" defaultMessage="Atrás" />
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            color="secondary"
            fullWidth
            size="large"
            type="submit"
            onClick={(e) => {
              formikContext.setFieldTouched("Externa_entre_semana");
              formikContext.setFieldTouched("Externa_fin_de_semana");
              formikContext.setFieldTouched("Interna_entre_semana");
              formikContext.setFieldTouched("Interna_fin_de_semana");
              onSubmitClick(e);
            }}
            variant="contained"
          >
            <FormattedMessage id="SignUp.Finish" defaultMessage="Finalizar" />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SignUpStep2ActionsInnerForm;
