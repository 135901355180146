const prepareTitulacionesInitialValue = (objValue: any) => {
  if (!objValue || objValue[0] === "") {
    return {
      value: "",
      label: "Ninguna",
    };
  }

  const item = titulacionesOptions.find((i: any) => i.value === objValue[0]);

  if (!item) {
    return {
      value: "",
      label: "Ninguna",
    };
  }
  return {
    value: item.value,
    label: item.label,
  };
};

const titulacionesOptions = [
  {
    value: "",
    label: "Ninguna",
    businessCountry: "es",
  },
  {
    value: "Atención a Personas en Situación de Dependencia (CFGM)",
    label: "Atención a Personas en Situación de Dependencia (CFGM)",
    businessCountry: "es",
  },
  {
    value:
      "Asistente personal/Auxiliares de Ayuda a domicilio (País Vasco) (C.P)",
    label:
      "Asistente personal/Auxiliares de Ayuda a domicilio (País Vasco) (C.P)",
    businessCountry: "es",
  },
  {
    value: "Atención sociosanitaria a personas dependientes en domicilio (C.P)",
    label: "Atención sociosanitaria a personas dependientes en domicilio (C.P)",
    businessCountry: "es",
  },
  {
    value: "Auxiliar de Clinica (CFGM)",
    label: "Auxiliar de Clinica (CFGM)",
    businessCountry: "es",
  },
  {
    value: "Auxiliar de Geriatria (CFGM)",
    label: "Auxiliar de Geriatria (CFGM)",
    businessCountry: "es",
  },
  {
    value: "Auxiliar de enfermería. (CFGM)",
    label: "Auxiliar de enfermería. (CFGM)",
    businessCountry: "es",
  },
  {
    value: "Auxiliar de Gerontología",
    label: "Auxiliar de Gerontología",
    businessCountry: "es",
  },
  {
    value: "Ayuda a Personas Dependientes en Instituciones Sociales (CFGM)",
    label: "Ayuda a Personas Dependientes en Instituciones Sociales (CFGM)",
    businessCountry: "es",
  },
  {
    value: "Certificado Habilitación Excepcional",
    label: "Certificado Habilitación Excepcional",
    businessCountry: "es",
  },
  {
    value: "Certificado Habilitación Provisional",
    label: "Certificado Habilitación Provisional",
    businessCountry: "es",
  },
  {
    value: "Grado en Enfermería",
    label: "Grado en Enfermería",
    businessCountry: "es",
  },
  {
    value:
      "Atención sociosanitaria a personas dependientes en instituciones sociales (C.P)",
    label:
      "Atención sociosanitaria a personas dependientes en instituciones sociales (C.P)",
    businessCountry: "es",
  },
  {
    value: "ADVD Assistante de vie dépendance",
    label: "ADVD Assistante de vie dépendance",
    businessCountry: "fr",
  },
  {
    value: "ADVF - Formation des Assistants de Vie aux Familles",
    label: "ADVF - Formation des Assistants de Vie aux Familles",
    businessCountry: "fr",
  },
  {
    value: "AET Aspiration Endo-Tracheal",
    label: "AET Aspiration Endo-Tracheal",
    businessCountry: "fr",
  },
  {
    value: "Bac pro ASSP - Accompagnement, soins et services à la personne",
    label: "Bac pro ASSP - Accompagnement, soins et services à la personne",
    businessCountry: "fr",
  },
  {
    value: "Bac pro Services aux personnes et aux territoires",
    label: "Bac pro Services aux personnes et aux territoires",
    businessCountry: "fr",
  },
  {
    value: "BEP ASSP Accompagnement, Soins et Services à la personne",
    label: "BEP ASSP Accompagnement, Soins et Services à la personne",
    businessCountry: "fr",
  },
  {
    value: "BEP Carrières sanitaires et sociales",
    label: "BEP Carrières sanitaires et sociales",
    businessCountry: "fr",
  },
  {
    value: "CAP Agricole services aux personnes et vente en espace rural",
    label: "CAP Agricole services aux personnes et vente en espace rural",
    businessCountry: "fr",
  },
  {
    value: "CAP ATMFC - Assistant technique en milieux familial et collectif",
    label: "CAP ATMFC - Assistant technique en milieux familial et collectif",
    businessCountry: "fr",
  },
  {
    value: "CAPA Services en milieu rural",
    label: "CAPA Services en milieu rural",
    businessCountry: "fr",
  },
  {
    value: "DEAES - Diplôme d’État d’accompagnant éducatif et social",
    label: "DEAES - Diplôme d’État d’accompagnant éducatif et social",
    businessCountry: "fr",
  },
  {
    value: "DEAMP - Diplôme d’État d’Aide Médico-Psychologique",
    label: "DEAMP - Diplôme d’État d’Aide Médico-Psychologique",
    businessCountry: "fr",
  },
  {
    value: "DEAS - Diplôme d'État d’aide-soignant",
    label: "DEAS - Diplôme d'État d’aide-soignant",
    businessCountry: "fr",
  },
  {
    value: "DEAVS - Diplôme d'État d’auxiliaire de vie sociale",
    label: "DEAVS - Diplôme d'État d’auxiliaire de vie sociale",
    businessCountry: "fr",
  },
  {
    value: "Mention complémentaire Aide à domicile",
    label: "Mention complémentaire Aide à domicile",
    businessCountry: "fr",
  },
  {
    value: "VAE Validation des acquis de l'expérience",
    label: "VAE Validation des acquis de l'expérience",
    businessCountry: "fr",
  },
  {
    value: "Autre",
    label: "Autre",
    businessCountry: "fr",
  },
  {
    value: "",
    label: "Sans diplôme",
    businessCountry: "fr",
  },
];

export { prepareTitulacionesInitialValue, titulacionesOptions as default };

export function degreesList(country = "es") {
  return titulacionesOptions.filter(
    (degree) => degree.businessCountry === country && degree.value !== ""
  );
}

export function degreesListWithNotNeededOption(country = "es") {
  const options = [
    {
      value: "No necesaria",
      label: "No necesaria",
    },
  ];
  return [...options, ...degreesList(country)];
}
