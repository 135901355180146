import moment from "moment";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import uuidv4 from "uuid/v4";
import { getActividadesSuccess } from "../actions/Actividades";
import { fetchStart, fetchSuccess } from "../actions/Common";
import { GET_ACTIVIDADES } from "../constants/ActionTypes";
import { apiEmpleo } from "../util/ApiEmpleo";

const getMisActividadesRequest = async (userId: string | null) => {
  let res = await apiEmpleo.get("users/" + userId + "/actividades", {
    params: {
      importancia: [2, 3, 4],
    },
  });
  if (res) {
    return res;
  }
};

const getActividadesRequest = async (
  userId: string | null,
  hideLoadingState = false
) => {
  let res = await apiEmpleo.get("actividades", {
    params: {
      importancia: [2, 3, 4],
      partial: 1,
      itemsPerPage: 50,
      "createdAt[after]": moment().subtract(12, "hours").format("YYYY-MM-DD"),
    },
    hideLoadingState: hideLoadingState ? hideLoadingState : false,
  });
  if (res) {
    return res;
  }
};

function* getActividades(payload: any) {
  try {
    const opId = uuidv4();
    if (!payload.payload.options || !payload.payload.options.hideLoadingState) {
      yield put(fetchStart(opId, "getActividades"));
    }
    let actividadesRequest: any;
    if (payload.payload.userId) {
      // @ts-ignore
      actividadesRequest = yield call(
        getMisActividadesRequest,
        payload.payload.userId
      );
    } else {
      // @ts-ignore
      actividadesRequest = yield call(
        getActividadesRequest,
        payload.payload.userId
      );
    }
    if (actividadesRequest) {
      const partialActividades = actividadesRequest["hydra:member"];
      const numActividades = actividadesRequest["hydra:totalItems"];
      yield put(
        getActividadesSuccess({
          partialActividades: partialActividades,
          numActividades: numActividades,
        })
      );
      if (
        !payload.payload.options ||
        !payload.payload.options.hideLoadingState
      ) {
        yield put(fetchSuccess(opId));
      }
    }
  } catch (error) {
    console.log("Error", error);
  }
}

export function* getActividadesSaga() {
  yield takeEvery(GET_ACTIVIDADES as any, getActividades);
}

export default function* rootSaga() {
  yield all([fork(getActividadesSaga)]);
}
