import { apiListMunicipalities } from "../../apiCoreV3/municipality/apiListMunicipalities";
import { TSelectOption } from "../../apps/affinity/pages/ServicePage/components/ServiceValuation/types";

export const cityOptionsForPostalCode = async (
  postalCode: string
): Promise<TSelectOption[]> => {
  const cityOptions: TSelectOption[] = [];

  // Check with CoreV3 Postal Code
  const res = await apiListMunicipalities({
    postal_code: postalCode,
  });
  if (res && res.municipalities) {
    res.municipalities.forEach((municipality) => {
      if (municipality.postal_code === postalCode) {
        cityOptions.push({
          value: municipality.name,
          label: municipality.name,
        });
      }
    });
  }

  return cityOptions;
};
