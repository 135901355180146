import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import { getCandidato } from "../../../../../../actions";
import { CONTRACT_STATE } from "../../../../../../api/contract/contract.types";
import useBackground from "../../../../../../util/useBackground";
import useFooter from "../../../../../../util/useFooter";
import useHeader from "../../../../../../util/useHeader";
import usePageTitle from "../../../../../../util/usePageTitle";
import { useContract } from "../../api/getContract";
import { TContractFull } from "../../types/contract.types";
import ActiveContractRoute from "./ActiveContractRoute";
import useActiveRouteUrlParams from "./hooks/useActiveRouteURLParams";

interface IActiveContractRouteContainerProps {
  match: {
    params: {
      contractId: string;
    };
  };
}

const ActiveContractRouteContainer: React.FC<IActiveContractRouteContainerProps> =
  (props) => {
    const {
      match: {
        params: { contractId },
      },
    } = props;
    const history = useHistory();
    const { formatMessage } = useIntl();

    const { filters, setFilters } = useActiveRouteUrlParams(history);
    const contractQuery = useContract({ contractId: contractId });
    const dispatch = useDispatch();
    const candidates = useSelector(
      (state: any) => state.candidatosState.candidatos
    );

    useHeader({
      type: "hidden",
    });
    useFooter({
      type: "hidden",
    });
    useBackground("cuideo");

    usePageTitle(
      contractQuery.data && contractQuery.data.Name
        ? contractQuery.data.Name
        : formatMessage({
            id: "ContractPage.Contract",
            defaultMessage: "Contrato",
          })
    );

    useEffect(() => {
      if (contractQuery.data) {
        if (contractQuery.data.Trabajador?.id) {
          dispatch(getCandidato(contractQuery.data.Trabajador.id));
        }
      }
    }, [contractQuery.data, dispatch]);

    const handleEmployeeTabChange = (newValue: number) => {
      const newFilters = { ...filters, eTab: newValue };
      setFilters(newFilters);
    };

    if (contractQuery.isLoading) {
      return null;
    }

    if (
      contractQuery.data?.Estado &&
      contractQuery.data.Estado !== CONTRACT_STATE.ACTIVE
    ) {
      return <Redirect to={`/contrato/${contractId}`} />;
    }

    if (!contractQuery.data) {
      return null;
    }

    const candidate = candidates.find((item: any) => {
      if (item.id === contractQuery.data.Trabajador?.id) {
        return item;
      }
      return false;
    });

    return (
      <ActiveContractRoute
        contract={contractQuery.data as TContractFull}
        candidate={candidate}
        tab={filters.eTab}
        onEmployeeTabChange={handleEmployeeTabChange}
      />
    );
  };

export default ActiveContractRouteContainer;
