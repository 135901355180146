export const formatCurrency = (value: number) => {
  if (typeof Intl === "object" && typeof Intl.NumberFormat === "function") {
    return new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    }).format(value);
  }
  return value.toString();
};
