import { Grid, Typography } from "@material-ui/core";
import AlarmIcon from "@material-ui/icons/Alarm";
import classNames from "classnames";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import {
  StyledValoracionesItem,
  StyledValoracionesItemData,
  StyledValoracionesItemLabel,
  StyledValoracionesItemNew,
  StyledValuationsItemBottom,
  StyledValuationsItemCenter,
  StyledValuationsItemTop,
} from "./ContractCardSC";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

const StyledWarning = styled(Grid)`
  padding: 0.5rem;
  color: inherit;

  .MuiSvgIcon-root {
    width: 1.125rem;
    height: 1.125rem;
  }
`;

const StyledWarningTypography = styled(Typography)`
  text-align: left;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.15;
  max-height: 1.875rem;
  overflow-y: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

interface IValuationsItemProps {
  label: React.ReactNode;
  number?: number;
  newItems: number;
  onClick: () => void;
  variant?: "error" | "warning";
  show24hCvWarning?: boolean;
  showFaceToFaceWarning?: boolean;
}

const ValuationsItem = (props: IValuationsItemProps) => {
  const {
    label,
    number,
    newItems,
    onClick,
    variant,
    show24hCvWarning,
    showFaceToFaceWarning,
  } = props;
  return (
    <StyledValoracionesItem
      onClick={onClick}
      className={classNames({
        CMuiValuationsItem: true,
        "CMuiValuationsItem-warning": variant === "warning",
        "CMuiValuationsItem-error": variant === "error",
      })}
    >
      <StyledValuationsItemTop>
        {newItems > 0 && (
          <StyledValoracionesItemNew>
            {"+" + newItems}
          </StyledValoracionesItemNew>
        )}
        <StyledValoracionesItemLabel>{label}</StyledValoracionesItemLabel>
      </StyledValuationsItemTop>
      <StyledValuationsItemCenter>
        <StyledValoracionesItemData>{number}</StyledValoracionesItemData>
      </StyledValuationsItemCenter>
      <StyledValuationsItemBottom>
        {show24hCvWarning && (
          <StyledWarning container spacing={4} wrap="nowrap">
            <Grid item>
              <AlarmIcon />
            </Grid>
            <Grid item>
              <StyledWarningTypography>
                <FormattedMessage
                  id="ValuationsItem.24h desde que se envió el CV"
                  defaultMessage="24h desde que se envió el CV"
                />
              </StyledWarningTypography>
            </Grid>
          </StyledWarning>
        )}
        {showFaceToFaceWarning && (
          <StyledWarning container spacing={4} wrap="nowrap">
            <Grid item>
              <AccountCircleIcon />
            </Grid>
            <Grid item>
              <StyledWarningTypography>
                <FormattedMessage
                  id="ValuationsItem.Candidato más de 2h en presencial"
                  defaultMessage="Candidato más de 2h en presencial"
                />
              </StyledWarningTypography>
            </Grid>
          </StyledWarning>
        )}
      </StyledValuationsItemBottom>
    </StyledValoracionesItem>
  );
};

export default ValuationsItem;
