import { useInfiniteQuery } from "react-query";
import { apiListCandidates } from "../../../../../apiCoreV3/candidate/apiListCandidates";
import {
  apiListConversations,
  IListConversationsParams,
} from "../../../../../apiCoreV3/conversation/apiListConversations";
import { InfiniteQueryConfig } from "../../../../../lib/react-query";
import { conversationKeys } from "../util/conversation.query-keys";

export const getConversations = async (
  params: IListConversationsParams = {}
) => {
  const response = await apiListConversations({
    orderBy: "updatedAt",
    order: "desc",
    ...params,
  });

  const contactIds = response.data.map(
    (conversation) => conversation.contact_id
  );

  const candidates = await apiListCandidates({
    id: contactIds,
    pageSize: contactIds.length,
  });

  const conversationsWithCandidates = response.data.map((conversation) => ({
    ...conversation,
    candidate: candidates.find((c) => c.id === conversation.contact_id),
  }));

  return {
    conversations: conversationsWithCandidates,
    totalItems: response.info.count_all ?? 0,
  };
};

type QueryFnType = typeof getConversations;

type UseConversationsOptions = {
  config?: InfiniteQueryConfig<QueryFnType>;
  params?: IListConversationsParams;
};

export const useInfiniteConversations = ({
  config,
  params,
}: UseConversationsOptions = {}) => {
  return useInfiniteQuery({
    ...config,
    // @TODO Why TS is warning this?
    queryKey: conversationKeys.all as any,
    queryFn: (queryContext) =>
      getConversations({ ...params, page: queryContext.pageParam ?? 1 } as any),
  });
};
