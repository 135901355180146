import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { apiCreateCommunicationIntent } from "../../../../../api/communicationIntent/apiCreateCommunicationIntent";
import {
  COMMUNICATION_INTENT_STATUS_PENDING,
  COMMUNICATION_INTENT_TYPE_EMPLOYEE_INTERESTED_IN_OFFER_V2,
} from "../../../../../domain/CommunicationIntent/CommunicationIntent";
import { useAuthUser } from "../../../../../reducers/hooks/useAuthUser";
import { userIsRecruiter } from "../../../models/Staff/Staff";
import {
  CommunicationIntentEmployeeResult,
  CommunicationIntentEmployeeValidationError,
  contractValidationErrors,
  CreateCommunicationIntentsState,
  createEmptyCreateCommunicationIntentsButtonState,
  employeeValidationErrors,
  MAX_COMMUNICATION_INTENTS_PER_CONTRACT,
  MAX_COMMUNICATION_INTENTS_PER_CONTRACT_RECRUITER,
  stateStartSend,
  TCommunicationIntentContract,
  TCommunicationIntentEmployee,
  TCreateCommunicationIntentsButtonState,
  updateStateValues,
  updateStateWithEmployeeResult,
} from "../CreateCommunicationIntentsButton.types";
import useContractBotAvailabilityQuery from "./useContractBotAvailabilityQuery";
import useEmployeesBotAvailabilityQuery from "./useEmployeesBotAvailabilityQuery";

export default function useCreateCommunicationIntentsState(
  contractId: string,
  employeeIds: string[]
) {
  const { authUser } = useAuthUser();
  const contractQuery = useContractBotAvailabilityQuery(contractId);
  const employeesQuery = useEmployeesBotAvailabilityQuery(employeeIds);
  const [state, setState] = useState<TCreateCommunicationIntentsButtonState>(
    createEmptyCreateCommunicationIntentsButtonState(contractId)
  );

  useEffect(() => {
    if (!contractQuery.data || !employeesQuery.data) {
      return;
    }
    const contractData = contractQuery.data as TCommunicationIntentContract;
    let finalNumCommunicationIntents =
      contractData.numCommunicationIntents ?? 0;
    const maxIntentsPerContract = userIsRecruiter(authUser) ? MAX_COMMUNICATION_INTENTS_PER_CONTRACT_RECRUITER : MAX_COMMUNICATION_INTENTS_PER_CONTRACT ;
    const newEmployees: TCommunicationIntentEmployee[] = employeesQuery.data[
      "hydra:member"
    ].map((employee) => {
      const validationErrors = employeeValidationErrors(employee);
      if (
        finalNumCommunicationIntents >= maxIntentsPerContract
      ) {
        validationErrors.push(
          CommunicationIntentEmployeeValidationError.MAX_PER_CONTRACT
        );
      }
      finalNumCommunicationIntents++;
      return {
        id: employee.id,
        Apellidos: employee.Apellidos,
        Email: employee.Email,
        Estado_Cuideo: employee.Estado_Cuideo,
        Name: employee.Name,
        Nombre: employee.Nombre,
        Phone: employee.Phone,
        botAvailable: employee.botAvailable,
        numCommunicationIntentsLastDay: employee.numCommunicationIntentsLastDay,
        phoneWaStatus: employee.phoneWaStatus,
        validationErrors,
        result: null,
        errorMessage: null,
      };
    });
    const contractErrors = contractValidationErrors(contractData);
    setState((prevState) => {
      const newState: TCreateCommunicationIntentsButtonState = {
        ...prevState,
        state:
          contractErrors.length === 0
            ? CreateCommunicationIntentsState.IDLE
            : CreateCommunicationIntentsState.CONTRACT_ERROR,
        contract: {
          id: contractData.id,
          Name: contractData.Name,
          ownerName: contractData.ownerName,
          Ciudad_oferta: contractData.Ciudad_oferta,
          validationErrors: contractErrors,
          numCommunicationIntents: contractData.numCommunicationIntents ?? 0,
          numSuccessfulCommunicationIntents:
            contractData.numSuccessfulCommunicationIntents ?? 0,
        },
        employees: newEmployees,
      };

      const newUpdatedState = updateStateValues(newState);

      return newUpdatedState;
    });
  }, [contractQuery.data, employeesQuery.data, authUser]);

  const handleOpenDialog = () => {
    setState((prevState) => ({ ...prevState, dialogOpen: true }));
  };

  const handleCloseDialog = () => {
    setState((prevState) => ({ ...prevState, dialogOpen: false }));
  };

  const handleSend = async () => {
    setState((prevState) => stateStartSend(prevState));

    for (let i = 0; i < state.employees.length; i++) {
      if (state.employees[i].validationErrors.length !== 0) {
        continue;
      }

      try {
        await apiCreateCommunicationIntent({
          type: COMMUNICATION_INTENT_TYPE_EMPLOYEE_INTERESTED_IN_OFFER_V2,
          status: COMMUNICATION_INTENT_STATUS_PENDING,
          employee: state.employees[i].id,
          data: {
            contractId: state.contract.id,
          },
        });

        setState((prevState) => {
          return updateStateWithEmployeeResult(
            prevState,
            state.employees[i].id,
            CommunicationIntentEmployeeResult.SUCCESS
          );
        });
      } catch (e) {
        let errorMessage: string | null = null;
        if (axios.isAxiosError(e)) {
          const axiosError = e as AxiosError<{ "hydra:description"?: string }>;
          if (axiosError.response?.data["hydra:description"]) {
            errorMessage = axiosError.response.data["hydra:description"];
          }
        }
        setState((prevState) => {
          return updateStateWithEmployeeResult(
            prevState,
            state.employees[i].id,
            CommunicationIntentEmployeeResult.ERROR,
            errorMessage
          );
        });
      }
    }

    setState((prevState) => ({
      ...prevState,
      state: CreateCommunicationIntentsState.DONE,
    }));
  };

  return {
    state,
    actions: {
      openDialog: handleOpenDialog,
      closeDialog: handleCloseDialog,
      send: handleSend,
    },
  };
}
