import { apiEmpleo } from "../../util/ApiEmpleo";

export type TGetEmployeeProcesses = {
  id: string;
  Name?: string;
  inProcessValuations: {
    id: string;
    Estado_en_proceso?: string;
    Name?: string;
    contrato: {
      id: string;
      Estado?: string;
      Name?: string;
      Tipo_de_Trato?: string;
      ownerName?: string;
    };
  }[];
};

const apiGetEmployeeOpenProcesses = async (employeeId: string) => {
  let res: TGetEmployeeProcesses = await apiEmpleo.get(
    `candidatos/${employeeId}/processes`
  );

  return res;
};

export { apiGetEmployeeOpenProcesses };
