import { apiCoreV3 } from "../apiCoreV3";

export type TSendMessageRequestContext = {
  candidate_valuation_id?: string;
  candidate_valuation_crm_id?: string;
  contract_id?: string;
  contract_crm_id?: string;
};

export type TSendMessageRequest = {
  conversation_id: string | null;
  channel_id: string;
  author_full_name: string | null;
  content: string;
  from: string;
  to: string;
  context?: TSendMessageRequestContext;
};

const apiSendMessage = async (payload: TSendMessageRequest) => {
  const res = await apiCoreV3.post(`/communication/send-message`, {
    ...payload,
  });

  return res;
};

export { apiSendMessage };
