import { Grid } from "@material-ui/core";
import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import {
  StyledUserInfoBox,
  StyledUserInfoData,
  StyledUserInfoField,
  StyledUserInfoLabel,
  StyledUserInfoSubtitle,
  StyledUserInfoTitle,
} from "./CoordinatorFileSC";
import moment from "moment";
import "moment/locale/es";

interface IUserInfoBoxProps {
  employee: {
    user?: {
      username: string;
      email: string;
      lastActivityTime?: string;
      createdAt?: string;
    };
    createdAt?: string;
  };
}

const UserInfoBox = (props: IUserInfoBoxProps) => {
  const { employee } = props;

  return (
    <StyledUserInfoBox>
      <StyledUserInfoTitle variant="h4">
        <FormattedMessage
          id="CoordinatorFile.UserInfo"
          defaultMessage="Candidato en el Portal de Empleo"
        />
      </StyledUserInfoTitle>

      {employee.user ? (
        <Fragment>
          <StyledUserInfoSubtitle>
            <FormattedMessage
              id="CoordinatorFile.CandidateHasUser"
              defaultMessage="El candidato tiene usuario en el portal de empleo."
            />
          </StyledUserInfoSubtitle>
          <StyledUserInfoField>
            <Grid container spacing={4}>
              <Grid item xs={6} md={5}>
                <StyledUserInfoLabel>
                  <FormattedMessage
                    id="CoordinatorFile.UserLogin"
                    defaultMessage="DNI para hacer login"
                  />
                </StyledUserInfoLabel>
              </Grid>
              <Grid item xs={6} md={7}>
                <StyledUserInfoData>
                  {employee.user.username}
                </StyledUserInfoData>
              </Grid>
            </Grid>
          </StyledUserInfoField>
          <StyledUserInfoField>
            <Grid container spacing={4}>
              <Grid item xs={6} md={5}>
                <StyledUserInfoLabel>
                  <FormattedMessage
                    id="CoordinatorFile.UserEmail"
                    defaultMessage="Email para hacer login"
                  />
                </StyledUserInfoLabel>
              </Grid>
              <Grid item xs={6} md={7}>
                <StyledUserInfoData>{employee.user.email}</StyledUserInfoData>
              </Grid>
            </Grid>
          </StyledUserInfoField>
          <StyledUserInfoField>
            <Grid container spacing={4}>
              <Grid item xs={6} md={5}>
                <StyledUserInfoLabel>
                  <FormattedMessage
                    id="CoordinatorFile.UserLastActivityTime"
                    defaultMessage="Último acceso"
                  />
                </StyledUserInfoLabel>
              </Grid>
              <Grid item xs={6} md={7}>
                <StyledUserInfoData>
                  {employee.user.lastActivityTime ? (
                    <Fragment>
                      {moment(employee.user.lastActivityTime).fromNow()}
                    </Fragment>
                  ) : (
                    <FormattedMessage
                      id="CoordinatorFile.NeverAccessed"
                      defaultMessage="Nunca"
                    />
                  )}
                </StyledUserInfoData>
              </Grid>
            </Grid>
          </StyledUserInfoField>
          <StyledUserInfoField>
            <Grid container spacing={4}>
              <Grid item xs={6} md={5}>
                <StyledUserInfoLabel>
                  <FormattedMessage
                    id="CoordinatorFile.EmployeeCreated"
                    defaultMessage="Candidato creado"
                  />
                </StyledUserInfoLabel>
              </Grid>
              <Grid item xs={6} md={7}>
                <StyledUserInfoData>
                  <Fragment>{moment(employee.createdAt).fromNow()}</Fragment>
                </StyledUserInfoData>
              </Grid>
            </Grid>
          </StyledUserInfoField>
        </Fragment>
      ) : (
        <StyledUserInfoSubtitle>
          <FormattedMessage
            id="CoordinatorFile.CandidateDoesNotHaveUser"
            defaultMessage="El candidato no tiene usuario en el portal de empleo."
          />
        </StyledUserInfoSubtitle>
      )}
    </StyledUserInfoBox>
  );
};

export default UserInfoBox;
