import { Dialog, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  setHideWelcomePopup,
  setOfertasFilters,
} from "../../../../actions/Empleo";
import capitalizeString from "../../../../util/capitalizeString";
import { sortProvincias } from "../../../../util/sortFunctions";
import CuideoMultiSelect from "../../../../components/CuideoFields/CuideoMultiSelect";

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    padding: 2rem;
    background-color: #e5edf1;
    min-width: 38rem;
    box-sizing: border-box;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  padding-top: 0;
  .MuiTypography-h6 {
    font-family: "Lora", Georgia, serif;
    line-height: 3rem;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
  }
`;

const StyledWrapper = styled.div`
  margin: 1.5rem auto 0;
  width: 23rem;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;

  label {
    text-align: center;
  }
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }
`;

const WelcomePopup = (props: any) => {
  const {
    ofertas,
    filtrosBusqueda,
    open,
    onClose,
    setHideWelcomePopup,
    hideWelcomePopup,
  } = props;

  const listadoProvincias: any = {};
  ofertas.forEach((oferta: any) => {
    if (
      !oferta.Provincia_oferta ||
      oferta.Provincia_oferta === "A reparar no tiene datos el cliente"
    ) {
      return;
    }
    const province = capitalizeString(oferta.Provincia_oferta);
    if (typeof listadoProvincias[province] === "undefined") {
      listadoProvincias[province] = 1;
    } else {
      listadoProvincias[province]++;
    }
  });
  const filtroProvincias: any = [];
  // eslint-disable-next-line
  Object.keys(listadoProvincias).map((item: any, index: any) => {
    filtroProvincias.push({
      provincia: item,
      numOfertas: listadoProvincias[item],
    });
  });
  // Sirve la misma funcion para ordenar
  const sortedProvincias = filtroProvincias.sort(sortProvincias);

  const handleClose = () => {
    setHideWelcomePopup();
    onClose();
  };
  const isOpen = open && !hideWelcomePopup;

  return (
    <Fragment>
      <StyledDialog
        onClose={handleClose}
        aria-labelledby="dialog-title"
        open={isOpen}
      >
        <StyledIconButton onClick={handleClose}>
          <CloseIcon color="primary" />
        </StyledIconButton>
        <StyledDialogTitle id="dialog-title">
          <FormattedMessage
            id="WelcomePopup.Where"
            defaultMessage="¿En qué provincia buscas trabajo?"
          />
        </StyledDialogTitle>
        <StyledWrapper>
          <CuideoMultiSelect
            name="provincia"
            required
            closeMenuOnSelect={true}
            id="provincia"
            menuPlacement="auto"
            menuPosition="fixed"
            labelTitle={
              <FormattedMessage
                id="WelcomePopup.SelectProvince"
                defaultMessage="Seleccionar provincia"
              />
            }
            placeholder={
              <FormattedMessage
                id="WelcomePopup.Example"
                defaultMessage="Ejemplo: Barcelona"
              />
            }
            onChange={(e: any) => {
              props.setOfertasFilters(
                e.value,
                filtrosBusqueda.ciudades,
                filtrosBusqueda.disponibilidad
              );
              handleClose();
            }}
            options={sortedProvincias.map((v: any, i: any) => ({
              value: v.provincia,
              label: v.provincia + " (" + v.numOfertas + ")",
            }))}
          />
        </StyledWrapper>
      </StyledDialog>
    </Fragment>
  );
};

const mapStateToProps = ({
  commonData,
  empleo,
}: {
  commonData: any;
  empleo: any;
}) => {
  const { provinces } = commonData;
  const { ofertas, filtrosBusqueda, hideWelcomePopup } = empleo;
  return { provinces, ofertas, filtrosBusqueda, hideWelcomePopup };
};

export default connect(mapStateToProps, {
  setOfertasFilters,
  setHideWelcomePopup,
})(WelcomePopup);
