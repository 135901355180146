const countries = [
  {
    id: "bbdb1064-9e23-45c9-a0bd-09644c86d4a4",
    code: "AD",
    name: "Andorra",
    slug: "andorra",
  },
  {
    id: "5d0238ee-3ae0-4e38-8de0-cca30d0d82e1",
    code: "AE",
    name: "Emiratos Árabes Unidos",
    slug: "emiratos-arabes-unidos",
  },
  {
    id: "4fc4244b-967b-4770-970e-a8049399a482",
    code: "AF",
    name: "Afganistán",
    slug: "afganistan",
  },
  {
    id: "4dcc6f5b-f3af-4c86-8616-59744762da16",
    code: "AG",
    name: "Antigua y Barbuda",
    slug: "antigua-y-barbuda",
  },
  {
    id: "1ad04469-a195-4c77-9fcf-b1a25baed262",
    code: "AI",
    name: "Anguila",
    slug: "anguila",
  },
  {
    id: "fcaee85b-9925-4aed-b6a8-b0d9d5b9a115",
    code: "AL",
    name: "Albania",
    slug: "albania",
  },
  {
    id: "050aa90f-02cc-4634-b399-433d2eea04b5",
    code: "AM",
    name: "Armenia",
    slug: "armenia",
  },
  {
    id: "9f3c52f1-59b9-442f-bc3a-2b9533ceee7d",
    code: "AO",
    name: "Angola",
    slug: "angola",
  },
  {
    id: "59f14ac8-b6bf-4d40-afef-6143594bb89b",
    code: "AQ",
    name: "Antártida",
    slug: "antartida",
  },
  {
    id: "376b2b1d-3c91-4c96-9aa7-302ed53ce3ba",
    code: "AR",
    name: "Argentina",
    slug: "argentina",
  },
  {
    id: "585e49cd-d42f-4e71-b451-6ecb163810a4",
    code: "AS",
    name: "Samoa Americana",
    slug: "samoa-americana",
  },
  {
    id: "b00cae7b-edbc-4fd4-bf21-118b1271ef9a",
    code: "AT",
    name: "Austria",
    slug: "austria",
  },
  {
    id: "1608d55d-0a10-44be-a7ec-7d88fbd4ef19",
    code: "AU",
    name: "Australia",
    slug: "australia",
  },
  {
    id: "831abf68-aaec-4782-a0e0-52954551cd1a",
    code: "AW",
    name: "Aruba",
    slug: "aruba",
  },
  {
    id: "ecf93f1f-e1e6-4135-b8cd-20f483370353",
    code: "AX",
    name: "Islas Åland",
    slug: "islas-aaland",
  },
  {
    id: "448c2e0b-a0db-44d7-8665-021c37c1adf1",
    code: "AZ",
    name: "Azerbaiyán",
    slug: "azerbaiyan",
  },
  {
    id: "3803ddae-8095-4947-adc6-5aa7af3222cc",
    code: "BA",
    name: "Bosnia y Herzegovina",
    slug: "bosnia-y-herzegovina",
  },
  {
    id: "d033c3c9-34e1-452b-b27c-24dd5ec803f0",
    code: "BB",
    name: "Barbados",
    slug: "barbados",
  },
  {
    id: "8d56857b-d411-4d27-b84e-29402b671c7e",
    code: "BD",
    name: "Bangladés",
    slug: "banglades",
  },
  {
    id: "18d49113-45e7-4553-aade-1c469bcf06bd",
    code: "BE",
    name: "Bélgica",
    slug: "belgica",
  },
  {
    id: "2cbe0748-300e-42b2-a7c1-f7735f286a6a",
    code: "BF",
    name: "Burkina Faso",
    slug: "burkina-faso",
  },
  {
    id: "01c18f55-809b-4d66-b940-704bb48508f3",
    code: "BG",
    name: "Bulgaria",
    slug: "bulgaria",
  },
  {
    id: "9ce783be-3438-46af-9eb3-165d51f019d8",
    code: "BH",
    name: "Baréin",
    slug: "barein",
  },
  {
    id: "e8588cb5-8376-4be8-ab3f-a76b77e8382d",
    code: "BI",
    name: "Burundi",
    slug: "burundi",
  },
  {
    id: "e7a68020-2464-45be-8fc6-25aaa698aed9",
    code: "BJ",
    name: "Benín",
    slug: "benin",
  },
  {
    id: "5fd1f448-d760-409e-8fb1-903143393acf",
    code: "BL",
    name: "San Bartolomé",
    slug: "san-bartolome",
  },
  {
    id: "f754e5a2-5704-41ae-9410-93afad35a50e",
    code: "BM",
    name: "Bermudas",
    slug: "bermudas",
  },
  {
    id: "23a319b5-decb-4dfb-af6e-44c311d58db0",
    code: "BN",
    name: "Brunéi Darussalam",
    slug: "brunei-darussalam",
  },
  {
    id: "d34471c9-7d21-497b-836a-61bd2e481343",
    code: "BO",
    name: "Bolivia, Estado Plurinacional de",
    slug: "bolivia-estado-plurinacional-de",
  },
  {
    id: "3043264d-4c66-4945-a96b-047118930b0a",
    code: "BQ",
    name: "Bonaire, San Eustaquio y Saba",
    slug: "bonaire-san-eustaquio-y-saba",
  },
  {
    id: "021253b2-74d1-47e0-ac38-e2877f8532dd",
    code: "BR",
    name: "Brasil",
    slug: "brasil",
  },
  {
    id: "fc32dbde-60d7-4493-84c1-9708f7fb67ec",
    code: "BS",
    name: "Bahamas",
    slug: "bahamas",
  },
  {
    id: "08d6d7b4-e8ca-45b8-9727-7a81afcd9ccc",
    code: "BT",
    name: "Bután",
    slug: "butan",
  },
  {
    id: "46504140-393f-462a-92e6-d47358b74a5e",
    code: "BV",
    name: "Isla Bouvet",
    slug: "isla-bouvet",
  },
  {
    id: "8d7539d5-405c-43f1-9770-8c32701efe06",
    code: "BW",
    name: "Botsuana",
    slug: "botsuana",
  },
  {
    id: "d21a70f1-1021-4953-b99b-81b500e6248d",
    code: "BY",
    name: "Bielorrusia",
    slug: "bielorrusia",
  },
  {
    id: "ba2c6ea3-41fd-40f6-96c3-d7f82e1bd56d",
    code: "BZ",
    name: "Belice",
    slug: "belice",
  },
  {
    id: "f1dcd15b-1c70-4ee5-8786-7b7fc8a8bd62",
    code: "CA",
    name: "Canadá",
    slug: "canada",
  },
  {
    id: "01d876d0-e0ec-4d0d-88b2-1e3b3941fc42",
    code: "CC",
    name: "Islas Cocos (Keeling)",
    slug: "islas-cocos-keeling",
  },
  {
    id: "e87e44de-7eb9-4f76-ba7d-b170ff63de28",
    code: "CD",
    name: "Congo, La República Democrática del",
    slug: "congo-la-republica-democratica-del",
  },
  {
    id: "100b4d8d-6cc6-4cad-995a-8ee185ec37fb",
    code: "CF",
    name: "República Centroafricana",
    slug: "republica-centroafricana",
  },
  {
    id: "f78dc80f-0e5b-490b-b05b-73894bcbf639",
    code: "CG",
    name: "Congo",
    slug: "congo",
  },
  {
    id: "9ce797ba-76fe-49c7-95d7-e47e3a8dd0a3",
    code: "CH",
    name: "Suiza",
    slug: "suiza",
  },
  {
    id: "39802cd4-c669-49ad-9825-b4a442e8c86f",
    code: "CI",
    name: "Costa de Marfil",
    slug: "costa-de-marfil",
  },
  {
    id: "9833e6f3-2533-49a2-9df9-bad8c4a05ae8",
    code: "CK",
    name: "Islas Cook",
    slug: "islas-cook",
  },
  {
    id: "91ee63d8-f6ee-466a-a977-fd88e0f8a7f1",
    code: "CL",
    name: "Chile",
    slug: "chile",
  },
  {
    id: "d3f58fbe-3572-4298-b9c0-ab116d4bd245",
    code: "CM",
    name: "Camerún",
    slug: "camerun",
  },
  {
    id: "14dcbac5-26f0-4a6b-b477-d4dffce70673",
    code: "CN",
    name: "China, República Popular",
    slug: "china-republica-popular",
  },
  {
    id: "22601a01-a212-46bc-8bfc-aab8710a0587",
    code: "CO",
    name: "Colombia",
    slug: "colombia",
  },
  {
    id: "44e0d8dc-88e2-48bc-9670-84699c4bf48c",
    code: "CR",
    name: "Costa Rica",
    slug: "costa-rica",
  },
  {
    id: "2916ab09-c5ee-4af9-bd91-04fbd2654764",
    code: "CU",
    name: "Cuba",
    slug: "cuba",
  },
  {
    id: "15445ce9-9c0f-4b52-8a8c-a27409fc92dc",
    code: "CV",
    name: "Cabo Verde",
    slug: "cabo-verde",
  },
  {
    id: "2fa69157-2f3a-436a-af46-10de77ddd60f",
    code: "CW",
    name: "Curazao",
    slug: "curazao",
  },
  {
    id: "c14955ab-da5c-4675-ab45-ace7a6c5f459",
    code: "CX",
    name: "Isla de Navidad",
    slug: "isla-de-navidad",
  },
  {
    id: "dfc7586f-6b0f-4558-b862-7c4fc85eb6a4",
    code: "CY",
    name: "Chipre",
    slug: "chipre",
  },
  {
    id: "2c37599c-3329-49ac-a36c-ac41054d15e5",
    code: "CZ",
    name: "República Checa",
    slug: "republica-checa",
  },
  {
    id: "fce4efc7-fb77-41ba-b271-71b94cf7597b",
    code: "DE",
    name: "Alemania",
    slug: "alemania",
  },
  {
    id: "03d01b8f-b945-418d-ba44-b194ac27dd99",
    code: "DJ",
    name: "Yibuti",
    slug: "yibuti",
  },
  {
    id: "c80d66d9-a227-4583-95a6-273a0efab316",
    code: "DK",
    name: "Dinamarca",
    slug: "dinamarca",
  },
  {
    id: "f7b95b29-3043-411e-8b88-86f5ffe282c0",
    code: "DM",
    name: "Dominica",
    slug: "dominica",
  },
  {
    id: "34d8eccb-8e80-428e-8fef-802a43b1ec88",
    code: "DO",
    name: "República Dominicana",
    slug: "republica-dominicana",
  },
  {
    id: "2173ebc8-00e3-49e8-b841-0453d1b2a772",
    code: "DZ",
    name: "Argelia",
    slug: "argelia",
  },
  {
    id: "1d4045c3-34d7-41b1-8d95-f8550554cec5",
    code: "EC",
    name: "Ecuador",
    slug: "ecuador",
  },
  {
    id: "84306be8-d4b6-4c19-ad79-9d3234e1c407",
    code: "EE",
    name: "Estonia",
    slug: "estonia",
  },
  {
    id: "6d7b611d-280f-4872-b660-70f8bf530353",
    code: "EG",
    name: "Egipto",
    slug: "egipto",
  },
  {
    id: "0e4a5675-1b89-4b9f-bdb3-2bdcc1baf2c8",
    code: "EH",
    name: "Sahara Occidental",
    slug: "sahara-occidental",
  },
  {
    id: "b58a3de7-9a95-4d69-aebf-f61e08367611",
    code: "ER",
    name: "Eritrea",
    slug: "eritrea",
  },
  {
    id: "f2077d10-ae1d-4afa-90a0-35fc56d637c2",
    code: "ES",
    name: "España",
    slug: "espana",
  },
  {
    id: "acee380c-f74f-4be1-9d83-49ba6a5627c8",
    code: "ET",
    name: "Etiopía",
    slug: "etiopia",
  },
  {
    id: "b238c708-158c-43eb-8869-34c00e5af7a2",
    code: "FI",
    name: "Finlandia",
    slug: "finlandia",
  },
  {
    id: "ea51543c-157e-44b3-883c-70d11de5857b",
    code: "FJ",
    name: "Fiyi",
    slug: "fiyi",
  },
  {
    id: "47c1aef9-50bc-4c3b-8d87-5447f1dc6e38",
    code: "FK",
    name: "Islas Falkland (Malvinas)",
    slug: "islas-falkland-malvinas",
  },
  {
    id: "2ab90077-2374-40e0-934a-ac3bc35f50c1",
    code: "FM",
    name: "Micronesia, Estados Federados de",
    slug: "micronesia-estados-federados-de",
  },
  {
    id: "5918ea35-6b0f-4c52-89d6-8616a19d5c18",
    code: "FO",
    name: "Islas Feroe",
    slug: "islas-feroe",
  },
  {
    id: "ddf57cae-28c8-4aa5-8822-1fa41663441a",
    code: "FR",
    name: "Francia",
    slug: "francia",
  },
  {
    id: "17d70c94-7e06-43c6-9a61-37a3e0c0066b",
    code: "GA",
    name: "Gabón",
    slug: "gabon",
  },
  {
    id: "82901e07-3036-41f0-ac16-3f12c9cb8738",
    code: "GB",
    name: "Reino Unido",
    slug: "reino-unido",
  },
  {
    id: "c8569c01-0101-45e0-9531-c0a150b0f17d",
    code: "GD",
    name: "Granada",
    slug: "granada",
  },
  {
    id: "3b77b61f-61b3-4926-b5e1-f84a8ca5813a",
    code: "GE",
    name: "Georgia",
    slug: "georgia",
  },
  {
    id: "3f0c554c-a8d2-4e34-881f-8610966c82cd",
    code: "GF",
    name: "Guayana Francesa",
    slug: "guayana-francesa",
  },
  {
    id: "906705a2-dd36-4490-b0b7-e87c3ba172de",
    code: "GG",
    name: "Guernsey",
    slug: "guernsey",
  },
  {
    id: "e12d0d47-c96c-4676-bea4-dda4a8daaebe",
    code: "GH",
    name: "Ghana",
    slug: "ghana",
  },
  {
    id: "a64c2d13-e19a-469f-a0e6-eae8384ded48",
    code: "GI",
    name: "Gibraltar",
    slug: "gibraltar",
  },
  {
    id: "dd542a5a-b739-434c-8f09-2696e73e827c",
    code: "GL",
    name: "Groenlandia",
    slug: "groenlandia",
  },
  {
    id: "ffa45a96-1928-49ab-a647-014011f9261a",
    code: "GM",
    name: "Gambia",
    slug: "gambia",
  },
  {
    id: "398409b6-bb65-45e9-935b-cb2abe723344",
    code: "GN",
    name: "Guinea",
    slug: "guinea",
  },
  {
    id: "63953f75-c58d-4327-8577-1878fec95e6c",
    code: "GP",
    name: "Guadalupe",
    slug: "guadalupe",
  },
  {
    id: "642154c5-cf66-467b-932a-b262f600e822",
    code: "GQ",
    name: "Guinea Ecuatorial",
    slug: "guinea-ecuatorial",
  },
  {
    id: "2cf8d35e-aa28-4ff1-b66c-ffb97467ab9b",
    code: "GR",
    name: "Grecia",
    slug: "grecia",
  },
  {
    id: "cd996580-80b1-4ab2-8516-6429ea486507",
    code: "GS",
    name: "Islas Georgias del Sur y Sandwich del Sur",
    slug: "islas-georgias-del-sur-y-sandwich-del-sur",
  },
  {
    id: "2207748e-11f9-471f-ae64-93384726f08b",
    code: "GT",
    name: "Guatemala",
    slug: "guatemala",
  },
  {
    id: "aa1f2b91-cb40-4724-9d29-12a0b656bf20",
    code: "GU",
    name: "Guam",
    slug: "guam",
  },
  {
    id: "2d35fbd8-f6fe-4e48-a68f-b8d61547e4d1",
    code: "GW",
    name: "Guinea-Bisáu",
    slug: "guinea-bisau",
  },
  {
    id: "a76e839f-714f-4c1a-a62b-6856c82e646f",
    code: "GY",
    name: "Guyana",
    slug: "guyana",
  },
  {
    id: "89a88e6f-c636-4aaf-8c54-0ecb5b586140",
    code: "HK",
    name: "Hong Kong",
    slug: "hong-kong",
  },
  {
    id: "4dab026e-5da6-44ab-9d2d-5c612f703e3f",
    code: "HM",
    name: "Islas Heard y Mcdonald",
    slug: "islas-heard-y-mcdonald",
  },
  {
    id: "b104427c-b59c-44d6-9bc2-393a907a9976",
    code: "HN",
    name: "Honduras",
    slug: "honduras",
  },
  {
    id: "e78cc099-153b-46d9-a484-43c613eb3f1e",
    code: "HR",
    name: "Croacia",
    slug: "croacia",
  },
  {
    id: "9b441896-f4fd-4ac5-ad71-d50bc7442231",
    code: "HT",
    name: "Haití",
    slug: "haiti",
  },
  {
    id: "1d14d1fc-4f32-4442-9970-df17a5fed104",
    code: "HU",
    name: "Hungría",
    slug: "hungria",
  },
  {
    id: "8c3f4342-6fc8-40be-af59-891aa3b9f14e",
    code: "ID",
    name: "Indonesia",
    slug: "indonesia",
  },
  {
    id: "dcd00ef7-c0b6-4949-8b19-6cf7e592d1c4",
    code: "IE",
    name: "Irlanda",
    slug: "irlanda",
  },
  {
    id: "88b84022-1aa7-41fd-8c6f-4221b57e06d9",
    code: "IL",
    name: "Israel",
    slug: "israel",
  },
  {
    id: "6fd1c24b-64bf-400d-b680-7d55035ce51b",
    code: "IM",
    name: "Isla de Man",
    slug: "isla-de-man",
  },
  {
    id: "055aa90d-18cd-4fcb-9ac3-e88735e7619e",
    code: "IN",
    name: "India",
    slug: "india",
  },
  {
    id: "db67cd60-4b36-4466-8f38-6317b900c366",
    code: "IO",
    name: "Territorio Británico del Océano Índico",
    slug: "territorio-britanico-del-oceano-indico",
  },
  {
    id: "1ed0b5d5-1656-44bb-9b3d-94de64ad1f9e",
    code: "IQ",
    name: "Irak",
    slug: "irak",
  },
  {
    id: "e2a5f361-1626-4eba-afa4-e9196b2576e8",
    code: "IR",
    name: "Irán, República Islámica de",
    slug: "iran-republica-islamica-de",
  },
  {
    id: "ad78a411-3d22-4c19-9f48-a87aa319f184",
    code: "IS",
    name: "Islandia",
    slug: "islandia",
  },
  {
    id: "8073c2a7-225d-40ab-96af-2892fb97b3b4",
    code: "IT",
    name: "Italia",
    slug: "italia",
  },
  {
    id: "38e7e4fe-14ea-4ffa-8ff7-cd8f29534583",
    code: "JE",
    name: "Jersey",
    slug: "jersey",
  },
  {
    id: "4c266f74-3918-4985-8a39-7d4a6f9a959c",
    code: "JM",
    name: "Jamaica",
    slug: "jamaica",
  },
  {
    id: "aa80e899-de04-4a18-8238-c0699bcedbcc",
    code: "JO",
    name: "Jordania",
    slug: "jordania",
  },
  {
    id: "bc7151fe-9a5a-4381-9558-8ef8a74a38a9",
    code: "JP",
    name: "Japón",
    slug: "japon",
  },
  {
    id: "bedb24e9-1970-4ce2-b597-2c908b0cc050",
    code: "KE",
    name: "Kenia",
    slug: "kenia",
  },
  {
    id: "4b3b5d21-8920-46b3-b840-3dcab38d0a03",
    code: "KG",
    name: "Kirguistán",
    slug: "kirguistan",
  },
  {
    id: "4347a6b7-ccf4-4ece-9769-cc3cd447921c",
    code: "KH",
    name: "Camboya",
    slug: "camboya",
  },
  {
    id: "edcc18e6-64e1-4108-b7fa-e69857604d1c",
    code: "KI",
    name: "Kiribati",
    slug: "kiribati",
  },
  {
    id: "26016777-da3a-4dd2-9c4e-e5d47f85e936",
    code: "KM",
    name: "Comoras",
    slug: "comoras",
  },
  {
    id: "e8923281-191e-407b-9fe3-d3f3a8536f51",
    code: "KN",
    name: "San Cristóbal y Nieves",
    slug: "san-cristobal-y-nieves",
  },
  {
    id: "2c759e69-f16b-4837-8050-df16e7d70ced",
    code: "KP",
    name: "Corea, República Democrática Popular de",
    slug: "corea-republica-democratica-popular-de",
  },
  {
    id: "2bf49fcb-2fa5-486d-99f4-d4745c7752a2",
    code: "KR",
    name: "Corea, República de",
    slug: "corea-republica-de",
  },
  {
    id: "c93508ac-2225-4b08-9507-0f7dfe027323",
    code: "KW",
    name: "Kuwait",
    slug: "kuwait",
  },
  {
    id: "a620c692-6e5b-4dd8-8880-6228f09f1ed3",
    code: "KY",
    name: "Islas Caimán",
    slug: "islas-caiman",
  },
  {
    id: "9d5c8049-df22-407c-a7ff-eacccd764e9b",
    code: "KZ",
    name: "Kazajistán",
    slug: "kazajistan",
  },
  {
    id: "0752671a-b7cd-4751-9af7-ee408fcd0420",
    code: "LA",
    name: "República Democrática Popular Lao",
    slug: "republica-democratica-popular-lao",
  },
  {
    id: "e7fdea89-fd2f-4f79-80f6-c2efbcdfd59d",
    code: "LB",
    name: "Líbano",
    slug: "libano",
  },
  {
    id: "d82634f1-8fbf-406e-9019-883b62e996c2",
    code: "LC",
    name: "Santa Lucía",
    slug: "santa-lucia",
  },
  {
    id: "149e44a8-0f6a-4120-a3a6-055b2bb048f5",
    code: "LI",
    name: "Liechtenstein",
    slug: "liechtenstein",
  },
  {
    id: "c723b8cf-5b1e-426a-8e3a-38b608771a4c",
    code: "LK",
    name: "Sri Lanka",
    slug: "sri-lanka",
  },
  {
    id: "67a99d9c-f52a-4cc8-92d5-23362dc817ae",
    code: "LR",
    name: "Liberia",
    slug: "liberia",
  },
  {
    id: "9ff80148-3c21-44ca-8cd2-aa463224c11c",
    code: "LS",
    name: "Lesoto",
    slug: "lesoto",
  },
  {
    id: "f4ec4d81-ba1a-48da-8b97-02965d9ca281",
    code: "LT",
    name: "Lituania",
    slug: "lituania",
  },
  {
    id: "6faa7861-4a65-45f9-a57a-4b1cd396e5ba",
    code: "LU",
    name: "Luxemburgo",
    slug: "luxemburgo",
  },
  {
    id: "a1d4f082-cd0f-4459-81c5-36d600e83d69",
    code: "LV",
    name: "Letonia",
    slug: "letonia",
  },
  {
    id: "933cf90e-086a-4911-b1fe-f3b964486b2a",
    code: "LY",
    name: "Libia",
    slug: "libia",
  },
  {
    id: "ed2ff4cb-c1d9-482b-86f4-a8116e1c3906",
    code: "MA",
    name: "Marruecos",
    slug: "marruecos",
  },
  {
    id: "6528c6a3-1452-447a-a72b-751fa0af1cb1",
    code: "MC",
    name: "Mónaco",
    slug: "monaco",
  },
  {
    id: "c956e4a6-c027-4dca-92b0-b91a793617d3",
    code: "MD",
    name: "Moldavia, República de",
    slug: "moldavia-republica-de",
  },
  {
    id: "e72fa3a9-3e49-4b29-baba-d079ec171acb",
    code: "ME",
    name: "Montenegro",
    slug: "montenegro",
  },
  {
    id: "8f861023-eaa0-4a04-a489-4bbf0753fcd6",
    code: "MF",
    name: "San Martín (Parte Francesa)",
    slug: "san-martin-parte-francesa",
  },
  {
    id: "a04664c3-82ff-471d-80f4-b6e05fbb92ef",
    code: "MG",
    name: "Madagascar",
    slug: "madagascar",
  },
  {
    id: "90a01b41-6260-4846-8f5e-33b3facdc03b",
    code: "MH",
    name: "Islas Marshall",
    slug: "islas-marshall",
  },
  {
    id: "1ea5a6aa-e5e7-4a5d-abed-ac99e00d47c4",
    code: "MK",
    name: "Macedonia, La Antigua República Yugoslava de",
    slug: "macedonia-la-antigua-republica-yugoslava-de",
  },
  {
    id: "eb75d108-a517-4213-ba5a-53ad80bee536",
    code: "ML",
    name: "Malí",
    slug: "mali",
  },
  {
    id: "39c9c67c-73f2-4c21-8fad-0a68ba8f71a7",
    code: "MM",
    name: "Myanmar",
    slug: "myanmar",
  },
  {
    id: "f094c8fd-6574-4b96-93f6-8544839f6f20",
    code: "MN",
    name: "Mongolia",
    slug: "mongolia",
  },
  {
    id: "a2f53cfa-3cf8-46a4-80a1-0549547f188a",
    code: "MO",
    name: "Macao",
    slug: "macao",
  },
  {
    id: "1b1a6aa8-451b-44fb-9145-9fc059d662c1",
    code: "MP",
    name: "Islas Marianas del Norte",
    slug: "islas-marianas-del-norte",
  },
  {
    id: "492c9e51-c93d-47bb-88bd-cfdb677581bc",
    code: "MQ",
    name: "Martinica",
    slug: "martinica",
  },
  {
    id: "1e09913b-fdb4-4215-951b-66c4eb17ca1d",
    code: "MR",
    name: "Mauritania",
    slug: "mauritania",
  },
  {
    id: "554f6555-d270-4d6e-8566-1350cdc893c4",
    code: "MS",
    name: "Montserrat",
    slug: "montserrat",
  },
  {
    id: "046e2f66-df9d-4b14-973b-167e9ebf3c88",
    code: "MT",
    name: "Malta",
    slug: "malta",
  },
  {
    id: "965c1670-46ef-4f22-9210-aecac8d75d28",
    code: "MU",
    name: "Mauricio",
    slug: "mauricio",
  },
  {
    id: "08986dd7-1d0a-45a7-a3d4-444737a8eefd",
    code: "MV",
    name: "Maldivas",
    slug: "maldivas",
  },
  {
    id: "0977822a-efb5-419f-9026-0c36fa2e7729",
    code: "MW",
    name: "Malaui",
    slug: "malaui",
  },
  {
    id: "3622603d-8170-4892-9afa-3945e81ff477",
    code: "MX",
    name: "México",
    slug: "mexico",
  },
  {
    id: "c0c7fbd9-675b-4ffa-93e3-c70eb5a4b550",
    code: "MY",
    name: "Malasia",
    slug: "malasia",
  },
  {
    id: "c95b90eb-8db4-406a-a771-94c54e2d6d5f",
    code: "MZ",
    name: "Mozambique",
    slug: "mozambique",
  },
  {
    id: "7b2779f5-919b-46b1-bf14-e72f52cb3300",
    code: "NA",
    name: "Nabimia",
    slug: "nabimia",
  },
  {
    id: "8306a3fb-4efa-473e-80b6-a0fdee9973d9",
    code: "NC",
    name: "Nueva Caledonia",
    slug: "nueva-caledonia",
  },
  {
    id: "bf8b5de5-ae49-440a-b5ce-2c93063e4766",
    code: "NE",
    name: "Níger",
    slug: "niger",
  },
  {
    id: "796b050d-b7a2-42e0-ab4f-05bd2ea2d7bc",
    code: "NF",
    name: "Isla Norfolk",
    slug: "isla-norfolk",
  },
  {
    id: "59cbb59b-e0b7-4b75-a4e6-486845d2d345",
    code: "NG",
    name: "Nigeria",
    slug: "nigeria",
  },
  {
    id: "c333c3b7-11fc-49b6-8a3b-96b801e02ea8",
    code: "NI",
    name: "Nicaragua",
    slug: "nicaragua",
  },
  {
    id: "16d5bbfd-d1d1-475f-a8eb-4f75aba537ec",
    code: "NL",
    name: "Países Bajos",
    slug: "paises-bajos",
  },
  {
    id: "0714574e-9dbb-40fe-88b0-9994f43da042",
    code: "NO",
    name: "Noruega",
    slug: "noruega",
  },
  {
    id: "4e7144e7-0e1c-4de7-9299-870654fe798d",
    code: "NP",
    name: "Nepal",
    slug: "nepal",
  },
  {
    id: "026bdd06-f9a2-4698-af69-fb1525d2d941",
    code: "NR",
    name: "Nauru",
    slug: "nauru",
  },
  {
    id: "bea986d8-7fbc-4e04-bad4-00c35a2c536e",
    code: "NU",
    name: "Niue",
    slug: "niue",
  },
  {
    id: "3e4de40b-4604-47de-b44b-c99956b67b6e",
    code: "NZ",
    name: "Nueva Zelanda",
    slug: "nueva-zelanda",
  },
  {
    id: "cef0b35f-03b3-45cf-a5db-bffb73bdbd4c",
    code: "OM",
    name: "Omán",
    slug: "oman",
  },
  {
    id: "876c60b4-ecc0-426e-8826-c260c11a37e5",
    code: "PA",
    name: "Panamá",
    slug: "panama",
  },
  {
    id: "86cc28e6-f3e0-428f-bc4b-733382b68b50",
    code: "PE",
    name: "Perú",
    slug: "peru",
  },
  {
    id: "8b5fde5b-e477-4a9f-9d1e-9cd3d42c3a9c",
    code: "PF",
    name: "Polinesia Francesa",
    slug: "polinesia-francesa",
  },
  {
    id: "b39c37ab-c292-4669-a268-87e2aec6bc1c",
    code: "PG",
    name: "Papúa Nueva Guinea",
    slug: "papua-nueva-guinea",
  },
  {
    id: "8d90b3e1-af68-4287-95c6-a0df51fbc533",
    code: "PH",
    name: "Filipinas",
    slug: "filipinas",
  },
  {
    id: "3a6915ab-0af0-492c-82a9-f7ca9b12d4bd",
    code: "PK",
    name: "Pakistán",
    slug: "pakistan",
  },
  {
    id: "faf57990-5b62-45b4-ac8e-025a21e0851d",
    code: "PL",
    name: "Polonia",
    slug: "polonia",
  },
  {
    id: "abb01f4a-0911-4713-a62d-ce09669800df",
    code: "PM",
    name: "San Pedro y Miquelón",
    slug: "san-pedro-y-miquelon",
  },
  {
    id: "351bc176-ed85-40c6-9dee-b83511ba990f",
    code: "PN",
    name: "Pitcairn",
    slug: "pitcairn",
  },
  {
    id: "e8df7e09-8594-47e4-a53c-da04cc973c84",
    code: "PR",
    name: "Puerto Rico",
    slug: "puerto-rico",
  },
  {
    id: "20824890-685f-4faf-bf98-6d2a0ea9d086",
    code: "PS",
    name: "Palestina, Estado de",
    slug: "palestina-estado-de",
  },
  {
    id: "8ae0970a-9090-4a26-b8ef-3b4149831eb5",
    code: "PT",
    name: "Portugal",
    slug: "portugal",
  },
  {
    id: "a0dfbc4b-335b-47c4-8f08-5d7cabdd146e",
    code: "PW",
    name: "Palaos",
    slug: "palaos",
  },
  {
    id: "88d95e14-39ec-4726-8535-d907f1d0666f",
    code: "PY",
    name: "Paraguay",
    slug: "paraguay",
  },
  {
    id: "b62b39e8-2cd1-4352-8da9-7af39e0f6151",
    code: "QA",
    name: "Qatar",
    slug: "qatar",
  },
  {
    id: "f40b7959-c2e9-478a-b3bc-b8c1ec61b35d",
    code: "RE",
    name: "Reunión",
    slug: "reunion",
  },
  {
    id: "5b638074-1af8-4c9e-9b5b-cae00c7d645f",
    code: "RO",
    name: "Rumania",
    slug: "rumania",
  },
  {
    id: "f3010d61-61dd-4a68-b47d-4a117bd1c653",
    code: "RS",
    name: "Serbia",
    slug: "serbia",
  },
  {
    id: "8b97fc56-0cf4-451f-8dcb-3ab53ede3c87",
    code: "RU",
    name: "Federacion Rusa",
    slug: "federacion-rusa",
  },
  {
    id: "4ee5ba66-a6ad-4616-9b89-46217c18e31c",
    code: "RW",
    name: "Ruanda",
    slug: "ruanda",
  },
  {
    id: "8c17cd23-7c03-4210-a97b-13bed7b63311",
    code: "SA",
    name: "Arabia Saudita",
    slug: "arabia-saudita",
  },
  {
    id: "260b38cd-0f7c-424b-b4d7-722388ee0964",
    code: "SB",
    name: "Islas Salomón",
    slug: "islas-salomon",
  },
  {
    id: "fdec4e5e-23fc-46a0-8463-07db0d8476b3",
    code: "SC",
    name: "Seychelles",
    slug: "seychelles",
  },
  {
    id: "409a478d-ac33-41bc-98d0-4344fe3a36f8",
    code: "SD",
    name: "Sudán",
    slug: "sudan",
  },
  {
    id: "57481240-c928-4e95-8302-6906041c7fd6",
    code: "SE",
    name: "Suecia",
    slug: "suecia",
  },
  {
    id: "ea89583d-29d7-4601-b9cf-e0799a670b58",
    code: "SG",
    name: "Singapur",
    slug: "singapur",
  },
  {
    id: "bb8c21d0-32c1-4021-8e26-8ded2cf20ac0",
    code: "SH",
    name: "Santa Helena, Ascensión y Tristán de Acuña",
    slug: "santa-helena-ascension-y-tristan-de-acuna",
  },
  {
    id: "8d854aa0-9744-4866-b8a4-a538068b7477",
    code: "SI",
    name: "Eslovenia",
    slug: "eslovenia",
  },
  {
    id: "289faca4-93c4-45df-aa95-a95f3d9776f0",
    code: "SJ",
    name: "Svalbard y Jan Mayen",
    slug: "svalbard-y-jan-mayen",
  },
  {
    id: "190c4b18-37dc-4e33-83d6-7af60900a716",
    code: "SK",
    name: "Eslovaquia",
    slug: "eslovaquia",
  },
  {
    id: "ee97f201-518f-457a-b61e-030ad1314777",
    code: "SL",
    name: "Sierra Leona",
    slug: "sierra-leona",
  },
  {
    id: "308112bf-60e0-45df-a100-6637eafc4978",
    code: "SM",
    name: "San Marino",
    slug: "san-marino",
  },
  {
    id: "1e3860b5-6b15-412a-8d17-1331d9b496b5",
    code: "SN",
    name: "Senegal",
    slug: "senegal",
  },
  {
    id: "380ac1b9-a4a5-4052-ae23-bf740c55be7a",
    code: "SO",
    name: "Somalia",
    slug: "somalia",
  },
  {
    id: "4b5194d4-fbd0-4afc-8dc4-a26fcb2adfd8",
    code: "SR",
    name: "Surinam",
    slug: "surinam",
  },
  {
    id: "19245b6e-5017-44db-b628-f08ccef8c988",
    code: "SS",
    name: "Sudán del Sur",
    slug: "sudan-del-sur",
  },
  {
    id: "29849882-b12a-4eca-8ed3-bbf78ddc2caa",
    code: "ST",
    name: "Santo Tomé y Principe",
    slug: "santo-tome-y-principe",
  },
  {
    id: "36d934cf-7b09-49ab-86c6-f519aede8379",
    code: "SV",
    name: "El Salvador",
    slug: "el-salvador",
  },
  {
    id: "97605802-185b-4eae-810c-42186b65eb72",
    code: "SX",
    name: "Sint Maarten (Parte Neerlandesa)",
    slug: "sint-maarten-parte-neerlandesa",
  },
  {
    id: "7e7f1e76-8a0b-432e-be9b-986524311a32",
    code: "SY",
    name: "Siria, República Arabe de",
    slug: "siria-republica-arabe-de",
  },
  {
    id: "d1828994-6062-4fde-b093-6e94471a0c50",
    code: "SZ",
    name: "Suazilandia",
    slug: "suazilandia",
  },
  {
    id: "b9ec601c-b499-4591-bcc1-86883675e4e2",
    code: "TC",
    name: "Islas Turcas y Caicos",
    slug: "islas-turcas-y-caicos",
  },
  {
    id: "c46f804c-9294-4ffe-8a46-569960f84a0e",
    code: "TD",
    name: "Chad",
    slug: "chad",
  },
  {
    id: "be5f35bc-2f50-46e2-a8b6-59c2b2d09060",
    code: "TF",
    name: "Territorios Australes Franceses",
    slug: "territorios-australes-franceses",
  },
  {
    id: "e0b0c122-f53f-452c-a207-bbdb7d5e8b0d",
    code: "TG",
    name: "Togo",
    slug: "togo",
  },
  {
    id: "f42bb0b3-2053-422f-9d0a-a666a5229c6d",
    code: "TH",
    name: "Tailandia",
    slug: "tailandia",
  },
  {
    id: "146348ea-4c42-4658-b1fb-a9d97509c9e5",
    code: "TJ",
    name: "Tayikistán",
    slug: "tayikistan",
  },
  {
    id: "5a919c14-1585-4178-8e5f-d270a34d1957",
    code: "TK",
    name: "Tokelau",
    slug: "tokelau",
  },
  {
    id: "be593d16-94d9-449f-a203-c8aae520246a",
    code: "TL",
    name: "Timor-Leste",
    slug: "timor-leste",
  },
  {
    id: "7c79dfe8-4162-44bb-8511-5bd14a506391",
    code: "TM",
    name: "Turkmenistán",
    slug: "turkmenistan",
  },
  {
    id: "e87e1029-91ee-4563-8361-d3eab122979b",
    code: "TN",
    name: "Túnez",
    slug: "tunez",
  },
  {
    id: "1c05c7a5-9974-45ff-87b4-3b3a59b70b6d",
    code: "TO",
    name: "Tonga",
    slug: "tonga",
  },
  {
    id: "c5c4a3ce-a1d7-42f1-99b7-1afdab9985b3",
    code: "TR",
    name: "Turquía",
    slug: "turquia",
  },
  {
    id: "9581f154-8c91-4edf-88ab-bbbc9ba929a5",
    code: "TT",
    name: "Trinidad y Tobago",
    slug: "trinidad-y-tobago",
  },
  {
    id: "44fcbcbe-1f96-44d1-9be3-f3fc2f35fb4d",
    code: "TV",
    name: "Tuvalu",
    slug: "tuvalu",
  },
  {
    id: "c814f6a9-5c5e-497c-9c04-ee7d63bbd4f7",
    code: "TW",
    name: "Taiwán, Provincia de China",
    slug: "taiwan-provincia-de-china",
  },
  {
    id: "79b85f9d-6e06-47be-a3ef-2d7c2895296b",
    code: "TZ",
    name: "Tanzania, República Unida de",
    slug: "tanzania-republica-unida-de",
  },
  {
    id: "2fca716e-b278-4105-9522-ebf386f99fb4",
    code: "UA",
    name: "Ucrania",
    slug: "ucrania",
  },
  {
    id: "581ddb03-8dba-4c6e-b5bb-b8d59d3742e9",
    code: "UG",
    name: "Uganda",
    slug: "uganda",
  },
  {
    id: "76ea0a57-c2bc-4fd4-a02d-f3d66f4645aa",
    code: "UM",
    name: "Islas Ultramarinas Menores de Estados Unidos",
    slug: "islas-ultramarinas-menores-de-estados-unidos",
  },
  {
    id: "48ab001c-6276-4c30-8118-d4f86b079879",
    code: "US",
    name: "Estados Unidos",
    slug: "estados-unidos",
  },
  {
    id: "78b2e3d3-f5e6-4362-88a5-c09bc61ccad6",
    code: "UY",
    name: "Uruguay",
    slug: "uruguay",
  },
  {
    id: "55c9e460-872f-43d1-962f-fbbb87a3d805",
    code: "UZ",
    name: "Uzbekistán",
    slug: "uzbekistan",
  },
  {
    id: "3f34caf8-bbf4-4b95-b38a-b1185bf8c277",
    code: "VA",
    name: "Santa Sede (Ciudad Estado Vaticano)",
    slug: "santa-sede-ciudad-estado-vaticano",
  },
  {
    id: "aaa0ee9a-211e-40ae-9619-4aa518365b79",
    code: "VC",
    name: "San Vicente y Las Granadinas",
    slug: "san-vicente-y-las-granadinas",
  },
  {
    id: "e8480319-6c7d-458b-8df9-279130155998",
    code: "VE",
    name: "Venezuela, República Bolivariana de",
    slug: "venezuela-republica-bolivariana-de",
  },
  {
    id: "b693a8bc-7690-425d-a0e7-a1d06b0982aa",
    code: "VG",
    name: "Islas Virgenes Británicas",
    slug: "islas-virgenes-britanicas",
  },
  {
    id: "bb97a6f7-75f5-40d1-bd9c-f160ab328839",
    code: "VI",
    name: "Islas Virgenes de Los Estados Unidos",
    slug: "islas-virgenes-de-los-estados-unidos",
  },
  {
    id: "23a4bdc7-d436-49c8-95b9-f77db0b84799",
    code: "VN",
    name: "Viet Nam",
    slug: "viet-nam",
  },
  {
    id: "b4cfa044-7da5-43bb-92e1-96a4619c0f92",
    code: "VU",
    name: "Vanuatu",
    slug: "vanuatu",
  },
  {
    id: "59377f64-2cb3-4d20-8b51-6f0c47e2b5bf",
    code: "WF",
    name: "Wallis y Futuna",
    slug: "wallis-y-futuna",
  },
  {
    id: "602542f7-3a61-4a8b-9ac0-0ff80723139c",
    code: "WS",
    name: "Samoa",
    slug: "samoa",
  },
  {
    id: "5e62e991-57f9-4339-a4d5-8ee6efe5bb45",
    code: "YE",
    name: "Yemen",
    slug: "yemen",
  },
  {
    id: "6bead50d-683c-4da7-8f40-1cb8cc568c7f",
    code: "YT",
    name: "Mayotte",
    slug: "mayotte",
  },
  {
    id: "98f151ce-3748-4cac-9067-41b04ca2db27",
    code: "ZA",
    name: "Sudáfrica",
    slug: "sudafrica",
  },
  {
    id: "a84af999-c2b5-4929-a319-9455b0b4f20b",
    code: "ZM",
    name: "Zambia",
    slug: "zambia",
  },
  {
    id: "bf013a97-ff2e-4712-98b8-cdd2ad2b2af9",
    code: "ZW",
    name: "Zimbabue",
    slug: "zimbabue",
  },
];

export function getCountryCodeFromCountryName(countryName: string) {
  const foundCountry = countries.find(
    (country) => country.name === countryName
  );
  return foundCountry ? foundCountry.code : countryName;
}

export const mostUsedCountriesOptionsEs = [
  {
    value: "Argelia",
    label: "Argelia",
  },
  {
    value: "Argentina",
    label: "Argentina",
  },
  {
    value: "Bolivia, Estado Plurinacional de",
    label: "Bolivia",
  },
  {
    value: "Brasil",
    label: "Brasil",
  },
  {
    value: "Bulgaria",
    label: "Bulgaria",
  },
  {
    value: "Camerún",
    label: "Camerún",
  },
  {
    value: "Chile",
    label: "Chile",
  },
  {
    value: "Colombia",
    label: "Colombia",
  },
  {
    value: "Cuba",
    label: "Cuba",
  },
  {
    value: "Ecuador",
    label: "Ecuador",
  },
  {
    value: "El Salvador",
    label: "El Salvador",
  },
  {
    value: "España",
    label: "España",
  },
  {
    value: "Filipinas",
    label: "Filipinas",
  },
  {
    value: "Francia",
    label: "Francia",
  },
  {
    value: "Georgia",
    label: "Georgia",
  },
  {
    value: "Guatemala",
    label: "Guatemala",
  },
  {
    value: "Guinea Ecuatorial",
    label: "Guinea Ecuatorial",
  },
  {
    value: "Honduras",
    label: "Honduras",
  },
  {
    value: "Italia",
    label: "Italia",
  },
  {
    value: "Marruecos",
    label: "Marruecos",
  },
  {
    value: "México",
    label: "México",
  },
  {
    value: "Nicaragua",
    label: "Nicaragua",
  },
  {
    value: "Nigeria",
    label: "Nigeria",
  },
  {
    value: "Paraguay",
    label: "Paraguay",
  },
  {
    value: "Perú",
    label: "Perú",
  },
  {
    value: "Polonia",
    label: "Polonia",
  },
  {
    value: "Portugal",
    label: "Portugal",
  },
  {
    value: "República Dominicana",
    label: "República Dominicana",
  },
  {
    value: "Rumania",
    label: "Rumania",
  },
  {
    value: "Federacion Rusa",
    label: "Rusia",
  },
  {
    value: "Sahara Occidental",
    label: "Sahara Occidental",
  },
  {
    value: "Senegal",
    label: "Senegal",
  },
  {
    value: "Ucrania",
    label: "Ucrania",
  },
  {
    value: "Uruguay",
    label: "Uruguay",
  },
  {
    value: "Venezuela, República Bolivariana de",
    label: "Venezuela",
  },
];

export const mostUserCountriesOptionsFr = [
  {
    value: "Francia",
    label: "France",
  },
  {
    value: "Camerún",
    label: "Cameroun",
  },
  {
    value: "Argelia",
    label: "Algérie",
  },
  {
    value: "Costa de Marfil",
    label: "Côte d'Ivoire",
  },
  {
    value: "Haiti",
    label: "Haïti",
  },
  {
    value: "Marruecos",
    label: "Maroc",
  },
  {
    value: "Senegal",
    label: "Sénégal",
  },
  {
    value: "Congo, La República Democrática del",
    label: "Congo",
  },
  {
    value: "Túnez",
    label: "Tunisie",
  },
  {
    value: "Mali",
    label: "Mali",
  },
  {
    value: "Benín",
    label: "Bénin",
  },
  {
    value: "Congo",
    label: "Congo",
  },
  {
    value: "Portugal",
    label: "le Portugal",
  },
  {
    value: "República Centroafricana",
    label: "République Centrafricaine",
  },
  {
    value: "Brasil",
    label: "Brésil",
  },
  {
    value: "Burkina Faso",
    label: "Burkina Faso",
  },
  {
    value: "Colombia",
    label: "La Colombie",
  },
  {
    value: "Guadalupe",
    label: "Guadalupe",
  },
  {
    value: "Guayana Francesa",
    label: "Guyane Française",
  },
  {
    value: "Italia",
    label: "Italie",
  },
];

export function countryOptions() {
  return countries;
}

export default countries;
