import {
  IconButton,
  Paper,
  Popover,
  Typography,
  Tooltip,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React, { Fragment, useState } from "react";
import styled from "styled-components";
import ZohoIcon from "../../assets/images/iconos/zoho.png";
import cuideoTheme from "../../containers/themes/defaultTheme";
import { FormattedMessage } from "react-intl";

const StyledPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 1.25rem;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledZohoButton = styled(IconButton)`
  width: 1rem;
  height: 1rem;
  padding: 0.25rem;
  margin-bottom: 1.125rem;
`;

const StyledZohoIcon = styled.img``;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledLabel = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-size: 0.875rem;
  opacity: 0.5;
  min-width: 8rem;
`;

const StyledData = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-size: 0.875rem;
`;

const StyledTitle = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 0.75rem;
`;

const StyledViewButton = styled(IconButton)``;

const ContractPopupViewButton = (props: any) => {
  const { contrato } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  return (
    <Fragment>
      <Tooltip
        title={
          <FormattedMessage
            id="ContractPopupViewButton.view"
            defaultMessage="Ver detalles de contrato"
          />
        }
      >
        <StyledViewButton
          color="primary"
          onClick={handleClick}
          className="MuiCuideoContractPopupViewButton"
        >
          <VisibilityIcon />
        </StyledViewButton>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableRestoreFocus
      >
        <StyledPaper>
          <StyledHeader>
            <StyledTitle>{contrato.Name}</StyledTitle>
            <StyledZohoButton
              color="primary"
              onClick={() => {
                window.open(
                  `https://crm.zoho.com/crm/org638326163/tab/CustomModule4/${contrato.zohoId}`
                );
              }}
            >
              <StyledZohoIcon src={ZohoIcon} />
            </StyledZohoButton>
          </StyledHeader>
          <StyledRow>
            <StyledLabel>
              <FormattedMessage
                id="ContractPopup.Client"
                defaultMessage="Cliente"
              />
            </StyledLabel>
            <StyledData>{contrato._Nombre_del_cliente}</StyledData>
          </StyledRow>
          <StyledRow>
            <StyledLabel>
              <FormattedMessage
                id="ContractPopup.ClientPhone"
                defaultMessage="Tlf. Cliente"
              />
            </StyledLabel>
            <StyledData>{contrato._Tel_fono_cliente}</StyledData>
          </StyledRow>
          {contrato.customer && contrato.customer.Email && (
            <StyledRow>
              <StyledLabel>
                <FormattedMessage
                  id="ContractPopup.ClientEmail"
                  defaultMessage="Email Cliente"
                />
              </StyledLabel>
              <StyledData>{contrato.customer.Email}</StyledData>
            </StyledRow>
          )}
          <StyledRow>
            <StyledLabel>
              <FormattedMessage
                id="ContractPopup.Status"
                defaultMessage="Estado"
              />
            </StyledLabel>
            <StyledData>{contrato.Estado}</StyledData>
          </StyledRow>
          <StyledRow>
            <StyledLabel>
              <FormattedMessage
                id="ContractPopup.ContractOwner"
                defaultMessage="Contrato Owner"
              />
            </StyledLabel>
            <StyledData>{contrato.ownerName}</StyledData>
          </StyledRow>
          {contrato.Servicio && contrato.Servicio.Name && (
            <StyledRow>
              <StyledLabel>
                <FormattedMessage
                  id="ContratoPopup.Service"
                  defaultMessage="Servicio"
                />
              </StyledLabel>
              <StyledData>{contrato.Servicio.Name}</StyledData>
            </StyledRow>
          )}
        </StyledPaper>
      </Popover>
    </Fragment>
  );
};

export default ContractPopupViewButton;
