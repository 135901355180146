import { Avatar, Typography } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { push } from "connected-react-router";
import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { userSignOut } from "../../actions/Auth";
import { avatarImgBaseUrl } from "../../config/assetLocations";
import cuideoTheme from "../../containers/themes/defaultTheme";
import { JOB_MY_PROFILE, JOB_OFFERS } from "../../constants/routes";

const StyledWrapper = styled.div``;

const StyledMenuHead = styled(Typography)`
  padding: 1rem 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${cuideoTheme.palette.primary.main};
  background-color: #e5edf1;
  min-height: 4rem;
  font-size: 1.25rem;

  .MuiAvatar-root {
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
  }
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: ${cuideoTheme.palette.primary.main};
  display: block;
  padding: 1.25rem 2rem;
  min-height: 2.5rem;
  line-height: 2.5rem;
  border-bottom: 1px solid ${cuideoTheme.palette.grey[200]};
`;

const StyledLogoutNavLink = styled(NavLink)`
  text-decoration: none;
  color: ${cuideoTheme.palette.primary.light};
  display: block;
  width: 100%;
  padding: 1.25rem 2rem;
  min-height: 2.5rem;
  line-height: 2.5rem;
`;

const StyledItemTitle = styled(Typography)`
  font-size: 1.25rem;
  font-weight: 700;
`;

const StyledItemDescription = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 400;
  white-space: normal;
`;

const StyledScrollWrapper = styled.div`
  max-height: calc(100vh - 10rem);
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const UserMenu = (props: any) => {
  const { authUser, userSignOut, handleClose } = props;

  return (
    <StyledWrapper>
      <StyledMenuHead>
        {authUser.userAvatar ? (
          <Avatar src={avatarImgBaseUrl + authUser.userAvatar} />
        ) : (
          <Avatar>
            <AccountCircleIcon />
          </Avatar>
        )}
        <strong>{authUser.userFullName}</strong>
      </StyledMenuHead>
      <div className="scrollable">
        <StyledScrollWrapper>
          <StyledNavLink
            to={JOB_OFFERS}
            onClick={() => {
              handleClose();
            }}
          >
            <StyledItemTitle>
              <FormattedMessage
                id="UserButton.OffersAvailable"
                defaultMessage="Ofertas disponibles"
              />
            </StyledItemTitle>
            <StyledItemDescription>
              <FormattedMessage
                id="UserButton.OffersAvailableHelp"
                defaultMessage="Busca entre ofertas disponibles e inscribete"
              />
            </StyledItemDescription>
          </StyledNavLink>
          {authUser && authUser.candidato && (
            <StyledNavLink
              to={"/empleo/misOfertas"}
              onClick={() => {
                handleClose();
              }}
            >
              <StyledItemTitle>
                <FormattedMessage
                  id="UserButton.MyOffers"
                  defaultMessage="Mis ofertas"
                />
              </StyledItemTitle>
              <StyledItemDescription>
                <FormattedMessage
                  id="UserButton.MyOffersDesc"
                  defaultMessage="Consulta tus inscripciones en ofertas activas y ofertas pasadas"
                />
              </StyledItemDescription>
            </StyledNavLink>
          )}
          {authUser && authUser.candidato && (
            <StyledNavLink
              to={JOB_MY_PROFILE}
              onClick={() => {
                handleClose();
              }}
            >
              <StyledItemTitle>
                <FormattedMessage
                  id="UserButton.MyProfile"
                  defaultMessage="Mi perfil"
                />
              </StyledItemTitle>
              <StyledItemDescription>
                <FormattedMessage
                  id="UserButton.MyProfileDesc"
                  defaultMessage="Consulta tu perfil y tus datos de perfil"
                />
              </StyledItemDescription>
            </StyledNavLink>
          )}
          <StyledLogoutNavLink
            to={JOB_OFFERS}
            onClick={() => {
              push(JOB_OFFERS);
              if (userSignOut) {
                userSignOut();
                handleClose();
              }
            }}
          >
            <StyledItemTitle>
              <FormattedMessage
                id="UserButton.SignOut"
                defaultMessage="Desconectar"
              />
            </StyledItemTitle>
            <StyledItemDescription>
              <FormattedMessage
                id="UserButton.SignOutDesc"
                defaultMessage="Cierra sesión y desconecta"
              />
            </StyledItemDescription>
          </StyledLogoutNavLink>
        </StyledScrollWrapper>
      </div>
    </StyledWrapper>
  );
};

const mapStateToProps = ({ auth }: { auth: { authUser: any } }) => {
  const { authUser } = auth;

  return { authUser };
};

export default connect(mapStateToProps, { push, userSignOut })(UserMenu);
