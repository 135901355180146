import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { Fragment, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import styled from "styled-components";
import { setOfertasFilters } from "../../../../actions/Empleo";
import useFormatters from "../../../../components/FormattedFields/useFormatters";
import cuideoTheme from "../../../../containers/themes/defaultTheme";
import capitalizeString from "../../../../util/capitalizeString";
import {
  TIPO_EXTERNA,
  TIPO_INTERNA_ENTRE_SEMANA,
  TIPO_INTERNA_FIN_DE_SEMANA,
} from "../../../../util/defaults/contrato";
import LocationIcon from "../../../../assets/images/iconos/location.svg";
import { ProvinceType } from "../../../../types/ProvinceType";
import { CityType } from "../../../../types/CityType";

const StyledLabel = styled.label`
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  color: ${cuideoTheme.palette.primary.main};
  line-height: 1.5;
  font-size: 1.1rem;
  margin-bottom: 0.25rem;
  font-weight: 700;
`;

const StyledFormLabel = styled(FormLabel)`
  font-size: 1.1rem;
  font-weight: 700;
  color: ${cuideoTheme.palette.primary.main};
  margin: 0.4rem 0;
`;

const StyledTextField = styled(TextField)`
  background-color: #e4ebef;
  border-radius: ${cuideoTheme.shape.borderRadius}px;
  border: none;
  height: 2.5rem;
  font-size: 1rem;

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .MuiSelect-select {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: ${cuideoTheme.palette.primary.main};
  }

  .MuiSvgIcon-root {
    color: ${cuideoTheme.palette.primary.main};
  }
`;

const StyledLocationIcon = styled.img`
  width: 1.1rem;
  height: 1.1rem;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  color: ${cuideoTheme.palette.primary.main};

  .MuiTypography-body1 {
    font-size: 1rem;
  }
  .MuiSvgIcon-root {
    width: 1.25rem;
    height: 1.25rem;
    color: ${cuideoTheme.palette.primary.main};
  }
`;

const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  pointer-events: none;
  position: absolute;
  right: 0.5rem;
`;

const StyledFormControl = styled(FormControl)`
  margin-bottom: 2.5rem;
`;

const StyledViewMore = styled(Typography)`
  cursor: pointer;
  font-size: 1rem;

  color: #00aeff;
`;

const StyledCheckbox = styled(Checkbox)`
  opacity: 0.4;
`;

const StyledFormGroup = styled(FormGroup)`
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    max-height: 12rem;
    overflow-y: scroll;
    flex-wrap: nowrap;

    ::-webkit-scrollbar {
      width: 0.5rem;
      border-radius: 0.25rem;
    }
    ::-webkit-scrollbar-track {
      background-color: #ebefe4;
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${cuideoTheme.palette.primary.light};
      border-radius: 0.25rem;
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: ${cuideoTheme.palette.primary.main};
    }
  }
`;

interface Props {
  provinces?: ProvinceType[];
  cities?: CityType[];
  filtrosBusqueda: any;
  setOfertasFilters: Function;
  ofertas: any;
}

const sortCiudades = (a: any, b: any) => {
  if (a.numOfertas > b.numOfertas) {
    return -1;
  }
  if (a.numOfertas < b.numOfertas) {
    return 1;
  }
  return 0;
};

const OfertasFilter = ({
  provinces = [],
  cities = [],
  ofertas,
  filtrosBusqueda,
  setOfertasFilters,
}: Props) => {
  const [numCiudadesVisibles, setNumCiudadesVisibles] = useState(4);
  const { formatContractJobType } = useFormatters();

  const listadoProvincias: any = {};
  const filteredOffers = ofertas.filter((offer: any) => {
    if (
      !offer.Provincia_oferta ||
      offer.Provincia_oferta === "A reparar no tiene datos el cliente"
    ) {
      return false;
    }
    if (
      !offer.Ciudad_oferta ||
      offer.Ciudad_oferta === "A reparar no tiene datos el cliente"
    ) {
      return false;
    }
    return true;
  });
  filteredOffers.forEach((oferta: any) => {
    const province = capitalizeString(oferta.Provincia_oferta);
    if (typeof listadoProvincias[province] === "undefined") {
      listadoProvincias[province] = 1;
    } else {
      listadoProvincias[province]++;
    }
  });
  const filtroProvincias: any = [];
  // eslint-disable-next-line
  Object.keys(listadoProvincias).map((item: any, index: any) => {
    filtroProvincias.push({
      provincia: item,
      numOfertas: listadoProvincias[item],
    });
  });

  const provinciaCiudades: any = {};
  filteredOffers.forEach((oferta: any) => {
    const province = capitalizeString(oferta.Provincia_oferta);
    const city = capitalizeString(oferta.Ciudad_oferta);
    if (typeof provinciaCiudades[province] === "undefined") {
      provinciaCiudades[province] = {};
      provinciaCiudades[province][city] = 1;
    } else {
      if (provinciaCiudades[province][city]) {
        provinciaCiudades[province][city] += 1;
      } else {
        provinciaCiudades[province][city] = 1;
      }
    }
  });

  const filtroCiudades: any = [];
  // eslint-disable-next-line
  Object.keys(provinciaCiudades).map((item: any, index: any) => {
    // eslint-disable-next-line
    Object.keys(provinciaCiudades[item]).map((item2: any, index2: any) => {
      filtroCiudades.push({
        ciudad: item2,
        numOfertas: provinciaCiudades[item][item2],
        province: item,
      });
    });
  });

  const sortedCiudades = filtroCiudades.sort(sortCiudades);
  const filteredCiudades = sortedCiudades.filter((item: any) => {
    if (item.ciudad === "null") {
      return false;
    }
    if (filtrosBusqueda.provincia) {
      if (filtrosBusqueda.provincia !== item.province) {
        return false;
      }
    }
    return true;
  });

  const numCiudades = filteredCiudades.length;

  const handleClickMoreCiudades = () => {
    setNumCiudadesVisibles(numCiudadesVisibles + 2);
  };

  return (
    <Fragment>
      <StyledFormControl fullWidth>
        <FormControl variant="outlined" fullWidth>
          <StyledLabel htmlFor="filtroProvincia">
            <FormattedMessage
              id="OffersFilter.Province"
              defaultMessage="Provincia:"
            />
          </StyledLabel>

          <StyledTextField
            variant="outlined"
            select
            value={filtrosBusqueda.provincia ? filtrosBusqueda.provincia : ""}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <StyledLocationIcon src={LocationIcon} />
                </InputAdornment>
              ),
            }}
            SelectProps={{
              IconComponent: () => <StyledExpandMoreIcon />,
              displayEmpty: true,
            }}
            onChange={(e: any) => {
              setOfertasFilters(e.target.value, [], []);
              window.scrollTo(0, 0);
            }}
          >
            <MenuItem value="">
              <FormattedMessage
                id="OffersFilter.AllProvinces"
                defaultMessage="Todas las provincias"
              />
            </MenuItem>
            {provinces.map((item: any) => (
              <MenuItem value={item.name} key={item.name}>
                {item.name} ({item.total})
              </MenuItem>
            ))}
          </StyledTextField>
        </FormControl>
      </StyledFormControl>
      {!!cities.length && (
        <StyledFormControl fullWidth>
          <StyledFormLabel>
            <FormattedMessage
              id="OffersFilter.Cities"
              defaultMessage="Ciudades:"
            />
          </StyledFormLabel>
          <StyledFormGroup>
            {cities.map((item: any) => {
              return (
                <StyledFormControlLabel
                  key={item.name}
                  label={`${item.name} (${item.total})`}
                  control={
                    <StyledCheckbox
                      checked={
                        filtrosBusqueda.ciudades.indexOf(item.name) !== -1
                      }
                      onChange={(e: any) => {
                        const newVal = e.target.checked;

                        const nuevasCiudades: string[] = [
                          ...filtrosBusqueda.ciudades,
                        ];
                        if (newVal) {
                          if (nuevasCiudades.indexOf(item.name) === -1) {
                            nuevasCiudades.push(item.name);
                          }
                        } else {
                          const index = nuevasCiudades.indexOf(item.name);
                          if (index > -1) {
                            nuevasCiudades.splice(index, 1);
                          }
                        }

                        setOfertasFilters(
                          filtrosBusqueda.provincia,
                          nuevasCiudades,
                          filtrosBusqueda.disponibilidad
                        );
                        window.scrollTo(0, 0);
                      }}
                      value={item.name}
                      size="small"
                    />
                  }
                />
              );
            })}
          </StyledFormGroup>
          {numCiudades > numCiudadesVisibles && (
            <StyledViewMore role="button" onClick={handleClickMoreCiudades}>
              <FormattedMessage
                id="OffersFilter.ViewMoreCities"
                defaultMessage="Ver más ciudades"
              />
            </StyledViewMore>
          )}
        </StyledFormControl>
      )}
      <StyledFormControl fullWidth>
        <StyledFormLabel>
          <FormattedMessage
            id="OffersFilter.Availability"
            defaultMessage="Disponibilidad/horarios:"
          />
        </StyledFormLabel>
        <FormGroup>
          <StyledFormControlLabel
            control={
              <StyledCheckbox
                checked={
                  filtrosBusqueda.disponibilidad.indexOf(
                    "Interna entre semana"
                  ) !== -1
                }
                onChange={(e: any) => {
                  const newVal = e.target.checked;

                  const nuevaDisponibilidad: string[] = [
                    ...filtrosBusqueda.disponibilidad,
                  ];
                  if (newVal) {
                    if (
                      nuevaDisponibilidad.indexOf("Interna entre semana") === -1
                    ) {
                      nuevaDisponibilidad.push("Interna entre semana");
                    }
                  } else {
                    const index = nuevaDisponibilidad.indexOf(
                      "Interna entre semana"
                    );
                    if (index > -1) {
                      nuevaDisponibilidad.splice(index, 1);
                    }
                  }

                  setOfertasFilters(
                    filtrosBusqueda.provincia,
                    filtrosBusqueda.ciudades,
                    nuevaDisponibilidad
                  );
                  window.scrollTo(0, 0);
                }}
                value="Interna entre semana"
              />
            }
            label={formatContractJobType(TIPO_INTERNA_ENTRE_SEMANA)}
          />
          <StyledFormControlLabel
            control={
              <StyledCheckbox
                value="Interna fin de semana"
                checked={
                  filtrosBusqueda.disponibilidad.indexOf(
                    "Interna fin de semana"
                  ) !== -1
                }
                onChange={(e: any) => {
                  const newVal = e.target.checked;

                  const nuevaDisponibilidad: string[] = [
                    ...filtrosBusqueda.disponibilidad,
                  ];
                  if (newVal) {
                    if (
                      nuevaDisponibilidad.indexOf("Interna fin de semana") ===
                      -1
                    ) {
                      nuevaDisponibilidad.push("Interna fin de semana");
                    }
                  } else {
                    const index = nuevaDisponibilidad.indexOf(
                      "Interna fin de semana"
                    );
                    if (index > -1) {
                      nuevaDisponibilidad.splice(index, 1);
                    }
                  }

                  setOfertasFilters(
                    filtrosBusqueda.provincia,
                    filtrosBusqueda.ciudades,
                    nuevaDisponibilidad
                  );
                  window.scrollTo(0, 0);
                }}
              />
            }
            label={formatContractJobType(TIPO_INTERNA_FIN_DE_SEMANA)}
          />
          <StyledFormControlLabel
            control={
              <StyledCheckbox
                value="Externa"
                checked={
                  filtrosBusqueda.disponibilidad.indexOf(
                    "Externa"
                  ) !== -1
                }
                onChange={(e: any) => {
                  const newVal = e.target.checked;

                  const nuevaDisponibilidad: string[] = [
                    ...filtrosBusqueda.disponibilidad,
                  ];
                  if (newVal) {
                    if (
                      nuevaDisponibilidad.indexOf("Externa") ===
                      -1
                    ) {
                      nuevaDisponibilidad.push("Externa");
                    }
                  } else {
                    const index = nuevaDisponibilidad.indexOf(
                      "Externa"
                    );
                    if (index > -1) {
                      nuevaDisponibilidad.splice(index, 1);
                    }
                  }

                  setOfertasFilters(
                    filtrosBusqueda.provincia,
                    filtrosBusqueda.ciudades,
                    nuevaDisponibilidad
                  );
                  window.scrollTo(0, 0);
                }}
              />
            }
            label={formatContractJobType(TIPO_EXTERNA)}
          />
        </FormGroup>
      </StyledFormControl>
    </Fragment>
  );
};

const mapStateToProps = ({
  commonData,
  empleo,
}: {
  commonData: any;
  empleo: any;
}) => {
  const { ofertas, filtrosBusqueda } = empleo;
  return { ofertas, filtrosBusqueda };
};

export default connect(mapStateToProps, { setOfertasFilters })(OfertasFilter);
