import { CircularProgress, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import {
  CONTRACT_PHASE,
  TGetContractsCollectionItem,
} from "../../../../../../api/contract/contract.types";
import { cuideoTheme } from "../../../../../../containers/themes/defaultTheme";
import {
  CONTRATO_ESTADO_ALTA_PENDIENTE,
  CONTRATO_ESTADO_CV_CLIENTE,
  CONTRATO_ESTADO_PRESENCIAL_CLIENTE,
} from "../../../../../../util/defaults/contrato";
import { useConnectedUsers } from "../../../connectedUser";
import { useInfiniteContracts } from "../../../contract";
import {
  ColoredAvatar,
  UserActiveLast,
  UserTags,
  useUser,
} from "../../../user";
import {
  TChangeOrderFn,
  TRecruiterManagerRouteFilters,
} from "../../routes/RecruiterManagerRoute/hooks/useRecruiterManagerRouteFilters";
import ContractsTable from "./ContractsTable";

const StyledWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledLoadingWrapper = styled.div`
  margin-top: calc(50vh - 4rem);
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const StyledTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;
`;

const StyledTopLeft = styled.div``;

const StyledTopRight = styled.div``;

const StyledBottom = styled.div`
  flex: 1 1 auto;
  margin: 1.5rem 0 0;
`;

const StyledRecruiterData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  .MuiAvatar-root {
    width: 3.5rem;
    height: 3.5rem;
    font-size: 2rem;
  }
`;

const StyledRecruiterDataRight = styled.div`
  .MuiChip-root {
    padding: 0.125rem 0.25rem;
    border-radius: 0.25rem;
    background-color: rgba(0, 72, 120, 0.1);
    font-size: 0.75rem;
    line-height: 1;
    height: 1.25rem;
    color: ${cuideoTheme.palette.primary.main};
    font-weight: 400;
    margin-right: 0.25rem;

    .MuiChip-label {
      padding: 0 0.25rem;
    }
  }

  .CMuiUserActiveLast {
    margin: 0.25rem 0;
  }
`;

const StyledRecruiterName = styled(Typography)`
  font-size: 1.5rem;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: ${cuideoTheme.palette.primary.main};
`;

interface IRecruiterViewProps {
  recruiterId: string;
  filters: TRecruiterManagerRouteFilters;
  onSelectedContractChange: (contractId: string) => void;
  onChangeTableOrder: TChangeOrderFn;
}

const RecruiterView: React.FC<IRecruiterViewProps> = (props) => {
  const { recruiterId, filters, onSelectedContractChange, onChangeTableOrder } =
    props;

  const userQuery = useUser({
    userId: recruiterId,
  });
  const contractsInfiniteQuery = useInfiniteContracts({
    params: {
      estado: [
        CONTRATO_ESTADO_ALTA_PENDIENTE,
        CONTRATO_ESTADO_CV_CLIENTE,
        CONTRATO_ESTADO_PRESENCIAL_CLIENTE,
      ],
      phase: [CONTRACT_PHASE.RECRUIT],
      itemsPerPage: 50,
      owner: [recruiterId],
      "order[fechaObjetivo]": filters["order[fechaObjetivo]"] ?? undefined,
      "order[cvDate]": filters["order[cvDate]"] ?? undefined,
      "order[createdAt]": filters["order[createdAt]"] ?? undefined,
      "order[squad]": filters["order[squad]"] ?? undefined,
    },
    config: {
      getNextPageParam: (lastPage, pages) => pages.length + 1,
    },
  });
  const { isUserConnected } = useConnectedUsers();

  if (userQuery.isLoading) {
    return (
      <StyledWrapper>
        <StyledLoadingWrapper>
          <CircularProgress thickness={5} size={24} />
        </StyledLoadingWrapper>
      </StyledWrapper>
    );
  }

  const handleLoadMoreContracts = () => {
    contractsInfiniteQuery.fetchNextPage();
  };

  let hasMoreItems = false;
  let totalItems = 0;
  const contracts: TGetContractsCollectionItem[] = [];
  contractsInfiniteQuery.data?.pages.forEach((group, i) => {
    if (i === 0) {
      totalItems = group["hydra:totalItems"] ?? 0;
    }
    group["hydra:member"].forEach((itemContract) => {
      contracts.push(itemContract);
    });
  });
  if (totalItems > contracts.length) {
    hasMoreItems = true;
  }

  return (
    <StyledWrapper key={`RecruiterView--${recruiterId}`}>
      <StyledTop>
        <StyledTopLeft>
          <StyledRecruiterData>
            <ColoredAvatar fullName={userQuery.data?.fullname} />
            <StyledRecruiterDataRight>
              <StyledRecruiterName>
                {userQuery.data?.fullname}
              </StyledRecruiterName>
              {/* <StyledUserDetails>
                {userDetails}
              </StyledUserDetails> */}
              {!!userQuery.data && <UserTags user={userQuery.data} />}
              {!!userQuery.data && (
                <UserActiveLast
                  lastActivityTime={userQuery.data?.lastActivityTime}
                  active={userQuery.data?.active ?? false}
                  enabled={userQuery.data?.enabled}
                  isOnline={isUserConnected(recruiterId)}
                />
              )}
            </StyledRecruiterDataRight>
          </StyledRecruiterData>
        </StyledTopLeft>
        <StyledTopRight></StyledTopRight>
      </StyledTop>
      <StyledBottom>
        <ContractsTable
          contracts={contracts}
          status={contractsInfiniteQuery.status}
          selectedContractId={filters.selectedContractId}
          onSelectedContractChange={onSelectedContractChange}
          hasMoreItems={hasMoreItems}
          onLoadMore={handleLoadMoreContracts}
          filters={filters}
          onChangeTableOrder={onChangeTableOrder}
        />
      </StyledBottom>
    </StyledWrapper>
  );
};

export default RecruiterView;
