export const MESSAGE_BIRD_CHANNEL_BOT = "8d5e40f1-6451-46b1-a704-2beffbaacae2";

export type TWaMessageContent = {
  text?: string;
  interactive?:
    | {
        type: "button";
        body?: {
          text?: string;
        };
      }
    | {
        type: "button_reply";
        reply: {
          id?: string;
          text?: string;
        };
      };
  hsm?: {
    templateName: string;
  };
};

export const MESSAGE_DIRECTION = {
  SENT: "sent",
  RECEIVED: "received",
} as const;

export type TWaMessageDirection =
  typeof MESSAGE_DIRECTION[keyof typeof MESSAGE_DIRECTION];

export const MESSAGE_STATUS = {
  RECEIVED: "received",
  SEND: "sent",
  DELIVERED: "delivered",
  REJECTED: "rejected",
  READ: "read",
  FAILED: "failed",
  PENDING: "pending",
} as const;

export type TWaMessageStatus =
  typeof MESSAGE_STATUS[keyof typeof MESSAGE_STATUS];

export const MESSAGE_TYPE = {
  TEXT: "text",
  HSM: "hsm",
  EVENT: "event",
  INTERACTIVE: "interactive",
} as const;

export type TWaMessageType = typeof MESSAGE_TYPE[keyof typeof MESSAGE_TYPE];

export type TWaMessageError = {
  code: number;
  description: string;
};

export type TGetWaMessagesCollectionItem = {
  id: string;
  externalId: string;
  conversationId: string;
  externalConversationId: string;
  externalChannelId: string;
  authorUserId?: string | null;
  authorFullName?: string | null;
  content: TWaMessageContent;
  direction: TWaMessageDirection;
  from: string;
  to: string;
  status: TWaMessageStatus;
  type: TWaMessageType;
  error?: TWaMessageError;
  createdAt: string;
  updatedAt: string;
};

export const conversationMessageText = (
  message: TGetWaMessagesCollectionItem
): string => {
  if (
    message.content.interactive?.type === "button" &&
    message.content.interactive.body?.text
  ) {
    return message.content.interactive.body.text;
  }

  if (
    message.content.interactive?.type === "button_reply" &&
    message.content.interactive.reply.text
  ) {
    return message.content.interactive.reply.text;
  }

  if (message.content.text) {
    return message.content.text;
  }

  return "*Mensaje no reconocido*";
};
