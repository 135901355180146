import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom";
import { initializeErrorReporting } from "./util/errorReporting";
import { initializePolyfills } from "./util/polyfills";

initializeErrorReporting();
initializePolyfills();

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    integrations: [],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const rootEl = document.getElementById("app-site");

let render = () => {
  const MainApp = require("./MainApp").default;
  ReactDOM.render(<MainApp />, rootEl);
};

render();
