import {
  GET_CANDIDATOS_SUCCESS,
  GET_CANDIDATOS,
  GET_CANDIDATO,
  GET_CANDIDATO_SUCCESS,
  UPDATE_CANDIDATOS,
  UPDATE_CANDIDATOS_TABLE_STATE,
  GET_FAVORITE_CANDIDATOS,
  GET_FAVORITE_CANDIDATOS_SUCCESS,
  FAVORITE_CANDIDATO,
  UNFAVORITE_CANDIDATO,
  FAVORITE_CANDIDATO_SUCCESS,
  UNFAVORITE_CANDIDATO_SUCCESS,
  GET_PARTIAL_CANDIDATO_SUCCESS,
} from "../constants/ActionTypes";

export const getCandidatos = ({
  params,
}: {
  params: { rowsPerPage: number; page: number };
}) => {
  return {
    type: GET_CANDIDATOS,
    payload: params,
  };
};

export const getCandidatosSuccess = ({
  partialCandidatos,
  numCandidatos,
}: {
  partialCandidatos: any[];
  numCandidatos: number;
}) => {
  return {
    type: GET_CANDIDATOS_SUCCESS,
    payload: { partialCandidatos, numCandidatos },
  };
};

export const getFavoriteCandidatos = ({ params }: { params: any }) => {
  return {
    type: GET_FAVORITE_CANDIDATOS,
    payload: params,
  };
};

export const getFavoriteCandidatosSuccess = ({
  allFavoriteCandidatos,
}: {
  allFavoriteCandidatos: any[];
}) => {
  return {
    type: GET_FAVORITE_CANDIDATOS_SUCCESS,
    payload: { allFavoriteCandidatos },
  };
};

export const favoriteCandidato = (candidato: any) => {
  return {
    type: FAVORITE_CANDIDATO,
    payload: candidato,
  };
};

export const favoriteCandidatoSuccess = (candidato: any) => {
  return {
    type: FAVORITE_CANDIDATO_SUCCESS,
    payload: candidato,
  };
};

export const unfavoriteCandidato = (candidato: any) => {
  return {
    type: UNFAVORITE_CANDIDATO,
    payload: candidato,
  };
};

export const unfavoriteCandidatoSuccess = (candidato: any) => {
  return {
    type: UNFAVORITE_CANDIDATO_SUCCESS,
    payload: candidato,
  };
};

export const getCandidato = (candidatoId: string, forceCall = false) => {
  return {
    type: GET_CANDIDATO,
    payload: {
      candidatoId,
      forceCall,
    },
  };
};

export const getCandidatoSuccess = ({
  partialCandidatos,
}: {
  partialCandidatos: any[];
}) => {
  return {
    type: GET_CANDIDATO_SUCCESS,
    payload: { partialCandidatos },
  };
};

export const getPartialCandidatoSuccess = ({
  partialCandidatos,
}: {
  partialCandidatos: any[];
}) => {
  return {
    type: GET_PARTIAL_CANDIDATO_SUCCESS,
    payload: { partialCandidatos },
  };
};

export const updateCandidatosTableState = (tableState: any) => {
  return {
    type: UPDATE_CANDIDATOS_TABLE_STATE,
    payload: tableState,
  };
};

export const updateCandidatos = (
  partialCandidatos: any,
  numCandidatos?: number
) => {
  return {
    type: UPDATE_CANDIDATOS,
    payload: {
      partialCandidatos,
      numCandidatos: numCandidatos ? numCandidatos : partialCandidatos.length,
    },
  };
};
