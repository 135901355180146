import { TGetContractsItem } from "../../../../../api/contract/contract.types";
import {
  contractHasExpiredFaceToFaceAtDate,
  contractHasMoreThan24hSinceCv,
  TContractPartialWithNumbers,
} from "../../contract";

export const showCvWarning = (
  contract?: TContractPartialWithNumbers | TGetContractsItem
) => {
  if (!contract) {
    return false;
  }

  if (!contract.numCvClient) {
    return false;
  }

  return (
    contractHasMoreThan24hSinceCv(contract) &&
    contract.numFaceToFaceClient === 0 &&
    contract.numContracted === 0
  );
};

export const showFaceToFaceWarning = (
  contract:
    | TContractPartialWithNumbers
    | TGetContractsItem
    | undefined = undefined,
  currentTime: Date
) => {
  if (!contract) {
    return false;
  }

  if (!contract.numFaceToFace) {
    return false;
  }

  return (
    contract.phase === "promote" &&
    contractHasExpiredFaceToFaceAtDate(contract, currentTime)
  );
};
