import React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import useUnassignedUnratedServicesQuery from "./hooks/useUnassignedUnratedServicesQuery";
import { StyledIconButton, StyledIconWrapper } from "../../SideNavSC";
import { Badge } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";

const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    color: #fff;
    background-color: #cc1016;
    font-weight: 600;
    top: 1rem;
    right: 0.5rem;
  }
`;

interface IUnratedServicesButtonWithBadgeProps {
  isActive: boolean;
}

const UnratedServicesButtonWithBadge = (
  props: IUnratedServicesButtonWithBadgeProps
) => {
  const { isActive } = props;

  const { formatMessage } = useIntl();
  const unassignedUnratedServices = useUnassignedUnratedServicesQuery();

  return (
    <StyledIconButton
      className={isActive ? "CMui-active" : undefined}
      aria-label={formatMessage({
        id: "SideNav.Services",
        defaultMessage: "Servicios",
      })}
    >
      <StyledBadge
        badgeContent={
          unassignedUnratedServices.data?.unassignedServices.length ?? 0
        }
        overlap="rectangular"
      >
        <StyledIconWrapper>
          <HomeIcon />
        </StyledIconWrapper>
      </StyledBadge>
    </StyledIconButton>
  );
};

export default UnratedServicesButtonWithBadge;
