import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import { FormattedMessage } from "react-intl";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import styled from "styled-components";
import { TGetContractsCollectionItem } from "../../../../../../api/contract/contract.types";
import {
  COLOR_RED_NOTIFICATION,
  cuideoTheme,
} from "../../../../../../containers/themes/defaultTheme";
import { useContractsWithNumbers } from "../../hooks/useContractsWithNumbers";
import {
  TChangeOrderFn,
  TRecruiterManagerRouteFilters,
} from "../../routes/RecruiterManagerRoute/hooks/useRecruiterManagerRouteFilters";
import ContractRow from "./ContractRow";
import { defaultTableColumnsForRecruiterView } from "./contracts-table.util";
import { TContractsTableColumns } from "./ContractsTable.types";
import ContractsTableHead from "./ContractsTableHead";

const StyledWrapper = styled.div`
  height: 100%;
`;

const StyledTableContainer = styled(TableContainer)`
  height: 100%;
  overflow: auto hidden;

  .MuiTable-root {
    height: 100%;
    width: 100%;
    overflow: hidden;

    .MuiTableCell-head {
      color: ${cuideoTheme.palette.primary.main};
      font-size: 0.75rem;
      line-height: 1.25;
      padding: 0.5rem 0.75rem;

      .MuiTableSortLabel-root {
        font-weight: 400;
        color: ${cuideoTheme.palette.primary.main};
      }

      .MuiTableSortLabel-icon {
        font-size: 0.8125rem;
        margin: 0 0 0 0.125rem;
      }
    }

    .MuiTableBody-root {
      .MuiTableRow-root {
        background-color: #fff;
        transition: all 0.1s ease-in-out;

        &:hover {
          background-color: rgb(245, 245, 245);
          cursor: pointer;
        }
        &.CMui-selected {
          background-color: rgb(235, 235, 235);
        }
      }

      .MuiTableCell-body {
        color: ${cuideoTheme.palette.primary.main};
        font-size: 0.9375rem;
        padding: 0.5rem 0.75rem;

        &.CMui-error {
          background-color: ${COLOR_RED_NOTIFICATION};
          color: #fff;
        }
      }
    }
  }

  .CMuiContractsTableList {
    ::-webkit-scrollbar {
      width: 0.25rem;
      height: 0.5rem;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: rgba(0, 72, 120, 0.25);
      width: 0.25rem;
      border-radius: 0.125rem;
    }
  }
`;

const StyledAlert = styled(Alert)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4rem;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
`;

interface IContractsTableProps {
  status: "error" | "loading" | "idle" | "success";
  contracts: TGetContractsCollectionItem[];
  columns?: TContractsTableColumns;
  selectedContractId: string | null;
  onSelectedContractChange: (contractId: string) => void;
  hasMoreItems?: boolean;
  onLoadMore: () => void;
  currentTime?: Date;
  isFetchingMore?: boolean;
  filters: TRecruiterManagerRouteFilters;
  onChangeTableOrder: TChangeOrderFn;
}

const ContractsTable: React.FC<IContractsTableProps> = (props) => {
  const {
    contracts,
    status,
    columns = defaultTableColumnsForRecruiterView,
    selectedContractId,
    onSelectedContractChange,
    hasMoreItems,
    onLoadMore,
    isFetchingMore,
    currentTime = new Date(),
    filters,
    onChangeTableOrder,
  } = props;

  const { contractsWithNumbers, isLoading: queryLoading } =
    useContractsWithNumbers(contracts);

  return (
    <StyledWrapper>
      <StyledTableContainer>
        <AutoSizer>
          {({ height, width }) => {
            return (
              <Table component="div" style={{ height, width }}>
                <TableHead component="div" style={{ width }}>
                  <TableRow
                    component="div"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <ContractsTableHead
                      columns={columns}
                      filters={filters}
                      onChangeTableOrder={onChangeTableOrder}
                    />
                  </TableRow>
                </TableHead>
                <TableBody component="div">
                  {status === "loading" ? (
                    <TableRow component="div" style={{ pointerEvents: "none" }}>
                      <TableCell
                        component="div"
                        colSpan={columns.length}
                        style={{ padding: 0, position: "relative" }}
                      >
                        <LinearProgress
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ) : status === "error" ? (
                    <TableRow component="div" style={{ pointerEvents: "none" }}>
                      <TableCell
                        component="div"
                        colSpan={columns.length}
                        style={{ padding: 0, position: "relative" }}
                      >
                        <StyledAlert severity="error">
                          <FormattedMessage
                            id="ContractsTable.Ha habido algún error cargando los datos"
                            defaultMessage="Ha habido algún error cargando los datos"
                          />
                        </StyledAlert>
                      </TableCell>
                    </TableRow>
                  ) : status === "success" && contracts.length === 0 ? (
                    <TableRow component="div" style={{ pointerEvents: "none" }}>
                      <TableCell
                        component="div"
                        colSpan={columns.length}
                        style={{ padding: 0, position: "relative" }}
                      >
                        <StyledAlert severity="info">
                          <FormattedMessage
                            id="ContractsTable.No se han encontrado contratos"
                            defaultMessage="No se han encontrado contratos"
                          />
                        </StyledAlert>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <AutoSizer disableWidth>
                      {({ height }) => {
                        return (
                          <List
                            className="CMuiContractsTableList"
                            style={{
                              overflow: "hidden auto",
                            }}
                            height={height}
                            width="100%"
                            itemCount={contractsWithNumbers.length}
                            itemSize={41}
                            overscanCount={6}
                            itemKey={(index, data) => data.items[index].id}
                            itemData={{
                              columns,
                              items: contractsWithNumbers,
                              isLoading: queryLoading,
                              currentTime,
                              selectedContractId,
                              onSelectedContractChange,
                            }}
                            onItemsRendered={(props) => {
                              if (isFetchingMore) {
                                return;
                              }
                              if (hasMoreItems) {
                                if (
                                  props.overscanStopIndex >=
                                  contracts.length * 0.9
                                ) {
                                  onLoadMore();
                                }
                              }
                            }}
                          >
                            {ContractRow}
                          </List>
                        );
                      }}
                    </AutoSizer>
                  )}
                </TableBody>
              </Table>
            );
          }}
        </AutoSizer>
      </StyledTableContainer>
    </StyledWrapper>
  );
};

export default ContractsTable;
