import { useLayoutEffect, useRef, useState } from "react";
import { TMessage } from "../../../types/message.type";

export default function useScrollToBottom(messages: TMessage[]) {
  const bottomRef = useRef<HTMLDivElement>(null);

  const [state, setState] = useState(() => {
    return {
      hasScrolledAtStart: false,
    };
  });

  useLayoutEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({
        behavior: state.hasScrolledAtStart ? "smooth" : "auto",
      });
      if (state.hasScrolledAtStart) {
        setState((prevState) => ({
          ...prevState,
          hasScrolledAtStart: true,
        }));
      }
    }
  }, [state.hasScrolledAtStart, messages]);

  return {
    bottomRef,
  };
}
