// Used in filters (filtro Provincias)
export const sortProvincias = (a: any, b: any) => {
  if (typeof String.prototype.localeCompare === "function") {
    return a.provincia.localeCompare(b.provincia);
  }
  if (a.provincia > b.provincia) {
    return 1;
  }
  if (a.provincia < b.provincia) {
    return -1;
  }
  return 0;
};
