import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useFormikContext } from "formik";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { FormikCheckbox } from "../../../../components/FormikFields";
import FormikDatePicker from "../../../../components/FormikFields/FormikDatePicker";
import { Box, Grid, Typography } from "@material-ui/core";
import { useMeasure } from "react-use";

const MIN_HEIGHT = 0;

const StyledSection = styled(Box)`
  border: 1px solid rgba(0, 72, 120, 0.5);
  border-radius: 0.25rem;
  padding: 1rem 0.75rem;
  box-sizing: border-box;
  background-color: #e4ebef;
`;

const StyledLabel = styled(Typography)`
  margin-top: -1.75rem;
  padding: 0 0.25rem;
  background-color: inherit;
  font-size: 0.875rem;
  color: rgb(51, 111, 149);
  background-color: inherit;
  width: fit-content;
`;

const StyledInnerContainer = styled.div`
  padding: 0.75rem 0;
  transition: min-height 0.1s ease-in-out, height 0.1s ease-in-out;
  overflow: hidden;
`;

interface ICommunicationsSection {}

const CommunicationsSection = (props: ICommunicationsSection) => {
  const formikContext = useFormikContext<any>();
  const [innerRef, { height }] = useMeasure();

  const boxHeight = height > MIN_HEIGHT ? height - 15 : MIN_HEIGHT;

  return (
    <StyledSection>
      <StyledLabel>
        <FormattedMessage
          id="CommunicationsSection.Comunicaciones"
          defaultMessage="Comunicaciones"
        />
      </StyledLabel>
      <StyledInnerContainer
        style={{
          minHeight: `${boxHeight}px`,
          height: `${boxHeight}px`,
        }}
      >
        <div ref={innerRef as any}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <Grid container spacing={8}>
              <Grid item xs={12} md={12}>
                <FormikCheckbox
                  label={
                    <FormattedMessage
                      id="CommunicationsSection.No enviar comunicaciones por Bot"
                      defaultMessage="No enviar comunicaciones por Bot"
                    />
                  }
                  name="noBot"
                  variant="compact"
                />
              </Grid>
              {formikContext.values.noBot && (
                <Grid item xs={12} md={12}>
                  <FormikDatePicker
                    name="No_iniciar_bot_hasta"
                    label={
                      <FormattedMessage
                        id="CommunicationsSection.Fecha hasta la que no se envían comunicaciones"
                        defaultMessage="Fecha hasta la que no se envían comunicaciones"
                      />
                    }
                    fullWidth
                    margin="nomargin"
                    size="small"
                  />
                </Grid>
              )}
            </Grid>
          </MuiPickersUtilsProvider>
        </div>
      </StyledInnerContainer>
    </StyledSection>
  );
};

export default CommunicationsSection;
