import React from "react";
import { useSelector } from "react-redux";
import EmployeesFiltersES from "./EmployeesFilters_es";
import EmployeesFiltersFR from "./EmployeesFilters_fr";

interface IEmployeesFilters {
  filters: any;
  setFilters: Function;
}

const EmployeesFilters = (props: IEmployeesFilters) => {
  const { filters, setFilters } = props;
  const provincesOptions = useSelector(
    (state: any) => state.commonData.provinciasOptions
  );

  if (filters.businessCountry === "fr") {
    return (
      <EmployeesFiltersFR
        employeeFilters={filters}
        employeeSetFilters={setFilters}
        provinciasOptions={provincesOptions}
      />
    );
  } else {
    return (
      <EmployeesFiltersES
        employeeFilters={filters}
        employeeSetFilters={setFilters}
        provinciasOptions={provincesOptions}
      />
    );
  }
};

export default React.memo(
  (props: IEmployeesFilters) => {
    return <EmployeesFilters {...props} />;
  },
  (prevProps, nextProps) => {
    if (
      prevProps.filters !== nextProps.filters ||
      prevProps.setFilters !== nextProps.setFilters
    ) {
      return false;
    }
    return true;
  }
);
