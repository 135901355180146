import { Button, Typography } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { JOB_SIGN_IN } from "../../constants/routes";

const StyledWrapper = styled.div`
  padding: 1rem;
`;

const StyledContent = styled.div``;

const StyledTypography = styled(Typography)`
  text-align: center;
  margin-top: 1rem;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  display: block;
  margin: 0.5rem auto;
  width: 15rem;
`;

const AnonymousMenu = (props: any) => {
  const { handleClose } = props;

  return (
    <StyledWrapper>
      <StyledContent>
        <StyledTypography color="primary">
          <FormattedMessage
            id="AnonymousMenu.AreYouNew"
            defaultMessage="¿Eres nuevo/a?"
          />
        </StyledTypography>
        <StyledLink to="/registroCuidador">
          <Button
            color="primary"
            variant="contained"
            size="medium"
            fullWidth
            style={{ textTransform: "none" }}
            onClick={(e: any) => {
              handleClose();
            }}
          >
            <FormattedMessage
              id="AnonymousMenu.SignUp"
              defaultMessage="Regístrate"
            />
          </Button>
        </StyledLink>
        <StyledTypography color="primary">
          <FormattedMessage
            id="AnonymousMenu.AlredyHaveAccount"
            defaultMessage="¿Ya tienes cuenta?"
          />
        </StyledTypography>
        <StyledLink to={JOB_SIGN_IN}>
          <Button
            color="primary"
            variant="outlined"
            size="medium"
            fullWidth
            style={{ textTransform: "none" }}
            onClick={(e: any) => {
              handleClose();
            }}
          >
            <FormattedMessage
              id="AnonymousMenu.SignIn"
              defaultMessage="Iniciar sesión"
            />
          </Button>
        </StyledLink>
      </StyledContent>
    </StyledWrapper>
  );
};

export default AnonymousMenu;
