import { apiEmpleo } from "../../util/ApiEmpleo";

export type TApiUpdateContractNotificationPayload = {
  viewed: boolean;
};

const apiUpdateContractNotification = async (
  contractNotificationId: string,
  payload: TApiUpdateContractNotificationPayload
) => {
  const res = await apiEmpleo.patch(
    `contract-notifications/${contractNotificationId}`,
    payload,
    {
      headers: {
        "Content-Type": "application/merge-patch+json",
      },
    }
  );

  return res;
};

export { apiUpdateContractNotification };
