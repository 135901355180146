import {
  SERVICE_PLAN,
  SERVICE_TYPE,
  TGetServicesCollectionItem,
} from "../../../../../api/service/service.types";

export const serviceCommercialNotes = (
  service: TGetServicesCollectionItem
): string | null => {
  return (
    service.Notas_depto_comercial_3 ?? service.Notas_depto_comercial ?? null
  );
};

export const serviceIsConfortPlus = (
  service: TGetServicesCollectionItem
): boolean => {
  if (
    service.Plan === SERVICE_PLAN.COMFORT_PLUS_IN_PERSON ||
    service.Plan === SERVICE_PLAN.COMFORT_PLUS_PHONE
  ) {
    return true;
  }

  return false;
};

export const serviceIsConfort = (
  service: TGetServicesCollectionItem
): boolean => {
  if (service.Plan === SERVICE_PLAN.COMFORT) {
    return true;
  }

  if (!service.Plan) {
    if (service.Tipo_de_Servicio === SERVICE_TYPE.SELECTION) {
      return true;
    }
  }

  return false;
};

export const serviceIsPunctualService = (
  service: TGetServicesCollectionItem
): boolean => {
  if (service.Plan === SERVICE_PLAN.PUNCTUAL_SELECTION) {
    return true;
  }

  return false;
};
