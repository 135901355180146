import React from "react";
import { useIntl } from "react-intl";
import useBackground from "../../../../../../util/useBackground";
import useFooter from "../../../../../../util/useFooter";
import useHeader from "../../../../../../util/useHeader";
import usePageTitle from "../../../../../../util/usePageTitle";
import ContractsPage from "./ContractsPage";
import useContractsPageState from "./hooks/useContractsPageState";

interface IContractsPageContainerProps {}

const ContractsPageContainer: React.FC<IContractsPageContainerProps> = (
  props
) => {
  const { formatMessage } = useIntl();

  useHeader({
    type: "hidden",
  });
  useFooter({
    type: "hidden",
  });
  useBackground("cuideo");
  usePageTitle(
    formatMessage({
      id: "ContractsPage.Contracts",
      defaultMessage: "Contratos",
    })
  );

  const {
    state: {
      userIsRecruiter,
      userIsPromoter,
      filters,
      contracts,
      totalItems,
      isLoading,
      hasError,
      recruitContracts,
      promoteContracts,
    },
    actions: {
      setFilters,
      mutateContract,
      updateContractPhase,
      updateContractImportantNotes,
      changePage,
      switchToRecruitContracts,
      switchToPromoteContracts,
    },
  } = useContractsPageState();

  if (!filters.ready) {
    return null;
  }

  return (
    <ContractsPage
      variant={
        userIsPromoter
          ? "promoter"
          : userIsRecruiter
          ? "recruiter"
          : "coordinator"
      }
      filters={filters}
      contracts={contracts}
      totalItems={totalItems}
      isLoading={isLoading}
      hasError={hasError}
      recruitContracts={recruitContracts}
      promoteContracts={promoteContracts}
      onMutateContract={mutateContract}
      onUpdateContractPhase={updateContractPhase}
      onContractImportantNotesUpdate={updateContractImportantNotes}
      onSetFilters={setFilters}
      onPageChange={changePage}
      onSwitchToRecruitContracts={switchToRecruitContracts}
      onSwitchToPromoteContracts={switchToPromoteContracts}
    />
  );
};

export default ContractsPageContainer;
