import React from "react";
import { useContractOffer } from "../../api/getContractOffer";
import { TContractFull } from "../../types/contract.types";
import ContractEditModal from "./ContractEditModal";

interface IContractEditModalContainerProps {
  contractId: string;
  open: boolean;
  onClose: () => void;
  onChange: (payload: any) => void;
}

const ContractEditModalContainer: React.VFC<IContractEditModalContainerProps> =
  (props) => {
    const { contractId, open, onClose, onChange } = props;

    const contractQuery = useContractOffer({
      contractId,
      config: {
        enabled: !!open,
      },
    });

    return (
      <ContractEditModal
        status={contractQuery.status}
        contract={contractQuery.data as TContractFull | undefined}
        open={open}
        onClose={onClose}
        onChange={onChange}
      />
    );
  };

export default ContractEditModalContainer;
