import styled from "styled-components";
import cuideoTheme from "../../containers/themes/defaultTheme";

const StyledPageContainer = styled.div<{ appMarginTop: any }>`
  ${({ appMarginTop }) => {
    return `
      margin: ${appMarginTop}rem auto 0;
    `;
  }}
  margin-left: 1rem;
  margin-right: 1rem;

  @media all and (min-width: ${cuideoTheme.breakpoints.values.sm}px) {
    margin-top: 6rem;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export default StyledPageContainer;
