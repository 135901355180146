import {
  Badge,
  ClickAwayListener,
  Fade,
  IconButton,
  Popper,
} from "@material-ui/core";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import NotificationsViewer from "../NotificationsViewer";
import {
  TGetNotificationsCollectionItem,
  TNotificationType,
} from "../../../../../../api/notification/notification.types";
import { useNotificationClick } from "../../hooks/useNotificationClick";
import { useMarkWaNotificationsAsDelivered } from "../../api/markWaNotificationsAsDelivered";
import { useIntl } from "react-intl";

const notificationTypes: TNotificationType[] = ["conversation_message"];

const StyledIconButton = styled(IconButton)`
  border-radius: 0.25rem;
  &:hover {
    background-color: #004b7b;
  }
  &.CMui-active {
    background-color: #195d88;
  }
`;

const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    color: #fff;
    background-color: #cc1016;
    font-weight: 600;
    top: 1rem;
    right: 0.5rem;
  }
`;

const StyledNotificationsIcon = styled(WhatsAppIcon)`
  color: #c4d2e4;
  width: 2rem;
  height: 2rem;
  padding: 0.25rem 0.5rem;
  display: block;
  margin: 0 auto;
`;

const StyledPaper = styled.div`
  min-width: 32rem;
  max-width: 32rem;
  background-color: #fff;
  border-radius: 0.5rem;
  border: 1px solid #ddd;
  box-shadow: 0 1px 0.5rem rgba(0, 0, 0, 0.25);
`;

interface IUserWaNotificationsButtonProps {
  numUndeliveredNotifications: number;
}

const UserWaNotificationsButton: React.FC<IUserWaNotificationsButtonProps> = (
  props
) => {
  const { numUndeliveredNotifications } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const markMineWaAsDeliveredMutation = useMarkWaNotificationsAsDelivered();
  const notificationClick = useNotificationClick();
  const { formatMessage } = useIntl();

  const isMenuOpen = Boolean(anchorEl);

  const handleNotificationsMenuOpen = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    if (numUndeliveredNotifications > 0) {
      (markMineWaAsDeliveredMutation as any).mutate();
    }
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const preventScrollBubbling = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  const handleNotificationClick = useCallback(
    (notification: TGetNotificationsCollectionItem) => {
      notificationClick(notification);
      setAnchorEl(null);
    },
    [notificationClick]
  );

  return (
    <>
      <StyledIconButton
        aria-label={formatMessage({
          id: "UserWaNotificationsButton.Mensajes",
          defaultMessage: "Mensajes",
        })}
        onClick={handleNotificationsMenuOpen}
      >
        <StyledBadge
          badgeContent={numUndeliveredNotifications}
          max={9}
          overlap="rectangular"
        >
          <StyledNotificationsIcon color="secondary" />
        </StyledBadge>
      </StyledIconButton>
      <Popper
        open={isMenuOpen}
        anchorEl={anchorEl}
        placement="right-end"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={250}>
            <StyledPaper onWheel={preventScrollBubbling}>
              <ClickAwayListener onClickAway={handleMenuClose}>
                <div>
                  <NotificationsViewer
                    title={formatMessage({
                      id: "UserWaNotificationsButton.Mensajes",
                      defaultMessage: "Mensajes",
                    })}
                    emptyLabel={formatMessage({
                      id: "UserWaNotificationsButton.No hay ningún mensaje",
                      defaultMessage: "No hay ningún mensaje",
                    })}
                    notificationTypes={notificationTypes}
                    onNotificationClick={handleNotificationClick}
                  />
                </div>
              </ClickAwayListener>
            </StyledPaper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default UserWaNotificationsButton;
