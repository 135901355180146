import { TGetServicesInfoManagerCollectionItem } from "../../../../api/service/service.types";

export const serviceIsUnassigned = (
  service: TGetServicesInfoManagerCollectionItem
) => {
  if (service.Owner?.fullname === "Cuideo Cuideo") {
    return true;
  }

  return false;
};
