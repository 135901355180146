import { Tooltip } from "@material-ui/core";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import DonutLargeIcon from "@material-ui/icons/DonutLarge";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import FavoriteIcon from "@material-ui/icons/Favorite";
import SchoolIcon from "@material-ui/icons/School";
import VideocamIcon from "@material-ui/icons/Videocam";
import { Fragment } from "react";
import { FaRobot } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { TGetContractsItemValuation } from "../../../../../../api/contract/contract.types";
import { TGetEmployeeItemUserFavorite } from "../../../../../../api/employee/employee.types";
import cuideoTheme, {
  COLOR_RED_NOTIFICATION,
} from "../../../../../../containers/themes/defaultTheme";
import {
  employeeIdExpiryIsValid,
  employeeIsValidatedOrBetter,
} from "../../../../models/Employee/Employee";
import { useFavoriteFormatter } from "../../../favorites";

const StyledFavIcon = styled(FavoriteIcon)`
  font-size: 1rem;
  color: ${COLOR_RED_NOTIFICATION};
  opacity: 0.5;
`;
const StyledSchoolIcon = styled(SchoolIcon)`
  font-size: 1rem;
  color: ${cuideoTheme.palette.primary.main};
  opacity: 0.5;
`;
const StyledAccountBoxIcon = styled(AccountBoxIcon)`
  font-size: 1rem;
  color: ${cuideoTheme.palette.primary.main};
  opacity: 0.5;
`;
const StyledDriveEtaIcon = styled(DriveEtaIcon)`
  font-size: 1rem;
  color: ${cuideoTheme.palette.primary.main};
  opacity: 0.5;
`;
const StyledPendingVideoIcon = styled(VideocamIcon)`
  font-size: 1rem;
  color: #f29339;
  opacity: 0.5;
`;
const StyledInProcessIcon = styled(DonutLargeIcon)`
  font-size: 1rem;
  color: ${cuideoTheme.palette.primary.main};
  opacity: 0.5;
`;
const StyledCompletedVideoIcon = styled(VideocamIcon)`
  font-size: 1rem;
  color: ${cuideoTheme.palette.primary.main};
  opacity: 0.5;
`;
const StyledFinalistVideoIcon = styled(VideocamIcon)`
  font-size: 1rem;
  color: ${cuideoTheme.palette.success.main};
  opacity: 0.5;
`;
const StyledInCommunicationIcon = styled.div`
  font-size: 1rem;
  color: ${cuideoTheme.palette.primary.main};
  opacity: 0.5;
`;
const StyledAssignmentIndIcon = styled(AssignmentIndIcon)`
  font-size: 1rem;
  color: #cc1016;
  opacity: 0.75;
`;

interface IValuationIconsProps {
  valuation: Pick<TGetContractsItemValuation, "candidato">;
  favorite?: TGetEmployeeItemUserFavorite;
}

const ValuationIcons = (props: IValuationIconsProps) => {
  const { valuation, favorite } = props;
  const { formatFavoriteTooltipContent } = useFavoriteFormatter();

  return (
    <Fragment>
      {!!favorite && (
        <Tooltip title={formatFavoriteTooltipContent(favorite)}>
          <StyledFavIcon />
        </Tooltip>
      )}
      {valuation.candidato &&
        valuation.candidato.Titulaci_n_oficial_SAD.length > 0 &&
        valuation.candidato.Titulaci_n_oficial_SAD[0] && (
          <Tooltip title={valuation.candidato.Titulaci_n_oficial_SAD[0]}>
            <StyledSchoolIcon />
          </Tooltip>
        )}
      {valuation.candidato &&
        valuation.candidato.Carnet_de_conducir &&
        valuation.candidato.Carnet_de_conducir === "SI" && (
          <Tooltip
            title={
              <FormattedMessage
                id="ValuationCard.DrivingLicense"
                defaultMessage="Tiene Carnet de Conducir"
              />
            }
          >
            <div>
              <StyledAccountBoxIcon />
            </div>
          </Tooltip>
        )}
      {valuation.candidato &&
        valuation.candidato.Veh_culo_propio &&
        valuation.candidato.Veh_culo_propio === "Si" && (
          <Tooltip
            title={
              <FormattedMessage
                id="ValuationCard.OwnsVehicle"
                defaultMessage="Tiene vehículo propio"
              />
            }
          >
            <div>
              <StyledDriveEtaIcon />
            </div>
          </Tooltip>
        )}
      {valuation.candidato?.interviewStatus &&
        valuation.candidato.interviewStatus === "pending" && (
          <Tooltip
            title={
              <FormattedMessage
                id="CandidatoCard.PendingInterview"
                defaultMessage="Videoentrevista pendiente"
              />
            }
          >
            <StyledPendingVideoIcon />
          </Tooltip>
        )}
      {valuation.candidato?.interviewStatus &&
        valuation.candidato.interviewStatus === "completed" && (
          <Tooltip
            title={
              <FormattedMessage
                id="CandidatoCard.CompletedInterview"
                defaultMessage="Videoentrevista completada"
              />
            }
          >
            <StyledCompletedVideoIcon />
          </Tooltip>
        )}
      {valuation.candidato?.interviewStatus &&
        valuation.candidato.interviewStatus === "finalist" && (
          <Tooltip
            title={
              <FormattedMessage
                id="CandidatoCard.FinalistInterview"
                defaultMessage="Videoentrevista finalista"
              />
            }
          >
            <StyledFinalistVideoIcon />
          </Tooltip>
        )}
      {!!(
        valuation.candidato?.numOpenValuationProcesses &&
        valuation.candidato.numOpenValuationProcesses > 0
      ) && (
        <Tooltip
          title={
            <FormattedMessage
              id="CandidatoCard.InOpenProcesses"
              defaultMessage="En procesos abiertos"
            />
          }
        >
          <StyledInProcessIcon />
        </Tooltip>
      )}
      {!!(
        valuation.candidato?.numOpenCommunicationIntents &&
        valuation.candidato.numOpenCommunicationIntents > 0
      ) && (
        <Tooltip
          title={
            <FormattedMessage
              id="CandidatoCard.InOpenCommunicationIntents"
              defaultMessage="Intentado comunicar recientemente vía Bot"
            />
          }
        >
          <StyledInCommunicationIcon>
            <FaRobot />
          </StyledInCommunicationIcon>
        </Tooltip>
      )}
      {valuation.candidato &&
        employeeIsValidatedOrBetter(valuation.candidato) &&
        !employeeIdExpiryIsValid(valuation.candidato) && (
          <Tooltip
            title={
              <FormattedMessage
                id="EmployeeCard.Fecha de vencimiento de DNI/NIE no válida"
                defaultMessage="Fecha de vencimiento de DNI/NIE no válida"
              />
            }
          >
            <StyledAssignmentIndIcon />
          </Tooltip>
        )}
    </Fragment>
  );
};

export default ValuationIcons;
