export const EMPLOYEE_YEARS_OF_EXPERIENCE_LESS_1_YEAR = "Menos de 1 año";
export const EMPLOYEE_YEARS_OF_EXPERIENCE_LESS_2_YEARS = "Menos de 2 años";
export const EMPLOYEE_YEARS_OF_EXPERIENCE_LESS_3_YEARS = "Menos de 3 años";
export const EMPLOYEE_YEARS_OF_EXPERIENCE_MORE_3_YEARS = "Más de 3 años";
export const EMPLOYEE_YEARS_OF_EXPERIENCE_MORE_5_YEARS = "Más de 5 años";
export const EMPLOYEE_YEARS_OF_EXPERIENCE_MORE_10_YEARS = "Más de 10 años";
export const EMPLOYEE_YEARS_OF_EXPERIENCE_NO_EXPERIENCE = "Sin experiencia";

export const EMPLOYEE_VIDEOINTERVIEW_STATUS_PENDING = "pending";
export const EMPLOYEE_VIDEOINTERVIEW_STATUS_COMPLETED = "completed";
export const EMPLOYEE_VIDEOINTERVIEW_STATUS_FINALIST = "finalist";
