import { Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import CuideoUserAvatar from "../../../../../../../../components/CuideoUserAvatar";
import { COLOR_SUCCESS } from "../../../../../../../../containers/themes/defaultTheme";
import { TStaffAssignationsCoordinator } from "../../types/StaffAssignationsCoordinator";

export const WITHOUT_VALUATION_COLOR = "#004b7b";
export const PENDING_SIGNUP_COLOR = "#004b7b";
export const ADVANCED_VALUATION_COLOR = "#4caf50";

const StyledBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 3.5rem;
  user-select: none;

  &.CMuiCoordinatorAssignationsItem-inactive {
    opacity: 0.5;
  }

  &:hover {
    cursor: pointer;
    background-color: rgb(245, 245, 245);
  }
`;

const StyledAvatarWrapper = styled.div`
  padding: 0.25rem 0.75rem 0.25rem 0.25rem;
  .MuiAvatar-root {
    width: 2rem;
    height: 2rem;
  }
`;

const StyledLeft = styled.div`
  padding: 0.25rem 0 0.25rem 0.5rem;
`;

const StyledRight = styled.div`
  padding: 0.25rem 0.5rem 0.25rem 0;
  flex: 1 1 auto;
`;

const StyledRightTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledRightTopLeft = styled.div`
  flex: 1 1 auto;
`;

const StyledRightTopRight = styled.div`
  min-width: 1.125rem;
  width: 1.125rem;
  text-align: right;
`;

const StyledRightBottom = styled.div``;

const StyledTotalNumber = styled(Typography)`
  font-weight: 700;
  font-size: 1.125rem;
`;

const StyledUserName = styled(Typography)`
  line-height: 1.25;
  font-size: 1rem;
  font-weight: 600;
  color: #222;
`;

const StyledBarsWrapper = styled.div`
  height: 1.125rem;
  display: flex;
  flex-direction: row;
`;

const StyledServiceBarWrapper = styled.div`
  height: 1rem;
  width: 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

const StyledContractBarsWrapper = styled.div`
  width: 50%;
`;

const StyledContractBarWrapper = styled.div`
  height: 0.625rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

const StyledServicesNumber = styled(Typography)`
  font-weight: 700;
  font-size: 0.875rem;
`;

const StyledContractsNumber = styled(Typography)`
  font-weight: 700;
  font-size: 0.75rem;
  width: 1rem;
  text-align: right;
`;

const StyledInactiveCoordinator = styled(Typography)`
  font-size: 0.75rem;
  line-height: 1;
  color: #888;
  margin: 0.125rem 0;
`;

const StyledActiveCoordinator = styled(Typography)`
  font-size: 0.75rem;
  line-height: 1;
  color: #888;
  margin: 0;
`;

const StyledOnlineCoordinator = styled(Typography)`
  font-size: 0.75rem;
  line-height: 1;
  color: ${COLOR_SUCCESS};
  margin: 0;
`;

const StyledBarContainer = styled.div`
  margin: 0 0.25rem;
  height: 1.125rem;
  width: 100%;
  position: relative;
`;

const StyledBar = styled.div`
  border-radius: 0 0.1875rem 0.1875rem 0;
  height: 0.5rem;
  top: 0.3125rem;
  position: absolute;
`;

const StyledThinnerBar = styled.div`
  border-radius: 0 0.1875rem 0.1875rem 0;
  height: 0.375rem;
  top: 0.375rem;
  position: absolute;
`;

interface ICoordinatorAssignationsItem {
  coordinator: TStaffAssignationsCoordinator;
  maxContracts: number;
  maxServices: number;
  onSelect: (ownerId: string) => void;
}

const CoordinatorAssignationsItem = (props: ICoordinatorAssignationsItem) => {
  const { coordinator, maxContracts, maxServices, onSelect } = props;

  const handleClick = () => {
    onSelect(coordinator.id);
  };

  let className = "CMuiCoordinatorAssignationsItem";
  if (!coordinator.active) {
    className += " CMuiCoordinatorAssignationsItem-inactive";
  }

  const withoutValuationBarWidth =
    (coordinator.servicesStats.withoutValuation.total / (maxServices + 1)) *
    100;

  const awaitingContractsBarWidth =
    (coordinator.contractStats.awaiting.total / (maxContracts + 1)) * 100;

  const advancedContractsBarWidth =
    ((coordinator.contractStats.cvClient.total +
      coordinator.contractStats.clientFaceToFace.total) /
      (maxContracts + 1)) *
    100;

  const totalNumber =
    coordinator.servicesStats.total +
    coordinator.contractStats.awaiting.total +
    coordinator.contractStats.clientFaceToFace.total +
    coordinator.contractStats.cvClient.total;

  return (
    <StyledBox className={className} onClick={handleClick}>
      <StyledLeft>
        <StyledAvatarWrapper>
          <CuideoUserAvatar
            user={{
              userFullName: "Paco",
              userAvatar: null,
            }}
          />
        </StyledAvatarWrapper>
      </StyledLeft>
      <StyledRight>
        <StyledRightTop>
          <StyledRightTopLeft>
            <StyledUserName>{coordinator.fullName}</StyledUserName>
            <StyledBarsWrapper>
              <StyledServiceBarWrapper>
                <StyledServicesNumber>
                  {coordinator.servicesStats.total}
                </StyledServicesNumber>
                <StyledBarContainer>
                  <StyledBar
                    style={{
                      backgroundColor: WITHOUT_VALUATION_COLOR,
                      width: `calc(${withoutValuationBarWidth}% + 0.1875rem)`,
                    }}
                  />
                </StyledBarContainer>
              </StyledServiceBarWrapper>
              <StyledContractBarsWrapper>
                <StyledContractBarWrapper>
                  <StyledContractsNumber>
                    {coordinator.contractStats.awaiting.total}
                  </StyledContractsNumber>
                  <StyledBarContainer>
                    <StyledThinnerBar
                      style={{
                        backgroundColor: PENDING_SIGNUP_COLOR,
                        width: `calc(${awaitingContractsBarWidth}% + 0.1875rem)`,
                        zIndex: 2,
                      }}
                    />
                  </StyledBarContainer>
                </StyledContractBarWrapper>
                <StyledContractBarWrapper>
                  <StyledContractsNumber>
                    {coordinator.contractStats.clientFaceToFace.total +
                      coordinator.contractStats.cvClient.total}
                  </StyledContractsNumber>
                  <StyledBarContainer>
                    <StyledThinnerBar
                      style={{
                        backgroundColor: ADVANCED_VALUATION_COLOR,
                        width: `calc(${advancedContractsBarWidth}% + 0.1875rem)`,
                        zIndex: 1,
                      }}
                    />
                  </StyledBarContainer>
                </StyledContractBarWrapper>
              </StyledContractBarsWrapper>
            </StyledBarsWrapper>
          </StyledRightTopLeft>
          <StyledRightTopRight>
            <StyledTotalNumber>{totalNumber}</StyledTotalNumber>
          </StyledRightTopRight>
        </StyledRightTop>
        <StyledRightBottom></StyledRightBottom>
        {!coordinator.active ? (
          <StyledInactiveCoordinator>
            <FormattedMessage
              id="CoordinatorAssignationsItem.Coordinador inactivo"
              defaultMessage="Coordinador inactivo"
            />
          </StyledInactiveCoordinator>
        ) : coordinator.isOnline ? (
          <StyledOnlineCoordinator>
            <FormattedMessage
              id="CoordinatorAssignationItem.En línea"
              defaultMessage="En línea"
            />
          </StyledOnlineCoordinator>
        ) : (
          <StyledActiveCoordinator>
            <FormattedMessage
              id="CoordinatorAssignationItem.Activo {timeAgo}"
              defaultMessage="Activo {timeAgo}"
              values={{
                timeAgo: moment(coordinator.lastActivityTime).fromNow(),
              }}
            />
          </StyledActiveCoordinator>
        )}
      </StyledRight>
    </StyledBox>
  );
};

export default CoordinatorAssignationsItem;
