import { Grid, RadioGroup } from "@material-ui/core";
import { useFormikContext } from "formik";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { TSelectOption } from "../../../../apps/affinity/pages/ServicePage/components/ServiceValuation/types";
import NoIcon from "../../../../assets/images/iconos/no.svg";
import SiIcon from "../../../../assets/images/iconos/si.svg";
import { FormikRadio, FormikSelect } from "../../../../components/FormikFields";
import FormikUpload from "../../../../components/FormikFields/FormikUpload";
import {
  StyledRegistroSection,
  StyledRegistroTitle,
} from "../../EmployeeSignUpSC";
import {
  StyledFormControl,
  StyledFormControlLabel,
  StyledFormHelperText,
  StyledIcon,
  StyledUncheckedIcon,
} from "./SignUpStep2InnerForm";

interface ISignUpStep2InterestDataInnerFormProps {
  educationOptions: TSelectOption[];
  experienceYearsOptions: TSelectOption[];
  languagesOptions: TSelectOption[];
  degreesOptions: TSelectOption[];
}

const SignUpStep2InterestDataInnerForm = (
  props: ISignUpStep2InterestDataInnerFormProps
) => {
  const { experienceYearsOptions, languagesOptions, degreesOptions } = props;
  const { formatMessage } = useIntl();
  const formikContext = useFormikContext<any>();

  return (
    <StyledRegistroSection>
      <StyledRegistroTitle>
        <FormattedMessage
          id="SignUp.Datos de interés"
          defaultMessage="Datos de interés"
        />
      </StyledRegistroTitle>
      <StyledFormControl
        error={
          !!formikContext.errors.permisoConducir &&
          !!formikContext.touched.permisoConducir
        }
      >
        <StyledFormControlLabel>
          <FormattedMessage
            id="SignUp.¿Tienes permiso de conducir?"
            defaultMessage="¿Tienes permiso de conducir?"
          />{" "}
          *
        </StyledFormControlLabel>
        <RadioGroup name="permisoConducir" style={{ width: "100%" }}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <FormikRadio
                label={<FormattedMessage id="SignUp.Si" defaultMessage="Si" />}
                name="permisoConducir"
                value="SI"
                color="primary"
                icon={<StyledUncheckedIcon src={SiIcon} />}
                checkedIcon={<StyledIcon src={SiIcon} />}
              />
            </Grid>
            <Grid item xs={6}>
              <FormikRadio
                label={<FormattedMessage id="SignUp.No" defaultMessage="No" />}
                name="permisoConducir"
                value="NO"
                color="primary"
                icon={<StyledUncheckedIcon src={NoIcon} />}
                checkedIcon={<StyledIcon src={NoIcon} />}
              />
            </Grid>
          </Grid>
        </RadioGroup>
        {!!formikContext.errors.permisoConducir &&
          !!formikContext.touched.permisoConducir && (
            <StyledFormHelperText>
              {formikContext.errors.permisoConducir}
            </StyledFormHelperText>
          )}
      </StyledFormControl>
      <StyledFormControl
        error={
          !!formikContext.errors.cochePropio &&
          !!formikContext.touched.cochePropio
        }
      >
        <StyledFormControlLabel>
          <FormattedMessage
            id="SignUp.¿Tienes coche propio?"
            defaultMessage="¿Tienes coche propio?"
          />{" "}
          *
        </StyledFormControlLabel>
        <RadioGroup name="cochePropio" style={{ width: "100%" }}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <FormikRadio
                label={<FormattedMessage id="SignUp.Si" defaultMessage="Si" />}
                name="cochePropio"
                value="Si"
                color="primary"
                icon={<StyledUncheckedIcon src={SiIcon} />}
                checkedIcon={<StyledIcon src={SiIcon} />}
              />
            </Grid>
            <Grid item xs={6}>
              <FormikRadio
                label={<FormattedMessage id="SignUp.No" defaultMessage="No" />}
                name="cochePropio"
                value="No"
                color="primary"
                icon={<StyledUncheckedIcon src={NoIcon} />}
                checkedIcon={<StyledIcon src={NoIcon} />}
              />
            </Grid>
          </Grid>
        </RadioGroup>
        {!!formikContext.errors.cochePropio &&
          !!formikContext.touched.cochePropio && (
            <StyledFormHelperText>
              {formikContext.errors.cochePropio}
            </StyledFormHelperText>
          )}
      </StyledFormControl>
      <FormikSelect
        name="A_os_de_experiencia"
        required
        label={
          <FormattedMessage
            id="SignUp.Años de experiencia"
            defaultMessage="Años de experiencia"
          />
        }
        closeMenuOnSelect={true}
        size="medium"
        placeholder={formatMessage({
          id: "SignUp.Selecciona",
          defaultMessage: "Selecciona",
        })}
        options={experienceYearsOptions}
        fullWidth
      />
      <FormikSelect
        name="idiomas"
        isMulti
        required
        label={
          <FormattedMessage id="SignUp.Idiomas" defaultMessage="Idiomas" />
        }
        closeMenuOnSelect={false}
        size="medium"
        placeholder={formatMessage({
          id: "SignUp.Selecciona",
          defaultMessage: "Selecciona",
        })}
        options={languagesOptions}
      />
      <FormikSelect
        name="titulaciones"
        label={
          <FormattedMessage
            id="SignUp.¿Dispones de alguna de las siguientes titulaciones oficiales?"
            defaultMessage="¿Dispones de alguna de las siguientes titulaciones oficiales?"
          />
        }
        closeMenuOnSelect={true}
        size="medium"
        placeholder={formatMessage({
          id: "SignUp.Selecciona",
          defaultMessage: "Selecciona",
        })}
        options={degreesOptions}
      />

      <FormikUpload
        label={
          <FormattedMessage
            id="EmployeeSignUp.Adjunta CV"
            defaultMessage="Adjunta CV"
          />
        }
        name="cv"
        fullWidth
        margin="normal"
        size="medium"
        uploadLabel={
          <FormattedMessage
            id="EmployeeSignUp.Subir CV"
            defaultMessage="Subir CV"
          />
        }
        changeLabel={
          <FormattedMessage
            id="EmployeeSignUp.Cambiar CV"
            defaultMessage="Cambiar CV"
          />
        }
      />
    </StyledRegistroSection>
  );
};

export default SignUpStep2InterestDataInnerForm;
