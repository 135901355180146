import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import useWindowWidth from "../../../../util/useWindowWidth";
import { cuideoTheme } from "../../../themes/defaultTheme";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const StyledDesktopTableContainer = styled(TableContainer)`
  color: ${cuideoTheme.palette.primary.main};

  .MuiTable-root {
    table-layout: fixed;
  }

  .MuiTableCell-head {
    color: ${cuideoTheme.palette.primary.main};
    font-weight: 700;
    text-align: center;
  }

  .MuiTableCell-root {
    color: ${cuideoTheme.palette.primary.main};
    text-align: center;
    line-height: 1.25;
  }

  .CMuiCell-first {
    font-weight: 700;
  }
`;

const StyledAccordion = styled(Accordion)`
  .MuiAccordionSummary-root {
    min-height: 2rem;
    color: ${cuideoTheme.palette.primary.main};
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    font-weight: 700;

    .MuiAccordionSummary-content {
      margin: 0;
    }
  }
`;

const StyledDescriptionList = styled.dl`
  margin: 0.25rem 0;
  color: ${cuideoTheme.palette.primary.main};
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
`;

const StyledDescriptionTerm = styled.dt`
  font-weight: 700;
`;

const StyledDescriptionDefinition = styled.dd``;

interface IWorkRegimeHelpProps {}

const WorkRegimeHelp: React.FC<IWorkRegimeHelpProps> = (props) => {
  const width = useWindowWidth();

  return (
    <Box>
      {width < 600 ? (
        <div>
          <StyledAccordion defaultExpanded elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              REEH
            </AccordionSummary>
            <AccordionDetails>
              <StyledDescriptionList>
                <StyledDescriptionTerm>
                  ¿Quién será el empleador?
                </StyledDescriptionTerm>
                <StyledDescriptionDefinition>
                  La familia del usuario o el propio usuario a cuidar
                </StyledDescriptionDefinition>
                <StyledDescriptionTerm>
                  ¿Qué tareas realizaré?
                </StyledDescriptionTerm>
                <StyledDescriptionDefinition>
                  Atención a las necesidades del usuario y ayuda en su rutina
                  diaria
                </StyledDescriptionDefinition>
                <StyledDescriptionTerm>
                  ¿Cuántos días de vacaciones tendré?
                </StyledDescriptionTerm>
                <StyledDescriptionDefinition>
                  2,5 días/mes trabajados, 30 días anuales en total
                </StyledDescriptionDefinition>
                <StyledDescriptionTerm>
                  ¿Necesito una titulación?
                </StyledDescriptionTerm>
                <StyledDescriptionDefinition>No</StyledDescriptionDefinition>
                <StyledDescriptionTerm>Observaciones</StyledDescriptionTerm>
                <StyledDescriptionDefinition>
                  Alta seguridad social y el contrato se rige por el Real
                  Decreto 1620/2011
                </StyledDescriptionDefinition>
              </StyledDescriptionList>
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion defaultExpanded elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              SAD
            </AccordionSummary>
            <AccordionDetails>
              <StyledDescriptionList>
                <StyledDescriptionTerm>
                  ¿Quién será el empleador?
                </StyledDescriptionTerm>
                <StyledDescriptionDefinition>
                  Cuideo
                </StyledDescriptionDefinition>
                <StyledDescriptionTerm>
                  ¿Qué tareas realizaré?
                </StyledDescriptionTerm>
                <StyledDescriptionDefinition>
                  Atención a las necesidades del usuario y ayuda en su rutina
                  diaria
                </StyledDescriptionDefinition>
                <StyledDescriptionTerm>
                  ¿Cuántos días de vacaciones tendré?
                </StyledDescriptionTerm>
                <StyledDescriptionDefinition>
                  2,5 días/mes trabajados, 30 días anuales en total
                </StyledDescriptionDefinition>
                <StyledDescriptionTerm>
                  ¿Necesito una titulación?
                </StyledDescriptionTerm>
                <StyledDescriptionDefinition>Si</StyledDescriptionDefinition>
                <StyledDescriptionTerm>Observaciones</StyledDescriptionTerm>
                <StyledDescriptionDefinition>
                  Se requiere tener un certificado profesional de acuerdo a las
                  bases del Real Decreto 331/1997
                </StyledDescriptionDefinition>
              </StyledDescriptionList>
            </AccordionDetails>
          </StyledAccordion>
        </div>
      ) : (
        <StyledDesktopTableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell variant="head"></TableCell>
                <TableCell variant="head">REEH</TableCell>
                <TableCell variant="head">SAD</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className="CMuiCell-first">
                  ¿Quién será el empleador?
                </TableCell>
                <TableCell>
                  La familia del usuario o el propio usuario a cuidar
                </TableCell>
                <TableCell>Cuideo</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="CMuiCell-first">
                  ¿Qué tareas realizaré?
                </TableCell>
                <TableCell>
                  Atención a las necesidades del usuario y ayuda en su rutina
                  diaria
                </TableCell>
                <TableCell>
                  Atención a las necesidades del usuario y ayuda en su rutina
                  diaria
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="CMuiCell-first">
                  ¿Cuántos días de vacaciones tendré?
                </TableCell>
                <TableCell>
                  2,5 días/mes trabajados, 30 días anuales en total
                </TableCell>
                <TableCell>
                  2,5 días/mes trabajados, 30 días anuales en total
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="CMuiCell-first">
                  ¿Necesito una titulación?
                </TableCell>
                <TableCell>No</TableCell>
                <TableCell>Si</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="CMuiCell-first">Observaciones</TableCell>
                <TableCell>
                  Alta seguridad social y el contrato se rige por el Real
                  Decreto 1620/2011
                </TableCell>
                <TableCell>
                  Se requiere tener un certificado profesional de acuerdo a las
                  bases del Real Decreto 331/1997
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </StyledDesktopTableContainer>
      )}
    </Box>
  );
};

export default WorkRegimeHelp;
