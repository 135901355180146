import React from "react";
import { FormattedMessage } from "react-intl";
import {
  CONTRATO_ESTADO_ACTIVO,
  CONTRATO_ESTADO_ALTA_PENDIENTE,
  CONTRATO_ESTADO_CANCELADO_PERDIDO_SELECCION,
  CONTRATO_ESTADO_CV_CLIENTE,
  CONTRATO_ESTADO_EN_ESPERA,
  CONTRATO_ESTADO_FIN_DE_CONTRATO,
  CONTRATO_ESTADO_PAGA_Y_RESERVA,
  CONTRATO_ESTADO_PRESENCIAL_CLIENTE,
  CONTRATO_ESTADO_SELECCIONADO,
} from "../../util/defaults/contrato";

interface IFormattedContractStatusProps {
  status: string;
}

const FormattedContractStatus = (props: IFormattedContractStatusProps) => {
  const { status } = props;

  if (status === CONTRATO_ESTADO_ACTIVO) {
    return (
      <FormattedMessage id="ContractStatus.Active" defaultMessage="Activo" />
    );
  }
  if (status === CONTRATO_ESTADO_ALTA_PENDIENTE) {
    return (
      <FormattedMessage
        id="ContractStatus.PendingSignUp"
        defaultMessage="Alta pendiente"
      />
    );
  }
  if (status === CONTRATO_ESTADO_CANCELADO_PERDIDO_SELECCION) {
    return (
      <FormattedMessage
        id="ContractStatus.CancelledSelection"
        defaultMessage="Cancelado - perdido selección"
      />
    );
  }
  if (status === CONTRATO_ESTADO_CV_CLIENTE) {
    return (
      <FormattedMessage
        id="ContractStatus.CVClient"
        defaultMessage="CV Cliente"
      />
    );
  }
  if (status === CONTRATO_ESTADO_EN_ESPERA) {
    return (
      <FormattedMessage
        id="ContractStatus.Awaiting"
        defaultMessage="En espera"
      />
    );
  }
  if (status === CONTRATO_ESTADO_FIN_DE_CONTRATO) {
    return (
      <FormattedMessage
        id="ContractStatus.Ended"
        defaultMessage="Fin de contrato"
      />
    );
  }
  if (status === CONTRATO_ESTADO_PAGA_Y_RESERVA) {
    return (
      <FormattedMessage
        id="ContractStatus.PayAndReserve"
        defaultMessage="Paga y reserva"
      />
    );
  }
  if (status === CONTRATO_ESTADO_PRESENCIAL_CLIENTE) {
    return (
      <FormattedMessage
        id="ContractStatus.FaceToFaceClient"
        defaultMessage="Presencial cliente"
      />
    );
  }
  if (status === CONTRATO_ESTADO_SELECCIONADO) {
    return (
      <FormattedMessage
        id="ContractStatus.Selected"
        defaultMessage="Seleccionado"
      />
    );
  }
  return null;
};

export default FormattedContractStatus;
