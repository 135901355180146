import React from "react";
import CandidatoFullProfile from "../../../../components/CandidatoFullProfile";
import ActiveContractHeader from "../../components/ContractHeader/ActiveContractHeader";
import VariableContainer from "../../../../components/VariableContainer";
import { TContractFull } from "../../types/contract.types";

interface IActiveContractRouteProps {
  contract: TContractFull;
  candidate: any;
  tab: number;
  onEmployeeTabChange: (newValue: number) => void;
}

const ActiveContractRoute: React.FC<IActiveContractRouteProps> = (props) => {
  const { contract, candidate, tab, onEmployeeTabChange } = props;

  return (
    <>
      <ActiveContractHeader contract={contract} />
      <VariableContainer>
        {!!candidate && (
          <CandidatoFullProfile
            candidato={candidate}
            // Actually needed only if there is another block using candidate data
            onChange={() => {}}
            tab={tab}
            onTabChange={onEmployeeTabChange}
            // Actually needed only if there is another block using candidate data
            onEmployeeProfileChange={() => {}}
            context={{
              contract_crm_id: contract.zohoId,
            }}
          />
        )}
      </VariableContainer>
    </>
  );
};

export default ActiveContractRoute;
