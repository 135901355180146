import moment from "moment";
import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import { push } from "connected-react-router";
import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import {
  getCandidato,
  snackErrorMessage,
  snackInfoMessage,
  snackSuccessMessage,
} from "../../../../actions";
import FormattedTaskField from "../../../../components/FormattedFields/FormattedTaskField";
import useFormatters from "../../../../components/FormattedFields/useFormatters";
import {
  TIPO_EXTERNA_ENTRE_SEMANA,
  TIPO_EXTERNA_FIN_DE_SEMANA,
  TIPO_INTERNA_ENTRE_SEMANA,
  TIPO_INTERNA_FIN_DE_SEMANA,
} from "../../../../util/defaults/contrato";
import {
  StyledCard,
  StyledCardAction,
  StyledCardActions,
  StyledCardContent,
  StyledCardHeader,
  StyledCardTitle,
  StyledChip,
  StyledData,
  StyledLabel,
} from "../ProfileStyles";
import styled from "styled-components";

const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiSvgIcon-root {
    width: 1.25rem;
    height: 1.25rem;
  }

  .MuiFormControlLabel-label {
    font-size: 1rem;
  }
`;

const AvailabilityCard = (props: any) => {
  const { candidato, push, authUser } = props;

  const { formatContractJobType } = useFormatters();

  const noBotDate = moment(candidato.No_iniciar_bot_hasta);
  const nowPlus10 = moment().add(1, "days");
  let noBotFlag = false;
  if (
    candidato.No_iniciar_bot_hasta &&
    noBotDate.isValid() &&
    noBotDate.isAfter(nowPlus10)
  ) {
    noBotFlag = true;
  }

  return (
    <StyledCard>
      <StyledCardHeader>
        <StyledCardTitle>
          <FormattedMessage
            id="AvailabilityCard.Availability"
            defaultMessage="Disponibilidad"
          />
        </StyledCardTitle>
        <StyledCardActions>
          {authUser && authUser.userId === candidato.id && (
            <StyledCardAction
              color="primary"
              variant="outlined"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                push("/empleo/miPerfil/disponibilidad");
              }}
            >
              <FormattedMessage
                id="AvailabilityCard.Edit"
                defaultMessage="Editar"
              />
            </StyledCardAction>
          )}
        </StyledCardActions>
      </StyledCardHeader>
      <StyledCardContent>
        <Grid container spacing={8}>
          <Grid item xs={12} md={12}>
            <StyledLabel>
              <FormattedMessage
                id="AvailabilityCard.Availability_"
                defaultMessage="Disponibilidad:"
              />
            </StyledLabel>
            <StyledData>
              {candidato.Interna_entre_semana && (
                <StyledChip
                  label={formatContractJobType(TIPO_INTERNA_ENTRE_SEMANA)}
                />
              )}
              {candidato.Interna_fin_de_semana && (
                <StyledChip
                  label={formatContractJobType(TIPO_INTERNA_FIN_DE_SEMANA)}
                />
              )}
              {candidato.Externa_entre_semana && (
                <StyledChip
                  label={formatContractJobType(TIPO_EXTERNA_ENTRE_SEMANA)}
                />
              )}
              {candidato.Externa_fin_de_semana && (
                <StyledChip
                  label={formatContractJobType(TIPO_EXTERNA_FIN_DE_SEMANA)}
                />
              )}
            </StyledData>
          </Grid>
        </Grid>
        <Grid container spacing={8}>
          <Grid item xs={12} md={12}>
            <StyledLabel>
              <FormattedMessage
                id="AvailabilityCard.Tasks"
                defaultMessage="¿Qué tipo de tareas puedes realizar?"
              />
            </StyledLabel>
            <StyledData>
              {candidato.Qu_tareas_quieres_o_puedes_realizar.length > 0 ? (
                candidato.Qu_tareas_quieres_o_puedes_realizar.map(
                  (item: any) => (
                    <StyledChip
                      key={item}
                      label={<FormattedTaskField task={item} />}
                    ></StyledChip>
                  )
                )
              ) : (
                <Fragment>-</Fragment>
              )}
            </StyledData>
          </Grid>
        </Grid>
        <Grid container spacing={8}>
          <Grid item xs={12} md={12}>
            <StyledFormControlLabel
              control={
                <Checkbox checked={noBotFlag} color="primary" readOnly />
              }
              label={
                <FormattedMessage
                  id="AvailabilityPage.No quiero recibir comunicaciones de ofertas de trabajo por Whatsapp"
                  defaultMessage="No quiero recibir comunicaciones de ofertas de trabajo por Whatsapp"
                />
              }
            />
          </Grid>
        </Grid>
      </StyledCardContent>
    </StyledCard>
  );
};

const mapStateToProps = ({
  candidatosState,
  commonData,
  auth,
}: {
  candidatosState: { candidatos: any };
  commonData: any;
  auth: { authUser: any };
}) => {
  const { candidatos } = candidatosState;
  const {
    paisesOptions,
    provinciasOptions,
    diasOptions,
    mesesOptions,
    anyosOptions,
    idiomasOptions,
    titulacionesOptions,
    tareasOptions,
    educacionOptions,
  } = commonData;
  const { authUser } = auth;

  return {
    candidatos,
    paisesOptions,
    provinciasOptions,
    diasOptions,
    mesesOptions,
    anyosOptions,
    idiomasOptions,
    titulacionesOptions,
    tareasOptions,
    educacionOptions,
    authUser,
  };
};

export default connect(mapStateToProps, {
  getCandidato,
  snackSuccessMessage,
  snackInfoMessage,
  snackErrorMessage,
  push,
})(AvailabilityCard);
