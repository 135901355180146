import { EMPLOYEE_STATUS } from "../../../../api/employee/employee.types";
import { VALUATION_STATUS } from "../../../../api/valuation/valuation.types";

export const outstandingIsSelected = (filters: any) =>
  filters?.estado?.length === 1 &&
  filters?.estado.includes(EMPLOYEE_STATUS.VALIDATED_PLUS) &&
  filters?.applyByGoalDate !== null &&
  filters?.applyByGoalDate !== undefined &&
  filters?.applyByGoalDate !== "";

export const valuationStatusIsSearch = (filters: any) =>
  filters?.estadoEnProceso === VALUATION_STATUS.SEARCH;

export const valuationStatusIsOutstanding = (filters: any) =>
  filters?.estadoEnProceso === VALUATION_STATUS.OUTSTANDING;
