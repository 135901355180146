import React, { useEffect, useState } from "react";
import {
  ConnectedUsersContext,
  TConnectedUsersContextValue,
  useConnectedUsers,
} from "../hooks/useConnectedUsers";
import { useConnectedUsers as useConnectedUsersQuery } from "../api/getConntectedUsers";

const REFETCH_INTERVAL_IN_MS = 15000;

interface IConnectedUsersProviderProps {}

const ConnectedUsersProvider: React.FC<IConnectedUsersProviderProps> = ({
  children,
}) => {
  return (
    <ConnectedUsersContext.Provider
      value={useState<TConnectedUsersContextValue>({ connectedUsers: [] })}
    >
      <InnerContextUsersProvider>{children}</InnerContextUsersProvider>
    </ConnectedUsersContext.Provider>
  );
};

const InnerContextUsersProvider: React.FC = ({ children }) => {
  const { updateConnectedUsers } = useConnectedUsers();
  const query = useConnectedUsersQuery({
    config: {
      refetchInterval: REFETCH_INTERVAL_IN_MS,
    },
  });

  useEffect(
    function updateConnectedUsersOnQueryUpdate() {
      if (query.data) {
        updateConnectedUsers(query.data.connectedUsers);
      }
    },
    [query.data, updateConnectedUsers]
  );

  return <>{children}</>;
};

export default ConnectedUsersProvider;
