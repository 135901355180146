export const KINSHIP_CHILD = "Hijo/a";
export const KINSHIP_GRANDSON = "Nieto/a";
export const KINSHIP_NEPHEW = "Sobrino/a";
export const KINSHIP_SON_IN_LAW = "Yerno/Nuera";
export const KINSHIP_SPOUSE = "Cónyuge";
export const KINSHIP_SISTER_IN_LAW = "Cuñada/a";
export const KINSHIP_BROTHER_SISTER = "Hermano/a";
export const KINSHIP_UNCLE = "Tio/a";
export const KINSHIP_FATHER_MOTHER = "Padre/madre";
export const KINSHIP_USER = "Usuario/a";
export const KINSHIP_CAREGIVER = "Cuidador/a";
export const KINSHIP_FRIEND = "Amigo/a";
export const KINSHIP_OTHER = "Otro";

const kinships = [
  {
    value: KINSHIP_CHILD,
  },
  {
    value: KINSHIP_GRANDSON,
  },
  {
    value: KINSHIP_NEPHEW,
  },
  {
    value: KINSHIP_SON_IN_LAW,
  },
  {
    value: KINSHIP_SPOUSE,
  },
  {
    value: KINSHIP_FRIEND,
  },
  {
    value: KINSHIP_SISTER_IN_LAW,
  },
  {
    value: KINSHIP_BROTHER_SISTER,
  },
  {
    value: KINSHIP_UNCLE,
  },
  {
    value: KINSHIP_FATHER_MOTHER,
  },
  {
    value: KINSHIP_USER,
  },
  {
    value: KINSHIP_CAREGIVER,
  },
  {
    value: KINSHIP_OTHER,
  },
];

export function kinshipsList(country = "es") {
  return kinships;
}

export default kinships;
