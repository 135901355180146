import React from "react";
import { FormattedMessage } from "react-intl";

interface IFormattedTaskFieldProps {
  task: string;
}

const FormattedTaskField = (props: IFormattedTaskFieldProps) => {
  const { task } = props;

  switch (task) {
    case "Administrar insulina/heparina":
      return (
        <FormattedMessage
          id="Task.Administrar insulina/heparina"
          defaultMessage="Administrar insulina/heparina"
        />
      );
    case "Alimentación por sonda":
      return (
        <FormattedMessage
          id="Task.Alimentación por sonda"
          defaultMessage="Alimentación por sonda"
        />
      );
    case "Aspiración endo-traqueal":
      return (
        <FormattedMessage
          id="Task.Aspiración endo-traqueal"
          defaultMessage="Aspiración endo-traqueal"
        />
      );
    case "Baño e higiene del usuario":
      return (
        <FormattedMessage
          id="Task.Baño e higiene del usuario"
          defaultMessage="Baño e higiene del usuario"
        />
      );
    case "Cambio de bolsas (urinaria/colostomia)":
      return (
        <FormattedMessage
          id="Task.Cambio de bolsas (urinaria/colostomia)"
          defaultMessage="Cambio de bolsas (urinaria/colostomia)"
        />
      );
    case "Cambio de pañal":
      return (
        <FormattedMessage
          id="Task.Cambio de pañal"
          defaultMessage="Cambio de pañal"
        />
      );
    case "Control de medicación":
      return (
        <FormattedMessage
          id="Task.Control de medicación"
          defaultMessage="Control de medicación"
        />
      );
    case "Control de nivel de azúcar en sangre":
      return (
        <FormattedMessage
          id="Task.Control de nivel de azúcar en sangre"
          defaultMessage="Control de nivel de azúcar en sangre"
        />
      );
    case "Convivir con animales de compañía":
      return (
        <FormattedMessage
          id="Task.Convivir con animales de compañía"
          defaultMessage="Convivir con animales de compañía"
        />
      );
    case "Cuidar de mas de 1 persona":
      return (
        <FormattedMessage
          id="Task.Cuidar de mas de 1 persona"
          defaultMessage="Cuidar de más de 1 persona"
        />
      );
    case "Curas básicas":
      return (
        <FormattedMessage
          id="Task.Curas básicas"
          defaultMessage="Curas básicas"
        />
      );
    case "Ejercicios cognitivos":
      return (
        <FormattedMessage
          id="Task.Ejercicios cognitivos"
          defaultMessage="Ejercicios cognitivos"
        />
      );
    case "Higiene encamado/a":
      return (
        <FormattedMessage
          id="Task.Higiene encamado/a"
          defaultMessage="Higiene encamado/a"
        />
      );
    case "Limpieza del hogar":
      return (
        <FormattedMessage
          id="Task.Limpieza del hogar"
          defaultMessage="Tareas básicas del hogar"
        />
      );
    case "Manejo de grúa":
      return (
        <FormattedMessage
          id="Task.Manejo de grúa"
          defaultMessage="Manejo de grúa"
        />
      );
    case "Cambiame":
      return <FormattedMessage id="Task.Cambiame" defaultMessage="Cambiame" />;
    case "Monitorización constantes vitales":
      return (
        <FormattedMessage
          id="Task.Monitorización constantes vitales"
          defaultMessage="Monitorización constantes vitales"
        />
      );
    case "Movilizaciones y transferencias":
      return (
        <FormattedMessage
          id="Task.Movilizaciones y transferencias"
          defaultMessage="Movilizaciones y transferencias"
        />
      );
    case "Nebulizaciones":
      return (
        <FormattedMessage
          id="Task.Nebulizaciones"
          defaultMessage="Nebulizaciones"
        />
      );
    case "Paseos y recados":
      return (
        <FormattedMessage
          id="Task.Paseos y recados"
          defaultMessage="Paseos y recados"
        />
      );
    case "Preparación de comidas":
      return (
        <FormattedMessage
          id="Task.Preparación de comidas"
          defaultMessage="Preparación de comidas"
        />
      );
    case "Programa de ejercicios físicos":
      return (
        <FormattedMessage
          id="Task.Programa de ejercicios físicos"
          defaultMessage="Programa de ejercicios físicos"
        />
      );
    case "Soporte emocional":
      return (
        <FormattedMessage
          id="Task.Soporte emocional"
          defaultMessage="Soporte emocional"
        />
      );
    case "Transporte y conducción":
      return (
        <FormattedMessage
          id="Task.Transporte y conducción"
          defaultMessage="Transporte y conducción"
        />
      );
    case "Soporte en la alimentación":
      return (
        <FormattedMessage
          id="Task.Soporte en la alimentación"
          defaultMessage="Soporte en la alimentación"
        />
      );
    case "Supervisión de las actividades diarias":
      return (
        <FormattedMessage
          id="Task.Supervisión de las actividades diarias"
          defaultMessage="Supervisión de las actividades diarias"
        />
      );
    case "Vigilia nocturna":
      return (
        <FormattedMessage
          id="Task.Vigilia nocturna"
          defaultMessage="Vigilia nocturna"
        />
      );
  }
  return null;
};

export default FormattedTaskField;
