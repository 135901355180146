export const COMMUNICATION_INTENT_TYPE_EMPLOYEE_INTERESTED_IN_OFFER =
  "employee_interested_in_offer";
export const COMMUNICATION_INTENT_TYPE_EMPLOYEE_INTERESTED_IN_OFFER_V2 =
  "employee_interested_in_offer_v2";
export const COMMUNICATION_INTENT_TYPE_EMPLOYEE_INTERESTED_IN_WORK =
  "employee_interested_in_work";

export type CommunicationIntentType =
  | typeof COMMUNICATION_INTENT_TYPE_EMPLOYEE_INTERESTED_IN_OFFER
  | typeof COMMUNICATION_INTENT_TYPE_EMPLOYEE_INTERESTED_IN_OFFER_V2
  | typeof COMMUNICATION_INTENT_TYPE_EMPLOYEE_INTERESTED_IN_WORK;

export const COMMUNICATION_INTENT_FLOW_RESULT_SUCCESS_ADD_VALUATION =
  "success_add_valuation";

export const COMMUNICATION_INTENT_STATUS_AWAITING_INVOCATION =
  "awaiting_invocation";
export const COMMUNICATION_INTENT_STATUS_PENDING = "pending";
export const COMMUNICATION_INTENT_STATUS_IN_PROCESS = "in_process";
export const COMMUNICATION_INTENT_STATUS_SUCCESS = "success";
export const COMMUNICATION_INTENT_STATUS_FINISHED = "finished";
export const COMMUNICATION_INTENT_STATUS_EXPIRED = "expired";
export const COMMUNICATION_INTENT_STATUS_FAILED = "failed";
export const COMMUNICATION_INTENT_STATUS_CANCELLED = "cancelled";
export const COMMUNICATION_INTENT_STATUS_IGNORED = "ignored";
export const COMMUNICATION_INTENT_STATUS_REJECTED = "rejected";

export type CommunicationIntentStatus =
  | typeof COMMUNICATION_INTENT_STATUS_AWAITING_INVOCATION
  | typeof COMMUNICATION_INTENT_STATUS_IN_PROCESS
  | typeof COMMUNICATION_INTENT_STATUS_SUCCESS
  | typeof COMMUNICATION_INTENT_STATUS_FINISHED
  | typeof COMMUNICATION_INTENT_STATUS_EXPIRED
  | typeof COMMUNICATION_INTENT_STATUS_FAILED
  | typeof COMMUNICATION_INTENT_STATUS_CANCELLED
  | typeof COMMUNICATION_INTENT_STATUS_IGNORED
  | typeof COMMUNICATION_INTENT_STATUS_PENDING;

export type CommunicationIntent = {
  id: string;
  externalId?: string | null;
  type: CommunicationIntentType;
  status: CommunicationIntentStatus;
  createdAt: string;
  invokedAt: string;
  completedAt: string;
  updatedAt: string;
  lastMessageAt: string | null;
  userHasInteracted: boolean;
  createdByName?: string;
  externalConversationId?: string | null;
  employee?: {
    id: string;
    Name?: string;
    Nombre?: string;
    Apellidos?: string;
    Phone?: string;
    avatarUrl?: string;
    Estado_Cuideo?: string;
  };
  data: {
    city?: string;
    contractCrmName?: string;
    contractId?: string;
    firstName?: string;
    lastName?: string;
    link?: string;
    salary?: string | number;
    schedule?: string;
    phone?: string;
    valuationId?: string;
    valuationCrmName?: string;
  };
};
