import { REGISTRO_SET_STEP } from "../constants/ActionTypes";

const initialRegistroState = {
  step: 1
};

const registro = (
  state = initialRegistroState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case REGISTRO_SET_STEP:
      return {
        step: action.payload
      };
    default:
      return state;
  }
};

export default registro;
