import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { REPORT_CLIENT_EVENT } from "../constants/ActionTypes";
import { apiEmpleo } from "../util/ApiEmpleo";
import { store } from "../store";
import { updateClientEventState, setClientEventSending } from "../actions";

function* reportClientEvent({ payload }: { payload: any }) {
  // This works like a buffer. It can send events only each 2 seconds so
  // it doesn't overload the server
  const clientEventState = store.getState().clientEvent;
  try {
    if (
      !clientEventState.isSending &&
      Date.now() - clientEventState.lastReported > 5000
    ) {
      yield put(setClientEventSending(true));
      const data = [...clientEventState.pendingEvents, payload];
      yield put(
        updateClientEventState({
          pendingEvents: [],
          lastReported: Date.now(),
        })
      );

      // @ts-ignore
      yield call(apiEmpleo.post, "clientEvent", data, {}, false);

      yield put(setClientEventSending(false));
    } else {
      yield put(
        updateClientEventState({
          lastReported: clientEventState.lastReported,
          pendingEvents: [...clientEventState.pendingEvents, payload],
        })
      );
    }
  } catch (e) {
    // We cant report client event
    console.log("Exception", e);
    yield put(setClientEventSending(false));
  }
}

export function* reportClientEventSaga() {
  yield takeEvery(REPORT_CLIENT_EVENT as any, reportClientEvent);
}

export default function* rootSaga() {
  yield all([fork(reportClientEventSaga)]);
}
