import { Typography } from "@material-ui/core";
import React from "react";
import { cyrb53 } from "../../../../util/hash/cybr53";

const COLORS = [
  "#35cd96",
  "#6bcbef",
  "#e542a3",
  "#91ab01",
  "#ffa97a",
  "#1f7aec",
  "#dfb610",
  "#029d00",
  "#8b7add",
  "#fe7c7f",
  "#ba33dc",
  "#59d368",
  "#b04632",
  "#fd85d4",
  "#8393ca",
  "#ff8f2c",
  "#3bdec3",
  "#b4876e",
  "#c90379",
  "#ef4b4f",
];

interface IMessageAuthorProps {
  seed?: string;
  author: string;
}

const MessageAuthor: React.FC<IMessageAuthorProps> = (props) => {
  const { author } = props;

  const seed = props.seed ? props.seed.charCodeAt(0) : 0;
  const hash = cyrb53(author, seed);
  const sel = hash % 20;

  return (
    <Typography
      className="CMuiCommunicationMessageAuthor"
      style={{ color: COLORS[sel] }}
    >
      {author}
    </Typography>
  );
};

export default MessageAuthor;
