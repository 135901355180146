export const HSM_TEMPLATE = {
  ES_JOB_INTEREST4: "empleo_interes4",
  ES_CONTINUE_CONVERSATION: "continuar_conversacion",
  ES_CLIENT_ANSWER: "respuesta_cliente",
} as const;

export type THsmTemplate = typeof HSM_TEMPLATE[keyof typeof HSM_TEMPLATE];

export type TTemplateAction = {
  templateName: THsmTemplate;
};

export type THsm = {
  hsm: {
    namespace: string;
    templateName: THsmTemplate;
    language: {
      code: string;
    };
  };
};
