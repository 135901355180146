import { IconButton } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import React, { Fragment, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import ConfirmDialog from "../../../components/ConfirmDialog";
import ProgressDialog from "../../../components/ProgressDialog";
import { apiEmpleo } from "../../../util/ApiEmpleo";
import {
  SUGGESTION_REJECTED,
  VALORACION_DESCARTADO,
  VALORACION_RECOMENDACION,
  VALORACION_SUGGESTION,
} from "../../../util/defaults/valoracion";

const StyledAssignButton = styled(IconButton)`
  text-transform: none;
  font-size: 0.875rem;
  color: #fff;
`;

interface DescartaValoracionesButtonProps {
  valoraciones: any[];
  label?: any;
  onDiscard: Function;
}

const DescartaValoracionesButton = (props: DescartaValoracionesButtonProps) => {
  const { valoraciones, label, onDiscard } = props;

  const { formatMessage } = useIntl();

  const [clicked, setClicked] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deletesDone, setDeletesDone] = useState(0);

  const handleDeletes = (valoraciones: any[]) => {
    const sendData = async (valoraciones: any[]) => {
      try {
        setDeleting(true);
        setDeletesDone(0);
        const req = [] as any[];
        for (let va = 0; va < valoraciones.length; va++) {
          if (valoraciones[va].Estado_en_proceso === VALORACION_SUGGESTION) {
            req.push({
              url: `suggestions/${valoraciones[va].id}`,
              data: {
                status: SUGGESTION_REJECTED,
              },
              config: {
                headers: {
                  "Content-Type": "application/merge-patch+json",
                },
              },
            });
          } else if (
            valoraciones[va].Estado_en_proceso === VALORACION_RECOMENDACION
          ) {
          } else {
            req.push({
              url: `valoraciones/${valoraciones[va].id}`,
              data: {
                Estado_en_proceso: VALORACION_DESCARTADO,
              },
              config: {
                headers: {
                  "Content-Type": "application/merge-patch+json",
                },
              },
            });
          }
          setDeletesDone((prev) => prev++);
        }
        await apiEmpleo.multiPatch(req);
        setDeleting(false);
        setClicked(false);
        setConfirmed(false);
        onDiscard();
      } catch (e) {
        console.log("Error discarding valoraciones");
      }
    };

    sendData(valoraciones);
  };

  const handleClick = () => {
    setClicked(true);
  };

  const handleConfirm = () => {
    setConfirmed(true);
    handleDeletes(valoraciones);
  };

  const handleConfirmCancel = () => {
    setClicked(false);
  };

  const candidateNames: string[] = [];
  valoraciones.forEach((valuation) => {
    if (valuation.candidato.Name) {
      candidateNames.push(valuation.candidato.Name);
    }
  });

  return (
    <Fragment>
      <StyledAssignButton
        className="MuiCuideo-DiscardButton"
        color="primary"
        onClick={handleClick}
      >
        <DeleteOutlineIcon />
        {label}
      </StyledAssignButton>
      <ConfirmDialog
        open={clicked && !confirmed}
        title={
          <FormattedMessage
            id="DiscardValuationsButton.Descartar valoraciones"
            defaultMessage="Descartar valoraciones"
          />
        }
        label={
          valoraciones.length > 1 ? (
            <FormattedMessage
              id="DiscardValuationsButton.¿Seguro que quieres eliminar del contrato a {candidates}?"
              defaultMessage="¿Seguro que quieres eliminar del contrato a {candidates}?"
              values={{
                candidates: <b>{candidateNames.join(", ")}</b>,
              }}
            />
          ) : (
            <FormattedMessage
              id="DiscardValuationsButton.¿Seguro que quieres eliminar del contrato a {candidates}?"
              defaultMessage="¿Seguro que quieres eliminar del contrato a {candidates}?"
              values={{
                candidates: <b>{candidateNames.join(", ")}</b>,
              }}
            />
          )
        }
        confirmLabel={formatMessage({
          id: "DiscardValuationsButton.Confirmar",
          defaultMessage: "Confirmar",
        })}
        cancelLabel={formatMessage({
          id: "DiscardValuationsButton.Cancelar",
          defaultMessage: "Cancelar",
        })}
        onConfirm={handleConfirm}
        onCancel={handleConfirmCancel}
      />
      <ProgressDialog
        open={deleting}
        label={
          <FormattedMessage
            id="DiscardValuationsButton.Descartando..."
            defaultMessage="Descartando..."
          />
        }
        iteration={deletesDone}
        totalIterations={valoraciones.length}
      />
    </Fragment>
  );
};

export default DescartaValoracionesButton;
