import { apiEmpleo } from "../../util/ApiEmpleo";
import { TContractPhase } from "./contract.types";

const apiUpdateContractPhase = async (
  contractId: string,
  phase: TContractPhase
) => {
  const res = await apiEmpleo.patch(
    `contracts/${contractId}/phase`,
    { phase },
    {
      headers: {
        "Content-Type": "application/merge-patch+json",
      },
    }
  );

  return res;
};

export { apiUpdateContractPhase };
