import {
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { Alert } from "@material-ui/lab";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import "moment/locale/es";
import { Fragment, useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { VALUATION_STATUS } from "../../../../api/valuation/valuation.types";
import ContratoTooltip from "../../../../components/Contrato/ContratoTooltip";
import HelperDialog from "../../../../components/HelperDialog/HelperDialog";
import cuideoTheme from "../../../../containers/themes/defaultTheme";
import {
  CONTRATO_ESTADO_ACTIVO,
  CONTRATO_ESTADO_FIN_DE_CONTRATO,
  CONTRATO_ESTADO_SELECCIONADO,
} from "../../../../util/defaults/contrato";
import { useCandidateValuations } from "../../features/valuation";
import ContractsTabHelp from "./ContractsTabHelp";
import FactCheckIcon from "../../../../assets/images/iconos/fact_check.svg";
import { Icon } from "../../../../components/shared/Icon";

const StyledWrapper = styled.div`
  margin: 1rem;
  max-width: 71.75rem;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2rem 0 1rem;
  justify-content: space-between;
`;

const StyledTitle = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-size: 1.875rem;
  font-weight: 700;
`;

const StyledContent = styled.div``;

const StyledTable = styled(Table)`
  .MuiTableCell-body {
    border: none;
  }
`;

const StyledTableHead = styled(TableHead)``;

const StyledTableRow = styled(TableRow)``;

const StyledBodyRow = styled(TableRow)`
  background-color: #fff;
`;

const StyledHeadCell = styled(TableCell)`
  background-color: rgba(0, 72, 120, 0.1);
  color: ${cuideoTheme.palette.primary.light};
  font-size: 0.75rem;
  line-height: normal;
  font-weight: 700;
`;

const StyledBodyCell = styled(TableCell)`
  font-size: 0.875rem;
  color: ${cuideoTheme.palette.primary.main};
  line-height: 1.25;
`;

const StyledTableBody = styled(TableBody)``;

const StyledPagination = styled(Pagination)`
  margin: 1rem 0 0;
  .MuiPagination-ul {
    justify-content: center;
  }
`;

const StyledHelpIconButton = styled(IconButton)`
  .MuiSvgIcon-root {
    width: 1.125rem;
    height: 1.125rem;
  }
`;

const StyledLoadingWrapper = styled(TableCell)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 4rem;
  width: 100%;
`;

const StyledErrorWrapper = styled(TableCell)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 4rem;
`;

const StyledErrorAlert = styled(Alert)``;

const StyledEmptyText = styled(Typography)`
  font-size: 0.875rem;
  text-align: center;
`;

const ContractsTab = (props: any) => {
  const { candidato } = props;
  const [page, setPage] = useState(1);
  const [helpOpen, setHelpOpen] = useState(false);
  const [selectedValuationId, setSelectedValuationId] =
    useState<string | null>(null);

  const valuationsQuery = useCandidateValuations({
    params: {
      candidateId: candidato.id,
      page,
      estadoEnProceso: VALUATION_STATUS.CONTRACTED,
      "contrato.estado": [
        CONTRATO_ESTADO_ACTIVO,
        CONTRATO_ESTADO_SELECCIONADO,
        CONTRATO_ESTADO_FIN_DE_CONTRATO,
      ],
    },
  });

  const handlePageChange = (event: any, value: any) => setPage(value);

  const handleHelpOpen = () => {
    setHelpOpen(true);
  };

  const handleHelpClose = () => {
    setHelpOpen(false);
  };

  const status = valuationsQuery.status;

  return (
    <StyledWrapper>
      <StyledHeader>
        <StyledTitle>
          <FormattedMessage
            id="ContractsTab.Contracts"
            defaultMessage="Contratos"
          />
        </StyledTitle>
        <StyledHelpIconButton color="primary" onClick={handleHelpOpen}>
          <HelpOutlineIcon />
        </StyledHelpIconButton>
      </StyledHeader>
      <StyledContent>
        <StyledTable>
          <StyledTableHead>
            <StyledTableRow>
              <StyledHeadCell>
                <FormattedMessage
                  id="ContractsTab.Contract"
                  defaultMessage="Contrato"
                />
              </StyledHeadCell>
              <StyledHeadCell>
                <FormattedMessage
                  id="ContractsTab.Status"
                  defaultMessage="Estado"
                />
              </StyledHeadCell>
              <StyledHeadCell>
                <FormattedMessage
                  id="ContractsTab.Customer"
                  defaultMessage="Cliente"
                />
              </StyledHeadCell>
              <StyledHeadCell>
                <FormattedMessage
                  id="ContractsTab.ServiceSchedule"
                  defaultMessage="Horario Servicio"
                />
              </StyledHeadCell>
              <StyledHeadCell>
                <FormattedMessage
                  id="ContractsTab.BeginAndEnd"
                  defaultMessage="Inicio y fin"
                />
              </StyledHeadCell>
              <StyledHeadCell>
                <FormattedMessage
                  id="ContractsTab.Owner"
                  defaultMessage="Owner"
                />
              </StyledHeadCell>
              <StyledHeadCell>
                <FormattedMessage
                  id="ContractsTab.EndReasonType"
                  defaultMessage="Tipo fin contrato"
                />
              </StyledHeadCell>
              <StyledHeadCell>
                <FormattedMessage
                  id="ContractsTab.EndNote"
                  defaultMessage="Nota"
                />
              </StyledHeadCell>
              <StyledHeadCell>
                <FormattedMessage
                  id="ContractsTab.Valuation"
                  defaultMessage="Valoración"
                />
              </StyledHeadCell>
              <StyledHeadCell />
            </StyledTableRow>
          </StyledTableHead>
          <StyledTableBody>
            {status === "loading" && (
              <StyledBodyRow>
                <TableCell colSpan={10}>
                  <StyledLoadingWrapper>
                    <CircularProgress thickness={5} size={22} />
                  </StyledLoadingWrapper>
                </TableCell>
              </StyledBodyRow>
            )}
            {status === "error" && (
              <StyledBodyRow>
                <TableCell colSpan={10}>
                  <StyledErrorWrapper>
                    <StyledErrorAlert severity="error">
                      <FormattedMessage
                        id="ContractsTab.Ha habido un error"
                        defaultMessage="Ha habido un error"
                      />
                    </StyledErrorAlert>
                  </StyledErrorWrapper>
                </TableCell>
              </StyledBodyRow>
            )}
            {status === "success" && valuationsQuery.data && (
              <>
                {valuationsQuery.data["hydra:member"].length === 0 ? (
                  <StyledBodyRow>
                    <TableCell colSpan={10}>
                      <StyledEmptyText>
                        <FormattedMessage
                          id="ContractsTab.Este candidato/a no tiene ningún contrato"
                          defaultMessage="Este candidato/a no tiene ningún contrato"
                        />
                      </StyledEmptyText>
                    </TableCell>
                  </StyledBodyRow>
                ) : (
                  valuationsQuery.data["hydra:member"].map((valuation) => {
                    const displayValorationRow =
                      valuation.contrato?.Compromiso ||
                      valuation.contrato?.Experiencia ||
                      valuation.contrato?.Comunicaci_n ||
                      valuation.contrato?.Paciencia_y_Empatia ||
                      valuation.contrato?.Car_cter;
                    return (
                      <>
                        <StyledBodyRow key={valuation.id}>
                          <StyledBodyCell>
                            <ContratoTooltip contrato={valuation.contrato} />
                          </StyledBodyCell>
                          <StyledBodyCell>
                            {valuation.contrato?.Estado}
                          </StyledBodyCell>
                          <StyledBodyCell>
                            {valuation.contrato?._Nombre_del_cliente}
                          </StyledBodyCell>
                          <StyledBodyCell>
                            {valuation.contrato?.Horario_Servicio}
                          </StyledBodyCell>
                          <StyledBodyCell>
                            {valuation.fecha_inicio_contrato &&
                              valuation.fecha_fin_contrato === undefined && (
                                <Fragment>
                                  Desde{" "}
                                  {moment(
                                    valuation.fecha_inicio_contrato
                                  ).format("DD/MM/YYYY")}
                                </Fragment>
                              )}
                            {valuation.fecha_inicio_contrato &&
                              valuation.fecha_fin_contrato && (
                                <Fragment>
                                  De{" "}
                                  {moment(
                                    valuation.fecha_inicio_contrato
                                  ).format("DD/MM/YYYY")}{" "}
                                  a{" "}
                                  {moment(valuation.fecha_fin_contrato).format(
                                    "DD/MM/YYYY"
                                  )}
                                </Fragment>
                              )}
                          </StyledBodyCell>
                          <StyledBodyCell>
                            {valuation.contrato?.ownerName}
                          </StyledBodyCell>
                          <StyledBodyCell>
                            {valuation.contrato?.Tipo_fin_contrato}
                          </StyledBodyCell>
                          <StyledBodyCell>
                            {valuation.contrato?.A_ade_nota_del_candidato_a}
                          </StyledBodyCell>
                          <StyledBodyCell>
                            {valuation.contrato?.Valoracion_Cuideo_Candidata}
                          </StyledBodyCell>
                          <StyledBodyCell>
                            {displayValorationRow && (
                              <Icon
                                src={FactCheckIcon}
                                onClick={() => {
                                  setSelectedValuationId(
                                    selectedValuationId === valuation.id
                                      ? null
                                      : valuation.id
                                  );
                                }}
                              />
                            )}
                          </StyledBodyCell>
                        </StyledBodyRow>
                        {selectedValuationId === valuation.id && (
                          <StyledBodyRow
                            style={{
                              backgroundColor: cuideoTheme.palette.grey[300],
                            }}
                          >
                            <StyledBodyCell colSpan={10}>
                              <div>
                                <b>Valoración Cliente</b>
                                <div
                                  style={{
                                    marginTop: 16,
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span>
                                    <b>Compromiso:</b>{" "}
                                    {valuation.contrato?.Compromiso}
                                  </span>
                                  <span>
                                    <b>Experiencia:</b>{" "}
                                    {valuation.contrato?.Experiencia}
                                  </span>
                                  <span>
                                    <b>Comunicación:</b>{" "}
                                    {valuation.contrato?.Comunicaci_n}
                                  </span>
                                  <span>
                                    <b>Paciencia y empatia:</b>{" "}
                                    {valuation.contrato?.Paciencia_y_Empatia}
                                  </span>
                                  <span>
                                    <b>Carácter:</b>{" "}
                                    {valuation.contrato?.Car_cter}
                                  </span>
                                </div>
                              </div>
                            </StyledBodyCell>
                          </StyledBodyRow>
                        )}
                      </>
                    );
                  })
                )}
              </>
            )}
          </StyledTableBody>
        </StyledTable>
        {status === "success" &&
          valuationsQuery.data &&
          valuationsQuery.data["hydra:totalItems"] > 0 && (
            <StyledPagination
              count={Math.floor(valuationsQuery.data["hydra:totalItems"] / 10)}
              page={page}
              onChange={handlePageChange}
              color="primary"
            />
          )}
      </StyledContent>
      <HelperDialog
        open={helpOpen}
        onClose={handleHelpClose}
        text={<ContractsTabHelp />}
        title={
          <FormattedMessage id="ContractsTab.Help" defaultMessage="Ayuda" />
        }
        okLabel={
          <FormattedMessage id="ContractsTab.Accept" defaultMessage="Aceptar" />
        }
      />
    </StyledWrapper>
  );
};

export default ContractsTab;
