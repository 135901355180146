import { Grid } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Skeleton from "@material-ui/lab/Skeleton";
import { push } from "connected-react-router";
import moment from "moment";
import "moment/locale/es";
import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import styled from "styled-components";
import { getCandidato } from "../../../../actions";
import { avatarImgBaseUrl } from "../../../../config/assetLocations";
import { formatAsPhoneNumber } from "../../../../util/formatters";
import {
  StyledCard,
  StyledCardAction,
  StyledCardActions,
  StyledCardContent,
  StyledCardHeader,
  StyledCardTitle,
  StyledData,
  StyledLabel,
} from "../ProfileStyles";

const StyledAvatarIcon = styled(AccountCircleIcon)`
  width: 2rem;
  height: 2rem;
`;

const StyledUserImage = styled.img`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  padding: 1%;
`;

const PersonalInformationCard = (props: any) => {
  const { candidato, push, authUser, WarningComponent } = props;

  return (
    <StyledCard>
      <StyledCardHeader>
        <StyledCardTitle>
          <FormattedMessage
            id="PersonalInformationCard.Title"
            defaultMessage="Información personal"
          />
        </StyledCardTitle>
        <StyledCardActions>
          {authUser && authUser.userId === candidato.id && (
            <StyledCardAction
              color="primary"
              variant="outlined"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                push("/empleo/miPerfil/informacionPersonal");
              }}
            >
              <FormattedMessage
                id="PersonalInformationCard.Edit"
                defaultMessage="Editar"
              />
            </StyledCardAction>
          )}
        </StyledCardActions>
      </StyledCardHeader>
      <StyledCardContent>
        {WarningComponent}
        <Grid container spacing={8}>
          <Grid item xs={12} md={4}>
            <StyledLabel>
              <FormattedMessage
                id="PersonalInformationCard.Name"
                defaultMessage="Nombre"
              />
            </StyledLabel>
            {candidato && candidato.Nombre ? (
              <StyledData>{candidato.Nombre}</StyledData>
            ) : (
              <Skeleton variant="rect" />
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledLabel>
              <FormattedMessage
                id="PersonalInformationCard.FirstSurname"
                defaultMessage="Primer apellido"
              />
            </StyledLabel>
            {candidato && candidato.Apellidos ? (
              <StyledData>{candidato.Apellidos}</StyledData>
            ) : (
              <Skeleton variant="rect" />
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledLabel>
              <FormattedMessage
                id="PersonalInformationCard.SecondSurname"
                defaultMessage="Segundo apellido"
              />
            </StyledLabel>
            {candidato && candidato.Apellidos_2 ? (
              <StyledData>{candidato.Apellidos_2}</StyledData>
            ) : (
              <StyledData>-</StyledData>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={8}>
          <Grid item xs={12} md={4}>
            <StyledLabel>
              <FormattedMessage
                id="PersonalInformationCard.Sex"
                defaultMessage="Sexo"
              />
            </StyledLabel>
            {candidato && candidato.Sexo ? (
              <StyledData>{candidato.Sexo}</StyledData>
            ) : (
              <StyledData>-</StyledData>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledLabel>
              <FormattedMessage
                id="PersonalInformationCard.Birthdate"
                defaultMessage="Fecha de nacimiento"
              />
            </StyledLabel>
            {candidato && candidato.Fecha_de_nacimiento ? (
              <StyledData>
                {moment(candidato.Fecha_de_nacimiento).format("DD/MM/YYYY")}
              </StyledData>
            ) : (
              <StyledData>-</StyledData>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledLabel>
              <FormattedMessage
                id="PersonalInformationCard.Birthplace"
                defaultMessage="País de nacimiento"
              />
            </StyledLabel>
            {candidato && candidato.Lugar_de_nacimiento ? (
              <StyledData>{candidato.Lugar_de_nacimiento}</StyledData>
            ) : (
              <StyledData>-</StyledData>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={8}>
          <Grid item xs={12} md={4}>
            <StyledLabel>
              <FormattedMessage
                id="PersonalInformationCard.DNI"
                defaultMessage="DNI"
              />
            </StyledLabel>
            {candidato && candidato.DNI_NIE ? (
              <StyledData>{candidato.DNI_NIE}</StyledData>
            ) : (
              <StyledData>-</StyledData>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledLabel>
              <FormattedMessage
                id="PersonalInformationCard.Phone"
                defaultMessage="Teléfono"
              />
            </StyledLabel>
            {candidato && candidato.Phone ? (
              <StyledData>{formatAsPhoneNumber(candidato.Phone)}</StyledData>
            ) : (
              <StyledData>-</StyledData>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledLabel>
              <FormattedMessage
                id="PersonalInformationCard.Email"
                defaultMessage="Correo electrónico"
              />
            </StyledLabel>
            {candidato && candidato.Email ? (
              <StyledData>{candidato.Email}</StyledData>
            ) : (
              <StyledData>-</StyledData>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={8}>
          <Grid item xs={12} md={4}>
            <StyledLabel>
              <FormattedMessage
                id="PersonalInformationCard.ProfileImage"
                defaultMessage="Imagen de perfil"
              />
            </StyledLabel>
            {candidato.avatarUrl ? (
              <StyledData>
                <StyledUserImage src={avatarImgBaseUrl + candidato.avatarUrl} />
              </StyledData>
            ) : (
              <StyledData>
                <StyledAvatarIcon />
              </StyledData>
            )}
          </Grid>
          <Grid item xs={12} md={8}>
            <StyledLabel>
              <FormattedMessage
                id="PersonalInformationCard.Address"
                defaultMessage="Dirección"
              />
            </StyledLabel>
            {candidato && candidato.Direccion ? (
              <StyledData>{`${candidato.Direccion}, ${candidato.Codigo_postal}, ${candidato.Ciudad}`}</StyledData>
            ) : (
              <StyledData>-</StyledData>
            )}
          </Grid>
        </Grid>
      </StyledCardContent>
    </StyledCard>
  );
};

const mapStateToProps = ({
  candidatosState,
  commonData,
  auth,
}: {
  candidatosState: { candidatos: any };
  commonData: any;
  auth: { authUser: any };
}) => {
  const { candidatos } = candidatosState;
  const {
    paisesOptions,
    provinciasOptions,
    idiomasOptions,
    titulacionesOptions,
    tareasOptions,
    educacionOptions,
  } = commonData;
  const { authUser } = auth;

  return {
    candidatos,
    paisesOptions,
    provinciasOptions,
    idiomasOptions,
    titulacionesOptions,
    tareasOptions,
    educacionOptions,
    authUser,
  };
};

export default connect(mapStateToProps, {
  getCandidato,
  push,
})(PersonalInformationCard);
