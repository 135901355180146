import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { CommunicationIntentContractValidationError } from "./CreateCommunicationIntentsButton.types";

const StyledAlert = styled(Alert)`
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25;

  .MuiSvgIconroot {
    width: 1.25rem;
    height: 1.25rem;
  }

  .MuiAlertTitleroot {
    font-size: 0.9375rem;
    line-height: 1.25;
  }
`;

const StyledList = styled.ul``;

const StyledItem = styled.li``;

interface IContractErrorsProps {
  errors: CommunicationIntentContractValidationError[];
}

const ContractErrors: React.FC<IContractErrorsProps> = ({ errors }) => {
  const renderErrorMessage = (
    error: CommunicationIntentContractValidationError
  ): React.ReactNode => {
    switch (error) {
      case CommunicationIntentContractValidationError.NO_NAME:
        return (
          <FormattedMessage
            id="ContractErrors.El Contrato no tiene identificador"
            defaultMessage="El contrato no tiene identificador"
          />
        );
      case CommunicationIntentContractValidationError.NO_SALARY:
        return (
          <FormattedMessage
            id="ContractErrors.El Contrato no tiene salario"
            defaultMessage="El contrato no tiene salario"
          />
        );
      case CommunicationIntentContractValidationError.NO_OWNER:
        return (
          <FormattedMessage
            id="ContractErrors.El Contrato no tiene propietario"
            defaultMessage="El contrato no tiene propietario"
          />
        );
      case CommunicationIntentContractValidationError.NO_SCHEDULE:
        return (
          <FormattedMessage
            id="ContractErrors.El Contrato no tiene horario"
            defaultMessage="El contrato no tiene horario"
          />
        );
      case CommunicationIntentContractValidationError.NO_CITY:
        return (
          <FormattedMessage
            id="ContractErrors.El Contrato no tiene ciudad"
            defaultMessage="El contrato no tiene ciudad"
          />
        );
      case CommunicationIntentContractValidationError.MAX_PER_CONTRACT:
        return (
          <FormattedMessage
            id="ContractErrors.Se ha alcanzado el máximo de intentos de comunicación por contrato"
            defaultMessage="Se ha alcanzado el máximo de intentos de comunicación por contrato"
          />
        );
      default:
        return (
          <FormattedMessage
            id="ContractErrors.Error desconocido"
            defaultMessage="Error desconocido"
          />
        );
    }
  };

  return (
    <StyledAlert severity="error">
      <AlertTitle>
        <FormattedMessage
          id="CreateCommunicationIntentsButton.Hay errores en el contrato por los que no puedes intentar contactar vía Whatsapp por este medio."
          defaultMessage="Hay errores en el contrato por los que no puedes intentar contactar vía Whatsapp por este medio."
        />
      </AlertTitle>
      <StyledList>
        {errors.map((error, index) => (
          <StyledItem key={`ci_ce_${index}_${error}`}>
            {renderErrorMessage(error)}
          </StyledItem>
        ))}
      </StyledList>
    </StyledAlert>
  );
};

export default ContractErrors;
