export const hasSearchText = (
  toSearch: any,
  toFind: any,
  caseSensitive = false
) => {
  function strFromLocale(str: string) {
    function match(letter: string) {
      function letterMatch(letter: string, normalizedLetter: string) {
        let location = new Intl.Collator("en-US", {
          usage: "search",
          sensitivity: "base",
        }).compare(letter, normalizedLetter);
        // eslint-disable-next-line
        return location == 0;
      }
      let normalizedLetter = letter
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/gi, "");
      if (letterMatch(letter, normalizedLetter)) {
        return normalizedLetter;
      } else {
        return letter;
      }
    }
    // eslint-disable-next-line
    return str.replace(/[^\u0000-\u007E]/g, match);
  }
  let stack = strFromLocale(toSearch);
  let needle = strFromLocale(toFind);

  if (!caseSensitive) {
    needle = needle.toLowerCase();
    stack = stack.toLowerCase();
  }

  return stack.indexOf(needle) >= 0;
};
