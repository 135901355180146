import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { cuideoTheme } from "../../../../../../containers/themes/defaultTheme";
import { TContractPartialWithNumbers } from "../../types/contract.types";
import {
  contractIsComfort,
  contractIsComfortPlus,
  contractIsPunctualService,
} from "../../util/contract.util";

const StyledPlanTag = styled.span`
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  color: ${cuideoTheme.palette.primary.main};
  font-weight: 600;
`;

interface IPlanTagProps {
  contract: TContractPartialWithNumbers;
}

const PlanTag: React.FC<IPlanTagProps> = ({ contract }) => {
  if (contractIsComfortPlus(contract)) {
    return (
      <StyledPlanTag>
        <FormattedMessage
          id="PlanTag.Servicio Confort+"
          defaultMessage="Servicio Confort+"
        />
      </StyledPlanTag>
    );
  }

  if (contractIsComfort(contract)) {
    return (
      <StyledPlanTag>
        <FormattedMessage
          id="PlanTag.Servicio Confort"
          defaultMessage="Servicio Confort"
        />
      </StyledPlanTag>
    );
  }

  if (contractIsPunctualService(contract)) {
    return (
      <StyledPlanTag>
        <FormattedMessage
          id="PlanTag.Servicio Puntual Selección"
          defaultMessage="Servicio Puntual Selección"
        />
      </StyledPlanTag>
    );
  }

  return null;
};

export default PlanTag;
