import moment from "moment";
import {
  CONTRACT_DEAL_TYPE,
  CONTRACT_STATE,
  CONTRACT_JOB_TYPE,
  TContractDealType,
  TContractJobType,
  TGetContractsItem,
} from "../../../../../api/contract/contract.types";
import { SERVICE_PLAN } from "../../../../../api/service/service.types";
import { TAuthenticatedUser } from "../../../../../reducers/Auth";
import { userIsPromoter } from "../../../models/Staff/Staff";
import {
  TContractFull,
  TContractPartialWithNumbers,
} from "../types/contract.types";

export const contractHasMoreThan24hSinceCv = (
  contract: TContractPartialWithNumbers | TGetContractsItem
) => {
  const cvWarningMom = moment(contract.nextCvFollowUpAt);
  if (cvWarningMom.isValid()) {
    const now = moment();
    if (now.isAfter(cvWarningMom)) {
      return true;
    }
  }

  return false;
};

export const contractIsInValuationPipeline = (
  contract: TContractPartialWithNumbers | TContractFull
): boolean => {
  if (!contract.Estado) {
    return false;
  }

  if (
    CONTRACT_STATE.PENDING_SIGN_UP === contract.Estado ||
    CONTRACT_STATE.CV_CLIENT === contract.Estado ||
    CONTRACT_STATE.FACE_TO_FACE_CLIENT === contract.Estado
  ) {
    return true;
  }

  return true;
};

export const contractHasCvWarning = (
  contract: TContractPartialWithNumbers,
  user: TAuthenticatedUser
): boolean => {
  if (!userIsPromoter(user)) {
    return false;
  }

  if (!contractIsInValuationPipeline(contract)) {
    return false;
  }

  if (!contract.nextCvFollowUpAt) {
    return false;
  }

  if (!contract.numCvClient) {
    return false;
  }

  if (contract.numContracted) {
    return false;
  }

  const cvWarningMom = moment(contract.nextCvFollowUpAt);
  if (cvWarningMom.isValid()) {
    const now = moment();
    if (now.isAfter(cvWarningMom)) {
      return true;
    }
  }

  return false;
};

export const contractDaysToTarget = (
  contract: TContractPartialWithNumbers,
  currentTime: Date
): number => {
  if (!contract.Fecha_objetivo) {
    return 0;
  }

  const objectiveDateMom = moment(contract.Fecha_objetivo);
  const currentMom = moment(currentTime);

  return objectiveDateMom.diff(currentMom, "days");
};

export const contractHasExpiredFaceToFaceAtDate = (
  contract: TContractPartialWithNumbers | TGetContractsItem,
  currentTime: Date
): boolean => {
  if (!contract.faceToFaceWarnAt) {
    return false;
  }

  const warningMom = moment(contract.faceToFaceWarnAt);
  if (warningMom.isValid()) {
    const now = moment(currentTime);
    if (now.isAfter(warningMom)) {
      return true;
    }
  }

  return false;
};

export const contractHasFaceToFaceWarning = (
  contract: TContractPartialWithNumbers,
  user: TAuthenticatedUser,
  currentTime: Date
): boolean => {
  if (!userIsPromoter(user)) {
    return false;
  }

  if (!contractIsInValuationPipeline(contract)) {
    return false;
  }

  if (!contract.numFaceToFace) {
    return false;
  }

  return contractHasExpiredFaceToFaceAtDate(contract, currentTime);
};

export const hasToDoCvFollowUp = (contract: TContractFull): boolean => {
  if (!contractIsInValuationPipeline(contract)) {
    return false;
  }

  if (contract.Seguimiento_cv_realizado) {
    return false;
  }

  if (!contract.nextCvFollowUpAt) {
    return false;
  }

  if (!contract.numCVCliente) {
    return false;
  }

  if (contract.numContratado) {
    return false;
  }

  const cvWarningMom = moment(contract.nextCvFollowUpAt);
  if (cvWarningMom.isValid()) {
    const now = moment();
    if (now.isAfter(cvWarningMom)) {
      return true;
    }
  }

  return false;
};

export const contractHasPastTargetDate = (
  contract: TContractPartialWithNumbers | TGetContractsItem,
  currentTime: Date
): boolean => {
  if (!contract.Fecha_objetivo) {
    return false;
  }

  const warningMom = moment(contract.Fecha_objetivo);
  if (warningMom.isValid()) {
    const now = moment(currentTime);
    if (now.isAfter(warningMom)) {
      return true;
    }
  }

  return false;
};

export const contractHasPastCvClientDate = (
  contract: TContractPartialWithNumbers | TGetContractsItem,
  currentTime: Date
): boolean => {
  if (!contract.Fecha_cv_cliente) {
    return false;
  }

  const warningMom = moment(contract.Fecha_cv_cliente);
  if (warningMom.isValid()) {
    const now = moment(currentTime);
    if (now.isAfter(warningMom)) {
      return true;
    }
  }

  return false;
};

export const contractJobTypeFromContractDealType = (
  dealType: TContractDealType
): TContractJobType | null => {
  if (dealType === CONTRACT_DEAL_TYPE.EXTERNAL_DURING_WEEK) {
    return CONTRACT_JOB_TYPE.EXTERNAL_DURING_WEEK;
  }
  if (dealType === CONTRACT_DEAL_TYPE.EXTERNAL_DURING_WEEKEND) {
    return CONTRACT_JOB_TYPE.EXTERNAL_DURING_WEEKEND;
  }
  if (dealType === CONTRACT_DEAL_TYPE.INTERNAL_DURING_WEEK) {
    return CONTRACT_JOB_TYPE.INTERNAL_DURING_WEEK;
  }
  if (dealType === CONTRACT_DEAL_TYPE.INTERNAL_DURING_WEEKEND) {
    return CONTRACT_JOB_TYPE.INTERNAL_DURING_WEEKEND;
  }

  // We cannot infer directly from other values
  return null;
};

export const contractIsComfortPlus = (
  contract: TContractPartialWithNumbers | TGetContractsItem
) => {
  if (
    contract.Servicio?.Plan === SERVICE_PLAN.COMFORT_PLUS_IN_PERSON ||
    contract.Servicio?.Plan === SERVICE_PLAN.COMFORT_PLUS_PHONE
  ) {
    return true;
  }

  return false;
};

export const contractIsComfort = (
  contract: TContractPartialWithNumbers | TGetContractsItem
) => {
  if (contract.Servicio?.Plan === SERVICE_PLAN.COMFORT) {
    return true;
  }

  if (!contract.Servicio?.Plan) {
    if (
      contract.Tipo_de_Trato === CONTRACT_DEAL_TYPE.INTERNAL_DURING_WEEK ||
      contract.Tipo_de_Trato === CONTRACT_DEAL_TYPE.INTERNAL_DURING_WEEKEND ||
      contract.Tipo_de_Trato ===
        CONTRACT_DEAL_TYPE.EXTERNAL_MORE_20_WEEKLY_HOURS ||
      contract.Tipo_de_Trato ===
        CONTRACT_DEAL_TYPE.EXTERNAL_LESS_20_WEEKLY_HOURS
    ) {
      return true;
    }
  }

  return false;
};

export const contractIsPunctualService = (
  contract: TContractPartialWithNumbers | TGetContractsItem
) => {
  if (contract.Servicio?.Plan === SERVICE_PLAN.PUNCTUAL_SELECTION) {
    return true;
  }

  return false;
};

export const contractIsExpress = (
  contract: TContractPartialWithNumbers | TGetContractsItem
): boolean => {
  if (contract?.Servicio_Express) {
    return true;
  }

  return false;
};
