import enLang from "./entries/en-US";
import esLang from "./entries/es_ES";
import frLang from "./entries/fr";

const AppLocale = {
  en: enLang,
  es: esLang,
  fr: frLang,
};

export default AppLocale;
