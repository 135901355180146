import { useQuery } from "react-query";
import { apiGetStaffUser } from "../../../../../api/user/apiGetStaffUser";
import {
  ExtractFnReturnType,
  QueryConfig,
} from "../../../../../lib/react-query";
import { userKeys } from "../util/user.query-keys";

const getUser = async ({ userId }: { userId: string }) => {
  return await apiGetStaffUser(userId);
};

type QueryFnType = typeof getUser;

type UseUserOptions = {
  userId: string;
  config?: QueryConfig<QueryFnType>;
};

export const useUser = ({ userId, config }: UseUserOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: userKeys.item(userId),
    queryFn: () => getUser({ userId }),
  });
};
