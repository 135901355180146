import { Typography } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import SadWorkers from "../../../../../assets/images/sad_workers.svg";
import { cuideoTheme } from "../../../../../containers/themes/defaultTheme";

const StyledWrapper = styled.div`
  margin: 0.25rem 0.5rem;
  padding: 0.25rem 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  border-radius: 0.375rem;
  width: 100%;
  box-shadow: inset 0 1px 1px 0 rgb(0 0 0 / 24%);
`;

const StyledImageWrapper = styled.div`
  .CMuiSadWorkersImage {
    width: 5rem;
    height: 5rem;
  }
  margin-right: 0.625rem;
`;

const StyledTitle = styled(Typography)`
  font-size: 1.125rem;
  font-weight: 700;
  color: ${cuideoTheme.palette.primary.main};
`;

const StyledText = styled(Typography)`
  font-size: 0.875rem;
  line-height: 1.35;
  color: ${cuideoTheme.palette.primary.main};
`;

interface ISadWorkerTagProps {}

const SadWorkerTag: React.FC<ISadWorkerTagProps> = (props) => {
  return (
    <StyledWrapper>
      <StyledImageWrapper>
        <img src={SadWorkers} alt="SAD" className="CMuiSadWorkersImage" />
      </StyledImageWrapper>
      <div>
        <StyledTitle>
          <FormattedMessage
            id="SadWorkerTag.Trabajador SAD"
            defaultMessage="Trabajador SAD"
          />
        </StyledTitle>
        <StyledText>
          <FormattedMessage
            id="SadWorkerTag.Este candidato es un trabajador SAD, tenlo en cuenta a la hora de actualizar sus datos o valorarlo."
            defaultMessage="Este candidato es un trabajador SAD, tenlo en cuenta a la hora de actualizar sus datos o valorarlo."
          />
        </StyledText>
      </div>
    </StyledWrapper>
  );
};

export default SadWorkerTag;
