import {
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CheckIcon from "@material-ui/icons/Check";
import { motion, Variants } from "framer-motion";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { userSignIn } from "../actions";
import { userSignInResetStatus } from "../actions/Auth";
import { setBackground } from "../actions/Layout";
import CuideoCheckbox from "../components/CuideoFields/CuideoCheckbox";
import { APP_START_PAGE } from "../util/defaults/app";
import useFooter from "../util/useFooter";
import useHeader from "../util/useHeader";
import usePageTitle from "../util/usePageTitle";
import cuideoTheme from "./themes/defaultTheme";

const StyledPageContainer = styled.div<{ appMarginTop: number }>`
  ${({ appMarginTop }) => {
    return `
    margin: ${appMarginTop}rem auto 0;
  `;
  }}

  @media all and (max-width: ${cuideoTheme.breakpoints.values.sm}px) {
    padding: 0;
    background-color: ${cuideoTheme.palette.common.white};
    margin: ${({ appMarginTop }) => appMarginTop}rem 0 0;
  }

  @media all and (min-width: ${cuideoTheme.breakpoints.values
      .md}px) and (max-width: ${cuideoTheme.breakpoints.values.lg}px) {
    margin: ${({ appMarginTop }) => appMarginTop + 3}rem 1rem 1rem;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.lg}px) {
    margin: ${({ appMarginTop }) => appMarginTop + 3}rem 0 1rem;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  min-height: calc(100vh - 10rem);
  width: 100%;

  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    min-height: 38rem;
  }
`;

const LoginBoxesWrapper = styled.div`
  @media all and (max-width: ${cuideoTheme.breakpoints.values.sm}px) {
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values
      .sm}px) and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    max-width: 30rem;
    margin: 2rem auto;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`;

const StyledBoxTitle = styled.h2`
  font-family: "Lora", Georgia, serif;
  color: #004b7b;
  font-weight: 700;
  margin: 0;

  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 1.25rem;
    line-height: 1.35;
    text-align: center;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 1.5rem;
    line-height: 1.4;
  }
`;
const StyledBoxSubtitle = styled.p`
  font-family: "Lora", Georgia, serif;
  color: #5bc0be;
  font-weight: 700;
  margin: 0;
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 1.25rem;
    line-height: 1.35;
    text-align: center;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 1.5rem;
    line-height: 1.4;
  }
`;

const StyledNewUserDetailText = styled.p`
  font-size: 1rem;
  line-height: 1.25;
  color: #004b7b;
  margin: 0 0 1rem;
`;

const StyledListItemAvatar = styled(ListItemAvatar)`
  min-width: unset;
  margin-right: 0.625rem;
  color: #5bc0be;
  width: 2rem;
  height: 2rem;
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    margin-right: 1rem;
  }
`;

const StyledCheckIconWrapper = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: ${cuideoTheme.palette.common.white};
  margin-top: 0.65rem;
`;

const StyledForgotLink = styled(Link)`
  text-decoration: none;
  color: #1dbbfa;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  display: block;
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 0.8rem;
    text-align: center;
    margin: 0.5rem 0 1.5rem;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 0.8rem;
    margin: 0.5rem 0 1.5rem;
  }
`;

const StyledRememberWrapper = styled.div`
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    margin: 0 auto;
    width: fit-content;
  }
`;

const loginBoxVariants: Variants = {
  shown: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.25, delay: 0.25 },
  },
  hidden: {
    opacity: 0,
    x: 50,
    transition: { duration: 0.1 },
  },
};
const LoginExistingUserBox = styled(motion.div)`
  background-color: #fff;
  padding: 2rem;
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    width: 28.5rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    padding: 2.8rem 2.375rem;
  }
`;

const StyledRememberLabel = styled.div`
  padding: 0.75rem 0;
`;

const signUpVariants: Variants = {
  shown: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.25, delay: 0.5 },
  },
  hidden: {
    opacity: 0,
    x: -50,
    transition: { duration: 0.1 },
  },
};
const LoginNewUserBox = styled(motion.div)`
  background-color: #50e3c2;
  padding: 2rem;
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    margin-left: 2rem;
    width: 28.5rem;
    box-sizing: border-box;
    padding: 2.8rem 4rem 5.25rem;
    border-radius: 0.5rem;
  }
`;

const StyledBoxesContent = styled.div`
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    min-height: 13rem;
    padding: 1rem 0 0;
  }
`;

const StyledTextField = styled(TextField)`
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    box-shadow: none;
  }
`;

const StyledList = styled(List)`
  margin-bottom: 2rem;
  .MuiListItem-root {
    padding: 0;
    margin: 0;
    align-items: flex-start;
  }

  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    .MuiListItem-root {
      margin-top: 0;
    }
  }
`;

const StyledErrorBox = styled.div`
  color: ${cuideoTheme.palette.error.main};
  background-color: ${(cuideoTheme.palette.error as any)[100]};
  padding: 1rem;
  margin-top: 1rem;
  border: 1px solid ${cuideoTheme.palette.error.main};
  border-radius: ${cuideoTheme.shape.borderRadius}px;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
`;

const JobsSignIn = ({
  authUser,
  history,
  loggingStatus,
  loggingMessage,
  userSignIn,
  setBackground,
  userSignInResetStatus,
  appMarginTop,
}: {
  authUser: any;
  history: any;
  loggingStatus: string | null;
  loggingMessage: string | null;
  userSignIn: Function;
  setBackground: Function;
  userSignInResetStatus: Function;
  appMarginTop: any;
}) => {
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
    rememberMe: true,
  });
  const [formError, setFormError] = useState("");
  const { formatMessage } = useIntl();

  useFooter({
    type: "normal",
  });
  useHeader({
    type: "simple",
    showHelpLink: true,
  });
  usePageTitle(
    formatMessage({ id: "JobsSignIn.Access", defaultMessage: "Acceder" })
  );

  useEffect(() => {
    setBackground("cuideo");

    // Reset form error when we enter this page
    userSignInResetStatus();
    // eslint-disable-next-line
  }, []);

  if (authUser !== null && authUser !== undefined) {
    // Redirect from this page to another one
    history.push(APP_START_PAGE);
  }

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (
      credentials.username.length === 0 ||
      credentials.password.length === 0
    ) {
      setFormError("Debes introducir unas credenciales válidas");
    } else {
      setFormError("");
      userSignIn({
        username: credentials.username,
        password: credentials.password,
        rememberMe: credentials.rememberMe,
      });
    }
  };

  const hasError = !!formError || loggingStatus === "loggingError";
  const errorMessage = formError || loggingMessage;

  return (
    <StyledPageContainer appMarginTop={appMarginTop}>
      <LoginContainer>
        <LoginBoxesWrapper>
          <LoginExistingUserBox
            initial="hidden"
            animate="shown"
            variants={loginBoxVariants}
          >
            <StyledBoxTitle>
              <FormattedMessage
                id="JobsSignIn.AlreadyHasAccount"
                defaultMessage="¿Ya tienes cuenta?"
              />
            </StyledBoxTitle>
            <StyledBoxSubtitle>
              <FormattedMessage
                id="JobsSignIn.Login"
                defaultMessage="Iniciar sesión"
              />
            </StyledBoxSubtitle>
            {hasError && <StyledErrorBox>{errorMessage}</StyledErrorBox>}
            <Grid container spacing={4}>
              <Grid item xs={12} md={9}>
                <form onSubmit={handleFormSubmit}>
                  <StyledBoxesContent>
                    <StyledTextField
                      label={
                        <FormattedMessage
                          id="JobsSignIn.DniEmail"
                          defaultMessage="DNI o Email"
                        />
                      }
                      fullWidth
                      onChange={(event) =>
                        setCredentials({
                          ...credentials,
                          username: event.target.value,
                        })
                      }
                      error={hasError}
                      value={credentials.username}
                      margin="normal"
                      variant="outlined"
                    />
                    <StyledTextField
                      type="password"
                      label={
                        <FormattedMessage
                          id="JobsSignIn.Password"
                          defaultMessage="Contraseña"
                        />
                      }
                      fullWidth
                      error={hasError}
                      onChange={(event) =>
                        setCredentials({
                          ...credentials,
                          password: event.target.value,
                        })
                      }
                      value={credentials.password}
                      margin="normal"
                      variant="outlined"
                    />

                    <StyledForgotLink to="/empleoResetPassword">
                      <FormattedMessage
                        id="JobsSignIn.PasswordLost"
                        defaultMessage="¿Has olvidado la contraseña?"
                      />
                    </StyledForgotLink>
                  </StyledBoxesContent>

                  <Grid container spacing={4}>
                    <Grid item xs={12} md={9}>
                      <Button
                        type="submit"
                        color="primary"
                        size="large"
                        disabled={loggingStatus === "loggingIn"}
                        variant="outlined"
                        fullWidth
                      >
                        {loggingStatus === "loggingIn" ? (
                          <FormattedMessage
                            id="JobsSignIn.LoggingIn"
                            defaultMessage="Iniciando sesión"
                          />
                        ) : (
                          <FormattedMessage
                            id="JobsSignIn.Login"
                            defaultMessage="Iniciar sesión"
                          />
                        )}
                      </Button>
                    </Grid>
                  </Grid>

                  <StyledRememberWrapper>
                    <CuideoCheckbox
                      label={
                        <StyledRememberLabel>
                          <FormattedMessage
                            id="JobsSignIn.RememberMe"
                            defaultMessage="Recuérdame"
                          />
                        </StyledRememberLabel>
                      }
                      checked={credentials.rememberMe}
                      onChange={() => {
                        setCredentials({
                          ...credentials,
                          rememberMe: !credentials.rememberMe,
                        });
                      }}
                    />
                  </StyledRememberWrapper>
                </form>
              </Grid>
            </Grid>
          </LoginExistingUserBox>

          <LoginNewUserBox
            initial="hidden"
            animate="shown"
            variants={signUpVariants}
          >
            <StyledBoxTitle>
              <FormattedMessage
                id="JobsSignIn.New"
                defaultMessage="¿Eres nuevo/a?"
              />
            </StyledBoxTitle>
            <StyledBoxTitle>
              <FormattedMessage
                id="JobsSignIn.SignUp"
                defaultMessage="Registrate"
              />
            </StyledBoxTitle>
            <StyledBoxesContent>
              <StyledList>
                <ListItem>
                  <StyledListItemAvatar>
                    <StyledCheckIconWrapper>
                      <CheckIcon
                        fontSize="default"
                        color="primary"
                        style={{ fontSize: "1.5rem", margin: "0.25rem" }}
                      />
                    </StyledCheckIconWrapper>
                  </StyledListItemAvatar>
                  <ListItemText>
                    <StyledNewUserDetailText>
                      <strong>
                        <FormattedMessage
                          id="JobsSignIn.FillForm"
                          defaultMessage="Rellena el formulario de inscripción"
                        />
                      </strong>
                      <br />
                      <FormattedMessage
                        id="JobsSignIn.CreateYourAccount"
                        defaultMessage="Crea tu cuenta, compártenos tus datos, quién eres y cuáles son tus especialidades."
                      />
                    </StyledNewUserDetailText>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <StyledListItemAvatar>
                    <StyledCheckIconWrapper>
                      <CheckIcon
                        fontSize="default"
                        color="primary"
                        style={{ fontSize: "1.5rem", margin: "0.25rem" }}
                      />
                    </StyledCheckIconWrapper>
                  </StyledListItemAvatar>
                  <ListItemText>
                    <StyledNewUserDetailText>
                      <strong>
                        <FormattedMessage
                          id="JobsSignIn.ApplyOffers"
                          defaultMessage="Aplica a las ofertas"
                        />
                      </strong>
                      <br />
                      <FormattedMessage
                        id="JobsSignIn.ChooseOffer"
                        defaultMessage="Elige la oferta de trabajo que se adapta a tus capacidades y necesidades actuales."
                      />
                    </StyledNewUserDetailText>
                  </ListItemText>
                </ListItem>
              </StyledList>
            </StyledBoxesContent>
            <Grid container spacing={4}>
              <Grid item xs={12} md={9}>
                <Link to="/registroCuidador" style={{ textDecoration: "none" }}>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    fullWidth
                  >
                    <FormattedMessage
                      id="JobsSignIn.SignUp"
                      defaultMessage="Registrate"
                    />
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </LoginNewUserBox>
        </LoginBoxesWrapper>
      </LoginContainer>
    </StyledPageContainer>
  );
};

const mapStateToProps = ({
  auth,
  layout,
}: {
  auth: {
    authUser: any;
    loggingStatus: string | null;
    loggingMessage: string | null;
  };
  layout: any;
}) => {
  const { authUser, loggingStatus, loggingMessage } = auth;
  const { appMarginTop } = layout;
  return { authUser, loggingStatus, loggingMessage, appMarginTop };
};

export default connect(mapStateToProps, {
  userSignIn,
  setBackground,
  userSignInResetStatus,
})(JobsSignIn);
