import { useQuery } from "react-query";
import {
  apiListContractsNumbers,
  IApiListContractsNumbersParams,
} from "../../../../../api/contract/apiListContractsNumbers";
import {
  ExtractFnReturnType,
  QueryConfig,
} from "../../../../../lib/react-query";
import { contractKeys } from "../util/contract.query-keys";

export const getContractsNumbers = async (
  params: IApiListContractsNumbersParams
) => {
  const response = await apiListContractsNumbers(params);

  return response["hydra:member"];
};

type QueryFnType = typeof getContractsNumbers;

type UseContractsOptions = {
  config?: QueryConfig<QueryFnType>;
  params: IApiListContractsNumbersParams;
};

export const useContractsNumbers = ({
  config,
  params,
}: UseContractsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: contractKeys.numbersList(params),
    queryFn: () => getContractsNumbers(params),
  });
};
