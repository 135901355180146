import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { TUnratedServicesResponse } from "../../hooks/useServicesUnratedPageQuery";
import TypeServiceBadge from "../TypeServiceBadge";
import CheckIcon from "@material-ui/icons/Check";

const StyledTable = styled(Table)`
  width: 100%;
  table-layout: fixed;

  .MuiTableCell-head {
    font-weight: 600;
    font-size: 0.875rem;
    padding: 0.5rem;
    border-bottom: 0.0625rem solid rgb(239, 239, 239);
    line-height: 1.25;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .MuiTableCell-head.CMuiTableCellClient {
    width: 10rem;
  }

  .MuiTableCell-body {
    padding: 0.5rem;
    font-size: 0.8375rem;
    color: #002740;
  }

  .MuiTableBody-root {
    .MuiTableCell-body {
      border-top: 0.0625rem solid rgb(239, 239, 239);
      border-bottom: 0.0625rem solid rgb(239, 239, 239);
      &.outdated {
        color: gray;
      }
    }
    .MuiTableRow-root {
      background-color: #fff;
      transition: all 0.1s ease-in-out;

      &:hover {
        background-color: rgb(245, 245, 245);
        cursor: pointer;
      }
      &.CMui-selected {
        background-color: rgb(235, 235, 235);
      }
    }
  }
`;

const StyledClientName = styled(Typography)`
  font-size: 0.9375rem;
`;

const StyledLoading = styled.div`
  height: 25.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledClientCell = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledBadge = styled.div`
  width: 50%;
`;

interface IUnassignedServicesTable {
  services: TUnratedServicesResponse[];
  onSelectService: (serviceId: string) => void;
  selectedServiceId: string | null;
  isLoading: boolean;
}

const UnassignedServicesTable = ({
  services,
  onSelectService,
  selectedServiceId,
  isLoading,
}: IUnassignedServicesTable) => {
  const handleRowClick = (serviceId: string) => {
    onSelectService(serviceId);
  };
  return (
    <StyledTable>
      <TableHead>
        <TableRow>
          <TableCell className="CMuiTableCellClient">
            <FormattedMessage
              id="UnassignedServicesTable.Cliente"
              defaultMessage="Cliente"
            />
          </TableCell>
          <TableCell>
            <FormattedMessage
              id="UnassignedServicesTable.Fecha de creación"
              defaultMessage="Fecha de creación"
            />
          </TableCell>
          <TableCell>
            <FormattedMessage
              id="UnassignedServicesTable.Fecha objetivo"
              defaultMessage="Fecha objetivo"
            />
          </TableCell>
          <TableCell>
            <FormattedMessage
              id="UnassignedServicesTable.Express"
              defaultMessage="Express"
            />
          </TableCell>
          <TableCell>
            <FormattedMessage
              id="UnassignedServicesTable.Provincia"
              defaultMessage="Provincia"
            />
          </TableCell>
          <TableCell>
            <FormattedMessage
              id="UnassignedServicesTable.Squad"
              defaultMessage="Squad"
            />
          </TableCell>
          <TableCell>
            <FormattedMessage
              id="UnassignedServicesTable.Referente B2B"
              defaultMessage="Referente B2B"
            />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {isLoading ? (
          <TableRow>
            <TableCell colSpan={7}>
              <StyledLoading>
                <CircularProgress size={24} />
              </StyledLoading>
            </TableCell>
          </TableRow>
        ) : (
          services.map((service) => {
            const createdAt = moment(service.createdAt);
            const objectiveDate = service.Fecha_inicio_servicio
              ? moment(service.Fecha_inicio_servicio)
              : null;
            return (
              <TableRow
                className={
                  selectedServiceId === service.id ? "CMui-selected" : undefined
                }
                key={service.id}
                onClick={() => {
                  handleRowClick(service.id);
                }}
              >
                <TableCell>
                  <StyledClientCell>
                    <StyledBadge>
                      <TypeServiceBadge
                        type={
                          "Seleccion" === service.Tipo_de_Servicio
                            ? "selection"
                            : "sad"
                        }
                        plan={service.Plan}
                      />
                    </StyledBadge>
                    <StyledClientName>
                      {service.customer?.First_Name}{" "}
                      {service.customer?.Last_Name}
                    </StyledClientName>
                  </StyledClientCell>
                </TableCell>
                <TableCell>{createdAt.format("DD/MM/YYYY HH:mm:ss")}</TableCell>
                <TableCell>{objectiveDate?.format("DD/MM/YYYY")}</TableCell>
                <TableCell>
                  {service.Servicio_Express && <CheckIcon fontSize="small" />}
                </TableCell>
                <TableCell>{service.Provincia}</TableCell>
                <TableCell>{service.Squad}</TableCell>
                <TableCell>{service.Referente_B2B?.Name}</TableCell>
              </TableRow>
            );
          })
        )}
      </TableBody>
    </StyledTable>
  );
};

export default UnassignedServicesTable;
