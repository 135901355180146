import IBAN from "iban";
import {
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import { apiEmpleo } from "../ApiEmpleo";

const validatePhone = (value: string | undefined) => {
  if (value) {
    const parsedPhoneNumber = parsePhoneNumber(value);
    if (parsedPhoneNumber) {
      if (isPossiblePhoneNumber(value)) {
        return true;
      }
    }
  }
  return false;
};

const validatePhoneOrEmpty = (value: string | undefined) => {
  if (!value) {
    return true;
  }
  return validatePhone(value);
};

const validateUniquePhone = async (value: any, candidatoId: any = null) => {
  if (validatePhone(value)) {
    // API does remove '+' and spaces so we can send directly
    let res = await apiEmpleo.post("validate/candidato/isPhoneUnique", {
      Phone: value,
      CandidatoId: candidatoId ? candidatoId : undefined,
    });

    if (res.hasOwnProperty("unique")) {
      if (res.hasOwnProperty("Name")) {
        return {
          unique: false,
          Name: res.Name,
        };
      }
      return {
        unique: res.unique,
      };
    }
    return {
      unique: false,
    };
  }
};

const validateDNI = (value: any) => {
  if (value) {
    const validChars = "TRWAGMYFPDXBNJZSQVHLCKET";
    const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    const nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    let str = value.toString().toUpperCase();

    if (!nifRexp.test(str) && !nieRexp.test(str)) return false;

    let nie = str
      .replace(/^[X]/, "0")
      .replace(/^[Y]/, "1")
      .replace(/^[Z]/, "2");

    let letter = str.substr(-1);
    let charIndex = parseInt(nie.substr(0, 8)) % 23;

    if (validChars.charAt(charIndex) === letter) return true;
  }
  return false;
};

const validateUniqueDNI = async (value: any, candidatoId: any = null) => {
  if (validateDNI(value)) {
    // API does remove '+' and spaces so we can send directly
    let res = await apiEmpleo.post("validate/candidato/isDNIUnique", {
      DNI_NIE: value,
      CandidatoId: candidatoId ? candidatoId : undefined,
    });

    if (res.hasOwnProperty("unique")) {
      if (res.hasOwnProperty("Name")) {
        return {
          unique: false,
          Name: res.Name,
        };
      }
      return {
        unique: res.unique,
      };
    }
    return {
      unique: false,
    };
  }
};

const validateEmail = (value: string) => {
  // Avoid accents (Zoho doesn't support them)
  const re =
    /^[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}$/;
  const isValid = re.test(value);
  if (isValid) {
    return true;
  }
  return false;
};

const validateUniqueEmail = async (value: string, candidatoId: any = null) => {
  if (validateEmail(value)) {
    // API does remove '+' and spaces so we can send directly
    let res = await apiEmpleo.post("validate/candidato/isEmailUnique", {
      Email: value,
      CandidatoId: candidatoId ? candidatoId : undefined,
    });

    if (res.hasOwnProperty("unique")) {
      if (res.hasOwnProperty("Name")) {
        return {
          unique: false,
          Name: res.Name,
        };
      }
      return {
        unique: res.unique,
      };
    }
    return {
      unique: false,
    };
  }
};

const validateIBANorEmpty = (value: string) => {
  return value ? IBAN.isValid(value) : true;
};

export const employeeSingUpValidator = {
  lastUsername: "",
  lastEmail: "",
  lastPhone: "",
  lastReturn: {} as any,

  async validate(username: string, email: string, phone: string) {
    try {
      if (
        this.lastUsername === username &&
        this.lastEmail === email &&
        this.lastPhone === phone
      ) {
        return {
          ...this.lastReturn,
        };
      }
      this.lastUsername = username;
      this.lastPhone = phone;
      this.lastEmail = email;

      let res = await apiEmpleo.post("employee_signup/validate", {
        username,
        email,
        Phone: phone,
      });

      if (res && res.status === "success") {
        this.lastReturn = {
          valid: true,
        };
        return {
          ...this.lastReturn,
        };
      }
    } catch (e: any) {
      this.lastReturn = {
        valid: false,
        violations: e.response.data.violations,
      };
      return {
        ...this.lastReturn,
      };
    }
  },
};

const validateNASSorEmpty = (nass: string): boolean => {
  if (!nass) {
    return true;
  }

  if (!nass.match(/^[0-9]{12}$/g)) {
    return false;
  }

  const na = parseInt(nass.substr(0, 2));
  const nb = parseInt(nass.substr(2, 8));
  const nc = parseInt(nass.substr(10, 2));
  let nd = 0;

  if (nb < 10000000) {
    nd = na * 10000000 + nb;
  } else {
    nd = na * 100000000 + nb;
  }

  return nc === nd % 97;
};

const validateNumericOrEmptyString = (value: string): boolean => {
  if ("" === value || typeof value === "undefined") {
    return true;
  }

  if (/^[0-9]+$/.test(value)) {
    return true;
  }

  return false;
};

const validateNumericFloatOrEmptyString = (value:string): boolean => {
  if ("" === value || typeof value === "undefined") {
    return true;
  }

  if (/^\d+(\.\d+)?$/.test(value)) {
    return true;
  }

  return false;
};

export {
  validatePhone,
  validatePhoneOrEmpty,
  validateDNI,
  validateUniquePhone,
  validateUniqueDNI,
  validateEmail,
  validateUniqueEmail,
  validateIBANorEmpty,
  validateNASSorEmpty,
  validateNumericOrEmptyString,
  validateNumericFloatOrEmptyString
};
