import { all, fork, put, takeEvery } from "redux-saga/effects";
import { getLoggedUserData } from "../actions";
import { APP_INITIALIZE } from "../constants/ActionTypes";

function* doInitializeApp() {
  try {
    yield put(getLoggedUserData());
  } catch (error) {
    console.log(error);
  }
}

export function* initializeAppSaga() {
  yield takeEvery(APP_INITIALIZE, doInitializeApp);
}

export default function* rootSaga() {
  yield all([fork(initializeAppSaga)]);
}
