import { Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { cuideoTheme } from "../../../../../../containers/themes/defaultTheme";
import { ColoredAvatar, UserActiveLast } from "../../../user";
import { TUserAssignations } from "../../types/UserGroupAssignations.types";

export const WITHOUT_VALUATION_COLOR = "#004b7b";
export const PENDING_SIGNUP_COLOR = "#004b7b";
export const ADVANCED_VALUATION_COLOR = "#4caf50";

const StyledBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 3.5rem;
  user-select: none;

  &.CMuiCoordinatorAssignationsItem-inactive {
    opacity: 0.5;
  }

  &:hover {
    cursor: pointer;
    background-color: rgb(245, 245, 245);
  }
`;

const StyledAvatarWrapper = styled.div`
  padding: 0.25rem 0.75rem 0.25rem 0.25rem;
  .MuiAvatar-root {
    width: 2rem;
    height: 2rem;
  }
`;

const StyledLeft = styled.div`
  padding: 0.25rem 0 0.25rem 0.5rem;
`;

const StyledRight = styled.div`
  padding: 0.25rem 0.5rem 0.25rem 0;
  flex: 1 1 auto;
`;

const StyledRightTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledRightTopLeft = styled.div`
  flex: 1 1 auto;
`;

const StyledRightTopRight = styled.div`
  min-width: 1.125rem;
  width: 1.125rem;
  text-align: right;
`;

const StyledRightBottom = styled.div`
  margin-top: 0.25rem;
`;

const StyledTotalNumber = styled(Typography)`
  font-weight: 700;
  font-size: 1.125rem;
`;

const StyledUserName = styled(Typography)`
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  line-height: 1.25;
  font-size: 0.875rem;
  font-weight: 600;
  height: 1.125rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
  color: ${cuideoTheme.palette.primary.main};
`;

const StyledBarsWrapper = styled.div`
  height: 1.125rem;
  display: flex;
  flex-direction: row;
`;

const StyledContractBarsWrapper = styled.div`
  width: 100%;
`;

const StyledContractBarWrapper = styled.div`
  height: 0.625rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

const StyledContractsNumber = styled(Typography)`
  font-weight: 700;
  font-size: 0.75rem;
  width: 1rem;
  text-align: right;
`;

const StyledBarContainer = styled.div`
  margin: 0 0.25rem;
  height: 1.125rem;
  width: 100%;
  position: relative;
`;

const StyledThinnerBar = styled.div`
  border-radius: 0 0.1875rem 0.1875rem 0;
  height: 0.375rem;
  top: 0.375rem;
  position: absolute;
`;

interface IRecruiterAssignationsItemProps {
  recruiter: TUserAssignations;
  maxContracts: number;
  onSelect: (ownerId: string) => void;
}

const RecruiterAssignationsItem: React.FC<IRecruiterAssignationsItemProps> = (
  props
) => {
  const { recruiter, maxContracts, onSelect } = props;

  const handleClick = () => {
    onSelect(recruiter.id);
  };

  let className = "CMuiCoordinatorAssignationsItem";
  if (!recruiter.active) {
    className += " CMuiCoordinatorAssignationsItem-inactive";
  }

  // const withoutValuationBarWidth =
  //   (recruiter.servicesStats.withoutValuation.total / (maxServices + 1)) *
  //   100;

  const awaitingContractsBarWidth =
    (recruiter.contractStats.awaiting.total / (maxContracts + 1)) * 100;

  const advancedContractsBarWidth =
    ((recruiter.contractStats.cvClient.total +
      recruiter.contractStats.clientFaceToFace.total) /
      (maxContracts + 1)) *
    100;

  const totalNumber =
    // recruiter.servicesStats.total +
    recruiter.contractStats.awaiting.total +
    recruiter.contractStats.clientFaceToFace.total +
    recruiter.contractStats.cvClient.total;

  return (
    <StyledBox className={className} onClick={handleClick}>
      <StyledLeft>
        <StyledAvatarWrapper>
          <ColoredAvatar fullName={recruiter.fullName} />
          {/* <CuideoUserAvatar
            user={{
              userFullName: "Paco",
              userAvatar: null,
            }}
          /> */}
        </StyledAvatarWrapper>
      </StyledLeft>
      <StyledRight>
        <StyledRightTop>
          <StyledRightTopLeft>
            <StyledUserName>{recruiter.fullName}</StyledUserName>
            <StyledBarsWrapper>
              {/* <StyledServiceBarWrapper>
                <StyledServicesNumber>
                  {recruiter.servicesStats.total}
                </StyledServicesNumber>
                <StyledBarContainer>
                  <StyledBar
                    style={{
                      backgroundColor: WITHOUT_VALUATION_COLOR,
                      width: `calc(${withoutValuationBarWidth}% + 0.1875rem)`,
                    }}
                  />
                </StyledBarContainer>
              </StyledServiceBarWrapper> */}
              <StyledContractBarsWrapper>
                <StyledContractBarWrapper>
                  <StyledContractsNumber>
                    {recruiter.contractStats.awaiting.total}
                  </StyledContractsNumber>
                  <StyledBarContainer>
                    <StyledThinnerBar
                      style={{
                        backgroundColor: PENDING_SIGNUP_COLOR,
                        width: `calc(${awaitingContractsBarWidth}% + 0.1875rem)`,
                        zIndex: 2,
                      }}
                    />
                  </StyledBarContainer>
                </StyledContractBarWrapper>
                <StyledContractBarWrapper>
                  <StyledContractsNumber>
                    {recruiter.contractStats.clientFaceToFace.total +
                      recruiter.contractStats.cvClient.total}
                  </StyledContractsNumber>
                  <StyledBarContainer>
                    <StyledThinnerBar
                      style={{
                        backgroundColor: ADVANCED_VALUATION_COLOR,
                        width: `calc(${advancedContractsBarWidth}% + 0.1875rem)`,
                        zIndex: 1,
                      }}
                    />
                  </StyledBarContainer>
                </StyledContractBarWrapper>
              </StyledContractBarsWrapper>
            </StyledBarsWrapper>
          </StyledRightTopLeft>
          <StyledRightTopRight>
            <StyledTotalNumber>{totalNumber}</StyledTotalNumber>
          </StyledRightTopRight>
        </StyledRightTop>
        <StyledRightBottom>
          <UserActiveLast
            lastActivityTime={recruiter.lastActivityTime}
            active={recruiter.active}
            enabled={recruiter.enabled}
            isOnline={recruiter.isOnline}
          />
        </StyledRightBottom>
      </StyledRight>
    </StyledBox>
  );
};

export default RecruiterAssignationsItem;
