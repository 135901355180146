import { apiEmpleo } from "../../util/ApiEmpleo";

export type TMarkAsReadMessagesRequest = {
  id: string;
}[];

const apiMarkAsReadMessages = async (payload: TMarkAsReadMessagesRequest) => {
  let res = await apiEmpleo.patch(`messages/mark-as-read`, payload);

  return res;
};

export { apiMarkAsReadMessages };
