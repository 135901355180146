import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import GoogleMapReact from "google-map-react";
import React, { Fragment, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import ConfirmDialog from "../../../components/ConfirmDialog";
import cuideoTheme from "../../../containers/themes/defaultTheme";
import { ValuationMarker } from "../features/valuation";
import { TOnSelectValuationFn } from "../features/valuation/types/valuation.types";
import ContratoMarker from "./ContratoMarker";
import MapGeoSearchField from "./MapGeoSearchField";
import MapMarker from "./MapMarker";

const StyledMaxWarning = styled.div`
  z-index: 1;
  padding: 0.5rem;
  background-color: ${cuideoTheme.palette.primary.main};
  top: 0.75rem;
  left: 0.75rem;
  max-width: 20rem;
  position: absolute;
  border-radius: ${cuideoTheme.shape.borderRadius}px;
  box-shadow: 0 2px 4px 0 #a2c4d8;

  .MuiSvgIcon-root {
    color: ${cuideoTheme.palette.common.white};
  }
`;

const StyledWarningTypography = styled(Typography)`
  color: ${cuideoTheme.palette.common.white};
  font-size: 0.875rem;
  line-height: 1.15;
`;

const StyledLoading = styled.div`
  z-index: 1;
  padding: 0.5rem;
  background-color: ${cuideoTheme.palette.primary.main};
  top: 0.75rem;
  left: 0.75rem;
  max-width: 20rem;
  position: absolute;
  border-radius: ${cuideoTheme.shape.borderRadius}px;
  box-shadow: 0 2px 4px 0 #a2c4d8;

  .MuiCircularProgress-svg {
    color: ${cuideoTheme.palette.common.white};
  }
`;

const StyledSearchWrapper = styled(Box)`
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  max-width: 18rem;
  z-index: 1;
`;

export interface ContratoValoracionesMapProps {
  contrato: any;
  valoraciones: any[];
  selectedValoracion?: any;
  hoveredValoracion?: any;
  onValoracionHover?: (valoracion: any) => void;
  onValoracionSelect: TOnSelectValuationFn;
  onMapChange?: (mapChanged: any) => void;
  showMaxWarning?: boolean;
  showLoading?: boolean;
  map?: any;
  allowMapSearch?: boolean;
  onContractLocationChange?: (
    lat: number,
    lng: number,
    label: string,
    errorMargin: number
  ) => void;
}

const ContratoValoracionesMap = (props: ContratoValoracionesMapProps) => {
  const {
    valoraciones,
    contrato,
    selectedValoracion,
    hoveredValoracion,
    onValoracionHover,
    onValoracionSelect,
    onMapChange,
    showMaxWarning,
    showLoading,
    map,
    allowMapSearch,
    onContractLocationChange,
  } = props;

  const divRef = useRef(null);
  // const [mouseOver, setMouseOver] = useState(false);
  const [marker, setMarker] = useState<{
    lat?: number;
    lng?: number;
    label?: string;
    errorMargin?: number;
  }>({});
  const [confirmingLocationChange, setConfirmingLocationChange] =
    useState(false);

  const { formatMessage } = useIntl();

  const handlePlaceSelected = (
    lat: number,
    lng: number,
    zoom: number,
    label: string,
    errorMargin: number
  ) => {
    onMapChange &&
      onMapChange({
        ...map,
        center: {
          lat: lat,
          lng: lng,
        },
        zoom: zoom,
        autoFocus: true,
      });
    setMarker({
      lat: lat,
      lng: lng,
      label: label,
      errorMargin: errorMargin,
    });
  };

  const handleMarkerAction = () => {
    setConfirmingLocationChange(true);
  };

  const handleConfirm = () => {
    setConfirmingLocationChange(false);
    if (
      !onContractLocationChange ||
      !marker.lat ||
      !marker.lng ||
      !marker.label ||
      !marker.errorMargin
    ) {
      return;
    }
    onContractLocationChange(
      marker.lat,
      marker.lng,
      marker.label,
      marker.errorMargin
    );
    setMarker({
      lat: undefined,
      lng: undefined,
      label: undefined,
    });
  };

  const handleConfirmCancel = () => {
    setConfirmingLocationChange(false);
  };

  return (
    <Fragment>
      <div
        style={{
          height: "100%",
          width: "100%",
        }}
        ref={divRef}
        // onMouseEnter={() => {
        //   setMouseOver(true);
        // }}
        // onMouseLeave={() => {
        //   setMouseOver(false);
        // }}
      >
        {showLoading ? (
          <StyledLoading>
            <Grid container spacing={4} alignItems="center" wrap="nowrap">
              <Grid item>
                <CircularProgress size="1rem" />
              </Grid>
              <Grid item>
                <StyledWarningTypography>
                  <FormattedMessage
                    id="ContratoValoracionesMap.Loading"
                    defaultMessage="Cargando..."
                  />
                </StyledWarningTypography>
              </Grid>
            </Grid>
          </StyledLoading>
        ) : (
          showMaxWarning && (
            <StyledMaxWarning>
              <Grid container spacing={4} alignItems="flex-start" wrap="nowrap">
                <Grid item>
                  <WarningIcon fontSize="small" />
                </Grid>
                <Grid item>
                  <StyledWarningTypography>
                    <FormattedMessage
                      id="ContratoValoracionesMap.maxwarning"
                      defaultMessage="Dada la gran cantidad de Candidatos en este área no se muestran todos. Puedes hacer zoom o usar los filtros para ver todos los candidatos."
                    />
                  </StyledWarningTypography>
                </Grid>
              </Grid>
            </StyledMaxWarning>
          )
        )}
        {allowMapSearch && (
          <StyledSearchWrapper
            style={{
              visibility: !!selectedValoracion ? "hidden" : "visible",
            }}
          >
            <MapGeoSearchField
              visible={!selectedValoracion}
              mapRef={divRef}
              onPlaceSelected={handlePlaceSelected}
            />
          </StyledSearchWrapper>
        )}
        <GoogleMapReact
          // Google Maps key already included
          // center={center}
          center={map.center}
          zoom={map.zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            map.data.setStyle({ clickable: false });
          }}
          onChange={(changeValue: any) => {
            onMapChange && onMapChange(changeValue);
          }}
          options={(maps) => {
            return {
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
            };
          }}
        >
          {valoraciones.map((valoracion: any, index: number) => {
            if (valoracion.candidato && valoracion.candidato.Latitud) {
              return (
                <ValuationMarker
                  key={`m${valoracion.id}`}
                  valuation={valoracion}
                  lat={valoracion.candidato.Latitud}
                  lng={valoracion.candidato.Longitud}
                  onSelect={onValoracionSelect}
                  isHovered={
                    !!hoveredValoracion &&
                    hoveredValoracion.id === valoracion.id
                  }
                  onMouseEnter={() => {
                    onValoracionHover && onValoracionHover(valoracion);
                  }}
                  onMouseLeave={() => {
                    onValoracionHover && onValoracionHover(null);
                  }}
                  appearDelay={index * 5}
                />
              );
            }
            return null;
          })}
          <ContratoMarker
            key={`mCon${contrato.id}`}
            lat={contrato.Latitud}
            lng={contrato.Longitud}
            contrato={contrato}
          />
          {marker.lat && marker.lng && (
            <MapMarker
              key={`map-Marker`}
              lat={marker.lat}
              lng={marker.lng}
              label={marker.label ? marker.label : ""}
              onMarkerAction={handleMarkerAction}
            />
          )}
        </GoogleMapReact>
      </div>
      <ConfirmDialog
        open={confirmingLocationChange}
        title={formatMessage({
          id: "ContractMap.LocationChangeTitle",
          defaultMessage: "Cambiar ubicación del Contrato",
        })}
        label={formatMessage({
          id: "ContractMap.LocationChangeLabel",
          defaultMessage:
            "¿Estás seguro de que deseas cambiar la localización del contrato a la nueva ubicación?",
        })}
        onCancel={handleConfirmCancel}
        onConfirm={handleConfirm}
      />
    </Fragment>
  );
};

export default ContratoValoracionesMap;
