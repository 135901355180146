import { useMutation } from "react-query";
import { apiMarkConversationAsRead } from "../../../../../apiCoreV3/conversation/apiMarkConversationAsRead";
import { MutationConfig, queryClient } from "../../../../../lib/react-query";
import { conversationKeys } from "../util/conversation.query-keys";

type TMarkConversationAsRead = {
  conversationId: string;
};

export const markConversationAsRead = ({
  conversationId,
}: TMarkConversationAsRead) => {
  return apiMarkConversationAsRead(conversationId);
};

type TUseMarkConversationAsReadOptions = {
  config?: MutationConfig<typeof markConversationAsRead>;
};

export const useMarkConversationAsRead = ({
  config,
}: TUseMarkConversationAsReadOptions = {}) => {
  return useMutation({
    onMutate: async (payload) => {
      await queryClient.cancelQueries(conversationKeys.all);
    },
    onSettled: () => {
      queryClient.invalidateQueries(conversationKeys.all);
    },
    ...config,
    mutationFn: markConversationAsRead,
  });
};
