import { Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import {
  CommunicationIntentEmployeeValidationError,
  MAX_COMMUNICATION_INTENTS_PER_CONTRACT,
  TCommunicationIntentEmployee,
} from "../CreateCommunicationIntentsButton.types";

const StyledWrapper = styled.div``;

const StyledAlert = styled(Alert)`
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25;
  background-color: transparent;

  .MuiSvgIconroot {
    width: 1.25rem;
    height: 1.25rem;
  }

  .MuiAlertTitleroot {
    font-size: 0.875rem;
    line-height: 1.25;
  }
`;

const StyledIssue = styled(Typography)`
  font-size: 0.875rem;
  line-height: 1.25;
  margin: 0.125rem 0;
`;

interface IEmployeeValidationIssuesProps {
  employeeId: string;
  validationErrors: TCommunicationIntentEmployee["validationErrors"];
}

const EmployeeValidationIssues: React.FC<IEmployeeValidationIssuesProps> = ({
  employeeId,
  validationErrors,
}) => {
  return (
    <StyledWrapper>
      {validationErrors.length === 0 ? (
        <StyledAlert severity="success">
          <FormattedMessage
            id="EmployeeValidationIssues.Disponible para enviar"
            defaultMessage="Disponible para enviar"
          />
        </StyledAlert>
      ) : (
        <StyledAlert severity="error">
          <FormattedMessage
            id="EmployeeValidationIssues.No se puede enviar por los siguientes problemas:"
            defaultMessage="No se puede enviar por los siguientes problemas:"
          />
          {validationErrors.map((error) => (
            <StyledIssue key={`ve_se_${employeeId}_${error}`}>
              {error === CommunicationIntentEmployeeValidationError.NO_EMAIL ? (
                <FormattedMessage
                  id="EmployeeValidationIssues.Candidato sin Email"
                  defaultMessage="Candidato sin Email"
                />
              ) : error ===
                CommunicationIntentEmployeeValidationError.NO_CRM_ID_NAME ? (
                <FormattedMessage
                  id="EmployeeValidationIssues.Candidato sin identificador"
                  defaultMessage="Candidato sin identificador"
                />
              ) : error ===
                CommunicationIntentEmployeeValidationError.NO_FIRST_NAME ? (
                <FormattedMessage
                  id="EmployeeValidationIssues.Candidato sin Nombre"
                  defaultMessage="Candidato sin Nombre"
                />
              ) : error ===
                CommunicationIntentEmployeeValidationError.NO_FULL_NAME ? (
                <FormattedMessage
                  id="EmployeeValidationIssues.Candidato sin Nombre completo"
                  defaultMessage="Candidato sin Nombre completo"
                />
              ) : error ===
                CommunicationIntentEmployeeValidationError.NO_PHONE ? (
                <FormattedMessage
                  id="EmployeeValidationIssues.Candidato sin teléfono"
                  defaultMessage="Candidato sin teléfono"
                />
              ) : error ===
                CommunicationIntentEmployeeValidationError.NO_SURNAME ? (
                <FormattedMessage
                  id="EmployeeValidationIssues.Candidato sin apellido"
                  defaultMessage="Candidato sin apellido"
                />
              ) : error ===
                CommunicationIntentEmployeeValidationError.NO_WA_REGISTERED ? (
                <FormattedMessage
                  id="EmployeeValidationIssues.Candidato sin Whatsapp registrado"
                  defaultMessage="Candidato sin Whatsapp registrado"
                />
              ) : error ===
                CommunicationIntentEmployeeValidationError.NO_WHATSAPPS ? (
                <FormattedMessage
                  id="EmployeeValidationIssues.Candidato no quiere recibir Whatsapps"
                  defaultMessage="Candidato no quiere recibir Whatsapps"
                />
              ) : error ===
                CommunicationIntentEmployeeValidationError.LIMIT24H ? (
                <FormattedMessage
                  id="EmployeeValidationIssues.Candidato ya ha recibido WhatsApp en las últimas 24 horas"
                  defaultMessage="Candidato ya ha recibido WhatsApp en las últimas 24 horas"
                />
              ) : error ===
                CommunicationIntentEmployeeValidationError.MAX_PER_CONTRACT ? (
                <FormattedMessage
                  id="EmployeeValidationIssues.Se ha alcanzado el máximo de {maxIntents} intentos por Contrato"
                  defaultMessage="Se ha alcanzado el máximo de {maxIntents} intentos por Contrato"
                  values={{
                    maxIntents: MAX_COMMUNICATION_INTENTS_PER_CONTRACT,
                  }}
                />
              ) : (
                <FormattedMessage
                  id="EmployeeValidationIssues.Error desconocido"
                  defaultMessage="Error desconocido"
                />
              )}
            </StyledIssue>
          ))}
        </StyledAlert>
      )}
    </StyledWrapper>
  );
};

export default EmployeeValidationIssues;
