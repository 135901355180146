import React from "react";
import styled from "styled-components";
import cuideoTheme from "../../../containers/themes/defaultTheme";
import { Box, Typography } from "@material-ui/core";
import {
  TIPO_TRATO_EXTERNA_MAS_20_HORAS,
  TIPO_TRATO_INTERNA_ENTRE_SEMANA,
  TIPO_TRATO_INTERNA_FIN_DE_SEMANA,
  TIPO_TRATO_INTERNA_FIN_DE_SEMANA_SIN_SUST,
  TIPO_TRATO_INTERNA_ENTRE_SEMANA_SIN_SUST,
  TIPO_TRATO_EXTERNA_MENOS_20_HORAS_SIN_SUST,
  TIPO_TRATO_EXTERNA_MENOS_20_HORAS,
  TIPO_TRATO_EXTERNA_MAS_20_HORAS_SIN_SUST,
  TIPO_TRATO_PROFESIONAL_POR_HORAS,
} from "../../../util/defaults/contrato";
import { FormattedMessage } from "react-intl";

const StyledWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledTipo = styled(Typography)<{ tipo: string }>`
  color: ${cuideoTheme.palette.primary.main};
  font-size: 0.75rem;
  letter-spacing: 0.0625rem;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;

  ${({ tipo }) => {
    if (
      tipo === TIPO_TRATO_INTERNA_ENTRE_SEMANA ||
      tipo === TIPO_TRATO_INTERNA_ENTRE_SEMANA_SIN_SUST
    ) {
      return `
        &:after {
          display: block;
          height: 0.25rem;
          width: 2rem;
          background-color: #fbb7c3;
          content: ' ';
          margin: 0.125rem 0 0.25rem;
        }
      `;
    }
    if (
      tipo === TIPO_TRATO_INTERNA_FIN_DE_SEMANA ||
      tipo === TIPO_TRATO_INTERNA_FIN_DE_SEMANA_SIN_SUST
    ) {
      return `
        &:after {
          display: block;
          height: 0.25rem;
          width: 2rem;
          background-color: rgba(126, 211, 33, 0.3);
          content: ' ';
          margin: 0.125rem 0 0.25rem;
        }
      `;
    }
    if (
      tipo === TIPO_TRATO_EXTERNA_MAS_20_HORAS ||
      tipo === TIPO_TRATO_EXTERNA_MAS_20_HORAS_SIN_SUST
    ) {
      return `
        &:after {
          display: block;
          height: 0.25rem;
          width: 2rem;
          background-color: rgba(144, 19, 254, 0.3);
          content: ' ';
          margin: 0.125rem 0 0.25rem;
        }
      `;
    }
    if (
      tipo === TIPO_TRATO_EXTERNA_MENOS_20_HORAS ||
      tipo === TIPO_TRATO_EXTERNA_MENOS_20_HORAS_SIN_SUST ||
      tipo === TIPO_TRATO_PROFESIONAL_POR_HORAS
    ) {
      return `
        &:after {
          display: block;
          height: 0.25rem;
          width: 2rem;
          background-color: rgba(245, 166, 35, 0.3);
          content: ' ';
          margin: 0.125rem 0 0.25rem;
        }
      `;
    }
    return ``;
  }}
`;

const StyledPunctual = styled(Typography)`
  background-color: rgba(235, 194, 0, 0.3);
  border: 1px solid rgb(235, 194, 0);
  color: ${cuideoTheme.palette.primary.main};
  padding: 0.125rem 0.25rem;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1;
  border-radius: 0.25rem;
  margin-left: 0.375rem;
`;

export interface StyledContratoTipoDeTratoProps {
  tipo: string;
  punctual?: boolean;
}

const StyledContratoTipoDeTrato = (props: StyledContratoTipoDeTratoProps) => {
  const { tipo, punctual } = props;

  return (
    <StyledWrapper>
      <StyledTipo tipo={tipo}>{tipo}</StyledTipo>
      {punctual && (
        <StyledPunctual>
          <FormattedMessage
            id="ContractType.PunctualContract"
            defaultMessage="Puntual"
          />
        </StyledPunctual>
      )}
    </StyledWrapper>
  );
};

export default StyledContratoTipoDeTrato;
