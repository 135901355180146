import React from "react";
import { FormattedMessage } from "react-intl";
import { StyledPathology, StyledSubTitle } from "./ContractDetailedTooltip";

interface IContractDetailedTooltipPathologies {
  contract: {
    Patolog_as_tratadas_previamente: string[];
    Servicio?: {
      Usuario1?: {
        Nombre_U1?: string;
        Patologias_U1?: string[];
        Nombre_U2?: string;
        Patologias_U2?: string[];
        Usuarios_a_cuidar?: number;
      };
    };
  };
}

const ContractDetailedTooltipPathologies = (
  props: IContractDetailedTooltipPathologies
) => {
  const { contract } = props;

  if (contract.Servicio?.Usuario1) {
    return (
      <>
        <StyledSubTitle>
          <FormattedMessage
            id="ContractDetailedTooltip.Patologías de {name}"
            defaultMessage="Patologías de {name}"
            values={{
              name: contract.Servicio.Usuario1.Nombre_U1,
            }}
          />
        </StyledSubTitle>
        {contract.Servicio.Usuario1.Patologias_U1?.map((item: string) => (
          <StyledPathology
            label={item}
            key={item}
            variant="outlined"
            color="secondary"
          />
        ))}
        {contract.Servicio.Usuario1.Usuarios_a_cuidar === 2 && (
          <>
            <StyledSubTitle>
              <FormattedMessage
                id="ContractDetailedTooltip.Patologías de {name}"
                defaultMessage="Patologías de {name}"
                values={{
                  name: contract.Servicio.Usuario1.Nombre_U2,
                }}
              />
            </StyledSubTitle>
            {contract.Servicio.Usuario1.Patologias_U2?.map((item: string) => (
              <StyledPathology
                label={item}
                key={item}
                variant="outlined"
                color="secondary"
              />
            ))}
          </>
        )}
      </>
    );
  }

  return (
    <>
      <StyledSubTitle>
        <FormattedMessage
          id="ContractDetailedTooltip.Patologías"
          defaultMessage="Patologías"
        />
      </StyledSubTitle>
      {contract.Patolog_as_tratadas_previamente.map((item: any) => (
        <StyledPathology
          label={item}
          key={item}
          variant="outlined"
          color="secondary"
        />
      ))}
    </>
  );
};

export default ContractDetailedTooltipPathologies;
