import { useQuery } from "react-query";
import { apiGetService } from "../../../../../../../api/service/apiGetService";

export default function useServicesUnratedPageQuery(serviceId: string | null) {
  return useQuery(
    ["unrated_service_detail", serviceId],
    async () => {
      if (!serviceId) {
        return null;
      }
      const res = await apiGetService(serviceId);
      return res;
    },
    {
      enabled: !!serviceId,
      cacheTime: 1000 * 60 * 5,
    }
  );
}
