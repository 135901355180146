import {
  FormControl,
  FormHelperText,
  FormLabel,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import cuideoTheme from "../../../themes/defaultTheme";
import ErrorsOnFormDialog from "../ErrorsOnFormDialog/ErrorsOnFormDialog";
import useSignUpStep2FormContext from "./hooks/useSignUpStep2FormContext";
import SignUpStep2ActionsInnerForm from "./SignUpStep2ActionsInnerForm";
import SignUpStep2AvailabilityInnerForm from "./SignUpStep2AvailabilityInnerForm";
import SignUpStep2InterestDataInnerForm from "./SignUpStep2InterestDataInnerForm";
import SignUpStep2PersonalDataInnerForm from "./SignUpStep2PersonalDataInnerForm";

export const StyledFormLabel = styled(Typography)`
  color: ${cuideoTheme.palette.primary.light};
  font-size: 1rem;
  white-space: wrap;
  padding: 0.2rem 0 0.2rem 0;
`;

export const StyledIcon = styled.img`
  max-width: 2.75rem;
  max-height: 2.75rem;
`;
export const StyledUncheckedIcon = styled.img`
  max-width: 2.75rem;
  max-height: 2.75rem;
  opacity: 0.4;
`;

export const StyledField = styled.div`
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 1rem;

  &:last-child {
    border-bottom: none;
  }
`;

export const StyledFormControl = styled(FormControl)`
  margin-bottom: 1rem;
  width: 100%;

  &:last-child {
    border-bottom: none;
  }
`;

export const StyledFormControlLabel = styled(FormLabel)`
  font-size: 1rem;
  white-space: wrap;
  margin-bottom: 0.375rem;
  color: ${cuideoTheme.palette.primary.light};
`;

export const StyledFormHelperText = styled(FormHelperText)`
  font-size: 0.8rem;
`;

interface ISignUpStep2InnerFormProps {
  onBack: (e: React.MouseEvent) => void;
}

const SignUpStep2InnerForm = (props: ISignUpStep2InnerFormProps) => {
  const { onBack } = props;
  const {
    diasOptions,
    mesesOptions,
    anyosOptions,
    paisesOptions,
    educacionOptions,
    idiomasOptions,
    titulacionesOptions,
    tareasOptions,
    experienceYearsOptions,
  } = useSelector((state: any) => state.commonData);
  const [errorsOnDialog, setErrorsOnDialog] = useState(false);
  const { formikContext, cityOptions, formActions } =
    useSignUpStep2FormContext();

  const handleErrorDialogClose = () => {
    setErrorsOnDialog(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.stopPropagation();
    e.preventDefault();

    const errors = await formikContext.validateForm();
    if (Object.keys(errors).length === 0) {
      formikContext.submitForm();
    } else {
      Object.keys(errors).forEach((key) => {
        formikContext.setFieldTouched(key, true, false);
      });
      setErrorsOnDialog(true);
    }
  };

  return (
    <>
      <SignUpStep2PersonalDataInnerForm
        dayOptions={diasOptions}
        monthOptions={mesesOptions}
        yearOptions={anyosOptions}
        countryOptions={paisesOptions}
        addressStreet={formikContext.values.registroCalle}
        changeAddressStreet={formActions.changeAddressStreet}
        postalCodeChange={formActions.postalCodeChange}
        selectAddress={formActions.selectAddress}
        cityOptions={cityOptions}
      />
      <SignUpStep2InterestDataInnerForm
        degreesOptions={titulacionesOptions}
        educationOptions={educacionOptions}
        experienceYearsOptions={experienceYearsOptions}
        languagesOptions={idiomasOptions}
      />
      <SignUpStep2AvailabilityInnerForm
        tasksOptions={tareasOptions}
        noBot={formikContext.values.noBot}
        toggleNoBot={formActions.toggleNoBot}
      />
      <SignUpStep2ActionsInnerForm
        onSubmitClick={handleSubmit}
        onBack={onBack}
      />
      <ErrorsOnFormDialog
        open={errorsOnDialog}
        onClose={handleErrorDialogClose}
      />
    </>
  );
};

export default SignUpStep2InnerForm;
