import { useQuery } from "react-query";
import { apiGetConversation } from "../../../../../apiCoreV3/conversation/apiGetConversation";
import {
  ExtractFnReturnType,
  QueryConfig,
} from "../../../../../lib/react-query";
import { conversationKeys } from "../util/conversation.query-keys";

type TGetConversationPayload = { conversationId: string };

export const getConversation = async ({
  conversationId,
}: TGetConversationPayload) => {
  return apiGetConversation(conversationId);
};

type QueryFnType = typeof getConversation;

type TUseConversationOptions = {
  conversationId: string;
  config?: QueryConfig<QueryFnType>;
};

export const useConversation = ({
  conversationId,
  config = {},
}: TUseConversationOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: conversationKeys.item(conversationId),
    queryFn: () => getConversation({ conversationId }),
  });
};
