import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  updateURLParams,
  urlParamsToObjectV2,
} from "../../../../../../../util/urlManager";
import _ from "lodash";

const initialState = {
  page: 1,
  selectedContractId: null as null | string,
  selectedOwnerId: null as null | string,
  "order[createdAt]": null as "asc" | "desc" | null,
  "order[priority]": null as "asc" | "desc" | null,
  "order[recruitPriority]": null as "asc" | "desc" | null,
  "order[squad]": null as "asc" | "desc" | null,
  "order[fechaObjetivo]": "asc" as "asc" | "desc" | null,
  "order[cvDate]": null as "asc" | "desc" | null,
  ready: false,
};

export type TRecruiterManagerRouteFilters = typeof initialState;

export type TChangeOrderFn = ({
  column,
  order,
}: {
  column: "order[createdAt]" | "order[fechaObjetivo]" | "order[squad]";
  order: "asc" | "desc" | null;
}) => void;

export default function useRecruiterManagerRouteFilters() {
  const [filters, setFiltersState] = useState({ ...initialState });
  // const authUser = useSelector((state: any) => state.auth.authUser);
  const history = useHistory();

  useEffect(() => {
    if (history.action !== "REPLACE") {
      const params = history.location.search
        ? urlParamsToObjectV2(history.location.search)
        : {};

      if (params.page && typeof params.page !== "number") {
        params.page = parseInt(params.page);
      }

      setFiltersState((prevFilters) => {
        if (!_.some([prevFilters], params)) {
          return {
            ...prevFilters,
            ...params,
            ready: true,
          };
        } else {
          return {
            ...prevFilters,
            ready: true,
          };
        }
      });
    }
    // eslint-disable-next-line
  }, [history, setFiltersState]);

  const setFilters = useCallback(
    (newFilters: TRecruiterManagerRouteFilters) => {
      const params = {
        ...newFilters,
      } as any;
      setFiltersState(newFilters);

      // Parse a bit wich parameters to set on url
      delete params.ready;
      Object.keys(initialState).forEach((key) => {
        if (Array.isArray((initialState as any)[key])) {
          if (
            _.isEqual(
              _.sortBy((initialState as any)[key]),
              _.sortBy(params[key])
            )
          ) {
            delete params[key];
          }
        } else {
          if (params[key] === (initialState as any)[key]) {
            delete params[key];
          }
        }
      });

      updateURLParams(params);
    },
    [setFiltersState]
  );

  return { filters, setFilters };
}
