import React from "react";
import styled from "styled-components";
import { TUserAssignations } from "../../types/UserGroupAssignations.types";
import RecruiterAssignationsItem from "./RecruiterAssignationsItem";

const StyledWrapper = styled.div``;

const StyledItemWrapper = styled.div`
  border-bottom: 1px solid rgb(239, 239, 239);

  &.CMui-selected {
    .CMuiCoordinatorAssignationsItem {
      background-color: rgb(235, 235, 235);
    }
  }
`;

interface IRecruiterAssignationsListProps {
  recruiters: TUserAssignations[];
  maxContracts: number;
  onSelectOwner: (ownerId: string | null) => void;
  selectedOwnerId: string | null;
}

const RecruiterAssignationsList: React.FC<IRecruiterAssignationsListProps> = (
  props
) => {
  const { recruiters, maxContracts, onSelectOwner, selectedOwnerId } = props;

  const handleOwnerSelect = (ownerId: string) => {
    onSelectOwner(ownerId);
  };

  return (
    <StyledWrapper>
      {recruiters.map((recruiter) => (
        <StyledItemWrapper
          key={`ca_${recruiter.id}`}
          className={
            selectedOwnerId === recruiter.id ? "CMui-selected" : undefined
          }
        >
          <RecruiterAssignationsItem
            recruiter={recruiter}
            maxContracts={maxContracts}
            onSelect={handleOwnerSelect}
          />
        </StyledItemWrapper>
      ))}
    </StyledWrapper>
  );
};

export default RecruiterAssignationsList;
