import * as React from "react";
import { connect, getIn } from "formik";
import { FormControlLabel, Radio } from "@material-ui/core";
import cuideoTheme from "../../containers/themes/defaultTheme";
import styled from "styled-components";
import classNames from "classnames";

const StyledFormControlLabel = styled(FormControlLabel)<{
  compact: any;
  customicons: boolean;
}>`
  border-radius: ${cuideoTheme.shape.borderRadius}px;
  height: ${(cuideoTheme.overrides as any).MuiInputBase.root.height};
  box-sizing: border-box;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  ${(props) => {
    if (props.checked) {
      return `
        color: ${cuideoTheme.palette.primary.main};
        background-color: #dcf1f2;
        border: 1px solid ${cuideoTheme.palette.secondary.main};
        & .MuiTypography-body1 {
          font-weight: 700;
        }
      `;
    }
    return `
      color: ${cuideoTheme.palette.text.secondary};
      background-color: ${cuideoTheme.palette.common.white};
      border: 1px solid #c2d1da;
      & .MuiTypography-body1 {
        font-weight: 400;
      }
    `;
  }}
  width: 100%;
  margin-left: 0;
  margin-right: 0;

  .MuiTypography-body1 {
    font-size: 1rem;
    line-height: 1.2;
  }

  ${(props) => {
    if (props.customicons) {
      return `
      .MuiIconButton-root {
        padding: 0;
      }
    `;
    }
  }}

  ${(props) => {
    if (props.compact) {
      return `
        height: 2.2rem;

        .MuiTypography-body1 {
          font-size: 0.9rem;
        }
        .MuiSvgIcon-root {
          height: 1rem;
          width: 1rem;
        }
      `;
    }
  }}
`;

interface Props {
  name: string;
  required?: boolean;
  variant?: "standard" | "outlined" | "filled" | "compact";
  label?: any;
  closeMenuOnSelect?: boolean;
  placeholder?: any;
  onChange?: any;
  options?: any;
  isMulti?: boolean;
  fullWidth?: boolean;
  color?: string;
  value?: any;
  icon?: any;
  checkedIcon?: any;
}

interface State {}

class FormikRadio extends React.Component<any, State> {
  shouldComponentUpdate(nextProps: any, nextState: any) {
    const next = nextProps.formik;
    const cur = this.props.formik;
    const name = this.props.name;
    if (
      getIn(next.values, name) !== getIn(cur.values, name) ||
      getIn(next.touched, name) !== getIn(cur.touched, name) ||
      getIn(next.errors, name) !== getIn(cur.errors, name)
    ) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    this.props.formik.registerField(this.props.name, {
      validate: this.props.validate,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.name !== prevProps.name) {
      this.props.formik.unregisterField(prevProps.name);
      this.props.formik.registerField(this.props.name, {
        validate: this.props.validate,
      });
    }

    if (this.props.validate !== prevProps.validate) {
      this.props.formik.registerField(this.props.name, {
        validate: this.props.validate,
      });
    }
  }

  componentWillUnmount() {
    this.props.formik.unregisterField(this.props.name);
  }

  render() {
    const {
      validate,
      name,
      variant,
      label,
      helperText,
      fullWidth,
      // value,
      icon,
      checkedIcon,
      ...others
    } = this.props;
    const formik = this.props.formik;

    const field = {
      value: getIn(formik.values, name),
      name,
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
    };
    const error = getIn(formik.errors, name);
    // eslint-disable-next-line
    const meta = {
      value: getIn(formik.values, name),
      error,
      touched: !!getIn(formik.touched, name),
      initialValue: getIn(formik.initialValues, name),
      initialTouched: !!getIn(formik.initialTouched, name),
      initialError: getIn(formik.initialErrors, name),
    };

    const checked = field.value === this.props.value;

    const radioControl =
      icon && checkedIcon ? (
        <Radio {...field} {...others} icon={icon} checkedIcon={checkedIcon} />
      ) : (
        <Radio {...field} {...others} />
      );

    return (
      <React.Fragment>
        <StyledFormControlLabel
          control={radioControl}
          label={label}
          checked={checked}
          compact={variant === "compact" ? true : undefined}
          customicons={icon && checkedIcon}
          className={classNames({
            CMuiFormikRadio: true,
            "CMui-error": !!meta.error && !!meta.touched,
          })}
        />
      </React.Fragment>
    );
  }
}

export default connect<Props, State>(FormikRadio as any);
