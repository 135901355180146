import React from "react";
import styled from "styled-components";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;

  .MuiSvgIcon-root {
    width: 1rem;
    height: 1rem;
    margin-left: 0.125rem;
  }
`;

interface IMessageFailedProps {}

const MessageFailed = (props: IMessageFailedProps) => {
  return (
    <StyledWrapper>
      <ErrorOutlineIcon
        style={{
          color: "#f44336",
          fontSize: "1rem",
        }}
      />
    </StyledWrapper>
  );
};

export default MessageFailed;
