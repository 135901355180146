import { apiEmpleo } from "../../util/ApiEmpleo";
import { USER_ROLES } from "../security";

export const COORDINATION_GROUP = {
  BCN: "ES_COORD_BCN",
  MAD: "ES_COORD_MAD",
  OTHER: "ES_COORD_OTH",
  SAD: "ES_COORD_SAD",
  //CAV: "ES_COORD_CAV",
  OTH_FAST: "ES_COORD_OTH_FAST",
  OTH_SLOW: "ES_COORD_OTH_SLOW",

  // Not used on Backend, only on frontend to give on-shift features
  ON_SHIFT: "ES_ON_SHIFT",
} as const;

export type TCoordinationGroup =
  (typeof COORDINATION_GROUP)[keyof typeof COORDINATION_GROUP];

export const COORDINATION_ROLE = {
  COORDINATOR: "COORDINATOR",
  PROMOTER: "PROMOTER",
  RECRUITER: "RECRUITER",
} as const;

export type TCoordinationRole =
  (typeof COORDINATION_ROLE)[keyof typeof COORDINATION_ROLE];

export type TStaffUserResponse = {
  active: boolean;
  enabled?: boolean;
  email: string;
  fullname: string;
  id: string;
  roles: string[];
  username: string;
  lastActivityTime?: string | null;
  coordinationGroups?: TCoordinationGroup[];
  numOwnedContracts: number;
  numOwnedDeals: number;
  numOwnedServices: number;
  zohoId?: string;
  zuId?: string;
};

interface IListStaffUsersParams {
  active?: boolean;
}

const apiListStaffUsers = async (inputParams: IListStaffUsersParams = {}) => {
  let res: {
    "hydra:member": TStaffUserResponse[];
    "hydra:totalItems": number;
  } = await apiEmpleo.get("staff", {
    params: {
      active:
        typeof inputParams.active !== "undefined"
          ? inputParams.active
          : undefined,
      roles: USER_ROLES.ROLE_STAFF,
      itemsPerPage: 500,
    },
  });

  return res;
};

export { apiListStaffUsers };
