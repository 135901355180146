import { useState } from "react";
import { apiUpdateContractCvFollowUp } from "../../../../../api/contract/apiUpdateContractCvFollowUp";
import invariant from "invariant";
import { STATUS, TCvFollowUp } from "../models/CvFollowUp.model";
import { TAuthenticatedUser } from "../../../../../reducers/Auth";
import { userIsPromoter } from "../../../models/Staff/Staff";
import { hasToDoCvFollowUp, TContractFull } from "../../../features/contract";

const invariantErrorMessage = "Contract should be set before cv follow action";

export default function useCvFollowUpState() {
  const [state, setState] = useState<TCvFollowUp>({
    initialized: false,
    mustDoFollowUp: false,
    contractId: null,
    selectedFollowUp: null,
    status: null,
  });

  const initStateFromContract = (
    contract: TContractFull,
    user: TAuthenticatedUser
  ): void => {
    if (!userIsPromoter(user)) {
      return;
    }

    if (hasToDoCvFollowUp(contract)) {
      setState((prevState) => {
        if (prevState.initialized) {
          return prevState;
        }
        return {
          ...prevState,
          initialized: true,
          contractId: contract.id,
          mustDoFollowUp: true,
        };
      });
    }
  };

  const sendMail = async () => {
    invariant(state.contractId !== null, invariantErrorMessage);

    setState((prevState) => ({
      ...prevState,
      status: STATUS.SEND_MAIL_PENDING,
    }));

    await apiUpdateContractCvFollowUp(state.contractId, {
      Email_seguimiento_cv: true,
    });

    setState((prevState) => ({
      ...prevState,
      status: null,
      selectedFollowUp: STATUS.SEND_MAIL,
    }));
  };

  const callClient = async () => {
    invariant(state.contractId !== null, invariantErrorMessage);

    setState((prevState) => ({
      ...prevState,
      status: STATUS.CALL_CLIENT_PENDING,
    }));

    await apiUpdateContractCvFollowUp(state.contractId, {
      Llamada_segumiento_cv: true,
    });

    setState((prevState) => ({
      ...prevState,
      status: null,
      selectedFollowUp: STATUS.CALL_CLIENT,
    }));
  };

  const noAnswerSendMail = async () => {
    invariant(state.contractId !== null, invariantErrorMessage);

    setState((prevState) => ({
      ...prevState,
      status: STATUS.NO_ANSWER_EMAIL_PENDING,
    }));

    await apiUpdateContractCvFollowUp(state.contractId, {
      Llamada_segumiento_cv: true,
    });

    setState((prevState) => ({
      ...prevState,
      status: null,
      selectedFollowUp: STATUS.NO_ANSWER_EMAIL,
    }));
  };

  const alreadyTalkedWithClient = async () => {
    invariant(state.contractId !== null, invariantErrorMessage);

    setState((prevState) => ({
      ...prevState,
      status: null,
      selectedFollowUp: STATUS.ALREADY_TALKED_CLIENT,
    }));
  };

  const confirmFollowUp = async () => {
    invariant(state.contractId !== null, invariantErrorMessage);

    setState((prevState) => ({
      ...prevState,
      status: STATUS.CONFIRM_PENDING,
    }));

    await apiUpdateContractCvFollowUp(state.contractId, {
      Seguimiento_cv_realizado: true,
    });

    setState((prevState) => ({
      ...prevState,
      mustDoFollowUp: false,
    }));
  };

  return {
    cvFollowUpState: state,
    initStateFromContract,
    sendMail,
    callClient,
    noAnswerSendMail,
    alreadyTalkedWithClient,
    confirmFollowUp,
  };
}
