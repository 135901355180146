import { Typography } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import {
  ADVANCED_VALUATION_COLOR,
  PENDING_SIGNUP_COLOR,
} from "./RecruiterAssignationsItem";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 100%;
`;

const StyledRight = styled.div`
  padding: 0.5rem 1rem;
  height: 100%;
`;

const StyledSectionTitle = styled(Typography)`
  font-size: 0.875rem;
  line-height: 1.25;
  font-weight: 600;
  color: #444;
  margin-bottom: 0.5rem;
`;

const StyledLegendItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const StyledLegendBox = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  border: 0.0625rem solid #ccc;
  box-shadow: 0.0625rem 0.0625rem 1px 0 rgba(0, 0, 0, 0.1);
  margin-right: 0.5rem;
  flex: 0 0 auto;
`;

const StyledLegendLabel = styled(Typography)`
  font-size: 0.75rem;
  line-height: 1;
  color: #555;
`;

interface IRecruitersAssignationsLegendProps {}

const RecruitersAssignationsLegend: React.FC<IRecruitersAssignationsLegendProps> =
  (props) => {
    return (
      <StyledWrapper>
        <StyledRight>
          <StyledSectionTitle>
            <FormattedMessage
              id="CoordinationAssignationsLegend.Contratos"
              defaultMessage="Contratos"
            />
          </StyledSectionTitle>
          <StyledLegendItem>
            <StyledLegendBox
              style={{ backgroundColor: PENDING_SIGNUP_COLOR }}
            ></StyledLegendBox>
            <StyledLegendLabel>
              <FormattedMessage
                id="CoordinationAssignationsLegend.Alta pendiente"
                defaultMessage="Alta pendiente"
              />
            </StyledLegendLabel>
          </StyledLegendItem>
          <StyledLegendItem>
            <StyledLegendBox
              style={{ backgroundColor: ADVANCED_VALUATION_COLOR }}
            ></StyledLegendBox>
            <StyledLegendLabel>
              <FormattedMessage
                id="CoordinationAssignationsLegend.CV Cliente / Presencial Cliente"
                defaultMessage="CV Cliente / Presencial Cliente"
              />
            </StyledLegendLabel>
          </StyledLegendItem>
        </StyledRight>
      </StyledWrapper>
    );
  };

export default RecruitersAssignationsLegend;
