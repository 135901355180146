import { apiEmpleo } from "../../util/ApiEmpleo";
import { TApiCollectionResponse } from "../apiCoreV2.types";
import { TGetNotificationsCollectionItem } from "./notification.types";

export interface IListNotificationsParams {
  page?: number;
  itemsPerPage?: number;
  read?: boolean;
  type?: string[];
  recipient?: string[];
  partial?: number;
}

const apiListNotifications = async (params: IListNotificationsParams) => {
  const res: TApiCollectionResponse<TGetNotificationsCollectionItem> =
    await apiEmpleo.get(`notifications`, { params });

  return res;
};

export { apiListNotifications };
