import Chip from "@material-ui/core/Chip";
import React from "react";
import styled from "styled-components";
import {
  SERVICE_PLAN,
  TServicePlan,
} from "../../../../../../../../api/service/service.types";

const SadBadge = styled(Chip)`
  background-color: #ebc200;
  color: #fff;
  border-radius: 0.2rem;
  font-size: 0.768rem;
  height: 1rem;
  font-weight: 700;
`;

const SelectionBadge = styled(Chip)`
  background-color: #004b7b;
  color: #fff;
  border-radius: 0.2rem;
  font-size: 0.768rem;
  height: 1rem;
  font-weight: 700;
`;

const PlanBadge = styled(Chip)`
  color: #fff;
  border-radius: 0.2rem;
  font-size: 0.768rem;
  height: 1rem;
  font-weight: 700;
`;

interface ITypeServiceBadge {
  type: string;
  plan?: TServicePlan | null;
}

const TypeServiceBadge: React.FC<ITypeServiceBadge> = ({ type, plan }) => {
  if ("sad" === type) {
    return <SadBadge label="SAD" color="secondary" size="small" />;
  }
  if (SERVICE_PLAN.COMFORT === plan) {
    return (
      <PlanBadge
        label="Confort"
        size="small"
        style={{
          backgroundColor: "#004b7b",
        }}
      />
    );
  }
  if (SERVICE_PLAN.COMFORT_PLUS_IN_PERSON === plan) {
    return (
      <PlanBadge
        label="Confort+ presencial"
        size="small"
        style={{
          backgroundColor: "#004b7b",
        }}
      />
    );
  }
  if (SERVICE_PLAN.COMFORT_PLUS_PHONE === plan) {
    return (
      <PlanBadge
        label="Confort+ telefónico"
        size="small"
        style={{
          backgroundColor: "#004b7b",
        }}
      />
    );
  }
  if (SERVICE_PLAN.PUNCTUAL_SELECTION === plan) {
    return (
      <PlanBadge
        label="Puntual Selección"
        size="small"
        style={{
          backgroundColor: "#004b7b",
        }}
      />
    );
  }
  return <SelectionBadge label="SEL" color="primary" size="small" />;
};

export default TypeServiceBadge;
