const provinces = [
  {
    id: "6902a53f-ae49-4549-b1cd-44997a77e140",
    code: "15",
    value: "Coruña, A",
    slug: "a-coruna",
    parentArea: "Galicia",
    businessCountry: "es",
  },
  {
    id: "25395f62-cc9d-4fab-9ae7-e2d08004b03d",
    code: "01",
    value: "Araba/Álava",
    slug: "alava",
    parentArea: "País Vasco",
    businessCountry: "es",
  },
  {
    id: "b7f16bd5-f6a7-4f82-af13-147bc3297ef7",
    code: "02",
    value: "Albacete",
    slug: "albacete",
    parentArea: "Castilla-La Mancha",
    businessCountry: "es",
  },
  {
    id: "c91c2cf6-0315-4690-b1b2-49b403676192",
    code: "04",
    value: "Almería",
    slug: "almeria",
    parentArea: "Andalucía",
    businessCountry: "es",
  },
  {
    id: "74bc6cab-dd32-438f-8bc4-b22b296c2d49",
    code: "03",
    value: "Alicante/Alacant",
    slug: "alicante",
    parentArea: "Comunidad Valenciana",
    businessCountry: "es",
  },
  {
    id: "691734bd-4b71-4b7f-805b-50bc235cac12",
    code: "33",
    value: "Asturias",
    slug: "asturias",
    parentArea: "Principado de Asturias",
    businessCountry: "es",
  },
  {
    id: "b9555c79-f8e0-4300-9a49-422c35f204b5",
    code: "05",
    value: "Ávila",
    slug: "avila",
    parentArea: "Castilla y León",
    businessCountry: "es",
  },
  {
    id: "b4686d0d-1713-4c9c-b81d-6e52515ba9f6",
    code: "06",
    value: "Badajoz",
    slug: "badajoz",
    parentArea: "Extremadura",
    businessCountry: "es",
  },
  {
    id: "1c102be7-dcfe-4b46-a59d-542526069dbd",
    code: "08",
    value: "Barcelona",
    slug: "barcelona",
    parentArea: "Cataluña",
    businessCountry: "es",
  },
  {
    id: "be1236fc-90bb-4fa2-ae10-faf725838f5e",
    code: "09",
    value: "Burgos",
    slug: "burgos",
    parentArea: "Castilla y León",
    businessCountry: "es",
  },
  {
    id: "909d4123-3b8f-444f-a54e-4e3d54015bb6",
    code: "10",
    value: "Cáceres",
    slug: "caceres",
    parentArea: "Extremadura",
    businessCountry: "es",
  },
  {
    id: "c9ddbad0-cc91-49de-bb15-67c2f62a40ef",
    code: "11",
    value: "Cádiz",
    slug: "cadiz",
    parentArea: "Andalucía",
    businessCountry: "es",
  },
  {
    id: "497b1fa0-f556-404d-ac0b-01db084617de",
    code: "39",
    value: "Cantabria",
    slug: "cantabria",
    parentArea: "Cantabria",
    businessCountry: "es",
  },
  {
    id: "38dd7e89-01a7-4cb2-aafb-1d70103db8a3",
    code: "12",
    value: "Castellón/Castelló",
    slug: "castellon",
    parentArea: "Comunidad Valenciana",
    businessCountry: "es",
  },
  {
    id: "a44780b6-e052-4aea-9aad-b45fd78e01de",
    code: "51",
    value: "Ceuta",
    slug: "ceuta",
    parentArea: "Ceuta",
    businessCountry: "es",
  },
  {
    id: "a1ec0ca5-4127-4f6e-a787-becffe8b1c34",
    code: "13",
    value: "Ciudad Real",
    slug: "ciudad-real",
    parentArea: "Castilla-La Mancha",
    businessCountry: "es",
  },
  {
    id: "0339c822-f85d-4093-acd2-e6b5ba7a68e3",
    code: "14",
    value: "Córdoba",
    slug: "cordoba",
    parentArea: "Andalucía",
    businessCountry: "es",
  },
  {
    id: "ca6edf4b-df31-4324-9206-255dfe78cdf8",
    code: "16",
    value: "Cuenca",
    slug: "cuenca",
    parentArea: "Castilla-La Mancha",
    businessCountry: "es",
  },
  {
    id: "4ec162c2-7958-466a-870f-31d3af8c1c18",
    code: "18",
    value: "Granada",
    slug: "granada",
    parentArea: "Andalucía",
    businessCountry: "es",
  },
  {
    id: "abb85c8e-b600-4188-92bc-cd7f799d6f34",
    code: "17",
    value: "Girona",
    slug: "girona",
    parentArea: "Cataluña",
    businessCountry: "es",
  },
  {
    id: "c0ad7bc3-b440-42e8-a08c-9636d95a83be",
    code: "19",
    value: "Guadalajara",
    slug: "guadalajara",
    parentArea: "Castilla-La Mancha",
    businessCountry: "es",
  },
  {
    id: "89d9a90b-58e2-4c7e-a10b-c1eb5471111a",
    code: "20",
    value: "Guipuzkoa",
    slug: "guipuzcoa",
    parentArea: "País Vasco",
    businessCountry: "es",
  },
  {
    id: "9ed50beb-5677-40f6-8066-5d10004b30b7",
    code: "21",
    value: "Huelva",
    slug: "huelva",
    parentArea: "Andalucía",
    businessCountry: "es",
  },
  {
    id: "94392c7a-42bf-4e84-8ee2-04923f202138",
    code: "22",
    value: "Huesca",
    slug: "huesca",
    parentArea: "Aragón",
    businessCountry: "es",
  },
  {
    id: "1440293f-f2e9-482b-a240-634c07609737",
    code: "07",
    value: "Balears, Illes",
    slug: "illes-balears",
    parentArea: "Baleares",
    businessCountry: "es",
  },
  {
    id: "fbcd8d73-047f-4585-811b-a3c0502449a6",
    code: "23",
    value: "Jaén",
    slug: "jaen",
    parentArea: "Andalucía",
    businessCountry: "es",
  },
  {
    id: "078b0eba-dadd-41ad-a84a-54307e6b4242",
    code: "26",
    value: "Rioja, La",
    slug: "la-rioja",
    parentArea: "Rioja, La",
    businessCountry: "es",
  },
  {
    id: "7571b776-6e31-47ff-b7a7-3511602c5091",
    code: "35",
    value: "Palmas, Las",
    slug: "las-palmas",
    parentArea: "Canarias",
    businessCountry: "es",
  },
  {
    id: "b4420eb7-4454-4581-897b-28dbbd809c37",
    code: "24",
    value: "León",
    slug: "leon",
    parentArea: "Castilla y León",
    businessCountry: "es",
  },
  {
    id: "0010dd8e-f8ef-40cc-97dc-4b9607a8cc39",
    code: "25",
    value: "Lleida",
    slug: "lleida",
    parentArea: "Cataluña",
    businessCountry: "es",
  },
  {
    id: "f8418f71-e54a-4157-8aed-b6d75f30fcaf",
    code: "27",
    value: "Lugo",
    slug: "lugo",
    parentArea: "Galicia",
    businessCountry: "es",
  },
  {
    id: "5abc4907-1ba5-4282-aabf-e43115e2edd3",
    code: "28",
    value: "Madrid",
    slug: "madrid",
    parentArea: "Comunidad de Madrid",
    businessCountry: "es",
  },
  {
    id: "49306086-4b8e-4e89-8a96-2a9f0537d205",
    code: "29",
    value: "Málaga",
    slug: "malaga",
    parentArea: "Andalucía",
    businessCountry: "es",
  },
  {
    id: "66c3cd48-8819-4bee-b824-4508efc7ac80",
    code: "52",
    value: "Melilla",
    slug: "melilla",
    parentArea: "Melilla",
    businessCountry: "es",
  },
  {
    id: "b664488a-9b1a-4016-88bd-f42af1928422",
    code: "30",
    value: "Murcia",
    slug: "murcia",
    parentArea: "Región de Murcia",
    businessCountry: "es",
  },
  {
    id: "0b569425-6580-41a9-9953-666e8de0b88e",
    code: "31",
    value: "Navarra",
    slug: "navarra",
    parentArea: "Comunidad Foral de Navarra",
    businessCountry: "es",
  },
  {
    id: "f8842670-10b4-4e02-8470-f9e827448995",
    code: "32",
    value: "Ourense",
    slug: "ourense",
    parentArea: "Galicia",
    businessCountry: "es",
  },
  {
    id: "819f484b-e1d9-4348-a295-1697728d30c2",
    code: "34",
    value: "Palencia",
    slug: "palencia",
    parentArea: "Castilla y León",
    businessCountry: "es",
  },
  {
    id: "6feac59d-ba50-40b5-a0bc-88e304cc97e9",
    code: "36",
    value: "Pontevedra",
    slug: "pontevedra",
    parentArea: "Galicia",
    businessCountry: "es",
  },
  {
    id: "2e63ca0f-dd95-42ad-a32f-045a30087fb6",
    code: "37",
    value: "Salamanca",
    slug: "salamanca",
    parentArea: "Castilla y León",
    businessCountry: "es",
  },
  {
    id: "40110fce-2078-4e16-8039-6ef0ae797076",
    code: "38",
    value: "Santa Cruz de Tenerife",
    slug: "santa-cruz-de-tenerife",
    parentArea: "Canarias",
    businessCountry: "es",
  },
  {
    id: "6e6e17ac-63c9-4ddc-b83a-21a8855fa3b5",
    code: "40",
    value: "Segovia",
    slug: "segovia",
    parentArea: "Castilla y León",
    businessCountry: "es",
  },
  {
    id: "e24b22db-c0bc-4bf8-91dc-f3ac9ce05e3d",
    code: "41",
    value: "Sevilla",
    slug: "sevilla",
    parentArea: "Andalucía",
    businessCountry: "es",
  },
  {
    id: "df4c1f4e-4b8f-470d-a869-4cba60275855",
    code: "42",
    value: "Soria",
    slug: "soria",
    parentArea: "Castilla y León",
    businessCountry: "es",
  },
  {
    id: "da0c10dc-84bd-4ed5-8239-25a27f2a559a",
    code: "43",
    value: "Tarragona",
    slug: "tarragona",
    parentArea: "Cataluña",
    businessCountry: "es",
  },
  {
    id: "16ad08d7-55e5-4c11-8c95-bc19496e3cc8",
    code: "44",
    value: "Teruel",
    slug: "teruel",
    parentArea: "Aragón",
    businessCountry: "es",
  },
  {
    id: "864a82cf-a770-4e4e-a8c3-e2fd75d95838",
    code: "45",
    value: "Toledo",
    slug: "toledo",
    parentArea: "Castilla-La Mancha",
    businessCountry: "es",
  },
  {
    id: "80541bed-5623-4095-9ef8-e702a1986202",
    code: "46",
    value: "Valencia/València",
    slug: "valencia",
    parentArea: "Comunidad Valenciana",
    businessCountry: "es",
  },
  {
    id: "7487dfd9-4594-4aa1-9df6-4abf3be3f9ab",
    code: "47",
    value: "Valladolid",
    slug: "valladolid",
    parentArea: "Castilla y León",
    businessCountry: "es",
  },
  {
    id: "d4b387f1-6764-4753-ab7a-2238e4e2e23d",
    code: "48",
    value: "Bizkaia",
    slug: "vizcaya",
    parentArea: "País Vasco",
    businessCountry: "es",
  },
  {
    id: "4e2b1445-0253-4e40-9157-95126eeda3f3",
    code: "49",
    value: "Zamora",
    slug: "zamora",
    parentArea: "Castilla y León",
    businessCountry: "es",
  },
  {
    id: "2b4e6948-8a58-418c-979e-29d1d4dbf084",
    code: "50",
    value: "Zaragoza",
    slug: "zaragoza",
    parentArea: "Aragón",
    businessCountry: "es",
  },
  {
    code: "01",
    value: "Ain",
    label: "Ain",
    businessCountry: "fr",
  },
  {
    code: "02",
    value: "Aisne",
    label: "Aisne",
    businessCountry: "fr",
  },
  { code: "03", value: "Allier", businessCountry: "fr" },
  { code: "04", value: "Alpes-de-Haute-Provence", businessCountry: "fr" },
  { code: "05", value: "Hautes-Alpes", businessCountry: "fr" },
  { code: "06", value: "Alpes-Maritimes", businessCountry: "fr" },
  { code: "07", value: "Ardèche", businessCountry: "fr" },
  { code: "08", value: "Ardennes", businessCountry: "fr" },
  { code: "09", value: "Ariège", businessCountry: "fr" },
  { code: "10", value: "Aube", businessCountry: "fr" },
  { code: "11", value: "Aude", businessCountry: "fr" },
  { code: "12", value: "Aveyron", businessCountry: "fr" },
  { code: "13", value: "Bouches-du-Rhône", businessCountry: "fr" },
  { code: "14", value: "Calvados", businessCountry: "fr" },
  { code: "15", value: "Cantal", businessCountry: "fr" },
  { code: "16", value: "Charente", businessCountry: "fr" },
  { code: "17", value: "Charente-Maritime", businessCountry: "fr" },
  { code: "18", value: "Cher", businessCountry: "fr" },
  { code: "19", value: "Corrèze", businessCountry: "fr" },
  { code: "21", value: "Côte-d'Or", businessCountry: "fr" },
  { code: "22", value: "Côtes d'Armor", businessCountry: "fr" },
  { code: "23", value: "Creuse", businessCountry: "fr" },
  { code: "24", value: "Dordogne", businessCountry: "fr" },
  { code: "25", value: "Doubs", businessCountry: "fr" },
  { code: "26", value: "Drôme", businessCountry: "fr" },
  { code: "27", value: "Eure", businessCountry: "fr" },
  { code: "28", value: "Eure-et-Loir", businessCountry: "fr" },
  { code: "29", value: "Finistère", businessCountry: "fr" },
  { code: "30", value: "Gard", businessCountry: "fr" },
  { code: "31", value: "Haute-Garonne", businessCountry: "fr" },
  { code: "32", value: "Gers", businessCountry: "fr" },
  { code: "33", value: "Gironde", businessCountry: "fr" },
  { code: "34", value: "Hérault", businessCountry: "fr" },
  { code: "35", value: "Ille-et-Vilaine", businessCountry: "fr" },
  { code: "36", value: "Indre", businessCountry: "fr" },
  { code: "37", value: "Indre-et-Loire", businessCountry: "fr" },
  { code: "38", value: "Isère", businessCountry: "fr" },
  { code: "39", value: "Jura", businessCountry: "fr" },
  { code: "40", value: "Landes", businessCountry: "fr" },
  { code: "41", value: "Loir-et-Cher", businessCountry: "fr" },
  { code: "42", value: "Loire", businessCountry: "fr" },
  { code: "43", value: "Haute-Loire", businessCountry: "fr" },
  { code: "44", value: "Loire-Atlantique", businessCountry: "fr" },
  { code: "45", value: "Loiret", businessCountry: "fr" },
  { code: "46", value: "Lot", businessCountry: "fr" },
  { code: "47", value: "Lot-et-Garonne", businessCountry: "fr" },
  { code: "48", value: "Lozère", businessCountry: "fr" },
  { code: "49", value: "Maine-et-Loire", businessCountry: "fr" },
  { code: "50", value: "Manche", businessCountry: "fr" },
  { code: "51", value: "Marne", businessCountry: "fr" },
  { code: "52", value: "Haute-Marne", businessCountry: "fr" },
  { code: "53", value: "Mayenne", businessCountry: "fr" },
  { code: "54", value: "Meurthe-et-Moselle", businessCountry: "fr" },
  { code: "55", value: "Meuse", businessCountry: "fr" },
  { code: "56", value: "Morbihan", businessCountry: "fr" },
  { code: "57", value: "Moselle", businessCountry: "fr" },
  { code: "58", value: "Nièvre", businessCountry: "fr" },
  { code: "59", value: "Nord", businessCountry: "fr" },
  { code: "60", value: "Oise", businessCountry: "fr" },
  { code: "61", value: "Orne", businessCountry: "fr" },
  { code: "62", value: "Pas-de-Calais", businessCountry: "fr" },
  { code: "63", value: "Puy-de-Dôme", businessCountry: "fr" },
  { code: "64", value: "Pyrénées-Atlantiques", businessCountry: "fr" },
  { code: "65", value: "Hautes-Pyrénées", businessCountry: "fr" },
  { code: "66", value: "Pyrénées-Orientales", businessCountry: "fr" },
  { code: "67", value: "Bas-Rhin", businessCountry: "fr" },
  { code: "68", value: "Haut-Rhin", businessCountry: "fr" },
  { code: "69", value: "Rhône", businessCountry: "fr" },
  { code: "70", value: "Haute-Saône", businessCountry: "fr" },
  { code: "71", value: "Saône-et-Loire", businessCountry: "fr" },
  { code: "72", value: "Sarthe", businessCountry: "fr" },
  { code: "73", value: "Savoie", businessCountry: "fr" },
  { code: "74", value: "Haute-Savoie", businessCountry: "fr" },
  { code: "75", value: "Paris", businessCountry: "fr" },
  { code: "76", value: "Seine-Maritime", businessCountry: "fr" },
  { code: "77", value: "Seine-et-Marne", businessCountry: "fr" },
  { code: "78", value: "Yvelines", businessCountry: "fr" },
  { code: "79", value: "Deux-Sèvres", businessCountry: "fr" },
  { code: "80", value: "Somme", businessCountry: "fr" },
  { code: "81", value: "Tarn", businessCountry: "fr" },
  { code: "82", value: "Tarn-et-Garonne", businessCountry: "fr" },
  { code: "83", value: "Var", businessCountry: "fr" },
  { code: "84", value: "Vaucluse", businessCountry: "fr" },
  { code: "85", value: "Vendée", businessCountry: "fr" },
  { code: "86", value: "Vienne", businessCountry: "fr" },
  { code: "87", value: "Haute-Vienne", businessCountry: "fr" },
  { code: "88", value: "Vosges", businessCountry: "fr" },
  { code: "89", value: "Yonne", businessCountry: "fr" },
  { code: "90", value: "Territoire-de-Belfort", businessCountry: "fr" },
  { code: "91", value: "Essonne", businessCountry: "fr" },
  { code: "92", value: "Hauts-de-Seine", businessCountry: "fr" },
  { code: "93", value: "Seine-Saint-Denis", businessCountry: "fr" },
  { code: "94", value: "Val-de-Marne", businessCountry: "fr" },
  { code: "95", value: "Val-D'Oise", businessCountry: "fr" },
];

export function regionsList(country = "es") {
  return provinces.filter((province) => province.businessCountry === country);
}

export default provinces;
