import { useIntl } from "react-intl";
import * as Yup from "yup";

export default function useContractEditFormValuationSchema() {
  const { formatMessage } = useIntl();

  const validationSchema = Yup.object({
    T_tulo: Yup.string()
      .required(
        formatMessage({
          id: "ContractEditModal.Debes introducir el título",
          defaultMessage: "Debes introducir el título",
        })
      )
      .test(
        "test",
        formatMessage({
          id: "ContractEditModal.El título debe comenzar por 'Cuidador/a '",
          defaultMessage: "El título debe comenzar por 'Cuidador/a '",
        }),
        (value) => {
          if (typeof value === "undefined") return true;
          const matches = value.match(/^Cuidador\/a /gs);
          return matches ? true : false;
        }
      ),
    Tipo: Yup.object().shape({
      value: Yup.string().required(
        formatMessage({
          id: "ContractEditModal.Debes introducir el tipo de oferta",
          defaultMessage: "Debes introducir el tipo de oferta",
        })
      ),
    }),
    Ciudad_oferta: Yup.string().required(
      formatMessage({
        id: "ContractEditModal.Debes introducir la ciudad",
        defaultMessage: "Debes introducir la ciudad",
      })
    ),
    Horario_Servicio: Yup.string().required(
      formatMessage({
        id: "ContractEditModal.Debes introducir el horario",
        defaultMessage: "Debes introducir el horario",
      })
    ),
    Que_buscamos: Yup.string()
      .required(
        formatMessage({
          id: "ContractEditModal.Debes introducir el Qué buscamos",
          defaultMessage: "Debes introducir el Qué buscamos",
        })
      )
      .test(
        "testMatch",
        formatMessage({
          id: "ContractEditModal.No puedes usar la palabra 'Cuidador' ni 'Cuidadora'. Usa 'Cuidador/a' en su lugar.",
          defaultMessage:
            "No puedes usar la palabra 'Cuidador' ni 'Cuidadora'. Usa 'Cuidador/a' en su lugar.",
        }),
        (value) => {
          if (typeof value === "undefined") return true;
          const matches = value.match(/cuidador(?!\/a)|cuidadora/i);
          return matches ? false : true;
        }
      ),
    Requisitos: Yup.string()
      .required(
        formatMessage({
          id: "ContractEditModal.Debes introducir los Requisitos",
          defaultMessage: "Debes introducir los Requisitos",
        })
      )
      .test(
        "testMatch",
        formatMessage({
          id: "ContractEditModal.No puedes usar la palabra 'Cuidador' ni 'Cuidadora'. Usa 'Cuidador/a' en su lugar.",
          defaultMessage:
            "No puedes usar la palabra 'Cuidador' ni 'Cuidadora'. Usa 'Cuidador/a' en su lugar.",
        }),
        (value) => {
          if (typeof value === "undefined") return true;
          const matches = value.match(/cuidador(?!\/a)|cuidadora/i);
          return matches ? false : true;
        }
      ),
    Salario_Bruto_Mensual: Yup.string().required(
      formatMessage({
        id: "ContractEditModal.Debes introducir el Salario",
        defaultMessage: "Debes introducir el Salario",
      })
    ),
    Tipo_de_salario: Yup.object().shape({
      value: Yup.string().required(
        formatMessage({
          id: "ContractEditModal.Debes introducir el tipo de salario",
          defaultMessage: "Debes introducir el tipo de salario",
        })
      ),
    }),
  });

  return validationSchema;
}
