import { useMutation } from "react-query";
import {
  apiUpdateReadNotification,
  TUpdateNotificationRequest,
} from "../../../../../api/notification/apiUpdateReadNotification";
import { TGetNotificationsCollectionItem } from "../../../../../api/notification/notification.types";
import { MutationConfig, queryClient } from "../../../../../lib/react-query";
import { badgesKeys } from "../../badges";
import { notificationKeys } from "./notification.query-keys";

type TUseUpdateNotification = {
  config?: MutationConfig<typeof apiUpdateReadNotification>;
};

export const useUpdateNotification = ({
  config,
}: TUseUpdateNotification = {}) => {
  return useMutation({
    onMutate: async (payload: TUpdateNotificationRequest) => {
      await queryClient.cancelQueries(notificationKeys.all);

      // const queries: any[] = queryClient.getQueriesData(notificationKeys.all);

      queryClient.setQueriesData(notificationKeys.all, (e: any) => {
        if (e.pages) {
          return {
            ...e,
            pages: e.pages.map(
              (page: { "hydra:member": TGetNotificationsCollectionItem[] }) => {
                return {
                  ...page,
                  "hydra:member": page["hydra:member"].map((notification) => {
                    if (notification.id === payload.notificationId) {
                      return {
                        ...notification,
                        ...payload,
                      };
                    }
                    return notification;
                  }),
                };
              }
            ),
          };
        }
        return e;
      });

      // console.log(
      //   "PrevQuery: ",
      //   queryClient.getQueriesData(notificationKeys.all)
      // );

      // if (!prevQuery) {
      //   return;
      // }

      // const newNotifications = prevQuery.notifications.map((notification) => ({
      //   ...notification,
      //   read:
      //     notification.id === payload.notificationId
      //       ? payload.read
      //       : notification.read,
      // }));

      // queryClient.setQueryData(["notifications", "list", authUser.userId], {
      //   ...prevQuery,
      //   notifications: newNotifications,
      // });

      // return { prevQuery };
    },
    onSettled: () => {
      queryClient.invalidateQueries(notificationKeys.all);
      queryClient.invalidateQueries(badgesKeys.mine);
    },
    ...config,
    mutationFn: apiUpdateReadNotification,
  });
};
