import { Grid, Switch, SwitchProps, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import {
  TCoordinationGroup,
  TStaffUserResponse,
} from "../../../../../../api/user/apiListStaffUsers";
import { cuideoTheme } from "../../../../../../containers/themes/defaultTheme";
import { TUserGroupAssignations } from "../../../recruitersManager/types/UserGroupAssignations.types";
import UserGroupSelector from "../../../user/components/UserGroupSelector/UserGroupSelector";
import RoleSelector from "./components/RoleSelector/RoleSelector";
import ServicesTable from "./components/ServicesTable";
import StaffAssignations from "./components/StaffAssignations";
import UnassignedServicesTable from "./components/UnassignedServicesTable";
import UnratedServiceDetail from "../../../service/components/UnratedServiceDetail/UnratedServiceDetail";
import { TServicesUnratedPageFilters } from "./hooks/useServicesUnratedPageFilters";
import { TUnratedServicesResponse } from "./hooks/useServicesUnratedPageQuery";
import { UNASIGNED_PAGE } from "./PromotersManagerRouteContainer";

const ASIDE_BREAKPOINT = 600;

const StyledPageContainer = styled.div`
  margin: 0;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

const StyledLeftAside = styled.aside`
  min-width: 20rem;
  max-width: 20rem;
  background-color: #f5f5f5;
  border-right: 0.125rem solid #ddd;
`;

const StyledMainContainer = styled.main`
  flex-grow: 1;
  padding: 1rem;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  height: 100%;
  box-sizing: border-box;

  ::-webkit-scrollbar {
    width: 0.25rem;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 72, 120, 0.25);
    width: 0.25rem;
    border-radius: 0.125rem;
  }
`;

const StyledAside = styled.aside`
  background-color: #f5f5f5;
  min-width: 20rem;
  max-width: 20rem;
  padding: 1rem;
  overflow-y: scroll;
  border-left: 0.125rem solid #ddd;

  ::-webkit-scrollbar {
    width: 0.25rem;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 72, 120, 0.25);
    width: 0.25rem;
    border-radius: 0.125rem;
  }
`;

const StyledPageTitle = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 700;
`;

const StyledPagination = styled(Pagination)`
  margin: 1rem 0;
  .MuiPagination-ul {
    justify-content: center;
  }
`;

const StyledTableWrapper = styled.div`
  padding: 1rem 0;
`;

const StyledOwerTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledOwnerName = styled.div`
  padding-right: 1rem;
`;

const StyledDisableSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

interface IPromotersManagerRoute {
  reloadButtonStatus: "idle" | "loading" | "error" | "success";
  onReloadButtonClick: () => void;
  filters: TServicesUnratedPageFilters;
  setFilters: (newFilters: TServicesUnratedPageFilters) => void;
  onPageChange: (event: any, newPage: number) => void;
  services: TUnratedServicesResponse[];
  totalServices: number;
  isLoading: boolean;
  queryError: any;
  onSelectService: (serviceId: string) => void;
  ownerOptions: TStaffUserResponse[];
  onOwnerChange: () => void;
  onSelectAllUnvaluedServices: () => void;
  onSelectOwner: (ownerId: string | null) => void;
  onSelectUnassignedServices: () => void;
  currentStaffUser: TStaffUserResponse | null;
  groups: TUserGroupAssignations[];
  onGroupChange: (group: TCoordinationGroup, ownerId: string) => Promise<void>;
  onActivationSwitchChange: (owerId: string, status: boolean) => Promise<void>;
  superAdminId: string | null;
  windowWidth: number;
}

const PromotersManagerRoute = (props: IPromotersManagerRoute) => {
  const {
    reloadButtonStatus,
    onReloadButtonClick,
    filters,
    onPageChange,
    services,
    totalServices,
    isLoading,
    onSelectService,
    ownerOptions,
    onOwnerChange,
    onSelectOwner,
    onSelectAllUnvaluedServices,
    onSelectUnassignedServices,
    currentStaffUser,
    groups,
    onGroupChange,
    onActivationSwitchChange,
    superAdminId,
    windowWidth,
  } = props;

  const { formatMessage } = useIntl();

  return (
    <StyledPageContainer>
      <StyledLeftAside>
        <StaffAssignations
          reloadButtonStatus={reloadButtonStatus}
          onReloadButtonClick={onReloadButtonClick}
          onSelectOwner={onSelectOwner}
          onSelectAllUnvaluedServices={onSelectAllUnvaluedServices}
          onSelectUnassignedServices={onSelectUnassignedServices}
          selectedOwnerId={filters.ownerId}
        />
      </StyledLeftAside>
      <StyledMainContainer>
        <Grid container justifyContent="space-between">
          <Grid item xs={6}>
            <StyledPageTitle>
              {null !== currentStaffUser &&
              filters.pageType !== UNASIGNED_PAGE ? (
                <StyledOwerTitle key={`staff_title_${currentStaffUser.id}`}>
                  <StyledOwnerName>{currentStaffUser.fullname}</StyledOwnerName>
                  <StyledDisableSwitch
                    theme={cuideoTheme}
                    checked={!!currentStaffUser.enabled}
                    title={formatMessage({
                      id: "PromotersManagerRoute.Activar/desactivar Coordinador/a",
                      defaultMessage: "Activar/desactivar Coordinador/a",
                    })}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      onActivationSwitchChange(
                        currentStaffUser.id,
                        event.target.checked
                      );
                    }}
                  />
                </StyledOwerTitle>
              ) : filters.pageType !== UNASIGNED_PAGE ? (
                <FormattedMessage
                  id="PromotersManagerRoute.Servicios sin valorar"
                  defaultMessage="Servicios sin valorar"
                />
              ) : (
                <FormattedMessage
                  id="PromotersManagerRoute.Servicios sin asignar"
                  defaultMessage="Servicios sin asignar"
                />
              )}
            </StyledPageTitle>
          </Grid>

          {null !== currentStaffUser && filters.pageType !== UNASIGNED_PAGE && (
            <>
              <Grid item xs={6}>
                <Grid container spacing={4} justifyContent="flex-end">
                  <Grid item>
                    <UserGroupSelector
                      currentStaffUser={currentStaffUser}
                      groups={groups}
                      onGroupChange={onGroupChange}
                    />
                  </Grid>
                  <Grid item>
                    <RoleSelector currentStaffUser={currentStaffUser} />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
        <StyledTableWrapper>
          {null === filters.pageType && (
            <ServicesTable
              selectedOwner={filters.ownerId}
              services={services}
              onSelectService={onSelectService}
              selectedServiceId={filters.selectedService}
              isLoading={isLoading}
            />
          )}
          {UNASIGNED_PAGE === filters.pageType && (
            <UnassignedServicesTable
              services={services}
              onSelectService={onSelectService}
              selectedServiceId={filters.selectedService}
              isLoading={isLoading}
            />
          )}
        </StyledTableWrapper>
        <StyledPagination
          count={Math.ceil(totalServices / 20)}
          page={filters.page}
          onChange={onPageChange}
          color="primary"
        />
      </StyledMainContainer>
      {windowWidth > ASIDE_BREAKPOINT && (
        <StyledAside>
          <UnratedServiceDetail
            superAdminId={superAdminId}
            serviceId={filters.selectedService}
            ownerOptions={ownerOptions}
            onOwnerChanged={onOwnerChange}
          />
        </StyledAside>
      )}
    </StyledPageContainer>
  );
};

export default PromotersManagerRoute;
