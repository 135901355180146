import { useMutation } from "react-query";
import {
  apiUpdateContract,
  TUpdateContractPayload,
} from "../../../../../api/contract/apiUpdateContract";
import { MutationConfig, queryClient } from "../../../../../lib/react-query";
import { contractKeys } from "../util/contract.query-keys";

type TUpdateContract = TUpdateContractPayload & { contractId: string };

export const updateContract = ({ contractId, ...payload }: TUpdateContract) => {
  return apiUpdateContract(contractId, payload);
};

type TUseUpdateContract = {
  config?: MutationConfig<typeof updateContract>;
};

export const useUpdateContract = ({ config }: TUseUpdateContract = {}) => {
  return useMutation({
    onMutate: async (payload: TUpdateContract) => {
      await queryClient.cancelQueries(contractKeys.all);
    },
    onSettled: () => {
      queryClient.invalidateQueries(contractKeys.all);
    },
    ...config,
    mutationFn: updateContract,
  });
};
