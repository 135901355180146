import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import uuidv4 from "uuid/v4";
import { fetchStart, fetchSuccess } from "../actions/Common";
import { getSiteStatsSuccess } from "../actions/Statistics";
import { GET_SITE_STATS } from "../constants/ActionTypes";
import { apiEmpleo } from "../util/ApiEmpleo";

const getStatisticsRequest = async () => {
  let res = await apiEmpleo.get("sitestats", {
    hideLoadingState: true,
  });
  if (res) {
    return res;
  }
};

function* getStatistics() {
  try {
    const opId = uuidv4();
    yield put(fetchStart(opId, "getStatistics"));
    // @ts-ignore
    const statisticsRequest = yield call(getStatisticsRequest);
    if (statisticsRequest) {
      yield put(getSiteStatsSuccess(statisticsRequest));
      yield put(fetchSuccess(opId));
    }
  } catch (error) {
    console.log("Error", error);
  }
}

export function* getStatisticsSaga() {
  yield takeEvery(GET_SITE_STATS as any, getStatistics);
}

export default function* rootSaga() {
  yield all([fork(getStatisticsSaga)]);
}
