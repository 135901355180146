import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { cuideoTheme } from "../../../../../../containers/themes/defaultTheme";
import { TContractPartialWithNumbers } from "../../types/contract.types";
import { contractIsExpress } from "../../util/contract.util";

const StyledExpressTag = styled.span`
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  color: ${cuideoTheme.palette.primary.main};
  font-weight: 400;
`;

interface IExpressTagProps {
  contract: TContractPartialWithNumbers;
}

const ExpressTag: React.FC<IExpressTagProps> = ({ contract }) => {
  if (contractIsExpress(contract)) {
    return (
      <StyledExpressTag>
        <FormattedMessage
          id="ExpressTag.Servicio Express"
          defaultMessage="🚀 Servicio Express"
        />
      </StyledExpressTag>
    );
  }

  return null;
};

export default ExpressTag;
