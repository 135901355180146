import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
} from "react";
import { TConnectedUser } from "../types/connectedUser.types";

export type TConnectedUsersContextValue = {
  connectedUsers: TConnectedUser[];
};

type TConnectedUsersContext = [
  TConnectedUsersContextValue,
  Dispatch<SetStateAction<TConnectedUsersContextValue>>
];

export const ConnectedUsersContext =
  createContext<TConnectedUsersContext | null>(null);

export const useConnectedUsers = () => {
  const value = useContext(ConnectedUsersContext);
  if (value === null) throw new Error("Missing ConnectedUsers provider");

  const [state, setState] = value;

  const isUserConnected = useCallback(
    (userId: string) => {
      const foundConnectedUser = state.connectedUsers.find(
        (cu) => cu.userId === userId
      );

      return !!foundConnectedUser;
    },
    [state.connectedUsers]
  );

  const updateConnectedUsers = useCallback(
    (connectedUsers: TConnectedUser[]) => {
      setState({
        connectedUsers,
      });
    },
    [setState]
  );

  return {
    connectedUsers: state.connectedUsers,
    isUserConnected,
    updateConnectedUsers,
  };
};
