import {
  REPORT_CLIENT_EVENT,
  UPDATE_CLIENT_EVENT_STATE,
  SET_CLIENT_EVENT_SEND_STATUS,
} from "../constants/ActionTypes";

export const EVENT_HEARTBEAT = "heartbeat";
export const EVENT_WINDOW_BLUR = "window_blur";
export const EVENT_WINDOW_FOCUS = "window_focus";
export const EVENT_LEAVE = "leave";
export const EVENT_CLIENT_JS_ERROR = "client_js_error";
export const EVENT_PAGEVIEW = "pageview";

export const reportClientEvent = (clientEvent: any) => {
  // Add a couple of values
  if (!clientEvent.triggeredOn) {
    clientEvent.triggeredOn = Date.now();
  }
  if (!clientEvent.clientAppId) {
    clientEvent.clientAppId =
      process.env.REACT_APP_AFFINITY === "true" ? "AFFINITY" : "EMPLEO";
  }

  return {
    type: REPORT_CLIENT_EVENT,
    payload: clientEvent,
  };
};

export const setClientEventSending = (isSending: boolean) => {
  return {
    type: SET_CLIENT_EVENT_SEND_STATUS,
    payload: isSending,
  };
};

export const updateClientEventState = (newState: any) => {
  return {
    type: UPDATE_CLIENT_EVENT_STATE,
    payload: newState,
  };
};
