import { createTheme } from "@material-ui/core/styles";

const defaultSettings = {
  palette: {
    primary: {
      main: "#004b7b",
      contrastText: "#fff",
      100: "#d8efff",
      50: "#ebf7fe",
      25: "#f5fbff",
    },
    secondary: {
      main: "#5bc0be",
      light: "#7bcccb",
      // main: "#ebc200",
      contrastText: "#fff",
    },
    error: {
      main: "#f64a69",
      100: "#feecf0",
    },
  },
  spacing: (factor: any) => `${0.1 * factor}rem`,
  status: {
    danger: "orange",
  },
  shape: {
    borderRadius: 5,
  },
  typography: {
    fontFamily: '"Source Sans Pro", Helvetica, Arial, sans-serif',
    fontSize: 16,
    // htmlFontSize: 10,
    h1: {
      fontFamily: '"Lora", Georgia, Serif',
      fontWeight: 700,
      fontSize: 32,
      color: "#004b7b",
    },
    h4: {
      fontFamily: '"Source Sans Pro", Helvetica, Arial, sans-serif',
      fontWeight: 700,
      fontSize: 24,
      color: "#004b7b",
    },
    // h6 se usa como title en los modal dialog y titulos en graficos
    h6: {
      fontFamily: '"Source Sans Pro", Helvetica, Arial, sans-serif',
      fontWeight: 700,
      fontSize: 18,
      color: "#004b7b",
    },
    button: {
      fontWeight: 700,
      fontSize: 16,
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        height: "50px",
        fontSize: "1rem",
        borderColor: "#c2d1da",
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: "#c2d1da",
        // boxShadow: "inset 0 1px 1px 0 rgba(0,0,0,.25)"
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 16px) scale(1)",
      },
    },
    MuiButton: {
      contained: {
        boxShadow: "none",
      },
    },
    MuiIconButton: {
      root: {
        padding: ".25rem",
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: "1rem",
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiPickersClockNumber: {
      clockNumber: {
        left: "calc(50% - 4px)",
      },
    },
  },
};

const theme = createTheme(defaultSettings);

export default theme;
export { theme as cuideoTheme };

export const COLOR_SUCCESS = "#4caf50";
export const COLOR_SUCCESS__HOVER = "#379938";

export const COLOR_RED_NOTIFICATION = "#cc1016";

export const COLOR_SUCCESS_ALERT_BG = "#edf7ed";
export const COLOR_SUCCESS_ALERT_FG = "#1e4620";

export const COLOR_INFO_ALERT_BG = "#e5f6fd";
export const COLOR_INFO_ALERT_FG = "#014361";

export const COLOR_ERROR_ALERT_BG = "#fdeded";
export const COLOR_ERROR_ALERT_FG = "#5f2120";

export const COLOR_WARNING_ALERT_BG = "#fff4e5";
export const COLOR_WARNING_ALERT_FG = "#663c00";

export const COLOR_BG_SOFT_GRAY = "#f5f5f5";
