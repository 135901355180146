import { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { SERVICE_STATE } from "../../../../../../../api/service/service.types";
import {
  updateURLParams,
  urlParamsToObject,
} from "../../../../../../../util/urlManager";

const initialState = {
  pageType: null as null | string,
  search: "",
  businessCountry: "es",
  state: [SERVICE_STATE.WITHOUT_VALIDATION],
  page: 1,
  selectedService: null as null | string,
  ownerId: null as null | string,
  ready: false,
};

export type TServicesUnratedPageFilters = typeof initialState;

export default function useServicesUnratedPageFilters() {
  const [filters, setFiltersState] = useState({ ...initialState });
  const authUser = useSelector((state: any) => state.auth.authUser);
  const history = useHistory();

  useEffect(() => {
    if (history.action !== "REPLACE") {
      const params = history.location.search
        ? urlParamsToObject(history.location.search)
        : {};

      if (params.page && typeof params.page !== "number") {
        params.page = parseInt(params.page);
      }

      setFiltersState((prevFilters) => {
        if (!_.some([prevFilters], params)) {
          return {
            ...prevFilters,
            owner:
              authUser.numOwnedContracts && authUser.numOwnedContracts > 0
                ? authUser.userId
                : "",
            ...params,
            ready: true,
          };
        } else {
          return {
            ...prevFilters,
            owner:
              authUser.numOwnedContracts && authUser.numOwnedContracts > 0
                ? authUser.userId
                : "",
            ready: true,
          };
        }
      });
    }
    // eslint-disable-next-line
  }, [history, setFiltersState]);

  const setFilters = useCallback(
    (newFilters: TServicesUnratedPageFilters) => {
      const params = {
        ...newFilters,
      } as any;
      setFiltersState(newFilters);

      // Parse a bit wich parameters to set on url
      delete params.ready;
      Object.keys(initialState).forEach((key) => {
        if (Array.isArray((initialState as any)[key])) {
          if (
            _.isEqual(
              _.sortBy((initialState as any)[key]),
              _.sortBy(params[key])
            )
          ) {
            delete params[key];
          }
        } else {
          if (params[key] === (initialState as any)[key]) {
            delete params[key];
          }
        }
      });

      updateURLParams(params);
    },
    [setFiltersState]
  );

  return { filters, setFilters };
}
