export default function nl2br(str: string, isXhtml = false) {
  var breakTag =
    // eslint-disable-next-line
    isXhtml || typeof isXhtml === "undefined" ? "<br " + "/>" : "<br>";

  return (str + "").replace(
    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
    "$1" + breakTag + "$2"
  );
}
