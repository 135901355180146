import {
  GET_CANDIDATOS,
  GET_CANDIDATOS_SUCCESS,
  GET_CANDIDATO,
  GET_CANDIDATO_SUCCESS,
  UPDATE_CANDIDATOS_TABLE_STATE,
  UPDATE_CANDIDATOS,
  SIGNOUT_USER_SUCCESS,
  GET_FAVORITE_CANDIDATOS,
  GET_FAVORITE_CANDIDATOS_SUCCESS,
  FAVORITE_CANDIDATO,
  UNFAVORITE_CANDIDATO,
  GET_PARTIAL_CANDIDATO_SUCCESS,
} from "../constants/ActionTypes";
import * as _ from "lodash";
import moment from "moment";

const INIT_STATE: {
  candidatos: any[];
  numCandidatos: number;
  favoriteCandidatos: any[];
  candidatosLoadingState: string;
  lastPartialSync: any;
  tableState: {
    data: any;
    totalItems: number;
    page: number;
    filters: {
      provinciaFilter: string[];
      estadoFilter: string[];
    };
    searchText: string;
  };
} = {
  candidatos: [],
  numCandidatos: 0,
  favoriteCandidatos: [],
  candidatosLoadingState: "unloaded",
  lastPartialSync: moment(),
  tableState: {
    data: [],
    totalItems: 0,
    page: 0,
    filters: {
      provinciaFilter: [],
      estadoFilter: [],
    },
    searchText: "",
  },
};

const employeeReducer = (
  state = INIT_STATE,
  action: { type: string; payload: any }
) => {
  let newCandidatos: any;
  let candidatos: any;
  switch (action.type) {
    case GET_CANDIDATOS:
      return {
        ...state,
        candidatosLoadingState: "loading",
      };
    case GET_CANDIDATOS_SUCCESS:
      newCandidatos = action.payload.partialCandidatos;
      candidatos = [...state.candidatos];
      newCandidatos.forEach((newCandidato: any) => {
        let o: any = state.candidatos.find(
          (o) => (o as any).id === newCandidato.id
        );
        if (!o) {
          candidatos.push(Object.assign({}, newCandidato));
        }
      });
      return {
        ...state,
        candidatos: candidatos,
        numCandidatos: action.payload.numCandidatos,
        candidatosLoadingState: candidatos.length ? "loaded" : "empty",
        lastPartialSync: moment(),
      };
    case GET_FAVORITE_CANDIDATOS:
      return {
        ...state,
      };
    case GET_FAVORITE_CANDIDATOS_SUCCESS:
      newCandidatos = [...action.payload.allFavoriteCandidatos];
      return {
        ...state,
        favoriteCandidatos: newCandidatos,
      };
    case GET_CANDIDATO:
      return {
        ...state,
        candidatosLoadingState: "loading",
      };
    case FAVORITE_CANDIDATO:
      return {
        ...state,
      };
    case UNFAVORITE_CANDIDATO:
      return {
        ...state,
      };
    case GET_CANDIDATO_SUCCESS:
      newCandidatos = action.payload.partialCandidatos;
      candidatos = [...state.candidatos];
      newCandidatos.forEach((newCandidato: any) => {
        let o: any = state.candidatos.find(
          (o) => (o as any).id === newCandidato.id
        );
        newCandidato.fullLoaded = true;
        if (o) {
          candidatos = _.remove(candidatos, (item: any) => {
            return item.id !== newCandidato.id;
          });
          candidatos.push(Object.assign({}, newCandidato));
        } else {
          candidatos.push(Object.assign({}, newCandidato));
        }
      });
      return {
        ...state,
        candidatos: candidatos,
        candidatosLoadingState: candidatos.length ? "loaded" : "empty",
      };
    case GET_PARTIAL_CANDIDATO_SUCCESS:
      newCandidatos = action.payload.partialCandidatos;
      candidatos = [...state.candidatos];
      newCandidatos.forEach((newCandidato: any) => {
        let o: any = state.candidatos.find(
          (o) => (o as any).id === newCandidato.id
        );
        newCandidato.fullLoaded = true;
        if (o) {
          candidatos = _.remove(candidatos, (item: any) => {
            return item.id !== newCandidato.id;
          });
          candidatos.push(Object.assign({}, { ...o, ...newCandidato }));
        } else {
          candidatos.push(Object.assign({}, newCandidato));
        }
      });
      return {
        ...state,
        candidatos: candidatos,
        candidatosLoadingState: candidatos.length ? "loaded" : "empty",
      };
    case UPDATE_CANDIDATOS_TABLE_STATE:
      return {
        ...state,
        tableState: action.payload,
      };
    case UPDATE_CANDIDATOS:
      newCandidatos = action.payload.partialCandidatos;
      candidatos = [...state.candidatos];
      newCandidatos.forEach((newCandidato: any) => {
        newCandidato.fullLoaded = false;
        let o: any = state.candidatos.find(
          (o) => (o as any).id === newCandidato.id
        );
        if (!o) {
          candidatos.push(Object.assign({}, newCandidato));
        }
      });
      return {
        ...state,
        candidatos: candidatos,
        numCandidatos: action.payload.numCandidatos,
        lastPartialSync: moment(),
      };
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        candidatos: [],
      };
    }
    default:
      return state;
  }
};

export default employeeReducer;
