import React from "react";
import styled from "styled-components";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { Typography } from "@material-ui/core";
import { TMessageContent } from "../../types/message.type";
import { fileNameFromUrl } from "../../util/message.util";

const StyledMediaContent = styled.div``;

const StyledImage = styled.img`
  display: block;
  max-height: 20rem;
  width: auto;
  max-width: 30rem;
`;

const StyledVideo = styled.video`
  display: block;
  max-height: 20rem;
  width: auto;
  max-width: 30rem;
`;

const StyledAudio = styled.audio`
  display: block;
  max-height: 20rem;
  width: 20rem;
  max-width: 30rem;
`;

const StyledDocumentWrapper = styled.div`
  height: 3rem;
  min-width: 20rem;
  background-color: rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledIconWrapper = styled.div`
  padding: 0.25rem 0.75rem;
`;

const StyledFilename = styled(Typography)`
  font-size: 0.9375rem;
`;

interface IMessageMediaContentProps {
  content: Pick<TMessageContent, "audio" | "image" | "video" | "file">;
}

const MessageMediaContent: React.VFC<IMessageMediaContentProps> = (props) => {
  const { content } = props;

  return (
    <StyledMediaContent>
      {!!content.image && <StyledImage src={content.image.url} />}
      {!!content.video && <StyledVideo src={content.video.url} controls />}
      {!!content.audio && <StyledAudio src={content.audio.url} controls />}
      {!!content.file && (
        <StyledDocumentWrapper>
          <StyledIconWrapper>
            <InsertDriveFileIcon />
          </StyledIconWrapper>
          <StyledFilename>
            {content.file.caption ?? fileNameFromUrl(content.file.url)}
          </StyledFilename>
        </StyledDocumentWrapper>
      )}
    </StyledMediaContent>
  );
};

export default MessageMediaContent;
