import React, { Fragment } from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import "moment/locale/es";
import { TGetContractsCollectionItem } from "../../../api/contract/contract.types";

const StyledPublished = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledNotPublished = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledNullPublished = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledPublishedCircle = styled.div`
  background-color: rgba(126, 211, 33, 1);
  width: 0.6875rem;
  height: 0.6875rem;
  border-radius: 50%;
  margin-right: 0.25rem;
`;

const StyledNotPublishedCircle = styled.div`
  background-color: rgba(255, 0, 0, 1);
  width: 0.6875rem;
  height: 0.6875rem;
  border-radius: 50%;
  margin-right: 0.5rem;
`;

const StyledNullPublishedCircle = styled.div`
  background-color: #ccc;
  width: 0.6875rem;
  height: 0.6875rem;
  border-radius: 50%;
  margin-right: 0.5rem;
`;

const StyledPublishedText = styled(Typography)`
  font-size: 0.6875rem;
  line-height: 1;
  margin: 0;
  color: rgba(126, 211, 33, 1);
`;

const StyledNotPublishedText = styled(Typography)`
  font-size: 0.6875rem;
  line-height: 1;
  margin: 0;
  color: rgba(255, 0, 0, 1);
`;

const StyledNullPublishedText = styled(Typography)`
  font-size: 0.6875rem;
  line-height: 1;
  margin: 0;
  color: #ccc;
`;

interface IPublishedStatus {
  contrato: Pick<
    TGetContractsCollectionItem,
    "Publicar" | "Fecha_publicacion_oferta"
  >;
}

const PublishedStatus = (props: IPublishedStatus) => {
  const { contrato } = props;
  return (
    <Fragment>
      {contrato.Publicar ? (
        <Fragment>
          {contrato.Publicar === "Si" ? (
            <StyledPublished className="MuiContrato-status MuiContrato-status-published">
              <StyledPublishedCircle className="MuiContrato-status-circle" />
              <StyledPublishedText className="MuiContrato-status-text">
                <FormattedMessage
                  id="ContractCard.Publicada: {fechaPub}"
                  defaultMessage="Publicada: {fechaPub}"
                  values={{
                    fechaPub:
                      moment(contrato.Fecha_publicacion_oferta).format(
                        "DD/MM HH:mm"
                      ) + "h",
                  }}
                />
              </StyledPublishedText>
            </StyledPublished>
          ) : (
            <StyledNotPublished className="MuiContrato-status MuiContrato-status-notpublished">
              <StyledNotPublishedCircle className="MuiContrato-status-circle" />
              <StyledNotPublishedText className="MuiContrato-status-text">
                <FormattedMessage
                  id="Publish.Despublicada"
                  defaultMessage="Despublicada"
                />
              </StyledNotPublishedText>
            </StyledNotPublished>
          )}
        </Fragment>
      ) : (
        <StyledNullPublished className="MuiContrato-status MuiContrato-status-nullpublished">
          <StyledNullPublishedCircle className="MuiContrato-status-circle" />
          <StyledNullPublishedText className="MuiContrato-status-text">
            <FormattedMessage
              id="Publish.Sin publicar"
              defaultMessage="Sin publicar"
            />
          </StyledNullPublishedText>
        </StyledNullPublished>
      )}
    </Fragment>
  );
};

export default PublishedStatus;
