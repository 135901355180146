import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import styled from "styled-components";
import cuideoTheme from "../../containers/themes/defaultTheme";
import { Grid, Typography } from "@material-ui/core";
import { JOB_SIGN_IN } from "../../constants/routes";

const StyledWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3rem;
  background-color: #f5e07f;
  padding: 0.75rem 1rem;
  box-sizing: border-box;
  z-index: 1;
`;

const StyledTypography = styled(Typography)`
  font-size: 1rem;
  line-height: 1.15;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  padding: 0 1rem;
  font-size: 1rem;
  line-height: 1.25;
  color: ${cuideoTheme.palette.primary.main};
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;

  &:last-child {
    border-left: 1px solid rgba(0, 76, 120, 0.25);
  }
`;

const NoTienesCuenta = (props: any) => {
  return (
    <StyledWrapper>
      <Grid container alignItems="center" spacing={4}>
        <Grid item xs={6}>
          <StyledTypography color="primary">
            <FormattedMessage id="¿No tienes cuenta?" />
          </StyledTypography>
        </Grid>
        <Grid item xs={6}>
          <StyledLink to="/registroCuidador">
            <strong>
              <FormattedMessage id="Registrate" />
            </strong>
          </StyledLink>
          <StyledLink to={JOB_SIGN_IN}>
            <strong>
              <FormattedMessage id="Entrar" />
            </strong>
          </StyledLink>
        </Grid>
      </Grid>
    </StyledWrapper>
  );
};

export default NoTienesCuenta;
