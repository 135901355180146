import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const StyledAccordion = styled(Accordion)`
  .MuiAccordionSummary-root {
    border-bottom: 1px solid #ebecf0;
    min-height: 2rem;
    .MuiAccordionSummary-content {
      margin: 0.25rem 0 0.25rem 0.25rem;
    }
    .MuiAccordionSummary-expandIcon {
      margin: 0.25rem 0 0.25rem 0;
    }
    .MuiTypography-h4 {
      font-size: 1rem;
      color: #002740;
    }
    .MuiSvgIcon-root {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
  .MuiAccordionDetails-root {
    padding: 0.5rem;
    display: block;
    .MuiTypography-root {
      font-size: 0.875rem;
    }
  }

  .MuiFormLabel-root {
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
  .MuiCheckbox-root {
    padding: 0.5rem;

    .MuiSvgIcon-root {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
`;

interface IInfoBoxProps {
  summary: React.ReactNode;
  details: React.ReactNode;
}

const InfoBox = (props: IInfoBoxProps) => {
  const { summary, details } = props;
  return (
    <StyledAccordion elevation={0} defaultExpanded className="CMuiInfoBox">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {summary}
      </AccordionSummary>
      <AccordionDetails>{details}</AccordionDetails>
    </StyledAccordion>
  );
};

export default InfoBox;
