import { apiEmpleo } from "../../util/ApiEmpleo";

export interface IChangeServiceOwnerRequest {
  Owner: string;
  ownerAssignedAt: string;
  ownerName: string;
}

const apiChangeServiceOwner = async (
  serviceId: string,
  request: IChangeServiceOwnerRequest
) => {
  if (request.Owner) {
    request.Owner = `/api/users/${request.Owner}`;
  }

  let res = await apiEmpleo.patch(`services/${serviceId}`, request, {
    headers: {
      "Content-Type": "application/merge-patch+json",
    },
  });

  return res;
};

export { apiChangeServiceOwner };
