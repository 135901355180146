import {
  apiListConversationMessages,
  IListConversationsMessagesParams,
} from "../../../../../apiCoreV3/conversation/apiListConversationMessages";
import { useQuery } from "react-query";
import {
  ExtractFnReturnType,
  QueryConfig,
} from "../../../../../lib/react-query";
import { convertMessageResponsesToMessages } from "../util/message.util";

export const getConversationMessages = async (
  conversationId: string,
  // remoteContactId: string,
  params: IListConversationsMessagesParams = {}
) => {
  const res = await apiListConversationMessages(conversationId, {
    orderBy: "createdAt",
    order: "desc",
    ...params,
  });

  return {
    messages: convertMessageResponsesToMessages(
      res.data.reverse()
      // remoteContactId
    ),
    totalItems: res.info.count_all ?? 0,
  };
};

type QueryFnType = typeof getConversationMessages;

type UseConversationMessagesOptions = {
  conversationId: string | null;
  // remoteContactId: string;
  params?: IListConversationsMessagesParams;
  config?: QueryConfig<QueryFnType>;
};

export const useConversationMessages = ({
  conversationId,
  // remoteContactId,
  params = {},
  config,
}: UseConversationMessagesOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["conversation-messages", "list", conversationId],
    queryFn: () => getConversationMessages(conversationId ?? "", params),
  });
};
