import {
  GET_VALORACIONES_SUCCESS,
  GET_VALORACIONES,
  GET_VALORACION,
  GET_VALORACION_SUCCESS,
  UPDATE_VALORACIONES_TABLE_STATE,
  UPDATE_VALORACIONES,
  UPDATE_MIS_VALORACIONES,
  GET_MIS_VALORACIONES,
  RESET_MIS_VALORACIONES
} from "../constants/ActionTypes";

export const getValoraciones = ({
  params
}: {
  params: { rowsPerPage: number; page: number };
}) => {
  return {
    type: GET_VALORACIONES,
    payload: params
  };
};

export const getValoracionesSuccess = ({
  partialValoraciones,
  numValoraciones
}: {
  partialValoraciones: any[];
  numValoraciones: number;
}) => {
  return {
    type: GET_VALORACIONES_SUCCESS,
    paylñoad: {
      partialValoraciones,
      numValoraciones
    }
  };
};

export const getValoracion = (valoracionId: string) => {
  return {
    type: GET_VALORACION,
    payload: valoracionId
  };
};

export const getValoracionSuccess = ({
  partialValoraciones
}: {
  partialValoraciones: any[];
}) => {
  return {
    type: GET_VALORACION_SUCCESS,
    payload: { partialValoraciones }
  };
};

export const updateValoracionesTableState = (tableState: any) => {
  return {
    type: UPDATE_VALORACIONES_TABLE_STATE,
    payload: tableState
  };
};

export const updateValoraciones = (
  partialValoraciones: any,
  numValoraciones?: number
) => {
  return {
    type: UPDATE_VALORACIONES,
    payload: {
      partialValoraciones,
      numValoraciones: numValoraciones
        ? numValoraciones
        : partialValoraciones.length
    }
  };
};

export const getMisValoraciones = (userId: string) => {
  return {
    type: GET_MIS_VALORACIONES,
    payload: userId
  };
};

export const updateMisValoraciones = (partialValoraciones: any) => {
  return {
    type: UPDATE_MIS_VALORACIONES,
    payload: partialValoraciones
  };
};

export const resetMisValoraciones = () => {
  return {
    type: RESET_MIS_VALORACIONES
  };
};
