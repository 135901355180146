export type TStaffAssignationsContractStats = {
  total: number;
  pendingSignUp: {
    total: number;
  };
  awaiting: {
    total: number;
  };
  cvClient: {
    total: number;
  };
  payAndReserve: {
    total: number;
  };
  clientFaceToFace: {
    total: number;
  };
};

export const newStaffAssignationsContractStats =
  (): TStaffAssignationsContractStats => {
    return {
      total: 0,
      pendingSignUp: {
        total: 0,
      },
      awaiting: {
        total: 0,
      },
      cvClient: {
        total: 0,
      },
      payAndReserve: {
        total: 0,
      },
      clientFaceToFace: {
        total: 0,
      },
    };
  };
