import { motion, Variants } from "framer-motion";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import styled from "styled-components";
import { getCandidato, setBackground } from "../../../../../../actions";
import cuideoTheme from "../../../../../../containers/themes/defaultTheme";
import useBackground from "../../../../../../util/useBackground";
import useFooter from "../../../../../../util/useFooter";
import useHeader from "../../../../../../util/useHeader";
import usePageTitle from "../../../../../../util/usePageTitle";
import CandidatoFullProfile from "../../../../components/CandidatoFullProfile";
import CandidatosMultiSelectFooter from "../../../../components/CandidatosMultiSelectFooter";
import CandidatosFilters from "../../../../components/EmployeesFilters/EmployeesFilters";
import CandidatosList from "../../components/CandidatesList/CandidatesList";
import CandidatosMap from "../../../../components/EmployeesMap";
import VariableContainer from "../../../../components/VariableContainer";
import useEmployeesPageURLParams from "./useEmployeesPageURLParams";
import { useEmployeesQuery } from "./useEmployeesQuery";
import { TOnCandidateChangeFn } from "../../types/candidate.types";
import { EMPLOYEE_STATUS } from "../../../../../../api/employee/employee.types";

const ITEMS_ON_MAP = 100;
const ITEMS_PER_PAGE = 10;

const StyledPageContainer = styled.div<{ appMarginTop: number }>`
  height: 100vh;
  overflow-y: hidden;

  ${({ appMarginTop }) => {
    return `
    margin: ${appMarginTop}rem auto 0;
  `;
  }}

  @media all and (max-width: ${cuideoTheme.breakpoints.values.sm}px) {
    padding: 0;
    background-color: ${cuideoTheme.palette.common.white};
    margin: ${({ appMarginTop }) => appMarginTop}rem 0 0;
  }

  @media all and (min-width: ${cuideoTheme.breakpoints.values
      .md}px) and (max-width: ${cuideoTheme.breakpoints.values.lg}px) {
    margin: ${({ appMarginTop }) => appMarginTop}rem 0 0;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.lg}px) {
    margin: ${({ appMarginTop }) => appMarginTop}rem 0 0;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
  }
`;

const StyledCandidatosListPane = styled.div`
  position: relative;
`;

const StyledMapPane = styled.div`
  position: relative;
`;

const StyledCandidatoPane = styled.div`
  position: relative;

  overflow-y: hidden;
  width: calc(100vw - 32rem);
`;

const StyledCandidatoContainer = styled.div`
  height: 100%;
`;

const listVariants: Variants = {
  enter: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05,
      delay: 0.1,
    },
  },
  exit: {
    opacity: 0,
  },
  loading: {
    opacity: 0.25,
    transition: { duration: 0.1 },
  },
};

const mapVariants: Variants = {
  enter: {
    opacity: 1,
    transition: { duration: 0.25, delay: 0.4 },
    x: 0,
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.1 },
    x: 15,
  },
};

const CandidatesRoute = (props: any) => {
  const { location, history, getCandidato, candidatos } = props;

  const [candidatosView, setCandidatosView] = useState([] as any[]);
  const [pagedCandidatos, setPagedCandidatos] = useState([] as any[]);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedEmployee, setSelectedEmployee] = useState(null as any);
  const [hoveredCandidato, setHoveredCandidato] = useState(null as any);
  const [selectedCandidatos, setSelectedCandidatos] = useState([] as any[]);
  const [selectAll, setSelectAll] = useState(false);
  const [map, setMap] = useState({} as any);
  const intl = useIntl();

  useHeader({
    type: "hidden",
  });
  useFooter({
    type: "hidden",
  });
  useBackground("cuideo");
  usePageTitle(
    intl.formatMessage({
      id: "EmployeesPage.Employees",
      defaultMessage: "Candidatos",
    })
  );

  const { filters, setFilters } = useEmployeesPageURLParams(
    window.location.search,
    history,
    location
  );

  useEffect(() => {
    setBackground("cuideo");
  }, []);

  const { data, isLoading, refetch } = useEmployeesQuery(
    filters,
    filters.ready,
    ITEMS_ON_MAP
  );

  useEffect(() => {
    if (!data) {
      return;
    }

    if (data.employees) {
      if (data.page === 1) {
        setCandidatosView(data.employees);
        setPagedCandidatos(data.employees.slice(0, 10));
      } else {
        setCandidatosView((prevCandidatos: any) => {
          const newMapEmployees = _.unionBy(
            prevCandidatos,
            data.employees,
            "id"
          );

          return newMapEmployees;
        });
        setPagedCandidatos(data.employees);
      }
    }
    if (typeof data.totalItems === "number") {
      setTotalItems(data.totalItems);
    }
  }, [data, filters.page]);

  useEffect(() => {
    const asyncOp = async () => {
      if (filters.enMapa === "si") {
        if (filters.y && filters.x && filters.z) {
          setMap({
            ...map,
            center: {
              lat: parseFloat(filters.y as any),
              lng: parseFloat(filters.x as any),
            },
            zoom: parseFloat(filters.z as any),
            bounds: undefined as any,
            marginBounds: undefined as any,
            size: undefined as any,
            autoFocus: false,
            loadOnChange: false,
            initialized: true,
          });
        }
      } else {
        setMap({
          ...map,
          center: {
            lat: map.center && map.center.lat ? map.center.lat : 39.4788,
            lng: map.center && map.center.lng ? map.center.lng : -2.1863,
          },
          zoom: map.zoom ? map.zoom : 6,
          bounds: undefined as any,
          marginBounds: undefined as any,
          autoFocus: true,
          loadOnChange: false,
          initialized: true,
        });
      }
    };

    if (filters.ready && !isLoading) {
      asyncOp();
    }
    // eslint-disable-next-line
  }, [location, filters, isLoading]);

  useEffect(() => {
    if (filters.selectedEmployeeId) {
      const foundEmployee = pagedCandidatos.find(
        (emp: any) => emp.id === filters.selectedEmployeeId
      );
      if (
        !selectedEmployee ||
        selectedEmployee.id !== filters.selectedEmployeeId
      ) {
        if (foundEmployee) {
          setSelectedEmployee({ ...foundEmployee });
          getCandidato(filters.selectedEmployeeId);
        } else {
          const foundInGeneral = candidatos.find((item: any) => {
            if (item.id === filters.selectedEmployeeId) {
              return item;
            }
            return false;
          });
          if (foundInGeneral) {
            setSelectedEmployee({ ...foundInGeneral });
          } else {
            getCandidato(filters.selectedEmployeeId);
          }
        }
      }
    } else {
      if (selectedEmployee) {
        setSelectedEmployee(null);
      }
    }
    // eslint-disable-next-line
  }, [candidatos, pagedCandidatos, filters.selectedEmployeeId]);

  const handlePageChange = (event: any, value: any) => {
    setSelectedCandidatos([]);
    const newFilters = { ...filters, page: value };
    setFilters(newFilters);
  };

  const handleChange: TOnCandidateChangeFn = (op, changedCandidate) => {
    if (op === "discard") {
      setCandidatosView((prevCandidatosView: any) => {
        const newItems = prevCandidatosView.map((item: any) => {
          if (item.id === changedCandidate.id) {
            const newItem = Object.assign({}, item);
            newItem.Estado_Cuideo = EMPLOYEE_STATUS.DISCARDED;
            return newItem;
          } else {
            return item;
          }
        });
        return newItems;
      });
    } else if (op === "fav") {
      if (filters.selectedEmployeeId) {
        getCandidato(filters.selectedEmployeeId);
      }
    }

    refetch();
  };

  const handleEmployeeProfileChange = () => {
    refetch();
    if (filters.selectedEmployeeId) {
      getCandidato(filters.selectedEmployeeId);
    }
  };

  const handleMultiSelectChange = useCallback(() => {
    refetch();
    setSelectedCandidatos([]);
  }, [refetch, setSelectedCandidatos]);

  const handleSingleSelection = (candidato: any) => {
    // Check if Selection is on current page and if not we must
    // change page in order to show on the list
    const pos = candidatosView
      .map((item: any) => item.id)
      .indexOf(candidato.id);
    if (pos !== -1) {
      const newPage = Math.floor(pos / ITEMS_PER_PAGE) + 1;
      setFilters({
        ...filters,
        selectedEmployeeId: candidato.id,
        page: newPage,
      });
    } else {
      setFilters({ ...filters, selectedEmployeeId: candidato.id });
    }
  };

  const handleMapChange = (changedValues: any) => {
    if (changedValues.autoFocus) {
      setMap({
        ...changedValues,
        autoFocus: false,
      });
      if (changedValues._setInMap && filters.enMapa !== "si") {
        setFilters({
          ...filters,
          enMapa: "si",
        });
      }
    } else {
      if (
        filters.enMapa === "si" &&
        (filters["latitud[gt]"] !== changedValues.bounds.se.lat ||
          filters["latitud[lt]"] !== changedValues.bounds.nw.lat)
      ) {
        setFilters({
          ...filters,
          page: 1,
          "latitud[gt]": changedValues.bounds.se.lat,
          "latitud[lt]": changedValues.bounds.nw.lat,
          "longitud[lt]": changedValues.bounds.se.lng,
          "longitud[gt]": changedValues.bounds.nw.lng,
          x: changedValues.center.lng,
          y: changedValues.center.lat,
          z: changedValues.zoom,
        });
      }
    }
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectAll(false);
      setSelectedCandidatos([]);
    } else {
      setSelectAll(true);
      setSelectedCandidatos([...candidatosView]);
    }
  };

  const handleUnselectAll = useCallback(() => {
    setSelectedCandidatos([]);
  }, [setSelectedCandidatos]);

  const handleCandidatoMultiSelect = (candidato: any) => {
    if (
      selectedCandidatos.filter((selCand: any) => selCand.id === candidato.id)
        .length
    ) {
      const filtered = selectedCandidatos.filter(
        (cand: any) => cand.id !== candidato.id
      );
      setSelectedCandidatos([...filtered]);
    } else {
      setSelectedCandidatos([...selectedCandidatos, candidato]);
    }
  };

  const handleCandidatoHover = (candidato: any) => {
    setHoveredCandidato(candidato);
  };

  const handleCandidatoSelect = (candidato: any) => {
    handleSingleSelection(candidato);
  };

  const handleTabChange = useCallback(
    (newValue: number) => {
      const newFilters = { ...filters, eTab: newValue };
      setFilters(newFilters);
    },
    [filters, setFilters]
  );

  const choosenCandidato = selectedEmployee
    ? candidatos.find((item: any) => {
        if (item.id === selectedEmployee.id) {
          return item;
        }
        return false;
      })
    : null;

  if (!filters.ready) {
    return null;
  }

  return (
    <StyledPageContainer appMarginTop={0}>
      <CandidatosFilters filters={filters} setFilters={setFilters} />
      <VariableContainer>
        <StyledCandidatosListPane
          style={{
            minWidth: "28rem",
          }}
        >
          <motion.div
            variants={listVariants}
            initial="exit"
            animate={
              !selectedEmployee || filters.vista === "lista" ? "enter" : "exit"
            }
            style={{
              position: "absolute",
              height: "100%",
            }}
          >
            <CandidatosList
              isLoading={isLoading}
              candidatos={pagedCandidatos}
              totalCandidatos={totalItems}
              selectedCandidato={selectedEmployee}
              selectedCandidatos={selectedCandidatos}
              selectedAll={selectAll}
              onToggleSelectAll={handleSelectAllChange}
              onCandidatoSelect={handleSingleSelection}
              // onCandidatoChange={handleChange}
              onToggleCandidatoMultiSelect={handleCandidatoMultiSelect}
              page={filters.page}
              onPageChange={handlePageChange}
              itemsPerPage={ITEMS_PER_PAGE}
              mutatingCandidatos={[]}
              onCandidatoHover={handleCandidatoHover}
              hoveredCandidato={hoveredCandidato}
            />
          </motion.div>
        </StyledCandidatosListPane>
        <StyledMapPane
          style={{
            width:
              selectedEmployee && filters.vista === "mapa"
                ? "28rem"
                : selectedEmployee && filters.vista === "lista"
                ? "0"
                : "calc(100% - 28rem)",
          }}
        >
          <motion.div
            variants={mapVariants}
            initial="exit"
            animate="enter"
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
          >
            <CandidatosMap
              candidatos={candidatosView}
              selectedCandidato={selectedEmployee}
              hoveredCandidato={hoveredCandidato}
              onCandidatoSelect={handleCandidatoSelect}
              onCandidatoHover={handleCandidatoHover}
              onMapChange={handleMapChange}
              map={map}
              showMaxWarning={
                !selectedEmployee && candidatosView.length >= ITEMS_ON_MAP
              }
              showLoading={isLoading}
              allowMapSearch
            />
          </motion.div>
        </StyledMapPane>

        <StyledCandidatoPane
          style={{
            width: !choosenCandidato ? "0" : "calc(100% - 28rem)",
          }}
        >
          <StyledCandidatoContainer>
            {selectedEmployee && selectedEmployee.id && (
              <CandidatoFullProfile
                candidato={
                  choosenCandidato &&
                  choosenCandidato.id === selectedEmployee.id
                    ? choosenCandidato
                    : selectedEmployee
                }
                onUnselect={() => {
                  setFilters({ ...filters, selectedEmployeeId: "" });
                }}
                onChange={handleChange}
                tab={filters.eTab}
                onTabChange={handleTabChange}
                onEmployeeProfileChange={handleEmployeeProfileChange}
              />
            )}
          </StyledCandidatoContainer>
        </StyledCandidatoPane>
      </VariableContainer>
      <CandidatosMultiSelectFooter
        onChange={handleMultiSelectChange}
        candidatos={selectedCandidatos}
        onUnselectAll={handleUnselectAll}
      />
    </StyledPageContainer>
  );
};

const mapStateToProps = ({
  layout,
  filters,
  candidatosState,
}: {
  layout: any;
  filters: any;
  candidatosState: any;
}) => {
  const { appMarginTop } = layout;
  const { candidatosFilters } = filters;
  const { candidatos } = candidatosState;
  return { appMarginTop, candidatosFilters, candidatos };
};

export default connect(mapStateToProps, {
  setBackground,
  getCandidato,
  // filtersSetCandidatos,
})(CandidatesRoute);
