import {
  GET_ACTIVIDADES,
  GET_ACTIVIDADES_SUCCESS,
} from "../constants/ActionTypes";

export const ACTIVIDAD_REGISTRO_CANDIDATO = "REGISTRO_CANDIDATO";
export const ACTIVIDAD_APLICA_OFERTA_CANDIDATO = "APLICA_OFERTA_CANDIDATO";
export const ACTIVIDAD_DESAPLICA_OFERTA_CANDIDATO =
  "DESAPLICA_OFERTA_CANDIDATO";
export const ACTIVIDAD_VER_PAGINA = "VER_PAGINA";
export const ACTIVIDAD_RESET_PASSWORD = "RESET_PASSWORD";
export const ACTIVIDAD_CAMBIA_MIS_DATOS = "CAMBIA_MIS_DATOS";
export const ACTIVIDAD_MODIFICA_CANDIDATO = "MODIFICA_CANDIDATO";
export const ACTIVIDAD_PUBLICA_OFERTA = "PUBLICA_OFERTA";
export const ACTIVIDAD_DESPUBLICA_OFERTA = "DESPUBLICA_OFERTA";
export const ACTIVIDAD_USER_SIGNIN_SUCCESS = "LOGIN SUCCESS";
export const ACTIVIDAD_USER_SIGNIN_ERROR = "LOGIN ERROR";
export const ACTIVIDAD_MODIFICA_CONTRATO = "MODIFICA_CONTRATO";

const INIT_STATE: {
  actividades: any[];
  numActividades: number;
} = {
  actividades: [],
  numActividades: 0,
};

// eslint-disable-next-line
export default (state = INIT_STATE, action: { type: string; payload: any }) => {
  switch (action.type) {
    case GET_ACTIVIDADES:
      return state;
    case GET_ACTIVIDADES_SUCCESS:
      const newActividades = action.payload.partialActividades;
      let actividades: any = [...state.actividades];
      newActividades.forEach((newActividad: any) => {
        let o: any = state.actividades.find(
          (o) => (o as any).id === newActividad.id
        );
        if (!o) {
          actividades.push(Object.assign({}, newActividad));
        }
      });
      return {
        ...state,
        actividades: actividades,
        numActividades: action.payload.numActividades,
      };
    default:
      return state;
  }
};
