import { useQuery } from "react-query";
import { apiListContractsNumbers } from "../api/contract/apiListContractsNumbers";

export default function useContractsNumbersQuery(contractIds: string[]) {
  return useQuery(
    ["contractNumbers", contractIds],
    async () => {
      let res = await apiListContractsNumbers({
        id: contractIds,
      });

      return res["hydra:member"];
    },
    {
      enabled: contractIds.length > 0,
    }
  );
}
