import { AxiosResponse } from "axios";
import { apiCoreV3 } from "../apiCoreV3";
import { TCandidateResponse } from "./apiCandidate.types";

const apiGetCandidate = async (candidateIdOrCrmId: string) => {
  const res: AxiosResponse<TCandidateResponse> = await apiCoreV3.get(
    `/employee/${candidateIdOrCrmId}`
  );

  return res.data;
};

export { apiGetCandidate };
