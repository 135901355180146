import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import {
  EMPLOYEE_WA_STATUS,
  TEmployeeWaStatus,
} from "../../../../api/employee/employeeApiTypes";
import {
  COLOR_RED_NOTIFICATION,
  COLOR_SUCCESS,
} from "../../../../containers/themes/defaultTheme";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import CheckIcon from "@material-ui/icons/Check";
import { Tooltip, Typography } from "@material-ui/core";

const StyledTag = styled.div`
  padding: 0.125rem 0.25rem;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;

  .MuiSvgIcon-root {
    width: 1rem;
    height: 1rem;
    margin-right: 0.25rem;
  }

  &.CMuiPhoneWasStatusTag-notRegistered {
    background-color: ${COLOR_RED_NOTIFICATION};
    color: #fff;
  }

  &.CMuiPhoneWasStatusTag-sentAndAnswer {
    background-color: ${COLOR_SUCCESS};
    color: #fff;
  }

  &.CMuiPhoneWasStatusTag-sentRead {
    background-color: ${COLOR_SUCCESS};
    color: #fff;
  }

  &.CMuiPhoneWasStatusTag-sentDelivered {
    background-color: ${COLOR_SUCCESS};
    color: #fff;
  }
`;

const StyledTagText = styled(Typography)`
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1;
`;

interface IPhoneWaStatusTagProps {
  waStatus: TEmployeeWaStatus;
}

const PhoneWaStatusTag = (props: IPhoneWaStatusTagProps) => {
  const { waStatus } = props;

  if (waStatus === EMPLOYEE_WA_STATUS.UNKNOWN) {
    return null;
  }

  if (waStatus === EMPLOYEE_WA_STATUS.ERROR_UNREGISTERED) {
    return (
      <Tooltip
        title={
          <FormattedMessage
            id="PhoneWaStatusTag.El número de teléfono aparece como no registrado en WhatsApp después de intentar contactar con él. No contactable vía WhatsApp/Bot"
            defaultMessage="El número de teléfono aparece como no registrado en WhatsApp después de intentar contactar con él. No contactable vía WhatsApp/Bot"
          />
        }
      >
        <StyledTag className="CMuiPhoneWaStatusTag CMuiPhoneWasStatusTag-notRegistered">
          <WhatsAppIcon />
          <StyledTagText>
            <FormattedMessage
              id="PhoneWaStatusTag.No registrado"
              defaultMessage="No registrado"
            />
          </StyledTagText>
        </StyledTag>
      </Tooltip>
    );
  }

  if (waStatus === EMPLOYEE_WA_STATUS.SENT_AND_ANSWER) {
    return (
      <Tooltip
        title={
          <FormattedMessage
            id="PhoneWaStatusTag.Hemos contactado y tenido respuesta desde el número de teléfono indicado."
            defaultMessage="Hemos contactado y tenido respuesta desde el número de teléfono indicado."
          />
        }
      >
        <StyledTag className="CMuiPhoneWaStatusTag CMuiPhoneWasStatusTag-sentAndAnswer">
          <SwapHorizIcon />
          <StyledTagText>
            <FormattedMessage
              id="PhoneWaStatusTag.Activo"
              defaultMessage="Activo"
            />
          </StyledTagText>
        </StyledTag>
      </Tooltip>
    );
  }

  if (waStatus === EMPLOYEE_WA_STATUS.SENT_READ) {
    return (
      <Tooltip
        title={
          <FormattedMessage
            id="PhoneWaStatusTag.Hemos contactado y recibido confirmación de que recibe mensajes vía WhatsApp pero no hemos recibido ninguna respuesta vía Bot."
            defaultMessage="Hemos contactado y recibido confirmación de que recibe mensajes vía WhatsApp pero no hemos recibido ninguna respuesta vía Bot."
          />
        }
      >
        <StyledTag className="CMuiPhoneWaStatusTag CMuiPhoneWasStatusTag-sentRead">
          <CheckIcon />
          <StyledTagText>
            <FormattedMessage
              id="PhoneWaStatusTag.Lee mensajes"
              defaultMessage="Lee mensajes"
            />
          </StyledTagText>
        </StyledTag>
      </Tooltip>
    );
  }

  if (waStatus === EMPLOYEE_WA_STATUS.SENT_DELIVERED) {
    return (
      <Tooltip
        title={
          <FormattedMessage
            id="PhoneWaStatusTag.Hemos enviado mensajes vía Bot pero no hemos recibido confirmación de que se hayan leído."
            defaultMessage="Hemos enviado mensajes vía Bot pero no hemos recibido confirmación de que se hayan leído."
          />
        }
      >
        <StyledTag className="CMuiPhoneWaStatusTag CMuiPhoneWasStatusTag-sentDelivered">
          <CheckIcon />
          <StyledTagText>
            <FormattedMessage
              id="PhoneWaStatusTag.Se puede enviar WA"
              defaultMessage="Se puede enviar WA"
            />
          </StyledTagText>
        </StyledTag>
      </Tooltip>
    );
  }

  return null;
};

export default PhoneWaStatusTag;
