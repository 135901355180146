import { apiEmpleo } from "../../util/ApiEmpleo";

export type TApiUpdateUserOnShiftPayload = {
  userId: string;
  onShift: boolean;
};

export const apiUpdateUserOnShift = async ({
  userId,
  onShift,
}: TApiUpdateUserOnShiftPayload) => {
  return apiEmpleo.post(`users/${userId}/on-shift`, {
    onShift,
  });
};
