import { AxiosResponse } from "axios";
import { apiCoreV3 } from "../apiCoreV3";

export type TCustomerNewestEmployerResponse = {
  id: string;
  firstname: string;
  surname: string;
  lastname: string;
  completed: boolean;
}

export const apiCustomerNewestEmployer = async (customerId: string) => {
  const res: AxiosResponse<TCustomerNewestEmployerResponse> =
    await apiCoreV3.get(
      `customer/${customerId}/employer`
    );

  return res.data;
}
