import React from "react";
import styled from "styled-components";
import { Tooltip } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import cuideoTheme from "../../containers/themes/defaultTheme";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

const StyledWrapper = styled.span`
  color: ${cuideoTheme.palette.primary.main};
  font-weight: bold;
  display: inline-block;
  vertical-align: top;
  height: 1rem;

  .MuiSvgIcon-root {
    width: 1rem;
    height: 1rem;
  }
`;

const VisibleFieldOnProfile = () => {
  return (
    <StyledWrapper>
      <Tooltip
        title={
          <FormattedMessage
            id="VisibleFieldOnProfile.VisibleOnProfile"
            defaultMessage="Visible en la ficha del candidato"
          />
        }
      >
        <AccountCircleIcon />
      </Tooltip>
    </StyledWrapper>
  );
};

export default VisibleFieldOnProfile;
