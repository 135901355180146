import mainMessages from "../i18n/main/es.json";
import settingsMessages from "../i18n/settings/es.json";
import affinityMessages from "../i18n/affinity/es.json";
import jobsiteMessages from "../i18n/jobsite/es.json";
import coordinatorMessages from "../i18n/coordinator/es.json";

const saLang = {
  messages: {
    ...mainMessages,
    ...affinityMessages,
    ...jobsiteMessages,
    ...settingsMessages,
    ...coordinatorMessages,
  },
  locale: "es",
};
export default saLang;
