import React from "react";
import styled from "styled-components";
import { TUserGroupAssignations } from "../../../../../recruitersManager/types/UserGroupAssignations.types";
import GroupAssignationsItem from "./GroupAssignationsItem";

const StyledWrapper = styled.div``;

const StyledItemWrapper = styled.div`
  border-bottom: 1px solid rgb(239, 239, 239);

  &.CMuiSelected {
    .CMuiCoordinatorAssignationsItem {
      background-color: rgb(235, 235, 235);
    }
  }
`;

interface IGroupAssignationsListProps {
  groups: TUserGroupAssignations[];
  onSelectOwner: (ownerId: string | null) => void;
  selectedOwnerId: string | null;
  maxGroupServices: number;
  maxGroupContracts: number;
}

const GroupAssignationsList = (props: IGroupAssignationsListProps) => {
  const {
    groups,
    onSelectOwner,
    selectedOwnerId,
    maxGroupContracts,
    maxGroupServices,
  } = props;

  return (
    <StyledWrapper>
      {groups.map((group) => (
        <StyledItemWrapper key={`as_gr_${group.id}`}>
          <GroupAssignationsItem
            group={group}
            onSelectOwner={onSelectOwner}
            selectedOwnerId={selectedOwnerId}
            maxServices={maxGroupServices}
            maxContracts={maxGroupContracts}
          />
        </StyledItemWrapper>
      ))}
    </StyledWrapper>
  );
};

export default GroupAssignationsList;
