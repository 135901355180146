import { Divider, Grid, Typography } from "@material-ui/core";
import { push } from "connected-react-router";
import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { userSignOut } from "../actions/Auth";
import ColabE from "../assets/images/colab-e-certificada.png";
import ColabMT from "../assets/images/colab-ministerio-trabajo.png";
import ColabSNE from "../assets/images/colab-sne.png";
import FacebookIcon from "../assets/images/iconos/facebook.svg";
import LinkedInIcon from "../assets/images/iconos/linked-in.svg";
import TwitterIcon from "../assets/images/iconos/twitter.svg";
import Logo from "../assets/images/logo.svg";
import ColabStripe from "../assets/images/stripe-secure.png";
import cuideoTheme from "./themes/defaultTheme";
import { JOB_OFFERS, JOB_SIGN_IN } from "../constants/routes";

const StyledFooter = styled.div`
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
`;

const StyledBlueFooter = styled.div`
  background-color: #002740;
  color: ${cuideoTheme.palette.common.white};
`;

const StyledBlueContainer = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  padding: 4.3125rem 0 10.81rem;

  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    padding: 3rem 1rem 6rem;
    text-align: center;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
  }
`;

const StyledYellowFooter = styled.div`
  background-color: #ebc200;
  color: ${cuideoTheme.palette.primary.main};
`;

const StyledYellowContainer = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  padding: 1.75rem 0;
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    text-align: center;
    padding: 1.75rem 1rem;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    display: flex;
    flex-direction: row;
  }
`;

const StyledYellowLeft = styled.div`
  flex-grow: 1;
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    margin-bottom: 1rem;
  }
`;

const StyledYellowRight = styled.div``;

const StyledYellowMenu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    display: flex;
    flex-direction: row;
  }
`;

const StyledYellowMenuItem = styled.li`
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    margin: 0.5rem 0;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    margin: 0 0.5rem;
  }
`;

const StyledYellowLink = styled.a`
  text-decoration: none;
  color: ${cuideoTheme.palette.primary.main};
`;

const StyledLogo = styled.img``;

const StyledTypography = styled(Typography)`
  margin: 1rem 0;
  line-height: 1.33;
  font-size: 1.125rem;
`;

const StyledBlueColumnContent = styled.div`
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    margin-top: 3.5rem;
  }
`;

const StyledMenuHeader = styled(Typography)`
  color: #ebc200;
  font-weight: 700;
`;

const StyledBlueMenu = styled.ul`
  padding: 0;
  list-style: none;
  margin: 1rem 0;
`;

const StyledBlueMenuItem = styled.li``;

const StyledBlueMenuLink = styled(NavLink)`
  color: ${cuideoTheme.palette.common.white};
  padding: 0.5rem 0;
  text-decoration: none;
  display: block;
`;

const StyledSocialLinks = styled.div`
  margin-bottom: 1rem;
  text-align: center;
`;

const StyledSocialLink = styled.a`
  margin: 0 1rem;
`;

const StyledSocialLinkImage = styled.img``;

const StyledColabWrapper = styled.div`
  position: relative;
  padding: 1rem 2rem;
  background-color: ${cuideoTheme.palette.common.white};
  border-radius: ${cuideoTheme.shape.borderRadius}px;
`;

const StyledColabUp = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledColabDown = styled.div``;

const StyledColabUpLeft = styled.div`
  width: 40%;
`;

const StyledColabUpRight = styled.div`
  width: 60%;
`;

const StyledColabImg = styled.img`
  width: 100%;
  height: auto;
`;

const Footer = (props: any) => {
  const { footer } = props;

  if (footer.type === "hidden") {
    return null;
  }

  return (
    <StyledFooter>
      <StyledBlueFooter>
        <StyledBlueContainer>
          <Grid container spacing={8}>
            <Grid item xs={12} md={3}>
              <StyledLogo src={Logo} />
              <StyledTypography>
                <FormattedMessage
                  id="Footer.CuidaCare"
                  defaultMessage="Cuida Care Marketing"
                />
                <br />
                <FormattedMessage
                  id="Footer.Agency"
                  defaultMessage="Agencia de colocación"
                />
                <br />
                <FormattedMessage
                  id="Footer.noAgency"
                  defaultMessage="Nº 0900000353"
                />
              </StyledTypography>
              <StyledTypography>
                <strong>
                  <FormattedMessage
                    id="Footer.Schedule"
                    defaultMessage="Horarios:"
                  />
                  <br />
                </strong>
                <FormattedMessage
                  id="Footer.MonThu"
                  defaultMessage="De lunes a jueves de 9 a 20h"
                />
                <br />
                <FormattedMessage
                  id="Footer.Friday"
                  defaultMessage="Viernes de 8 a 18h"
                />
              </StyledTypography>
            </Grid>
            <Grid item xs={12} md={1}></Grid>
            <Grid item xs={12} md={4}>
              <StyledBlueColumnContent>
                <StyledMenuHeader>
                  <FormattedMessage id="Footer.Menu" defaultMessage="Menú:" />
                </StyledMenuHeader>
                <StyledBlueMenu>
                  <StyledBlueMenuItem>
                    <StyledBlueMenuLink to={JOB_OFFERS}>
                      <FormattedMessage
                        id="Footer.Home"
                        defaultMessage="Inicio"
                      />
                    </StyledBlueMenuLink>
                  </StyledBlueMenuItem>
                  <StyledBlueMenuItem>
                    <StyledBlueMenuLink to={JOB_SIGN_IN}>
                      <FormattedMessage
                        id="Footer.SignIn"
                        defaultMessage="Inicio sesión"
                      />
                    </StyledBlueMenuLink>
                  </StyledBlueMenuItem>
                  <StyledBlueMenuItem>
                    <StyledBlueMenuLink to={"/registroCuidador"}>
                      <FormattedMessage
                        id="Footer.SignUp"
                        defaultMessage="Registrarse"
                      />
                    </StyledBlueMenuLink>
                  </StyledBlueMenuItem>
                </StyledBlueMenu>
              </StyledBlueColumnContent>
            </Grid>
            <Grid item xs={12} md={4}>
              <StyledBlueColumnContent>
                <StyledSocialLinks>
                  <StyledSocialLink href="https://www.facebook.com/cuideo.spain/">
                    <StyledSocialLinkImage src={FacebookIcon} />
                  </StyledSocialLink>
                  <StyledSocialLink href="https://twitter.com/cuideo">
                    <StyledSocialLinkImage src={TwitterIcon} />
                  </StyledSocialLink>
                  <StyledSocialLink href="https://es.linkedin.com/company/cuideo">
                    <StyledSocialLinkImage src={LinkedInIcon} />
                  </StyledSocialLink>
                </StyledSocialLinks>
                <StyledColabWrapper>
                  <StyledColabUp>
                    <StyledColabUpLeft>
                      <StyledColabImg src={ColabE} />
                    </StyledColabUpLeft>
                    <StyledColabUpRight>
                      <StyledColabImg src={ColabSNE} />

                      <StyledColabImg src={ColabMT} />
                    </StyledColabUpRight>
                  </StyledColabUp>
                  <Divider />
                  <StyledColabDown>
                    <StyledColabImg src={ColabStripe} />
                  </StyledColabDown>
                </StyledColabWrapper>
              </StyledBlueColumnContent>
            </Grid>
          </Grid>
        </StyledBlueContainer>
      </StyledBlueFooter>
      <StyledYellowFooter>
        <StyledYellowContainer>
          <StyledYellowLeft>
            <FormattedMessage
              id="Footer.Copyright"
              defaultMessage="Cuideo.com 2020 © Todos los derechos reservados"
            />
          </StyledYellowLeft>
          <StyledYellowRight>
            <StyledYellowMenu>
              <StyledYellowMenuItem>
                <StyledYellowLink href="https://cuideo.com/">
                  <FormattedMessage
                    id="Footer.CommercialWeb"
                    defaultMessage="Web comercial"
                  />
                </StyledYellowLink>
              </StyledYellowMenuItem>
              <StyledYellowMenuItem>
                <StyledYellowLink href="https://cuideo.com/politica-de-privacidad/">
                  <FormattedMessage
                    id="Footer.PrivacyPolicy"
                    defaultMessage="Política de privacidad"
                  />
                </StyledYellowLink>
              </StyledYellowMenuItem>
              <StyledYellowMenuItem>
                <StyledYellowLink href="https://cuideo.com/politica-de-cookies/">
                  <FormattedMessage
                    id="Footer.CookiePolicy"
                    defaultMessage="Política de cookies"
                  />
                </StyledYellowLink>
              </StyledYellowMenuItem>
              <StyledYellowMenuItem>
                <StyledYellowLink href="https://cuideo.com/aviso-legal/">
                  <FormattedMessage
                    id="Footer.LegalAdvice"
                    defaultMessage="Aviso legal"
                  />
                </StyledYellowLink>
              </StyledYellowMenuItem>
            </StyledYellowMenu>
          </StyledYellowRight>
        </StyledYellowContainer>
      </StyledYellowFooter>
    </StyledFooter>
  );
};

const mapStateToProps = ({
  auth,
  layout,
}: {
  auth: { authUser: any };
  layout: any;
}) => {
  const { authUser } = auth;
  const { footer } = layout;
  return { authUser, footer };
};

export default connect(mapStateToProps, { push, userSignOut })(Footer);
