import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { apiUpdateContractPhase } from "../../../../../../../api/contract/apiUpdateContractPhase";
import { TContractPhase } from "../../../../../../../api/contract/contract.types";
import { TContractsPageFilters } from "./useContractsPageURLParams";
import toast from "react-hot-toast";
import { queryClient } from "../../../../../../../lib/react-query";
import { contractKeys } from "../../../util/contract.query-keys";
import { TContractPartialWithNumbers } from "../../../types/contract.types";

export type TUpdateContractPhase = {
  id: string;
  contractName?: string;
  phase: TContractPhase;
};

export default function useContractPhaseMutation(
  contractsPageFilters: TContractsPageFilters
) {
  const { formatMessage } = useIntl();

  const updateContractPhase = async (payload: TUpdateContractPhase) => {
    await apiUpdateContractPhase(payload.id, payload.phase);
  };

  return useMutation(updateContractPhase, {
    onMutate: (payload) => {
      queryClient.cancelQueries(contractKeys.all);

      const queryKey = contractKeys.listForContractsPage(contractsPageFilters);
      const prevQuery:
        | { contracts: TContractPartialWithNumbers[] }
        | undefined = queryClient.getQueryData(queryKey);

      if (!prevQuery) {
        return;
      }

      const newContracts: TContractPartialWithNumbers[] =
        prevQuery.contracts.map((contract) => {
          if (contract.id !== payload.id) {
            return contract;
          }

          return {
            ...contract,
            _uiMutation:
              payload.phase === "promote"
                ? "to_promote"
                : payload.phase === "recruit"
                ? "to_recruit"
                : "to_shared",
          };
        });

      queryClient.setQueryData(queryKey, {
        ...prevQuery,
        contracts: newContracts,
      });

      return {
        prevQuery,
      };
    },
    onError: (err, payload, context: any) => {
      queryClient.setQueryData(
        contractKeys.listForContractsPage(contractsPageFilters),
        context.prevQuery
      );
    },
    onSettled: (data, error, payload) => {
      queryClient.invalidateQueries(contractKeys.all);

      if (error) {
        toast.error(
          formatMessage(
            {
              id: "ContractCard.Ha habido un error al intentar modificar el {contractName}",
              defaultMessage:
                "Ha habido un error al intentar modificar el {contractName}",
            },
            {
              contractName: payload.contractName ?? "Contrato",
            }
          )
        );

        return;
      }

      if (payload.phase === "promote") {
      }

      if (payload.phase === "recruit") {
        toast.success(
          formatMessage(
            {
              id: "ContractCard.El {contractName} se ha envíado al Recruiter",
              defaultMessage: "El {contractName} se ha enviado al Recruiter",
            },
            {
              contractName: payload.contractName ?? "Contrato",
            }
          )
        );
      }

      if (payload.phase === "recruit_and_promote") {
        toast.success(
          formatMessage(
            {
              id: "ContractCard.El {contractName} se ha compartido",
              defaultMessage: "El {contractName} se ha compartido",
            },
            {
              contractName: payload.contractName ?? "Contrato",
            }
          )
        );
      }
    },
  });
}
