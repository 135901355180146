import { Button, Dialog, Typography } from "@material-ui/core";
import { Form, Formik } from "formik";
import styled from "styled-components";
import { cuideoTheme } from "../../../../../containers/themes/defaultTheme";
import { Fragment } from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import useContractToSelectedStateDialogValidationSchema from "../hooks/useContractToSelectedStateDialogValidationSchema";
import ContractToSelectedStateDialogInnerForm from "./ContractToSelectedStateDialogInnerForm";
import useContractToSelectedStateDialogFormInitialValues from "../hooks/useContractToSelectedStateDialogFormInitialValues";

export const StyledContractToSelectedStateDialog = styled(Dialog)`
  .MuiPaper-root {
    max-width: 80rem;
    min-width: 80rem;
    overflow: auto;
    overflow-x: hidden;
    min-height: 45rem;
  }
`;

export const ContractToSelectedStateDialogHeader = styled(Typography)`
  margin: 1.25rem 2rem;
  text-align: center;
  color: ${cuideoTheme.palette.primary.main};
  line-height: 1.25;
  font-size: 1.25rem;
  font-weight: 700;
`;

export const ContractToSelectedStateDialogContent = styled(Typography)`
  margin: 0.75rem 2rem;
  text-align: left;
  color: ${cuideoTheme.palette.primary.main};
  line-height: 1.25;
  font-size: 1rem;
`;

export const StyledActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 0.5rem 0.5rem;
`;

export const StyledCancelButton = styled(Button)``;

export const StyledConfirmButton = styled(Button)`
  .MuiCircularProgress-root {
    color: #aaa;
  }
`;

interface IContractToSelectedStateDialogProps {
  open: boolean;
  isRequestLoading: boolean;
  contract: any;
  onAccept: (values: any) => void;
  onCancel: () => void;
}

const ContractToSelectedStateDialog: React.FC<IContractToSelectedStateDialogProps> = (props) => {
  const { open, isRequestLoading, contract, onAccept, onCancel } = props;
  const initialValues = useContractToSelectedStateDialogFormInitialValues(contract);
  const validationSchema = useContractToSelectedStateDialogValidationSchema();
  return (
    <StyledContractToSelectedStateDialog
      open={open}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
      }}
    >
      <Fragment>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values: any, actions: any) => {
            const payload = {
              Tipo_de_contrato: values.Tipo_de_contrato.value,
              Horario_Servicio: values.Horario_Servicio,
              Horas_jornada_semanal: parseInt(values.Horas_jornada_semanal),
              Horas_de_pernocta: values.Horas_de_pernocta.value,
              Noches_de_pernocta: values.Noches_de_pernocta?.value ?? null,
              Horas_de_presencia: values.Horas_de_presencia.value,
              N_de_horas_de_presencia_a_la_semana1: values.N_de_horas_de_presencia_a_la_semana
                ? parseFloat(values.N_de_horas_de_presencia_a_la_semana)
                : null,
              Fecha_de_inicio_real: moment(values.Fecha_de_inicio_real).toISOString(),
              Fecha_fin_contrato: values.Fecha_fin_contrato ? moment(values.Fecha_fin_contrato).toISOString() : null,
              Per_odo_de_prueba: values.Per_odo_de_prueba,
              Tipo_de_salario: values.Tipo_de_salario.value,
              Numero_de_pagas: values.Numero_de_pagas.value,
              Salario_Bruto_Mensual: parseFloat(values.Salario_Bruto_Mensual),
              Cl_usulas_especiales: values.Cl_usulas_especiales
            };
            onAccept(payload);
          }}
        >
          <Form>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <ContractToSelectedStateDialogInnerForm
                isRequestLoading={isRequestLoading}
                contract={contract}
                onClose={onCancel}
              />
            </MuiPickersUtilsProvider>
          </Form>
        </Formik>
      </Fragment>
    </StyledContractToSelectedStateDialog>
  );
};

export default ContractToSelectedStateDialog;
