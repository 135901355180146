import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import _ from "lodash";
import React from "react";
import FilterItemHead from "./FilterItemHead";
import { Filter } from "./FiltersButton";

interface IFilterProps {
  filter: Filter;
  filterState: any;
  onFilterChange: (
    filterName: string,
    isOpen: boolean,
    value: string | string[]
  ) => void;
}

const FilterItem = (props: IFilterProps) => {
  const { filter, filterState, onFilterChange } = props;

  if (filter.type === "select") {
    return (
      <Box key={`fb-s-${filter.name}`} className="CMuiFiltersPaperFilterItem">
        <FilterItemHead
          open={filterState.isOpen}
          isActive={filterState.isActive}
          filterLabel={filter.filterLabel}
          onClick={() => {
            onFilterChange(
              filter.name,
              !filterState.isOpen,
              filterState.value
                ? filterState.value
                : filter.options && filter.options[0]
                ? filter.options[0].value
                : ""
            );
          }}
        />
        <Collapse in={filterState.isOpen}>
          <Box className="CMuiFiltersPaperFilterContent">
            <Box className="CMuiFiltersPaperFilterOption">
              <Select
                className="CMuiFiltersPaperFilterSelect"
                fullWidth
                variant="outlined"
                displayEmpty
                value={filterState.value}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                  onFilterChange(
                    filter.name,
                    filterState.isOpen,
                    typeof e.target.value === "string" ? e.target.value : ""
                  );
                }}
              >
                {filter.options &&
                  filter.options.map((option, index) => (
                    <MenuItem
                      key={`fbs-${filter.name}-${index}`}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
              </Select>
            </Box>
          </Box>
        </Collapse>
      </Box>
    );
  } else if (filter.type === "check") {
    return (
      <Box key={`fb-s-${filter.name}`} className="CMuiFiltersPaperFilterItem">
        <FilterItemHead
          open={filterState.isOpen}
          isActive={filterState.isActive}
          filterLabel={filter.filterLabel}
          onClick={() => {
            onFilterChange(filter.name, !filterState.isOpen, filterState.value);
          }}
        />
      </Box>
    );
  } else {
    return (
      <Box key={`fb-s-${filter.name}`} className="CMuiFiltersPaperFilterItem">
        <FilterItemHead
          open={filterState.isOpen}
          isActive={filterState.isActive}
          filterLabel={filter.filterLabel}
          onClick={() => {
            onFilterChange(filter.name, !filterState.isOpen, filterState.value);
          }}
        />
        <Collapse in={filterState.isOpen}>
          <Box className="CMuiFiltersPaperFilterContent">
            {filter.options &&
              filter.options.map((option, index) => (
                <Box
                  key={`fb-o-${filter.name}-${index}`}
                  className="CMuiFiltersPaperFilterOption"
                >
                  <FormControlLabel
                    className="CMuiFiltersPaperFilterOptionControl"
                    label={option.label}
                    control={
                      <Checkbox
                        color="primary"
                        checked={filterState.value.includes(option.value)}
                        onChange={(e: any) => {
                          onFilterChange(
                            filter.name,
                            filterState.isOpen,
                            _.xor(filterState.value, [option.value])
                          );
                        }}
                      />
                    }
                  />
                </Box>
              ))}
          </Box>
        </Collapse>
      </Box>
    );
  }
};

export default React.memo(
  (props: IFilterProps) => {
    return <FilterItem {...props} />;
  },
  (prevProps, nextProps) => {
    if (
      prevProps.filter !== nextProps.filter ||
      prevProps.filterState !== nextProps.filterState ||
      prevProps.onFilterChange !== nextProps.onFilterChange
    ) {
      return false;
    }
    return true;
  }
);
