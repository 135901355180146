import { apiEmpleo } from "../../util/ApiEmpleo";
import { TMessageModule } from "./message.types";

export type TCreateMessageRequest = {
  parentId: string;
  module: TMessageModule;
  content: string;
};

const apiCreateMessage = async (payload: TCreateMessageRequest) => {
  let res = await apiEmpleo.post(`messages`, payload, {
    headers: {
      "Content-Type": "application/ld+json",
    },
  });

  return res;
};

export { apiCreateMessage };
