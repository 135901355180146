import { Button, Dialog, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import cuideoTheme from "../../containers/themes/defaultTheme";
import IntlMessages from "../../util/IntlMessages";

const StyledDialog = styled(Dialog)``;

const StyledTitle = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-size: 1.5rem;
  font-weight: 700;
  margin: 1rem;
`;

const StyledLabel = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  margin: 1rem;
`;

const StyledDialogActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem 0.5rem 0.5rem;
`;

const StyledCancelButton = styled(Button)`
  margin: 0.5rem;
  flex-grow: 1;
`;

const StyledConfirmButton = styled(Button)`
  margin: 0.5rem;
  flex-grow: 1;
`;

interface ConfirmDialogProps {
  open: boolean;
  title: any;
  label: any;
  onConfirm: Function;
  onCancel: Function;
  confirmLabel?: any;
  cancelLabel?: any;
}

const ConfirmDialog = (props: ConfirmDialogProps) => {
  const { open, title, label, onConfirm, onCancel, confirmLabel, cancelLabel } =
    props;

  const handleCancelClick = (e: React.MouseEvent) => {
    onCancel();
  };

  const handleConfirmClick = (e: React.MouseEvent) => {
    onConfirm();
  };

  return (
    <StyledDialog open={open} className="MuiCuideo-ConfirmDialog">
      <StyledTitle>{title}</StyledTitle>
      <StyledLabel>{label}</StyledLabel>
      <StyledDialogActions>
        <StyledCancelButton
          color="primary"
          variant="text"
          onClick={handleCancelClick}
          fullWidth
        >
          {cancelLabel ? cancelLabel : <IntlMessages id="No" />}
        </StyledCancelButton>
        <StyledConfirmButton
          color="primary"
          variant="contained"
          onClick={handleConfirmClick}
          fullWidth
        >
          {confirmLabel ? confirmLabel : <IntlMessages id="Si" />}
        </StyledConfirmButton>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default ConfirmDialog;
