import { TContractManagerInfoResponse } from "../../../../../../../api/contract/apiListAllContractsManagerInfo";
import { TGetServicesInfoManagerCollectionItem } from "../../../../../../../api/service/service.types";
import { TCoordinationGroup } from "../../../../../../../api/user/apiListStaffUsers";
import { TUserServiceAssignationsStats } from "../../../../recruitersManager/types/UserGroupAssignations.types";
import { TStaffAssignationsContractStats } from "./StaffAssignationContractStats";

export type TStaffAssignationsCoordinator = {
  id: string;
  fullName: string;
  active: boolean;
  lastActivityTime: string | null;
  coordinationGroups: TCoordinationGroup[];
  isOnline: boolean;
  contracts: TContractManagerInfoResponse[];
  services: TGetServicesInfoManagerCollectionItem[];
  servicesStats: TUserServiceAssignationsStats;
  contractStats: TStaffAssignationsContractStats;
};

export const sortStaffAssignationsCoordinator = (
  a: TStaffAssignationsCoordinator,
  b: TStaffAssignationsCoordinator
) => {
  if (a.active && !b.active) {
    return -1;
  }
  if (!a.active && b.active) {
    return 1;
  }
  if (a.services.length > b.services.length) {
    return -1;
  }
  if (a.services.length < b.services.length) {
    return 1;
  }
  if (a.contracts.length > b.contracts.length) {
    return -1;
  }
  if (a.contracts.length < b.contracts.length) {
    return 1;
  }
  return a.fullName > b.fullName ? 1 : -1;
};
