import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const StyledDialog = styled(Dialog)`
  .CMuiHelperDialogTitle {
    .MuiTypography-h6 {
      font-size: 1.25rem;
    }
  }
  .CMuiHelperDialogContent {
    .MuiTypography-root {
      font-size: 1rem;
      line-height: 1.35;
    }
  }
`;

interface IHelperDialogProps {
  open: boolean;
  title?: React.ReactNode | string;
  text: React.ReactNode | string;
  okLabel?: React.ReactNode | string;
  onClose: () => void;
}

const HelperDialog = (props: IHelperDialogProps) => {
  const { open, text, onClose } = props;

  const title = props.title || "Help";
  const okLabel = props.okLabel || "Accept";

  return (
    <StyledDialog className="CMuiHelperDialog" open={open} onClose={onClose}>
      <DialogTitle className="CMuiHelperDialogTitle">{title}</DialogTitle>
      <DialogContent className="CMuiHelperDialogContent">{text}</DialogContent>
      <DialogActions className="CMuiHelperDialogActions">
        <Button
          className="CMuiHelperDialogOkButton"
          color="primary"
          variant="contained"
          onClick={onClose}
        >
          {okLabel}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default HelperDialog;
