import moment from "moment";
import { TGetNotificationsCollectionItem } from "../../../../../api/notification/notification.types";

export const notificationHasPeriodSeparator = (
  notification: TGetNotificationsCollectionItem,
  prevNotification: TGetNotificationsCollectionItem,
  currentTime = new Date()
) => {
  const momDate = moment(notification.createdAt);
  const prevDate = moment(prevNotification.createdAt);
  const momDateStr = momDate.format("YYYY/MM/DD");
  const prevDateStr = prevDate.format("YYYY/MM/DD");

  if (momDateStr !== prevDateStr) {
    const momStartOfDay = moment(currentTime).startOf("day");
    momStartOfDay.subtract(1, "day");
    if (prevDate.isAfter(momStartOfDay)) {
      return true;
    }
  }
  return false;
};
