import React from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import styled from "styled-components";
import {
  COLOR_ERROR_ALERT_BG,
  COLOR_ERROR_ALERT_FG,
  COLOR_INFO_ALERT_BG,
  COLOR_INFO_ALERT_FG,
  COLOR_SUCCESS_ALERT_BG,
  COLOR_SUCCESS_ALERT_FG,
  COLOR_WARNING_ALERT_BG,
  COLOR_WARNING_ALERT_FG,
} from "../../../../containers/themes/defaultTheme";
import { CircularProgress, Typography } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";

const StyledResultsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  padding: 0.25rem 0;
  border-radius: 0.25rem;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.07);
  transition: all 0.1s ease-in-out;

  &.CMui-success {
    background-color: ${COLOR_SUCCESS_ALERT_BG};
    color: ${COLOR_SUCCESS_ALERT_FG};
  }

  &.CMui-error {
    background-color: ${COLOR_ERROR_ALERT_BG};
    color: ${COLOR_ERROR_ALERT_FG};
  }

  &.CMui-warning {
    background-color: ${COLOR_WARNING_ALERT_BG};
    color: ${COLOR_WARNING_ALERT_FG};
  }

  &.CMui-progress {
    background-color: ${COLOR_INFO_ALERT_BG};
    color: ${COLOR_INFO_ALERT_FG};
  }
`;

const StyledIconWrapper = styled.div`
  margin-right: 0.75rem;

  .MuiSvgIconroot {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

const StyledText = styled(Typography)`
  color: inherit;
  font-size: 0.9375rem;
`;

interface IStatusAlertProps {
  inProgress: boolean;
  numSuccessfulSents: number;
  numToBeSent: number;
}

const StatusAlert: React.FC<IStatusAlertProps> = ({
  inProgress,
  numSuccessfulSents,
  numToBeSent,
}) => {
  const isSuccess = !inProgress && numSuccessfulSents === numToBeSent;
  const isError = !inProgress && numSuccessfulSents === 0;
  const isWarning =
    !inProgress && !isError && numSuccessfulSents !== numToBeSent;

  return (
    <StyledResultsWrapper
      className={classNames({
        "CMui-success": isSuccess,
        "CMui-warning": isWarning,
        "CMui-error": isError,
        "CMui-progress": inProgress,
      })}
    >
      <StyledIconWrapper>
        {inProgress ? (
          <CircularProgress size={16} thickness={5} disableShrink />
        ) : isSuccess ? (
          <CheckCircleOutlineIcon />
        ) : isError ? (
          <HighlightOffIcon />
        ) : (
          <ReportProblemIcon />
        )}
      </StyledIconWrapper>
      <StyledText>
        <FormattedMessage
          id="CreateCommunicationsIntentsButton.Se han enviado {numDone} intentos de envío satisfactoriamente de un total de {numTotal}"
          defaultMessage="Se han enviado {numDone} intentos de envío satisfactoriamente de un total de {numTotal}"
          values={{
            numDone: numSuccessfulSents,
            numTotal: numToBeSent,
          }}
        />
      </StyledText>
    </StyledResultsWrapper>
  );
};

export default StatusAlert;
