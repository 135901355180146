export interface Props {
  src: string;
  alt?: string;
  onClick?: () => void;
}

export const Icon = ({ src, alt, onClick }: Props) => {
  return (
    <img
      src={src}
      alt={alt ?? src}
      style={{ ...(onClick ? { cursor: "pointer" } : {}) }}
      onClick={onClick}
    />
  );
};
