import React from "react";
import useRecruiterAssignationsState from "./hooks/useRecruiterAssignationsState";
import RecruiterAssignations from "./RecruiterAssignations";

interface IRecruiterAssignationsContainerProps {
  selectedOwnerId: string | null;
  onSelectedOwnerChange: (ownerId: string | null) => void;
}

const RecruiterAssignationsContainer: React.FC<IRecruiterAssignationsContainerProps> =
  (props) => {
    const { selectedOwnerId, onSelectedOwnerChange } = props;
    const recruiterAssignations = useRecruiterAssignationsState();

    return (
      <RecruiterAssignations
        isLoading={recruiterAssignations.isLoading}
        totalContracts={recruiterAssignations.totalContracts}
        groups={recruiterAssignations.groups}
        maxGroupContracts={recruiterAssignations.maxContractsAssigned}
        selectedOwnerId={selectedOwnerId}
        onSelectedOwnerChange={onSelectedOwnerChange}
      />
    );
  };

export default RecruiterAssignationsContainer;
