import * as Sentry from "@sentry/browser";
import { Formik, FormikHelpers } from "formik";
import moment from "moment";
import "moment/locale/es";
import _ from "lodash";
import { useState } from "react";
import { connect } from "react-redux";
import {
  getCandidato,
  getPartialCandidatoSuccess,
  hideBackdrop,
  modalShow,
  showBackdrop,
  snackErrorMessage,
  snackInfoMessage,
  snackSuccessMessage,
} from "../../../../../actions";
import { EMPLOYEE_TIME_SLOT, EMPLOYEE_WORK_REGIME } from "../../../../../api/employee/employee.types";
import { apiEmpleo } from "../../../../../util/ApiEmpleo";
import { geocodeAddress } from "../../../../../util/geocodeAddress";
import CoordinatorFileInnerForm from "./CoordinatorFileInnerForm";
import useCoordinatorFileESFormOptions from "./hooks/useCoordinatorFileESFormOptions";
import useCoordinatorFileESInitialValues from "./hooks/useCoordinatorFileESInitialValues";
import useCoordinatorFileESValidationSchema from "./hooks/useCoordinatorFileESValidationSchema";

const FichaCoordinadora = (props: any) => {
  const {
    candidato,
    getPartialCandidatoSuccess,
    snackErrorMessage,
    modalShow,
    onEmployeeChanged,
  } = props;
  const [state, setState] = useState({
    isUpdating: false,
  });
  const validationSchema = useCoordinatorFileESValidationSchema();
  const formOptions = useCoordinatorFileESFormOptions();

  const initialValues = useCoordinatorFileESInitialValues(
    candidato,
    formOptions
  );

  const handleFormSubmit = (values: any, actions: FormikHelpers<any>) => {
    const sendData = async (values: any) => {
      setState({
        ...state,
        isUpdating: true,
      });

      const newData: any = {};
      if (values.Nombre !== candidato.Nombre) {
        newData.Nombre = values.Nombre;
      }
      if (values.Apellidos !== candidato.Apellidos) {
        newData.Apellidos = values.Apellidos;
      }
      if (values.Apellidos_2 !== candidato.Apellidos_2) {
        newData.Apellidos_2 = values.Apellidos_2;
      }
      if (values.Sexo !== candidato.Sexo) {
        newData.Sexo = values.Sexo;
      }
      if (values.Lugar_de_nacimiento.value !== candidato.Lugar_de_nacimiento) {
        newData.Lugar_de_nacimiento = values.Lugar_de_nacimiento.value;
      }
      const birthDate = moment(values.Fecha_de_nacimiento);
      if (birthDate.isValid()) {
        const formattedBirthDate = birthDate.format("YYYY-MM-DD");
        if (formattedBirthDate !== candidato.Fecha_de_nacimiento) {
          newData.Fecha_de_nacimiento = formattedBirthDate;
        }
      }
      if (values.Direccion !== candidato.Direccion) {
        newData.Direccion = values.Direccion;
      }
      if (values.Numero !== candidato.Numero) {
        newData.Numero = values.Numero;
      }
      if (values.Piso !== candidato.Piso) {
        newData.Piso = values.Piso;
      }
      if (values.Codigo_postal !== candidato.Codigo_postal) {
        newData.Codigo_postal = values.Codigo_postal;
      }
      if (values.Provincia.value !== candidato.Provincia) {
        newData.Provincia = values.Provincia.value;
      }
      if (values.Ciudad.value !== candidato.Ciudad) {
        newData.Ciudad = values.Ciudad.value;
      }
      if (
        values.Direccion === values.autocompletedDireccion &&
        values.Numero === values.autocompletedNumero &&
        values.Codigo_postal === values.autocompletedCodigoPostal &&
        values.Ciudad === values.autocompletedCiudad
      ) {
        if (values.Latitud !== candidato.Latitud) {
          newData.Latitud = values.Latitud;
        }
        if (values.Longitud !== candidato.Longitud) {
          newData.Longitud = values.Longitud;
        }
      } else {
        // The address has been manually changed
        // We can try to solve with geocoding the complete address
        // Direccion, Numero, Codigo_postal Ciudad, Provincia, País
        try {
          const geo = await geocodeAddress({
            Direccion: values.Direccion,
            Numero: values.Numero,
            Codigo_postal: values.Codigo_postal,
            Ciudad: values.Ciudad,
            Provincia: values.Provincia.value,
            Pais: values.Pais ? values.Pais : undefined,
          });
          if (geo) {
            newData.Latitud = geo.lat.toString();
            newData.Longitud = geo.lng.toString();
          }
        } catch (e) {
          Sentry.captureException(
            new Error("Error trying to geocode address from CoordinatorFile")
          );
        }
      }
      const newPhone = values.Phone.replace(/\s+/g, "");
      if (newPhone !== candidato.Phone) {
        newData.Phone = newPhone;
      }
      if (values.Email !== candidato.Email) {
        newData.Email = values.Email;
      }
      if (values.DNI_NIE !== candidato.DNI_NIE) {
        newData.DNI_NIE = values.DNI_NIE;
      }
      if (values.N_CUENTA !== candidato.N_CUENTA) {
        newData.N_CUENTA = values.N_CUENTA;
      }
      const expiryDate = moment(values.Vencimiento_NIE);
      if (expiryDate.isValid()) {
        newData.Vencimiento_NIE = expiryDate.format("YYYY-MM-DD");
      }
      newData.Otros_Idiomas = values.Otros_Idiomas.map(
        (item: any) => item.value
      );
      if (!candidato.socialSecurityBlocked && values.N_SS !== candidato.N_SS) {
        newData.N_SS = values.N_SS;
      }
      if (values.Nivel_formativo?.value !== candidato.Nivel_formativo) {
        newData.Nivel_formativo = values.Nivel_formativo.value;
      }
      if (values.A_os_de_experiencia && values.A_os_de_experiencia.value) {
        newData.A_os_de_experiencia = values.A_os_de_experiencia.value;
      }
      newData.Titulaci_n_oficial_SAD = values.Titulaci_n_oficial_SAD.value
        ? [values.Titulaci_n_oficial_SAD.value]
        : [];
      if (values.Sexo !== candidato.Sexo) {
        newData.Sexo = values.Sexo;
      }
      if (values.Carnet_de_conducir !== candidato.Carnet_de_conducir) {
        newData.Carnet_de_conducir = values.Carnet_de_conducir;
      }
      if (values.Veh_culo_propio !== candidato.Veh_culo_propio) {
        newData.Veh_culo_propio = values.Veh_culo_propio;
      }
      if (values.Notas_disponibilidad !== candidato.Notas_disponibilidad) {
        newData.Notas_disponibilidad = values.Notas_disponibilidad;
      }
      if (values.Trabajando_Hasta !== candidato.Trabajando_Hasta) {
        if (values.Trabajando_Hasta) {
          newData.Trabajando_Hasta = moment(values.Trabajando_Hasta).format(
            "YYYY-MM-DD"
          );
        } else if (values.Trabajando_Hasta === null) {
          newData.Trabajando_Hasta = moment()
            .add(1, "years")
            .format("YYYY-MM-DD");
        } else if (values.Trabajando_Hasta === undefined) {
          newData.Trabajando_Hasta = null;
        }
      }
      if (values.W_Breve_descripci_n !== candidato.W_Breve_descripci_n) {
        newData.W_Breve_descripci_n = values.W_Breve_descripci_n;
      }
      if (
        values.Observaciones_generales !== candidato.Observaciones_generales
      ) {
        newData.Observaciones_generales = values.Observaciones_generales;
      }
      if (values.Interna_entre_semana !== candidato.Interna_entre_semana) {
        newData.Interna_entre_semana = values.Interna_entre_semana;
      }
      if (values.Interna_fin_de_semana !== candidato.Interna_fin_de_semana) {
        newData.Interna_fin_de_semana = values.Interna_fin_de_semana;
      }
      if (values.Externa_entre_semana !== candidato.Externa_entre_semana) {
        newData.Externa_entre_semana = values.Externa_entre_semana;
      }
      if (values.Externa_fin_de_semana !== candidato.Externa_fin_de_semana) {
        newData.Externa_fin_de_semana = values.Externa_fin_de_semana;
      }
      if (values.No_mostrar_imagen !== candidato.No_mostrar_imagen) {
        newData.No_mostrar_imagen = values.No_mostrar_imagen;
      }
      if (values.hasWhatsapp !== candidato.hasWhatsapp) {
        newData.hasWhatsapp = values.hasWhatsapp;
      }
      if (values.noBot) {
        const formDate = moment(values.No_iniciar_bot_hasta);
        if (formDate.isValid()) {
          newData.No_iniciar_bot_hasta = formDate.format("YYYY-MM-DD");
          newData.botAvailable = false;
        } else {
          newData.No_iniciar_bot_hasta = moment("2300-01-01T00:00:00").format(
            "YYYY-MM-DD"
          );
          newData.botAvailable = false;
        }
      } else {
        newData.No_iniciar_bot_hasta = null;
      }
      const newTareas = [] as string[];
      formOptions.taskOptions.forEach((item: any) => {
        if (values["tarea__" + item.value]) {
          newTareas.push(item.value);
        }
      });
      newData.Qu_tareas_quieres_o_puedes_realizar = newTareas;

      const newPathologies = [] as string[];
      formOptions.pathologyOptions.forEach((item: any) => {
        if (values["pathology__" + item.value]) {
          newPathologies.push(item.value);
        }
      });
      newData.Patolog_as_en_las_que_tiene_experiencia = newPathologies;

      newData.R_gimen_de_trabajo = [];
      if (values.REEH) {
        newData.R_gimen_de_trabajo.push(EMPLOYEE_WORK_REGIME.REEH);
      }
      if (values.SAD) {
        newData.R_gimen_de_trabajo.push(EMPLOYEE_WORK_REGIME.SAD);
      }

      let newTimeSlot = [] as string[];
      formOptions.timeSlotOptions.forEach((item: any) => {
        if (newData.Interna_entre_semana || newData.Interna_fin_de_semana) {
          newTimeSlot = [EMPLOYEE_TIME_SLOT.MORNING, EMPLOYEE_TIME_SLOT.NOON, EMPLOYEE_TIME_SLOT.AFTERNOON];
        } else {
          if (values["timeSlot__" + item.value]) {
            newTimeSlot.push(item.value);
          }
        }
      });
      newData.Franja_horaria = newTimeSlot;

      try {
        let doInitialPartialUpdate = true;
        if (
          initialValues.cif_obverse &&
          values.cif_obverse !== initialValues.cif_obverse
        ) {
          doInitialPartialUpdate = false;
        }
        if (
          initialValues.cif_back &&
          values.cif_back !== initialValues.cif_back
        ) {
          doInitialPartialUpdate = false;
        }
        if (values.cif_obverse && typeof values.cif_obverse === "object") {
          doInitialPartialUpdate = false;
        }
        if (values.cif_back && typeof values.cif_back === "object") {
          doInitialPartialUpdate = false;
        }
        if (
          initialValues.curriculum &&
          values.curriculum !== initialValues.curriculum
        ) {
          doInitialPartialUpdate = false;
        }
        if (values.curriculum && typeof values.curriculum === "object") {
          doInitialPartialUpdate = false;
        }

        if (doInitialPartialUpdate) {
          getPartialCandidatoSuccess({
            partialCandidatos: [
              {
                ...newData,
                id: candidato.id,
              },
            ],
          });
        }
        await apiEmpleo.patch(
          "candidatos/" + candidato.id,
          {
            ...newData,
          },
          {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          }
        );

        if (
          initialValues.cif_obverse &&
          values.cif_obverse !== initialValues.cif_obverse
        ) {
          await apiEmpleo.delete(`/candidatos/${candidato.id}/obverse-cif`);
        }
        if (
          initialValues.cif_back &&
          values.cif_back !== initialValues.cif_back
        ) {
          await apiEmpleo.delete(`/candidatos/${candidato.id}/back-cif`);
        }

        if (values.cif_obverse && typeof values.cif_obverse === "object") {
          const form = new FormData();
          form.append("file", values.cif_obverse);
          await apiEmpleo.post(
            `/candidatos/${candidato.id}/obverse-cif`,
            form,
            {
              headers: {
                "content-type": `multipart/form-data; boundary=${
                  (form as any)._boundary
                }`,
              },
            }
          );
        }

        if (values.cif_back && typeof values.cif_back === "object") {
          const form = new FormData();
          form.append("file", values.cif_back);
          await apiEmpleo.post(`/candidatos/${candidato.id}/back-cif`, form, {
            headers: {
              "content-type": `multipart/form-data; boundary=${
                (form as any)._boundary
              }`,
            },
          });
        }

        if (
          initialValues.curriculum &&
          !_.isEqual(values.curriculum, initialValues.curriculum)
        ) {
          await apiEmpleo.delete(`/candidatos/${candidato.id}/curriculum`);
        }

        if (
          values.curriculum &&
          typeof values.curriculum === "object" &&
          !_.isEqual(values.curriculum, initialValues.curriculum)
        ) {
          const form = new FormData();
          form.append("file", values.curriculum);
          await apiEmpleo.post(`/candidatos/${candidato.id}/curriculum`, form, {
            headers: {
              "content-type": `multipart/form-data; boundary=${
                (form as any)._boundary
              }`,
            },
          });
        }

        if (!doInitialPartialUpdate) {
          const afterRes = await apiEmpleo.get(`/candidatos/${candidato.id}`);
          getPartialCandidatoSuccess({
            partialCandidatos: [
              {
                ...afterRes,
                id: candidato.id,
              },
            ],
          });
        }
        actions.setSubmitting(false);
        onEmployeeChanged && onEmployeeChanged();
      } catch (e) {
        console.log("Catched error", e);
        modalShow({
          open: true,
          type: "error",
          message:
            "Ha habido un error al intentar guardar los cambios del candidato en remoto. Es posible que se haya perdido la información o no se haya almacenado en Zoho CRM adecuadamente.",
        });
        actions.resetForm();
        actions.setSubmitting(false);
      }

      setState({
        ...state,
        isUpdating: false,
      });
    };

    sendData(values);
  };

  const handlePartialUpdate = (fieldName: string, data: any) => {
    const sendPartialData = async (fieldName: string, values: any) => {
      const newData: any = {};
      if (fieldName === "Experiencia_y_referencias") {
        newData.Experiencia_y_referencias = values;
        // Should update the pathologies
        newData.Patolog_as_en_las_que_tiene_experiencia =
          updatePathologiesFromExperiences(
            candidato.Patolog_as_en_las_que_tiene_experiencia,
            values
          );
      }
      if (fieldName === "Formaciones") {
        newData.Formaciones = values;
      }
      if (fieldName === "Observaciones_generales") {
        newData.Observaciones_generales = values;
      }
      try {
        getPartialCandidatoSuccess({
          partialCandidatos: [
            {
              ...newData,
              id: candidato.id,
            },
          ],
        });
        await apiEmpleo.patch(
          "candidatos/" + candidato.id,
          {
            ...newData,
          },
          {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          }
        );
      } catch (e) {
        snackErrorMessage(
          "Ha habido algún problema al actualizar el candidato"
        );
      }
    };
    sendPartialData(fieldName, data);
  };

  const updatePathologiesFromExperiences = (
    currentPathologiesWithExperience: string[],
    experiences: any
  ) => {
    const paths = [...currentPathologiesWithExperience];
    experiences.forEach((experience: any) => {
      experience.Patolog_as_tratadas.forEach((pathology: string) => {
        if (!paths.includes(pathology)) {
          paths.push(pathology);
        }
      });
    });
    return paths;
  };

  const setUpdating = (isUpdating: boolean) => {
    setState({
      ...state,
      isUpdating,
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={(values: any, actions: FormikHelpers<any>) => {
        handleFormSubmit(values, actions);
      }}
    >
      <CoordinatorFileInnerForm
        employee={candidato}
        isUpdating={state.isUpdating}
        formOptions={formOptions}
        setUpdating={setUpdating}
        onPartialUpdate={handlePartialUpdate}
      />
    </Formik>
  );
};

export default connect(null, {
  getCandidato,
  getPartialCandidatoSuccess,
  snackSuccessMessage,
  snackInfoMessage,
  snackErrorMessage,
  showBackdrop,
  hideBackdrop,
  modalShow,
})(FichaCoordinadora);
