import ManIcon from "../../../../assets/images/iconos/man.svg";
import WomanIcon from "../../../../assets/images/iconos/woman.svg";
import { Grid, RadioGroup } from "@material-ui/core";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { TSelectOption } from "../../../../apps/affinity/pages/ServicePage/components/ServiceValuation/types";
import {
  FormikAddressField,
  FormikDate,
  FormikRadio,
  FormikSelect,
  FormikTextField,
} from "../../../../components/FormikFields";
import FormikUpload from "../../../../components/FormikFields/FormikUpload";
import {
  StyledRegistroSection,
  StyledRegistroTitle,
} from "../../EmployeeSignUpSC";
import {
  StyledField,
  StyledFormControl,
  StyledFormControlLabel,
  StyledFormHelperText,
  StyledFormLabel,
  StyledIcon,
  StyledUncheckedIcon,
} from "./SignUpStep2InnerForm";
import { TSignUpStep2FormContext } from "./hooks/useSignUpStep2FormContext";
import { useFormikContext } from "formik";

const StyledSelectWrapper = styled.div`
  .CMuiFormikSelect {
    margin: 0;
  }
`;
interface ISignUpStep2PersonalDataInnerFormProps {
  dayOptions: TSelectOption[];
  monthOptions: TSelectOption[];
  yearOptions: TSelectOption[];
  countryOptions: TSelectOption[];
  cityOptions: TSelectOption[];
  addressStreet: string;
  changeAddressStreet: TSignUpStep2FormContext["formActions"]["changeAddressStreet"];
  selectAddress: TSignUpStep2FormContext["formActions"]["selectAddress"];
  postalCodeChange: TSignUpStep2FormContext["formActions"]["postalCodeChange"];
}

const SignUpStep2PersonalDataInnerForm = (
  props: ISignUpStep2PersonalDataInnerFormProps
) => {
  const {
    dayOptions,
    monthOptions,
    yearOptions,
    countryOptions,
    addressStreet,
    changeAddressStreet,
    selectAddress,
    postalCodeChange,
    cityOptions,
  } = props;
  const { formatMessage } = useIntl();
  const formikContext = useFormikContext<any>();

  return (
    <StyledRegistroSection>
      <StyledRegistroTitle>
        <FormattedMessage
          id="SignUp.Tus datos personales"
          defaultMessage="Tus datos personales"
        />
      </StyledRegistroTitle>
      <FormikDate
        name="fechaNacimiento"
        margin="normal"
        size="medium"
        required
        label={
          <FormattedMessage
            id="SignUp.Fecha de nacimiento"
            defaultMessage="Fecha de nacimiento"
          />
        }
        gridWidths={{
          day: { xs: 3 },
          month: { xs: 5 },
          year: { xs: 4 },
        }}
        options={{
          day: dayOptions,
          month: monthOptions,
          year: yearOptions,
        }}
      />
      <StyledFormControl
        error={!!formikContext.errors.Sexo && !!formikContext.touched.Sexo}
      >
        <StyledFormControlLabel>
          <FormattedMessage id="SignUp.Sexo" defaultMessage="Sexo" /> *
        </StyledFormControlLabel>
        <RadioGroup name="Sexo" style={{ width: "100%" }}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <FormikRadio
                label={<FormattedMessage id="Hombre" defaultMessage="Hombre" />}
                name="Sexo"
                value="Hombre"
                color="primary"
                icon={<StyledUncheckedIcon src={ManIcon} />}
                checkedIcon={<StyledIcon src={ManIcon} />}
              />
            </Grid>
            <Grid item xs={6}>
              <FormikRadio
                label={<FormattedMessage id="Mujer" defaultMessage="Mujer" />}
                name="Sexo"
                value="Mujer"
                color="primary"
                icon={<StyledUncheckedIcon src={WomanIcon} />}
                checkedIcon={<StyledIcon src={WomanIcon} />}
              />
            </Grid>
          </Grid>
        </RadioGroup>
        {!!formikContext.errors.Sexo && !!formikContext.touched.Sexo && (
          <StyledFormHelperText>
            {formikContext.errors.Sexo}
          </StyledFormHelperText>
        )}
      </StyledFormControl>
      <FormikUpload
        label={
          <FormattedMessage
            id="SignUp.Imagen de perfil"
            defaultMessage="Imagen de perfil"
          />
        }
        name="avatar"
        fullWidth
        margin="normal"
        size="medium"
        showMiniThumb
      />
      <FormikSelect
        name="Pais_nacimiento"
        required
        label={
          <FormattedMessage
            id="SignUp.País de nacimiento"
            defaultMessage="País de nacimiento"
          />
        }
        closeMenuOnSelect={true}
        size="medium"
        placeholder={formatMessage({
          id: "SignUp.Selecciona",
          defaultMessage: "Selecciona",
        })}
        options={countryOptions}
      />
      <StyledField>
        <StyledFormLabel>
          <FormattedMessage
            id="SignUp.WhereDoYouLive"
            defaultMessage="¿Dónde vives actualmente?"
          />{" "}
          *
        </StyledFormLabel>
        <Grid container spacing={4}>
          <Grid item xs={8}>
            <FormikAddressField
              name="registroCalle"
              placeholder={formatMessage({
                id: "SignUp.AddressStreet",
                defaultMessage: "Calle",
              })}
              required
              margin="nomargin"
              size="medium"
              fullWidth
              value={addressStreet}
              handleChange={changeAddressStreet}
              handleSelect={selectAddress}
            />
          </Grid>
          <Grid item xs={2}>
            <FormikTextField
              name="registroNumero"
              placeholder={formatMessage({
                id: "SignUp.AddressNumber",
                defaultMessage: "Num.",
              })}
              required
              fullWidth
              margin="nomargin"
              size="medium"
            />
          </Grid>
          <Grid item xs={2}>
            <FormikTextField
              name="registroPiso"
              placeholder={formatMessage({
                id: "SignUp.AddressFloor",
                defaultMessage: "Piso",
              })}
              required
              margin="nomargin"
              fullWidth
              size="medium"
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={5}>
            <FormikTextField
              name="registroCodigoPostal"
              placeholder={formatMessage({
                id: "SignUp.PostalCode",
                defaultMessage: "Código postal",
              })}
              margin="nomargin"
              required
              fullWidth
              size="medium"
              onChange={postalCodeChange}
            />
          </Grid>
          <Grid item xs={12} sm={7}>
            <StyledSelectWrapper>
              <FormikSelect
                name="registroCiudad"
                placeholder={formatMessage({
                  id: "SignUp.Ciudad",
                  defaultMessage: "Ciudad",
                })}
                size="medium"
                options={cityOptions}
                disabled={cityOptions.length < 2}
                isSearchable={false}
              />
            </StyledSelectWrapper>
          </Grid>
        </Grid>
      </StyledField>
    </StyledRegistroSection>
  );
};

export default SignUpStep2PersonalDataInnerForm;
