import { Grid } from "@material-ui/core";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import moment from "moment";
import "moment/locale/es";
import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import ExperienciasIcon from "../../../assets/images/iconos/experiencias.svg";
import FormacionesIcon from "../../../assets/images/iconos/formaciones.svg";
import TareasIcon from "../../../assets/images/iconos/tareas.svg";
import LogoBlanco from "../../../assets/images/logo.svg";
import CuideoUserAvatar from "../../CuideoUserAvatar";
import useFormatters from "../../FormattedFields/useFormatters";
import {
  StyledBigGridCell,
  StyledBody,
  StyledBottomWrapper,
  StyledCuideoLogo,
  StyledCurve,
  StyledData,
  StyledDataItem,
  StyledDescriptionDetailWrapper,
  StyledDescriptionWrapper,
  StyledEmployeeAvatar,
  StyledEmployeeName,
  StyledEmployeeProfile,
  StyledEmployeeTitle,
  StyledEmployeeWrapper,
  StyledExperience,
  StyledExperienceDescription,
  StyledExperiencesSection,
  StyledExperienceTitle,
  StyledFormation,
  StyledFormationDescription,
  StyledFormationTitle,
  StyledFormationValid,
  StyledHeader,
  StyledIcon,
  StyledLabel,
  StyledPathologiesTitle,
  StyledPathology,
  StyledReferenceText,
  StyledSection,
  StyledSectionIcon,
  StyledSectionLabel,
  StyledSectionTitle,
  StyledSexText,
  StyledSvgWrapper,
  StyledTask,
} from "./EmployeeProfileSC";
import {
  employeeIsMale,
  TEmployeePublicProfile,
} from "./models/EmployeePublicProfile";

interface IEmployeeProfileESProps {
  employee: TEmployeePublicProfile;
}

const EmployeeProfileES = (props: IEmployeeProfileESProps) => {
  const { employee } = props;
  const { formatTask, formatPathology } = useFormatters();

  const tasks = employee.Qu_tareas_quieres_o_puedes_realizar ?? [];
  const languages = employee.Otros_Idiomas?.join(", ").trim() ?? [];
  const hasDrivingLicense = !!(
    employee.Carnet_de_conducir?.toLowerCase() === "si"
  );
  const hasOwnVehicle = !!(employee.Veh_culo_propio?.toLowerCase() === "si");

  const references =
    employee.Experiencia_y_referencias?.filter((experience: any) => {
      if (experience.Mostrar_Ref_Ficha) {
        return true;
      }
      return false;
    }) ?? [];

  return (
    <StyledEmployeeProfile>
      <StyledHeader>
        <StyledEmployeeWrapper>
          <StyledEmployeeAvatar>
            <CuideoUserAvatar
              user={{
                userFullName: employee.Nombre,
                userAvatar: employee.No_mostrar_imagen
                  ? null
                  : employee.avatarUrl,
              }}
            />

            <StyledEmployeeTitle>
              <StyledSexText>
                {employeeIsMale(employee) ? (
                  <FormattedMessage
                    id="EmployeeProfile.MaleEmployeeProfile"
                    defaultMessage="Ficha Candidato"
                  />
                ) : (
                  <FormattedMessage
                    id="EmployeeProfile.FemaleEmployeeProfile"
                    defaultMessage="Ficha Candidata"
                  />
                )}
              </StyledSexText>
              <StyledEmployeeName>
                {employee.Nombre} {employee.Apellidos}
              </StyledEmployeeName>
            </StyledEmployeeTitle>
          </StyledEmployeeAvatar>
        </StyledEmployeeWrapper>
        <StyledCuideoLogo src={LogoBlanco}></StyledCuideoLogo>
      </StyledHeader>
      <StyledCurve viewBox="0 0 100 30" preserveAspectRatio="none">
        <path fill="#004b7b" d="M 0,30L35,30C55,25,45,5,65,0L100,0L0,0" />
      </StyledCurve>

      <StyledBody>
        <Grid container spacing={8} alignItems="flex-start">
          <Grid item xs={12} md={6}>
            {employee.W_Breve_descripci_n && (
              <StyledDescriptionWrapper>
                {employee.W_Breve_descripci_n}
              </StyledDescriptionWrapper>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledDescriptionDetailWrapper>
              <StyledDataItem>
                <StyledLabel>
                  <FormattedMessage
                    id="EmployeeProfile.Localization"
                    defaultMessage="Localización"
                  />
                </StyledLabel>
                <StyledData>
                  {employee.Ciudad}, {employee.Provincia}
                </StyledData>
              </StyledDataItem>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  {employee.Fecha_de_nacimiento && (
                    <StyledDataItem>
                      <StyledLabel>
                        <FormattedMessage
                          id="EmployeeProfile.Birthdate"
                          defaultMessage="Fecha de nacimiento"
                        />
                      </StyledLabel>
                      <StyledData>
                        {moment(employee.Fecha_de_nacimiento).format(
                          "DD/MM/YYYY"
                        )}
                      </StyledData>
                    </StyledDataItem>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <StyledDataItem>
                    <StyledLabel>
                      <FormattedMessage
                        id="EmployeeProfile.Nationality"
                        defaultMessage="Nacionalidad"
                      />
                    </StyledLabel>
                    <StyledData>{employee.Lugar_de_nacimiento}</StyledData>
                  </StyledDataItem>
                </Grid>
              </Grid>
              <StyledDataItem>
                <StyledLabel>
                  <FormattedMessage
                    id="EmployeeProfile.Languages"
                    defaultMessage="Idiomas"
                  />
                </StyledLabel>
                <StyledData>{languages}</StyledData>
              </StyledDataItem>
              <Grid container spacing={4}>
                {hasDrivingLicense && (
                  <Grid item xs={6}>
                    <StyledDataItem>
                      <StyledLabel>
                        <FormattedMessage
                          id="EmployeeProfile.Carnet de conducir"
                          defaultMessage="Carnet de conducir"
                        />
                      </StyledLabel>
                      <StyledData>
                        <FormattedMessage
                          id="EmployeeProfile.Si"
                          defaultMessage="Si"
                        />
                      </StyledData>
                    </StyledDataItem>
                  </Grid>
                )}
                {hasOwnVehicle && (
                  <Grid item xs={6}>
                    <StyledDataItem>
                      <StyledLabel>
                        <FormattedMessage
                          id="EmployeeProfile.Vehículo propio"
                          defaultMessage="Vehículo propio"
                        />
                      </StyledLabel>
                      <StyledData>
                        <FormattedMessage
                          id="EmployeeProfile.Si"
                          defaultMessage="Si"
                        />
                      </StyledData>
                    </StyledDataItem>
                  </Grid>
                )}
              </Grid>
            </StyledDescriptionDetailWrapper>
          </Grid>
        </Grid>
        <Grid container spacing={8}>
          <Grid item xs={12} md={6}>
            <StyledBigGridCell>
              <StyledSectionTitle>
                <StyledSectionLabel>
                  <FormattedMessage
                    id="EmployeeProfile.Tasks"
                    defaultMessage="Tareas que puede realizar"
                  />
                </StyledSectionLabel>
                <StyledSectionIcon>
                  <StyledIcon src={TareasIcon} />
                </StyledSectionIcon>
              </StyledSectionTitle>
              <Grid container spacing={8}>
                <Grid item xs={6}>
                  {tasks
                    .filter((task, index) => !(index % 2))
                    .map((task) => (
                      <StyledTask key={task}>{formatTask(task)}</StyledTask>
                    ))}
                </Grid>
                <Grid item xs={6}>
                  {tasks
                    .filter((task, index) => index % 2)
                    .map((task) => (
                      <StyledTask key={task}>{formatTask(task)}</StyledTask>
                    ))}
                </Grid>
              </Grid>
              {employee.Formaciones && employee.Formaciones.length > 0 && (
                <StyledSection>
                  <StyledSectionTitle>
                    <StyledSectionLabel>
                      <FormattedMessage
                        id="EmployeeProfile.Formation"
                        defaultMessage="Formación : "
                      />
                    </StyledSectionLabel>
                    <StyledSectionIcon>
                      <StyledIcon src={FormacionesIcon} />
                    </StyledSectionIcon>
                  </StyledSectionTitle>
                  {employee.Formaciones.map((item: any, index: number) => {
                    return (
                      <StyledFormation key={"for" + index}>
                        <StyledFormationTitle>
                          {item.Nombre_escuela_centro}
                        </StyledFormationTitle>
                        <StyledFormationDescription>
                          {item.Nombre_del_curso}
                        </StyledFormationDescription>
                        <StyledFormationValid>
                          {item.Titulo_valido_en_Espa_a &&
                          item.Titulo_valido_en_Espa_a.toLowerCase() ===
                            "si" ? (
                            <FormattedMessage
                              id="EmployeeProfile.ValidInSpain"
                              defaultMessage="Válido en España"
                            />
                          ) : (
                            <FormattedMessage
                              id="EmployeeProfile.NotValidInSpain"
                              defaultMessage="No válido en España"
                            />
                          )}
                        </StyledFormationValid>
                      </StyledFormation>
                    );
                  })}
                </StyledSection>
              )}
            </StyledBigGridCell>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledBigGridCell>
              <StyledSection>
                {employee.Experiencia_y_referencias &&
                  employee.Experiencia_y_referencias.length > 0 && (
                    <StyledExperiencesSection>
                      <StyledSectionTitle>
                        <StyledSectionLabel>
                          <FormattedMessage
                            id="EmployeeProfile.Exeperience"
                            defaultMessage="Experiencia : "
                          />
                        </StyledSectionLabel>
                        <StyledSectionIcon>
                          <StyledIcon src={ExperienciasIcon} />
                        </StyledSectionIcon>
                      </StyledSectionTitle>
                      {employee.Experiencia_y_referencias.map((item, index) => {
                        return (
                          <StyledExperience key={"exp" + index}>
                            <StyledExperienceTitle>
                              {item.Empresa_o_famila}
                            </StyledExperienceTitle>
                            <StyledExperienceDescription>
                              {item.Funciones_y_tareas_realizadas}
                            </StyledExperienceDescription>
                            {item.Patolog_as_tratadas &&
                              item.Patolog_as_tratadas.length > 0 && (
                                <Fragment>
                                  <StyledPathologiesTitle>
                                    <FormattedMessage
                                      id="EmployeeProfile.Pathologies"
                                      defaultMessage="Patologías tratadas"
                                    />
                                  </StyledPathologiesTitle>
                                  <Grid container spacing={4}>
                                    <Grid item xs={6}>
                                      {item.Patolog_as_tratadas.filter(
                                        (itemPat, indexPat) => !(indexPat % 2)
                                      ).map((itemPat, indexPat) => (
                                        <StyledPathology
                                          key={index + "pat" + indexPat}
                                        >
                                          {formatPathology(itemPat)}
                                        </StyledPathology>
                                      ))}
                                    </Grid>
                                    <Grid item xs={6}>
                                      {item.Patolog_as_tratadas.filter(
                                        (itemPat, indexPat) => indexPat % 2
                                      ).map((itemPat, indexPat) => (
                                        <StyledPathology
                                          key={index + "pat" + indexPat}
                                        >
                                          {formatPathology(itemPat)}
                                        </StyledPathology>
                                      ))}
                                    </Grid>
                                  </Grid>
                                </Fragment>
                              )}
                          </StyledExperience>
                        );
                      })}
                    </StyledExperiencesSection>
                  )}
              </StyledSection>
            </StyledBigGridCell>
          </Grid>
        </Grid>
        {references.length !== 0 && (
          <StyledBottomWrapper>
            <StyledSection>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <StyledSectionTitle>
                    <StyledSectionLabel>
                      <FormattedMessage
                        id="EmployeeProfile.Referencias"
                        defaultMessage="Referencias"
                      />
                    </StyledSectionLabel>
                    <StyledSvgWrapper>
                      <QuestionAnswerIcon />
                    </StyledSvgWrapper>
                  </StyledSectionTitle>
                </Grid>
              </Grid>
              <Grid container spacing={4} alignItems="center">
                {references.map((reference: any) => (
                  <Grid item xs={12} md={6} key={reference.id}>
                    <StyledReferenceText>
                      "{reference.Comentarios_referencias}"
                    </StyledReferenceText>
                  </Grid>
                ))}
              </Grid>
            </StyledSection>
          </StyledBottomWrapper>
        )}
      </StyledBody>
    </StyledEmployeeProfile>
  );
};

export default EmployeeProfileES;
