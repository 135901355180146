import React from "react";
import styled from "styled-components";
import CheckIcon from "@material-ui/icons/Check";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 0.125rem;

  .MuiSvgIcon-root {
    width: 1rem;
    height: 1rem;
    color: #bbb;
  }
`;

interface IMessageSingleCheckProps {}

const MessageSingleCheck = (props: IMessageSingleCheckProps) => {
  return (
    <StyledWrapper>
      <CheckIcon style={{ fontSize: "1rem" }} />
    </StyledWrapper>
  );
};

export default MessageSingleCheck;
