export const SERVICE_HOLDER_COMPANION_ALONE = "Vive solo/a";
export const SERVICE_HOLDER_COMPANION_WITH_PARTNER =
  "Vive acompañado/a de su pareja";
export const SERVICE_HOLDER_COMPANION_WITH_RELATIVE =
  "Vive acompañado/a de un familiar";
export const SERVICE_HOLDER_COMPANION_OTHER = "Otros";

export const SERVICE_HOLDER_EXPRESION_CAN_HAVE_A_CONVERSATION =
  "Puede mantener una conversación";
export const SERVICE_HOLDER_EXPRESION_HAVE_DIFFICULTIES = "Tiene dificultades";
export const SERVICE_HOLDER_EXPRESION_UNDERSTANDABLE = "Se entiende";

export const SERVICE_HOLDER_MOBILITY_NEEDS_WALKER = "Necesita andador";
export const SERVICE_HOLDER_MOBILITY_NEEDS_HELP = "Necesita ayuda";
export const SERVICE_HOLDER_MOBILITY_NEEDS_CANE = "Necesita bastón";
export const SERVICE_HOLDER_MOBILITY_NEEDS_SUPERVISION = "Necesita supervisión";
export const SERVICE_HOLDER_MOBILITY_NEEDS_CRUTCHES = "Usa muletas";
export const SERVICE_HOLDER_MOBILITY_USES_WHEELCHAIR = "Va en silla de ruedas";
export const SERVICE_HOLDER_MOBILITY_AUTONOMOUS = "Es autónomo/a para moverse";

export const SERVICE_HOLDER_HEARING_LEVEL_LOW = "Bajo";
export const SERVICE_HOLDER_HEARING_LEVEL_MEDIUM = "Medio";
export const SERVICE_HOLDER_HEARING_LEVEL_GOOD = "Bueno";

export const SERVICE_HOLDER_HEADPHONES_YES = "Si";
export const SERVICE_HOLDER_HEADPHONES_NO = "No";

export const SERVICE_HOLDER_VISION_LEVEL_VERY_GOOD = "Muy buena";
export const SERVICE_HOLDER_VISION_LEVEL_GOOD = "Buena";
export const SERVICE_HOLDER_VISION_LEVEL_GLASSES = "Usa Gafas";
export const SERVICE_HOLDER_VISION_LEVEL_DETERIORATED = "Deteriorada";

export const SERVICE_HOLDER_SENSORIAL_TREATMENT_YES = "Si";
export const SERVICE_HOLDER_SENSORIAL_TREATMENT_NO = "No";

export const SERVICE_HOLDER_EMOTIONAL_STATUS_CALMED = "Calmado/a";
export const SERVICE_HOLDER_EMOTIONAL_STATUS_PERSEVERANT = "Perseverante";
export const SERVICE_HOLDER_EMOTIONAL_STATUS_FRIENDLY = "Amable";
export const SERVICE_HOLDER_EMOTIONAL_STATUS_INSECURE = "Inseguro/a";
export const SERVICE_HOLDER_EMOTIONAL_STATUS_OBSESSIVE = "Obsesivo/a";
export const SERVICE_HOLDER_EMOTIONAL_STATUS_SENSIBLE = "Sensible";
export const SERVICE_HOLDER_EMOTIONAL_STATUS_NERVOUS = "Nervioso/a";
export const SERVICE_HOLDER_EMOTIONAL_STATUS_SENTIMENTAL = "Sentimental";
export const SERVICE_HOLDER_EMOTIONAL_STATUS_APATHETIC = "Apático/a";
export const SERVICE_HOLDER_EMOTIONAL_STATUS_QUIET = "Tranquilo/a";
export const SERVICE_HOLDER_EMOTIONAL_STATUS_AFFABLE = "Afable";
export const SERVICE_HOLDER_EMOTIONAL_STATUS_CONFORMIST = "Conformista";
export const SERVICE_HOLDER_EMOTIONAL_STATUS_LAZY = "Perezoso/a";
export const SERVICE_HOLDER_EMOTIONAL_STATUS_MESSY = "Desordenado/a";
export const SERVICE_HOLDER_EMOTIONAL_STATUS_SUSPICIOUS = "Desconfiado/a";
export const SERVICE_HOLDER_EMOTIONAL_STATUS_STRONG_CHARACTER =
  "Carácter fuerte";
export const SERVICE_HOLDER_EMOTIONAL_STATUS_EXIGENT = "Exigente";
export const SERVICE_HOLDER_EMOTIONAL_STATUS_FAMILIAR = "Familiar";
export const SERVICE_HOLDER_EMOTIONAL_STATUS_FUNNY = "Divertido/a";
export const SERVICE_HOLDER_EMOTIONAL_STATUS_HOMELIKE = "Hogareño/a";
export const SERVICE_HOLDER_EMOTIONAL_STATUS_NICE = "Simpático";
export const SERVICE_HOLDER_EMOTIONAL_STATUS_SILENT = "Callado/a";
export const SERVICE_HOLDER_EMOTIONAL_STATUS_SOCIABLE = "Sociable";
export const SERVICE_HOLDER_EMOTIONAL_STATUS_CONVERSATIONAL = "Conversador/a";

export const SERVICE_HOLDER_CARETAKER_ADAPT_ALREADY_HAD =
  "Si, ha tenido cuidador/a antes";
export const SERVICE_HOLDER_CARETAKER_ADAPT_NOT_WANT_TO_ACCEPT_IT =
  "No, no lo acepta";
export const SERVICE_HOLDER_CARETAKER_ADAPT_RETICENT_BUT_WILL_TRY =
  "Está reticente pero quieren intentarlo";
export const SERVICE_HOLDER_CARETAKER_ADAPT_DIDNT_HAVE_BUT_WANT_TO =
  "No ha tenido cuidadora, pero la quiere, pide o acepta";

export const SERVICE_HOLDER_SLEEP_QUALITY_VERY_GOOD = "Muy Buena";
export const SERVICE_HOLDER_SLEEP_QUALITY_GOOD = "Buena";
export const SERVICE_HOLDER_SLEEP_QUALITY_MEDIUM = "Regular";
export const SERVICE_HOLDER_SLEEP_QUALITY_BAD = "Mala";

export const SERVICE_HOLDER_TOILET_LEVEL_AUTONOMOUS = "Autónomo";
export const SERVICE_HOLDER_TOILET_LEVEL_NEEDS_HELP = "Necesita ayuda";
export const SERVICE_HOLDER_TOILET_LEVEL_WEARS_A_DIAPER = "Lleva pañal";
export const SERVICE_HOLDER_TOILET_LEVEL_BEDRIDDEN = "Higiene encamado";
export const SERVICE_HOLDER_TOILET_LEVEL_OTHERS = "Otros";

export const SERVICE_HOLDER_HOME_TYPE_HOUSE = "Casa";
export const SERVICE_HOLDER_HOME_TYPE_FLAT = "Piso";

export const SERVICE_HOLDER_HOME_FEATURE_ELEVATOR = "Edificio con ascensor";
export const SERVICE_HOLDER_HOME_FEATURE_ADAPTED_BATHROOM = "Baño adaptado";
export const SERVICE_HOLDER_HOME_FEATURE_CRANE = "Grúa para movilizaciones";
export const SERVICE_HOLDER_HOME_FEATURE_CAMERAS = "Cámaras de videovigilancia";
export const SERVICE_HOLDER_HOME_FEATURE_ARTICULATED_BED = "Cama articulada";
export const SERVICE_HOLDER_HOME_FEATURE_PETS = "Mascotas";
export const SERVICE_HOLDER_HOME_FEATURE_CLEAN_SERVICE =
  "Servicios externos de limpieza - soporte ley dependencia";
export const SERVICE_HOLDER_HOME_FEATURE_TELE_ASSISTANCE =
  "Teleasistencia en el domicilio";
