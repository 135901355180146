import { Grid } from "@material-ui/core";
import { connect, getIn } from "formik";
import moment from "moment";
import "moment/locale/es";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import CuideoMultiSelect from "../CuideoFields/CuideoMultiSelect";
import { StyledError, StyledField, StyledLabel } from "./StyledComponents";

interface State {
  day: any;
  month: any;
  year: any;
  dayTouched: boolean;
  monthTouched: boolean;
  yearTouched: boolean;
}

class FormikDate extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      day: "",
      month: "",
      year: "",
      dayTouched: false,
      monthTouched: false,
      yearTouched: false,
    };
  }

  shouldComponentUpdate(nextProps: any, nextState: any) {
    if (nextProps.forceComponentUpdate) {
      return true;
    }
    if (
      this.state.day !== nextState.day ||
      this.state.month !== nextState.month ||
      this.state.year !== nextState.year ||
      this.state.dayTouched !== nextState.dayTouched ||
      this.state.monthTouched !== nextState.monthTouched ||
      this.state.yearTouched !== nextState.yearTouched
    ) {
      return true;
    }
    const next = nextProps.formik;
    const cur = this.props.formik;
    const name = this.props.name;
    if (
      getIn(next.values, name) !== getIn(cur.values, name) ||
      getIn(next.errors, name) !== getIn(cur.errors, name) ||
      getIn(next.touched, name) !== getIn(cur.touched, name)
    ) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    this.props.formik.registerField(this.props.name, {
      validate: this.props.validate,
    });

    // Set initial state after initing Formik
    const formik = this.props.formik;
    if (formik.initialValues[this.props.name]) {
      const momentDate = moment(formik.initialValues[this.props.name]);
      if (momentDate) {
        this.setState({
          day: {
            value: momentDate.format("DD"),
            label: momentDate.format("DD"),
          },
          month: {
            value: momentDate.format("MM"),
            label: this.props.options.month.find(
              (item: any) => item.value === parseInt(momentDate.format("MM"))
            ).label,
          },
          year: {
            value: momentDate.format("YYYY"),
            label: momentDate.format("YYYY"),
          },
        });
      }
    }
  }

  pad(num: number, size: number): string {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.name !== prevProps.name) {
      this.props.formik.unregisterField(prevProps.name);
      this.props.formik.registerField(this.props.name, {
        validate: this.props.validate,
      });
    }

    if (this.props.validate !== prevProps.validate) {
      this.props.formik.registerField(this.props.name, {
        validate: this.props.validate,
      });
    }

    const formik = this.props.formik;
    const touched = !!getIn(formik.touched, this.props.name);
    if (
      !touched &&
      this.state.dayTouched &&
      this.state.monthTouched &&
      this.state.yearTouched
    ) {
      formik.setFieldTouched(this.props.name);
    }
    let dateStr = "";
    if (this.state.year && this.state.year.value) {
      dateStr += this.state.year.value;
    } else {
      dateStr += "undefined";
    }
    if (this.state.month && this.state.month.value) {
      dateStr += `-${this.pad(this.state.month.value, 2)}`;
    } else {
      dateStr += "-undefined";
    }
    if (this.state.day && this.state.day.value) {
      dateStr += `-${this.pad(this.state.day.value, 2)}`;
    } else {
      dateStr += "-undefined";
    }
    if (formik.values[this.props.name] !== dateStr) {
      formik.setFieldValue(this.props.name, dateStr);
    }
  }

  componentWillUnmount() {
    this.props.formik.unregisterField(this.props.name);
  }

  handleDayChange(value: any, action: any) {
    this.setState({ day: value, dayTouched: true });
  }

  handleDayBlur(e: any) {
    this.setState({ dayTouched: true });
  }

  handleMonthChange(value: any, action: any) {
    this.setState({ month: value, monthTouched: true });
  }

  handleMonthBlur(e: any) {
    this.setState({ monthTouched: true });
  }

  handleYearChange(value: any, action: any) {
    this.setState({ year: value, yearTouched: true });
  }

  handleYearBlur(e: any) {
    this.setState({ yearTouched: true });
  }

  render() {
    const {
      name,
      variant,
      label,
      margin,
      size,
      required,
      gridWidths,
      options,
      labelSuffix,
      disabled,
    } = this.props;
    const formik = this.props.formik;

    const error = getIn(formik.errors, name);
    const touched = !!getIn(formik.touched, name);

    return (
      <StyledField
        fullWidth
        margin={margin || "normal"}
        size={size || "medium"}
      >
        {label && (
          <StyledLabel
            error={!!error && touched ? "error" : undefined}
            size={size || "medium"}
          >
            {label} {required && <span className="required-field"> *</span>}{" "}
            {labelSuffix}
          </StyledLabel>
        )}
        <Grid container spacing={4} alignItems="center">
          <Grid
            item
            xs={
              gridWidths && gridWidths.day && gridWidths.day.xs
                ? gridWidths.day.xs
                : 3
            }
            sm={
              gridWidths && gridWidths.day && gridWidths.day.sm
                ? gridWidths.day.sm
                : 3
            }
            md={
              gridWidths && gridWidths.day && gridWidths.day.md
                ? gridWidths.day.md
                : 3
            }
            lg={
              gridWidths && gridWidths.day && gridWidths.day.lg
                ? gridWidths.day.lg
                : 3
            }
          >
            <CuideoMultiSelect
              error={!!error && touched}
              variant={variant}
              value={this.state.day}
              placeholder={
                <FormattedMessage id="Date.Day" defaultMessage="Día" />
              }
              options={options.day}
              onChange={this.handleDayChange.bind(this)}
              onBlur={this.handleDayBlur.bind(this)}
              isDisabled={disabled}
            />
          </Grid>
          <Grid
            item
            xs={
              gridWidths && gridWidths.month && gridWidths.month.xs
                ? gridWidths.month.xs
                : 5
            }
            sm={
              gridWidths && gridWidths.month && gridWidths.month.sm
                ? gridWidths.month.sm
                : 5
            }
            md={
              gridWidths && gridWidths.month && gridWidths.month.md
                ? gridWidths.month.md
                : 5
            }
            lg={
              gridWidths && gridWidths.month && gridWidths.month.lg
                ? gridWidths.month.lg
                : 5
            }
          >
            <CuideoMultiSelect
              error={!!error && touched}
              variant={variant}
              value={this.state.month}
              placeholder={
                <FormattedMessage id="Date.Month" defaultMessage="Mes" />
              }
              options={options.month}
              onChange={this.handleMonthChange.bind(this)}
              onBlur={this.handleMonthBlur.bind(this)}
              isDisabled={disabled}
            />
          </Grid>
          <Grid
            item
            xs={
              gridWidths && gridWidths.year && gridWidths.year.xs
                ? gridWidths.year.xs
                : 4
            }
            sm={
              gridWidths && gridWidths.year && gridWidths.year.sm
                ? gridWidths.year.sm
                : 4
            }
            md={
              gridWidths && gridWidths.year && gridWidths.year.md
                ? gridWidths.year.md
                : 4
            }
            lg={
              gridWidths && gridWidths.year && gridWidths.year.lg
                ? gridWidths.year.lg
                : 4
            }
          >
            <CuideoMultiSelect
              error={!!error && touched}
              variant={variant}
              value={this.state.year}
              placeholder={
                <FormattedMessage id="Date.Year" defaultMessage="Año" />
              }
              options={options.year}
              onChange={this.handleYearChange.bind(this)}
              onBlur={this.handleYearBlur.bind(this)}
              isDisabled={disabled}
            />
          </Grid>
        </Grid>
        {touched && error && (
          <StyledError size={size || "medium"}>
            {error.value ? error.value : error}
          </StyledError>
        )}
      </StyledField>
    );
  }
}

export default connect(FormikDate);
