import React from "react";
import styled from "styled-components";
import IntlMessages from "../../util/IntlMessages";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { Typography } from "@material-ui/core";

interface SizeProp {
  size?: "small" | "medium" | "large";
}

const StyledEnCurso = styled(Typography)<SizeProp>`
  color: #7ed321;

  ${({ size }) => {
    if (size === "small")
      return `
      font-size: 0.75rem;
    
      .MuiSvgIcon-root {
        width: 0.75rem;
        height: 0.75rem;
        margin-right: 0.25rem;
      }
  
      `;
    return `
    font-size: 0.875rem;
    
    .MuiSvgIcon-root {
      width: 0.875rem;
      height: 0.875rem;
      margin-right: 0.25rem;
    }
    `;
  }}
`;

const StyledDescartado = styled(Typography)<SizeProp>`
  color: #ebc200;

  ${({ size }) => {
    if (size === "small")
      return `
      font-size: 0.75rem;
    
      .MuiSvgIcon-root {
        width: 0.75rem;
        height: 0.75rem;
        margin-right: 0.25rem;
      }
  
      `;
    return `
    font-size: 0.875rem;
    
    .MuiSvgIcon-root {
      width: 0.875rem;
      height: 0.875rem;
      margin-right: 0.25rem;
    }
    `;
  }}
`;

const StyledContratado = styled(Typography)<SizeProp>`
  font-size: 0.875rem;
  color: #ffffff;
  background-color: #7ed321;
  border-radius: 3px;
  padding: 0.125rem 0;
  font-weight: 700;

  .MuiSvgIcon-root {
    width: 0.875rem;
    height: 0.875rem;
    margin-right: 0.25rem;
    vertical-align: middle;
  }
  ${({ size }) => {
    if (size === "small")
      return `
      font-size: 0.75rem;
    
      .MuiSvgIcon-root {
        width: 0.75rem;
        height: 0.75rem;
        margin-right: 0.25rem;
      }
  
      `;
    return `
    font-size: 0.875rem;
    
    .MuiSvgIcon-root {
      width: 0.875rem;
      height: 0.875rem;
      margin-right: 0.25rem;
    }
    `;
  }}
`;

const EstadoValoracion = (props: SizeProp & any) => {
  const { estadoValoracion, publicar, candidatoSexo } = props;
  const size = props.size || "medium";

  if (!publicar && estadoValoracion !== "06_Contratado") {
    return (
      <StyledDescartado size={size}>
        <CloseIcon />
        {candidatoSexo && candidatoSexo === "Hombre" ? (
          <IntlMessages id="No has sido seleccionado" />
        ) : (
          <IntlMessages id="No has sido seleccionada" />
        )}
      </StyledDescartado>
    );
  }
  if (estadoValoracion === "00_Asociado") {
    return (
      <StyledEnCurso size={size}>
        <RadioButtonUncheckedIcon />
        <IntlMessages id="En curso" />
      </StyledEnCurso>
    );
  }
  if (estadoValoracion === "01_Sin Valorar") {
    return (
      <StyledEnCurso size={size}>
        <RadioButtonUncheckedIcon />
        <IntlMessages id="En curso" />
      </StyledEnCurso>
    );
  }
  if (estadoValoracion === "02_Descartado") {
    return (
      <StyledDescartado size={size}>
        <CloseIcon />
        {candidatoSexo && candidatoSexo === "Hombre" ? (
          <IntlMessages id="No has sido seleccionado" />
        ) : (
          <IntlMessages id="No has sido seleccionada" />
        )}
      </StyledDescartado>
    );
  }
  if (estadoValoracion === "03_Telefónica") {
    return (
      <StyledEnCurso size={size}>
        <RadioButtonUncheckedIcon />
        <IntlMessages id="En curso" />
      </StyledEnCurso>
    );
  }
  if (estadoValoracion === "04_Presencial") {
    return (
      <StyledEnCurso size={size}>
        <RadioButtonUncheckedIcon />
        <IntlMessages id="En curso" />
      </StyledEnCurso>
    );
  }
  if (estadoValoracion === "04_CV a cliente") {
    return (
      <StyledEnCurso size={size}>
        <RadioButtonUncheckedIcon />
        <IntlMessages id="En curso" />
      </StyledEnCurso>
    );
  }
  if (estadoValoracion === "05_Presencial Cliente") {
    return (
      <StyledEnCurso size={size}>
        <RadioButtonUncheckedIcon />
        <IntlMessages id="En curso" />
      </StyledEnCurso>
    );
  }
  if (estadoValoracion === "06_Contratado") {
    return (
      <StyledContratado size={size}>
        <CheckIcon />
        {candidatoSexo && candidatoSexo === "Hombre" ? (
          <IntlMessages id="Seleccionado" />
        ) : (
          <IntlMessages id="Seleccionada" />
        )}
      </StyledContratado>
    );
  }

  return (
    <StyledEnCurso size={size}>
      <RadioButtonUncheckedIcon />
      <IntlMessages id="En curso" />
    </StyledEnCurso>
  );
};

export default EstadoValoracion;
