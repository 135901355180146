import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { cuideoTheme } from "../../../../../../containers/themes/defaultTheme";
import { TUserGroupAssignations } from "../../types/UserGroupAssignations.types";
import { groupName } from "../../util/recruiter-groups.util";
import RecruiterAssignationsList from "./RecruiterAssignationsList";

export const WITHOUT_VALUATION_COLOR = "#004b7b";
export const PENDING_SIGNUP_COLOR = "#004b7b";
export const ADVANCED_VALUATION_COLOR = "#4caf50";

const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  border-radius: 0;

  .MuiAccordionSummary-root {
    padding: 0;
  }

  .MuiAccordionSummary-content {
    margin: 0;
    display: block;
    padding: 0;
    border-bottom: 0.0625rem solid #eee;
  }
  .MuiAccordionDetails-root {
    display: block;
    padding: 0;
  }
`;

const StyledBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 4rem;
  user-select: none;
  width: 100%;
  background-color: rgb(248, 248, 248);

  &.CMuiCoordinatorAssignationsItem-inactive {
    opacity: 0.5;
  }

  &:hover {
    cursor: pointer;
    background-color: rgb(241, 241, 241);
  }
`;

const StyledLeft = styled.div`
  padding: 0.25rem 0 0.25rem 0.5rem;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledLeftTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

const StyledLeftTopLeft = styled.div`
  flex: 0 0 auto;
  width: 40%;
`;

const StyledLeftTopRight = styled.div`
  flex: 0 0 auto;
  width: 60%;
`;

const StyledLeftBottom = styled.div``;

const StyledRight = styled.div`
  padding: 0.25rem 0.5rem 0.25rem 0;
  flex: 0 0 auto;
  width: 2rem;
  text-align: right;
`;

const StyledGroupName = styled(Typography)`
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  line-height: 1.25;
  font-size: 0.9375rem;
  font-weight: 700;
  color: ${cuideoTheme.palette.primary.dark};
`;

const StyledBarContainer = styled.div`
  margin: 0 0.5rem 0 0.25rem;
  height: 1.125rem;
  width: 100%;
  position: relative;
`;

const StyledContractBarsWrapper = styled.div`
  margin-top: 0.25rem;
`;

const StyledContractsNumber = styled(Typography)`
  font-weight: 700;
  font-size: 0.875rem;
  width: 1rem;
  text-align: right;
`;

const StyledContractBarWrapper = styled.div`
  height: 0.75rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

const StyledThinnerBar = styled.div`
  border-radius: 0 0.25rem 0.25rem 0;
  height: 0.5rem;
  top: 0.3125rem;
  position: absolute;
  transition: width 0.25s ease-in-out;
`;

const StyledTotalNumber = styled(Typography)`
  font-weight: 700;
  font-size: 1.125rem;
`;

const StyledOnlineCoordinators = styled(Typography)`
  font-size: 0.75rem;
  line-height: 1;
  margin: 0.125rem 0;
  color: #666;
`;

interface IGroupAssignationsItemProps {
  group: TUserGroupAssignations;
  onSelectedOwnerChange: (ownerId: string | null) => void;
  isLoading?: boolean;
  selectedOwnerId: string | null;
  maxContracts: number;
}

const GroupAssignationsItem: React.FC<IGroupAssignationsItemProps> = (
  props
) => {
  const {
    group,
    onSelectedOwnerChange,
    isLoading,
    selectedOwnerId,
    maxContracts,
  } = props;

  const awaitingContractsBarWidth = isLoading
    ? 0
    : (group.contractStats.awaiting.total / (maxContracts + 1)) * 100;

  const advancedContractsBarWidth = isLoading
    ? 0
    : ((group.contractStats.cvClient.total +
        group.contractStats.clientFaceToFace.total) /
        (maxContracts + 1)) *
      100;

  const totalNumber =
    group.contractStats.awaiting.total +
    group.contractStats.clientFaceToFace.total +
    group.contractStats.cvClient.total;

  return (
    <StyledAccordion>
      <AccordionSummary>
        <StyledBox>
          <StyledLeft>
            <StyledLeftTop>
              <StyledLeftTopLeft>
                <StyledGroupName>{groupName(group.id)}</StyledGroupName>
              </StyledLeftTopLeft>
              <StyledLeftTopRight>
                <StyledContractBarsWrapper>
                  <StyledContractBarWrapper>
                    <StyledContractsNumber>
                      {isLoading ? (
                        <Skeleton />
                      ) : (
                        group.contractStats.awaiting.total
                      )}
                    </StyledContractsNumber>
                    <StyledBarContainer>
                      <StyledThinnerBar
                        style={{
                          backgroundColor: PENDING_SIGNUP_COLOR,
                          width: `calc(${awaitingContractsBarWidth}% + 0.1875rem)`,
                          zIndex: 2,
                        }}
                      />
                    </StyledBarContainer>
                  </StyledContractBarWrapper>
                  <StyledContractBarWrapper>
                    <StyledContractsNumber>
                      {isLoading ? (
                        <Skeleton />
                      ) : (
                        group.contractStats.clientFaceToFace.total +
                        group.contractStats.cvClient.total
                      )}
                    </StyledContractsNumber>
                    <StyledBarContainer>
                      <StyledThinnerBar
                        style={{
                          backgroundColor: ADVANCED_VALUATION_COLOR,
                          width: `calc(${advancedContractsBarWidth}% + 0.1875rem)`,
                          zIndex: 1,
                        }}
                      />
                    </StyledBarContainer>
                  </StyledContractBarWrapper>
                </StyledContractBarsWrapper>
              </StyledLeftTopRight>
            </StyledLeftTop>
            <StyledLeftBottom>
              <StyledOnlineCoordinators>
                {isLoading ? (
                  <Skeleton />
                ) : (
                  <>
                    {group.users.length === 0 ? (
                      <FormattedMessage
                        id="GroupAssignationsItem.Grupo sin reclutadores"
                        defaultMessage="Grupo sin reclutadores"
                      />
                    ) : group.users.length === 1 ? (
                      <FormattedMessage
                        id="GroupAssignationsItem.1 reclutador/a"
                        defaultMessage="1 reclutador/a"
                      />
                    ) : (
                      <FormattedMessage
                        id="GroupAssignationsItem.{numRecruiters} reclutadores"
                        defaultMessage="{numRecruiters} reclutadores"
                        values={{
                          numRecruiters: group.users.length,
                        }}
                      />
                    )}
                  </>
                )}
              </StyledOnlineCoordinators>
            </StyledLeftBottom>
          </StyledLeft>
          <StyledRight>
            <StyledTotalNumber>
              {isLoading ? <Skeleton /> : totalNumber}
            </StyledTotalNumber>
          </StyledRight>
        </StyledBox>
      </AccordionSummary>
      <AccordionDetails>
        <RecruiterAssignationsList
          recruiters={group.users}
          maxContracts={group.maxContractsAssigned}
          onSelectOwner={onSelectedOwnerChange}
          selectedOwnerId={selectedOwnerId}
        />
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default GroupAssignationsItem;
