import { CircularProgress, Tab, Tabs, Typography } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { cuideoTheme } from "../../../../../../../../containers/themes/defaultTheme";
import { IContractQuickFilterData } from "../../ContractsPage";
import { TContractsPresetFilterValue } from "../../hooks/useContractsPageURLParams";

const StyledTabs = styled(Tabs)`
  .MuiTab-root {
    text-transform: none;
    color: ${cuideoTheme.palette.primary.main};

    .CMuiTabLabelNumberBackground {
      background-color: rgba(0, 72, 120, 0.15);
    }

    .MuiCircularProgress-colorPrimary {
      position: absolute;
      top: 20%;
      left: 20%;
    }

    &.Mui-selected {
      background-color: ${cuideoTheme.palette.primary.main};
      color: ${cuideoTheme.palette.common.white};

      .CMuiTabLabelNumberBackground {
        background-color: rgba(255, 255, 255, 0.1);
      }

      .MuiCircularProgress-colorPrimary {
        color: ${cuideoTheme.palette.common.white};
      }
    }
  }

  .MuiTabs-indicator {
    display: none;
  }
`;

const StyledLabelWrapper = styled.span`
  display: flex;
  align-items: center;
`;

const StyledLabel = styled(Typography)`
  font-size: 1rem;
  font-weight: 700;
`;

const StyledLabelInfoWrapper = styled.span`
  margin-left: 0.5rem;
  position: relative;
  width: 1.25rem;
  height: 1.3125rem;
  text-align: center;
`;

const StyledLabelInfoBackground = styled.span`
  display: block;
  position: absolute;
  border-radius: 50%;
  top: -0.25rem;
  left: -0.1875rem;
  height: 1.825rem;
  width: 1.825rem;
`;

const StyledLabelNumber = styled(Typography)`
  font-size: 0.875rem;
  letter-spacing: -0.0625rem;
  font-weight: 600;
`;

type TTabOption = "selection" | "recruitment";

interface IContractsQuickFiltersTabsProps {
  tabOptions: TTabOption[];
  value: TContractsPresetFilterValue;
  recruitContracts: IContractQuickFilterData;
  promoteContracts: IContractQuickFilterData;
  onRecruitContractsClick: () => void;
  onPromoteContractsClick: () => void;
}

const ContractsQuickFiltersTabs: React.FC<IContractsQuickFiltersTabsProps> = (
  props
) => {
  const {
    tabOptions,
    value,
    recruitContracts,
    promoteContracts,
    onRecruitContractsClick,
    onPromoteContractsClick,
  } = props;

  const handleTabsChange = (
    event: React.ChangeEvent<{}>,
    newValue: TContractsPresetFilterValue
  ) => {
    switch (newValue) {
      case "recruit":
        onRecruitContractsClick();
        break;
      case "promote":
        onPromoteContractsClick();
        break;
    }
  };

  return (
    <StyledTabs
      variant="fullWidth"
      textColor="primary"
      value={value}
      onChange={handleTabsChange}
    >
      {tabOptions.map((tabOption) => {
        if (tabOption === "selection") {
          return (
            <Tab
              key="contracts-page__tab--selection"
              label={
                <StyledLabelWrapper>
                  <StyledLabel>
                    <FormattedMessage
                      id="ContractsTabs.Contratos en proceso de selección"
                      defaultMessage="Contratos en proceso de selección"
                    />
                  </StyledLabel>
                  <StyledLabelInfoWrapper>
                    <StyledLabelInfoBackground className="CMuiTabLabelNumberBackground" />
                    {promoteContracts.isLoading ? (
                      <CircularProgress size={12} />
                    ) : (
                      <StyledLabelNumber className="CMuiTabLabelNumber">
                        {promoteContracts.totalItems > 99
                          ? "+99"
                          : promoteContracts.totalItems}
                      </StyledLabelNumber>
                    )}
                  </StyledLabelInfoWrapper>
                </StyledLabelWrapper>
              }
              value="promote"
            />
          );
        }

        if (tabOption === "recruitment") {
          return (
            <Tab
              key="contracts-page__tab--recruitment"
              label={
                <StyledLabelWrapper>
                  <StyledLabel>
                    <FormattedMessage
                      id="ContractsTabs.Contratos en proceso de reclutamiento"
                      defaultMessage="Contratos en proceso de reclutamiento"
                    />
                  </StyledLabel>
                  <StyledLabelInfoWrapper>
                    <StyledLabelInfoBackground className="CMuiTabLabelNumberBackground" />
                    {recruitContracts.isLoading ? (
                      <CircularProgress size={14} />
                    ) : (
                      <StyledLabelNumber className="CMuiTabLabelNumber">
                        {recruitContracts.totalItems > 99
                          ? "+99"
                          : recruitContracts.totalItems}
                      </StyledLabelNumber>
                    )}
                  </StyledLabelInfoWrapper>
                </StyledLabelWrapper>
              }
              value="recruit"
            />
          );
        }

        return null;
      })}
    </StyledTabs>
  );
};

export default ContractsQuickFiltersTabs;
