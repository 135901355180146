import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Switch,
  Typography,
} from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { cuideoTheme } from "../../../../../../containers/themes/defaultTheme";
import {
  ColoredAvatar,
  UserActiveLast,
  UserGroupSelector,
  UserRoleSelector,
  UserTags,
  useUpdateUser,
  useUpdateUserGroups,
  useUpdateUserRole,
  useUser,
} from "../../../user";
import SettingsIcon from "@material-ui/icons/Settings";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FormattedMessage } from "react-intl";
import { initRecruiterGroups } from "../../util/recruiter-groups.util";
import { TCoordinationGroup } from "../../../../../../api/user/apiListStaffUsers";
import { Skeleton } from "@material-ui/lab";
import { useConnectedUsers } from "../../../connectedUser";
import { USER_ROLES } from "../../../../../../api/security";
import { useUpdateUserOnShift } from "../../../user/api/updateUserOnShift";

const StyledWrapper = styled.div`
  width: 100%;
`;

const StyledUserInfo = styled.div`
  margin: 1rem 2rem;
  min-height: 16rem;
  text-align: center;

  .MuiChip-root {
    padding: 0.125rem 0.25rem;
    border-radius: 0.25rem;
    background-color: rgba(0, 72, 120, 0.1);
    font-size: 0.875rem;
    line-height: 1;
    height: 1.5rem;
    color: ${cuideoTheme.palette.primary.main};
    font-weight: 400;
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;

    .MuiChip-label {
      padding: 0 0.25rem;
    }
  }

  .CMuiUserActiveLast {
    margin: 0.25rem 0;
    text-align: center;
    justify-content: center;
  }
`;

const StyledUserAvatar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 0 0.75rem 0;

  .MuiAvatar-root {
    width: 5rem;
    height: 5rem;

    font-size: 3rem;
  }
`;

const StyledUserName = styled(Typography)`
  font-family: "Poppins", Arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.35;
  margin-bottom: 0.25rem;
  text-align: center;
  color: ${cuideoTheme.palette.primary.main};
`;

const StyledAccordion = styled(Accordion)`
  margin: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 72, 120, 0.15);

  &::before {
    display: none;
  }

  .MuiSvgIcon-root {
    width: 1.25rem;
    height: 1.25rem;
    margin: 0 0.5rem 0 0;
    color: ${cuideoTheme.palette.primary.main};
  }

  .MuiAccordionSummary-root {
    min-height: 3rem;
    padding-left: 0.5rem;
    padding-right: 0.75rem;
  }

  .MuiAccordionSummary-content {
    color: ${cuideoTheme.palette.primary.main};
    font-family: "Poppins", Arial, sans-serif;
    font-size: 0.9375rem;
    font-weight: 700;
    line-height: 1.35;
    margin: 0;

    .MuiSvgIcon-root {
      width: 1.25rem;
      height: 1.25rem;
      margin: 0 0.25rem 0 0;
    }
  }

  .MuiAccordionDetails-root {
    display: block;
  }

  &.Mui-expanded {
    margin: 1rem;
  }
`;

const StyledItem = styled.div`
  padding: 0.75rem 0.75rem;
  border-top: 1px solid rgba(0, 72, 120, 0.15);
  box-sizing: border-box;
  width: 100%;
`;

const StyledItemTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.25rem;
`;

const StyledItemLabel = styled(Typography)`
  font-family: "Poppins", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.35;
  color: ${cuideoTheme.palette.primary.main};
`;

const StyledItemControl = styled.div`
  .CMuiUserGroupSelect__control,
  .CMuiUserRoleSelect__control {
    min-width: 9rem;
    height: 2.125em;
    min-height: 2.125rem;
  }

  .CMuiUserGroupSelect__value-container,
  .CMuiUserRoleSelect__value-container {
    padding: 0 0.5rem;
  }

  .CMuiUserGroupSelect__indicator,
  .CMuiUserRoleSelect__indicator {
    padding: 0.375rem 0.5rem;
  }
`;

const StyledItemBottom = styled.div``;

const StyledItemDescription = styled(Typography)`
  font-size: 0.8125rem;
  line-height: 1.35;
  color: ${cuideoTheme.palette.primary.light};
`;

interface IRecruiterAsideProps {
  recruiterId: string;
}

const RecruiterAside: React.FC<IRecruiterAsideProps> = (props) => {
  const { recruiterId } = props;

  const userQuery = useUser({
    userId: recruiterId,
  });

  const { isUserConnected } = useConnectedUsers();
  const updateUserGroupsMutation = useUpdateUserGroups();
  const updateUserRoleMutation = useUpdateUserRole();
  const updateUserMutation = useUpdateUser();
  const updateOnShiftMutation = useUpdateUserOnShift();
  const groups = initRecruiterGroups();

  const handleUserGroupChange = async (
    group: TCoordinationGroup,
    ownerId: string
  ) => {
    await updateUserGroupsMutation.mutateAsync({
      userId: ownerId,
      coordinationGroups: [group.toString()],
    });
  };

  const handleUserRoleChange = async (role: string, ownerId: string) => {
    await updateUserRoleMutation.mutateAsync({
      userId: ownerId,
      role: role as any,
    });
  };

  const handleChangeEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!userQuery.data) {
      return;
    }

    const newEnabled = event.target.checked;
    if (userQuery.data.enabled !== newEnabled) {
      updateUserMutation.mutate({
        userId: userQuery.data.id,
        enabled: newEnabled,
      });
    }
  };

  const handleChangeOnShift = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!userQuery.data) {
      return;
    }

    const newOnShift = event.target.checked;
    updateOnShiftMutation.mutate({
      userId: userQuery.data.id,
      onShift: newOnShift,
    });
  };

  const userOnShift =
    !!userQuery.data &&
    userQuery.data.roles.includes(USER_ROLES.ROLE_ES_ON_SHIFT);

  return (
    <StyledWrapper>
      <StyledUserInfo>
        <StyledUserAvatar>
          <ColoredAvatar fullName={userQuery.data?.fullname} />
        </StyledUserAvatar>
        <StyledUserName>
          {userQuery.isLoading ? <Skeleton /> : userQuery.data?.fullname}
        </StyledUserName>
        {userQuery.isLoading ? (
          <Skeleton />
        ) : (
          !!userQuery.data && <UserTags user={userQuery.data} />
        )}
        {userQuery.isLoading ? (
          <Skeleton />
        ) : (
          !!userQuery.data && (
            <UserActiveLast
              lastActivityTime={userQuery.data?.lastActivityTime}
              active={userQuery.data?.active ?? false}
              enabled={userQuery.data?.enabled}
              isOnline={isUserConnected(recruiterId)}
            />
          )
        )}
      </StyledUserInfo>
      <StyledAccordion elevation={0} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <SettingsIcon />
          <FormattedMessage
            id="RecruiterAside.Configuración"
            defaultMessage="Configuración"
          />
        </AccordionSummary>
        <AccordionDetails>
          <StyledItem>
            <StyledItemTop>
              <StyledItemLabel>
                <FormattedMessage
                  id="RecruiterAside.Squad"
                  defaultMessage="Squad"
                />
              </StyledItemLabel>
              <StyledItemControl>
                {userQuery.isLoading ? (
                  <Skeleton width={100} height={36} />
                ) : (
                  !!userQuery.data && (
                    <UserGroupSelector
                      groups={groups}
                      currentStaffUser={userQuery.data}
                      onGroupChange={handleUserGroupChange}
                      showSquadPrefix={false}
                    />
                  )
                )}
              </StyledItemControl>
            </StyledItemTop>
            <StyledItemBottom>
              <StyledItemDescription>
                <FormattedMessage
                  id="RecruiterAside.El Squad asignado al usuario. Lo puedes cambiar para asignar al usuario a otro Squad."
                  defaultMessage="El Squad asignado al usuario. Lo puedes cambiar para asignar al usuario a otro Squad."
                />
              </StyledItemDescription>
            </StyledItemBottom>
          </StyledItem>
          <StyledItem>
            <StyledItemTop>
              <StyledItemLabel>
                <FormattedMessage
                  id="RecruiterAside.Rol"
                  defaultMessage="Rol"
                />
              </StyledItemLabel>
              <StyledItemControl>
                {userQuery.isLoading ? (
                  <Skeleton width={100} height={36} />
                ) : (
                  !!userQuery.data && (
                    <UserRoleSelector
                      user={userQuery.data}
                      onRoleChange={handleUserRoleChange}
                    />
                  )
                )}
              </StyledItemControl>
            </StyledItemTop>
            <StyledItemBottom>
              <StyledItemDescription>
                <FormattedMessage
                  id="RecruiterAside.El rol del usuario. Puedes cambiarlo entre Recruiter, Promoter y Coordinación"
                  defaultMessage="El rol del usuario. Puedes cambiarlo entre Recruiter, Promoter y Coordinación"
                />
              </StyledItemDescription>
            </StyledItemBottom>
          </StyledItem>
          <StyledItem>
            <StyledItemTop>
              <StyledItemLabel>
                <FormattedMessage
                  id="RecruiterAside.Disponible"
                  defaultMessage="Disponible"
                />
              </StyledItemLabel>
              <StyledItemControl>
                {userQuery.isLoading ? (
                  <Skeleton width={40} height={36} />
                ) : (
                  !!userQuery.data && (
                    <Switch
                      color="primary"
                      checked={!!userQuery.data.enabled}
                      onChange={handleChangeEnabled}
                    />
                  )
                )}
              </StyledItemControl>
            </StyledItemTop>
            <StyledItemBottom>
              <StyledItemDescription>
                <FormattedMessage
                  id="RecruiterAside.Indica si el usuario está disponible. Si no está disponible no se tendrá en cuenta, por ejemplo, a la hora de auto-asignar contratos."
                  defaultMessage="Indica si el usuario está disponible. Si no está disponible no se tendrá en cuenta, por ejemplo, a la hora de auto-asignar contratos."
                />
              </StyledItemDescription>
            </StyledItemBottom>
          </StyledItem>
          <StyledItem>
            <StyledItemTop>
              <StyledItemLabel>
                <FormattedMessage
                  id="RecruiterAside.De guardia"
                  defaultMessage="De guardia"
                />
              </StyledItemLabel>
              <StyledItemControl>
                {userQuery.isLoading ? (
                  <Skeleton width={40} height={36} />
                ) : (
                  !!userQuery.data && (
                    <Switch
                      color="primary"
                      checked={userOnShift}
                      onChange={handleChangeOnShift}
                    />
                  )
                )}
              </StyledItemControl>
            </StyledItemTop>
            <StyledItemBottom>
              <StyledItemDescription>
                <FormattedMessage
                  id="RecruiterAside.Indica si el usuario está de guardia. Al estar de guardia tiene permisos para hacer todas las operaciones de coordinación y se asignarán todos los Contratos entre usuarios de guardia."
                  defaultMessage="Indica si el usuario está de guardia. Al estar de guardia tiene permisos para hacer todas las operaciones de coordinación y se asignarán todos los Contratos entre usuarios de guardia."
                />
              </StyledItemDescription>
            </StyledItemBottom>
          </StyledItem>
        </AccordionDetails>
      </StyledAccordion>
    </StyledWrapper>
  );
};

export default RecruiterAside;
