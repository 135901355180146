import {
  GET_SITE_STATS,
  GET_SITE_STATS_SUCCESS,
  GET_STATISTIC,
  GET_STATISTIC_SUCCESS,
} from "../constants/ActionTypes";

const INIT_STATE: {
  siteStats: any;
  statistics: any;
  siteStatsLoading: boolean;
} = {
  siteStats: null,
  statistics: {},
  siteStatsLoading: false,
};

// eslint-disable-next-line
export default (state = INIT_STATE, action: { type: string; payload: any }) => {
  switch (action.type) {
    case GET_SITE_STATS:
      return {
        ...state,
        siteStatsLoading: true,
      };
    case GET_SITE_STATS_SUCCESS:
      return {
        ...state,
        siteStats: action.payload,
        siteStatsLoading: false,
      };
    case GET_STATISTIC:
      return {
        ...state,
      };
    case GET_STATISTIC_SUCCESS:
      return {
        ...state,
      };
    default:
      return state;
  }
};
