import { apiEmpleo } from "../../util/ApiEmpleo";

export type TUpdateContractSelectionAdvisorNotesRequest = {
  Notas_Asesora_Seleccion: string | null;
};

const apiUpdateContractSelectionAdviserNotes = async (
  contractId: string,
  payload: TUpdateContractSelectionAdvisorNotesRequest
) => {
  const res = await apiEmpleo.patch(
    `contracts/${contractId}/selection-advisor-notes`,
    payload,
    {
      headers: {
        "Content-Type": "application/merge-patch+json",
      },
    }
  );

  return res;
};

export { apiUpdateContractSelectionAdviserNotes };
