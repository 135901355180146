import React from "react";
import styled from "styled-components";
import { TStaffAssignationsCoordinator } from "../../types/StaffAssignationsCoordinator";
import CoordinatorAssignationsItem from "./CoordinatorAssignationsItem";

const StyledWrapper = styled.div``;

const StyledItemWrapper = styled.div`
  border-bottom: 1px solid rgb(239, 239, 239);

  &.CMuiSelected {
    .CMuiCoordinatorAssignationsItem {
      background-color: rgb(235, 235, 235);
    }
  }
`;

interface ICoordinatorAssignationsList {
  coordinators: TStaffAssignationsCoordinator[];
  maxContracts: number;
  maxServices: number;
  onSelectOwner: (ownerId: string | null) => void;
  selectedOwnerId: string | null;
}

const CoordinatorAssignationsList = (props: ICoordinatorAssignationsList) => {
  const {
    coordinators,
    maxContracts,
    maxServices,
    onSelectOwner,
    selectedOwnerId,
  } = props;

  const handleOwnerSelect = (ownerId: string) => {
    onSelectOwner(ownerId);
  };

  return (
    <StyledWrapper>
      {coordinators.map((coordinator) => (
        <StyledItemWrapper
          key={`ca_${coordinator.id}`}
          className={
            selectedOwnerId === coordinator.id ? "CMuiSelected" : undefined
          }
        >
          <CoordinatorAssignationsItem
            coordinator={coordinator}
            maxContracts={maxContracts}
            maxServices={maxServices}
            onSelect={handleOwnerSelect}
          />
        </StyledItemWrapper>
      ))}
    </StyledWrapper>
  );
};

export default CoordinatorAssignationsList;
