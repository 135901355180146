import { Box, Typography } from "@material-ui/core";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ProtectedImage from "../ProtectedImage/ProtectedImage";

const StyledBox = styled(Box)``;

const StyledImg = styled.img``;

const StyledPdf = styled(Box)`
  .CMuiThumbFileNameWrapper {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .CMuiThumbFileName {
    font-size: 0.875rem;
  }
`;

const Thumb = (props: {
  file: any;
  allowZoom?: boolean;
  fixedPdfWidth?: number;
}) => {
  const { file, allowZoom, fixedPdfWidth } = props;
  const [state, setState] = useState({
    loading: false,
    thumb: undefined as any,
    urlFile: undefined,
    type: undefined as string | undefined,
    name: undefined as string | undefined,
  });

  useEffect(() => {
    if (file) {
      if (typeof file === "object") {
        setState((s: any) => {
          return {
            ...s,
            loading: true,
            urlFile: undefined,
            type: file.type,
            name: file.name,
          };
        });
        let reader = new FileReader();

        reader.onloadend = () => {
          setState((s: any) => {
            return { ...s, loading: false, thumb: reader.result };
          });
        };

        reader.readAsDataURL(file);
      } else {
        setState((s: any) => {
          return {
            ...s,
            loading: false,
            urlFile: file,
            type: undefined,
            name: undefined,
          };
        });
      }
    }
  }, [file]);

  return (
    <StyledBox className="CMuiThumb">
      {state.urlFile && (
        <ProtectedImage
          src={state.urlFile as any}
          allowZoom={allowZoom}
          fixedPdfWidth={fixedPdfWidth}
        />
      )}
      {(state.type === "image/jpeg" || state.type === "image/png") && (
        <StyledImg src={state.thumb} alt="ALT" className="CMuiThumbImage" />
      )}
      {state.type === "application/pdf" && (
        <StyledPdf
          display="flex"
          flexDirection="row"
          className="CMuiThumbPdf"
          alignItems="center"
        >
          <PictureAsPdfIcon />
          <Box flexGrow={1} className="CMuiThumbFileNameWrapper">
            <Typography className="CMuiThumbFileName">{state.name}</Typography>
          </Box>
        </StyledPdf>
      )}
    </StyledBox>
  );
};

export default Thumb;
