import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { motion, Variants } from "framer-motion";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import img from "../../assets/images/empleo/registro/step_1.jpg";
import img2 from "../../assets/images/empleo/registro/step_2.jpg";
import { cuideoTheme } from "../themes/defaultTheme";
import SignUpStep1 from "./components/SignUpStep1/SignUpStep1";
import SignUpStep2 from "./components/SignUpStep2/SignUpStep2";
import { TStepSubmitFn } from "./models/CandidateSignUp.model";

const StyledSignUpContainer = styled.div`
  margin-top: 3rem;
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    margin-top: 4rem;
  }
`;

const StyledSignUpContent = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;

const StyledSignUpFormWrapper = styled.div`
  margin: 0;

  @media all and (max-width: ${cuideoTheme.breakpoints.values.sm}px) {
    width: 100%;
    max-width: 34rem;
    margin: 0 auto;
    padding: 2rem 1rem;
    box-sizing: border-box;
  }

  @media all and (min-width: ${cuideoTheme.breakpoints.values
      .sm}px) and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    width: 34rem;
    margin: 0 auto;
    padding: 2rem 1rem;
    box-sizing: border-box;
  }

  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    width: calc(50% - 5rem);
    margin: 2rem auto 0;
    margin-right: calc(50% + 2rem);
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.lg}px) {
    width: 420px;
    margin: 2rem auto 0;
    margin-right: calc(50% + 8rem);
  }
`;

const StyledSignUpStepText = styled(Typography)`
  font-size: 1rem;
  font-weight: 700;
  color: ${cuideoTheme.palette.primary.main};
`;

const StyledSignUpForm = styled.div`
  display: block;
  position: relative;
`;

const StyledStep1Wrapper = styled(motion.div)`
  width: 100%;
  min-width: 100%;
  position: absolute;
  top: 0;
  padding-bottom: 2rem;
`;

const StyledStep2Wrapper = styled(motion.div)`
  width: 100%;
  min-width: 100%;
  position: absolute;
  top: 0;
  padding-bottom: 2rem;
`;

const StyledStep1Image = styled(motion.div)`
  @media all and (max-width: ${cuideoTheme.breakpoints.values.sm}px) {
    display: none;
  }

  @media all and (min-width: ${cuideoTheme.breakpoints.values
      .sm}px) and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    display: none;
  }

  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    background-image: url(${img});
    background-size: cover;
    background-repeat: no-repeat;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    left: 50%;
    z-index: 10;
  }
`;

const StyledStep2Image = styled(motion.div)`
  @media all and (max-width: ${cuideoTheme.breakpoints.values.sm}px) {
    display: none;
  }

  @media all and (min-width: ${cuideoTheme.breakpoints.values
      .sm}px) and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    display: none;
  }

  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    background-image: url(${img2});
    background-size: cover;
    background-repeat: no-repeat;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    left: 50%;
    z-index: 9;
  }
`;

const StyledErrorBox = styled(Typography)`
  font-size: 1rem;
  font-weight: 400;
  color: ${cuideoTheme.palette.error.main};
  background-color: ${(cuideoTheme.palette.error as any)["100"]};
  border: 1px solid ${cuideoTheme.palette.error.main};
  padding: 1rem;
  margin: 1rem auto;
  border-radius: ${cuideoTheme.shape.borderRadius};
`;

const step1Variants: Variants = {
  P1: {
    opacity: 1,
    x: 0,
    visibility: "visible",
    display: "block",
    transitionEnd: {
      position: "inherit",
    },
  },
  P2: {
    opacity: 0,
    x: -100,
    transitionEnd: {
      visibility: "hidden",
      display: "none",
    },
  },
  exit: {
    opacity: 0,
    x: 100,
  },
};

const step2Variants: Variants = {
  P1: {
    opacity: 0,
    x: 100,
    transitionEnd: {
      visibility: "hidden",
      display: "none",
    },
  },
  P2: {
    opacity: 1,
    x: 0,
    visibility: "visible",
    display: "block",
    transitionEnd: {
      position: "inherit",
    },
  },
  exit: {
    opacity: 0,
    x: 0,
  },
};

const i1Variants: Variants = {
  enter: {
    opacity: 1,
    transition: {
      duration: 0.5,
      delay: 0.1,
    },
  },
  P1: {
    opacity: 1,
  },
  P2: {
    opacity: 0,
  },
  exit: {
    opacity: 0,
  },
};

const i2Variants: Variants = {
  enter: {
    opacity: 1,
    transition: {
      duration: 0.5,
      delay: 0.1,
    },
  },
  P1: {
    opacity: 0,
  },
  P2: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

interface ICandidateSignUpPageProps {
  step: number;
  onStepSubmit: TStepSubmitFn;
  registerStatus: string;
  registerMessage: string;
  onUserSignUpResetError: () => void;
}

const CandidateSignUpPage: React.FC<ICandidateSignUpPageProps> = (props) => {
  const {
    step,
    onStepSubmit,
    registerStatus,
    registerMessage,
    onUserSignUpResetError,
  } = props;

  const poseImg = step === 2 ? "P2" : "enter";
  const poseImg2 = step === 2 ? "P2" : "P1";

  return (
    <StyledSignUpContainer>
      <StyledSignUpContent data-step={step}>
        <StyledSignUpFormWrapper>
          <StyledSignUpStepText>
            <FormattedMessage
              id="CandidateSignUp.Paso {step}/2"
              defaultMessage="Paso {step}/2"
              values={{
                step,
              }}
            />
          </StyledSignUpStepText>
          <StyledSignUpForm>
            <StyledStep1Wrapper
              initial="exit"
              animate={step === 1 ? "P1" : "P2"}
              variants={step1Variants}
            >
              <SignUpStep1 onSubmit={onStepSubmit} />
            </StyledStep1Wrapper>
            <StyledStep2Wrapper
              initial="exit"
              animate={step === 1 ? "P1" : "P2"}
              variants={step2Variants}
            >
              <SignUpStep2 onSubmit={onStepSubmit} />
            </StyledStep2Wrapper>
          </StyledSignUpForm>
        </StyledSignUpFormWrapper>
        <StyledStep1Image
          initial="exit"
          variants={i1Variants}
          animate={poseImg}
        />
        <StyledStep2Image
          initial="exit"
          variants={i2Variants}
          animate={poseImg2}
        />
      </StyledSignUpContent>
      {registerStatus === "error" && (
        <Dialog open={!!registerStatus}>
          <DialogTitle>
            <FormattedMessage
              id="CandidateSignUp.Error"
              defaultMessage="Error"
            />
          </DialogTitle>
          <DialogContent style={{ textAlign: "center" }}>
            <Box margin={8}>
              <Typography color="primary">
                <FormattedMessage
                  id="CandidateSignUp.Hemos encontrado un error al registrar el candidato"
                  defaultMessage="Hemos encontrado un error al registrar el candidato"
                />
              </Typography>
              <StyledErrorBox>{registerMessage}</StyledErrorBox>
              <Typography color="primary">
                <FormattedMessage
                  id="CandidateSignUp.Puedes volver a intentarlo"
                  defaultMessage="Puedes volver a intentarlo"
                />
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="outlined"
              onClick={onUserSignUpResetError}
            >
              <FormattedMessage
                id="CandidateSignUp.Aceptar"
                defaultMessage="Aceptar"
              />
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </StyledSignUpContainer>
  );
};

export default CandidateSignUpPage;
