import { Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import {
  COLOR_SUCCESS,
  cuideoTheme,
} from "../../../../../../containers/themes/defaultTheme";
import { ColoredAvatar } from "../../../user";
import StorageIcon from "@material-ui/icons/Storage";

const StyledEvent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: flex-start;

  &.CMui-success {
    .CMuiEventText {
      color: ${COLOR_SUCCESS};
    }
  }
`;

const StyledLeft = styled.div`
  .MuiAvatar-root {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1rem;
  }
`;

const StyledRight = styled.div``;

const StyledEventText = styled(Typography)`
  font-size: 0.875rem;
  color: ${cuideoTheme.palette.primary.main};
  line-height: 1.15;
`;

const StyledEventMeta = styled(Typography)`
  font-size: 0.75rem;
  color: #888;
  line-height: 1.15;
`;

interface IContractEventProps {
  event: any;
}

const ContractEvent: React.FC<IContractEventProps> = (props) => {
  const { event } = props;

  if (event.eventType === "valuation_contracted") {
    return (
      <StyledEvent className="CMui-success">
        <StyledLeft>
          <ColoredAvatar fullName={event.user.fullname} />
        </StyledLeft>
        <StyledRight>
          <StyledEventText className="CMuiEventText">
            <FormattedMessage
              id="Event.{userName} ha movido la valoración de {candidateName} a Contratado"
              defaultMessage="{userName} ha movido la valoración de {candidateName} a Contratado"
              values={{
                userName: <b>{event.user.fullname}</b>,
                candidateName: (
                  <b>
                    {event.employee.Nombre + " " + event.employee.Apellidos}
                  </b>
                ),
              }}
            />
          </StyledEventText>
          <StyledEventMeta>{moment(event.createdAt).fromNow()}</StyledEventMeta>
        </StyledRight>
      </StyledEvent>
    );
  }

  if (event.eventType === "contract_auto_assign") {
    return (
      <StyledEvent>
        <StyledLeft>
          <StorageIcon />
        </StyledLeft>
        <StyledRight>
          <StyledEventText className="CMuiEventText">
            {event.context.rule === "assigned_by_squad" ? (
              <FormattedMessage
                id="Event.Se ha auto-asignado el contrato a {userName} por {ruleText}"
                defaultMessage="Se ha auto-asignado el contrato a {userName} por {ruleText}"
                values={{
                  userName: <b>{event.context.assignedTo.userFullName}</b>,
                  ruleText: <b>Squad: {event.context.details.squad}</b>,
                }}
              />
            ) : event.context.rule ===
              "assigned_by_recruiter_with_less_contracts" ? (
              <FormattedMessage
                id="Event.Se ha auto-asignado el contrato a {userName} por ser recruiter con menos contractos"
                defaultMessage="Se ha auto-asignado el contrato a {userName} por ser recruiter con menos contractos"
                values={{
                  userName: <b>{event.context.assignedTo.userFullName}</b>,
                }}
              />
            ) : event.context.rule === "assigned_by_shift" ? (
              <FormattedMessage
                id="Event.Se ha auto-asignado el contrato a {userName} por estar de guardia"
                defaultMessage="Se ha auto-asignado el contrato a {userName} por estar de guardia"
                values={{
                  userName: <b>{event.context.assignedTo.userFullName}</b>,
                }}
              />
            ) : (
              <FormattedMessage
                id="Event.Se ha auto-asignado el contrato a {userName}"
                defaultMessage="Se ha auto-asignado el contrato a {userName}"
                values={{
                  userName: <b>{event.context.assignedTo.userFullName}</b>,
                }}
              />
            )}
          </StyledEventText>
          <StyledEventMeta>{moment(event.createdAt).fromNow()}</StyledEventMeta>
        </StyledRight>
      </StyledEvent>
    );
  }

  if (event.eventType === "valuation_client_face_to_face") {
    return (
      <StyledEvent>
        <StyledLeft>
          <ColoredAvatar fullName={event.user.fullname} />
        </StyledLeft>
        <StyledRight>
          <StyledEventText>
            <FormattedMessage
              id="Event.{userName} ha movido la valoración de {candidateName} a Presencial Cliente"
              defaultMessage="{userName} ha movido la valoración de {candidateName} a Presencial Cliente"
              values={{
                userName: <b>{event.user.fullname}</b>,
                candidateName: (
                  <b>
                    {event.employee.Nombre + " " + event.employee.Apellidos}
                  </b>
                ),
              }}
            />
          </StyledEventText>
          <StyledEventMeta>{moment(event.createdAt).fromNow()}</StyledEventMeta>
        </StyledRight>
      </StyledEvent>
    );
  }

  if (event.eventType === "valuation_cv_client") {
    return (
      <StyledEvent>
        <StyledLeft>
          <ColoredAvatar fullName={event.user.fullname} />
        </StyledLeft>
        <StyledRight>
          <StyledEventText>
            <FormattedMessage
              id="Event.{userName} ha movido la valoración de {candidateName} a CV Cliente"
              defaultMessage="{userName} ha movido la valoración de {candidateName} a CV Cliente"
              values={{
                userName: <b>{event.user.fullname}</b>,
                candidateName: (
                  <b>
                    {event.employee.Nombre + " " + event.employee.Apellidos}
                  </b>
                ),
              }}
            />
          </StyledEventText>
          <StyledEventMeta>{moment(event.createdAt).fromNow()}</StyledEventMeta>
        </StyledRight>
      </StyledEvent>
    );
  }

  if (event.eventType === "contract_update_phase") {
    return (
      <StyledEvent>
        <StyledLeft>
          <ColoredAvatar fullName={event.user.fullname} />
        </StyledLeft>
        <StyledRight>
          <StyledEventText>
            {event.context.changes.phase?.old_value === "recruit" &&
              event.context.changes.phase?.new_value === "promote" && (
                <FormattedMessage
                  id="Event.{userName} ha pasado el contrato al gestor"
                  defaultMessage="{userName} ha pasado el contrato al gestor"
                  values={{
                    userName: <b>{event.user.fullname}</b>,
                  }}
                />
              )}
            {event.context.changes.phase?.old_value === "promote" &&
              event.context.changes.phase?.new_value === "recruit" && (
                <FormattedMessage
                  id="Event.{userName} ha devuelto el contrato al recruiter"
                  defaultMessage="{userName} ha devuelto el contrato al recruiter"
                  values={{
                    userName: <b>{event.user.fullname}</b>,
                  }}
                />
              )}
          </StyledEventText>
          <StyledEventMeta>{moment(event.createdAt).fromNow()}</StyledEventMeta>
        </StyledRight>
      </StyledEvent>
    );
  }

  if (event.eventType === "contract_cv_follow_up") {
    return (
      <StyledEvent>
        <StyledLeft>
          <ColoredAvatar fullName={event.user.fullname} />
        </StyledLeft>
        <StyledRight>
          <StyledEventText>
            <FormattedMessage
              id="Event.{userName} ha hecho el seguimiento de los candidatos en estado CV"
              defaultMessage="{userName} ha hecho el seguimiento de los candidatos en estado CV"
              values={{
                userName: <b>{event.user.fullname}</b>,
              }}
            />
          </StyledEventText>
          <StyledEventMeta>{moment(event.createdAt).fromNow()}</StyledEventMeta>
        </StyledRight>
      </StyledEvent>
    );
  }

  return null;
};

export default ContractEvent;
