import React from "react";
import styled from "styled-components";
import { TGetServicesInfoManagerCollectionItem } from "../../../../../../../../api/service/service.types";
import { TUserGroupAssignations } from "../../../../../recruitersManager/types/UserGroupAssignations.types";
import ReloadButton from "../../../../components/ReloadButton/ReloadButton";
import { TStaffAssignationsCoordinator } from "../../types/StaffAssignationsCoordinator";
import CoordinatorAssignationsLegend from "./CoordinatorAssignationsLegend";
import GroupAssignationsList from "./GroupAssignationsList";
import UnassignedServices from "./UnassignedServices";
import UnValuedServices from "./UnValuedServices";

const StyledWrapper = styled.div`
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const StyledFixedHeightTop = styled.div`
  height: 8rem;
  box-sizing: border-box;
  border-bottom: 0.125rem solid rgb(235, 235, 235);
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

const StyledFixedHeightTopLeft = styled.div`
  .CMuiReloadButton {
    width: 6rem;
    height: 100%;
    font-weight: 600;

    .MuiSvgIcon-root {
      height: 2.25rem;
      width: 2.25rem;
      color: #fff;
    }

    &.Mui-disabled {
      .MuiSvgIcon-root {
        color: #999;
      }
    }
  }
`;

const StyledFixedHeightTopRight = styled.div``;

const StyledVariableBottom = styled.div`
  overflow-y: scroll;
  height: 100%;
  max-height: calc(100vh - 12rem);
  box-sizing: border-box;

  ::-webkit-scrollbar {
    width: 0.375rem;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 72, 120, 0.25);
    width: 0.375rem;
    border-radius: 0.125rem;
  }
`;

const StyledFixedBottom = styled.div`
  height: 8rem;
  box-sizing: border-box;
`;

const StyledListWrapper = styled.div`
  background-color: #fff;
`;

interface IStaffAssignations {
  reloadButtonStatus: "idle" | "loading" | "error" | "success";
  onReloadButtonClick: () => void;
  isLoading: boolean;
  coordinators: TStaffAssignationsCoordinator[];
  groups: TUserGroupAssignations[];
  unassignedServices: TGetServicesInfoManagerCollectionItem[];
  numUnValuedServices: number;
  maxContracts: number;
  maxServices: number;
  onSelectAllUnvaluedServices: () => void;
  onSelectOwner: (ownerId: string | null) => void;
  selectedOwnerId: string | null;
  onSelectUnassignedServices: () => void;
  maxGroupServices: number;
  maxGroupContracts: number;
}

const StaffAssignations = (props: IStaffAssignations) => {
  const {
    reloadButtonStatus,
    onReloadButtonClick,
    groups,
    unassignedServices,
    numUnValuedServices,
    onSelectAllUnvaluedServices,
    onSelectOwner,
    selectedOwnerId,
    onSelectUnassignedServices,
    maxGroupContracts,
    maxGroupServices,
  } = props;

  const handleSelectUnvaluedServices = () => {
    onSelectAllUnvaluedServices();
  };

  const handleSelectUnassignedServices = () => {
    onSelectUnassignedServices();
  };

  const numUnassignedServices = unassignedServices.length;

  return (
    <StyledWrapper>
      <StyledFixedHeightTop>
        <StyledFixedHeightTopLeft>
          <ReloadButton
            status={reloadButtonStatus}
            onClick={onReloadButtonClick}
          />
        </StyledFixedHeightTopLeft>
        <StyledFixedHeightTopRight>
          <UnValuedServices
            numUnValuedServices={numUnValuedServices}
            onClick={handleSelectUnvaluedServices}
          />
          <UnassignedServices
            numUnassignedServices={numUnassignedServices}
            onClick={handleSelectUnassignedServices}
          />
        </StyledFixedHeightTopRight>
      </StyledFixedHeightTop>
      <StyledVariableBottom>
        <StyledListWrapper>
          <GroupAssignationsList
            groups={groups}
            selectedOwnerId={selectedOwnerId}
            onSelectOwner={onSelectOwner}
            maxGroupServices={maxGroupServices}
            maxGroupContracts={maxGroupContracts}
          />
        </StyledListWrapper>
      </StyledVariableBottom>
      <StyledFixedBottom>
        <CoordinatorAssignationsLegend />
      </StyledFixedBottom>
    </StyledWrapper>
  );
};

export default StaffAssignations;
