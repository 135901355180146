import { FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core";
import { connect, getIn } from "formik";
import * as React from "react";
import styled from "styled-components";
import cuideoTheme from "../../containers/themes/defaultTheme";
import { StyledField, StyledLabel } from "./StyledComponents";

const StyledFormControlLabel = styled(FormControlLabel)<{
  compact?: string;
  customicons: boolean;
}>`
  border-radius: ${cuideoTheme.shape.borderRadius}px;
  height: ${(cuideoTheme.overrides as any).MuiInputBase.root.height};
  box-sizing: border-box;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  ${(props) => {
    if (props.checked) {
      return `
        color: ${cuideoTheme.palette.primary.main};
        background-color: #dcf1f2;
        border: 1px solid ${cuideoTheme.palette.secondary.main};
        & .MuiTypography-body1 {
          font-weight: 700;
        }
      `;
    }
    return `
      color: ${cuideoTheme.palette.text.secondary};
      background-color: ${cuideoTheme.palette.common.white};
      border: 1px solid #c2d1da;
      & .MuiTypography-body1 {
        font-weight: 400;
      }
    `;
  }}
  width: 100%;
  margin-left: 0;
  margin-right: 0;

  .MuiTypography-body1 {
    font-size: 1rem;
    line-height: 1.2;
  }

  ${(props) => {
    if (props.customicons) {
      return `
      .MuiIconButton-root {
        padding: 0;
      }
    `;
    }
  }}

  ${(props) => {
    if (props.compact) {
      return `
        height: 2.2rem;

        .MuiTypography-body1 {
          font-size: 0.9rem;
        }
        .MuiSvgIcon-root {
          height: 1rem;
          width: 1rem;
        }

              `;
    }
  }}
`;

const StyledIcon = styled.img`
  max-width: 2.75rem;
  max-height: 2.75rem;
`;
const StyledUncheckedIcon = styled.img`
  max-width: 2.75rem;
  max-height: 2.75rem;
  opacity: 0.4;
`;

interface Props {
  name: string;
  required?: boolean;
  variant?: "standard" | "outlined" | "filled" | "compact";
  size?: "medium" | "small";
  margin?: "nomargin" | "normal";
  label?: any;
  closeMenuOnSelect?: boolean;
  placeholder?: any;
  onChange?: any;
  options: {
    label: any;
    value: string;
    icon?: any;
    checkedIcon?: any;
    width?: {
      xs?: number;
      sm?: number;
      md?: number;
      lg?: number;
    };
  }[];
  isMulti?: boolean;
  fullWidth?: boolean;
  color?: string;
  value?: any;
}

interface State {}

class FormikRadioGroup extends React.Component<any, State> {
  shouldComponentUpdate(nextProps: any, nextState: any) {
    const next = nextProps.formik;
    const cur = this.props.formik;
    const name = this.props.name;
    if (
      getIn(next.values, name) !== getIn(cur.values, name) ||
      getIn(next.errors, name) !== getIn(cur.errors, name)
    ) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    this.props.formik.registerField(this.props.name, {
      validate: this.props.validate,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.name !== prevProps.name) {
      this.props.formik.unregisterField(prevProps.name);
      this.props.formik.registerField(this.props.name, {
        validate: this.props.validate,
      });
    }

    if (this.props.validate !== prevProps.validate) {
      this.props.formik.registerField(this.props.name, {
        validate: this.props.validate,
      });
    }
  }

  componentWillUnmount() {
    this.props.formik.unregisterField(this.props.name);
  }

  render() {
    const {
      validate,
      name,
      variant,
      label,
      required,
      helperText,
      margin,
      size,
      fullWidth,
      // value,
      options,
      color,
      ...others
    } = this.props;
    const formik = this.props.formik;

    const field = {
      value: getIn(formik.values, name),
      name,
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
    };
    const error = getIn(formik.errors, name);
    const touched = !!getIn(formik.touched, name);

    return (
      <StyledField
        fullWidth
        margin={margin || "normal"}
        size={size || "medium"}
      >
        {label && (
          <StyledLabel
            error={!!error && touched ? "error" : undefined}
            size={size || "medium"}
          >
            {label}
            {required && <span className="required-field"> *</span>}
          </StyledLabel>
        )}

        <RadioGroup name={name}>
          <Grid container spacing={4} alignItems="center">
            {options.map((option: any) => {
              const checked = option.value === field.value;
              const radioControl =
                option.icon && option.checkedIcon ? (
                  <Radio
                    {...field}
                    value={option.value}
                    {...others}
                    icon={<StyledUncheckedIcon src={option.icon} />}
                    color={color || "primary"}
                    checkedIcon={<StyledIcon src={option.checkedIcon} />}
                    checked={checked}
                  />
                ) : (
                  <Radio
                    {...field}
                    value={option.value}
                    {...others}
                    color={color || "primary"}
                  />
                );

              return (
                <Grid
                  key={`${name}_${option.value}`}
                  item
                  xs={option.width && option.width.xs ? option.width.xs : 6}
                  sm={
                    option.width && option.width.sm
                      ? option.width.sm
                      : undefined
                  }
                  md={
                    option.width && option.width.md
                      ? option.width.md
                      : undefined
                  }
                  lg={
                    option.width && option.width.lg
                      ? option.width.lg
                      : undefined
                  }
                >
                  <StyledFormControlLabel
                    control={radioControl}
                    label={option.label}
                    checked={checked}
                    compact={variant === "compact" ? "compact" : undefined}
                    customicons={option.icon && option.checkedIcon}
                  />
                </Grid>
              );
            })}
          </Grid>
        </RadioGroup>
      </StyledField>
    );
  }
}

export default connect<Props, State>(FormikRadioGroup as any);
