import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { connect, FormikContextType, getIn } from "formik";
import React, { Fragment } from "react";
import styled from "styled-components";
import cuideoTheme from "../../containers/themes/defaultTheme";
import Thumb from "./Thumb";

const StyledField = styled(FormControl)`
  border: 1px solid rgba(0, 72, 120, 0.25);
  border-radius: 0.25rem;
  padding: 1rem 0.75rem;
  box-sizing: border-box;
  background-color: #e4ebef;
  z-index: 2;

  .CMuiAttachCifLabel {
    margin-top: -1.625rem;
    background-color: inherit;
    font-size: 1rem;
    color: rgb(51, 111, 149);
    background-color: inherit;
    width: fit-content;
  }
  .CMuiAttachCifButtons {
    text-align: center;
    padding: 1rem 0;
  }

  .CMuiAttachCifBoxContent {
    margin: 0.5rem 0 0;

    .CMuiAttachCifBoxContentRow {
      display: flex;
      flex-direction: row;
      align-items: stretch;

      .CMuiAttachCifContentWrapper {
        position: relative;
        height: 10rem;
        overflow: hidden;
      }

      .CMuiAttachCifBoxObverse {
        width: 50%;
        margin-right: 0.5rem;
      }
      .CMuiAttachCifBoxBack {
        width: 50%;
        margin-left: 0.5rem;
      }
      .CMuiThumb {
        .CMuiProtectedImgImg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .CMuiAttachCifBoxContentLabel {
      font-size: 0.9375rem;
      font-weight: 600;
      color: ${cuideoTheme.palette.primary.main};
    }

    .CMuiAttachCifChangeButtons {
      margin: 1rem 0 0;
      text-align: center;
    }
    .CMuiAttachCifRemoveButton {
      background-color: ${cuideoTheme.palette.error.main};
    }
  }

  .CMuiThumb {
    .CMuiThumbImage {
      max-height: 10rem;
      width: auto;
      max-width: 100%;
      display: block;
      margin: 0 auto;
    }
  }
`;

const StyledDialog = styled(Dialog)`
  .CMuiThumb {
    .CMuiThumbImage {
      max-height: 8rem;
      width: auto;
      max-width: 100%;
      display: block;
      margin: 0 auto;
    }
  }

  .CMuiAttachCifStep2,
  .CMuiAttachCifStep1 {
    margin-bottom: 1rem;
    border: 0.0625rem solid rgba(0, 72, 120, 0.25);
    border-radius: 0.25rem;
    overflow: hidden;
  }
  .CMuiAttachCifStepTitle {
    padding: 0.25rem 0.5rem;
    background-color: rgb(0, 72, 120);
    color: #fff;
    font-weight: 600;
    line-height: 1.35;
    font-size: 1rem;
  }
  .CMuiAttachCifStepContent {
    padding: 0.25rem 0.5rem;
    text-align: center;

    .CMuiAttachCifStepText {
      font-size: 0.875rem;
    }
    .CMuiAttachCifStepHelpText {
      font-size: 0.75rem;
      color: #666;
    }
    .CMuiAttachCifStepErrorText {
      font-size: 0.875rem;
      color: ${cuideoTheme.palette.error.main};
    }
  }

  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    .CMuiAttachCifStep2,
    .CMuiAttachCifStep1 {
      min-width: 22rem;
    }
  }
`;

const StyledInputWrapper = styled.div`
  position: relative;
  width: 9rem;
  margin: 0.5rem auto;
`;

const StyledInput = styled.input`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
`;

const StyledButton = styled(Button)`
  background-color: rgba(0, 72, 120, 0.25);
  color: ${cuideoTheme.palette.primary.main};
  text-transform: none;
  width: 9rem;
  height: 3rem;
`;

interface IFormikAttachCifProps {
  obverseFieldName: string;
  backFieldName: string;
  label?: React.ReactNode | string;
  helperText?: React.ReactNode | string;
  fullWidth?: boolean;
  changeLabel?: React.ReactNode | string;
  formik?: FormikContextType<any>;
  validFileTypes?: string[];
  allowZoom?: boolean;
  messages?: {
    label?: string;
    dialogTitle?: string;
    firstStepTitle?: string;
    firstStepText?: string;
    firstStepHelper?: string;
    secondStepTitle?: string;
    secondStepAwait?: string;
    secondStepText?: string;
    secondStepHelper?: string;
    okLabel?: string;
    cancelLabel?: string;
    addCifLabel?: string;
    uploadLabel?: string;
    removeCifLabel?: string;
    changeCifLabel?: string;
    errorFileFormat?: string;
    errorFileSize?: string;
  };
}

type TFormikAttachCifState = {
  open: boolean;
  valid: boolean;
  obverseFile: any;
  backFile: any;
  obverseFileError: string | null;
  backFileError: string | null;
};

class FormikAttachCif extends React.Component<
  IFormikAttachCifProps,
  TFormikAttachCifState
> {
  constructor(props: IFormikAttachCifProps) {
    super(props);

    this.state = {
      open: false,
      valid: false,
      obverseFile: null,
      backFile: null,
      obverseFileError: null,
      backFileError: null,
    };
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleDialogOpen = this.handleDialogOpen.bind(this);
    this.handleDialogAccept = this.handleDialogAccept.bind(this);
    this.handleRemoveCif = this.handleRemoveCif.bind(this);
    this.handleImageUpdate = this.handleImageUpdate.bind(this);
  }

  shouldComponentUpdate(
    nextProps: IFormikAttachCifProps,
    nextState: TFormikAttachCifState
  ) {
    return true;
  }

  componentDidMount() {}

  componentDidUpdate(prevProps: IFormikAttachCifProps) {}

  handleDialogOpen() {
    this.setState({
      ...this.state,
      open: true,
      obverseFile: null,
      backFile: null,
      obverseFileError: null,
      backFileError: null,
    });
  }

  handleDialogClose() {
    this.setState({ ...this.state, open: false });
  }

  handleDialogAccept() {
    if (this.props.formik) {
      this.props.formik.setFieldValue(
        this.props.obverseFieldName,
        this.state.obverseFile,
        false
      );
      this.props.formik.setFieldValue(
        this.props.backFieldName,
        this.state.backFile,
        false
      );
    }

    this.setState({
      ...this.state,
      open: false,
    });
  }

  handleRemoveCif() {
    if (this.props.formik) {
      this.props.formik.setFieldValue(this.props.obverseFieldName, null, false);
      this.props.formik.setFieldValue(this.props.backFieldName, null, false);
    }
  }

  handleImageUpdate(fieldName: string, file: any) {
    const messages = this.props.messages || {};

    const validFileTypes = this.props.validFileTypes || [
      "image/jpeg",
      "application/pdf",
    ];

    if (!validFileTypes.includes(file.type)) {
      this.setState({
        ...this.state,
        [`${fieldName}Error`]: messages.errorFileFormat
          ? messages.errorFileFormat
          : "El archivo debe ser JPG o PDF",
      });
      return;
    }
    if (file.size === 0) {
      this.setState({
        ...this.state,
        [`${fieldName}Error`]: messages.errorFileSize
          ? messages.errorFileSize
          : "El archivo parece no tener contenido",
      });
      return;
    }
    if (file.size > 4000000) {
      this.setState({
        ...this.state,
        [`${fieldName}Error`]: messages.errorFileSize
          ? messages.errorFileSize
          : "El archivo debe tener menos de 4MB",
      });
      return;
    }
    this.setState({
      ...this.state,
      [`${fieldName}Error`]: null,
      [`${fieldName}`]: file,
    });
  }

  render() {
    const {
      fullWidth,
      helperText,
      obverseFieldName,
      backFieldName,
      allowZoom,
    } = this.props;

    const messages = this.props.messages || {};

    const label = messages.label || "Adjuntar DNI/NIE";
    const dialogTitle = messages.dialogTitle || "Adjuntar DNI/NIE";
    const firstStepTitle = messages.firstStepTitle || "Anverso";
    const firstStepText =
      messages.firstStepText ||
      "Primero adjunta una imagen del anverso de tu DNI/NIE";
    const firstStepHelper =
      messages.firstStepHelper ||
      "Debes subir un archivo de tipo JPG o PDF. Es preferible que sea JPG.";
    const secondStepTitle = messages.secondStepTitle || "Reverso";
    const secondStepText =
      messages.secondStepText || "Ahora debes añadir el reverso de tu DNI/NIE";
    const secondStepAwait =
      messages.secondStepAwait || "Primero adjunta en anverso de tu DNI/NIE";
    const secondStepHelper =
      messages.secondStepHelper ||
      "Igual que en el caso anterior debe ser un archivo tipo JPG o PDF";
    const okLabel = messages.okLabel || "Aceptar";
    const cancelLabel = messages.cancelLabel || "Cancelar";
    const addCifLabel =
      messages.addCifLabel || "Haz click aquí para adjuntar tu DNI/NIE";
    const uploadLabel = messages.uploadLabel || "Elegir archivo";
    const removeCifLabel = messages.removeCifLabel || "Eliminar";
    const changeCifLabel = messages.changeCifLabel || "Cambiar";

    const obverseValue = this.props.formik
      ? getIn(this.props.formik.values, obverseFieldName)
      : null;
    const backValue = this.props.formik
      ? getIn(this.props.formik.values, backFieldName)
      : null;

    return (
      <StyledField fullWidth={fullWidth} className="CMuiAttachCif">
        {label && <FormLabel className="CMuiAttachCifLabel">{label}</FormLabel>}
        {(!obverseValue || !backValue) && (
          <Box className="CMuiAttachCifButtons">
            <Button
              className="CMuiAttachCifAddButton"
              variant="contained"
              color="primary"
              onClick={this.handleDialogOpen}
            >
              {addCifLabel}
            </Button>
          </Box>
        )}
        {obverseValue && backValue && (
          <Box className="CMuiAttachCifBoxContent">
            <Box className="CMuiAttachCifBoxContentRow">
              <Box
                className="CMuiAttachCifBoxObverse"
                display="flex"
                flexDirection="column"
              >
                <Box>
                  <Typography className="CMuiAttachCifBoxContentLabel">
                    {firstStepTitle}
                  </Typography>
                </Box>
                <Box
                  flexGrow={1}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  className="CMuiAttachCifContentWrapper"
                >
                  <Thumb file={obverseValue} allowZoom={allowZoom} />
                </Box>
              </Box>
              <Box
                className="CMuiAttachCifBoxBack"
                display="flex"
                flexDirection="column"
              >
                <Box>
                  <Typography className="CMuiAttachCifBoxContentLabel">
                    {secondStepTitle}
                  </Typography>
                </Box>
                <Box
                  flexGrow={1}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  className="CMuiAttachCifContentWrapper"
                >
                  <Thumb file={backValue} allowZoom={allowZoom} />
                </Box>
              </Box>
            </Box>
            <Grid
              container
              spacing={4}
              className="CMuiAttachCifChangeButtons"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <Button
                  className="CMuiAttachCifRemoveButton"
                  color="primary"
                  variant="contained"
                  onClick={this.handleRemoveCif}
                >
                  {removeCifLabel}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className="CMuiAttachCifAddButton"
                  variant="contained"
                  color="primary"
                  onClick={this.handleDialogOpen}
                >
                  {changeCifLabel}
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
        {helperText && (
          <FormHelperText className="CMuiAttachCifHelperText">
            {helperText}
          </FormHelperText>
        )}
        <StyledDialog
          open={this.state.open}
          className="CMuiAttachCifDialog"
          onClose={this.handleDialogClose}
        >
          <DialogTitle className="CMuiAttachCifDialogTitle">
            {dialogTitle}
          </DialogTitle>
          <DialogContent className="CMuiAttachCifDialogContent">
            <Box className="CMuiAttachCifStep1">
              <Typography className="CMuiAttachCifStepTitle">
                {firstStepTitle}
              </Typography>
              <Box className="CMuiAttachCifStepContent">
                {this.state.obverseFile ? (
                  <Fragment>
                    <Thumb file={this.state.obverseFile} />
                  </Fragment>
                ) : (
                  <Fragment>
                    <Typography className="CMuiAttachCifStepText">
                      {firstStepText}
                    </Typography>
                    <StyledInputWrapper>
                      <StyledInput
                        type="file"
                        id="obverse"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          // Some Android could not put any item in the File List
                          if (
                            event.currentTarget.files &&
                            event.currentTarget.files[0]
                          ) {
                            this.handleImageUpdate(
                              "obverseFile",
                              event.currentTarget.files[0]
                            );
                          } else {
                            // let msg = `FormikAttchCif cannot find files after onChange event on obverseFile`;
                            // msg +=
                            //   "\n  - currentTarget.files type: " +
                            //   typeof event.currentTarget.files;
                            // msg +=
                            //   "\n  - object properties: " +
                            //   Object.getOwnPropertyNames(
                            //     event.currentTarget.files
                            //   ).join(",");
                            // msg +=
                            //   "\n  - object length (if it has): " +
                            //   (event.currentTarget.files as any).length;
                            // reportError(msg, window.location.href, 490);
                          }
                        }}
                      />
                      <StyledButton startIcon={<AddIcon />}>
                        {uploadLabel}
                      </StyledButton>
                    </StyledInputWrapper>
                    {this.state.obverseFileError ? (
                      <Typography className="CMuiAttachCifStepErrorText">
                        {this.state.obverseFileError}
                      </Typography>
                    ) : (
                      <Typography className="CMuiAttachCifStepHelpText">
                        {firstStepHelper}
                      </Typography>
                    )}
                  </Fragment>
                )}
              </Box>
            </Box>
            <Box className="CMuiAttachCifStep2">
              <Typography className="CMuiAttachCifStepTitle">
                {secondStepTitle}
              </Typography>
              <Box className="CMuiAttachCifStepContent">
                {this.state.backFile ? (
                  <Fragment>
                    <Thumb file={this.state.backFile} />
                  </Fragment>
                ) : (
                  <Fragment>
                    {this.state.obverseFile ? (
                      <Fragment>
                        <Typography className="CMuiAttachCifStepText">
                          {secondStepText}
                        </Typography>
                        <StyledInputWrapper>
                          <StyledInput
                            type="file"
                            id="back"
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              if (
                                event.currentTarget.files &&
                                event.currentTarget.files[0]
                              ) {
                                this.handleImageUpdate(
                                  "backFile",
                                  event.currentTarget.files[0]
                                );
                              } else {
                                // let msg = `FormikAttchCif cannot find files after onChange event on backFile`;
                                // msg +=
                                //   "\n  - currentTarget.files type: " +
                                //   typeof event.currentTarget.files;
                                // msg +=
                                //   "\n  - object properties: " +
                                //   Object.getOwnPropertyNames(
                                //     event.currentTarget.files
                                //   ).join(",");
                                // reportError(msg, window.location.href, 490);
                              }
                            }}
                          />
                          <StyledButton startIcon={<AddIcon />}>
                            {uploadLabel}
                          </StyledButton>
                        </StyledInputWrapper>
                        {this.state.backFileError ? (
                          <Typography className="CMuiAttachCifStepErrorText">
                            {this.state.backFileError}
                          </Typography>
                        ) : (
                          <Typography className="CMuiAttachCifStepHelpText">
                            {secondStepHelper}
                          </Typography>
                        )}
                      </Fragment>
                    ) : (
                      <Typography className="CMuiAttachCifStepText">
                        {secondStepAwait}
                      </Typography>
                    )}
                  </Fragment>
                )}
              </Box>
            </Box>
          </DialogContent>
          <DialogActions className="CMuiAttachCifDialogActions">
            <Button
              className="CMuiAttachCifCancelButton"
              variant="outlined"
              onClick={this.handleDialogClose}
              color="primary"
            >
              {cancelLabel}
            </Button>
            <Button
              className="CMuiAttachCifAcceptButton"
              variant="contained"
              color="primary"
              onClick={this.handleDialogAccept}
              disabled={!this.state.obverseFile || !this.state.backFile}
            >
              {okLabel}
            </Button>
          </DialogActions>
        </StyledDialog>
      </StyledField>
    );
  }
}

export default connect<IFormikAttachCifProps, TFormikAttachCifState>(
  FormikAttachCif as any
);
