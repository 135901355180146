import { useQuery } from "react-query";
import { apiEmpleo } from "../../../util/ApiEmpleo";

export default function useEmployeeNotesQuery(
  parentId: string,
  moduleName: string
) {
  return useQuery(["notes", parentId, moduleName], async () => {
    const params = {
      parentId: parentId,
      zohoModule: moduleName,
    };
    let res = await apiEmpleo.get("notes", { params });
    return res["hydra:member"];
  });
}
