import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  StyledEmployeeInfoDialog,
  StyledEmployeeInfoDialogText,
} from "./ValuationPipelineButtonSC";
import InfoIcon from "@material-ui/icons/Info";
import { FormattedMessage } from "react-intl";
import { TGetEmployeeProcesses } from "../../../../api/employee/apiGetEmployeeProcesses";
import cuideoTheme from "../../../../containers/themes/defaultTheme";
import FormattedValuationStatus from "../../../../components/Valoraciones/FormattedValuationStatus";

const StyledTable = styled(Table)`
  margin: 1rem 0;

  .MuiTableCell-head {
    background-color: rgba(255, 255, 255, 0.5);
    padding: 0.25rem;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: normal;
    color: rgb(51, 111, 149);
  }

  .MuiTableCell-body {
    background-color: #fff;
    padding: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.25;
    color: ${cuideoTheme.palette.primary.main};
  }
`;

const StyledContractName = styled(Typography)`
  color: #f64a69;
  font-size: 0.75rem;
`;

interface IEmployeeInOtherProcessesDialog {
  open: boolean;
  onEmployeeInProcessCancel: () => void;
  onEmployeeInProcessConfirm: () => void;
  employeeOtherProcesses: TGetEmployeeProcesses["inProcessValuations"];
}

const EmployeeInOtherProcessesDialog = (
  props: IEmployeeInOtherProcessesDialog
) => {
  const {
    open,
    onEmployeeInProcessCancel,
    onEmployeeInProcessConfirm,
    employeeOtherProcesses,
  } = props;
  const [state, setState] = useState({
    employeeOtherProcesses: [] as TGetEmployeeProcesses["inProcessValuations"],
  });

  useEffect(() => {
    if (open) {
      setState({
        employeeOtherProcesses: employeeOtherProcesses,
      });
    }
  }, [employeeOtherProcesses, open]);

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onEmployeeInProcessCancel();
  };

  const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onEmployeeInProcessConfirm();
  };

  return (
    <StyledEmployeeInfoDialog open={open}>
      <DialogTitle>
        <InfoIcon />
        <FormattedMessage
          id="EmployeeInOtherProcessesDialog.Candidato en otro proceso"
          defaultMessage="Candidato en otro proceso"
        />
      </DialogTitle>
      <DialogContent>
        <StyledEmployeeInfoDialogText>
          <FormattedMessage
            id="EmployeeInOtherProcessesDialog.Este candidato tiene otro proceso de selección activo. Por favor, revisa el caso antes de continuar"
            defaultMessage="Este candidato tiene otro proceso de selección activo. Por favor, revisa el caso antes de continuar"
          />
        </StyledEmployeeInfoDialogText>
        <StyledTable>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage
                  id="EmployeeInOtherProcessesDialog.Contrato"
                  defaultMessage="Contrato"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  id="EmployeeInOtherProcessesDialog.Tipo de Trato"
                  defaultMessage="Tipo de Trato"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  id="EmployeeInOtherProcessesDialog.Estado candidatura"
                  defaultMessage="Estado candidatura"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  id="EmployeeInOtherProcessesDialog.Estado contrato"
                  defaultMessage="Estado contrato"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  id="EmployeeInOtherProcessesDialog.Propietario"
                  defaultMessage="Propietario"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.employeeOtherProcesses.map((otherProcess) => (
              <TableRow key={otherProcess.id}>
                <TableCell>
                  <StyledContractName>
                    {otherProcess.contrato.Name}
                  </StyledContractName>
                </TableCell>
                <TableCell>{otherProcess.contrato.Tipo_de_Trato}</TableCell>
                <TableCell>
                  {otherProcess.Estado_en_proceso ? (
                    <FormattedValuationStatus
                      status={otherProcess.Estado_en_proceso}
                    />
                  ) : (
                    "-"
                  )}
                </TableCell>
                <TableCell>{otherProcess.contrato.Estado}</TableCell>
                <TableCell>{otherProcess.contrato.ownerName}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
        <StyledEmployeeInfoDialogText>
          <FormattedMessage
            id="EmployeeInOtherProcessesDialog.¿Seguro que quieres continuar?"
            defaultMessage="¿Seguro que quieres continuar?"
          />
        </StyledEmployeeInfoDialogText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleCancel}>
          <FormattedMessage
            id="EmployeeInOtherProcessesDialog.Cancelar"
            defaultMessage="Cancelar"
          />
        </Button>
        <Button color="primary" variant="contained" onClick={handleConfirm}>
          <FormattedMessage
            id="EmployeeInOtherProcessesDialog.Continuar"
            defaultMessage="Continuar"
          />
        </Button>
      </DialogActions>
    </StyledEmployeeInfoDialog>
  );
};

export default EmployeeInOtherProcessesDialog;
