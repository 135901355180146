import axios from "axios";

const apiCoreV3 = axios.create({
  baseURL: process.env.REACT_APP_V3_API_URL
    ? process.env.REACT_APP_V3_API_URL
    : `http://localhost/api/`,
  headers: {
    "Content-Type": "application/json",
  },
});

export { apiCoreV3 };
