import moment from "moment";
import { apiCoreV3 } from "../apiCoreV3/apiCoreV3";
import {
  APPLY_OFFER,
  APPLY_OFFER_SUCCESS,
  DEFER_APPLY,
  GET_LOGGED_USER_DATA,
  GET_LOGGED_USER_DATA_ERROR,
  GET_LOGGED_USER_DATA_SUCCESS,
  GET_USER,
  HIDE_INSCRIBETE_HIGHLIGHT,
  INIT_URL,
  REFRESING_TOKEN,
  SHOW_INSCRIBETE_HIGHLIGHT,
  SHOW_MESSAGE,
  SIGNIN_RESET_STATUS,
  SIGNIN_USER,
  SIGNIN_USER_ERROR,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_ERROR,
  SIGNUP_USER_RESET_ERROR,
  SIGNUP_USER_SUCCESS,
  TOKEN_REFRESHED,
  UNAPPLY_OFFER,
  UNAPPLY_OFFER_SUCCESS,
  USER_REFRESH_TOKEN_SET,
  USER_SET_REMEMBER_ME,
  USER_TOKEN_SET,
} from "../constants/ActionTypes";
import axios from "../util/ApiEmpleo";

const parseJwt = (token: string) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const userSignUp = (datosRegistro: any) => {
  return {
    type: SIGNUP_USER,
    payload: datosRegistro,
  };
};

export const userSignUpSuccess = (authUser: any) => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: authUser,
  };
};

export const userSignUpError = (message: string) => {
  return {
    type: SIGNUP_USER_ERROR,
    payload: message,
  };
};

export const userSignUpResetError = () => {
  return {
    type: SIGNUP_USER_RESET_ERROR,
  };
};

export const userSignIn = (user: any) => {
  return {
    type: SIGNIN_USER,
    payload: user,
  };
};

export const userSetToken = (token: string | null, rememberMe = false) => {
  const storage = rememberMe ? localStorage : sessionStorage;
  if (token) {
    const parsedJwt = parseJwt(token);
    const tokenExpiry = moment(parsedJwt.exp * 1000);
    storage.setItem("token", JSON.stringify(token));
    storage.setItem("tokenExpiry", JSON.stringify(tokenExpiry));
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    apiCoreV3.defaults.headers.common["Authorization"] = "Bearer " + token;
    return {
      type: USER_TOKEN_SET,
      payload: {
        token,
        tokenExpiry,
      },
    };
  } else {
    // In this case we clear both storages... Just in case
    localStorage.removeItem("token");
    localStorage.removeItem("tokenExpiry");
    localStorage.removeItem("authUser");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("tokenExpiry");
    sessionStorage.removeItem("authUser");
    delete axios.defaults.headers.common["Authorization"];
    delete apiCoreV3.defaults.headers.common["Authorization"];
    return {
      type: USER_TOKEN_SET,
      payload: {
        token,
        tokenExpiry: null,
      },
    };
  }
};

export const refreshingToken = () => {
  return {
    type: REFRESING_TOKEN,
  };
};

export const tokenRefreshed = () => {
  return {
    type: TOKEN_REFRESHED,
  };
};

export const userSetRememberMe = (rememberMe: boolean) => {
  return {
    type: USER_SET_REMEMBER_ME,
    payload: rememberMe,
  };
};

export const userSetRefreshToken = (
  refreshToken: string | null,
  rememberMe = false
) => {
  const storage = rememberMe ? localStorage : sessionStorage;
  if (refreshToken) {
    storage.setItem("refreshToken", JSON.stringify(refreshToken));
  } else {
    localStorage.removeItem("refreshToken");
    sessionStorage.removeItem("refreshToken");
  }
  return {
    type: USER_REFRESH_TOKEN_SET,
    payload: refreshToken,
  };
};

export const userSignOut = () => {
  return {
    type: SIGNOUT_USER,
  };
};

export const userSignInSuccess = () => {
  return {
    type: SIGNIN_USER_SUCCESS,
  };
};

export const userSignInError = (message: any) => {
  return {
    type: SIGNIN_USER_ERROR,
    payload: message,
  };
};

export const userSignInResetStatus = () => {
  return {
    type: SIGNIN_RESET_STATUS,
  };
};

export const userSignOutSuccess = () => {
  delete axios.defaults.headers.common["Authorization"];

  return {
    type: SIGNOUT_USER_SUCCESS,
  };
};

export const showAuthMessage = (message: any) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const getUser = () => {
  return {
    type: GET_USER,
    payload: "GET USER",
  };
};

export const getLoggedUserData = (updateStaff = false) => {
  return {
    type: GET_LOGGED_USER_DATA,
    payload: {
      updateStaff,
    },
  };
};

export const getLoggedUserDataSuccess = (authUser: any) => {
  return {
    type: GET_LOGGED_USER_DATA_SUCCESS,
    payload: authUser,
  };
};

export const getLoggedUserDataError = (error: string) => {
  return {
    type: GET_LOGGED_USER_DATA_ERROR,
    payload: error,
  };
};

export const setInitUrl = (url: string) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

export const applyOffer = (offer: any) => {
  return {
    type: APPLY_OFFER,
    payload: offer,
  };
};

export const applyOfferSuccess = ({
  valoracion,
  offer,
}: {
  valoracion: any;
  offer: any;
}) => {
  return {
    type: APPLY_OFFER_SUCCESS,
    payload: {
      valoracion,
      offer,
    },
  };
};

export const unApplyOffer = ({
  valoracionId,
  offerId,
}: {
  valoracionId: string;
  offerId: string;
}) => {
  return {
    type: UNAPPLY_OFFER,
    payload: {
      valoracionId,
      offerId,
    },
  };
};

export const unApplyOfferSuccess = (valoracionId: string) => {
  return {
    type: UNAPPLY_OFFER_SUCCESS,
    payload: valoracionId,
  };
};

export const hideInscribeteHightlight = () => {
  return {
    type: HIDE_INSCRIBETE_HIGHLIGHT,
  };
};

export const showInscribeteHightlight = () => {
  return {
    type: SHOW_INSCRIBETE_HIGHLIGHT,
  };
};

export const deferApply = (offer: any) => {
  return {
    type: DEFER_APPLY,
    payload: offer,
  };
};
