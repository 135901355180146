import { SUGGESTION_ACCEPTED, SUGGESTION_REJECTED } from "../util/defaults/valoracion";

export type TSuggestion = {
  id: string;
  createdAt?: string;
  updatedAt?: string;
  type?: TSuggestionType;
  status?: TSuggestionStatus;
  contract?: any;
  employee?: any;
  distance?: number;
  score?: number;
  issues?: any;
}

export const SUGGESTION_TYPE_VALIDATED_PLUS = 'validated_plus';
export const SUGGESTION_TYPE_WAITING_ROOM = 'waiting_room';
export const SUGGESTION_TYPE_AFFINITY = 'affinity';

export type TSuggestionType = 
  typeof SUGGESTION_TYPE_VALIDATED_PLUS
  | typeof SUGGESTION_TYPE_WAITING_ROOM
  | typeof SUGGESTION_TYPE_AFFINITY

export type TSuggestionStatus =
  typeof SUGGESTION_ACCEPTED
  | typeof SUGGESTION_REJECTED