import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import React from "react";
import FlipMove from "react-flip-move";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { TGetEmployeesCollectionItem } from "../../../../../../api/employee/employee.types";
import cuideoTheme from "../../../../../../containers/themes/defaultTheme";
import CandidateCard from "../CandidateCard/CandidateCard";

const MAX_PAGINABLE_ITEMS = 10_000;

const StyledWrapper = styled.div`
  height: 100%;
`;

const StyledCandidatosHeader = styled.div`
  background-color: rgba(255, 255, 255, 0.85);
  position: absolute;
  top: 0;
  left: 0;
  right: 0.5rem;
  padding: 0.5rem 1rem;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledSelectAll = styled(Typography)``;

const StyledTotalCandidatos = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-size: 0.875rem;
  line-height: 1.25;
  text-align: right;
`;

const StyledSelectAllFormContolLabel = styled(FormControlLabel)`
  padding: 0 1rem;
  .MuiButtonBase-root {
    padding: 0.25rem;
  }
  .MuiFormControlLabel-label {
    font-size: 0.875rem;
    color: ${cuideoTheme.palette.primary.main};
  }
  .MuiSvgIcon-root {
    width: 1.25rem;
    height: 1.25rem;
    color: ${cuideoTheme.palette.primary.main};
  }
`;

const StyledCandidatosContainer = styled.div`
  padding: 2rem 1rem 1rem;
  background-color: ${cuideoTheme.palette.common.white};
  width: 28rem;
  overflow-y: scroll;
  height: 100%;
  box-sizing: border-box;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 72, 120, 0.25);
    width: 0.5rem;
    border-radius: 0.25rem;
  }
`;

const StyledCandidatoWrapper = styled.div`
  margin: 1rem 0;
`;

const StyledPagination = styled(Pagination)`
  .MuiPagination-ul {
    justify-content: center;
  }
`;

interface EmployeesListProps {
  isLoading: boolean;
  candidatos: TGetEmployeesCollectionItem[];
  totalCandidatos: number;
  selectedCandidato: any;
  selectedCandidatos: any[];
  selectedAll: boolean;
  onToggleSelectAll: () => void;
  onCandidatoSelect: (valoracion: any) => void;
  // onCandidatoChange: (valoracion: any) => void;
  onToggleCandidatoMultiSelect: (valoracion: any) => void;
  page: number;
  onPageChange: (event: any, newPage: number) => void;
  itemsPerPage: number;
  mutatingCandidatos: any[];
  hoveredCandidato?: any;
  onCandidatoHover?: (candidato: any) => void;
}

const CandidatesList = (props: EmployeesListProps) => {
  const {
    isLoading,
    candidatos,
    totalCandidatos,
    selectedCandidato,
    selectedCandidatos,
    selectedAll,
    onToggleSelectAll,
    onCandidatoSelect,
    // onCandidatoChange,
    onToggleCandidatoMultiSelect,
    page,
    onPageChange,
    itemsPerPage,
    hoveredCandidato,
    onCandidatoHover,
  } = props;

  const totalPaginableItems =
    totalCandidatos > MAX_PAGINABLE_ITEMS
      ? Math.ceil(MAX_PAGINABLE_ITEMS / itemsPerPage)
      : Math.ceil(totalCandidatos / itemsPerPage);

  return (
    <StyledWrapper>
      {candidatos.length > 0 && (
        <StyledCandidatosHeader>
          <StyledSelectAll>
            <StyledSelectAllFormContolLabel
              control={
                <Checkbox
                  checked={selectedAll}
                  onChange={onToggleSelectAll}
                  color="primary"
                />
              }
              label={
                <FormattedMessage
                  id="EmployeesList.SelectAll"
                  defaultMessage="Seleccionar todos"
                />
              }
            />
          </StyledSelectAll>
          <StyledTotalCandidatos>
            <FormattedMessage
              id="EmployeesList.Total"
              defaultMessage="{numCandidatos} candidatos"
              values={{
                numCandidatos: totalCandidatos,
              }}
            />
          </StyledTotalCandidatos>
        </StyledCandidatosHeader>
      )}
      <StyledCandidatosContainer>
        <FlipMove
          duration={250}
          staggerDelayBy={20}
          enterAnimation="fade"
          leaveAnimation={false} // If not, we can have a nasty bug
        >
          {candidatos.map((candidate) => (
            <StyledCandidatoWrapper key={candidate.id}>
              <CandidateCard
                candidate={candidate}
                selected={
                  !!selectedCandidato && selectedCandidato.id === candidate.id
                    ? true
                    : false
                }
                // onCandidateChange={onCandidatoChange}
                onCandidateSelect={onCandidatoSelect}
                onCandidateMultiSelect={onToggleCandidatoMultiSelect}
                multiSelected={Boolean(
                  selectedCandidatos.filter(
                    (selCand: any) => selCand.id === candidate.id
                  ).length
                )}
                onMouseEnter={() => {
                  onCandidatoHover && onCandidatoHover(candidate);
                }}
                onMouseLeave={() => {
                  onCandidatoHover && onCandidatoHover(null);
                }}
                hovered={
                  !!hoveredCandidato && hoveredCandidato.id === candidate.id
                }
                concealed={
                  !!selectedCandidato && selectedCandidato.id !== candidate.id
                }
              />
            </StyledCandidatoWrapper>
          ))}
        </FlipMove>
        {!isLoading && totalCandidatos === 0 && (
          <Typography color="primary">
            <FormattedMessage
              id="EmployeesList.NoEmployees"
              defaultMessage="No hay Candidatos con los criterios establecidos. Pruebe con otros criterios."
            />
          </Typography>
        )}
        {totalCandidatos > 0 && totalCandidatos > itemsPerPage && (
          <StyledPagination
            count={totalPaginableItems}
            page={page}
            onChange={onPageChange}
            color="primary"
          />
        )}
      </StyledCandidatosContainer>
    </StyledWrapper>
  );
};

export default CandidatesList;
