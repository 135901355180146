import { Skeleton } from "@material-ui/lab";
import classNames from "classnames";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { cuideoTheme } from "../../../../../../containers/themes/defaultTheme";
import { TContractPartial } from "../../types/contract.types";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 1rem;
  width: 100%;
  height: 1rem;
`;

const StyledStep = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex: 1 1 auto;

  .CMuiStep__pre {
    position: relative;
    height: 100%;
    background-color: #e5ecf1;
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: -0.375rem;
      top: 0;
      width: 0.625rem;
      height: 50%;
      background-color: #e5ecf1;
      transform: skew(40deg, 0);
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: -0.375rem;
      bottom: 0;
      width: 0.625rem;
      height: 50%;
      background-color: #e5ecf1;
      transform: skew(-40deg, 0);
    }
  }
  .CMuiStep__body {
    padding: 0 0.75rem;
    height: 100%;
    background-color: #e5ecf1;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    font-size: 0.6875rem;
    font-weight: 700;
    color: ${cuideoTheme.palette.primary.main};

    &.CMuiStep__body--last {
      text-align: right;
    }
  }
  .CMuiStep__post {
    position: relative;
    height: 100%;
    background-color: #e5ecf1;
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: -0.375rem;
      top: 0;
      width: 0.625rem;
      height: 50%;
      background-color: #e5ecf1;
      transform: skew(40deg, 0);
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: -0.375rem;
      bottom: 0;
      width: 0.625rem;
      height: 50%;
      background-color: #e5ecf1;
      transform: skew(-40deg, 0);
    }
  }

  &.CMui-active {
    .CMuiStep__pre {
      background-color: ${cuideoTheme.palette.primary.main};
      &::before {
        background-color: ${cuideoTheme.palette.primary.main};
      }
      &::after {
        background-color: ${cuideoTheme.palette.primary.main};
      }
    }
    .CMuiStep__body {
      background-color: ${cuideoTheme.palette.primary.main};
      color: #fff;
    }
    .CMuiStep__post {
      background-color: ${cuideoTheme.palette.primary.main};
      &::before {
        background-color: ${cuideoTheme.palette.primary.main};
      }
      &::after {
        background-color: ${cuideoTheme.palette.primary.main};
      }
    }
  }
`;

const StyledPreStep = styled.div``;

const StyledStepBody = styled.div`
  flex: 1 1 auto;
`;

const StyledPostStep = styled.div``;

interface IContractPhaseStepperProps {
  isLoading?: boolean;
  disableLabels?: boolean;
  phase: TContractPartial["phase"];
}

const ContractPhaseStepper: React.FC<IContractPhaseStepperProps> = (props) => {
  const { isLoading, disableLabels = false, phase } = props;

  return (
    <StyledWrapper className="CMuiContractPhaseStepper">
      <StyledStep
        className={classNames({
          CMuiContractPhaseStepper__step: true,
          "CMui-active": phase === "recruit",
        })}
      >
        {isLoading ? (
          <StyledStepBody>
            <Skeleton />
          </StyledStepBody>
        ) : (
          <>
            <StyledStepBody className="CMuiStep__body">
              {!disableLabels && (
                <FormattedMessage
                  id="ContractPhaseStepper.Reclutamiento"
                  defaultMessage="Reclutamiento"
                />
              )}
            </StyledStepBody>
            <StyledPostStep className="CMuiStep__post"></StyledPostStep>
          </>
        )}
      </StyledStep>
      <StyledStep
        className={classNames({
          CMuiContractPhaseStepper__step: true,
          "CMui-active": phase === "promote",
        })}
      >
        {isLoading ? (
          <StyledStepBody>
            <Skeleton />
          </StyledStepBody>
        ) : (
          <>
            <StyledPreStep className="CMuiStep__pre"></StyledPreStep>
            <StyledStepBody className="CMuiStep__body CMuiStep__body--last">
              {!disableLabels && (
                <FormattedMessage
                  id="ContractPhaseStepper.Gestión"
                  defaultMessage="Gestión"
                />
              )}
            </StyledStepBody>
          </>
        )}
      </StyledStep>
    </StyledWrapper>
  );
};

export default ContractPhaseStepper;
