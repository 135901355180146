import { useQuery } from "react-query";
import {
  apiListCandidateValuations,
  IApiListCandidateValuationsParams,
} from "../../../../../api/valuation/apiListCandidateValuations";
import {
  ExtractFnReturnType,
  QueryConfig,
} from "../../../../../lib/react-query";
import { valuationKeys } from "./valuation.query-keys";

type QueryFnType = typeof apiListCandidateValuations;

type TUseCandidateValuationsOptions = {
  params: IApiListCandidateValuationsParams;
  config?: QueryConfig<QueryFnType>;
};

export const useCandidateValuations = ({
  params,
  config,
}: TUseCandidateValuationsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: valuationKeys.listForCandidate(params),
    queryFn: () => apiListCandidateValuations(params),
  });
};
