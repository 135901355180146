import toast from "react-hot-toast";
import { FormattedMessage, useIntl } from "react-intl";
import { TCoordinationGroup } from "../../../../../../api/user/apiListStaffUsers";
import { apiUpdateCoordinationGroup } from "../../../../../../api/user/apiUpdateCoordinationGroup";
import { queryClient } from "../../../../../../lib/react-query";
import useBackground from "../../../../../../util/useBackground";
import useFooter from "../../../../../../util/useFooter";
import useHeader from "../../../../../../util/useHeader";
import usePageTitle from "../../../../../../util/usePageTitle";
import useWindowWidth from "../../../../../../util/useWindowWidth";
import useCurrentPromoterQuery from "./hooks/useCurrentPromoterQuery";
import usePromotersUserQuery from "./hooks/usePromoterUsersQuery";
import useServicesUnratedPageFilters from "./hooks/useServicesUnratedPageFilters";
import useServicesUnratedPageQuery from "./hooks/useServicesUnratedPageQuery";
import useUserEnabledMutation from "./hooks/useUserEnabledMutation";
import PromotersManagerRoute from "./PromotersManagerRoute";
import { initGroups } from "./types/StaffAssignationsGroup";

export const UNASIGNED_PAGE = "unassigned";

interface IPromotersManagerRouteContainer {}

const PromotersManagerRouteContainer = (
  props: IPromotersManagerRouteContainer
) => {
  const { formatMessage } = useIntl();
  useHeader({
    type: "hidden",
  });
  useFooter({
    type: "hidden",
  });
  useBackground("cuideo");
  usePageTitle(
    formatMessage({
      id: "ServicesPage.Services",
      defaultMessage: "Servicios",
    })
  );
  const windowWidth = useWindowWidth();

  const { filters, setFilters } = useServicesUnratedPageFilters();

  const coordinatorsQuery = usePromotersUserQuery();

  const servicesQuery = useServicesUnratedPageQuery(filters);

  const currentStaffUser = useCurrentPromoterQuery(filters.ownerId);
  const currentStaffUserEnabledMutation = useUserEnabledMutation();

  const groups = initGroups();

  const handlePageChange = (event: any, newPage: number) => {
    setFilters({
      ...filters,
      page: newPage,
    });
  };

  const handleSelectService = (serviceId: string) => {
    setFilters({
      ...filters,
      selectedService: filters.selectedService === serviceId ? null : serviceId,
    });
  };

  const handleOwnerChanged = () => {
    invalidateAllQueries();
  };

  const handleOwnerChange = (newOwnerId: string | null) => {
    setFilters({
      ...filters,
      ownerId: newOwnerId,
      selectedService: null,
      page: 1,
      pageType:
        coordinatorsQuery.data?.superAdminUserId === newOwnerId
          ? UNASIGNED_PAGE
          : null,
    });
    invalidateAllQueries();
  };

  const handleSelectAllUnvaluedServices = () => {
    setFilters({
      ...filters,
      ownerId: null,
      selectedService: null,
      page: 1,
      pageType: null,
    });
    invalidateAllQueries();
  };

  const handleSelectUnassignedServices = () => {
    if (coordinatorsQuery.data?.superAdminUserId) {
      setFilters({
        ...filters,
        ownerId: coordinatorsQuery.data.superAdminUserId,
        page: 1,
        selectedService: null,
        pageType: UNASIGNED_PAGE,
      });
    }
    invalidateAllQueries();
  };

  const handleGroupChange = async (
    group: TCoordinationGroup,
    ownerId: string
  ) => {
    await apiUpdateCoordinationGroup(ownerId, {
      coordinationGroups: [group.toString()],
    });
    queryClient.invalidateQueries();
    toast.success(
      formatMessage({
        id: "PromotersManagerRouteContainer.Grupo asignado con éxito",
        defaultMessage: "Grupo asignado con éxito",
      })
    );
  };

  const handleActivationSwitch = async (ownerId: string, status: boolean) => {
    const toastId = toast.loading(
      <span>
        <FormattedMessage
          id="PromotersManagerRouteContainer.Actualizando usuario {userName}"
          defaultMessage="Actualizando usuario {userName}"
          values={{
            userName: <b>{currentStaffUser.data?.fullname}</b>,
          }}
        />
      </span>
    );
    try {
      await currentStaffUserEnabledMutation.mutateAsync({
        id: ownerId,
        enabled: status,
      });
      if (status) {
        toast.success(
          <span>
            <FormattedMessage
              id="PromotersManagerRouteContainer.Has habilitado a {userName}"
              defaultMessage="Has habilitado a {userName}"
              values={{
                userName: <b>{currentStaffUser.data?.fullname}</b>,
              }}
            />
          </span>,
          {
            id: toastId,
          }
        );
      } else {
        toast.success(
          <span>
            <FormattedMessage
              id="PromotersManagerRouteContainer.Has deshabilitado a {userName}"
              defaultMessage="Has deshabilitado a {userName}"
              values={{
                userName: <b>{currentStaffUser.data?.fullname}</b>,
              }}
            />
          </span>,
          {
            id: toastId,
          }
        );
      }
    } catch (e) {
      toast.error(
        <span>
          <FormattedMessage
            id="PromotersManagerRouteContainer.Ha habido un error al intentar modificar el usuario"
            defaultMessage="Ha habido un error al intentar modificar el usuario"
          />
        </span>,
        {
          id: toastId,
        }
      );
    }
  };

  const invalidateAllQueries = () => {
    queryClient.invalidateQueries();
  };

  const handleReloadButtonClick = () => {
    invalidateAllQueries();
  };

  const reloadButtonStatus = (): "idle" | "success" | "error" | "loading" => {
    let status: "idle" | "success" | "error" | "loading" = "idle";

    if (
      coordinatorsQuery.status === "loading" ||
      servicesQuery.status === "loading" ||
      currentStaffUser.status === "loading"
    ) {
      status = "loading";
    }

    if (
      coordinatorsQuery.isFetching ||
      servicesQuery.isFetching ||
      currentStaffUser.isFetching
    ) {
      status = "loading";
    }

    return status;
  };

  return (
    <PromotersManagerRoute
      reloadButtonStatus={reloadButtonStatus()}
      onReloadButtonClick={handleReloadButtonClick}
      onActivationSwitchChange={handleActivationSwitch}
      superAdminId={coordinatorsQuery.data?.superAdminUserId ?? null}
      filters={filters}
      setFilters={setFilters}
      services={servicesQuery.data?.services ?? []}
      totalServices={servicesQuery.data?.totalItems ?? 0}
      onPageChange={handlePageChange}
      isLoading={servicesQuery.isLoading}
      queryError={servicesQuery.isError}
      onSelectService={handleSelectService}
      ownerOptions={coordinatorsQuery.data?.ownerOptions ?? []}
      onOwnerChange={handleOwnerChanged}
      onSelectOwner={handleOwnerChange}
      onSelectAllUnvaluedServices={handleSelectAllUnvaluedServices}
      onSelectUnassignedServices={handleSelectUnassignedServices}
      currentStaffUser={currentStaffUser.data ?? null}
      groups={groups}
      onGroupChange={handleGroupChange}
      windowWidth={windowWidth}
    />
  );
};

export default PromotersManagerRouteContainer;
