import DateFnsUtils from "@date-io/date-fns";
import { Divider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import classNames from "classnames";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import CalendarIcon from "../../../../../../assets/images/iconos/calendar.png";
import { localeMap } from "../../../../../../lngProvider/entries/date-fns_locales";
import {
  StyledDatePicker,
  StyledDatesWrapper,
  StyledTriDataDate,
  StyledTriDataDatesWrapper,
  StyledTriDataWrapper,
  StyledTriIcon,
  StyledTriItem,
  StyledTriLabel,
} from "./ContractCardSC";
import { authUserHasRole, useAuthUser } from "../../../../../../reducers/hooks/useAuthUser";
import { USER_ROLES } from "../../../../../../api/security";

interface IContractDatesProps {
  objectiveDate?: string;
  cvClientTargetDate?: string;
  hasPastTargetDate?: boolean;
  hasPastCvClientDate?: boolean;
  onObjectiveDateChange: (
    date: MaterialUiPickersDate,
    value?: string | null | undefined
  ) => void;
  onCvClientTargetDateChange: (
    date: MaterialUiPickersDate,
    value?: string | null | undefined
  ) => void;
}

const ContractDates: React.FC<IContractDatesProps> = (props) => {
  const {
    objectiveDate,
    cvClientTargetDate,
    hasPastTargetDate,
    hasPastCvClientDate,
    onObjectiveDateChange,
    onCvClientTargetDateChange,
  } = props;

  const { authUser } = useAuthUser();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap["es"]}>
      <StyledDatesWrapper>
        <StyledTriItem>
          <StyledTriIcon src={CalendarIcon} />
          <StyledTriDataDatesWrapper>
            <StyledTriDataWrapper>
              <StyledTriLabel>
                <FormattedMessage
                  id="ContractCard.Fecha cv cliente"
                  defaultMessage="Fecha cv cliente"
                />
              </StyledTriLabel>
              <StyledTriDataDate
                className={classNames({
                  "CMui-warning": hasPastCvClientDate ?? false,
                })}
              >
                <StyledDatePicker
                  disableToolbar
                  emptyLabel="-"
                  variant="inline"
                  format="dd/MM/yyyy"
                  value={
                    cvClientTargetDate
                      ? moment(cvClientTargetDate).toDate()
                      : null
                  }
                  onChange={onCvClientTargetDateChange}
                  autoOk
                  disabled={authUser && authUserHasRole(authUser, USER_ROLES.ROLE_ES_RECRUITER)}
                />
              </StyledTriDataDate>
            </StyledTriDataWrapper>
            <Divider orientation="vertical" flexItem />
            <StyledTriDataWrapper>
              <StyledTriLabel>
                <FormattedMessage
                  id="ContractCard.Fecha objetivo"
                  defaultMessage="Fecha objetivo"
                />
              </StyledTriLabel>
              <StyledTriDataDate
                className={classNames({
                  "CMui-warning": hasPastTargetDate ?? false,
                })}
              >
                <StyledDatePicker
                  disableToolbar
                  emptyLabel="Sin fecha objetivo"
                  variant="inline"
                  format="dd/MM/yyyy"
                  value={objectiveDate ? moment(objectiveDate).toDate() : null}
                  onChange={onObjectiveDateChange}
                  autoOk
                />
              </StyledTriDataDate>
            </StyledTriDataWrapper>
          </StyledTriDataDatesWrapper>
        </StyledTriItem>
      </StyledDatesWrapper>
    </MuiPickersUtilsProvider>
  );
};
export default ContractDates;
