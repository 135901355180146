import React from "react";
import styled from "styled-components";
import cuideoTheme from "../../containers/themes/defaultTheme";
import { Typography, Button } from "@material-ui/core";
import IntlMessages from "../../util/IntlMessages";
import { NavLink } from "react-router-dom";
import { JOB_OFFERS } from "../../constants/routes";

const StyledWrapper = styled.div`
  height: calc(100vh - 10rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledErrorHeader = styled(Typography)`
  text-align: center;
  font-size: 1.75rem;
  font-family: "Lora", Georgia, serif;
  font-weight: 600;
  max-width: 20rem;
  margin: 0 auto 2rem;
  color: ${cuideoTheme.palette.primary.main};
`;

const StyledErrorText = styled(Typography)`
  text-align: center;
  font-size: 1.1rem;
  max-width: 20rem;
  margin: 0 auto 1.5rem;
  color: ${cuideoTheme.palette.primary.main};
`;

const NotFoundElement = (props: any) => {
  const { title, description, hasHomeLink } = props;

  return (
    <StyledWrapper>
      {title && <StyledErrorHeader>{title}</StyledErrorHeader>}
      {description && <StyledErrorText>{description}</StyledErrorText>}
      {hasHomeLink && (
        <StyledErrorText>
          <NavLink to={JOB_OFFERS} style={{ textDecoration: "none" }}>
            <Button color="primary" variant="contained">
              <IntlMessages id="Ir a ofertas" />
            </Button>
          </NavLink>
        </StyledErrorText>
      )}
    </StyledWrapper>
  );
};

export default NotFoundElement;
