import React, { Fragment } from "react";
import CircularProgress from "../components/CircularProgress";
import styled from "styled-components";

const StyledCircularProgress = styled(CircularProgress)`
  margin-left: auto;
  margin-right: auto;
  width: 3rem;
`;

const LoadingPage = () => {
  return (
    <Fragment>
      <StyledCircularProgress />
    </Fragment>
  );
};

export default LoadingPage;
