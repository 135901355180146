const nivelesFormativos = [
  {
    id: "39aa3b50-fd27-42d8-ad42-19b85df21d0e",
    code: "eps",
    name: "30_Estudios post secundarios",
    label: "Estudios post secundarios",
    slug: "estudios-post-secundarios"
  },
  {
    id: "5d29540c-bd85-4ab8-a32e-70eb1da1f036",
    code: "es",
    name: "20_Estudios secundarios",
    label: "Estudios secundarios",
    slug: "estudios-secundarios"
  },
  {
    id: "28dae202-bc8e-4654-bd97-8d671b76e9c3",
    code: "nb",
    name: "10_Nivel basico",
    label: "Nivel básico",
    slug: "nivel-basico"
  },
  {
    id: "ffc718cc-12ee-48d1-97d8-d3fc6015aed9",
    code: "se",
    name: "00_Sin Estudios",
    label: "Sin estudios",
    slug: "sin-estudios"
  }
];

export default nivelesFormativos;

export const getNivelFormativoLabel = (name: string) => {
  const nivel = nivelesFormativos.find((item: any) => item.name === name);
  if (nivel) {
    return nivel.label;
  }
  return "";
};
