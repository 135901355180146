import { useState, useEffect } from "react";
import opcionesTitulaciones from "../../util/defaults/titulaciones";

function useEmployeeDegreesOptions(businessCountry: string) {
  const [degreesOptions, setDegreesOptions] = useState(
    [] as { value: string; label: string }[]
  );

  useEffect(() => {
    const newDegrees: { value: string; label: string }[] = [];
    let filter = "es";
    if (businessCountry === "fr") {
      filter = "fr";
    }

    // eslint-disable-next-line
    opcionesTitulaciones.map((item: any) => {
      if (item.businessCountry === filter) {
        newDegrees.push({
          value: item.value,
          label: item.label,
        });
      }
    });

    setDegreesOptions(newDegrees);
  }, [businessCountry]);

  return degreesOptions;
}

export default useEmployeeDegreesOptions;
