import { apiEmpleo } from "../../../util/ApiEmpleo";
import { useMutation } from "react-query";
import { queryClient } from "../../../lib/react-query";

export default function useAddNoteMutation(
  parentId: string,
  moduleName: string
) {
  const addNote = async (data: any) => {
    await apiEmpleo.post(`notes`, {
      Note_Content: data.Note_Content,
      parentId: data.parentId,
      zohoModule: data.zohoModule,
    });
  };

  return useMutation(addNote as any, {
    onMutate: (newData: any) => {
      queryClient.cancelQueries([`notes`, parentId, moduleName]);

      const previousNotes: any = queryClient.getQueryData([
        "notes",
        parentId,
        moduleName,
      ]);

      if (previousNotes && newData) {
        const newNotes = [...previousNotes, { ...newData }];

        queryClient.setQueryData(
          ["notes", parentId, moduleName],
          [...newNotes]
        );
      }

      return () =>
        queryClient.setQueryData(
          ["notes", parentId, moduleName],
          previousNotes
        );
    },
    onError: (err, newNotes, rollback: any) => {
      rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries(["notes", parentId, moduleName]);
    },
  });
}
