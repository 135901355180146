import axios from "../../util/ApiEmpleo";

const apiUpdateEmployeeAvatar = async (employeeId: string, avatarImg: Blob) => {
  const form = new FormData();
  form.append("file", avatarImg);

  const res = await axios.post(`/candidatos/${employeeId}/avatar`, form, {
    headers: {
      "content-type": `multipart/form-data; boundary=${
        (form as any)._boundary
      }`,
    },
  });

  return res;
};

export { apiUpdateEmployeeAvatar };
