import React from "react";
import { FormattedMessage } from "react-intl";
import {
  VALORACION_CONTRATADO,
  VALORACION_CVCLIENTE,
  VALORACION_DESCARTADO,
  VALORACION_PRESENCIAL,
  VALORACION_PRESENCIAL_CLIENTE,
  VALORACION_SIN_VALORAR,
  VALORACION_TELEFONICA,
} from "../../util/defaults/valoracion";

interface IFormattedValuationStatusProps {
  status: string;
}

const FormattedValuationStatus = (props: IFormattedValuationStatusProps) => {
  const { status } = props;

  if (status === VALORACION_SIN_VALORAR) {
    return (
      <FormattedMessage id="01_Sin Valorar" defaultMessage="Sin Valorar" />
    );
  }
  if (status === VALORACION_DESCARTADO) {
    return <FormattedMessage id="02_Descartado" defaultMessage="Descartado" />;
  }
  if (status === VALORACION_TELEFONICA) {
    return <FormattedMessage id="03_Telefónica" defaultMessage="Telefónica" />;
  }
  if (status === VALORACION_PRESENCIAL) {
    return <FormattedMessage id="04_Presencial" defaultMessage="Presencial" />;
  }
  if (status === VALORACION_CVCLIENTE) {
    return (
      <FormattedMessage id="04_CV a cliente" defaultMessage="CV a Cliente" />
    );
  }
  if (status === VALORACION_PRESENCIAL_CLIENTE) {
    return (
      <FormattedMessage
        id="05_Presencial Cliente"
        defaultMessage="Presencial cliente"
      />
    );
  }
  if (status === VALORACION_CONTRATADO) {
    return <FormattedMessage id="06_Contratado" defaultMessage="Contratado" />;
  }
  return null;
};

export default FormattedValuationStatus;
