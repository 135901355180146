import { Typography } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import {
  CONTRACT_PHASE,
  TGetContractsCollectionItem,
} from "../../../../../../api/contract/contract.types";
import { cuideoTheme } from "../../../../../../containers/themes/defaultTheme";
import {
  CONTRATO_ESTADO_ALTA_PENDIENTE,
  CONTRATO_ESTADO_CV_CLIENTE,
  CONTRATO_ESTADO_PRESENCIAL_CLIENTE,
} from "../../../../../../util/defaults/contrato";
import { useInfiniteContracts } from "../../../contract";
import {
  TChangeOrderFn,
  TRecruiterManagerRouteFilters,
} from "../../routes/RecruiterManagerRoute/hooks/useRecruiterManagerRouteFilters";
import { defaultTableColumnsForAllContractsView } from "../RecruiterView/contracts-table.util";
import ContractsTable from "../RecruiterView/ContractsTable";

const StyledWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;
`;

const StyledTopLeft = styled.div``;

const StyledTopRight = styled.div``;

const StyledTitleData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

const StyledTitle = styled(Typography)`
  font-size: 1.5rem;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: ${cuideoTheme.palette.primary.main};
`;

const StyledBottom = styled.div`
  flex: 1 1 auto;
  margin: 1.5rem 0 0;
`;

interface IAllContractsViewProps {
  filters: TRecruiterManagerRouteFilters;
  onSelectedContractChange: (contractId: string) => void;
  onChangeTableOrder: TChangeOrderFn;
}

const AllContractsView: React.FC<IAllContractsViewProps> = (props) => {
  const { filters, onSelectedContractChange, onChangeTableOrder } = props;

  const contractsInfiniteQuery = useInfiniteContracts({
    params: {
      estado: [
        CONTRATO_ESTADO_ALTA_PENDIENTE,
        CONTRATO_ESTADO_CV_CLIENTE,
        CONTRATO_ESTADO_PRESENCIAL_CLIENTE,
      ],
      phase: [CONTRACT_PHASE.RECRUIT],
      itemsPerPage: 50,
      "order[fechaObjetivo]": filters["order[fechaObjetivo]"] ?? undefined,
      "order[cvDate]": filters["order[cvDate]"] ?? undefined,
      "order[createdAt]": filters["order[createdAt]"] ?? undefined,
      "order[squad]": filters["order[squad]"] ?? undefined,
    },
    config: {
      getNextPageParam: (lastPage, pages) => pages.length + 1,
    },
  });

  const handleLoadMoreContracts = () => {
    contractsInfiniteQuery.fetchNextPage();
  };

  let hasMoreItems = false;
  let totalItems = 0;
  const contracts: TGetContractsCollectionItem[] = [];
  contractsInfiniteQuery.data?.pages.forEach((group, i) => {
    if (i === 0) {
      totalItems = group["hydra:totalItems"] ?? 0;
    }
    group["hydra:member"].forEach((itemContract) => {
      contracts.push(itemContract);
    });
  });
  if (totalItems > contracts.length) {
    hasMoreItems = true;
  }

  return (
    <StyledWrapper key={`AllContractsView`}>
      <StyledTop>
        <StyledTopLeft>
          <StyledTitleData>
            <StyledTitle>
              <FormattedMessage
                id="AllContractsView.Contratos"
                defaultMessage="Contratos"
              />
            </StyledTitle>
          </StyledTitleData>
        </StyledTopLeft>
        <StyledTopRight></StyledTopRight>
      </StyledTop>
      <StyledBottom>
        <ContractsTable
          contracts={contracts}
          status={contractsInfiniteQuery.status}
          isFetchingMore={contractsInfiniteQuery.isFetchingNextPage}
          selectedContractId={filters.selectedContractId}
          onSelectedContractChange={onSelectedContractChange}
          columns={defaultTableColumnsForAllContractsView}
          onLoadMore={handleLoadMoreContracts}
          hasMoreItems={hasMoreItems}
          filters={filters}
          onChangeTableOrder={onChangeTableOrder}
        />
      </StyledBottom>
    </StyledWrapper>
  );
};

export default AllContractsView;
