import { Card, Checkbox, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import React, { useRef } from "react";
import { FaRobot } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import CuideoUserAvatar from "../../../../../../components/CuideoUserAvatar";
import FormattedEmployeeExperienceField from "../../../../../../components/FormattedFields/FormattedEmployeeExperienceField";
import { cuideoTheme } from "../../../../../../containers/themes/defaultTheme";
import { useAuthUser } from "../../../../../../reducers/hooks/useAuthUser";
import {
  VALORACION_CONTRATADO,
  VALORACION_DESCARTADO,
  VALORACION_RECOMENDACION,
  VALORACION_SUGGESTION,
} from "../../../../../../util/defaults/valoracion";
import ValuationPipelineButton from "../../../../components/ValuationPipelineButton";
import { CandidateStatusTag } from "../../../candidate";
import {
  TContractValuation,
  TOnContractedValuationFn,
  TOnMultiSelectValuationFn,
  TOnSelectValuationFn,
} from "../../types/valuation.types";
// import SuggestionIssues from "./SuggestionIssues";
import moment from "moment";
import { candidateFavoriteOfUser } from "../../../candidate/util/candidate.util";
import { TOnContractValuationChangeFn } from "../../../contract/types/contract-valuation.types";
import ValoracionDescartarButton from "../ValuationDiscardButton/ValuationDiscardButton";
import SuggestionType from "./SuggestionType";
import ValuationIcons from "./ValuationIcons";
import { ADDED_FROM_OUTSTANDINGS } from "../../../../../../constants/CandidateOutstandingMessagesTypes";
import StarIcon from "@material-ui/icons/Star";
import { YELLOW } from "../../../../../../constants/colors";

const StyledCard = styled(Card)<{
  selected: boolean;
  hovered?: string;
  concealed?: string;
  disabled?: string;
  outstanding?: boolean;
}>`
  border: 2px solid #e7e7e7;
  box-shadow: none;
  border-radius: 0.5rem;
  transition: all ease-in-out 150ms;
  overflow: visible;
  &:hover {
    cursor: pointer;
    background-color: #fbfbfb;
    border: 2px solid ${cuideoTheme.palette.primary.main};
  }

  ${({ selected }) => {
    if (selected) {
      return `
        background-color: #f0f1f2;
      `;
    }
  }}
  ${({ concealed }) => {
    if (concealed) {
      return `
        opacity: 0.5;
        &:hover {
          opacity: 0.75;
        }
          `;
    }
  }}
  ${({ hovered, outstanding }) => {
    if (outstanding) {
      return `
        border: 2px solid ${YELLOW};
      `;
    }
    if (hovered) {
      return `
        background-color: #fbfbfb;
        border: 2px solid ${cuideoTheme.palette.primary.main};
      `;
    }
  }}
  ${({ disabled }) => {
    if (disabled) {
      return `
      pointer-events: none;
      user-select: none;
      opacity: 0.35;
      &:hover {
        opacity: 0.35;
      }
    `;
    }
  }}
`;

const StyledFirstRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const StyledIcons = styled.div`
  max-width: 2.5rem;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
`;

const StyledRowIcons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  .MuiSvgIcon-root {
    opacity: 0.75;
    margin: 0.125rem 0.125rem 0;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  padding: 0.25rem;
  .MuiSvgIcon-root {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

const StyledAvatarWrapper = styled.div`
  margin: 0.5rem 1rem 0.5rem 0.25rem;
  .MuiAvatar-root {
    width: 5rem;
    height: 5rem;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
  .CMuiCuideoUserAvatar-felizVitaLogo {
    width: 1.875rem;
    height: 1.875rem;
  }
`;

const StyledCandidatoInfoWrapper = styled.div`
  flex-grow: 1;
  margin-right: 0.5rem;
  position: relative;
`;

const StyledCandidatoName = styled(Typography)`
  color: #222;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1;
  margin: 0;
  padding-right: 2.25rem;
  max-height: 1.25rem;
  overflow-y: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
`;

const StyledCandidatoCreated = styled(Typography)`
  font-size: 0.75rem;
  color: ${cuideoTheme.palette.primary.main};
  opacity: 0.75;
  line-height: 1.15;
  margin: 0 2.25rem 0.5rem 0;
`;

const StyledStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledCandidatoEstado = styled.div`
  .CMuiCandidateStatus {
    height: 2rem;
    padding: 0.25rem 0.5rem;
    width: 6rem;
    text-align: center;
  }

  margin-right: 0.5rem;
`;

const StyledPipelineButton = styled.div`
  flex-grow: 1;
  .MuiFilledInput-root {
    height: 2.5rem;
    background-color: #ff4a6b;
  }
  .MuiSelect-select {
    font-size: 1rem;
  }
  .MuiFilledInput-input {
    padding-top: 21px;
  }
  .MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(12px, 4px) scale(0.75);
  }
`;

const StyledSecondRow = styled.div`
  background-color: #f0f1f2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.125rem 0.25rem;
  min-height: 1.5rem;
`;

const StyledInscritaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .MuiSvgIcon-root {
    color: green;
    width: 1rem;
    height: 1rem;
  }

  .CMuiIcon {
    color: #004b7b;
    width: 1rem;
    height: 1rem;
    margin-right: 0.125rem;
  }
`;

const StyledInscritaText = styled(Typography)`
  font-size: 0.75rem;
  color: ${cuideoTheme.palette.primary.main};
`;

interface IValuationCardProps {
  valuation: TContractValuation;
  isSelected: boolean;
  onSelect: TOnSelectValuationFn;
  isDisabled: boolean;
  onChange: TOnContractValuationChangeFn;
  isMultiSelected: boolean;
  onMultiSelect: TOnMultiSelectValuationFn;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  isHovered?: boolean;
  isConcealed?: boolean;
  isBlocked?: boolean;
  canAdvanceMoreValuations: boolean;
  isOutstanding: boolean;
  onContracted: TOnContractedValuationFn;
}

const ValuationCard: React.FC<IValuationCardProps> = (props) => {
  const {
    valuation,
    isSelected,
    onSelect,
    onMultiSelect,
    isDisabled,
    onChange,
    isMultiSelected,
    onMouseEnter,
    onMouseLeave,
    isBlocked,
    isConcealed,
    isHovered,
    canAdvanceMoreValuations,
    isOutstanding,
    onContracted,
  } = props;

  const cardRef = useRef();
  const { authUser, permissions } = useAuthUser();

  const handleValuationClick = () => {
    onSelect(valuation);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();

    onMultiSelect(valuation);
  };

  const favorite = valuation.candidato
    ? candidateFavoriteOfUser(valuation.candidato, authUser.userId)
    : null;

  const isOutstandingValuation =
    valuation.Descripci_n === ADDED_FROM_OUTSTANDINGS;

  return (
    <StyledCard
      onClick={handleValuationClick}
      selected={isSelected}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      hovered={isHovered ? "hovered" : undefined}
      concealed={isConcealed ? "concealed" : undefined}
      disabled={isDisabled ? "disabled" : undefined}
      ref={cardRef}
      outstanding={isOutstandingValuation}
    >
      {isOutstandingValuation && (
        <div
          style={{
            position: "absolute",
            backgroundColor: YELLOW,
            padding: 2,
            borderRadius: "4px 0px 20px 0px",
          }}
        >
          <StarIcon fontSize="small" />
        </div>
      )}
      <StyledFirstRow>
        {!isBlocked && (
          <StyledCheckbox
            checked={isMultiSelected}
            onChange={handleCheckboxChange}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
            }}
            color="primary"
          />
        )}
        <StyledAvatarWrapper>
          <CuideoUserAvatar
            user={{
              userFullName: "",
              userAvatar: valuation.candidato?.avatarUrl,
            }}
            isFelizVita={valuation.candidato?.Company === "FV"}
          />
        </StyledAvatarWrapper>
        <StyledCandidatoInfoWrapper>
          {valuation.Estado_en_proceso !== VALORACION_RECOMENDACION &&
            valuation.Estado_en_proceso !== VALORACION_SUGGESTION && (
              <StyledIcons>
                <ValuationIcons
                  valuation={valuation}
                  favorite={favorite ?? undefined}
                />
              </StyledIcons>
            )}
          {/* {valuation.Estado_en_proceso === VALORACION_SUGGESTION && (
            <StyledIcons>
              <SuggestionIssues valuation={valuation} />
            </StyledIcons>
          )} */}
          {valuation.candidato && (
            <StyledCandidatoName>
              {valuation.candidato.Nombre} {valuation.candidato.Apellidos}
            </StyledCandidatoName>
          )}
          <StyledCandidatoCreated>
            {valuation.candidato?.age &&
            valuation.candidato?.A_os_de_experiencia ? (
              <>
                <FormattedMessage
                  id="ValuationCard.AgeAndExp"
                  defaultMessage="{age} años. "
                  values={{
                    age: valuation.candidato.age,
                  }}
                />
                <FormattedEmployeeExperienceField
                  value={valuation.candidato.A_os_de_experiencia}
                />
              </>
            ) : valuation.candidato?.age ? (
              <FormattedMessage
                id="ValuationCard.Age"
                defaultMessage="{age} años."
                values={{
                  age: valuation.candidato.age,
                }}
              />
            ) : valuation.candidato?.A_os_de_experiencia ? (
              <FormattedEmployeeExperienceField
                value={valuation.candidato.A_os_de_experiencia}
              />
            ) : (
              ""
            )}
          </StyledCandidatoCreated>
          <StyledStatus>
            {valuation.candidato?.Estado_Cuideo && (
              <StyledCandidatoEstado>
                <CandidateStatusTag
                  candidateId={valuation.candidato.id}
                  candidateName={valuation.candidato.Nombre}
                  status={valuation.candidato?.Estado_Cuideo}
                  numContractsWorked={valuation.candidato?.numContractsWorked}
                  numDaysWorked={valuation.candidato?.numDaysWorked}
                  canOpenDetails={!isConcealed}
                />
              </StyledCandidatoEstado>
            )}
            {!isBlocked && (
              <StyledPipelineButton>
                <ValuationPipelineButton
                  valoracion={valuation}
                  disabled={isDisabled || (valuation as any).alreadyAdded}
                  // alreadyAdded={valoracion.alreadyAdded}
                  onChange={onChange}
                  userPermissions={permissions}
                  canAdvanceMoreValuations={canAdvanceMoreValuations}
                  isOutstanding={isOutstanding}
                  onContracted={onContracted}
                />
              </StyledPipelineButton>
            )}
          </StyledStatus>
        </StyledCandidatoInfoWrapper>
      </StyledFirstRow>
      <StyledSecondRow
        style={{
          backgroundColor:
            valuation.Estado_en_proceso === VALORACION_SUGGESTION
              ? "#e8f4fd"
              : valuation.Estado_en_proceso === VALORACION_RECOMENDACION ||
                !valuation.Candidato_Web
              ? "#fff4e5"
              : "#f0f1f2",
        }}
      >
        {valuation.Estado_en_proceso !== VALORACION_RECOMENDACION &&
        valuation.Estado_en_proceso !== VALORACION_SUGGESTION ? (
          <>
            {valuation.Candidato_Web ? (
              <StyledInscritaWrapper>
                <CheckIcon />
                <StyledInscritaText>
                  <FormattedMessage
                    id="ValuationCard.inscrita"
                    defaultMessage="{Inscrita} el {fechaInscrita} a las {horaInscrita}"
                    values={{
                      Inscrita: <b>Inscrita</b>,
                      fechaInscrita: moment(valuation.createdAt).format(
                        "DD/MM/YYYY"
                      ),
                      horaInscrita: moment(valuation.createdAt).format("HH:mm"),
                    }}
                  />
                </StyledInscritaText>
              </StyledInscritaWrapper>
            ) : valuation.Source === "Añadido Bot Affinity" ||
              valuation.Descripci_n === "Añadido Bot Affinity" ? (
              <StyledInscritaWrapper>
                <FaRobot className="CMuiIcon" />
                <StyledInscritaText>
                  <FormattedMessage
                    id="ValuationCard.BotAdded"
                    defaultMessage="{AgregadaPorBot} el {fechaInscrita} a las {horaInscrita}"
                    values={{
                      AgregadaPorBot: <b>Agregada por Bot</b>,
                      fechaInscrita: moment(valuation.createdAt).format(
                        "DD/MM/YYYY"
                      ),
                      horaInscrita: moment(valuation.createdAt).format("HH:mm"),
                    }}
                  />
                </StyledInscritaText>
              </StyledInscritaWrapper>
            ) : (
              <StyledInscritaWrapper>
                <AddIcon />
                <StyledInscritaText>
                  <FormattedMessage
                    id="ValuationCard.agregada"
                    defaultMessage="{Agregada} el {fechaInscrita} a las {horaInscrita}"
                    values={{
                      Agregada: <b>Agregada</b>,
                      fechaInscrita: moment(valuation.createdAt).format(
                        "DD/MM/YYYY"
                      ),
                      horaInscrita: moment(valuation.createdAt).format("HH:mm"),
                    }}
                  />
                </StyledInscritaText>
              </StyledInscritaWrapper>
            )}
            {valuation.Estado_en_proceso !== VALORACION_DESCARTADO &&
              !(
                valuation.Estado_en_proceso === VALORACION_CONTRATADO &&
                !authUser.userRoles.includes("ROLE_MANAGER")
              ) && (
                <ValoracionDescartarButton
                  valuation={valuation}
                  isDisabled={isDisabled}
                  onChange={onChange}
                />
              )}
          </>
        ) : (
          <>
            <StyledInscritaWrapper>
              <StyledInscritaText>
                {isOutstanding ? (
                  <FormattedMessage
                    id="ValuationCard.termina"
                    defaultMessage="{Termina} el contrato el {fecha}"
                    values={{
                      Termina: moment(valuation.candidato?.lastContractEndDate).isBefore(moment())
                        ? 'Terminó'
                        : 'Termina',
                      fecha: moment(
                        valuation.candidato?.lastContractEndDate
                      ).format("DD/MM/YYYY"),
                    }}
                  />
                ) : valuation.Estado_en_proceso === VALORACION_SUGGESTION ? (
                  <SuggestionType type={(valuation as any).suggestionType} />
                ) : valuation.candidato?.createdAt ? (
                  <FormattedMessage
                    id="ValuationCard.Creada el {fechaCreacion}"
                    defaultMessage="Creada el {fechaCreacion}"
                    values={{
                      fechaCreacion: moment(
                        valuation.candidato.createdAt
                      ).format("DD/MM/YYYY"),
                    }}
                  />
                ) : null}
              </StyledInscritaText>
            </StyledInscritaWrapper>

            {valuation.candidato &&
              valuation.Estado_en_proceso === VALORACION_RECOMENDACION && (
                <StyledRowIcons>
                  <ValuationIcons
                    valuation={valuation}
                    favorite={favorite ?? undefined}
                  />
                </StyledRowIcons>
              )}
            {valuation.Estado_en_proceso === VALORACION_SUGGESTION && (
              <ValoracionDescartarButton
                valuation={valuation}
                isDisabled={isDisabled}
                onChange={onChange}
              />
            )}
          </>
        )}
      </StyledSecondRow>
    </StyledCard>
  );
};

export default React.memo(
  (props: IValuationCardProps) => {
    return <ValuationCard {...props} />;
  },
  (prevProps, nextProps) => {
    if (
      prevProps.isSelected !== nextProps.isSelected ||
      prevProps.isHovered !== nextProps.isHovered ||
      prevProps.isBlocked !== nextProps.isBlocked ||
      prevProps.isDisabled !== nextProps.isDisabled ||
      prevProps.isMultiSelected !== nextProps.isMultiSelected ||
      prevProps.isConcealed !== nextProps.isConcealed
    ) {
      return false;
    }

    if (
      prevProps.valuation.candidato?.Vencimiento_NIE !==
        nextProps.valuation.candidato?.Vencimiento_NIE ||
      prevProps.valuation.candidato?.avatarUrl !==
        nextProps.valuation.candidato?.avatarUrl ||
      prevProps.valuation.candidato?.favoritedBy !==
        nextProps.valuation.candidato?.favoritedBy
    ) {
      return false;
    }
    return true;
  }
);
