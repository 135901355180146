import { Typography } from "@material-ui/core";
import classNames from "classnames";
import moment from "moment";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { TStaffUserResponse } from "../../../../../../api/user/apiListStaffUsers";
import {
  COLOR_RED_NOTIFICATION,
  COLOR_SUCCESS,
} from "../../../../../../containers/themes/defaultTheme";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
  color: #888;
  &.CMui-error {
    color: ${COLOR_RED_NOTIFICATION};
  }
  &.CMui-success {
    color: ${COLOR_SUCCESS};
    .CMuiUserActiveLastOnlineBall {
      background-color: ${COLOR_SUCCESS};
    }
  }
`;

const StyledLabel = styled(Typography)`
  font-size: 0.75rem;
  line-height: 1;
  margin: 0;
  color: inherit;
`;

const StyledOnlineBall = styled.div`
  display: block;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
`;

interface IUserActiveLastProps {
  lastActivityTime: TStaffUserResponse["lastActivityTime"];
  active: TStaffUserResponse["active"];
  enabled: TStaffUserResponse["enabled"];
  isOnline?: boolean;
  currentTime?: Date;
}

const UserActiveLast: React.FC<IUserActiveLastProps> = (props) => {
  const {
    lastActivityTime,
    active,
    enabled,
    isOnline,
    currentTime = new Date(),
  } = props;

  return (
    <StyledWrapper
      className={classNames({
        CMuiUserActiveLast: true,
        "CMui-error": !active,
        "CMui-disabled": !enabled,
        "CMui-success": isOnline,
      })}
    >
      {!active ? (
        <StyledLabel className="CMuiUserActiveLastLabel">
          <FormattedMessage
            id="UserActiveLast.Inactivo"
            defaultMessage="Inactivo"
          />
        </StyledLabel>
      ) : !enabled ? (
        <StyledLabel className="CMuiUserActiveLastLabel">
          <FormattedMessage
            id="UserActiveLast.No disponible"
            defaultMessage="No disponible"
          />
        </StyledLabel>
      ) : isOnline ? (
        <>
          <StyledOnlineBall className="CMuiUserActiveLastOnlineBall" />
          <StyledLabel className="CMuiUserActiveLastLabel">
            <FormattedMessage
              id="UserActiveLast.En línea"
              defaultMessage="En línea"
            />
          </StyledLabel>
        </>
      ) : (
        <StyledLabel className="CMuiUserActiveLastLabel">
          <FormattedMessage
            id="UserActiveLast.Activo {timeAgo}"
            defaultMessage="Activo {timeAgo}"
            values={{
              timeAgo: moment(lastActivityTime).from(currentTime),
            }}
          />
        </StyledLabel>
      )}
    </StyledWrapper>
  );
};

export default UserActiveLast;
