import axios from "axios";
import { push } from "connected-react-router";
import moment from "moment";
import uuidv4 from "uuid/v4";
import {
  fetchStart,
  fetchSuccess,
  modalShow,
  refreshingToken,
  tokenRefreshed,
  uploadStart,
  uploadSuccess,
  userSetRefreshToken,
  userSetToken,
} from "../actions";
import { apiCoreV3 } from "../apiCoreV3/apiCoreV3";
import { store } from "../store";
import { JOB_SIGN_IN } from "../constants/routes";

let sessId = sessionStorage.getItem("sessId");
if (!sessId) {
  sessId = uuidv4();
  sessionStorage.setItem("sessId", sessId);
}

const apiAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : `http://dev.corev2.cuideo.com/api/`,
  headers: {
    "Content-Type": "application/json",
    "X-Sess-Id": sessId,
    "X-Client-Id": process.env.REACT_APP_CLIENT_ID
      ? process.env.REACT_APP_CLIENT_ID
      : "none",
  },
});

// Checks if token is valid and if not, we refresh it if possible
// Updates or dispatch token to CoreV2 and CoreV3 clients
const isTokenValid = async () => {
  const storeState = store.getState();

  // Anonymous is always ok
  if (!storeState.auth.token) {
    return true;
  }
  if (
    storeState.auth.tokenExpiry &&
    moment(storeState.auth.tokenExpiry) > moment()
  ) {
    return true;
  }
  if (storeState.auth.refreshToken) {
    // Try to refresh token using direct axios
    delete apiAxios.defaults.headers.common["Authorization"];
    delete apiCoreV3.defaults.headers.common["Authorization"];
    try {
      store.dispatch(refreshingToken());
      let res = await apiAxios.post(
        "token/refresh",
        {
          refresh_token: storeState.auth.refreshToken,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res && res.data) {
        if (res.data.token) {
          store.dispatch(
            userSetToken(res.data.token, storeState.auth.rememberMe)
          );
        }
        if (res.data.refresh_token) {
          store.dispatch(
            userSetRefreshToken(
              res.data.refresh_token,
              storeState.auth.rememberMe
            )
          );
        }
      }
      store.dispatch(tokenRefreshed());
    } catch (e) {
      // @ts-ignore
      if (e.response && e.response.status && e.response.status === 401) {
        // Unauthorized access. Most probably an expired refresh token or just
        // a closed session
        store.dispatch(userSetToken(null));
        store.dispatch(userSetRefreshToken(null));
        // Not dispatching a userSignOut to avoid snack Message
        store.dispatch(
          modalShow({
            open: true,
            type: "error_fixed_message",
            message: "unauthorized",
            variant: "user_expired",
          })
        );
        store.dispatch(push(JOB_SIGN_IN));
      }

      store.dispatch(tokenRefreshed());

      throw e;
    }
  }
  return false;
};

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const waitForTokenReady = async () => {
  let numTries = 0;
  while (store.getState().auth.isRefreshingToken && numTries < 3) {
    await sleep(500);
  }
};

const apiEmpleo = {
  async get(endpoint: string, config?: any) {
    await waitForTokenReady();
    const opId = uuidv4();
    if (config && !config.hideLoadingState) {
      store.dispatch(fetchStart(opId, endpoint));
    }
    const validToken = await isTokenValid();
    if (validToken) {
    }
    const getConfig = config ? config : {};
    let res = await apiAxios.get(endpoint, getConfig);
    if (res.data) {
      if (config && !config.hideLoadingState) {
        store.dispatch(fetchSuccess(opId));
      }
      return res.data;
    }
  },

  async getRawBinary(endpoint: string, config?: any) {
    await waitForTokenReady();
    const opId = uuidv4();
    if (config && !config.hideLoadingState) {
      store.dispatch(fetchStart(opId, endpoint));
    }
    await isTokenValid();
    const getConfig = config ? config : {};
    getConfig.responseType = "arraybuffer";
    let res = await apiAxios.get(endpoint, getConfig);
    if (res) {
      if (config && !config.hideLoadingState) {
        store.dispatch(fetchSuccess(opId));
      }
      return res;
    }
  },

  async post(
    endpoint: string,
    data?: any,
    config?: any,
    showStatusOnScreen = true
  ) {
    await waitForTokenReady();
    const opId = uuidv4();
    showStatusOnScreen && store.dispatch(uploadStart(opId, endpoint));
    try {
      const validToken = await isTokenValid();
      if (validToken) {
      }
    } catch (e) {
      throw e;
    }
    const postData = data ? data : {};
    const postConfig = config ? config : {};
    let res = await apiAxios.post(endpoint, postData, postConfig);
    if (res.data) {
      showStatusOnScreen && store.dispatch(uploadSuccess(opId));
      return res.data;
    }
  },

  async delete(endpoint: string, config?: any) {
    await waitForTokenReady();
    const opId = uuidv4();
    store.dispatch(uploadStart(opId, endpoint));
    const validToken = await isTokenValid();
    if (validToken) {
    }
    const deleteConfig = config ? config : {};
    let res = await apiAxios.delete(endpoint, deleteConfig);
    // 204 is the OK for the delete ops
    if (res.status === 204) {
      store.dispatch(uploadSuccess(opId));
      return res.data;
    }
  },

  async put(endpoint: string, data?: any, config?: any) {
    await waitForTokenReady();
    const opId = uuidv4();
    store.dispatch(uploadStart(opId, endpoint));
    const validToken = await isTokenValid();
    if (validToken) {
    }
    const putData = data ? data : {};
    const putConfig = config ? config : {};
    let res = await apiAxios.put(endpoint, putData, putConfig);
    if (res.data) {
      store.dispatch(uploadSuccess(opId));
      return res.data;
    }
  },

  async patch(endpoint: string, data?: any, config?: any) {
    await waitForTokenReady();
    const opId = uuidv4();
    store.dispatch(uploadStart(opId, endpoint));
    const validToken = await isTokenValid();
    if (validToken) {
    }
    const patchData = data ? data : {};
    const patchConfig = config ? config : {};
    let res = await apiAxios.patch(endpoint, patchData, patchConfig);
    if (res.data) {
      store.dispatch(uploadSuccess(opId));
      return res;
    }
    return res;
  },

  async multiPatch(multiReq: any) {
    await waitForTokenReady();
    const validToken = await isTokenValid();
    if (validToken) {
    }
    const reqArray = [] as any;
    multiReq.forEach(async (item: any) => {
      reqArray.push(await apiAxios.patch(item.url, item.data, item.config));
    });

    const batchSize = 1;
    let position = 0;
    let res: any = [];
    while (position < reqArray.length) {
      const reqsForBatch = reqArray.slice(position, position + batchSize);
      // TODO: Review this: Not working as expected
      const partialRes = await Promise.all(reqsForBatch);
      res = [...res, ...partialRes];
      position += batchSize;
    }

    return res;
  },
};

const apiPatchRequest = async (endpoint: string, newObj: any, oldObj: any) => {
  const newData: any = {};
  for (let prop in newObj) {
    if (Object.prototype.hasOwnProperty.call(newObj, prop)) {
      if (!oldObj.hasOwnProperty(prop) || oldObj[prop] !== newObj[prop]) {
        newData[prop] = newObj[prop];
      }
    }
  }

  let res = await apiEmpleo.patch(
    endpoint,
    {
      ...newData,
    },
    {
      headers: {
        "Content-Type": "application/merge-patch+json",
      },
    }
  );

  return res;
};

export { apiEmpleo, apiPatchRequest, apiAxios as default };
