import { useQuery } from "react-query";
import { apiGetOffers } from "../../../api/offers/apiGetOffers";
import { OffersFilter } from "../../../api/offers/apiOffer.types";
import { useState } from "react";
import { OfertaType } from "../../../types/OfertaType";

type UseOffersQueryOptions = {
  filters: OffersFilter;
};

function clean(obj: any) {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName].length === 0
    ) {
      delete obj[propName];
    }
  }
  return obj;
}

export const useOffersQuery = ({ filters }: UseOffersQueryOptions) => {
  const [offers, setOffers] = useState<OfertaType[]>([]);
  const [displayedCounter, setDisplayedCounter] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);

  const nonNullFilters = clean({ ...filters });

  const getOffers = async (filters: OffersFilter) => {
    const response = await apiGetOffers(filters);
    const responseOffers = response["hydra:member"];
    if (filters.page === 1) {
      setOffers(responseOffers);
      setDisplayedCounter(responseOffers.length);
    } else {
      setOffers([...offers, ...responseOffers]);
      setDisplayedCounter(
        (displayedCounter) => displayedCounter + responseOffers.length
      );
    }
    const responseTotalItems = response["hydra:totalItems"];
    setTotalItems(responseTotalItems);
    return {
      offers: responseOffers,
      totalItems: responseTotalItems,
    };
  };

  const query = useQuery({
    queryKey: ["empleo-offers", nonNullFilters, page],
    queryFn: () => getOffers({ ...nonNullFilters, page }),
  });

  const addNextPage = () => setPage((page) => page + 1);
  const resetPage = () => setPage(1);

  return {
    offers,
    totalItems,
    displayLoadMore: displayedCounter < totalItems,
    ...query,
    addNextPage,
    resetPage,
  };
};
