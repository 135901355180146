import moment from "moment";
import { useQuery } from "react-query";
import { apiGetServices } from "../../../../../../../api/service/apiGetServices";
import { TGetServicesCollectionItem } from "../../../../../../../api/service/service.types";
import { TServicesUnratedPageFilters } from "./useServicesUnratedPageFilters";

export type TUnratedServicesResponse = TGetServicesCollectionItem & {
  hoursUntilAssignment: number | null;
};

export default function useServicesUnratedPageQuery(
  servicesPageFilters: TServicesUnratedPageFilters
) {
  return useQuery(
    ["unrated_services", servicesPageFilters.page, servicesPageFilters.ownerId],
    async () => {
      const params = {
        itemsPerPage: 20,
        partial: 1,
        page: servicesPageFilters.page,
      } as any;

      params.state = servicesPageFilters.state;
      params["order[ownerAssignedAt]"] = "asc";

      if (null !== servicesPageFilters.ownerId) {
        params["owner"] = servicesPageFilters.ownerId;
      }

      const res = await apiGetServices(params);
      const now = moment();
      const services = res["hydra:member"].map(
        (service: TGetServicesCollectionItem) => {
          if (!service.ownerAssignedAt) {
            return {
              ...service,
              hoursUntilAssignment: null,
            };
          }
          const then = moment(service.ownerAssignedAt);
          const ms = moment(now).diff(then);
          const d = moment.duration(ms);
          const newService: TUnratedServicesResponse = {
            ...service,
            hoursUntilAssignment: Math.floor(d.asHours()),
          };
          return newService;
        }
      );
      return {
        services,
        totalItems: res["hydra:totalItems"],
      };
    },
    {
      enabled: servicesPageFilters.ready,
      cacheTime: 1000 * 60 * 5,
    }
  );
}
