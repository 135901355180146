import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { cuideoTheme } from "../../../../../../containers/themes/defaultTheme";
import { TContractFull } from "../../types/contract.types";
import {
  contractIsComfort,
  contractIsComfortPlus,
} from "../../util/contract.util";

const StyledPlanTag = styled.span`
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-size: 0.75rem;
  color: ${cuideoTheme.palette.primary.main};
`;

interface IPlanTagProps {
  contract: TContractFull;
}

const PlanTag: React.FC<IPlanTagProps> = ({ contract }) => {
  if (contractIsComfortPlus(contract)) {
    return (
      <StyledPlanTag>
        <FormattedMessage id="PlanTag.Confort+" defaultMessage="Confort+" />
      </StyledPlanTag>
    );
  }

  if (contractIsComfort(contract)) {
    return (
      <StyledPlanTag>
        <FormattedMessage id="PlanTag.Confort" defaultMessage="Confort" />
      </StyledPlanTag>
    );
  }

  return null;
};

export default PlanTag;
