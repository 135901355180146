import { useQuery } from "react-query";
import { apiEmpleo } from "../util/ApiEmpleo";

export default function useEmployeeVideoInterviewQuery(employeeId: string) {
  return useQuery(
    ["employeeVideoInterview", employeeId],
    async () => {
      let res = await apiEmpleo.get(`candidatos/${employeeId}/video-interview`);

      return res;
    },
    {
      enabled: !!employeeId,
      retry: 1,
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 5,
    }
  );
}
