import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import {
  FormikCheckbox,
  FormikSelect,
  FormikTextField,
} from "../../../../../../components/FormikFields";
import { cuideoTheme } from "../../../../../../containers/themes/defaultTheme";
import {
  TIPO_TRATO_EXTERNA_MAS_20_HORAS,
  TIPO_TRATO_EXTERNA_MAS_20_HORAS_SIN_SUST,
  TIPO_TRATO_EXTERNA_MENOS_20_HORAS,
  TIPO_TRATO_EXTERNA_MENOS_20_HORAS_SIN_SUST,
  TIPO_TRATO_INTERNA_ENTRE_SEMANA,
  TIPO_TRATO_INTERNA_ENTRE_SEMANA_SIN_SUST,
  TIPO_TRATO_INTERNA_FIN_DE_SEMANA,
  TIPO_TRATO_INTERNA_FIN_DE_SEMANA_SIN_SUST,
} from "../../../../../../util/defaults/contrato";
import PublicadoStatus from "../../../../components/PublicadoStatus";
import useContractTaskOptions from "../../../../hooks/useContractTaskOptions";
import { TContractFull } from "../../types/contract.types";
import { TContractEditModalForm } from "./ContractEditModal.types";
import useContractEditFormInitialValues from "./hooks/useContractEditFormInitialValues";
import useContractEditFormValuationSchema from "./hooks/useContractEditFormValuationSchema";
import useContractEditModalSubmit from "./hooks/useContractEditModalSubmit";

const StyledContent = styled.div`
  padding: 0.5rem 1rem;
  max-height: calc(100vh - 20rem);
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 72, 120, 0.25);
    width: 0.5rem;
    border-radius: 0.25rem;
  }
`;

const StyledWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const StyledPublishInfo = styled.div`
  .MuiContrato-status {
    padding: 0.6875rem 0.875rem;
    border: 1px solid #c2d1da;
    border-radius: ${cuideoTheme.shape.borderRadius}px;
  }
  .MuiContrato-status-published {
    border-color: rgba(126, 211, 33, 1);
  }
  .MuiContrato-status-notpublished {
    border-color: rgba(255, 0, 0, 1);
  }
  .MuiContrato-status-nullpublished {
    border-color: #ccc;
  }

  .MuiContrato-status-circle {
  }
  .MuiContrato-status-text {
    font-size: 1rem;
    font-weight: 700;
  }
`;

const StyledDisclaimer = styled(Typography)`
  font-size: 0.75rem;
  font-style: italic;
  line-height: normal;
`;

const StyledPublicarButton = styled(Button)`
  margin-left: 0.5rem;
  height: 2.5rem;
  width: 6.25rem;

  .MuiCircularProgress-root {
    color: #aaa;
  }
`;

const StyledDespublicarButton = styled(Button)`
  margin-left: 0.5rem;
  height: 2.5rem;
  width: 8.125rem;

  .MuiCircularProgress-root {
    color: #aaa;
  }
`;

const StyledAlert = styled(Alert)`
  font-size: 0.875rem;
`;

const StyledActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 0.5rem 0.5rem;
`;

const StyledCancelButton = styled(Button)``;

const StyledSaveButton = styled(Button)`
  margin-left: 0.5rem;
  height: 2.5rem;
  width: 6.25rem;

  .MuiCircularProgress-root {
    color: #aaa;
  }
`;

interface IContractEditInnerModalProps {
  contract: TContractFull;
  onClose: () => void;
  onChange: (payload: any) => void;
}

const ContractEditInnerModal: React.FC<IContractEditInnerModalProps> = (
  props
) => {
  const { contract, onClose, onChange } = props;

  const taskOptions = useContractTaskOptions();
  const validationSchema = useContractEditFormValuationSchema();
  const formInitialValues = useContractEditFormInitialValues(contract);
  const { submitStatus, submitError, submitData } = useContractEditModalSubmit(
    contract,
    onChange
  );

  const handleFormSubmit = async (
    values: TContractEditModalForm,
    actions: FormikHelpers<TContractEditModalForm>
  ) => {
    try {
      await submitData(values);
      onClose();
    } catch (e) {}
  };

  const tipoTratoOptions = [
    {
      value: TIPO_TRATO_INTERNA_ENTRE_SEMANA,
      label: TIPO_TRATO_INTERNA_ENTRE_SEMANA,
    },
    {
      value: TIPO_TRATO_INTERNA_FIN_DE_SEMANA,
      label: TIPO_TRATO_INTERNA_FIN_DE_SEMANA,
    },
    {
      value: TIPO_TRATO_EXTERNA_MAS_20_HORAS,
      label: TIPO_TRATO_EXTERNA_MAS_20_HORAS,
    },
    {
      value: TIPO_TRATO_EXTERNA_MENOS_20_HORAS,
      label: TIPO_TRATO_EXTERNA_MENOS_20_HORAS,
    },
    {
      value: TIPO_TRATO_INTERNA_ENTRE_SEMANA_SIN_SUST,
      label: TIPO_TRATO_INTERNA_ENTRE_SEMANA_SIN_SUST,
    },
    {
      value: TIPO_TRATO_INTERNA_FIN_DE_SEMANA_SIN_SUST,
      label: TIPO_TRATO_INTERNA_FIN_DE_SEMANA_SIN_SUST,
    },
    {
      value: TIPO_TRATO_EXTERNA_MAS_20_HORAS_SIN_SUST,
      label: TIPO_TRATO_EXTERNA_MAS_20_HORAS_SIN_SUST,
    },
    {
      value: TIPO_TRATO_EXTERNA_MENOS_20_HORAS_SIN_SUST,
      label: TIPO_TRATO_EXTERNA_MENOS_20_HORAS_SIN_SUST,
    },
  ];

  const tipoOfertaOptions = [
    {
      value: "Interna entre semana",
      label: "Interna entre semana",
    },
    {
      value: "Interna fin de semana",
      label: "Interna fin de semana",
    },
    {
      value: "Externa entre semana",
      label: "Externa entre semana",
    },
    {
      value: "Externa fin de semana",
      label: "Externa fin de semana",
    },
  ];

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={handleFormSubmit}
    >
      {(props) => (
        <Form>
          <StyledWrapper>
            {!props.isSubmitting && (
              <>
                {submitStatus === "error" ? (
                  <StyledAlert severity="error">{submitError}</StyledAlert>
                ) : (
                  props.submitCount !== 0 &&
                  !props.isValid && (
                    <StyledAlert severity="error">
                      <FormattedMessage
                        id="ContractEditModal.Tienes errores en el formulario. Por favor, revisalo"
                        defaultMessage="Tienes errores en el formulario. Por favor, revisalo"
                      />
                    </StyledAlert>
                  )
                )}
              </>
            )}
            <StyledContent>
              <Grid container spacing={8}>
                <Grid item xs={12} md={6}>
                  <StyledPublishInfo>
                    <PublicadoStatus contrato={contract} />
                  </StyledPublishInfo>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikCheckbox
                    name="Promoci_n_Indeed"
                    variant="compact"
                    label={
                      <FormattedMessage
                        id="ContractEditModal.Solicitar promo Indeed"
                        defaultMessage="Solicitar promo Indeed"
                      />
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={8}>
                <Grid item xs={12}>
                  <FormikTextField
                    name="T_tulo"
                    label={
                      <FormattedMessage
                        id="ContractEditModal.Título"
                        defaultMessage="Título"
                      />
                    }
                    fullWidth
                    size="small"
                    required
                    margin="nomargin"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={8}>
                <Grid item xs={12} md={6}>
                  <FormikSelect
                    name="Tipo_de_Trato"
                    label={
                      <FormattedMessage
                        id="ContractEditModal.Tipo de trato"
                        defaultMessage="Tipo de trato"
                      />
                    }
                    required
                    variant="compact"
                    margin="nomargin"
                    size="small"
                    disabled
                    options={tipoTratoOptions}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikSelect
                    name="Tipo"
                    label={
                      <FormattedMessage
                        id="ContractEditModal.Tipo"
                        defaultMessage="Tipo"
                      />
                    }
                    required
                    variant="compact"
                    margin="nomargin"
                    size="small"
                    options={tipoOfertaOptions}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={8}>
                <Grid item xs={12} md={6}>
                  <FormikTextField
                    name="Ciudad_oferta"
                    label={
                      <FormattedMessage
                        id="ConctractEditModal.Ciudad"
                        defaultMessage="Ciudad"
                      />
                    }
                    fullWidth
                    size="small"
                    required
                    margin="nomargin"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikTextField
                    name="Horario_Servicio"
                    label={
                      <FormattedMessage
                        id="ContractEditModal.Horario"
                        defaultMessage="Horario"
                      />
                    }
                    fullWidth
                    size="small"
                    required
                    margin="nomargin"
                    disabled
                  />
                </Grid>
              </Grid>
              <Box marginTop={8} marginBottom={8}>
                <Divider />
              </Box>
              <Grid container spacing={8}>
                <Grid item xs={12}>
                  <FormikTextField
                    name="Que_buscamos"
                    label={
                      <FormattedMessage
                        id="ContractEditModal.Qué buscamos?"
                        defaultMessage="Qué buscamos?"
                      />
                    }
                    multiline
                    maxRows="5"
                    minRows="3"
                    fullWidth
                    size="small"
                    required
                    margin="nomargin"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={8}>
                <Grid item xs={12}>
                  <FormikTextField
                    name="Requisitos"
                    label={
                      <FormattedMessage
                        id="ContractEditModal.Requisitos"
                        defaultMessage="Requisitos"
                      />
                    }
                    multiline
                    maxRows="5"
                    minRows="3"
                    fullWidth
                    size="small"
                    required
                    margin="nomargin"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikSelect
                    name="Tareas_solicitadas"
                    label={
                      <FormattedMessage
                        id="ContractEditModal.Tareas a realizar"
                        defaultMessage="Tareas a realizar"
                      />
                    }
                    isMulti
                    margin="nomargin"
                    size="small"
                    useBodyPortal
                    closeMenuOnSelect={false}
                    options={taskOptions}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={8} alignItems="center">
                <Grid item xs={12} md={4}>
                  <FormikTextField
                    name="Salario_Bruto_Mensual"
                    label={
                      <FormattedMessage
                        id="ContractEditModal.Salario"
                        defaultMessage="Salario"
                      />
                    }
                    fullWidth
                    size="small"
                    moneyformat
                    endAdornment={
                      <InputAdornment position="end">€</InputAdornment>
                    }
                    required
                    margin="normal"
                  />
                  <FormikSelect
                    name="Tipo_de_salario"
                    label={
                      <FormattedMessage
                        id="ContractEditModal.Tipo de salario"
                        defaultMessage="Tipo de salario"
                      />
                    }
                    margin="nomargin"
                    size="small"
                    variant="compact"
                    closeMenuOnSelect={true}
                    useBodyPortal
                    required
                    options={[
                      { value: "Diario", label: "Diario" },
                      { value: "Mensual", label: "Mensual" },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <StyledDisclaimer color="primary">
                    <FormattedMessage
                      id="ContratoEditModal.* Salario y condiciones prefijadas por la familia. El contrato laboral se establecerá entre el empleador (familia) y el trabajador a través del REEH (Régimen especial de empleadas de hogar). Cuideo, como agencia de colocación registrada con número 0900000353 interviene en el proceso de selección entre empleador y trabajador"
                      defaultMessage="* Salario y condiciones prefijadas por la familia. El contrato laboral se establecerá entre el empleador (familia) y el trabajador a través del REEH (Régimen especial de empleadas de hogar). Cuideo, como agencia de colocación registrada con número 0900000353 interviene en el proceso de selección entre empleador y trabajador"
                    />
                  </StyledDisclaimer>
                </Grid>
              </Grid>
            </StyledContent>
            <StyledActions>
              <StyledCancelButton
                color="primary"
                onClick={onClose}
                disabled={props.isSubmitting}
              >
                <FormattedMessage
                  id="ContractEditModal.Cancelar"
                  defaultMessage="Cancelar"
                />
              </StyledCancelButton>
              {contract.Publicar === "Si" ? (
                <StyledDespublicarButton
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    props.setFieldValue("Publicar", "No");
                    props.setFieldValue("op", "unpublish");
                    props.submitForm();
                  }}
                  disabled={props.isSubmitting}
                >
                  {submitStatus === "publishing" ? (
                    <CircularProgress size={18} thickness={5} />
                  ) : (
                    <FormattedMessage
                      id="ContractEditModal.Despublicar"
                      defaultMessage="Despublicar"
                    />
                  )}
                </StyledDespublicarButton>
              ) : (
                <StyledPublicarButton
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    props.setFieldValue("Publicar", "Si");
                    props.setFieldValue("op", "publish");
                    props.submitForm();
                  }}
                  disabled={props.isSubmitting}
                >
                  {submitStatus === "publishing" ? (
                    <CircularProgress size={18} thickness={5} />
                  ) : (
                    <FormattedMessage
                      id="ContractEditModal.Publicar"
                      defaultMessage="Publicar"
                    />
                  )}
                </StyledPublicarButton>
              )}
              <StyledSaveButton
                color="primary"
                variant="contained"
                type="submit"
                onClick={() => {
                  props.setFieldValue("op", "save");
                  props.submitForm();
                }}
                disabled={props.isSubmitting}
              >
                {submitStatus === "updating" ? (
                  <CircularProgress size={18} thickness={5} />
                ) : (
                  <FormattedMessage
                    id="ContractEditModal.Guardar"
                    defaultMessage="Guardar"
                  />
                )}
              </StyledSaveButton>
            </StyledActions>
          </StyledWrapper>
        </Form>
      )}
    </Formik>
  );
};

export default ContractEditInnerModal;
