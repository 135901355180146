import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { CONTRACT_NUM_PAYMENTS, CONTRACT_OVERNIGHT_NIGHTS, TGetContractsCollectionItem } from "../../../../../api/contract/contract.types";
import { getContractCustomerNewestEmployer } from "../../../../../util/Contrato/util";

export default function useContractToSelectedStateDialogFormContext() {
  const formikContext = useFormikContext<any>();
  const {
    contractTypeOptions,
    contractOvernightHoursOptions,
    contractPresenceHoursOptions,
    contractSalaryTypeOptions,
  } = useSelector((state: any) => state.commonData);

  const getCustomerNewestEmployer = async (contract: TGetContractsCollectionItem) => {
    return await getContractCustomerNewestEmployer(contract);
  };

  const contractOvernightNightsOptions: any[] = [
    {
      value: CONTRACT_OVERNIGHT_NIGHTS.ZERO,
      label: CONTRACT_OVERNIGHT_NIGHTS.ZERO
    },
    {
      value: CONTRACT_OVERNIGHT_NIGHTS.ONE,
      label: CONTRACT_OVERNIGHT_NIGHTS.ONE
    },
    {
      value: CONTRACT_OVERNIGHT_NIGHTS.TWO,
      label: CONTRACT_OVERNIGHT_NIGHTS.TWO
    },
    {
      value: CONTRACT_OVERNIGHT_NIGHTS.THREE,
      label: CONTRACT_OVERNIGHT_NIGHTS.THREE
    },
    {
      value: CONTRACT_OVERNIGHT_NIGHTS.FOUR,
      label: CONTRACT_OVERNIGHT_NIGHTS.FOUR
    },
    {
      value: CONTRACT_OVERNIGHT_NIGHTS.FIVE,
      label: CONTRACT_OVERNIGHT_NIGHTS.FIVE
    },
    {
      value: CONTRACT_OVERNIGHT_NIGHTS.SIX,
      label: CONTRACT_OVERNIGHT_NIGHTS.SIX
    },
    {
      value: CONTRACT_OVERNIGHT_NIGHTS.SEVEN,
      label: CONTRACT_OVERNIGHT_NIGHTS.SEVEN
    },
  ];

  const contractNumPaymentsOptions: any[] = [
    {
      value: CONTRACT_NUM_PAYMENTS.TWELVE,
      label: CONTRACT_NUM_PAYMENTS.TWELVE
    },
    {
      value: CONTRACT_NUM_PAYMENTS.TRHIRTEEN,
      label: CONTRACT_NUM_PAYMENTS.TRHIRTEEN
    },
    {
      value: CONTRACT_NUM_PAYMENTS.FOURTEEN,
      label: CONTRACT_NUM_PAYMENTS.FOURTEEN
    }
  ];

  return {
    formikContext,
    formOptions: {
      contractTypeOptions,
      contractOvernightHoursOptions,
      contractOvernightNightsOptions,
      contractPresenceHoursOptions,
      contractSalaryTypeOptions,
      contractNumPaymentsOptions
    },
    formActions: {
      getCustomerNewestEmployer
    }
  };
}

export type TContractToSelectedStateDialogFormContext = ReturnType<
  typeof useContractToSelectedStateDialogFormContext
>;
