import { apiEmpleo } from "../../util/ApiEmpleo";
import { CONTRACT_PHASE } from "./contract.types";

export type TContractManagerInfoResponse = {
  id: string;
  Estado?: string | null;
  Fecha_objetivo?: string | null;
  Owner?: {
    id: string;
  };
  Servicio?: {
    id: string;
    Owner?: {
      id: string;
      fullname?: string;
    };
    ownerName?: string;
  };
  Servicio_corta_duraci_n?: string | null;
  Tipo?: string | null;
  Tipo_de_Trato?: string | null;
};

export interface IApiListContractsManagerInfoParams {
  estado?: string[];
  phase?: (typeof CONTRACT_PHASE.RECRUIT | typeof CONTRACT_PHASE.PROMOTE)[];
  owner?: string[];
  itemsPerPage?: number;
}

const apiListContractsManagerInfo = async (
  params: IApiListContractsManagerInfoParams = {}
) => {
  const apiParams = {
    itemsPerPage: 1000,
    estado: typeof params.estado !== "undefined" ? params.estado : undefined,
    owner: typeof params.owner !== "undefined" ? params.owner : undefined,
    phase: typeof params.phase !== "undefined" ? params.phase : undefined,
  };

  let res: {
    "hydra:member": TContractManagerInfoResponse[];
    "hydra:totalItems": number;
  } = await apiEmpleo.get("contracts/info-manager", {
    params: apiParams,
  });

  return res;
};

export { apiListContractsManagerInfo };
