import cuideoTheme from "../../../containers/themes/defaultTheme";
import styled from "styled-components";
import { Button, Dialog, Typography } from "@material-ui/core";

export const StyledDialog = styled(Dialog)``;

export const StyledFormWrapper = styled.div`
  margin: 0 1rem;

  .CMuiDNIButtonAllowWrapper {
    .MuiFormControlLabel-label {
      color: ${cuideoTheme.palette.primary.main};
      font-size: 0.875rem;
    }
    .MuiSvgIcon-root {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
`;

export const StyledErrorBox = styled.div`
  color: ${cuideoTheme.palette.error.main};
  background-color: ${(cuideoTheme.palette.error as any)[100]};
  padding: 1rem;
  margin-top: 1rem;
  border: 1px solid ${cuideoTheme.palette.error.main};
  border-radius: ${cuideoTheme.shape.borderRadius}px;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
`;

export const StyledHelpText = styled(Typography)`
  font-size: 1rem;
  color: ${cuideoTheme.palette.primary.main};
`;

export const StyledSaveButton = styled(Button)`
  min-width: 6.25rem;
  min-height: 2.5rem;

  .MuiCircularProgress-root {
    color: #999;
  }
`;
