import { apiEmpleo } from "../../util/ApiEmpleo";

export type TApiUpdateUserGroupsRequest = {
  coordinationGroups: string[];
};

const apiUpdateCoordinationGroup = async (
  ownerId: string,
  request: TApiUpdateUserGroupsRequest
) => {
  let res = await apiEmpleo.patch(`users/${ownerId}`, request, {
    headers: {
      "Content-Type": "application/merge-patch+json",
    },
  });
  return res;
};

export { apiUpdateCoordinationGroup };
