import { OffersFilter } from "./apiOffer.types";
import { apiEmpleo } from "../../util/ApiEmpleo";
import { objectToURLParams } from "../../util/urlManager";
import { OfertaType } from "../../types/OfertaType";

const apiGetOffers = async (filters: OffersFilter) => {
  const res: {
    "hydra:member": OfertaType[];
    "hydra:totalItems": number;
  } = await apiEmpleo.get(`/contratos?${objectToURLParams(filters)}`);
  return res;
};

const apiGetOffersProvinces = async () => {
  const res = await apiEmpleo.get(`/contratos/provincias`);
  return res;
};

const apiGetOffersProvinceCities = async (province: string) => {
  const res = await apiEmpleo.get(
    `/contratos/provincias/ciudades?provincia=${province}`
  );
  return res;
};

export { apiGetOffers, apiGetOffersProvinces, apiGetOffersProvinceCities };
