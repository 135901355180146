import { Box, Typography } from "@material-ui/core";
import classNames from "classnames";
import moment from "moment";
import React, { Fragment } from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import MessageAuthor from "./MessageAuthor";
import { messageIsReceived, TMessage } from "./models/Message";

const StyledMessageWrapper = styled.div`
  clear: both;

  .CMuiCommunicationMessageAuthor {
    font-size: 0.9375rem;
    font-weight: 600;
    line-height: 1.25;
  }
`;

const StyledSentMessage = styled(Box)`
  margin: 0.125rem 0 0.125rem auto;
  padding: 0.5rem 0.75rem;
  background-color: #e1ffc7;
  border-radius: 0.25rem 0 0.25rem 0.25rem;
  max-width: 85%;
  width: fit-content;
  position: relative;
  box-shadow: 0 0.0625rem 0.0625rem rgba(0, 0, 0, 0.15);

  .CMuiMarkdown p {
    margin: 0;
  }

  .CMuiMarkdown code {
    font-size: 0.875rem;
  }

  &.CMuiMessage-withBottomMargin {
    margin: 0.125rem 0 0.625rem auto;
  }

  &:after {
    border-width: 0 0 0.5rem 0.5rem;
    border-color: transparent transparent transparent #e1ffc7;
    top: 0;
    right: -0.5rem;
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
  }
`;

const StyledReceivedMessage = styled(Box)`
  margin: 0.125rem auto 0.125rem 0;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
  max-width: 85%;
  width: fit-content;
  border-radius: 0 0.25rem 0.25rem 0.25rem;
  position: relative;
  box-shadow: 0 0.0625rem 0.0625rem rgba(0, 0, 0, 0.15);

  .CMuiMarkdown p {
    margin: 0;
  }

  .CMuiMarkdown code {
    font-size: 0.875rem;
  }

  &.CMuiMessage-withBottomMargin {
    margin: 0.125rem auto 0.625rem 0;
  }

  &:after {
    border-width: 0 0.5rem 0.5rem 0;
    border-color: transparent #fff transparent transparent;
    top: 0;
    left: -0.5rem;
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
  }
`;

const StyledMessageTextWrapper = styled.div`
  position: relative;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  display: flex;
  flex-direction: row;
`;

const StyledMessageText = styled.span`
  font-family: "Open Sans Pro", Helvetica, Arial, sans-serif;
  font-size: 0.9375rem;
  color: #333;
`;

const StyledMetadata = styled(Box)`
  position: relative;
  float: right;
  margin: -0.75rem -0.25rem -0.375rem 0.25rem;
`;

const StyledInnerMetadata = styled(Box)`
  display: flex;
  flex-direction: row;
`;

const StyledMessageTime = styled(Typography)`
  font-size: 0.75rem;
  color: #777;
`;

interface IConversationMessageProps {
  message: TMessage;
  seed?: string;
}

const ConversationMessage = (props: IConversationMessageProps) => {
  const { message, seed } = props;

  const hoursAgo = moment().diff(moment(message.createdAt), "hours");
  const separatorString =
    hoursAgo > 24
      ? "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
      : "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";

  return (
    <StyledMessageWrapper
      className={classNames({
        CMuiMessageWrapper: true,
      })}
    >
      {messageIsReceived(message) ? (
        <StyledReceivedMessage
          className={classNames({
            CMuiMessage: true,
            "CMuiMessage-in": true,
            "CMuiMessage-withAuthor": message.showAuthor,
            "CMuiMessage-withBottomMargin": message.marginBottom,
          })}
        >
          {!!message.createdBy && message.showAuthor && (
            <MessageAuthor author={message.createdBy.fullname} seed={seed} />
          )}
          <StyledMessageTextWrapper>
            <StyledMessageText>
              <ReactMarkdown className="CMuiMarkdown">
                {message.content + separatorString}
              </ReactMarkdown>
            </StyledMessageText>
          </StyledMessageTextWrapper>
          <StyledMetadata>
            {hoursAgo > 24 ? (
              <StyledMessageTime>
                {moment(message.createdAt).format("DD/MM/YYYY - HH:mm")}
              </StyledMessageTime>
            ) : (
              <StyledMessageTime>
                {moment(message.createdAt).format("HH:mm")}
              </StyledMessageTime>
            )}
          </StyledMetadata>
        </StyledReceivedMessage>
      ) : (
        <Fragment>
          <StyledSentMessage
            className={classNames({
              CMuiMessage: true,
              "CMuiMessage-out": true,
              "CMuiMessage-withAuthor": message.showAuthor,
              "CMuiMessage-withBottomMargin": message.marginBottom,
            })}
          >
            <StyledMessageTextWrapper>
              <StyledMessageText>
                <ReactMarkdown className="CMuiMarkdown">
                  {message.content + separatorString}
                </ReactMarkdown>
              </StyledMessageText>
            </StyledMessageTextWrapper>
            <StyledMetadata>
              <StyledInnerMetadata>
                {hoursAgo > 24 ? (
                  <StyledMessageTime>
                    {moment(message.createdAt).format("DD/MM/YYYY - HH:mm")}
                  </StyledMessageTime>
                ) : (
                  <StyledMessageTime>
                    {moment(message.createdAt).format("HH:mm")}
                  </StyledMessageTime>
                )}
              </StyledInnerMetadata>
            </StyledMetadata>
          </StyledSentMessage>
        </Fragment>
      )}
    </StyledMessageWrapper>
  );
};

export default ConversationMessage;
