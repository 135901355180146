import { Chip } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { USER_ROLES } from "../../../../../../api/security";
import {
  COORDINATION_GROUP,
  TStaffUserResponse,
} from "../../../../../../api/user/apiListStaffUsers";

const StyledTagsWrapper = styled.div``;

const StyledTag = styled(Chip)``;

interface IUserTagsProps {
  user: TStaffUserResponse;
}

const UserTags: React.FC<IUserTagsProps> = (props) => {
  const { user } = props;

  return (
    <StyledTagsWrapper>
      {user.roles.includes(USER_ROLES.ROLE_COORDINATION) && (
        <StyledTag
          label={
            <FormattedMessage
              id="UserTag.Coordinador/a"
              defaultMessage="Coordinador/a"
            />
          }
        />
      )}
      {user.roles.includes(USER_ROLES.ROLE_ES_PROMOTER) && (
        <StyledTag
          label={
            <FormattedMessage id="UserTag.Gestor/a" defaultMessage="Gestor/a" />
          }
        />
      )}
      {user.roles.includes(USER_ROLES.ROLE_ES_RECRUITER) && (
        <StyledTag
          label={
            <FormattedMessage
              id="UserTag.Reclutador/a"
              defaultMessage="Reclutador/a"
            />
          }
        />
      )}
      {user.coordinationGroups?.includes(COORDINATION_GROUP.BCN) && (
        <StyledTag
          label={
            <FormattedMessage
              id="UserTag.Squad: Barcelona"
              defaultMessage="Squad: Barcelona"
            />
          }
        />
      )}
      {user.coordinationGroups?.includes(COORDINATION_GROUP.MAD) && (
        <StyledTag
          label={
            <FormattedMessage
              id="UserTag.Squad: Madrid"
              defaultMessage="Squad: Madrid"
            />
          }
        />
      )}
      {/* {user.coordinationGroups?.includes(COORDINATION_GROUP.CAV) && (
        <StyledTag
          label={
            <FormattedMessage
              id="UserTag.Squad: País Vasco"
              defaultMessage="Squad: País Vasco"
            />
          }
        />
      )} */}
      {user.coordinationGroups?.includes(COORDINATION_GROUP.OTH_FAST) && (
        <StyledTag
          label={
            <FormattedMessage
              id="UserTag.Squad: Otros rápido"
              defaultMessage="Squad: Otros rápido"
            />
          }
        />
      )}
      {user.coordinationGroups?.includes(COORDINATION_GROUP.OTH_SLOW) && (
        <StyledTag
          label={
            <FormattedMessage
              id="UserTag.Squad: Otros lentos"
              defaultMessage="Squad: Otros lentos"
            />
          }
        />
      )}
    </StyledTagsWrapper>
  );
};

export default UserTags;
