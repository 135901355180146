import { IApiListCandidateValuationsParams } from "../../../../../api/valuation/apiListCandidateValuations";

export const valuationKeys = {
  all: ["valuations"] as const,
  listForCandidate: (params: IApiListCandidateValuationsParams) => {
    return [
      ...valuationKeys.all,
      "candidate-list",
      hashForCandidateList(params),
    ];
  },
};

const hashForCandidateList = (params: IApiListCandidateValuationsParams) => {
  let hash = "";
  hash += params.candidateId + ":";

  return hash;
};
