import React, { useState, useLayoutEffect, useRef } from "react";
import styled from "styled-components";

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const VariableContainer = (props: any) => {
  const { hash } = props;
  const targetRef = useRef(null);
  const [layout, setLayout] = useState({ x: 0, y: 0, width: 0, height: 0 });

  useLayoutEffect(() => {
    const updateWidth = () => {
      if (targetRef && targetRef.current) {
        const bounding = (targetRef.current as any).getBoundingClientRect();
        // Set height
        if (bounding) {
          const newHeight = window.innerHeight - bounding.y - 1; // Avoid scrollbar
          setLayout({
            x: bounding.x,
            y: bounding.y,
            width: bounding.width,
            height: newHeight,
          });
        }
      }
    };

    updateWidth();
    window.addEventListener("resize", updateWidth);

    // Body should not scroll
    // document.body.style.overflow = "hidden";
    return () => {
      // document.body.style.overflow = "unset";
      window.removeEventListener("resize", updateWidth);
    };
  }, [hash]);

  return (
    <StyledContentContainer ref={targetRef} style={{ height: layout.height }}>
      {props.children}
    </StyledContentContainer>
  );
};

export default VariableContainer;
