import { useQuery } from "react-query";
import { apiListContractEvents } from "../../../../../api/contract/apiListContractEvents";
import {
  ExtractFnReturnType,
  QueryConfig,
} from "../../../../../lib/react-query";

export const getContractEvents = async ({
  contractId,
}: {
  contractId: string;
}) => {
  const response = await apiListContractEvents(contractId);

  return {
    events: response["hydra:member"],
    totalItems: response["hydra:totalItems"],
  };
};

type QueryFnType = typeof getContractEvents;

type UseContractEventsOptions = {
  contractId: string;
  config?: QueryConfig<QueryFnType>;
};

export const useContractEvents = ({
  contractId,
  config,
}: UseContractEventsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["contract-events", contractId],
    queryFn: () => getContractEvents({ contractId }),
  });
};
