import { Card } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import React from "react";
import styled from "styled-components";
import { TUpdateContractImportantNotes } from "../../api/updateContractImportantNotes";
import { TContractPartial } from "../../types/contract.types";
import ContractsFilters from "./components/ContractsFilters/ContractsFilters";
import ContractsQuickFiltersTabs from "./components/ContractsQuickFiltersTabs";
import ContractsList from "./ContractsList";
import ErrorBox from "./ErrorBox";
import { TUpdateContractPhase } from "./hooks/useContractPhaseMutation";
import { TContractsPageFilters } from "./hooks/useContractsPageURLParams";
import { TUpdateContractPartial } from "./hooks/useOffersMutation";
import NoContractsFound from "./NoContractsFound";

const MAX_PAGINABLE_ITEMS = 10_000;

const StyledPageContainer = styled.div`
  margin: 3.5rem 0 1rem;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
`;

const StyledQuickFiltersWrapper = styled(Card)`
  margin: 0 0 1rem;
  box-shadow: none;
`;

const StyledContratosWrapper = styled.div``;

const StyledPagination = styled(Pagination)`
  .MuiPagination-ul {
    justify-content: center;
  }
`;

export interface IContractQuickFilterData {
  isLoading: boolean;
  totalItems: number;
}

interface IContractsPageProps {
  variant: "recruiter" | "promoter" | "coordinator";
  filters: TContractsPageFilters;
  contracts?: TContractPartial[];
  totalItems?: number;
  isLoading: boolean;
  hasError: boolean;
  recruitContracts?: IContractQuickFilterData;
  promoteContracts?: IContractQuickFilterData;
  onMutateContract: (payload: TUpdateContractPartial) => void;
  onUpdateContractPhase: (payload: TUpdateContractPhase) => void;
  onContractImportantNotesUpdate: (
    payload: TUpdateContractImportantNotes
  ) => void;
  onSetFilters: (newFilters: TContractsPageFilters) => void;
  onPageChange: (page: number) => void;
  onSwitchToRecruitContracts: () => void;
  onSwitchToPromoteContracts: () => void;
}

const ContractsPage = (props: IContractsPageProps) => {
  const {
    variant,
    filters,
    contracts,
    totalItems,
    isLoading,
    hasError,
    recruitContracts,
    promoteContracts,
    onMutateContract,
    onUpdateContractPhase,
    onContractImportantNotesUpdate,
    onSetFilters,
    onPageChange,
    onSwitchToPromoteContracts,
    onSwitchToRecruitContracts,
  } = props;

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    onPageChange(page);
  };

  const totalPaginableItems =
    typeof totalItems !== "undefined" && Boolean(totalItems > 0)
      ? totalItems > MAX_PAGINABLE_ITEMS
        ? Math.ceil(MAX_PAGINABLE_ITEMS / 10)
        : Math.ceil(totalItems / 10)
      : 0;

  return (
    <StyledPageContainer>
      <ContractsFilters
        contractsFilters={filters}
        contractsSetFilters={onSetFilters}
      />
      {variant === "promoter" &&
        !!recruitContracts &&
        !!promoteContracts && (
          <StyledQuickFiltersWrapper>
            <ContractsQuickFiltersTabs
              tabOptions={["selection", "recruitment"]}
              value={filters.preset}
              recruitContracts={recruitContracts}
              promoteContracts={promoteContracts}
              onRecruitContractsClick={onSwitchToRecruitContracts}
              onPromoteContractsClick={onSwitchToPromoteContracts}
            />
          </StyledQuickFiltersWrapper>
        )}
      {variant === "recruiter" && !!recruitContracts && !!promoteContracts && (
        <StyledQuickFiltersWrapper>
          <ContractsQuickFiltersTabs
            tabOptions={["recruitment", "selection"]}
            value={filters.preset}
            recruitContracts={recruitContracts}
            promoteContracts={promoteContracts}
            onRecruitContractsClick={onSwitchToRecruitContracts}
            onPromoteContractsClick={onSwitchToPromoteContracts}
          />
        </StyledQuickFiltersWrapper>
      )}

      <StyledContratosWrapper>
        {!isLoading && contracts && contracts.length > 0 && (
          <ContractsList
            contracts={contracts}
            isLoading={isLoading}
            onChange={onMutateContract}
            onContractPhaseUpdate={onUpdateContractPhase}
            onContractImportantNotesUpdate={onContractImportantNotesUpdate}
          />
        )}
      </StyledContratosWrapper>
      {totalPaginableItems > 0 ? (
        <StyledPagination
          count={totalPaginableItems}
          page={filters.page}
          onChange={handlePageChange}
          color="primary"
        />
      ) : null}
      {!isLoading && totalItems === 0 && <NoContractsFound />}
      {!!hasError && <ErrorBox />}
    </StyledPageContainer>
  );
};

export default ContractsPage;
