import {
  CommunicationIntentType,
  COMMUNICATION_INTENT_STATUS_AWAITING_INVOCATION,
  COMMUNICATION_INTENT_STATUS_PENDING,
} from "../../domain/CommunicationIntent/CommunicationIntent";
import { apiEmpleo } from "../../util/ApiEmpleo";

export interface ICreateCommunicationIntentRequest {
  type: CommunicationIntentType;
  status:
    | typeof COMMUNICATION_INTENT_STATUS_AWAITING_INVOCATION
    | typeof COMMUNICATION_INTENT_STATUS_PENDING;
  employee: string;
  data?: {
    contractId?: string;
  };
}

const apiCreateCommunicationIntent = async (
  communicationIntentRequest: ICreateCommunicationIntentRequest
) => {
  if (communicationIntentRequest.employee) {
    communicationIntentRequest.employee = `/api/candidatos/${communicationIntentRequest.employee}`;
  }

  let res = await apiEmpleo.post(
    `communication_intents`,
    communicationIntentRequest,
    {
      headers: {
        "Content-Type": "application/ld+json",
      },
    }
  );

  return res;
};

export { apiCreateCommunicationIntent };
