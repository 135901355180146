import { TEmployeeResponse } from "../../../../apps/affinity/models/Employee/Employee";

export type TEmployeePublicProfile = Pick<
  TEmployeeResponse,
  | "id"
  | "Apellidos"
  | "Business_Country"
  | "Carnet_de_conducir"
  | "Ciudad"
  | "D_partement"
  | "Estado_Cuideo"
  | "Experiencia_y_referencias"
  | "Fecha_de_nacimiento"
  | "Formaciones"
  | "Nombre"
  | "No_mostrar_imagen"
  | "Lugar_de_nacimiento"
  | "Otros_Idiomas"
  | "Provincia"
  | "Qu_tareas_quieres_o_puedes_realizar"
  | "Sexo"
  | "Titulaci_n_oficial_SAD"
  | "Veh_culo_propio"
  | "W_Breve_descripci_n"
  | "avatarUrl"
>;

export const employeeIsMale = (employee: TEmployeePublicProfile) => {
  return employee.Sexo === "Hombre";
};
