import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import {
  COLOR_SUCCESS,
  COLOR_SUCCESS__HOVER,
  cuideoTheme,
} from "../../../../../containers/themes/defaultTheme";

const StyledToPromotePhaseDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 24rem;
  }
`;

const StyledContent = styled(Typography)`
  font-size: 1rem;
  text-align: center;
  color: ${cuideoTheme.palette.primary.main};
`;

const StyledNoButton = styled(Button)`
  width: 9rem;
  height: 2.5rem;
`;

const StyledYesButton = styled(Button)`
  width: 9rem;
  background-color: ${COLOR_SUCCESS};
  height: 2.5rem;

  &:hover {
    background-color: ${COLOR_SUCCESS__HOVER};
  }

  .MuiCircularProgress-root {
    color: #999;
  }
`;

interface IToPromotePhaseDialogProps {
  open: boolean;
  isUpdating: boolean;
  contractCrmIdName: string;
  onAccept: () => void;
  onCancel: (e: React.MouseEvent) => void;
}

const ToPromotePhaseDialog: React.FC<IToPromotePhaseDialogProps> = (props) => {
  const { open, isUpdating, contractCrmIdName, onAccept, onCancel } = props;

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onCancel(e);
  };

  const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onAccept();
  };

  return (
    <StyledToPromotePhaseDialog open={open}>
      <DialogContent>
        <StyledContent>
          <FormattedMessage
            id="ToPromotePhaseDialog.¿Quieres que el {contractCrmIdName} sea visible para la gestora?"
            defaultMessage="¿Quieres que el {contractCrmIdName} sea visible para la gestora?"
            values={{
              contractCrmIdName: <b>{contractCrmIdName}</b>,
            }}
          />
        </StyledContent>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid item>
            <StyledNoButton
              color="primary"
              variant="contained"
              onClick={handleCancel}
              disabled={isUpdating}
            >
              <FormattedMessage
                id="ToPromotePhaseDialog.Todavía no"
                defaultMessage="Todavía no"
              />
            </StyledNoButton>
          </Grid>
          <Grid item>
            <StyledYesButton
              color="primary"
              variant="contained"
              onClick={handleConfirm}
              disabled={isUpdating}
            >
              {isUpdating ? (
                <CircularProgress size={20} thickness={5} disableShrink />
              ) : (
                <FormattedMessage
                  id="ToPromotePhaseDialog.Si"
                  defaultMessage="Si"
                />
              )}
            </StyledYesButton>
          </Grid>
        </Grid>
      </DialogActions>
    </StyledToPromotePhaseDialog>
  );
};

export default ToPromotePhaseDialog;
