import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import PhotoIcon from "@material-ui/icons/Photo";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import React, { useEffect, useRef, useState } from "react";
import {
  default as AvatarEditor,
  default as ReactAvatarEditor,
  Position,
} from "react-avatar-editor";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { apiDeleteEmployeeAvatar } from "../../../../api/employee/apiDeleteEmployeeAvatar";
import { apiUpdateEmployeeAvatar } from "../../../../api/employee/apiUpdateEmployeeAvatar";
import CuideoUserAvatar from "../../../../components/CuideoUserAvatar";
import { avatarImgBaseUrl } from "../../../../config/assetLocations";
import { COLOR_RED_NOTIFICATION } from "../../../../containers/themes/defaultTheme";
import { dataUriToFile } from "../../../../util/dataUriToFile";
import {
  TEditableAvatarOnChangedHandler,
  TEditableAvatarState,
} from "./types/EditableAvatar.type";
import cuideoTheme from "../../../../containers/themes/defaultTheme";
import { Alert } from "@material-ui/lab";

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledFullEditButton = styled(Button)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  opacity: 0;
  line-height: 1.25;
  text-transform: none;
  display: block;
  font-size: 0.75rem;
  font-weight: 600;

  .MuiButton-label {
    display: block;
  }
  .MuiSvgIcon-root {
    display: block;
    margin: 0 auto;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1;
  }
`;

const StyledDialog = styled(Dialog)``;

const StyledImageEditWrapper = styled.div`
  width: 35.125rem;
  height: 35.125rem;

  margin: 0.5rem auto;
  background-color: #ccc;
`;

const StyledUploadButton = styled(Button)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);

  .MuiSvgIcon-root {
    margin-right: 0.25rem;
  }

  .MuiCircularProgress-root {
    color: #999;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.45);
  }
`;

const StyledWarning = styled(Alert)`
  font-size: 0.875rem;
  max-width: 35.25rem;
`;

const StyledAcceptButton = styled(Button)`
  .MuiCircularProgress-root {
    color: #999;
  }
`;

const StyledViewableAvatar = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`;

const StyledDeleteButton = styled(Button)`
  background-color: ${COLOR_RED_NOTIFICATION};
  color: #fff;

  .MuiCircularProgress-root {
    color: #999;
  }

  &:hover {
    background-color: #ac0409;
  }

  &.Mui-disabled {
    background-color: #ddd;
    color: #999;
  }
`;

const StyledConfirmDialog = styled(Dialog)`
  .MuiDialogContent-root {
    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
    font-size: 1rem;
    line-height: 1.35;
    color: ${cuideoTheme.palette.primary.main};
  }
`;

interface IEditableAvatarProps {
  employeeId: string;
  fileUrl: string | null;
  canEdit?: boolean;
  onAvatarChanged?: TEditableAvatarOnChangedHandler;
  isFelizVita?: boolean;
}

const EditableAvatar: React.FC<IEditableAvatarProps> = ({
  employeeId,
  fileUrl,
  canEdit,
  onAvatarChanged,
  isFelizVita,
}) => {
  const ref = useRef<AvatarEditor>();
  const [state, setState] = useState<TEditableAvatarState>({
    viewMode: "view_upload",
    image: null,
    isHovering: false,
    isDialogOpen: false,
    isDeleteConfirmDialogOpen: false,
    position: { x: 0.5, y: 0.5 },
    scale: 1,
    opStatus: null,
    canRemove: !!fileUrl && !!canEdit,
  });

  useEffect(() => {
    if (!state.isDialogOpen) {
      return;
    }

    setState((prevState) => {
      if (fileUrl) {
        return {
          ...prevState,
          image: avatarImgBaseUrl + fileUrl,
          viewMode: "view_existing",
          canRemove: !!fileUrl && !!canEdit,
        };
      }

      return {
        ...prevState,
      };
    });
  }, [state.isDialogOpen, fileUrl, canEdit]);

  const handleMouseEnter = () => {
    setState((prevState) => ({
      ...prevState,
      isHovering: true,
    }));
  };

  const handleMouseLeave = () => {
    setState((prevState) => ({
      ...prevState,
      isHovering: false,
    }));
  };

  const handleEditButtonClick = () => {
    setState((prevState) => ({
      ...prevState,
      isDialogOpen: true,
    }));
  };

  const handleDialogClose = () => {
    setState((prevState) => ({
      ...prevState,
      isDialogOpen: false,
    }));
  };

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => {
      if (e.target.files) {
        return {
          ...prevState,
          image: e.target.files[0],
          viewMode: "customize",
        };
      }

      return { ...prevState };
    });
  };

  const handlePositionChange = (position: Position) => {
    setState((prevState) => ({
      ...prevState,
      position,
    }));
  };

  const handleWheel = (e: React.WheelEvent) => {
    const scale = state.scale + e.deltaY * -0.01;

    setState((prevState) => ({
      ...prevState,
      scale: scale < 1 ? 1 : scale,
    }));
  };

  const handleAccept = async () => {
    if (!ref.current) {
      return;
    }

    const uriData = ref.current
      .getImageScaledToCanvas()
      .toDataURL("image/jpeg", 0.9);

    setState((prevState) => ({
      ...prevState,
      opStatus: "uploading",
    }));

    const res = await apiUpdateEmployeeAvatar(
      employeeId,
      dataUriToFile(uriData)
    );

    setState((prevState) => ({
      ...prevState,
      opStatus: null,
      isDialogOpen: false,
    }));

    onAvatarChanged?.(res.data["@id"], res.data.url);
  };

  const handleDeleteAvatar = () => {
    setState((prevState) => ({
      ...prevState,
      isDeleteConfirmDialogOpen: true,
    }));
  };

  const handleDeleteConfirmCancel = () => {
    setState((prevState) => ({
      ...prevState,
      isDeleteConfirmDialogOpen: false,
    }));
  };

  const handleDeleteConfirmConfirm = async () => {
    setState((prevState) => ({
      ...prevState,
      isDeleteConfirmDialogOpen: false,
      opStatus: "deleting",
    }));

    await apiDeleteEmployeeAvatar(employeeId);

    setState((prevState) => ({
      ...prevState,
      image: null,
      viewMode: "view_upload",
      opStatus: null,
    }));

    onAvatarChanged?.(null, null);
  };

  return (
    <>
      <StyledWrapper
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <CuideoUserAvatar
          user={{
            userFullName: "",
            userAvatar: fileUrl,
          }}
          isFelizVita={isFelizVita}
        />
        <StyledFullEditButton onClick={handleEditButtonClick}>
          {canEdit ? (
            <>
              {!!fileUrl ? (
                <>
                  <PhotoIcon />
                  <FormattedMessage
                    id="EditableAvatar.Modificar foto de perfil"
                    defaultMessage="Modificar foto de perfil"
                  />
                </>
              ) : (
                <>
                  <AddCircleOutlineOutlinedIcon />
                  <FormattedMessage
                    id="EditableAvatar.Añadir foto de perfil"
                    defaultMessage="Añadir foto de perfil"
                  />
                </>
              )}
            </>
          ) : (
            <>
              {!!fileUrl ? (
                <>
                  <VisibilityOutlinedIcon />
                  <FormattedMessage
                    id="EditableAvatar.Ver foto de perfil"
                    defaultMessage="Ver foto de perfil"
                  />
                </>
              ) : (
                <>
                  <AddCircleOutlineOutlinedIcon />
                  <FormattedMessage
                    id="EditableAvatar.Añadir foto de perfil"
                    defaultMessage="Añadir foto de perfil"
                  />
                </>
              )}
            </>
          )}
        </StyledFullEditButton>
      </StyledWrapper>
      <StyledDialog
        open={state.isDialogOpen}
        onClose={handleDialogClose}
        maxWidth="md"
      >
        <DialogTitle>
          <FormattedMessage
            id="EditableAvatar.Foto de Perfil"
            defaultMessage="Foto de Perfil"
          />
        </DialogTitle>
        <DialogContent>
          {!canEdit && (
            <StyledWarning severity="warning">
              <FormattedMessage
                id="EditableAvatar.No puedes cambiar este avatar porque ha sido configurado directamente por el usuario"
                defaultMessage="No puedes cambiar este avatar porque ha sido configurado directamente por el usuario"
              />
            </StyledWarning>
          )}
          <StyledImageEditWrapper onWheel={handleWheel}>
            {state.viewMode === "customize" ? (
              <ReactAvatarEditor
                ref={ref as any}
                image={state.image}
                width={512}
                height={512}
                borderRadius={256}
                scale={state.scale}
                position={state.position}
                onPositionChange={handlePositionChange}
              />
            ) : state.viewMode === "view_upload" ? (
              // @ts-ignore
              <StyledUploadButton variant="contained" component="label">
                <CloudUploadIcon />
                <FormattedMessage
                  id="EditableAvatar.Subir imagen"
                  defaultMessage="Subir imagen"
                />
                <input
                  type="file"
                  hidden
                  onChange={handleSelectFile}
                  accept="image/*"
                />
              </StyledUploadButton>
            ) : (
              <StyledViewableAvatar src={state.image} />
            )}
          </StyledImageEditWrapper>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            spacing={4}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={6}>
              {state.canRemove && state.viewMode === "view_existing" && (
                <StyledDeleteButton
                  onClick={handleDeleteAvatar}
                  disabled={!!state.opStatus}
                  startIcon={
                    state.opStatus === "deleting" ? (
                      <CircularProgress size={18} thickness={5} disableShrink />
                    ) : undefined
                  }
                >
                  <FormattedMessage
                    id="EditableAvatar.Eliminar"
                    defaultMessage="Eliminar"
                  />
                </StyledDeleteButton>
              )}
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="center"
                spacing={4}
              >
                {state.viewMode === "customize" ? (
                  <>
                    <Grid item>
                      <Button
                        color="primary"
                        onClick={handleDialogClose}
                        disabled={!!state.opStatus}
                      >
                        <FormattedMessage
                          id="EditableAvatar.Cancelar"
                          defaultMessage="Cancelar"
                        />
                      </Button>
                    </Grid>
                    <Grid item>
                      <StyledAcceptButton
                        variant="contained"
                        color="primary"
                        onClick={handleAccept}
                        disabled={!!state.opStatus}
                        startIcon={
                          state.opStatus === "uploading" ? (
                            <CircularProgress
                              size={18}
                              thickness={5}
                              disableShrink
                            />
                          ) : undefined
                        }
                      >
                        <FormattedMessage
                          id="EditableAvatar.Aceptar"
                          defaultMessage="Aceptar"
                        />
                      </StyledAcceptButton>
                    </Grid>
                  </>
                ) : (
                  <Button
                    color="primary"
                    onClick={handleDialogClose}
                    disabled={!!state.opStatus}
                  >
                    <FormattedMessage
                      id="EditableAvatar.Cerrar"
                      defaultMessage="Cerrar"
                    />
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </StyledDialog>
      <StyledConfirmDialog open={state.isDeleteConfirmDialogOpen}>
        <DialogTitle>
          <FormattedMessage
            id="EditableAvatar.Eliminar foto de perfil"
            defaultMessage="Eliminar foto de perfil"
          />
        </DialogTitle>
        <DialogContent>
          <FormattedMessage
            id="EditableAvatar.Vas a eliminar la foto de perfil. Esta acción no se puede dehacer. ¿Estás seguro?"
            defaultMessage="Vas a eliminar la foto de perfil. Esta acción no se puede dehacer. ¿Estás seguro?"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteConfirmCancel} color="primary">
            <FormattedMessage id="EditableAvatar.No" defaultMessage="No" />
          </Button>
          <Button
            onClick={handleDeleteConfirmConfirm}
            color="primary"
            variant="contained"
          >
            <FormattedMessage id="EditableAvatar.Si" defaultMessage="Si" />
          </Button>
        </DialogActions>
      </StyledConfirmDialog>
    </>
  );
};

export default EditableAvatar;
