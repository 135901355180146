import moment from "moment";
import {
  EMPLOYEE_FELIZ_VITA_STATUS,
  EMPLOYEE_STATUS,
} from "../../../../api/employee/employee.types";

export type TEmployeeStatus =
  | typeof EMPLOYEE_STATUS.NOT_VALIDATED
  | typeof EMPLOYEE_STATUS.VALIDATED
  | typeof EMPLOYEE_STATUS.ACTIVE
  | typeof EMPLOYEE_STATUS.VALIDATED_PLUS
  | typeof EMPLOYEE_STATUS.DISCARDED;

export type TEmployeeFelizVitaStatus =
  | typeof EMPLOYEE_FELIZ_VITA_STATUS.NOT_VALIDATED
  | typeof EMPLOYEE_FELIZ_VITA_STATUS.VALIDATED
  | typeof EMPLOYEE_FELIZ_VITA_STATUS.ACTIVE
  | typeof EMPLOYEE_FELIZ_VITA_STATUS.VALIDATED_PLUS
  | typeof EMPLOYEE_FELIZ_VITA_STATUS.IN_PROCESS
  | typeof EMPLOYEE_FELIZ_VITA_STATUS.DISCARDED;

export const EMPLOYEE_GENDER = {
  MAN: "Hombre",
  WOMAN: "Mujer",
};

export type TEmployeeGender =
  | typeof EMPLOYEE_GENDER.MAN
  | typeof EMPLOYEE_GENDER.WOMAN;

export type TEmployeeFormation = {
  Nombre_escuela_centro?: string;
  Nombre_del_curso?: string;
  Titulo_valido_en_Espa_a?: string;
};

export type TEmployeeExperience = {
  Empresa_o_famila?: string;
  Funciones_y_tareas_realizadas?: string;
  Patolog_as_tratadas?: string[];
};

export type TEmployeeBusinessCountry = "es" | "fr";

export const EMPLOYEE_DRIVING_LICENSE = {
  YES: "SI",
  NO: "NO",
};

export type TEmployeeDrivingLicense =
  | typeof EMPLOYEE_DRIVING_LICENSE.YES
  | typeof EMPLOYEE_DRIVING_LICENSE.NO;

export const EMPLOYEE_OWNS_VEHICLE = {
  YES: "Si",
  NO: "No",
};

export type TEmployeeOwnsVehicle =
  | typeof EMPLOYEE_OWNS_VEHICLE.YES
  | typeof EMPLOYEE_OWNS_VEHICLE.NO;

export type TEmployeeResponse = {
  id: string;
  Apellidos?: string;
  Business_Country?: TEmployeeBusinessCountry;
  Carnet_de_conducir?: TEmployeeDrivingLicense;
  Ciudad?: string;
  D_partement?: string;
  Estado_Cuideo?: TEmployeeStatus;
  Experiencia_y_referencias?: TEmployeeExperience[];
  Fecha_de_nacimiento?: string;
  Formaciones?: TEmployeeFormation[];
  Nombre?: string;
  No_mostrar_imagen?: boolean;
  Lugar_de_nacimiento?: string;
  Otros_Idiomas?: string[];
  Provincia?: string;
  Qu_tareas_quieres_o_puedes_realizar?: string[];
  Sexo?: TEmployeeGender;
  Titulaci_n_oficial_SAD?: string[];
  Veh_culo_propio?: TEmployeeOwnsVehicle;
  Vencimiento_NIE?: string | null;
  W_Breve_descripci_n?: string;
  avatarUrl?: string;
};

export const employeeHasSpanishBusinessCountry = (
  employee: TEmployeeResponse
): boolean => {
  if (employee.Business_Country === "es") {
    return true;
  }
  return false;
};

export const employeeIsValidatedOrBetter = (employee: TEmployeeResponse) => {
  if (
    employee.Estado_Cuideo === EMPLOYEE_STATUS.VALIDATED ||
    employee.Estado_Cuideo === EMPLOYEE_STATUS.VALIDATED_PLUS ||
    employee.Estado_Cuideo === EMPLOYEE_STATUS.ACTIVE
  ) {
    return true;
  }

  return false;
};

export const employeeIdExpiryIsValid = (employee: TEmployeeResponse) => {
  if (null === employee.Vencimiento_NIE) {
    return false;
  }

  const expiryMom = moment(employee.Vencimiento_NIE);
  const now = moment();
  if (expiryMom.isAfter(now)) {
    return true;
  }
  return false;
};

export const employeeShouldShowInvalidIdExpiryDateWarning = (
  employee: TEmployeeResponse
): boolean => {
  if (
    employeeIsValidatedOrBetter(employee) &&
    employeeHasSpanishBusinessCountry(employee) &&
    !employeeIdExpiryIsValid(employee)
  ) {
    return true;
  }
  return false;
};
