import { CONTRACT_CANDIDATE_SORT_ORDER, TContractCandidateSortOrder } from "../api/contract/contract.types";
import {
  EMPLOYEE_AVAILABILITY,
  EMPLOYEE_STATUS,
} from "../api/employee/employee.types";
import {
  FILTERS_RESET_CONTRATO,
  FILTERS_RESET_CONTRATO_SUGGESTIONS,
  FILTERS_SET_CONTRATO,
  FILTERS_SET_FAVORITE_CANDIDATOS,
  FILTERS_SET_TRANSFER,
  GET_STAFF_SUCCESS,
} from "../constants/ActionTypes";
import {
  CONTRATO_ESTADO_ALTA_PENDIENTE,
  CONTRATO_ESTADO_CV_CLIENTE,
  CONTRATO_ESTADO_PRESENCIAL_CLIENTE,
} from "../util/defaults/contrato";

const initialFilters = {
  loggedUserId: null as string | null,
  loggedUserOwnedContracts: null as string | null,
  transferFilters: {
    search: "",
    owner: "owner",
    publicar: "",
    estado: [
      CONTRATO_ESTADO_ALTA_PENDIENTE,
      CONTRATO_ESTADO_CV_CLIENTE,
      CONTRATO_ESTADO_PRESENCIAL_CLIENTE,
    ],
    tipoDeTrato: [] as string[],
    provinciaOferta: "",
    orden: "recientes",
  },
  contratoFilters: {
    search: "",
    titulacion: "",
    sexo: "",
    estado: [
      EMPLOYEE_STATUS.NOT_VALIDATED,
      EMPLOYEE_STATUS.ACTIVE,
      EMPLOYEE_STATUS.VALIDATED,
      EMPLOYEE_STATUS.VALIDATED_PLUS,
    ],
    ciudad: "",
    origen: "" as "" | "web" | "cuideo",
    provincia: "",
    lugarDeNacimiento: "",
    carnet: "",
    vehiculo: "",
    edad: [] as string[],
    experience: "",
    tasks: [] as string[],
    pathologies: [] as string[],
    tipo: "",
    orden: CONTRACT_CANDIDATE_SORT_ORDER.APPROPIATED as TContractCandidateSortOrder,
    vista: "lista",
    availability: [
      EMPLOYEE_AVAILABILITY.AVAILABLE,
    ] as typeof EMPLOYEE_AVAILABILITY[keyof typeof EMPLOYEE_AVAILABILITY][],
    page: 1,
  },
  favoriteCandidatosFilters: {
    search: "",
    titulacion: "",
    sexo: "",
    estadoCuideo: [
      EMPLOYEE_STATUS.NOT_VALIDATED,
      EMPLOYEE_STATUS.ACTIVE,
      EMPLOYEE_STATUS.VALIDATED,
      EMPLOYEE_STATUS.VALIDATED_PLUS,
    ],
    ciudad: "",
    provincia: "",
    tipo: [] as string[],
    orden: "recientes" as "recientes" | "antiguos" | "activos",
    vista: "lista",
    page: 1,
  },
};

const filters = (
  state = initialFilters,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case FILTERS_SET_TRANSFER:
      return {
        ...state,
        transferFilters: action.payload,
      };
    case FILTERS_SET_CONTRATO:
      return {
        ...state,
        contratoFilters: action.payload,
      };
    case FILTERS_RESET_CONTRATO:
      return {
        ...state,
        contratoFilters: initialFilters.contratoFilters,
      };
    case FILTERS_RESET_CONTRATO_SUGGESTIONS:
      return {
        ...state,
        contratoFilters: {
          ...initialFilters.contratoFilters,
          estado: [
            EMPLOYEE_STATUS.ACTIVE,
            EMPLOYEE_STATUS.VALIDATED,
            EMPLOYEE_STATUS.VALIDATED_PLUS,
          ],
        },
      };
    case FILTERS_SET_FAVORITE_CANDIDATOS:
      return {
        ...state,
        favoriteCandidatosFilters: action.payload,
      };
    case GET_STAFF_SUCCESS:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default filters;
