import { CircularProgress, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { COLOR_INFO_ALERT_BG } from "../../../../../../containers/themes/defaultTheme";
import { THsm } from "../../types/templateAction.types";
import {
  StyledConversationContainer,
  StyledConversationContainerWrapper,
  StyledEventAlert,
  StyledFloatingActions,
  StyledWrapper,
} from "./Conversation";
import TemplateActionButtons from "./TemplateActionButtons";

const StyledAwaitingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background-color: ${COLOR_INFO_ALERT_BG};
  max-width: 65%;
  margin: 0.5rem auto;

  .MuiCircularProgress-root {
    margin-right: 0.5rem;
  }
`;

const StyledAwaitingText = styled(Typography)`
  font-size: 0.875rem;
  line-height: 1.25;
`;

interface IDummyConversationProps {
  onSendHsm: (hsm: THsm) => void;
}

const DummyConversation: React.FC<IDummyConversationProps> = (props) => {
  const { onSendHsm } = props;
  const [state, setState] = useState<"idle" | "awaiting">("idle");

  const handleSendHsm = (hsm: THsm) => {
    setState("awaiting");
    onSendHsm(hsm);
  };

  return (
    <StyledWrapper>
      <StyledConversationContainerWrapper>
        <StyledConversationContainer>
          <StyledEventAlert severity="warning">
            <FormattedMessage
              id="Conversation.Todavía no tenemos registrado ningún mensaje con este Candidato/a. Podrás hablar con el/ella una vez responda a uno de los intentos de comunicación del Bot o bien puedes enviar una plantilla para intentar que responda."
              defaultMessage="Todavía no tenemos registrado ningún mensaje con este Candidato/a. Podrás hablar con el/ella una vez responda a uno de los intentos de comunicación del Bot o bien puedes enviar una plantilla para intentar que responda."
            />
          </StyledEventAlert>

          {state === "awaiting" && (
            <StyledAwaitingWrapper>
              <CircularProgress size={20} thickness={5} />
              <StyledAwaitingText>
                <FormattedMessage
                  id="Conversation.Esperando a recibir información de la plataforma. Esto puede llevar un tiempo..."
                  defaultMessage="Esperando a recibir información de la plataforma. Esto puede llevar un tiempo..."
                />
              </StyledAwaitingText>
            </StyledAwaitingWrapper>
          )}

          {state === "idle" && (
            <StyledFloatingActions key={`conversation_fabs_no_conv`}>
              <TemplateActionButtons onSendHsm={handleSendHsm} />
            </StyledFloatingActions>
          )}
        </StyledConversationContainer>
      </StyledConversationContainerWrapper>
    </StyledWrapper>
  );
};

export default DummyConversation;
