import { apiEmpleo } from "../../util/ApiEmpleo";
import { TGetContractsItem } from "./contract.types";

const apiGetContract = async (contractId: string) => {
  const res: TGetContractsItem = await apiEmpleo.get(`contratos/${contractId}`);

  return res;
};

export { apiGetContract };
