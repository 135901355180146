import _ from "lodash";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import {
  EMPLOYEE_AVAILABILITY,
  EMPLOYEE_FELIZ_VITA_STATUS,
  EMPLOYEE_STATUS,
  EMPLOYEE_WORK_REGIME,
} from "../../../../api/employee/employee.types";
import CuideoSearchField from "../../../../components/CuideoFields/CuideoSearchField";
import useFormatters from "../../../../components/FormattedFields/useFormatters";
import cuideoTheme from "../../../../containers/themes/defaultTheme";
import {
  EMPLOYEE_VIDEOINTERVIEW_STATUS_COMPLETED,
  EMPLOYEE_VIDEOINTERVIEW_STATUS_FINALIST,
  EMPLOYEE_VIDEOINTERVIEW_STATUS_PENDING,
  EMPLOYEE_YEARS_OF_EXPERIENCE_LESS_1_YEAR,
  EMPLOYEE_YEARS_OF_EXPERIENCE_LESS_2_YEARS,
  EMPLOYEE_YEARS_OF_EXPERIENCE_LESS_3_YEARS,
  EMPLOYEE_YEARS_OF_EXPERIENCE_MORE_10_YEARS,
  EMPLOYEE_YEARS_OF_EXPERIENCE_MORE_3_YEARS,
  EMPLOYEE_YEARS_OF_EXPERIENCE_MORE_5_YEARS,
  EMPLOYEE_YEARS_OF_EXPERIENCE_NO_EXPERIENCE,
} from "../../../../util/defaults/candidato";
import {
  TIPO_EXTERNA_ENTRE_SEMANA,
  TIPO_EXTERNA_FIN_DE_SEMANA,
  TIPO_INTERNA_ENTRE_SEMANA,
  TIPO_INTERNA_FIN_DE_SEMANA,
} from "../../../../util/defaults/contrato";
import { languagesList } from "../../../../util/defaults/idiomas";
import { mostUsedCountriesOptionsEs } from "../../../../util/defaults/paises";
import { pathologiesList } from "../../../../util/defaults/patologias";
import { tasksList } from "../../../../util/defaults/tareas";
import useDebounce from "../../../../util/useDebounce";
import { resetState } from "../../features/candidate/routes/CandidatesRoute/useEmployeesPageURLParams";
import FilterButton from "../FilterButton";
import FiltersButton, { Filter } from "../FiltersButton/FiltersButton";

const StyledWrapper = styled.div`
  background-color: ${cuideoTheme.palette.common.white};
  padding: 0.5rem 0 0.5rem 0;
  box-shadow: 0 2px 4px 0 rgba(0, 72, 120, 0.25);
  position: relative;
  overflow: visible;
  z-index: 10;
`;

const StyledContainer = styled.div`
  margin: 0 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledButtonWrapper = styled.div`
  margin: 0.125rem 0 0.125rem 0.25rem;
`;

const StyledLeft = styled.div``;

const StyledRight = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const CandidatosFilters = (props: any) => {
  const { employeeFilters, employeeSetFilters, provinciasOptions } = props;
  const [searchTerm, setSearchTerm] = useState(employeeFilters.search);
  const { formatMessage } = useIntl();
  const { formatLanguage } = useFormatters();

  const handleApply = (filterName: string, value: any) => {
    const newFilters = {
      ...employeeFilters,
      [filterName]: value,
      page: 1,
      "latitud[gt]":
        filterName === "provincia" ? undefined : employeeFilters["latitud[gt]"],
      "latitud[lt]":
        filterName === "provincia" ? undefined : employeeFilters["latitud[lt]"],
      "longitud[gt]":
        filterName === "provincia"
          ? undefined
          : employeeFilters["longitud[gt]"],
      "longitud[lt]":
        filterName === "provincia"
          ? undefined
          : employeeFilters["longitud[lt]"],
    };
    if (!_.isEqual(employeeFilters, newFilters)) {
      employeeSetFilters(newFilters);
    }
  };

  const handleReset = (filterName: string) => {
    const newFilters = { ...employeeFilters, [filterName]: "", page: 1 };
    if (!_.isEqual(employeeFilters, newFilters)) {
      employeeSetFilters(newFilters);
    }
  };

  const handleSearchChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    const newFilters = { ...employeeFilters, search: debouncedSearchTerm };
    if (!_.isEqual(employeeFilters, newFilters)) {
      employeeSetFilters({ ...newFilters, page: 1 });
    }
  }, [debouncedSearchTerm, employeeFilters, employeeSetFilters]);

  const handleFiltersButtonChange = (filtersValues: any) => {
    const newFilters = {
      ...employeeFilters,
      titulacion: filtersValues.titulacion.isActive
        ? filtersValues.titulacion.value
        : "",
      sexo: filtersValues.sexo.isActive ? filtersValues.sexo.value : "",
      carnet: filtersValues.carnet.isActive ? filtersValues.carnet.value : "",
      vehiculo: filtersValues.vehiculo.isActive
        ? filtersValues.vehiculo.value
        : "",
      inOpenProcess: filtersValues.inOpenProcess.isActive
        ? filtersValues.inOpenProcess.value
        : "",
      inRecentProcesses: filtersValues.inRecentProcesses.isActive
        ? filtersValues.inRecentProcesses.value
        : "",
      edad: filtersValues.edad.isActive ? filtersValues.edad.value : [],
      tipo: filtersValues.tipo.isActive ? filtersValues.tipo.value : [],
      estadoCuideo: filtersValues.estadoCuideo.isActive
        ? filtersValues.estadoCuideo.value
        : [],
      felizVitaStatus: filtersValues.felizVitaStatus.isActive
        ? filtersValues.felizVitaStatus.value
        : [],
      experience: filtersValues.experience.isActive
        ? filtersValues.experience.value
        : [],
      provincia:
        employeeFilters.businessCountry === "es" &&
        filtersValues.provincia.value
          ? filtersValues.provincia.value
          : "",
      department:
        employeeFilters.businessCountry === "fr" &&
        filtersValues.department.isActive
          ? filtersValues.department.value
          : "",
      tasks: filtersValues.tasks.isActive ? filtersValues.tasks.value : [],
      pathologies: filtersValues.pathologies.isActive
        ? filtersValues.pathologies.value
        : [],
      languages: filtersValues.languages.isActive
        ? filtersValues.languages.value
        : [],
      lugarDeNacimiento: filtersValues.lugarDeNacimiento.value
        ? filtersValues.lugarDeNacimiento.value
        : "",
      interviewStatus: filtersValues.interviewStatus.isActive
        ? filtersValues.interviewStatus.value
        : [],
      workRegime: filtersValues.workRegime.isActive
        ? filtersValues.workRegime.value
        : [],
      company: filtersValues.company.isActive
        ? filtersValues.company.value
        : "",
      hasWhatsapp: filtersValues.hasWhatsapp.isActive
        ? filtersValues.hasWhatsapp.value
        : "",
      botAvailable: filtersValues.botAvailable.isActive
        ? filtersValues.botAvailable.value
        : "",
      availability: filtersValues.availability.value ?? [],
      page: 1,
    };
    if (!_.isEqual(employeeFilters, newFilters)) {
      employeeSetFilters(newFilters);
    }
  };

  const filters = [] as Filter[];
  filters.push({
    name: "carnet",
    filterLabel: formatMessage({
      id: "EmployeesFilters.DrivingLicense",
      defaultMessage: "Carnet de conducir",
    }),
    type: "select",
    options: [
      {
        value: "",
        label: formatMessage({
          id: "EmployeesFilters.ChooseAnOption",
          defaultMessage: "Selecciona una opción",
        }),
      },
      {
        value: "SI",
        label: formatMessage({
          id: "EmployeesFilters.DrivingLicense.SI",
          defaultMessage: "Tiene carnet de conducir",
        }),
      },
      {
        value: "NO",
        label: formatMessage({
          id: "EmployeesFilters.DrivingLicense.NO",
          defaultMessage: "No tiene carnet de conducir",
        }),
      },
    ],
  });
  filters.push({
    name: "vehiculo",
    filterLabel: formatMessage({
      id: "EmployeesFilters.OwnsVehicle",
      defaultMessage: "Vehículo propio",
    }),
    type: "select",
    options: [
      {
        value: "",
        label: formatMessage({
          id: "EmployeesFilters.ChooseAnOption",
          defaultMessage: "Selecciona una opción",
        }),
      },
      {
        value: "Si",
        label: formatMessage({
          id: "EmployeesFilters.OwnsVehicle.Si",
          defaultMessage: "Tiene vehículo propio",
        }),
      },
      {
        value: "No",
        label: formatMessage({
          id: "EmployeesFilters.OwnsVehicle.No",
          defaultMessage: "No tiene vehículo propio",
        }),
      },
    ],
  });
  filters.push({
    name: "titulacion",
    filterLabel: formatMessage({
      id: "EmployeesFilters.OfficialDegree",
      defaultMessage: "Titulación oficial",
    }),
    type: "select",
    options: [
      {
        value: "",
        label: formatMessage({
          id: "EmployeesFilters.ChooseAnOption",
          defaultMessage: "Selecciona una opción",
        }),
      },
      {
        value: "si",
        label: formatMessage({
          id: "EmployeesFilters.OfficialDegree.si",
          defaultMessage: "Con titulación oficial",
        }),
      },
      {
        value: "no",
        label: formatMessage({
          id: "EmployeesFilters.OfficialDegree.no",
          defaultMessage: "Sin titulación oficial",
        }),
      },
    ],
  });
  filters.push({
    name: "inOpenProcess",
    filterLabel: formatMessage({
      id: "EmployeesFilters.InOpenProcess",
      defaultMessage: "En procesos abiertos",
    }),
    type: "select",
    options: [
      {
        value: "",
        label: formatMessage({
          id: "EmployeesFilters.ChooseAnOption",
          defaultMessage: "Selecciona una opción",
        }),
      },
      {
        value: "yes",
        label: formatMessage({
          id: "EmployeesFilters.InOpenProcess.yes",
          defaultMessage: "En procesos abiertos",
        }),
      },
      {
        value: "no",
        label: formatMessage({
          id: "EmployeesFilters.InOpenProcess.no",
          defaultMessage: "Sin procesos abiertos",
        }),
      },
    ],
  });
  filters.push({
    name: "edad",
    filterLabel: formatMessage({
      id: "EmployeesFilters.Age",
      defaultMessage: "Edad",
    }),
    type: "multi",
    options: [
      {
        value: "18-25",
        label: formatMessage({
          id: "EmployeesFilters.Age.18-25",
          defaultMessage: "18-25 años",
        }),
      },
      {
        value: "26-35",
        label: formatMessage({
          id: "EmployeesFilters.Age.26-35",
          defaultMessage: "26-35 años",
        }),
      },
      {
        value: "36-45",
        label: formatMessage({
          id: "EmployeesFilters.Age.36-45",
          defaultMessage: "36-45 años",
        }),
      },
      {
        value: "46-55",
        label: formatMessage({
          id: "EmployeesFilters.Age.46-55",
          defaultMessage: "46-55 años",
        }),
      },
      {
        value: "56-65",
        label: formatMessage({
          id: "EmployeesFilters.Age.56-65",
          defaultMessage: "56-65 años",
        }),
      },
      {
        value: "+65",
        label: formatMessage({
          id: "EmployeesFilters.Age.+65",
          defaultMessage: "+65 años",
        }),
      },
    ],
  });
  filters.push({
    name: "sexo",
    filterLabel: formatMessage({
      id: "EmployeesFilters.Sex",
      defaultMessage: "Sexo del Candidato",
    }),
    type: "select",
    options: [
      {
        value: "",
        label: formatMessage({
          id: "EmployeesFilters.ChooseAnOption",
          defaultMessage: "Selecciona una opción",
        }),
      },
      {
        value: "Mujer",
        label: formatMessage({
          id: "EmployeesFilters.Sex.Mujer",
          defaultMessage: "Mujer",
        }),
      },
      {
        value: "Hombre",
        label: formatMessage({
          id: "EmployeesFilters.Sex.Hombre",
          defaultMessage: "Hombre",
        }),
      },
    ],
  });
  filters.push({
    name: "provincia",
    filterLabel: formatMessage({
      id: "EmployeesFilters.Province",
      defaultMessage: "Provincia",
    }),
    type: "select",
    options: [
      {
        value: "",
        label: formatMessage({
          id: "EmployeesFilters.ChooseProvince",
          defaultMessage: "Selecciona provincia",
        }),
      },
      ...provinciasOptions,
    ],
  });
  filters.push({
    name: "estadoCuideo",
    filterLabel: formatMessage({
      id: "EmployeesFilters.EmployeeStatus",
      defaultMessage: "Estado del Candidato",
    }),
    type: "multi",
    options: [
      {
        value: EMPLOYEE_STATUS.NOT_VALIDATED,
        label: formatMessage({
          id: "EmployeesFilters.WithoutValidation",
          defaultMessage: "Sin Validar",
        }),
      },
      {
        value: EMPLOYEE_STATUS.VALIDATED,
        label: formatMessage({
          id: "EmployeesFilters.Validated",
          defaultMessage: "Validado",
        }),
      },
      {
        value: EMPLOYEE_STATUS.ACTIVE,
        label: formatMessage({
          id: "EmployeesFilters.Active",
          defaultMessage: "Activo",
        }),
      },
      {
        value: EMPLOYEE_STATUS.VALIDATED_PLUS,
        label: formatMessage({
          id: "EmployeesFilters.ValidatedPlus",
          defaultMessage: "Validado Plus",
        }),
      },
      {
        value: EMPLOYEE_STATUS.DISCARDED,
        label: formatMessage({
          id: "EmployeesFilters.Discarded",
          defaultMessage: "Descartado",
        }),
      },
    ],
  });
  filters.push({
    name: "felizVitaStatus",
    filterLabel: formatMessage({
      id: "EmployeesFilters.EmployeeFelizVitaStatus",
      defaultMessage: "Estado FV del Candidato",
    }),
    type: "multi",
    options: [
      {
        value: EMPLOYEE_FELIZ_VITA_STATUS.NOT_VALIDATED,
        label: formatMessage({
          id: "EmployeesFilters.WithoutValidation",
          defaultMessage: "Sin Validar",
        }),
      },
      {
        value: EMPLOYEE_FELIZ_VITA_STATUS.VALIDATED,
        label: formatMessage({
          id: "EmployeesFilters.Validated",
          defaultMessage: "Validado",
        }),
      },
      {
        value: EMPLOYEE_FELIZ_VITA_STATUS.ACTIVE,
        label: formatMessage({
          id: "EmployeesFilters.Active",
          defaultMessage: "Activo",
        }),
      },
      {
        value: EMPLOYEE_FELIZ_VITA_STATUS.VALIDATED_PLUS,
        label: formatMessage({
          id: "EmployeesFilters.ValidatedPlus",
          defaultMessage: "Validado Plus",
        }),
      },
      {
        value: EMPLOYEE_FELIZ_VITA_STATUS.DISCARDED,
        label: formatMessage({
          id: "EmployeesFilters.Discarded",
          defaultMessage: "Descartado",
        }),
      },
      {
        value: EMPLOYEE_FELIZ_VITA_STATUS.IN_PROCESS,
        label: formatMessage({
          id: "EmployeesFilters.InProcess",
          defaultMessage: "En Proceso",
        }),
      },
    ],
  });
  filters.push({
    name: "tipo",
    filterLabel: formatMessage({
      id: "EmployeesFilters.EmployeeAvailability",
      defaultMessage: "Disponibilidad del candidato",
    }),
    type: "multi",
    options: [
      {
        value: TIPO_INTERNA_ENTRE_SEMANA,
        label: formatMessage({
          id: "EmployeesFilters.Availability.InternalWeek",
          defaultMessage: "Interna entre semana",
        }),
      },
      {
        value: TIPO_INTERNA_FIN_DE_SEMANA,
        label: formatMessage({
          id: "EmployeesFilters.Availability.InternalWeekend",
          defaultMessage: "Interna fin de semana",
        }),
      },
      {
        value: TIPO_EXTERNA_ENTRE_SEMANA,
        label: formatMessage({
          id: "EmployeesFilters.Availability.ExternalWeek",
          defaultMessage: "Externa entre semana",
        }),
      },
      {
        value: TIPO_EXTERNA_FIN_DE_SEMANA,
        label: formatMessage({
          id: "EmployeesFilters.Availability.ExternalWeekend",
          defaultMessage: "Externa fin de semana",
        }),
      },
    ],
  });
  filters.push({
    name: "interviewStatus",
    filterLabel: formatMessage({
      id: "EmployeesFilters.InterviewStatus",
      defaultMessage: "Videoentrevista",
    }),
    type: "multi",
    options: [
      {
        value: EMPLOYEE_VIDEOINTERVIEW_STATUS_PENDING,
        label: formatMessage({
          id: "EmployeesFilters.InterviewStatus.Pending",
          defaultMessage: "Pendiente",
        }),
      },
      {
        value: EMPLOYEE_VIDEOINTERVIEW_STATUS_COMPLETED,
        label: formatMessage({
          id: "EmployeesFilters.InterviewStatus.Completed",
          defaultMessage: "Completada",
        }),
      },
      {
        value: EMPLOYEE_VIDEOINTERVIEW_STATUS_FINALIST,
        label: formatMessage({
          id: "EmployeesFilters.InterviewStatus.Finalist",
          defaultMessage: "Finalista",
        }),
      },
    ],
  });
  filters.push({
    name: "pathologies",
    filterLabel: formatMessage({
      id: "EmployeesFilters.Pathologies",
      defaultMessage: "Patologías",
    }),
    type: "multi",
    options: pathologiesList(
      employeeFilters.businessCountry === "fr" ? "fr" : "es"
    ).map((pathology) => {
      return {
        value: pathology.value,
        label: pathology.label,
      };
    }),
  });
  filters.push({
    name: "tasks",
    filterLabel: formatMessage({
      id: "EmployeesFilters.Tasks",
      defaultMessage: "Tareas",
    }),
    type: "multi",
    options: tasksList(
      employeeFilters.businessCountry === "fr" ? "fr" : "es"
    ).map((task) => {
      return {
        value: task.value,
        label: task.label,
      };
    }),
  });
  filters.push({
    name: "languages",
    filterLabel: formatMessage({
      id: "EmployeesFilters.Languages",
      defaultMessage: "Idiomas",
    }),
    type: "multi",
    options: languagesList(
      employeeFilters.businessCountry === "fr" ? "fr" : "es"
    ).map((language) => {
      return {
        value: language.value,
        label: formatLanguage(language.value),
      };
    }),
  });
  filters.push({
    name: "experience",
    filterLabel: formatMessage({
      id: "EmployeesFilters.ExperienceYears",
      defaultMessage: "Años de experiencia",
    }),
    type: "multi",
    options: [
      {
        value: EMPLOYEE_YEARS_OF_EXPERIENCE_NO_EXPERIENCE,
        label: formatMessage({
          id: `filterExp.NoExperience`,
          defaultMessage: "Sin experiencia",
        }),
      },
      {
        value: EMPLOYEE_YEARS_OF_EXPERIENCE_LESS_1_YEAR,
        label: formatMessage({
          id: `filterExp.Less1Year`,
          defaultMessage: "Menos de 1 año",
        }),
      },
      {
        value: EMPLOYEE_YEARS_OF_EXPERIENCE_LESS_2_YEARS,
        label: formatMessage({
          id: `filterExp.Less2Years`,
          defaultMessage: "Menos de 2 años",
        }),
      },
      {
        value: EMPLOYEE_YEARS_OF_EXPERIENCE_LESS_3_YEARS,
        label: formatMessage({
          id: `filterExp.Less3Years`,
          defaultMessage: "Menos de 3 años",
        }),
      },
      {
        value: EMPLOYEE_YEARS_OF_EXPERIENCE_MORE_3_YEARS,
        label: formatMessage({
          id: `filterExp.More3Years`,
          defaultMessage: "Más de 3 años",
        }),
      },
      {
        value: EMPLOYEE_YEARS_OF_EXPERIENCE_MORE_5_YEARS,
        label: formatMessage({
          id: `filterExp.More5Years`,
          defaultMessage: "Más de 5 años",
        }),
      },
      {
        value: EMPLOYEE_YEARS_OF_EXPERIENCE_MORE_10_YEARS,
        label: formatMessage({
          id: `filterExp.More10Years`,
          defaultMessage: "Más de 10 años",
        }),
      },
    ],
  });
  filters.push({
    name: "hasWhatsapp",
    filterLabel: formatMessage({
      id: "EmployeesFilters.Tiene whatsapp",
      defaultMessage: "Tiene whatsapp",
    }),
    type: "select",
    options: [
      {
        value: "",
        label: formatMessage({
          id: "EmployeesFilters.ChooseAnOption",
          defaultMessage: "Selecciona una opción",
        }),
      },
      {
        value: "yes",
        label: formatMessage({
          id: "EmployeesFilters.Con whatsapp",
          defaultMessage: "Con whatsapp",
        }),
      },
      {
        value: "no",
        label: formatMessage({
          id: "ContractFilters.Sin whatsapp",
          defaultMessage: "Sin whatsapp",
        }),
      },
    ],
  });
  filters.push({
    name: "workRegime",
    filterLabel: formatMessage({
      id: "EmployeesFilters.Régimen de trabajo",
      defaultMessage: "Régimen de trabajo",
    }),
    type: "multi",
    options: [
      {
        value: EMPLOYEE_WORK_REGIME.REEH,
        label: formatMessage({
          id: "EmployeesFilters.REEH",
          defaultMessage: "REEH",
        }),
      },
      {
        value: EMPLOYEE_WORK_REGIME.SAD,
        label: formatMessage({
          id: "EmployeesFilters.SAD",
          defaultMessage: "SAD",
        }),
      },
    ],
  });
  filters.push({
    name: "lugarDeNacimiento",
    filterLabel: formatMessage({
      id: "EmployeesFilters.Birthplace",
      defaultMessage: "Lugar de nacimiento",
    }),
    type: "select",
    options: [
      {
        value: "",
        label: formatMessage({
          id: "EmployeesFilters.ChooseAnOption",
          defaultMessage: "Selecciona una opción",
        }),
      },
      ...mostUsedCountriesOptionsEs.map((country) => {
        return {
          value: country.value,
          label: country.label,
        };
      }),
    ],
  });
  filters.push({
    name: "botAvailable",
    filterLabel: formatMessage({
      id: "EmployeesFilters.Bot disponible",
      defaultMessage: "Bot disponible",
    }),
    type: "select",
    options: [
      {
        value: "",
        label: formatMessage({
          id: "EmployeesFilters.ChooseAnOption",
          defaultMessage: "Selecciona una opción",
        }),
      },
      {
        value: "yes",
        label: formatMessage({
          id: "EmployeesFilters.BotAvailable.yes",
          defaultMessage: "Bot disponible",
        }),
      },
      {
        value: "no",
        label: formatMessage({
          id: "ContractFilters.BotAvailable.no",
          defaultMessage: "Bot no disponible",
        }),
      },
    ],
  });
  filters.push({
    name: "company",
    filterLabel: formatMessage({
      id: "EmployeesFilters.Empresa",
      defaultMessage: "Empresa",
    }),
    type: "select",
    options: [
      {
        value: "",
        label: formatMessage({
          id: "EmployeesFilters.ChooseAnOption",
          defaultMessage: "Selecciona una opción",
        }),
      },
      {
        value: "Cuideo",
        label: formatMessage({
          id: "EmployeesFilters.Company.Cuideo",
          defaultMessage: "Cuideo",
        }),
      },
      {
        value: "FV",
        label: formatMessage({
          id: "EmployeesFilters.Company.FelizVita",
          defaultMessage: "FelizVita",
        }),
      },
    ],
  });

  filters.push({
    name: "availability",
    filterLabel: formatMessage({
      id: "EmployeesFilters.WorkAvailability",
      defaultMessage: "Disponibilidad",
    }),
    type: "multi",
    options: [
      {
        value: EMPLOYEE_AVAILABILITY.AVAILABLE,
        label: formatMessage({
          id: "EmployeesFilters.WorkAvailability.Available",
          defaultMessage: "Disponible",
        }),
      },
      {
        value: EMPLOYEE_AVAILABILITY.WORKING,
        label: formatMessage({
          id: "EmployeesFilters.WorkAvailability.Working",
          defaultMessage: "Trabajando",
        }),
      },
    ],
  });

  return (
    <StyledWrapper>
      <StyledContainer>
        <StyledLeft>
          <CuideoSearchField
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Buscar"
          />
        </StyledLeft>
        <StyledRight>
          <StyledButtonWrapper>
            <FilterButton
              defaultButtonText={
                <FormattedMessage
                  id="EmployeesFilters.Country"
                  defaultMessage="País"
                />
              }
              options={[
                {
                  value: "es",
                  label: formatMessage({
                    id: "EmployeesFilters.Country.es",
                    defaultMessage: "España",
                  }),
                },
                {
                  value: "fr",
                  label: formatMessage({
                    id: "EmployeesFilters.Country.fr",
                    defaultMessage: "Francia",
                  }),
                },
              ]}
              initialValue={employeeFilters.businessCountry}
              handleApply={handleApply}
              handleReset={handleReset}
              filterName="businessCountry"
              inUse={employeeFilters.businessCountry !== ""}
              resetOptions={"es"}
            />
          </StyledButtonWrapper>
          <StyledButtonWrapper>
            <FilterButton
              defaultButtonText={
                <FormattedMessage
                  id="EmployeesFilters.Map"
                  defaultMessage="Mapa"
                />
              }
              options={[
                {
                  value: "no",
                  label: formatMessage({
                    id: "EmployeesFilters.Map.no",
                    defaultMessage: "Cualquier resultado",
                  }),
                },
                {
                  value: "si",
                  label: formatMessage({
                    id: "EmployeesFilters.Map.si",
                    defaultMessage: "Restringir resultados al mapa",
                  }),
                },
              ]}
              initialValue={employeeFilters.enMapa}
              handleApply={handleApply}
              handleReset={handleReset}
              filterName="enMapa"
              inUse={employeeFilters.enMapa !== "no"}
              resetOptions={"no"}
            />
          </StyledButtonWrapper>
          <StyledButtonWrapper>
            <FiltersButton
              filters={filters}
              filtersValues={{
                carnet: {
                  value: employeeFilters.carnet,
                },
                vehiculo: {
                  value: employeeFilters.vehiculo,
                },
                titulacion: {
                  value: employeeFilters.titulacion,
                },
                edad: {
                  value: employeeFilters.edad,
                },
                sexo: {
                  value: employeeFilters.sexo,
                },
                estadoCuideo: {
                  value: employeeFilters.estadoCuideo,
                },
                felizVitaStatus: {
                  value: employeeFilters.felizVitaStatus,
                },
                experience: {
                  value: employeeFilters.experience,
                },
                inOpenProcess: {
                  value: employeeFilters.inOpenProcess,
                },
                inRecentProcesses: {
                  value: employeeFilters.inRecentProcesses,
                },
                interviewStatus: {
                  value: employeeFilters.interviewStatus,
                },
                company: {
                  value: employeeFilters.company,
                },
                pathologies: {
                  value: employeeFilters.pathologies,
                },
                tasks: {
                  value: employeeFilters.tasks,
                },
                languages: {
                  value: employeeFilters.languages,
                },
                tipo: {
                  value: employeeFilters.tipo,
                },
                provincia: {
                  value: employeeFilters.provincia,
                },
                department: {
                  value: employeeFilters.department,
                },
                lugarDeNacimiento: {
                  value: employeeFilters.lugarDeNacimiento,
                },
                workRegime: {
                  value: employeeFilters.workRegime,
                },
                hasWhatsapp: {
                  value: employeeFilters.hasWhatsapp,
                },
                botAvailable: {
                  value: employeeFilters.botAvailable,
                },
                availability: {
                  value: employeeFilters.availability,
                },
              }}
              resetOptions={{
                carnet: {
                  value: resetState.carnet,
                },
                vehiculo: {
                  value: resetState.vehiculo,
                },
                titulacion: {
                  value: resetState.titulacion,
                },
                edad: {
                  value: resetState.edad,
                },
                sexo: {
                  value: resetState.sexo,
                },
                estadoCuideo: {
                  value: resetState.estadoCuideo,
                },
                felizVitaStatus: {
                  value: resetState.felizVitaStatus,
                },
                experience: {
                  value: resetState.experience,
                },
                inOpenProcess: {
                  value: resetState.inOpenProcess,
                },
                inRecentProcesses: {
                  value: resetState.inRecentProcesses,
                },
                interviewStatus: {
                  value: resetState.interviewStatus,
                },
                company: {
                  value: resetState.company,
                },
                pathologies: {
                  value: resetState.pathologies,
                },
                tasks: {
                  value: resetState.tasks,
                },
                languages: {
                  value: resetState.languages,
                },
                tipo: {
                  value: resetState.tipo,
                },
                provincia: {
                  value: resetState.provincia,
                },
                department: {
                  value: resetState.department,
                },
                lugarDeNacimiento: {
                  value: resetState.lugarDeNacimiento,
                },
                workRegime: {
                  value: resetState.workRegime,
                },
                hasWhatsapp: {
                  value: resetState.hasWhatsapp,
                },
                botAvailable: {
                  value: resetState.botAvailable,
                },
                availability: {
                  value: resetState.availability,
                },
              }}
              onFiltersChange={handleFiltersButtonChange}
            />
          </StyledButtonWrapper>
          <StyledButtonWrapper>
            <FilterButton
              defaultButtonText={
                <FormattedMessage
                  id="EmployeesFilters.Sort"
                  defaultMessage="Ordenar"
                />
              }
              options={[
                {
                  value: "recientes",
                  label: formatMessage({
                    id: "EmployeesFilters.Sort.MoreRecent",
                    defaultMessage: "Más recientes",
                  }),
                },
                {
                  value: "antiguas",
                  label: formatMessage({
                    id: "EmployeesFilters.Sort.LessRecent",
                    defaultMessage: "Menos recientes",
                  }),
                },
              ]}
              initialValue={employeeFilters.orden}
              handleApply={handleApply}
              handleReset={handleReset}
              filterName="orden"
              inUse={employeeFilters.orden !== ""}
              resetOptions={"recientes"}
            />
          </StyledButtonWrapper>
        </StyledRight>
      </StyledContainer>
    </StyledWrapper>
  );
};

export default CandidatosFilters;
