export const MESSAGE_MODULE = {
  CONTRACT: "contract",
} as const;

export type TMessageModule = typeof MESSAGE_MODULE[keyof typeof MESSAGE_MODULE];

export const MESSAGE_STATUS = {
  SENT: "sent",
  DELIVERED: "delivered",
  READ: "read",
} as const;

export type TMessageStatus = typeof MESSAGE_STATUS[keyof typeof MESSAGE_STATUS];

export type TGetMessagesCollectionItem = {
  id: string;
  parentId: string;
  module: TMessageModule;
  status: TMessageStatus;
  content: string;
  createdBy?: {
    id: string;
    fullname: string;
  } | null;
  createdAt: string;
};
