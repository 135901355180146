import { Button } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { cuideoTheme } from "../../../../../../containers/themes/defaultTheme";
import { TUserGroupAssignations } from "../../types/UserGroupAssignations.types";
import GroupAssignationsList from "./GroupAssignationsList";
import RecruitersAssignationsLegend from "./RecruitersAssignationsLegend";

const StyledWrapper = styled.div`
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const StyledFixedHeightTop = styled.div`
  height: 4rem;
  box-sizing: border-box;
  border-bottom: 0.125rem solid rgb(235, 235, 235);
`;

const StyledAllContractsButton = styled(Button)`
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  color: ${cuideoTheme.palette.primary.main};
`;

const StyledNumberSpan = styled.span`
  font-size: 1.5rem;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: ${cuideoTheme.palette.primary.main};
  margin-right: 0.5rem;
`;

const StyledVariablePanel = styled.div`
  flex: 1 1 auto;
  overflow-y: scroll;
  height: 100%;
  max-height: calc(100vh - 6rem);
  box-sizing: border-box;

  ::-webkit-scrollbar {
    width: 0.375rem;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 72, 120, 0.25);
    width: 0.375rem;
    border-radius: 0.125rem;
  }
`;

const StyledFixedBottom = styled.div`
  flex: 0 0 auto;
  height: 6rem;
  box-sizing: border-box;
`;

interface IRecruiterAssignationsProps {
  isLoading: boolean;
  selectedOwnerId: string | null;
  totalContracts: number;
  onSelectedOwnerChange: (ownerId: string | null) => void;
  groups: TUserGroupAssignations[];
  maxGroupContracts: number;
}

const RecruiterAssignations: React.FC<IRecruiterAssignationsProps> = (
  props
) => {
  const {
    isLoading,
    groups,
    totalContracts,
    maxGroupContracts,
    selectedOwnerId,
    onSelectedOwnerChange,
  } = props;

  const handleClickAllContracts = () => {
    onSelectedOwnerChange(null);
  };

  return (
    <StyledWrapper>
      <StyledFixedHeightTop>
        <StyledAllContractsButton fullWidth onClick={handleClickAllContracts}>
          <StyledNumberSpan>{totalContracts}</StyledNumberSpan>
          <FormattedMessage
            id="RecruiterAssignations.Contratos"
            defaultMessage="Contratos"
          />
        </StyledAllContractsButton>
      </StyledFixedHeightTop>
      <StyledVariablePanel>
        <GroupAssignationsList
          groups={groups}
          isLoading={isLoading}
          maxGroupContracts={maxGroupContracts}
          selectedOwnerId={selectedOwnerId}
          onSelectedOwnerChange={onSelectedOwnerChange}
        />
      </StyledVariablePanel>
      <StyledFixedBottom>
        <RecruitersAssignationsLegend />
      </StyledFixedBottom>
    </StyledWrapper>
  );
};

export default RecruiterAssignations;
