import TouchAppIcon from "@material-ui/icons/TouchApp";
import { Box, Grow, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import SendIcon from "@material-ui/icons/Send";
import classNames from "classnames";
import React, { useRef, useState } from "react";
import { FaRobot } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import {
  TWorkerStatus,
  WORKER_STATUS,
} from "../../../../../../apiCoreV3/worker/suggestion-worker.types";
import {
  COLOR_RED_NOTIFICATION,
  cuideoTheme,
} from "../../../../../../containers/themes/defaultTheme";

const StyledBotBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid rgba(0, 72, 120, 0.5);
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  color: ${cuideoTheme.palette.primary.main};

  &.CMui-finished {
    background-color: #e9e9ef;
  }

  &.CMui-error {
    background-color: #fba9a4;
  }

  &.CMui-paused {
    background-color: #e9e9ef;
  }

  &.CMui-working {
    background-color: #c5e8b2;
  }
`;

const StyledTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 1.125rem;
`;

const StyledBottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 0.75rem;
`;

const StyledBotIconWrapper = styled.div`
  margin-right: 0.5rem;
`;

const StyledBotData = styled(Typography)`
  font-weight: 400;
  line-height: 1;
  font-size: 1rem;
`;

const StyledBottomIconWrapper = styled.div`
  .MuiSvgIcon-root {
    width: 0.75rem;
    height: 0.75rem;
    margin-right: 0.25rem;
  }

  &.CMui-error {
    color: ${COLOR_RED_NOTIFICATION};
  }
`;

const StyledBottomText = styled(Typography)`
  font-size: 0.75rem;

  &.CMui-error {
    color: ${COLOR_RED_NOTIFICATION};
    font-weight: 700;
  }
`;

const StyledPaper = styled.div`
  padding: 0.75rem 1rem;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: #fff;
  z-index: 1;
  min-width: 12rem;
  top: calc(100% + 0.0625rem);
  left: -8rem;
  right: -8rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 8px 0 rgba(0, 75, 123, 0.2);

  &.CMui-error {
    background-color: #fba9a4;
    color: ${COLOR_RED_NOTIFICATION};
  }

  &.CMui-finished {
    background-color: #e9e9ef;
  }

  &.CMui-working {
    background-color: #c5e8b2;
  }
`;

const StyledPaperLeft = styled.div`
  .MuiSvgIcon-root {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
  }
`;

const StyledPaperRight = styled.div``;

const StyledPaperTitle = styled(Typography)`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.35;
`;

const StyledErrorDetail = styled(Typography)`
  font-weight: 400;
  font-size: 0.75rem;
`;

interface IBotInfoProps {
  status?: TWorkerStatus;
  errorCode?: string;
  errorMessage?: string;
  numCommunicationIntents: number;
  numSuccessfulCommunicationIntents: number;
  onClick: () => void;
}

const BotInfo: React.FC<IBotInfoProps> = (props) => {
  const {
    status,
    errorCode,
    errorMessage,
    numCommunicationIntents,
    numSuccessfulCommunicationIntents,
    onClick,
  } = props;
  const timerRef: React.MutableRefObject<ReturnType<typeof setTimeout> | null> =
    useRef<ReturnType<typeof setTimeout>>(null);
  const [open, setOpen] = useState(false);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    timerRef.current = setTimeout(() => {
      setOpen(true);
    }, 500);
  };

  const handlePopoverClose = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    if (open) {
      setOpen(false);
    }
  };

  if (typeof status === "undefined") {
    return null;
  }

  const statusClassName = classNames({
    "CMui-pending": status === WORKER_STATUS.PENDING,
    "CMui-working": status === WORKER_STATUS.WORKING,
    "CMui-paused": status === WORKER_STATUS.PAUSED,
    "CMui-finished": status === WORKER_STATUS.FINISHED,
    "CMui-error": status === WORKER_STATUS.ERROR,
  });

  return (
    <StyledBotBox
      role="button"
      onClick={onClick}
      className={statusClassName}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <StyledTop>
        <StyledBotIconWrapper>
          <FaRobot />
        </StyledBotIconWrapper>
        <StyledBotData>
          {numSuccessfulCommunicationIntents} / {numCommunicationIntents}
        </StyledBotData>
      </StyledTop>
      {status === WORKER_STATUS.FINISHED && (
        <StyledBottom>
          <StyledBottomIconWrapper className={statusClassName}>
            <CheckCircleIcon />
          </StyledBottomIconWrapper>
          <StyledBottomText>
            <FormattedMessage
              id="BotInfo.Finalizado"
              defaultMessage="Finalizado"
            />
          </StyledBottomText>
        </StyledBottom>
      )}
      {status === WORKER_STATUS.WORKING && (
        <StyledBottom>
          <StyledBottomIconWrapper className={statusClassName}>
            <SendIcon />
          </StyledBottomIconWrapper>
          <StyledBottomText>
            <FormattedMessage
              id="BotInfo.Enviando..."
              defaultMessage="Enviando..."
            />
          </StyledBottomText>
        </StyledBottom>
      )}
      {status === WORKER_STATUS.PAUSED && (
        <StyledBottom>
          <StyledBottomIconWrapper className={statusClassName}>
            <PauseCircleFilledIcon />
          </StyledBottomIconWrapper>
          <StyledBottomText>
            <FormattedMessage id="BotInfo.En pausa" defaultMessage="En pausa" />
          </StyledBottomText>
        </StyledBottom>
      )}
      {status === WORKER_STATUS.ERROR && (
        <StyledBottom>
          <StyledBottomIconWrapper className={statusClassName}>
            <ErrorIcon />
          </StyledBottomIconWrapper>
          <StyledBottomText className={statusClassName}>
            <FormattedMessage id="BotInfo.Error" defaultMessage="Error" />
          </StyledBottomText>
        </StyledBottom>
      )}
      {status === WORKER_STATUS.MANUAL && (
        <StyledBottom>
          <StyledBottomIconWrapper className={statusClassName}>
            <TouchAppIcon />
          </StyledBottomIconWrapper>
          <StyledBottomText className={statusClassName}>
            <FormattedMessage id="BotInfo.Manual" defaultMessage="Manual" />
          </StyledBottomText>
        </StyledBottom>
      )}
      {(status === WORKER_STATUS.ERROR ||
        status === WORKER_STATUS.WORKING ||
        status === WORKER_STATUS.PAUSED ||
        status === WORKER_STATUS.MANUAL ||
        status === WORKER_STATUS.FINISHED) && (
        <Grow in={open}>
          <StyledPaper className={statusClassName}>
            {status === WORKER_STATUS.ERROR && (
              <>
                <StyledPaperLeft>
                  <ErrorIcon />
                </StyledPaperLeft>
                <StyledPaperRight>
                  {errorCode === "missing_fields_for_contract_suggestion" ? (
                    <>
                      <StyledPaperTitle>
                        <FormattedMessage
                          id="BotInfo.Hay algún error en los datos del contrato. Corrígelos para que el Bot pueda reanudarse en la próxima tanda de envíos."
                          defaultMessage="Hay algún error en los datos del contrato. Corrígelos para que el Bot pueda reanudarse en la próxima tanda de envíos."
                        />
                      </StyledPaperTitle>
                      {errorMessage?.includes("salary_type") && (
                        <StyledErrorDetail>
                          <FormattedMessage
                            id="BotInfo.Falta el Tipo de salario"
                            defaultMessage="Falta el Tipo de salario"
                          />
                        </StyledErrorDetail>
                      )}
                      {errorMessage?.includes("schedule") && (
                        <StyledErrorDetail>
                          <FormattedMessage
                            id="BotInfo.Falta el Horario"
                            defaultMessage="Falta el Horario"
                          />
                        </StyledErrorDetail>
                      )}
                      {errorMessage?.includes("salary") && (
                        <StyledErrorDetail>
                          <FormattedMessage
                            id="BotInfo.Falta el Salario Bruto"
                            defaultMessage="Falta el Salario Bruto"
                          />
                        </StyledErrorDetail>
                      )}
                      {errorMessage?.includes("province") && (
                        <StyledErrorDetail>
                          <FormattedMessage
                            id="BotInfo.Falta la provincia"
                            defaultMessage="Falta la provincia"
                          />
                        </StyledErrorDetail>
                      )}
                    </>
                  ) : (
                    <StyledPaperTitle>
                      <FormattedMessage
                        id="BotInfo.Hay algún error que impide el envío de mensajes"
                        defaultMessage="Hay algún error que impide el envío de mensajes"
                      />
                    </StyledPaperTitle>
                  )}
                </StyledPaperRight>
              </>
            )}
            {status === WORKER_STATUS.FINISHED && (
              <>
                <StyledPaperLeft>
                  <CheckCircleIcon />
                </StyledPaperLeft>
                <StyledPaperRight>
                  <StyledPaperTitle>
                    <FormattedMessage
                      id="BotInfo.El Bot ha terminado el envío para este Contrato"
                      defaultMessage="El Bot ha terminado el envío para este Contrato"
                    />
                  </StyledPaperTitle>
                </StyledPaperRight>
              </>
            )}
            {status === WORKER_STATUS.PAUSED && (
              <>
                <StyledPaperLeft>
                  <PauseCircleFilledIcon />
                </StyledPaperLeft>
                <StyledPaperRight>
                  <StyledPaperTitle>
                    <FormattedMessage
                      id="BotInfo.El Bot está en pausa para este Contrato"
                      defaultMessage="El Bot está en pausa para este Contrato"
                    />
                  </StyledPaperTitle>
                </StyledPaperRight>
              </>
            )}
            {status === WORKER_STATUS.WORKING && (
              <>
                <StyledPaperLeft>
                  <SendIcon />
                </StyledPaperLeft>
                <StyledPaperRight>
                  <StyledPaperTitle>
                    <FormattedMessage
                      id="BotInfo.El Bot está enviando mensajes para inscribir candidatos/as en este Contrato"
                      defaultMessage="El Bot está enviando mensajes para inscribir candidatos/as en este Contrato"
                    />
                  </StyledPaperTitle>
                </StyledPaperRight>
              </>
            )}
            {status === WORKER_STATUS.MANUAL && (
              <>
                <StyledPaperLeft>
                  <TouchAppIcon />
                </StyledPaperLeft>
                <StyledPaperRight>
                  <StyledPaperTitle>
                    <FormattedMessage
                      id="BotInfo.El Bot ha intentado contactar con Candidatos a petición de un Coordinador/a"
                      defaultMessage="El Bot ha intentado contactar con Candidatos a petición de un Coordinador/a"
                    />
                  </StyledPaperTitle>
                </StyledPaperRight>
              </>
            )}
          </StyledPaper>
        </Grow>
      )}
    </StyledBotBox>
  );
};

export default BotInfo;
