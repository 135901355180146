import React from "react";
import styled from "styled-components";
import cuideoTheme from "../../containers/themes/defaultTheme";
import {
  Dialog,
  Typography,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button
} from "@material-ui/core";
import IntlMessages from "../../util/IntlMessages";
import Transition from "./Transition";

const StyledErrorModal = styled(Dialog)`
  .MuiPaper-root {
    max-width: 20rem;
    color: ${cuideoTheme.palette.error.contrastText};
    background-color: ${cuideoTheme.palette.error.dark};
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  .MuiTypography-root {
    color: ${cuideoTheme.palette.error.contrastText};
    font-size: 1.25rem;
  }
`;

const StyledModalText = styled(Typography)`
  line-height: normal;
`;

const StyledModalActions = styled(DialogActions)`
  justify-content: center;
  margin: 0.5rem 0;
`;

const ErrorModal = (props: any) => {
  const { modal, handleDialogClose } = props;

  return (
    <StyledErrorModal
      open={modal.open}
      TransitionComponent={Transition as any}
      keepMounted
      onClose={handleDialogClose}
    >
      <StyledDialogTitle>
        <IntlMessages id="Error" />
      </StyledDialogTitle>
      <DialogContent>
        <StyledModalText>{modal.message}</StyledModalText>
      </DialogContent>
      <StyledModalActions>
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => {
            handleDialogClose();
          }}
        >
          <IntlMessages id="Entendido" />
        </Button>
      </StyledModalActions>
    </StyledErrorModal>
  );
};

export default ErrorModal;
