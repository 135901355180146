import { Typography } from "@material-ui/core";
import { motion, Variants } from "framer-motion";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import SadWorkers from "../../../../../../assets/images/sad_workers.svg";
import { cuideoTheme } from "../../../../../../containers/themes/defaultTheme";

const StyledWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledImage = styled.img`
  text-align: center;
  margin: 0 auto;
  display: block;
`;

const StyledText = styled(Typography)`
  line-height: 1.35;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 600;
  color: ${cuideoTheme.palette.primary.main};
`;

const variants: Variants = {
  enter: {
    opacity: 1,
    scale: 1,
    transition: { duration: 0.25, delay: 1 },
  },
  exit: {
    opacity: 0,
    scale: 0.5,
    transition: { duration: 0.1 },
  },
};

interface ISelectConversationProps {}

const SelectConversation: React.FC<ISelectConversationProps> = (props) => {
  return (
    <StyledWrapper>
      <motion.div initial="exit" animate="enter" variants={variants}>
        <StyledImage src={SadWorkers} />
      </motion.div>
      <motion.div initial="exit" animate="enter" variants={variants}>
        <StyledText>
          <FormattedMessage
            id="SelectConversation.Selecciona una conversación para poder ver sus mensajes"
            defaultMessage="Selecciona una conversación para poder ver sus mensajes"
          />
        </StyledText>
      </motion.div>
    </StyledWrapper>
  );
};

export default SelectConversation;
