import { useQuery } from "react-query";
import { apiListEmployeesBotAvailability } from "../../../../../api/employee/apiListEmployeesBotAvailability";

export default function useEmployeesBotAvailabilityQuery(
  employeeIds: string[]
) {
  return useQuery(
    ["employees_bot_availability", employeeIds.join("_")],
    async () => {
      const res = await apiListEmployeesBotAvailability(employeeIds);

      return res;
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );
}
