import { AxiosResponse } from "axios";
import { TApiPaginatedResponse } from "../api-corev3.types";
import { apiCoreV3 } from "../apiCoreV3";
import { TMessageResponse } from "../message/apiMessage.types";

const defaultParameters = {
  page: 1,
  pageSize: 20,
};

export interface IListConversationsMessagesParams {
  page?: number;
  pageSize?: number;
  order?: "desc" | "asc";
  orderBy?: "createdAt" | "updatedAt";
}

const apiListConversationMessages = async (
  conversationId: string,
  params: IListConversationsMessagesParams = {}
) => {
  const res: AxiosResponse<TApiPaginatedResponse<TMessageResponse>> =
    await apiCoreV3.get(
      `/communication/conversations/${conversationId}/messages`,
      {
        params: {
          ...defaultParameters,
          ...params,
        },
      }
    );

  return res.data;
};

export { apiListConversationMessages };
