import { Badge, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import WhatsAppSvg from "../../../../../../assets/images/iconos/whatsapp.svg";
import { useConversationMessages } from "../../api/getConversationMessages";
import { TConversation } from "../../types/conversation.types";
import MessageDoubleCheck from "../Conversation/MessageDoubleCheck";
import MessageFailed from "../Conversation/MessageFailed";
import MessageSingleCheck from "../Conversation/MessageSingleCheck";
import { MESSAGE_DIRECTION } from "../../types/message.type";
import {
  conversationMessageText,
  removeFirstLineWithAuthor,
} from "../../util/message.util";
import { useIntersection } from "react-use";
import { useAuthUser } from "../../../../../../reducers/hooks/useAuthUser";
import { numUnreadMessagesForConversation } from "../../util/conversation.util";
import { COLOR_SUCCESS } from "../../../../../../containers/themes/defaultTheme";
import classNames from "classnames";

const StyledBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: rgb(245, 245, 245);
  }
`;

const StyledLeft = styled.div`
  padding: 0.25rem 0 0.25rem 0.5rem;
`;

const StyledWhatsAppWrapper = styled.div`
  padding: 0.25rem 0.75rem 0.25rem 0.25rem;
  .CMuiWhatsIcon {
    width: 2rem;
    height: 2rem;
  }
  .MuiAvatar-root {
    width: 2rem;
    height: 2rem;
  }
`;

const StyledRight = styled.div`
  padding: 0.25rem 0.5rem 0.25rem 0;
  flex: 1 1 auto;
  border-bottom: 1px solid #efefef;
  min-height: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const StyledRightTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
`;

const StyledRightTopLeft = styled.div``;

const StyledRightTopRight = styled.div``;

const StyledTimeString = styled(Typography)`
  font-size: 0.625rem;
  color: #777;
  line-height: 1;
  margin-bottom: 0.4375rem;

  &.CMui-success {
    font-size: 0.6875rem;
    color: ${COLOR_SUCCESS};
    font-weight: 700;
  }
`;

const StyledTargetName = styled(Typography)`
  line-height: 1.45;
  font-size: 1rem;
  font-weight: 600;
  color: #333;

  max-width: 18rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const StyledRightCenter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
`;

const StyledRightCenterLeft = styled.div``;

const StyledRightCenterRight = styled.div``;

const StyledLastPhraseWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .MuiSvgIcon-root {
    margin-top: -0.125rem;
    margin-right: 0.125rem;
  }
`;

const StyledLastPhrase = styled(Typography)`
  line-height: 1.25;
  font-size: 0.875rem;
  color: #777;

  height: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 18rem;
`;

const StyledUnreadBadge = styled(Badge)`
  .MuiBadge-colorPrimary {
    background-color: ${COLOR_SUCCESS};
    font-weight: 600;
  }
`;

const StyledUnreadPlaceholder = styled.div`
  width: 0.5rem;
  height: 0.625rem;
`;

interface IConversationsListItemProps {
  conversation: TConversation;
  onClick?: (conversationId: string) => void;
}

const ConversationsListItem: React.FC<IConversationsListItemProps> = (
  props
) => {
  const { conversation, onClick } = props;
  const [appeared, setAppeared] = useState(false);
  const intersectionRef = React.useRef(null);
  const { authUser } = useAuthUser();
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: "1px",
    threshold: 0,
  });
  const messagesQuery = useConversationMessages({
    conversationId: conversation.id,
    config: {
      enabled: appeared,
    },
  });

  useEffect(
    function setAppearedWhenVisibleOnScreen() {
      if (!!intersection && intersection.intersectionRatio > 0) {
        if (!appeared) {
          setAppeared(true);
        }
      }
    },
    [appeared, intersection]
  );

  const timeString = useMemo(() => {
    const iTime = moment().startOf("day").subtract(7, "days");
    const lastMessageAt = moment(conversation.updated_at);
    if (lastMessageAt.isBefore(iTime)) {
      return lastMessageAt.format("DD/MM/YYYY");
    }
    iTime.add(6, "days");
    if (lastMessageAt.isBefore(iTime)) {
      return lastMessageAt.format("dddd");
    }
    return lastMessageAt.format("HH:mm");
  }, [conversation.updated_at]);

  const handleClick = () => {
    onClick?.(conversation.id);
  };

  const lastMessage = messagesQuery.data?.messages
    ? messagesQuery.data.messages[messagesQuery.data.messages.length - 1]
    : null;

  const numUnreadMessages = !!authUser.coreV3Id
    ? numUnreadMessagesForConversation(conversation, authUser.coreV3Id)
    : 0;

  return (
    <div ref={intersectionRef}>
      <StyledBox onClick={handleClick} className="CMuiCommunicationIntentItem">
        <StyledLeft>
          <StyledWhatsAppWrapper>
            <img className="CMuiWhatsIcon" src={WhatsAppSvg} alt="" />
          </StyledWhatsAppWrapper>
        </StyledLeft>
        <StyledRight>
          <StyledRightTop>
            <StyledRightTopLeft>
              {conversation.candidate ? (
                <StyledTargetName>
                  {conversation.candidate.full_name}
                </StyledTargetName>
              ) : (
                <StyledTargetName>
                  <FormattedMessage
                    id="ConversationsListItem.( Desconocido {phoneNumber} )"
                    defaultMessage="( Desconocido {phoneNumber} )"
                    values={{
                      phoneNumber: conversation.contact_phone,
                    }}
                  />
                </StyledTargetName>
              )}
            </StyledRightTopLeft>
            <StyledRightTopRight>
              <StyledTimeString
                className={classNames({
                  "CMui-success": numUnreadMessages > 0,
                })}
              >
                {timeString}
              </StyledTimeString>
            </StyledRightTopRight>
          </StyledRightTop>
          <StyledRightCenter>
            <StyledRightCenterLeft>
              <StyledLastPhraseWrapper>
                {lastMessage?.direction === "sent" &&
                  lastMessage.status === "delivered" && (
                    <MessageDoubleCheck isRead={false} />
                  )}
                {lastMessage?.direction === "sent" &&
                  lastMessage.status === "read" && (
                    <MessageDoubleCheck isRead={true} />
                  )}
                {lastMessage?.direction === "sent" &&
                  lastMessage.status === "sent" && <MessageSingleCheck />}
                {lastMessage?.direction === "sent" &&
                  lastMessage.status === "transmitted" && (
                    <MessageSingleCheck />
                  )}
                {lastMessage?.direction === "sent" &&
                  lastMessage.status === "failed" && <MessageFailed />}
                {lastMessage?.direction === "sent" &&
                  lastMessage.status === "rejected" && <MessageFailed />}

                <StyledLastPhrase>
                  {!lastMessage ? (
                    <Skeleton animation="wave" width={300} height={22} />
                  ) : lastMessage.direction === MESSAGE_DIRECTION.RECEIVED ? (
                    conversationMessageText(lastMessage)
                  ) : (
                    removeFirstLineWithAuthor(
                      conversationMessageText(lastMessage)
                    )
                  )}
                </StyledLastPhrase>
              </StyledLastPhraseWrapper>
            </StyledRightCenterLeft>
            <StyledRightCenterRight>
              <StyledUnreadBadge
                badgeContent={numUnreadMessages}
                color="primary"
                max={9}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                overlap="rectangular"
              >
                <StyledUnreadPlaceholder />
              </StyledUnreadBadge>
            </StyledRightCenterRight>
          </StyledRightCenter>
        </StyledRight>
      </StyledBox>
    </div>
  );
};

export default ConversationsListItem;
