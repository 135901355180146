import {
  apiListContracts,
  IApiListContractsParams,
} from "../../../../../api/contract/apiListContracts";
import { InfiniteQueryConfig } from "../../../../../lib/react-query";
import { useInfiniteQuery } from "react-query";
import { contractKeys } from "../util/contract.query-keys";

type QueryFnType = typeof apiListContracts;

type UseContractsOptions = {
  config?: InfiniteQueryConfig<QueryFnType>;
  params: IApiListContractsParams;
};

export const useInfiniteContracts = ({
  config,
  params,
}: UseContractsOptions) => {
  // @ts-ignore
  return useInfiniteQuery({
    ...config,
    // @TODO: why is there an issue here?
    queryKey: contractKeys.list(params),
    queryFn: (queryContext) =>
      apiListContracts({
        ...params,
        page: queryContext.pageParam ?? 1,
        partial: queryContext.pageParam !== 1 ? 1 : 0,
      }),
  });
};
