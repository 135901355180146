import { Grow, Typography } from "@material-ui/core";
import React, { useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { EMPLOYEE_STATUS } from "../../../../../../api/employee/employee.types";
import { cuideoTheme } from "../../../../../../containers/themes/defaultTheme";
import CandidateContractsDetail from "./CandidateContractsDetail";

const StyledTag = styled.div<{ status: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ccc;
  padding: 0.125rem 0.5rem;
  border-radius: ${cuideoTheme.shape.borderRadius}px;
  min-height: 1.875rem;

  ${(props) => {
    switch (props.status) {
      case "Descartado":
        return `
          background-color: orange;
          color: ${cuideoTheme.palette.primary.main};
        `;
      case "Sin Validar":
        return `
          background-color: ${cuideoTheme.palette.grey[300]};
          color: ${cuideoTheme.palette.primary.main};
          `;
      case "Validado Plus":
        return `
          background-color: #ebc200;
          color: ${cuideoTheme.palette.primary.main};
        `;
      case "Activo":
        return `
          background-color: green;
          color: ${cuideoTheme.palette.common.white};
        `;
      default:
        return `
          background-color: ${cuideoTheme.palette.primary.light};
          color: ${cuideoTheme.palette.primary.contrastText};
        `;
    }
  }}
`;

const StyledTagTop = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledTagLabel = styled(Typography)`
  font-size: 0.8125rem;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
`;

const StyledDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  font-size: 0.75rem;
`;

const StyledDetailsLeft = styled(Typography)`
  font-size: inherit;
  line-height: 1;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const StyledDetailsRight = styled(Typography)`
  font-size: inherit;
  line-height: 1;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const StyledPaper = styled.div`
  padding: 0.75rem 1rem;
  position: absolute;
  display: block;
  background-color: #fff;
  z-index: 1000;
  top: calc(100% + 0.0625rem);
  left: -8rem;
  right: -8rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 8px 0 rgba(0, 75, 123, 0.2);
`;

const StyledLabel = styled(Typography)`
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  text-align: left;
`;

const StyledDetailTableWrapper = styled.div`
  padding: 0.5rem 0.125rem;
`;

interface ICandidateStatusTagProps {
  candidateId: string;
  candidateName?: string;
  status: string;
  numContractsWorked?: number;
  numDaysWorked?: number;
  canOpenDetails?: boolean;
}

const CandidateStatusTag: React.FC<ICandidateStatusTagProps> = (props) => {
  const {
    candidateId,
    candidateName,
    numContractsWorked,
    numDaysWorked,
    canOpenDetails = false,
  } = props;
  let parsedStatus = props.status;

  const { formatMessage } = useIntl();
  const [open, setOpen] = useState(false);
  const timerRef: React.MutableRefObject<ReturnType<typeof setTimeout> | null> =
    useRef<ReturnType<typeof setTimeout>>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    const canOpen =
      canOpenDetails &&
      props.status === EMPLOYEE_STATUS.VALIDATED_PLUS &&
      numDaysWorked !== 0 &&
      numDaysWorked !== undefined &&
      numContractsWorked !== 0 &&
      numContractsWorked !== undefined;

    if (canOpen) {
      timerRef.current = setTimeout(() => {
        setOpen(true);
      }, 500);
    }
  };

  const handlePopoverClose = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    if (open) {
      setOpen(false);
    }
  };

  if (!parsedStatus) {
    parsedStatus = EMPLOYEE_STATUS.NOT_VALIDATED;
  }
  if (parsedStatus === "Validado T") {
    parsedStatus = EMPLOYEE_STATUS.VALIDATED;
  }
  if (parsedStatus === "Validado E") {
    parsedStatus = EMPLOYEE_STATUS.VALIDATED;
  }

  return (
    <StyledTag
      className="CMuiCandidateStatus"
      status={parsedStatus}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <StyledTagTop>
        <StyledTagLabel>{parsedStatus}</StyledTagLabel>
      </StyledTagTop>
      {parsedStatus === EMPLOYEE_STATUS.VALIDATED_PLUS &&
        !!numContractsWorked &&
        !!numDaysWorked && (
          <StyledDetails>
            <StyledDetailsLeft>
              {numContractsWorked === 1 ? (
                <FormattedMessage
                  id="CandidateStatusTag.1 contrato"
                  defaultMessage="1 contrato"
                />
              ) : (
                <FormattedMessage
                  id="CandidateStatusTag.{numContractsWorked} contratos"
                  defaultMessage="{numContractsWorked} contratos"
                  values={{ numContractsWorked }}
                />
              )}
            </StyledDetailsLeft>
            <StyledDetailsRight>{numDaysWorked}d</StyledDetailsRight>
          </StyledDetails>
        )}
      {canOpenDetails && (
        <Grow in={open}>
          <StyledPaper>
            <StyledLabel>
              <FormattedMessage
                id="CandidateStatusTag.{candidateName} ha trabajado con Cuideo {time} en {contracts}"
                defaultMessage="{candidateName} ha trabajado con Cuideo {time} en {contracts}"
                values={{
                  candidateName: <b>{candidateName}</b>,
                  time:
                    numDaysWorked === 1 ? (
                      <b>
                        {formatMessage({
                          id: "CandidateStatusTag.1 día",
                          defaultMessage: "1 día",
                        })}
                      </b>
                    ) : (
                      <b>
                        {formatMessage(
                          {
                            id: "CandidateStatusTag.{numDaysWorked} días",
                            defaultMessage: "{numDaysWorked} días",
                          },
                          {
                            numDaysWorked: numDaysWorked,
                          }
                        )}
                      </b>
                    ),
                  contracts:
                    numContractsWorked === 1 ? (
                      <b>
                        {formatMessage({
                          id: "CandidateStatusTag.1 contrato",
                          defaultMessage: "1 contrato",
                        })}
                      </b>
                    ) : (
                      <b>
                        {formatMessage(
                          {
                            id: "CandidateStatusTag.{numContractsWorked} contratos",
                            defaultMessage: "{numContractsWorked} contratos",
                          },
                          {
                            numContractsWorked: numContractsWorked,
                          }
                        )}
                      </b>
                    ),
                }}
              />
            </StyledLabel>
            <StyledDetailTableWrapper>
              {open && <CandidateContractsDetail candidateId={candidateId} />}
            </StyledDetailTableWrapper>
          </StyledPaper>
        </Grow>
      )}
    </StyledTag>
  );
};

export default CandidateStatusTag;
