import {
  GET_ACTIVIDADES,
  GET_ACTIVIDADES_SUCCESS
} from "../constants/ActionTypes";

export const getActividades = (
  userId: string | null,
  options: { hideLoadingState: boolean } | null
) => {
  return {
    type: GET_ACTIVIDADES,
    payload: {
      userId,
      options
    }
  };
};

export const getActividadesSuccess = ({
  partialActividades,
  numActividades
}: {
  partialActividades: any[];
  numActividades: number;
}) => {
  return {
    type: GET_ACTIVIDADES_SUCCESS,
    payload: { partialActividades, numActividades }
  };
};
