import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import uuidv4 from "uuid/v4";
import * as Yup from "yup";
import NoIcon from "../../../../assets/images/iconos/no.svg";
import SiIcon from "../../../../assets/images/iconos/si.svg";
import cuideoTheme from "../../../../containers/themes/defaultTheme";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import {
  FormikRadioGroup,
  FormikTextField,
} from "../../../../components/FormikFields";
import VisibleFieldOnProfile from "../../../../components/FormikFields/VisibleFieldOnProfile";

const StyledEmptyMessage = styled(Typography)`
  text-align: center;
  font-size: 1rem;
`;
const StyledSectionHeader = styled(Typography)`
  font-size: 1.25rem;
  color: ${cuideoTheme.palette.primary.main};
  border-bottom: 1px solid ${cuideoTheme.palette.grey[300]};
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
`;

const StyledFormWrapper = styled.div`
  margin: 0 1rem;
`;

const StyledTableBodyCell = styled(TableCell)`
  font-size: 0.875rem;
  color: ${cuideoTheme.palette.primary.main};
  line-height: 1.25;
  padding: 0.5rem 0.75rem;
`;

const StyledHeadCell = styled(TableCell)`
  background-color: rgba(0, 72, 120, 0.1);
  color: ${cuideoTheme.palette.primary.light};
  font-size: 0.75rem;
  line-height: normal;
  font-weight: 700;
  padding: 0.75rem 0.75rem;
`;

const StyledBodyRow = styled(TableRow)`
  background-color: #fff;
`;

const StyledTable = styled(Table)`
  table-layout: fixed;
`;

const StyledIconButton = styled(IconButton)`
  border: 1px solid #c2d1da;
  margin: 0.125rem;
  color: ${cuideoTheme.palette.primary.light};
`;

interface IFormationsTableProps {
  Formaciones: any;
  onChange: (data: any) => void;
}

const FormationsTable = (props: IFormationsTableProps) => {
  const { Formaciones, onChange } = props;
  const { formatMessage } = useIntl();

  const [formaciones, setFormaciones] = useState([] as any);
  const [state, setState] = useState({
    open: false,
    mode: "add",
  });

  const [formInitialValues, setFormInitialValues] = useState({
    id: "",
    Nombre_del_curso: "",
    Nombre_escuela_centro: "",
    Titulo_valido_en_Espa_a: "",
  });
  const [deleteId, setDeleteId] = useState<string | null>(null);

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  useEffect(() => {
    if (Formaciones) {
      setFormaciones(Formaciones);
    } else {
      setFormaciones([]);
    }
  }, [Formaciones]);

  const handleClickAdd = () => {
    setFormInitialValues({
      id: uuidv4(),
      Nombre_del_curso: "",
      Nombre_escuela_centro: "",
      Titulo_valido_en_Espa_a: "",
    });
    setState({
      open: true,
      mode: "add",
    });
  };

  const handleDelete = (id: string) => {
    setDeleteId(id);
  };

  const handleDeleteConfirm = (id: string) => {
    const newFormaciones = formaciones.filter((item: any) => item.id !== id);
    setFormaciones(newFormaciones);
    onChange(newFormaciones);
    setDeleteId(null);
  };

  const handleEdit = (formacion: any) => {
    setFormInitialValues({
      id: formacion.id,
      Nombre_del_curso: formacion.Nombre_del_curso,
      Nombre_escuela_centro: formacion.Nombre_escuela_centro,
      Titulo_valido_en_Espa_a: formacion.Titulo_valido_en_Espa_a.toLowerCase(),
    });
    setState({
      open: true,
      mode: "edit",
    });
  };

  const handleFormSubmit = (values: any, actions: any) => {
    if (state.mode === "add") {
      setFormaciones([...formaciones, { ...values }]);
      onChange([...formaciones, { ...values }]);
    } else {
      // Edit mode
      const newFormaciones = [...formaciones];
      const pos = newFormaciones.map((i: any) => i.id).indexOf(values.id);
      newFormaciones[pos] = { ...values };
      setFormaciones(newFormaciones);
      onChange(newFormaciones);
    }
    handleClose();
  };

  const validationSchema = Yup.object({
    id: Yup.string().required(),
    Nombre_del_curso: Yup.string().required(
      formatMessage({
        id: "FormationsTable.RequiredName",
        defaultMessage: "Debes introducir el nombre del curso",
      })
    ),
    Nombre_escuela_centro: Yup.string().required(
      formatMessage({
        id: "FormationsTable.RequiredSchool",
        defaultMessage: "Debes introducir el nombre de la escuela",
      })
    ),
  });

  return (
    <Fragment>
      <Grid container spacing={4}>
        <Grid item style={{ flexGrow: 1 }}>
          <StyledSectionHeader variant="h4">
            <FormattedMessage
              id="FormationsTable.Formations"
              defaultMessage="Formaciones"
            />{" "}
            <VisibleFieldOnProfile />
          </StyledSectionHeader>
        </Grid>
        <Grid item>
          <Fab
            variant="extended"
            size="small"
            color="primary"
            onClick={handleClickAdd}
          >
            <AddIcon />
          </Fab>
        </Grid>
      </Grid>
      <StyledTable>
        <TableHead>
          <TableRow>
            <StyledHeadCell>
              <FormattedMessage
                id="FormationsTable.CourseName"
                defaultMessage="Nombre del curso"
              />
            </StyledHeadCell>
            <StyledHeadCell>
              <FormattedMessage
                id="FormationsTable.SchoolName"
                defaultMessage="Nombre de la escuela"
              />
            </StyledHeadCell>
            <StyledHeadCell>
              <FormattedMessage
                id="FormationsTable.CountryValid"
                defaultMessage="Válido en España"
              />
            </StyledHeadCell>
            <StyledHeadCell>
              <FormattedMessage
                id="FormationsTable.Actions"
                defaultMessage="Acciones"
              />
            </StyledHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formaciones && formaciones.length > 0 ? (
            formaciones.map((item: any) => {
              return (
                <StyledBodyRow key={item.id}>
                  <StyledTableBodyCell>
                    {item.Nombre_del_curso}
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    {item.Nombre_escuela_centro}
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    {item.Titulo_valido_en_Espa_a}
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    <Tooltip title="Editar">
                      <StyledIconButton
                        aria-label="editar"
                        color="primary"
                        onClick={() => {
                          handleEdit(item);
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </StyledIconButton>
                    </Tooltip>
                    <Tooltip title="Eliminar">
                      <StyledIconButton
                        aria-label="eliminar"
                        color="primary"
                        onClick={() => {
                          handleDelete(item.id);
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </StyledIconButton>
                    </Tooltip>
                  </StyledTableBodyCell>
                </StyledBodyRow>
              );
            })
          ) : (
            <StyledBodyRow>
              <TableCell colSpan={4}>
                <StyledEmptyMessage>
                  <FormattedMessage
                    id="FormationsTable.NoPrevious"
                    defaultMessage="No hay recogidas formaciones previas"
                  />
                </StyledEmptyMessage>
              </TableCell>
            </StyledBodyRow>
          )}
        </TableBody>
      </StyledTable>
      <Dialog open={state.open} onClose={handleClose}>
        <DialogTitle>
          {state.mode === "add" ? (
            <FormattedMessage
              id="FormationsTable.Add"
              defaultMessage="Añade formaciones"
            />
          ) : (
            <FormattedMessage
              id="FormationsTable.Edit"
              defaultMessage="Modifica formación"
            />
          )}
        </DialogTitle>
        <Formik
          initialValues={formInitialValues}
          validationSchema={validationSchema}
          onSubmit={(values: any, actions: any) => {
            handleFormSubmit(values, actions);
          }}
        >
          {(props) => (
            <Fragment>
              <form onSubmit={props.handleSubmit}>
                <DialogContent>
                  <StyledFormWrapper>
                    <Grid container spacing={8}>
                      <Grid item xs={12}>
                        <FormikTextField
                          name="Nombre_del_curso"
                          variant="outlined"
                          fullWidth
                          required
                          margin="normal"
                          label={
                            <FormattedMessage
                              id="FormationsTable.CourseName"
                              defaultMessage="Nombre del curso"
                            />
                          }
                        />
                        <FormikTextField
                          name="Nombre_escuela_centro"
                          variant="outlined"
                          fullWidth
                          required
                          margin="normal"
                          label={
                            <FormattedMessage
                              id="FormationsTable.SchoolName"
                              defaultMessage="Nombre de la escuela"
                            />
                          }
                        />
                        <FormikRadioGroup
                          name="Titulo_valido_en_Espa_a"
                          fullWidth
                          label={
                            <FormattedMessage
                              id="FormationsTable.CountryValid"
                              defaultMessage="Válido en España"
                            />
                          }
                          size="small"
                          variant="compact"
                          options={[
                            {
                              label: <FormattedMessage id="Si" />,
                              value: "si",
                              icon: SiIcon,
                              checkedIcon: SiIcon,
                            },
                            {
                              label: <FormattedMessage id="No" />,
                              value: "no",
                              icon: NoIcon,
                              checkedIcon: NoIcon,
                            },
                          ]}
                        />
                      </Grid>
                    </Grid>
                  </StyledFormWrapper>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    <FormattedMessage
                      id="FormationsTable.Cancel"
                      defaultMessage="Cancelar"
                    />
                  </Button>
                  <Button color="primary" variant="contained" type="submit">
                    <FormattedMessage
                      id="FormationsTable.Save"
                      defaultMessage="Guardar"
                    />
                  </Button>
                </DialogActions>
              </form>
            </Fragment>
          )}
        </Formik>
      </Dialog>
      <ConfirmDialog
        open={!!deleteId}
        title={
          <FormattedMessage
            id="FormationsTable.DeleteTitle"
            defaultMessage="Eliminar formación"
          />
        }
        label={
          <FormattedMessage
            id="FormationsTable.DeleteLabel"
            defaultMessage="¿Estás seguro que deseas eliminar la formación indicada?"
          />
        }
        onConfirm={() => {
          handleDeleteConfirm(deleteId ? deleteId : "");
        }}
        onCancel={() => {
          setDeleteId(null);
        }}
      />
    </Fragment>
  );
};

export default React.memo(
  (props: IFormationsTableProps) => {
    return <FormationsTable {...props} />;
  },
  (prevProps, nextProps) => {
    if (prevProps.Formaciones !== nextProps.Formaciones) {
      return false;
    }
    return true;
  }
);
