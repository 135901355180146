import { all } from "redux-saga/effects";
import authSagas from "./Auth";
import empleoSagas from "./Empleo";
import commonSagas from "./Common";
import candidatosSagas from "./Candidatos";
import actividadesSagas from "./Actividades";
import statisticsSagas from "./Statistics";
import valoracionesSagas from "./Valoraciones";
import usuariosSaga from "./Usuarios";
import clientEventSaga from "./ClientEvent";

export default function* rootSaga(getState: any) {
  yield all([
    authSagas(),
    empleoSagas(),
    commonSagas(),
    candidatosSagas(),
    actividadesSagas(),
    statisticsSagas(),
    valoracionesSagas(),
    usuariosSaga(),
    clientEventSaga(),
  ]);
}
