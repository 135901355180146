import { useMutation } from "react-query";
import {
  apiCreateMessage,
  TCreateMessageRequest,
} from "../../../../../api/message/apiCreateMessage";
import {
  createMessage,
  messageShouldHaveBottomMargin,
  TMessage,
} from "../models/Message";
import toast from "react-hot-toast";
import { useIntl } from "react-intl";
import { queryClient } from "../../../../../lib/react-query";

export type TAddMessagePayload = TCreateMessageRequest & {};

export default function useMessagesAddMutation(
  parentId: string,
  module: string,
  userId: string
) {
  const { formatMessage } = useIntl();

  const addMessage = async (payload: TAddMessagePayload) => {
    await apiCreateMessage({
      parentId: payload.parentId,
      module: payload.module,
      content: payload.content,
    });
  };

  return useMutation(addMessage, {
    onMutate: (payload) => {
      queryClient.cancelQueries(["messages"]);

      const prevQuery: { messages: TMessage[] } | undefined =
        queryClient.getQueryData([
          "messages",
          "list",
          module,
          parentId,
          userId,
        ]);

      if (!prevQuery) {
        return;
      }

      const newMessages: TMessage[] = [
        ...prevQuery.messages,
        {
          ...createMessage(payload.content, payload.parentId),
          createdBy: { id: userId, fullname: "" },
        },
      ];

      if (newMessages.length > 1) {
        newMessages[newMessages.length - 2].marginBottom =
          messageShouldHaveBottomMargin(
            newMessages[newMessages.length - 2],
            newMessages[newMessages.length - 1]
          );
      }

      queryClient.setQueryData(["messages", "list", module, parentId, userId], {
        ...prevQuery,
        messages: newMessages,
      });

      return {
        prevQuery,
      };
    },
    onError: (err, payload, context: any) => {
      queryClient.setQueryData(
        ["messages", "list", module, parentId, userId],
        context.prevQuery
      );
    },
    onSettled: (data, error, payload) => {
      queryClient.invalidateQueries(["messages"]);

      if (error) {
        toast.error(
          formatMessage({
            id: "ConversationMessage.Ha habido un error y no se ha podido enviar el mensaje",
            defaultMessage:
              "Ha habido un error y no se ha podido enviar el mensaje",
          })
        );
      }
    },
  });
}
