import { FormControl, FormControlLabel } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import React from "react";
import styled from "styled-components";
import cuideoTheme from "../../containers/themes/defaultTheme";

interface CuideoCheckboxProps {
  label: any;
  checked?: any;
  onChange?: any;
  opaque?: boolean;
}

const StyledFormControlLabel = styled(FormControlLabel)`
  color: #004b7b;
  line-height: normal;
  align-items: center;

  .MuiFormControlLabel-label {
    font-size: 0.8rem;
    line-height: 1.35;
  }
`;

const StyledFormControl = styled(FormControl)`
  margin: 0.5rem 0;
`;

const StyledCheckBoxOutlineBlankIcon = styled(CheckBoxOutlineBlankIcon)`
  color: #004b7b;
`;

const StyledIconWrapper = styled.div<{ opaque?: boolean }>`
  position: relative;
  height: 1.5rem;
  width: 1.5rem;
  ${({ opaque }) => {
    if (opaque) {
      return `opacity: 1`;
    }
    return `opacity: 0.4`;
  }}

  &:after {
    background-color: ${cuideoTheme.palette.common.white};
    margin: 0;
    padding: 0;
    content: " ";
    position: absolute;
    width: 1rem;
    height: 1rem;
    top: 0.3rem;
    left: 0.25rem;
    z-index: -1;
  }
`;

const CuideoCheckbox = (props: CuideoCheckboxProps) => {
  const { opaque } = props;
  return (
    <StyledFormControl fullWidth>
      <StyledFormControlLabel
        control={
          <Checkbox
            icon={
              <StyledIconWrapper opaque={opaque}>
                <StyledCheckBoxOutlineBlankIcon fontSize="small" />
              </StyledIconWrapper>
            }
            checkedIcon={
              <StyledIconWrapper opaque={opaque}>
                <CheckBoxIcon fontSize="small" />
              </StyledIconWrapper>
            }
            color="primary"
            checked={props.checked}
            onChange={props.onChange}
          />
        }
        label={props.label}
      />
    </StyledFormControl>
  );
};

export default CuideoCheckbox;
