import { apiEmpleo } from "../../util/ApiEmpleo";
import { CONTRACT_STATE, TContractNumPayments, TContractOvernightHours, TContractOvernightNights, TContractPresenceHours, TContractSalaryType, TContractState, TContractType } from "./contract.types";

export type TUpdateContractToSelectedStatePayload = {
  Estado: TContractState;
  Tipo_de_contrato: TContractType;
  Horario_Servicio: string;
  Horas_jornada_semanal: number;
  Horas_de_pernocta: TContractOvernightHours;
  Noches_de_pernocta: TContractOvernightNights;
  Horas_de_presencia: TContractPresenceHours;
  N_de_horas_de_presencia_a_la_semana1: number;
  Fecha_de_inicio_real: string;
  Per_odo_de_prueba: string;
  Tipo_de_salario: TContractSalaryType;
  Numero_de_pagas: TContractNumPayments;
  Salario_Bruto_Mensual: number;
  Cl_usulas_especiales: string;
};

const apiUpdateContractToSelectedState = async (
  contractId: string,
  payload: TUpdateContractToSelectedStatePayload
) => {
  const payloadWithState = {
    ...payload,
    Estado: CONTRACT_STATE.SELECTED
  }
  const res = await apiEmpleo.patch(`contratos/${contractId}`, payloadWithState, {
    headers: {
      "Content-Type": "application/merge-patch+json",
    },
  });

  return res;
};

export { apiUpdateContractToSelectedState };
