import { Grow, IconButton, Paper, Typography } from "@material-ui/core";
import React, { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import ZohoIcon from "../../assets/images/iconos/zoho.png";
import cuideoTheme from "../../containers/themes/defaultTheme";

const StyledContratoName = styled(Typography)`
  color: #f64a69;
  font-size: 0.75rem;
  text-align: right;
`;

const StyledPaper = styled(Paper)`
  padding: 1rem;
  position: absolute;
  z-index: 1;
  min-width: 15rem;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledZohoButton = styled(IconButton)`
  width: 1rem;
  height: 1rem;
  padding: 0.25rem;
  margin-bottom: 1.125rem;
`;

const StyledZohoIcon = styled.img``;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledLabel = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-size: 0.875rem;
  opacity: 0.5;
  min-width: 8rem;
`;

const StyledData = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-size: 0.875rem;
`;

const StyledTitle = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 0.75rem;
`;

const StyledWrapper = styled.div`
  position: relative;
`;

const ContratoTooltip = (props: any) => {
  const { contrato } = props;
  const timerRef: React.MutableRefObject<ReturnType<typeof setTimeout> | null> =
    useRef<ReturnType<typeof setTimeout>>(null);
  const [open, setOpen] = useState(false);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    timerRef.current = setTimeout(() => {
      setOpen(true);
    }, 500);
  };

  const handlePopoverClose = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    if (open) {
      setOpen(false);
    }
  };

  return (
    <StyledWrapper
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <StyledContratoName className="MuiCuideo-ContratoTooltip-label">
        {contrato.Name}
      </StyledContratoName>
      <Grow in={open}>
        <StyledPaper>
          <StyledHeader>
            <StyledTitle>{contrato.Name}</StyledTitle>
            <StyledZohoButton
              color="primary"
              onClick={() => {
                window.open(
                  `https://crm.zoho.com/crm/org638326163/tab/CustomModule4/${contrato.zohoId}`
                );
              }}
            >
              <StyledZohoIcon src={ZohoIcon} />
            </StyledZohoButton>
          </StyledHeader>
          <StyledRow>
            <StyledLabel>
              <FormattedMessage
                id="ContractTooltip.Customer"
                defaultMessage="Cliente"
              />
            </StyledLabel>
            <StyledData>{contrato._Nombre_del_cliente}</StyledData>
          </StyledRow>
          <StyledRow>
            <StyledLabel>
              <FormattedMessage
                id="ContractTooltip.CustomerPhone"
                defaultMessage="Tlf. Cliente"
              />
            </StyledLabel>
            <StyledData>{contrato._Tel_fono_cliente}</StyledData>
          </StyledRow>
          {contrato.customer && contrato.customer.Email && (
            <StyledRow>
              <StyledLabel>
                <FormattedMessage
                  id="ContractTooltip.CustomerEmail"
                  defaultMessage="Email Cliente"
                />
              </StyledLabel>
              <StyledData>{contrato.customer.Email}</StyledData>
            </StyledRow>
          )}
          <StyledRow>
            <StyledLabel>
              <FormattedMessage
                id="ContractTooltip.Status"
                defaultMessage="Estado"
              />
            </StyledLabel>
            <StyledData>{contrato.Estado}</StyledData>
          </StyledRow>
          <StyledRow>
            <StyledLabel>
              <FormattedMessage
                id="ContractTooltip.Owner"
                defaultMessage="Contrato Owner"
              />
            </StyledLabel>
            <StyledData>{contrato.ownerName}</StyledData>
          </StyledRow>
          {contrato.Servicio && contrato.Servicio.Name && (
            <StyledRow>
              <StyledLabel>
                <FormattedMessage
                  id="ContractTooltip.Service"
                  defaultMessage="Servicio"
                />
              </StyledLabel>
              <StyledData>{contrato.Servicio.Name}</StyledData>
            </StyledRow>
          )}
        </StyledPaper>
      </Grow>
    </StyledWrapper>
  );
};

export default ContratoTooltip;
