import { IconButton, OutlinedInput } from "@material-ui/core";
import React, { useState } from "react";
import SendIcon from "@material-ui/icons/Send";
import { useIntl } from "react-intl";
import styled from "styled-components";

const StyledInputContainer = styled.div`
  padding: 0.375rem 1rem 0.375rem 1rem;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledInputWrapper = styled.div`
  flex: 1 1 100%;
`;

const StyledSendButton = styled(IconButton)`
  margin-left: 0.5rem;
`;

const StyledOutlinedInput = styled(OutlinedInput)`
  height: 2.75rem;
  background-color: #fff;
`;

interface IInputBoxProps {
  disabled: boolean;
  onSendMessage: (message: string) => void;
}

const InputBox: React.FC<IInputBoxProps> = (props) => {
  const { disabled, onSendMessage } = props;
  const { formatMessage } = useIntl();

  const [state, setState] = useState({
    content: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      content: e.target.value,
    });
  };

  const submitForm = () => {
    if (state.content !== "") {
      onSendMessage(state.content);
      setState({ content: "" });
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    submitForm();
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      if (!e.ctrlKey) {
        e.preventDefault();
        submitForm();
      }
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit}>
      <StyledInputContainer className="CMuiInputContainer">
        <StyledInputWrapper>
          <StyledOutlinedInput
            fullWidth
            placeholder={formatMessage({
              id: "InputMessage.Escribe un mensaje aquí",
              defaultMessage: "Escribe un mensaje aquí",
            })}
            value={state.content}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            disabled={disabled}
          />
        </StyledInputWrapper>
        <StyledSendButton
          type="submit"
          color="primary"
          disabled={disabled}
          title={formatMessage({
            id: "InputBox.Enviar mensaje",
            defaultMessage: "Enviar mensaje",
          })}
        >
          <SendIcon />
        </StyledSendButton>
      </StyledInputContainer>
    </form>
  );
};

export default InputBox;
