import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import provincias from "../defaults/provincias";

export type TAddress = {
  routeName: string;
  streetNumber: string;
  province: string;
  city: string;
  postalCode: string;
  lat: null | number;
  lng: null | number;
};

export const geocodeAddressToObject = async (
  address: string
): Promise<TAddress> => {
  const results = await geocodeByAddress(address);

  const newAddress: TAddress = {
    routeName: "",
    streetNumber: "",
    province: "",
    city: "",
    postalCode: "",
    lat: null,
    lng: null,
  };
  const result = results[0];
  result.address_components.forEach((item) => {
    if (item.types.includes("route")) {
      newAddress.routeName = item.long_name;
    }
    if (item.types.includes("street_number")) {
      newAddress.streetNumber = item.long_name;
    }
    if (item.types.includes("administrative_area_level_2")) {
      newAddress.province = item.long_name;
    }
    if (item.types.includes("locality")) {
      newAddress.city = item.long_name;
    }
    if (item.types.includes("postal_code")) {
      newAddress.postalCode = item.long_name;
      if (newAddress.postalCode.length === 5) {
        const code = newAddress.postalCode.substring(0, 2);
        const prov = provincias.find((item) => {
          return item.code === code && item.businessCountry === "es";
        });
        if (prov) {
          newAddress.province = prov.value;
        }
      }
    }
  });

  const latLngResult = await getLatLng(result);
  if (latLngResult && latLngResult.lat && latLngResult.lng) {
    newAddress.lat = latLngResult.lat;
    newAddress.lng = latLngResult.lng;
  }

  return newAddress;
};
