import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { TAuthenticatedUser } from "../../../../../../../reducers/Auth";
import {
  CONTRATO_ESTADO_ALTA_PENDIENTE,
  CONTRATO_ESTADO_CV_CLIENTE,
  CONTRATO_ESTADO_PRESENCIAL_CLIENTE,
} from "../../../../../../../util/defaults/contrato";
import {
  updateURLParams,
  urlParamsToObject,
} from "../../../../../../../util/urlManager";
import {
  userIsPromoter,
  userIsRecruiter,
} from "../../../../../models/Staff/Staff";

export type TContractsPresetFilterValue =
  | "recruit"
  | "promote"
  | "active"
  | null;

export const initialState = {
  search: "",
  preset: null as TContractsPresetFilterValue,
  origen: [] as string[],
  owner: [] as string[] | "",
  "service.owner": [] as string[] | "",
  publicar: "" as "" | "Si" | "No" | "null",
  tipoDeTrato: [] as string[],
  ciudad: "",
  botLaunched: "",
  estado: [
    CONTRATO_ESTADO_ALTA_PENDIENTE,
    CONTRATO_ESTADO_CV_CLIENTE,
    CONTRATO_ESTADO_PRESENCIAL_CLIENTE,
  ],
  provinciaOferta: "",
  orden: "cvDate" as "cvDate" | "priority" | "newest" | "oldest",
  page: 1,
  ready: false,
};

export const resetState = {
  origen: [] as string[],
  preset: null as TContractsPresetFilterValue,
  owner: [] as string[] | "",
  "service.owner": [] as string[] | "",
  publicar: "" as "" | "Si" | "No" | "null",
  tipoDeTrato: [] as string[],
  ciudad: "",
  botLaunched: "",
  estado: [
    CONTRATO_ESTADO_ALTA_PENDIENTE,
    CONTRATO_ESTADO_CV_CLIENTE,
    CONTRATO_ESTADO_PRESENCIAL_CLIENTE,
  ],
  provinciaOferta: "",
};

export type TContractsPageFilters = typeof initialState;

export default function useContractsPageURLParams(
  history: any,
  authUser: TAuthenticatedUser
) {
  const [filters, setFiltersState] = useState<TContractsPageFilters>({
    ...initialState,
    preset: userIsPromoter(authUser)
      ? "promote"
      : userIsRecruiter(authUser)
      ? "recruit"
      : null,
  });

  useEffect(() => {
    if (history.action !== "REPLACE") {
      const params = history.location.search
        ? urlParamsToObject(history.location.search)
        : {};

      if (params.page && typeof params.page !== "number") {
        params.page = parseInt(params.page);
      }

      setFiltersState((prevFilters) => {
        const newState = {
          ...prevFilters,
        };

        if (!userIsPromoter(authUser) && !userIsRecruiter(authUser)) {
          newState.owner =
            authUser.numOwnedContracts && authUser.numOwnedContracts > 0
              ? [authUser.userId]
              : "";
        } else if (userIsRecruiter(authUser)) {
          newState.owner =
            authUser.numOwnedContracts && authUser.numOwnedContracts > 0
              ? [authUser.userId]
              : "";
        } else if (userIsPromoter(authUser)) {
          newState["service.owner"] = [authUser.userId];
        }

        if (!_.some([prevFilters], params)) {
          return {
            ...newState,
            ...params,
            ready: true,
          };
        } else {
          return {
            ...newState,
            ready: true,
          };
        }
      });
    }
    // eslint-disable-next-line
  }, [history, setFiltersState]);

  const setFilters = useCallback(
    (newFilters: TContractsPageFilters) => {
      const params = {
        ...newFilters,
      } as any;
      setFiltersState(newFilters);

      // Parse a bit wich parameters to set on url
      delete params.ready;

      // Clean not needed parameters
      Object.keys(initialState).forEach((key) => {
        if (Array.isArray((initialState as any)[key])) {
          if (
            _.isEqual(
              _.sortBy((initialState as any)[key]),
              _.sortBy(params[key])
            )
          ) {
            delete params[key];
          }
        } else {
          if (params[key] === (initialState as any)[key]) {
            delete params[key];
          }
        }
      });

      updateURLParams(params);
    },
    [setFiltersState]
  );

  return { filters, setFilters };
}
