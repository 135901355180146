import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import cuideoTheme from "../../containers/themes/defaultTheme";

const StyledExpansionPanel = styled(Accordion)`
  background-color: transparent;
  border: 1px solid ${cuideoTheme.palette.primary.main};
  border-radius: ${cuideoTheme.shape.borderRadius}px;
`;

const StyledExpansionPanelSummary = styled(AccordionSummary)`
  min-height: 3rem;
  &.Mui-expanded {
    min-height: 3rem;
  }
  .MuiAccordionSummary-content {
    margin: 0.5rem 0;
  }
`;

const StyledSummaryTypography = styled(Typography)`
  text-transform: uppercase;
  font-size: 0.625rem;
`;

const StyledTypography = styled(Typography)`
  font-size: 0.625rem;
  opacity: 0.75;
  line-height: 1.2;
`;

const LeerInfoLegal = () => {
  return (
    <StyledExpansionPanel elevation={0}>
      <StyledExpansionPanelSummary
        expandIcon={<ExpandMoreIcon color="primary" />}
      >
        <StyledSummaryTypography color="primary">
          <FormattedMessage
            id="Legal.Title"
            defaultMessage="Información Básica sobre Protección de Datos"
          />
        </StyledSummaryTypography>
      </StyledExpansionPanelSummary>
      <AccordionDetails>
        <StyledTypography color="primary">
          <FormattedMessage
            id="Legal.Line1"
            defaultMessage="En cumplimiento de lo establecido en el Reglamento General de Protección de Datos de la Unión Europea, les facilitamos la siguiente información básica sobre protección de datos:"
          />
          <br />
          <FormattedMessage
            id="Legal.Line2"
            defaultMessage="Responsable: CUIDA CARE MARKETING, S.L. (CUIDEO)"
          />
          <br />
          <FormattedMessage
            id="Legal.Line3"
            defaultMessage="Finalidad: Selección de Personal."
          />
          <br />
          <FormattedMessage
            id="Legal.Line5"
            defaultMessage="Categorías de datos: Se tratan categorías especiales de datos como el origen étnico o racial."
          />
          <br />
          <FormattedMessage
            id="Legal.Line6"
            defaultMessage="Derechos: Acceder, rectificar y suprimir los datos, así como otros derechos, como se explica en la información adicional que se puede consultar en "
          />
          <a
            href="https://cuideo.com/politica-de-privacidad/"
            target="_blank"
            rel="noreferrer"
          >
            https://cuideo.com/politica-de-privacidad/
          </a>
        </StyledTypography>
      </AccordionDetails>
    </StyledExpansionPanel>
  );
};

export default LeerInfoLegal;
