import { Box, Button, Card, Grid, Typography } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import InfoIcon from "@material-ui/icons/Info";
import Alert from "@material-ui/lab/Alert";
import { DatePicker } from "@material-ui/pickers";
import styled from "styled-components";
import cuideoTheme, {
  COLOR_RED_NOTIFICATION,
  COLOR_WARNING_ALERT_FG,
} from "../../../../../../containers/themes/defaultTheme";

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  box-shadow: 0 2px 8px 0 rgba(0, 75, 123, 0.2);
  height: 16.75rem;
  border: 1px solid #fff;
  overflow: visible;

  &:hover {
    border: 1px solid ${cuideoTheme.palette.primary.main};
    cursor: pointer;
  }
`;

export const StyledNuevoServicio = styled.div`
  width: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(235, 194, 0, 0.2);
  border-radius: 0.5rem 0 0 0.5rem;
  margin: -0.125rem;
`;

export const StyledReplacementContract = styled.div`
  width: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 75, 123, 0.2);
  border-radius: 0.4rem 0 0 0.4rem;
  border-right: 1px solid rgba(0, 75, 123, 0.2);
  margin: -0.125rem;
`;

export const StyledContratoFakeFV = styled.div`
  width: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(65, 106, 251, 0.66);
  border-radius: 0.5rem 0 0 0.5rem;
  margin: -0.125rem;
`;

export const StyledContratoFakeFVText = styled(Typography)`
  transform: rotate(-180deg);
  font-size: 0.75rem;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: 0.2rem;
  writing-mode: tb-rl;
`;

export const StyledServicioExistente = styled.div`
  width: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem 0 0 0.5rem;
  margin: -0.125rem;
`;

export const StyledNuevoServicioText = styled(Typography)`
  transform: rotate(-180deg);
  font-size: 0.75rem;
  text-transform: uppercase;
  color: #f5a623;
  font-weight: 600;
  letter-spacing: 0.2rem;
  writing-mode: tb-rl;
`;

export const StyledReplacementContractText = styled(Typography)`
  transform: rotate(-180deg);
  font-size: 0.75rem;
  text-transform: uppercase;
  color: #004b7b;
  font-weight: 600;
  letter-spacing: 0.2rem;
  writing-mode: tb-rl;
`;

export const StyledContratoInfo = styled.div`
  padding: 0.5rem;
  width: 40%;
  position: relative;
  border-right: 1px solid ${cuideoTheme.palette.divider};
`;

export const StyledContratoValoraciones = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
`;

export const StyledFirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 1.5rem;
  margin-bottom: 0.25rem;
`;

export const StyledSecondRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const StyledThirdRow = styled.div`
  height: 8.5rem;
`;

export const StyledBottomInfoLine = styled.div`
  position: absolute;
  bottom: 0.125rem;
  left: 0.375rem;
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
`;

export const StyledPublishInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledActions = styled.div`
  display: flex;
  flex-direction: row;

  .MuiCuideoContractPopupViewButton {
    width: 1.5rem;
    height: 1.5rem;

    .MuiSvgIcon-root {
      width: 1rem;
      height: 1rem;
    }
  }
`;

export const StyledContratoTitleDetails = styled.div``;

export const StyledContratoDetails = styled.div`
  min-width: 8rem;
`;

export const StyledContratoTitulo = styled(Typography)`
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.15;
  color: #002740;
  max-height: 3.75rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const StyledContratoEstatus = styled(Typography)`
  color: ${cuideoTheme.palette.primary.light};
  font-size: 0.75rem;
  text-align: right;
  line-height: 1.25;
`;

export const StyledItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0.75rem 0;
`;

export const StyledIcon = styled.img`
  display: block;
  height: 1rem;
  width: 1rem;
  margin-right: 0.5rem;
  margin-top: 0.0625rem;
`;

export const StyledItemDetail = styled(Typography)`
  margin-top: 0;
  font-size: 0.75rem;
  line-height: 1.25;
  color: ${cuideoTheme.palette.primary.main};
`;
export const StyledItemMoneyDetail = styled(Typography)`
  margin-top: 0;
  font-size: 1rem;
  line-height: 1.25;
  color: ${cuideoTheme.palette.primary.main};
`;

export const StyledItemBuscamosDetail = styled.div`
  flex-grow: 1;
  margin: 0;
  .MuiTypography-root {
    font-size: 0.75rem;
    line-height: 1.25;
    color: ${cuideoTheme.palette.primary.main};
  }
`;

export const StyledCreated = styled(Typography)`
  font-size: 0.6875rem;
  line-height: 1;
  margin: 0 0 0 0.5rem;
  padding: 0 0 0 0.5rem;
  color: ${cuideoTheme.palette.primary.main};
  opacity: 0.5;
  border-left: 1px solid ${cuideoTheme.palette.primary.main};
`;

export const StyledActionLink = styled.a`
  display: block;
  text-decoration: none;
  width: 1rem;
  height: 1rem;
  margin-left: 0.25rem;
  padding: 0.25rem;

  &:hover {
    background-color: rgba(0, 72, 120, 0.25);
  }
`;

export const StyledActionButton = styled(Button)`
  text-decoration: none;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.25rem;
  padding: 0.25rem;
  min-width: unset;
`;

export const StyledActionIcon = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  max-width: 1rem;
  max-height: 1rem;
`;

export const StyledValoracionesInfo = styled.div`
  padding: 0.5rem 0.25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StyledValoracionesButtons = styled.div`
  display: flex;
  flex-direction: row;

  .CMuiItemWrapper {
    margin-right: 0.5rem;
  }
`;

export const StyledValoracionesInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  &:first-child {
    border-right: 1px solid ${cuideoTheme.palette.divider};
  }
`;

export const StyledValoracionesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  flex-grow: 1;
`;

export const StyledValoracionesItem = styled.div`
  background-color: #cdd5d8;
  margin: 0.125rem;
  flex-grow: 1;
  width: 16.6666%;
  text-align: center;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid transparent;
  position: relative;
  color: ${cuideoTheme.palette.primary.main};

  &.CMuiValuationsItem-warning {
    background-color: #e7e378;
    color: ${COLOR_WARNING_ALERT_FG};
  }

  &.CMuiValuationsItem-error {
    background-color: #d3a7a8;
    color: ${COLOR_RED_NOTIFICATION};
  }

  &:hover {
    border: 1px solid ${cuideoTheme.palette.primary.main};
  }
`;

export const StyledValuationsItemTop = styled.div`
  flex: 1 1 auto;
  height: calc(50% - 1.125rem);

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const StyledValuationsItemCenter = styled.div`
  height: 3.25rem;
`;

export const StyledValuationsItemBottom = styled.div`
  flex: 1 1 auto;
  height: calc(50% - 2.125rem);

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const StyledValoracionesItemNew = styled(Typography)`
  color: ${cuideoTheme.palette.common.white};
  background-color: #ebc200;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1.375rem;
  padding: 0.125rem;
  position: absolute;
  top: 0.875rem;
  left: calc(50% - 1rem);
  height: 1.375rem;
  width: 1.375rem;
  border-radius: 50%;
`;

export const StyledValoracionesItemLabel = styled(Typography)`
  color: inherit;
  font-size: 1rem;
  line-height: 1.15;
  padding: 0 0.25rem;
`;

export const StyledValoracionesItemData = styled(Typography)`
  color: inherit;
  font-size: 2.25rem;
  font-weight: 900;
`;

export const StyledValotacionsItemSubDetail = styled(Box)`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0.125rem 0;
`;

export const StyledCheckedIcon = styled(CheckCircleIcon)`
  width: 1.25rem;
  height: 1.25rem;
`;

export const StyledCancelIcon = styled(CancelIcon)`
  width: 1.25rem;
  height: 1.25rem;
`;

export const StyledInfoIcon = styled(InfoIcon)`
  width: 1.25rem;
  height: 1.25rem;
`;

export const StyledValoracionButton = styled(Button)`
  text-transform: none;
  margin-right: 0.5rem;
  font-weight: 400;
  font-size: 1rem;
`;

export const StyledButtonIcon = styled.img`
  margin-right: 0.5rem;
  width: 1rem;
  height: 1rem;
`;

export const StyledTriItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0.5rem;
  margin: -0.125rem 0 -0.125rem;

  &.CMui-warning {
    background-color: rgba(235, 194, 0, 0.2);
  }
`;

export const StyledTriIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  margin-right: 0.5rem;
`;

export const StyledTriDataDatesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0.25rem;
`;

export const StyledTriDataWrapper = styled.div``;

export const StyledTriLabel = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  opacity: 0.5;
  font-size: 0.75rem;
  font-weight: 600;
  margin: 0;
  line-height: 1.15;
`;

export const StyledTriOwnerLabel = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  opacity: 0.5;
  font-size: 0.75rem;
  font-weight: 600;
  margin: 0 0 -0.125rem 0;
  line-height: 1;
  padding: 0 0.375rem;
`;

export const StyledTriOwnerData = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  line-height: 1.1;
  padding: 0 0.375rem;

  max-width: 10rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledDatesWrapper = styled.div`
  border: 1px solid rgba(0, 72, 120, 0.5);
  border-radius: 0.25rem;
  display: flex;
  margin-right: 0.375rem;
`;

export const StyledTriDateDataWrapper = styled.div`
  margin: 0 0.375rem;
`;

export const StyledTriDateLabel = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  opacity: 0.5;
  font-size: 0.75rem;
  font-weight: 600;
  margin: 0 0 0.125rem 0;
  line-height: 1;
`;

export const StyledTriDateData = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-size: 1rem;
  margin: 0;
  line-height: 1.1;

  max-width: 10rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.CMui-warning {
    color: ${COLOR_RED_NOTIFICATION};
  }
`;

export const StyledTriDataDate = styled(Box)`
  color: ${cuideoTheme.palette.primary.main};
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  line-height: 1.15;

  &.CMui-warning {
    .MuiInputBase-input {
      color: ${COLOR_RED_NOTIFICATION};
    }
  }
`;

export const StyledCVWarning = styled.div`
  background-color: rgba(248, 231, 28, 0.2);
  font-size: 0.75rem;
  line-height: normal;
  padding: 0.25rem;
  display: flex;
  flex-direction: row;
  border-radius: ${cuideoTheme.shape.borderRadius}px;
  align-items: center;
`;

export const StyledCVWarningIcon = styled.img`
  margin-right: 0.25rem;
  width: 1.25rem;
  height: 1.25rem;
  display: block;
`;

export const StyledCVWarningText = styled(Typography)`
  color: #f5a623;
  font-size: 0.75rem;
  line-height: normal;
`;

export const StyledClickablePhoneNumber = styled.a`
  text-decoration: none;
  font-weight: 600;
  color: ${cuideoTheme.palette.primary.main};
  padding: 0 0.25rem;
  border-radius: ${cuideoTheme.shape.borderRadius}px;

  &:hover {
    background-color: rgba(0, 72, 120, 0.25);
  }
`;

export const StyledContractName = styled(Typography)`
  color: ${cuideoTheme.palette.common.black};
  font-weight: bold;
  font-size: 0.9375rem;
  text-align: right;
  line-height: 1.25;
`;

export const StyledLocationAlert = styled(Alert)`
  padding: 0.25rem;
  background-color: rgba(248, 231, 28, 0.2);
  color: #f5a623;
  .MuiAlert-icon {
    padding: 0;
    margin-right: 0.25rem;
  }
  .MuiAlert-message {
    padding: 0;
    font-size: 0.75rem;
    line-height: 1;
  }
`;

export const StyledDatePicker = styled(DatePicker)`
  font-weight: 400;

  .MuiInputBase-root {
    height: 1.25rem;
    width: 5.25rem;
    background-color: rgba(0, 72, 120, 0.1);
    padding: 0 0.25rem;

    &:hover,
    &:focus {
      background-color: rgba(0, 72, 120, 0.25);
    }
  }
  .MuiInputBase-input {
    color: ${cuideoTheme.palette.primary.main};
    cursor: pointer;
    padding: 0;
    height: 1.25rem;
  }
  .MuiInput-underline:before,
  .MuiInput-underline:hover:before {
    border: none;
  }
  .MuiInput-underline:after {
    border: none;
  }
`;

export const StyledFullHeightGrid = styled(Grid)`
  height: 100%;
`;

export const StyledCardDemoteActions = styled.div`
  position: absolute;
  bottom: 0.125rem;
  right: 0;
  display: flex;
  flex-direction: row;
`;
