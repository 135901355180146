import {
  GET_USUARIOS,
  GET_USUARIOS_SUCCESS,
  GET_USUARIO,
  GET_USUARIO_SUCCESS,
  UPDATE_USUARIOS_TABLE_STATE,
  UPDATE_USUARIOS,
  GET_STAFF,
  GET_STAFF_SUCCESS
} from "../constants/ActionTypes";

export const getUsuarios = ({ params }: { params: any }) => {
  return {
    type: GET_USUARIOS,
    payload: params
  };
};

export const getUsuariosSuccess = ({
  partialUsuarios,
  numUsuarios
}: {
  partialUsuarios: any[];
  numUsuarios: number;
}) => {
  return {
    type: GET_USUARIOS_SUCCESS,
    payload: { partialUsuarios, numUsuarios }
  };
};

export const getStaff = ({ params }: { params: any }) => {
  return {
    type: GET_STAFF,
    payload: params
  };
};

export const getStaffSuccess = ({
  partialUsuarios
}: {
  partialUsuarios: any[];
}) => {
  return {
    type: GET_STAFF_SUCCESS,
    payload: { partialUsuarios }
  };
};

export const getUsuario = (usuarioId: string) => {
  return {
    type: GET_USUARIO,
    payload: usuarioId
  };
};

export const getUsuarioSuccess = ({
  partialUsuarios
}: {
  partialUsuarios: any[];
}) => {
  return {
    type: GET_USUARIO_SUCCESS,
    payload: { partialUsuarios }
  };
};

export const updateUsuariosTableState = (tableState: any) => {
  return {
    type: UPDATE_USUARIOS_TABLE_STATE,
    payload: tableState
  };
};

export const updateUsuarios = (partialUsuarios: any) => {
  return {
    type: UPDATE_USUARIOS,
    payload: partialUsuarios
  };
};
