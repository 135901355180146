import { useInfiniteQuery } from "react-query";
import {
  apiListNotifications,
  IListNotificationsParams,
} from "../../../../../api/notification/apiListNotifications";
import { InfiniteQueryConfig } from "../../../../../lib/react-query";
import { notificationKeys } from "./notification.query-keys";

type QueryFnType = typeof apiListNotifications;

type UseInfiniteNotificationsOptions = {
  config?: InfiniteQueryConfig<QueryFnType>;
  params: IListNotificationsParams;
};

export const useInfiniteNotifications = ({
  config,
  params,
}: UseInfiniteNotificationsOptions) => {
  // @ts-ignore
  return useInfiniteQuery({
    ...config,
    queryKey: notificationKeys.list(params),
    queryFn: (queryContext) =>
      apiListNotifications({
        ...params,
        page: queryContext.pageParam ?? 1,
        partial: queryContext.pageParam !== 1 ? 1 : 0,
      }),
  });
};
