import { store } from "../store";
import { reportClientEvent } from "../actions/ClientEvent";

const reportError = async (
  errorMsg: any,
  url: any,
  lineNumber: any,
  type = "client_js_error",
  context = {} as any
) => {
  const state = store.getState();
  if (state.auth && state.auth.authUser) {
    errorMsg += "\r\n - Username: " + state.auth.authUser.userName;
    errorMsg += "\r\n - User ID: " + state.auth.authUser.userId;
  }
  store.dispatch(
    reportClientEvent({
      type,
      clientEventSequenceNumber: 1,
      details: {
        errorMsg,
        url,
        lineNumber: window.location.href,
        ...context,
      },
    })
  );
};

const initializeErrorReporting = () => {
  if (!window.onerror) {
    window.onerror = function catchError(errorMsg, url, lineNumber) {
      reportError(errorMsg, url, lineNumber);
    };

    window.addEventListener("unhandledrejection", function (ev: any) {
      reportError(
        ev.reason.stack ? ev.reason.stack : ev.reason.message,
        ev.srcElement && ev.srcElement.origin
          ? ev.srcElement.origin
          : "undefined",
        window.location.href
      );
    });
  }
};

export { reportError, initializeErrorReporting };
