import { AxiosResponse } from "axios";
import { TApiPaginatedResponse } from "../api-corev3.types";
import { apiCoreV3BasicClient } from "../apiCoreV3BasicClient";
import { TSuggestionWorkerResponse } from "./suggestion-worker.types";

interface IApiListBotWorkersParams {
  page?: number;
  pageSize?: number;
  contractId?: string[];
  contractCrmId?: string[];
}

const apiListBotWorkers = async (params: IApiListBotWorkersParams) => {
  const res: AxiosResponse<TApiPaginatedResponse<TSuggestionWorkerResponse>> =
    await apiCoreV3BasicClient.get(`bot-workers`, { params });

  return res.data;
};

export { apiListBotWorkers };
