import {
  CHANGE_LAYOUT,
  WINDOW_WIDTH,
  LAYOUT_SET_MARGIN_TOP,
  LAYOUT_SET_PADDING_LEFT,
  LAYOUT_SET_BACKGROUND,
  USER_DRAWER_MENU_OPEN,
  USER_DRAWER_MENU_CLOSE,
  LAYOUT_CHANGE_HEADER_TYPE,
  SIGNOUT_USER,
  LAYOUT_CHANGE_FOOTER_TYPE
} from "../constants/ActionTypes";

export interface HeaderInterface {
  type: "normal" | "simple" | "hidden";
  showHelpLink?: boolean;
  title?: null | string;
  showBackLink?: boolean;
  backLinkUrl?: null | string;
  backEl?: null;
  backCallback?: null | Function;
  actionEl?: null;
}

export interface FooterInterface {
  type: "normal" | "hidden";
}

const initialLayout = {
  appPaddingLeft: 0,
  appMarginTop: 3.5,
  width: window.innerWidth,
  background: "cuideo",
  isUserDrawerMenuOpen: false,
  header: {
    type: process.env.REACT_APP_EMPLEO === "true" ? "normal" : "hidden",
    showHelpLink: true,
    title: null,
    showBackLink: false,
    backCallback: null,
    backLinkUrl: null,
    backEl: null,
    actionEl: null
  },
  footer: {
    type: process.env.REACT_APP_EMPLEO === "true" ? "normal" : "hidden"
  }
} as LayoutState;

interface LayoutState {
  appPaddingLeft: number;
  appMarginTop: number;
  background: "cuideo" | "white";
  header: HeaderInterface;
  footer: FooterInterface;
}

interface LayoutActionTypes {
  type: string;
  payload: any;
}

const layout = (state = initialLayout, action: LayoutActionTypes) => {
  switch (action.type) {
    case CHANGE_LAYOUT:
      return {
        ...state,
        appPaddingLeft: action.payload.appPadingLeft
          ? action.payload.appPaddingLeft
          : state.appPaddingLeft,
        appMarginTop: action.payload.appMarginTop
          ? action.payload.appMarginTop
          : state.appMarginTop
      };
    case LAYOUT_CHANGE_HEADER_TYPE:
      // return {
      //   ...state,
      //   headerType: action.payload
      // };

      return {
        ...state,
        header: {
          type: action.payload.type,
          showHelpLink: action.payload.hasOwnProperty("showHelpLink")
            ? action.payload.showHelpLink
            : false,
          title: action.payload.hasOwnProperty("title")
            ? action.payload.title
            : null,
          showBackLink: action.payload.hasOwnProperty("showBackLink")
            ? action.payload.showBackLink
            : false,
          backLinkUrl: action.payload.hasOwnProperty("backLinkUrl")
            ? action.payload.backLinkUrl
            : null,
          backEl: action.payload.hasOwnProperty("backEl")
            ? action.payload.backEl
            : null,
          actionEl: action.payload.hasOwnProperty("actionEl")
            ? action.payload.actionEl
            : null,
          backCallback: action.payload.hasOwnProperty("backCallback")
            ? action.payload.backCallback
            : null
        }
        // headerType: {
        //   type: action.payload.type,
        //   showHelpLink: action.payload.showHelpLink === false ? false : true
        // }
      };
    case LAYOUT_CHANGE_FOOTER_TYPE:
      return {
        ...state,
        footer: {
          type: action.payload.type
        }
      };
    case LAYOUT_SET_PADDING_LEFT:
      return {
        ...state,
        appPaddingLeft: action.payload
      };
    case LAYOUT_SET_MARGIN_TOP:
      return {
        ...state,
        appMarginTop: action.payload
      };
    case LAYOUT_SET_BACKGROUND:
      return {
        ...state,
        background: action.payload
      };
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.payload.width
      };
    case USER_DRAWER_MENU_OPEN:
      return {
        ...state,
        isUserDrawerMenuOpen: true
      };
    case USER_DRAWER_MENU_CLOSE:
      return {
        ...state,
        isUserDrawerMenuOpen: false
      };
    case SIGNOUT_USER:
      return {
        ...state,
        isUserDrawerMenuOpen: false
      };
    default:
      return state;
  }
};

export default layout;
