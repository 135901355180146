import { Box } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const StyledItem = styled(Box)``;

const StyledLabel = styled.p`
  line-height: 1.35;
  font-size: 0.75rem;
  font-weight: 700;
  color: #6b778c;
  margin: 0;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
`;

const StyledData = styled.p`
  line-height: 1.35;
  font-size: 0.9375rem;
  color: #172b4d;
  margin: 0;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
`;

interface IInfoBoxItemProps {
  label: string;
  data?: string;
}

const InfoBoxItem = (props: IInfoBoxItemProps) => {
  const { label, data } = props;
  return (
    <StyledItem>
      <StyledLabel>{label}</StyledLabel>
      <StyledData>{data ?? "-"}</StyledData>
    </StyledItem>
  );
};

export default InfoBoxItem;
