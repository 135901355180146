import { useQuery } from "react-query";
import { apiGetContractBotAvailability } from "../../../../../api/contract/apiGetContractBotAvailability";

export default function useContractBotAvailabilityQuery(contractId: string) {
  return useQuery(
    ["contract_bot_availability", contractId],
    async () => {
      const res = await apiGetContractBotAvailability(contractId);

      return res;
    },
    {
      enabled: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
}
