import moment from "moment";
import { TGetCandidateValuationsCollectionItem } from "../../../../../api/valuation/valuation.types";

export const daysInValuation = (
  valuation: TGetCandidateValuationsCollectionItem
) => {
  const startDate = valuation.fecha_inicio_contrato;
  const endDate = valuation.fecha_fin_contrato;

  if (!startDate || !endDate) {
    return 0;
  }

  const momStart = moment(startDate);
  const momEnd = moment(endDate);

  return momEnd.diff(momStart, "days") + 1;
};
