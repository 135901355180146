import {
  GET_VALORACIONES,
  GET_VALORACIONES_SUCCESS,
  GET_VALORACION,
  GET_VALORACION_SUCCESS,
  UPDATE_VALORACIONES_TABLE_STATE,
  UPDATE_VALORACIONES,
  UPDATE_MIS_VALORACIONES,
  GET_MIS_VALORACIONES,
  RESET_MIS_VALORACIONES,
} from "../constants/ActionTypes";

export type ValoracionEstado =
  | "00_Asociado"
  | "01_Sin Valorar"
  | "02_Descartado"
  | "03_Telefónica"
  | "04_CV a cliente"
  | "04_Presencial"
  | "05_Presencial Cliente"
  | "06_Contratado";

const INIT_STATE: {
  valoraciones: any[];
  numValoraciones: number;
  misValoraciones: any[];
  misValoracionesLoadingState: string;
  tableState: {
    data: any;
    totalItems: number;
    page: number;
    filters: {
      provinciaFilter: string[];
      estadoFilter: string[];
    };
    searchText: string;
  };
} = {
  valoraciones: [],
  numValoraciones: 0,
  misValoraciones: [],
  misValoracionesLoadingState: "unloaded",
  tableState: {
    data: [],
    totalItems: 0,
    page: 0,
    filters: {
      provinciaFilter: [],
      estadoFilter: [],
    },
    searchText: "",
  },
};

// eslint-disable-next-line
export default (state = INIT_STATE, action: { type: string; payload: any }) => {
  let newValoraciones: any;
  let valoraciones: any;
  switch (action.type) {
    case GET_VALORACIONES:
      return state;
    case GET_VALORACIONES_SUCCESS:
      newValoraciones = action.payload.partialValoraciones;
      valoraciones = [...state.valoraciones];
      newValoraciones.forEach((newValoracion: any) => {
        let o: any = state.valoraciones.find(
          (o) => (o as any).id === newValoracion.id
        );
        if (!o) {
          valoraciones.push(Object.assign({}, newValoracion));
        }
      });
      return {
        ...state,
        valoraciones: valoraciones,
        numValoraciones: action.payload.numValoraciones,
      };
    case GET_VALORACION:
      return state;
    case GET_VALORACION_SUCCESS:
      newValoraciones = action.payload.partialCandidatos;
      valoraciones = [...state.valoraciones];
      newValoraciones.forEach((newValoracion: any) => {
        let o: any = state.valoraciones.find(
          (o) => (o as any).id === newValoracion.id
        );
        if (!o) {
          valoraciones.push(Object.assign({}, newValoracion));
        }
      });
      return {
        ...state,
        valoraciones: valoraciones,
      };
    case UPDATE_VALORACIONES_TABLE_STATE:
      return {
        ...state,
        tableState: action.payload,
      };
    case UPDATE_VALORACIONES:
      newValoraciones = action.payload.partialValoraciones;
      valoraciones = [...state.valoraciones];
      newValoraciones.forEach((newValoracion: any) => {
        let o: any = state.valoraciones.find(
          (o) => (o as any).id === newValoracion.id
        );
        if (!o) {
          valoraciones.push(Object.assign({}, newValoracion));
        }
      });
      return {
        ...state,
        valoraciones: valoraciones,
        numValoraciones: action.payload.numValoraciones,
      };
    case GET_MIS_VALORACIONES:
      return state;
    case UPDATE_MIS_VALORACIONES:
      newValoraciones = action.payload;
      valoraciones = [...state.misValoraciones];
      newValoraciones.forEach((newValoracion: any) => {
        let o: any = state.misValoraciones.find(
          (o) => (o as any).id === newValoracion.id
        );
        if (!o) {
          valoraciones.push(Object.assign({}, newValoracion));
        }
      });
      return {
        ...state,
        misValoraciones: valoraciones,
      };
    case RESET_MIS_VALORACIONES:
      return {
        ...state,
        misValoraciones: [],
      };
    default:
      return state;
  }
};
