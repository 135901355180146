import { Grid } from "@material-ui/core";
import { push } from "connected-react-router";
import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { getCandidato } from "../../../../actions";
import FormattedEmployeeExperienceField from "../../../../components/FormattedFields/FormattedEmployeeExperienceField";
import {
  StyledCard,
  StyledCardAction,
  StyledCardActions,
  StyledCardContent,
  StyledCardHeader,
  StyledCardTitle,
  StyledChip,
  StyledData,
  StyledLabel,
} from "../ProfileStyles";

const InterestDataCard = (props: any) => {
  const { candidato, push, authUser, educacionOptions, WarningComponent } =
    props;

  const nivelFormativo = educacionOptions.find(
    (item: any) => item.value === candidato.Nivel_formativo
  );

  return (
    <StyledCard>
      <StyledCardHeader>
        <StyledCardTitle>
          <FormattedMessage
            id="InterestDataCard.Title"
            defaultMessage="Datos de interés"
          />
        </StyledCardTitle>
        <StyledCardActions>
          {authUser && authUser.userId === candidato.id && (
            <StyledCardAction
              color="primary"
              variant="outlined"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                // setOpen(true);
                push("/empleo/miPerfil/datosInteres");
              }}
            >
              <FormattedMessage
                id="InterestDataCard.Edit"
                defaultMessage="Editar"
              />
            </StyledCardAction>
          )}
        </StyledCardActions>
      </StyledCardHeader>
      <StyledCardContent>
        {WarningComponent}
        <Grid container spacing={8}>
          <Grid item xs={12} md={4}>
            <StyledLabel>
              <FormattedMessage
                id="InterestDataCard.OwnsCar"
                defaultMessage="Coche propio"
              />
            </StyledLabel>
            {candidato && candidato.Veh_culo_propio ? (
              <StyledData style={{ textTransform: "capitalize" }}>
                {candidato.Veh_culo_propio.toLowerCase()}
              </StyledData>
            ) : (
              <StyledData>-</StyledData>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledLabel>
              <FormattedMessage
                id="InterestDataCard.DrivingLicense"
                defaultMessage="¿Tienes permiso de conducir?"
              />
            </StyledLabel>
            {candidato && candidato.Carnet_de_conducir ? (
              <StyledData style={{ textTransform: "capitalize" }}>
                {candidato.Carnet_de_conducir.toLowerCase()}
              </StyledData>
            ) : (
              <StyledData>-</StyledData>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledLabel>
              <FormattedMessage
                id="InterestDataCard.Education"
                defaultMessage="Educación:"
              />
            </StyledLabel>
            {nivelFormativo && nivelFormativo.label ? (
              <StyledData>
                {nivelFormativo.label}
                {/* <IntlMessages id={nivelFormativo.label} /> */}
              </StyledData>
            ) : (
              <StyledData>-</StyledData>
            )}
          </Grid>
          {candidato.A_os_de_experiencia && (
            <Grid item xs={12} md={4}>
              <StyledLabel>
                <FormattedMessage
                  id="InterestDataCard.YearsExperience"
                  defaultMessage="Años de experiencia:"
                />
              </StyledLabel>
              {candidato.A_os_de_experiencia ? (
                <StyledData>
                  <FormattedEmployeeExperienceField
                    value={candidato.A_os_de_experiencia}
                  />
                </StyledData>
              ) : (
                <StyledData>-</StyledData>
              )}
            </Grid>
          )}
        </Grid>
        <Grid container spacing={8}>
          <Grid item xs={12} md={12}>
            <StyledLabel>
              <FormattedMessage
                id="InterestDataCard.Languages"
                defaultMessage="Idiomas:"
              />
            </StyledLabel>
            <StyledData>
              {candidato.Otros_Idiomas.length > 0 ? (
                candidato.Otros_Idiomas.map((item: any) => (
                  <StyledChip key={item} label={item}></StyledChip>
                ))
              ) : (
                <StyledData>-</StyledData>
              )}
            </StyledData>
          </Grid>
        </Grid>
        <Grid container spacing={8}>
          <Grid item xs={12} md={12}>
            <StyledLabel>
              <FormattedMessage
                id="InterestDataCard.Degrees"
                defaultMessage="Titulaciones:"
              />
            </StyledLabel>
            <StyledData>
              {candidato.Titulaci_n_oficial_SAD &&
              candidato.Titulaci_n_oficial_SAD.length > 0 ? (
                candidato.Titulaci_n_oficial_SAD.map((item: any) => {
                  if (item !== "") {
                    return <StyledChip key={item} label={item}></StyledChip>;
                  }
                  return null;
                })
              ) : (
                <StyledData>-</StyledData>
              )}
            </StyledData>
          </Grid>
        </Grid>
      </StyledCardContent>
    </StyledCard>
  );
};

const mapStateToProps = ({
  candidatosState,
  commonData,
  auth,
}: {
  candidatosState: { candidatos: any };
  commonData: any;
  auth: { authUser: any };
}) => {
  const { candidatos } = candidatosState;
  const { educacionOptions } = commonData;
  const { authUser } = auth;

  return {
    candidatos,
    educacionOptions,
    authUser,
  };
};

export default connect(mapStateToProps, {
  getCandidato,
  push,
})(InterestDataCard);
