import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Mention, MentionsInput, OnChangeHandlerFunc } from "react-mentions";
import inputStyle from "./inputStyle";
import mentionStyle from "./mentionStyle";
import SendIcon from "@material-ui/icons/Send";
import { IconButton } from "@material-ui/core";
import { useIntl } from "react-intl";

const StyledInputContainer = styled.div`
  background-color: #d1d7db;
  padding: 0.125rem 1rem 0.125rem 1rem;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledInputWrapper = styled.div`
  flex: 1 1 100%;
`;

const StyledSendButton = styled(IconButton)`
  margin-left: 0.5rem;
`;

interface IInputMessageProps {
  onSendMessage: (content: string) => void;
  mentionsData: any;
}

const InputMessage: React.FC<IInputMessageProps> = (props) => {
  const { onSendMessage, mentionsData } = props;
  const { formatMessage } = useIntl();
  const inputRef = useRef(null as any);
  const [value, setValue] = useState({
    content: "",
  });

  const handleChange: OnChangeHandlerFunc = (e) => {
    setValue({
      content: e.target.value,
    });
  };

  const submitForm = () => {
    if (value.content !== "") {
      onSendMessage(value.content);
      setValue({ content: "" });
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    submitForm();
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      if (!e.ctrlKey) {
        e.preventDefault();
        submitForm();
      }
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit}>
      <StyledInputContainer className="CMuiConversationInputContainer">
        <StyledInputWrapper>
          <MentionsInput
            value={value.content}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            inputRef={inputRef}
            // suggestionsPortalHost={container}
            allowSuggestionsAboveCursor
            style={inputStyle}
            placeholder={formatMessage({
              id: "InputMessage.Escribe una nota aquí",
              defaultMessage: "Escribe una nota aquí",
            })}
          >
            <Mention
              trigger="@"
              // data={mentionsData}
              data={[]}
              style={mentionStyle}
              markup="crm[__id__]crm"
              displayTransform={(id) => {
                return mentionsData.find((item: any) => item.id === id).display;
              }}
            />
          </MentionsInput>
        </StyledInputWrapper>
        <StyledSendButton type="submit" color="primary">
          <SendIcon />
        </StyledSendButton>
      </StyledInputContainer>
    </form>
  );
};

export default InputMessage;
