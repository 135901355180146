import { motion, Variants } from "framer-motion";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useContractsNumbersQuery from "../../../../../../queries/useContractsNumbersQuery";
import ContractCard from "../../components/ContractCard";
import { useBotWorkers } from "./hooks/use-bot-worker";
import { TUpdateContractPhase } from "./hooks/useContractPhaseMutation";
import moment from "moment";
import { WORKER_STATUS } from "../../../../../../apiCoreV3/worker/suggestion-worker.types";
import { TUpdateContractImportantNotes } from "../../api/updateContractImportantNotes";
import {
  TContractPartial,
  TContractPartialWithNumbers,
} from "../../types/contract.types";

const StyledContratoWrapper = styled.div`
  margin-bottom: 1rem;
`;

const listVariants: Variants = {
  enter: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      delay: 0.1,
    },
  },
  exit: {
    opacity: 0,
  },
};

const itemVariants: Variants = {
  enter: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.25 },
  },
  exit: {
    opacity: 0,
    y: 15,
    transition: { duration: 0.1 },
  },
};

interface IContractsListProps {
  contracts: TContractPartial[];
  isLoading?: boolean;
  onChange: (contractData: any) => void;
  onContractPhaseUpdate: (payload: TUpdateContractPhase) => void;
  onContractImportantNotesUpdate: (
    payload: TUpdateContractImportantNotes
  ) => void;
}

const ContractsList = (props: IContractsListProps) => {
  const {
    contracts,
    isLoading,
    onChange,
    onContractPhaseUpdate,
    onContractImportantNotesUpdate,
  } = props;

  const [contractsWithNumbers, setContractsWithNumbers] =
    useState<TContractPartialWithNumbers[]>(contracts);
  const { data } = useContractsNumbersQuery(
    contracts.map((contract) => contract.id)
  );
  const contractCrmIds = contracts
    .filter((contract) => !!contract.zohoId)
    .map((contract) => contract.zohoId);
  const botQuery = useBotWorkers(contractCrmIds as string[]);

  useEffect(() => {
    if (!data) {
      return;
    }

    const newContracts: TContractPartialWithNumbers[] = [];
    contracts.forEach((c: TContractPartial) => {
      let newContract;
      const found = data.find((d: any) => c.id === d.id);
      if (found) {
        newContract = {
          ...found,
          ...c,
          bot_worker: undefined,
        } as TContractPartialWithNumbers;
      } else {
        newContract = {
          ...c,
          bot_worker: undefined,
        } as TContractPartialWithNumbers;
      }

      if (botQuery.data?.data) {
        const botFound = botQuery.data.data.find(
          (worker) => worker.contract_crm_id === c.zohoId
        );
        if (botFound) {
          newContract.bot_worker = {
            ...botFound,
          };
        } else {
          if (c.numCommunicationIntents) {
            const dateStr = moment().format("Y/m/d");
            newContract.bot_worker = {
              contract_id: c.id,
              created_at: dateStr,
              date_end: dateStr,
              date_start: dateStr,
              id: c.id,
              num_bot_inscriptions: c.numSuccessfulCommunicationIntents ?? 0,
              num_bot_intents: c.numCommunicationIntents ?? 0,
              status: WORKER_STATUS.MANUAL,
            };
          }
        }
      }

      newContracts.push(newContract);
    });
    setContractsWithNumbers(newContracts);
  }, [data, contracts, setContractsWithNumbers, botQuery.data]);

  return (
    <motion.div
      initial="exit"
      animate={isLoading ? "loading" : "enter"}
      variants={listVariants}
    >
      {contractsWithNumbers.map((contract) => {
        return (
          <motion.div variants={itemVariants} key={contract.id}>
            <StyledContratoWrapper>
              <ContractCard
                contract={contract}
                onChange={onChange}
                onContractPhaseUpdate={onContractPhaseUpdate}
                onContractImportantNotesUpdate={onContractImportantNotesUpdate}
              />
            </StyledContratoWrapper>
          </motion.div>
        );
      })}
    </motion.div>
  );
};

export default ContractsList;
