import { reportClientEvent } from "../actions/ClientEvent";
import { store } from "../store";

const reportPageView = async (location: any) => {
  // Affinity app does not report anything here...
  // if (
  //   process.env.REACT_APP_AFFINITY === "true" &&
  //   !process.env.REACT_APP_EMPLEO
  // ) {
  //   return;
  // }

  // console.log("Reporting pageview: ", location);

  const storeState = store.getState();
  const authUser =
    storeState && storeState.auth && storeState.auth.authUser
      ? storeState.auth.authUser
      : null;
  // let res = await apiEmpleo.post(
  //   "reportActivity",
  //   {
  //     type: "pageview",
  //     context: {
  //       pathname: location.pathname
  //     }
  //   },
  //   {},
  //   false
  // );
  store.dispatch(
    reportClientEvent({
      type: "pageview",
      details: {
        pathname: location.pathname,
      },
    })
  );

  // We report to analytics only on normal users (no staff)
  if (
    authUser &&
    authUser.userRoles &&
    (authUser.userRoles.includes("ROLE_STAFF") ||
      authUser.userRoles.includes("ROLE_ADMIN"))
  ) {
    return;
  }

  // We don't report public employee profiles
  if (location.pathname.indexOf("/ficha") === 0) {
    return;
  }
  if ((window as any).gtag && process.env.REACT_APP_ANALYTICS_ID) {
    (window as any).gtag("config", process.env.REACT_APP_ANALYTICS_ID, {
      page_path: location.pathname,
    });
  }
};

export { reportPageView };
