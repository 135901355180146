import { useIntl } from "react-intl";
import * as Yup from "yup";
import moment from "moment";

export default function useSignUpStep2ValidationSchema() {
  const { formatMessage } = useIntl();

  const validationSchema = Yup.object({
    Sexo: Yup.string().required(
      formatMessage({
        id: "SignUp.SexRequired",
        defaultMessage: "Debes especificar el sexo",
      })
    ),
    idiomas: Yup.array()
      .min(
        1,
        formatMessage({
          id: "SignUp.LanguageRequired",
          defaultMessage: "Debes indicar al menos 1 idioma",
        })
      )
      .of(
        Yup.object().shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
        })
      ),
    permisoConducir: Yup.string().required(
      formatMessage({
        id: "SignUp.Debes indicar si tienes permiso de conducir",
        defaultMessage: "Debes indicar si tienes permiso de conducir",
      })
    ),
    cochePropio: Yup.string().required(
      formatMessage({
        id: "SignUp.Debes indicar si tienes coche propio",
        defaultMessage: "Debes indicar si tienes coche propio",
      })
    ),
    A_os_de_experiencia: Yup.object().shape({
      value: Yup.string().required(
        formatMessage({
          id: "SignUp.ExperienceRequired",
          defaultMessage: "Debes indicar los años de experiencia",
        })
      ),
    }),
    Pais_nacimiento: Yup.object().shape({
      value: Yup.string().required(
        formatMessage({
          id: "SignUp.BirthplaceRequired",
          defaultMessage: "Debes indicar el país de nacimiento",
        })
      ),
    }),
    registroCalle: Yup.string().required(
      formatMessage({
        id: "SignUp.AddressStreetRequired",
        defaultMessage: "Debes especificar la calle",
      })
    ),
    registroNumero: Yup.string().required(
      formatMessage({ id: "SignUp.Required", defaultMessage: "Obligatorio" })
    ),
    registroCiudad: Yup.object()
      .nullable()
      .required(
        formatMessage({
          id: "SignUp.Debes especificar la ciudad. Comprueba el código postal.",
          defaultMessage:
            "Debes especificar la ciudad. Comprueba el código postal.",
        })
      )
      .shape({
        value: Yup.string().required(
          formatMessage({
            id: "SignUp.Debes especificar la ciudad. Comprueba el código postal.",
            defaultMessage:
              "Debes especificar la ciudad. Comprueba el código postal.",
          })
        ),
      }),
    registroCodigoPostal: Yup.string().required(
      formatMessage({
        id: "SignUp.PostalCodeRequired",
        defaultMessage: "Debes especificar el código postal",
      })
    ),
    fechaNacimiento: Yup.string()
      .required(
        formatMessage({
          id: "SignUp.BirthdateRequired",
          defaultMessage: "Debes especificar la fecha de nacimiento",
        })
      )
      .test(
        "valid-date",
        formatMessage({
          id: "SignUp.BirthdateInvalid",
          defaultMessage: "La fecha de nacimiento ha de ser válida",
        }),
        (value) => {
          if (value && value.indexOf("undefined") === -1) {
            const mom = moment(value, "YYYY-M-D").utcOffset("+0200");
            if (!mom.isValid()) {
              return false;
            }
            const now = moment();
            const yearsDifference = now.diff(mom, "years");
            if (yearsDifference < 18) {
              return false;
            }
            if (yearsDifference > 90) {
              return false;
            }
            return true;
          }
          return false;
        }
      ),
    avatar: Yup.mixed()
      // .required(formatMessage({ id: "Se debe subir una imagen" }))
      .test(
        "fileSize",
        formatMessage({
          id: "SignUp.FileTooBig",
          defaultMessage: "El archivo es demasiado grande",
        }),
        (value) => {
          if (!value) return true;
          if (!value.size) return true;
          if (value.size < 2000000) return true; // 2MB limit
          return false;
        }
      )
      .test(
        "fileType",
        formatMessage({
          id: "SignUp.FileType",
          defaultMessage: "El archivo debe ser de tipo JPG o PNG",
        }),
        (value) => {
          if (!value) return true;
          if (!value.type) return true;
          if (["image/png", "image/jpeg"].includes(value.type)) return true;
          return false;
        }
      ),
    cv: Yup.mixed()
      .test(
        "fileSize",
        formatMessage({
          id: "SignUp.FileTooBig",
          defaultMessage: "El archivo es demasiado grande",
        }),
        (value) => {
          if (!value) return true;
          if (!value.size) return true;
          if (value.size < 2000000) return true; // 2MB limit
          return false;
        }
      )
      .test(
        "minFileSize",
        formatMessage({
          id: "SignUp.ErrorCVMinSize",
          defaultMessage: "El archivo es muy pequeño y parece no ser válido",
        }),
        (value) => {
          if (!value) return true;
          if (value.size === 0) return false;
          return true;
        }
      )
      .test(
        "fileType",
        formatMessage({
          id: "SignUp.CVTypeError",
          defaultMessage: "El archivo debe ser de tipo JPG, PNG o PDF",
        }),
        (value) => {
          if (!value) return true;
          if (!value.type) return true;
          if (
            ["image/png", "image/jpeg", "application/pdf"].includes(value.type)
          )
            return true;
          return false;
        }
      ),
    R_gimen_de_trabajo: Yup.array().min(
      1,
      formatMessage({
        id: "SignUp.Debes seleccionar al menos 1 régimen de trabajo",
        defaultMessage: "Debes seleccionar al menos 1 régimen de trabajo",
      })
    ),
    acceptTerms: Yup.boolean()
      .required(
        formatMessage({
          id: "SignUp.Debes aceptar los términos y condiciones",
          defaultMessage: "Debes aceptar los términos y condiciones",
        })
      )
      .oneOf(
        [true],
        formatMessage({
          id: "SignUp.Debes aceptar los términos y condiciones",
          defaultMessage: "Debes aceptar los términos y condiciones",
        })
      ),
    Externa_entre_semana: Yup.boolean(),
    Externa_fin_de_semana: Yup.boolean(),
    Interna_entre_semana: Yup.boolean(),
    Interna_fin_de_semana: Yup.boolean(),
    Franja_horaria: Yup.array()
  }).test("atLeastOneAvailabilityTest", "", function (values) {
    const {
      Externa_entre_semana,
      Externa_fin_de_semana,
      Interna_entre_semana,
      Interna_fin_de_semana,
    } = values;
    if (
      !Externa_entre_semana &&
      !Externa_fin_de_semana &&
      !Interna_entre_semana &&
      !Interna_fin_de_semana
    ) {
      return this.createError({
        path: "atLeastOneAvailability",
        message: "Debe elegir al menos una opción",
      });
    }
    return true;
  }).test("atLeastOneTimeSlotIfExternalAvailabilityTest", "", function(values) {
    const {
      Externa_entre_semana,
      Externa_fin_de_semana,
      Franja_horaria
    } = values;
    if (
      (Externa_entre_semana || Externa_fin_de_semana) &&
      Franja_horaria.length === 0
    ) {
      return this.createError({
        path: "atLeastOneTimeSlotIfExternalAvailability",
        message: "Debe elegir al menos una opción",
      });
    }
    return true;
  });

  return validationSchema;
}
