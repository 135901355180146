import { useState, useEffect } from "react";
import taskOptions from "../../../util/defaults/tareas";

function useContractTaskOptions(businessCountry: string = "es") {
  const [tasksOptions, setTasksOptions] = useState(
    [] as { value: string; label: string }[]
  );

  useEffect(() => {
    let filter = "es";
    if (businessCountry === "fr") {
      filter = "fr";
    }

    const newTasks = taskOptions
      .filter((item) => item.businessCountry === filter)
      .map((item) => {
        return {
          value: item.value,
          label: item.label,
        };
      });

    setTasksOptions(newTasks);
  }, [businessCountry]);

  return tasksOptions;
}

export default useContractTaskOptions;
