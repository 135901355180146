export const SERVICE_HOLDER_STATE_VALUED = "Valorado";
export const SERVICE_HOLDER_STATE_WITHOUT_VALUATION = "Sin valorar";

export type TServiceHolderState =
  | typeof SERVICE_HOLDER_STATE_VALUED
  | typeof SERVICE_HOLDER_STATE_WITHOUT_VALUATION;

export const SERVICE_HOLDER_USER_STATUS_ACTIVE = "Activo";
export const SERVICE_HOLDER_USER_STATUS_ADMISSION_TO_RESIDENCE =
  "Ingreso en residencia";
export const SERVICE_HOLDER_USER_STATUS_HOSPITALIZATION = "Hospitalización";
export const SERVICE_HOLDER_USER_STATUS_DEATH = "Defunción";
export const SERVICE_HOLDER_USER_STATUS_INACTIVE = "Baja";

export type TServiceHolderUserStatus =
  | typeof SERVICE_HOLDER_USER_STATUS_ACTIVE
  | typeof SERVICE_HOLDER_USER_STATUS_ADMISSION_TO_RESIDENCE
  | typeof SERVICE_HOLDER_USER_STATUS_HOSPITALIZATION
  | typeof SERVICE_HOLDER_USER_STATUS_DEATH
  | typeof SERVICE_HOLDER_USER_STATUS_INACTIVE;

export const SERVICE_HOLDER_SOURCE = {
  AFFINITY: "Affinity",
  CHECKOUT: "Checkout",
  MIGRATION: "Migración",
  CUIDEO_ASSIST: "CuideoAsist",
} as const;

export type TServiceHolderSource =
  typeof SERVICE_HOLDER_SOURCE[keyof typeof SERVICE_HOLDER_SOURCE];
