import { Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { useNotificationPeriod } from "../../hooks/useNotificationPeriod";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.0625rem 0.5rem;
`;

const StyledLeft = styled.div``;

const StyledPeriodText = styled(Typography)`
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 600;
  color: #777;
`;

const StyledRight = styled.div``;

interface INotificationPeriodSeparatorProps {
  createdAt: string;
}

const NotificationPeriodSeparator: React.FC<INotificationPeriodSeparatorProps> =
  (props) => {
    const { createdAt } = props;
    const { formatPeriod } = useNotificationPeriod();

    return (
      <StyledWrapper>
        <StyledLeft>
          <StyledPeriodText>{formatPeriod(createdAt)}</StyledPeriodText>
        </StyledLeft>
        <StyledRight></StyledRight>
      </StyledWrapper>
    );
  };

export default NotificationPeriodSeparator;
