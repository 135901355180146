import { USER_ROLES } from "../../../../../api/security";
import {
  COORDINATION_GROUP,
  TCoordinationGroup,
} from "../../../../../api/user/apiListStaffUsers";
import {
  TUserAssignations,
  TUserContractAssignationsStats,
  TUserGroupAssignations,
  TUserServiceAssignationsStats,
} from "../types/UserGroupAssignations.types";

const newRecruiterAssignationServiceStats =
  (): TUserServiceAssignationsStats => {
    return {
      total: 0,
      withoutValuation: {
        total: 0,
        postponed: 0,
      },
    };
  };

export const newRecruiterAssignationContractStats =
  (): TUserContractAssignationsStats => {
    return {
      total: 0,
      pendingSignUp: {
        total: 0,
      },
      awaiting: {
        total: 0,
      },
      cvClient: {
        total: 0,
      },
      payAndReserve: {
        total: 0,
      },
      clientFaceToFace: {
        total: 0,
      },
    };
  };

export const initRecruiterGroups = () => {
  const newGroups: TUserGroupAssignations[] = [];

  newGroups.push({
    id: COORDINATION_GROUP.ON_SHIFT,
    serviceStats: newRecruiterAssignationServiceStats(),
    contractStats: newRecruiterAssignationContractStats(),
    users: [],
    unassignedContracts: [],
    maxContractsAssigned: 0,
    maxServicesAssigned: 0,
  });
  newGroups.push({
    id: COORDINATION_GROUP.BCN,
    serviceStats: newRecruiterAssignationServiceStats(),
    contractStats: newRecruiterAssignationContractStats(),
    users: [],
    unassignedContracts: [],
    maxContractsAssigned: 0,
    maxServicesAssigned: 0,
  });
  newGroups.push({
    id: COORDINATION_GROUP.MAD,
    serviceStats: newRecruiterAssignationServiceStats(),
    contractStats: newRecruiterAssignationContractStats(),
    users: [],
    unassignedContracts: [],
    maxContractsAssigned: 0,
    maxServicesAssigned: 0,
  });
  /* newGroups.push({
    id: COORDINATION_GROUP.CAV,
    serviceStats: newRecruiterAssignationServiceStats(),
    contractStats: newRecruiterAssignationContractStats(),
    users: [],
    unassignedContracts: [],
    maxContractsAssigned: 0,
    maxServicesAssigned: 0,
  }); */
  newGroups.push({
    id: COORDINATION_GROUP.OTH_FAST,
    serviceStats: newRecruiterAssignationServiceStats(),
    contractStats: newRecruiterAssignationContractStats(),
    users: [],
    unassignedContracts: [],
    maxContractsAssigned: 0,
    maxServicesAssigned: 0,
  });
  newGroups.push({
    id: COORDINATION_GROUP.OTH_SLOW,
    serviceStats: newRecruiterAssignationServiceStats(),
    contractStats: newRecruiterAssignationContractStats(),
    users: [],
    unassignedContracts: [],
    maxContractsAssigned: 0,
    maxServicesAssigned: 0,
  });
  newGroups.push({
    id: COORDINATION_GROUP.SAD,
    serviceStats: newRecruiterAssignationServiceStats(),
    contractStats: newRecruiterAssignationContractStats(),
    users: [],
    unassignedContracts: [],
    maxContractsAssigned: 0,
    maxServicesAssigned: 0,
  });
  newGroups.push({
    id: COORDINATION_GROUP.OTHER,
    serviceStats: newRecruiterAssignationServiceStats(),
    contractStats: newRecruiterAssignationContractStats(),
    users: [],
    unassignedContracts: [],
    maxContractsAssigned: 0,
    maxServicesAssigned: 0,
  });

  return newGroups;
};

export const recruiterIsOnShift = (recruiter: TUserAssignations): boolean => {
  if (recruiter.roles.includes(USER_ROLES.ROLE_ES_ON_SHIFT)) {
    return true;
  }
  return false;
};

export const validGroupForRecruiter = (
  recruiter: TUserAssignations
): TCoordinationGroup => {
  if (!recruiter.roles.includes(USER_ROLES.ROLE_ES_RECRUITER)) {
    return COORDINATION_GROUP.OTHER;
  }

  if (
    recruiter.coordinationGroups &&
    recruiter.coordinationGroups.includes(COORDINATION_GROUP.MAD)
  ) {
    return COORDINATION_GROUP.MAD;
  }
  if (
    recruiter.coordinationGroups &&
    recruiter.coordinationGroups.includes(COORDINATION_GROUP.BCN)
  ) {
    return COORDINATION_GROUP.BCN;
  }
  if (
    recruiter.coordinationGroups &&
    recruiter.coordinationGroups.includes(COORDINATION_GROUP.SAD)
  ) {
    return COORDINATION_GROUP.SAD;
  }
  /* if (
    recruiter.coordinationGroups &&
    recruiter.coordinationGroups.includes(COORDINATION_GROUP.CAV)
  ) {
    return COORDINATION_GROUP.CAV;
  } */
  if (
    recruiter.coordinationGroups &&
    recruiter.coordinationGroups.includes(COORDINATION_GROUP.OTH_FAST)
  ) {
    return COORDINATION_GROUP.OTH_FAST;
  }
  if (
    recruiter.coordinationGroups &&
    recruiter.coordinationGroups.includes(COORDINATION_GROUP.OTH_SLOW)
  ) {
    return COORDINATION_GROUP.OTH_SLOW;
  }
  return COORDINATION_GROUP.OTHER;
};

export const groupName = (id: TCoordinationGroup): string => {
  if (id === COORDINATION_GROUP.BCN) {
    return "Barcelona";
  }
  if (id === COORDINATION_GROUP.MAD) {
    return "Madrid";
  }
  if (id === COORDINATION_GROUP.SAD) {
    return "SAD";
  }
  /* if (id === COORDINATION_GROUP.CAV) {
    return "País vasco";
  } */
  if (id === COORDINATION_GROUP.OTH_FAST) {
    return "Otros rápido";
  }
  if (id === COORDINATION_GROUP.OTH_SLOW) {
    return "Otros difícil";
  }
  if (id === COORDINATION_GROUP.ON_SHIFT) {
    return "De guardia";
  }
  return "Sin asignar";
};

export const sortRecruiterAssignations = (
  a: TUserAssignations,
  b: TUserAssignations
) => {
  if (a.active && !b.active) {
    return -1;
  }
  if (!a.active && b.active) {
    return 1;
  }
  // if (a.services.length > b.services.length) {
  //   return -1;
  // }
  // if (a.services.length < b.services.length) {
  //   return 1;
  // }
  if (a.contracts.length > b.contracts.length) {
    return -1;
  }
  if (a.contracts.length < b.contracts.length) {
    return 1;
  }
  return a.fullName > b.fullName ? 1 : -1;
};
