import { Button, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import ZohoIcon from "../../../../../../../../assets/images/iconos/zoho.png";

const StyledLinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  vertical-align: middle;
`;

const StyledActionLink = styled(Button)`
  text-decoration: none;
  margin-left: 0.25rem;
  padding: 0.25rem;
  min-width: auto;

  &:hover {
    background-color: rgba(0, 72, 120, 0.25);
  }
`;

const StyledActionIcon = styled.img`
  display: block;
  width: 0.875rem;
  height: 0.875rem;
`;
const StyledActionLinkText = styled(Typography)`
  font-size: 0.75rem;
  text-transform: none;
`;

interface ICrmServiceLink {
  zohoName: string;
  zohoId: string;
}

const CrmServiceLink = (props: ICrmServiceLink) => {
  const { zohoName, zohoId } = props;
  return (
    <StyledLinkWrapper>
      <StyledActionLinkText>{zohoName}</StyledActionLinkText>
      {zohoId && (
        <StyledActionLink
          color="primary"
          onClick={() => {
            window.open(
              `https://crm.zoho.com/crm/org638326163/tab/CustomModule17/${zohoId}`
            );
          }}
        >
          <StyledActionIcon src={ZohoIcon} />
        </StyledActionLink>
      )}
    </StyledLinkWrapper>
  );
};

export default CrmServiceLink;
