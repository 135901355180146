const pathologies = [
  {
    id: "8bc4693e-1d31-469c-a211-af1aac83153a",
    value: "Anemia",
    label: "Anemia",
    slug: "anemia",
    businessCountry: "es",
  },
  {
    id: "86bb9192-4ba8-4973-9351-5bdc67a4b30f",
    value: "Artrosis",
    label: "Artrosis",
    slug: "artrosis",
    businessCountry: "es",
  },
  {
    id: "7871f8ac-597d-4bc7-a0dd-9b0d2fb8d4e8",
    value: "Autismo",
    label: "Autismo",
    slug: "autismo",
    businessCountry: "es",
  },
  {
    id: "6e255a60-be21-4d63-aa91-cf4e0d9d1dde",
    value: "Cáncer",
    label: "Cáncer",
    slug: "cancer",
    businessCountry: "es",
  },
  {
    id: "9b8539a6-cf2e-4537-9953-75b144d62f1b",
    value: "Cuerpos de Lewy",
    label: "Cuerpos de Lewy",
    slug: "cuerpos-de-lewy",
    businessCountry: "es",
  },
  {
    id: "cb2b421b-685a-4668-b245-b567a52eae0b",
    value: "Cuidados paliativos",
    label: "Cuidados paliativos",
    slug: "cuidados-paliativos",
    businessCountry: "es",
  },
  {
    id: "6244b28e-e42c-4f79-a7b7-f6d14e94d813",
    value: "Déficit de memoria y atención",
    label: "Déficit de memoria y atención",
    slug: "deficit-memoria-atencion",
    businessCountry: "es",
  },
  {
    id: "c25fb65e-5574-47ea-99b9-883403ef1aae",
    value: "Depresión",
    label: "Depresión",
    slug: "depresion",
    businessCountry: "es",
  },
  {
    id: "dae337da-9a58-4db2-8c2d-7e4b3d66b92b",
    value: "Diabetes",
    label: "Diabetes",
    slug: "diabetes",
    businessCountry: "es",
  },
  {
    id: "06967205-1678-4180-8fa6-ba4056a6b80c",
    value: "Diversos tipos de demencia",
    label: "Diversos tipos de demencia",
    slug: "diversos-tipos-de-demencia",
    businessCountry: "es",
  },
  {
    id: "41fb7161-b2f4-400d-986e-260f05aced63",
    value: "ELA / EM",
    label: "ELA / EM",
    slug: "ela-esclerosis-multiple",
    businessCountry: "es",
  },
  {
    id: "771e3e81-f12f-4673-8509-21a2b16b5057",
    value: "Enfermedad de Alzheimer",
    label: "Enfermedad de Alzheimer",
    slug: "enfermedad-de-alzheimer",
    businessCountry: "es",
  },
  {
    id: "8c553e08-df9a-4b25-ad76-0ab54f2d12d0",
    value: "Enfermedad de EPOC",
    label: "Enfermedad de EPOC",
    slug: "enfermedad-de-epoc",
    businessCountry: "es",
  },
  {
    id: "53b518e4-24c0-4fb2-bbd8-97e7c504e705",
    value: "Enfermedad de Parkinson",
    label: "Enfermedad de Parkinson",
    slug: "enfermedad-de-parkinson",
    businessCountry: "es",
  },
  {
    id: "e4fd34a2-e316-4158-be65-eb4a5ed5e278",
    value: "Enfermedad de TOC",
    label: "Enfermedad de TOC",
    slug: "enfermedad-de-toc",
    businessCountry: "es",
  },
  {
    id: "e9c70544-fad1-4f25-a23d-856e6c11eaa6",
    value: "Epilepsia",
    label: "Epilepsia",
    slug: "epilepsia",
    businessCountry: "es",
  },
  {
    id: "5795f7cd-3fad-453d-883d-b212404324a2",
    value: "Esquizofrenia",
    label: "Esquizofrenia",
    slug: "esquizofrenia",
    businessCountry: "es",
  },
  {
    id: "6b3acb58-8b66-4ce2-994f-cd56833d7031",
    value: "Fibromialgia",
    label: "Fibromialgia",
    slug: "fibromialgia",
    businessCountry: "es",
  },
  {
    id: "b5055fc4-7935-44b8-b3e5-d76257806e9a",
    value: "Fracturas (cadera) y traumatismos",
    label: "Fracturas (cadera) y traumatismos",
    slug: "fracturas-cadera-y-traumatismos",
    businessCountry: "es",
  },
  {
    id: "5b1ad8bc-bfd5-4676-8802-f9151ae40fcd",
    value: "Hipertensión",
    label: "Hipertensión",
    slug: "hipertension",
    businessCountry: "es",
  },
  {
    id: "8a5a25d9-5f2d-4f86-9330-1f4622e1c1ca",
    value: "Ictus",
    label: "Ictus",
    slug: "ictus",
    businessCountry: "es",
  },
  {
    id: "9429dee3-4bdd-4283-acf8-3ebf33012472",
    value: "Inmovilidad",
    label: "Inmovilidad",
    slug: "inmovilidad",
    businessCountry: "es",
  },
  {
    id: "e9ca8244-fad1-4f95-a28d-846e6c91eaa6",
    value: "Insuficiencia renal",
    label: "Insuficiencia renal",
    slug: "insuficiencia-renal",
    businessCountry: "es",
  },
  {
    id: "0f4192fb-c708-43b0-92c0-30d5d5cdab2b",
    value: "Insuficiencia respiratoria/cardíaca",
    label: "Insuficiencia respiratoria/cardíaca",
    slug: "insuficiencia-respiratoria-cardiaca",
    businessCountry: "es",
  },
  {
    id: "a3c5a5ff-dedd-4baa-a580-78a90f543582",
    value: "Movilidad reducida",
    label: "Movilidad reducida",
    slug: "movilidad-reducida",
    businessCountry: "es",
  },
  {
    id: "8a5029d5-4bdb-457e-8056-00b26ebc71f7",
    value: "Obesidad mórbida",
    label: "Obesidad mórbida",
    slug: "obesidad-morbida",
    businessCountry: "es",
  },
  {
    id: "69fe871e-70a9-4eb9-969b-c666e9d1d207",
    value: "Osteoporosis",
    label: "Osteoporosis",
    slug: "osteoporosis",
    businessCountry: "es",
  },
  {
    id: "9ba75a2e-3f87-4a05-a35e-351b42f32b42",
    value: "Otras patologías",
    label: "Otras patologías",
    slug: "otras-patologias",
    businessCountry: "es",
  },
  {
    id: "6a2e12ed-2c5d-40ad-87eb-c476fec5c540",
    value: "Otras patologías psiquiátricas",
    label: "Otras patologías psiquiátricas",
    slug: "otras-patologias-psiquiatricas",
    businessCountry: "es",
  },
  {
    id: "53b1dcac-f1b3-43c7-be97-d24bd8d2e374",
    value: "Patologías sensoriales y auditivas",
    label: "Patologías sensoriales y auditivas",
    slug: "patologias-sensoriales-auditivas",
    businessCountry: "es",
  },
  {
    id: "043ee1ba-8a76-493b-832c-054ab8830927",
    value: "Problemas de la memoria y la atención",
    label: "Problemas de la memoria y la atención",
    slug: "problemas-de-la-memoria-y-la-atencion",
    businessCountry: "es",
  },
  {
    id: "d359e25e-87f4-4365-acd8-d52f24a3f432",
    value: "Síndrome de Down",
    label: "Síndrome de Down",
    slug: "sindrome-de-down",
    businessCountry: "es",
  },
  {
    id: "66f7e515-b356-4bd4-b188-e99067f5094d",
    value: "Sobrepeso",
    label: "Sobrepeso",
    slug: "sobrepeso",
    businessCountry: "es",
  },
  {
    id: "186d40cc-1a3c-44ca-a7ac-3d1251b93c9e",
    value: "Trastorno Bipolar",
    label: "Trastorno Bipolar",
    slug: "trastorno-bipolar",
    businessCountry: "es",
  },
  {
    id: "179e6ecb-d03e-4d02-bf47-9abbed54bdfe",
    value: "VIH",
    label: "VIH",
    slug: "vih",
    businessCountry: "es",
  },
  {
    id: "99df20d8-6fbe-4eeb-85da-9fb0ee7b0543",
    value: "Sin patologías",
    label: "Sin patologías",
    slug: "sin-patologias",
    businessCountry: "es",
  },
  {
    id: "8bc4693e-1d31-469c-a211-af1aac83153a",
    value: "Anemia",
    label: "Anémie",
    slug: "anemia",
    businessCountry: "fr",
  },
  {
    id: "86bb9192-4ba8-4973-9351-5bdc67a4b30f",
    value: "Artrosis",
    label: "Arthrose",
    slug: "artrosis",
    businessCountry: "fr",
  },
  {
    id: "7871f8ac-597d-4bc7-a0dd-9b0d2fb8d4e8",
    value: "Autismo",
    label: "Trouble du spectre de l’Autisme",
    slug: "autismo",
    businessCountry: "fr",
  },
  {
    id: "186d40cc-1a3c-44ca-a7ac-3d1251b93c9e",
    value: "Trastorno Bipolar",
    label: "Troubles bipolaires",
    slug: "bipolaridad",
    businessCountry: "fr",
  },
  {
    id: "6e255a60-be21-4d63-aa91-cf4e0d9d1dde",
    value: "Cáncer",
    label: "Cancer",
    slug: "cancer",
    businessCountry: "fr",
  },
  {
    id: "9b8539a6-cf2e-4537-9953-75b144d62f1b",
    value: "Cuerpos de Lewy",
    label: "Maladie à corps de Lewy",
    slug: "cuerpos-de-lewy",
    businessCountry: "fr",
  },
  // {
  //   id: "6244b28e-e42c-4f79-a7b7-f6d14e94d813",
  //   value: "Déficit de memoria y atención",
  //   label: "Déficit de memoria y atención",
  //   slug: "deficit-memoria-atencion",
  //   businessCountry: "fr",
  // },
  {
    id: "c25fb65e-5574-47ea-99b9-883403ef1aae",
    value: "Depresión",
    label: "Dépression",
    slug: "depresion",
    businessCountry: "fr",
  },
  {
    id: "dae337da-9a58-4db2-8c2d-7e4b3d66b92b",
    value: "Diabetes",
    label: "Diabète",
    slug: "diabetes",
    businessCountry: "fr",
  },
  {
    id: "06967205-1678-4180-8fa6-ba4056a6b80c",
    value: "Diversos tipos de demencia",
    label: "Démence sénile",
    slug: "diversos-tipos-de-demencia",
    businessCountry: "fr",
  },
  {
    id: "41fb7161-b2f4-400d-986e-260f05aced63",
    value: "ELA (Esclerosis múltiple)",
    label: "Sclérose latérale amyotrophique",
    slug: "ela-esclerosis-multiple",
    businessCountry: "fr",
  },
  {
    id: "771e3e81-f12f-4673-8509-21a2b16b5057",
    value: "Enfermedad de Alzheimer",
    label: "Alzheimer",
    slug: "enfermedad-de-alzheimer",
    businessCountry: "fr",
  },
  {
    id: "8c553e08-df9a-4b25-ad76-0ab54f2d12d0",
    value: "Enfermedad de EPOC",
    label: "Maladie pulmonaire obstructive chronique (MPOC)",
    slug: "enfermedad-de-epoc",
    businessCountry: "fr",
  },
  {
    id: "53b518e4-24c0-4fb2-bbd8-97e7c504e705",
    value: "Enfermedad de Parkinson",
    label: "Parkinson",
    slug: "enfermedad-de-parkinson",
    businessCountry: "fr",
  },
  {
    id: "e4fd34a2-e316-4158-be65-eb4a5ed5e278",
    value: "Enfermedad de TOC",
    label: "Troubles obsessionnels compulsifs",
    slug: "enfermedad-de-toc",
    businessCountry: "fr",
  },
  {
    id: "e9c70544-fad1-4f25-a23d-856e6c11eaa6",
    value: "Epilepsia",
    label: "Epilepsia",
    slug: "epilepsia",
    businessCountry: "fr",
  },
  {
    id: "5795f7cd-3fad-453d-883d-b212404324a2",
    value: "Esquizofrenia",
    label: "Schizophrénie",
    slug: "esquizofrenia",
    businessCountry: "fr",
  },
  // {
  //   id: "6b3acb58-8b66-4ce2-994f-cd56833d7031",
  //   value: "Fibromialgia",
  //   label: "Fibromialgia",
  //   slug: "fibromialgia",
  //   businessCountry: "fr",
  // },
  {
    id: "b5055fc4-7935-44b8-b3e5-d76257806e9a",
    value: "Fracturas (cadera) y traumatismos",
    label: "Fractures",
    slug: "fracturas-cadera-y-traumatismos",
    businessCountry: "fr",
  },
  {
    id: "5b1ad8bc-bfd5-4676-8802-f9151ae40fcd",
    value: "Hipertensión",
    label: "Hypertension",
    slug: "hipertension",
    businessCountry: "fr",
  },
  {
    id: "8a5a25d9-5f2d-4f86-9330-1f4622e1c1ca",
    value: "Ictus",
    label: "Accident vasculaire cérébral",
    slug: "ictus",
    businessCountry: "fr",
  },
  {
    id: "9429dee3-4bdd-4283-acf8-3ebf33012472",
    value: "Inmovilidad",
    label: "Paralysie",
    slug: "inmovilidad",
    businessCountry: "fr",
  },
  {
    id: "e9ca8244-fad1-4f95-a28d-846e6c91eaa6",
    value: "Insuficiencia renal",
    label: "Insuffisance rénale",
    slug: "insuficiencia-renal",
    businessCountry: "fr",
  },
  {
    id: "0f4192fb-c708-43b0-92c0-30d5d5cdab2b",
    value: "Insuficiencia respiratoria/cardíaca",
    label: "Insuffisance respiratoire/cardiaque",
    slug: "insuficiencia-respiratoria-cardiaca",
    businessCountry: "fr",
  },
  // {
  //   id: "a3c5a5ff-dedd-4baa-a580-78a90f543582",
  //   value: "Movilidad reducida",
  //   label: "Movilidad reducida",
  //   slug: "movilidad-reducida",
  //   businessCountry: "fr",
  // },
  // {
  //   id: "8a5029d5-4bdb-457e-8056-00b26ebc71f7",
  //   value: "Obesidad mórbida",
  //   label: "Obesidad mórbida",
  //   businessCountry: "fr",
  // },
  // {
  //   id: "6a2e12ed-2c5d-40ad-87eb-c476fec5c540",
  //   value: "Otras patologías psiquiátricas",
  //   label: "Otras patologías psiquiátricas",
  //   slug: "otras-patologias-psiquiatricas",
  //   businessCountry: "fr",
  // },
  {
    id: "53b1dcac-f1b3-43c7-be97-d24bd8d2e374",
    value: "Patologías sensoriales",
    label: "Déficiences sensorielles",
    slug: "patologias-sensoriales",
    businessCountry: "fr",
  },
  {
    id: "043ee1ba-8a76-493b-832c-054ab8830927",
    value: "Problemas de la memoria y la atención",
    label: "Problème de mémoire et d’attention",
    slug: "problemas-de-la-memoria-y-la-atencion",
    businessCountry: "fr",
  },
  // {
  //   id: "d359e25e-87f4-4365-acd8-d52f24a3f432",
  //   value: "Síndrome de Down",
  //   label: "Síndrome de Down",
  //   slug: "sindrome-de-down",
  //   businessCountry: "fr",
  // },
  // {
  //   id: "66f7e515-b356-4bd4-b188-e99067f5094d",
  //   value: "Sobrepeso",
  //   label: "Sobrepeso",
  //   slug: "sobrepeso",
  //   businessCountry: "es",
  // },
  {
    id: "179e6ecb-d03e-4d02-bf47-9abbed54bdfe",
    value: "VIH",
    label: "VIH",
    slug: "vih",
    businessCountry: "fr",
  },
  {
    id: "99df20d8-6fbe-4eeb-85da-9fb0ee7b0543",
    value: "Sin patologías",
    label: "Sans pathologies",
    slug: "sin-patologias",
    businessCountry: "fr",
  },
];

export function getPathologyLabelForCountry(
  pathologyValue: string,
  businessCountry: string
) {
  let filter = "es";
  if (businessCountry === "fr") {
    filter = "fr";
  }

  const found = pathologies.find(
    (item) => filter === item.businessCountry && item.value === pathologyValue
  );
  if (found) {
    return found.label;
  }

  return pathologyValue;
}

export function pathologiesList(country = "es") {
  return pathologies.filter(
    (pathology) => pathology.businessCountry === country
  );
}

export function pathologiesOptionsList(country = "es") {
  return pathologiesList(country).map((item) => ({
    value: item.value,
    label: item.label,
  }));
}

export default pathologies;
