import {
  Button,
  ClickAwayListener,
  Grow,
  IconButton,
  Popper,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ContactSupportOutlinedIcon from "@material-ui/icons/ContactSupportOutlined";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { Alert } from "@material-ui/lab";
import classNames from "classnames";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { TGetEmployeeItemUserFavorite } from "../../../../../../api/employee/employee.types";
import {
  COLOR_RED_NOTIFICATION,
  cuideoTheme,
} from "../../../../../../containers/themes/defaultTheme";
import { useFavoriteFormatter } from "../../hooks/useFavoriteFormatter";
import {
  TCreateFavoriteFn,
  TDeleteFavoriteFn,
  TUpdateFavoriteFn,
} from "../../types/UserFavoriteCandidate";

const StyledIconButton = styled(IconButton)`
  padding: 0.1875rem;
  border: 1px solid #c2d1da;
  .MuiSvgIcon-root {
    width: 1.125rem;
    height: 1.125rem;
    color: ${cuideoTheme.palette.primary.light};
  }
  &:hover {
    .MuiSvgIcon-root {
      color: ${cuideoTheme.palette.primary.main};
    }
  }

  &.Mui-selected {
    .MuiSvgIcon-root {
      color: ${COLOR_RED_NOTIFICATION};
    }
  }
`;

const StyledPaper = styled.div`
  width: 16rem;
  background-color: #fff;
  border-radius: 0.25rem;
  padding: 0.5rem 0.75rem;
  box-shadow: 0 2px 4px 0 rgba(0, 72, 120, 0.25);
`;

const StyledPaperTitle = styled(Typography)`
  text-align: center;
  color: ${cuideoTheme.palette.primary.main};
  font-weight: 700;
  font-size: 0.9375rem;
  line-height: 1.35;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
`;

const StyledTextField = styled(TextField)`
  margin: 0.5rem 0;

  .MuiInputBase-multiline {
    padding: 0.5rem 0.625rem;
    height: auto;
  }
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const StyledDeleteButton = styled(Button)`
  background-color: ${COLOR_RED_NOTIFICATION};
`;

const StyledSaveButton = styled(Button)``;

const StyledErrorAlert = styled(Alert)`
  font-size: 0.875rem;
  line-height: 1.35;
  padding: 0.125rem 0.5rem;
`;

const StyledHelpContent = styled.div`
  margin: 0.375rem 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const StyledHelpIconWrapper = styled.div`
  margin-right: 0.375rem;
  .MuiSvgIcon-root {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

const StyledHelpText = styled(Typography)`
  font-family: "Open Sans", sans-serif;
  font-size: 0.75rem;
  color: ${cuideoTheme.palette.primary.main};
  line-height: 1.35;
`;

interface IFavButtonProps {
  candidateId: string;
  favorite?: TGetEmployeeItemUserFavorite;
  onCreateFavorite: TCreateFavoriteFn;
  onUpdateFavorite: TUpdateFavoriteFn;
  onDeleteFavorite: TDeleteFavoriteFn;
}

export const FavButton: React.VFC<IFavButtonProps> = ({
  candidateId,
  favorite,
  onCreateFavorite,
  onUpdateFavorite,
  onDeleteFavorite,
}) => {
  const [state, setState] = useState({
    anchorEl: null as null | HTMLElement,
    note: favorite?.note ?? "",
    status: "idle" as "success" | "error" | "loading" | "idle",
    error: null as null | string,
  });
  const { formatMessage } = useIntl();
  const { formatFavoriteTooltipContent } = useFavoriteFormatter();

  const handleMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
    setState((prevState) => ({ ...prevState, anchorEl: e.currentTarget }));
  };

  const handleMenuClose = () => {
    setState((prevState) => ({ ...prevState, anchorEl: null, error: null }));
  };

  const handleNoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, note: e.target.value }));
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      status: "loading",
    }));
    try {
      if (!!favorite) {
        await onUpdateFavorite(favorite.id, state.note);
      } else {
        await onCreateFavorite(candidateId, state.note);
      }
      setState((prevState) => ({
        ...prevState,
        anchorEl: null,
        status: "success",
      }));
    } catch (e) {
      setState((prevState) => ({
        ...prevState,
        status: "error",
        error: formatMessage({
          id: "FavButton.Ha habido algún error.",
          defaultMessage: "Ha habido algún error.",
        }),
      }));
    }
  };

  const handleDelete = async () => {
    if (!favorite) {
      return;
    }

    setState((prevState) => ({
      ...prevState,
      status: "loading",
    }));
    try {
      await onDeleteFavorite(favorite.id);
      setState((prevState) => ({
        ...prevState,
        status: "success",
        note: "",
        anchorEl: null,
      }));
    } catch (e) {
      setState((prevState) => ({
        ...prevState,
        status: "error",
        error: formatMessage({
          id: "FavButton.Ha habido algún error.",
          defaultMessage: "Ha habido algún error.",
        }),
      }));
    }
  };

  return (
    <>
      <Tooltip title={formatFavoriteTooltipContent(favorite)}>
        <StyledIconButton
          // aria-label={favoritesText}
          onClick={handleMenuOpen}
          className={classNames({
            "Mui-selected": !!favorite,
          })}
        >
          {!!favorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
        </StyledIconButton>
      </Tooltip>
      <Popper
        open={!!state.anchorEl}
        anchorEl={state.anchorEl}
        placement="bottom"
        transition
        style={{
          // zIndex over the Tooltip
          zIndex: 10000,
        }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={150}>
            <StyledPaper>
              <ClickAwayListener onClickAway={handleMenuClose}>
                <div>
                  <StyledPaperTitle>
                    {!!favorite ? (
                      <FormattedMessage
                        id="FavButton.¿Quieres modificar o eliminar el favorito?"
                        defaultMessage="¿Quieres modificar o eliminar el favorito?"
                      />
                    ) : (
                      <FormattedMessage
                        id="FavButton.¿Quieres agregar el candidato como favorito?"
                        defaultMessage="¿Quieres agregar el candidato como favorito?"
                      />
                    )}
                  </StyledPaperTitle>
                  <form onSubmit={handleSubmit}>
                    {!!state.error && (
                      <StyledErrorAlert severity="error">
                        {state.error}
                      </StyledErrorAlert>
                    )}
                    <StyledTextField
                      variant="outlined"
                      multiline
                      fullWidth
                      minRows={3}
                      maxRows={5}
                      autoFocus
                      placeholder={formatMessage({
                        id: "FavButton.Puedes escribir un motivo",
                        defaultMessage: "Puedes escribir un motivo",
                      })}
                      value={state.note}
                      onChange={handleNoteChange}
                    />
                    <StyledHelpContent>
                      <StyledHelpIconWrapper>
                        <ContactSupportOutlinedIcon color="primary" />
                      </StyledHelpIconWrapper>
                      <StyledHelpText>
                        <FormattedMessage
                          id="FavButton.Aparecerá en la lista de sugeridos de tus contratos de la zona"
                          defaultMessage="Aparecerá en la lista de sugeridos de tus contratos de la zona"
                        />
                      </StyledHelpText>
                    </StyledHelpContent>
                    <StyledButtonsContainer>
                      <div>
                        {!!favorite && (
                          <StyledDeleteButton
                            variant="contained"
                            onClick={handleDelete}
                            size="small"
                            color="primary"
                            disabled={state.status === "loading"}
                          >
                            <FormattedMessage
                              id="FavButton.Eliminar"
                              defaultMessage="Eliminar"
                            />
                          </StyledDeleteButton>
                        )}
                      </div>
                      <StyledSaveButton
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="small"
                        disabled={state.status === "loading"}
                      >
                        {!!favorite ? (
                          <FormattedMessage
                            id="FavButton.Modificar"
                            defaultMessage="Modificar"
                          />
                        ) : (
                          <FormattedMessage
                            id="FavButton.Añadir"
                            defaultMessage="Añadir"
                          />
                        )}
                      </StyledSaveButton>
                    </StyledButtonsContainer>
                  </form>
                </div>
              </ClickAwayListener>
            </StyledPaper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
