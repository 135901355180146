import {
  ROLE_BOT_TESTER,
  ROLE_COMMERCIAL,
  ROLE_COORDINATION,
  ROLE_MANAGER,
  TUserRole,
  USER_ROLES,
} from "../../../../api/security";

export type TStaff = {
  userId: string;
  userAvatar: string | null;
  userPreferredLanguage: string;
  userName: string;
  userEmail: string;
  userFullName: string;
  userRoles: TUserRole[];
};

export const userHasRole = (staffUser: TStaff, role: TUserRole): boolean => {
  if (staffUser.userRoles.indexOf(role) !== -1) {
    return true;
  }

  return false;
};

export const userCanFollowUpService = (staffUser: TStaff): boolean => {
  if (userHasRole(staffUser, ROLE_COMMERCIAL)) {
    return true;
  }

  if (userHasRole(staffUser, ROLE_MANAGER)) {
    return true;
  }

  return false;
};

export const userCanValueService = (staffUser: TStaff): boolean => {
  if (userHasRole(staffUser, ROLE_COORDINATION)) {
    return true;
  }

  if (userHasRole(staffUser, ROLE_MANAGER)) {
    return true;
  }

  return false;
};

export const userCanLaunchTestBot = (staffUser: TStaff): boolean => {
  if (userHasRole(staffUser, ROLE_BOT_TESTER)) {
    return true;
  }
  return false;
};

export const userIsRecruiter = (staffUser: TStaff): boolean => {
  if (userHasRole(staffUser, USER_ROLES.ROLE_MANAGER)) {
    return false;
  }

  if (userHasRole(staffUser, USER_ROLES.ROLE_ADMIN)) {
    return false;
  }

  if (userHasRole(staffUser, USER_ROLES.ROLE_ES_RECRUITER)) {
    return true;
  }

  return false;
};

export const userIsPromoter = (staffUser: TStaff): boolean => {
  if (userHasRole(staffUser, USER_ROLES.ROLE_MANAGER)) {
    return false;
  }

  if (userHasRole(staffUser, USER_ROLES.ROLE_ADMIN)) {
    return false;
  }

  if (userHasRole(staffUser, USER_ROLES.ROLE_ES_PROMOTER)) {
    return true;
  }

  return false;
};
