import { Backdrop, Grid } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { hideMessage, hideSnackMessage, modalClose } from "../../actions";
import cuideoTheme from "../../containers/themes/defaultTheme";
import ErrorModal from "./ErrorModal";
import FixedErrorModal from "./FixedErrorModal";
import SuccessModal from "./SuccessModal";

interface StyledSnackbarProp {
  snacktype: "info" | "error" | "warning" | "success";
}

const StyledSnackbar = styled(Snackbar)<StyledSnackbarProp>`
  .MuiSnackbarContent-root {
    background-color: ${({ snacktype }) => {
      if (snacktype === "info") return cuideoTheme.palette.primary.main;
      if (snacktype === "error") return cuideoTheme.palette.error.main;
      if (snacktype === "warning") return "#ebc200";
      return "#43a047";
    }};
    color: #fff;
  }
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: 10;
`;

const InfoView = (props: any) => {
  const { modal, showBackdrop, modalClose } = props;
  const [snackMessageText, setSnackMessageText] = useState("");
  const [open, setOpen] = useState(false);

  const { snackMessage, hideSnackMessage } = props as any;
  const newMessage = snackMessage ? snackMessage.message : null;
  if (!open && newMessage && snackMessageText !== newMessage) {
    setSnackMessageText(newMessage);
    setOpen(true);
  }

  let showMessage = "";
  if (snackMessage) {
    showMessage = snackMessage.message;
  }

  const snacktype = snackMessage ? snackMessage.type : null;

  let snackIcon = <CheckCircleIcon />;
  if (snackMessage && snackMessage.type === "error") {
    snackIcon = <ErrorIcon />;
  }

  const handleDialogClose = () => {
    modalClose();
  };

  return (
    <Fragment>
      <StyledSnackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={open}
        key={showMessage}
        autoHideDuration={5000}
        onClose={() => {
          setOpen(false);
          hideSnackMessage();
          setSnackMessageText("");
        }}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={
          <Grid container spacing={4} wrap="nowrap">
            <Grid item>{snackIcon}</Grid>
            <Grid item style={{ lineHeight: "1.5rem" }}>
              {showMessage}
            </Grid>
          </Grid>
        }
        snacktype={snacktype}
      />
      {modal.type && modal.type === "error_fixed_message" && (
        <FixedErrorModal modal={modal} handleDialogClose={handleDialogClose} />
      )}
      {modal.type && modal.type === "error" && (
        <ErrorModal modal={modal} handleDialogClose={handleDialogClose} />
      )}
      {modal.type && modal.type === "success" && (
        <SuccessModal modal={modal} handleDialogClose={handleDialogClose} />
      )}
      <StyledBackdrop open={showBackdrop}></StyledBackdrop>
    </Fragment>
  );
};

const mapStateToProps = ({
  commonData,
  modal,
}: {
  commonData: any;
  modal: any;
}) => {
  const { snackMessage, showBackdrop } = commonData;

  return { snackMessage, showBackdrop, modal };
};

export default connect(mapStateToProps, {
  hideMessage,
  hideSnackMessage,
  modalClose,
})(InfoView);
