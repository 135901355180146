import {
  Button,
  Card,
  CardActions,
  CardContent,
  Slide,
  Typography,
} from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { FormattedMessage } from "react-intl";
import { TGetServicesCollectionItem } from "../../../../api/service/service.types";

const StyledReminder = styled(Card)`
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 1rem;
  max-width: 20rem;
  background-color: rgb(255, 244, 229);
  border: 0.0625rem solid rgba(102, 60, 0, 0.15);
`;

const StyledReminderContent = styled(CardContent)``;
const StyledCardActions = styled(CardActions)`
  justify-content: space-between;
`;

const StyledTitle = styled(Typography)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  vertical-align: unset;
  font-size: 1.1rem;
  text-align: center;
`;

const StyledRemindLaterButton = styled(Button)`
  font-weight: 400;
`;

const StyledRateButton = styled(Button)``;

interface IDelayedServiceReminder {
  service: TGetServicesCollectionItem;
  onDelay: (serviceId: string) => void;
}

const DelayedServiceReminder = (props: IDelayedServiceReminder) => {
  const { service, onDelay } = props;
  const dispatch = useDispatch();

  const handleDelay = () => {
    onDelay(service.id);
  };

  const handleValuationClick = () => {
    dispatch(push(encodeURI(`/service/${service.id}`)));
  };

  return (
    <Slide direction="up" in={true} mountOnEnter unmountOnExit>
      <StyledReminder>
        <StyledReminderContent>
          <StyledTitle>
            <NotificationImportantIcon fontSize="large" />
            <FormattedMessage
              id="DelayedServiceReminder.Tienes el servicio #{serviceName} pospuesto y pendiente de valorar"
              defaultMessage="Tienes el servicio #{serviceName} pospuesto y pendiente de valorar"
              values={{ serviceName: service.Name }}
            />
          </StyledTitle>
        </StyledReminderContent>
        <StyledCardActions>
          <StyledRemindLaterButton variant="outlined" onClick={handleDelay}>
            <FormattedMessage
              id="DelayedServiceReminder.Recordar más tarde"
              defaultMessage="Recordar más tarde"
            />
          </StyledRemindLaterButton>
          <StyledRateButton
            color="primary"
            variant="contained"
            onClick={handleValuationClick}
          >
            <FormattedMessage
              id="DelayedServiceReminder.Valorar"
              defaultMessage="Valorar"
            />
          </StyledRateButton>
        </StyledCardActions>
      </StyledReminder>
    </Slide>
  );
};

export default DelayedServiceReminder;
