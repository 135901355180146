import { Grid } from "@material-ui/core";
import moment from "moment";
import "moment/locale/es";
import React, { Fragment } from "react";
import { IntlProvider } from "react-intl";
import ExperienciasIcon from "../../../assets/images/iconos/experiencias.svg";
import FormacionesIcon from "../../../assets/images/iconos/formaciones.svg";
import TareasIcon from "../../../assets/images/iconos/tareas.svg";
import LogoBlanco from "../../../assets/images/logo.svg";
import AppLocale from "../../../lngProvider";
import CuideoUserAvatar from "../../CuideoUserAvatar";
import useFormatters from "../../FormattedFields/useFormatters";
import {
  StyledBigGridCell,
  StyledBody,
  StyledCuideoLogo,
  StyledCurve,
  StyledData,
  StyledDataItem,
  StyledEmployeeAvatar,
  StyledEmployeeName,
  StyledEmployeeProfile,
  StyledEmployeeTitle,
  StyledEmployeeWrapper,
  StyledExperience,
  StyledExperienceDescription,
  StyledExperiencesSection,
  StyledExperienceTitle,
  StyledFormation,
  StyledFormationDescription,
  StyledFormationTitle,
  StyledFormationValid,
  StyledHeader,
  StyledIcon,
  StyledLabel,
  StyledPathologiesTitle,
  StyledPathology,
  StyledSection,
  StyledSectionIcon,
  StyledSectionLabel,
  StyledSectionTitle,
  StyledSexText,
  StyledTask,
} from "./EmployeeProfileSC";
import {
  employeeIsMale,
  TEmployeePublicProfile,
} from "./models/EmployeePublicProfile";

interface IEmployeeProfileFRProps {
  employee: TEmployeePublicProfile;
}

const IntlEmployeeProfileFR = (props: IEmployeeProfileFRProps) => {
  const { employee } = props;
  const locale = AppLocale["fr"];

  return (
    <IntlProvider locale="fr" messages={locale.messages}>
      <EmployeeProfileFR employee={employee} />
    </IntlProvider>
  );
};

const EmployeeProfileFR = (props: IEmployeeProfileFRProps) => {
  const { employee } = props;
  const { formatCountry, formatLanguage, formatTask, formatPathology } =
    useFormatters();

  const tasks = employee.Qu_tareas_quieres_o_puedes_realizar ?? [];
  const formations = employee.Formaciones ?? [];
  const translatedLanguages =
    employee.Otros_Idiomas?.map((lang: string) => {
      return formatLanguage(lang);
    }) ?? [];
  const country = employee.Lugar_de_nacimiento
    ? formatCountry(employee.Lugar_de_nacimiento)
    : "";
  const languages = translatedLanguages.join(", ").trim();

  return (
    <StyledEmployeeProfile>
      <StyledHeader>
        <StyledEmployeeWrapper>
          <StyledEmployeeAvatar>
            <CuideoUserAvatar
              user={{
                userAvatar: employee.No_mostrar_imagen
                  ? undefined
                  : employee.avatarUrl,
              }}
            />

            <StyledEmployeeTitle>
              <StyledSexText>
                {employeeIsMale(employee) ? "Fiche Candidat" : "Fiche Candidat"}
              </StyledSexText>
              <StyledEmployeeName>
                {employee.Nombre} {employee.Apellidos}
              </StyledEmployeeName>
            </StyledEmployeeTitle>
          </StyledEmployeeAvatar>
        </StyledEmployeeWrapper>
        <StyledCuideoLogo src={LogoBlanco}></StyledCuideoLogo>
      </StyledHeader>
      <StyledCurve viewBox="0 0 100 30" preserveAspectRatio="none">
        <path fill="#004b7b" d="M 0,30L35,30C55,25,45,5,65,0L100,0L0,0" />
      </StyledCurve>

      <StyledBody>
        <Grid container spacing={8}>
          <Grid item xs={12} md={6}>
            <StyledBigGridCell>
              {employee.W_Breve_descripci_n && (
                <StyledSection>{employee.W_Breve_descripci_n}</StyledSection>
              )}
              <StyledSection>
                <StyledDataItem>
                  <StyledLabel>Localisation</StyledLabel>
                  <StyledData>
                    {employee.Ciudad}, {employee.D_partement}
                  </StyledData>
                </StyledDataItem>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    {employee.Fecha_de_nacimiento && (
                      <StyledDataItem>
                        <StyledLabel>Date de naissance</StyledLabel>
                        <StyledData>
                          {moment(employee.Fecha_de_nacimiento).format(
                            "DD/MM/YYYY"
                          )}
                        </StyledData>
                      </StyledDataItem>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <StyledDataItem>
                      <StyledLabel>Pays de naissance</StyledLabel>
                      <StyledData>{country}</StyledData>
                    </StyledDataItem>
                  </Grid>
                </Grid>
                <StyledDataItem>
                  <StyledLabel>Langues</StyledLabel>
                  <StyledData>{languages}</StyledData>
                </StyledDataItem>
              </StyledSection>
              <StyledSection>
                <StyledSectionTitle>
                  <StyledSectionLabel>
                    Tâches qu'il / elle peut réaliser
                  </StyledSectionLabel>
                  <StyledSectionIcon>
                    <StyledIcon src={TareasIcon} />
                  </StyledSectionIcon>
                </StyledSectionTitle>
                <Grid container spacing={8}>
                  <Grid item xs={6}>
                    {tasks
                      .filter((task, index) => !(index % 2))
                      .map((task) => (
                        <StyledTask key={task}>{formatTask(task)}</StyledTask>
                      ))}
                  </Grid>
                  <Grid item xs={6}>
                    {tasks
                      .filter((task, index) => index % 2)
                      .map((task) => (
                        <StyledTask key={task}>{formatTask(task)}</StyledTask>
                      ))}
                  </Grid>
                </Grid>
              </StyledSection>
              {((employee.Titulaci_n_oficial_SAD &&
                !!employee.Titulaci_n_oficial_SAD.length) ||
                (employee.Formaciones && employee.Formaciones.length > 0)) && (
                <StyledSection>
                  <StyledSectionTitle>
                    <StyledSectionLabel>Formation :</StyledSectionLabel>
                    <StyledSectionIcon>
                      <StyledIcon src={FormacionesIcon} />
                    </StyledSectionIcon>
                  </StyledSectionTitle>
                  {employee.Titulaci_n_oficial_SAD &&
                    !!employee.Titulaci_n_oficial_SAD.length && (
                      <StyledDataItem>
                        <StyledLabel>Qualification officielle</StyledLabel>
                        <StyledData>
                          {employee.Titulaci_n_oficial_SAD[0]}
                        </StyledData>
                      </StyledDataItem>
                    )}
                  {formations.map((item: any, index: number) => {
                    return (
                      <StyledFormation key={`for_${index}`}>
                        <StyledFormationTitle>
                          {item.Nombre_escuela_centro}
                        </StyledFormationTitle>
                        <StyledFormationDescription>
                          {item.Nombre_del_curso}
                        </StyledFormationDescription>
                        <StyledFormationValid>
                          {item.Titulo_valido_en_Espa_a &&
                          item.Titulo_valido_en_Espa_a.toLowerCase() === "si"
                            ? "Valide en France"
                            : "n'est pas valide en France"}
                        </StyledFormationValid>
                      </StyledFormation>
                    );
                  })}
                </StyledSection>
              )}
            </StyledBigGridCell>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledBigGridCell>
              {employee.Experiencia_y_referencias &&
                employee.Experiencia_y_referencias.length > 0 && (
                  <StyledExperiencesSection>
                    <StyledSectionTitle>
                      <StyledSectionLabel>Expérience :</StyledSectionLabel>
                      <StyledSectionIcon>
                        <StyledIcon src={ExperienciasIcon} />
                      </StyledSectionIcon>
                    </StyledSectionTitle>
                    {employee.Experiencia_y_referencias.map((item, index) => {
                      return (
                        <StyledExperience key={"exp" + index}>
                          <StyledExperienceTitle>
                            {item.Empresa_o_famila}
                          </StyledExperienceTitle>
                          <StyledExperienceDescription>
                            {item.Funciones_y_tareas_realizadas}
                          </StyledExperienceDescription>
                          {item.Patolog_as_tratadas &&
                            item.Patolog_as_tratadas.length > 0 && (
                              <Fragment>
                                <StyledPathologiesTitle>
                                  Pathologies traitées
                                </StyledPathologiesTitle>
                                <Grid container spacing={4}>
                                  <Grid item xs={6}>
                                    {item.Patolog_as_tratadas.filter(
                                      (itemPat, indexPat) => !(indexPat % 2)
                                    ).map((itemPat, indexPat) => (
                                      <StyledPathology
                                        key={index + "pat" + indexPat}
                                      >
                                        {formatPathology(itemPat)}
                                      </StyledPathology>
                                    ))}
                                  </Grid>
                                  <Grid item xs={6}>
                                    {item.Patolog_as_tratadas.filter(
                                      (itemPat, indexPat) => indexPat % 2
                                    ).map((itemPat, indexPat) => (
                                      <StyledPathology
                                        key={index + "pat" + indexPat}
                                      >
                                        {formatPathology(itemPat)}
                                      </StyledPathology>
                                    ))}
                                  </Grid>
                                </Grid>
                              </Fragment>
                            )}
                        </StyledExperience>
                      );
                    })}
                  </StyledExperiencesSection>
                )}
            </StyledBigGridCell>
          </Grid>
        </Grid>
      </StyledBody>
    </StyledEmployeeProfile>
  );
};

export default IntlEmployeeProfileFR;
