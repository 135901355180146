import { Grid, IconButton, Tooltip } from "@material-ui/core";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/Phone";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { TGetEmployeeItemUserFavorite } from "../../api/employee/employee.types";
import CreateCommunicationIntentsButton from "../../apps/affinity/components/CreateCommIntentsButton";
import {
  ESquad,
  SQUADS_ABLE_TO_RUN_BOT,
} from "../../apps/affinity/features/squad/types/squad.types";
import cuideoTheme from "../../containers/themes/defaultTheme";
import { VALORACION_RECOMENDACION } from "../../util/defaults/valoracion";

const StyledActionsWrapper = styled.div``;

const StyledIconButton = styled(IconButton)`
  padding: 0.33rem;
  border: 1px solid #c2d1da;
  .MuiSvgIcon-root {
    color: ${cuideoTheme.palette.primary.light};
  }
  &:hover {
    .MuiSvgIcon-root {
      color: ${cuideoTheme.palette.primary.main};
    }
  }
`;

interface IEmployeeStaffActionsProps {
  employee: {
    id: string;
    botAvailable: boolean;
    numOpenCommunicationIntents: number;
    numCommunicationIntentsLastDay?: number;
    Phone?: string;
    Email?: string;
    favoritedBy?: TGetEmployeeItemUserFavorite[];
  };
  valuation?: {
    id: string;
    Estado_en_proceso: string;
    contrato?: {
      id: string;
      Ciudad_oferta?: string;
      Ciudad_Servicio?: string;
      Horario_Servicio?: string;
      Salario_Bruto_Mensual?: number;
      Squad?: ESquad;
    };
  };
  onCandidateChange: () => void;
}

const EmployeeStaffActions: React.FC<IEmployeeStaffActionsProps> = ({
  employee,
  valuation,
  onCandidateChange,
}) => {
  const { formatMessage } = useIntl();

  const canUseBot =
    valuation?.contrato?.Squad &&
    SQUADS_ABLE_TO_RUN_BOT.includes(valuation.contrato.Squad);

  const sendToWhatsappText = formatMessage({
    id: "EmployeeStaffActions.Enviar whatsapp",
    defaultMessage: "Enviar whatsapp",
  });

  const sendEmailText = formatMessage({
    id: "EmployeeStaffActions.Enviar email",
    defaultMessage: "Enviar email",
  });

  const callText = formatMessage(
    {
      id: "EmployeeStaffActions.Llamar por teléfono a {candidatePhone}",
      defaultMessage: "Llamar por teléfono a {candidatePhone}",
    },
    { candidatePhone: employee.Phone }
  );

  return (
    <StyledActionsWrapper>
      <Grid container spacing={4}>
        {!!employee.Phone && typeof employee.Phone === "string" && (
          <Grid item>
            <Tooltip title={sendToWhatsappText}>
              <StyledIconButton
                aria-label={sendToWhatsappText}
                onClick={(e: any) => {
                  window.open(
                    "https://api.whatsapp.com/send?phone=" +
                      employee.Phone?.replace("+", ""),
                    "_blank"
                  );
                }}
              >
                <WhatsAppIcon />
              </StyledIconButton>
            </Tooltip>
          </Grid>
        )}
        {employee.Email && (
          <Grid item>
            <Tooltip title={sendEmailText}>
              <a href={`mailto:${employee.Email}`}>
                <StyledIconButton aria-label={sendEmailText}>
                  <MailOutlineIcon />
                </StyledIconButton>
              </a>
            </Tooltip>
          </Grid>
        )}
        {employee.Phone && (
          <Grid item>
            <Tooltip title={callText}>
              <a href={`tel:${employee.Phone}`}>
                <StyledIconButton aria-label={callText}>
                  <PhoneIcon />
                </StyledIconButton>
              </a>
            </Tooltip>
          </Grid>
        )}
        {!!valuation &&
          !!valuation.contrato &&
          canUseBot &&
          valuation.Estado_en_proceso === VALORACION_RECOMENDACION && (
            <Grid item>
              <CreateCommunicationIntentsButton
                contractId={valuation.contrato.id}
                employeeIds={[employee.id]}
              />
            </Grid>
          )}
      </Grid>
    </StyledActionsWrapper>
  );
};

export default EmployeeStaffActions;
