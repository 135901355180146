import { useMutation } from "react-query";
import { apiMarkMineWaAsDelivered } from "../../../../../api/notification/apiMarkMineWaAsDelivered";
import { MutationConfig, queryClient } from "../../../../../lib/react-query";
import { badgesKeys } from "../../badges";
import { notificationKeys } from "./notification.query-keys";

type TUseMarkWaNotificationsAsDelivered = {
  config?: MutationConfig<typeof apiMarkMineWaAsDelivered>;
};

export const useMarkWaNotificationsAsDelivered = ({
  config,
}: TUseMarkWaNotificationsAsDelivered = {}) => {
  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries(notificationKeys.all);
    },
    onSettled: () => {
      queryClient.invalidateQueries(notificationKeys.all);
      queryClient.invalidateQueries(badgesKeys.mine);
    },
    ...config,
    mutationFn: apiMarkMineWaAsDelivered,
  });
};
