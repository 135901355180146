import React from "react";
import styled from "styled-components";
import cuideoTheme from "../../containers/themes/defaultTheme";
import { EMPLOYEE_STATUS } from "../../api/employee/employee.types";

const StyledTag = styled.span<{ estado: any }>`
  background-color: #ccc;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 0.8rem;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-weight: 700;
  border-radius: ${cuideoTheme.shape.borderRadius}px;

  ${(props) => {
    switch (props.estado) {
      case EMPLOYEE_STATUS.DISCARDED:
        return `
          background-color: orange;
          color: ${cuideoTheme.palette.primary.main};
        `;
      case EMPLOYEE_STATUS.NOT_VALIDATED:
        return `
          background-color: ${cuideoTheme.palette.grey[300]};
          color: ${cuideoTheme.palette.primary.main};
          `;
      case EMPLOYEE_STATUS.VALIDATED_PLUS:
        return `
          background-color: #ebc200;
          color: ${cuideoTheme.palette.primary.main};
        `;
      case EMPLOYEE_STATUS.ACTIVE:
        return `
          background-color: green;
          color: ${cuideoTheme.palette.common.white};
        `;
      default:
        return `
          background-color: ${cuideoTheme.palette.primary.light};
          color: ${cuideoTheme.palette.primary.contrastText};
        `;
    }
  }}
`;

const CandidatoEstadoTag = (props: any) => {
  const { estado } = props;

  let parsedEstado = estado;
  if (!parsedEstado) {
    parsedEstado = EMPLOYEE_STATUS.NOT_VALIDATED;
  }
  if (parsedEstado === "Validado T") {
    parsedEstado = EMPLOYEE_STATUS.VALIDATED;
  }
  if (parsedEstado === "Validado E") {
    parsedEstado = EMPLOYEE_STATUS.VALIDATED;
  }

  return (
    <StyledTag className="MuiCuideo-CandidatoEstado" estado={parsedEstado}>
      {parsedEstado}
    </StyledTag>
  );
};

export default CandidatoEstadoTag;
