import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import "moment/locale/es";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import ReactPlayer from "react-player";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { getPartialCandidatoSuccess, modalShow } from "../../../actions";
import HireflixLogo from "../../../assets/images/iconos/hireflix.png";
import useSnacks from "../../../components/hooks/useSnacks";
import cuideoTheme from "../../../containers/themes/defaultTheme";
import useEmployeeVideoInterviewQuery from "../../../queries/useEmployeeVideoInterviewQuery";
import { apiEmpleo } from "../../../util/ApiEmpleo";

const StyledWrapper = styled.div`
  margin: 1rem;
  max-width: 58.75rem;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2rem 0 0.5rem;
  justify-content: space-between;
`;

const StyledTitle = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-size: 1.875rem;
  font-weight: 700;
`;

const StyledContent = styled.div`
  .CMuiVideoInterviewSubtitle {
    color: ${cuideoTheme.palette.primary.main};
    font-weight: 700;
    font-size: 1.125rem;
  }

  .CMuiVideoInterviewLeft {
    display: flex;
    flex-direction: column;
    border-right: 1px solid ${cuideoTheme.palette.divider};
    padding-right: 1rem;
  }
  .CMuiVideoInterviewRight {
    padding-left: 1rem;
  }
  .CMuiVideoInterviewGenerationContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    .CMuiVideoInterviewDataBox {
      margin-top: -4rem;
    }
  }
  .CMuiVideoInterviewResponseContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    min-height: 32.5rem;
  }

  .CMuiVideoInterviewDataRow {
    margin: 1rem 0;
  }
  .CMuiVideoInterviewDataLabel {
    font-size: 1rem;
    line-height: 1.25;
  }
  .CMuiVideoInterviewDataValue {
    font-size: 1rem;
    line-height: 1.25;
    font-weight: 700;
    text-align: right;
  }

  .CMuiVideoInterviewActions {
    width: 80%;
    margin: 1rem auto;
    max-width: 18rem;

    .CMuiVideoInterviewButton {
      margin: 1rem 0;
    }
  }

  .CMuiVideoInterviewHireflixLink {
    text-decoration: none;
    display: block;
    margin: 1.75rem auto;
    text-align: center;
  }
  .CMuiVideoInterviewHireflixButton {
    .CMuiVideoInterviewHireflixLogo {
      width: 1.25rem;
      height: auto;
      margin-right: 0.5rem;
      display: block;
    }
  }

  .CMuiVideoInterviewWaitingMessage,
  .CMuiVideoInterviewNoInterviewMessage {
    text-align: center;
    line-height: 1.35;
    margin-top: -4rem;
  }

  .CMuiVideoInterviewNoInterview {
    text-align: center;
    line-height: 1.35;
    margin-top: 0;
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  width: 1.5rem;
  height: 1.5rem;
  color: #fff;
`;

const StyledTextArea = styled.textarea`
  opacity: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

const StyledPlayerWrapper = styled.div`
  margin: 1rem 0 0;
`;

const StyledVideoWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 75%;
`;

const StyledVideoInnerWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

const StyledPlaylist = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  width: 100%;
  overflow: hidden;
`;

const StyledPlaylistItem = styled.div`
  flex: 1 1 0px;
  padding: 0.25rem;

  .CMuiVideoInterviewPlaylistItemImg {
    width: 100%;
    height: auto;
  }

  &:hover,
  &:focus {
    background-color: rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }
`;

const StyledPlaylistItemTitle = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 700;
  text-align: center;
  line-height: 1.25;
  color: ${cuideoTheme.palette.primary.main};
`;

type TInterviewState =
  | "none"
  | "generating"
  | "pending"
  | "finalist"
  | "completed";

type TVideoInterviewTabProps = {
  employee: {
    id: string;
    ID_Videoentrevista?: string;
    Link_Publico?: string;
    Link_Privado?: string;
    interviewOwnerName?: string;
    interviewStatus?: "completed" | "pending" | "finalist" | string;
    interviewQuestions?: {
      title?: string;
      description?: string;
      thumbnail?: string;
      url?: string;
    }[];
    Fecha_de_Videoentrevista?: string;
    Fecha_creacion_Videoentrevista?: string;
  };
};

const VideoInterviewTab = (props: TVideoInterviewTabProps) => {
  const { employee } = props;
  const textRef = useRef<HTMLTextAreaElement>(null);
  const { snackSuccessMessage, snackErrorMessage } = useSnacks();
  const dispatch = useDispatch();

  const [interviewState, setInterviewState] = useState<TInterviewState>("none");
  const [linkCopied, setLinkCopied] = useState(false);
  const [videoPlaying, setVideoPlaying] = useState(0);

  const { data } = useEmployeeVideoInterviewQuery(employee.id);

  useEffect(() => {
    if (data) {
      const newEmployeeData = {
        id: employee.id,
      } as any;

      if (
        data.Fecha_creacion_Videoentrevista &&
        data.Fecha_creacion_Videoentrevista !==
          employee.Fecha_creacion_Videoentrevista
      ) {
        newEmployeeData.Fecha_creacion_Videoentrevista =
          data.Fecha_creacion_Videoentrevista;
      }
      if (data.Link_Publico && data.Link_Publico !== employee.Link_Publico) {
        newEmployeeData.Link_Publico = data.Link_Publico;
      }
      if (data.Link_Privado && data.Link_Privado !== employee.Link_Privado) {
        newEmployeeData.Link_Privado = data.Link_Privado;
      }
      if (
        data.ID_Videoentrevista &&
        data.ID_Videoentrevista !== employee.ID_Videoentrevista
      ) {
        newEmployeeData.ID_Videoentrevista = data.ID_Videoentrevista;
      }
      if (
        data.interviewOwnerName &&
        data.interviewOwnerName !== employee.interviewOwnerName
      ) {
        newEmployeeData.interviewOwnerName = data.interviewOwnerName;
      }
      if (
        data.interviewStatus &&
        data.interviewStatus !== employee.interviewStatus
      ) {
        newEmployeeData.interviewStatus = data.interviewStatus;
      }
      if (
        data.interviewQuestions &&
        data.interviewQuestions !== employee.interviewQuestions
      ) {
        newEmployeeData.interviewQuestions = data.interviewQuestions;
      }

      if (
        newEmployeeData.Fecha_creacion_Videoentrevista ||
        newEmployeeData.Fecha_de_Videoentrevista ||
        newEmployeeData.ID_Videoentrevista ||
        newEmployeeData.Link_Privado ||
        newEmployeeData.Link_Publico ||
        newEmployeeData.interviewOwnerName ||
        newEmployeeData.interviewStatus ||
        newEmployeeData.interviewQuestions
      ) {
        dispatch(
          getPartialCandidatoSuccess({
            partialCandidatos: [newEmployeeData],
          })
        );
      } else {
      }
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (linkCopied) {
      setTimeout(() => {
        setLinkCopied(false);
      }, 2000);
    }
  }, [linkCopied]);

  const handleGenerateClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    const sendInterviewRequest = async (employeeId: string) => {
      try {
        setInterviewState("generating");
        let res = await apiEmpleo.put(
          `candidatos/${employeeId}/video-interview`
        );
        setInterviewState("pending");
        dispatch(
          getPartialCandidatoSuccess({
            partialCandidatos: [
              {
                id: employeeId,
                Fecha_creacion_Videoentrevista:
                  res.Fecha_creacion_Videoentrevista
                    ? res.Fecha_creacion_Videoentrevista
                    : undefined,
                Fecha_de_Videoentrevista: res.Fecha_de_Videoentrevista
                  ? res.Fecha_de_Videoentrevista
                  : undefined,
                Link_Publico: res.Link_Publico ? res.Link_Publico : undefined,
                Link_Privado: res.Link_Privado ? res.Link_Privado : undefined,
                ID_Videoentrevista: res.ID_Videoentrevista
                  ? res.ID_Videoentrevista
                  : undefined,
                interviewOwnerName: res.interviewOwnerName
                  ? res.interviewOwnerName
                  : undefined,
                interviewStatus: "pending",
              },
            ],
          })
        );
        snackSuccessMessage("Se ha generado una entrevista");
      } catch (e) {
        // @ts-ignore
        if (e.response.status === 409) {
          dispatch(
            modalShow({
              open: true,
              type: "error",
              message:
                "Parece ser que el Candidato ya tiene una videoentrevista generada en Hireflix. Puedes intentar acceder directamente en Hireflix, o si quieres generar una nueva debes borrar antes la que existe desde Hireflix.",
            })
          );
          setInterviewState("none");
        } else {
          setInterviewState("none");
          snackErrorMessage("Ha habido un problema al generar la entrevista");
        }
      }
    };

    sendInterviewRequest(employee.id);
  };

  const handleCopyLink = (e: React.MouseEvent<HTMLButtonElement>): void => {
    if (textRef && textRef.current) {
      textRef.current.select();
      document.execCommand("copy");
      (e.target as HTMLButtonElement).focus();
      setLinkCopied(true);
    }
  };

  const changeVideoPlaying = (newVideo: number) => {
    if (videoPlaying !== newVideo) {
      setVideoPlaying(newVideo);
    }
  };

  return (
    <StyledWrapper>
      <StyledHeader>
        <StyledTitle>
          <FormattedMessage
            id="VideoInterviewTab.Title"
            defaultMessage="Videoentrevistas"
          />
        </StyledTitle>
      </StyledHeader>
      <StyledContent>
        <Grid
          container
          spacing={8}
          alignItems="stretch"
          justifyContent="center"
        >
          <Grid item xs={12} md={6} className="CMuiVideoInterviewLeft">
            <Typography className="CMuiVideoInterviewSubtitle">
              <FormattedMessage
                id="VideoInterviewTab.Generation"
                defaultMessage="Generación entrevista"
              />
            </Typography>
            <Box className="CMuiVideoInterviewGenerationContent">
              <Box className="CMuiVideoInterviewDataBox">
                {employee.Link_Publico ? (
                  <Fragment>
                    <Grid
                      container
                      spacing={4}
                      alignItems="center"
                      className="CMuiVideoInterviewDataRow"
                    >
                      <Grid item xs={12} md={6}>
                        <Typography className="CMuiVideoInterviewDataLabel">
                          <FormattedMessage
                            id="VideoInterviewTab.Creator"
                            defaultMessage="Creador entrevista: "
                          />
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography className="CMuiVideoInterviewDataValue">
                          {employee.interviewOwnerName}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={4}
                      alignItems="center"
                      className="CMuiVideoInterviewDataRow"
                    >
                      <Grid item xs={12} md={6}>
                        <Typography className="CMuiVideoInterviewDataLabel">
                          <FormattedMessage
                            id="VideoInterviewTab.Date"
                            defaultMessage="Fecha generación entrevista: "
                          />
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {employee.Fecha_creacion_Videoentrevista && (
                          <Typography className="CMuiVideoInterviewDataValue">
                            {moment(employee.Fecha_creacion_Videoentrevista)
                              .local()
                              .format("DD/MM/YYYY - HH:mm")}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Fragment>
                ) : (
                  <Typography className="CMuiVideoInterviewNoInterview">
                    <FormattedMessage
                      id="VideoInterviewTab.NoInterview"
                      defaultMessage="Todavía no se ha generado una videoentrevista para este candidato"
                    />
                  </Typography>
                )}
              </Box>
              <Box className="CMuiVideoInterviewActions">
                <Button
                  className="CMuiVideoInterviewButton"
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={handleGenerateClick}
                  disabled={
                    !!(employee.Link_Publico || interviewState === "generating")
                  }
                  startIcon={
                    interviewState === "generating" && (
                      <StyledCircularProgress size={20} />
                    )
                  }
                >
                  {interviewState === "generating" ? (
                    <FormattedMessage
                      id="VideoInterviewTab.GeneratingInterview"
                      defaultMessage="Generando videoentrevista..."
                    />
                  ) : interviewState === "completed" ? (
                    <FormattedMessage
                      id="VideoInterviewTab.GenerateAnotherInterview"
                      defaultMessage="Generar nueva videoentrevista"
                    />
                  ) : (
                    <FormattedMessage
                      id="VideoInterviewTab.GenerateInterview"
                      defaultMessage="Generar videoentrevista"
                    />
                  )}
                </Button>
                {employee.Link_Publico && (
                  <Fragment>
                    <StyledTextArea
                      ref={textRef}
                      value={employee.Link_Publico}
                      readOnly
                    />
                    <Button
                      className="CMuiVideoInterviewButton"
                      color="secondary"
                      variant="contained"
                      fullWidth
                      onClick={handleCopyLink}
                    >
                      {linkCopied ? (
                        <FormattedMessage
                          id="VideoInterviewTab.CopiedLink"
                          defaultMessage="Link Copiado"
                        />
                      ) : (
                        <FormattedMessage
                          id="VideoInterviewTab.CopyLink"
                          defaultMessage="Copiar link"
                        />
                      )}
                    </Button>
                  </Fragment>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} className="CMuiVideoInterviewRight">
            <Grid
              container
              spacing={4}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item>
                <Typography className="CMuiVideoInterviewSubtitle">
                  <FormattedMessage
                    id="VideoInterviewTab.Response"
                    defaultMessage="Respuesta del candidato"
                  />
                </Typography>
              </Grid>
            </Grid>
            <Box className="CMuiVideoInterviewResponseContent">
              {employee.interviewStatus === "completed" ||
              employee.interviewStatus === "finalist" ? (
                <Fragment>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      {employee.interviewQuestions?.[0]?.url ? (
                        <StyledPlayerWrapper>
                          <StyledVideoWrapper>
                            <StyledVideoInnerWrapper>
                              <ReactPlayer
                                url={
                                  employee.interviewQuestions[videoPlaying]?.url
                                }
                                controls
                                width="100%"
                                height="100%"
                              />
                            </StyledVideoInnerWrapper>
                          </StyledVideoWrapper>
                          <StyledPlaylist>
                            <StyledPlaylistItem
                              onClick={() => changeVideoPlaying(0)}
                            >
                              <img
                                className="CMuiVideoInterviewPlaylistItemImg"
                                title={employee.interviewQuestions[0].title}
                                alt={employee.interviewQuestions[0].title}
                                src={employee.interviewQuestions[0].thumbnail}
                              />
                              <StyledPlaylistItemTitle>
                                {employee.interviewQuestions[0].title}
                              </StyledPlaylistItemTitle>
                            </StyledPlaylistItem>
                            {employee.interviewQuestions[1] ? (
                              <StyledPlaylistItem
                                onClick={() => changeVideoPlaying(1)}
                              >
                                <img
                                  className="CMuiVideoInterviewPlaylistItemImg"
                                  title={employee.interviewQuestions[1].title}
                                  alt={employee.interviewQuestions[1].title}
                                  src={employee.interviewQuestions[1].thumbnail}
                                />
                                <StyledPlaylistItemTitle>
                                  {employee.interviewQuestions[1].title}
                                </StyledPlaylistItemTitle>
                              </StyledPlaylistItem>
                            ) : (
                              <StyledPlaylistItem />
                            )}
                            {employee.interviewQuestions[2] ? (
                              <StyledPlaylistItem
                                onClick={() => changeVideoPlaying(2)}
                              >
                                <img
                                  className="CMuiVideoInterviewPlaylistItemImg"
                                  title={employee.interviewQuestions[2].title}
                                  alt={employee.interviewQuestions[2].title}
                                  src={employee.interviewQuestions[2].thumbnail}
                                />
                                <StyledPlaylistItemTitle>
                                  {employee.interviewQuestions[2].title}
                                </StyledPlaylistItemTitle>
                              </StyledPlaylistItem>
                            ) : (
                              <StyledPlaylistItem />
                            )}
                          </StyledPlaylist>
                        </StyledPlayerWrapper>
                      ) : (
                        <div></div>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={4} alignItems="center">
                    <Grid item xs={12}>
                      {employee.Fecha_de_Videoentrevista && (
                        <a
                          className="CMuiVideoInterviewHireflixLink"
                          href={employee.Link_Privado}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button
                            className="CMuiVideoInterviewHireflixButton"
                            variant="outlined"
                            color="primary"
                          >
                            <img
                              src={HireflixLogo}
                              className="CMuiVideoInterviewHireflixLogo"
                              alt="Ver en Hireflix"
                            />
                            <FormattedMessage
                              id="VideoInterviewTab.OpenInHireflix"
                              defaultMessage="Ver en Hireflix"
                            />
                          </Button>
                        </a>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography className="CMuiVideoInterviewDataLabel">
                        <FormattedMessage
                          id="VideoInterviewTab.ResponseDate"
                          defaultMessage="Fecha de respuesta: "
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {employee.Fecha_de_Videoentrevista && (
                        <Typography className="CMuiVideoInterviewDataValue">
                          {moment(employee.Fecha_de_Videoentrevista).format(
                            "DD/MM/YYYY - HH:mm"
                          )}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Fragment>
              ) : employee.interviewStatus === "pending" ? (
                <Typography className="CMuiVideoInterviewWaitingMessage">
                  <FormattedMessage
                    id="VideoInterviewTab.AwaitingResponse"
                    defaultMessage="Se ha generado una entrevista. Esperando a que el candidato la complete"
                  />
                </Typography>
              ) : (
                <Typography className="CMuiVideoInterviewNoInterviewMessage">
                  <FormattedMessage
                    id="VideoInterviewTab.NoInterviewHelp"
                    defaultMessage="No hay entrevistas para este candidato. Puedes crear una usando el botón Generar Videoentrevista"
                  />
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </StyledContent>
    </StyledWrapper>
  );
};

export default VideoInterviewTab;
