import React, { Fragment } from "react";
import LinkifyIt from "linkify-it";
import tlds from "tlds";

const linkify = new LinkifyIt();
linkify.tlds(tlds);

const StringComponent = (text: string, key: string): React.ReactNode => {
  return <span key={key} dangerouslySetInnerHTML={{ __html: text }} />;
};

const ComponentDecorator = (
  decoratedHref: string,
  decoratedText: string,
  key: number
): React.ReactNode => {
  return (
    <a href={decoratedHref} key={key} target="_blank" rel="noopener noreferrer">
      {decoratedText}
    </a>
  );
};

interface ILinkifyProps {
  keyPrefix: string;
  value: string;
}

const Linkify = (props: ILinkifyProps) => {
  const { keyPrefix, value } = props;

  const matchDecorator = (text: string): any => {
    return linkify.match(text);
  };

  const parseString = (string: string, key: string) => {
    if (string === "") {
      return string;
    }

    const matches = matchDecorator(string);
    const elements = [];
    let lastIndex = 0;
    if (!matches) {
      elements.push(StringComponent(string, key));
      return elements[0];
    }

    matches.forEach((match: any, i: number) => {
      // Push preceding text if there is any
      if (match.index > lastIndex) {
        elements.push(
          StringComponent(string.substring(lastIndex, match.index), key)
        );
      }

      // const decoratedHref = this.props.hrefDecorator(match.url);
      const decoratedHref = match.url;
      const decoratedText = match.text;
      const decoratedComponent = ComponentDecorator(
        decoratedHref,
        decoratedText,
        i
      );
      elements.push(decoratedComponent);

      lastIndex = match.lastIndex;
    });

    // Push remaining text if there is any
    if (string.length > lastIndex) {
      elements.push(string.substring(lastIndex));
    }

    return elements.length === 1 ? elements[0] : elements;
  };

  const parse = (
    children: any,
    keyPrefix: string,
    keyIndex: number = 0
  ): any => {
    if (typeof children === "string") {
      return parseString(children, `${keyPrefix}_${keyIndex}`);
    } else if (
      React.isValidElement(children) &&
      children.type !== "a" &&
      children.type !== "button"
    ) {
      return React.cloneElement(
        children,
        { key: `${keyPrefix}_${keyIndex}` },
        parse((children.props as any).children, keyPrefix)
      );
    } else if (Array.isArray(children)) {
      return children.map((child, i) => parse(child, keyPrefix, i));
    }

    return children;
  };

  return <Fragment>{parse(value, keyPrefix)}</Fragment>;
};

export default Linkify;
