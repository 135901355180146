import { useQuery } from "react-query";
import { USER_ROLES } from "../../../../../../../api/security";
import { apiListStaffUsers } from "../../../../../../../api/user/apiListStaffUsers";

export default function usePromoterUsersQuery() {
  return useQuery(
    ["promoter_users"],
    async () => {
      const res = await apiListStaffUsers({});

      const filteredUsers = res["hydra:member"].filter((user) => {
        if (user.fullname === "Cuideo Cuideo") {
          return true;
        }

        if (user.roles.indexOf(USER_ROLES.ROLE_ES_PROMOTER) !== -1) {
          return true;
        }

        if (user.fullname === "magda@cuideo.com") {
          return true;
        }

        return false;
      });

      const sortedUsers = filteredUsers.sort((a, b) => {
        if (a.fullname < b.fullname) {
          return -1;
        }
        return 1;
      });

      const superAdminId =
        sortedUsers.find((user) => user.fullname === "Cuideo Cuideo")?.id ??
        "-";
      return {
        users: sortedUsers,
        ownerOptions: sortedUsers.filter(
          (user) => !!user.active && user.id !== superAdminId
        ),
        superAdminUserId: superAdminId,
        totalItems: res["hydra:totalItems"],
      };
    },
    {
      enabled: true,
      cacheTime: 1000 * 60 * 5,
    }
  );
}
