import { apiEmpleo } from "../../util/ApiEmpleo";

export type TUpdateStaffUserRequest = {
  active?: boolean;
  enabled?: boolean;
};

const apiUpdateStaffUser = async (
  userId: string,
  user: TUpdateStaffUserRequest
) => {
  return apiEmpleo.patch(`users/${userId}`, user, {
    headers: {
      "Content-Type": "application/merge-patch+json",
    },
  });
};

export { apiUpdateStaffUser };
