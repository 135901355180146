const departments = [
  {
    value: "Paris",
    label: "75 - Paris",
  },
  {
    value: "Hauts-de-Seine",
    label: "92 - Hauts-de-Seine",
  },
  {
    value: "Seine-Saint-Denis",
    label: "93 - Seine-Saint-Denis",
  },
  {
    value: "Val-de-Marne",
    label: "94 - Val-de-Marne",
  },
  {
    value: "Val-D'Oise",
    label: "95 - Val-D'Oise",
  },
  {
    value: "Yvelines",
    label: "78 - Yvelines",
  },
  {
    value: "Essonne",
    label: "91 - Essonne",
  },
];

export { departments as departmentsList };

export default departments;
