import { useQuery } from "react-query";
import { apiListContracts } from "../../../../../../../api/contract/apiListContracts";
import { contractKeys } from "../../../util/contract.query-keys";
import { TContractsPageFilters } from "./useContractsPageURLParams";

export default function useContractsPageNumRecruitQuery(
  filters: TContractsPageFilters,
  enabled = true
) {
  return useQuery(
    contractKeys.numForContractsPage(filters, "recruit"),
    async () => {
      const params = {
        itemsPerPage: 1,
        phase: "recruit",
      } as any;

      if (filters.publicar !== "") {
        if (filters.publicar === "null") {
          params["exists[publicar]"] = false;
          delete params.publicar;
        } else {
          params.publicar = filters.publicar;
        }
      } else {
        delete params.publicar;
      }
      if (filters.tipoDeTrato.length) {
        params.tipoDeTrato = filters.tipoDeTrato;
      } else {
        delete params.tipoDeTrato;
      }
      if (filters.origen.length) {
        params.origen = filters.origen;
      } else {
        delete params.origen;
      }
      if (filters.estado.length) {
        params.estado = filters.estado;
      } else {
        delete params.estado;
      }
      if (filters.provinciaOferta !== "") {
        params.provinciaOferta = filters.provinciaOferta;
      }
      if (filters.botLaunched === "yes") {
        params["numCommunicationIntents[gte]"] = 1;
      }
      if (filters.botLaunched === "no") {
        params["numCommunicationIntents[lt]"] = 1;
      }
      if (!params.owner && filters.owner !== "") {
        params.owner = filters.owner;
      }
      if (params.owner === "owner") {
        delete params.owner;
      }
      if (!params["service.owner"] && filters["service.owner"] !== "") {
        params["service.owner"] = filters["service.owner"];
      }
      if (params["service.owner"] === "owner") {
        delete params["service.owner"];
      }
      if (filters.search !== "") {
        params.searchFor = filters.search;
      }

      const res = await apiListContracts(params);

      return {
        totalItems: res["hydra:totalItems"],
      };
    },
    {
      enabled: filters.ready && enabled,
      retry: 0,
    }
  );
}
