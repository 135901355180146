import { useQuery } from "react-query";
import { apiListConnectedUsers } from "../../../../../../../../../api/user/apiListConnectedUsers";

export default function useConnectedUsersQuery(refetchInterval = 15000) {
  return useQuery(
    ["connected_users"],
    async () => {
      const res = await apiListConnectedUsers();

      return {
        connectedUsers: res["hydra:member"],
        totalConnectedUsers: res["hydra:totalItems"],
      };
    },
    {
      enabled: true,
      refetchInterval,
    }
  );
}
