import React from "react";
import { useIntl } from "react-intl";
import useBackground from "../../../../../../util/useBackground";
import useFooter from "../../../../../../util/useFooter";
import useHeader from "../../../../../../util/useHeader";
import usePageTitle from "../../../../../../util/usePageTitle";
import ConnectedUsersProvider from "../../../connectedUser/providers/ConnectedUsersProvider";
import useRecruiterManagerRouteFilters, {
  TChangeOrderFn,
} from "./hooks/useRecruiterManagerRouteFilters";
import RecruiterManagerRoute from "./RecruiterManagerRoute";

interface IRecruiterManagerRouteContainerProps {}

const RecruiterManagerRouteContainer: React.FC<IRecruiterManagerRouteContainerProps> =
  (props) => {
    const { formatMessage } = useIntl();
    useHeader({
      type: "hidden",
    });
    useFooter({
      type: "hidden",
    });
    useBackground("white");
    usePageTitle(
      formatMessage({
        id: "RecruiterManager.Recruit Manager",
        defaultMessage: "Recruit Manager",
      })
    );
    const { filters, setFilters } = useRecruiterManagerRouteFilters();

    const handleChangeSelectedOwnerId = (ownerId: string | null) => {
      setFilters({
        ...filters,
        selectedOwnerId: ownerId,
        selectedContractId: null,
      });
    };

    const handleChangeSelectedContractId = (contractId: string | null) => {
      setFilters({
        ...filters,
        selectedContractId: contractId,
      });
    };

    const handleChangeTableOrder: TChangeOrderFn = ({ column, order }) => {
      setFilters({
        ...filters,
        "order[createdAt]": null,
        "order[fechaObjetivo]": null,
        "order[cvDate]": null,
        "order[squad]": null,
        [column]: order,
      });
    };

    if (!filters.ready) {
      return null;
    }

    return (
      <ConnectedUsersProvider>
        <RecruiterManagerRoute
          filters={filters}
          onSelectedOwnerChange={handleChangeSelectedOwnerId}
          onSelectedContractChange={handleChangeSelectedContractId}
          onChangeTableOrder={handleChangeTableOrder}
        />
      </ConnectedUsersProvider>
    );
  };

export default RecruiterManagerRouteContainer;
