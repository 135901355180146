import { USER_ROLES } from "../security";
import { TStaffUserResponse } from "./apiListStaffUsers";

export const staffUserIsPromoter = (user: TStaffUserResponse) => {
  if (user.roles.includes(USER_ROLES.ROLE_ES_PROMOTER)) {
    return true;
  }

  return false;
};

export const staffUserIsRecruiter = (user: TStaffUserResponse) => {
  if (user.roles.includes(USER_ROLES.ROLE_ES_RECRUITER)) {
    return true;
  }

  return false;
};
