import { useQuery } from "react-query";
import { apiListConnectedUsers } from "../../../../../api/user/apiListConnectedUsers";
import {
  ExtractFnReturnType,
  QueryConfig,
} from "../../../../../lib/react-query";
import { connectedUserKeys } from "../util/connected-user.query-keys";

export const getConnectedUsers = async () => {
  const response = await apiListConnectedUsers();

  return {
    connectedUsers: response["hydra:member"],
    totalItems: response["hydra:totalItems"],
  };
};

type QueryFnType = typeof getConnectedUsers;

type UseConnectedUsersOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useConnectedUsers = ({
  config,
}: UseConnectedUsersOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: connectedUserKeys.all,
    queryFn: () => getConnectedUsers(),
  });
};
