import React from "react";
import Conversation from "../../../conversation/components/Conversation";
import ConversationsList from "../../../conversation/components/ConversationsList/ConversationsList";
import { useCommunicationsContext } from "../../hooks/useCommunicationsContext";
import ConversationsRouteLayout from "./ConversationsRouteLayout";
import SelectConversation from "./SelectConversation";

interface IConversationsRouteProps {}

const ConversationsRoute: React.FC<IConversationsRouteProps> = (props) => {
  const communicationsContext = useCommunicationsContext();

  return (
    <ConversationsRouteLayout
      leftAside={
        <ConversationsList
          selectedConversationId={
            communicationsContext.filters.selectedConversationId
          }
          onSelectConversation={communicationsContext.selectConversationId}
        />
      }
      main={
        communicationsContext.filters.selectedConversationId ? (
          <Conversation
            conversationId={
              communicationsContext.filters.selectedConversationId
            }
          />
        ) : (
          <SelectConversation />
        )
      }
    />
  );
};

export default ConversationsRoute;
