export const WORKER_STATUS = {
  PENDING: "pending",
  WORKING: "working",
  PAUSED: "paused",
  FINISHED: "finished",
  ERROR: "error",
  // Special status to flag that the Bot works manually
  MANUAL: "manual",
} as const;

export type TWorkerStatus = typeof WORKER_STATUS[keyof typeof WORKER_STATUS];

export type TSuggestionWorkerResponse = {
  id: string;
  contract_id: string;
  contract_crm_id?: string;
  status: TWorkerStatus;
  error_code?: string;
  error_message?: string;
  num_bot_intents: number;
  num_bot_inscriptions: number;
  created_at: string;
  date_start: string;
  date_end: string;
};
