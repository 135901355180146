import { Fab, Tooltip, Typography, Zoom } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import FormSvg from "../../assets/icons/form.svg";
import { THsm } from "../../types/templateAction.types";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const StyledTooltip = styled(Tooltip)``;

const StyledFab = styled(Fab)`
  margin: 0 0 0 1rem;
  background-color: #f7f7f7;

  &:hover {
    background-color: #e7e7e7;
  }
`;

const StyledLabel = styled(Typography)`
  font-size: 0.875rem;
`;

const StyledTextToSend = styled(Typography)`
  font-size: 0.875rem;
`;

const StyledIcon = styled.img`
  display: block;
  width: 2.5rem;
  height: 2.5rem;
`;

interface ITemplateActionButtonsProps {
  onSendHsm: (hsm: THsm) => void;
}

const TemplateActionButtons: React.FC<ITemplateActionButtonsProps> = (
  props
) => {
  const { onSendHsm } = props;

  const handleSendTemplate = () => {
    onSendHsm({
      hsm: {
        namespace: "3815f017_d1ca_4bca_a4cd_32788aacc629",
        templateName: "continuar_conversacion",
        language: { code: "es_ES" },
      },
    });
  };

  const handleSendCustomerAnswerTemplate = () => {
    onSendHsm({
      hsm: {
        namespace: "3815f017_d1ca_4bca_a4cd_32788aacc629",
        templateName: "respuesta_cliente",
        language: { code: "es" },
      },
    });
  };

  return (
    <StyledWrapper>
      <Zoom in timeout={250} unmountOnExit style={{ transitionDelay: "500ms" }}>
        <StyledTooltip
          title={
            <>
              <StyledLabel>
                <FormattedMessage
                  id="TemplateActionButton.Enviar la siguiente plantilla para contactar"
                  defaultMessage="Enviar la siguiente plantilla para contactar"
                />
              </StyledLabel>
              <StyledTextToSend>
                <FormattedMessage
                  id="TemplateActionButton.¡Hola! Nos gustaría hablar de tu proceso y continuar nuestra conversación anterior."
                  defaultMessage="¡Hola! Nos gustaría hablar de tu proceso y continuar nuestra conversación anterior."
                />
              </StyledTextToSend>
            </>
          }
        >
          <StyledFab onClick={handleSendTemplate}>
            <StyledIcon src={FormSvg} />
          </StyledFab>
        </StyledTooltip>
      </Zoom>
      <Zoom in timeout={250} unmountOnExit style={{ transitionDelay: "750ms" }}>
        <StyledTooltip
          title={
            <>
              <StyledLabel>
                <FormattedMessage
                  id="TemplateActionButton.Enviar la siguiente plantilla para contactar"
                  defaultMessage="Enviar la siguiente plantilla para contactar"
                />
              </StyledLabel>
              <StyledTextToSend>
                <FormattedMessage
                  id="TemplateActionButton.¡Hola! soy de Cuideo y me gustaría hablar sobre tu candidatura en la oferta de empleo."
                  defaultMessage="¡Hola! soy de Cuideo y me gustaría hablar sobre tu candidatura en la oferta de empleo."
                />
              </StyledTextToSend>
            </>
          }
        >
          <StyledFab onClick={handleSendCustomerAnswerTemplate}>
            <StyledIcon src={FormSvg} />
          </StyledFab>
        </StyledTooltip>
      </Zoom>
    </StyledWrapper>
  );
};

export default TemplateActionButtons;
