import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import cuideoTheme from "../../containers/themes/defaultTheme";
import { apiEmpleo } from "../../util/ApiEmpleo";
import {
  changeURLWithoutLoading,
  createURL,
  urlParamsToObject,
} from "../../util/urlManager";

const textLabels = {
  body: {
    noMatch: "No se han encontrado resultados",
    toolTip: "Ordenar",
    columnHeaderTooltip: (column: any) => `Ordenar por ${column.label}`,
  },
  pagination: {
    next: "Siguiente",
    previous: "Anterior",
    rowsPerPage: "Filas por página:",
    displayRows: "de",
  },
  toolbar: {
    search: "Buscar",
    downloadCsv: "Descargar CSV",
    print: "Imprimir",
    viewColumns: "Ver Columnas",
    filterTable: "Filtrar Tabla",
  },
  filter: {
    all: "Todos",
    title: "FILTROS",
    reset: "RESET",
  },
  viewColumns: {
    title: "Mostrar Columnas",
    titleAria: "Mostrar/Ocultar columnas",
  },
  selectedRows: {
    text: "fila(s) seleccionadass",
    delete: "Borrar",
    deleteAria: "Borrar filas seleccionadas",
  },
};

const StyledMUIDataTableWrapper = styled.div`
  > .MuiPaper-root {
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  .MuiToolbar-root {
    padding: 0 1rem;
  }

  .MuiTableCell-head {
    color: ${cuideoTheme.palette.primary.main};
    opacity: 1;
    background-color: ${(cuideoTheme.palette.primary as any)[50]};
  }

  .MuiTypography-caption {
    color: ${cuideoTheme.palette.primary.main};
    opacity: 0.5;
  }

  .MuiTableCell-body {
    color: ${cuideoTheme.palette.primary.main};
  }

  .MuiIconButton-root {
    color: ${cuideoTheme.palette.primary.main};
  }
`;

const StyledMUIDenseDataTableWrapper = styled.div`
  .MuiPaper-root {
    border: none;
    box-shadow: none;
  }

  .MuiToolbar-root {
    padding: 0 1rem;
  }

  .MuiTableCell-head {
    color: ${cuideoTheme.palette.primary.main};
    opacity: 0.5;
  }

  .MuiTypography-caption {
    color: ${cuideoTheme.palette.primary.main};
    opacity: 0.5;
  }

  .MuiTableCell-body {
    color: ${cuideoTheme.palette.primary.main};
    font-size: 0.8rem;
    padding: 0.5rem 1rem 0.5rem 0.6rem;
  }

  .MuiIconButton-root {
    color: ${cuideoTheme.palette.primary.main};
  }
`;

const StyledWrapper = styled.div`
  position: relative;
`;

const CuideoDataTable = (props: any) => {
  const { requestUri, setDataFunction, additionalParams, emptyContent } = props;

  const [numTotalRows, setNumTotalRows] = useState(0);
  // eslint-disable-next-line
  const [isLoading, setLoading] = useState(false);
  const [isEmpty, setEmpty] = useState(false);

  let options = {} as any;
  if (props.options) {
    options = { ...props.options, textLabels: textLabels };
  } else {
    options = { textLabels: textLabels };
  }

  const defaults = {
    rowsPerPage:
      props.options && props.options.rowsPerPage
        ? props.options.rowsPerPage
        : 10,
  };

  const getData = async ({ tableState }: any) => {
    // setLoading(true);
    const urlParams = urlParamsToObject(window.location.search);
    const params = { ...urlParams, ...additionalParams };
    const res = await apiEmpleo.get(requestUri, {
      params,
    });
    if (res["hydra:member"]) {
      setDataFunction(res["hydra:member"]);
    }
    if (res["hydra:totalItems"]) {
      setNumTotalRows(res["hydra:totalItems"]);
      setEmpty(false);
    } else {
      setEmpty(true);
    }
    setLoading(false);
  };

  const handleTableUpdate = (action: any, tableState: any) => {
    const urlParams = {} as any;
    if (
      tableState.rowsPerPage &&
      tableState.rowsPerPage !== defaults.rowsPerPage
    ) {
      urlParams.itemsPerPage = tableState.rowsPerPage;
    }
    if (tableState.page && tableState.page !== 0) {
      urlParams.page = tableState.page + 1;
    }
    const urlState = createURL(window.location.pathname, urlParams, null);
    changeURLWithoutLoading(urlState);
    if (numTotalRows > defaults.rowsPerPage) {
      getData({
        tableState,
      });
    }
  };

  useEffect(() => {
    getData({});
    // eslint-disable-next-line
  }, []);

  // Handler
  options.onTableChange = (action: any, tableState: any) => {
    if (action === "propsUpdate") {
      return;
    }
    if (action === "changePage") {
      handleTableUpdate(action, tableState);
    }
    if (action === "changeRowsPerPage") {
      handleTableUpdate(action, tableState);
    }
    if (action === "search") {
      handleTableUpdate(action, tableState);
    }
    if (action === "filterChange") {
      handleTableUpdate(action, tableState);
    }
    if (action === "sort") {
      handleTableUpdate(action, tableState);
    }
  };

  if (isEmpty && emptyContent) {
    return emptyContent;
  }

  if (props.variant && props.variant === "dense") {
    return (
      <StyledWrapper>
        {/* <StyledInfo /> */}
        <StyledMUIDenseDataTableWrapper>
          <MUIDataTable {...props} options={options} />
        </StyledMUIDenseDataTableWrapper>
      </StyledWrapper>
    );
  }

  return (
    <StyledWrapper>
      {/* <StyledInfo /> */}
      <StyledMUIDataTableWrapper>
        <MUIDataTable {...props} options={options} />
      </StyledMUIDataTableWrapper>
    </StyledWrapper>
  );
};

export default CuideoDataTable;
