import { useQuery } from "react-query";
import { apiListAllServicesManagerInfo } from "../../../../../../../../../api/service/apiListAllServicesManagerInfo";
import { SERVICE_STATE } from "../../../../../../../../../api/service/service.types";

export default function useServicesManagerInfoQuery() {
  return useQuery(
    ["services_manager_info"],
    async () => {
      const res = await apiListAllServicesManagerInfo({
        state: [SERVICE_STATE.WITHOUT_VALIDATION],
      });

      return {
        services: res["hydra:member"],
        totalServices: res["hydra:totalItems"],
      };
    },
    {
      enabled: true,
      cacheTime: 1000 * 60 * 5,
    }
  );
}
