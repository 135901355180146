import {
  GET_OFFERS,
  GET_OFFERS_SUCCESS,
  FETCH_START,
  FETCH_SUCCESS,
  SET_OFERTAS_FILTERS,
  GET_APPLIED_OFFERS,
  UPDATE_APPLIED_OFFERS,
  GET_OFFER,
  GET_OFFER_SUCCESS,
  HIDE_WELCOME_POPUP,
  RESET_OFFERS,
  SET_NUM_OFERTAS_VISIBLES_HOME
} from "../constants/ActionTypes";

export const getOffers = (user?: any) => {
  return {
    type: GET_OFFERS,
    payload: {
      user
    }
  };
};

export const getOffer = (offerId: string, user?: any) => {
  return {
    type: GET_OFFER,
    payload: {
      offerId,
      user
    }
  };
};

export const getOffersSuccess = (offers: any, user?: any) => {
  return {
    type: GET_OFFERS_SUCCESS,
    payload: {
      offers,
      user
    }
  };
};

export const getOfferSuccess = (offer: any, user?: any) => {
  return {
    type: GET_OFFER_SUCCESS,
    payload: {
      offer,
      user
    }
  };
};

export const resetOffers = () => {
  return {
    type: RESET_OFFERS
  };
};

export const updateAppliedOffers = (appliedOffers: string[]) => {
  return {
    type: UPDATE_APPLIED_OFFERS,
    payload: appliedOffers
  };
};

export const setOfertasFilters = (
  provincia: string,
  ciudades: string[],
  disponibilidad: string[]
) => {
  return {
    type: SET_OFERTAS_FILTERS,
    payload: { provincia, ciudades, disponibilidad }
  };
};

export const getAppliedOffers = () => {
  return (dispatch: Function) => {
    dispatch({ type: FETCH_START });
    dispatch({ type: FETCH_SUCCESS });
    const appliedOffers = localStorage.getItem("appliedOffers");
    if (appliedOffers) {
      dispatch({
        type: GET_APPLIED_OFFERS,
        payload: JSON.parse(appliedOffers)
      });
    } else {
      dispatch({ type: GET_APPLIED_OFFERS, payload: [] });
    }
  };
};

export const setHideWelcomePopup = () => {
  sessionStorage.setItem("hideWelcomePopup", JSON.stringify(true));
  return {
    type: HIDE_WELCOME_POPUP
  };
};

export const setNumOfertasVisiblesHome = (numOfertasVisibles: number) => {
  return {
    type: SET_NUM_OFERTAS_VISIBLES_HOME,
    payload: numOfertasVisibles
  };
};
