import { Box, CircularProgress, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const StyledBox = styled(Box)`
  padding: 1rem;
  text-align: center;
`;

interface ICircularProgressBoxProps {
  message?: string;
}

const CircularProgressBox = (props: ICircularProgressBoxProps) => {
  const message = props.message || "Cargando...";

  return (
    <StyledBox className="CMuiCircularProgressBox">
      <CircularProgress />
      <Typography className="CMuiCircularProgressBoxMessage">
        {message}
      </Typography>
    </StyledBox>
  );
};

export default CircularProgressBox;
