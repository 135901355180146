import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import DelayedServiceReminder from "./DelayedServiceReminder";
import useDelayedServicesList, {
  TReminderStorage,
} from "./hooks/useDelayedServicesList";
import useGetDelayedServiceQuery from "./hooks/useGetDelayedServiceQuery";

interface IDelayedServiceReminder {}

const DelayedServiceReminderContainer = (props: IDelayedServiceReminder) => {
  const owner = useSelector((state: any) => state.auth.authUser);
  const { delayedServices, addDelayedService } = useDelayedServicesList();

  const delayedServiceQuery = useGetDelayedServiceQuery(
    owner?.userId,
    delayedServices
  );

  const handleDelay = (serviceId: string) => {
    const newDelayedTime = moment();
    newDelayedTime.set({ h: newDelayedTime.hour() + 1 });
    const delayedService: TReminderStorage = {
      id: serviceId,
      until: newDelayedTime.toISOString(),
    };
    addDelayedService(delayedService);
  };

  if (!delayedServiceQuery.data) {
    return null;
  }

  return (
    <DelayedServiceReminder
      service={delayedServiceQuery.data}
      onDelay={handleDelay}
    />
  );
};

export default DelayedServiceReminderContainer;
