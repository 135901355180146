import { Dialog, LinearProgress, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import cuideoTheme from "../../containers/themes/defaultTheme";

const StyledDialog = styled(Dialog)``;

const StyledTitle = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-size: 1.5rem;
  font-weight: 700;
  margin: 1rem;
`;

const StyledLabel = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  margin: 1rem;
`;

const StyledLinearProgress = styled(LinearProgress)`
  margin: 0.5rem 1rem 1rem;
`;

interface ConfirmDialogProps {
  open: boolean;
  title?: any;
  label: any;
  iteration: number;
  totalIterations: number;
}

const ProgressDialog = (props: ConfirmDialogProps) => {
  const { open, title, label, iteration, totalIterations } = props;

  const value = Math.ceil((iteration / totalIterations) * 100);

  return (
    <StyledDialog open={open} className="MuiCuideo-ProgressDialog">
      <StyledTitle>{title}</StyledTitle>
      <StyledLabel>{label}</StyledLabel>
      <StyledLinearProgress
        variant="determinate"
        color="primary"
        value={value}
      />
    </StyledDialog>
  );
};

export default ProgressDialog;
