import { Avatar, Badge } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import React from "react";
import styled from "styled-components";
import FelizVitaLogo from "../../assets/images/feliz_vita_logo.svg";
import { avatarImgBaseUrl } from "../../config/assetLocations";

const StyledAccountCircleIcon = styled(AccountCircleIcon)`
  width: 100%;
  height: 100%;
`;

const StyledFelizVitaLogoWrapper = styled.div`
  background-color: #fff;
  padding: 0.25rem;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  box-sizing: border-box;
`;

const StyledFelizVitaLogo = styled.img`
  width: 100%;
  height: 100%;
`;

interface ICuideoUserAvatarProps {
  className?: string;
  user: {
    userFullName?: string;
    userAvatar?: string | null;
  };
  isFelizVita?: boolean;
}

const CuideoUserAvatar: React.FC<ICuideoUserAvatarProps> = ({
  className,
  user,
  isFelizVita,
}) => {
  const userAvatar = user.userAvatar || null;

  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      badgeContent={
        isFelizVita ? (
          <StyledFelizVitaLogoWrapper className="CMuiCuideoUserAvatar-felizVitaLogo">
            <StyledFelizVitaLogo src={FelizVitaLogo} />
          </StyledFelizVitaLogoWrapper>
        ) : undefined
      }
    >
      {userAvatar ? (
        <Avatar className={className} src={avatarImgBaseUrl + userAvatar} />
      ) : (
        <Avatar className={className} alt="">
          <StyledAccountCircleIcon />
        </Avatar>
      )}
    </Badge>
  );
};

export default CuideoUserAvatar;
