import { apiEmpleo } from "../../util/ApiEmpleo";

export type TUpdateNotificationRequest = {
  notificationId: string;
  read?: boolean;
};

const apiUpdateReadNotification = async ({
  notificationId,
  read,
}: TUpdateNotificationRequest) => {
  const res = await apiEmpleo.patch(
    `/notifications/${notificationId}`,
    {
      read,
    },
    {
      headers: {
        "Content-Type": "application/merge-patch+json",
      },
    }
  );

  return res;
};

export { apiUpdateReadNotification };
