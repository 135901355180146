import { Grid, Typography } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import cuideoTheme from "../../../containers/themes/defaultTheme";

const StyledWrapper = styled.div`
  position: absolute;
  z-index: 105;
  cursor: pointer;
`;

const StyledPaperWrapper = styled.div`
  padding: 0;
  position: absolute;
  transform: translate(-50%, -100%);
`;

const StyledPaper = styled.div`
  position: relative;
  background: #002740;
  padding: 0.5rem;
  box-shadow: 0 2px 8px 0 rgba(0, 75, 123, 0.2);
  width: 12rem;
  border-radius: ${cuideoTheme.shape.borderRadius}px;

  :after,
  :before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  :after {
    border-color: rgba(136, 183, 213, 0);
    border-top-color: #002740;
    border-width: 0.75rem;
    margin-left: -0.75rem;
  }
  :before {
    border-color: rgba(194, 225, 245, 0);
    border-top-color: #002740;
    border-width: 0.75rem;
    margin-left: -0.75rem;
  }
`;

const StyledContratoTitle = styled(Typography)`
  color: ${cuideoTheme.palette.common.white};
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1;
`;

const StyledContratoAddress = styled(Typography)`
  color: ${cuideoTheme.palette.common.white};
  font-size: 0.75rem;
  font-weight: 400;
  opacity: 0.75;
  line-height: 1.15;
  margin-top: 0.25rem;
`;

const StyledLocationWrapper = styled.div`
  padding: 0.25rem;
  background-color: ${cuideoTheme.palette.primary.main};
`;

const StyledLocationOnIcon = styled(LocationOnIcon)`
  color: ${cuideoTheme.palette.common.white};
`;

export const ContratoMarker = (props: any) => {
  const { contrato } = props;
  const { formatMessage } = useIntl();

  const title = contrato.formatted_address
    ? contrato.formatted_address
    : contrato.C_digo_Postal_Oferta;
  const ubiType =
    contrato.location_type === "ROOFTOP"
      ? formatMessage({
          id: "ContractMarker.Exact",
          defaultMessage: "Ubicación exacta",
        })
      : formatMessage({
          id: "ContractMarker.Approximate",
          defaultMessage: "Ubicación aproximada",
        });

  return (
    <StyledWrapper>
      <StyledPaperWrapper>
        <StyledPaper>
          <Grid container spacing={4} alignItems="flex-start" wrap="nowrap">
            <Grid item>
              <StyledLocationWrapper>
                <StyledLocationOnIcon />
              </StyledLocationWrapper>
            </Grid>
            <Grid item>
              <StyledContratoTitle>{title}</StyledContratoTitle>
              <StyledContratoAddress>{ubiType}</StyledContratoAddress>
            </Grid>
          </Grid>
        </StyledPaper>
      </StyledPaperWrapper>
    </StyledWrapper>
  );
};

export default ContratoMarker;
