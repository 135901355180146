import React, { Fragment, useEffect, useRef } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import styled from "styled-components";
import logoImg from "../../assets/images/logo_azul.svg";
import cuideoTheme from "../../containers/themes/defaultTheme";
import { Button, IconButton } from "@material-ui/core";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import MenuIcon from "@material-ui/icons/Menu";
import { userSignOut } from "../../actions/Auth";
import OfertasFilters from "./OfertasFilters";
import {
  changeLayout,
  openUserDrawerMenu,
  closeUserDrawerMenu,
} from "../../actions/Layout";
import CloseIcon from "@material-ui/icons/Close";
import CuideoUserAvatar from "../CuideoUserAvatar";
import AnonymousMenu from "./AnonymousMenu";
import UserMenu from "./UserMenu";
import useWindowWidth from "../../util/useWindowWidth";
import UserButton from "./UserButton";
import { APP_START_PAGE } from "../../util/defaults/app";
import { FormattedMessage } from "react-intl";
import { JOB_OFFERS, JOB_SIGN_IN } from "../../constants/routes";

const StyledAppbar = styled(AppBar)<{
  leftpadding: number;
  toolbarcolor: string;
}>`
  background-color: ${() =>
    process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "pre"
      ? "#fff3ba"
      : "#ffffff"};
  color: #004b7b;
  ${({ leftpadding }) => {
    if (leftpadding) {
      return `
        padding-left: ${leftpadding}rem;
      `;
    }
    return `
      padding-left: 0;
    `;
  }}

  .MuiToolbar-root {
    background-color: ${({ toolbarcolor }) =>
      toolbarcolor === "lightblue"
        ? "#e5edf1"
        : process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "pre"
        ? "#fff3ba"
        : "#ffffff"};
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  // transition: min-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  min-height: 0;
`;

const StyledLogo = styled.img<{ headerType: string }>`
  height: 2rem;
  width: auto;
  ${({ headerType }) => {
    if (headerType === "simple") {
      return `
      display: block;
      margin: 0 auto;
          `;
    }
    return `
      margin-left: 1rem;
    `;
  }}
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    height: 2.375rem;
  }
`;

const StyledAppbarActions = styled.div`
  margin-left: auto;
`;

const StyledAppbarAnonymousButtons = styled.div`
  margin-right: 2rem;
`;

const StyledRightLink = styled(Link)`
  margin-right: 2rem;

  &:last-child {
    margin-right: 0;
  }
`;

const StyledButton = styled(Button)`
  .MuiButton-label {
    letter-spacing: 0.033rem;
    font-size: 1rem;
    font-weight: 400;
  }

  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    min-width: 10rem;
    padding: 0.1rem;
  }
`;

const StyledCuideoUserAvatar = styled(CuideoUserAvatar)`
  width: 2rem;
  height: 2rem;
  color: ${cuideoTheme.palette.primary.main};
`;

interface HeaderProps {
  headerType?: "simple" | "normal" | "hidden";
  leftpadding?: number;
  appMarginTop?: number;
  userSignOut?: Function;
  authUser?: any | null;
  router?: any;
  changeLayout: Function;
  appPaddingLeft: number;
  openUserDrawerMenu: Function;
  closeUserDrawerMenu: Function;
}

const Header = ({
  headerType = "normal",
  leftpadding = 0,
  appMarginTop,
  authUser,
  userSignOut,
  router,
  changeLayout,
  appPaddingLeft,
  openUserDrawerMenu,
  closeUserDrawerMenu,
}: HeaderProps) => {
  const headerRef = useRef(null);
  const [isMobileFiltersOpen, setMobileFiltersOpen] = React.useState(false);
  const [isAnonymousMenuOpen, setAnonymousMenuOpen] = React.useState(false);
  const [isUserMenuOpen, setUserMenuOpen] = React.useState(false);

  const width = useWindowWidth();

  const handleMobileFiltersClick = (isOpen: boolean) => {
    setMobileFiltersOpen(isOpen);
  };

  const setMobileMenusClosed = () => {
    setMobileFiltersOpen(false);
    setAnonymousMenuOpen(false);
    setUserMenuOpen(false);
  };

  const toolbarColor = isUserMenuOpen ? "lightblue" : "white";

  let rightContent = (
    <StyledAppbarAnonymousButtons>
      <StyledRightLink to={JOB_SIGN_IN} style={{ textDecoration: "none" }}>
        <StyledButton
          color="primary"
          variant="outlined"
          size="small"
          style={{ textTransform: "none" }}
        >
          <b>
            <FormattedMessage
              id="Header.Login"
              defaultMessage="Iniciar sesión"
            />
          </b>
        </StyledButton>
      </StyledRightLink>
      <StyledRightLink
        to="/registroCuidador"
        style={{ textDecoration: "none" }}
      >
        <StyledButton
          color="primary"
          variant="contained"
          size="small"
          style={{ textTransform: "none" }}
        >
          <b>
            <FormattedMessage id="Header.SignUp" defaultMessage="Regístrate" />
          </b>
        </StyledButton>
      </StyledRightLink>
    </StyledAppbarAnonymousButtons>
  );

  if (authUser) {
    if (width >= 960) {
      rightContent = (
        <Fragment>
          <UserButton />
        </Fragment>
      );
    }
    if (width < 960) {
      rightContent = (
        <Fragment>
          <StyledAppbarAnonymousButtons>
            {isMobileFiltersOpen || isUserMenuOpen ? (
              <IconButton
                edge="end"
                aria-label="cerrar"
                color="primary"
                onClick={() => {
                  setMobileMenusClosed();
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : (
              <IconButton
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={() => {
                  setUserMenuOpen(true);
                }}
              >
                <StyledCuideoUserAvatar user={authUser} />
              </IconButton>
            )}
          </StyledAppbarAnonymousButtons>
        </Fragment>
      );
    }
  } else {
    if (width < 960) {
      rightContent = (
        <Fragment>
          <StyledAppbarAnonymousButtons>
            {isMobileFiltersOpen || isAnonymousMenuOpen ? (
              <IconButton
                edge="end"
                aria-label="cerrar"
                color="primary"
                onClick={() => {
                  setMobileMenusClosed();
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : (
              <IconButton
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={() => {
                  setAnonymousMenuOpen(true);
                }}
              >
                <MenuIcon />
              </IconButton>
            )}
          </StyledAppbarAnonymousButtons>
        </Fragment>
      );
    }
  }

  const handleResize = () => {
    let top = 3.5;
    if (router.location.pathname === JOB_OFFERS) {
      if (width < cuideoTheme.breakpoints.values.md) {
        top = 7.5;
      }
    }

    if (top !== appMarginTop) {
      changeLayout(appPaddingLeft, top);
    }
  };

  useEffect(() => {
    handleResize();
    // eslint-disable-next-line
  }, [width, router]);

  if (headerType === "hidden") {
    return <Fragment></Fragment>;
  }

  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
  const windowHeight = window.screen.height;

  const appBarStyles =
    isAnonymousMenuOpen || isMobileFiltersOpen || isUserMenuOpen
      ? { minHeight: isIOS ? windowHeight : window.innerHeight }
      : undefined;

  if (isAnonymousMenuOpen || isMobileFiltersOpen || isUserMenuOpen) {
    document.body.style.overflow = "hidden";
    document.body.style.position = "relative";
  } else {
    document.body.style.overflow = "visible";
    document.body.style.position = "static";
  }

  return (
    <StyledAppbar
      leftpadding={leftpadding}
      style={appBarStyles}
      toolbarcolor={toolbarColor}
      ref={headerRef}
    >
      <Toolbar disableGutters={false}>
        <NavLink to={APP_START_PAGE}>
          <StyledLogo src={logoImg} headerType={headerType} width={width} />
        </NavLink>
        {headerType === "normal" && (
          <Fragment>
            <div style={{ flexGrow: 1 }}></div>
            <StyledAppbarActions>{rightContent}</StyledAppbarActions>
          </Fragment>
        )}
      </Toolbar>
      {isAnonymousMenuOpen ? (
        <AnonymousMenu handleClose={setMobileMenusClosed} />
      ) : authUser && isUserMenuOpen ? (
        <Fragment>
          {width < 960 ? (
            <UserMenu
              authUser={authUser}
              handleClose={() => {
                setUserMenuOpen(false);
              }}
            />
          ) : (
            <Fragment></Fragment>
          )}
        </Fragment>
      ) : (
        <Fragment>
          {width < 960 && router.location.pathname === JOB_OFFERS && (
            <div className="scrollable">
              <OfertasFilters
                handleMobileFiltersClick={handleMobileFiltersClick}
                isMobileFiltersOpen={isMobileFiltersOpen}
              />
            </div>
          )}
        </Fragment>
      )}
    </StyledAppbar>
  );
};

const mapStateToProps = ({
  layout,
  auth,
  router,
}: {
  layout: any;
  auth: { authUser: any };
  router: any;
}) => {
  const { appMarginTop, appPaddingLeft } = layout;
  const { authUser } = auth;
  return { authUser, router, appMarginTop, appPaddingLeft };
};

export default connect(mapStateToProps, {
  userSignOut,
  changeLayout,
  openUserDrawerMenu,
  closeUserDrawerMenu,
})(Header);
