/* eslint-disable no-extend-native */
const initializePolyfills = () => {
  if (typeof Object.assign != "function") {
    // Must be writable: true, enumerable: false, configurable: true
    Object.defineProperty(Object, "assign", {
      value: function assign(target: any, varArgs: any) {
        // .length of function is 2
        if (target == null) {
          // TypeError if undefined or null
          throw new TypeError("Cannot convert undefined or null to object");
        }

        var to = Object(target);

        for (var index = 1; index < arguments.length; index++) {
          var nextSource = arguments[index];

          if (nextSource != null) {
            // Skip over if undefined or null
            for (var nextKey in nextSource) {
              // Avoid bugs when hasOwnProperty is shadowed
              if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                to[nextKey] = nextSource[nextKey];
              }
            }
          }
        }
        return to;
      },
      writable: true,
      configurable: true,
    });
  }

  if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, "includes", {
      value: function (searchElement: any, fromIndex: any) {
        if (this == null) {
          throw new TypeError('"this" es null o no está definido');
        }

        // 1. Dejar que O sea ? ToObject(this value).
        var o = Object(this);

        // 2. Dejar que len sea ? ToLength(? Get(O, "length")).
        var len = o.length >>> 0;

        // 3. Si len es 0, devuelve false.
        if (len === 0) {
          return false;
        }

        // 4. Dejar que n sea ? ToInteger(fromIndex).
        //    (Si fromIndex no está definido, este paso produce el valor 0.)
        var n = fromIndex | 0;

        // 5. Si n ≥ 0, entonces
        //  a. Dejar que k sea n.
        // 6. Else n < 0,
        //  a. Dejar que k sea len + n.
        //  b. Si k < 0, Dejar que k sea 0.
        var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

        function sameValueZero(x: any, y: any) {
          return (
            x === y ||
            (typeof x === "number" &&
              typeof y === "number" &&
              isNaN(x) &&
              isNaN(y))
          );
        }

        // 7. Repite, mientras k < len
        while (k < len) {
          // a. Dejar que elementK sea el resultado de ? Get(O, ! ToString(k)).
          // b. Si SameValueZero(searchElement, elementK) es true, devuelve true.
          if (sameValueZero(o[k], searchElement)) {
            return true;
          }
          // c. Incrementa k por 1.
          k++;
        }

        // 8. Devuelve false
        return false;
      },
    });
  }

  // https://tc39.github.io/ecma262/#sec-array.prototype.findindex
  if (!Array.prototype.findIndex) {
    Object.defineProperty(Array.prototype, "findIndex", {
      value: function (predicate: any) {
        // 1. Let O be ? ToObject(this value).
        if (this == null) {
          throw new TypeError('"this" is null or not defined');
        }

        var o = Object(this);

        // 2. Let len be ? ToLength(? Get(O, "length")).
        var len = o.length >>> 0;

        // 3. If IsCallable(predicate) is false, throw a TypeError exception.
        if (typeof predicate !== "function") {
          throw new TypeError("predicate must be a function");
        }

        // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
        var thisArg = arguments[1];

        // 5. Let k be 0.
        var k = 0;

        // 6. Repeat, while k < len
        while (k < len) {
          // a. Let Pk be ! ToString(k).
          // b. Let kValue be ? Get(O, Pk).
          // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
          // d. If testResult is true, return k.
          var kValue = o[k];
          if (predicate.call(thisArg, kValue, k, o)) {
            return k;
          }
          // e. Increase k by 1.
          k++;
        }

        // 7. Return -1.
        return -1;
      },
      configurable: true,
      writable: true,
    });
  }
};

export { initializePolyfills };
