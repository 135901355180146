import { useQuery } from "react-query";
import {
  apiListContractsManagerInfo,
  IApiListContractsManagerInfoParams,
} from "../../../../../api/contract/apiListAllContractsManagerInfo";
import {
  ExtractFnReturnType,
  QueryConfig,
} from "../../../../../lib/react-query";
import { contractKeys } from "../util/contract.query-keys";

export const getContractsInfo = async (
  params: IApiListContractsManagerInfoParams = {}
) => {
  const response = await apiListContractsManagerInfo(params);

  return {
    contracts: response["hydra:member"],
    totalItems: response["hydra:totalItems"],
  };
};

type QueryFnType = typeof getContractsInfo;

type UseContractsInfoOptions = {
  config?: QueryConfig<QueryFnType>;
  params?: IApiListContractsManagerInfoParams;
};

export const useContractsInfo = ({
  config,
  params,
}: UseContractsInfoOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: contractKeys.listInfo(params ?? {}),
    queryFn: () => getContractsInfo(params),
  });
};
