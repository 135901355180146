import {
  GET_USUARIOS,
  GET_USUARIOS_SUCCESS,
  GET_USUARIO,
  GET_USUARIO_SUCCESS,
  UPDATE_USUARIOS_TABLE_STATE,
  UPDATE_USUARIOS,
  GET_STAFF,
  GET_STAFF_SUCCESS,
} from "../constants/ActionTypes";

export type TUserState = {
  usuarios: any[];
  staff: any[];
  staffMentions: {
    id: string;
    display: string;
    active: boolean;
  }[];
  numUsuarios: number;
  tableState: {
    data: any;
    totalItems: number;
    page: number;
    filters: {
      roleFilter: string[];
    };
    searchText: string;
  };
};

const INIT_STATE: TUserState = {
  usuarios: [],
  staff: [],
  staffMentions: [],
  numUsuarios: 0,
  tableState: {
    data: [],
    totalItems: 0,
    page: 1,
    filters: {
      roleFilter: [],
    },
    searchText: "",
  },
};

// eslint-disable-next-line
export default (state = INIT_STATE, action: { type: string; payload: any }) => {
  let newUsuarios: any;
  let usuarios: any;
  switch (action.type) {
    case GET_USUARIOS:
      return state;
    case GET_USUARIOS_SUCCESS:
      newUsuarios = action.payload.partialUsuarios;
      usuarios = [...state.usuarios];
      newUsuarios.forEach((newUsuario: any) => {
        let o: any = state.usuarios.find(
          (o) => (o as any).id === newUsuario.id
        );
        if (!o) {
          usuarios.push(Object.assign({}, newUsuario));
        }
      });
      return {
        ...state,
        usuarios: usuarios,
        numUsuarios: action.payload.numUsuarios,
      };
    case GET_STAFF:
      return state;
    case GET_STAFF_SUCCESS:
      newUsuarios = action.payload.partialUsuarios;
      usuarios = [...state.usuarios];
      newUsuarios.forEach((newUsuario: any) => {
        let o: any = state.usuarios.find(
          (o) => (o as any).id === newUsuario.id
        );
        if (!o && newUsuario.active && newUsuario.zohoId && newUsuario.zuId) {
          usuarios.push(Object.assign({}, newUsuario));
        }
      });
      const mentions = newUsuarios.map((item: any) => {
        return {
          id: "user#" + item.zohoId + "#" + item.zuId,
          display: item.fullname,
          active: item.active,
        };
      });
      mentions.push({
        id: "group#2241690000024458319#2241690000024458319",
        display: "Selección",
        active: true,
      });
      mentions.push({
        id: "group#2241690000024458341#2241690000024458341",
        display: "Comercial",
        active: true,
      });
      mentions.push({
        id: "group#2241690000026095001#2241690000026095001",
        display: "Laboral",
        active: true,
      });
      mentions.push({
        id: "group#2241690000040811479#2241690000040811479",
        display: "Agentes comercial",
        active: true,
      });
      // mentions.push({
      //   id: "role#2241690000000026008#2241690000000026008",
      //   display: "Laboral",
      //   active: true,
      // });
      // mentions.push({
      //   id: "role#2241690000037725947#2241690000037725947",
      //   display: "Coordinación",
      //   active: true,
      // });
      // mentions.push({
      //   id: "role#2241690000037725957#2241690000037725957",
      //   display: "Comercial",
      //   active: true,
      // });
      // mentions.push({
      //   id: "role#2241690000194579001#2241690000194579001",
      //   display: "Marketing",
      //   active: true,
      // });
      // mentions.push({
      //   id: "role#2241690000185495145#2241690000185495145",
      //   display: "Laboral FR",
      //   active: true,
      // });
      // mentions.push({
      //   id: "role#2241690000185495149#2241690000185495149",
      //   display: "Coordinación FR",
      //   active: true,
      // });
      // mentions.push({
      //   id: "role#2241690000185495153#2241690000185495153",
      //   display: "Comercial FR",
      //   active: true,
      // });
      return {
        ...state,
        staff: usuarios,
        staffMentions: mentions,
      };
    case GET_USUARIO:
      return state;
    case GET_USUARIO_SUCCESS:
      newUsuarios = action.payload.partialCandidatos;
      usuarios = [...state.usuarios];
      newUsuarios.forEach((newUsuario: any) => {
        let o: any = state.usuarios.find(
          (o) => (o as any).id === newUsuario.id
        );
        if (!o) {
          usuarios.push(Object.assign({}, newUsuario));
        }
      });
      return {
        ...state,
        usuarios: usuarios,
      };
    case UPDATE_USUARIOS_TABLE_STATE:
      return {
        ...state,
        tableState: action.payload,
      };
    case UPDATE_USUARIOS:
      newUsuarios = action.payload;
      usuarios = [...state.usuarios];
      newUsuarios.forEach((newUsuario: any) => {
        let o: any = state.usuarios.find(
          (o) => (o as any).id === newUsuario.id
        );
        if (!o) {
          usuarios.push(Object.assign({}, newUsuario));
        }
      });
      return {
        ...state,
        usuarios: usuarios,
      };
    default:
      return state;
  }
};
