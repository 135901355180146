import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { SERVICE_TYPE } from "../../../../../../api/service/service.types";
import { TStaffUserResponse } from "../../../../../../api/user/apiListStaffUsers";
import { formatCurrency } from "../../../../../../util/formatNumbers";
import InfoBox from "../ServiceInfoPanel/InfoBox";
import InfoBoxElement from "../ServiceInfoPanel/InfoBoxElement";
import InfoBoxItem from "../ServiceInfoPanel/InfoBoxitem";
import CrmServiceLink from "../../../promotersManager/routes/UnratedServicesPage/components/CrmServiceLink";
import IncentivesBox from "../../../promotersManager/routes/UnratedServicesPage/components/IncentivesBox";
import ServiceOwnerSelector from "../../../promotersManager/routes/UnratedServicesPage/components/ServiceOwnerSelector/ServiceOwnerSelector";
import useUnratedServiceDetailQuery from "./hooks/useUnratedServiceDetailQuery";
import { serviceCommercialNotes } from "../../util/service.util";

const StyledAsideTitle = styled(Typography)``;

const StyledInfoText = styled(Typography)`
  font-size: 0.875rem;
`;

interface IUnratedServiceDetail {
  superAdminId: string | null;
  serviceId: string | null;
  ownerOptions: TStaffUserResponse[];
  onOwnerChanged: () => void;
}

const UnratedServiceDetail = (props: IUnratedServiceDetail) => {
  const { serviceId, ownerOptions, onOwnerChanged, superAdminId } = props;

  const serviceQuery = useUnratedServiceDetailQuery(serviceId);

  const { formatMessage } = useIntl();

  const handleOwnerChanged = () => {
    serviceQuery.refetch();
    onOwnerChanged();
  };

  if (null === serviceId) {
    return (
      <InfoBox
        summary={
          <StyledAsideTitle>
            <FormattedMessage
              id="UnratedServicesPage.Información"
              defaultMessage="Información"
            />
          </StyledAsideTitle>
        }
        details={
          <StyledInfoText>
            <FormattedMessage
              id="UnratedServicePage.Selecciona un servicio para más información"
              defaultMessage="Selecciona un servicio para más información"
            />
          </StyledInfoText>
        }
      />
    );
  }

  return (
    <InfoBox
      summary={
        <StyledAsideTitle>
          <FormattedMessage
            id="UnratedServicesPage.Detalles del servicio"
            defaultMessage="Detalles del servicio"
          />
        </StyledAsideTitle>
      }
      details={
        <Grid container spacing={8}>
          <Grid item xs={12} md={12}>
            <InfoBoxElement
              label={formatMessage({
                id: "UnratedServicesPage.Nombre en Zoho",
                defaultMessage: "Nombre en Zoho",
              })}
              data={
                <CrmServiceLink
                  zohoName={serviceQuery.data?.Name}
                  zohoId={serviceQuery.data?.zohoId}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <InfoBoxElement
              label={formatMessage({
                id: "UnratedServicesPage.Coordinador/a del Servicio",
                defaultMessage: "Coordinador/a del Servicio",
              })}
              data={
                !!serviceQuery.data?.Owner && (
                  <ServiceOwnerSelector
                    currentOwner={
                      serviceQuery.data?.Owner?.id !== superAdminId
                        ? serviceQuery.data?.Owner?.id ?? null
                        : null
                    }
                    ownerOptions={ownerOptions}
                    serviceId={serviceId}
                    onOwnerChanged={handleOwnerChanged}
                  />
                )
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InfoBoxItem
              label={formatMessage({
                id: "UnratedServicesPage.Tipo",
                defaultMessage: "Tipo",
              })}
              data={serviceQuery.data?.Tipo_de_Servicio}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InfoBoxItem
              label={formatMessage({
                id: "UnratedServicesPage.Plan",
                defaultMessage: "Plan",
              })}
              data={serviceQuery.data?.Plan}
            />
          </Grid>
          {serviceQuery.data?.Tipo_de_Servicio === SERVICE_TYPE.SELECTION && (
            <Grid item xs={6}>
              <InfoBoxItem
                label={formatMessage({
                  id: "UnratedServicesPage.Salario bruto",
                  defaultMessage: "Salario bruto",
                })}
                data={
                  serviceQuery.data?.Salario_bruto_ventas
                    ? formatCurrency(serviceQuery.data?.Salario_bruto_ventas)
                    : "-"
                }
              />
            </Grid>
          )}
          {serviceQuery.data?.Tipo_de_Servicio === SERVICE_TYPE.SELECTION && (
            <Grid item xs={6}>
              <InfoBoxItem
                label={formatMessage({
                  id: "UnratedServicesPage.Salario neto",
                  defaultMessage: "Salario neto",
                })}
                data={
                  serviceQuery.data?.Salario_neto_ventas
                    ? formatCurrency(serviceQuery.data?.Salario_neto_ventas)
                    : "-"
                }
              />
            </Grid>
          )}
          {serviceQuery.data?.Tipo_de_Servicio === SERVICE_TYPE.SELECTION && (
            <Grid item xs={6}>
              <InfoBoxItem
                label={formatMessage({
                  id: "UnratedServicesPage.Salario diario",
                  defaultMessage: "Salario diario",
                })}
                data={
                  serviceQuery.data?.Sueldo_d_a_trabajador
                    ? formatCurrency(serviceQuery.data?.Sueldo_d_a_trabajador)
                    : "-"
                }
              />
            </Grid>
          )}
          {serviceQuery.data?.Tipo_de_Servicio === SERVICE_TYPE.SELECTION && (
            <Grid item xs={6}>
              <InfoBoxItem
                label={formatMessage({
                  id: "UnratedServicesPage.Seguridad social",
                  defaultMessage: "Seguridad social",
                })}
                data={
                  serviceQuery.data?.Seguridad_social_ventas
                    ? formatCurrency(serviceQuery.data?.Seguridad_social_ventas)
                    : "-"
                }
              />
            </Grid>
          )}
          {serviceQuery.data?.Tipo_de_Servicio === SERVICE_TYPE.SELECTION && (
            <IncentivesBox
              summary={
                <Typography variant="h4">
                  <FormattedMessage
                    id="UnratedServicesPage.Incentivos"
                    defaultMessage="Incentivos"
                  />
                </Typography>
              }
              details={
                <Grid container spacing={8}>
                  <Grid item xs={12}>
                    <InfoBoxItem
                      label={formatMessage({
                        id: "UnratedServicesPage.Detalle de incentivos",
                        defaultMessage: "Detalle de incentivos",
                      })}
                      data={serviceQuery.data?.Detalle_incentivos}
                    />
                  </Grid>
                </Grid>
              }
            />
          )}
          <Grid item xs={12} md={12}>
            <InfoBoxItem
              label={formatMessage({
                id: "UnratedServicesPage.Horario",
                defaultMessage: "Horario",
              })}
              data={serviceQuery.data?.Horario_servicio_venta}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <InfoBoxItem
              label={formatMessage({
                id: "UnratedServicesPage.Ciudad",
                defaultMessage: "Ciudad",
              })}
              data={serviceQuery.data?.Ciudad}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <InfoBoxItem
              label={formatMessage({
                id: "UnratedServicesPage.Notas del departamento comercial",
                defaultMessage: "Notas del departamento comercial",
              })}
              data={
                !!serviceQuery.data
                  ? serviceCommercialNotes(serviceQuery.data) ?? "-"
                  : "-"
              }
            />
          </Grid>
        </Grid>
      }
    />
  );
};

export default UnratedServiceDetail;
