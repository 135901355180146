import { apiEmpleo } from "../../util/ApiEmpleo";
import {
  TContractDealType,
  TContractPublished,
  TContractSalaryType,
  TContractJobType,
} from "./contract.types";

export type TUpdateContractPayload = {
  T_tulo?: string;
  Tipo_de_trato?: TContractDealType;
  Tipo?: TContractJobType;
  Ciudad_oferta?: string;
  Horario_Servicio?: string;
  Que_buscamos?: string;
  Requisitos?: string;
  Tipo_de_salario?: TContractSalaryType;
  Salario_Bruto_Mensual?: number;
  Tareas_solicitadas?: string[];
  Publicar?: TContractPublished;
  Promoci_n_Indeed?: string;
  Owner?: string;
  ownerName?: string;
};

const apiUpdateContract = async (
  contractId: string,
  payload: TUpdateContractPayload
) => {
  if (payload.Owner) {
    payload.Owner = `/api/users/${payload.Owner}`;
  }

  const res = await apiEmpleo.patch(`contratos/${contractId}`, payload, {
    headers: {
      "Content-Type": "application/merge-patch+json",
    },
  });

  return res;
};

export { apiUpdateContract };
