import {
  GET_SITE_STATS,
  GET_SITE_STATS_SUCCESS,
  GET_STATISTIC,
  GET_STATISTIC_SUCCESS
} from "../constants/ActionTypes";

export const getSiteStats = () => {
  return {
    type: GET_SITE_STATS
  };
};

export const getSiteStatsSuccess = (siteStats: any) => {
  return {
    type: GET_SITE_STATS_SUCCESS,
    payload: siteStats
  };
};

export const getStatistic = (
  statName: string,
  statPeriod: string,
  startDate: any,
  endDate: any
) => {
  return {
    type: GET_STATISTIC,
    payload: {
      statName,
      statPeriod,
      startDate,
      endDate
    }
  };
};

export const getStatisticSuccess = (statData: any) => {
  return {
    type: GET_STATISTIC_SUCCESS,
    payload: statData
  };
};
