import { useMutation } from "react-query";
import {
  apiMarkAsReadMessages,
  TMarkAsReadMessagesRequest,
} from "../../../../../api/message/apiMarkAsReadMessages";
import { queryClient } from "../../../../../lib/react-query";

export default function useMarkAsReadMutation() {
  const markAsRead = async (payload: TMarkAsReadMessagesRequest) => {
    await apiMarkAsReadMessages(payload);
  };

  return useMutation(markAsRead, {
    onMutate: (payload) => {
      queryClient.cancelQueries(["messages"]);
    },
    onSettled: (data, error, payload) => {
      queryClient.invalidateQueries(["messages"]);
    },
  });
}
