import {
  CircularProgress,
  Divider,
  FormControlLabel,
  Switch,
  Typography,
} from "@material-ui/core";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import ErrorIcon from "@material-ui/icons/Error";
import classNames from "classnames";
import { motion, Variants } from "framer-motion";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import { useScroll } from "react-use";
import styled from "styled-components";
import {
  TGetNotificationsCollectionItem,
  TNotificationType,
} from "../../../../../../api/notification/notification.types";
import IntersectionPlaceholder from "../../../../../../components/IntersectionPlaceholder";
import {
  COLOR_RED_NOTIFICATION,
  cuideoTheme,
} from "../../../../../../containers/themes/defaultTheme";
import { useAuthUser } from "../../../../../../reducers/hooks/useAuthUser";
import { useInfiniteNotifications } from "../../api/getInfiniteNotifications";
import { useUpdateNotification } from "../../api/updateNotification";
import { useNotificationPeriod } from "../../hooks/useNotificationPeriod";
import { notificationHasPeriodSeparator } from "../../util/notification.utl";
import NotificationItem from "./NotificationItem";
import NotificationPeriodSeparator from "./NotificationPeriodSeparator";

const StyledWrapper = styled.div`
  height: calc(100vh - 12rem);
  position: relative;

  overflow-y: scroll;
  overflow-x: hidden;
`;

const StyledTop = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: #fff;

  &.CMui-sticky {
    position: sticky;
    top: 0;
    z-index: 10;
    border-bottom: 1px solid #ccc;
    padding: 1.5rem 1.25rem 0.5rem 1.25rem;
  }
`;

const StyledTopLeft = styled.div`
  flex: 1 1 0;
`;

const StyledTopCenter = styled.div`
  flex: 1 1 0;
  text-align: center;
`;

const StyledTopRight = styled.div`
  flex: 1 1 0;
`;

const StyledTopMiniTitle = styled(Typography)`
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  text-align: center;
`;

const StyledTopTitle = styled(Typography)`
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
`;

const StyledTopActions = styled.div``;

const StyledTopPeriod = styled(Typography)`
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 600;
  color: #777;
`;

const StyledControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-size: 0.875rem;
  }

  .MuiSwitch-root {
    width: 2.5rem;
    height: 1rem;
    padding: 0 0.375rem;

    .MuiSwitch-switchBase {
      padding: 0.0625rem 0.4375rem;

      &.Mui-checked {
        transform: translateX(0.6875rem);
      }
    }
    .MuiSwitch-thumb {
      width: 0.875rem;
      height: 0.875rem;
    }
  }
`;

const StyledDivider = styled(Divider)``;

const StyledListContainer = styled.div`
  padding: 0.5rem 0.75rem;
`;

const StyledItemWrapper = styled.div``;

const StyledLoadingWrapper = styled.div`
  position: absolute;
  top: calc(50% - 2rem);
  left: calc(50% - 1rem);
`;

const StyledCenteredWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 90%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .MuiSvgIcon-root {
    width: 6rem;
    height: 6rem;
    margin-bottom: 1rem;
    color: ${cuideoTheme.palette.primary.main};
  }

  &.CMui-error {
    .MuiSvgIcon-root {
      color: ${COLOR_RED_NOTIFICATION};
    }
  }
`;

const StyledErrorText = styled(Typography)`
  color: ${COLOR_RED_NOTIFICATION};
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
`;

const StyledCenteredText = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
`;

const notifyIconVariants: Variants = {
  enter: {
    opacity: 1,
    scale: 1,
    transition: { delay: 0.25 },
  },
  exit: {
    opacity: 0,
    scale: 0.75,
  },
};

export type TNotificationWithPosition = TGetNotificationsCollectionItem & {
  yPos: number;
};

interface INotificationsViewerProps {
  title: string;
  emptyLabel: string;
  notificationTypes: TNotificationType[];
  onNotificationClick: (notification: TGetNotificationsCollectionItem) => void;
}

const NotificationsViewer: React.FC<INotificationsViewerProps> = (props) => {
  const { title, emptyLabel, notificationTypes, onNotificationClick } = props;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { y } = useScroll(wrapperRef);
  const [state, setState] = useState({
    showOnlyNotRead: false,
    notifications: [] as TNotificationWithPosition[],
    hasMoreItems: false,
  });
  const { authUser } = useAuthUser();
  const { formatPeriod } = useNotificationPeriod();

  const notificationsInfiniteQuery = useInfiniteNotifications({
    params: {
      recipient: [`user:${authUser.userId}`],
      type: notificationTypes,
      read: state.showOnlyNotRead ? false : undefined,
      itemsPerPage: 10,
    },
    config: {
      getNextPageParam: (lastPage, pages) => pages.length + 1,
    },
  });
  const updateNotificationMutation = useUpdateNotification();

  useEffect(
    function updateNotificationsState() {
      let hasMoreItems = false;
      let totalItems = 0;
      const notifications: TNotificationWithPosition[] = [];
      notificationsInfiniteQuery.data?.pages.forEach((group, i) => {
        if (i === 0) {
          totalItems = group["hydra:totalItems"] ?? 0;
        }
        group["hydra:member"].forEach((itemContract) => {
          notifications.push({ ...itemContract, yPos: 0 });
        });
      });
      if (totalItems > notifications.length) {
        hasMoreItems = true;
      }
      setState((prevState) => ({
        ...prevState,
        notifications,
        hasMoreItems,
      }));
    },
    [notificationsInfiniteQuery.data?.pages]
  );

  const handleLoadMoreNotifications = () => {
    notificationsInfiniteQuery.fetchNextPage();
  };

  const handleSwitchToggle = () => {
    setState((prevState) => ({
      ...prevState,
      showOnlyNotRead: !prevState.showOnlyNotRead,
    }));
  };

  const notificationChangeRead = useCallback(
    (notificationId: string, read: boolean) => {
      updateNotificationMutation.mutate({
        notificationId,
        read,
      });
    },
    // eslint-disable-next-line
    [updateNotificationMutation.mutate]
  );

  const handleUpdateNotificationYPos = useCallback(
    (notification: TNotificationWithPosition, newYPos: number) => {
      setState((prevState) => ({
        ...prevState,
        notifications: prevState.notifications.map((prevNotification) => {
          if (prevNotification.id === notification.id) {
            return {
              ...prevNotification,
              yPos: newYPos,
            };
          }
          return prevNotification;
        }),
      }));
    },
    []
  );

  const firstNotificationVisible = state.notifications.find((notification) => {
    return y - 15 < notification.yPos;
  });

  return (
    <StyledWrapper ref={wrapperRef}>
      <StyledTop
        className={classNames({
          "CMui-sticky": y > 50,
        })}
      >
        {y > 50 ? (
          <>
            <StyledTopLeft>
              {!!firstNotificationVisible && (
                <StyledTopPeriod>
                  {formatPeriod(firstNotificationVisible.createdAt)}
                </StyledTopPeriod>
              )}
            </StyledTopLeft>
            <StyledTopCenter>
              <StyledTopMiniTitle>{title}</StyledTopMiniTitle>
            </StyledTopCenter>
            <StyledTopRight></StyledTopRight>
          </>
        ) : (
          <>
            <StyledTopTitle>{title}</StyledTopTitle>
            <StyledTopActions>
              <StyledControlLabel
                control={
                  <Switch
                    checked={state.showOnlyNotRead}
                    onChange={handleSwitchToggle}
                    color="primary"
                  />
                }
                label={
                  <FormattedMessage
                    id="NotificationViewer.Mostrar sólo las no leídas"
                    defaultMessage="Mostrar solo las no leídas"
                  />
                }
                labelPlacement="start"
              />
            </StyledTopActions>
          </>
        )}
      </StyledTop>
      <StyledDivider />
      <StyledListContainer>
        {notificationsInfiniteQuery.status === "loading" ? (
          <StyledLoadingWrapper>
            <CircularProgress thickness={5} size={28} />
          </StyledLoadingWrapper>
        ) : notificationsInfiniteQuery.status === "error" ? (
          <StyledCenteredWrapper className="CMui-error">
            <motion.div
              initial="exit"
              animate="enter"
              variants={notifyIconVariants}
            >
              <ErrorIcon />
            </motion.div>
            <StyledErrorText>
              <FormattedMessage
                id="Notifications.Ha habido algún error accediendo a las notificaciones"
                defaultMessage="Ha habido algún error accediendo a las notificaciones"
              />
            </StyledErrorText>
          </StyledCenteredWrapper>
        ) : state.notifications.length === 0 ? (
          <StyledCenteredWrapper>
            <motion.div
              initial="exit"
              animate="enter"
              variants={notifyIconVariants}
            >
              <AllInboxIcon />
            </motion.div>
            <StyledCenteredText>{emptyLabel}</StyledCenteredText>
          </StyledCenteredWrapper>
        ) : (
          state.notifications.map((notification, index) => (
            <Fragment key={`${notification.id}__wrap`}>
              {(index === 0 ||
                notificationHasPeriodSeparator(
                  notification,
                  state.notifications[index - 1]
                )) && (
                <NotificationPeriodSeparator
                  createdAt={notification.createdAt}
                />
              )}
              {state.hasMoreItems &&
                !notificationsInfiniteQuery.isFetchingNextPage &&
                state.notifications.length - index < 3 && (
                  <IntersectionPlaceholder
                    onVisibleOnScreen={handleLoadMoreNotifications}
                  />
                )}
              <StyledItemWrapper key={notification.id}>
                <NotificationItem
                  notification={notification}
                  onNotificationClick={onNotificationClick}
                  onUpdateYPos={handleUpdateNotificationYPos}
                  onNotificationReadChange={notificationChangeRead}
                />
              </StyledItemWrapper>
            </Fragment>
          ))
        )}
      </StyledListContainer>
    </StyledWrapper>
  );
};

export default NotificationsViewer;
