import { Badge } from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { useConversations } from "../../../../apps/affinity/features/conversation";
import MessagesPng from "../../../../assets/images/messages.png";
import { useAuthUser } from "../../../../reducers/hooks/useAuthUser";
import { StyledIcon, StyledIconButton } from "../../SideNavSC";

const REFETCH_TIME_IN_MS = 15000;

const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    color: #fff;
    background-color: #cc1016;
    font-weight: 600;
    top: 1rem;
    right: 0.5rem;
  }
`;

interface ICommunicationsButtonWithBadgeProps {
  isActive: boolean;
}

const CommunicationsButtonWithBadge: React.FC<ICommunicationsButtonWithBadgeProps> =
  (props) => {
    const { isActive } = props;

    const { formatMessage } = useIntl();
    const { authUser } = useAuthUser();
    const unreadConversationsQuery = useConversations({
      params: {
        interestedId: authUser.coreV3Id,
        unread: true,
      },
      config: {
        refetchInterval: REFETCH_TIME_IN_MS,
      },
    });

    return (
      <StyledIconButton
        className={isActive ? "CMui-active" : undefined}
        aria-label={formatMessage({
          id: "SideNav.Comunicaciones",
          defaultMessage: "Comunicaciones",
        })}
      >
        <StyledBadge
          badgeContent={
            unreadConversationsQuery.data?.conversations.length ?? 0
          }
          overlap="rectangular"
        >
          <StyledIcon
            src={MessagesPng}
            alt={formatMessage({
              id: "SideNav.Comunicaciones",
              defaultMessage: "Comunicaciones",
            })}
            title={formatMessage({
              id: "SideNav.Comunicaciones",
              defaultMessage: "Comunicaciones",
            })}
          />
        </StyledBadge>
      </StyledIconButton>
    );
  };

export default CommunicationsButtonWithBadge;
