import DateFnsUtils from "@date-io/date-fns";
import { Box, Divider, Grid } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import { Form, Formik, FormikHelpers } from "formik";
import { motion } from "framer-motion";
import moment from "moment";
import "moment/locale/es";
import { Fragment, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import * as Yup from "yup";
import { string } from "yup";
import {
  getCandidato,
  getPartialCandidatoSuccess,
  hideBackdrop,
  modalShow,
  showBackdrop,
  snackErrorMessage,
  snackInfoMessage,
  snackSuccessMessage,
} from "../../../../../actions";
import ManIcon from "../../../../../assets/images/iconos/man.svg";
import NoIcon from "../../../../../assets/images/iconos/no.svg";
import SiIcon from "../../../../../assets/images/iconos/si.svg";
import WomanIcon from "../../../../../assets/images/iconos/woman.svg";
import NotesV2Table from "../../../../../components/Candidato/NotesV2Table/NotesV2Table";
import useCountryPathologies from "../../../../../components/Candidato/useCountryPathologies";
import useEmployeeDegreesOptions from "../../../../../components/CandidatoCard/useEmployeeDegreesOptions";
import useEmployeeProvincesOptions from "../../../../../components/CandidatoCard/useEmployeeProvincesOptions";
import useEmployeeTaskOptions from "../../../../../components/CandidatoCard/useEmployeeTaskOptions";
import useFormatters from "../../../../../components/FormattedFields/useFormatters";
import {
  FormikCheckbox,
  FormikRadioGroup,
  FormikSelect,
  FormikTextField,
} from "../../../../../components/FormikFields";
import FormikAttachCif from "../../../../../components/FormikFields/FormikAttachCif";
import FormikCVUpload from "../../../../../components/FormikFields/FormikCVUpload";
import FormikDatePicker from "../../../../../components/FormikFields/FormikDatePicker";
import VisibleFieldOnProfile from "../../../../../components/FormikFields/VisibleFieldOnProfile";
import { apiEmpleo } from "../../../../../util/ApiEmpleo";
import provincias from "../../../../../util/defaults/provincias";
import { prepareTitulacionesInitialValue } from "../../../../../util/defaults/titulaciones";
import { formatAsPhoneNumber } from "../../../../../util/formatters";
import {
  validateEmail,
  validateIBANorEmpty,
  validatePhone,
  validateUniqueEmail,
  validateUniquePhone,
} from "../../../../../util/validators/yupValidators";
import ExperienciasTable from "../../ExperiencesTable/ExperiencesTable";
import FormacionesTable from "../../FormationsTable/FormationsTable";
import {
  profileVariants,
  StyledField,
  StyledGroup,
  StyledHeaderWithButton,
  StyledHelper,
  StyledLabel,
  StyledSectionHeader,
  StyledTopSaveButton,
  StyledWrapper,
} from "../CoordinatorFileSC";
import UserInfoBox from "../UserInfoBox";

const CoordinatorFileFR = (props: any) => {
  const {
    candidato,
    getPartialCandidatoSuccess,
    paisesOptions,
    idiomasOptions,
    experienceYearsOptions,
    modalShow,
  } = props;
  const { formatMessage } = useIntl();
  const { formatExperience, formatLanguage, formatCountry } = useFormatters();

  const pathologyOptions = useCountryPathologies("fr");

  const titulacionesOptions = useEmployeeDegreesOptions(
    candidato.Business_Country
  );
  const tareasOptions = useEmployeeTaskOptions(candidato.Business_Country);

  const provinciasOptions = useEmployeeProvincesOptions(
    candidato.Business_Country
  );

  const initialValues = useMemo(() => {
    const noBotDate = moment(candidato.No_iniciar_bot_hasta);
    let noBotText = "";
    let noBotFlag = false;
    if (candidato.No_iniciar_bot_hasta && noBotDate.isValid()) {
      noBotFlag = true;
      const nowPlus50 = moment().add(50, "years");
      if (noBotDate.isBefore(nowPlus50)) {
        noBotText = noBotDate.format("YYYY-MM-DD");
      }
    }

    const tempInitialValues = {
      Tratamiento: {
        value: candidato.Tratamiento,
        label: candidato.Tratamiento,
      },
      Nombre: candidato.Nombre,
      Apellidos: candidato.Apellidos,
      N_CUENTA: candidato.N_CUENTA,
      Sexo: candidato.Sexo,
      Fecha_de_nacimiento: candidato.Fecha_de_nacimiento
        ? moment(candidato.Fecha_de_nacimiento).toDate()
        : null,
      Lugar_de_nacimiento: {
        value: candidato.Lugar_de_nacimiento,
        label: formatCountry(candidato.Lugar_de_nacimiento),
      },
      Otros_Idiomas: candidato.Otros_Idiomas.map((item: any) => {
        return {
          value: item,
          label: formatLanguage(item),
        };
      }),
      A_os_de_experiencia: candidato.A_os_de_experiencia
        ? {
            value: candidato.A_os_de_experiencia,
            label: formatExperience(candidato.A_os_de_experiencia),
          }
        : null,
      Direccion: candidato.Direccion,
      Numero: candidato.Numero,
      Piso: candidato.Piso,
      Codigo_postal: candidato.Codigo_postal,
      Phone: formatAsPhoneNumber(candidato.Phone),
      Email: candidato.Email,
      Ciudad: candidato.Ciudad,
      D_partement: {
        value: candidato.D_partement,
        label: candidato.D_partement,
      },
      Carnet_de_conducir: candidato.Carnet_de_conducir,
      Veh_culo_propio: candidato.Veh_culo_propio,
      Titulaci_n_oficial_SAD: prepareTitulacionesInitialValue(
        candidato.Titulaci_n_oficial_SAD
      ),
      Interna_entre_semana: candidato.Interna_entre_semana,
      Interna_fin_de_semana: candidato.Interna_fin_de_semana,
      Externa_entre_semana: candidato.Externa_entre_semana,
      Externa_fin_de_semana: candidato.Externa_fin_de_semana,
      Observaciones_generales: candidato.Observaciones_generales,
      Codigo_ofertas_FR: candidato.Codigo_ofertas_FR,
      Notas_disponibilidad: candidato.Notas_disponibilidad,
      N_SS: candidato.N_SS,
      W_Breve_descripci_n: candidato.W_Breve_descripci_n,
      Formaciones: candidato.Formaciones,
      Experiencia_y_referencias: candidato.Experiencia_y_referencias,
      Latitud: candidato.Latitud,
      Longitud: candidato.Longitud,
      cif_obverse:
        candidato.obverseCif && candidato.obverseCif.fileUrl
          ? `candidatos/${candidato.id}/obverse-cif/view`
          : null,
      cif_back:
        candidato.backCif && candidato.backCif.fileUrl
          ? `candidatos/${candidato.id}/back-cif/view`
          : null,
      curriculum:
        candidato.curriculum && candidato.curriculum.fileUrl
          ? // ? candidato.curriculum.fileUrl
            {
              fileUrl: candidato.curriculum.fileUrl,
              fileView: `candidatos/${candidato.id}/curriculum/view`,
            }
          : null,

      No_mostrar_imagen: candidato.No_mostrar_imagen ? true : false,
      No_iniciar_bot_hasta: noBotText,
      noBot: noBotFlag,
    } as any;
    tareasOptions.forEach((item: any) => {
      tempInitialValues["tarea__" + item.value] =
        candidato.Qu_tareas_quieres_o_puedes_realizar.includes(item.value);
    });
    pathologyOptions.forEach((item: any) => {
      tempInitialValues["pathology__" + item.value] =
        candidato.Patolog_as_en_las_que_tiene_experiencia.includes(item.value);
    });

    return tempInitialValues;
    // eslint-disable-next-line
  }, [tareasOptions, pathologyOptions, candidato, formatMessage]);

  const validationSchema = Yup.object({
    Nombre: Yup.string().required(
      formatMessage({
        id: "CoordinatorFile.RequiredName",
        defaultMessage: "Debes introducir un nombre",
      })
    ),
    Apellidos: Yup.string().required(
      formatMessage({
        id: "CoordinatorFile.RequiredSurname",
        defaultMessage: "Debes introducir el primer apellido",
      })
    ),
    Fecha_de_nacimiento: Yup.mixed().test(
      "valid_date",
      formatMessage({
        id: "CoordinatorFile.La fecha de nacimiento ha de ser válida",
        defaultMessage: "La fecha de nacimiento ha de ser válida",
      }),
      (value) => {
        const momDate = moment(value);
        if (momDate.isValid()) {
          return true;
        }
        return false;
      }
    ),
    N_CUENTA: Yup.string().test(
      "n_cta",
      formatMessage({
        id: "CoordinatorFile.ValidIban",
        defaultMessage: "Número de cuenta no válida",
      }),
      validateIBANorEmpty
    ),
    curriculum: Yup.mixed()
      .test(
        "fileSize",
        formatMessage({
          id: "CoordinatorFile.El archivo es demasiado grande",
          defaultMessage: "El archivo es demasiado grande",
        }),
        (value) => {
          if (!value) return true;
          if (!value.size) return true;
          if (value.size < 2000000) return true; // 2MB limit
          return false;
        }
      )
      .test(
        "fileType",
        formatMessage({
          id: "CoordinatorFile.El archivo debe ser de tipo JPG, PNG o PDF",
          defaultMessage: "El archivo debe ser de tipo JPG, PNG o PDF",
        }),
        (value) => {
          if (!value) return true;
          if (!value.type) return true;
          if (
            ["image/png", "image/jpeg", "application/pdf"].includes(value.type)
          )
            return true;
          return false;
        }
      ),
  });

  const handleFormSubmit = (values: any, actions: FormikHelpers<any>) => {
    const sendData = async (values: any) => {
      const newData: any = {};
      if (values.Nombre !== candidato.Nombre) {
        newData.Nombre = values.Nombre;
      }
      if (values.Apellidos !== candidato.Apellidos) {
        newData.Apellidos = values.Apellidos;
      }
      if (values.Sexo !== candidato.Sexo) {
        newData.Sexo = values.Sexo;
      }
      if (values.Lugar_de_nacimiento.value !== candidato.Lugar_de_nacimiento) {
        newData.Lugar_de_nacimiento = values.Lugar_de_nacimiento.value;
      }
      const birthDate = moment(values.Fecha_de_nacimiento);
      if (birthDate.isValid()) {
        newData.Fecha_de_nacimiento = birthDate.format("YYYY-MM-DD");
      }
      if (values.Direccion !== candidato.Direccion) {
        newData.Direccion = values.Direccion;
      }
      if (values.Numero !== candidato.Numero) {
        newData.Numero = values.Numero;
      }
      if (values.Piso !== candidato.Piso) {
        newData.Piso = values.Piso;
      }
      if (values.Codigo_postal !== candidato.Codigo_postal) {
        newData.Codigo_postal = values.Codigo_postal;
      }
      if (values.D_partement.value !== candidato.D_partement) {
        newData.D_partement = values.D_partement.value;
      }
      if (values.Ciudad !== candidato.Ciudad) {
        newData.Ciudad = values.Ciudad;
      }
      const newPhone = values.Phone.replace(/\s+/g, "");
      if (newPhone !== candidato.Phone) {
        newData.Phone = newPhone;
      }
      if (values.Email !== candidato.Email) {
        newData.Email = values.Email;
      }
      if (values.N_CUENTA !== candidato.N_CUENTA) {
        newData.N_CUENTA = values.N_CUENTA;
      }
      newData.Otros_Idiomas = values.Otros_Idiomas.map(
        (item: any) => item.value
      );
      if (values.N_SS !== candidato.N_SS) {
        newData.N_SS = values.N_SS;
      }
      newData.Tratamiento = values.Tratamiento.value;
      if (values.A_os_de_experiencia && values.A_os_de_experiencia.value) {
        newData.A_os_de_experiencia = values.A_os_de_experiencia.value;
      }
      newData.Titulaci_n_oficial_SAD = values.Titulaci_n_oficial_SAD.value
        ? [values.Titulaci_n_oficial_SAD.value]
        : [];
      if (values.Sexo !== candidato.Sexo) {
        newData.Sexo = values.Sexo;
      }
      if (values.Carnet_de_conducir !== candidato.Carnet_de_conducir) {
        newData.Carnet_de_conducir = values.Carnet_de_conducir;
      }
      if (values.Veh_culo_propio !== candidato.Veh_culo_propio) {
        newData.Veh_culo_propio = values.Veh_culo_propio;
      }
      if (values.Notas_disponibilidad !== candidato.Notas_disponibilidad) {
        newData.Notas_disponibilidad = values.Notas_disponibilidad;
      }
      if (values.W_Breve_descripci_n !== candidato.W_Breve_descripci_n) {
        newData.W_Breve_descripci_n = values.W_Breve_descripci_n;
      }
      if (
        values.Observaciones_generales !== candidato.Observaciones_generales
      ) {
        newData.Observaciones_generales = values.Observaciones_generales;
      }
      if (values.Interna_entre_semana !== candidato.Interna_entre_semana) {
        newData.Interna_entre_semana = values.Interna_entre_semana;
      }
      if (values.Interna_fin_de_semana !== candidato.Interna_fin_de_semana) {
        newData.Interna_fin_de_semana = values.Interna_fin_de_semana;
      }
      if (values.Externa_entre_semana !== candidato.Externa_entre_semana) {
        newData.Externa_entre_semana = values.Externa_entre_semana;
      }
      if (values.Externa_fin_de_semana !== candidato.Externa_fin_de_semana) {
        newData.Externa_fin_de_semana = values.Externa_fin_de_semana;
      }
      if (values.No_mostrar_imagen !== candidato.No_mostrar_imagen) {
        newData.No_mostrar_imagen = values.No_mostrar_imagen;
      }
      const newTareas = [] as string[];
      tareasOptions.forEach((item: any) => {
        if (values["tarea__" + item.value]) {
          newTareas.push(item.value);
        }
      });
      newData.Qu_tareas_quieres_o_puedes_realizar = newTareas;

      const newPathologies = [] as string[];
      pathologyOptions.forEach((item: any) => {
        if (values["pathology__" + item.value]) {
          newPathologies.push(item.value);
        }
      });
      newData.Patolog_as_en_las_que_tiene_experiencia = newPathologies;

      try {
        // showBackdrop();
        getPartialCandidatoSuccess({
          partialCandidatos: [
            {
              ...newData,
              id: candidato.id,
            },
          ],
        });
        let res = await apiEmpleo.patch(
          "candidatos/" + candidato.id,
          {
            ...newData,
          },
          {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          }
        );

        if (
          initialValues.cif_obverse &&
          values.cif_obverse !== initialValues.cif_obverse
        ) {
          await apiEmpleo.delete(`/candidatos/${candidato.id}/obverse-cif`, {
            params: {
              doNotLog: 1,
            },
          });
        }
        if (
          initialValues.cif_back &&
          values.cif_back !== initialValues.cif_back
        ) {
          await apiEmpleo.delete(`/candidatos/${candidato.id}/back-cif`, {
            params: {
              doNotLog: 1,
            },
          });
        }

        if (values.cif_obverse && typeof values.cif_obverse === "object") {
          const form = new FormData();
          form.append("file", values.cif_obverse);
          await apiEmpleo.post(
            `/candidatos/${candidato.id}/obverse-cif`,
            form,
            {
              headers: {
                "content-type": `multipart/form-data; boundary=${
                  (form as any)._boundary
                }`,
              },
            }
          );
        }

        if (values.cif_back && typeof values.cif_back === "object") {
          const form = new FormData();
          form.append("file", values.cif_back);
          await apiEmpleo.post(`/candidatos/${candidato.id}/back-cif`, form, {
            headers: {
              "content-type": `multipart/form-data; boundary=${
                (form as any)._boundary
              }`,
            },
          });
        }

        if (
          initialValues.curriculum &&
          values.curriculum !== initialValues.curriculum
        ) {
          await apiEmpleo.delete(`/candidatos/${candidato.id}/curriculum`, {
            params: {
              doNotLog: 1,
            },
          });
        }

        if (values.curriculum && typeof values.curriculum === "object") {
          const form = new FormData();
          form.append("file", values.curriculum);
          await apiEmpleo.post(`/candidatos/${candidato.id}/curriculum`, form, {
            headers: {
              "content-type": `multipart/form-data; boundary=${
                (form as any)._boundary
              }`,
            },
          });
        }

        if (res.status === 200) {
          // Refresh "me" (reloads screen but we need it to update values)
          // hideBackdrop();
          actions.setSubmitting(false);
          // getCandidato(candidato.id);
          // snackSuccessMessage("Has cambiado los datos correctamente");
        } else {
          console.log("Error!");
        }
      } catch (e: any) {
        console.log("Catched error", e.response);
        // hideBackdrop();
        modalShow({
          open: true,
          type: "error",
          message: "Ha habido algún error al intentar guardar los cambios",
        });
        actions.resetForm();
        actions.setSubmitting(false);
      }
    };

    sendData(values);
  };

  const updatePathologiesFromExperiences = (
    currentPathologiesWithExperience: string[],
    experiences: any
  ) => {
    const paths = [...currentPathologiesWithExperience];
    experiences.forEach((experience: any) => {
      experience.Patolog_as_tratadas.forEach((pathology: string) => {
        if (!paths.includes(pathology)) {
          paths.push(pathology);
        }
      });
    });
    return paths;
  };

  if (pathologyOptions.length === 0) {
    return null;
  }

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values: any, actions: any) => {
          handleFormSubmit(values, actions);
        }}
      >
        {(props) => {
          const handlePartialFormUpdate = (fieldName: string, data: any) => {
            const sendPartialData = async (fieldName: string, values: any) => {
              const newData: any = {};

              if (fieldName === "Experiencia_y_referencias") {
                newData.Experiencia_y_referencias = values;
                // Should update the pathologies
                newData.Patolog_as_en_las_que_tiene_experiencia =
                  updatePathologiesFromExperiences(
                    candidato.Patolog_as_en_las_que_tiene_experiencia,
                    values
                  );
              }
              if (fieldName === "Formaciones") {
                newData.Formaciones = values;
              }
              if (fieldName === "Observaciones_generales") {
                newData.Observaciones_generales = values;
              }

              try {
                getPartialCandidatoSuccess({
                  partialCandidatos: [
                    {
                      ...newData,
                      id: candidato.id,
                    },
                  ],
                });

                let res = await apiEmpleo.patch(
                  "candidatos/" + candidato.id,
                  {
                    ...newData,
                  },
                  {
                    headers: {
                      "Content-Type": "application/merge-patch+json",
                    },
                  }
                );

                if (res.status === 200) {
                  // Refresh "me" (reloads screen but we need it to update values)
                  // getCandidato(candidato.id);
                  // snackSuccessMessage("Candidato actualizado");
                } else {
                  console.log("Error!");
                }
              } catch (e) {
                snackErrorMessage(
                  "Ha habido algún problema al actualizar el candidato"
                );
              }
            };

            props.setFieldValue(fieldName, data);
            sendPartialData(fieldName, data);
          };

          const validatePhoneField = async (value: string) => {
            return new Promise((resolve) => {
              let syncError;

              try {
                string()
                  .required(
                    formatMessage({
                      id: "CoordinatorFile.El teléfono es obligatorio",
                      defaultMessage: "El teléfono es obligatorio",
                    })
                  )
                  .test(
                    "valid-phone",
                    formatMessage({
                      id: "CoordinatorFile.El teléfono debe ser válido",
                      defaultMessage: "El teléfono debe ser válido",
                    }),
                    validatePhone
                  )
                  .validateSync(value);
              } catch (e) {
                syncError = e;
              }

              if (syncError) {
                return resolve(syncError);
              }

              // As this is defined here it can access props of the previous
              // state, so we can compare if there is any difference
              if (props.values.Phone === value) {
                // Return same error
                return resolve(
                  props.errors.Phone ? { message: props.errors.Phone } : null
                );
              }

              validateUniquePhone(value, candidato.id).then((ret: any) => {
                if (!ret.unique) {
                  resolve({
                    message: formatMessage(
                      {
                        id: "El teléfono ya está en uso por {Name}",
                      },
                      {
                        Name: ret.Name,
                      }
                    ),
                  });
                }
                resolve([]);
              });
            }).then((errors: any) => {
              if (errors && errors.message) {
                return errors.message;
              }
            });
          };

          const validateEmailField = async (value: string) => {
            return new Promise((resolve) => {
              if (!value) {
                // Accept empty values (email can be empty)
                resolve([]);
              }

              let syncError;

              try {
                string()
                  .test(
                    "valid-email",
                    formatMessage({
                      id: "CoordinatorFile.El correo electrónico debe ser válido",
                      defaultMessage: "El correo electrónico debe ser válido",
                    }),
                    validateEmail
                  )
                  .validateSync(value);
              } catch (e) {
                syncError = e;
              }

              if (syncError) {
                return resolve(syncError);
              }

              if (props.values.Email === value) {
                return resolve(
                  props.errors.Email ? { message: props.errors.Email } : null
                );
              }

              validateUniqueEmail(value, candidato.id).then((ret: any) => {
                if (!ret.unique) {
                  resolve({
                    message: formatMessage(
                      {
                        id: "CoordinatorFile.El correo electrónico ya está en uso por {Name}",
                        defaultMessage:
                          "El correo electrónico ya está en uso por {Name}",
                      },
                      {
                        Name: ret.Name,
                      }
                    ),
                  });
                }
                resolve([]);
              });
            }).then((errors: any) => {
              if (errors && errors.message) {
                return errors.message;
              }
            });
          };

          return (
            <Fragment>
              <Form>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <>
                    <StyledWrapper>
                      <motion.div
                        initial="exit"
                        animate="enter"
                        variants={profileVariants}
                      >
                        <Box width="100%">
                          <StyledGroup>
                            <StyledHeaderWithButton>
                              <StyledSectionHeader variant="h4">
                                <FormattedMessage
                                  id="CoordinatorFile.PersonalData"
                                  defaultMessage="Datos Personales"
                                />
                              </StyledSectionHeader>
                              <StyledTopSaveButton
                                type="submit"
                                variant="contained"
                                color="secondary"
                                disabled={
                                  !props.dirty ||
                                  !props.isValid ||
                                  props.isSubmitting
                                }
                              >
                                {props.isSubmitting ? (
                                  <FormattedMessage
                                    id="CoordinatorFile.Saving"
                                    defaultMessage="Guardando"
                                  />
                                ) : (
                                  <FormattedMessage
                                    id="CoordinatorFile.UpdateChanges"
                                    defaultMessage="Actualizar cambios"
                                  />
                                )}
                              </StyledTopSaveButton>
                            </StyledHeaderWithButton>
                            <Grid container spacing={8}>
                              <Grid item xs={12} md={4}>
                                <FormikSelect
                                  name="Tratamiento"
                                  required
                                  fullWidth
                                  label={
                                    <FormattedMessage
                                      id="CoordinatorFile.Treatment"
                                      defaultMessage="Tratamiento"
                                    />
                                  }
                                  closeMenuOnSelect={true}
                                  variant="compact"
                                  size="small"
                                  placeholder={formatMessage({
                                    id: "Coordinator.select",
                                    defaultMessage: "select",
                                  })}
                                  options={[
                                    {
                                      value: "Madame",
                                      label: "Madame",
                                    },
                                    {
                                      value: "Mademoiselle",
                                      label: "Mademoiselle",
                                    },
                                    {
                                      value: "Monsieur",
                                      label: "Monsieur",
                                    },
                                  ]}
                                />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <FormikTextField
                                  name="Nombre"
                                  label={
                                    <FormattedMessage
                                      id="CoordinatorFile.Name"
                                      defaultMessage="Nombre"
                                    />
                                  }
                                  labelSuffix={<VisibleFieldOnProfile />}
                                  fullWidth
                                  required
                                  uppercase
                                />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <FormikTextField
                                  name="Apellidos"
                                  label={
                                    <FormattedMessage
                                      id="CoordinatorFile.Surname1"
                                      defaultMessage="Primer Apellido"
                                    />
                                  }
                                  labelSuffix={<VisibleFieldOnProfile />}
                                  fullWidth
                                  required
                                  uppercase
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={8}>
                              <Grid item xs={12} md={7}>
                                <FormikDatePicker
                                  name="Fecha_de_nacimiento"
                                  label={
                                    <FormattedMessage
                                      id="CoordinatorFile.Fecha de nacimiento"
                                      defaultMessage="Fecha de nacimiento"
                                    />
                                  }
                                  fullWidth
                                  margin="nomargin"
                                  size="small"
                                />
                              </Grid>
                              <Grid item xs={12} md={5}>
                                <FormikSelect
                                  label={
                                    <FormattedMessage
                                      id="CoordinatorFile.Birthplace"
                                      defaultMessage="Lugar de nacimiento"
                                    />
                                  }
                                  labelSuffix={<VisibleFieldOnProfile />}
                                  name="Lugar_de_nacimiento"
                                  required
                                  variant="compact"
                                  size="small"
                                  margin="nomargin"
                                  closeMenuOnSelect={true}
                                  placeholder={formatMessage({
                                    id: "CoordinatorFile.Lugar de nacimiento",
                                    defaultMessage: "Lugar de nacimiento",
                                  })}
                                  options={paisesOptions}
                                />
                              </Grid>
                            </Grid>
                          </StyledGroup>
                          <StyledGroup>
                            <Grid container spacing={10}>
                              <Grid item xs={12} md={4}>
                                {candidato.avatarUrl && (
                                  <FormikCheckbox
                                    label={
                                      <FormattedMessage
                                        id="CoordinatorFile.Ocultar imagen en perfil público"
                                        defaultMessage="Ocultar imagen en perfil público"
                                      />
                                    }
                                    name="No_mostrar_imagen"
                                    variant="compact"
                                  />
                                )}
                              </Grid>
                              <Grid item xs={12} md={2}></Grid>
                              <Grid item xs={12} md={6}>
                                {/* <CommunicationsSection /> */}
                              </Grid>
                            </Grid>
                          </StyledGroup>
                          <StyledGroup>
                            <Grid container spacing={8}>
                              <Grid item xs={12} md={2}>
                                <FormikTextField
                                  name="Numero"
                                  label={
                                    <FormattedMessage
                                      id="CoordinatorFile.AddressNumber"
                                      defaultMessage="Número:"
                                    />
                                  }
                                  fullWidth
                                  margin="nomargin"
                                />
                              </Grid>
                              <Grid item xs={12} md={5}>
                                <FormikTextField
                                  name="Direccion"
                                  label={
                                    <FormattedMessage
                                      id="CoordinatorFile.Address"
                                      defaultMessage="Dirección:"
                                    />
                                  }
                                  fullWidth
                                  margin="nomargin"
                                />
                              </Grid>
                              <Grid item xs={12} md={5}>
                                <FormikTextField
                                  name="Piso"
                                  label={
                                    <FormattedMessage
                                      id="CoordinatorFile.AddressComplement"
                                      defaultMessage="Complemento"
                                    />
                                  }
                                  fullWidth
                                  margin="nomargin"
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={8}>
                              <Grid item xs={12} md={4}>
                                <FormikTextField
                                  name="Codigo_postal"
                                  label={
                                    <FormattedMessage
                                      id="CoordinatorFile.AddressPostalCode"
                                      defaultMessage="Código Postal"
                                    />
                                  }
                                  fullWidth
                                  margin="nomargin"
                                  type="postalCode"
                                  onChange={(e: any) => {
                                    if (e.target.value.length > 1) {
                                      const code = e.target.value.substring(
                                        0,
                                        2
                                      );
                                      const prov = provincias.find(
                                        (item: any) => {
                                          return (
                                            item.code === code &&
                                            item.businessCountry === "fr"
                                          );
                                        }
                                      );
                                      if (prov) {
                                        props.setFieldValue("D_partement", {
                                          value: prov.value,
                                          label: prov.value,
                                        });
                                      }
                                    }
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <FormikSelect
                                  label={
                                    <FormattedMessage
                                      id="CoordinatorFile.AddressDepartment"
                                      defaultMessage="Department"
                                    />
                                  }
                                  name="D_partement"
                                  variant="compact"
                                  size="small"
                                  margin="nomargin"
                                  closeMenuOnSelect={true}
                                  options={provinciasOptions}
                                />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <FormikTextField
                                  name="Ciudad"
                                  label={
                                    <FormattedMessage
                                      id="CoordinatorFile.AddressCity"
                                      defaultMessage="Ciudad"
                                    />
                                  }
                                  labelSuffix={<VisibleFieldOnProfile />}
                                  fullWidth
                                  margin="nomargin"
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={8}>
                              <Grid item xs={12} md={6}>
                                <FormikTextField
                                  name="Phone"
                                  label={
                                    <FormattedMessage
                                      id="CoordinatorFile.Phone"
                                      defaultMessage="Teléfono"
                                    />
                                  }
                                  fullWidth
                                  telformat
                                  validate={validatePhoneField}
                                  defaultPhonePrefix="+33"
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <FormikTextField
                                  name="Email"
                                  label={
                                    <FormattedMessage
                                      id="CoordinatorFile.Email"
                                      defaultMessage="Correo electrónico"
                                    />
                                  }
                                  fullWidth
                                  validate={validateEmailField}
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={8} alignItems="flex-end">
                              <Grid item xs={12} md={4}></Grid>
                              <Grid item xs={12} md={2}></Grid>
                              <Grid item xs={12} md={6}></Grid>
                            </Grid>
                            <Grid container spacing={8} alignItems="center">
                              <Grid item xs={12}>
                                <FormikTextField
                                  name="N_CUENTA"
                                  label={
                                    <FormattedMessage
                                      id="CoordinatorFile.AccountNumber"
                                      defaultMessage="Número de Cuenta"
                                    />
                                  }
                                  fullWidth
                                  margin="nomargin"
                                  ibanformat
                                  placeholder="ESXX XXXX XXXX XXXX XXXX XXXX"
                                />
                              </Grid>
                            </Grid>
                          </StyledGroup>
                        </Box>
                        <Divider />
                        <Box>
                          <StyledGroup>
                            <Grid container spacing={8}>
                              <Grid item xs={12} md={6}>
                                <FormikSelect
                                  label={
                                    <FormattedMessage
                                      id="CoordinatorFile.Languages"
                                      defaultMessage="Idiomas"
                                    />
                                  }
                                  labelSuffix={<VisibleFieldOnProfile />}
                                  name="Otros_Idiomas"
                                  isMulti
                                  variant="compact"
                                  margin="nomargin"
                                  size="small"
                                  closeMenuOnSelect={false}
                                  placeholder={formatMessage({
                                    id: "CoordinatorFile.Selecciona",
                                    defaultMessage: "Selecciona",
                                  })}
                                  options={idiomasOptions}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <FormikTextField
                                  name="N_SS"
                                  margin="nomargin"
                                  label={
                                    <FormattedMessage
                                      id="CoordinatorFile.SSNumber"
                                      defaultMessage="Número afiliación Seguridad Social"
                                    />
                                  }
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <FormikSelect
                                  name="Titulaci_n_oficial_SAD"
                                  label={
                                    <FormattedMessage
                                      id="CoordinatorFile.AnyDegree"
                                      defaultMessage="¿Dispones de alguna de las siguientes titulaciones oficiales?"
                                    />
                                  }
                                  variant="compact"
                                  closeMenuOnSelect={true}
                                  options={titulacionesOptions}
                                  margin="nomargin"
                                  size="small"
                                  placeholder={formatMessage({
                                    id: "CoordinatorFile.Selecciona",
                                    defaultMessage: "Selecciona",
                                  })}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <FormikSelect
                                  name="A_os_de_experiencia"
                                  label={
                                    <FormattedMessage
                                      id="CoordinatorFile.ExperienceYears"
                                      defaultMessage="Años de experiencia"
                                    />
                                  }
                                  variant="compact"
                                  closeMenuOnSelect={true}
                                  margin="nomargin"
                                  size="small"
                                  placeholder={formatMessage({
                                    id: "CoordinatorFile.Selecciona una opción",
                                    defaultMessage: "Selecciona una opción",
                                  })}
                                  options={experienceYearsOptions}
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={8} alignItems="flex-start">
                              <Grid item xs={12} md={6}>
                                <Grid
                                  container
                                  spacing={8}
                                  alignItems="flex-end"
                                >
                                  <Grid item xs={12} md={8}></Grid>
                                  <Grid item xs={12} md={4}></Grid>
                                  <Grid item xs={12}></Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <Box style={{ marginTop: "0.75rem" }}>
                                  <FormikAttachCif
                                    obverseFieldName="cif_obverse"
                                    backFieldName="cif_back"
                                    messages={{
                                      label: "Carte d'identité nationale",
                                      addCifLabel: "Adjunta un documento",
                                      firstStepText:
                                        "Primero adjunta una imagen del anverso",
                                      secondStepAwait:
                                        "Primero adjunta una imagen del anverso",
                                      secondStepText:
                                        "Ahora debes añadir el reverso",
                                    }}
                                    fullWidth
                                    allowZoom
                                  />
                                </Box>
                              </Grid>
                            </Grid>

                            <Grid container spacing={8}>
                              <Grid item xs={12} md={4}>
                                <FormikRadioGroup
                                  name="Sexo"
                                  fullWidth
                                  label={
                                    <FormattedMessage
                                      id="CoordinatorFile.Sex"
                                      defaultMessage="Sexo"
                                    />
                                  }
                                  size="small"
                                  variant="compact"
                                  options={[
                                    {
                                      label: (
                                        <FormattedMessage
                                          id="Hombre"
                                          defaultMessage="Hombre"
                                        />
                                      ),
                                      value: "Hombre",
                                      icon: ManIcon,
                                      checkedIcon: ManIcon,
                                    },
                                    {
                                      label: (
                                        <FormattedMessage
                                          id="Mujer"
                                          defaultMessage="Mujer"
                                        />
                                      ),
                                      value: "Mujer",
                                      icon: WomanIcon,
                                      checkedIcon: WomanIcon,
                                    },
                                  ]}
                                />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <FormikRadioGroup
                                  name="Carnet_de_conducir"
                                  fullWidth
                                  label={
                                    <FormattedMessage
                                      id="CoordinatorFile.DrivingLicense"
                                      defaultMessage="Permiso de conducir"
                                    />
                                  }
                                  size="small"
                                  variant="compact"
                                  options={[
                                    {
                                      label: (
                                        <FormattedMessage
                                          id="Si"
                                          defaultMessage="Si"
                                        />
                                      ),
                                      value: "SI",
                                      icon: SiIcon,
                                      checkedIcon: SiIcon,
                                    },
                                    {
                                      label: (
                                        <FormattedMessage
                                          id="No"
                                          defaultMessage="No"
                                        />
                                      ),
                                      value: "NO",
                                      icon: NoIcon,
                                      checkedIcon: NoIcon,
                                    },
                                  ]}
                                />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <FormikRadioGroup
                                  name="Veh_culo_propio"
                                  fullWidth
                                  label={
                                    <FormattedMessage
                                      id="CoordinatorFile.OwnsCar"
                                      defaultMessage="¿Tienes coche propio?"
                                    />
                                  }
                                  size="small"
                                  variant="compact"
                                  options={[
                                    {
                                      label: (
                                        <FormattedMessage
                                          id="Si"
                                          defaultMessage="Si"
                                        />
                                      ),
                                      value: "Si",
                                      icon: SiIcon,
                                      checkedIcon: SiIcon,
                                    },
                                    {
                                      label: (
                                        <FormattedMessage
                                          id="No"
                                          defaultMessage="No"
                                        />
                                      ),
                                      value: "No",
                                      icon: NoIcon,
                                      checkedIcon: NoIcon,
                                    },
                                  ]}
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={8}>
                              <Grid item xs={12}>
                                <FormikTextField
                                  name="W_Breve_descripci_n"
                                  label={
                                    <FormattedMessage
                                      id="CoordinatorFile.ShortDescription"
                                      defaultMessage="Breve descripción"
                                    />
                                  }
                                  labelSuffix={<VisibleFieldOnProfile />}
                                  fullWidth
                                  multiline
                                  maxRows="8"
                                  minRows="3"
                                />
                              </Grid>
                            </Grid>
                          </StyledGroup>
                          <Grid container spacing={8}>
                            <Grid item xs={12}>
                              <FormikTextField
                                name="Observaciones_generales"
                                label={
                                  <FormattedMessage
                                    id="CoordinatorFile.GeneralObservations"
                                    defaultMessage="Observaciones"
                                  />
                                }
                                labelSuffix={<VisibleFieldOnProfile />}
                                fullWidth
                                multiline
                                maxRows="4"
                                minRows="2"
                                disabled
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormikTextField
                                name="Codigo_ofertas_FR"
                                label={
                                  <FormattedMessage
                                    id="CoordinatorFile.Ofertas inscritas desde portal de empleo"
                                    defaultMessage="Ofertas inscritas desde portal de empleo"
                                  />
                                }
                                fullWidth
                                multiline
                                maxRows="4"
                                minRows="2"
                                disabled
                              />
                            </Grid>
                          </Grid>
                          <Box marginTop={10} marginBottom={10}>
                            <NotesV2Table
                              parentId={candidato.id}
                              moduleName="Candidatos"
                            />
                          </Box>
                          <StyledGroup>
                            <StyledSectionHeader variant="h4">
                              <FormattedMessage
                                id="CoordinatorFile.Availability"
                                defaultMessage="Disponibilidad"
                              />
                            </StyledSectionHeader>
                            <Grid container spacing={8}>
                              <Grid item xs={12}>
                                <StyledField>
                                  <StyledLabel>
                                    <FormattedMessage
                                      id="CoordinatorFile.JobType"
                                      defaultMessage="Tipología de trabajo"
                                    />
                                  </StyledLabel>
                                  <Grid container spacing={4}>
                                    <Grid item xs={12} md={3}>
                                      {/* We use frech labels directly here */}
                                      <FormikCheckbox
                                        label="Travail de jour, en semaine"
                                        variant="compact"
                                        name="Externa_entre_semana"
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <FormikCheckbox
                                        label="Travail de jour, le week-end"
                                        name="Externa_fin_de_semana"
                                        variant="compact"
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <FormikCheckbox
                                        label="Travail de nuit, en semaine"
                                        name="Interna_entre_semana"
                                        variant="compact"
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <FormikCheckbox
                                        label="Travail de nuit, le week-end"
                                        name="Interna_fin_de_semana"
                                        variant="compact"
                                      />
                                    </Grid>
                                  </Grid>
                                </StyledField>
                              </Grid>
                            </Grid>
                            <Grid container spacing={8}>
                              <Grid item xs={12}>
                                <FormikTextField
                                  name="Notas_disponibilidad"
                                  label={
                                    <FormattedMessage
                                      id="CoordinatorFile.AdditionalComment"
                                      defaultMessage="¿Quieres añadir algún comentario más en relación a tu disponibilidad?"
                                    />
                                  }
                                  fullWidth
                                  multiline
                                  maxRows="8"
                                  minRows="3"
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={8}>
                              <Grid item xs={12}>
                                <StyledField>
                                  <StyledLabel>
                                    <FormattedMessage
                                      id="CoordinatorFile.TaskTypes"
                                      defaultMessage="Tipo de tarea que puedes realizar"
                                    />{" "}
                                    <VisibleFieldOnProfile />{" "}
                                  </StyledLabel>
                                  <Grid container spacing={4}>
                                    {tareasOptions.map((item: any) => {
                                      if (
                                        props.initialValues[
                                          `tarea__${item.value}`
                                        ] !== undefined
                                      ) {
                                        return (
                                          <Grid
                                            item
                                            xs={12}
                                            md={4}
                                            lg={3}
                                            key={item.name}
                                          >
                                            <FormikCheckbox
                                              label={item.label}
                                              name={`tarea__${item.value}`}
                                              variant="compact"
                                            />
                                          </Grid>
                                        );
                                      }
                                      return null;
                                    })}
                                  </Grid>
                                </StyledField>
                              </Grid>
                            </Grid>
                          </StyledGroup>
                          <StyledGroup>
                            <ExperienciasTable
                              candidato={candidato}
                              Experiencia_y_referencias={
                                props.values.Experiencia_y_referencias
                              }
                              onChange={(data: any) => {
                                handlePartialFormUpdate(
                                  "Experiencia_y_referencias",
                                  data
                                );
                              }}
                            />
                            <Box marginTop={8} marginBottom={10}>
                              <Grid container spacing={8}>
                                <Grid item xs={12}>
                                  <StyledField>
                                    <StyledLabel>
                                      <FormattedMessage
                                        id="CoordinatorFile.PathologyTypes"
                                        defaultMessage="Patologías en las que tiene experiencia"
                                      />
                                    </StyledLabel>
                                    <Grid container spacing={4}>
                                      {pathologyOptions.map((item) => {
                                        return (
                                          <Grid
                                            item
                                            xs={12}
                                            md={4}
                                            lg={3}
                                            key={item.value}
                                            className="CMuiCoordinatorFilePathology"
                                          >
                                            <FormikCheckbox
                                              label={item.label}
                                              name={"pathology__" + item.value}
                                              variant="compact"
                                            />
                                          </Grid>
                                        );
                                      })}
                                    </Grid>
                                  </StyledField>
                                </Grid>
                              </Grid>
                              <StyledHelper>
                                <FormattedMessage
                                  id="CoordinatorFile.PathologyHelper"
                                  defaultMessage="Las patologías que aparecen en la tabla de experiencias se añaden automáticamente en las patologías en las que el candidato tiene experiencia"
                                />
                              </StyledHelper>
                            </Box>
                          </StyledGroup>
                          <FormikCVUpload
                            label={
                              <FormattedMessage
                                id="CoordinatorFile.CVLabel"
                                defaultMessage="Curriculum"
                              />
                            }
                            name="curriculum"
                            fullWidth
                            margin="normal"
                            size="medium"
                            changeLabel={
                              <FormattedMessage
                                id="InterestDataPage.ChangeCv"
                                defaultMessage="Cambiar"
                              />
                            }
                            uploadLabel={
                              <FormattedMessage
                                id="InterestDataPage.UploadCv"
                                defaultMessage="Subir"
                              />
                            }
                            showBigThumb
                          />
                          <StyledGroup>
                            <FormacionesTable
                              Formaciones={props.values.Formaciones}
                              onChange={(data: any) => {
                                handlePartialFormUpdate("Formaciones", data);
                              }}
                            />
                          </StyledGroup>
                        </Box>
                        <Box mt={10} mb={10}>
                          <UserInfoBox employee={candidato} />
                        </Box>
                      </motion.div>
                    </StyledWrapper>
                  </>
                </MuiPickersUtilsProvider>
              </Form>
            </Fragment>
          );
        }}
      </Formik>
    </Box>
  );
};

const mapStateToProps = ({
  candidatosState,
  commonData,
}: {
  candidatosState: { candidatos: any };
  commonData: any;
}) => {
  const { candidatos } = candidatosState;
  const {
    paisesOptions,
    provinciasOptions,
    diasOptions,
    mesesOptions,
    anyosOptions,
    idiomasOptions,
    titulacionesOptions,
    tareasOptions,
    educacionOptions,
    experienceYearsOptions,
  } = commonData;

  return {
    candidatos,
    paisesOptions,
    provinciasOptions,
    diasOptions,
    mesesOptions,
    anyosOptions,
    idiomasOptions,
    titulacionesOptions,
    tareasOptions,
    educacionOptions,
    experienceYearsOptions,
  };
};

const ConnectedCoordinatorFileFR = connect(mapStateToProps, {
  getCandidato,
  getPartialCandidatoSuccess,
  snackSuccessMessage,
  snackInfoMessage,
  snackErrorMessage,
  showBackdrop,
  hideBackdrop,
  modalShow,
})(CoordinatorFileFR);

const IntlCoordinatorFile = (props: any) => {
  return (
    // <IntlProvider locale="fr" messages={locale.messages}>
    <ConnectedCoordinatorFileFR {...props} />
    // </IntlProvider>
  );
};

export default IntlCoordinatorFile;
