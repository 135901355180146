import { useQuery } from "react-query";
import { apiGetOffersProvinces } from "../../../api/offers/apiGetOffers";
import { useState } from "react";
import { ProvinceType } from "../../../types/ProvinceType";

export const useProvincesQuery = () => {
  const [provinces, setProvinces] = useState<ProvinceType[]>([]);

  const getrProvinces = async () => {
    const response = await apiGetOffersProvinces();
    setProvinces(response);
    return {
      offers: response,
    };
  };

  const query = useQuery({
    queryKey: ["empleo-provinces"],
    queryFn: () => getrProvinces(),
  });

  return {
    provinces,
    ...query,
  };
};
