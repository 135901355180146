import { useMutation } from "react-query";
import { apiUpdateContractImportantNotes } from "../../../../../api/contract/apiUpdateContractImportantNotes";
import { MutationConfig, queryClient } from "../../../../../lib/react-query";
import { TContractsPageFilters } from "../routes/ContractsPage/hooks/useContractsPageURLParams";
import { TContractPartialWithNumbers } from "../types/contract.types";
import { contractKeys } from "../util/contract.query-keys";

export type TUpdateContractImportantNotes = {
  contractId: string;
  Notas_importantes?: string;
};

export const updatePromoterNotes = (payload: TUpdateContractImportantNotes) => {
  return apiUpdateContractImportantNotes(payload.contractId, {
    Notas_importantes: payload.Notas_importantes ?? null,
  });
};

type TUseUpdateContractImportantNotesOptions = {
  contractsPageFilters: TContractsPageFilters;
  config?: MutationConfig<typeof updatePromoterNotes>;
};

export const useUpdateContractImportantNotes = ({
  contractsPageFilters,
  config,
}: TUseUpdateContractImportantNotesOptions) => {
  return useMutation({
    onMutate: async (payload: TUpdateContractImportantNotes) => {
      const queryKey = contractKeys.listForContractsPage(contractsPageFilters);
      await queryClient.cancelQueries(contractKeys.all);

      const prevQuery:
        | { contracts: TContractPartialWithNumbers[] }
        | undefined = queryClient.getQueryData(queryKey);

      if (!prevQuery) {
        return;
      }

      const newContracts: TContractPartialWithNumbers[] =
        prevQuery.contracts.map((contract) => {
          if (contract.id !== payload.contractId) {
            return contract;
          }

          return {
            ...contract,
            Notas_importantes:
              payload.Notas_importantes === null
                ? undefined
                : payload.Notas_importantes,
          };
        });

      queryClient.setQueryData(queryKey, {
        ...prevQuery,
        contracts: newContracts,
      });

      return {
        prevQuery,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries(contractKeys.all);
    },
    ...config,
    mutationFn: updatePromoterNotes,
  });
};
