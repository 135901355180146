import { Button } from "@material-ui/core";
import { Formik, FormikHelpers } from "formik";
import React, { Fragment, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { string } from "yup";
import { modalShow } from "../../../actions";
import { apiEmpleo } from "../../../util/ApiEmpleo";
import {
  validateDNI,
  validateUniqueDNI,
} from "../../../util/validators/yupValidators";
import useSnacks from "../../hooks/useSnacks";
import ChangeDNIButtonForm from "./ChangeDNIButtonForm";
import { StyledDialog } from "./ChangeDNIButtonSC";

export interface IEmployeeChangeDNIPayload {
  id: string;
  DNI_NIE: string;
}

interface ChangeDNIButtonFormValues {
  DNI_NIE: string;
  allow_any: boolean;
}

interface CambioDNIButtonProps {
  employee: IEmployeeChangeDNIPayload;
  onChangeSuccess: (payload: IEmployeeChangeDNIPayload) => void;
  onChangeStart: (payload: IEmployeeChangeDNIPayload) => void;
  onChangeError: (payload: IEmployeeChangeDNIPayload) => void;
}

const ChangeDNIButton = (props: CambioDNIButtonProps) => {
  const { employee, onChangeStart, onChangeSuccess, onChangeError } = props;
  const { snackSuccessMessage } = useSnacks();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const [state, setState] = useState<{
    open: boolean;
    initialValues: ChangeDNIButtonFormValues;
  }>({
    open: false,
    initialValues: {
      DNI_NIE: employee.DNI_NIE,
      allow_any: false,
    },
  });

  const handleOpenDialog = () => {
    setState({
      ...state,
      open: true,
      initialValues: {
        DNI_NIE: employee.DNI_NIE,
        allow_any: false,
      },
    });
  };

  const closeDialogAndResetForm = () => {
    setState((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const handleClose = () => {
    closeDialogAndResetForm();
  };

  const validateForm = async (values: ChangeDNIButtonFormValues) => {
    try {
      if (values.allow_any) {
        string()
          .required(
            formatMessage({
              id: "El DNI/NIE es obligatorio",
              defaultMessage: "El DNI/NIE es obligatorio",
            })
          )
          .validateSync(values.DNI_NIE);
      } else {
        string()
          .required(
            formatMessage({
              id: "El DNI/NIE es obligatorio",
              defaultMessage: "El DNI/NIE es obligatorio",
            })
          )
          .test(
            "valid-DNI",
            formatMessage({
              id: "El DNI debe ser válido",
              defaultMessage: "El DNI debe ser válido",
            }),
            validateDNI
          )
          .validateSync(values.DNI_NIE);
      }
    } catch (e) {
      if (e instanceof Error) {
        return {
          DNI_NIE: e.message,
        };
      }
    }

    const uniqueResponse = await validateUniqueDNI(values.DNI_NIE, employee.id);
    if (uniqueResponse && !uniqueResponse.unique) {
      return {
        DNI_NIE: formatMessage(
          {
            id: "El DNI ya está en uso por {Name}",
          },
          {
            Name: uniqueResponse.Name,
          }
        ),
      };
    }
  };

  const handleFormSubmit = (
    values: ChangeDNIButtonFormValues,
    actions: FormikHelpers<ChangeDNIButtonFormValues>
  ) => {
    const sendData = async (values: ChangeDNIButtonFormValues) => {
      try {
        onChangeStart(employee);
        let res = await apiEmpleo.patch(
          "candidatos/" + employee.id,
          {
            DNI_NIE: values.DNI_NIE,
          },
          {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          }
        );

        if (res.status === 200) {
          snackSuccessMessage("Has cambiado el DNI correctamente");
          onChangeSuccess({
            id: employee.id,
            DNI_NIE: values.DNI_NIE,
          });
          closeDialogAndResetForm();
        }
      } catch (e) {
        dispatch(
          modalShow({
            open: true,
            type: "error",
            message: "Ha habido algún error al intentar guardar los cambios",
          })
        );
        actions.resetForm();
        actions.setSubmitting(false);
        onChangeError(employee);
      }
    };

    if (values.DNI_NIE === employee.DNI_NIE) {
      return;
    }

    sendData(values);
  };

  return (
    <Fragment>
      <Button
        variant="contained"
        fullWidth
        color="secondary"
        onClick={handleOpenDialog}
      >
        <FormattedMessage
          id="ChangeDNIButton.Change"
          defaultMessage="Cambiar"
        />
      </Button>
      <StyledDialog open={state.open} onClose={handleClose}>
        <Formik
          initialValues={state.initialValues}
          validate={validateForm}
          validateOnMount
          initialTouched={{ DNI_NIE: true }}
          onSubmit={handleFormSubmit}
        >
          <ChangeDNIButtonForm onClose={handleClose} />
        </Formik>
      </StyledDialog>
    </Fragment>
  );
};

export default ChangeDNIButton;
