import { CircularProgress, Tooltip } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import classNames from "classnames";
import React from "react";
import styled from "styled-components";
import {
  COLOR_RED_NOTIFICATION,
  COLOR_SUCCESS,
} from "../../../../../containers/themes/defaultTheme";
import {
  CommunicationIntentEmployeeResult,
  TCommunicationIntentEmployeeResult,
} from "../CreateCommunicationIntentsButton.types";

const StyledWrapper = styled.div`
  padding: 0.5rem 0.25rem;

  .MuiSvgIcon-root {
    width: 1.375rem;
    height: 1.375rem;
  }

  &.CMui-success {
    color: ${COLOR_SUCCESS};
  }

  &.CMui-error {
    color: ${COLOR_RED_NOTIFICATION};
  }

  &.CMui-in-progress {
    opacity: 0.5;
  }
`;

interface IEmployeeCommunicationIntentResultProps {
  result: TCommunicationIntentEmployeeResult;
  errorMessage: string | null;
}

const EmployeeCommunicationIntentResult: React.FC<
  IEmployeeCommunicationIntentResultProps
> = ({ result, errorMessage }) => {
  return (
    <StyledWrapper
      className={classNames({
        "CMui-success": result === CommunicationIntentEmployeeResult.SUCCESS,
        "CMui-error": result === CommunicationIntentEmployeeResult.ERROR,
        "CMui-in-progress":
          result === CommunicationIntentEmployeeResult.IN_PROGRESS,
      })}
    >
      {result === CommunicationIntentEmployeeResult.ERROR && (
        <Tooltip title={errorMessage ?? ""}>
          <HighlightOffIcon />
        </Tooltip>
      )}
      {result === CommunicationIntentEmployeeResult.SUCCESS && (
        <CheckCircleIcon />
      )}
      {result === CommunicationIntentEmployeeResult.IN_PROGRESS && (
        <CircularProgress size={16} thickness={5} disableShrink />
      )}
    </StyledWrapper>
  );
};

export default EmployeeCommunicationIntentResult;
