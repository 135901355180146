import { Box } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const StyledItem = styled(Box)``;

const StyledLabel = styled.p`
  line-height: 1.35;
  font-size: 0.75rem;
  font-weight: 700;
  color: #6b778c;
  margin: 0;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
`;

const StyledData = styled.div``;

interface IInfoBoxElementProps {
  label: string;
  data?: React.ReactNode;
}

const InfoBoxElement = (props: IInfoBoxElementProps) => {
  const { label, data } = props;
  return (
    <StyledItem>
      <StyledLabel>{label}</StyledLabel>
      <StyledData>{data ?? "-"}</StyledData>
    </StyledItem>
  );
};

export default InfoBoxElement;
