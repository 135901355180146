import isEqual from "lodash/isEqual";
import { useHistory } from "react-router";
import { useAuthUser } from "../../../../../../../reducers/hooks/useAuthUser";
import {
  userIsPromoter,
  userIsRecruiter,
} from "../../../../../models/Staff/Staff";
import { useUpdateContractImportantNotes } from "../../../api/updateContractImportantNotes";
import useContractPhaseMutation from "./useContractPhaseMutation";
import useContractsPageNumActiveQuery from "./useContractsPageNumActiveQuery";
import useContractsPageNumPromoteQuery from "./useContractsPageNumPromoteQuery";
import useContractsPageNumRecruitQuery from "./useContractsPageNumRecruitQuery";
import useContractsPageURLParams, {
  TContractsPageFilters,
} from "./useContractsPageURLParams";
import useOffersMutation from "./useOffersMutation";
import useOffersQuery from "./useOffersQuery";

export default function useContractsPageState() {
  const { authUser } = useAuthUser();
  const history = useHistory();

  const { filters, setFilters } = useContractsPageURLParams(history, authUser);

  const {
    data: { contracts, totalItems } = {
      contracts: undefined,
      totalItems: undefined,
    },
    isLoading,
    error,
  } = useOffersQuery(filters, filters.ready, authUser);
  const mutateContractMutation = useOffersMutation(filters);
  const updateContractPhaseMutation = useContractPhaseMutation(filters);
  const updateContractImportantNotesMutation = useUpdateContractImportantNotes({
    contractsPageFilters: filters,
  });

  const numRecruitContractsQuery = useContractsPageNumRecruitQuery(
    filters,
    userIsPromoter(authUser) || userIsRecruiter(authUser)
  );
  const numPromoteContractsQuery = useContractsPageNumPromoteQuery(
    filters,
    userIsPromoter(authUser) || userIsRecruiter(authUser)
  );
  const numActiveContractsQuery = useContractsPageNumActiveQuery(
    filters,
    userIsPromoter(authUser)
  );

  const changePage = (page: number) => {
    const newFilters = { ...filters, page };
    if (!isEqual(filters, newFilters)) {
      setFilters(newFilters);
    }
  };

  const refetchNumbers = () => {
    numRecruitContractsQuery.refetch();
    numPromoteContractsQuery.refetch();
    if (numActiveContractsQuery.data) {
      if (numActiveContractsQuery.data.totalItems < 100) {
        numActiveContractsQuery.refetch();
      }
    }
  };

  const switchToRecruitContracts = () => {
    const newFilters: TContractsPageFilters = {
      ...filters,
      preset: "recruit",
      page: 1,
    };
    if (!isEqual(filters, newFilters)) {
      setFilters(newFilters);
      refetchNumbers();
    }
  };

  const switchToPromoteContracts = () => {
    const newFilters: TContractsPageFilters = {
      ...filters,
      preset: "promote",
      page: 1,
    };
    if (!isEqual(filters, newFilters)) {
      setFilters(newFilters);
      refetchNumbers();
    }
  };

  return {
    state: {
      userIsRecruiter: userIsRecruiter(authUser),
      userIsPromoter: userIsPromoter(authUser),
      filters,
      contracts,
      totalItems,
      isLoading,
      hasError: !!error,
      recruitContracts: {
        isLoading: numRecruitContractsQuery.isLoading,
        totalItems: numRecruitContractsQuery.data?.totalItems ?? 0,
      },
      promoteContracts: {
        isLoading: numPromoteContractsQuery.isLoading,
        totalItems: numPromoteContractsQuery.data?.totalItems ?? 0,
      },
      activeContracts: {
        isLoading: numActiveContractsQuery.isLoading,
        totalItems: numActiveContractsQuery.data?.totalItems ?? 0,
      },
    },
    actions: {
      setFilters,
      mutateContract: mutateContractMutation.mutate,
      updateContractPhase: updateContractPhaseMutation.mutate,
      updateContractImportantNotes: updateContractImportantNotesMutation.mutate,
      changePage,
      switchToPromoteContracts,
      switchToRecruitContracts,
    },
  };
}
