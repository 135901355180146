import styled from "styled-components";
import {
  COLOR_RED_NOTIFICATION,
  cuideoTheme,
} from "../../../../../../containers/themes/defaultTheme";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Link } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";

export const StyledWrapper = styled.div`
  background-color: #f5f5f5;
  padding: 0.5rem 1rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledSection = styled.div`
  padding: 0 0.5rem;
`;

export const StyledLink = styled(Link)`
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  text-decoration: none;
  color: ${cuideoTheme.palette.primary.main};
  opacity: 0.5;
`;
export const StyledArrowBackIosIcon = styled(ArrowBackIosIcon)`
  font-size: 1.5rem;
`;

export const StyledDivider = styled.div`
  height: 2rem;
  width: 1px;
  background-color: ${cuideoTheme.palette.primary.main};
  opacity: 0.25;
`;

export const StyledGrowSection = styled.div`
  padding: 0 0.5rem;
  flex-grow: 1;
`;

export const StyledFirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const StyledFirstRowLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledRowSection = styled.div`
  margin: 0 0.5rem;
`;

export const StyledContratoTitleDetails = styled.div``;

export const StyledContractStatus = styled(Typography)`
  color: ${cuideoTheme.palette.primary.light};
  font-size: 0.75rem;
`;

export const StyledActionLink = styled(Button)`
  text-decoration: none;
  margin-left: 0.25rem;
  padding: 0.25rem;

  &:hover {
    background-color: rgba(0, 72, 120, 0.25);
  }
`;

export const StyledActionIcon = styled.img`
  display: block;
  width: 0.875rem;
  height: 0.875rem;
  margin-right: 0.5rem;
`;

export const StyledActionLinkText = styled(Typography)`
  font-size: 0.75rem;
  text-transform: none;
`;

export const StyledFirstRowRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledTriItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledTriIcon = styled.img`
  width: 0.875rem;
  height: 0.875rem;
  display: block;
  margin-right: 0.5rem;
`;

export const StyledTriDataWrapper = styled.div``;

export const StyledTriDataLabel = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  opacity: 0.75;
  font-size: 0.6875rem;
  font-weight: 400;
  line-height: 1;
  margin: 0;
`;

export const StyledTriData = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.25;
  margin: 0;

  &.CMui-warning {
    color: ${COLOR_RED_NOTIFICATION};
  }
`;

export const StyledSecondRow = styled.div`
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
`;

export const StyledSecondRowLeft = styled.div``;

export const StyledTitleRowSection = styled.div`
  margin: 0 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StyledContractTitle = styled(Typography)`
  font-size: 1.55rem;
  font-weight: 700;
  letter-spacing: normal;
  font-stretch: normal;
  line-height: 1;
  color: #002740;
  padding-bottom: 0.75rem;
`;
