import {
  Avatar,
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { push } from "connected-react-router";
import React, { Fragment, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { userSignOut } from "../../actions/Auth";
import { avatarImgBaseUrl } from "../../config/assetLocations";
import cuideoTheme from "../../containers/themes/defaultTheme";
import { JOB_MY_PROFILE, JOB_OFFERS } from "../../constants/routes";

const StyledUserButton = styled(Button)`
  margin-right: 0.5rem;
  .MuiButton-label {
    font-size: 1rem;
    text-transform: none;
    font-weight: 400;
  }
  .MuiAvatar-root {
    margin-right: 0.5rem;
    color: ${cuideoTheme.palette.primary.main};
  }
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: ${cuideoTheme.palette.primary.main};
  display: block;
  width: 100%;
  padding: 1rem 2rem;
  min-height: 2.5rem;
  line-height: 2.5rem;
  border-bottom: 1px solid ${cuideoTheme.palette.grey[200]};
`;

const StyledLogoutNavLink = styled(NavLink)`
  text-decoration: none;
  color: ${cuideoTheme.palette.primary.light};
  display: block;
  width: 100%;
  padding: 1rem 2rem;
  min-height: 2.5rem;
  line-height: 2.5rem;
`;

const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    box-shadow: 0 2px 8px 0 #c2d1da;
  }
  .MuiList-root {
    padding: 0;
    max-width: 20rem;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  padding: 0;
  min-height: unset;
`;

const StyledLogoutMenuItem = styled(MenuItem)`
  min-height: unset;
  padding: 0;
`;

const StyledMenuHead = styled(Box)`
  padding: 1rem 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${cuideoTheme.palette.primary.main};
  background-color: #e5edf1;
  min-height: 4rem;
  font-size: 1.25rem;
  font-family: "Source sans pro", Helvetica, Arial, sans-serif;

  .MuiAvatar-root {
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
  }
`;

const StyledItemTitle = styled(Typography)`
  font-size: 1rem;
  font-weight: 700;
`;

const StyledItemDescription = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 400;
  white-space: normal;
`;

const UserButton = (props: any) => {
  const { authUser, push, userSignOut } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleUserDesktopButtonClick = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <StyledUserButton color="inherit" onClick={handleUserDesktopButtonClick}>
        {authUser.userAvatar ? (
          <Avatar src={avatarImgBaseUrl + authUser.userAvatar} />
        ) : (
          <Avatar>
            <AccountCircleIcon />
          </Avatar>
        )}
        <strong>{authUser.userFullName}</strong>
        <ChevronRightIcon />
      </StyledUserButton>
      <StyledMenu
        id="user-button-menu"
        anchorEl={anchorEl}
        keepMounted={false}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <StyledMenuHead>
          {authUser.userAvatar ? (
            <Avatar src={avatarImgBaseUrl + authUser.userAvatar} />
          ) : (
            <Avatar>
              <AccountCircleIcon />
            </Avatar>
          )}
          <strong>{authUser.userFullName}</strong>
        </StyledMenuHead>
        <StyledMenuItem>
          <StyledNavLink
            to={JOB_OFFERS}
            onClick={() => {
              handleClose();
            }}
          >
            <StyledItemTitle>
              <FormattedMessage
                id="UserButton.OffersAvailable"
                defaultMessage="Ofertas disponibles"
              />
            </StyledItemTitle>
            <StyledItemDescription>
              <FormattedMessage
                id="UserButton.OffersAvailableHelp"
                defaultMessage="Busca entre ofertas disponibles e inscribete"
              />
            </StyledItemDescription>
          </StyledNavLink>
        </StyledMenuItem>
        {authUser && authUser.candidato && (
          <StyledMenuItem>
            <StyledNavLink
              to={"/empleo/misOfertas/activas"}
              onClick={() => {
                handleClose();
              }}
            >
              <StyledItemTitle>
                <FormattedMessage
                  id="UserButton.MyOffers"
                  defaultMessage="Mis ofertas"
                />
              </StyledItemTitle>
              <StyledItemDescription>
                <FormattedMessage
                  id="UserButton.MyOffersDesc"
                  defaultMessage="Consulta tus inscripciones en ofertas activas y ofertas pasadas"
                />
              </StyledItemDescription>
            </StyledNavLink>
          </StyledMenuItem>
        )}
        {authUser && authUser.candidato && (
          <StyledMenuItem>
            <StyledNavLink
              to={JOB_MY_PROFILE}
              onClick={() => {
                handleClose();
              }}
            >
              <StyledItemTitle>
                <FormattedMessage
                  id="UserButton.MyProfile"
                  defaultMessage="Mi perfil"
                />
              </StyledItemTitle>
              <StyledItemDescription>
                <FormattedMessage
                  id="UserButton.MyProfileDesc"
                  defaultMessage="Consulta tu perfil y tus datos de perfil"
                />
              </StyledItemDescription>
            </StyledNavLink>
          </StyledMenuItem>
        )}
        {authUser && authUser.userRoles.includes("ROLE_STAFF") && (
          <StyledMenuItem>
            <StyledNavLink
              to={"/empleo/candidatos"}
              onClick={() => {
                handleClose();
              }}
            >
              <StyledItemTitle>
                <FormattedMessage
                  id="UserButton.Employees"
                  defaultMessage="Candidatos"
                />
              </StyledItemTitle>
              <StyledItemDescription>
                <FormattedMessage
                  id="UserButton.EmployeesDesc"
                  defaultMessage="Busca entre todos los candidatos y consulta o modifica sus datos."
                />
              </StyledItemDescription>
            </StyledNavLink>
          </StyledMenuItem>
        )}
        {authUser && authUser.userRoles.includes("ROLE_STAFF") && (
          <StyledMenuItem>
            <StyledNavLink
              to={"/empleo/valoraciones"}
              onClick={() => {
                handleClose();
              }}
            >
              <StyledItemTitle>
                <FormattedMessage
                  id="UserButton.Valuations"
                  defaultMessage="Inscripciones"
                />
              </StyledItemTitle>
              <StyledItemDescription>
                <FormattedMessage
                  id="UserButton.ValuationsDesc"
                  defaultMessage="Busca entre todas las valoraciones y consulta su estado."
                />
              </StyledItemDescription>
            </StyledNavLink>
          </StyledMenuItem>
        )}
        <StyledLogoutMenuItem>
          <StyledLogoutNavLink
            to={JOB_OFFERS}
            onClick={() => {
              push(JOB_OFFERS);
              if (userSignOut) {
                userSignOut();
              }
            }}
          >
            <StyledItemTitle>
              <FormattedMessage
                id="UserButton.SignOut"
                defaultMessage="Desconectar"
              />
            </StyledItemTitle>
            <StyledItemDescription>
              <FormattedMessage
                id="UserButton.SignOutDesc"
                defaultMessage="Cierra sesión y desconecta"
              />
            </StyledItemDescription>
          </StyledLogoutNavLink>
        </StyledLogoutMenuItem>
      </StyledMenu>
    </Fragment>
  );
};

const mapStateToProps = ({ auth }: { auth: { authUser: any } }) => {
  const { authUser } = auth;
  return { authUser };
};

export default connect(mapStateToProps, { push, userSignOut })(UserButton);
