import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import React from "react";
import styled from "styled-components";
import cuideoTheme from "../../containers/themes/defaultTheme";
import IntlMessages from "../../util/IntlMessages";
import Transition from "./Transition";

const StyledSuccessModal = styled(Dialog)`
  .MuiPaper-root {
    max-width: 20rem;
    color: ${cuideoTheme.palette.primary.main};
    background-color: #50e3c2;
  }
`;

const StyledModalText = styled(Typography)`
  line-height: normal;
  font-size: 1.0625rem;
  font-weight: 700;
  max-width: 16rem;
  margin: 0 auto;
  text-align: center;
`;

const StyledModalActions = styled(DialogActions)`
  justify-content: center;
  margin: 0.5rem 0;
`;

const StyledSuccessIconWrapper = styled.div`
  margin: 1rem auto;
`;

const StyledCheckIconWrapper = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: ${cuideoTheme.palette.common.white};
  margin-top: 0.65rem;
`;

const SuccessModal = (props: any) => {
  const { modal, handleDialogClose } = props;

  return (
    <StyledSuccessModal
      open={modal.open}
      TransitionComponent={Transition as any}
      keepMounted
      onClose={handleDialogClose}
    >
      <StyledSuccessIconWrapper>
        <StyledCheckIconWrapper>
          <CheckIcon
            fontSize="default"
            color="primary"
            style={{ fontSize: "1.5rem", margin: "0.25rem" }}
          />
        </StyledCheckIconWrapper>
      </StyledSuccessIconWrapper>
      <DialogContent>
        <StyledModalText>
          <IntlMessages id={modal.message} />
        </StyledModalText>
      </DialogContent>
      {modal.context && modal.context.contratoTitle && (
        <DialogContent>
          <StyledModalText>{modal.context.contratoTitle}</StyledModalText>
        </DialogContent>
      )}
      <StyledModalActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            handleDialogClose();
          }}
        >
          <IntlMessages id="Continuar" />
        </Button>
      </StyledModalActions>
    </StyledSuccessModal>
  );
};

export default SuccessModal;
