import { Grid, Typography } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import EventIcon from "@material-ui/icons/Event";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { push } from "connected-react-router";
import moment from "moment";
import "moment/locale/es";
import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import styled from "styled-components";
import { getCandidato } from "../../actions/Candidatos";
import {
  snackErrorMessage,
  snackInfoMessage,
  snackSuccessMessage,
} from "../../actions/Common";
import { avatarImgBaseUrl } from "../../config/assetLocations";
import CandidatoEstadoTag from "./CandidatoEstadoTag";
import {
  StyledCardAction,
  StyledCardContent,
  StyledProfileCard,
} from "./PerfilStyles";

const StyledActions = styled.div`
  text-align: right;
`;

const StyledAvatarIcon = styled(AccountCircleIcon)`
  width: 4rem;
  height: 4rem;
`;

const StyledAvatarWrapper = styled.div``;

const StyledUserImage = styled.img`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  padding: 5%;
`;

const StyledProfileName = styled(Typography)`
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.15;
`;

const StyledTypography = styled(Typography)`
  font-size: 0.875rem;
`;

const StyledIconWrapper = styled.div`
  .MuiSvgIcon-root {
    font-size: 1rem;
    opacity: 0.5;
  }
`;

const StyledProfileDetails = styled(Typography)`
  opacity: 0.75;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
`;

// const StyledStaffActionsWrapper = styled.div`
//   .MuiGrid-container {
//     width: fit-content;
//     margin-top: 0.5rem;
//   }
//   .MuiButtonBase-root {
//     margin-right: 0.5rem;
//   }
// `;

const PerfilCard = (props: any) => {
  const { candidato, push, authUser } = props;

  return (
    <StyledProfileCard>
      <StyledCardContent>
        <Grid container spacing={8}>
          <Grid item xs={12} md={8}>
            <Grid container spacing={8} alignItems="center">
              <Grid item xs={12} md={6}>
                <Grid container spacing={4} alignItems="center" wrap="nowrap">
                  <Grid item>
                    <StyledAvatarWrapper>
                      {candidato.avatarUrl ? (
                        <StyledUserImage
                          src={avatarImgBaseUrl + candidato.avatarUrl}
                        />
                      ) : (
                        <StyledAvatarIcon />
                      )}
                    </StyledAvatarWrapper>
                  </Grid>
                  <Grid item>
                    <StyledProfileName>
                      {candidato.Nombre + " " + candidato.Apellidos}
                    </StyledProfileName>
                    <StyledProfileDetails>
                      <FormattedMessage
                        id="ProfileCard.SexAndAge"
                        defaultMessage="{sexo}, {edad} años"
                        values={{
                          sexo:
                            candidato.Sexo === "Hombre" ? "Hombre" : "Mujer",
                          edad: (
                            <b>
                              {moment().diff(
                                moment(candidato.Fecha_de_nacimiento),
                                "years"
                              )}
                            </b>
                          ),
                        }}
                      />
                    </StyledProfileDetails>
                    {authUser && authUser.userRoles.includes("ROLE_STAFF") && (
                      <CandidatoEstadoTag estado={candidato.Estado_Cuideo} />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container spacing={4} wrap="nowrap">
                  <Grid item>
                    <StyledIconWrapper>
                      <LocationOnIcon />
                    </StyledIconWrapper>
                  </Grid>
                  <Grid item>
                    <StyledTypography>
                      {candidato.Ciudad + " (" + candidato.Provincia + ")"}
                    </StyledTypography>
                  </Grid>
                </Grid>
                <Grid container spacing={4} wrap="nowrap">
                  <Grid item>
                    <StyledIconWrapper>
                      <EventIcon />
                    </StyledIconWrapper>
                  </Grid>
                  <Grid item>
                    <StyledTypography>
                      <FormattedMessage
                        id="ProfileCard.Registered"
                        defaultMessage="Registrado {since}"
                        values={{
                          since: <b>{moment(candidato.createdAt).fromNow()}</b>,
                        }}
                      />
                    </StyledTypography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledActions>
              <StyledCardAction
                color="primary"
                variant="outlined"
                style={{ marginLeft: "auto" }}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  push(`/ficha/${candidato.id}`);
                }}
              >
                <FormattedMessage
                  id="ProfileCard.ViewPublic"
                  defaultMessage="Ver perfil público"
                />
              </StyledCardAction>
              {authUser &&
                authUser.userRoles &&
                authUser.userRoles.includes("ROLE_STAFF") && (
                  <StyledCardAction
                    color="primary"
                    variant="outlined"
                    style={{ marginLeft: "auto" }}
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                      push(`/coordinadora/candidato/${candidato.id}`);
                    }}
                  >
                    <FormattedMessage
                      id="ProfileCard.ViewCoordinator"
                      defaultMessage="Vista coordinadora"
                    />
                  </StyledCardAction>
                )}
            </StyledActions>
          </Grid>
        </Grid>
        {/* {authUser &&
          authUser.userRoles &&
          authUser.userRoles.includes("ROLE_STAFF") && (
            <StyledStaffActionsWrapper>
              <CandidatoStaffActions employee={candidato} />
            </StyledStaffActionsWrapper>
          )} */}
      </StyledCardContent>
    </StyledProfileCard>
  );
};

const mapStateToProps = ({
  candidatosState,
  commonData,
  auth,
}: {
  candidatosState: { candidatos: any };
  commonData: any;
  auth: { authUser: any };
}) => {
  const { candidatos } = candidatosState;
  const {
    paisesOptions,
    provinciasOptions,
    diasOptions,
    mesesOptions,
    anyosOptions,
    idiomasOptions,
    titulacionesOptions,
    tareasOptions,
    educacionOptions,
  } = commonData;
  const { authUser } = auth;

  return {
    candidatos,
    paisesOptions,
    provinciasOptions,
    diasOptions,
    mesesOptions,
    anyosOptions,
    idiomasOptions,
    titulacionesOptions,
    tareasOptions,
    educacionOptions,
    authUser,
  };
};

export default connect(mapStateToProps, {
  getCandidato,
  snackSuccessMessage,
  snackInfoMessage,
  snackErrorMessage,
  push,
})(PerfilCard);
