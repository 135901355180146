import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import {
  apiChangeServiceOwner,
  IChangeServiceOwnerRequest,
} from "../../../../../../../../api/service/apiChangeServiceOwner";
import { TStaffUserResponse } from "../../../../../../../../api/user/apiListStaffUsers";
import PersonalCareImg from "../../../../../../../../assets/images/personal-care.svg";
import SelectStyledWrapper from "../SelectStyledWrapper";

const StyledDialog = styled(Dialog)``;

const StyledText = styled(Typography)`
  font-size: 0.9375rem;
  line-height: 1.35;
  margin-bottom: 0.5rem;
`;

const StyledContentWithImage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1rem 0 1rem;
`;

const StyledImage = styled.img`
  height: 5rem;
  width: auto;
`;

const StyledRight = styled.div``;

const StyledConfirmButton = styled(Button)`
  width: 7.25rem;
  height: 2.5rem;

  .MuiCircularProgress-root {
    color: #999;
  }
`;

interface IServiceOwnerSelector {
  currentOwner: string | null;
  ownerOptions: TStaffUserResponse[];
  serviceId: string;
  onOwnerChanged: () => void;
}

const ServiceOwnerSelector = (props: IServiceOwnerSelector) => {
  const { ownerOptions, serviceId, onOwnerChanged, currentOwner } = props;
  const { formatMessage } = useIntl();

  const [state, setState] = useState({
    dialogOpen: false,
    newOwnerId: null as string | null,
    newOwnerFullName: null as string | null,
    inProcess: false,
  });

  const handleCancelDialog = () => {
    setState((prevState) => ({
      ...prevState,
      dialogOpen: false,
    }));
  };

  const handleConfirmChangeOwner = async () => {
    if (!state.newOwnerId || !state.newOwnerFullName) {
      return;
    }

    setState({
      ...state,
      inProcess: true,
    });

    const request: IChangeServiceOwnerRequest = {
      Owner: state.newOwnerId,
      ownerAssignedAt: moment().toISOString(),
      ownerName: state.newOwnerFullName,
    };

    await apiChangeServiceOwner(serviceId, request);

    onOwnerChanged();
    setState((prevState) => ({
      ...prevState,
      dialogOpen: false,
      inProcess: false,
    }));
  };

  const handleOwnerChange = (newValue: any, action: any) => {
    if (!newValue || !newValue.value) {
      return;
    }

    const selectedOwner = ownerOptions.find(
      (ownerOption) => ownerOption.id === newValue.value
    );

    if (!selectedOwner) {
      return;
    }

    setState({
      ...state,
      dialogOpen: true,
      newOwnerId: selectedOwner.id,
      newOwnerFullName: selectedOwner.fullname,
    });
  };

  const options = ownerOptions.map((ownerOption) => {
    return {
      value: ownerOption.id,
      label: ownerOption.fullname,
    };
  });

  return (
    <>
      <SelectStyledWrapper
        onChange={handleOwnerChange}
        options={options}
        placeholder={
          currentOwner
            ? formatMessage({
                id: "ServiceOwnSelector.Reasignar a...",
                defaultMessage: "Reasignar a...",
              })
            : formatMessage({
                id: "ServiceOwnSelector.Asignar a...",
                defaultMessage: "Asignar a...",
              })
        }
      />
      <StyledDialog open={state.dialogOpen}>
        <DialogTitle>
          <FormattedMessage
            id="UnratedServicePage.Confirmar cambio de propietario"
            defaultMessage="Confirmar cambio de propietario"
          />
        </DialogTitle>
        <StyledContentWithImage>
          <StyledImage src={PersonalCareImg} />
          <StyledRight>
            <DialogContent>
              <StyledText>
                <FormattedMessage
                  id="UnratedServicePage.Vas a cambiar el propietario del servicio a {newOwnerFullName}"
                  defaultMessage="Vas a cambiar el propietario del servicio a {newOwnerFullName}"
                  values={{
                    newOwnerFullName: <b>{state.newOwnerFullName}</b>,
                  }}
                />
              </StyledText>
              <StyledText>
                <FormattedMessage
                  id="UnratedServicePage.¿Seguro que quieres continuar?"
                  defaultMessage="¿Seguro que quieres continuar?"
                />
              </StyledText>
            </DialogContent>
          </StyledRight>
        </StyledContentWithImage>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleCancelDialog}
            disabled={state.inProcess}
          >
            <FormattedMessage
              id="UnratedServicePage.Cancelar"
              defaultMessage="Cancelar"
            />
          </Button>
          <StyledConfirmButton
            variant="contained"
            color="primary"
            onClick={handleConfirmChangeOwner}
            disabled={state.inProcess}
          >
            {state.inProcess ? (
              <CircularProgress size={19} thickness={5} />
            ) : (
              <FormattedMessage
                id="UnratedServicePage.Confirmar"
                defaultMessage="Confirmar"
              />
            )}
          </StyledConfirmButton>
        </DialogActions>
      </StyledDialog>
    </>
  );
};

export default ServiceOwnerSelector;
