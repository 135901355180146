import {
  CHANGE_LAYOUT,
  WINDOW_WIDTH,
  LAYOUT_SET_PADDING_LEFT,
  LAYOUT_SET_MARGIN_TOP,
  LAYOUT_SET_BACKGROUND,
  USER_DRAWER_MENU_OPEN,
  USER_DRAWER_MENU_CLOSE,
  LAYOUT_CHANGE_HEADER_TYPE,
  LAYOUT_CHANGE_FOOTER_TYPE
} from "../constants/ActionTypes";
import { HeaderInterface } from "../reducers/Layout";

export const changeLayout = (appPadingLeft: number, appMarginTop: number) => {
  return {
    type: CHANGE_LAYOUT,
    payload: {
      appPadingLeft,
      appMarginTop
    }
  };
};

export const changeHeader = (header: HeaderInterface) => {
  return {
    type: LAYOUT_CHANGE_HEADER_TYPE,
    payload: header
  };
};

export const changeFooter = (footerType: { type: "normal" | "hidden" }) => {
  return {
    type: LAYOUT_CHANGE_FOOTER_TYPE,
    payload: footerType
  };
};

export const setBackground = (background: "cuideo" | "white") => {
  return {
    type: LAYOUT_SET_BACKGROUND,
    payload: background
  };
};

export const setMarginTop = (marginTop: number) => {
  return {
    type: LAYOUT_SET_MARGIN_TOP,
    payload: marginTop
  };
};

export const setPaddingLeft = (paddingLeft: number) => {
  return {
    type: LAYOUT_SET_PADDING_LEFT,
    payload: paddingLeft
  };
};

export const updateWindowWidth = (width: number) => {
  return { type: WINDOW_WIDTH, payload: { width } };
};

export const openUserDrawerMenu = () => {
  return {
    type: USER_DRAWER_MENU_OPEN
  };
};

export const closeUserDrawerMenu = () => {
  return {
    type: USER_DRAWER_MENU_CLOSE
  };
};
