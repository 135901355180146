import { Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { TGetNotificationsCollectionItem } from "../../../../../../api/notification/notification.types";
import useFormatters from "../../../../../../components/FormattedFields/useFormatters";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

const StyledValuationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
`;

const StyledValuationStatus = styled(Typography)`
  font-size: 0.75rem;
`;

const StyledContractWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
`;

const StyledContractName = styled(Typography)`
  font-size: 0.75rem;
  font-weight: 700;
`;

const StyledContractStatus = styled(Typography)`
  font-size: 0.75rem;
`;

interface INotificationContextTagsProps {
  notification: TGetNotificationsCollectionItem;
}

const NotificationContextTags: React.FC<INotificationContextTagsProps> = (
  props
) => {
  const { notification } = props;
  const { formatValuationStatus } = useFormatters();

  return (
    <StyledWrapper>
      <StyledContractWrapper>
        <StyledContractName>{notification.contract?.Name}</StyledContractName>
        <StyledContractStatus>
          {notification.contract?.Estado}
        </StyledContractStatus>
      </StyledContractWrapper>
      <StyledValuationWrapper>
        {/* <StyledValuationName>
          {notification.valuation?.Name}
        </StyledValuationName> */}
        {!!notification.valuation?.Estado_en_proceso && (
          <StyledValuationStatus>
            {formatValuationStatus(notification.valuation.Estado_en_proceso)}
          </StyledValuationStatus>
        )}
      </StyledValuationWrapper>
    </StyledWrapper>
  );
};

export default NotificationContextTags;
