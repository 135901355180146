export const STATUS = {
  SEND_MAIL: "send_mail",
  SEND_MAIL_PENDING: "send_mail_pending",
  CALL_CLIENT: "call_client",
  CALL_CLIENT_PENDING: "call_client_pending",
  NO_ANSWER_EMAIL: "no_answer_email",
  NO_ANSWER_EMAIL_PENDING: "no_answer_email_pending",
  ALREADY_TALKED_CLIENT: "already_talked_client",
  ALREADY_TALKED_CLIENT_PENDING: "already_talked_client_pending",
  CONFIRM_PENDING: "confirm_pending",
  ERROR: "error",
} as const;

export type TCvFollowUpStatus = typeof STATUS[keyof typeof STATUS];

export type TCvSelectedFollowUp =
  | typeof STATUS.SEND_MAIL
  | typeof STATUS.CALL_CLIENT
  | typeof STATUS.NO_ANSWER_EMAIL
  | typeof STATUS.ALREADY_TALKED_CLIENT;

export type TCvFollowUp = {
  initialized: boolean;
  mustDoFollowUp: boolean;
  contractId: string | null;
  status: TCvFollowUpStatus | null;
  selectedFollowUp: TCvSelectedFollowUp | null;
  error?: string | null;
};
