import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  MESSAGE_MODULE,
  MESSAGE_STATUS,
} from "../../../../../api/message/message.types";
import { TAuthenticatedUser } from "../../../../../reducers/Auth";
import { urlParamsToObject } from "../../../../../util/urlManager";
import useMarkAsReadMutation from "./useMarkAsReadMutation";
import useMessagesAddMutation from "./useMessagesAddMutation";
import useMessagesQuery from "./useMessagesQuery";

export default function useConversationState(contractId: string) {
  const history = useHistory();
  const authUser = useSelector(
    (state: any) => state.auth.authUser as TAuthenticatedUser
  );
  const bottomRef = useRef<HTMLDivElement>(null);
  const [state, setState] = useState(() => {
    let expanded = false;

    if (history.action !== "REPLACE") {
      const params = history.location.search
        ? urlParamsToObject(history.location.search)
        : {};

      if (params.expandNotes) {
        expanded = true;
      }
    }

    return {
      expanded,
      hasScrolledAtStart: false,
    };
  });
  const query = useMessagesQuery(
    contractId,
    MESSAGE_MODULE.CONTRACT,
    authUser.userId
  );
  const addMessageMutation = useMessagesAddMutation(
    contractId,
    MESSAGE_MODULE.CONTRACT,
    authUser.userId
  );
  const markAsReadMutation = useMarkAsReadMutation();

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({
        behavior: state.hasScrolledAtStart ? "smooth" : "auto",
      });
      if (state.hasScrolledAtStart) {
        setState((prevState) => ({
          ...prevState,
          hasScrolledAtStart: true,
        }));
      }
    }
  }, [state.hasScrolledAtStart, query.data]);

  useEffect(() => {
    if (!state.expanded) {
      return;
    }
    if (!markAsReadMutation.isIdle) {
      return;
    }

    const unreadNotOwnMessages =
      query.data?.messages.filter(
        (message) =>
          message.status !== MESSAGE_STATUS.READ &&
          authUser.userId !== message.createdBy?.id
      ) ?? [];

    if (unreadNotOwnMessages.length === 0) {
      return;
    }

    const parsedRequest = unreadNotOwnMessages.map((message) => ({
      id: message.id,
    }));

    markAsReadMutation.mutate(parsedRequest);
  }, [query.data, authUser, markAsReadMutation, state.expanded]);

  const toggleExpanded = () => {
    setState((prevState) => ({
      ...prevState,
      expanded: !prevState.expanded,
    }));
  };

  return {
    bottomRef,
    state: {
      expanded: state.expanded,
      hasScrolledAtStart: state.hasScrolledAtStart,
      isLoading: query.isLoading,
      hasError: query.isError,
      messages: query.data?.messages,
      totalItems: query.data?.totalItems,
      numUnreadMessages: query.data?.numUnreadMessages,
    },
    actions: {
      addMessage: addMessageMutation.mutate,
      toggleExpanded,
    },
  };
}
