import { IconButton, Typography } from "@material-ui/core";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import classNames from "classnames";
import moment from "moment";
import React, { useLayoutEffect, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { TGetNotificationsCollectionItem } from "../../../../../../api/notification/notification.types";
import ColoredAvatar from "../../../../../../components/ColoredAvatar";
import { avatarImgBaseUrl } from "../../../../../../config/assetLocations";
import { cuideoTheme } from "../../../../../../containers/themes/defaultTheme";
import { conversationMessageTextFromJsonString } from "../../../conversation";
import NotificationContextTags from "./NotificationContextTags";
import { TNotificationWithPosition } from "./NotificationsViewer";

const StyledItem = styled.div`
  padding: 0.375rem 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  min-height: 4rem;

  &:hover {
    background-color: #f7f7f7;
    cursor: pointer;

    .CMuiReadToggleButton {
      border-color: rgba(0, 0, 0, 0.1);
    }
  }
`;

const StyledLeft = styled.div`
  flex: 0 0 auto;
  padding: 0.5rem 0.5rem 0.5rem 0;
`;

const StyledCenter = styled.div`
  flex: 1 1 auto;
  padding: 0.125rem 0;
`;

const StyledRight = styled.div`
  flex: 0 0 auto;
  padding: 0 0 0 0.375rem;
`;

const StyledContent = styled.div``;

const StyledContentTitle = styled.div``;

const StyledContentDetail = styled.div``;

const StyledItemTitle = styled(Typography)`
  display: inline-block;
  font-size: 1rem;
  margin-right: 0.625rem;
  line-height: 1.25;
`;

const StyledTimeAgo = styled(Typography)`
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  color: #888;
  line-height: 1.25;
`;

const StyledMessageContext = styled.div``;

const StyledMessage = styled(Typography)`
  margin: 0.375rem 0.25rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border: 1px solid rgba(0, 72, 120, 0.25);
  border-radius: 0.25rem;
  line-height: 1.25;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const StyledRadioWrapperButton = styled(IconButton)`
  padding: 0.4375rem;
  border: 1px solid transparent;
`;

const StyledNewCircle = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: ${cuideoTheme.palette.primary.main};

  transform: scale(0);
  transition: transform 0.125s ease-in-out;

  &.CMui-checked {
    transform: scale(1);
  }
`;

const StyledToUserWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  opacity: 0.75;

  .MuiSvgIcon-root {
    width: 1.125rem;
    height: 1.125rem;
  }
`;

const StyledToUserLabel = styled(Typography)`
  font-size: 0.75rem;
`;

interface INotificationItemProps {
  notification: TNotificationWithPosition;
  currentTime?: Date;
  onUpdateYPos: (
    notification: TNotificationWithPosition,
    newYPos: number
  ) => void;
  onNotificationClick: (notification: TGetNotificationsCollectionItem) => void;
  onNotificationReadChange: (notificationId: string, read: boolean) => void;
}

const NotificationItem: React.FC<INotificationItemProps> = (props) => {
  const {
    notification,
    onUpdateYPos,
    currentTime = new Date(),
    onNotificationClick,
    onNotificationReadChange,
  } = props;

  const { formatMessage } = useIntl();
  const divRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (!divRef.current) {
      return;
    }

    if (divRef.current.offsetTop !== notification.yPos) {
      onUpdateYPos(notification, divRef.current.offsetTop);
    }
  }, [notification, onUpdateYPos]);

  const handleNotificationClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onNotificationClick(notification);
  };

  const handleReadToggle = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onNotificationReadChange(notification.id, !notification.read);
  };

  let avatars = null as null | React.ReactElement;
  let content = null as null | React.ReactElement;
  let detail = null as null | React.ReactElement;
  if (notification.type === "unread_contract_message") {
    if (notification.details.messageAuthors.length === 1) {
      content = (
        <FormattedMessage
          id="Notification.{author} ha escrito en {contractName}"
          defaultMessage="{author} ha escrito en {contractName}"
          values={{
            author: (
              <b>{notification.details.messageAuthors[0].userFullName}</b>
            ),
            contractName: <b>{notification.details.contractCrmIdName}</b>,
          }}
        />
      );
      detail = (
        <StyledMessage>{notification.details.lastUnreadMessage}</StyledMessage>
      );
      avatars = (
        <ColoredAvatar
          fullName={
            notification.details.messageAuthors[0].userFullName ?? undefined
          }
        />
      );
    }
  }

  if (notification.type === "conversation_message") {
    let candidateFullName = "";
    let avatarUrl = undefined as string | undefined;
    if (notification.candidate) {
      if (notification.candidate.avatarUrl) {
        avatarUrl = avatarImgBaseUrl + notification.candidate.avatarUrl;
      }
      if (notification.candidate.Nombre) {
        candidateFullName = notification.candidate.Nombre + " ";
      }
      if (notification.candidate.Apellidos) {
        candidateFullName += notification.candidate.Apellidos;
      }
      candidateFullName = candidateFullName.trim();
    } else {
      candidateFullName = notification.details.candidateFullName ?? "unknown";
    }
    content = (
      <FormattedMessage
        id="Notification.{candidateName} ha escrito un mensaje"
        defaultMessage="{candidateName} ha escrito un mensaje"
        values={{
          candidateName: <b>{candidateFullName}</b>,
        }}
      />
    );
    const messageContent = conversationMessageTextFromJsonString(
      notification.details.message
    );
    detail = (
      <StyledMessageContext>
        <StyledMessage>{messageContent}</StyledMessage>
        <NotificationContextTags notification={notification} />
      </StyledMessageContext>
    );
    avatars = (
      <ColoredAvatar avatarUrl={avatarUrl} fullName={candidateFullName} />
    );
  }

  return (
    <StyledItem ref={divRef} onClick={handleNotificationClick}>
      <StyledLeft>{avatars || <ColoredAvatar />}</StyledLeft>
      <StyledCenter>
        <StyledContent>
          {!!notification.toUser && (
            <StyledToUserWrapper>
              <ArrowRightAltIcon />
              <StyledToUserLabel>
                {notification.toUser.fullname}
              </StyledToUserLabel>
            </StyledToUserWrapper>
          )}
          <StyledContentTitle>
            <StyledItemTitle>
              {content || (
                <FormattedMessage
                  id="Notification.Hay mensajes nuevos en {contractName}"
                  defaultMessage="Hay mensajes nuevos en {contractName}"
                  values={{
                    contractName: (
                      <b>{notification.details.contractCrmIdName}</b>
                    ),
                  }}
                />
              )}
            </StyledItemTitle>
            <StyledTimeAgo>
              {moment(notification.createdAt).from(currentTime)}
            </StyledTimeAgo>
          </StyledContentTitle>
          {!!detail && <StyledContentDetail>{detail}</StyledContentDetail>}
        </StyledContent>
      </StyledCenter>
      <StyledRight>
        <StyledRadioWrapperButton
          className="CMuiReadToggleButton"
          onClick={handleReadToggle}
          title={
            !notification.read
              ? formatMessage({
                  id: "Notification.Marcar como leído",
                  defaultMessage: "Marcar como leído",
                })
              : formatMessage({
                  id: "Notification.Marcar como no leído",
                  defaultMessage: "Marcar como no leído",
                })
          }
        >
          <StyledNewCircle
            className={classNames({
              "CMui-checked": !notification.read,
            })}
          />
        </StyledRadioWrapperButton>
      </StyledRight>
    </StyledItem>
  );
};

export default React.memo(
  (props: INotificationItemProps) => {
    return <NotificationItem {...props} />;
  },
  (prevProps, nextProps) => {
    if (
      prevProps.notification !== nextProps.notification ||
      prevProps.currentTime !== nextProps.currentTime ||
      prevProps.onNotificationClick !== nextProps.onNotificationClick ||
      prevProps.onNotificationReadChange !==
        nextProps.onNotificationReadChange ||
      prevProps.onUpdateYPos !== nextProps.onUpdateYPos
    ) {
      return false;
    }
    return true;
  }
);
