import { apiEmpleo } from "../../util/ApiEmpleo";

export type TGetEmployeeBotAvailabilityResponse = {
  id: string;
  Apellidos?: string | null;
  Email?: string | null;
  Estado_Cuideo?: string | null;
  Name?: string | null;
  No_iniciar_bot_hasta?: string | null;
  Nombre?: string | null;
  Phone?: string | null;
  botAvailable?: boolean;
  numCommunicationIntentsLastDay: number;
  numOpenCommunicationIntents: number;
  numOpenValuationProcesses: 0;
  phoneWaStatus?: string | null;
};

const apiListEmployeesBotAvailability = async (employeeIds: string[]) => {
  let res: {
    "hydra:member": TGetEmployeeBotAvailabilityResponse[];
    "hydra:totalItems": number;
  } = await apiEmpleo.get(`employees/bot-availability`, {
    params: {
      id: employeeIds,
      itemsPerPage: 200,
    },
  });

  return res;
};

export { apiListEmployeesBotAvailability };
