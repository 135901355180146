import { Button, Dialog, Typography } from "@material-ui/core";
import styled from "styled-components";
import { cuideoTheme } from "../../../../../containers/themes/defaultTheme";
import { Formik } from "formik";
import { FormattedMessage } from "react-intl";
import { FormikTextField } from "../../../../../components/FormikFields";

export const StyledWarrantyZeroContractAfterContractedDialog = styled(Dialog)`
  .MuiPaper-root {
    max-width: 25rem;
  }
`;

export const StyledWarrantyZeroContractAfterContractedHeader = styled(Typography)`
  margin: 1.25rem 2rem;
  text-align: center;
  color: ${cuideoTheme.palette.primary.main};
  line-height: 1.25;
  font-size: 1.25rem;
  font-weight: 700;
`;

export const StyledWarrantyZeroContractAfterContractedDialogContentCV1 = styled(Typography)`
  margin: 0.75rem 2rem;
  text-align: left;
  color: ${cuideoTheme.palette.primary.main};
  line-height: 1.25;
  font-size: 1rem;
`;

export const StyledWarrantyZeroContractAfterContractedDialogContentCV2 = styled.div`
  margin: 0.75rem 2rem;
`;

export const StyledActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 0.5rem 0.5rem;
`;

export const StyledCancelButton = styled(Button)``;

export const StyledConfirmButton = styled(Button)`
  .MuiCircularProgress-root {
    color: #aaa;
  }
`;

interface IWarrantyZeroContractAfterContractedDialogProps {
  open: boolean;
  candidateName: string;
  onAccept: (values: any) => void;
  onCancel: (e: React.MouseEvent) => void;
}

const WarrantyZeroContractAfterContractedDialog: React.FC<IWarrantyZeroContractAfterContractedDialogProps> = (props) => {
  const { open, candidateName, onAccept, onCancel } = props;

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onCancel(e);
  }

  return (
    <StyledWarrantyZeroContractAfterContractedDialog
      open={open}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
      }}
    >
      <Formik
        initialValues={{
          Notas_Asesora_Seleccion: "",
        }}
        onSubmit={(values: any, actions: any) => {
          onAccept(values);
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <StyledWarrantyZeroContractAfterContractedHeader>
              <FormattedMessage
                id="ValuationPipelineButton.{nombreCandidato} ha sido seleccionado/a"
                defaultMessage="{nombreCandidato} ha sido seleccionado/a"
                values={{
                  nombreCandidato:
                    candidateName
                }}
              />
            </StyledWarrantyZeroContractAfterContractedHeader>
            <StyledWarrantyZeroContractAfterContractedDialogContentCV1>
              <FormattedMessage
                id="ValuationPipelineButton.¿Quieres añadir alguna nota referente a la selección para la Asesora Laboral?"
                defaultMessage="¿Quieres añadir alguna nota referente a la selección para la Asesora Laboral?"
              />
            </StyledWarrantyZeroContractAfterContractedDialogContentCV1>
            <StyledWarrantyZeroContractAfterContractedDialogContentCV2>
              <FormikTextField
                name="Notas_Asesora_Seleccion"
                fullWidth
                multiline
                minRows="3"
                maxRows="5"
                size="small"
                placeholder="Añade el comentario aquí"
                margin="nomargin"
              />
            </StyledWarrantyZeroContractAfterContractedDialogContentCV2>
            <StyledActions>
              <StyledCancelButton color="primary" onClick={handleCancel}>
                <FormattedMessage
                  id="ValuationPipelineButton.Cancelar"
                  defaultMessage="Cancelar"
                />
              </StyledCancelButton>
              <StyledConfirmButton
                color="primary"
                variant="contained"
                type="submit"
              >
                <FormattedMessage
                  id="ValuationPipelineButton.Enviar"
                  defaultMessage="Enviar"
                />
              </StyledConfirmButton>
            </StyledActions>
          </form>
        )}
      </Formik>
    </StyledWarrantyZeroContractAfterContractedDialog>
  );
};

export default WarrantyZeroContractAfterContractedDialog;
