import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import cuideoTheme from "../../../../../../containers/themes/defaultTheme";

const StyledServiceUser = styled.div`
  font-size: 0.875rem;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  line-height: 1.15;
  color: ${cuideoTheme.palette.primary.main};
`;

const StyledNameSection = styled.div``;

const StyledNameSectionTitle = styled(Typography)`
  font-size: 1rem;
  font-weight: 700;
`;

const StyledSection = styled.div``;

const StyledSectionTitle = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 700;
  text-decoration: underline;
`;

const StyledUserDetail = styled(Typography)`
  font-size: 0.875rem;
`;

const StyledItem = styled.div`
  margin: 0.25rem 0;
`;

const StyledLabel = styled.span`
  font-weight: 700;
`;

interface IServiceUserReport {
  serviceUser: {
    activities?: string;
    caretakerAdaptation?: string;
    height?: number;
    toiletLevel?: string[];
    sleepQuality?: string;
    emotionalStatusComments?: string;
    mobilityComments?: string;
    pathologiesComments?: string;
    diet?: string;
    status?: string;
    emotionalStatus?: string[];
    birthDate?: string;
    speechLevel?: string;
    toiletIndications?: string;
    sleepIndications?: string;
    medication?: string;
    mobility?: string[];
    name?: string;
    pathologies?: string[];
    weight?: number;
    surname?: string;
    dependencyLawGrade?: string;
  };
}

const ServiceUserReport = (props: IServiceUserReport) => {
  const { serviceUser } = props;

  const userAge = moment().diff(moment(serviceUser.birthDate), "years");
  const formattedUserAge = userAge > 18 ? userAge : null;

  return (
    <StyledServiceUser>
      <StyledNameSection>
        <StyledNameSectionTitle>
          {serviceUser.name} {serviceUser.surname}
        </StyledNameSectionTitle>
        <Grid container alignItems="center" spacing={8}>
          {formattedUserAge && (
            <Grid item>
              <StyledUserDetail>
                <FormattedMessage
                  id="ServiceUser.{age} años"
                  defaultMessage="{age} años"
                  values={{
                    age: formattedUserAge,
                  }}
                />
              </StyledUserDetail>
            </Grid>
          )}
          {serviceUser.height && (
            <Grid item>
              <StyledUserDetail>
                <FormattedMessage
                  id="ServiceUser.{height} cm"
                  defaultMessage="{height} cm"
                  values={{
                    height: serviceUser.height,
                  }}
                />
              </StyledUserDetail>
            </Grid>
          )}
          {serviceUser.weight && (
            <Grid item>
              <StyledUserDetail>
                <FormattedMessage
                  id="ServiceUser.{weight} kg"
                  defaultMessage="{weight} kg"
                  values={{
                    weight: serviceUser.weight,
                  }}
                />
              </StyledUserDetail>
            </Grid>
          )}
        </Grid>
      </StyledNameSection>
      <StyledSection>
        <StyledSectionTitle>
          <FormattedMessage
            id="ServiceUser.Estado físico y cognitivo"
            defaultMessage="Estado físico y cognitivo"
          />
        </StyledSectionTitle>

        {serviceUser.pathologiesComments && (
          <StyledItem>
            <StyledLabel>
              <FormattedMessage
                id="ServiceUser.Comentario patologías:"
                defaultMessage="Comentario patologías:"
              />
            </StyledLabel>
            &nbsp;
            {serviceUser.pathologiesComments}
          </StyledItem>
        )}
        <StyledItem>
          <StyledLabel>
            <FormattedMessage
              id="ServiceUser.Medicación:"
              defaultMessage="Medicación:"
            />
          </StyledLabel>
          &nbsp;
          {serviceUser.medication}
        </StyledItem>
        <StyledItem>
          <StyledLabel>
            <FormattedMessage id="ServiceUser.Dieta:" defaultMessage="Dieta:" />
          </StyledLabel>
          &nbsp;
          {serviceUser.diet}
        </StyledItem>
        {serviceUser.speechLevel && (
          <StyledItem>
            <StyledLabel>
              <FormattedMessage
                id="ServiceUser.Habla / Expresión:"
                defaultMessage="Habla / Expresión:"
              />
            </StyledLabel>
            &nbsp;
            {serviceUser.speechLevel}
          </StyledItem>
        )}
        <StyledItem>
          <StyledLabel>
            <FormattedMessage
              id="ServiceUser.Movilidad:"
              defaultMessage="Movilidad:"
            />
          </StyledLabel>
          &nbsp;
          {serviceUser.mobility?.join(", ")}
        </StyledItem>
        {serviceUser.mobilityComments && (
          <StyledItem>
            <StyledLabel>
              <FormattedMessage
                id="ServiceUser.Comentarios movilidad:"
                defaultMessage="Comentarios movilidad:"
              />
            </StyledLabel>
            &nbsp;
            {serviceUser.mobilityComments}
          </StyledItem>
        )}
      </StyledSection>
      <StyledSection>
        <StyledSectionTitle>
          <FormattedMessage
            id="ServiceUser.Higiene personal y calidad del sueño"
            defaultMessage="Higiene personal y calidad del sueño"
          />
        </StyledSectionTitle>

        <StyledItem>
          <StyledLabel>
            <FormattedMessage id="ServiceUser.Aseo:" defaultMessage="Aseo:" />
          </StyledLabel>
          &nbsp;
          {serviceUser.toiletLevel?.join(", ")}
        </StyledItem>
        {serviceUser.toiletIndications && (
          <StyledItem>
            <StyledLabel>
              <FormattedMessage
                id="ServiceUser.Indicaciones aseo:"
                defaultMessage="Indicaciones aseo:"
              />
            </StyledLabel>
            &nbsp;
            {serviceUser.toiletIndications}
          </StyledItem>
        )}
        <StyledItem>
          <StyledLabel>
            <FormattedMessage
              id="ServiceUser.Calidad del sueño:"
              defaultMessage="Calidad del sueño:"
            />
          </StyledLabel>
          &nbsp;
          {serviceUser.sleepQuality}
        </StyledItem>
        {serviceUser.sleepIndications && (
          <StyledItem>
            <StyledLabel>
              <FormattedMessage
                id="ServiceUser.Indicaciones del sueño:"
                defaultMessage="Indicaciones del sueño:"
              />
            </StyledLabel>
            &nbsp;
            {serviceUser.sleepIndications}
          </StyledItem>
        )}
      </StyledSection>
      <StyledSection>
        <StyledSectionTitle>
          <FormattedMessage
            id="ServiceUser.Estado emocional y carácter"
            defaultMessage="Estado emocional y carácter"
          />
        </StyledSectionTitle>
        <StyledItem>
          <StyledLabel>
            <FormattedMessage
              id="ServiceUser.Estado emocional y carácter:"
              defaultMessage="Estado emocional y carácter:"
            />
          </StyledLabel>
          &nbsp;
          {serviceUser.emotionalStatus?.join(", ")}
        </StyledItem>
        {serviceUser.emotionalStatusComments && (
          <StyledItem>
            <StyledLabel>
              <FormattedMessage
                id="ServiceUser.Comentarios estado emocional y carácter:"
                defaultMessage="Comentarios estado emocional y carácter:"
              />
            </StyledLabel>
            &nbsp;
            {serviceUser.emotionalStatusComments}
          </StyledItem>
        )}
        <StyledItem>
          <StyledLabel>
            <FormattedMessage
              id="ServiceUser.Adaptación a cuidados:"
              defaultMessage="Adaptación a cuidados:"
            />
          </StyledLabel>
          &nbsp;
          {serviceUser.caretakerAdaptation}
        </StyledItem>
        {serviceUser.activities && (
          <StyledItem>
            <StyledLabel>
              <FormattedMessage
                id="ServiceUser.Actividades y aficiones:"
                defaultMessage="Actividades y aficiones:"
              />
            </StyledLabel>
            &nbsp;
            {serviceUser.activities}
          </StyledItem>
        )}
        {serviceUser.dependencyLawGrade && (
          <StyledItem>
            <StyledLabel>
              <FormattedMessage
                id="ServiceUser.Ley de dependencia:"
                defaultMessage="Ley de dependencia:"
              />
            </StyledLabel>
            &nbsp;
            {serviceUser.dependencyLawGrade}
          </StyledItem>
        )}
      </StyledSection>
    </StyledServiceUser>
  );
};

export default ServiceUserReport;
