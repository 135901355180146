import {
  Box,
  Button,
  ClickAwayListener,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import React, { Fragment, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { applyOffer, deferApply, unApplyOffer } from "../../../../actions/Auth";
import cuideoTheme from "../../../../containers/themes/defaultTheme";
import useWindowWidth from "../../../../util/useWindowWidth";
import { JOB_SIGN_IN } from "../../../../constants/routes";

const StyledButton = styled(Button)``;

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    max-width: 23rem;
    background-color: #f5e07f;
    color: ${cuideoTheme.palette.primary.main};
    line-height: normal;
  }
`;

const StyledAppliedDialog = styled(Dialog)`
  .MuiPaper-root {
    max-width: 23rem;
    background-color: #50e3c2;
    color: ${cuideoTheme.palette.primary.main};
    line-height: normal;
  }
`;

const StyledTooltipDialog = styled.div`
  background-color: #f5e07f;
  padding: 1rem;
  border-radius: ${cuideoTheme.shape.borderRadius}px;
  text-align: left;
`;

const StyledTooltipWrapper = styled.div`
  .MuiTooltip-tooltip {
    padding: 0;
    border-radius: ${cuideoTheme.shape.borderRadius}px;
    box-shadow: 0 4px 6px 0 rgba(65, 117, 5, 0.4);
    margin-top: 0;
    z-index: 1500;
  }
  .MuiTooltip-popper {
    pointer-events: auto;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    .MuiTooltip-tooltip {
      min-width: 22.5rem;
    }
  }
`;

const StyledCloseIconButton = styled(IconButton)`
  margin-right: -0.5rem;
  margin-top: -0.5rem;
`;

const StyledAppliedTooltipDialog = styled.div`
  background-color: #50e3c2;
  padding: 1rem;
  border-radius: ${cuideoTheme.shape.borderRadius}px;
`;

const StyledAppliedTooltipWrapper = styled.div`
  .MuiTooltip-tooltip {
    padding: 0;
    border-radius: ${cuideoTheme.shape.borderRadius}px;
    box-shadow: 0 4px 6px 0 rgba(65, 117, 5, 0.4);
    margin-top: 0;
    z-index: 1500;
  }
  .MuiTooltip-popper {
    pointer-events: auto;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    .MuiTooltip-tooltip {
      min-width: 22.5rem;
    }
  }
`;

const StyledApplyButton = styled(Button)<{ applied: any }>`
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 0.875rem;
    letter-spacing: 0.035rem;
    padding: 0.5rem 1rem;
    border: 1px solid ${cuideoTheme.palette.primary.main};
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 1.1rem;
    letter-spacing: 0.035rem;
    padding: 0.5rem 1rem;
    border: 1px solid ${cuideoTheme.palette.primary.main};
    min-width: 15rem;
  }
  ${({ applied }) =>
    applied &&
    `
    @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
      border: 1px solid ${cuideoTheme.palette.secondary.main};
    }
    @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
      border: 1px solid ${cuideoTheme.palette.secondary.main};
    }
  `}
`;

const StyledDialogContentText = styled(DialogContentText)`
  font-size: 1.1rem;
  line-height: 1.2;
  margin: 0.75rem 0;
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 0;
  top: 0;
`;

interface Props {
  size: "medium" | "small" | "large";
  children: any;
  width: number;
  applyOffer: Function;
  unApplyOffer: Function;
  deferApply: Function;
  appliedOffers: any;
  appliedOffersLoaded: boolean;
  offer: any;
  authUser: any;
  variant?: "highlighted" | "normal";
  className?: any;
}

const AplicarOfertaButton = (props: Props & any) => {
  const [mustRegister, setMustRegisterDisplay] = useState(false);
  const [justApplied, setJustApplied] = useState(false);
  const {
    appliedOffers,
    appliedOffersLoaded,
    applyOffer,
    unApplyOffer,
    offer,
    authUser,
    className,
    deferApply,
    ...others
  } = props;
  const width = useWindowWidth();

  const offerId = offer.id;

  const appliedOffer = appliedOffers.find((item: any) => {
    if (item.contratoId === offerId) return true;
    return false;
  });
  const found = !!appliedOffer;
  const isApplied = !!found;

  const canUnApplyOffer = false;

  useEffect(() => {
    if (!appliedOffersLoaded) {
      // props.getAppliedOffers();
    }
    // eslint-disable-next-line
  }, []);

  const handleDialogClose = () => {
    if (authUser) {
      setJustApplied(false);
    } else {
      setMustRegisterDisplay(false);
    }
  };

  const handleButtonClick = () => {
    if (authUser) {
      if (isApplied) {
        // Debemos hacer unApply con el id de la valoración y no de
        // la oferta
        const valoracion = appliedOffers.find((item: any) => {
          if (item.contratoId === offerId) return true;
          return false;
        });
        if (valoracion && canUnApplyOffer) {
          unApplyOffer({
            valoracionId: valoracion.id,
            offerId: offerId,
          });
        }
        // Si no hay valoracion estamos en un estado que hay que esperar
      } else {
        applyOffer(offer);
        setJustApplied(true);
      }
      // if (justApplied) {
      //   setJustApplied(false);
      // } else {
      //   setJustApplied(true);
      // }
    } else {
      // If we defer here we are deferring when click on blue button
      // deferApply(offer);
      setMustRegisterDisplay(true);
    }
  };

  const handleTooltipClose = () => {
    if (authUser) {
      setJustApplied(false);
    } else {
      setMustRegisterDisplay(false);
    }
  };

  if (authUser) {
    if (!authUser.userRoles.includes("ROLE_EMPLOYEE")) {
      return <Fragment />;
    }

    return (
      <Fragment>
        {width >= 768 && (
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <StyledAppliedTooltipWrapper>
              <Tooltip
                PopperProps={{ disablePortal: true }}
                onClose={handleTooltipClose}
                open={justApplied && isApplied}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={
                  <Fragment>
                    <StyledAppliedTooltipDialog>
                      <Grid container spacing={4}>
                        <Grid item xs={10}>
                          <StyledDialogContentText
                            color="primary"
                            style={{ textAlign: "left" }}
                          >
                            <strong>
                              <FormattedMessage
                                id="ApplyButton.Inscribed"
                                defaultMessage="¡Te has inscrito a la oferta!"
                              />
                            </strong>
                            <br />
                            <FormattedMessage
                              id="ApplyButton.MoreChances"
                              defaultMessage="Recuerda, cuantas más ofertas más probabilidades de conseguir trabajo."
                            />
                          </StyledDialogContentText>
                        </Grid>
                        <Grid item xs={2}>
                          <Box textAlign="right">
                            <StyledCloseIconButton
                              aria-label="cerrar"
                              onClick={handleDialogClose}
                            >
                              <CloseIcon color="primary" />
                            </StyledCloseIconButton>
                          </Box>
                        </Grid>
                      </Grid>
                    </StyledAppliedTooltipDialog>
                  </Fragment>
                }
              >
                <StyledApplyButton
                  color={isApplied ? "secondary" : "primary"}
                  applied={isApplied ? "applied" : undefined}
                  variant="contained"
                  size={props.size}
                  onClick={handleButtonClick}
                  className={className}
                  {...others}
                >
                  {isApplied ? (
                    <Fragment>
                      <CheckIcon fontSize="small" />
                      {width < cuideoTheme.breakpoints.values.sm ? (
                        <FormattedMessage
                          id="ApplyButton.Applied"
                          defaultMessage="Aplicada"
                        />
                      ) : (
                        <FormattedMessage
                          id="ApplyButton.AppliedOffer"
                          defaultMessage="Oferta Aplicada"
                        />
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>{props.children}</Fragment>
                  )}
                </StyledApplyButton>
              </Tooltip>
            </StyledAppliedTooltipWrapper>
          </ClickAwayListener>
        )}
        {width < 768 && (
          <Fragment>
            <StyledApplyButton
              color={isApplied ? "secondary" : "primary"}
              applied={isApplied ? "applied" : undefined}
              variant="contained"
              size={props.size}
              onClick={handleButtonClick}
              className={className}
              {...others}
            >
              {isApplied ? (
                <Fragment>
                  <CheckIcon fontSize="small" />
                  {width < cuideoTheme.breakpoints.values.sm ? (
                    <FormattedMessage
                      id="ApplyButton.Applied"
                      defaultMessage="Aplicada"
                    />
                  ) : (
                    <FormattedMessage
                      id="ApplyButton.AppliedOffer"
                      defaultMessage="Oferta Aplicada"
                    />
                  )}
                </Fragment>
              ) : (
                <Fragment>{props.children}</Fragment>
              )}
            </StyledApplyButton>
            <StyledAppliedDialog
              onClose={handleDialogClose}
              open={justApplied && isApplied}
              aria-labelledby="yaEstasRegistrada"
            >
              <DialogContent style={{ padding: "1rem" }}>
                <Grid container spacing={4}>
                  <Grid item xs={10}>
                    <StyledDialogContentText color="primary">
                      <strong>
                        <FormattedMessage
                          id="ApplyButton.Inscribed"
                          defaultMessage="¡Te has inscrito a la oferta!"
                        />
                      </strong>
                      <br />
                      <FormattedMessage
                        id="ApplyButton.MoreChances"
                        defaultMessage="Recuerda, cuantas más ofertas más probabilidades de conseguir trabajo."
                      />
                    </StyledDialogContentText>
                  </Grid>
                  <Grid item xs={2}>
                    <Box textAlign="right">
                      <StyledCloseIconButton
                        aria-label="cerrar"
                        onClick={handleDialogClose}
                      >
                        <CloseIcon color="primary" />
                      </StyledCloseIconButton>
                    </Box>
                  </Grid>
                </Grid>
              </DialogContent>
            </StyledAppliedDialog>
          </Fragment>
        )}
      </Fragment>
    );
  }

  return (
    <Fragment>
      {width >= 768 && (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <StyledTooltipWrapper>
            <Tooltip
              PopperProps={{ disablePortal: true }}
              onClose={handleTooltipClose}
              open={mustRegister}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={
                <Fragment>
                  <StyledTooltipDialog>
                    <Grid container spacing={4}>
                      <Grid item xs={10}>
                        <StyledDialogContentText color="primary">
                          <strong>
                            <FormattedMessage
                              id="ApplyButton.ContinueToApply"
                              defaultMessage="Continúa registrándote o iniciando sesión para aplicar a esta oferta."
                            />
                          </strong>
                        </StyledDialogContentText>
                      </Grid>
                      <Grid item xs={2}>
                        <Box textAlign="right">
                          <StyledCloseIconButton
                            aria-label="cerrar"
                            onClick={handleDialogClose}
                          >
                            <CloseIcon color="primary" />
                          </StyledCloseIconButton>
                        </Box>
                      </Grid>
                    </Grid>
                    <Box mb={4}>
                      <Grid container spacing={4} alignItems="center">
                        <Grid item xs={12} sm={5}>
                          <Typography
                            color="primary"
                            style={{ fontSize: "1.1rem" }}
                          >
                            <FormattedMessage
                              id="ApplyButton.IfYouAreNey"
                              defaultMessage="Si eres nuevo/a:"
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <Link
                            to="/registroCuidador"
                            onClick={() => {
                              deferApply(offer);
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <StyledButton
                              color="primary"
                              variant="contained"
                              size="medium"
                              fullWidth
                              style={{ textTransform: "none" }}
                            >
                              <FormattedMessage
                                id="ApplyButton.SignUp"
                                defaultMessage="Regístrate"
                              />
                            </StyledButton>
                          </Link>
                        </Grid>
                      </Grid>
                    </Box>
                    <Grid container spacing={4} alignItems="center">
                      <Grid item xs={12} sm={5}>
                        <Typography
                          color="primary"
                          style={{ fontSize: "1.1rem", lineHeight: 1.25 }}
                        >
                          <FormattedMessage
                            id="ApplyButton.IfYouHaveAccount"
                            defaultMessage="Si ya tienes cuenta cuideo:"
                          />
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <Link
                          to={JOB_SIGN_IN}
                          onClick={() => {
                            deferApply(offer);
                          }}
                          style={{ textDecoration: "none" }}
                        >
                          <StyledButton
                            color="primary"
                            variant="outlined"
                            size="medium"
                            fullWidth
                            style={{ textTransform: "none" }}
                          >
                            <FormattedMessage
                              id="ApplyButton.SignIn"
                              defaultMessage="Iniciar sesión"
                            />
                          </StyledButton>
                        </Link>
                      </Grid>
                    </Grid>
                  </StyledTooltipDialog>
                </Fragment>
              }
            >
              <StyledApplyButton
                color="primary"
                variant="contained"
                size={props.size}
                onClick={handleButtonClick}
                className={className}
                {...others}
              >
                {props.children}
              </StyledApplyButton>
            </Tooltip>
          </StyledTooltipWrapper>
        </ClickAwayListener>
      )}
      {width < 768 && (
        <Fragment>
          <StyledApplyButton
            color="primary"
            variant="contained"
            size={props.size}
            onClick={handleButtonClick}
            className={className}
            {...others}
          >
            {props.children}
          </StyledApplyButton>
          <StyledDialog
            onClose={handleDialogClose}
            open={mustRegister}
            aria-labelledby="debesRegistrarteTitle"
          >
            <DialogContent style={{ padding: "1rem" }}>
              <StyledIconButton aria-label="cerrar" onClick={handleDialogClose}>
                <CloseIcon color="primary" />
              </StyledIconButton>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <StyledDialogContentText
                    color="primary"
                    style={{ textAlign: "center", marginTop: "1.5rem" }}
                  >
                    <strong>
                      <FormattedMessage
                        id="ApplyButton.ContinueToApply"
                        defaultMessage="Continúa registrándote o iniciando sesión para aplicar a esta oferta."
                      />
                    </strong>
                  </StyledDialogContentText>
                </Grid>
              </Grid>
              <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} sm={6}>
                  <Typography
                    color="primary"
                    style={{ fontSize: "1.0625rem", textAlign: "center" }}
                  >
                    <FormattedMessage
                      id="ApplyButton.IfYouAreNey"
                      defaultMessage="Si eres nuevo/a:"
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} style={{ textAlign: "center" }}>
                  <Link
                    to="/registroCuidador"
                    onClick={() => {
                      deferApply(offer);
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <StyledButton
                      color="primary"
                      variant="contained"
                      size="medium"
                      style={{ textTransform: "none", minWidth: "11.25rem" }}
                    >
                      <FormattedMessage
                        id="ApplyButton.SignUp"
                        defaultMessage="Regístrate"
                      />
                    </StyledButton>
                  </Link>
                </Grid>
              </Grid>
              <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} sm={6}>
                  <Typography
                    color="primary"
                    style={{
                      fontSize: "1.0625rem",
                      textAlign: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <FormattedMessage
                      id="ApplyButton.IfYouHaveAccount"
                      defaultMessage="Si ya tienes cuenta cuideo:"
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} style={{ textAlign: "center" }}>
                  <Link to={JOB_SIGN_IN} style={{ textDecoration: "none" }}>
                    <StyledButton
                      color="primary"
                      onClick={() => {
                        deferApply(offer);
                      }}
                      variant="outlined"
                      size="medium"
                      style={{ textTransform: "none", minWidth: "11.25rem" }}
                    >
                      <FormattedMessage
                        id="ApplyButton.SignIn"
                        defaultMessage="Iniciar sesión"
                      />
                    </StyledButton>
                  </Link>
                </Grid>
              </Grid>
            </DialogContent>
          </StyledDialog>
        </Fragment>
      )}
    </Fragment>
  );
};

const mapStateToProps = ({
  layout,
  empleo,
  auth,
}: {
  layout: any;
  empleo: any;
  auth: any;
}) => {
  const { authUser, appliedOffers } = auth;
  const { appliedOffersLoaded } = empleo;
  return { appliedOffers, appliedOffersLoaded, authUser };
};

export default connect(mapStateToProps, {
  applyOffer,
  unApplyOffer,
  deferApply,
})(AplicarOfertaButton);
