import { apiEmpleo } from "../../util/ApiEmpleo";
import { TApiCollectionResponse } from "../apiCoreV2.types";
import { TGetCandidateValuationsCollectionItem } from "./valuation.types";

export interface IApiListCandidateValuationsParams {
  candidateId: string;
  page?: number;
  itemsPerPage?: number;
  estadoEnProceso?: string;
  "contrato.estado"?: string[];
  partial?: number;
}

export const apiListCandidateValuations = async (
  params: IApiListCandidateValuationsParams
) => {
  let res: TApiCollectionResponse<TGetCandidateValuationsCollectionItem> =
    await apiEmpleo.get(`candidatos/${params.candidateId}/valoraciones`, {
      params,
    });

  return res;
};
