import { useIntl } from "react-intl";
import { CONTRACT_REPLACEMENT_REASON } from "../../../../../../../api/contract/contract.types";
import { CONTRACT_INDEED_ASK_FOR_PROMO, CONTRACT_ORIGIN_SALE, CONTRACT_ORIGIN_WARRANTY_NINETY, CONTRACT_ORIGIN_WARRANTY_ZERO } from "../../../../../../../util/defaults/contrato";
import { formatCurrency } from "../../../../../../../util/formatNumbers";
import { TContractFull } from "../../../types/contract.types";
import { contractJobTypeFromContractDealType } from "../../../util/contract.util";
import { TContractEditModalForm } from "../ContractEditModal.types";

export default function useContractEditFormInitialValues(
  contract: TContractFull
) {
  const { formatMessage } = useIntl();

  const defaultTitle = (contract: TContractFull) => {
    if (
      contract.Origen === CONTRACT_ORIGIN_SALE &&
      contract.Servicio_corta_duraci_n === "Si"
    ) {
      return formatMessage(
        {
          id: "ContractEditForm.Cuidador/a {contractType} (servicio puntual) | {city}",
          defaultMessage:
            "Cuidador/a {contractType} (servicio puntual)| {city} Servicio Puntual",
        },
        {
          contractType: contract.Tipo_de_Trato,
          city: contract.Ciudad_servicio,
        }
      );
    }

    if (
      (contract.Origen === CONTRACT_ORIGIN_WARRANTY_ZERO || contract.Origen === CONTRACT_ORIGIN_WARRANTY_NINETY) &&
      (contract.Motivo_reemplazo === CONTRACT_REPLACEMENT_REASON.FESTIVITY ||
        contract.Motivo_reemplazo === CONTRACT_REPLACEMENT_REASON.HOLIDAYS ||
        contract.Motivo_reemplazo === CONTRACT_REPLACEMENT_REASON.ILLNESS)
    ) {
      return formatMessage(
        {
          id: "ContractEditForm.Cuidador/a {contractType} (servicio puntual)| {city}",
          defaultMessage:
            "Cuidador/a {contractType} | (servicio puntual){city}",
        },
        {
          contractType: contract.Tipo_de_Trato,
          city: contract.Ciudad_servicio,
        }
      );
    }

    return formatMessage(
      {
        id: "ContractEditForm.Cuidador/a {contractType} | {city}",
        defaultMessage: "Cuidador/a {contractType} | {city}",
      },
      {
        contractType: contract.Tipo_de_Trato,
        city: contract.Ciudad_servicio,
      }
    );
  };

  const defaultLookingFor = (contract: TContractFull) => {
    let usersDetail = "Usuario 1: ";
    if (
      !!contract.Servicio?.Usuario1?.Patologias_U1 &&
      contract.Servicio.Usuario1.Patologias_U1.length !== 0
    ) {
      usersDetail += contract.Servicio.Usuario1.Patologias_U1.join(", ") + ". ";
    }
    if (
      !!contract.Servicio?.Usuario1?.Movilidad_U1 &&
      contract.Servicio.Usuario1.Movilidad_U1.length !== 0
    ) {
      usersDetail += contract.Servicio.Usuario1.Movilidad_U1.join(", ") + ". ";
    }

    if (contract.Servicio?.Usuario1?.Usuarios_a_cuidar === 2) {
      usersDetail += "\nUsuario 2: ";

      if (
        !!contract.Servicio?.Usuario1?.Patologias_U2 &&
        contract.Servicio.Usuario1.Patologias_U2.length !== 0
      ) {
        usersDetail +=
          contract.Servicio.Usuario1.Patologias_U2.join(", ") + ". ";
      }
      if (
        !!contract.Servicio?.Usuario1?.Movilidad_U2 &&
        contract.Servicio.Usuario1.Movilidad_U2.length !== 0
      ) {
        usersDetail +=
          contract.Servicio.Usuario1.Movilidad_U2.join(", ") + ". ";
      }
    }

    return formatMessage(
      {
        id: "ContractEditForm.Cuidador/a para {usersToTakeCare} {convivenceUnit}.\n{usersDetail",
        defaultMessage:
          "Cuidador/a para {usersToTakeCare} {convivenceUnit}.\n{usersDetail}",
      },
      {
        usersToTakeCare:
          contract.Servicio?.Usuario1?.Usuarios_a_cuidar === 2
            ? formatMessage({
                id: "ContractEditForm.2 usuarios",
                defaultMessage: "2 usuarios",
              })
            : formatMessage({
                id: "ContractEditForm.1 usuario",
                defaultMessage: "1 usuario",
              }),
        convivenceUnit: contract.Servicio?.Usuario1?.Unidad_de_convivencia
          ? formatMessage(
              {
                id: "ContractEditForm.que {convivenceUnit}",
                defaultMessage: "que {convivenceUnit}",
              },
              {
                convivenceUnit:
                  contract.Servicio.Usuario1.Unidad_de_convivencia,
              }
            )
          : "",
        usersDetail,
      }
    );
  };

  const defaultRequisites = (contract: TContractFull) => {
    const tasks =
      contract.Tareas_solicitadas && contract.Tareas_solicitadas.length !== 0
        ? contract.Tareas_solicitadas
        : contract.Servicio?.Tareas_solicitadas &&
          contract.Servicio.Tareas_solicitadas.length !== 0
        ? contract.Servicio.Tareas_solicitadas
        : [];

    return formatMessage(
      {
        id: "ContractEditForm.Precisamos cuidador/a {tasks}, que disponga de documentación en regla y de referencias laborales que nos aporten seguridad y garantías. Valoramos formación sociosanitaria.",
        defaultMessage:
          "Precisamos cuidador/a {tasks}, que disponga de documentación en regla y de referencias laborales que nos aporten seguridad y garantías. Valoramos formación sociosanitaria.",
      },
      {
        tasks:
          tasks.length !== 0
            ? formatMessage(
                {
                  id: "ContractEditForm.con experiencia en {tasksList}",
                  defaultMessage: "con experiencia en {tasksList}",
                },
                {
                  tasksList:
                    contract.Servicio?.Tareas_solicitadas?.join(", ") ?? "",
                }
              )
            : "",
      }
    );
  };

  const type = contract.Tipo
    ? contract.Tipo
    : contract.Tipo_de_Trato
    ? contractJobTypeFromContractDealType(contract.Tipo_de_Trato)
    : null;

  const formInitialValues: TContractEditModalForm = {
    T_tulo: contract.T_tulo ?? defaultTitle(contract),
    Tipo_de_Trato: contract.Tipo_de_Trato
      ? {
          value: contract.Tipo_de_Trato,
          label: contract.Tipo_de_Trato,
        }
      : null,
    Tipo: type
      ? {
          value: type,
          label: type,
        }
      : null,
    Ciudad_oferta: contract.Ciudad_oferta ?? contract.Ciudad_servicio ?? "",
    Horario_Servicio: contract.Horario_Servicio ?? "",
    Que_buscamos: contract.Que_buscamos ?? defaultLookingFor(contract),
    Tareas_solicitadas:
      contract.Tareas_solicitadas && contract.Tareas_solicitadas.length !== 0
        ? contract.Tareas_solicitadas.map((item: any) => {
            return {
              value: item,
              label: item,
            };
          })
        : contract.Servicio?.Tareas_solicitadas
        ? contract.Servicio.Tareas_solicitadas.map((item: any) => {
            return {
              value: item,
              label: item,
            };
          })
        : [],
    Requisitos: contract.Requisitos ?? defaultRequisites(contract),
    Salario_Bruto_Mensual: formatCurrency(
      contract.Salario_Bruto_Mensual ?? 0
    ).replace(/[^0-9.,]/g, ""),
    Tipo_de_salario: contract.Tipo_de_salario
      ? {
          value: contract.Tipo_de_salario,
          label: contract.Tipo_de_salario,
        }
      : contract.Servicio?.Tipo_de_salario
      ? {
          value: contract.Servicio.Tipo_de_salario,
          label: contract.Servicio.Tipo_de_salario,
        }
      : null,
    Publicar: contract.Publicar ?? "",
    Promoci_n_Indeed:
      contract.Promoci_n_Indeed === CONTRACT_INDEED_ASK_FOR_PROMO,
  };

  return formInitialValues;
}
