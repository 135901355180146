import { Button, ButtonGroup, Dialog, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import styled from "styled-components";
import cuideoTheme from "../../../../containers/themes/defaultTheme";
import {
  VALORACION_RECOMENDACION,
  VALORACION_SUGGESTION,
} from "../../../../util/defaults/valoracion";

export const StyledButtonGroup = styled(ButtonGroup)<{ estado: string }>`
  box-shadow: none;
  height: 2.5rem;
  background-color: #ff4a6b;
  .MuiButtonGroup-groupedContained {
    border: none;
  }

  ${({ estado }) => {
    if (
      estado === VALORACION_RECOMENDACION ||
      estado === VALORACION_SUGGESTION
    ) {
      return `
        background-color: ${cuideoTheme.palette.primary.light};

        .Mui-disabled {
          color: #ccc;
        }
      `;
    }
  }}
`;

export const StyledButton = styled(Button)<{ estado: string }>`
  background-color: #ff4a6b;
  position: relative;
  flex-grow: 1;
  &:hover {
    background-color: #ef3a5b;
  }

  &.Mui-disabled {
    background-color: #aaaaaa;
  }
`;

export const StyledAddButton = styled(Button)`
  background-color: ${cuideoTheme.palette.primary.light};
  position: relative;

  line-height: 1;
  text-transform: none;
  font-size: 0.9375rem;
  color: ${cuideoTheme.palette.common.white};

  &:hover {
    background-color: ${cuideoTheme.palette.primary.light};
  }
`;

export const StyledArrowButton = styled(Button)<{ estado: string }>`
  background-color: #cb3b56;
  color: ${cuideoTheme.palette.common.white};
  width: 2.25rem;
  min-width: unset;

  &:hover {
    background-color: #bb2b46;
  }

  ${({ estado }) => {
    if (estado === VALORACION_RECOMENDACION) {
      return `
        background-color: ${cuideoTheme.palette.primary.dark};

        &:hover {
          background-color: #002e4a;
        }
      `;
    }
  }}
`;

export const StyledLabel = styled.span`
  line-height: 1;
  text-transform: none;
  font-size: 0.625rem;
  font-weight: 400;
  position: absolute;
  top: 0.3125rem;
  left: 0.75rem;
  color: ${cuideoTheme.palette.common.white};
`;

export const StyledNextStatus = styled.span`
  line-height: 1;
  text-transform: none;
  font-size: 0.9375rem;
  text-align: left;
  position: absolute;
  bottom: 0.375rem;
  left: 0.75rem;
  color: ${cuideoTheme.palette.common.white};
`;

export const StyledCheckingAvailabilityWrapper = styled.span`
  margin-top: 0.375rem;
  .MuiCircularProgress-svg {
    color: #fff;
  }
`;

export const StyledContratarDialog = styled(Dialog)`
  .MuiPaper-root {
    max-width: 25rem;
  }
`;

export const StyledContratarHeader = styled.div`
  background-color: #d13b56;
  height: 4rem;
  position: relative;
  color: ${cuideoTheme.palette.primary.main};
  padding: 0.75rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StyledAvatarWrapper = styled.div`
  position: absolute;
  bottom: -2rem;
  left: 50%;
  transform: translateX(-50%);

  .MuiAvatar-root {
    width: 5rem;
    height: 5rem;
    border: 0.25rem solid #e14b66;
  }
`;

export const StyledActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 0.5rem 0.5rem;
`;

export const StyledCancelButton = styled(Button)``;

export const StyledConfirmButton = styled(Button)`
  .MuiCircularProgress-root {
    color: #aaa;
  }
`;

export const StyledDialogContentC1 = styled(Typography)`
  margin: 3rem 2rem 0.75rem;
  text-align: center;
  font-weight: 700;
  color: ${cuideoTheme.palette.primary.main};
  line-height: 1.25;
  font-size: 1.25rem;
`;

export const StyledDialogContentC2 = styled(Typography)`
  margin: 0.75rem 2rem;
  text-align: center;
  color: ${cuideoTheme.palette.primary.main};
  line-height: 1.25;
  font-size: 1.5rem;
`;

export const StyledDialogContentC3 = styled(Typography)`
  margin: 0.75rem 2rem;
  text-align: center;
  color: ${cuideoTheme.palette.primary.main};
  line-height: 1.25;
  font-size: 0.875rem;
`;

export const StyledDialogAlertC4 = styled(Alert)`
  margin: 0.25rem 1.5rem;
  font-size: 0.875rem;
  line-height: 1.35;
`;

export const StyledCVDialog = styled(Dialog)`
  .MuiPaper-root {
    max-width: 25rem;
  }
`;

export const StyledCVHeader = styled(Typography)`
  margin: 1.25rem 2rem;
  text-align: center;
  color: ${cuideoTheme.palette.primary.main};
  line-height: 1.25;
  font-size: 1.25rem;
  font-weight: 700;
`;

export const StyledDialogContentCV1 = styled(Typography)`
  margin: 0.75rem 2rem;
  text-align: left;
  color: ${cuideoTheme.palette.primary.main};
  line-height: 1.25;
  font-size: 1rem;
`;

export const StyledDialogContentCV2 = styled(Typography)`
  margin: 0.75rem 2rem;
  text-align: left;
  color: ${cuideoTheme.palette.primary.main};
  line-height: 1.25;
  font-size: 1rem;
`;

export const StyledDialogContentCV3 = styled.div`
  margin: 0.75rem 2rem;
`;

export const StyledPreCliDialog = styled(Dialog)`
  .MuiPaper-root {
    max-width: 25rem;
  }
`;

export const StyledPreCliHeader = styled(Typography)`
  margin: 1.25rem 2rem;
  text-align: center;
  color: ${cuideoTheme.palette.primary.main};
  line-height: 1.25;
  font-size: 1.25rem;
  font-weight: 700;
`;

export const StyledDialogContentPreCli = styled.div`
  margin: 1rem 2rem;
`;

export const StyledIcon = styled.img``;

export const StyledPreCliTypography = styled(Typography)`
  margin: 1rem 0;
  text-align: left;
  color: ${cuideoTheme.palette.primary.main};
  line-height: 1.25;
  font-size: 1rem;
`;

export const StyledEmployeeInfoDialog = styled(Dialog)`
  .MuiPaper-root {
    max-width: 40rem;
    background-color: rgb(229, 246, 253);
  }

  .MuiDialogTitle-root {
    .MuiSvgIcon-root {
      vertical-align: top;
      margin-right: 0.25rem;
    }
  }
`;

export const StyledEmployeeInfoDialogText = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  line-height: 1.25;
  font-size: 1rem;
  margin: 0 0 0.5rem;
`;
