import { motion, Variants } from "framer-motion";
import React, { Fragment } from "react";
import InteresaAlgunaOferta from "../../../../components/Highlights/InteresaAlgunaOferta";
import OfertaCard from "../OfferCard";

interface Props {
  ofertas: any;
  includeInteresaBox?: number;
}

const listVariants: Variants = {
  enter: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05,
      delay: 0.1,
    },
  },
  exit: {
    opacity: 0,
  },
};

const itemVariants: Variants = {
  enter: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.25 },
  },
  exit: {
    opacity: 0,
    y: 15,
    transition: { duration: 0.1 },
  },
};

const OfertaCardList = (props: Props) => {
  const { includeInteresaBox } = props;

  return (
    <Fragment>
      <motion.div initial="exit" animate="enter" variants={listVariants}>
        {props.ofertas.map((item: any, index: any) => {
          return (
            <motion.div key={item.id} variants={itemVariants}>
              {includeInteresaBox && index === includeInteresaBox && (
                <InteresaAlgunaOferta />
              )}
              <OfertaCard oferta={item} />
            </motion.div>
          );
        })}
      </motion.div>
    </Fragment>
  );
};

export default OfertaCardList;
