import { useMutation } from "react-query";
import {
  apiSendMessage,
  TSendMessageRequest,
} from "../../../../../apiCoreV3/message/apiSendMessage";
import {
  ExtractFnReturnType,
  MutationConfig,
  queryClient,
} from "../../../../../lib/react-query";
import {
  createNewPendingMessage,
  updateAuthorAndMarginInWaMessagesArray,
} from "../util/message.util";
import { getConversationMessages } from "./getConversationMessages";

export const sendConversationMessage = (payload: TSendMessageRequest) => {
  return apiSendMessage(payload);
};

type TUseCreateConversationMessageOptions = {
  config?: MutationConfig<typeof sendConversationMessage>;
};

export const useCreateConversationMessage = ({
  config,
}: TUseCreateConversationMessageOptions = {}) => {
  return useMutation({
    onMutate: async (payload: TSendMessageRequest) => {
      await queryClient.cancelQueries([
        "conversation-messages",
        "list",
        payload.conversation_id,
      ]);

      // prevQuery can be undefined when there is no previous messages
      // and no previous conversation
      const prevQuery:
        | undefined
        | ExtractFnReturnType<typeof getConversationMessages> =
        queryClient.getQueryData([
          "conversation-messages",
          "list",
          payload.conversation_id,
        ]);

      if (!prevQuery) {
        return undefined;
      }

      const newMessages = [
        ...prevQuery.messages,
        createNewPendingMessage(
          JSON.parse(payload.content),
          payload.from,
          payload.author_full_name ?? ""
        ),
      ];

      const newData = {
        ...prevQuery,
        messages: updateAuthorAndMarginInWaMessagesArray(newMessages),
      };

      queryClient.setQueryData(
        ["conversation-messages", "list", payload.conversation_id],
        newData
      );

      return { prevQuery };
    },
    onError: (err, payload, context: any) => {
      queryClient.setQueryData(
        ["conversation-messages", "list", payload.conversation_id],
        context.prevQuery
      );
    },
    onSettled: (data, error, newMessage) => {
      queryClient.invalidateQueries([
        "conversation-messages",
        "list",
        newMessage.conversation_id,
      ]);
    },
    ...config,
    mutationFn: sendConversationMessage,
  });
};
