import { Typography } from "@material-ui/core";
import styled from "styled-components";
import cuideoTheme from "../../containers/themes/defaultTheme";

export const StyledField = styled("div")<{
  fullWidth: boolean;
  margin: string;
  size: string;
}>`
  ${({ fullWidth }) => fullWidth && `width: 100%;`}
  padding-top: 0;
  padding-bottom: 0;

  ${({ margin }) => {
    if (margin === "normal") {
      return `
      margin: 1rem 0;
      `;
    }
    if (margin === "nomargin") {
      return ``;
    }
  }}

  &:last-child {
    border-bottom: none;
  }
`;

export const StyledLabel = styled(Typography)<{ error?: string; size: string }>`
  color: ${({ error }) =>
    error ? cuideoTheme.palette.error.main : cuideoTheme.palette.primary.light};
  white-space: wrap;

  ${({ size }) => {
    if (size === "medium") {
      return `
        font-size: 1rem;
        padding: 0.2rem 0 0.2rem 0;
      `;
    }
    if (size === "small") {
      return `
        font-size: 0.8rem;
        padding: 0.2rem 0 0.2rem 0;

        @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
          font-size: 0.8rem;
        }
      `;
    }
  }}
`;

export const StyledHelperText = styled.div``;

export const StyledError = styled(Typography)<{ size: string }>`
  color: ${cuideoTheme.palette.error.main};

  ${({ size }) => {
    if (size === "medium") {
      return `
        font-size: 0.8rem;
      `;
    }
    if (size === "small") {
      return `
        font-size: 0.8rem;

      `;
    }
  }}
`;
