import { useQuery } from "react-query";
import { apiGetContract } from "../../../../../api/contract/apiGetContract";
import { TGetContractsItem } from "../../../../../api/contract/contract.types";
import {
  ExtractFnReturnType,
  QueryConfig,
} from "../../../../../lib/react-query";
import { contractKeys } from "../util/contract.query-keys";

export const getContract = async ({
  contractId,
}: {
  contractId: string;
}): Promise<TGetContractsItem> => {
  return apiGetContract(contractId);
};

type QueryFnType = typeof getContract;

type TUseContractOptions = {
  contractId: string;
  config?: QueryConfig<QueryFnType>;
};

export const useContract = ({ contractId, config }: TUseContractOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: contractKeys.item(contractId),
    queryFn: () => getContract({ contractId }),
  });
};
