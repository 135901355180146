export const VALORACION_SIN_VALORAR = "01_Sin Valorar";
export const VALORACION_DESCARTADO = "02_Descartado";
export const VALORACION_TELEFONICA = "03_Telefónica";
export const VALORACION_PRESENCIAL = "04_Presencial";
export const VALORACION_CVCLIENTE = "04_CV a cliente";
export const VALORACION_PRESENCIAL_CLIENTE = "05_Presencial Cliente";
export const VALORACION_CONTRATADO = "06_Contratado";

// Special used only to simulate Valoracion
export const VALORACION_RECOMENDACION = "00_Recomendado";
export const VALORACION_SUGGESTION = "00_Suggestion";

export const SUGGESTION_ACCEPTED = "accepted";
export const SUGGESTION_REJECTED = "rejected";
export const SUGGESTION_SUBSTITUTE = "substitute";
