import { Box, Button, Grid } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Mention, MentionsInput } from "react-mentions";
import styled from "styled-components";
import cuideoTheme from "../../../containers/themes/defaultTheme";
import inputStyle from "./inputStyle";
import mentionStyle from "./mentionStyle";

const StyledBox = styled(Box)`
  .MuiFilledInput-multiline {
    background-color: rgba(0, 72, 120, 0.1);
    color: ${cuideoTheme.palette.primary.main};
    font-weight: 600;
    height: unset;
    padding: 0.5rem 0.75rem;

    .MuiFilledInput-input {
      ::-webkit-scrollbar {
        width: 0.5rem;
      }
      ::-webkit-scrollbar-track {
        background: transparent;
      }
      ::-webkit-scrollbar-thumb {
        background: rgba(0, 72, 120, 0.25);
        width: 0.5rem;
        border-radius: 0.25rem;
      }
    }
  }

  .CMuiNotesFormButtons {
    margin: 0.5rem 0 0;

    .MuiButton-root {
      font-size: 0.875rem;
      padding: 0.25rem 0.5rem;
    }
  }
`;

type TNotesFormProps = {
  onFormSubmit: (note: string) => void;
  onFormCancel: () => void;
  container: any;
  mentionsData: any;
};

const NotesForm = (props: TNotesFormProps) => {
  const { onFormSubmit, onFormCancel, container, mentionsData } = props;

  const inputRef = useRef(null as any);
  const [value, setValue] = useState({
    note: "",
  });

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleChange: any = (e: any) => {
    setValue({
      note: e.target.value,
    });
  };

  const handleSubmit = () => {
    onFormSubmit(value.note);
  };

  return (
    <StyledBox>
      <MentionsInput
        value={value.note}
        onChange={handleChange}
        inputRef={inputRef}
        suggestionsPortalHost={container}
        style={inputStyle}
      >
        <Mention
          trigger="@"
          data={mentionsData}
          style={mentionStyle}
          markup="crm[__id__]crm"
          displayTransform={(id) => {
            return mentionsData.find((item: any) => item.id === id).display;
          }}
        />
      </MentionsInput>

      <Grid
        className="CMuiNotesFormButtons"
        container
        alignItems="center"
        spacing={4}
        justifyContent="flex-end"
      >
        <Grid item>
          <Button variant="outlined" color="primary" onClick={onFormCancel}>
            <FormattedMessage id="NotesForm.Cancel" defaultMessage="Cancelar" />
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            <FormattedMessage id="NotesForm.Save" defaultMessage="Guardar" />
          </Button>
        </Grid>
      </Grid>
    </StyledBox>
  );
};

export default NotesForm;
