import { Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import nl2br from "../../../../../../util/nl2br";
import cuideoTheme from "../../../../../../containers/themes/defaultTheme";
import { FormattedMessage } from "react-intl";
import ServiceUserReport from "./ServiceUserReport";
import { TContractFull } from "../..";

const StyledWrapper = styled.div``;

const StyledBiText = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-size: 0.875rem;
  line-height: 1.2;
  font-weight: 400;
`;

const StyledItem = styled.div`
  font-size: 0.875rem;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  line-height: 1.15;
  color: ${cuideoTheme.palette.primary.main};
`;

const StyledLabel = styled.span`
  font-weight: 700;
`;

const StyledUsersWrapper = styled(Grid)`
  margin: 0.75rem 0 0;
  width: 100%;
`;

const StyledUserWrapper = styled(Paper)`
  max-width: 24rem;
  background-color: #f5f5f5;
  padding: 0.25rem 0.5rem;
`;

interface ICuideoDetailedTooltipReport {
  contract: TContractFull;
}

const CuideoDetailedTooltipReport = (props: ICuideoDetailedTooltipReport) => {
  const { contract } = props;

  if (contract.Servicio?.Usuario1) {
    const serviceHolder = contract.Servicio.Usuario1;
    return (
      <StyledWrapper>
        {!!serviceHolder.Unidad_de_convivencia && (
          <StyledItem>
            <StyledLabel>
              <FormattedMessage
                id="ContractDetail.Unidad de convivencia:"
                defaultMessage="Unidad de convivencia:"
              />
            </StyledLabel>
            &nbsp;
            {serviceHolder.Unidad_de_convivencia}
          </StyledItem>
        )}
        {!!serviceHolder.Tipo_de_vivienda && (
          <StyledItem>
            <StyledLabel>
              <FormattedMessage
                id="ContractDetail.Tipo de vivienda:"
                defaultMessage="Tipo de vivienda:"
              />
            </StyledLabel>
            &nbsp;
            {serviceHolder.Tipo_de_vivienda}
          </StyledItem>
        )}
        {!!contract.Servicio.Sexo_trabajador && (
          <StyledItem>
            <StyledLabel>
              <FormattedMessage
                id="ContractDetail.Sexo preferido:"
                defaultMessage="Sexo preferido:"
              />
            </StyledLabel>
            &nbsp;
            {contract.Servicio.Sexo_trabajador}
          </StyledItem>
        )}
        {!!contract.Servicio.Titulaci_n_oficial && (
          <StyledItem>
            <StyledLabel>
              <FormattedMessage
                id="ContractDetail.Titulación oficial:"
                defaultMessage="Titulación oficial:"
              />
            </StyledLabel>
            &nbsp;
            {contract.Servicio.Titulaci_n_oficial}
          </StyledItem>
        )}
        {serviceHolder.Caracteristicas_de_la_vivienda &&
          serviceHolder.Caracteristicas_de_la_vivienda.length !== 0 && (
            <StyledItem>
              <StyledLabel>
                <FormattedMessage
                  id="ContractDetail.Características de la vivienda:"
                  defaultMessage="Características de la vivienda:"
                />
              </StyledLabel>
              &nbsp;
              {serviceHolder.Caracteristicas_de_la_vivienda?.join(", ")}
            </StyledItem>
          )}
        {serviceHolder.Comunicacion_con_transporte_publico && (
          <StyledItem>
            <StyledLabel>
              <FormattedMessage
                id="ContractDetail.Comunicación con transporte público:"
                defaultMessage="Comunicación con transporte público:"
              />
            </StyledLabel>
            &nbsp;
            {serviceHolder.Comunicacion_con_transporte_publico}
          </StyledItem>
        )}
        {serviceHolder.Notas_coordinadora && (
          <StyledItem>
            <StyledLabel>
              <FormattedMessage
                id="ContractDetail.Notas coordinadora:"
                defaultMessage="Notas coordinadora:"
              />
            </StyledLabel>
            &nbsp;
            {serviceHolder.Notas_coordinadora}
          </StyledItem>
        )}
        {contract.Servicio.Otras_notas_valoracion && (
          <StyledItem>
            <StyledLabel>
              <FormattedMessage
                id="ContractDetail.Otras notas valoración:"
                defaultMessage="Otras notas valoración:"
              />
            </StyledLabel>
            &nbsp;
            {contract.Servicio.Otras_notas_valoracion}
          </StyledItem>
        )}
        {contract.Servicio.Rutinas && (
          <StyledItem>
            <StyledLabel>
              <FormattedMessage
                id="ContractDetail.Rutinas / Otros requisitos:"
                defaultMessage="Rutinas / Otros requisitos:"
              />
            </StyledLabel>
            &nbsp;
            {contract.Servicio.Rutinas}
          </StyledItem>
        )}
        <StyledUsersWrapper
          container
          justifyContent="center"
          wrap="nowrap"
          spacing={8}
        >
          <Grid item>
            <StyledUserWrapper>
              <ServiceUserReport
                serviceUser={{
                  activities: serviceHolder.Actividades_y_aficiones_U1,
                  caretakerAdaptation:
                    serviceHolder.Adaptaci_n_de_cuidados_U1,
                  height: serviceHolder.Altura_U1,
                  toiletLevel: serviceHolder.Aseo_U1,
                  sleepQuality: serviceHolder.Calidad_del_sue_o_U1,
                  emotionalStatusComments:
                    serviceHolder.Comentarios_estado_emocional_y_caracter_U1,
                  mobilityComments: serviceHolder.Comentarios_movilidad_U1,
                  pathologiesComments:
                    serviceHolder.Comentarios_patologias_U1,
                  diet: serviceHolder.Dieta,
                  status: serviceHolder.Estado_U1,
                  emotionalStatus:
                    serviceHolder.Estado_emocional_y_caracter_U1,
                  birthDate: serviceHolder.Fecha_de_nacimiento_U1,
                  speechLevel: serviceHolder.Habla_expresion_U1,
                  toiletIndications: serviceHolder.Indicaciones_del_aseo_U1,
                  sleepIndications: serviceHolder.Indicaciones_del_sueno_U1,
                  medication: serviceHolder.Medicacion_U1,
                  mobility: serviceHolder.Movilidad_U1,
                  name: serviceHolder.Nombre_U1,
                  pathologies: serviceHolder.Patologias_U1,
                  weight: serviceHolder.Peso_U1,
                  surname: serviceHolder.Primer_apellido_U1,
                  dependencyLawGrade:
                    serviceHolder.Grado_Ley_de_dependencia_U1,
                }}
              />
            </StyledUserWrapper>
          </Grid>
          {contract.Servicio.Usuario1.Usuarios_a_cuidar === 2 && (
            <Grid item>
              <StyledUserWrapper>
                <ServiceUserReport
                  serviceUser={{
                    activities: serviceHolder.Actividades_y_aficiones_U2,
                    caretakerAdaptation:
                      serviceHolder.Adaptaci_n_de_cuidados_U2,
                    height: serviceHolder.Altura_U2,
                    toiletLevel: serviceHolder.Aseo_U2,
                    sleepQuality: serviceHolder.Calidad_del_sue_o_U2,
                    emotionalStatusComments:
                      serviceHolder.Comentarios_estado_emocional_y_caracter_U2,
                    mobilityComments: serviceHolder.Comentarios_movilidad_U2,
                    pathologiesComments:
                      serviceHolder.Comentarios_patologias_U2,
                    diet: serviceHolder.Dieta_U2,
                    status: serviceHolder.Estado_U2,
                    emotionalStatus:
                      serviceHolder.Estado_emocional_y_caracter_U2,
                    birthDate: serviceHolder.Fecha_de_nacimiento_U2,
                    speechLevel: serviceHolder.Habla_expresion_U2,
                    toiletIndications: serviceHolder.Indicaciones_del_aseo_U2,
                    sleepIndications: serviceHolder.Indicaciones_del_sueno_U2,
                    medication: serviceHolder.Medicacion_U2,
                    mobility: serviceHolder.Movilidad_U2,
                    name: serviceHolder.Nombre_U2,
                    pathologies: serviceHolder.Patologias_U2,
                    weight: serviceHolder.Peso_U2,
                    surname: serviceHolder.Primer_apellido_U2,
                    dependencyLawGrade:
                      serviceHolder.Grado_Ley_de_dependencia_U2,
                  }}
                />
              </StyledUserWrapper>
            </Grid>
          )}
        </StyledUsersWrapper>
      </StyledWrapper>
    );
  }

  if (contract.Servicio?.Informe_general_inicial) {
    return (
      <StyledBiText
        dangerouslySetInnerHTML={{
          __html: nl2br(contract.Servicio.Informe_general_inicial),
        }}
      />
    );
  }

  return (
    <StyledBiText
      dangerouslySetInnerHTML={{
        __html: nl2br(contract.Que_buscamos ?? ""),
      }}
    />
  );
};

export default CuideoDetailedTooltipReport;
