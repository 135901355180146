import { useSelector } from "react-redux";
import {
  COORDINATION_GROUP,
  TStaffUserResponse,
} from "../../api/user/apiListStaffUsers";
import { TSelectGroupedOption } from "../../apps/affinity/pages/ServicePage/components/ServiceValuation/types";
import { useMemo } from "react";

import { USER_ROLES } from "../../api/security";

const generateSelectOptions = (staffState: TStaffUserResponse[], filterFn: (item: TStaffUserResponse) => boolean): TSelectGroupedOption[] => {
  const barcelona: TSelectGroupedOption = { label: "Barcelona", options: [] };
  const madrid: TSelectGroupedOption = { label: "Madrid", options: [] };
  const euskadi: TSelectGroupedOption = { label: "País Vasco", options: [] };
  const otherFast: TSelectGroupedOption = { label: "Otros rápido", options: [] };
  const otherSlow: TSelectGroupedOption = { label: "Otros lento", options: [] };
  const other: TSelectGroupedOption = { label: "Otros", options: [] };

  const sortedStaff = staffState.sort((a, b) => a.fullname.localeCompare(b.fullname));

  sortedStaff.forEach((item) => {
    if (!item.active || !item.enabled || !filterFn(item)) {
      return;
    }

    let included = false;
    if (item.coordinationGroups?.includes(COORDINATION_GROUP.BCN)) {
      barcelona.options.push({ value: item.id, label: item.fullname });
      included = true;
    }
    if (item.coordinationGroups?.includes(COORDINATION_GROUP.MAD)) {
      madrid.options.push({ value: item.id, label: item.fullname });
      included = true;
    }
    /* if (item.coordinationGroups?.includes(COORDINATION_GROUP.CAV)) {
      euskadi.options.push({ value: item.id, label: item.fullname });
      included = true;
    } */
    if (item.coordinationGroups?.includes(COORDINATION_GROUP.OTH_FAST)) {
      otherFast.options.push({ value: item.id, label: item.fullname });
      included = true;
    }
    if (item.coordinationGroups?.includes(COORDINATION_GROUP.OTH_SLOW)) {
      otherSlow.options.push({ value: item.id, label: item.fullname });
      included = true;
    }
    if (!included) {
      other.options.push({ value: item.id, label: item.fullname });
    }
  });

  return [barcelona, madrid, euskadi, otherFast, otherSlow, other];
}

export const useCoordinationStaffOptions = (): TSelectGroupedOption[] => {
  const staffState: TStaffUserResponse[] = useSelector((state: any) => state.usuariosState.staff);

  return useMemo(() => generateSelectOptions(staffState, () => true), [staffState]);
}

export const useCoordinationPromoterOptions = (): TSelectGroupedOption[] => {
  const staffState: TStaffUserResponse[] = useSelector((state: any) => state.usuariosState.staff);

  return useMemo(() =>
    generateSelectOptions(staffState, (item) =>
      item.roles.includes(USER_ROLES.ROLE_ES_PROMOTER) || item.roles.includes(USER_ROLES.ROLE_ES_ON_SHIFT)
    ), [staffState]
  );
}
