import { Button, Grid, InputAdornment, OutlinedInput } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React, { Fragment } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import styled from "styled-components";
import { setOfertasFilters } from "../../actions/Empleo";
import OfertasFilter from "../../apps/jobsite/components/OffersFilter";
import FiltersIcon from "../../assets/images/iconos/filters.png";
import cuideoTheme from "../../containers/themes/defaultTheme";
import IntlMessages from "../../util/IntlMessages";
import { useProvincesQuery } from "../../apps/jobsite/hooks/useProvincesQuery";
import { useCitiesQuery } from "../../apps/jobsite/hooks/useCitiesQuery";

const StyledFilterButtonWrapper = styled.div`
  margin-left: 1.3rem;
  margin-right: 1.3rem;
  margin-bottom: 0.5rem;
`;

const StyledFilterButton = styled(OutlinedInput)`
  background-color: #e5edf1;
  color: ${cuideoTheme.palette.primary.main};
  height: 2rem;
  text-transform: none;
  font-weight: 400;
  border-radius: ${cuideoTheme.shape.borderRadius}px;

  .MuiOutlinedInput-input {
    text-align: left;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  &:hover {
    background-color: ${(cuideoTheme.palette.primary as any)["100"]};
    color: ${cuideoTheme.palette.primary.main};
  }
  &:active {
    background-color: ${(cuideoTheme.palette.primary as any)["100"]};
    color: ${cuideoTheme.palette.primary.main};
  }
  &:focus {
    background-color: ${(cuideoTheme.palette.primary as any)["100"]};
    color: ${cuideoTheme.palette.primary.main};
  }
`;

const StyledWrapper = styled.div``;

const StyledFiltersIcon = styled.img`
  width: 1rem;
  height: 1rem;
`;

const StyledOptionsWrapper = styled.div`
  padding: 1rem;
  max-height: calc(100vh - 15rem);
  overflow-y: scroll;
`;

const StyledButtonsWrapper = styled.div`
  padding: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: ${cuideoTheme.palette.common.white};
`;

interface Props {
  provinces: any;
  filtrosBusqueda: any;
  setOfertasFilters: Function;
  ofertas: any;
  handleMobileFiltersClick: (isOpen: boolean) => void;
  isMobileFiltersOpen: boolean;
  intl: any;
}

const OfertasFilters = (props: Props) => {
  const {
    ofertas,
    handleMobileFiltersClick,
    isMobileFiltersOpen,
    filtrosBusqueda,
  } = props;
  const { formatMessage } = props.intl;

  const { provinces } = useProvincesQuery();

  const { cities } = useCitiesQuery(filtrosBusqueda.provincia ?? "");

  const listadoProvincias: any = {};
  ofertas.forEach((oferta: any) => {
    if (oferta.Provincia_oferta === "A reparar no tiene datos el cliente") {
      return;
    }
    if (oferta.Ciudad_oferta === "A reparar no tiene datos el cliente") {
      return;
    }
    if (typeof listadoProvincias[oferta.Provincia_oferta] === "undefined") {
      listadoProvincias[oferta.Provincia_oferta] = 1;
    } else {
      listadoProvincias[oferta.Provincia_oferta]++;
    }
  });
  const filtroProvincias: any = [];
  // eslint-disable-next-line
  Object.keys(listadoProvincias).map((item: any, index: any) => {
    filtroProvincias.push({
      provincia: item,
      numOfertas: listadoProvincias[item],
    });
  });

  const provinciaCiudades: any = {};
  ofertas.forEach((oferta: any) => {
    if (typeof provinciaCiudades[oferta.Provincia_oferta] === "undefined") {
      provinciaCiudades[oferta.Provincia_oferta] = {};
      provinciaCiudades[oferta.Provincia_oferta][oferta.Ciudad_oferta] = 1;
    } else {
      if (provinciaCiudades[oferta.Provincia_oferta][oferta.Ciudad_oferta]) {
        provinciaCiudades[oferta.Provincia_oferta][oferta.Ciudad_oferta] += 1;
      } else {
        provinciaCiudades[oferta.Provincia_oferta][oferta.Ciudad_oferta] = 1;
      }
    }
  });

  const filtroCiudades: any = [];
  // eslint-disable-next-line
  Object.keys(provinciaCiudades).map((item: any, index: any) => {
    // eslint-disable-next-line
    Object.keys(provinciaCiudades[item]).map((item2: any, index2: any) => {
      filtroCiudades.push({
        ciudad: item2,
        numOfertas: provinciaCiudades[item][item2],
        province: item,
      });
    });
  });

  const handleResetFilters = () => {
    props.setOfertasFilters("", [], []);
  };

  if (!isMobileFiltersOpen) {
    return (
      <Fragment>
        <StyledFilterButtonWrapper>
          <StyledFilterButton
            id="mobileFilterInput"
            fullWidth
            value={formatMessage({ id: "Filtrar ofertas de trabajo" })}
            onClick={() => {
              handleMobileFiltersClick(true);
            }}
            labelWidth={0}
            startAdornment={
              <InputAdornment position="start">
                <StyledFiltersIcon src={FiltersIcon} />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <ChevronRightIcon />
              </InputAdornment>
            }
          />
        </StyledFilterButtonWrapper>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <StyledWrapper>
        <StyledOptionsWrapper>
          <OfertasFilter provinces={provinces} cities={cities} />
        </StyledOptionsWrapper>
        <StyledButtonsWrapper>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                color="secondary"
                fullWidth
                onClick={() => {
                  handleResetFilters();
                }}
              >
                <IntlMessages id="Reiniciar" />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => {
                  handleMobileFiltersClick(false);
                }}
              >
                <IntlMessages id="Aplicar Filtro" />
              </Button>
            </Grid>
          </Grid>
        </StyledButtonsWrapper>
      </StyledWrapper>
    </Fragment>
  );
};

const mapStateToProps = ({
  commonData,
  empleo,
}: {
  commonData: any;
  empleo: any;
}) => {
  const { provinces } = commonData;
  const { ofertas, filtrosBusqueda } = empleo;
  return { provinces, ofertas, filtrosBusqueda };
};

export default connect(mapStateToProps, { setOfertasFilters })(
  injectIntl<any, any>(OfertasFilters)
);
