import { IListNotificationsParams } from "../../../../../api/notification/apiListNotifications";

export const notificationKeys = {
  all: ["notifications"],
  list: (params: IListNotificationsParams) => {
    return [
      ...notificationKeys.all,
      "list",
      hashForListNotificationsParams(params),
    ];
  },
};

const hashForListNotificationsParams = (params: IListNotificationsParams) => {
  let hash = "";

  hash += params.page ?? "";
  hash += ":" + params.itemsPerPage ?? "";
  hash += ":" + params.recipient ?? "";
  hash += ":" + params.type ?? "";
  hash += ":" + params.read ?? "";

  return hash;
};
