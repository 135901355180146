import { SWITCH_LANGUAGE } from "../constants/ActionTypes";

const initialSettings = {
  locale: {
    languageId: "spanish",
    locale: "es",
    name: "Español",
    icon: "es",
  },
};

interface SettingsActionTypes {
  type: string;
  payload: any;
}

const settings = (state = initialSettings, action: SettingsActionTypes) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE":
      return {
        ...state,
        navCollapsed: false,
      };
    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload,
      };
    default:
      return state;
  }
};

export default settings;
