import {
  Chip,
  IconButton,
  Paper,
  Popover,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import "moment/locale/es";
import React, { Fragment, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import AvatarIcon from "../../../../../../assets/images/iconos/avatar.png";
import BadgeIcon from "../../../../../../assets/images/iconos/badge.png";
import CalendarIcon from "../../../../../../assets/images/iconos/calendar.png";
import ListIcon from "../../../../../../assets/images/iconos/list.png";
import LocationIcon from "../../../../../../assets/images/iconos/location.svg";
import HorarioIcon from "../../../../../../components/CuideoIcons/HorarioIcon.svg";
import InfoIcon from "../../../../../../components/CuideoIcons/Information.svg";
import MoneyIcon from "../../../../../../components/CuideoIcons/MoneyIcon.svg";
import useFormatters from "../../../../../../components/FormattedFields/useFormatters";
import cuideoTheme from "../../../../../../containers/themes/defaultTheme";
import { formatCurrency } from "../../../../../../util/formatNumbers";
import { TContractFull } from "../..";
import PublicadoStatus from "../../../../components/PublicadoStatus";
import ContractDetailedTooltipPathologies from "./ContractDetailedTooltipPathologies";
import ContractDetailedTooltipReport from "./ContractDetailedTooltipReport";

const StyledInformationButton = styled(IconButton)`
  margin: 0 0 0.75rem 0.5rem;
`;

const StyledInfoIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  display: block;
`;

const StyledPaper = styled(Paper)`
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

const StyledLeft = styled.div`
  border-right: 1px solid #004b7b;
  padding: 1rem;
  max-width: 40rem;
  min-width: 30rem;
`;

const StyledRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 20rem;
  min-width: 20rem;
`;

const StyledStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const StyledContratoData = styled.div``;

const StyledContratoDataName = styled(Typography)`
  text-align: right;
  line-height: 1;
  font-size: 0.75rem;
  color: ${cuideoTheme.palette.primary.main};
`;

const StyledContratoStatus = styled(Typography)`
  text-align: right;
  line-height: 1.25;
  font-size: 0.75rem;
  color: ${cuideoTheme.palette.primary.main};
  opacity: 0.5;
`;

const StyledTitle = styled(Typography)`
  color: ${cuideoTheme.palette.common.black};
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 700;
  margin: 0.5rem 0 0.75rem 1rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid rgba(0, 72, 120, 0.2);
`;

const StyledContratoDetails = styled.div`
  margin: 0 0 0 1rem;
`;

const StyledDateOwner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0.5rem 0.75rem;
`;

const StyledBlueData = styled.div`
  background-color: #e5edf1;
  flex-grow: 1;
`;

const StyledBiItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0.75rem 0;
`;

const StyledBiIcon = styled.img`
  margin-right: 0.5rem;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  min-width: 1.25rem;
`;

const StyledBiText = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-size: 0.875rem;
  line-height: 1.2;
  font-weight: 400;
`;

const StyledTriItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0.5rem;
`;

const StyledTriIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  margin-right: 0.5rem;
`;

const StyledTriDataWrapper = styled.div``;

const StyledTriLabel = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  opacity: 0.5;
  font-size: 0.75rem;
  font-weight: 700;
  margin: 0;
  line-height: 1.15;
`;

const StyledTriData = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  line-height: 1.15;
`;

const StyledPublishInfo = styled.div``;

const StyledTasks = styled.div`
  padding: 0.75rem 0.5rem 0.75rem 1.5rem;
  border-bottom: 1px solid ${cuideoTheme.palette.primary.main};
`;

const StyledTask = styled(Chip)`
  margin: 0.125rem;
  font-size: 0.875rem;
  height: 1.5rem;
`;

const StyledPathologies = styled.div`
  padding: 0.75rem 0.5rem 0.75rem 1.5rem;
`;

export const StyledPathology = styled(Chip)`
  margin: 0.125rem;
  font-size: 0.875rem;
  height: 1.5rem;
`;

export const StyledSubTitle = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-size: 1rem;
  font-weight: 700;
`;

const StyledItem = styled.div`
  margin-left: 1.75rem;
  font-size: 0.875rem;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  line-height: 1.15;
  color: ${cuideoTheme.palette.primary.main};
`;

const StyledLabel = styled.span`
  font-weight: 700;
`;

interface IContractDetailedTooltipProps {
  contract: TContractFull;
}

const ContractDetailedTooltip = (props: IContractDetailedTooltipProps) => {
  const { contract } = props;
  const { formatMessage } = useIntl();
  const { formatTask } = useFormatters();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  return (
    <Fragment>
      <StyledInformationButton
        color="primary"
        onClick={handleClick}
        title={formatMessage({
          id: "ContractDetailedTooltip.Ver información detallada del Contrato",
          defaultMessage: "Ver información detallada del Contrato",
        })}
      >
        <StyledInfoIcon src={InfoIcon} />
      </StyledInformationButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableRestoreFocus
      >
        <StyledPaper>
          <StyledLeft>
            <StyledStatus>
              <StyledPublishInfo>
                <PublicadoStatus contrato={contract} />
              </StyledPublishInfo>
              <StyledContratoData>
                <StyledContratoDataName>{contract.Name}</StyledContratoDataName>
                <StyledContratoStatus>
                  <FormattedMessage
                    id="ContractCard.Estado:"
                    defaultMessage="Estado:"
                  />
                  &nbsp;{contract.Estado}
                </StyledContratoStatus>
              </StyledContratoData>
            </StyledStatus>
            <StyledTitle>{contract.T_tulo}</StyledTitle>
            <StyledContratoDetails>
              <StyledBiItem>
                <StyledBiIcon src={MoneyIcon} />
                <StyledBiText>
                  {contract.Salario_Bruto_Mensual ? (
                    <Fragment>
                      {formatCurrency(contract.Salario_Bruto_Mensual)}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <FormattedMessage
                        id="ContractCard.No indicado"
                        defaultMessage="No indicado"
                      />
                    </Fragment>
                  )}
                </StyledBiText>
              </StyledBiItem>
              <StyledBiItem>
                <StyledBiIcon src={LocationIcon} />
                <StyledBiText>
                  {contract.Ciudad_oferta}, {contract.C_digo_Postal_Oferta}
                </StyledBiText>
              </StyledBiItem>
              <StyledBiItem>
                <StyledBiIcon src={HorarioIcon} />
                <StyledBiText>{contract.Horario_Servicio}</StyledBiText>
              </StyledBiItem>
              {contract.Motivo_reemplazo != null && (<StyledBiItem>
                <StyledItem>
                  <StyledLabel>
                    <FormattedMessage
                      id="ContractDetail.Motivo de reemplazo"
                      defaultMessage="Motivo de reemplazo:"
                    />
                  </StyledLabel>
                  &nbsp;
                  {contract.Motivo_reemplazo}
                </StyledItem>
              </StyledBiItem>
              )}
              <StyledBiItem>
                <StyledBiIcon src={AvatarIcon} />
                <StyledBiText>
                  {contract._Nombre_del_cliente}
                  <br />
                  {contract._Tel_fono_cliente}
                </StyledBiText>
              </StyledBiItem>
              {!!contract.Servicio?.Fecha_objetivo_entrevistas && (
                <StyledBiItem>
                  <StyledBiIcon src={CalendarIcon} />
                  <StyledBiText>
                    <FormattedMessage
                      id="ContractDetailedTooltip.Fecha cv cliente:"
                      defaultMessage="Fecha cv cliente:"
                    />
                    <br />
                    {moment(
                      contract.Servicio.Fecha_objetivo_entrevistas
                    ).format("DD/MM/YYYY")}
                  </StyledBiText>
                </StyledBiItem>
              )}
              <StyledBiItem>
                <StyledBiIcon src={ListIcon} />
                <ContractDetailedTooltipReport contract={contract} />
              </StyledBiItem>
            </StyledContratoDetails>
          </StyledLeft>
          <StyledRight>
            <StyledDateOwner>
              <StyledTriItem>
                <StyledTriIcon src={CalendarIcon} />
                <StyledTriDataWrapper>
                  <StyledTriLabel>
                    <FormattedMessage
                      id="ContractCard.Fecha objetivo"
                      defaultMessage="Fecha objetivo"
                    />
                  </StyledTriLabel>
                  <StyledTriData>
                    {moment(contract.Fecha_objetivo).format("DD/MM/YYYY")}
                  </StyledTriData>
                </StyledTriDataWrapper>
              </StyledTriItem>
              <StyledTriItem>
                <StyledTriIcon src={BadgeIcon} />
                <StyledTriDataWrapper>
                  <StyledTriLabel>
                    <FormattedMessage
                      id="ContractCard.Propietario"
                      defaultMessage="Propietario"
                    />
                  </StyledTriLabel>
                  <StyledTriData>{contract.ownerName}</StyledTriData>
                </StyledTriDataWrapper>
              </StyledTriItem>
            </StyledDateOwner>
            <StyledBlueData>
              <StyledTasks>
                <StyledSubTitle>
                  <FormattedMessage
                    id="ContractDetailedTooltip.Tareas"
                    defaultMessage="Tareas"
                  />
                </StyledSubTitle>
                {!!contract.Tareas_solicitadas &&
                  contract.Tareas_solicitadas.map((item) => (
                    <StyledTask
                      label={formatTask(item)}
                      key={item}
                      variant="outlined"
                      color="primary"
                    />
                  ))}
              </StyledTasks>
              <StyledPathologies>
                <ContractDetailedTooltipPathologies contract={contract} />
              </StyledPathologies>
            </StyledBlueData>
          </StyledRight>
        </StyledPaper>
      </Popover>
    </Fragment>
  );
};

export default ContractDetailedTooltip;
