import React, { useState, useEffect } from "react";
import queryString from "query-string";
import { apiEmpleo } from "../util/ApiEmpleo";
import {
  userSetToken,
  userSetRefreshToken,
  getLoggedUserData,
} from "../actions";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Redirect } from "react-router-dom";
import IntlMessages from "../util/IntlMessages";
import { Typography, Box } from "@material-ui/core";
import { APP_START_PAGE } from "../util/defaults/app";

const ZohoOauth2Callback = (props: any) => {
  const {
    location,
    userSetToken,
    userSetRefreshToken,
    getLoggedUserData,
    authUser,
  } = props;

  const [state, setState] = useState("unloaded");

  useEffect(() => {
    const loginRequest = async (parsed: any) => {
      if (state === "unloaded") {
        try {
          let res = await apiEmpleo.post("/auth/zoho_login_check", {
            code: parsed.code,
            "accounts-server": parsed["accounts-server"],
            location: parsed.location,
            redirectUri: window.location.origin + window.location.pathname,
            clientId: process.env.REACT_APP_ZOHO_CLIENT_ID
              ? process.env.REACT_APP_ZOHO_CLIENT_ID
              : undefined,
            clientSecret: process.env.REACT_APP_ZOHO_CLIENT_SECRET
              ? process.env.REACT_APP_ZOHO_CLIENT_SECRET
              : undefined,
          });
          if (res.token) {
            userSetToken(res.token, true);
          }
          if (res.refresh_token) {
            userSetRefreshToken(res.refresh_token, true);
          }
          // After setting tokens all will be ready asking for a who am i
          getLoggedUserData();
          setState("loaded");
        } catch (e) {
          console.log("Catched:", e);
          setState("error");
        }
      }
    };

    const parsed = queryString.parse(location.search);
    loginRequest(parsed);
    // eslint-disable-next-line
  }, [state]);

  if (authUser && state === "loaded") {
    let destPath = APP_START_PAGE;
    if (sessionStorage.getItem("pathAfterZohoLogin")) {
      destPath = sessionStorage.getItem("pathAfterZohoLogin") as string;
      sessionStorage.removeItem("pathAfterZohoLogin");
    }

    return <Redirect to={destPath} />;
  }

  return (
    <Box>
      <Typography color="primary">
        <IntlMessages id="Conectando con Zoho..." />
      </Typography>
    </Box>
  );
};

const mapStateToProps = ({ auth }: { auth: { authUser: any } }) => {
  const { authUser } = auth;

  return { authUser };
};

export default connect(mapStateToProps, {
  getLoggedUserData,
  userSetToken,
  userSetRefreshToken,
  push,
})(ZohoOauth2Callback);
