import {
  formatPhoneNumberIntl,
  parsePhoneNumber,
} from "react-phone-number-input";

const formatAsPhoneNumber = (value: string) => {
  let newValue = value;
  if (newValue) {
    const parsedPhoneNumber = parsePhoneNumber(value);

    if (parsedPhoneNumber) {
      return formatPhoneNumberIntl(newValue);
    } else {
      // Try to add '+'
      if (newValue.charAt(0) !== "+") {
        newValue = "+" + newValue;

        const parsed2 = parsePhoneNumber(newValue);
        if (parsed2) {
          // Its ok
          return formatPhoneNumberIntl(newValue);
        }
      }
    }
  }

  return value;
};

export { formatAsPhoneNumber };
