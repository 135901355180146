import { apiEmpleo } from "../../util/ApiEmpleo";

export type TGetContractBotAvailabilityResponse = {
  id: string;
  Ciudad_oferta?: string | null;
  Horario_Servicio?: string | null;
  Name?: string | null;
  formattedGrossSalary?: string | null;
  numCommunicationIntents?: number | null;
  numSuccessfulCommunicationIntents?: number | null;
  ownerName?: string | null;
};

const apiGetContractBotAvailability = async (contractId: string) => {
  let res: TGetContractBotAvailabilityResponse = await apiEmpleo.get(
    `contracts/${contractId}/bot-availability`
  );

  return res;
};

export { apiGetContractBotAvailability };
