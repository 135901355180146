import { FormControl, FormControlLabel } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { connect, getIn } from "formik";
import * as React from "react";
import styled from "styled-components";
import cuideoTheme from "../../containers/themes/defaultTheme";

const StyledFormControlLabel = styled(FormControlLabel)<{
  variant?: string;
  compact?: string;
}>`
  ${({ variant }) =>
    variant !== "smooth" &&
    `
  border: 1px solid #c2d1da;
  border-radius: ${cuideoTheme.shape.borderRadius}px;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  `}
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  height: ${(cuideoTheme.overrides as any).MuiInputBase.root.height};
  background-color: #ffffff;
  color: ${cuideoTheme.palette.text.secondary};

  &.CMui-error {
    background-color: #fef5f7;
    border-color: #f64a69;
  }

  .MuiTypography-body1 {
    font-size: 0.9rem;
    line-height: 1.1;
  }
  .MuiSvgIcon-root {
    height: 1.25rem;
    width: 1.25rem;
  }
  ${({ checked, variant }) => {
    if (checked && variant === "smooth") {
      return `
      color: ${cuideoTheme.palette.primary.main};
      & .MuiTypography-body1 {
        font-weight: 700;
      }
    `;
    }
    if (checked) {
      return `
      color: ${cuideoTheme.palette.primary.main};
      background-color: #dcf1f2;
      border: 1px solid ${cuideoTheme.palette.secondary.main};
      & .MuiTypography-body1 {
        font-weight: 700;
      }
    `;
    }
  }}

  .MuiTypography-body1 {
    font-size: 1rem;
    line-height: 1.2;
  }

  ${(props) => {
    if (props.compact) {
      return `
        height: 2.2rem;

        .MuiTypography-body1 {
          font-size: 0.9rem;
        }
        .MuiSvgIcon-root {
          height: 1rem;
          width: 1rem;
        }
      `;
    }
  }}
`;

interface Props {
  name: string;
  required?: boolean;
  variant?: "standard" | "outlined" | "filled" | "compact" | "smooth";
  label?: any;
  closeMenuOnSelect?: boolean;
  placeholder?: any;
  onChange?: any;
  options?: any;
  isMulti?: boolean;
  fullWidth?: boolean;
  checked?: boolean;
  className?: any;
  forceUpdate?: boolean;
}

interface State {}

class FormikCheckbox extends React.Component<any, State> {
  shouldComponentUpdate(nextProps: any, nextState: any) {
    const next = nextProps.formik;
    const cur = this.props.formik;
    const name = this.props.name;
    if (
      getIn(next.values, name) !== getIn(cur.values, name) ||
      getIn(next.errors, name) !== getIn(cur.errors, name) ||
      (nextProps.forceUpdate !== undefined && nextProps.forceUpdate)
    ) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    this.props.formik.registerField(this.props.name, {
      validate: this.props.validate,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.name !== prevProps.name) {
      this.props.formik.unregisterField(prevProps.name);
      this.props.formik.registerField(this.props.name, {
        validate: this.props.validate,
      });
    }

    if (this.props.validate !== prevProps.validate) {
      this.props.formik.registerField(this.props.name, {
        validate: this.props.validate,
      });
    }
  }

  componentWillUnmount() {
    this.props.formik.unregisterField(this.props.name);
  }

  render() {
    const {
      validate,
      name,
      variant,
      label,
      helperText,
      fullWidth,
      color,
      className,
      forceUpdate,
      ...others
    } = this.props;
    const formik = this.props.formik;

    const field = {
      value: getIn(formik.values, name),
      name,
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
    };

    return (
      <FormControl fullWidth>
        <StyledFormControlLabel
          control={
            <Checkbox
              {...field}
              checked={field.value}
              color={color || "primary"}
              {...others}
            />
          }
          label={label}
          checked={field.value}
          compact={variant === "compact" ? "compact" : undefined}
          variant={variant}
          className={className}
          {...others}
        />
      </FormControl>
    );
  }
}

export default connect<Props, State>(FormikCheckbox as any);
