import { useEffect } from "react";
import { changeHeader } from "../actions/Layout";
import { store } from "../store";
import { HeaderInterface } from "../reducers/Layout";

function useHeader({
  type,
  showHelpLink,
  title,
  showBackLink,
  backLinkUrl,
  backEl,
  actionEl,
}: HeaderInterface) {
  const storeState = store.getState();
  const header = storeState.layout.header;

  useEffect(() => {
    if (header.type !== type || header.showHelpLink !== showHelpLink) {
      store.dispatch(
        changeHeader({
          type,
          showHelpLink,
          title,
          showBackLink,
          backLinkUrl,
          backEl,
          actionEl,
        })
      );
    }
    // eslint-disable-next-line
  }, []);

  return null;
}

export default useHeader;
