import DateFnsUtils from "@date-io/date-fns";
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import { Formik } from "formik";
import moment from "moment";
import "moment/locale/es";
import React, { Fragment, useState } from "react";
import toast from "react-hot-toast";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import {
  modalShow,
  snackErrorMessage,
  snackSuccessMessage,
} from "../../../../actions";
import {
  apiGetEmployeeOpenProcesses,
  TGetEmployeeProcesses,
} from "../../../../api/employee/apiGetEmployeeProcesses";
import CalendarIcon from "../../../../assets/images/iconos/calendar.png";
import LocationIcon from "../../../../assets/images/iconos/location.svg";
import CuideoUserAvatar from "../../../../components/CuideoUserAvatar";
import {
  FormikCheckbox,
  FormikTextField,
} from "../../../../components/FormikFields";
import FormikDatePicker from "../../../../components/FormikFields/FormikDateTimePicker";
import { apiEmpleo } from "../../../../util/ApiEmpleo";
import {
  VALORACION_CONTRATADO,
  VALORACION_CVCLIENTE,
  VALORACION_DESCARTADO,
  VALORACION_PRESENCIAL,
  VALORACION_PRESENCIAL_CLIENTE,
  VALORACION_RECOMENDACION,
  VALORACION_SIN_VALORAR,
  VALORACION_SUGGESTION,
  VALORACION_TELEFONICA,
} from "../../../../util/defaults/valoracion";
import { TOnContractValuationChangeFn } from "../../features/contract/types/contract-valuation.types";
import { employeeIdExpiryIsValid } from "../../models/Employee/Employee";
import { TContractPagePermissions } from "../../pages/ContractPage/hooks/useContractPagePermissions";
import EmployeeInOtherProcessesDialog from "./EmployeeInOtherProcessesDialog";
import {
  StyledActions,
  StyledAddButton,
  StyledArrowButton,
  StyledAvatarWrapper,
  StyledButton,
  StyledButtonGroup,
  StyledCancelButton,
  StyledCheckingAvailabilityWrapper,
  StyledConfirmButton,
  StyledContratarDialog,
  StyledContratarHeader,
  StyledCVDialog,
  StyledCVHeader,
  StyledDialogAlertC4,
  StyledDialogContentC1,
  StyledDialogContentC2,
  StyledDialogContentCV1,
  StyledDialogContentCV2,
  StyledDialogContentCV3,
  StyledDialogContentPreCli,
  StyledIcon,
  StyledLabel,
  StyledNextStatus,
  StyledPreCliDialog,
  StyledPreCliHeader,
  StyledPreCliTypography,
} from "./ValuationPipelineButtonSC";
import { ADDED_FROM_OUTSTANDINGS } from "../../../../constants/CandidateOutstandingMessagesTypes";
import { TOnContractedValuationFn } from "../../features/valuation/types/valuation.types";

const NUM_OF_VALUATIONS_HIGH_TO_SHOW_WARNING = 50;

interface IValuationPipelineButtonProps {
  valoracion: any;
  snackSuccessMessage: any;
  disabled: boolean;
  onChange: TOnContractValuationChangeFn;
  modalShow: any;
  userPermissions: TContractPagePermissions;
  /**
   * Limit if the valuations can advance in pipeline farther than Phone
   * status.
   */
  canAdvanceMoreValuations: boolean;
  isOutstanding?: boolean;
  onContracted: TOnContractedValuationFn;
}

const ValuationPipelineButton: React.FC<IValuationPipelineButtonProps> = (
  props
) => {
  const {
    valoracion,
    disabled,
    onChange,
    modalShow,
    userPermissions,
    canAdvanceMoreValuations,
    isOutstanding = false,
    onContracted
  } = props;
  const { formatMessage } = useIntl();
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [state, setState] = useState({
    checkingEmployeeAvailability: false,
    employeeOtherOpenProcesses:
      [] as TGetEmployeeProcesses["inProcessValuations"],
    nextState: null as null | string,
  });
  const [open, setOpen] = React.useState(false);
  const [contractState, setContractState] = useState({
    open: false,
    status: "idle" as "idle" | "loading" | "error" | "success",
  });
  const [enviandoCV, setEnviandoCV] = useState(false);
  const [entrevistando, setEntrevistando] = useState(false);

  const assertCanAdvanceMoreValuations = () => {
    if (!canAdvanceMoreValuations) {
      modalShow({
        open: true,
        type: "error",
        message: formatMessage({
          id: "ValuationPipelineButton.No se pueden pasar avanzar más valoraciones. Intenta descartar alguna de las valoraciones avanzadas antes.",
          defaultMessage:
            "No se pueden pasar avanzar más valoraciones. Intenta descartar alguna de las valoraciones avanzadas antes.",
        }),
      });
      return false;
    }
    return true;
  };

  const checkEmployeeAvailability = async () => {
    setState((prevState) => ({
      ...prevState,
      checkingEmployeeAvailability: true,
      employeeOtherOpenProcesses: [],
    }));
    const res = await apiGetEmployeeOpenProcesses(valoracion.candidato.id);

    const filteredValuations = res.inProcessValuations.filter(
      (valuation) => valuation.id !== valoracion.id
    );
    setState((prevState) => ({
      ...prevState,
      checkingEmployeeAvailability: false,
    }));

    return filteredValuations;
  };

  const handleClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (valoracion.Estado_en_proceso === VALORACION_SIN_VALORAR) {
      handleToPhonePhase();
    }
    if (valoracion.Estado_en_proceso === VALORACION_DESCARTADO) {
      handleToWithoutValuation();
    }
    if (valoracion.Estado_en_proceso === VALORACION_RECOMENDACION) {
      handleToWithoutValuation(isOutstanding ? ADDED_FROM_OUTSTANDINGS : "");
    }
    if (valoracion.Estado_en_proceso === VALORACION_SUGGESTION) {
      handleToWithoutValuation();
    }
    if (valoracion.Estado_en_proceso === VALORACION_TELEFONICA) {
      handleToFaceToFace();
    }
    if (valoracion.Estado_en_proceso === VALORACION_PRESENCIAL) {
      handleToCv();
    }
    if (valoracion.Estado_en_proceso === VALORACION_CVCLIENTE) {
      handleToClientFaceToFace();
    }
    if (valoracion.Estado_en_proceso === VALORACION_PRESENCIAL_CLIENTE) {
      handleToContracted();
    }
  };

  const handleEmployeeInProcessConfirm = () => {
    if (state.nextState === VALORACION_TELEFONICA) {
      changeToPhonePhase();
    }
    if (state.nextState === VALORACION_PRESENCIAL) {
      changeToFaceToFace();
    }
    if (state.nextState === VALORACION_CVCLIENTE) {
      setEnviandoCV(true);
    }
    if (state.nextState === VALORACION_PRESENCIAL_CLIENTE) {
      setEntrevistando(true);
    }
    if (state.nextState === VALORACION_CONTRATADO) {
      setContractState((prevState) => ({
        ...prevState,
        open: true,
      }));
    }
  };

  const hangleToggle = (e: any) => {
    e.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleCloseDialog = (e: React.MouseEvent) => {
    e.stopPropagation();
    setContractState((prevState) => ({
      ...prevState,
      open: false,
    }));
    setEnviandoCV(false);
    setEntrevistando(false);
  };

  const changeToContractedStatus = async (
    valoracion: any,
    data: any,
    successMsg: any,
    errorMsg: any
  ) => {
    setContractState((prevState) => ({
      ...prevState,
      status: "loading"
    }));
    onContracted(valoracion, data, successMsg, errorMsg);
    setContractState((prevState) => ({
      ...prevState,
      open: false,
      status: "success"
    }));
  };

  const changeStatus = async (
    valoracion: any,
    data: any,
    successMsg: any,
    errorMsg: any
  ) => {
    const updatedValuation = {
      ...valoracion,
      Estado_en_proceso:
        data?.Estado_en_proceso ?? valoracion.Estado_en_proceso,
      Descripci_n: data?.Descripci_n ?? valoracion.Descripci_n,
    };
    onChange({
      op: "update_status_start",
      updatedValuation,
      refreshAfterUpdate: false,
    });
    try {
      if (valoracion.Estado_en_proceso === VALORACION_RECOMENDACION) {
        await apiEmpleo.post("valoraciones", {
          ...data,
          contrato: `/api/contratos/${valoracion.contrato.id}`,
          candidato: `/api/candidatos/${valoracion.candidato.id}`,
        });
      } else if (valoracion.Estado_en_proceso === VALORACION_SUGGESTION) {
        await apiEmpleo.patch(
          `suggestions/${valoracion.id}`,
          {
            status: "accepted",
          },
          {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          }
        );
      } else {
        await apiEmpleo.patch(`valoraciones/${valoracion.id}`, data, {
          headers: {
            "Content-Type": "application/merge-patch+json",
          },
        });
      }
      toast.success(successMsg);
      onChange({
        op: "update_status_end",
        updatedValuation,
        refreshAfterUpdate: true,
      });
    } catch (e) {
      modalShow({
        open: true,
        type: "error",
        message: errorMsg,
      });
    }
  };

  const handleConfirmContratar = (e: React.MouseEvent) => {
    e.stopPropagation();
    changeToContractedStatus(
      valoracion,
      { Estado_en_proceso: VALORACION_CONTRATADO },
      formatMessage(
        {
          id: "ValuationPipelineButton.Has movido la valoración de {candidato} a Seleccionad@",
          defaultMessage:
            "Has movido la valoración de {candidato} a Seleccionad@",
        },
        {
          candidato: `${valoracion.candidato.Nombre} ${valoracion.candidato.Apellidos}`,
        }
      ),
      formatMessage(
        {
          id: "ValuationPipelineButton.La valoración de {candidato} no ha podido ser movida a Seleccionad@",
          defaultMessage:
            "La valoración de {candidato} no ha podido ser movida a Seleccionad@",
        },
        {
          candidato: `${valoracion.candidato.Nombre} ${valoracion.candidato.Apellidos}`,
        }
      )
    );
  };

  const handleConfirmCV = (values: any) => {
    changeStatus(
      valoracion,
      {
        Estado_en_proceso: VALORACION_CVCLIENTE,
        Notas_para_correo_electronico: values.Notas_para_correo_electronico,
        Perfil_de_candidato_verificado: "Si",
      },
      formatMessage(
        {
          id: "ValuationPipelineButton.Has movido la valoración de {candidato} a CV",
          defaultMessage: "Has movido la valoración de {candidato} a CV",
        },
        {
          candidato: `${valoracion.candidato.Nombre} ${valoracion.candidato.Apellidos}`,
        }
      ),
      formatMessage(
        {
          id: "ValuationPipelineButton.No se ha podido mover la valoración de {candidato} a CV",
          defaultMessage:
            "No se ha podido mover la valoración de {candidato} a CV",
        },
        {
          candidato: `${valoracion.candidato.Nombre} ${valoracion.candidato.Apellidos}`,
        }
      )
    );
  };

  const handleConfirmPreCli = (values: any) => {
    changeStatus(
      valoracion,
      {
        Estado_en_proceso: VALORACION_PRESENCIAL_CLIENTE,
        Fecha_y_hora_entrevista: moment(
          values.Fecha_y_hora_entrevista
        ).format(),
        Lugar_de_la_entrevista: values.Lugar_de_la_entrevista,
        Enviar_email_a_cliente: values.Enviar_email_a_cliente ? "Si" : "No",
        Notas_email_entrevista: values.Notas_email_entrevista,
      },
      formatMessage(
        {
          id: "ValuationPipelineButton.Has movido la valoración de {candidato} a Presencial Cliente",
          defaultMessage:
            "Has movido la valoración de {candidato} a Presencial Cliente",
        },
        {
          candidato: `${valoracion.candidato.Nombre} ${valoracion.candidato.Apellidos}`,
        }
      ),
      formatMessage(
        {
          id: "ValuationPipelineButton.No se ha podido mover la valoración de {candidato} a Presencial Cliente",
          defaultMessage:
            "No se ha podido mover la valoración de {candidato} a Presencial Cliente",
        },
        {
          candidato: `${valoracion.candidato.Nombre} ${valoracion.candidato.Apellidos}`,
        }
      )
    );
  };

  const handleToWithoutValuation = (description?: string) => {
    changeStatus(
      valoracion,
      {
        Estado_en_proceso: VALORACION_SIN_VALORAR,
        ...(description ? { Descripci_n: description } : {}),
      },
      formatMessage(
        {
          id: "ValuationPipelineButton.Has movido la valoración de {candidato} a Sin Valorar",
          defaultMessage:
            "Has movido la valoración de {candidato} a Sin Valorar",
        },
        {
          candidato: `${valoracion.candidato.Nombre} ${valoracion.candidato.Apellidos}`,
        }
      ),
      formatMessage(
        {
          id: "ValuationPipelineButton.No se ha podido mover la valoración de {candidato} a Sin Valorar",
          defaultMessage:
            "No se ha podido mover la valoración de {candidato} a Sin Valorar",
        },
        {
          candidato: `${valoracion.candidato.Nombre} ${valoracion.candidato.Apellidos}`,
        }
      )
    );
  };

  const handleToPhonePhase = async () => {
    if (
      (valoracion.Estado_en_proceso === VALORACION_SIN_VALORAR ||
        valoracion.Estado_en_proceso === VALORACION_DESCARTADO) &&
      !assertCanAdvanceMoreValuations()
    ) {
      return;
    }
    const valuations = await checkEmployeeAvailability();
    if (valuations.length === 0) {
      changeToPhonePhase();
    } else {
      setState((prevState) => ({
        ...prevState,
        nextState: VALORACION_TELEFONICA,
        employeeOtherOpenProcesses: valuations,
      }));
    }
  };

  const changeToPhonePhase = () => {
    changeStatus(
      valoracion,
      { Estado_en_proceso: VALORACION_TELEFONICA },
      formatMessage(
        {
          id: "ValuationPipelineButton.Has movido la valoración de {candidato} a Telefonica",
          defaultMessage:
            "Has movido la valoración de {candidato} a Telefonica",
        },
        {
          candidato: `${valoracion.candidato.Nombre} ${valoracion.candidato.Apellidos}`,
        }
      ),
      formatMessage(
        {
          id: "ValuationPipelineButton.No se ha podido mover la valoración de {candidato} a Telefónica",
          defaultMessage:
            "No se ha podido mover la valoración de {candidato} a Telefónica",
        },
        {
          candidato: `${valoracion.candidato.Nombre} ${valoracion.candidato.Apellidos}`,
        }
      )
    );
  };

  const handleToFaceToFace = async () => {
    if (
      (valoracion.Estado_en_proceso === VALORACION_SIN_VALORAR ||
        valoracion.Estado_en_proceso === VALORACION_DESCARTADO) &&
      !assertCanAdvanceMoreValuations()
    ) {
      return;
    }
    const valuations = await checkEmployeeAvailability();
    if (valuations.length === 0) {
      changeToFaceToFace();
    } else {
      setState((prevState) => ({
        ...prevState,
        nextState: VALORACION_PRESENCIAL,
        employeeOtherOpenProcesses: valuations,
      }));
    }
  };

  const changeToFaceToFace = () => {
    changeStatus(
      valoracion,
      { Estado_en_proceso: VALORACION_PRESENCIAL },
      formatMessage(
        {
          id: "ValuationPipelineButton.Has movido la valoración de {candidato} a Presencial",
          defaultMessage:
            "Has movido la valoración de {candidato} a Presencial",
        },
        {
          candidato: `${valoracion.candidato.Nombre} ${valoracion.candidato.Apellidos}`,
        }
      ),
      formatMessage(
        {
          id: "ValuationPipelineButton.No se ha podido mover la valoración de {candidato} a Presencial",
          defaultMessage:
            "No se ha podido mover la valoración de {candidato} a Presencial",
        },
        {
          candidato: `${valoracion.candidato.Nombre} ${valoracion.candidato.Apellidos}`,
        }
      )
    );
  };

  const handleToCv = async () => {
    if (
      (valoracion.Estado_en_proceso === VALORACION_SIN_VALORAR ||
        valoracion.Estado_en_proceso === VALORACION_DESCARTADO) &&
      !assertCanAdvanceMoreValuations()
    ) {
      return;
    }
    const valuations = await checkEmployeeAvailability();
    if (valuations.length === 0) {
      setEnviandoCV(true);
    } else {
      setState((prevState) => ({
        ...prevState,
        nextState: VALORACION_CVCLIENTE,
        employeeOtherOpenProcesses: valuations,
      }));
    }
  };

  const handleToClientFaceToFace = async () => {
    if (
      (valoracion.Estado_en_proceso === VALORACION_SIN_VALORAR ||
        valoracion.Estado_en_proceso === VALORACION_DESCARTADO) &&
      !assertCanAdvanceMoreValuations()
    ) {
      return;
    }
    const valuations = await checkEmployeeAvailability();
    if (valuations.length === 0) {
      setEntrevistando(true);
    } else {
      setState((prevState) => ({
        ...prevState,
        nextState: VALORACION_PRESENCIAL_CLIENTE,
        employeeOtherOpenProcesses: valuations,
      }));
    }
  };

  const handleToContracted = async () => {
    if (
      (valoracion.Estado_en_proceso === VALORACION_SIN_VALORAR ||
        valoracion.Estado_en_proceso === VALORACION_DESCARTADO) &&
      !assertCanAdvanceMoreValuations()
    ) {
      return;
    }
    const valuations = await checkEmployeeAvailability();
    if (valuations.length === 0) {
      setContractState((prevState) => ({
        ...prevState,
        open: true,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        nextState: VALORACION_CONTRATADO,
        employeeOtherOpenProcesses: valuations,
      }));
    }
  };

  const handleEmployeeInProcessCancel = () => {
    setState((prevState) => ({
      ...prevState,
      checkingEmployeeAvailability: false,
      employeeOtherOpenProcesses: [],
    }));
  };

  if (valoracion.Estado_en_proceso === VALORACION_CONTRATADO) {
    return null;
  }

  const employeeHasValidIdExpiryDate = employeeIdExpiryIsValid(
    valoracion.candidato
  );

  let disabledButton = false;
  if (state.checkingEmployeeAvailability) {
    disabledButton = true;
  } else {
    if (valoracion.Estado_en_proceso === VALORACION_SIN_VALORAR) {
      if (!userPermissions.canHandleRecruitPhaseValuations) {
        disabledButton = true;
      }
      if (!userPermissions.canHandleToRecruitPhaseValuations) {
        disabledButton = true;
      }
    }
    if (valoracion.Estado_en_proceso === VALORACION_TELEFONICA) {
      if (!employeeHasValidIdExpiryDate) {
        disabledButton = true;
      }
      if (!userPermissions.canHandleRecruitPhaseValuations) {
        disabledButton = true;
      }
      if (!userPermissions.canHandleToRecruitPhaseValuations) {
        disabledButton = true;
      }
    }
    if (valoracion.Estado_en_proceso === VALORACION_PRESENCIAL) {
      if (!userPermissions.canHandlePromotePhaseValuations) {
        disabledButton = true;
      }
      if (!userPermissions.canHandleToPromotePhaseValuations) {
        disabledButton = true;
      }
    }
    if (valoracion.Estado_en_proceso === VALORACION_CVCLIENTE) {
      if (!userPermissions.canHandlePromotePhaseValuations) {
        disabledButton = true;
      }
      if (!userPermissions.canHandleToPromotePhaseValuations) {
        disabledButton = true;
      }
    }
    if (valoracion.Estado_en_proceso === VALORACION_PRESENCIAL_CLIENTE) {
      if (!userPermissions.canHandlePromotePhaseValuations) {
        disabledButton = true;
      }
      if (!userPermissions.canHandleToPromotePhaseValuations) {
        disabledButton = true;
      }
    }
  }

  return (
    <Fragment>
      <StyledButtonGroup
        variant="contained"
        ref={anchorRef}
        disabled={disabled}
        fullWidth
        estado={valoracion.Estado_en_proceso}
      >
        {valoracion.Estado_en_proceso === VALORACION_RECOMENDACION ||
        valoracion.Estado_en_proceso === VALORACION_SUGGESTION ? (
          <StyledAddButton onClick={handleClick}>
            {valoracion.alreadyAdded ? (
              <FormattedMessage
                id="ValuationPipelineButton.Ya se ha añadido"
                defaultMessage="Ya se ha añadido"
              />
            ) : (
              <FormattedMessage
                id="ValuationPipelineButton.Añadir a Contrato"
                defaultMessage="Añadir a Contrato"
              />
            )}
          </StyledAddButton>
        ) : (
          [
            <StyledButton
              key={`vpb-${valoracion.id}`}
              onClick={handleClick}
              estado={valoracion.Estado_en_proceso}
              disabled={disabledButton}
            >
              {state.checkingEmployeeAvailability ? (
                <StyledCheckingAvailabilityWrapper>
                  <CircularProgress size={18} thickness={6} />
                </StyledCheckingAvailabilityWrapper>
              ) : (
                <>
                  <StyledLabel>
                    <FormattedMessage
                      id="ValuationPipelineButton.Avanzar en pipeline"
                      defaultMessage="Avanzar en pipeline"
                    />
                  </StyledLabel>
                  <StyledNextStatus>
                    {valoracion.Estado_en_proceso ===
                      VALORACION_SIN_VALORAR && (
                      <FormattedMessage
                        id="03_Telefónica"
                        defaultMessage="Telefónica"
                      />
                    )}
                    {valoracion.Estado_en_proceso === VALORACION_DESCARTADO && (
                      <FormattedMessage
                        id="01_Sin Valorar"
                        defaultMessage="Sin Valorar"
                      />
                    )}
                    {valoracion.Estado_en_proceso === VALORACION_TELEFONICA && (
                      <FormattedMessage
                        id="04_Presencial"
                        defaultMessage="Presencial"
                      />
                    )}
                    {valoracion.Estado_en_proceso === VALORACION_PRESENCIAL && (
                      <FormattedMessage
                        id="04_CV a cliente"
                        defaultMessage="CV a Cliente"
                      />
                    )}
                    {valoracion.Estado_en_proceso === VALORACION_CVCLIENTE && (
                      <FormattedMessage
                        id="ValuationPipelineButton.Pres. Cliente"
                        defaultMessage="Pres. Cliente"
                      />
                    )}
                    {valoracion.Estado_en_proceso ===
                      VALORACION_PRESENCIAL_CLIENTE && (
                      <FormattedMessage
                        id="06_Contratado"
                        defaultMessage="Contratado"
                      />
                    )}
                  </StyledNextStatus>
                </>
              )}
            </StyledButton>,
            <StyledArrowButton
              key={`vpab-${valoracion.id}`}
              size="small"
              onClick={hangleToggle}
              estado={valoracion.Estado_en_proceso}
            >
              <ArrowDropDownIcon />
            </StyledArrowButton>,
          ]
        )}
      </StyledButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        style={{
          zIndex: 1,
        }}
      >
        {({ TransitionProps, placement }) => {
          return (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "right top" : "right bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="estados">
                    {valoracion.Estado_en_proceso !== VALORACION_SIN_VALORAR &&
                      userPermissions.canHandleToRecruitPhaseValuations && (
                        <MenuItem
                          value={VALORACION_SIN_VALORAR}
                          onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            handleToWithoutValuation();
                          }}
                        >
                          <FormattedMessage
                            id="01_Sin Valorar"
                            defaultMessage="Sin Valorar"
                          />
                        </MenuItem>
                      )}
                    {valoracion.Estado_en_proceso !== VALORACION_TELEFONICA &&
                      userPermissions.canHandleToRecruitPhaseValuations && (
                        <MenuItem
                          value={VALORACION_TELEFONICA}
                          onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            handleToPhonePhase();
                          }}
                        >
                          <FormattedMessage
                            id="03_Telefónica"
                            defaultMessage="Telefónica"
                          />
                        </MenuItem>
                      )}
                    {valoracion.Estado_en_proceso !== VALORACION_PRESENCIAL &&
                      employeeHasValidIdExpiryDate &&
                      userPermissions.canHandleToRecruitPhaseValuations &&
                      valoracion.Estado_en_proceso !==
                        VALORACION_RECOMENDACION && (
                        <MenuItem
                          value={VALORACION_PRESENCIAL}
                          onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            handleToFaceToFace();
                          }}
                        >
                          <FormattedMessage
                            id="04_Presencial"
                            defaultMessage="Presencial"
                          />
                        </MenuItem>
                      )}
                    {valoracion.Estado_en_proceso !== VALORACION_CVCLIENTE &&
                      employeeHasValidIdExpiryDate &&
                      userPermissions.canHandleToPromotePhaseValuations &&
                      valoracion.Estado_en_proceso !==
                        VALORACION_RECOMENDACION && (
                        <MenuItem
                          value={VALORACION_CVCLIENTE}
                          onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            handleToCv();
                          }}
                        >
                          <FormattedMessage
                            id="04_CV a cliente"
                            defaultMessage="CV a Cliente"
                          />
                        </MenuItem>
                      )}
                    {valoracion.Estado_en_proceso !==
                      VALORACION_PRESENCIAL_CLIENTE &&
                      employeeHasValidIdExpiryDate &&
                      userPermissions.canHandleToPromotePhaseValuations &&
                      valoracion.Estado_en_proceso !==
                        VALORACION_RECOMENDACION && (
                        <MenuItem
                          value={VALORACION_PRESENCIAL_CLIENTE}
                          onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            handleToClientFaceToFace();
                          }}
                        >
                          <FormattedMessage
                            id="05_Presencial Cliente"
                            defaultMessage="Presencial cliente"
                          />
                        </MenuItem>
                      )}
                    {valoracion.Estado_en_proceso !== VALORACION_CONTRATADO &&
                      employeeHasValidIdExpiryDate &&
                      userPermissions.canHandleToPromotePhaseValuations &&
                      valoracion.Estado_en_proceso !==
                        VALORACION_RECOMENDACION && (
                        <MenuItem
                          value={VALORACION_CONTRATADO}
                          onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            handleToContracted();
                          }}
                        >
                          <FormattedMessage
                            id="06_Contratado"
                            defaultMessage="Contratado"
                          />
                        </MenuItem>
                      )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          );
        }}
      </Popper>
      <StyledContratarDialog
        open={contractState.open}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
        }}
      >
        <StyledContratarHeader>
          <StyledAvatarWrapper>
            <CuideoUserAvatar
              user={{
                userFullName: "",
                userAvatar: valoracion.candidato.avatarUrl,
              }}
            />
          </StyledAvatarWrapper>
        </StyledContratarHeader>
        <StyledDialogContentC1>
          <FormattedMessage
            id="ValuationPipelineButton.Contratar a {nombre}"
            defaultMessage="Contratar a {nombre}"
            values={{
              nombre:
                valoracion.candidato.Nombre +
                " " +
                valoracion.candidato.Apellidos,
            }}
          />
        </StyledDialogContentC1>
        <StyledDialogContentC2>
          <FormattedMessage
            id="ValuationPipelineButton.¿Seguro que desea contratar a {nombre} para el contrato?"
            defaultMessage="¿Seguro que desea contratar a {nombre} para el contrato?"
            values={{
              nombre: (
                <b>
                  {valoracion.candidato.Nombre +
                    " " +
                    valoracion.candidato.Apellidos}
                </b>
              ),
            }}
          />
        </StyledDialogContentC2>
        <StyledDialogAlertC4 severity="warning">
          <FormattedMessage
            id="ValuationPipelineButton.El resto de valoraciones se descartarán automáticamente para liberar a los Candidatos para otros procesos. {possibleDelay}"
            defaultMessage="El resto de valoraciones se descartarán automáticamente para liberar a los Candidatos para otros procesos. {possibleDelay}"
            values={{
              possibleDelay:
                valoracion.contract?.valoraciones &&
                valoracion.contract.valoraciones.length >
                  NUM_OF_VALUATIONS_HIGH_TO_SHOW_WARNING
                  ? formatMessage({
                      id: "ValuationPipelineButton.Al haber un número importante de valoraciones es posible que confirmar esta operación lleve unos segundos.",
                      defaultMessage:
                        "Al haber un número importante de valoraciones es posible que confirmar esta operación lleve unos segundos.",
                    })
                  : "",
            }}
          />
        </StyledDialogAlertC4>
        <StyledActions>
          <StyledCancelButton
            color="primary"
            onClick={handleCloseDialog}
            disabled={contractState.status === "loading"}
          >
            <FormattedMessage
              id="ValuationPipelineButton.Cancelar"
              defaultMessage="Cancelar"
            />
          </StyledCancelButton>
          <StyledConfirmButton
            color="primary"
            variant="contained"
            onClick={handleConfirmContratar}
            disabled={contractState.status === "loading"}
            startIcon={
              contractState.status === "loading" ? (
                <CircularProgress thickness={5} size={16} />
              ) : undefined
            }
          >
            <FormattedMessage
              id="ValuationPipelineButton.Contratar"
              defaultMessage="Contratar"
            />
          </StyledConfirmButton>
        </StyledActions>
      </StyledContratarDialog>

      <StyledCVDialog
        open={enviandoCV}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
        }}
      >
        <Formik
          initialValues={{
            Notas_para_correo_electronico: "",
          }}
          onSubmit={(values: any, actions: any) => {
            handleConfirmCV(values);
          }}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <StyledCVHeader>
                <FormattedMessage
                  id="ValuationPipelineButton.Envío CV a cliente de {nombreCandidato}"
                  defaultMessage="Envío CV a cliente de {nombreCandidato}"
                  values={{
                    nombreCandidato:
                      valoracion.candidato.Nombre +
                      " " +
                      valoracion.candidato.Apellidos,
                  }}
                />
              </StyledCVHeader>
              <StyledDialogContentCV1>
                <FormattedMessage
                  id="ValuationPipelineButton.Vas a enviar la ficha pública de {nombreCandidato} al cliente a su dirección de email."
                  defaultMessage="Vas a enviar la ficha pública de {nombreCandidato} al cliente a su dirección de email."
                  values={{
                    nombreCandidato:
                      valoracion.candidato.Nombre +
                      " " +
                      valoracion.candidato.Apellidos,
                    nombreCliente:
                      valoracion.contrato &&
                      valoracion.contrato._Nombre_del_cliente
                        ? valoracion.contrato._Nombre_del_cliente
                        : undefined,
                  }}
                />
              </StyledDialogContentCV1>
              <StyledDialogContentCV2>
                <FormattedMessage
                  id="ValuationPipelineButton.Puedes añadir un comentario para facilitar más detalles al cliente sobre esta candidata:"
                  defaultMessage="Puedes añadir un comentario para facilitar más detalles al cliente sobre esta candidata:"
                />
              </StyledDialogContentCV2>
              <StyledDialogContentCV3>
                <FormikTextField
                  name="Notas_para_correo_electronico"
                  fullWidth
                  multiline
                  minRows="3"
                  maxRows="5"
                  size="small"
                  placeholder="Añade el comentario aquí"
                  margin="nomargin"
                />
              </StyledDialogContentCV3>
              <StyledActions>
                <StyledCancelButton color="primary" onClick={handleCloseDialog}>
                  <FormattedMessage
                    id="ValuationPipelineButton.Cancelar"
                    defaultMessage="Cancelar"
                  />
                </StyledCancelButton>
                <StyledConfirmButton
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  <FormattedMessage
                    id="ValuationPipelineButton.Enviar"
                    defaultMessage="Enviar"
                  />
                </StyledConfirmButton>
              </StyledActions>
            </form>
          )}
        </Formik>
      </StyledCVDialog>

      <StyledPreCliDialog
        open={entrevistando}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
        }}
      >
        <Formik
          initialValues={{
            Fecha_y_hora_entrevista: new Date(),
            Lugar_de_la_entrevista: "",
            Enviar_email_a_cliente: true,
            Notas_email_entrevista: "",
          }}
          onSubmit={(values: any, actions: any) => {
            handleConfirmPreCli(values);
          }}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <StyledPreCliHeader>
                <FormattedMessage
                  id="ValuationPipelineButton.Presencial cliente"
                  defaultMessage="Presencial cliente"
                />
              </StyledPreCliHeader>
              <StyledDialogContentPreCli>
                <StyledPreCliTypography>
                  <FormattedMessage
                    id="ValuationPipelineButton.Vas a definir una entrevista de el candidato {nombreCandidato} para que vaya a tener una entrevista con el cliente de la oferta, indica por favor hora y fecha de la entrevista."
                    defaultMessage="Vas a definir una entrevista de el candidato {nombreCandidato} para que vaya a tener una entrevista con el cliente de la oferta, indica por favor hora y fecha de la entrevista."
                    values={{
                      nombreCandidato:
                        valoracion.candidato.Nombre +
                        " " +
                        valoracion.candidato.Apellidos,
                    }}
                  />
                </StyledPreCliTypography>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <Grid container spacing={4} alignItems="center">
                    <Grid item xs={12} md={2}>
                      <StyledIcon src={CalendarIcon} />
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <FormikDatePicker
                        name="Fecha_y_hora_entrevista"
                        label={
                          <FormattedMessage
                            id="ValuationPipelineButton.Fecha y hora entrevista"
                            defaultMessage="Fecha y hora entrevista"
                          />
                        }
                        fullWidth
                        margin="normal"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
                <Grid container spacing={4} alignItems="center">
                  <Grid item xs={12} md={2}>
                    <StyledIcon src={LocationIcon} />
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <FormikTextField
                      name="Lugar_de_la_entrevista"
                      label={
                        <FormattedMessage
                          id="ValuationPipelineButton.Lugar de la entrevista:"
                          defaultMessage="Lugar de la entrevista:"
                        />
                      }
                      fullWidth
                      margin="nomargin"
                      size="small"
                    />
                  </Grid>
                </Grid>
                <Box mt={8}>
                  <Grid container spacing={4} alignItems="center">
                    <Grid item xs={12} md={2}></Grid>
                    <Grid item xs={12} md={10}>
                      <FormikCheckbox
                        name="Enviar_email_a_cliente"
                        label={
                          <FormattedMessage
                            id="ValuationPipelineButton.¿Enviar email a cliente?"
                            defaultMessage="¿Enviar email a cliente?"
                          />
                        }
                        variant="compact"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={4} alignItems="center">
                    <Grid item xs={12} md={2}></Grid>
                    <Grid item xs={12} md={10}>
                      <FormikTextField
                        name="Notas_email_entrevista"
                        label={
                          <FormattedMessage
                            id="ValuationPipelineButton.Notas email entrevista"
                            defaultMessage="Notas email entrevista"
                          />
                        }
                        fullWidth
                        multiline
                        minRows="3"
                        maxRows="5"
                        margin="nomargin"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </StyledDialogContentPreCli>

              <StyledActions>
                <StyledCancelButton color="primary" onClick={handleCloseDialog}>
                  <FormattedMessage
                    id="ValuationPipelineButton.Cancelar"
                    defaultMessage="Cancelar"
                  />
                </StyledCancelButton>
                <StyledConfirmButton
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  <FormattedMessage
                    id="ValuationPipelineButton.Enviar"
                    defaultMessage="Enviar"
                  />
                </StyledConfirmButton>
              </StyledActions>
            </form>
          )}
        </Formik>
      </StyledPreCliDialog>

      <EmployeeInOtherProcessesDialog
        open={state.employeeOtherOpenProcesses.length !== 0}
        onEmployeeInProcessCancel={handleEmployeeInProcessCancel}
        onEmployeeInProcessConfirm={handleEmployeeInProcessConfirm}
        employeeOtherProcesses={state.employeeOtherOpenProcesses}
      />
    </Fragment>
  );
};

const mapStateToProps = ({ auth }: { auth: any }) => {
  const { authUser } = auth;
  return { authUser };
};

export default connect(mapStateToProps, {
  snackSuccessMessage,
  snackErrorMessage,
  modalShow,
})(ValuationPipelineButton);
