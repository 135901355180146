import { useAuthUser } from "../../../../../reducers/hooks/useAuthUser";

export type TContractPagePermissions = {
  canPromoteContract: boolean;
  canDemoteContract: boolean;
  canHandleRecruitPhaseValuations: boolean;
  canHandlePromotePhaseValuations: boolean;
  canHandleToRecruitPhaseValuations: boolean;
  canHandleToPromotePhaseValuations: boolean;
  canConverseInContract: boolean;
};

export default function useContractPagePermissions(): TContractPagePermissions {
  const { permissions } = useAuthUser();
  return {
    canPromoteContract: permissions.canPromoteContract,
    canDemoteContract: permissions.canDemoteContract,
    canHandleRecruitPhaseValuations:
      permissions.canHandleRecruitPhaseValuations,
    canHandlePromotePhaseValuations:
      permissions.canHandlePromotePhaseValuations,
    canHandleToRecruitPhaseValuations:
      permissions.canHandleToRecruitPhaseValuations,
    canHandleToPromotePhaseValuations:
      permissions.canHandleToPromotePhaseValuations,
    canConverseInContract: permissions.canConverseInContract,
  };

  // const user = useSelector(
  //   (state: any) => state.auth.authUser as TAuthenticatedUser
  // );
  // // eslint-disable-next-line
  // const [permissions, setPermissions] = useState(() => {
  //   if (
  //     userHasRole(user, USER_ROLES.ROLE_ADMIN) ||
  //     userHasRole(user, USER_ROLES.ROLE_MANAGER)
  //   ) {
  //     return {
  //       canPromoteContract: true,
  //       canDemoteContract: true,
  //       canHandleRecruitPhaseValuations: true,
  //       canHandlePromotePhaseValuations: true,
  //       canHandleToRecruitPhaseValuations: true,
  //       canHandleToPromotePhaseValuations: true,
  //     };
  //   }

  //   if (userHasRole(user, USER_ROLES.ROLE_ES_RECRUITER)) {
  //     return {
  //       canPromoteContract: true,
  //       canDemoteContract: false,
  //       canHandleRecruitPhaseValuations: true,
  //       canHandlePromotePhaseValuations: false,
  //       canHandleToRecruitPhaseValuations: true,
  //       canHandleToPromotePhaseValuations: false,
  //     };
  //   }

  //   if (userHasRole(user, USER_ROLES.ROLE_ES_PROMOTER)) {
  //     return {
  //       canPromoteContract: false,
  //       canDemoteContract: true,
  //       canHandleRecruitPhaseValuations: false,
  //       canHandlePromotePhaseValuations: true,
  //       canHandleToRecruitPhaseValuations: false,
  //       canHandleToPromotePhaseValuations: true,
  //     };
  //   }

  //   return {
  //     canPromoteContract: true,
  //     canDemoteContract: true,
  //     canHandleRecruitPhaseValuations: true,
  //     canHandlePromotePhaseValuations: true,
  //     canHandleToRecruitPhaseValuations: true,
  //     canHandleToPromotePhaseValuations: true,
  //   };
  // });

  // return permissions;
}
