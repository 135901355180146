import { IconButton } from "@material-ui/core";
import styled from "styled-components";

export const StyledRegistroSection = styled.div`
  margin-bottom: 3rem;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;

  .CMuiEmployeeSignUpSubtitle {
    line-height: 1.35;
  }

  .CMuiFormikRadio {
    &.CMui-error {
      background-color: #fef5f7;
      border-color: #f64a69;
    }
  }
`;

export const StyledRegistroTitle = styled.h2`
  font-family: "Lora", Georgia, serif;
  font-size: 1.66rem;
  font-weight: 700;
  color: #004b7b;
  margin-top: 0;
  margin-bottom: 1rem;
`;

export const StyledHelpIconButton = styled(IconButton)`
  .MuiSvgIcon-root {
    width: 1.125rem;
    height: 1.125rem;
  }
`;
