import React from "react";

export const nl2StringArray = (str: string): string[] => {
  const breakTag = "\n";
  return str.split(breakTag);
};

export const stringWithLineBreaksToJsx = (str: string): JSX.Element => {
  const arr = nl2StringArray(str);

  if (arr.length === 1) {
    return <>{arr[0]}</>;
  }

  return (
    <>
      {arr.map((item, index) => (
        <React.Fragment key={index}>
          {item}
          <br />
        </React.Fragment>
      ))}
    </>
  );
};
