import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import InfoIcon from "@material-ui/icons/Info";
import {
  COLOR_SUCCESS,
  COLOR_SUCCESS__HOVER,
  cuideoTheme,
} from "../../../../../containers/themes/defaultTheme";
import {
  STATUS,
  TCvFollowUpStatus,
  TCvSelectedFollowUp,
} from "../models/CvFollowUp.model";
import CheckIcon from "@material-ui/icons/Check";
import classNames from "classnames";

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    max-width: 40rem;
    background-color: rgb(229, 246, 253);
  }

  .MuiDialogTitle-root {
    .MuiSvgIcon-root {
      vertical-align: top;
      margin-right: 0.25rem;
    }
  }
`;

const StyledContentText = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  line-height: 1.25;
  font-size: 1rem;
`;

const StyledActionsWrapper = styled(Grid)`
  margin: 0.5rem 0;
`;

const StyledActionButton = styled(Button)`
  text-transform: none;
  font-size: 0.875rem;
  line-height: 1.25;
  min-height: 2.75rem;

  .MuiCircularProgress-root {
    color: #999;
  }

  &.CMui-selected {
    background-color: ${cuideoTheme.palette.primary.main};
    color: ${cuideoTheme.palette.primary.contrastText};
  }
`;

const StyledDoneButton = styled(Button)`
  background-color: ${COLOR_SUCCESS};
  width: 13.75rem;
  height: 2.5rem;

  .MuiCircularProgress-root {
    color: #999;
  }

  &:hover {
    background-color: ${COLOR_SUCCESS__HOVER};
  }
`;

interface ICvFollowUpDialogProps {
  open: boolean;
  status: TCvFollowUpStatus | null;
  selectedFollowUp: TCvSelectedFollowUp | null;
  onSendEmail: () => void;
  onCallClient: () => void;
  onNotAnsweredSendEmail: () => void;
  onAlreadyTalkedWithClient: () => void;
  onFollowUpDone: () => void;
}

const CvFollowUpDialog: React.FC<ICvFollowUpDialogProps> = (props) => {
  const {
    open,
    status,
    selectedFollowUp,
    onSendEmail,
    onCallClient,
    onNotAnsweredSendEmail,
    onAlreadyTalkedWithClient,
    onFollowUpDone,
  } = props;

  return (
    <StyledDialog open={open}>
      <DialogTitle>
        <InfoIcon />
        <FormattedMessage
          id="CvFollowUpDialog.Seguimiento de CVs"
          defaultMessage="Seguimiento de CVs"
        />
      </DialogTitle>
      <DialogContent>
        <StyledContentText>
          <FormattedMessage
            id="CvFollowUpDialog.Hace 24h que el Cliente ha recibido CVs. ¿Cómo quieres hacer el seguimiento?"
            defaultMessage="Hace 24h que el Cliente ha recibido CVs. ¿Cómo quieres hacer el seguimiento?"
          />
        </StyledContentText>
        <StyledActionsWrapper
          container
          spacing={8}
          justifyContent="center"
          wrap="nowrap"
        >
          <Grid item>
            <StyledActionButton
              variant="contained"
              color="primary"
              size="small"
              onClick={onSendEmail}
              disabled={!!status || !!selectedFollowUp}
              startIcon={
                selectedFollowUp === STATUS.SEND_MAIL ? (
                  <CheckIcon />
                ) : undefined
              }
              className={classNames({
                "CMui-selected": selectedFollowUp === STATUS.SEND_MAIL,
              })}
            >
              {status === STATUS.SEND_MAIL_PENDING ? (
                <CircularProgress size={18} thickness={5} />
              ) : (
                <FormattedMessage
                  id="CvFollowUpDialog.Enviar un correo"
                  defaultMessage="Enviar un correo"
                />
              )}
            </StyledActionButton>
          </Grid>
          <Grid item>
            <StyledActionButton
              variant="contained"
              color="primary"
              size="small"
              onClick={onCallClient}
              disabled={!!status || !!selectedFollowUp}
              startIcon={
                selectedFollowUp === STATUS.CALL_CLIENT ? (
                  <CheckIcon />
                ) : undefined
              }
              className={classNames({
                "CMui-selected": selectedFollowUp === STATUS.CALL_CLIENT,
              })}
            >
              {status === STATUS.CALL_CLIENT_PENDING ? (
                <CircularProgress size={18} thickness={5} />
              ) : (
                <FormattedMessage
                  id="CvFollowUpDialog.Llamar al cliente"
                  defaultMessage="Llamar al cliente"
                />
              )}
            </StyledActionButton>
          </Grid>
          <Grid item>
            <StyledActionButton
              variant="contained"
              color="primary"
              size="small"
              onClick={onNotAnsweredSendEmail}
              disabled={!!status || !!selectedFollowUp}
              startIcon={
                selectedFollowUp === STATUS.NO_ANSWER_EMAIL ? (
                  <CheckIcon />
                ) : undefined
              }
              className={classNames({
                "CMui-selected": selectedFollowUp === STATUS.NO_ANSWER_EMAIL,
              })}
            >
              {status === STATUS.NO_ANSWER_EMAIL_PENDING ? (
                <CircularProgress size={18} thickness={5} />
              ) : (
                <FormattedMessage
                  id="CvFollowUpDialog.Le he llamado pero no contesta. Enviar un correo"
                  defaultMessage="Le he llamado pero no contesta. Enviar un correo"
                />
              )}
            </StyledActionButton>
          </Grid>
          <Grid item>
            <StyledActionButton
              variant="contained"
              color="primary"
              size="small"
              onClick={onAlreadyTalkedWithClient}
              disabled={!!status || !!selectedFollowUp}
              startIcon={
                selectedFollowUp === STATUS.ALREADY_TALKED_CLIENT ? (
                  <CheckIcon />
                ) : undefined
              }
              className={classNames({
                "CMui-selected":
                  selectedFollowUp === STATUS.ALREADY_TALKED_CLIENT,
              })}
            >
              <FormattedMessage
                id="CvFollowUpDialog.Ya he hablado con el Cliente"
                defaultMessage="Ya he hablado con el Cliente"
              />
            </StyledActionButton>
          </Grid>
        </StyledActionsWrapper>
      </DialogContent>
      <DialogActions>
        <StyledDoneButton
          variant="contained"
          color="primary"
          onClick={onFollowUpDone}
          disabled={status === STATUS.CONFIRM_PENDING || !selectedFollowUp}
        >
          {status === STATUS.CONFIRM_PENDING ? (
            <CircularProgress size={18} thickness={5} />
          ) : (
            <FormattedMessage
              id="CvFollowUpDialog.Confirmar seguimiento"
              defaultMessage="Confirmar seguimiento"
            />
          )}
        </StyledDoneButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default CvFollowUpDialog;
