import React, { useRef } from "react";
import styled from "styled-components";
import { Typography, Button } from "@material-ui/core";
import FichaCandidato from "../../../components/Candidato/EmployeeProfile/EmployeeProfile";
import cuideoTheme from "../../../containers/themes/defaultTheme";
import { FormattedMessage } from "react-intl";

const StyledWrapper = styled.div`
  margin: 1rem;
  max-width: 58.75rem;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2rem 0 0.5rem;
  justify-content: space-between;
`;

const StyledTitle = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-size: 1.875rem;
  font-weight: 700;
`;

const StyledContent = styled.div``;

const StyledTextarea = styled.textarea`
  opacity: 0;
`;

const StyledClipboardButton = styled(Button)`
  color: ${cuideoTheme.palette.primary.main};
  text-transform: none;
  font-size: 400;
`;

const FichaPublicaTab = (props: any) => {
  const { candidato } = props;

  const textAreaRef = useRef(null);

  const handleClick = (e: any) => {
    (textAreaRef.current as any).select();
    document.execCommand("copy");
    e.target.focus();
  };

  return (
    <StyledWrapper>
      <StyledHeader>
        <StyledTitle>
          <FormattedMessage
            id="PublicProfile.Title"
            defaultMessage="Ficha pública"
          />
        </StyledTitle>
        <StyledTextarea
          ref={textAreaRef}
          value={
            candidato.Business_Country === "fr"
              ? `https://emploi.cuideo.fr/fiche/${candidato.id}`
              : `https://empleo.cuideo.com/ficha/${candidato.id}`
          }
        />
        <StyledClipboardButton
          onClick={handleClick}
          variant="outlined"
          color="primary"
        >
          <FormattedMessage
            id="PublicProfile.CopyURL"
            defaultMessage="Copiar URL"
          />
        </StyledClipboardButton>
      </StyledHeader>
      <StyledContent>
        <FichaCandidato candidato={candidato} />
      </StyledContent>
    </StyledWrapper>
  );
};

export default FichaPublicaTab;
