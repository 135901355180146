import * as Sentry from "@sentry/browser";
import { useState } from "react";
import toast from "react-hot-toast";
import { useIntl } from "react-intl";
import { apiEmpleo } from "../../../../../../../util/ApiEmpleo";
import {
  CONTRACT_INDEED_ASK_FOR_PROMO,
  CONTRACT_INDEED_NO_PROMO,
} from "../../../../../../../util/defaults/contrato";
import { TContractEditModalForm } from "../ContractEditModal.types";
import { queryClient } from "../../../../../../../lib/react-query";
import { TContractFull, TContractPartial } from "../../../types/contract.types";
import moment from "moment";

type TContractEditModalSubmitState = {
  submitStatus: null | "updating" | "publishing" | "error";
  submitError: null | string;
};

export default function useContractEditModalSubmit(
  contract: TContractPartial | TContractFull,
  onChange: (payload: any) => void
) {
  const { formatMessage } = useIntl();
  const [state, setState] = useState<TContractEditModalSubmitState>({
    submitStatus: null,
    submitError: null,
  });

  const submitData = async (values: TContractEditModalForm) => {
    const newData: any = {};
    if (values.T_tulo !== contract.T_tulo) {
      newData.T_tulo = values.T_tulo;
    }
    if (values.Tipo_de_Trato?.value !== contract.Tipo_de_Trato) {
      newData.Tipo_de_Trato = values.Tipo_de_Trato?.value ?? null;
    }
    if (values.Tipo?.value !== contract.Tipo) {
      newData.Tipo = values.Tipo?.value ?? null;
    }
    if (values.Ciudad_oferta !== contract.Ciudad_oferta) {
      newData.Ciudad_oferta = values.Ciudad_oferta;
    }
    if (values.Horario_Servicio !== contract.Horario_Servicio) {
      newData.Horario_Servicio = values.Horario_Servicio;
    }
    if (values.Que_buscamos !== contract.Que_buscamos) {
      newData.Que_buscamos = values.Que_buscamos;
    }
    if (values.Requisitos !== contract.Requisitos) {
      newData.Requisitos = values.Requisitos;
    }
    if (values.Tipo_de_salario?.value !== contract.Tipo_de_salario) {
      newData.Tipo_de_salario = values.Tipo_de_salario?.value ?? null;
    }
    const salario = parseFloat(
      values.Salario_Bruto_Mensual.replace(".", "").replace(",", ".")
    );
    if (salario !== contract.Salario_Bruto_Mensual) {
      newData.Salario_Bruto_Mensual = salario;
    }
    newData.Tareas_solicitadas = [] as string[];
    values.Tareas_solicitadas.forEach((item: any) => {
      newData.Tareas_solicitadas.push(item.value);
    });
    if (values.Publicar === "Si" || values.Publicar === "No") {
      newData.Publicar = values.Publicar;
      if (values.Publicar === "Si") {
        newData.Fecha_publicacion_oferta = moment().format(
          "YYYY-MM-DD HH:mm:ss"
        );
      }
    }
    if (values.Promoci_n_Indeed) {
      newData.Promoci_n_Indeed = CONTRACT_INDEED_ASK_FOR_PROMO;
    } else {
      newData.Promoci_n_Indeed = CONTRACT_INDEED_NO_PROMO;
    }

    try {
      const submitStatus =
        values.op === "publish"
          ? "publishing"
          : values.op === "unpublish"
          ? "publishing"
          : "updating";
      setState((prevState) => ({
        ...prevState,
        submitStatus,
      }));
      await apiEmpleo.patch(`contratos/${contract.id}`, newData, {
        headers: {
          "Content-Type": "application/merge-patch+json",
        },
      });
      setState((prevState) => ({
        ...prevState,
        submitStatus: null,
      }));
      queryClient.invalidateQueries(["contracts"]);

      if (values.op === "publish") {
        toast.success(
          formatMessage(
            {
              id: "ContractEditModal.Has publicado el {contractName}",
              defaultMessage: "Has publicado el {contractName}",
            },
            { contractName: contract.Name ?? "Contrato" }
          )
        );
      }
      if (values.op === "unpublish") {
        toast.success(
          formatMessage(
            {
              id: "ContractEditModal.Has despublicado el {contractName}",
              defaultMessage: "Has despublicado el {contractName}",
            },
            { contractName: contract.Name ?? "Contrato" }
          )
        );
      }
      if (values.op === "save") {
        toast.success(
          formatMessage(
            {
              id: "ContractEditModal.Has actualizado los datos del {contractName}",
              defaultMessage: "Has actualizado los datos del {contractName}",
            },
            { contractName: contract.Name ?? "Contrato" }
          )
        );
      }
    } catch (e) {
      console.error(
        "Error trying to update contract information from edit modal: ",
        e
      );
      Sentry.captureException(e);

      setState((prevState) => ({
        ...prevState,
        submitStatus: "error",
        submitError: formatMessage({
          id: "ContractEditModal.Ha habido un error actualizando los datos",
          defaultMessage: "Ha habido un error actualizando los datos",
        }),
      }));

      throw e;
    }
  };

  return {
    submitStatus: state.submitStatus,
    submitError: state.submitError,
    submitData,
  };
}
