import { useQuery } from "react-query";
import { apiListMessages } from "../../../../../api/message/apiListMessages";
import { TGetMessagesCollectionItem } from "../../../../../api/message/message.types";
import { MESSAGE_STATUS } from "../../../../../api/waMessage/waMessage.types";
import {
  messageShouldHaveBottomMargin,
  messageShouldShowAuthor,
  TMessage,
} from "../models/Message";

const REFETCH_INTERVAL_IN_MS = 15000;

const convertMessagesCollectionItemsToMessages = (
  messages: TGetMessagesCollectionItem[],
  userId: string
): TMessage[] => {
  const convertedMessages: TMessage[] = [];

  for (let i = 0; i < messages.length; i++) {
    const direction = userId === messages[i].createdBy?.id ? "out" : "in";

    const newMessage: TMessage = {
      ...messages[i],
      direction,
      showAuthor: messageShouldShowAuthor(
        messages[i],
        i === 0 ? null : messages[i - 1]
      ),
      marginBottom: messageShouldHaveBottomMargin(
        messages[i],
        i === messages.length ? null : messages[i + 1]
      ),
    };

    convertedMessages.push(newMessage);
  }

  return convertedMessages;
};

export default function useMessagesQuery(
  parentId: string,
  module: string,
  userId: string
) {
  return useQuery(
    ["messages", "list", module, parentId, userId],
    async () => {
      const res = await apiListMessages({
        module: [module],
        parentId: [parentId],
        itemsPerPage: 200,
        "order[createdAt]": "desc",
      });

      const messages = convertMessagesCollectionItemsToMessages(
        res["hydra:member"].reverse(),
        userId
      );

      const numMyUnreadMessages = messages.reduce(
        (prevValue, currentMessage) => {
          if (
            currentMessage.status !== MESSAGE_STATUS.READ &&
            currentMessage.createdBy?.id !== userId
          ) {
            return prevValue + 1;
          }
          return prevValue;
        },
        0
      );

      return {
        messages,
        totalItems: res["hydra:totalItems"],
        numUnreadMessages: numMyUnreadMessages,
      };
    },
    {
      enabled: true,
      retry: 0,
      refetchInterval: REFETCH_INTERVAL_IN_MS,
    }
  );
}
