import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { snackSuccessMessage, snackErrorMessage } from "../../actions";

export default function useSnacks() {
  const dispatch = useDispatch();
  const successMessage = useCallback(
    (message: string) => {
      dispatch(snackSuccessMessage(message));
    },
    [dispatch]
  );

  const errorMessage = useCallback(
    (message: string) => {
      dispatch(snackErrorMessage(message));
    },
    [dispatch]
  );

  return {
    snackSuccessMessage: successMessage,
    snackErrorMessage: errorMessage,
  };
}
