import { useMutation } from "react-query";
import { USER_ROLES } from "../../../../../api/security";
import { TStaffUserResponse } from "../../../../../api/user/apiListStaffUsers";
import {
  apiUpdateUserOnShift,
  TApiUpdateUserOnShiftPayload,
} from "../../../../../api/user/apiUpdateUserOnShift";
import { MutationConfig, queryClient } from "../../../../../lib/react-query";
import { userKeys } from "../util/user.query-keys";

type TUseUpdateUserOnShift = {
  config?: MutationConfig<typeof apiUpdateUserOnShift>;
};

export const useUpdateUserOnShift = ({
  config,
}: TUseUpdateUserOnShift = {}) => {
  return useMutation({
    onMutate: async (payload: TApiUpdateUserOnShiftPayload) => {
      await queryClient.cancelQueries(userKeys.all);

      const previousData: TStaffUserResponse | undefined =
        queryClient.getQueryData(userKeys.item(payload.userId));

      if (!previousData) {
        return undefined;
      }

      const newRoles = payload.onShift
        ? [...previousData.roles, USER_ROLES.ROLE_ES_ON_SHIFT]
        : previousData.roles.filter(
            (role) => role !== USER_ROLES.ROLE_ES_ON_SHIFT
          );
      queryClient.setQueryData(userKeys.item(payload.userId), {
        ...previousData,
        roles: newRoles,
      });

      return () => {
        queryClient.setQueryData(userKeys.item(payload.userId), previousData);
      };
    },
    onError: (err, payload, rollback: any) => {
      rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries(userKeys.all);
    },
    ...config,
    mutationFn: apiUpdateUserOnShift,
  });
};
