import { Button, Paper } from "@material-ui/core";
import styled from "styled-components";

export const StyledFiltersButton = styled(Button)`
  text-transform: none;
  font-weight: 400;
  height: 2rem;
  padding: 0 0.875rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .CMuiFiltersButtonTextContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .MuiTypography-root {
    font-size: 0.9375rem;
    line-height: 1;
    padding: 0 0.25rem;
  }
  .MuiSvgIcon-root {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

export const StyledPopover = styled(Paper)`
  overflow: hidden;
  min-width: 48rem;
  max-width: 48rem;

  .CMuiFiltersPaperActions {
    padding: 0.125rem 0.25rem;
    background-color: #cdd5d8;
    border-bottom: 1px solid rgba(0, 72, 120, 0.25);
  }

  .CMuiFiltersPaperActionsTitle {
    font-size: 1rem;
    font-weight: 600;
  }

  .CMuiFiltersPaperApplyButton,
  .CMuiFiltersPaperResetButton,
  .CMuiFiltersPaperCancelButton {
    font-size: 0.875rem;
    text-transform: none;
    padding: 0.125rem 0.5rem;
  }

  .CMuiFiltersPaperContent {
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }
  .CMuiFiltersPaperContentLeft,
  .CMuiFiltersPaperContentMiddle,
  .CMuiFiltersPaperContentRight {
    width: 33.33333%;
    max-width: 33.33333%;
    min-width: 33.33333%;
  }
  .CMuiFiltersPaperContentLeft,
  .CMuiFiltersPaperContentMiddle {
    border-right: 1px solid rgba(0, 72, 120, 0.25);
  }
  .CMuiFiltersPaperFilterHead {
    padding: 0.25rem 0.5rem;
    border-bottom: 1px solid rgba(0, 72, 120, 0.25);
    cursor: pointer;
  }
  .CMuiFiltersPaperFilterHeadBall {
    width: 1rem;
    height: 1rem;
    background-color: rgba(0, 72, 120, 0.1);
    border-radius: 50%;
    transition: background-color 0.1s ease;

    &.CMuiFiltersPaperFilterHeadBall--checked {
      background-color: rgba(0, 72, 120, 1);
    }
  }

  .CMuiFiltersPaperFilterArrow {
    color: #bbb;
    transition: transform 0.1s ease;

    &.CMuiFiltersPaperFilterArrow--up {
      transform: rotate(180deg);
    }
  }

  .CMuiFiltersPaperFilterLabel {
    font-size: 0.9375rem;
    font-weight: 600;
  }

  .CMuiFiltersPaperFilterContent {
    border-bottom: 1px solid rgba(0, 72, 120, 0.25);
    max-height: 10rem;
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 0.5rem;
    }
    ::-webkit-scrollbar-track {
      background: rgba(0, 72, 120, 0.07);
    }
    ::-webkit-scrollbar-thumb {
      background: rgba(0, 72, 120, 0.25);
      width: 0.5rem;
      border-radius: 0.25rem;
    }
  }
  .CMuiFiltersPaperFilterOption {
    background-color: rgba(0, 72, 120, 0.07);
    display: block;
    padding: 0.125rem 0.5rem;
  }
  .CMuiFiltersPaperFilterOptionControl {
    .MuiCheckbox-root {
      padding: 0.25rem 0.5rem;
    }
    .MuiFormControlLabel-label {
      font-size: 0.875rem;
    }
    .MuiSvgIcon-root {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
  .CMuiFiltersPaperFilterSelect {
    height: 1.75rem;
    font-size: 0.875rem;
    background-color: #fff;
    margin: 0.25rem 0;

    .MuiSelect-select {
      padding: 0.5rem 2rem 0.5rem 0.75rem;
    }
  }
`;
