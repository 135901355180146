import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { cuideoTheme } from "../../../../../../containers/themes/defaultTheme";
import { TContractFull } from "../../types/contract.types";
import { contractIsExpress } from "../../util/contract.util";

const StyledExpressTag = styled.span`
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-size: 0.75rem;
  color: ${cuideoTheme.palette.primary.main};
`;

interface IExpressTagProps {
  contract: TContractFull;
}

const ExpressTag: React.FC<IExpressTagProps> = ({ contract }) => {
  if (contractIsExpress(contract)) {
    return (
      <StyledExpressTag>
        <FormattedMessage id="ExpressTag.Express" defaultMessage="🚀 Express" />
      </StyledExpressTag>
    );
  }

  return null;
};

export default ExpressTag;
