import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  filtersSetTransfer,
  getStaff,
  getStaffSuccess,
} from "../../../actions";
import CuideoSearchField from "../../../components/CuideoFields/CuideoSearchField";
import cuideoTheme from "../../../containers/themes/defaultTheme";
import {
  CONTRATO_ESTADO_ACTIVO,
  CONTRATO_ESTADO_ALTA_PENDIENTE,
  CONTRATO_ESTADO_CANCELADO_PERDIDO_SELECCION,
  CONTRATO_ESTADO_CV_CLIENTE,
  CONTRATO_ESTADO_EN_ESPERA,
  CONTRATO_ESTADO_FIN_DE_CONTRATO,
  CONTRATO_ESTADO_PAGA_Y_RESERVA,
  CONTRATO_ESTADO_PRESENCIAL_CLIENTE,
  TIPO_TRATO_EXTERNA_MAS_20_HORAS,
  TIPO_TRATO_EXTERNA_MAS_20_HORAS_SIN_SUST,
  TIPO_TRATO_EXTERNA_MENOS_20_HORAS,
  TIPO_TRATO_EXTERNA_MENOS_20_HORAS_SIN_SUST,
  TIPO_TRATO_INTERNA_ENTRE_SEMANA,
  TIPO_TRATO_INTERNA_ENTRE_SEMANA_SIN_SUST,
  TIPO_TRATO_INTERNA_FIN_DE_SEMANA,
  TIPO_TRATO_INTERNA_FIN_DE_SEMANA_SIN_SUST,
} from "../../../util/defaults/contrato";
import useDebounce from "../../../util/useDebounce";
import FilterButton from "./FilterButton";

const StyledWrapper = styled.div`
  background-color: ${cuideoTheme.palette.common.white};
  padding: 0.5rem 1rem;
`;

const StyledContainer = styled.div`
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledButtonWrapper = styled.div`
  margin: 0 0 0 0.5rem;
`;

const StyledLeft = styled.div``;

const StyledRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledSearchWrapper = styled.div`
  .Mui-CuideoSearch {
    height: 1.75rem;
    background-color: transparent;
    border: 1px solid #ccc;
  }
`;

const TransferFilters = (props: any) => {
  const { transferFilters, filtersSetTransfer, staff, getStaff, authUser } =
    props;
  const [searchTerm, setSearchTerm] = useState("");

  const handleApply = (filterName: string, value: any) => {
    const newFilters = { ...transferFilters, [filterName]: value };
    filtersSetTransfer(newFilters);
  };

  const handleReset = (filterName: string) => {
    const newFilters = { ...transferFilters, [filterName]: "" };
    filtersSetTransfer(newFilters);
  };

  const handleSearchChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    // Load staff members
    if (staff.length === 0) {
      getStaff({ params: { itemsPerPage: 200 } });
    }
    // eslint-disable-next-line
  }, []);

  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  useEffect(() => {
    const newFilters = { ...transferFilters, search: searchTerm };
    if (transferFilters.owner === "owner") {
      const found = staff.find((item: any) => {
        return item.id === authUser.userId;
      });
      if (found) {
        newFilters.owner = found.id;
      } else {
        newFilters.owner = "";
      }
    }
    filtersSetTransfer(newFilters);
    // eslint-disable-next-line
  }, [debouncedSearchTerm, staff, filtersSetTransfer]);

  const staffOptions = staff.map((item: any) => {
    return {
      value: item.id,
      label: item.fullname,
    };
  });

  return (
    <StyledWrapper>
      <StyledContainer>
        <StyledLeft>
          <StyledSearchWrapper>
            <CuideoSearchField
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Buscar por id o nombre"
            />
          </StyledSearchWrapper>
        </StyledLeft>
        <StyledRight>
          <StyledButtonWrapper>
            <FilterButton
              defaultButtonText={
                <FormattedMessage
                  id="TransferFilters.Status"
                  defaultMessage="Estado"
                />
              }
              options={[
                {
                  value: CONTRATO_ESTADO_ALTA_PENDIENTE,
                  label: CONTRATO_ESTADO_ALTA_PENDIENTE,
                },
                {
                  value: CONTRATO_ESTADO_CV_CLIENTE,
                  label: CONTRATO_ESTADO_CV_CLIENTE,
                },
                {
                  value: CONTRATO_ESTADO_PRESENCIAL_CLIENTE,
                  label: CONTRATO_ESTADO_PRESENCIAL_CLIENTE,
                },
                {
                  value: CONTRATO_ESTADO_EN_ESPERA,
                  label: CONTRATO_ESTADO_EN_ESPERA,
                },
                {
                  value: CONTRATO_ESTADO_PAGA_Y_RESERVA,
                  label: CONTRATO_ESTADO_PAGA_Y_RESERVA,
                },
                {
                  value: CONTRATO_ESTADO_ACTIVO,
                  label: CONTRATO_ESTADO_ACTIVO,
                },
                {
                  value: CONTRATO_ESTADO_FIN_DE_CONTRATO,
                  label: CONTRATO_ESTADO_FIN_DE_CONTRATO,
                },
                {
                  value: CONTRATO_ESTADO_CANCELADO_PERDIDO_SELECCION,
                  label: CONTRATO_ESTADO_CANCELADO_PERDIDO_SELECCION,
                },
              ]}
              initialValue={transferFilters.estado}
              multi
              multiButtonText={
                <FormattedMessage
                  id="TransferFilters.VariousStates"
                  defaultMessage="Varios estados"
                />
              }
              handleApply={handleApply}
              handleReset={handleReset}
              filterName="estado"
              inUse={transferFilters.estado.length}
            />
          </StyledButtonWrapper>
          <StyledButtonWrapper>
            <FilterButton
              defaultButtonText={
                <FormattedMessage
                  id="TransferFilters.Type"
                  defaultMessage="Tipología"
                />
              }
              options={[
                {
                  value: TIPO_TRATO_INTERNA_ENTRE_SEMANA,
                  label: TIPO_TRATO_INTERNA_ENTRE_SEMANA,
                },
                {
                  value: TIPO_TRATO_INTERNA_FIN_DE_SEMANA,
                  label: TIPO_TRATO_INTERNA_FIN_DE_SEMANA,
                },
                {
                  value: TIPO_TRATO_EXTERNA_MAS_20_HORAS,
                  label: TIPO_TRATO_EXTERNA_MAS_20_HORAS,
                },
                {
                  value: TIPO_TRATO_EXTERNA_MENOS_20_HORAS,
                  label: TIPO_TRATO_EXTERNA_MENOS_20_HORAS,
                },
                {
                  value: TIPO_TRATO_INTERNA_ENTRE_SEMANA_SIN_SUST,
                  label: TIPO_TRATO_INTERNA_ENTRE_SEMANA_SIN_SUST,
                },
                {
                  value: TIPO_TRATO_INTERNA_FIN_DE_SEMANA_SIN_SUST,
                  label: TIPO_TRATO_INTERNA_FIN_DE_SEMANA_SIN_SUST,
                },
                {
                  value: TIPO_TRATO_EXTERNA_MAS_20_HORAS_SIN_SUST,
                  label: TIPO_TRATO_EXTERNA_MAS_20_HORAS_SIN_SUST,
                },
                {
                  value: TIPO_TRATO_EXTERNA_MENOS_20_HORAS_SIN_SUST,
                  label: TIPO_TRATO_EXTERNA_MENOS_20_HORAS_SIN_SUST,
                },
              ]}
              initialValue={transferFilters.tipoDeTrato}
              multi
              multiButtonText={
                <FormattedMessage
                  id="TransferFilters.VariousTypes"
                  defaultMessage="Varios tipos"
                />
              }
              handleApply={handleApply}
              handleReset={handleReset}
              filterName="tipoDeTrato"
              inUse={transferFilters.tipoDeTrato.length}
            />
          </StyledButtonWrapper>
          <StyledButtonWrapper>
            <FilterButton
              defaultButtonText={
                <FormattedMessage
                  id="TransferFilters.Owner"
                  defaultMessage="Propietario"
                />
              }
              options={staffOptions}
              initialValue={transferFilters.owner}
              handleApply={handleApply}
              handleReset={handleReset}
              filterName="owner"
              inUse={transferFilters.owner !== ""}
            />
          </StyledButtonWrapper>
        </StyledRight>
      </StyledContainer>
    </StyledWrapper>
  );
};

const mapStateToProps = ({
  layout,
  filters,
  commonData,
  usuariosState,
  auth,
}: {
  layout: any;
  filters: any;
  commonData: any;
  usuariosState: any;
  auth: any;
}) => {
  const { appMarginTop } = layout;
  const { transferFilters } = filters;
  const { provinciasOptions } = commonData;
  const { staff } = usuariosState;
  const { authUser } = auth;
  return { appMarginTop, transferFilters, provinciasOptions, staff, authUser };
};

export default connect(mapStateToProps, {
  filtersSetTransfer,
  getStaff,
  getStaffSuccess,
})(TransferFilters);
