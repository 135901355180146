import { Button, IconButton, Typography } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Alert } from "@material-ui/lab";
import { Variants } from "framer-motion";
import { Link } from "react-router-dom";
import styled from "styled-components";
import cuideoTheme, {
  COLOR_SUCCESS,
} from "../../../../containers/themes/defaultTheme";

export const StyledWrapper = styled.div`
  margin: 1rem 1rem 0;
`;

export const StyledField = styled.div`
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;

  &:last-child {
    border-bottom: none;
  }

  .CMuiCoordinatorFileTask {
    .MuiFormControlLabel-label {
      line-height: 1;
    }
  }
  .CMuiCoordinatorFilePathology {
    .MuiFormControlLabel-label {
      line-height: 1;
    }
  }
`;

export const StyledLabel = styled(Typography)`
  color: ${cuideoTheme.palette.primary.light};
  font-size: 0.8rem;
  white-space: wrap;
  padding: 0.2rem 0 0.2rem 0;

  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 0.8rem;
  }
`;

export const StyledSectionHeader = styled(Typography)`
  font-size: 1.875rem;
  font-weight: 700;
  color: ${cuideoTheme.palette.primary.main};
  border-bottom: 1px solid ${cuideoTheme.palette.grey[300]};
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
`;

export const StyledHeaderWithButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const StyledGroup = styled.div`
  padding: 1rem 0;
  max-width: 100%;
`;

export const StyledActionsContainer = styled.div`
  position: fixed;
  z-index: 9;
  top: 4rem;
  right: 0;
  width: calc(100% - 18.5rem);
`;

export const StyledActions = styled.div`
  background-color: ${cuideoTheme.palette.common.white};
  padding: 0.75rem 1rem 0.5rem;
  border-left: 3px solid #c2d1da;
  border-bottom: 3px solid #c2d1da;
`;

export const StyledActionsContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledActionsContentLeft = styled.div``;

export const StyledBackLink = styled(Link)`
  text-decoration: none;
`;

export const StyledActionsContentMiddle = styled.div`
  flex-grow: 1;
`;

export const StyledActionsContentRight = styled.div``;

export const StyledSaveButton = styled(Button)``;

export const StyledTopSaveButton = styled(Button)`
  min-width: 10rem;
  min-height: 2.5rem;

  .MuiCircularProgress-root {
    color: #999;
  }
`;

export const BackIconButton = styled(IconButton)``;

export const StyledProfileName = styled(Typography)`
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.25;
  color: ${cuideoTheme.palette.primary.main};
`;

export const StyledAvatarIcon = styled(AccountCircleIcon)`
  width: 2.5rem;
  height: 2.5rem;
`;

export const StyledAvatarWrapper = styled.div``;

export const StyledUserImage = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  padding: 5%;
`;

export const StyledProfileId = styled(Typography)`
  font-size: 0.875rem;
  opacity: 0.5;
  color: ${cuideoTheme.palette.primary.main};
`;

export const headerVariants: Variants = {
  enter: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.25 },
  },
  exit: {
    opacity: 0,
    y: -50,
  },
};

export const profileVariants: Variants = {
  enter: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.25 },
  },
  exit: {
    opacity: 0,
    y: 30,
  },
};

export const StyledUserInfoBox = styled.div`
  border: 1px solid rgba(0, 72, 120, 0.25);
  border-radius: ${cuideoTheme.shape.borderRadius}px;
  padding: 1rem;
`;

export const StyledUserInfoTitle = styled(Typography)`
  font-size: 1.25rem;
  color: ${cuideoTheme.palette.primary.main};
  border-bottom: 1px solid ${cuideoTheme.palette.grey[300]};
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
`;

export const StyledUserInfoSubtitle = styled(Typography)`
  font-size: 0.875rem;
  color: ${cuideoTheme.palette.primary.main};
  margin: 0.25rem 0;
  padding: 0.25rem 0;
`;

export const StyledUserInfoField = styled.div``;

export const StyledUserInfoLabel = styled(Typography)`
  font-size: 0.875rem;
  color: ${cuideoTheme.palette.primary.main};
  font-weight: 600;
`;

export const StyledUserInfoData = styled(Typography)`
  font-size: 0.875rem;
  color: ${cuideoTheme.palette.primary.main};
`;

export const StyledAdvancedDetailsContainer = styled.div`
  max-width: 30rem;
`;

export const StyledHelper = styled(Typography)`
  margin: 0.25rem 0;
  color: ${cuideoTheme.palette.primary.main};
  font-size: 0.75rem;
  font-style: italic;
`;

export const StyledIdExpiryWrapper = styled.div`
  &.CMui-invalid {
    padding: 0.5rem 1rem;
    background-color: rgb(255, 244, 229);
  }
`;

export const StyledExpiryAlert = styled(Alert)`
  padding: 0;
  font-size: 0.875rem;
`;

export const StyledPhoneWrapper = styled.div`
  position: relative;
  width: 100%;

  .CMuiPhoneWaStatusTag {
    position: absolute;
    top: 0.125rem;
    right: 0;
  }
`;

export const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  color: ${COLOR_SUCCESS};
  width: 1rem;
  height: 1rem;
  margin: 0.125rem 0 -0.125rem 0;
`;
