import { MODAL_SHOW, MODAL_CLOSE, MODAL_RESET } from "../constants/ActionTypes";

export interface ModalInterface {
  open: boolean;
  type: "error" | "error_fixed_message" | "info" | "success" | null;
  message: string;
  variant?: "user_expired" | null;
  context?: any;
}

const initialModal = {
  open: false,
  type: null,
  message: "",
  variant: null
};

const modal = (
  state = initialModal,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case MODAL_SHOW:
      return {
        ...state,
        open: action.payload.open,
        type: action.payload.type,
        message: action.payload.message,
        variant: action.payload.variant ? action.payload.variant : null,
        context: action.payload.context ? action.payload.context : null
      };
    case MODAL_CLOSE:
      return {
        ...state,
        open: false
      };
    case MODAL_RESET:
      return {
        ...initialModal
      };
    default:
      return state;
  }
};

export default modal;
