import { IApiListContractsManagerInfoParams } from "../../../../../api/contract/apiListAllContractsManagerInfo";
import { IApiListContractsParams } from "../../../../../api/contract/apiListContracts";
import { IApiListContractsNumbersParams } from "../../../../../api/contract/apiListContractsNumbers";
import {
  TContractsPageFilters,
  TContractsPresetFilterValue,
} from "../routes/ContractsPage/hooks/useContractsPageURLParams";

export const contractKeys = {
  all: ["contracts"] as const,
  list: (params: IApiListContractsParams) => {
    return [...contractKeys.all, "list", hashForList(params)];
  },
  listForContractsPage: (filters: TContractsPageFilters) => {
    return [...contractKeys.all, "list", filters] as const;
  },
  numForContractsPage: (
    filters: TContractsPageFilters,
    preset: TContractsPresetFilterValue
  ) => {
    const filtered: Omit<TContractsPageFilters, "page" | "orden"> & {
      page?: number;
      orden?: string;
    } = {
      ...filters,
      preset,
    };
    if (typeof filtered.page !== "undefined") {
      delete filtered.page;
    }
    if (typeof filtered.orden !== "undefined") {
      delete filtered.orden;
    }

    return [...contractKeys.all, "num", filtered] as const;
  },
  item: (contractId: string) => ["contracts", "item", contractId],
  itemOffer: (contractId: string) => [
    ...contractKeys.all,
    "offer",
    "item",
    contractId,
  ],
  listInfo: (params: IApiListContractsManagerInfoParams) => [
    ...contractKeys.all,
    "info",
    hashForInfoParams(params),
  ],
  numbersList: (params: IApiListContractsNumbersParams) => [
    ...contractKeys.all,
    "list-numbers",
    hashForListNumbersParams(params),
  ],
};

const hashForInfoParams = (params: IApiListContractsManagerInfoParams) => {
  let hash = "";

  if (params.estado?.length !== 0) {
    hash += params.estado?.join(",");
  }

  return hash;
};

const hashForList = (params: IApiListContractsParams) => {
  let hash = "";
  hash += params.page ?? "";
  hash += ":" + params.itemsPerPage ?? "";
  hash += ":" + params.partial ?? "";
  hash += ":" + params.publicar ?? "";
  hash += ":" + params.tipoDeTrato ?? "";
  hash += ":" + params.origen ?? "";
  hash += ":" + params.estado ?? "";
  hash += ":" + params.provinciaOferta ?? "";
  hash += ":" + params.owner ?? "";
  hash += ":" + params["order[createdAt]"] ?? "";
  hash += ":" + params["order[priority]"] ?? "";
  hash += ":" + params["order[fechaObjetivo]"] ?? "";
  hash += ":" + params["order[cvDate]"] ?? "";
  hash += ":" + params["order[squad]"] ?? "";
  hash += ":" + params["order[recruitPriority]"] ?? "";

  return hash;
};

const hashForListNumbersParams = (params: IApiListContractsNumbersParams) => {
  let hash = "";
  hash += params.page ?? "";
  hash += ":" + params.itemsPerPage ?? "";
  hash += params.id.join(",");

  return hash;
};
