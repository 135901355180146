import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import {
  COORDINATION_ROLE,
  TCoordinationRole,
  TStaffUserResponse,
} from "../../../../../../../../api/user/apiListStaffUsers";
import SelectStyledWrapper from "../SelectStyledWrapper";
import PersonalCareImg from "../../../../../../../../assets/images/personal-care.svg";
import { staffUserIsPromoter } from "../../../../../../../../api/user/user.types";

const StyledDialog = styled(Dialog)``;

const StyledText = styled(Typography)`
  font-size: 0.9375rem;
  line-height: 1.35;
  margin-bottom: 0.5rem;
`;

const StyledContentWithImage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1rem 0 1rem;
`;

const StyledImage = styled.img`
  height: 5rem;
  width: auto;
`;

const StyledRight = styled.div``;

const StyledConfirmButton = styled(Button)`
  width: 7.25rem;
  height: 2.5rem;

  .MuiCircularProgress-root {
    color: #999;
  }
`;

interface IRoleSelectorProps {
  currentStaffUser: TStaffUserResponse;
}

const RoleSelector: React.FC<IRoleSelectorProps> = (props) => {
  const { currentStaffUser } = props;
  const [state, setState] = useState({
    dialogOpen: false,
    newRole: null as TCoordinationRole | null,
    inProcess: false,
  });
  const { formatMessage } = useIntl();

  const options = [
    {
      value: COORDINATION_ROLE.COORDINATOR,
      label: "Coordinador",
    },
    {
      value: COORDINATION_ROLE.PROMOTER,
      label: "Promoter",
    },
    {
      value: COORDINATION_ROLE.RECRUITER,
      label: "Recruiter",
    },
  ];
  const currentOption = staffUserIsPromoter(currentStaffUser)
    ? {
        value: COORDINATION_ROLE.PROMOTER,
        label: "Rol: Promoter",
      }
    : {
        value: COORDINATION_ROLE.COORDINATOR,
        label: "Rol: Coordinador",
      };

  const handleRoleChange = () => {};

  const handleConfirmGroupChange = () => {
    // if (null === state.newGroup) {
    //   return;
    // }
    // setState((prevState) => ({ ...prevState, inProcess: true }));
    // let newGroup = state.newGroup;
    // onGroupChange(newGroup, currentStaffUser.id);
    // setState((prevState) => ({
    //   ...prevState,
    //   inProcess: false,
    //   dialogOpen: false,
    // }));
  };

  const handleCancelDialog = () => {
    setState((prevState) => ({
      ...prevState,
      dialogOpen: false,
    }));
  };

  return (
    <>
      <SelectStyledWrapper
        options={options}
        value={currentOption}
        onChange={handleRoleChange}
        placeholder={formatMessage({
          id: "RoleSelector.Selecciona rol...",
          defaultMessage: "Selecciona rol",
        })}
        isDisabled
      />
      <StyledDialog open={state.dialogOpen}>
        <DialogTitle>
          <FormattedMessage
            id="RoleSelector.Confirmar cambio de rol"
            defaultMessage="Confirmar cambio de rol"
          />
        </DialogTitle>
        <StyledContentWithImage>
          <StyledImage src={PersonalCareImg} />
          <StyledRight>
            <DialogContent>
              <StyledText>
                <FormattedMessage
                  id="RoleSelector.Vas a cambiar el rol de {coordinatorName} de {currentRole} a {newRole}"
                  defaultMessage="Vas a cambiar el rol de {coordinatorName} de {currentRole} a {newRole}"
                  values={{
                    coordinatorName: <b>{currentStaffUser.fullname}</b>,
                    // currentGroup: <b>{groupName(currentGroup)}</b>,
                    // newGroup: <b>{state.newGroupName}</b>,
                  }}
                />
              </StyledText>
              <StyledText>
                <FormattedMessage
                  id="RoleSelector.¿Seguro que quieres continuar?"
                  defaultMessage="¿Seguro que quieres continuar?"
                />
              </StyledText>
            </DialogContent>
          </StyledRight>
        </StyledContentWithImage>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleCancelDialog}
            disabled={state.inProcess}
          >
            <FormattedMessage
              id="RoleSelector.Cancelar"
              defaultMessage="Cancelar"
            />
          </Button>
          <StyledConfirmButton
            variant="contained"
            color="primary"
            onClick={handleConfirmGroupChange}
            disabled={state.inProcess}
          >
            {state.inProcess ? (
              <CircularProgress size={19} thickness={5} />
            ) : (
              <FormattedMessage
                id="RoleSelector.Confirmar"
                defaultMessage="Confirmar"
              />
            )}
          </StyledConfirmButton>
        </DialogActions>
      </StyledDialog>
    </>
  );
};

export default RoleSelector;
