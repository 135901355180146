import { Typography } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import classNames from "classnames";
import moment from "moment";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { CONTRACT_NOTIFICATION_TYPE } from "../../../../../../api/contract/contract.types";
import {
  COLOR_ERROR_ALERT_BG,
  COLOR_INFO_ALERT_BG,
  COLOR_INFO_ALERT_FG,
  COLOR_RED_NOTIFICATION,
  cuideoTheme,
} from "../../../../../../containers/themes/defaultTheme";
import { TContractPartialWithNumbers } from "../../types/contract.types";

export const StyledNotificationWarning = styled.div`
  background-color: ${COLOR_ERROR_ALERT_BG};
  color: ${COLOR_RED_NOTIFICATION};
  font-size: 0.75rem;
  line-height: normal;
  padding: 0.25rem;
  display: flex;
  flex-direction: row;
  border-radius: ${cuideoTheme.shape.borderRadius}px;
  align-items: flex-start;

  .MuiSvgIcon-root {
    margin-right: 0.25rem;
    color: ${COLOR_RED_NOTIFICATION};
    width: 1.125rem;
    height: 1.125rem;
  }

  &.CMui-viewed {
    background-color: ${COLOR_INFO_ALERT_BG};
    color: ${COLOR_INFO_ALERT_FG};

    .MuiSvgIcon-root {
      color: ${COLOR_INFO_ALERT_FG};
    }
  }
`;

export const StyledNotificationText = styled(Typography)`
  font-size: 0.75rem;
  line-height: normal;
`;

interface IContractLastNotificationProps {
  contract: TContractPartialWithNumbers;
  currentTime?: Date;
}

const ContractLastNotification: React.VFC<IContractLastNotificationProps> = (
  props
) => {
  const { contract, currentTime = new Date() } = props;

  if (!contract.notifications) {
    return null;
  }

  if (contract.notifications.length === 0) {
    return null;
  }

  const firstNotification = contract.notifications[0];

  if (
    firstNotification.type ===
    CONTRACT_NOTIFICATION_TYPE.REQUIRES_RECRUITER_WORK
  ) {
    return (
      <StyledNotificationWarning
        className={classNames({
          "CMui-viewed": !!firstNotification.viewed,
        })}
      >
        {firstNotification.viewed ? <InfoOutlinedIcon /> : <NewReleasesIcon />}
        <StyledNotificationText>
          <FormattedMessage
            id="ContractCard.Devuelto a reclutamiento {timeAgo}"
            defaultMessage="Devuelto a reclutamiento {timeAgo}"
            values={{
              timeAgo: moment(firstNotification.createdAt).from(
                moment(currentTime)
              ),
            }}
          />
        </StyledNotificationText>
      </StyledNotificationWarning>
    );
  }

  if (
    firstNotification.type === CONTRACT_NOTIFICATION_TYPE.RETURNED_BY_RECRUITER
  ) {
    return (
      <StyledNotificationWarning
        className={classNames({
          "CMui-viewed": !!firstNotification.viewed,
        })}
      >
        {firstNotification.viewed ? <InfoOutlinedIcon /> : <NewReleasesIcon />}
        <StyledNotificationText>
          <FormattedMessage
            id="ContractCard.Devuelto a gestión {timeAgo}"
            defaultMessage="Devuelto a gestión {timeAgo}"
            values={{
              timeAgo: moment(firstNotification.createdAt).from(
                moment(currentTime)
              ),
            }}
          />
        </StyledNotificationText>
      </StyledNotificationWarning>
    );
  }

  // Should never get here
  return null;
};

export default ContractLastNotification;
