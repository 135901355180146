import { useCallback, useEffect, useState } from "react";
import {
  updateURLParams,
  urlParamsToObject,
} from "../../../../../../../util/urlManager";
import _ from "lodash";

const initialState = {
  eTab: 0,
  ready: false,
};

export type TActiveRouteFilters = typeof initialState;

export default function useActiveRouteUrlParams(history: any) {
  const [filters, setFiltersState] = useState({ ...initialState });

  useEffect(() => {
    if (!filters.ready || history.action !== "REPLACE") {
      const params = history.location.search
        ? urlParamsToObject(history.location.search)
        : {};

      if (params.eTab && typeof params.eTab !== "number") {
        params.eTab = parseInt(params.eTab);
      }
      Object.keys(params).forEach((key) => {
        if (
          Array.isArray((initialState as any)[key]) &&
          typeof params[key] === "string"
        ) {
          params[key] = [params[key]];
        }
      });

      setFiltersState((prevFilters) => {
        if (!_.some([prevFilters], params)) {
          return {
            ...prevFilters,
            ...params,
            ready: true,
          };
        } else {
          return {
            ...prevFilters,
            ready: true,
          };
        }
      });
    }
    // eslint-disable-next-line
  }, [history, setFiltersState]);

  const setFilters = useCallback(
    (newFilters: TActiveRouteFilters) => {
      const params = {
        ...newFilters,
      } as any;
      setFiltersState(newFilters);

      delete params.ready;

      // Clean not needed parameters
      Object.keys(initialState).forEach((key) => {
        if (Array.isArray((initialState as any)[key])) {
          if (
            _.isEqual(
              _.sortBy((initialState as any)[key]),
              _.sortBy(params[key])
            )
          ) {
            delete params[key];
          }
        } else {
          if (params[key] === (initialState as any)[key]) {
            delete params[key];
          }
        }
      });

      updateURLParams(params);
    },
    [setFiltersState]
  );

  return {
    filters,
    setFilters,
  };
}
