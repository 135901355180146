import moment from "moment";
import { useQuery } from "react-query";
import { apiGetCandidate } from "../../../../../apiCoreV3/candidate/apiGetCandidate";
import {
  CONVERSATION_STATUS,
  TConversationResponse,
} from "../../../../../apiCoreV3/conversation/apiConversation.types";
import { apiListConversations } from "../../../../../apiCoreV3/conversation/apiListConversations";
import {
  ExtractFnReturnType,
  QueryConfig,
} from "../../../../../lib/react-query";

const compareConversationsByUpdatedAt = (
  a: TConversationResponse,
  b: TConversationResponse
) => {
  if (moment(a.updated_at).isBefore(moment(b.updated_at))) {
    return -1;
  }
  return 1;
};

export const getCandidateAndConversation = async (candidateCrmId: string) => {
  const candidateRes = await apiGetCandidate(candidateCrmId);

  const conversationsRes = await apiListConversations({
    contactId: candidateRes.id,
  });

  // Filter by active conversations and sort by updated_at field
  const activeConversations = conversationsRes.data.filter(
    (conversation) => conversation.status === CONVERSATION_STATUS.ACTIVE
  );

  const sortedActiveConversations = activeConversations.sort(
    compareConversationsByUpdatedAt
  );

  if (activeConversations.length > 1) {
    console.warn(
      "Detected more than 1 active conversations for candidate ",
      candidateRes.id
    );
  }

  if (activeConversations.length === 0) {
    console.warn(
      "No active conversations detected for candidate ",
      candidateRes.id
    );
  }

  return {
    candidate: candidateRes,
    conversation: sortedActiveConversations[0] ?? undefined,
  };
};

type QueryFnType = typeof getCandidateAndConversation;

type useCandidateAndConversationQueryOptions = {
  candidateCrmId: string;
  config?: QueryConfig<QueryFnType>;
};

export const useCandidateAndConversationQuery = ({
  candidateCrmId,
  config,
}: useCandidateAndConversationQueryOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["candidate-and-conversation", "item", candidateCrmId],
    queryFn: () => getCandidateAndConversation(candidateCrmId),
  });
};
