import { TextField } from "@material-ui/core";
import { connect, getIn } from "formik";
import IBAN from "iban";
import React from "react";
import {
  formatPhoneNumberIntl,
  parsePhoneNumber,
} from "react-phone-number-input";
import styled from "styled-components";
import cuideoTheme from "../../containers/themes/defaultTheme";
import { formatCurrency } from "../../util/formatNumbers";

const StyledField = styled("div")<{
  fullWidth: boolean;
  margin: string;
  size: string;
}>`
  ${({ fullWidth }) => fullWidth && `width: 100%;`}
  padding-top: 0;
  padding-bottom: 0;
  ${({ margin }) => {
    if (margin === "normal") {
      return `
      margin: 1rem 0;
      `;
    }
    if (margin === "nomargin") {
      return ``;
    }
  }}

  &:last-child {
    border-bottom: none;
  }
`;

const StyledLabel = styled.label<{ error?: string; size?: string }>`
  display: block;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  line-height: 1.5;
  white-space: wrap;
  color: ${({ error }) =>
    error ? cuideoTheme.palette.error.main : cuideoTheme.palette.primary.light};

  ${({ size }) => {
    if (size === "medium") {
      return `
        font-size: 1rem;
        padding: 0.2rem 0 0.2rem 0;
      `;
    }
    if (size === "small") {
      return `
        font-size: 0.8rem;
        padding: 0.2rem 0 0.2rem 0;

        @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
          font-size: 0.8rem;
        }
      `;
    }
  }}
`;

const StyledTextField = styled(TextField)<{
  size: string;
  error: boolean;
  disabled: boolean;
}>`
  .MuiOutlinedInput-root {
    height: auto;
    font-weight: 600;
    background-color: ${({ error, disabled }) =>
      error
        ? "#fef5f7"
        : disabled
        ? "#ededed"
        : cuideoTheme.palette.common.white};
    border-radius: ${cuideoTheme.shape.borderRadius}px;
    color: ${cuideoTheme.palette.primary.main};
  }
  .MuiOutlinedInput-notchedOutline {
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  }

  ${({ size }) => {
    if (size === "medium") {
      return `
      .MuiOutlinedInput-root {
        font-size: 1.125rem;
      }
      .MuiOutlinedInput-input {
        padding-top: 0.875rem;
        padding-bottom: 0.875rem;
      }
      .MuiOutlinedInput-multiline {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }
      .MuiOutlinedInput-inputMultiline {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
      }

      .MuiFormHelperText-root {
        font-size: 0.8rem;
        margin: 0.25rem;
      }
      @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
        .MuiOutlinedInput-root {
          font-size: 1.125rem;
        }
      }
          `;
    }
    if (size === "small") {
      return `
      .MuiOutlinedInput-root {
        font-size: 1rem;
      }
      .MuiOutlinedInput-input {
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;
      }
      .MuiOutlinedInput-multiline {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }
      .MuiOutlinedInput-inputMultiline {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
      }

      .MuiFormHelperText-root {
        font-size: 0.8rem;
        margin: 0.25rem;
      }
      @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
        .MuiOutlinedInput-root {
          font-size: 1rem;
        }
      }
          `;
    }
  }}
`;

export interface FormikTextFieldProps {
  ref?: React.MutableRefObject<HTMLElement | null>;
  name: string;
  type?: "text" | "number" | "password" | "postalCode" | "tel";
  size?: "medium" | "small";
  margin?: "nomargin" | "normal";
  variant?: "standard" | "outlined" | "filled";
  label?: any;
  helperText?: any;
  fullWidth?: boolean;
  multiline?: boolean;
  minRows?: string;
  maxRows?: string;
  uppercase?: boolean;
  telformat?: boolean;
  moneyformat?: boolean;
  placeholder?: string;
  required?: boolean;
  endAdornment?: any;
  forceComponentUpdate?: boolean;
  onChange?: Function;
  onBlur?: Function;
  onKeyDown?: Function;
  autoComplete?: any;
  role?: string;
  validate?: Function;
  disabled?: boolean;
  ibanformat?: boolean;
  defaultPhonePrefix?: string;
  labelSuffix?: any;
}

interface State {}

class FormikTextField extends React.Component<any, State> {
  shouldComponentUpdate(nextProps: any, nextState: any) {
    if (nextProps.forceComponentUpdate) {
      return true;
    }
    const next = nextProps.formik;
    const cur = this.props.formik;
    const name = this.props.name;
    if (
      getIn(next.values, name) !== getIn(cur.values, name) ||
      getIn(next.errors, name) !== getIn(cur.errors, name) ||
      getIn(next.touched, name) !== getIn(cur.touched, name)
    ) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    this.props.formik.registerField(this.props.name, {
      validate: this.props.validate,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.name !== prevProps.name) {
      this.props.formik.unregisterField(prevProps.name);
      this.props.formik.registerField(this.props.name, {
        validate: this.props.validate,
      });
    }

    if (this.props.validate !== prevProps.validate) {
      this.props.formik.registerField(this.props.name, {
        validate: this.props.validate,
      });
    }
  }

  componentWillUnmount() {
    this.props.formik.unregisterField(this.props.name);
  }

  toUpperCase(value: string) {
    return value.toUpperCase();
  }

  toPhoneFormat(value: string) {
    let newPhone = value;
    if (newPhone.length > 0 && newPhone.charAt(0) !== "+") {
      const prefix = this.props.defaultPhonePrefix || "+34";
      newPhone = prefix + newPhone;
    }

    const parsedNumber = parsePhoneNumber(newPhone);
    if (parsedNumber) {
      return formatPhoneNumberIntl(newPhone);
    }
    return value;
  }

  toIbanFormat(value: string) {
    return IBAN.printFormat(value, " ");
  }

  toMoneyFormat(value: string) {
    let lastComma = false;
    if (value.slice(-1) === ".") {
      lastComma = true;
    }

    let formattedValue =
      value !== ""
        ? formatCurrency(parseFloat(value)).replace(/[^0-9.,]/g, "")
        : "";

    if (lastComma) {
      formattedValue = `${formattedValue},`;
    }

    return formattedValue;
  }

  parseCurrencyValue(value: string) {
    const val = value
      .replace(".", "")
      .replace(",", ".")
      .replace(/[^0-9.]/g, "");

    return this.toMoneyFormat(val);
  }

  handleChange(e: any) {
    if (this.props.uppercase) {
      e.target.value = this.toUpperCase(e.target.value);
    }
    if (this.props.telformat) {
      // Parse numbers so we don't contain any unicode characters. Only
      // numbers, spaces and + are allowed.
      const parsedNumber = e.target.value.replace(/[^0-9+\s]/g, "");
      e.target.value = this.toPhoneFormat(parsedNumber);
    }
    if (this.props.ibanformat) {
      e.target.value = this.toIbanFormat(e.target.value);
    }
    if (this.props.moneyformat) {
      e.target.value = this.parseCurrencyValue(e.target.value);
    }
    if (this.props.type && this.props.type === "postalCode") {
      e.target.value = e.target.value.replace(/[^0-9]/g, "").substring(0, 5);
    }

    if (this.props.onChange) {
      this.props.onChange(e);
    }

    this.props.formik.handleChange(e);
  }

  handleBlur(e: any) {
    if (this.props.uppercase) {
      e.target.value = this.toUpperCase(e.target.value);
    }
    if (this.props.telformat) {
      e.target.value = this.parseCurrencyValue(e.target.value);
    }
    if (this.props.ibanformat) {
      e.target.value = this.toIbanFormat(e.target.value);
    }
    if (this.props.moneyformat) {
      e.target.value = this.toMoneyFormat(e.target.value);
    }

    if (this.props.onBlur) {
      this.props.onBlur(e);
    }

    this.props.formik.handleBlur(e);
  }

  render() {
    const {
      ref,
      name,
      variant,
      label,
      helperText,
      fullWidth,
      multiline,
      minRows,
      maxRows,
      margin,
      size,
      placeholder,
      labelSuffix,
      required,
      endAdornment,
      type,
      onKeyDown,
      autoComplete,
      role,
      disabled,
    } = this.props;
    const formik = this.props.formik;

    const field = {
      value: getIn(formik.values, name),
      name,
      id: name,
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
    };

    field.onChange = this.handleChange.bind(this);
    field.onBlur = this.handleBlur.bind(this);

    const error = getIn(formik.errors, name);
    const touched = !!getIn(formik.touched, name);

    const parsedType = type === "postalCode" ? "text" : type;

    return (
      <StyledField fullWidth margin={margin || "normal"} size={size || "small"}>
        {label && (
          <StyledLabel
            error={touched && !!error ? "error" : undefined}
            size={size || "small"}
            htmlFor={name}
          >
            {label} {required && " *"} {labelSuffix}
          </StyledLabel>
        )}
        <StyledTextField
          {...field}
          ref={ref}
          fullWidth={fullWidth}
          variant={variant || "outlined"}
          multiline={multiline}
          minRows={minRows}
          type={parsedType}
          placeholder={placeholder}
          maxRows={maxRows}
          error={touched && !!error}
          helperText={touched && error ? error : helperText}
          size={size || "small"}
          InputProps={{ endAdornment }}
          onKeyDown={onKeyDown}
          autoComplete={autoComplete}
          role={role}
          disabled={disabled}
        />
      </StyledField>
    );
  }
}

export default connect<FormikTextFieldProps, State>(FormikTextField as any);
