import React from "react";
import { Grow } from "@material-ui/core";

const Transition = React.forwardRef<unknown, any>(function Transition(
  props,
  ref
) {
  return <Grow ref={ref} {...props} />;
});

export default Transition;
