import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import ErrorIcon from "@material-ui/icons/Error";

const StyledDialog = styled(Dialog)``;

const StyledColumnLayout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const StyledLeft = styled.div`
  flex: 0 0 auto;
  margin-right: 0.75rem;
`;

const StyledIconWrapper = styled.div`
  margin-top: 0.25rem;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #fdeded;

  .MuiSvgIcon-root {
    color: #cc1016;
  }
`;

const StyledRight = styled.div``;

const StyledTitle = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0.375rem 0;
`;

const StyledSubtitle = styled(Typography)`
  margin: 0.5rem 0;
  font-size: 1.125rem;
  font-weight: 700;
`;

const StyledText = styled(Typography)`
  font-size: 1.125rem;
  line-height: 1.35;
`;

interface IErrorsOnFormDialogProps {
  open: boolean;
  onClose: () => void;
}

const ErrorsOnFormDialog: React.FC<IErrorsOnFormDialogProps> = ({
  open,
  onClose,
}) => {
  return (
    <StyledDialog open={open} onClose={onClose}>
      <DialogContent>
        <StyledColumnLayout>
          <StyledLeft>
            <StyledIconWrapper>
              <ErrorIcon />
            </StyledIconWrapper>
          </StyledLeft>
          <StyledRight>
            <StyledTitle color="primary">
              <FormattedMessage
                id="ErrorsOnFormDialog.Error"
                defaultMessage="Error"
              />
            </StyledTitle>
            <StyledSubtitle color="primary">
              <FormattedMessage
                id="ErrorsOnFormDialog.Hay errores en el formulario."
                defaultMessage="Hay errores en el formulario."
              />
            </StyledSubtitle>
            <StyledText color="primary">
              <FormattedMessage
                id="ErrorsOnFormDialog.No es posible continuar porque hay errores en el formulario. Por favor, revisa los campos marcados en rojo"
                defaultMessage="No es posible continuar porque hay errores en el formulario. Por favor, revisa los campos marcados en rojo"
              />
            </StyledText>
          </StyledRight>
        </StyledColumnLayout>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined" autoFocus>
          <FormattedMessage
            id="ErrorsOnFormDialog.Aceptar"
            defaultMessage="Aceptar"
          />
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default ErrorsOnFormDialog;
