import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { motion } from "framer-motion";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import cuideoTheme from "../../../containers/themes/defaultTheme";
import AssignCandidatoButton from "./AssignCandidatoButton";

const StyledFooter = styled.div`
  padding: 1rem 1rem 1rem 5rem;
  background-color: ${cuideoTheme.palette.primary.main};
`;

const StyledRow = styled(Grid)``;

const StyledLeftSection = styled(Grid)`
  text-align: left;
`;

const StyledNumberText = styled(Typography)`
  color: ${cuideoTheme.palette.common.white};
  font-weight: 700;
`;

const StyledCenterSection = styled(Grid)`
  text-align: center;
`;

const StyledRightSection = styled(Grid)`
  text-align: right;
`;

const StyledCloseButton = styled(IconButton)`
  color: ${cuideoTheme.palette.common.white};
`;

const StyledAssignWrapper = styled.div`
  color: ${cuideoTheme.palette.common.white};
  .MuiCuideo-AssignButton {
  }
`;

const footerVariants = {
  enter: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.25 },
    display: "block",
  },
  exit: {
    opacity: 0,
    y: 25,
    transition: { duration: 0.25 },
    transitionEnd: {
      display: "none",
    },
  },
};

export interface CandidatosMultiSelectFooterProps {
  onChange: Function;
  candidatos: any[];
  onUnselectAll: Function;
}

const CandidatosMultiSelectFooter = (
  props: CandidatosMultiSelectFooterProps
) => {
  const { candidatos, onChange, onUnselectAll } = props;

  const handleAssignChange = () => {
    onChange();
  };

  return (
    <motion.div
      variants={footerVariants}
      animate={candidatos.length ? "enter" : "exit"}
      initial="exit"
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 2,
      }}
    >
      <StyledFooter>
        <StyledRow
          container
          spacing={8}
          justifyContent="space-between"
          alignItems="center"
        >
          <StyledLeftSection item xs={12} md={4}>
            <StyledNumberText>
              <FormattedMessage
                id="MultiselectFooter.Employees"
                defaultMessage="{numCandidatos} candidatos seleccionados"
                values={{ numCandidatos: candidatos.length }}
              />
            </StyledNumberText>
          </StyledLeftSection>
          <StyledCenterSection item xs={12} md={4}>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item>
                <Tooltip
                  title={
                    <FormattedMessage
                      id="MultiSelect.Assign"
                      defaultMessage="Asignar candidatos a otros contratos"
                    />
                  }
                >
                  <StyledAssignWrapper>
                    <AssignCandidatoButton
                      candidatos={candidatos}
                      handleChange={handleAssignChange}
                    />
                  </StyledAssignWrapper>
                </Tooltip>
              </Grid>
            </Grid>
          </StyledCenterSection>
          <StyledRightSection item xs={12} md={4}>
            <StyledCloseButton
              onClick={() => {
                onUnselectAll();
              }}
            >
              <CloseIcon />
            </StyledCloseButton>
          </StyledRightSection>
        </StyledRow>
      </StyledFooter>
    </motion.div>
  );
};

export default React.memo(
  (props: CandidatosMultiSelectFooterProps) => {
    return <CandidatosMultiSelectFooter {...props} />;
  },
  (prevProps, nextProps) => {
    if (
      prevProps.onChange !== nextProps.onChange ||
      prevProps.onUnselectAll !== nextProps.onUnselectAll ||
      prevProps.candidatos !== nextProps.candidatos
    ) {
      return false;
    }
    return true;
  }
);
