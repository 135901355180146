import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import styled from "styled-components";
import { FormikTextField } from ".";
import { FormikTextFieldProps } from "./FormikTextField";

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledDropdownContainer = styled.div`
  position: absolute;
  z-index: 1;
  padding-top: 8px;
  padding-bottom: 8px;
  transition: opacity: 299ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 199ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
  border-radius: 4px;
  width: 100%;
  min-width: 18rem;
  background-color: #fff;
`;

const StyledSuggestion = styled.div`
  padding: 0.5rem 1rem;
  font-family: "Source sans pro", Helvetica, Arial, sans-serif;
`;

interface Props {
  handleSelect: any;
  value: any;
  handleChange: any;
  name: string;
  placeholder?: any;
  size?: "medium" | "small";
  margin?: "nomargin" | "normal";
  label?: any;
}

class FormikAddressField extends React.Component<
  Props & FormikTextFieldProps,
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = { address: "" };
  }

  render() {
    const { name, value, placeholder, margin, size, label, ...other } =
      this.props;

    return (
      <PlacesAutocomplete
        searchOptions={{
          types: ["address"],
          componentRestrictions: { country: "es" },
        }}
        // value={(this.state as any).address}
        // onChange={this.handleChange}
        value={value}
        onChange={this.props.handleChange}
        onSelect={this.props.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          return (
            <StyledWrapper>
              <FormikTextField
                {...other}
                {...getInputProps({
                  placeholder: placeholder,
                })}
                autoComplete="calleRegistro"
                type="text"
                name={name}
                size={size}
                margin={margin}
                label={label}
              />
              {!!suggestions.length && (
                <StyledDropdownContainer>
                  {suggestions.map((suggestion, i) => {
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <StyledSuggestion
                        {...getSuggestionItemProps(suggestion, {
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </StyledSuggestion>
                    );
                  })}
                </StyledDropdownContainer>
              )}
            </StyledWrapper>
          );
        }}
      </PlacesAutocomplete>
    );
  }
}

export default FormikAddressField;
