import {
  Fade,
  IconButton,
  OutlinedInput,
  Popper,
  Typography,
} from "@material-ui/core";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { cuideoTheme } from "../../../../../../containers/themes/defaultTheme";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { featureAnimation } from "../../../../../../containers/themes/animations";
import uuid from "uuid";

const StyledText = styled(Typography)({
  display: "-webkit-box",
  "-webkit-line-clamp": "3",
  "-webkit-box-orient": "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
  border: "1px solid transparent",
  padding: "0.25rem",
  borderRadius: "0.25rem",
  outline: "none",
  width: "100%",

  "&.CMui-noNotes": {
    opacity: 0.5,
  },

  "&:hover, &:focus": {
    borderColor: "rgba(0,72,120,.5)",
  },
});

const StyledPaper = styled.div`
  max-width: 18rem;
  padding: 0.625rem 1rem;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  background-color: #f5f7f9;
  border-radius: 0.25rem;
`;

const StyledPopupText = styled(Typography)`
  font-size: 0.875rem;
  line-height: 1.25;
  color: ${cuideoTheme.palette.primary.main};
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: -0.25rem 0 -0.25rem -0.25rem;
  width: 100%;

  &.CMui-featured {
    animation: ${featureAnimation} 1s 1;
  }
`;

const StyledActions = styled.div`
  margin-left: 0.25rem;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
`;

const StyledOutlinedInput = styled(OutlinedInput)`
  flex-grow: 1;
  font-size: 0.75rem;
  height: unset;
  padding: 0.125rem 0.25rem;

  .MuiInputBase-inputMultiline {
    padding: 0.25rem 0.25rem;
    max-height: 2.25rem;
    height: 2.25rem;
  }
`;

const StyledIconButton = styled(IconButton)`
  width: 1.125rem;
  height: 1.125rem;
  border: 1px solid ${cuideoTheme.palette.primary.main};
  margin-bottom: 0.25rem;

  .MuiSvgIcon-root {
    width: 1rem;
    height: 1rem;
  }

  &.CMui-contained {
    background-color: ${cuideoTheme.palette.primary.main};
    color: #fff;
  }
`;

interface IEditableTextOverflowWithPopupProps {
  text?: string;
  onTextChange: (newValue?: string) => void;
}

const EditableTextOverflowWithPopup: React.VFC<IEditableTextOverflowWithPopupProps> =
  (props) => {
    const { text, onTextChange } = props;
    const [state, setState] = useState({
      text,
      isEditing: false,
      editingText: text,
      featured: false,
      id: uuid.v4(),
    });
    const { formatMessage } = useIntl();
    const overflowingText = useRef<HTMLSpanElement | null>(null);
    const timerRef = useRef<null | ReturnType<typeof setTimeout>>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    useEffect(() => {
      if (state.isEditing) {
        return;
      }

      if (text !== state.text) {
        setState((prevState) => ({
          ...prevState,
          text: text,
          editingText: text,
          featured: true,
          id: uuid.v4(),
        }));
      }
    }, [text, state.text, state.isEditing]);

    const checkOverflow = (textContainer: HTMLSpanElement | null): boolean => {
      if (textContainer)
        return (
          textContainer.offsetHeight < textContainer.scrollHeight ||
          textContainer.offsetWidth < textContainer.scrollWidth
        );
      return false;
    };

    const handleMouseEnter = (e: any) => {
      if (checkOverflow(overflowingText.current)) {
        timerRef.current = setTimeout(() => {
          setAnchorEl(overflowingText.current);
        }, 500);
      } else {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
      }
    };

    const handleMouseLeave = () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      if (!!anchorEl) {
        setAnchorEl(null);
      }
    };

    const handleClick = () => {
      setState((prevState) => ({
        ...prevState,
        isEditing: true,
      }));
    };

    const handleEditCancel = () => {
      setState((prevState) => ({
        ...prevState,
        isEditing: false,
        editingText: text,
      }));
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setState((prevState) => ({
        ...prevState,
        editingText: e.target.value,
      }));
    };

    const handleSubmit = () => {
      if (state.editingText === text) {
        setState((prevState) => ({
          ...prevState,
          isEditing: false,
        }));
        return;
      }

      const newText = state.editingText === "" ? undefined : state.editingText;
      setState((prevState) => ({
        ...prevState,
        text: newText,
        isEditing: false,
      }));

      onTextChange(newText);
    };

    const outputText =
      state.text ??
      formatMessage({
        id: "EditableTextOverflow.No hay notas",
        defaultMessage: "No hay notas",
      });

    if (state.isEditing) {
      return (
        <StyledWrapper
          key={`etf__${state.id}`}
          className={classNames({
            "CMui-featured": state.featured,
          })}
        >
          <StyledOutlinedInput
            value={state.editingText}
            onChange={handleInputChange}
            placeholder={formatMessage({
              id: "EditableTextOverflow.Escribe una nota aquí",
              defaultMessage: "Escribe una nota aquí",
            })}
            multiline
            maxRows={3}
            fullWidth
            autoFocus
          />
          <StyledActions>
            <StyledIconButton
              color="primary"
              className="CMui-contained"
              onClick={handleSubmit}
            >
              <CheckIcon />
            </StyledIconButton>
            <StyledIconButton color="primary" onClick={handleEditCancel}>
              <CloseIcon />
            </StyledIconButton>
          </StyledActions>
        </StyledWrapper>
      );
    }

    return (
      <StyledWrapper
        key={`etf__${state.id}`}
        className={classNames({
          "CMui-featured": state.featured,
        })}
      >
        <StyledText
          ref={overflowingText}
          className={classNames({
            "CMui-noNotes": !state.text,
          })}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleClick}
          role="textbox"
          tabIndex={0}
        >
          {outputText}
        </StyledText>
        <Popper
          placement="bottom"
          open={!!anchorEl}
          anchorEl={anchorEl}
          modifiers={{
            arrow: {
              enabled: false,
            },
          }}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={250}>
              <StyledPaper>
                <StyledPopupText>{outputText}</StyledPopupText>
              </StyledPaper>
            </Fade>
          )}
        </Popper>
      </StyledWrapper>
    );
  };

export default EditableTextOverflowWithPopup;
