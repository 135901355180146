import moment from "moment";
import { useQuery } from "react-query";
import { apiGetServices } from "../../../../../api/service/apiGetServices";
import {
  SERVICE_STATE,
  TGetServicesCollectionItem,
} from "../../../../../api/service/service.types";
import { TReminderStorage } from "./useDelayedServicesList";

export default function useGetDelayedServiceQuery(
  ownerId?: string,
  delayedServices?: TReminderStorage[],
  enabledQuery = true
) {
  const getRemindDate = (serviceId: string) => {
    const delayedService = delayedServices?.find((delayedService) => {
      return delayedService.id === serviceId;
    });

    if (delayedService) {
      return moment(delayedService.until);
    }
    return null;
  };
  return useQuery(
    ["delayed_service", delayedServices, ownerId],
    async () => {
      const params = {
        businessCountry: "es",
        state: [SERVICE_STATE.WITHOUT_VALIDATION],
        partial: 1,
        owner: ownerId,
      } as any;

      params["order[ownerAssignedAt]"] = "asc";

      const now = moment();
      const res = await apiGetServices(params);
      const services = res["hydra:member"].filter(
        (service: TGetServicesCollectionItem) => {
          if (!service.delayedDate) {
            return false;
          }
          const remindDate = getRemindDate(service.id);
          const delayedDate = moment(service.delayedDate);
          return (
            delayedDate.isBefore(now) &&
            (!remindDate || remindDate.isBefore(now))
          );
        }
      );
      return services[0] ?? null;
    },
    {
      enabled: enabledQuery,
      cacheTime: 1000 * 60 * 5,
      refetchInterval: 60000,
    }
  );
}
