import { useQuery } from "react-query";
import { apiPostBadges } from "../../../../../api/badges/apiPostBadges";
import {
  ExtractFnReturnType,
  QueryConfig,
} from "../../../../../lib/react-query";
import { badgesKeys } from "./badges.query-keys";

type QueryFnType = typeof apiPostBadges;

type TUseBadgesOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useBadges = ({ config }: TUseBadgesOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: badgesKeys.mine,
    queryFn: () => apiPostBadges(),
  });
};
