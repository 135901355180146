import moment from "moment";
import {
  EMPLOYEE_STATUS,
  TGetEmployeeItem,
  TGetEmployeeItemUserFavorite,
  TGetEmployeesCollectionItem,
} from "../../../../../api/employee/employee.types";

export const candidateIsFavoriteOfUser = (
  candidate: Pick<TGetEmployeeItem, "favoritedBy">,
  userId: string
): boolean => {
  const foundFav = candidate.favoritedBy?.find((fav) => fav.userId === userId);

  return !!foundFav;
};

export const candidateFavoriteOfUser = (
  candidate: Pick<TGetEmployeeItem, "favoritedBy">,
  userId: string
): TGetEmployeeItemUserFavorite | undefined => {
  return candidate.favoritedBy?.find((fav) => fav.userId === userId);
};

export const candidateHasSpanishBusinessCountry = (
  candidate: TGetEmployeesCollectionItem
): boolean => {
  if (candidate.Business_Country === "es") {
    return true;
  }
  return false;
};

export const candidateIsValidatedOrBetter = (
  candidate: TGetEmployeesCollectionItem
) => {
  if (
    candidate.Estado_Cuideo === EMPLOYEE_STATUS.VALIDATED ||
    candidate.Estado_Cuideo === EMPLOYEE_STATUS.VALIDATED_PLUS ||
    candidate.Estado_Cuideo === EMPLOYEE_STATUS.ACTIVE
  ) {
    return true;
  }

  return false;
};

export const candidateIdExpiryIsValid = (
  candidate: TGetEmployeesCollectionItem
) => {
  if (null === candidate.Vencimiento_NIE) {
    return false;
  }

  const expiryMom = moment(candidate.Vencimiento_NIE);
  const now = moment();
  if (expiryMom.isAfter(now)) {
    return true;
  }
  return false;
};

export const candidateShouldShowInvalidIdExpiryDateWarning = (
  candidate: TGetEmployeesCollectionItem
): boolean => {
  if (
    candidateIsValidatedOrBetter(candidate) &&
    candidateHasSpanishBusinessCountry(candidate) &&
    !candidateIdExpiryIsValid(candidate)
  ) {
    return true;
  }
  return false;
};
