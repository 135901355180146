import { Typography } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import {
  ADVANCED_VALUATION_COLOR,
  PENDING_SIGNUP_COLOR,
  WITHOUT_VALUATION_COLOR,
} from "./CoordinatorAssignationsItem";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 100%;
`;

const StyledLeft = styled.div`
  padding: 0.5rem 1rem;
  height: 100%;
  width: 50%;
`;

const StyledRight = styled.div`
  padding: 0.5rem 1rem;
  border-left: 1px solid #ddd;
  height: 100%;
  width: 50%;
`;

const StyledSectionTitle = styled(Typography)`
  font-size: 0.875rem;
  line-height: 1.25;
  font-weight: 600;
  color: #444;
  margin-bottom: 0.5rem;
`;

const StyledLegendItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const StyledLegendBox = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  border: 0.0625rem solid #ccc;
  box-shadow: 0.0625rem 0.0625rem 1px 0 rgba(0, 0, 0, 0.1);
  margin-right: 0.5rem;
  flex: 0 0 auto;
`;

const StyledLegendLabel = styled(Typography)`
  font-size: 0.75rem;
  line-height: 1;
  color: #555;
`;

interface ICoordinatorAssignationsLegend {}

const CoordinatorAssignationsLegend = (
  props: ICoordinatorAssignationsLegend
) => {
  return (
    <StyledWrapper>
      <StyledLeft>
        <StyledSectionTitle>
          <FormattedMessage
            id="CoordinationAssignationsLegend.Servicios"
            defaultMessage="Servicios"
          />
        </StyledSectionTitle>
        <StyledLegendItem>
          <StyledLegendBox
            style={{ backgroundColor: WITHOUT_VALUATION_COLOR }}
          ></StyledLegendBox>
          <StyledLegendLabel>
            <FormattedMessage
              id="CoordinationAssignationsLegend.Sin valorar"
              defaultMessage="Sin valorar"
            />
          </StyledLegendLabel>
        </StyledLegendItem>
      </StyledLeft>
      <StyledRight>
        <StyledSectionTitle>
          <FormattedMessage
            id="CoordinationAssignationsLegend.Contratos"
            defaultMessage="Contratos"
          />
        </StyledSectionTitle>
        <StyledLegendItem>
          <StyledLegendBox
            style={{ backgroundColor: PENDING_SIGNUP_COLOR }}
          ></StyledLegendBox>
          <StyledLegendLabel>
            <FormattedMessage
              id="CoordinationAssignationsLegend.Alta pendiente"
              defaultMessage="Alta pendiente"
            />
          </StyledLegendLabel>
        </StyledLegendItem>
        <StyledLegendItem>
          <StyledLegendBox
            style={{ backgroundColor: ADVANCED_VALUATION_COLOR }}
          ></StyledLegendBox>
          <StyledLegendLabel>
            <FormattedMessage
              id="CoordinationAssignationsLegend.CV Cliente / Presencial Cliente"
              defaultMessage="CV Cliente / Presencial Cliente"
            />
          </StyledLegendLabel>
        </StyledLegendItem>
      </StyledRight>
    </StyledWrapper>
  );
};

export default CoordinatorAssignationsLegend;
