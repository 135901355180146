export const SERVICE_BUSINESS_COUNTRY = {
  ES: "es",
  FR: "fr",
} as const;

export type TServiceBusinessCountry =
  typeof SERVICE_BUSINESS_COUNTRY[keyof typeof SERVICE_BUSINESS_COUNTRY];

export const SERVICE_STATE = {
  WITHOUT_VALIDATION: "Sin Valorar",
  VALUED: "Valorado",
  CLOSED: "Cerrado / Contratado",
  ACTIVE_SAD: "Activo SAD",
  AWAITING: "En Espera",
  DISCHARGED: "Baja Servicio",
} as const;

export type TServiceState = typeof SERVICE_STATE[keyof typeof SERVICE_STATE];

export const SERVICE_TYPE = {
  SELECTION: "Seleccion",
  HOURLY_PROFESSIONAL: "Profesional por Horas",
  CUIDEO_ASIST: "Cuideo Asist",
} as const;

export type TServiceType = typeof SERVICE_TYPE[keyof typeof SERVICE_TYPE];

export const SERVICE_DEAL_TYPE = {
  INTERNAL_DURING_WEEK: "Interna entre semana",
  EXTERNAL_DURING_WEEK: "Externa entre semana",
  INTERNAL_DURING_WEEKEND: "Interna fin de semana",
  EXTERNAL_DURING_WEEKEND: "Externa fin de semana",
  HOURLY_PROFESSIONAL: "Profesional por Horas",
  CUIDEO_ASIST: "Cuideo Asist",
  EXTERNAL_LESS_20_HOURS: "Externa menos de 20 horas semanales",
  EXTERNAL_MORE_20_HOURS: "Externa más de 20 horas semanales",
} as const;

export type TServiceDealType =
  typeof SERVICE_DEAL_TYPE[keyof typeof SERVICE_DEAL_TYPE];

export const SERVICE_PREFERRED_SEX = {
  MAN: "Hombre",
  WOMAN: "Mujer",
  INDIFFERENT: "Indiferente",
} as const;

export type TServicePreferredSex =
  typeof SERVICE_PREFERRED_SEX[keyof typeof SERVICE_PREFERRED_SEX];

export const SERVICE_PLAN = {
  COMFORT: "Confort",
  COMFORT_PLUS_IN_PERSON: "Confort+ presencial",
  COMFORT_PLUS_PHONE: "Confort+ telefónico",
  FELIZ_VITA: "FelizVita",
  PUNCTUAL_SELECTION: "Puntual Selección",
  PUNCTUAL_FELIZVITA: "Puntual FelizVita",
} as const;

export type TServicePlan = typeof SERVICE_PLAN[keyof typeof SERVICE_PLAN];

export type TGetServicesCollectionItem = {
  id: string;
  Activar_Servicio_Cuideo_Asist?: boolean;
  Aplicar_incentivos?: boolean;
  Business_Country?: TServiceBusinessCountry;
  C_digo_postal_servicio?: string;
  Cantidad_horas_trabajador?: number;
  Cerrado_Contratadodate?: string;
  Ciudad?: string;
  Cl_usulas_especiales?: string;
  Comunidad_Aut_noma?: string;
  Cruz_Roja_Teleasistencia?: string;
  Cuota_Alta?: string;
  Cuota_Cuideo?: string;
  D_as?: string;
  Descanso_semanal?: string;
  Detalle_incentivos?: string;
  Edad_usuario1?: number;
  Edad_usuario2?: number;
  Enviar_resumen_valoracion?: boolean;
  Estado_Servicio?: string;
  Estado_de_la_vivienda?: string;
  Fecha_alta_pago_nomina?: string;
  Fecha_baja_pago_nomina?: string;
  Fecha_inicio_servicio?: string;
  Fecha_objetivo_entrevistas?: string;
  Festivos_y_vacaciones_incluidos?: string;
  Fin_de_servicio?: string;
  Horario_Servicio?: string;
  Horario_servicio_venta?: string;
  Horas_jornada_semanal?: number;
  Incentivos?: string[];
  Informe_general_inicial?: string;
  Inicio_de_servicio?: any;
  Name?: string;
  Nombre_usuario1?: string;
  Nombre_usuario2?: string;
  /**
   * @deprecated
   */
  Notas_depto_comercial?: string;
  /**
   * New commercial dpto notes field
   */
  Notas_depto_comercial_3?: string;
  OCU?: string;
  Observaciones1?: string;
  Otras_notas_valoracion?: string;
  Otros_contactos?: string;
  Otros_requisitos?: string;
  Owner?: any;
  Pago_de_n_mina?: boolean;
  Patolog_as_tratadas_previamente?: string[];
  Plan?: TServicePlan | null;
  Provincia?: string;
  Rutinas?: string | null;
  Salario_Bruto?: number;
  Salario_bruto_base?: any;
  Salario_bruto_total_Servicio?: any;
  Salario_bruto_ventas?: number;
  Salario_neto_total_Servicio?: any;
  Salario_neto_ventas?: number;
  Seguimiento_datetime?: string;
  Seguridad_social_Total_Servicio?: any;
  Seguridad_social_ventas?: number;
  Servicio_Express?: boolean;
  Servicio_Paraguas?: string;
  Servicio_corta_duracion?: string;
  Sexo_trabajador?: TServicePreferredSex;
  Sueldo_d_a_trabajador?: any;
  Tareas_solicitadas?: string[];
  Telefono_cliente?: string;
  Tipo_de_Servicio?: TServiceType;
  Tipo_de_Trato?: TServiceDealType;
  Tipo_de_salario?: string;
  Titulaci_n_oficial?: string;
  Total_coste_mensual_trabajador?: any;
  Usuario?: any;
  Usuario1?: {
    id: string;
    Name?: string;
    zohoId?: string;
    Usuarios_a_cuidar?: number;
    Unidad_de_convivencia?: string;
    Direccion?: string;
    Direccion_2?: string;
    Ciudad?: string;
    Codigo_postal?: string;
    Region?: string;
    Area?: string;
    Latitud?: string;
    Longitud?: string;
    Estado_U1?: string;
    Nombre_U1?: string;
    Primer_apellido_U1?: string;
    Segundo_apellido_U1?: string;
    Fecha_de_nacimiento_U1?: string;
    DNI_U1?: string;
    Grado_Ley_de_dependencia_U1?: string | null;
    Patologias_U1?: string[];
    Comentarios_patologias_U1?: string;
    Habla_expresion_U1?: string;
    Medicacion_U1?: string;
    Dieta?: string;
    Movilidad_U1?: string[];
    Comentarios_movilidad_U1?: string;
    Peso_U1?: number;
    Altura_U1?: number;
    Estado_emocional_y_caracter_U1?: string[];
    Adaptaci_n_de_cuidados_U1?: string;
    Comentarios_estado_emocional_y_caracter_U1?: string;
    Actividades_y_aficiones_U1?: string;
    Calidad_del_sue_o_U1?: string;
    Indicaciones_del_sueno_U1?: string;
    Aseo_U1?: string[];
    Indicaciones_del_aseo_U1?: string;
    Estado_U2?: string;
    Nombre_U2?: string;
    Primer_apellido_U2?: string;
    Segundo_apellido_U2?: string;
    Fecha_de_nacimiento_U2?: string;
    DNI_U2?: string;
    Grado_Ley_de_dependencia_U2?: string | null;
    Patologias_U2?: string[];
    Comentarios_patologias_U2?: string;
    Habla_expresion_U2?: string;
    Medicacion_U2?: string;
    Dieta_U2?: string;
    Movilidad_U2?: string[];
    Comentarios_movilidad_U2?: string;
    Peso_U2?: number;
    Altura_U2?: number;
    Estado_emocional_y_caracter_U2?: string[];
    Comentarios_estado_emocional_y_caracter_U2?: string;
    Adaptaci_n_de_cuidados_U2?: string;
    Actividades_y_aficiones_U2?: string;
    Calidad_del_sue_o_U2?: string;
    Indicaciones_del_sueno_U2?: string;
    Aseo_U2?: string[];
    Indicaciones_del_aseo_U2?: string;
    Tipo_de_vivienda?: string;
    Caracteristicas_de_la_vivienda?: string[];
    Proveedor_de_Teleasistencia?: string;
    Comunicacion_con_transporte_publico?: string;
    Notas_coordinadora?: string;
    activeBankAccount?: {
      id: string;
      zohoId?: string;
      Name?: string;
      Estado?: string;
      IBAN?: string;
      Titular_cuenta?: string;
      BIC?: string;
      Direccion?: string;
      Ciudad?: string;
      Region?: string;
      Area?: string;
      Pais?: string;
      Documento_identidad?: string;
    };
    relatedContacts?: {
      id: string;
      Parentesco?: string;
      Contacto: {
        id: string;
        zohoId?: string;
        Business_Country?: string;
        Email?: string;
        First_Name?: string;
        Last_Name?: string;
        Phone?: string;
      };
    }[];
  };
  Valor_pago_de_n_mina?: string;
  Valor_inc_total?: any;

  Direccion_entrega_Cuideo_Asist_distinta_servicio?: string;
  Ciudad_entrega_Cuideo_Asist?: string;
  Codigo_postal_entrega_Cuideo_Asist?: string;
  Direccion_entrega_Cuideo_Asist?: string;
  Region_entrega_Cuideo_Asist?: string;
  Area_entrega_Cuideo_Asist?: string;
  Latitud_entrega_Cuideo_Asist?: number;
  Longitud_entrega_Cuideo_Asist?: number;
  formatted_address_entrega_Cuideo_Asist?: string;

  contracts?: any;
  customer?: {
    Business_Country?: string;
    Email?: string;
    First_Name?: string;
    Last_Name?: string;
    Lead_Source?: string;
    Phone?: string;
    Parentesco?: string;
    id?: string;
    zohoId?: string;
  };
  ownerAssignedAt?: string;
  delayedDate?: string;
  delayedReason?: string;
  createdAt?: string;
  ownerName?: string;
  zohoId?: string;
  valuationData?: any;
  notesDuringValuation?: string;

  Lead_External_ID?: string;
  Referente_B2B?: {
    id: string;
    Name: string;
  };

  Venta_relacionada?: {
    id: string;
    ownerName?: string;
  };
  valuationInProcess: boolean;
  Squad?: string;
  Zona_sin_base: boolean | undefined;
};

export type TGetServicesInfoManagerCollectionItem = {
  id: string;
  Estado_Servicio?: TServiceState;
  Name?: string;
  Owner?: {
    id: string;
    fullname?: string;
  };
  delayedDate?: string;
  ownerAssignedAt?: string;
};
