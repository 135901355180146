import { useFormikContext } from "formik";
import React, { useState } from "react";
import { TSelectOption } from "../../../../../apps/affinity/pages/ServicePage/components/ServiceValuation/types";
import provincias from "../../../../../util/defaults/provincias";
import { cityOptionsForPostalCode } from "../../../../../util/geo/cityOptionsForPostalCode";
import { geocodeAddressToObject } from "../../../../../util/geo/geocodeAddressToObject";
import { useSelector } from "react-redux";

export default function useSignUpStep2FormContext() {
  const formikContext = useFormikContext<any>();
  const [cityOptions, setCityOptions] = useState<TSelectOption[]>([]);
  const { timeSlotOptions } = useSelector((state: any) => state.commonData);

  const changeAddressStreet = (address: string) => {
    formikContext.setFieldValue("registroCalle", address);
  };

  const selectAddress = async (address: string) => {
    const newAddress = await geocodeAddressToObject(address);

    const newCityOptions = await cityOptionsForPostalCode(
      newAddress.postalCode
    );
    if (newCityOptions.length === 0) {
      newCityOptions.push({
        value: newAddress.city,
        label: newAddress.city,
      });
    } else {
      if (newCityOptions[0]) {
        newAddress.city = newCityOptions[0].value;
      }
    }
    setCityOptions(newCityOptions);

    formikContext.setFieldValue("registroCalle", newAddress.routeName);
    formikContext.setFieldValue("autocompletedCalle", newAddress.routeName);
    formikContext.setFieldTouched("registroCalle");

    formikContext.setFieldValue("registroNumero", newAddress.streetNumber);
    formikContext.setFieldValue("autocompletedNumero", newAddress.streetNumber);
    formikContext.setFieldTouched("registroNumero");

    formikContext.setFieldValue("registroProvincia", newAddress.province);

    if (newCityOptions[0]) {
      formikContext.setFieldValue("registroCiudad", newCityOptions[0]);
      formikContext.setFieldValue(
        "autocompletedCiudad",
        newCityOptions[0].value
      );
    } else {
      formikContext.setFieldValue("registroCiudad", "");
      formikContext.setFieldValue("autocompletedCiudad", "");
    }
    formikContext.setFieldTouched("registroCiudad");

    formikContext.setFieldValue("registroCodigoPostal", newAddress.postalCode);
    formikContext.setFieldValue(
      "autocompletedCodigoPostal",
      newAddress.postalCode
    );
    formikContext.setFieldTouched("registroCodigoPostal");

    formikContext.setFieldValue("registroPiso", "");

    if (newAddress.lat) {
      formikContext.setFieldValue("latitud", newAddress.lat);
    } else {
      formikContext.setFieldValue("latitud", "");
    }
    if (newAddress.lng) {
      formikContext.setFieldValue("longitud", newAddress.lng);
    } else {
      formikContext.setFieldValue("longitud", "");
    }
  };

  const postalCodeChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value.replace(/\D/g, "");
    if (e.target.value.length > 5) {
      e.target.value = e.target.value.substring(0, 5);
    }
    const postalCode = e.target.value;

    if (postalCode.length === 5) {
      const code = postalCode.substring(0, 2);
      const prov = provincias.find((item: any) => {
        return item.code === code && item.businessCountry === "es";
      });
      if (prov) {
        formikContext.setFieldValue("registroProvincia", prov.value);
      }

      const newCityOptions = await cityOptionsForPostalCode(postalCode);
      setCityOptions(newCityOptions);
      if (newCityOptions.length === 0) {
        formikContext.setFieldValue("registroCiudad", "");
      }
      if (newCityOptions[0]) {
        formikContext.setFieldValue("registroCiudad", newCityOptions[0]);
      }
      formikContext.setFieldTouched("registroCiudad");
    }
  };

  const toggleNoBot = () => {
    formikContext.setFieldValue("noBot", !formikContext.values.noBot);
  };

  const toggleWorkRegime = (regime: string) => {
    const currentRegime: string[] = formikContext.values.R_gimen_de_trabajo;
    if (currentRegime.indexOf(regime) !== -1) {
      formikContext.setFieldValue(
        "R_gimen_de_trabajo",
        currentRegime.filter((r) => r !== regime)
      );
    } else {
      formikContext.setFieldValue("R_gimen_de_trabajo", [
        ...currentRegime,
        regime,
      ]);
    }
  };

  const toggleTimeSlot = (slot: string) => {
    const currentTimeSlot: string[] = formikContext.values.Franja_horaria;
    if (currentTimeSlot.indexOf(slot) !== -1) {
      formikContext.setFieldValue(
        "Franja_horaria",
        currentTimeSlot.filter((s) => s !== slot)
      );
    } else {
      formikContext.setFieldValue("Franja_horaria", [
        ...currentTimeSlot,
        slot
      ]);
    }
  };

  return {
    formikContext,
    cityOptions,
    timeSlotOptions,
    formActions: {
      changeAddressStreet,
      selectAddress,
      postalCodeChange,
      toggleNoBot,
      toggleWorkRegime,
      toggleTimeSlot
    },
  };
}

export type TSignUpStep2FormContext = ReturnType<
  typeof useSignUpStep2FormContext
>;
