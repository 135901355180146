import { IconButton, InputAdornment, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Form, Formik } from "formik";
import { motion } from "framer-motion";
import React, { Fragment, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import { userSignIn } from "../actions";
import { FormikOutlinedTextField } from "../components/FormikFields";
import { apiEmpleo } from "../util/ApiEmpleo";
import useFooter from "../util/useFooter";
import useHeader from "../util/useHeader";
import usePageTitle from "../util/usePageTitle";
import cuideoTheme from "./themes/defaultTheme";
import { JOB_SIGN_IN } from "../constants/routes";

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  min-height: calc(100vh - 10rem);
  width: 100%;

  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    min-height: 38rem;
  }
`;

const LoginBoxesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const StyledBoxTitle = styled.h2`
  font-family: "Lora", Georgia, serif;
  font-size: 1.5rem;
  line-height: 1.4;
  color: #004b7b;
  font-weight: 700;
  margin: 0;
`;
const StyledBoxSubtitle = styled.p`
  font-family: "Lora", Georgia, serif;
  font-size: 1.5rem;
  line-height: 1.4;
  color: #5bc0be;
  font-weight: 700;
  margin: 0;
`;

const StyledForgotLink = styled(Link)`
  text-decoration: none;
  color: #1dbbfa;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  display: block;
  margin: 0 0 1.5rem;
`;

const boxVariants = {
  shown: {
    opacity: 1,
    scale: 1,
    transition: { duration: 0.25, delay: 0.25 },
  },
  hidden: {
    opacity: 0,
    scale: 1.2,
    transition: { duration: 0.1 },
  },
};

const LoginExistingUserBox = styled(motion.div)`
  background-color: #fff;
  padding: 2rem;
  width: 24rem;
  border-radius: 0.5rem;
`;

const StyledHelperText = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  line-height: normal;
  margin: 1rem 0 0.5rem;
  font-size: 0.875rem;
`;

const StyledErrorBox = styled.div`
  color: ${cuideoTheme.palette.error.main};
  background-color: ${(cuideoTheme.palette.error as any)[100]};
  padding: 1rem;
  margin-top: 1rem;
  border: 1px solid ${cuideoTheme.palette.error.main};
  border-radius: ${cuideoTheme.shape.borderRadius}px;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
`;

const JobsResetPassword = (props: any) => {
  const { formatMessage } = props.intl;
  const [error, setError] = useState("");
  const [confirmCode, setConfirmCode] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [phase, setPhase] = useState(1);
  const [eventId, setEventId] = useState("");
  useFooter({
    type: "normal",
  });
  useHeader({
    type: "simple",
    showHelpLink: true,
  });
  usePageTitle(
    formatMessage({
      id: "JobsResetPassword.Resetea la contraseña",
      defaultMessage: "Resetea la contraseña",
    })
  );

  const phaseOneValidationSchema = Yup.object({
    dni: Yup.string().required(
      formatMessage({
        id: "JobsResetPassword.Introduce tu DNI o Email",
        defaultMessage: "Introduce tu DNI o Email",
      })
    ),
  });

  const phaseTwoValidationSchema = Yup.object({
    code: Yup.string().required(
      formatMessage({ id: "Debes introducir el código" })
    ),
  });

  const phaseThreeValidationSchema = Yup.object({
    password: Yup.string()
      .required(formatMessage({ id: "Debes introducir una nueva contraseña" }))
      .min(
        6,
        formatMessage({ id: "La contraseña debe tener al menos 6 caracteres" })
      ),
  });

  const handlePasswordVisibilityChange = (e: any) => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = (values: any) => {
    // Cleanup errors...
    setError("");

    if (phase === 1) {
      const sendPhaseOneData = async (username: string) => {
        try {
          let res = await apiEmpleo.post("users/resetPassword", {
            username,
          });
          if (res && res.data && res.data.eventId) {
            setEventId(res.data.eventId);
            setPhase(2);
          } else {
            setError(formatMessage({ id: "Ha habido un error" }));
          }
        } catch (e: any) {
          if (e.response.status === 400) {
            setError(
              formatMessage({
                id: "JobsResetPassword.No es válido",
                defaultMessage: "No es válido",
              })
            );
          } else {
            setError(formatMessage({ id: "Ha habido un error" }));
          }
        }
      };

      // Call API to create an activity to reset password
      const dni = values.dni;
      sendPhaseOneData(dni);
    }
    if (phase === 2) {
      const sendPhaseTwoData = async (code: string) => {
        try {
          await apiEmpleo.post("users/resetPassword/confirmUser", {
            eventId: eventId,
            confirmCode: code,
          });
          setConfirmCode(code);
          setPhase(3);
        } catch (e: any) {
          if (e.response.status === 400) {
            setError(
              formatMessage({
                id: "El código introducido no es válido",
              })
            );
          } else {
            setError(formatMessage({ id: "Ha habido un error" }));
          }
        }
      };
      const code = values.code;
      sendPhaseTwoData(code);
    }
    if (phase === 3) {
      const sendPhaseThreeData = async (newPassword: string) => {
        try {
          await apiEmpleo.post("users/resetPassword/setPassword", {
            eventId: eventId,
            confirmCode: confirmCode,
            newPassword: newPassword,
          });
          setPhase(4);
        } catch (e: any) {
          if (e.response.status === 400) {
            setError(
              formatMessage({
                id: "Ha habido un error",
              })
            );
          }
        }
      };
      const newPassword = values.password;
      sendPhaseThreeData(newPassword);
    }
  };

  return (
    <LoginContainer>
      <LoginBoxesWrapper>
        <LoginExistingUserBox
          initial="hidden"
          animate="shown"
          variants={boxVariants}
        >
          <StyledBoxTitle>
            <FormattedMessage
              id="JobsReset.LostPassword"
              defaultMessage="¿Perdiste la Contraseña?"
            />
          </StyledBoxTitle>
          <StyledBoxSubtitle>
            <FormattedMessage
              id="JobsReset.Reset"
              defaultMessage="Resetea la contraseña"
            />
          </StyledBoxSubtitle>
          {error && <StyledErrorBox>{error}</StyledErrorBox>}
          {phase === 1 && (
            <Formik
              initialValues={{ dni: "" }}
              validationSchema={phaseOneValidationSchema}
              onSubmit={(values: any, actions: any) => {
                handleSubmit(values);
              }}
            >
              {(props: any) => {
                return (
                  <Form>
                    <FormikOutlinedTextField
                      name="dni"
                      required
                      variant="outlined"
                      margin="normal"
                      size="medium"
                      label={
                        <FormattedMessage
                          id="JobsReset.DniEmail"
                          defaultMessage="Introduce tu DNI o Email"
                        />
                      }
                      autoFocus
                      fullWidth
                    />

                    <div>
                      <StyledForgotLink to={JOB_SIGN_IN}>
                        <FormattedMessage
                          id="JobsReset.Login"
                          defaultMessage="Vuelve a la pantalla de Login"
                        />
                      </StyledForgotLink>
                    </div>

                    <div>
                      <Button
                        type="submit"
                        color="primary"
                        size="large"
                        variant="outlined"
                      >
                        <FormattedMessage
                          id="JobsReset.ResetPassword"
                          defaultMessage="Resetea la contraseña"
                        />
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
          {phase === 2 && (
            <Formik
              initialValues={{ code: "" }}
              validationSchema={phaseTwoValidationSchema}
              onSubmit={(values: any, actions: any) => {
                handleSubmit(values);
              }}
            >
              {(props: any) => {
                return (
                  <Form>
                    <StyledHelperText>
                      <FormattedMessage
                        id="JobsReset.Code"
                        defaultMessage="Te acabamos de mandar un código a tu teléfono para confirmar que eres tú. Introducelo y podrás poner una contraseña nueva"
                      />
                    </StyledHelperText>
                    <FormikOutlinedTextField
                      name="code"
                      required
                      variant="outlined"
                      margin="normal"
                      size="medium"
                      label={
                        <FormattedMessage
                          id="JobsReset.EnterCode"
                          defaultMessage="Código"
                        />
                      }
                      autoFocus
                      fullWidth
                    />

                    <div>
                      <StyledForgotLink to={JOB_SIGN_IN}>
                        <FormattedMessage
                          id="JobsReset.Login"
                          defaultMessage="Vuelve a la pantalla de Login"
                        />
                      </StyledForgotLink>
                    </div>

                    <div>
                      <Button
                        type="submit"
                        color="primary"
                        size="large"
                        variant="outlined"
                      >
                        <FormattedMessage
                          id="JobsReset.CheckCode"
                          defaultMessage="Comprobar código"
                        />
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
          {phase === 3 && (
            <Formik
              initialValues={{ password: "" }}
              validationSchema={phaseThreeValidationSchema}
              onSubmit={(values: any, actions: any) => {
                handleSubmit(values);
              }}
            >
              {(props: any) => {
                return (
                  <Form>
                    <StyledHelperText>
                      <FormattedMessage
                        id="JobsReset.ChangePass"
                        defaultMessage="Perfecto, ahora sólo tienes que seleccionar una contraseña nueva y podrás acceder al portal"
                      />
                    </StyledHelperText>
                    <FormikOutlinedTextField
                      name="password"
                      required
                      variant="outlined"
                      margin="normal"
                      size="medium"
                      label={
                        <FormattedMessage
                          id="JobsReset.NewPassword"
                          defaultMessage="Nueva contraseña"
                        />
                      }
                      autoFocus
                      type={passwordVisible ? "text" : "password"}
                      fullWidth
                      forceComponentUpdate={true}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="cambia visibilidad contraseña"
                            onClick={handlePasswordVisibilityChange}
                          >
                            {passwordVisible ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />

                    <div>
                      <StyledForgotLink to={JOB_SIGN_IN}>
                        <FormattedMessage
                          id="JobsReset.Login"
                          defaultMessage="Vuelve a la pantalla de Login"
                        />
                      </StyledForgotLink>
                    </div>

                    <div>
                      <Button
                        type="submit"
                        color="primary"
                        size="large"
                        variant="outlined"
                      >
                        <FormattedMessage
                          id="JobsReset.SaveNewPassword"
                          defaultMessage="Guarda la nueva contraseña"
                        />
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
          {phase === 4 && (
            <Fragment>
              <StyledHelperText>
                <FormattedMessage
                  id="JobsReset.Completed"
                  defaultMessage="Ya está! Ya puedes volver al Login y acceder al portal usando tu DNI y tu nueva contraseña."
                />
              </StyledHelperText>
              <div>
                <StyledForgotLink to={JOB_SIGN_IN}>
                  <FormattedMessage
                    id="JobsReset.Login"
                    defaultMessage="Vuelve a la pantalla de Login"
                  />
                </StyledForgotLink>
              </div>
            </Fragment>
          )}
        </LoginExistingUserBox>
      </LoginBoxesWrapper>
    </LoginContainer>
  );
};

const mapStateToProps = ({ auth }: { auth: { authUser: string } }) => {
  const { authUser } = auth;
  return { authUser };
};

export default connect(mapStateToProps, { userSignIn })(
  injectIntl<any, any>(JobsResetPassword)
);
