import { TMessageResponse } from "../../../../../apiCoreV3/message/apiMessage.types";
import { THsmTemplate } from "./templateAction.types";

export const BOT_CONTACT_ID = "ec3e894e-ccc1-4655-8fbb-37412f567304";

export const MESSAGE_DIRECTION = {
  SENT: "sent",
  RECEIVED: "received",
} as const;

export type TMessageDirection =
  typeof MESSAGE_DIRECTION[keyof typeof MESSAGE_DIRECTION];

export type TMessage = Omit<TMessageResponse, "content"> & {
  direction: TMessageDirection;
  content: TMessageContent;
  showAuthor: boolean;
  marginBottom: boolean;
};

export type TMessageContent = {
  text?: string;
  interactive?: {
    type: "button_reply" | "button";
    reply?: {
      text: string;
    };
    text?: string;
    body?: {
      text: string;
    };
  };
  hsm?: {
    templateName: THsmTemplate;
    params: any[];
  };
  image?: {
    caption?: string;
    url: string;
  };
  audio?: {
    caption?: string;
    url: string;
  };
  video?: {
    caption?: string;
    url: string;
  };
  file?: {
    caption?: string;
    url: string;
  };
};
