import { useQuery } from "react-query";
import { apiListAllServicesManagerInfo } from "../../../../../api/service/apiListAllServicesManagerInfo";
import { SERVICE_STATE } from "../../../../../api/service/service.types";
import { serviceIsUnassigned } from "../../../../../apps/affinity/models/Service/ServiceManagerInfo";

export default function useUnassignedUnratedServicesQuery(
  refetchInterval = 30000
) {
  return useQuery(
    ["unassigned_unrated_services"],
    async () => {
      const res = await apiListAllServicesManagerInfo({
        state: [SERVICE_STATE.WITHOUT_VALIDATION],
      });

      const filteredServices = res["hydra:member"].filter((service) => {
        return serviceIsUnassigned(service);
      });

      return {
        unassignedServices: filteredServices,
      };
    },
    {
      enabled: true,
      refetchInterval,
    }
  );
}
