import { Box, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const StyledBox = styled(Box)`
  .MuiTypography-root {
    font-size: 1rem;
    line-height: 1.35;
  }
`;

const InscriptionsTabHelp = () => {
  return (
    <StyledBox>
      <Typography>
        En este listado se muestran las valoraciones o aplicaciones a ofertas
        del Candidato cuyo contrato correspondiente esté en uno de los
        siguientes estados: Alta Pendiente, En Espera, Paga y Reserva, CV
        Cliente o Presencial Cliente
      </Typography>
    </StyledBox>
  );
};

export default InscriptionsTabHelp;
