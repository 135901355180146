import {
  Box,
  Card,
  CardActions,
  CardContent,
  Chip,
  Grid,
  Typography,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CheckIcon from "@material-ui/icons/Check";
import { motion, Variants } from "framer-motion";
import moment from "moment";
import "moment/locale/es";
import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import LocationIcon from "../../../../assets/images/iconos/location.svg";
import HorarioIcon from "../../../../components/CuideoIcons/HorarioIcon.svg";
import MoneyIcon from "../../../../components/CuideoIcons/MoneyIcon.svg";
import EstadoValoracion from "../../../../components/Valoraciones/EstadoValoracion";
import cuideoTheme from "../../../../containers/themes/defaultTheme";
import { OfertaType } from "../../../../types/OfertaType";
import { formatCurrency } from "../../../../util/formatNumbers";
import { stringWithLineBreaksToJsx } from "../../../../util/nl2stringArray";
import useWindowWidth from "../../../../util/useWindowWidth";
import AplicarOfertaButton from "../ApplyOfferButton/ApplyOfferButton";
import { JOB_OFFERS } from "../../../../constants/routes";

const StyledCard = styled(Card)`
  color: ${cuideoTheme.palette.primary.main};
  margin-bottom: 1rem;
  box-shadow: none;

  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    padding: 1rem;
    box-shadow: 0 2px 4px 0 #a2c4d8;
  }

  .MuiCardHeader-title {
    font-weight: 700;
  }
`;

const StyledCardContentHeadDetails = styled(CardContent)`
  color: ${cuideoTheme.palette.primary.main};
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  padding-bottom: 0.25rem;
`;

const StyledCardHeader = styled.h5`
  padding: 0 1rem 0.5rem 1rem;
  margin: 0;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-weight: 700;
`;

const StyledHeaderTitle = styled.span`
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 1.375rem;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 1.625rem;
    line-height: 2rem;
    margin-right: 0.5rem;
  }
`;

const StyledServicioPuntual = styled.span`
  color: ${cuideoTheme.palette.primary.main};
  font-weight: 400;
  background-color: rgba(235, 194, 0, 0.3);
  border: 1px solid #ebc200;
  border-radius: ${cuideoTheme.shape.borderRadius}px;
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 1rem;
    padding: 0.375rem 0.5625rem;
    display: block;
    width: fit-content;
    margin: 0.25rem 0;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 1rem;
    padding: 0.375rem 0.5625rem;
    display: block;
    width: fit-content;
    margin: 0.25rem 0;
  }
`;

const StyledCardContent = styled(CardContent)`
  padding-top: 0;
`;

const StyledChip = styled(Chip)`
  border-radius: ${cuideoTheme.shape.borderRadius}px;
  margin-right: 0.5rem;
  margin-bottom: 0.25rem;
  font-size: 1rem;
  padding: 1.2rem 0.25rem;
`;

const StyledIcon = styled.img`
  width: 1rem;
  max-height: 1rem;
  opacity: 0.5;
  margin-right: 0.5rem;
`;

const StyledContentBox = styled(Box)`
  border-top: 1px solid #e4ebef;

  margin: 1.5rem 0;

  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    padding-right: 22.222222%;
  }
`;

const StyledSectionHeader = styled.h4`
  color: ${cuideoTheme.palette.primary.main};
  font-weight: 700;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-size: 1.25rem;
`;

const StyledAplicarOfertaButtonWrapper = styled.div``;

const StyledAplicarOfertaButton = styled(AplicarOfertaButton)`
  font-size: 1.1rem;
`;

const StyledLink = styled(Link)`
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  text-decoration: none;
  color: ${cuideoTheme.palette.primary.main};
  font-size: 0.75rem;
  opacity: 0.5;
  margin: 1rem;
`;

const StyledArrowBackIosIcon = styled(ArrowBackIosIcon)`
  font-size: 0.8rem;
`;

const linkVariants: Variants = {
  entry: {
    opacity: 1,
    x: 0,
  },
  exit: {
    opacity: 0,
    x: 15,
  },
};

const StyledBackLinkWrapper = styled(motion.div)`
  margin-top: 1rem;
`;

const StyledCardActions = styled(CardActions)`
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    padding: 1rem;
  }
`;

const StyledOfertaActionArea = styled.div`
  display: block;
  margin: 0 1rem;
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    margin-right: 1rem;
    margin-left: auto;
    margin-top: 1rem;
    text-align: right;
  }
`;

const StyledEstadoWrapper = styled.div`
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  border: 2px solid #c2d1da;
  border-radius: 0.25rem;
  padding: 0.375rem;
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin-bottom: 0.25rem;
`;

const StyledEstadoLabel = styled.span`
  font-size: 1rem;
  font-weight: 700;
  color: #a2c3d8;
  flex-shrink: 1;
  margin-right: 0.5rem;
`;

const StyledConditions = styled(Typography)`
  font-size: 0.875rem;
  font-style: italic;
  line-height: 1.35;
`;

interface Props {
  oferta: OfertaType;
  authUser: any;
  appliedOffers: any;
}

const FullOfertaCard = (props: Props) => {
  const { oferta, authUser, appliedOffers } = props;

  const width = useWindowWidth();

  // eslint-disable-next-line
  const appliedOffer = appliedOffers.find((item: any) => {
    if (item.contratoId === oferta.id) return true;
  });
  const found = !!appliedOffer;
  const isApplied = !!found;

  return (
    <StyledCard>
      {width < 960 && (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <StyledBackLinkWrapper
              initial="exit"
              animate="entry"
              variants={linkVariants}
            >
              <StyledLink
                to={JOB_OFFERS}
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  window.history.back();
                }}
              >
                <StyledArrowBackIosIcon />
                <FormattedMessage
                  id="FullOfferCard.Back"
                  defaultMessage="Volver"
                />
              </StyledLink>
            </StyledBackLinkWrapper>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <StyledCardContentHeadDetails>
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs={6} md={3}>
                {moment(oferta.Fecha_publicacion_oferta).fromNow()}
              </Grid>
              <Grid item xs={6} md={9}>
                ID: {oferta.Name}
              </Grid>
            </Grid>
          </StyledCardContentHeadDetails>

          <StyledCardHeader>
            <StyledHeaderTitle>{oferta.T_tulo}</StyledHeaderTitle>
            {oferta.Servicio_corta_duraci_n === "Si" && (
              <StyledServicioPuntual>
                <FormattedMessage
                  id="OfferCard.Puntual"
                  defaultMessage="Servicio Puntual"
                />
              </StyledServicioPuntual>
            )}
          </StyledCardHeader>
          <StyledCardContent>
            <Box>
              <StyledIcon src={LocationIcon} />
              <Typography component="span">{oferta.Ciudad_oferta}</Typography>
            </Box>
            <Box>
              <StyledIcon src={HorarioIcon} />
              <Typography component="span">
                {oferta.Horario_Servicio}
              </Typography>
            </Box>
            <Box>
              <StyledIcon src={MoneyIcon} />
              <Typography component="span" color="secondary">
                <strong>
                  <FormattedMessage
                    id="OfferCard.GrossSalary"
                    defaultMessage="Salario bruto:"
                  />
                  &nbsp;
                  {oferta.Salario_Bruto_Mensual ? (
                    <Fragment>
                      {formatCurrency(oferta.Salario_Bruto_Mensual)}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <FormattedMessage
                        id="OfferCard.NotProvided"
                        defaultMessage="No indicado"
                      />
                    </Fragment>
                  )}
                  {oferta.Tipo_de_salario &&
                    oferta.Tipo_de_salario === "Mensual" && (
                      <FormattedMessage
                        id="OfferCard.PerMonth"
                        defaultMessage="/mes"
                      />
                    )}
                  {oferta.Tipo_de_salario &&
                    oferta.Tipo_de_salario === "Diario" && (
                      <FormattedMessage
                        id="OfferCard.PerDay"
                        defaultMessage="/día"
                      />
                    )}
                </strong>
              </Typography>
            </Box>
          </StyledCardContent>
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledOfertaActionArea>
            {isApplied && (
              <StyledEstadoWrapper>
                <StyledEstadoLabel>
                  <FormattedMessage
                    id="FullOfferCard.Status"
                    defaultMessage="Estado:"
                  />
                </StyledEstadoLabel>
                <EstadoValoracion
                  estadoValoracion={appliedOffer.estadoEnProceso}
                  publicar={oferta.Publicar}
                  candidatoSexo={authUser.Sexo}
                />
              </StyledEstadoWrapper>
            )}
            <StyledAplicarOfertaButtonWrapper>
              <StyledAplicarOfertaButton size="large" fullWidth offer={oferta}>
                <FormattedMessage
                  id="OfferCard.ApplyOffer"
                  defaultMessage="Aplicar a la Oferta"
                />
              </StyledAplicarOfertaButton>
            </StyledAplicarOfertaButtonWrapper>
          </StyledOfertaActionArea>
        </Grid>
      </Grid>

      <StyledCardContent>
        <StyledContentBox>
          <StyledSectionHeader>
            <FormattedMessage
              id="FullOfferCard.WhatWeSearch"
              defaultMessage="¿Qué buscamos?"
            />
          </StyledSectionHeader>
          <Typography component="p">
            {!!oferta.Que_buscamos
              ? stringWithLineBreaksToJsx(oferta.Que_buscamos)
              : "-"}
          </Typography>
        </StyledContentBox>

        <StyledContentBox>
          <StyledSectionHeader>
            <FormattedMessage
              id="FullOfferCard.Requisites"
              defaultMessage="Requisitos"
            />
          </StyledSectionHeader>
          <Typography component="p">
            {!!oferta.Requisitos
              ? stringWithLineBreaksToJsx(oferta.Requisitos)
              : "-"}
          </Typography>
        </StyledContentBox>

        <StyledContentBox>
          <StyledSectionHeader>
            <FormattedMessage
              id="FullOfferCard.Tasks"
              defaultMessage="Tareas a realizar:"
            />
          </StyledSectionHeader>
          {oferta.Tareas_solicitadas.map((item: any, index: any) => (
            <StyledChip
              icon={
                authUser &&
                authUser.candidato &&
                authUser.candidato.Qu_tareas_quieres_o_puedes_realizar.includes(
                  item
                ) ? (
                  <CheckIcon />
                ) : undefined
              }
              label={item}
              key={item}
              variant="outlined"
              color="secondary"
            />
          ))}
        </StyledContentBox>

        <StyledContentBox>
          <Grid container>
            <Grid item xs={6}>
              <StyledSectionHeader>
                <FormattedMessage
                  id="OfferCard.GrossSalary"
                  defaultMessage="Salario bruto:"
                />
              </StyledSectionHeader>
            </Grid>
            <Grid item xs={6}>
              <Box style={{ textAlign: "right" }}>
                <StyledSectionHeader>
                  {oferta.Salario_Bruto_Mensual ? (
                    <Fragment>
                      {formatCurrency(oferta.Salario_Bruto_Mensual)}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <FormattedMessage
                        id="OfferCard.NotProvided"
                        defaultMessage="No indicado"
                      />
                    </Fragment>
                  )}
                  {oferta.Tipo_de_salario &&
                    oferta.Tipo_de_salario === "Mensual" && (
                      <FormattedMessage
                        id="OfferCard.PerMonth"
                        defaultMessage="/mes"
                      />
                    )}
                  {oferta.Tipo_de_salario &&
                    oferta.Tipo_de_salario === "Diario" && (
                      <FormattedMessage
                        id="OfferCard.PerDay"
                        defaultMessage="/día"
                      />
                    )}
                </StyledSectionHeader>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <StyledConditions>
                <FormattedMessage
                  id="FullOfferCard.LegalConditions"
                  defaultMessage="Salario y condiciones prefijadas por la familia. El contrato laboral se establecerá entre el empleador (familia) y el trabajador a través del REEH (Régimen especial de empleadas de hogar). Cuideo, como agencia de colocación registrada con número 0900000353 interviene en el proceso de selección entre empleador y trabajador."
                />
              </StyledConditions>
            </Grid>
          </Grid>
        </StyledContentBox>

        <Grid container spacing={2}>
          <Grid item xs={12}></Grid>
        </Grid>
      </StyledCardContent>
      <StyledCardActions>
        <StyledAplicarOfertaButton size="medium" fullWidth offer={oferta}>
          <FormattedMessage
            id="OfferCard.ApplyOffer"
            defaultMessage="Aplicar a la Oferta"
          />
        </StyledAplicarOfertaButton>
      </StyledCardActions>
    </StyledCard>
  );
};

const mapStateToProps = ({ auth }: { auth: any }) => {
  const { authUser, appliedOffers } = auth;

  return { authUser, appliedOffers };
};

export default connect(mapStateToProps, {})(FullOfertaCard);
