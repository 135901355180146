import moment from "moment";
import "moment/locale/es";

const evaluateOffer = (offer: any, authUser?: any) => {
  let score = 0;
  if (authUser && authUser.candidato) {
    if (offer.Provincia_oferta === authUser.candidato.Provincia) {
      score += 10;
    }
    if (offer.Tipo === "Interna entre semana") {
      if (authUser.candidato.Interna_entre_semana) {
        score += 10;
      }
    }
    if (offer.Tipo === "Interna fin de semana") {
      if (authUser.candidato.Interna_fin_de_semana) {
        score += 10;
      }
      // if (authUser.candidato.Interna_entre_semana) {
      //   score += 5;
      // }
    }
    if (offer.Tipo === "Externa entre semana") {
      if (authUser.candidato.Externa_entre_semana) {
        score += 10;
      }
    }
    if (offer.Tipo === "Externa fin de semana") {
      if (authUser.candidato.Externa_fin_de_semana) {
        score += 10;
      }
      // if (authUser.candidato.Externa_entre_semana) {
      //   score += 5;
      // }
    }
    // Tareas: Each tarea in offer not on candidato -2
    offer.Tareas_solicitadas.forEach((item: any) => {
      if (
        !authUser.candidato.Qu_tareas_quieres_o_puedes_realizar.includes(item)
      ) {
        score -= 2;
      }
    });
  }
  const evaluatedOffer = {
    ...offer,
    score,
  };

  return evaluatedOffer;
};

const compareOffers = (a: any, b: any) => {
  if (a.score > b.score) {
    return -1;
  }
  if (a.score < b.score) {
    return 1;
  }

  const aDate = moment(a.Fecha_publicacion_oferta);
  const bDate = moment(b.Fecha_publicacion_oferta);
  if (aDate > bDate) {
    return -1;
  }
  if (aDate < bDate) {
    return 1;
  }

  return 0;
};

export { evaluateOffer, compareOffers };
