import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useState } from "react";
import styled from "styled-components";
import cuideoTheme from "../../containers/themes/defaultTheme";

const StyledAvatar = styled(Avatar)`
  background-color: #ebc200;
  color: #004b7b;
`;

const StyledMenu = styled(Menu)`
  .MuiMenu-paper {
    min-width: 16rem;

    .MuiMenu-list {
      padding: 0;
    }
  }

  .CMuiLoggedUserButtonMenuItem-header {
    opacity: 1;
    padding: 0.75rem 1rem;
    background-color: rgba(0, 72, 120, 0.05);

    .CMuiLoggedUserButtonHeaderName {
      font-weight: bold;
      font-size: 1.125rem;
      line-height: 1.35;
      color: ${cuideoTheme.palette.primary.main};
    }
    .CMuiLoggedUserButtonHeaderEmail {
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.25;
      color: ${cuideoTheme.palette.primary.main};
      opacity: 0.75;
    }
    .CMuiLoggedUserButtonHeaderAvatar {
      .MuiAvatar-root {
        width: 2.25rem;
        height: 2.25rem;
      }
    }
  }

  .CMuiLoggedUserButtonMenuItem {
    padding: 0.75rem 1rem;
    border-top: 1px solid rgb(238, 238, 238);

    .MuiSvgIcon-root {
      width: 1.25rem;
      height: 1.25rem;
      color: ${cuideoTheme.palette.primary.main};
    }
    .CMuiLoggedUserButtonMenuItemLabel {
      font-size: 1rem;
      color: ${cuideoTheme.palette.primary.main};
      line-height: 1.25;
      font-weight: bold;
      margin: 0 0 0.375rem;
    }
    .CMuiLoggedUserButtonMenuItemDescription {
      font-size: 0.875rem;
      color: ${cuideoTheme.palette.primary.main};
      line-height: 1.25;
    }
  }

  .CMuiLoggedUserButtonMenuItem-footer {
    opacity: 1;
    background-color: rgba(0, 72, 120, 0.05);
    border-top: 1px solid rgb(238, 238, 238);
    display: block;
  }
  .CMuiTypography-footer {
    display: block;
    width: 100%;
    text-align: right;
    font-size: 0.625rem;
    color: ${cuideoTheme.palette.primary.main};
  }
`;

export type TLoggedUserButtonOption = {
  label: string;
  description?: string;
  icon?: any;
  className?: string;
  onClick: () => void;
  weight?: number;
};

export interface ILoggedUserButtonProps {
  avatarUrl?: string;
  userFullName?: string;
  userEmail?: string;
  userInitials?: string;
  options: TLoggedUserButtonOption[];
}

const LoggedUserButton = (props: ILoggedUserButtonProps) => {
  const { avatarUrl, userFullName, userEmail, userInitials } = props;
  const options: TLoggedUserButtonOption[] = props.options || [];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <IconButton
        aria-haspopup="true"
        aria-label={userFullName}
        color="inherit"
        onClick={handleClick}
      >
        {avatarUrl ? (
          <Avatar src={avatarUrl} />
        ) : (
          <StyledAvatar>{userInitials}</StyledAvatar>
        )}
      </IconButton>
      <StyledMenu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        className="CMuiLoggedUserButtonMenu"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={!!anchorEl}
        onClose={handleClose}
      >
        <MenuItem className="CMuiLoggedUserButtonMenuItem-header" disabled>
          <Grid
            container
            spacing={4}
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Grid item>
              <Typography className="CMuiLoggedUserButtonHeaderName">
                {userFullName}
              </Typography>
              <Typography className="CMuiLoggedUserButtonHeaderEmail">
                {userEmail}
              </Typography>
            </Grid>
            <Grid item className="CMuiLoggedUserButtonHeaderAvatar">
              {avatarUrl ? (
                <Avatar src={avatarUrl} />
              ) : (
                <StyledAvatar>{userInitials}</StyledAvatar>
              )}
            </Grid>
          </Grid>
        </MenuItem>
        {options.map((option, index) => {
          return (
            <MenuItem
              key={`lub-${index}`}
              className="CMuiLoggedUserButtonMenuItem"
              onClick={() => {
                handleClose();
                option.onClick();
              }}
              dense
            >
              <Grid container spacing={4} alignItems="flex-start">
                {option.icon && (
                  <Grid item>
                    <Box className="CMuiLoggedUserButtonMenuItemIcon">
                      {option.icon}
                    </Box>
                  </Grid>
                )}
                <Grid item>
                  <Typography className="CMuiLoggedUserButtonMenuItemLabel">
                    {option.label}
                  </Typography>
                  {option.description && (
                    <Typography className="CMuiLoggedUserButtonMenuItemDescription">
                      {option.description}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </MenuItem>
          );
        })}
        <MenuItem
          className="CMuiLoggedUserButtonMenuItem-footer"
          disabled
        ></MenuItem>
      </StyledMenu>
    </Fragment>
  );
};

export { LoggedUserButton };
