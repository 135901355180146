import { Form, Formik } from "formik";
import moment from "moment";
import React, { Fragment } from "react";
import { geocodeAddress } from "../../../../util/geocodeAddress";
import { TStepSubmitFn } from "../../models/CandidateSignUp.model";
import useSignUpStep2ValidationSchema from "./hooks/useSignUpStep2ValidationSchema";
import SignUpStep2InnerForm from "./SignUpStep2InnerForm";
import { EMPLOYEE_TIME_SLOT } from "../../../../api/employee/employee.types";

interface Props {
  onSubmit: TStepSubmitFn;
}

const SignUpStep2: React.FC<Props> = (props) => {
  const { onSubmit } = props;
  const validationSchema = useSignUpStep2ValidationSchema();

  const handleBack = (e: React.MouseEvent) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    onSubmit({ step: 1 });
  };

  const initialValues = {
    Pais_nacimiento: "",
    Sexo: "",
    avatar: null,
    FechaDia: "",
    FechaMes: "",
    FechaYear: "",
    fechaNacimiento: "",
    registroCalle: "",
    autocompletedCalle: "",
    registroNumero: "",
    autocompletedNumero: "",
    registroBloque: "",
    registroPiso: "",
    registroProvincia: "",
    registroCiudad: null,
    autocompletedCiudad: "",
    registroCodigoPostal: "",
    autocompletedCodigoPostal: "",
    longitud: "",
    latitud: "",
    permisoConducir: "",
    cochePropio: "",
    A_os_de_experiencia: "",
    R_gimen_de_trabajo: [],
    idiomas: [],
    titulaciones: "",
    Externa_entre_semana: false,
    Externa_fin_de_semana: false,
    Interna_entre_semana: false,
    Interna_fin_de_semana: false,
    noBot: false,
    acceptTerms: false,
    Franja_horaria: []
  };

  const handleFormSubmit = (values: any, actions: any) => {
    const sendDataToParent = async (values: any) => {

      const timeSlot = (values.Interna_entre_semana || values.Interna_fin_de_semana)
        ? [EMPLOYEE_TIME_SLOT.MORNING, EMPLOYEE_TIME_SLOT.NOON, EMPLOYEE_TIME_SLOT.AFTERNOON]
        : values.Franja_horaria;

      const data = {
        sexo: values.Sexo,
        paisNacimiento: values.Pais_nacimiento,
        fechaNacimiento: moment(values.fechaNacimiento, "YYYY-M-D")
          .utcOffset("+0200")
          .format("YYYY-MM-DD"),
        direccion: values.registroCalle,
        permisoConducir: values.permisoConducir,
        cochePropio: values.cochePropio,
        A_os_de_experiencia: (values.A_os_de_experiencia as any).value,
        Externa_entre_semana: values.Externa_entre_semana,
        Externa_fin_de_semana: values.Externa_fin_de_semana,
        Interna_entre_semana: values.Interna_entre_semana,
        Interna_fin_de_semana: values.Interna_fin_de_semana,
        ciudad: values.registroCiudad,
        codigoPostal: values.registroCodigoPostal,
        calle: values.registroCalle,
        bloque: values.registroBloque,
        numero: values.registroNumero,
        piso: values.registroPiso,
        provincia: values.registroProvincia,
        cv: values.cv,
        idiomas:
          values.idiomas && values.idiomas.length
            ? values.idiomas.map((item: any) => item.value)
            : [],
        titulaciones:
          values.titulaciones && values.titulaciones.value
            ? [values.titulaciones.value]
            : [],
        tipoTareas: [],
        R_gimen_de_trabajo: values.R_gimen_de_trabajo,
        avatar: values.avatar,
        No_iniciar_bot_hasta: !values.noBot // Now the meaning of the whatsapp bot field phrase has change to positive so we deny the condition
          ? moment("2301-01-01T00:00:00").format("YYYY-MM-DD")
          : null,
        Franja_horaria: timeSlot,
      } as any;
      if (
        values.registroCalle === values.autocompletedCalle &&
        values.registroNumero === values.autocompletedNumero &&
        values.registroCodigoPostal === values.autocompletedCodigoPostal &&
        values.registroCiudad === values.autocompletedCiudad
      ) {
        data.latitud = values.registroLatitud;
        data.longitud = values.registroLongitud;
      } else {
        // The address has been manually changed
        data.latitud = null;
        data.longitud = null;
        // We can try to solve with geocoding the complete address
        // Direccion, Numero, Codigo_postal Ciudad, Provincia, País
        try {
          let geo = await geocodeAddress({
            Direccion: values.registroCalle,
            Numero: values.registroNumero,
            Codigo_postal: values.registroCodigoPostal,
            Ciudad: values.registroCiudad,
            Provincia: values.registroProvincia,
            Pais: values.Pais ? values.Pais : undefined,
          });
          if (geo) {
            data.latitud = geo.lat.toString();
            data.longitud = geo.lng.toString();
          }
        } catch (e) {
          // Error in Google Geocoding. We leave lat & lng as null
        }
      }

      onSubmit({
        sendStatus: "send",
        data,
      });
    };

    sendDataToParent(values);
  };

  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        <Form>
          <SignUpStep2InnerForm onBack={handleBack} />
        </Form>
      </Formik>
    </Fragment>
  );
};

export default SignUpStep2;
