import React, { Fragment } from "react";
import styled from "styled-components";
import { Card, Grid, Typography, CardContent } from "@material-ui/core";
import cuideoTheme from "../../containers/themes/defaultTheme";
import useWindowWidth from "../../util/useWindowWidth";
import LocationIcon from "../../assets/images/iconos/location.svg";
import MoneyIcon from "../CuideoIcons/MoneyIcon.svg";
import HorarioIcon from "../CuideoIcons/HorarioIcon.svg";
import { formatCurrency } from "../../util/formatNumbers";
import IntlMessages from "../../util/IntlMessages";
import moment from "moment";
import "moment/locale/es";
import EstadoValoracion from "./EstadoValoracion";
import { Link } from "react-router-dom";

const StyledCard: any = styled(Card)<{ estadovaloracion: any }>`
  margin: 1rem 0;

  ${({ estadovaloracion }) => {
    if (estadovaloracion === "02_Descartado") {
      return `background-color: #eff4f6;`;
    }
  }}

  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;

const StyledTitle = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0.5rem;
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 1rem;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 1.3125;
  }
`;

const StyledDetail = styled.div`
  opacity: 0.5;
  color: ${cuideoTheme.palette.primary.main};
  line-height: normal;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 0.75rem;
    margin-bottom: 0.25rem;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 0.875rem;
  }
`;

const StyledSalarioDetail = styled.div`
  opacity: 1;
  color: ${cuideoTheme.palette.secondary.main};
  line-height: normal;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;

  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 0.75rem;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 0.875rem;
  }
`;

const StyledCardContent = styled(CardContent)`
  padding: 1rem;
  &:last-child {
    padding: 1rem;
  }
`;

const StyledIcon = styled.img`
  opacity: 0.5;
  margin-right: 0.5rem;
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    max-height: 0.75rem;
    max-width: 0.75rem;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    max-height: 0.875rem;
    max-width: 0.875rem;
  }
`;

const StyledValoracionWrapper = styled.div`
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 0.75rem;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
  }
`;

const StyledTimeAgo = styled(Typography)`
  color: #90b1c6;
  text-align: right;
  line-height: normal;

  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 0.75rem;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 0.875rem;
  }
`;

const StyledGridA1 = styled(Grid)`
  max-width: 70%;
  flex-basis: 70%;
`;
const StyledGridA2 = styled(Grid)`
  max-width: 15%;
  flex-basis: 15%;
`;
const StyledGridA3 = styled(Grid)`
  max-width: 15%;
  flex-basis: 15%;
`;

const StyledGridB1 = styled(Grid)`
  max-width: 20%;
  flex-basis: 20%;
`;
const StyledGridB2 = styled(Grid)`
  max-width: 20%;
  flex-basis: 20%;
`;
const StyledGridB3 = styled(Grid)`
  max-width: 20%;
  flex-basis: 20%;
`;
const StyledGridB4 = styled(Grid)`
  max-width: 40%;
  flex-basis: 40%;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const ValoracionCard = (props: any) => {
  const {
    contratoId,
    ofertaTitle,
    contratoName,
    contratoCiudad,
    salarioBruto,
    tipoSalario,
    horarioServicio,
    valoracionDate,
    estadoValoracion,
    publicar,
    candidatoSexo,
  } = props;

  const width = useWindowWidth();

  const salarioElement = (
    <Fragment>
      {salarioBruto ? (
        <Fragment>{formatCurrency(salarioBruto)}</Fragment>
      ) : (
        <Fragment>
          <IntlMessages id="No indicado" />
        </Fragment>
      )}
      {tipoSalario && tipoSalario === "Mensual" && <IntlMessages id="/mes" />}
      {tipoSalario && tipoSalario === "Diario" && <IntlMessages id="/día" />}
    </Fragment>
  );

  let calculatedEstadoValoracion = estadoValoracion;
  if (!publicar && estadoValoracion !== "06_Contratado") {
    calculatedEstadoValoracion = "02_Descartado";
  }

  if (width < cuideoTheme.breakpoints.values.md) {
    return (
      <StyledCard elevation={0} estadovaloracion={calculatedEstadoValoracion}>
        <StyledCardContent>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={6}>
              <StyledValoracionWrapper>
                <EstadoValoracion
                  estadoValoracion={estadoValoracion}
                  publicar={publicar}
                  size="small"
                  candidatoSexo={candidatoSexo}
                />
              </StyledValoracionWrapper>
            </Grid>
            <Grid item xs={6}>
              <StyledTimeAgo>{moment(valoracionDate).fromNow()}</StyledTimeAgo>
            </Grid>
          </Grid>
          {contratoId ? (
            <StyledLink to={`/empleo/oferta/${contratoId}`}>
              <StyledTitle>{ofertaTitle}</StyledTitle>
            </StyledLink>
          ) : (
            <StyledTitle>{ofertaTitle}</StyledTitle>
          )}
          <StyledDetail>ID: {contratoName}</StyledDetail>
          <Grid container spacing={4} alignItems="flex-start">
            <Grid item xs={6}>
              <StyledDetail>
                <StyledIcon src={LocationIcon} />
                {contratoCiudad}
              </StyledDetail>
            </Grid>
            <Grid item xs={6}>
              <StyledSalarioDetail>
                <strong>
                  <StyledIcon src={MoneyIcon} />
                  {salarioElement}
                </strong>
              </StyledSalarioDetail>
            </Grid>
          </Grid>
          <StyledDetail>
            <StyledIcon src={HorarioIcon} />
            {horarioServicio}
          </StyledDetail>
        </StyledCardContent>
      </StyledCard>
    );
  }

  return (
    <StyledCard elevation={0} estadovaloracion={calculatedEstadoValoracion}>
      <StyledCardContent>
        <Grid container spacing={4} alignItems="center">
          <StyledGridA1 item xs={12} md={8}>
            {contratoId ? (
              <StyledLink to={`/empleo/oferta/${contratoId}`}>
                <StyledTitle>{ofertaTitle}</StyledTitle>
              </StyledLink>
            ) : (
              <StyledTitle>{ofertaTitle}</StyledTitle>
            )}
            <Grid container spacing={4}>
              <StyledGridB1 item xs={12} md={3}>
                <StyledDetail>ID: {contratoName}</StyledDetail>
              </StyledGridB1>
              <StyledGridB2 item xs={12} md={2}>
                <StyledDetail>
                  <StyledIcon src={LocationIcon} />
                  {contratoCiudad}
                </StyledDetail>
              </StyledGridB2>
              <StyledGridB3 item xs={12} md={2}>
                <StyledSalarioDetail>
                  <strong>
                    <StyledIcon src={MoneyIcon} />
                    {salarioElement}
                  </strong>
                </StyledSalarioDetail>
              </StyledGridB3>
              <StyledGridB4 item xs={12} md={5}>
                <StyledDetail>
                  <StyledIcon src={HorarioIcon} />
                  {horarioServicio}
                </StyledDetail>
              </StyledGridB4>
            </Grid>
          </StyledGridA1>
          <StyledGridA2 item xs={12} md={2}>
            <EstadoValoracion
              estadoValoracion={estadoValoracion}
              publicar={publicar}
              candidatoSexo={candidatoSexo}
            />
          </StyledGridA2>
          <StyledGridA3 item xs={12} md={2}>
            <StyledTimeAgo>{moment(valoracionDate).fromNow()}</StyledTimeAgo>
          </StyledGridA3>
        </Grid>
      </StyledCardContent>
    </StyledCard>
  );
};

export default ValoracionCard;
