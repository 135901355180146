// export type TStaffAssignationsServiceStats = {
//   total: number;
//   withoutValuation: {
//     total: number;
//     postponed: number;
//   };
// };

import { TUserServiceAssignationsStats } from "../../../../recruitersManager/types/UserGroupAssignations.types";

export const newStaffAssignationServiceStats =
  (): TUserServiceAssignationsStats => {
    return {
      total: 0,
      withoutValuation: {
        total: 0,
        postponed: 0,
      },
    };
  };
