import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import styled from "styled-components";
import logoImg from "../../assets/images/logo_azul.svg";
import { Button, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import cuideoTheme from "../../containers/themes/defaultTheme";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { connect } from "react-redux";
import useWindowWidth from "../../util/useWindowWidth";
import { APP_START_PAGE } from "../../util/defaults/app";
import { FormattedMessage } from "react-intl";

const StyledAppbar = styled(AppBar)<{ toolbarcolor: string }>`
  background-color: ${() =>
    process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "pre"
      ? "#fff3ba"
      : "#ffffff"};
  color: #004b7b;

  .MuiToolbar-root {
    background-color: ${({ toolbarcolor }) =>
      toolbarcolor === "lightblue"
        ? "#e5edf1"
        : process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "pre"
        ? "#fff3ba"
        : "#ffffff"};
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

const StyledLogo = styled.img`
  width: auto;
  display: block;
  margin: 0 auto;
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    height: 2rem;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    height: 2.375rem;
  }
`;

const StyledLogoWrapper = styled.div`
  width: auto;
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    margin: 0 auto 0 1rem;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    margin: 0 auto;
  }
`;

const StyledBackButton = styled(Button)`
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    min-width: 3rem;
    margin-right: 0.5rem;
    .MuiButton-label {
      letter-spacing: 0.033rem;
    }
  }

  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    min-width: 6rem;
    margin-right: 0.5rem;
    .MuiButton-label {
      letter-spacing: 0.033rem;
      font-size: 1rem;
    }
  }
`;

const StyledLink = styled(Link)`
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  text-decoration: none;
  color: ${cuideoTheme.palette.primary.main};
`;

const StyledArrowBackIosIcon = styled(ArrowBackIosIcon)`
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 1.5rem;
  }

  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 0.8rem;
  }
`;

const SimpleHeader = (props: any) => {
  const { header } = props;
  const width = useWindowWidth();
  const midGridWidth = width < cuideoTheme.breakpoints.values.md ? 7 : 4;

  return (
    <StyledAppbar toolbarcolor="white">
      <Toolbar disableGutters={false}>
        <Grid
          container
          spacing={4}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={1} md={4}>
            <StyledLink
              to="/empleo/candidatos"
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                if (header.backCallback) {
                  header.backCallback();
                } else {
                  window.history.back();
                }
              }}
            >
              <StyledBackButton color="primary" size="small">
                <StyledArrowBackIosIcon />
                {width >= cuideoTheme.breakpoints.values.md && (
                  <FormattedMessage id="Header.Back" defaultMessage="Volver" />
                )}
              </StyledBackButton>
            </StyledLink>
          </Grid>
          <Grid item xs={midGridWidth} md={midGridWidth}>
            <StyledLogoWrapper>
              <Link to={APP_START_PAGE}>
                <StyledLogo
                  src={logoImg}
                  style={midGridWidth === 7 ? { marginLeft: 0 } : {}}
                />
              </Link>
            </StyledLogoWrapper>
          </Grid>
          <Grid item xs={4} md={4} style={{ textAlign: "right" }}></Grid>
        </Grid>
      </Toolbar>
    </StyledAppbar>
  );
};

const mapStateToProps = ({ layout }: { layout: any }) => {
  const { header } = layout;
  return { header };
};

export default connect(mapStateToProps, {})(SimpleHeader);
