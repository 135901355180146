import { Button } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import ReplayIcon from "@material-ui/icons/Replay";

const StyledButton = styled(Button)`
  .MuiButton-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-transform: none;
  }
`;

const StyledIcon = styled.div``;

const StyledLabel = styled.span`
  line-height: 1;
`;

interface IReloadButtonProps {
  status: "idle" | "loading" | "error" | "success";
  onClick: () => void;
}

const ReloadButton: React.FC<IReloadButtonProps> = (props) => {
  const { status, onClick } = props;

  return (
    <StyledButton
      className="CMuiReloadButton"
      color="secondary"
      variant="contained"
      onClick={onClick}
      disabled={status === "loading"}
    >
      <StyledIcon>
        <ReplayIcon color="primary" />
      </StyledIcon>
      <StyledLabel>
        {status === "idle" && (
          <FormattedMessage
            id="ReloadButton.Recargar"
            defaultMessage="Recargar"
          />
        )}
        {status === "loading" && (
          <FormattedMessage
            id="ReloadButton.Cargando..."
            defaultMessage="Cargando..."
          />
        )}
        {status === "error" && (
          <FormattedMessage id="ReloadButton.Error" defaultMessage="Error" />
        )}
        {status === "success" && (
          <FormattedMessage
            id="ReloadButton.Recargado"
            defaultMessage="Recargado"
          />
        )}
      </StyledLabel>
    </StyledButton>
  );
};

export default ReloadButton;
