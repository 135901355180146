import { apiEmpleo } from "../../util/ApiEmpleo";
import { TGetServicesInfoManagerCollectionItem } from "./service.types";

// export type TServiceManagerInfoResponse = {
//   id: string;
//   Estado_Servicio?: string | null;
//   Name?: string | null;
//   Owner?: {
//     id: string;
//     fullname?: string | null;
//   };
//   ownerAssignedAt?: string | null;
// };

interface IApiListAllServicesManagerInfoParams {
  state?: string[];
}

const apiListAllServicesManagerInfo = async (
  params: IApiListAllServicesManagerInfoParams
) => {
  const apiParams = {
    itemsPerPage: 1000,
    state: typeof params.state !== "undefined" ? params.state : undefined,
  };

  let res: {
    "hydra:member": TGetServicesInfoManagerCollectionItem[];
    "hydra:totalItems": number;
  } = await apiEmpleo.get("services/info-manager", {
    params: apiParams,
  });

  return res;
};

export { apiListAllServicesManagerInfo };
