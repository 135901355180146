import { apiEmpleo } from "../../util/ApiEmpleo";

export type TPostBadgesResponse = {
  numUndeliveredNotifications?: number;
  numUndeliveredWaNotifications?: number;
  numUnassignedServices?: number;
  numUnvaluedServices?: number;
};

export const apiPostBadges = async () => {
  const res: TPostBadgesResponse = await apiEmpleo.post("badges");

  return res;
};
