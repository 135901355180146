import { Button } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

const StyledButton = styled(Button)`
  padding: 0.25rem 1rem;
`;

const StyledButtonContent = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledUnvaluedServicesNumber = styled.span`
  font-size: 2.25rem;
  line-height: 1.15;
  font-weight: 900;
  color: #004b7b;
`;

const StyledButtonLabel = styled.span`
  margin-left: 1rem;
  text-transform: none;
  color: #004b7b;
  font-weight: 400;
`;

interface IUnValuedServices {
  numUnValuedServices: number;
  onClick: () => void;
}

const UnValuedServices = (props: IUnValuedServices) => {
  const { numUnValuedServices, onClick } = props;

  return (
    <StyledButton fullWidth onClick={onClick}>
      <StyledButtonContent>
        <StyledUnvaluedServicesNumber>
          {numUnValuedServices}
        </StyledUnvaluedServicesNumber>
        <StyledButtonLabel>
          <FormattedMessage
            id="UnValuedServices.Servicios sin valorar"
            defaultMessage="Servicios sin valorar"
          />
        </StyledButtonLabel>
      </StyledButtonContent>
    </StyledButton>
  );
};

export default UnValuedServices;
