import React, { Fragment, useState } from "react";
import { Typography, Button, TableRow, TableCell } from "@material-ui/core";
import IntlMessages from "../../util/IntlMessages";
import styled from "styled-components";
import cuideoTheme from "../../containers/themes/defaultTheme";
import { connect } from "react-redux";
import CuideoDataTable from "../CuideoDataTable/ManagedDataTablev2";
import { NavLink } from "react-router-dom";
import { getMisValoraciones } from "../../actions/Valoraciones";
import { fetchStart, fetchSuccess, fetchError } from "../../actions/Common";
import ValoracionCard from "./ValoracionCard";
import { JOB_OFFERS } from "../../constants/routes";

// Used to hide some parts
const StyledWrapper = styled.div`
  .MuiTableHead-root {
    display: none;
  }
  .MuiToolbar-regular {
    display: none;
  }
  .MuiToolbar-regular.MuiTablePagination-toolbar {
    display: flex;
  }
  .MuiTableCell-root {
    padding: 0;
    border: none;
  }
`;

const StyledEmptyContent = styled.div`
  max-width: 16rem;
  min-height: 21rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media all and (max-width: ${cuideoTheme.breakpoints.values.sm}px) {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.sm}px) {
  }
`;

const StyledEmptyTitle = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-weight: 700;
  font-size: 1.125rem;
  line-height: normal;
`;

const StyledEmptySubtitle = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-size: 1.125rem;
  line-height: normal;
  margin-bottom: 1rem;
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
`;

const ValoracionesPasadas = ({
  authUser,
  appliedOffers,
  getMisValoraciones,
  misValoraciones,
  fetchStart,
  fetchSuccess,
  fetchError,
  candidato
}: {
  authUser: any;
  appliedOffers: any;
  getMisValoraciones: Function;
  misValoraciones: any;
  fetchStart: Function;
  fetchSuccess: Function;
  fetchError: Function;
  candidato: any;
}) => {
  const [tableData, setTableData] = useState([]);

  const tableColumns = [
    {
      name: "contrato.id",
      options: {
        sort: false,
        searchable: false,
        filter: false,
        display: "false"
      }
    },
    {
      name: "contrato.T_tulo",
      options: {
        filter: false
      }
    },
    {
      name: "Estado_en_proceso",
      options: {
        sort: false,
        searchable: false,
        filter: false,
        display: "false"
        // filterOptions: {
        //   names: [
        //     "Activo",
        //     "Descartado",
        //     "Sin Validar",
        //     "Validado",
        //     "Validado E",
        //     "Validado Plus",
        //     "Validado T"
        //   ]
        // },
        // filterType: "multiselect",
        // filterList: []
      }
    },
    {
      name: "createdAt",
      label: "Fecha",
      options: {
        // customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
        //   return moment(value).fromNow();
        // },
        filter: false
      }
    },
    {
      name: "contrato.Provincia_oferta",
      label: "Provincia",
      options: {
        sort: false,
        searchable: false,
        filter: false,
        display: "false"
      }
    },
    {
      name: "contrato.Tipo",
      label: "Tipo",
      options: {
        sort: false,
        searchable: false,
        filter: false,
        display: "false"
      }
    },
    {
      name: "contrato.Salario_Bruto_Mensual",
      label: "Salario bruto",
      options: {
        sort: false,
        searchable: false,
        filter: false,
        display: "false"
      }
    },
    {
      name: "contrato.Tipo_de_salario",
      label: "Tipo de salario",
      options: {
        sort: false,
        searchable: false,
        filter: false,
        display: "false"
      }
    },
    {
      name: "contrato.Ciudad_oferta",
      label: "Ciudad",
      options: {
        sort: false,
        searchable: false,
        filter: false,
        display: "false"
      }
    },
    {
      name: "contrato.Name",
      label: "ID Contrato",
      options: {
        sort: false,
        searchable: false,
        filter: false,
        display: "false"
      }
    },
    {
      name: "contrato.Horario_Servicio",
      label: "ID Contrato",
      options: {
        sort: false,
        searchable: false,
        filter: false,
        display: "false"
      }
    }
  ];

  const requestUri = "/candidatos/" + candidato.id + "/valoraciones";

  // This function must transform data into a valid array of data for
  // the MuiDataTable
  const setDataFunction = (newData: any) => {
    const newTableData: any = [...tableData];
    newData.forEach((item: any, index: number) => {
      const found = newTableData.find((element: any) => {
        return element[0] === item.contrato.id;
      });
      if (!found) {
        newTableData.push(item);
      }
    });
    setTableData(newTableData);
  };

  const customRowRender = (
    data: any[],
    dataIndex: number,
    rowIndex: number
  ) => {
    return (
      <TableRow key={data[0]}>
        <TableCell>
          <ValoracionCard
            contratoId={data[0]}
            ofertaTitle={data[1]}
            contratoName={data[9]}
            contratoCiudad={data[8]}
            salarioBruto={data[6]}
            tipoSalario={data[7]}
            horarioServicio={data[10]}
            estadoValoracion={data[2]}
            valoracionDate={data[3]}
            publicar={false}
            candidatoSexo={candidato.Sexo}
          />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Fragment>
      <StyledWrapper>
        <CuideoDataTable
          columns={tableColumns as any}
          requestUri={requestUri}
          data={tableData}
          setDataFunction={setDataFunction}
          additionalParams={{
            "contrato.publicar": "No"
          }}
          title={
            <Typography variant="h4">
              <IntlMessages id="Ofertas Pasadas" />
            </Typography>
          }
          selectableRows="none"
          emptyContent={
            <StyledEmptyContent>
              <StyledEmptyTitle>
                <IntlMessages id="No tienes ofertas caducadas" />
              </StyledEmptyTitle>
              <StyledEmptySubtitle>
                <IntlMessages id="¿A qué esperas? ¡Encuentra la oferta que buscas!" />
              </StyledEmptySubtitle>
              <StyledNavLink to={JOB_OFFERS}>
                <Button fullWidth color="secondary" variant="contained">
                  <IntlMessages id="Buscar ofertas" />
                </Button>
              </StyledNavLink>
            </StyledEmptyContent>
          }
          options={{
            selectableRows: "none",
            download: false,
            print: false,
            rowsPerPage: 10,
            rowsPerPageOptions: [10],
            responsive: "scrollFullHeight",
            customRowRender: customRowRender
          }}
        />
      </StyledWrapper>
    </Fragment>
  );
};

const mapStateToProps = ({
  auth,
  valoracionesState
}: {
  auth: any;
  valoracionesState: any;
}) => {
  const { authUser, appliedOffers } = auth;
  const { misValoraciones } = valoracionesState;

  return { authUser, appliedOffers, misValoraciones };
};

export default connect(mapStateToProps, {
  getMisValoraciones,
  fetchStart,
  fetchSuccess,
  fetchError
})(ValoracionesPasadas);
