import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

interface IFilterItemHeadProps {
  open: boolean;
  isActive: boolean;
  filterLabel: string;
  onClick: () => void;
}

const FilterItemHead = (props: IFilterItemHeadProps) => {
  const { open, isActive, filterLabel, onClick } = props;

  let ballClasses = "CMuiFiltersPaperFilterHeadBall";
  let iconClasses = "CMuiFiltersPaperFilterArrow";
  if (open) {
    iconClasses += " CMuiFiltersPaperFilterArrow--up";
  }
  if (isActive) {
    ballClasses += " CMuiFiltersPaperFilterHeadBall--checked";
  }

  return (
    <Box className="CMuiFiltersPaperFilterHead" onClick={onClick}>
      <Grid container spacing={4} alignItems="center" wrap="nowrap">
        <Grid item>
          <Box className={ballClasses}></Box>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography className="CMuiFiltersPaperFilterLabel">
            {filterLabel}
          </Typography>
        </Grid>
        <Grid item>
          <ExpandMoreIcon className={iconClasses} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FilterItemHead;
