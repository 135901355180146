import { TConversation } from "../types/conversation.types";

export const conversationRemoteContactId = (
  conversation: TConversation
): string => {
  return conversation.contact_id;
};

export const numUnreadMessagesForConversation = (
  conversation: TConversation,
  interestedId: string
): number => {
  const filteredStakeHolders = conversation.conversation_stake_holder.filter(
    (stakeHolder) => stakeHolder.interested_id === interestedId
  );

  return filteredStakeHolders.length === 0
    ? 0
    : filteredStakeHolders[0].unread_messages;
};
