import { useMemo } from "react";
import { useSelector } from "react-redux";
import { TUserRole, USER_ROLES } from "../../api/security";
import { TAuthenticatedUser } from "../Auth";

export type TAppPermissions = {
  accessToRecruitManager: boolean;
  accessToPromoteManager: boolean;

  accessToContractsRoute: boolean;
  canPromoteContract: boolean;
  canDemoteContract: boolean;
  canHandleRecruitPhaseValuations: boolean;
  canHandlePromotePhaseValuations: boolean;
  canHandleToRecruitPhaseValuations: boolean;
  canHandleToPromotePhaseValuations: boolean;
  canConverseInContract: boolean;

  accessToServicesRoute: boolean;

  accessToCandidatesRoute: boolean;

  accessToConversationsRoute: boolean;

  canDisableStaffUsers: boolean;
};

export const useAuthUser = () => {
  const authUser = useSelector(
    (state: any) => state.auth.authUser as TAuthenticatedUser
  );

  const permissions: TAppPermissions = useMemo(() => {
    if (!authUser) {
      return {
        accessToCandidatesRoute: false,
        accessToContractsRoute: false,
        canDemoteContract: false,
        canPromoteContract: false,
        canHandlePromotePhaseValuations: false,
        canHandleRecruitPhaseValuations: false,
        canHandleToPromotePhaseValuations: false,
        canHandleToRecruitPhaseValuations: false,
        canConverseInContract: false,
        accessToConversationsRoute: false,
        accessToPromoteManager: false,
        accessToRecruitManager: false,
        accessToServicesRoute: false,
        canDisableStaffUsers: false,
      };
    }

    return {
      accessToRecruitManager:
        authUserHasRole(authUser, USER_ROLES.ROLE_RECRUIT_MANAGER) ||
        authUserHasRole(authUser, USER_ROLES.ROLE_PROMOTE_MANAGER) ||
        authUserHasRole(authUser, USER_ROLES.ROLE_MANAGER),
      accessToPromoteManager:
        authUserHasRole(authUser, USER_ROLES.ROLE_RECRUIT_MANAGER) ||
        authUserHasRole(authUser, USER_ROLES.ROLE_PROMOTE_MANAGER) ||
        authUserHasRole(authUser, USER_ROLES.ROLE_MANAGER),

      accessToContractsRoute: authUserHasRole(authUser, USER_ROLES.ROLE_STAFF),
      canPromoteContract:
        authUserHasRole(authUser, USER_ROLES.ROLE_ES_RECRUITER) ||
        authUserHasRole(authUser, USER_ROLES.ROLE_ADMIN) ||
        authUserHasRole(authUser, USER_ROLES.ROLE_MANAGER),
      canDemoteContract:
        authUserHasRole(authUser, USER_ROLES.ROLE_ES_PROMOTER) ||
        authUserHasRole(authUser, USER_ROLES.ROLE_ADMIN) ||
        authUserHasRole(authUser, USER_ROLES.ROLE_MANAGER),
      canHandleRecruitPhaseValuations:
        authUserHasRole(authUser, USER_ROLES.ROLE_ES_ON_SHIFT) ||
        (!authUserHasRole(authUser, USER_ROLES.ROLE_ES_PROMOTER) &&
          (authUserHasRole(authUser, USER_ROLES.ROLE_ES_RECRUITER) ||
            authUserHasRole(authUser, USER_ROLES.ROLE_COORDINATION) ||
            authUserHasRole(authUser, USER_ROLES.ROLE_MANAGER))),
      canHandlePromotePhaseValuations:
        authUserHasRole(authUser, USER_ROLES.ROLE_ES_ON_SHIFT) ||
        (!authUserHasRole(authUser, USER_ROLES.ROLE_ES_RECRUITER) &&
          (authUserHasRole(authUser, USER_ROLES.ROLE_ES_PROMOTER) ||
            authUserHasRole(authUser, USER_ROLES.ROLE_COORDINATION) ||
            authUserHasRole(authUser, USER_ROLES.ROLE_MANAGER))),
      canHandleToRecruitPhaseValuations:
        authUserHasRole(authUser, USER_ROLES.ROLE_ES_ON_SHIFT) ||
        (!authUserHasRole(authUser, USER_ROLES.ROLE_ES_PROMOTER) &&
          (authUserHasRole(authUser, USER_ROLES.ROLE_ES_RECRUITER) ||
            authUserHasRole(authUser, USER_ROLES.ROLE_COORDINATION) ||
            authUserHasRole(authUser, USER_ROLES.ROLE_MANAGER))),
      canHandleToPromotePhaseValuations:
        authUserHasRole(authUser, USER_ROLES.ROLE_ES_ON_SHIFT) ||
        (!authUserHasRole(authUser, USER_ROLES.ROLE_ES_RECRUITER) &&
          (authUserHasRole(authUser, USER_ROLES.ROLE_ES_PROMOTER) ||
            authUserHasRole(authUser, USER_ROLES.ROLE_COORDINATION) ||
            authUserHasRole(authUser, USER_ROLES.ROLE_MANAGER))),
      canConverseInContract:
        authUserHasRole(authUser, USER_ROLES.ROLE_ES_PROMOTER) ||
        authUserHasRole(authUser, USER_ROLES.ROLE_ES_RECRUITER) ||
        authUserHasRole(authUser, USER_ROLES.ROLE_COORDINATION) ||
        authUserHasRole(authUser, USER_ROLES.ROLE_ADMIN) ||
        authUserHasRole(authUser, USER_ROLES.ROLE_MANAGER),

      accessToServicesRoute:
        authUserHasRole(authUser, USER_ROLES.ROLE_ES_ON_SHIFT) ||
        (authUserHasRole(authUser, USER_ROLES.ROLE_STAFF) &&
          !authUserHasRole(authUser, USER_ROLES.ROLE_ES_RECRUITER)),

      accessToCandidatesRoute: authUserHasRole(authUser, USER_ROLES.ROLE_STAFF),

      accessToConversationsRoute: authUserHasRole(
        authUser,
        USER_ROLES.ROLE_COMM_TESTER
      ),

      canDisableStaffUsers:
        authUserHasRole(authUser, USER_ROLES.ROLE_RECRUIT_MANAGER) ||
        authUserHasRole(authUser, USER_ROLES.ROLE_PROMOTE_MANAGER) ||
        authUserHasRole(authUser, USER_ROLES.ROLE_MANAGER),
    };
  }, [authUser]);

  return { authUser, permissions };
};

export const authUserHasRole = (
  authUser: TAuthenticatedUser,
  role: TUserRole
): boolean => {
  if (authUser.userRoles.indexOf(role) !== -1) {
    return true;
  }

  return false;
};
