import moment from "moment";
import {
  MESSAGE_MODULE,
  MESSAGE_STATUS,
  TGetMessagesCollectionItem,
} from "../../../../../api/message/message.types";
import uuidv4 from "uuid/v4";

export type TMessage = Pick<
  TGetMessagesCollectionItem,
  | "id"
  | "parentId"
  | "module"
  | "status"
  | "content"
  | "createdBy"
  | "createdAt"
> & {
  direction: "in" | "out";
  showAuthor: boolean;
  marginBottom: boolean;
};

export const messageIsReceived = (message: TMessage): boolean => {
  return message.direction === "in";
};

export const messageShouldShowAuthor = (
  message: TGetMessagesCollectionItem,
  prevMessage: TGetMessagesCollectionItem | null
): boolean => {
  if (!prevMessage) {
    return true;
  }

  if (!message.createdBy) {
    return false;
  }

  if (prevMessage.createdBy?.id === message.createdBy?.id) {
    const prevMessageCreatedMom = moment(prevMessage.createdAt);
    const messageCreatedMom = moment(message.createdAt);
    if (prevMessageCreatedMom.isValid() && messageCreatedMom.isValid()) {
      prevMessageCreatedMom.add(2, "hours");
      if (prevMessageCreatedMom.isAfter(messageCreatedMom)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  return true;
};

export const messageShouldHaveBottomMargin = (
  message: TGetMessagesCollectionItem,
  nextMessage: TGetMessagesCollectionItem | null
): boolean => {
  if (!nextMessage) {
    return true;
  }

  if (!message.createdBy) {
    return true;
  }

  if (nextMessage.createdBy?.id === message.createdBy?.id) {
    const nextMessageCreatedMom = moment(nextMessage.createdAt);
    const messageCreatedMom = moment(message.createdAt);
    if (nextMessageCreatedMom.isValid() && messageCreatedMom.isValid()) {
      messageCreatedMom.add(2, "hours");
      if (messageCreatedMom.isAfter(nextMessageCreatedMom)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  return true;
};

export const createMessage = (content: string, parentId: string): TMessage => {
  return {
    id: uuidv4(),
    parentId,
    module: MESSAGE_MODULE.CONTRACT,
    status: MESSAGE_STATUS.SENT,
    content,
    createdAt: moment().format(),
    direction: "out",
    showAuthor: false,
    marginBottom: true,
  };
};
