import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { JOB_MY_PROFILE, JOB_OFFERS } from "../../constants/routes";
import useBackground from "../../util/useBackground";
import useFooter from "../../util/useFooter";
import useHeader from "../../util/useHeader";
import usePageTitle from "../../util/usePageTitle";
import CandidateSignUpPage from "./CandidateSignUpPage";
import useCandidateSignUpState from "./hooks/useCandidateSignUpState";

interface ICandidateSignUpPageContainerProps {}

const CandidateSignUpPageContainer: React.FC<ICandidateSignUpPageContainerProps> =
  (props) => {
    const { formatMessage } = useIntl();

    const {
      state,
      actions: { stepSubmit, signUpReset },
    } = useCandidateSignUpState();
    useHeader({
      type: "simple",
      showHelpLink: false,
    });
    useFooter({
      type: "hidden",
    });
    useBackground("cuideo");
    usePageTitle(
      formatMessage({
        id: "CandidateSignUp.Registro - Empleo | Cuideo",
        defaultMessage: "Registro - Empleo | Cuideo",
      })
    );

    const { authUser, registerStatus, registerMessage } = useSelector(
      (state: any) => state.auth as any
    );

    if (authUser && registerStatus === "registered") {
      return <Redirect to={{ pathname: JOB_MY_PROFILE }} />;
    }

    if (authUser && !registerStatus) {
      return <Redirect to={{ pathname: JOB_OFFERS }} />;
    }

    return (
      <CandidateSignUpPage
        step={state.step}
        onStepSubmit={stepSubmit}
        registerStatus={registerStatus}
        registerMessage={registerMessage}
        onUserSignUpResetError={signUpReset}
      />
    );
  };

export default CandidateSignUpPageContainer;
