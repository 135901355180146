import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { TContractManagerInfoResponse } from "../../../../../../../api/contract/apiListAllContractsManagerInfo";
import { CONTRACT_PHASE } from "../../../../../../../api/contract/contract.types";
import { TGetServicesInfoManagerCollectionItem } from "../../../../../../../api/service/service.types";
import { COORDINATION_GROUP } from "../../../../../../../api/user/apiListStaffUsers";
import {
  CONTRATO_ESTADO_ALTA_PENDIENTE,
  CONTRATO_ESTADO_CV_CLIENTE,
  CONTRATO_ESTADO_PRESENCIAL_CLIENTE,
} from "../../../../../../../util/defaults/contrato";
import { useConnectedUsers } from "../../../../connectedUser";
import { useContractsInfo } from "../../../../contract";
import { useRecruiterUsers } from "../../../../user/api/getRecruiterUsers";
import {
  TUserAssignations,
  TUserContractAssignationsStats,
  TUserGroupAssignations,
} from "../../../types/UserGroupAssignations.types";
import {
  initRecruiterGroups,
  newRecruiterAssignationContractStats,
  recruiterIsOnShift,
  sortRecruiterAssignations,
  validGroupForRecruiter,
} from "../../../util/recruiter-groups.util";

export type TUserAssignationsState = {
  groups: TUserGroupAssignations[];
  totalContracts: number;
  recruiters: TUserAssignations[];
  unassignedServices: TGetServicesInfoManagerCollectionItem[];
  maxServicesAssigned: number;
  maxContractsAssigned: number;
  maxGroupServices: number;
  maxGroupContracts: number;
};

export default function useRecruiterAssignationsState() {
  const [state, setState] = useState<TUserAssignationsState>({
    groups: initRecruiterGroups(),
    totalContracts: 0,
    recruiters: [],
    unassignedServices: [],
    maxServicesAssigned: 0,
    maxContractsAssigned: 0,
    maxGroupServices: 0,
    maxGroupContracts: 0,
  });
  const recruitersQuery = useRecruiterUsers();
  const contractsQuery = useContractsInfo({
    params: {
      estado: [
        CONTRATO_ESTADO_ALTA_PENDIENTE,
        CONTRATO_ESTADO_CV_CLIENTE,
        CONTRATO_ESTADO_PRESENCIAL_CLIENTE,
      ],
      phase: [CONTRACT_PHASE.RECRUIT],
    },
  });
  const { isUserConnected } = useConnectedUsers();

  useEffect(() => {
    if (!recruitersQuery.data) {
      return;
    }

    if (!contractsQuery.data) {
      return;
    }

    const groups: TUserAssignationsState["groups"] = initRecruiterGroups();
    const recruiters: TUserAssignationsState["recruiters"] = [];

    recruitersQuery.data.recruiters.forEach((recruiter) => {
      const recruiterContracts: TContractManagerInfoResponse[] = [];
      const recruiterContractStats: TUserContractAssignationsStats =
        newRecruiterAssignationContractStats();

      contractsQuery.data?.contracts.forEach((contract) => {
        if (contract.Owner?.id === recruiter.id) {
          recruiterContracts.push(contract);

          recruiterContractStats.total++;
          if (contract.Estado === CONTRATO_ESTADO_ALTA_PENDIENTE) {
            recruiterContractStats.awaiting.total++;
          }
          if (contract.Estado === CONTRATO_ESTADO_CV_CLIENTE) {
            recruiterContractStats.cvClient.total++;
          }
          if (contract.Estado === CONTRATO_ESTADO_PRESENCIAL_CLIENTE) {
            recruiterContractStats.clientFaceToFace.total++;
          }
        }
      });

      const isOnline = isUserConnected(recruiter.id);

      const newRecruiter: TUserAssignations = {
        id: recruiter.id,
        fullName: recruiter.fullname,
        active: recruiter.active,
        enabled: recruiter.enabled ?? false,
        roles: recruiter.roles,
        lastActivityTime: recruiter.lastActivityTime ?? null,
        coordinationGroups: recruiter.coordinationGroups ?? [],
        isOnline: isOnline,
        contracts: recruiterContracts,
        services: [],
        servicesStats: {
          total: 0,
          withoutValuation: {
            total: 0,
            postponed: 0,
          },
        },
        contractStats: recruiterContractStats,
      };
      recruiters.push(newRecruiter);
    });

    // Filter inactive with 0 services & contracts
    const filteredRecruiters = recruiters.filter((recruiter) => {
      if (recruiter.active) {
        return true;
      }

      if (
        // recruiter.services.length === 0 &&
        recruiter.contracts.length === 0
      ) {
        return false;
      }

      return true;
    });

    // Add filtered recruiter to his/her group
    filteredRecruiters.forEach((recruiter) => {
      // Add the recruiter to his squad group
      const targetGroup = validGroupForRecruiter(recruiter);
      const foundGroup = groups.find((group) => group.id === targetGroup);
      if (foundGroup) {
        foundGroup.users.push(recruiter);
      }
      // Add also to OnShift group if user qualifies
      if (recruiterIsOnShift(recruiter)) {
        const onShiftGroup = groups.find(
          (group) => group.id === COORDINATION_GROUP.ON_SHIFT
        );
        if (onShiftGroup) {
          onShiftGroup.users.push(recruiter);
        }
      }
    });

    let maxServices = 0;
    let maxContracts = 0;
    let maxContractsState = 0;
    filteredRecruiters.forEach((recruiter) => {
      // if (coordinator.services.length > maxServices) {
      //   maxServices = coordinator.services.length;
      // }

      if (recruiter.contracts.length > maxContracts) {
        maxContracts = recruiter.contracts.length;
      }

      if (recruiter.contractStats.awaiting.total > maxContractsState) {
        maxContractsState = recruiter.contractStats.awaiting.total;
      }

      const advanced =
        recruiter.contractStats.clientFaceToFace.total +
        recruiter.contractStats.cvClient.total;
      if (advanced > maxContractsState) {
        maxContractsState = advanced;
      }
    });

    // Add stats to groups
    let maxGroupServices = 0;
    let maxGroupContracts = 0;
    groups.forEach((group) => {
      let maxContracts = 0;
      let maxContractsState = 0;
      group.users.forEach((recruiter) => {
        // if (coordinator.services.length > maxServices) {
        //   maxServices = coordinator.services.length;
        // }

        if (recruiter.contracts.length > maxContracts) {
          maxContracts = recruiter.contracts.length;
        }

        if (recruiter.contractStats.awaiting.total > maxContractsState) {
          maxContractsState = recruiter.contractStats.awaiting.total;
        }

        const advanced =
          recruiter.contractStats.clientFaceToFace.total +
          recruiter.contractStats.cvClient.total;
        if (advanced > maxContractsState) {
          maxContractsState = advanced;
        }

        // group.servicesStats.total += coordinator.servicesStats.total;
        // group.servicesStats.withoutValuation.total +=
        //   coordinator.servicesStats.withoutValuation.total;
        // group.servicesStats.withoutValuation.postponed +=
        //   coordinator.servicesStats.withoutValuation.postponed;

        group.contractStats.total += recruiter.contractStats.total;
        group.contractStats.awaiting.total +=
          recruiter.contractStats.awaiting.total;
        group.contractStats.clientFaceToFace.total +=
          recruiter.contractStats.clientFaceToFace.total;
        group.contractStats.cvClient.total +=
          recruiter.contractStats.cvClient.total;
        group.contractStats.payAndReserve.total +=
          recruiter.contractStats.payAndReserve.total;
        group.contractStats.pendingSignUp.total +=
          recruiter.contractStats.pendingSignUp.total;
      });

      // group.maxServicesAssigned = maxServices;
      group.maxContractsAssigned = maxContractsState;

      // if (group.servicesStats.total > maxGroupServices) {
      //   maxGroupServices = group.servicesStats.total;
      // }
      const contractAwaiting = group.contractStats.awaiting.total;
      const contractAdvanced =
        group.contractStats.clientFaceToFace.total +
        group.contractStats.cvClient.total;
      const contractMax =
        contractAwaiting > contractAdvanced
          ? contractAwaiting
          : contractAdvanced;
      if (contractMax > maxGroupContracts) {
        maxGroupContracts = contractMax;
      }
    });

    // Sort by coordinators with more services
    const sortedRecruiters = filteredRecruiters.sort(sortRecruiterAssignations);

    // Sort in each group
    groups.forEach((group) => {
      group.users = group.users.sort(sortRecruiterAssignations);
    });

    const newState: TUserAssignationsState = {
      groups,
      totalContracts: contractsQuery.data.contracts.length,
      recruiters: sortedRecruiters,
      unassignedServices: [],
      maxServicesAssigned: maxServices,
      maxContractsAssigned: maxContractsState,
      maxGroupServices,
      maxGroupContracts,
    };

    if (!isEqual(newState, state)) {
      setState({
        groups,
        totalContracts: newState.totalContracts,
        recruiters: sortedRecruiters,
        unassignedServices: [],
        maxServicesAssigned: maxServices,
        maxContractsAssigned: maxContractsState,
        maxGroupServices,
        maxGroupContracts,
      });
    }
  }, [recruitersQuery, contractsQuery, state, isUserConnected]);

  return {
    isLoading: recruitersQuery.isLoading,
    groups: state.groups,
    totalContracts: state.totalContracts,
    maxContractsAssigned: state.maxGroupContracts,
  };
}
