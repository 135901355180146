import classNames from "classnames";
import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import {
  COLOR_RED_NOTIFICATION,
  COLOR_SUCCESS,
  cuideoTheme,
} from "../../../../../../containers/themes/defaultTheme";

const loadingAnimation = keyframes`
  0% { opacity: 0.25; }
  50% {opacity: 1; }
  100% {opacity: 0.25;}
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 0.875rem;
  width: 100%;
  height: 2.5rem;

  &.CMui-loading {
    .CMuiContractPhaseStepper__step {
      .CMuiStep__pre,
      .CMuiStep__body,
      .CMuiStep__post {
        animation: ${loadingAnimation} 1s ease-in-out infinite;
      }
    }
    .CMuiContractPhaseStepper__step:nth-child(1) {
      .CMuiStep__pre,
      .CMuiStep__body,
      .CMuiStep__post {
        animation-delay: 0;
      }
    }
    .CMuiContractPhaseStepper__step:nth-child(2) {
      .CMuiStep__pre,
      .CMuiStep__body,
      .CMuiStep__post {
        animation-delay: 0.1s;
      }
    }
    .CMuiContractPhaseStepper__step:nth-child(3) {
      .CMuiStep__pre,
      .CMuiStep__body,
      .CMuiStep__post {
        animation-delay: 0.2s;
      }
    }
    .CMuiContractPhaseStepper__step:nth-child(4) {
      .CMuiStep__pre,
      .CMuiStep__body,
      .CMuiStep__post {
        animation-delay: 0.3s;
      }
    }
    .CMuiContractPhaseStepper__step:nth-child(5) {
      .CMuiStep__pre,
      .CMuiStep__body,
      .CMuiStep__post {
        animation-delay: 0.4s;
      }
    }
    .CMuiContractPhaseStepper__step:nth-child(6) {
      .CMuiStep__pre,
      .CMuiStep__body,
      .CMuiStep__post {
        animation-delay: 0.5s;
      }
    }
  }
`;

const StyledStep = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;

  flex: 1 1 0px;

  .CMuiStep__pre {
    position: relative;
    height: 100%;
    background-color: #e5ecf1;
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: -0.375rem;
      top: 0;
      width: 0.625rem;
      height: 50%;
      background-color: inherit;
      transform: skew(20deg, 0);
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: -0.375rem;
      bottom: 0;
      width: 0.625rem;
      height: 50%;
      background-color: inherit;
      transform: skew(-20deg, 0);
    }
  }
  .CMuiStep__body {
    padding: 0 0.75rem;
    height: 100%;
    background-color: #e5ecf1;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    font-size: 0.9375rem;
    font-weight: 700;
    color: ${cuideoTheme.palette.primary.main};

    &.CMuiStep__body--last {
      text-align: right;
    }
  }
  .CMuiStep__post {
    position: relative;
    height: 100%;
    background-color: #e5ecf1;
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: -0.375rem;
      top: 0;
      width: 0.625rem;
      height: 50%;
      background-color: inherit;
      transform: skew(20deg, 0);
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: -0.375rem;
      bottom: 0;
      width: 0.625rem;
      height: 50%;
      background-color: inherit;
      transform: skew(-20deg, 0);
    }
  }

  &.CMui-active {
    .CMuiStep__pre {
      background-color: ${cuideoTheme.palette.primary.main};
    }
    .CMuiStep__body {
      background-color: ${cuideoTheme.palette.primary.main};
      color: #fff;
    }
    .CMuiStep__post {
      background-color: ${cuideoTheme.palette.primary.main};
    }
  }

  &.CMui-success {
    .CMuiStep__pre {
      background-color: ${COLOR_SUCCESS};
    }
    .CMuiStep__body {
      background-color: ${COLOR_SUCCESS};
      color: #fff;
    }
    .CMuiStep__post {
      background-color: ${COLOR_SUCCESS};
    }
  }

  &.CMui-warning {
    .CMuiStep__pre {
      background-color: #ebc200;
    }
    .CMuiStep__body {
      background-color: #ebc200;
      color: #fff;
    }
    .CMuiStep__post {
      background-color: #ebc200;
    }
  }

  &.CMui-error {
    .CMuiStep__pre {
      background-color: ${COLOR_RED_NOTIFICATION};
    }
    .CMuiStep__body {
      background-color: ${COLOR_RED_NOTIFICATION};
      color: #fff;
    }
    .CMuiStep__post {
      background-color: ${COLOR_RED_NOTIFICATION};
    }
  }

  &.CMui-active,
  &.CMui-success {
    &:nth-child(1) {
      .CMuiStep__pre,
      .CMuiStep__body,
      .CMuiStep__post {
        transition: background-color 0.15s ease-in-out 0s,
          color 0.15s ease-in-out 0s;
      }
    }
    &:nth-child(2) {
      .CMuiStep__pre,
      .CMuiStep__body,
      .CMuiStep__post {
        transition: background-color 0.15s ease-in-out 0.05s,
          color 0.15s ease-in-out 0.05s;
      }
    }
    &:nth-child(3) {
      .CMuiStep__pre,
      .CMuiStep__body,
      .CMuiStep__post {
        transition: background-color 0.15s ease-in-out 0.1s,
          color 0.15s ease-in-out 0.1s;
      }
    }
    &:nth-child(4) {
      .CMuiStep__pre,
      .CMuiStep__body,
      .CMuiStep__post {
        transition: background-color 0.15s ease-in-out 0.15s,
          color 0.15s ease-in-out 0.15s;
      }
    }
    &:nth-child(5) {
      .CMuiStep__pre,
      .CMuiStep__body,
      .CMuiStep__post {
        transition: background-color 0.15s ease-in-out 0.2s,
          color 0.15s ease-in-out 0.2s;
      }
    }
    &:nth-child(6) {
      .CMuiStep__pre,
      .CMuiStep__body,
      .CMuiStep__post {
        transition: background-color 0.15s ease-in-out 0.25s,
          color 0.15s ease-in-out 0.25s;
      }
    }
  }
`;

const StyledPreStep = styled.div``;

const StyledStepBody = styled.div`
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledPostStep = styled.div``;

interface IContractPipelineStepperProps {
  isLoading?: boolean;
  disableLabels?: boolean;
  numWithoutVal?: number;
  numPhone?: number;
  numFaceToFace?: number;
  numCvClient?: number;
  numFaceToFaceClient?: number;
  numContracted?: number;
  warningOnCv?: boolean;
  warningFaceToFace?: boolean;
}

const ContractPipelineStepper: React.FC<IContractPipelineStepperProps> = (
  props
) => {
  const {
    isLoading,
    disableLabels = false,
    numWithoutVal = 0,
    numPhone = 0,
    numFaceToFace = 0,
    numCvClient = 0,
    numFaceToFaceClient = 0,
    numContracted = 0,
    warningOnCv = false,
    warningFaceToFace = false,
  } = props;

  const [state, setState] = useState({
    isLoading: true,
  });

  useEffect(() => {
    if (state.isLoading && !isLoading) {
      const timer = setTimeout(() => {
        setState({ isLoading: false });
      }, 100);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [isLoading, state.isLoading]);

  return (
    <StyledWrapper
      className={classNames({
        CMuiContractPhaseStepper: true,
        "CMui-loading": state.isLoading,
      })}
    >
      <StyledStep
        className={classNames({
          CMuiContractPhaseStepper__step: true,
          "CMui-active":
            !state.isLoading &&
            numWithoutVal +
              numPhone +
              numFaceToFace +
              numCvClient +
              numFaceToFaceClient +
              numContracted >
              0,
        })}
      >
        <StyledStepBody className="CMuiStep__body">
          {!isLoading && !disableLabels && numWithoutVal}
        </StyledStepBody>
        <StyledPostStep className="CMuiStep__post"></StyledPostStep>
      </StyledStep>
      <StyledStep
        className={classNames({
          CMuiContractPhaseStepper__step: true,
          "CMui-active":
            !state.isLoading &&
            numPhone +
              numFaceToFace +
              numCvClient +
              numFaceToFaceClient +
              numContracted >
              0,
        })}
      >
        <StyledPreStep className="CMuiStep__pre"></StyledPreStep>
        <StyledStepBody className="CMuiStep__body CMuiStep__body--last">
          {!isLoading && !disableLabels && numPhone}
        </StyledStepBody>
        <StyledPostStep className="CMuiStep__post"></StyledPostStep>
      </StyledStep>
      <StyledStep
        className={classNames({
          CMuiContractPhaseStepper__step: true,
          "CMui-active":
            !state.isLoading &&
            numFaceToFace + numCvClient + numFaceToFaceClient + numContracted >
              0,
          "CMui-warning": !state.isLoading && warningFaceToFace,
        })}
      >
        <StyledPreStep className="CMuiStep__pre"></StyledPreStep>
        <StyledStepBody className="CMuiStep__body CMuiStep__body--last">
          {!isLoading && !disableLabels && numFaceToFace}
        </StyledStepBody>
        <StyledPostStep className="CMuiStep__post"></StyledPostStep>
      </StyledStep>
      <StyledStep
        className={classNames({
          CMuiContractPhaseStepper__step: true,
          "CMui-active":
            !state.isLoading &&
            numCvClient + numFaceToFaceClient + numContracted > 0,
          "CMui-error": !state.isLoading && warningOnCv,
        })}
      >
        <StyledPreStep className="CMuiStep__pre"></StyledPreStep>
        <StyledStepBody className="CMuiStep__body CMuiStep__body--last">
          {!isLoading && !disableLabels && numCvClient}
        </StyledStepBody>
        <StyledPostStep className="CMuiStep__post"></StyledPostStep>
      </StyledStep>
      <StyledStep
        className={classNames({
          CMuiContractPhaseStepper__step: true,
          "CMui-active":
            !state.isLoading && numFaceToFaceClient + numContracted > 0,
        })}
      >
        <StyledPreStep className="CMuiStep__pre"></StyledPreStep>
        <StyledStepBody className="CMuiStep__body CMuiStep__body--last">
          {!isLoading && !disableLabels && numFaceToFaceClient}
        </StyledStepBody>
        <StyledPostStep className="CMuiStep__post"></StyledPostStep>
      </StyledStep>
      <StyledStep
        className={classNames({
          CMuiContractPhaseStepper__step: true,
          "CMui-active": !state.isLoading && numContracted > 0,
          "CMui-success": !state.isLoading && numContracted > 0,
        })}
      >
        <StyledPreStep className="CMuiStep__pre"></StyledPreStep>
        <StyledStepBody className="CMuiStep__body CMuiStep__body--last">
          {!isLoading && !disableLabels && numContracted}
        </StyledStepBody>
      </StyledStep>
    </StyledWrapper>
  );
};

export default ContractPipelineStepper;
