import { Button, Dialog, Typography } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import toast from "react-hot-toast";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { modalShow } from "../../../../../../actions";
import { TGetContractsItemValuation } from "../../../../../../api/contract/contract.types";
import TrashIcon from "../../../../../../assets/images/iconos/trash-can.png";
import cuideoTheme from "../../../../../../containers/themes/defaultTheme";
import { apiEmpleo } from "../../../../../../util/ApiEmpleo";
import {
  VALORACION_CONTRATADO,
  VALORACION_DESCARTADO,
  VALORACION_SUGGESTION,
} from "../../../../../../util/defaults/valoracion";
import { TOnContractValuationChangeFn } from "../../../contract/types/contract-valuation.types";
import { TContractValuation } from "../../types/valuation.types";

const StyledDelete = styled(Button)`
  text-transform: none;
  font-weight: 400;
  font-size: 0.75rem;
  padding: 0 0.5rem;
`;

const StyledButtonIcon = styled.img`
  margin-right: 0.5rem;
  width: 0.75rem;
  height: 0.75rem;
`;

const StyledInscritaText = styled(Typography)`
  font-size: 0.75rem;
  color: ${cuideoTheme.palette.primary.main};
`;

const StyledDescartarDialog = styled(Dialog)``;

const StyledHeader = styled.div`
  background-color: #cdd5d8;
  color: ${cuideoTheme.palette.primary.main};
  padding: 0.75rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledTitle = styled(Typography)`
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
`;

const StyledActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 0.5rem 0.5rem;
`;

const StyledCancelButton = styled(Button)``;

const StyledConfirmButton = styled(Button)``;

const StyledDialogContent = styled(Typography)`
  padding: 1rem;
  font-size: 0.9375rem;
  color: ${cuideoTheme.palette.primary.main};
`;

interface IValuationDiscardButtonProps {
  valuation: TContractValuation;
  isDisabled: boolean;
  onChange: TOnContractValuationChangeFn;
}

const ValuationDiscardButton = (props: IValuationDiscardButtonProps) => {
  const { valuation, isDisabled, onChange } = props;
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [descartando, setDescartando] = useState(false);

  const handleEliminar = (e: React.MouseEvent) => {
    e.stopPropagation();
    setDescartando(true);
  };

  const handleCloseDialog = (e: React.MouseEvent) => {
    e.stopPropagation();
    setDescartando(false);
  };

  const handleConfirm = (e: React.MouseEvent) => {
    const sendData = async () => {
      const data = {
        Estado_en_proceso: VALORACION_DESCARTADO,
      };
      const updatedValuation: TGetContractsItemValuation = {
        ...valuation,
        Estado_en_proceso: VALORACION_DESCARTADO,
      };

      setDescartando(false);
      // handleChange({ ...valuation, status: "mutating" });
      onChange({
        op: "update_status_start",
        updatedValuation,
        refreshAfterUpdate: false,
      });
      try {
        if (valuation.Estado_en_proceso === VALORACION_SUGGESTION) {
          await apiEmpleo.patch(
            `suggestions/${valuation.id}`,
            {
              status: "rejected",
            },
            {
              headers: {
                "Content-Type": "application/merge-patch+json",
              },
            }
          );
        } else {
          await apiEmpleo.patch(`valoraciones/${valuation.id}`, data, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          });
          toast.success(
            formatMessage(
              {
                id: "PipelineButton.Se ha descartado la valoración de {candidato}",
                defaultMessage: "Se ha descartado la valoración de {candidato}",
              },
              {
                candidato: `${valuation.candidato?.Nombre} ${valuation.candidato?.Apellidos}`,
              }
            )
          );
          onChange({
            op: "update_status_end",
            updatedValuation,
            refreshAfterUpdate: true,
          });
          // handleChange({ ...valoracion, status: "deleted" });
        }
      } catch (e) {
        dispatch(
          modalShow({
            open: true,
            type: "error",
            message: formatMessage({
              id: "PipelineButton.Ha habido algún error",
              defaultMessage: "Ha habido algún error",
            }),
          })
        );
      }
    };

    e.stopPropagation();
    sendData();
  };

  return (
    <Fragment>
      {valuation.Estado_en_proceso !== VALORACION_DESCARTADO ? (
        <StyledDelete
          color="primary"
          onClick={handleEliminar}
          disabled={isDisabled}
        >
          <StyledButtonIcon src={TrashIcon} />
          {valuation.Estado_en_proceso === VALORACION_SUGGESTION ? (
            <FormattedMessage
              id="ValuationCard.Rechazar sugerencia"
              defaultMessage="Rechazar sugerencia"
            />
          ) : (
            <FormattedMessage
              id="ValuationCard.Eliminar de la oferta"
              defaultMessage="Eliminar de la oferta"
            />
          )}
        </StyledDelete>
      ) : (
        <StyledInscritaText>
          <FormattedMessage
            id="DiscardButton.AlreadyDiscarded"
            defaultMessage="Ya descartada"
          />
        </StyledInscritaText>
      )}
      <StyledDescartarDialog
        open={descartando}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        <StyledHeader>
          <StyledTitle color="primary">
            {valuation.Estado_en_proceso === VALORACION_SUGGESTION ? (
              <FormattedMessage
                id="PipelineButton.Descartar Sugerencia"
                defaultMessage="Descartar Sugerencia"
              />
            ) : (
              <FormattedMessage
                id="PipelineButton.Descartar Valoración"
                defaultMessage="Descartar Valoración"
              />
            )}
          </StyledTitle>
        </StyledHeader>
        <StyledDialogContent>
          {valuation.Estado_en_proceso === VALORACION_SUGGESTION ? (
            <FormattedMessage
              id="PipelineButton.Vas a descartar la sugerencia de {nombre}"
              defaultMessage="Vas a descartar la sugerencia de {nombre}"
              values={{
                nombre: `${valuation.candidato?.Nombre} ${valuation.candidato?.Apellidos}`,
              }}
            />
          ) : (
            <Fragment>
              <Typography>
                <FormattedMessage
                  id="PipelineButton.Vas a descartar la aplicación de {nombre}"
                  defaultMessage="Vas a descartar la aplicación de {nombre}"
                  values={{
                    nombre: `${valuation.candidato?.Nombre} ${valuation.candidato?.Apellidos}`,
                  }}
                />
              </Typography>
              {valuation.Estado_en_proceso === VALORACION_CONTRATADO && (
                <Typography>
                  <FormattedMessage
                    id="PipelineButton.Atención! Si eliminas esta candidata has de mirar si la actividad laboral de ALTA está realizada o no, y cancelarla en caso de que no esté realizada"
                    defaultMessage="Atención! Si eliminas esta candidata has de mirar si la actividad laboral de ALTA está realizada o no, y cancelarla en caso de que no esté realizada"
                  />
                </Typography>
              )}
            </Fragment>
          )}
        </StyledDialogContent>
        <StyledActions>
          <StyledCancelButton color="primary" onClick={handleCloseDialog}>
            <FormattedMessage
              id="PipelineButton.Cancelar"
              defaultMessage="Cancelar"
            />
          </StyledCancelButton>
          <StyledConfirmButton
            color="primary"
            variant="contained"
            onClick={handleConfirm}
          >
            <FormattedMessage
              id="PipelineButton.Confirmar"
              defaultMessage="Confirmar"
            />
          </StyledConfirmButton>
        </StyledActions>
      </StyledDescartarDialog>
    </Fragment>
  );
};

export default ValuationDiscardButton;
