import React from "react";
import { FormattedMessage } from "react-intl";
import {
  EMPLOYEE_YEARS_OF_EXPERIENCE_LESS_1_YEAR,
  EMPLOYEE_YEARS_OF_EXPERIENCE_LESS_2_YEARS,
  EMPLOYEE_YEARS_OF_EXPERIENCE_LESS_3_YEARS,
  EMPLOYEE_YEARS_OF_EXPERIENCE_MORE_10_YEARS,
  EMPLOYEE_YEARS_OF_EXPERIENCE_MORE_3_YEARS,
  EMPLOYEE_YEARS_OF_EXPERIENCE_MORE_5_YEARS,
  EMPLOYEE_YEARS_OF_EXPERIENCE_NO_EXPERIENCE,
} from "../../util/defaults/candidato";

interface IFormattedEmployeeExperienceFieldProps {
  value: string;
}

const FormattedEmployeeExperienceField = (
  props: IFormattedEmployeeExperienceFieldProps
) => {
  const { value } = props;

  if (value === EMPLOYEE_YEARS_OF_EXPERIENCE_LESS_1_YEAR) {
    return (
      <FormattedMessage
        id="EmployeeExperience.Less1Year"
        defaultMessage="Menos de 1 año de experiencia"
      />
    );
  }
  if (value === EMPLOYEE_YEARS_OF_EXPERIENCE_LESS_2_YEARS) {
    return (
      <FormattedMessage
        id="EmployeeExperience.Less2Years"
        defaultMessage="Menos de 2 años de experiencia"
      />
    );
  }
  if (value === EMPLOYEE_YEARS_OF_EXPERIENCE_LESS_3_YEARS) {
    return (
      <FormattedMessage
        id="EmployeeExperience.Less3Years"
        defaultMessage="Menos de 3 años de experiencia"
      />
    );
  }
  if (value === EMPLOYEE_YEARS_OF_EXPERIENCE_MORE_3_YEARS) {
    return (
      <FormattedMessage
        id="EmployeeExperience.More3Years"
        defaultMessage="Más de 3 años de experiencia"
      />
    );
  }
  if (value === EMPLOYEE_YEARS_OF_EXPERIENCE_MORE_5_YEARS) {
    return (
      <FormattedMessage
        id="EmployeeExperience.More5Years"
        defaultMessage="Más de 5 años de experiencia"
      />
    );
  }
  if (value === EMPLOYEE_YEARS_OF_EXPERIENCE_MORE_10_YEARS) {
    return (
      <FormattedMessage
        id="EmployeeExperience.More10Years"
        defaultMessage="Más de 10 años de experiencia"
      />
    );
  }
  if (value === EMPLOYEE_YEARS_OF_EXPERIENCE_NO_EXPERIENCE) {
    return (
      <FormattedMessage
        id="EmployeeExperience.NoExperience"
        defaultMessage="Sin experiencia previa"
      />
    );
  }
  return null;
};

export default FormattedEmployeeExperienceField;
