import { Box, Card, Checkbox, Tooltip, Typography } from "@material-ui/core";
import React, { useRef } from "react";
import styled from "styled-components";
import {
  COLOR_RED_NOTIFICATION,
  cuideoTheme,
} from "../../../../../../containers/themes/defaultTheme";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import DonutLargeIcon from "@material-ui/icons/DonutLarge";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import FavoriteIcon from "@material-ui/icons/Favorite";
import SchoolIcon from "@material-ui/icons/School";
import VideocamIcon from "@material-ui/icons/Videocam";
import { TGetEmployeesCollectionItem } from "../../../../../../api/employee/employee.types";
import {
  TOnCandidateMultiSelectFn,
  TOnCandidateSelectFn,
} from "../../types/candidate.types";
import { FormattedMessage } from "react-intl";
import CuideoUserAvatar from "../../../../../../components/CuideoUserAvatar";
import FormattedEmployeeExperienceField from "../../../../../../components/FormattedFields/FormattedEmployeeExperienceField";
import CandidateStatusTag from "../CandidateStatusTag/CandidateStatusTag";
import moment from "moment";
import {
  candidateFavoriteOfUser,
  candidateShouldShowInvalidIdExpiryDateWarning,
} from "../../util/candidate.util";
import { useAuthUser } from "../../../../../../reducers/hooks/useAuthUser";
import { useFavoriteFormatter } from "../../../favorites";

const StyledCard = styled(Card)<{
  selected: boolean;
  hovered?: string;
  concealed?: string;
}>`
  border: 2px solid #e7e7e7;
  box-shadow: none;
  border-radius: 0.5rem;
  overflow: visible;
  transition: all ease-in-out 150ms;
  &:hover {
    cursor: pointer;
    background-color: #fbfbfb;
    border: 2px solid ${cuideoTheme.palette.primary.main};
  }

  ${({ selected }) => {
    if (selected) {
      return `
      background-color: #f0f1f2;
    `;
    }
  }}
  ${({ concealed }) => {
    if (concealed) {
      return `
        opacity: 0.5;
        &:hover {
          opacity: 0.75;
        }
          `;
    }
  }}
  ${({ hovered }) => {
    if (hovered) {
      return `
      background-color: #fbfbfb;
      border: 2px solid ${cuideoTheme.palette.primary.main};

      `;
    }
  }}
`;

const StyledFirstRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding-right: 1.75rem;
`;
const StyledIcons = styled.div`
  max-width: 1.5rem;
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  display: flex;
  flex-direction: column;
`;

const StyledCheckbox = styled(Checkbox)`
  padding: 0.25rem;
  .MuiSvgIcon-root {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

const StyledAvatarWrapper = styled.div`
  margin: 0.5rem 1rem 0.5rem 0.25rem;
  .MuiAvatar-root {
    width: 5rem;
    height: 5rem;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
  .CMuiCuideoUserAvatar-felizVitaLogo {
    width: 1.875rem;
    height: 1.875rem;
  }
`;

const StyledCandidatoInfoWrapper = styled.div`
  margin-right: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

const StyledNameAndStatusWrapper = styled.div`
  flex-grow: 1;
`;

const StyledCandidatoName = styled(Typography)`
  color: #222;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.15;
  max-height: 1.3125rem;
  margin: 0;
  margin-bottom: 0;
  overflow-y: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
`;

const StyledStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledCandidatoEstado = styled.div`
  .CMuiCandidateStatus {
    width: 6.5rem;
    text-align: center;
  }

  margin-right: 0.5rem;
`;

const StyledSecondRow = styled.div`
  background-color: #f0f1f2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.125rem 0.25rem;
`;

const StyledCreatedWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .MuiSvgIcon-root {
    color: green;
    width: 1rem;
    height: 1rem;
  }
`;

const StyledCreatedText = styled(Typography)`
  font-size: 0.75rem;
  color: ${cuideoTheme.palette.primary.main};
`;

const StyledActiveWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .MuiSvgIcon-root {
    color: green;
    width: 1rem;
    height: 1rem;
  }
`;
const StyledActiveText = styled(Typography)`
  font-size: 0.75rem;
  color: ${cuideoTheme.palette.primary.main};
`;

const StyledFavIcon = styled(FavoriteIcon)`
  font-size: 1rem;
  color: ${COLOR_RED_NOTIFICATION};
  opacity: 0.5;
`;
const StyledSchoolIcon = styled(SchoolIcon)`
  font-size: 1rem;
  color: ${cuideoTheme.palette.primary.main};
  opacity: 0.5;
`;
const StyledAccountBoxIcon = styled(AccountBoxIcon)`
  font-size: 1rem;
  color: ${cuideoTheme.palette.primary.main};
  opacity: 0.5;
`;
const StyledDriveEtaIcon = styled(DriveEtaIcon)`
  font-size: 1rem;
  color: ${cuideoTheme.palette.primary.main};
  opacity: 0.5;
`;
const StyledInProcessIcon = styled(DonutLargeIcon)`
  font-size: 1rem;
  color: ${cuideoTheme.palette.primary.main};
  opacity: 0.5;
`;

const StyledPendingVideoIcon = styled(VideocamIcon)`
  font-size: 1rem;
  color: #f29339;
  opacity: 0.5;
`;
const StyledCompletedVideoIcon = styled(VideocamIcon)`
  font-size: 1rem;
  color: ${cuideoTheme.palette.primary.main};
  opacity: 0.5;
`;
const StyledFinalistVideoIcon = styled(VideocamIcon)`
  font-size: 1rem;
  color: ${cuideoTheme.palette.success.main};
  opacity: 0.5;
`;
const StyledInCommunicationIcon = styled(ChatBubbleIcon)`
  font-size: 1rem;
  color: ${cuideoTheme.palette.primary.main};
  opacity: 0.5;
`;
const StyledAssignmentIndIcon = styled(AssignmentIndIcon)`
  font-size: 1rem;
  color: #cc1016;
  opacity: 0.75;
`;

const StyledAdditionalInfoWrapper = styled(Box)`
  .MuiCuideoCandidatoCard-age {
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 0.75rem;
    color: ${cuideoTheme.palette.primary.main};
    line-height: 1.25;
    margin: 0 0 0.25rem;
  }
`;

interface ICandidateCardProps {
  candidate: TGetEmployeesCollectionItem;
  selected: boolean;
  // onCandidateChange: TOnCandidateChangeFn;
  onCandidateSelect: TOnCandidateSelectFn;
  onCandidateMultiSelect: TOnCandidateMultiSelectFn;
  multiSelected: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  hovered: boolean;
  concealed: boolean;
}

const CandidateCard: React.FC<ICandidateCardProps> = (props) => {
  const {
    candidate,
    selected,
    // onCandidateChange,
    onCandidateMultiSelect,
    onCandidateSelect,
    multiSelected,
    onMouseEnter,
    onMouseLeave,
    hovered,
    concealed,
  } = props;
  const cardRef = useRef();
  const { authUser } = useAuthUser();
  const { formatFavoriteTooltipContent } = useFavoriteFormatter();

  const handleCandidateClick = () => {
    onCandidateSelect(candidate);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();

    onCandidateMultiSelect(candidate);
  };

  const favorite = candidateFavoriteOfUser(candidate, authUser.userId);

  return (
    <StyledCard
      onClick={handleCandidateClick}
      selected={selected}
      onMouseEnter={() => {
        onMouseEnter();
      }}
      onMouseLeave={() => {
        onMouseLeave();
      }}
      hovered={hovered ? "hovered" : undefined}
      concealed={concealed ? "concealed" : undefined}
      ref={cardRef}
    >
      <StyledFirstRow>
        <StyledIcons>
          {!!favorite && (
            <Tooltip title={formatFavoriteTooltipContent(favorite)}>
              <StyledFavIcon />
            </Tooltip>
          )}
          {candidate.Titulaci_n_oficial_SAD &&
            candidate.Titulaci_n_oficial_SAD.length > 0 &&
            candidate.Titulaci_n_oficial_SAD[0] && (
              <Tooltip title={candidate.Titulaci_n_oficial_SAD[0]}>
                <StyledSchoolIcon />
              </Tooltip>
            )}
          {candidate.Carnet_de_conducir &&
            candidate.Carnet_de_conducir === "SI" && (
              <Tooltip
                title={
                  <FormattedMessage
                    id="EmployeeCard.DrivingLicense"
                    defaultMessage="Tiene carnet de conducir"
                  />
                }
              >
                <StyledAccountBoxIcon />
              </Tooltip>
            )}
          {candidate.Veh_culo_propio && candidate.Veh_culo_propio === "Si" && (
            <Tooltip
              title={
                <FormattedMessage
                  id="EmployeeCard.OwnsVehicle"
                  defaultMessage="Tiene vehículo propio"
                />
              }
            >
              <StyledDriveEtaIcon />
            </Tooltip>
          )}
          {candidate.interviewStatus &&
            candidate.interviewStatus === "pending" && (
              <Tooltip
                title={
                  <FormattedMessage
                    id="EmployeeCard.PendingInterview"
                    defaultMessage="Videoentrevista pendiente"
                  />
                }
              >
                <StyledPendingVideoIcon />
              </Tooltip>
            )}
          {candidate.interviewStatus &&
            candidate.interviewStatus === "completed" && (
              <Tooltip
                title={
                  <FormattedMessage
                    id="EmployeeCard.CompletedInterview"
                    defaultMessage="Videoentrevista completada"
                  />
                }
              >
                <StyledCompletedVideoIcon />
              </Tooltip>
            )}
          {candidate.interviewStatus &&
            candidate.interviewStatus === "finalist" && (
              <Tooltip
                title={
                  <FormattedMessage
                    id="EmployeeCard.FinalistInterview"
                    defaultMessage="Videoentrevista finalista"
                  />
                }
              >
                <StyledFinalistVideoIcon />
              </Tooltip>
            )}
          {!!(
            candidate.numOpenValuationProcesses &&
            candidate.numOpenValuationProcesses > 0
          ) && (
            <Tooltip
              title={
                <FormattedMessage
                  id="EmployeeCard.InOpenProcesses"
                  defaultMessage="En procesos abiertos"
                />
              }
            >
              <StyledInProcessIcon />
            </Tooltip>
          )}
          {!!(
            candidate.numOpenCommunicationIntents &&
            candidate.numOpenCommunicationIntents > 0
          ) && (
            <Tooltip
              title={
                <FormattedMessage
                  id="EmployeeCard.InOpenCommunicationIntents"
                  defaultMessage="Intentado comunicar recientemente"
                />
              }
            >
              <StyledInCommunicationIcon />
            </Tooltip>
          )}
          {candidateShouldShowInvalidIdExpiryDateWarning(candidate) && (
            <Tooltip
              title={
                <FormattedMessage
                  id="EmployeeCard.Fecha de vencimiento de DNI/NIE no válida"
                  defaultMessage="Fecha de vencimiento de DNI/NIE no válida"
                />
              }
            >
              <StyledAssignmentIndIcon />
            </Tooltip>
          )}
        </StyledIcons>
        <StyledCheckbox
          checked={multiSelected}
          onChange={handleCheckboxChange}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
          }}
          color="primary"
        />
        <StyledAvatarWrapper>
          <CuideoUserAvatar
            user={{
              userFullName: "",
              userAvatar: candidate.avatarUrl,
            }}
            isFelizVita={candidate.Company === "FV"}
          />
        </StyledAvatarWrapper>
        <StyledCandidatoInfoWrapper>
          <StyledNameAndStatusWrapper>
            {candidate && (
              <StyledCandidatoName>
                {candidate.Nombre} {candidate.Apellidos}
              </StyledCandidatoName>
            )}
            <StyledAdditionalInfoWrapper>
              <Typography className="MuiCuideoCandidatoCard-age">
                {candidate.age && candidate.A_os_de_experiencia ? (
                  <>
                    <FormattedMessage
                      id="EmployeeCard.AgeAndExp"
                      defaultMessage="{age} años. "
                      values={{
                        age: candidate.age,
                      }}
                    />
                    <FormattedEmployeeExperienceField
                      value={candidate.A_os_de_experiencia}
                    />
                  </>
                ) : candidate.age ? (
                  <FormattedMessage
                    id="EmployeeCard.Age"
                    defaultMessage="{age} años."
                    values={{
                      age: candidate.age,
                    }}
                  />
                ) : candidate.A_os_de_experiencia ? (
                  <FormattedEmployeeExperienceField
                    value={candidate.A_os_de_experiencia}
                  />
                ) : (
                  ""
                )}
              </Typography>
            </StyledAdditionalInfoWrapper>
            <StyledStatus>
              <StyledCandidatoEstado>
                <CandidateStatusTag
                  candidateId={candidate.id}
                  candidateName={candidate.Nombre}
                  status={candidate.Estado_Cuideo}
                  numContractsWorked={candidate.numContractsWorked}
                  numDaysWorked={candidate.numDaysWorked}
                  canOpenDetails={!concealed}
                />
              </StyledCandidatoEstado>
            </StyledStatus>
          </StyledNameAndStatusWrapper>
        </StyledCandidatoInfoWrapper>
      </StyledFirstRow>
      <StyledSecondRow>
        <StyledCreatedWrapper>
          <StyledCreatedText></StyledCreatedText>
        </StyledCreatedWrapper>
        <StyledActiveWrapper>
          <StyledActiveText>
            <FormattedMessage
              id="EmployeeCard.Active"
              defaultMessage="Activo {hace}"
              values={{
                hace: moment(candidate.lastActivityTime).fromNow(),
              }}
            />
          </StyledActiveText>
        </StyledActiveWrapper>
      </StyledSecondRow>
    </StyledCard>
  );
};

export default React.memo(
  (props: ICandidateCardProps) => {
    return <CandidateCard {...props} />;
  },
  (prevProps, nextProps) => {
    if (
      prevProps.hovered !== nextProps.hovered ||
      prevProps.multiSelected !== nextProps.multiSelected ||
      prevProps.concealed !== nextProps.concealed ||
      prevProps.selected !== nextProps.selected
    ) {
      return false;
    }

    // Comparing inside candidate object
    if (
      prevProps.candidate.Vencimiento_NIE !==
        nextProps.candidate.Vencimiento_NIE ||
      prevProps.candidate.avatar !== nextProps.candidate.avatar ||
      prevProps.candidate.favoritedBy !== nextProps.candidate.favoritedBy
    ) {
      return false;
    }

    return true;
  }
);
