import React from "react";

import Select, { StylesConfig } from "react-select";
import styled from "styled-components";
import { FormControl } from "@material-ui/core";
import cuideoTheme from "../../containers/themes/defaultTheme";

const StyledFormControl = styled(FormControl)`
  position: inherit;
`;

interface StyledLabelExtraProps {
  error?: boolean;
}

const StyledLabel = styled.label<StyledLabelExtraProps>`
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  color: ${(props) => (props.error ? "#ff0000" : "#004b7b")};
  line-height: 1.5;
  margin-bottom: 0.25rem;
`;

const CuideoMultiSelect = (props: any) => {
  const { variant, error, useBodyPortal } = props;

  const customStyles: StylesConfig = {
    control: (provided, state) => ({
      ...provided,
      alignItems: "center",
      backgroundColor: error
        ? "#fef5f7"
        : state.isDisabled
        ? "#ededed"
        : cuideoTheme.palette.common.white,
      borderRadius: cuideoTheme.shape.borderRadius,
      display: "flex",
      flexWrap: "wrap",
      minHeight: variant === "compact" ? 30 : 50,
      outline: 0,
      transition: "all 100ms",
      boxSizing: "border-box",
      boxShadow: "inset 0 1px 1px 0 rgba(0,0,0,.25)",
      borderColor: error ? "#f64a69" : "#c2d1da",
      borderStyle: "solid",
      borderWidth: 1,
      fontFamily: "'Source Sans Pro', Helvetica, Arial, sans-serif",
      fontWeight: variant === "compact" ? 600 : 600,
      fontSize: variant === "compact" ? "1rem" : "1.125rem",
    }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px solid #c2d1da",
      fontFamily: "'Source Sans Pro', Helvetica, Arial, sans-serif",
    }),
    indicatorSeparator: () => ({
      backgroundColor: "transparent",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: cuideoTheme.palette.primary.main,
      padding: 5,
    }),
    multiValue: (provided, state) => ({
      ...provided,
      color: cuideoTheme.palette.primary.main,
      backgroundColor: "#e5edf1",
      border: "1px solid #004b7b",
      borderRadius: 5,
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: cuideoTheme.palette.primary.main,
      fontSize: "1rem",
      padding: variant === "compact" ? 2 : 5,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: cuideoTheme.palette.primary.main,
      fontSize: variant === "compact" ? "1rem" : "1.125rem",
      padding: 5,
      opacity: 0.4,
    }),
    input: (provided, state) => ({
      ...provided,
      color: cuideoTheme.palette.primary.main,
      fontSize: variant === "compact" ? "1rem" : "1.125rem",
      fontWeight: variant === "compact" ? 600 : 600,
      padding: 5,
    }),
    menuPortal: (provided, state) => ({
      ...provided,
      zIndex: 9999,
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 99,
    }),
  };

  return (
    <StyledFormControl variant="outlined" fullWidth>
      {props.labelTitle && (
        <StyledLabel htmlFor={props.id} error={props.error}>
          {props.labelTitle} {props.required && " *"}
        </StyledLabel>
      )}
      <Select
        {...props}
        menuPortalTarget={useBodyPortal ? document.body : undefined}
        styles={customStyles}
      />
    </StyledFormControl>
  );
};

export default CuideoMultiSelect;
