import React from "react";
import { Route, Switch } from "react-router-dom";
import Error404 from "../components/Error404";
import { useAuthUser } from "../reducers/hooks/useAuthUser";
import ConversationsRoute from "./affinity/features/communications/routes/ConversationsRoute";
import ActiveContractRoute from "./affinity/features/contract/routes/ActiveContractRoute";
import ContractsPage from "./affinity/features/contract/routes/ContractsPage";
import RecruiterManagerRoute from "./affinity/features/recruitersManager";
import ContractPage from "./affinity/pages/ContractPage";
import CandidatesRoute from "./affinity/features/candidate/routes/CandidatesRoute/CandidatesRoute";
// import ServiceEditValuationPage from "./affinity/pages/ServiceEditValuationPage";
// import ServiceFollowUpPage from "./affinity/pages/ServiceFollowUpPage";
// import ServicePage from "./affinity/pages/ServicePage";
// import ServicesPage from "./affinity/pages/ServicesPage";
import UnratedServicesPage from "./affinity/features/promotersManager/routes/UnratedServicesPage";
import Empleo from "./jobsite";

interface IMainAppProps {
  match: {
    url: string;
  };
}

const MainApp: React.FC<IMainAppProps> = (props) => {
  const { match } = props;
  const { permissions } = useAuthUser();

  return (
    <div>
      <Switch>
        {process.env.REACT_APP_AFFINITY === "true" &&
          permissions.accessToRecruitManager && (
            <Route
              path={`${match.url}recruitment-manager`}
              component={RecruiterManagerRoute}
            />
          )}

        {process.env.REACT_APP_AFFINITY === "true" &&
          permissions.accessToPromoteManager && (
            <Route
              path={`${match.url}unrated-services`}
              component={UnratedServicesPage}
            />
          )}

        {process.env.REACT_APP_AFFINITY === "true" &&
          permissions.accessToContractsRoute && (
            <Route path={`${match.url}contratos`} component={ContractsPage} />
          )}
        {process.env.REACT_APP_AFFINITY === "true" &&
          permissions.accessToContractsRoute && (
            <Route
              path={`${match.url}contrato/:contratoId`}
              component={ContractPage}
            />
          )}
        {process.env.REACT_APP_AFFINITY === "true" &&
          permissions.accessToContractsRoute && (
            <Route
              path={`${match.url}active-contract/:contractId`}
              component={ActiveContractRoute}
            />
          )}

        {/* {process.env.REACT_APP_AFFINITY === "true" &&
          permissions.accessToServicesRoute && (
            <Route path={`${match.url}services`} component={ServicesPage} />
          )}
        {process.env.REACT_APP_AFFINITY === "true" &&
          permissions.accessToServicesRoute && (
            <Route
              path={`${match.url}service/:serviceId/follow-up`}
              component={ServiceFollowUpPage}
            />
          )}
        {process.env.REACT_APP_AFFINITY === "true" &&
          permissions.accessToServicesRoute && (
            <Route
              path={`${match.url}service/:serviceId/edit-valuation`}
              component={ServiceEditValuationPage}
            />
          )}
        {process.env.REACT_APP_AFFINITY === "true" &&
          permissions.accessToServicesRoute && (
            <Route
              path={`${match.url}service/:serviceId`}
              component={ServicePage}
            />
          )} */}

        {process.env.REACT_APP_AFFINITY === "true" &&
          permissions.accessToCandidatesRoute && (
            <Route
              path={`${match.url}candidatos`}
              component={CandidatesRoute}
            />
          )}

        {process.env.REACT_APP_AFFINITY === "true" &&
          permissions.accessToConversationsRoute && (
            <Route
              path={`${match.url}conversations`}
              component={ConversationsRoute}
            />
          )}

        {process.env.REACT_APP_EMPLEO === "true" && (
          <Route path={`${match.url}empleo`} component={Empleo} />
        )}

        <Route component={Error404} />
      </Switch>
    </div>
  );
};

export default MainApp;
