import { Box, Grid, Typography } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { motion } from "framer-motion";
import React, { Fragment, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { getOffer, getOffers, setBackground } from "../../../actions";
import NotFoundElement from "../../../components/BasicComponents/NotFoundElement";
import cuideoTheme from "../../../containers/themes/defaultTheme";
import { getOfertasRecomendadas } from "../../../util/Contrato/util";
import { APP_START_PAGE } from "../../../util/defaults/app";
import useFooter from "../../../util/useFooter";
import useHeader from "../../../util/useHeader";
import usePageTitle from "../../../util/usePageTitle";
import useWindowWidth from "../../../util/useWindowWidth";
import FullOfertaCard from "../components/FullOfferCard";
import OfertaCardList from "../components/OfertaCardList";

const StyledPageContainer = styled.div<{ appMarginTop: number }>`
  overflow-x: hidden;
  overflow-y: hidden;

  ${({ appMarginTop }) => {
    return `
    margin: ${appMarginTop}rem auto 0;
  `;
  }}

  @media all and (max-width: ${cuideoTheme.breakpoints.values.sm}px) {
    padding: 0;
    background-color: ${cuideoTheme.palette.common.white};
    margin: ${({ appMarginTop }) => appMarginTop}rem 0 0;
  }

  @media all and (min-width: ${cuideoTheme.breakpoints.values
      .md}px) and (max-width: ${cuideoTheme.breakpoints.values.lg}px) {
    margin: ${({ appMarginTop }) => appMarginTop + 3}rem 1rem 1rem;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.lg}px) {
    margin: ${({ appMarginTop }) => appMarginTop + 3}rem 0 1rem;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const StyledLink = styled(Link)`
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  text-decoration: none;
  color: ${cuideoTheme.palette.primary.main};
  opacity: 0.5;
`;

const StyledArrowBackIosIcon = styled(ArrowBackIosIcon)`
  font-size: 0.8rem;
`;

const StyledSimilares = styled.div`
  padding: 4rem 1rem 2rem;
  background-color: #e4ebef;

  @media all and (min-width: ${cuideoTheme.breakpoints.values.sm}px) {
    padding: 2rem 0 1rem;
  }
`;

const StyledSimilaresHeader = styled(Typography)`
  font-size: 1.25rem;
  font-weight: 700;
  color: ${cuideoTheme.palette.primary.main};
  text-align: left;
  margin: 1rem;

  @media all and (max-width: ${cuideoTheme.breakpoints.values.sm}px) {
    text-align: center;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    margin: 4rem 1rem 2rem;
  }
`;

const cardVariants = {
  entry: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: 15,
  },
};

const linkVariants = {
  entry: {
    opacity: 1,
    x: 0,
  },
  exit: {
    opacity: 0,
    x: 15,
  },
};

const PaginaOferta = (props: any) => {
  const {
    ofertas,
    match,
    appMarginTop,
    authUser,
    appliedOffers,
    setBackground,
    getOffer,
  } = props;
  const [ofertaStatus, setOfertaStatus] = useState("unload");
  const width = useWindowWidth();
  const [ofertasSimilares, setOfertasSimilares] = useState([] as any[]);
  const intl = useIntl();
  // eslint-disable-next-line
  const oferta = ofertas.find((item: any) => {
    if (item.id === match.params.ofertaId) {
      return item;
    }
  });

  useHeader({
    type: "normal",
  });
  useFooter({
    type: "normal",
  });
  usePageTitle(
    oferta && oferta.T_tulo
      ? oferta.T_tulo
      : intl.formatMessage({ id: "OfferPage.Offer " })
  );

  useEffect(() => {
    if (!oferta || !oferta.fullLoaded) {
      if (ofertaStatus === "unload") {
        setOfertaStatus("loading");
        getOffer(match.params.ofertaId, authUser);
      }
    }

    setBackground("cuideo");
    setOfertasSimilares(
      getOfertasRecomendadas(ofertas, appliedOffers, authUser)
    );
    // eslint-disable-next-line
  }, [ofertaStatus]);

  if (!oferta) {
    return (
      <StyledPageContainer appMarginTop={appMarginTop}>
        <NotFoundElement
          title={
            <FormattedMessage
              id="OfferPage.La oferta que buscas no existe"
              defaultMessage="La oferta que buscas no existe"
            />
          }
          description={
            <FormattedMessage
              id="OfferPage.Puede que la oferta ya no esté activa o se haya cerrado."
              defaultMessage="Puede que la oferta ya no esté activa o se haya cerrado."
            />
          }
          hasHomeLink={true}
        />
      </StyledPageContainer>
    );
  }

  return (
    <StyledPageContainer appMarginTop={appMarginTop}>
      {width >= 960 && (
        <Fragment>
          <Grid container>
            <Grid item xs={12}></Grid>
          </Grid>
          <Box mb={8}>
            <motion.div initial="exit" animate="entry" variants={linkVariants}>
              <StyledLink
                to={APP_START_PAGE}
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  window.history.back();
                }}
              >
                <StyledArrowBackIosIcon />
                <FormattedMessage
                  id="OfferPage.Volver"
                  defaultMessage="Volver"
                />
              </StyledLink>
            </motion.div>
          </Box>
        </Fragment>
      )}
      <Grid container spacing={8}>
        <Grid item xs={12} md={10} lg={9}>
          <motion.div initial="exit" animate="entry" variants={cardVariants}>
            <FullOfertaCard oferta={oferta} />
          </motion.div>
        </Grid>
      </Grid>
      <Grid container spacing={8}>
        <Grid item xs={12} md={10} lg={9}>
          <StyledSimilares>
            <StyledSimilaresHeader>
              {authUser ? (
                <FormattedMessage
                  id="OfferPage.Otras Ofertas interesantes para ti:"
                  defaultMessage="Otras Ofertas interesantes para ti:"
                />
              ) : (
                <FormattedMessage
                  id="OfferPage.Ofertas similares:"
                  defaultMessage="Ofertas similares:"
                />
              )}
            </StyledSimilaresHeader>
            <OfertaCardList ofertas={ofertasSimilares} />
          </StyledSimilares>
        </Grid>
      </Grid>
    </StyledPageContainer>
  );
};

const mapStateToProps = ({
  empleo,
  layout,
  auth,
}: {
  empleo: { ofertas: any };
  layout: { appMarginTop: number };
  auth: { authUser: any; appliedOffers: any };
}) => {
  const { ofertas } = empleo;
  const { appMarginTop } = layout;
  const { authUser, appliedOffers } = auth;

  return { ofertas, appMarginTop, authUser, appliedOffers };
};

export default connect(mapStateToProps, { getOffers, getOffer, setBackground })(
  PaginaOferta
);
