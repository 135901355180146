import ChatIcon from "@material-ui/icons/Chat";
import {
  Avatar,
  Button,
  Card,
  Grid,
  Tooltip,
  Typography,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import moment from "moment";
import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import {
  formatPhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import styled from "styled-components";
import AvatarIcon from "../../../../../../assets/images/iconos/avatar.png";
import BadgeIcon from "../../../../../../assets/images/iconos/badge.png";
import ListIcon from "../../../../../../assets/images/iconos/list.png";
import ZohoIcon from "../../../../../../assets/images/iconos/zoho.png";
import HorarioIcon from "../../../../../../components/CuideoIcons/HorarioIcon.svg";
import MoneyIcon from "../../../../../../components/CuideoIcons/MoneyIcon.svg";
import { avatarImgBaseUrl } from "../../../../../../config/assetLocations";
import { cuideoTheme } from "../../../../../../containers/themes/defaultTheme";
import { formatCurrency } from "../../../../../../util/formatNumbers";
import StyledContratoTipoDeTrato from "../../../../components/StyledContratoTipoDeTrato";
import { IContractCardProps } from "./ContractCard";
import ContractLocation from "./ContractLocation";
import EditableTextOverflowWithPopup from "./EditableTextOverflowWithPopup";
import { useHistory } from "react-router";
import WarningIcon from "../../../../../../assets/images/iconos/yellow-warning.png";
import { CONTRACT_ORIGIN_SALE, CONTRACT_ORIGIN_WARRANTY_ZERO } from "../../../../../../util/defaults/contrato";
import { StyledReplacementContract, StyledReplacementContractText } from "./ContractCardSC";

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  box-shadow: 0 2px 8px 0 rgba(0, 75, 123, 0.2);
  height: 14.75rem;
  border: 1px solid #fff;
  overflow: visible;

  &:hover {
    border: 1px solid ${cuideoTheme.palette.primary.main};
    cursor: pointer;
  }
`;

const StyledNewService = styled.div`
  width: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(235, 194, 0, 0.2);
  border-radius: 0.5rem 0 0 0.5rem;
`;

const StyledNewServiceText = styled(Typography)`
  transform: rotate(-180deg);
  font-size: 0.75rem;
  text-transform: uppercase;
  color: #f5a623;
  font-weight: 600;
  letter-spacing: 0.2rem;
  writing-mode: tb-rl;
`;

const StyledExistingService = styled.div`
  width: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem 0 0 0.5rem;
`;

const StyledContractInfo = styled.div`
  padding: 0.5rem;
  width: 40%;
  position: relative;
  border-right: 1px solid ${cuideoTheme.palette.divider};
`;

const StyledFirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 1.5rem;
  margin-bottom: 0.25rem;
`;

const StyledContractStatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledContractStatus = styled(Typography)`
  font-size: 0.6875rem;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  padding: 0;
  color: rgba(126, 211, 33, 1);
`;

const StyledSinceText = styled(Typography)`
  font-size: 0.6875rem;
  line-height: 1;
  margin: 0 0 0 0.25rem;
  padding: 0 0 0 0.25rem;
  color: #999;
`;

const StyledContractLegalType = styled(Typography)`
  font-size: 0.6875rem;
  line-height: 1;
  margin: 0 0 0 0.5rem;
  padding: 0 0 0 0.5rem;
  color: ${cuideoTheme.palette.primary.main};
  opacity: 0.5;
`;

const StyledActions = styled.div`
  display: flex;
  flex-direction: row;

  .MuiCuideoContractPopupViewButton {
    width: 1.5rem;
    height: 1.5rem;

    .MuiSvgIcon-root {
      width: 1rem;
      height: 1rem;
    }
  }
`;

const StyledActionButton = styled(Button)`
  text-decoration: none;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.25rem;
  padding: 0.25rem;
  min-width: unset;

  .MuiSvgIcon-root {
    width: 1.125rem;
    height: 1.125rem;
    color: ${cuideoTheme.palette.primary.main};
  }
`;

const StyledActionIcon = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  max-width: 1rem;
  max-height: 1rem;
`;

const StyledSecondRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const StyledContractTitleDetails = styled.div``;

const StyledContractTitle = styled(Typography)`
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.15;
  color: #002740;
  max-height: 3.75rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const StyledContractDetails = styled.div`
  min-width: 8rem;
`;

const StyledContractName = styled(Typography)`
  color: ${cuideoTheme.palette.common.black};
  font-weight: bold;
  font-size: 0.9375rem;
  text-align: right;
  line-height: 1.25;
`;

const StyledThirdRow = styled.div`
  height: 8.5rem;
`;

const StyledFullHeightGrid = styled(Grid)`
  height: 100%;
`;

const StyledContractProfile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 0.5rem 0;
`;

const StyledProfileAvatar = styled(Avatar)`
  width: 1.875rem;
  height: 1.875rem;

  margin-right: 0.3125rem;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
`;

const StyledProfileInfo = styled.div``;

const StyledProfileName = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-size: 0.9375rem;
  font-weight: 600;
  line-height: 1.25;
`;

const StyledProfileCrmIdName = styled(Typography)`
  opacity: 0.5;
  font-size: 0.75rem;
  line-height: 1.25;
`;

const StyledItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0.5rem 0;

  > .MuiSvgIcon-root {
    width: 1.125rem;
    height: 1.125rem;
    color: ${cuideoTheme.palette.primary.light};
    margin-right: 0.5rem;
  }
`;

const StyledIcon = styled.img`
  display: block;
  height: 1rem;
  width: 1rem;
  margin-right: 0.5rem;
  margin-top: 0.0625rem;
`;

const StyledItemDetail = styled(Typography)`
  margin-top: 0;
  font-size: 0.75rem;
  line-height: 1.25;
  color: ${cuideoTheme.palette.primary.main};
`;

const StyledClickablePhoneNumber = styled.a`
  text-decoration: none;
  font-weight: 600;
  color: ${cuideoTheme.palette.primary.main};
  padding: 0 0.25rem;
  border-radius: ${cuideoTheme.shape.borderRadius}px;

  &:hover {
    background-color: rgba(0, 72, 120, 0.25);
  }
`;

const StyledItemSearchDetail = styled.div`
  flex-grow: 1;
  margin: 0;
  .MuiTypography-root {
    font-size: 0.75rem;
    line-height: 1.25;
    color: ${cuideoTheme.palette.primary.main};
  }
`;

const StyledRight = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
`;

const StyledRightTop = styled.div`
  padding: 0.5rem 0.25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledRightTopLeft = styled.div`
  display: flex;
  flex-direction: row;

  .CMuiItemWrapper {
    margin-right: 0.5rem;
  }
`;

const StyledRightTopRight = styled.div`
  display: flex;
  flex-direction: row;

  &:first-child {
    border-right: 1px solid ${cuideoTheme.palette.divider};
  }
`;

const StyledTriItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0.5rem;
  margin: -0.125rem 0 -0.125rem;

  &.CMui-warning {
    background-color: rgba(235, 194, 0, 0.2);
  }
`;

const StyledTriIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  margin-right: 0.5rem;
`;

const StyledTriDataWrapper = styled.div``;

const StyledTriOwnerLabel = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  opacity: 0.5;
  font-size: 0.75rem;
  font-weight: 600;
  margin: 0 0 -0.125rem 0;
  line-height: 1;
  padding: 0 0.375rem;
`;

const StyledTriOwnerData = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  line-height: 1.1;
  padding: 0 0.375rem;

  max-width: 10rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledRightBottom = styled.div`
  padding: 0 0.75rem;
`;

const StyledSubCard = styled.div``;

const StyledSubCardTitle = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.35;
  color: ${cuideoTheme.palette.primary.main};
  border-bottom: 1px solid #7a7a7a;
`;

const StyledSubCardContent = styled.div``;

const StyledItemMoneyDetail = styled(Typography)`
  margin-top: 0;
  font-size: 1rem;
  line-height: 1.25;
  color: ${cuideoTheme.palette.primary.main};
`;

const ActiveContractCard: React.FC<IContractCardProps> = (props) => {
  const { contract, onContractImportantNotesUpdate } = props;
  const history = useHistory();

  const handleChangeImportantNotes = (newText?: string) => {
    onContractImportantNotesUpdate({
      contractId: contract.id,
      Notas_importantes: newText,
    });
  };

  const handleClickOnChat = () => {
    history.push(`/active-contract/${contract.id}?eTab=6`);
  };

  const contractOwnerName = contract.ownerName;
  const serviceOwnerName = contract.Servicio?.ownerName;

  return (
    <StyledCard>
      {contract.Origen === CONTRACT_ORIGIN_SALE ? (
        <StyledNewService>
          <StyledNewServiceText>
            <FormattedMessage
              id="ContractCard.NewService"
              defaultMessage="Nuevo Servicio"
            />
          </StyledNewServiceText>
        </StyledNewService>
      ) : contract.Origen === CONTRACT_ORIGIN_WARRANTY_ZERO ? (
        <StyledReplacementContract>
          <StyledReplacementContractText>
            <FormattedMessage
              id="ContractCard.ReplacementContract"
              defaultMessage="Contrato sustitución"
            />
          </StyledReplacementContractText>
        </StyledReplacementContract>
      ) : (
        <StyledExistingService></StyledExistingService>
      )}
      <StyledContractInfo>
        <StyledFirstRow>
          <StyledContractStatusWrapper>
            <StyledContractStatus>
              <FormattedMessage
                id="CotractCard.Activo"
                defaultMessage="Activo"
              />
            </StyledContractStatus>
            {!!contract.Fecha_inicio_contrato && (
              <StyledSinceText>
                <FormattedMessage
                  id="ContractCard.desde {timeAgo}"
                  defaultMessage="desde {timeAgo}"
                  values={{
                    timeAgo: moment(contract.Fecha_inicio_contrato).format(
                      "DD/MM/YYYY"
                    ),
                  }}
                />
              </StyledSinceText>
            )}
            {!!contract.Tipo_de_contrato && (
              <StyledContractLegalType>
                {contract.Tipo_de_contrato}
              </StyledContractLegalType>
            )}
          </StyledContractStatusWrapper>
          <StyledActions>
            <Tooltip
              title={
                <FormattedMessage
                  id="ContractCard.Chatear con Trabajador/a"
                  defaultMessage="Chatear con Trabajador/a"
                />
              }
            >
              <StyledActionButton onClick={handleClickOnChat}>
                <ChatIcon />
              </StyledActionButton>
            </Tooltip>
            <Tooltip
              title={
                <FormattedMessage
                  id="ContractCard.Ver en Zoho"
                  defaultMessage="Ver en Zoho"
                />
              }
            >
              <StyledActionButton
                color="primary"
                onClick={() => {
                  if (contract.zohoId) {
                    window.open(
                      `https://crm.zoho.com/crm/org638326163/tab/CustomModule4/${contract.zohoId}`
                    );
                  }
                }}
              >
                <StyledActionIcon src={ZohoIcon} />
              </StyledActionButton>
            </Tooltip>
          </StyledActions>
        </StyledFirstRow>
        <StyledSecondRow>
          <StyledContractTitleDetails>
            {!!contract.Tipo_de_Trato && (
              <StyledContratoTipoDeTrato
                tipo={contract.Tipo_de_Trato}
                punctual={contract.Servicio_corta_duraci_n === "Si"}
              />
            )}
            <StyledContractTitle>{contract.T_tulo}</StyledContractTitle>
          </StyledContractTitleDetails>
          <StyledContractDetails>
            <StyledContractName>{contract.Name}</StyledContractName>
          </StyledContractDetails>
        </StyledSecondRow>
        <StyledThirdRow>
          <StyledFullHeightGrid container spacing={8}>
            <StyledFullHeightGrid item xs={12} sm={6}>
              {!!contract.Usuario && (
                <StyledContractProfile>
                  <StyledProfileAvatar></StyledProfileAvatar>
                  <StyledProfileInfo>
                    <StyledProfileName>
                      {contract.Usuario.Nombre}{" "}
                      {contract.Usuario.Primer_Apellido}
                    </StyledProfileName>
                    <StyledProfileCrmIdName>
                      {contract.Usuario.Name}
                    </StyledProfileCrmIdName>
                  </StyledProfileInfo>
                </StyledContractProfile>
              )}
              {!!contract.Trabajador && (
                <StyledContractProfile>
                  <StyledProfileAvatar
                    src={
                      !!contract.Trabajador.avatarUrl
                        ? avatarImgBaseUrl + contract.Trabajador.avatarUrl
                        : undefined
                    }
                  ></StyledProfileAvatar>
                  <StyledProfileInfo>
                    <StyledProfileName>
                      {contract.Trabajador.Nombre}{" "}
                      {contract.Trabajador.Apellidos}
                    </StyledProfileName>
                    <StyledProfileCrmIdName>
                      {contract.Trabajador.Name}
                    </StyledProfileCrmIdName>
                  </StyledProfileInfo>
                </StyledContractProfile>
              )}
            </StyledFullHeightGrid>
            <StyledFullHeightGrid item xs={12} sm={6}>
              <StyledItem>
                <StyledIcon src={AvatarIcon}></StyledIcon>
                <StyledItemDetail>
                  {contract._Nombre_del_cliente},
                  {typeof contract._Tel_fono_cliente === "string" &&
                  isValidPhoneNumber(contract._Tel_fono_cliente) ? (
                    <StyledClickablePhoneNumber
                      href={`tel:${contract._Tel_fono_cliente}`}
                    >
                      {contract._Tel_fono_cliente}
                    </StyledClickablePhoneNumber>
                  ) : (
                    <Fragment>
                      {formatPhoneNumber(contract._Tel_fono_cliente)}
                    </Fragment>
                  )}
                </StyledItemDetail>
              </StyledItem>
              <StyledItem>
                <StyledIcon src={ListIcon}></StyledIcon>
                <StyledItemSearchDetail>
                  <EditableTextOverflowWithPopup
                    text={contract.Notas_importantes}
                    onTextChange={handleChangeImportantNotes}
                  />
                </StyledItemSearchDetail>
              </StyledItem>
              {contract.Servicio?.Zona_sin_base && (
                <StyledItem>
                  <StyledIcon src={WarningIcon}></StyledIcon>
                  <StyledItemDetail>
                    <FormattedMessage
                      id="ContractCard.Zona sin base"
                      defaultMessage="Zona sin base"
                    />
                  </StyledItemDetail>
                </StyledItem>
              )}
            </StyledFullHeightGrid>
          </StyledFullHeightGrid>
        </StyledThirdRow>
      </StyledContractInfo>
      <StyledRight>
        <StyledRightTop>
          <StyledRightTopLeft></StyledRightTopLeft>
          <StyledRightTopRight>
            {contractOwnerName === serviceOwnerName ? (
              <StyledTriItem>
                <StyledTriIcon src={BadgeIcon} />
                <StyledTriDataWrapper>
                  <StyledTriOwnerLabel>
                    <FormattedMessage
                      id="ActiveContractCard.Propietario"
                      defaultMessage="Propietario"
                    />
                  </StyledTriOwnerLabel>
                  <StyledTriOwnerData>{contractOwnerName}</StyledTriOwnerData>
                </StyledTriDataWrapper>
              </StyledTriItem>
            ) : (
              <>
                {!!contractOwnerName && (
                  <StyledTriItem>
                    <StyledTriIcon src={BadgeIcon} />
                    <StyledTriDataWrapper>
                      <StyledTriOwnerLabel>
                        <FormattedMessage
                          id="ActiveContractCard.Propietario contrato"
                          defaultMessage="Propietario contrato"
                        />
                      </StyledTriOwnerLabel>
                      <StyledTriOwnerData>
                        {contractOwnerName}
                      </StyledTriOwnerData>
                    </StyledTriDataWrapper>
                  </StyledTriItem>
                )}
                {!!serviceOwnerName && (
                  <StyledTriItem>
                    <StyledTriIcon src={BadgeIcon} />
                    <StyledTriDataWrapper>
                      <StyledTriOwnerLabel>
                        <FormattedMessage
                          id="ActiveContractCard.Propietario servicio"
                          defaultMessage="Propietario servicio"
                        />
                      </StyledTriOwnerLabel>
                      <StyledTriOwnerData>
                        {serviceOwnerName}
                      </StyledTriOwnerData>
                    </StyledTriDataWrapper>
                  </StyledTriItem>
                )}
              </>
            )}
          </StyledRightTopRight>
        </StyledRightTop>
        <StyledRightBottom>
          <StyledSubCard>
            <StyledSubCardTitle>
              <FormattedMessage
                id="ActiveContract.Detalles del contrato"
                defaultMessage="Detalles del contrato"
              />
            </StyledSubCardTitle>
            <StyledSubCardContent>
              <StyledFullHeightGrid container spacing={4}>
                <StyledFullHeightGrid item xs={12} md={6}>
                  {contract.Horario_Servicio && (
                    <StyledItem>
                      <StyledIcon src={HorarioIcon}></StyledIcon>
                      <StyledItemDetail>
                        {contract.Horario_Servicio}
                      </StyledItemDetail>
                    </StyledItem>
                  )}
                  {contract.Horas_jornada_semanal && (
                    <StyledItem>
                      <StyledIcon src={HorarioIcon}></StyledIcon>
                      <StyledItemDetail>
                        <FormattedMessage
                          id="ActiveContractCard.{hours}h/semanales"
                          defaultMessage="{hours}h/semanales"
                          values={{
                            hours: contract.Horas_jornada_semanal,
                          }}
                        />
                      </StyledItemDetail>
                    </StyledItem>
                  )}
                </StyledFullHeightGrid>
                <StyledFullHeightGrid item xs={12} md={6}>
                  <StyledItem>
                    <ContractLocation contract={contract} disableTag />
                  </StyledItem>
                </StyledFullHeightGrid>
              </StyledFullHeightGrid>
            </StyledSubCardContent>
          </StyledSubCard>
          <StyledSubCard>
            <StyledSubCardTitle>
              <FormattedMessage
                id="ActiveContract.Salario trabajador"
                defaultMessage="Salario trabajador"
              />
            </StyledSubCardTitle>
            <StyledSubCardContent>
              <StyledItem>
                <StyledIcon src={MoneyIcon}></StyledIcon>
                <StyledItemMoneyDetail>
                  {contract.Salario_Bruto_Mensual ? (
                    <Fragment>
                      <FormattedMessage
                        id="ActiveContractCard.{salary} brutos "
                        defaultMessage="{salary} brutos "
                        values={{
                          salary: formatCurrency(
                            contract.Salario_Bruto_Mensual
                          ),
                        }}
                      />
                    </Fragment>
                  ) : (
                    <Fragment>
                      <FormattedMessage
                        id="ContractCard.NotReported"
                        defaultMessage="No indicado"
                      />
                    </Fragment>
                  )}
                  {contract.Tipo_de_salario &&
                    contract.Tipo_de_salario === "Mensual" && (
                      <FormattedMessage
                        id="ContractCard.MonthSuffix"
                        defaultMessage="/mes"
                      />
                    )}
                  {contract.Tipo_de_salario &&
                    contract.Tipo_de_salario === "Diario" && (
                      <FormattedMessage
                        id="ContractCard.DaySuffix"
                        defaultMessage="/día"
                      />
                    )}
                </StyledItemMoneyDetail>
              </StyledItem>
              {!!contract.Detalle_incentivos && (
                <StyledItem>
                  <InfoOutlinedIcon />
                  <StyledItemDetail>
                    {contract.Detalle_incentivos}
                  </StyledItemDetail>
                </StyledItem>
              )}
            </StyledSubCardContent>
          </StyledSubCard>
        </StyledRightBottom>
      </StyledRight>
    </StyledCard>
  );
};

export default ActiveContractCard;
