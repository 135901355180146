import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { TContractManagerInfoResponse } from "../../../../../../../../../api/contract/apiListAllContractsManagerInfo";
import {
  SERVICE_STATE,
  TGetServicesInfoManagerCollectionItem,
} from "../../../../../../../../../api/service/service.types";
import {
  CONTRATO_ESTADO_ALTA_PENDIENTE,
  CONTRATO_ESTADO_CV_CLIENTE,
  CONTRATO_ESTADO_PRESENCIAL_CLIENTE,
} from "../../../../../../../../../util/defaults/contrato";
import {
  TUserAssignations,
  TUserGroupAssignations,
  TUserServiceAssignationsStats,
} from "../../../../../../recruitersManager/types/UserGroupAssignations.types";
import { serviceIsUnassigned } from "../../../../../../../models/Service/ServiceManagerInfo";
import usePromoterUsersQuery from "../../../hooks/usePromoterUsersQuery";
import {
  newStaffAssignationsContractStats,
  TStaffAssignationsContractStats,
} from "../../../types/StaffAssignationContractStats";
import { sortStaffAssignationsCoordinator } from "../../../types/StaffAssignationsCoordinator";
import { newStaffAssignationServiceStats } from "../../../types/StaffAssignationServiceStats";
import {
  initGroups,
  validGroupForCoordinator,
} from "../../../types/StaffAssignationsGroup";
import useConnectedUsersQuery from "./useConnectedUsersQuery";
import useContractsManagerInfoQuery from "./useContractsManagerInfoQuery";
import useServicesManagerInfoQuery from "./useServicesManagerInfoQuery";

export type TStaffAssignations = {
  groups: TUserGroupAssignations[];
  coordinators: TUserAssignations[];
  unassignedServices: TGetServicesInfoManagerCollectionItem[];
  maxServicesAssigned: number;
  maxContractsAssigned: number;
  maxGroupServices: number;
  maxGroupContracts: number;
};

export default function useStaffAssignationsQuery() {
  const [state, setState] = useState<TStaffAssignations>({
    groups: [],
    coordinators: [],
    unassignedServices: [],
    maxServicesAssigned: 0,
    maxContractsAssigned: 0,
    maxGroupServices: 0,
    maxGroupContracts: 0,
  });
  const coordinatorsQuery = usePromoterUsersQuery();
  const servicesQuery = useServicesManagerInfoQuery();
  const contractsQuery = useContractsManagerInfoQuery();
  const connectedUsersQuery = useConnectedUsersQuery();

  useEffect(() => {
    if (!coordinatorsQuery.data) {
      return;
    }

    if (!servicesQuery.data) {
      return;
    }

    if (!contractsQuery.data) {
      return;
    }

    if (!connectedUsersQuery.data) {
      return;
    }

    const groups: TStaffAssignations["groups"] = initGroups();
    const coordinators: TStaffAssignations["coordinators"] = [];
    const unassignedServices: TGetServicesInfoManagerCollectionItem[] = [];
    coordinatorsQuery.data.users.forEach((user) => {
      const userServices: TGetServicesInfoManagerCollectionItem[] = [];
      const userServicesStats: TUserServiceAssignationsStats =
        newStaffAssignationServiceStats();

      servicesQuery.data?.services.forEach((service) => {
        if (serviceIsUnassigned(service)) {
          if (
            !unassignedServices.find(
              (unassignedService) => unassignedService.id === service.id
            )
          ) {
            unassignedServices.push(service);
          }
          return;
        }

        if (service.Owner?.id === user.id) {
          userServices.push(service);

          userServicesStats.total++;
          if (service.Estado_Servicio === SERVICE_STATE.WITHOUT_VALIDATION) {
            userServicesStats.withoutValuation.total++;
          }
        }
      });

      const userContracts: TContractManagerInfoResponse[] = [];
      const userContractsStats: TStaffAssignationsContractStats =
        newStaffAssignationsContractStats();

      contractsQuery.data?.contracts.forEach((contract) => {
        if (contract.Servicio?.Owner?.id === user.id) {
          userContracts.push(contract);

          userContractsStats.total++;
          if (contract.Estado === CONTRATO_ESTADO_ALTA_PENDIENTE) {
            userContractsStats.awaiting.total++;
          }
          if (contract.Estado === CONTRATO_ESTADO_CV_CLIENTE) {
            userContractsStats.cvClient.total++;
          }
          if (contract.Estado === CONTRATO_ESTADO_PRESENCIAL_CLIENTE) {
            userContractsStats.clientFaceToFace.total++;
          }
        }
      });

      const isOnline = !!connectedUsersQuery.data?.connectedUsers.find(
        (connectedUser) => connectedUser.userId === user.id
      );

      const newCoordinator: TUserAssignations = {
        id: user.id,
        fullName: user.fullname,
        active: user.active,
        enabled: user.enabled ?? false,
        lastActivityTime: user.lastActivityTime ?? null,
        coordinationGroups: user.coordinationGroups ?? [],
        roles: user.roles,
        isOnline: isOnline,
        contracts: userContracts,
        services: userServices,
        servicesStats: userServicesStats,
        contractStats: userContractsStats,
      };
      coordinators.push(newCoordinator);
    });

    // Filter inactive with 0 services & contracts
    const filteredCoordinators = coordinators.filter((coordinator) => {
      if (coordinator.active) {
        return true;
      }

      if (
        coordinator.services.length === 0 &&
        coordinator.contracts.length === 0
      ) {
        return false;
      }

      return true;
    });

    // Add filtered coordinator to his/her group
    filteredCoordinators.forEach((coordinator) => {
      const targetGroup = validGroupForCoordinator(coordinator);
      const foundGroup = groups.find((group) => group.id === targetGroup);
      if (foundGroup) {
        foundGroup.users.push(coordinator);
      }
    });

    let maxServices = 0;
    let maxContracts = 0;
    let maxContractsState = 0;
    filteredCoordinators.forEach((coordinator) => {
      if (coordinator.services.length > maxServices) {
        maxServices = coordinator.services.length;
      }

      if (coordinator.contracts.length > maxContracts) {
        maxContracts = coordinator.contracts.length;
      }

      if (coordinator.contractStats.awaiting.total > maxContractsState) {
        maxContractsState = coordinator.contractStats.awaiting.total;
      }

      const advanced =
        coordinator.contractStats.clientFaceToFace.total +
        coordinator.contractStats.cvClient.total;
      if (advanced > maxContractsState) {
        maxContractsState = advanced;
      }
    });

    // Add stats to groups
    let maxGroupServices = 0;
    let maxGroupContracts = 0;
    groups.forEach((group) => {
      let maxServices = 0;
      let maxContracts = 0;
      let maxContractsState = 0;
      group.users.forEach((coordinator) => {
        if (coordinator.services.length > maxServices) {
          maxServices = coordinator.services.length;
        }

        if (coordinator.contracts.length > maxContracts) {
          maxContracts = coordinator.contracts.length;
        }

        if (coordinator.contractStats.awaiting.total > maxContractsState) {
          maxContractsState = coordinator.contractStats.awaiting.total;
        }

        const advanced =
          coordinator.contractStats.clientFaceToFace.total +
          coordinator.contractStats.cvClient.total;
        if (advanced > maxContractsState) {
          maxContractsState = advanced;
        }

        group.serviceStats.total += coordinator.servicesStats.total;
        group.serviceStats.withoutValuation.total +=
          coordinator.servicesStats.withoutValuation.total;
        group.serviceStats.withoutValuation.postponed +=
          coordinator.servicesStats.withoutValuation.postponed;

        group.contractStats.total += coordinator.contractStats.total;
        group.contractStats.awaiting.total +=
          coordinator.contractStats.awaiting.total;
        group.contractStats.clientFaceToFace.total +=
          coordinator.contractStats.clientFaceToFace.total;
        group.contractStats.cvClient.total +=
          coordinator.contractStats.cvClient.total;
        group.contractStats.payAndReserve.total +=
          coordinator.contractStats.payAndReserve.total;
        group.contractStats.pendingSignUp.total +=
          coordinator.contractStats.pendingSignUp.total;
      });

      group.maxServicesAssigned = maxServices;
      group.maxContractsAssigned = maxContractsState;

      if (group.serviceStats.total > maxGroupServices) {
        maxGroupServices = group.serviceStats.total;
      }
      const contractAwaiting = group.contractStats.awaiting.total;
      const contractAdvanced =
        group.contractStats.clientFaceToFace.total +
        group.contractStats.cvClient.total;
      const contractMax =
        contractAwaiting > contractAdvanced
          ? contractAwaiting
          : contractAdvanced;
      if (contractMax > maxGroupContracts) {
        maxGroupContracts = contractMax;
      }
    });

    // Sort by coordinators with more services
    const sortedCoordinators = filteredCoordinators.sort(
      sortStaffAssignationsCoordinator
    );

    // Sort in each group
    groups.forEach((group) => {
      group.users = group.users.sort(sortStaffAssignationsCoordinator);
    });

    const newState = {
      groups,
      coordinators: sortedCoordinators,
      unassignedServices,
      maxServicesAssigned: maxServices,
      maxContractsAssigned: maxContractsState,
      maxGroupServices,
      maxGroupContracts,
    };

    if (!isEqual(newState, state)) {
      setState({
        groups,
        coordinators: sortedCoordinators,
        unassignedServices,
        maxServicesAssigned: maxServices,
        maxContractsAssigned: maxContractsState,
        maxGroupServices,
        maxGroupContracts,
      });
    }
    // eslint-disable-next-line
  }, [coordinatorsQuery, servicesQuery, contractsQuery, connectedUsersQuery]);

  return {
    isLoading:
      coordinatorsQuery.isLoading ||
      servicesQuery.isLoading ||
      contractsQuery.isLoading,
    groups: state.groups,
    coordinators: state.coordinators,
    numUnValuedServices: servicesQuery.data?.services.length ?? 0,
    unassignedServices: state.unassignedServices,
    maxServicesAssigned: state.maxServicesAssigned,
    maxContractsAssigned: state.maxContractsAssigned,
    maxGroupServices: state.maxGroupServices,
    maxGroupContracts: state.maxGroupContracts,
  };
}

export type TStaffAssignationsQuery = ReturnType<
  typeof useStaffAssignationsQuery
>;
