import React from "react";
import useStaffAssignationsQuery from "./hooks/useStaffAssignationsQuery";
import StaffAssignations from "./StaffAssignations";

interface IStaffAssignationsContainer {
  reloadButtonStatus: "idle" | "loading" | "error" | "success";
  onReloadButtonClick: () => void;
  onSelectAllUnvaluedServices: () => void;
  onSelectOwner: (ownerId: string | null) => void;
  selectedOwnerId: string | null;
  onSelectUnassignedServices: () => void;
}

const StaffAssignationsContainer = (props: IStaffAssignationsContainer) => {
  const {
    reloadButtonStatus,
    onReloadButtonClick,
    onSelectAllUnvaluedServices,
    onSelectOwner,
    selectedOwnerId,
    onSelectUnassignedServices,
  } = props;

  const staffAssignationsQuery = useStaffAssignationsQuery();

  return (
    <StaffAssignations
      reloadButtonStatus={reloadButtonStatus}
      onReloadButtonClick={onReloadButtonClick}
      isLoading={staffAssignationsQuery.isLoading}
      coordinators={staffAssignationsQuery.coordinators}
      maxContracts={staffAssignationsQuery.maxContractsAssigned}
      maxServices={staffAssignationsQuery.maxServicesAssigned}
      groups={staffAssignationsQuery.groups}
      unassignedServices={staffAssignationsQuery.unassignedServices}
      numUnValuedServices={staffAssignationsQuery.numUnValuedServices}
      onSelectAllUnvaluedServices={onSelectAllUnvaluedServices}
      onSelectOwner={onSelectOwner}
      selectedOwnerId={selectedOwnerId}
      onSelectUnassignedServices={onSelectUnassignedServices}
      maxGroupServices={staffAssignationsQuery.maxGroupServices}
      maxGroupContracts={staffAssignationsQuery.maxGroupContracts}
    />
  );
};

export default StaffAssignationsContainer;
