import { Typography } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import cuideoTheme from "../../../../../../../../containers/themes/defaultTheme";

const StyledDaysText = styled(Typography)`
  color: red;
  font-weight: 700;
  font-size: 0.9375rem;
  line-height: 1;
`;

interface ITimeWithoutValidation {
  hours: number | null;
}

const TimeWithoutValidation = (props: ITimeWithoutValidation) => {
  const { hours } = props;
  if (null === hours) {
    return (
      <StyledDaysText
        style={{
          color: cuideoTheme.palette.primary.main,
          fontWeight: 400,
        }}
      >
        -
      </StyledDaysText>
    );
  }
  if (24 <= hours) {
    const days = Math.floor(hours / 24);
    return (
      <StyledDaysText>
        <FormattedMessage
          id="TimeWithoutValidation.{days} días"
          defaultMessage="{days} días"
          values={{
            days: days,
          }}
        />
      </StyledDaysText>
    );
  }

  return (
    <StyledDaysText
      style={{
        color: 6 <= hours ? "orange" : cuideoTheme.palette.primary.main,
        fontWeight: 6 <= hours ? 700 : 400,
      }}
    >
      <FormattedMessage
        id="TimeWithoutValidation.{hours} horas"
        defaultMessage="{hours} horas"
        values={{
          hours: hours,
        }}
      />
    </StyledDaysText>
  );
};

export default TimeWithoutValidation;
