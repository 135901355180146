import React from "react";
import Select, { Props, StylesConfig } from "react-select";

interface ISelectStyledWrapperProps extends Props {}

const SelectStyledWrapper = (props: ISelectStyledWrapperProps) => {
  const customStyles: StylesConfig = {
    control: (provided, state) => ({
      ...provided,
      fontFamily: "'Source Sans Pro', Helvetica, Arial, sans-serif",
      fontSize: "1rem",
      fontWeight: 600,
      backgroundColor: "rgba(0, 72, 120, .1)",
      border: "none",
      boxShadow: state.isFocused ? "0 0 0px 2px #002740" : "none",
      outline: "none",
      "&:hover": {
        backgroundColor: "rgba(0, 72, 120, .15)",
        cursor: "pointer",
      },
    }),
    container: (provided, state) => ({
      ...provided,
    }),
    option: (provided, state) => ({
      ...provided,
      fontFamily: "'Source Sans Pro', Helvetica, Arial, sans-serif",
      fontSize: "1rem",
      fontWeight: 600,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontFamily: "'Source Sans Pro', Helvetica, Arial, sans-serif",
      fontSize: "1rem",
      fontWeight: 600,
    }),
    placeholder: (provided, state) => ({
      ...provided,
    }),
    input: (provided, state) => ({
      ...provided,
      fontFamily: "'Source Sans Pro', Helvetica, Arial, sans-serif",
      fontSize: "1rem",
      fontWeight: 600,
    }),
    valueContainer: (provided, props) => ({
      ...provided,
      fontFamily: "'Source Sans Pro', Helvetica, Arial, sans-serif",
      fontSize: "1rem",
      fontWeight: 600,
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? "#aaa" : "#555",
    }),
  };

  return <Select {...props} styles={customStyles} />;
};

export default SelectStyledWrapper;
