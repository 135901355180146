import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  favoriteCandidatoSuccess,
  getCandidatosSuccess,
  getCandidatoSuccess,
  getFavoriteCandidatosSuccess,
  unfavoriteCandidatoSuccess,
} from "../actions/Candidatos";
import {
  FAVORITE_CANDIDATO,
  GET_CANDIDATO,
  GET_CANDIDATOS,
  UNFAVORITE_CANDIDATO,
} from "../constants/ActionTypes";
import { store } from "../store";
import { apiEmpleo } from "../util/ApiEmpleo";

function* getCandidatos({ payload }: { payload: any }) {
  try {
    // @ts-ignore
    const candidatosRequest = yield call(apiEmpleo.get, "candidatos", {
      params: payload,
    });
    if (candidatosRequest) {
      const partialCandidatos = candidatosRequest["hydra:member"];
      const numCandidatos = candidatosRequest["hydra:totalItems"];
      yield put(
        getCandidatosSuccess({
          partialCandidatos: partialCandidatos,
          numCandidatos: numCandidatos,
        })
      );
    }
  } catch (error) {
    console.log("Error", error);
  }
}

function* getCandidato({
  payload,
}: {
  payload: {
    candidatoId: string;
    forceCall: boolean;
  };
}) {
  try {
    let url = "candidatos/" + payload.candidatoId;
    if (payload.forceCall) {
      url += `?ts=${Date.now()}`;
    }
    // @ts-ignore
    const candidatoRequest = yield call(apiEmpleo.get, url);
    const candidatosRequest = [candidatoRequest];
    if (candidatosRequest) {
      const partialCandidatos = candidatosRequest;
      yield put(
        getCandidatoSuccess({
          partialCandidatos: partialCandidatos,
        })
      );
    }
  } catch (error) {
    console.log("Error", error);
  }
}

function* favCandidato({ type, payload }: { type: string; payload: any }) {
  const candidato = payload;
  try {
    // @ts-ignore
    yield call(
      apiEmpleo.post,
      `users/favoriteCandidatos/create?id=${candidato.id}`,
      {}
    );
    yield put(favoriteCandidatoSuccess(candidato));

    // Update favourites
    const storeState = store.getState();
    const userId =
      storeState.auth &&
      storeState.auth.authUser &&
      storeState.auth.authUser.userId
        ? storeState.auth.authUser.userId
        : null;
    if (userId) {
      // @ts-ignore
      let favRes = yield call(
        apiEmpleo.get,
        `users/${userId}/favorite_candidatos?ts=${Date.now()}`,
        {}
      );
      yield put(
        getFavoriteCandidatosSuccess({
          allFavoriteCandidatos: favRes["hydra:member"],
        })
      );
    }
  } catch (e) {
    console.log("Error SAGA FAV ", e);
  }
}

function* unfavCandidato({ type, payload }: { type: string; payload: any }) {
  const candidato = payload;
  try {
    // @ts-ignore
    yield call(
      apiEmpleo.post,
      `users/favoriteCandidatos/destroy?id=${candidato.id}`,
      {}
    );
    yield put(unfavoriteCandidatoSuccess(candidato));

    // Update favourites
    const storeState = store.getState();
    const userId =
      storeState.auth &&
      storeState.auth.authUser &&
      storeState.auth.authUser.userId
        ? storeState.auth.authUser.userId
        : null;
    if (userId) {
      // @ts-ignore
      let favRes = yield call(
        apiEmpleo.get,
        `users/${userId}/favorite_candidatos?ts=${Date.now()}`,
        {}
      );
      yield put(
        getFavoriteCandidatosSuccess({
          allFavoriteCandidatos: favRes["hydra:member"],
        })
      );
    }
  } catch (e) {
    console.log("Error SAGA UNFAV ", e);
  }
}

export function* getCandidatosSaga() {
  yield takeEvery(GET_CANDIDATOS as any, getCandidatos);
}

export function* getCandidatoSaga() {
  yield takeEvery(GET_CANDIDATO as any, getCandidato);
}

export function* favoriteCandidatoSaga() {
  yield takeEvery(FAVORITE_CANDIDATO as any, favCandidato);
}

export function* unfavoriteCandidatoSaga() {
  yield takeEvery(UNFAVORITE_CANDIDATO as any, unfavCandidato);
}

export default function* rootSaga() {
  yield all([
    fork(getCandidatosSaga),
    fork(getCandidatoSaga),
    fork(favoriteCandidatoSaga),
    fork(unfavoriteCandidatoSaga),
  ]);
}
