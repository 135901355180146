import { Box, CircularProgress } from "@material-ui/core";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
// @ts-ignore
import { Document, Page } from "react-pdf/dist/umd/entry.webpack";
import styled from "styled-components";
import { apiEmpleo } from "../../util/ApiEmpleo";
import ModalViewer from "./ModalViewer";

const StyledWrapper = styled(Box)`
  height: 100%;
  .CMuiProtectedImgImg {
    display: block;
    max-width: 100%;
    max-height: 100%;
    height: auto;
    cursor: zoom-in;

    &:hover {
      opacity: 0.75;
    }
  }
  .CMuiProtectedImgLoading {
    display: block;
    margin: 0 auto;
  }
`;

const StyledPdf = styled(Box)`
  text-align: center;
`;

interface IProtectedImageProps {
  src: string;
  allowZoom?: boolean;
  fixedPdfWidth?: number;
}

const ProtectedImage = (props: IProtectedImageProps) => {
  const { src, allowZoom } = props;
  const thumbRef = useRef<HTMLDivElement>(null);

  const [state, setState] = useState({
    image: null as any,
    loading: true,
    type: "",
    open: false,
    thumbWidth: 100,
  });

  const getSizes = useCallback(() => {
    let thumbBounding = null as null | { width: number; height: number };
    if (thumbRef && thumbRef.current) {
      thumbBounding = {
        width: thumbRef.current.getBoundingClientRect().width,
        height: thumbRef.current.getBoundingClientRect().height,
      };
    }
    const newData = {
      thumbWidth:
        thumbBounding && thumbBounding.width
          ? thumbBounding.width
          : state.thumbWidth,
    };
    return newData;
  }, [state.thumbWidth]);

  const updateSizes = useCallback(() => {
    const sizes = getSizes();
    setState((prevState) => ({
      ...prevState,
      ...sizes,
    }));
  }, [getSizes]);

  useLayoutEffect(() => {
    window.addEventListener("resize", updateSizes);

    return () => window.removeEventListener("resize", updateSizes);
  }, [updateSizes]);

  useEffect(() => {
    const requestImage = async (src: string) => {
      let res = await apiEmpleo.getRawBinary(src);

      if (res) {
        let reader = new FileReader();
        reader.onloadend = () => {
          setState((prevState) => ({
            ...prevState,
            image: reader.result,
            loading: false,
            type: res ? res.headers["content-type"] : "image/jpeg",
            open: false,
          }));

          window.dispatchEvent(new Event("resize"));
        };

        const blobRes = new Blob([res.data], {
          type: res.headers["content-type"],
        });

        reader.readAsDataURL(blobRes);
      }
    };

    requestImage(src);
  }, [src]);

  const handleOpen = () => {
    if (!allowZoom) {
      return;
    }

    setState({
      ...state,
      open: true,
    });
  };

  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };

  return (
    <Fragment>
      <StyledWrapper className="CMuiProtectedImg">
        {state.loading ? (
          <CircularProgress className="CMuiProtectedImgLoading" size={24} />
        ) : (
          <Fragment>
            {state.type.match(/^image/g) ? (
              <div
                ref={thumbRef}
                style={{
                  height: "100%",
                }}
              >
                <img
                  className="CMuiProtectedImgImg"
                  src={state.image}
                  alt="ALT"
                  onClick={handleOpen}
                />
              </div>
            ) : (
              <div ref={thumbRef}>
                <StyledPdf onClick={handleOpen}>
                  <Document file={state.image}>
                    <Page pageNumber={1} width={state.thumbWidth} />
                  </Document>
                </StyledPdf>
              </div>
            )}
          </Fragment>
        )}
        {allowZoom && (
          <ModalViewer
            open={state.open}
            src={state.image}
            type={state.type}
            onClose={handleClose}
          />
        )}
      </StyledWrapper>
    </Fragment>
  );
};

export default ProtectedImage;
