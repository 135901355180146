import { useFormikContext } from "formik";
import React, { useState } from "react";
import { IEmployeeChangeDNIPayload } from "../../../../../../components/Candidato/ChangeDNIButton/ChangeDNIButton";
import provincias from "../../../../../../util/defaults/provincias";
import { cityOptionsForPostalCode } from "../../../../../../util/geo/cityOptionsForPostalCode";
import { geocodeAddressToObject } from "../../../../../../util/geo/geocodeAddressToObject";
import { TSelectOption } from "../../../../../affinity/pages/ServicePage/components/ServiceValuation/types";
import { useSelector } from "react-redux";

export default function useCoordinatorFileESFormContext() {
  const formikContext = useFormikContext<any>();
  const [cityOptions, setCityOptions] = useState<TSelectOption[]>([]);
  const { timeSlotOptions } =
    useSelector((state: any) => state.commonData);

  const changeAddressStreet = (address: string) => {
    formikContext.setFieldValue("Direccion", address);
  };

  const selectAddress = async (address: string) => {
    const newAddress = await geocodeAddressToObject(address);

    const newCityOptions = await cityOptionsForPostalCode(
      newAddress.postalCode
    );
    if (newCityOptions.length === 0) {
      newCityOptions.push({
        value: newAddress.city,
        label: newAddress.city,
      });
    } else {
      if (newCityOptions[0]) {
        newAddress.city = newCityOptions[0].value;
      }
    }
    setCityOptions(newCityOptions);

    formikContext.setFieldValue("Direccion", newAddress.routeName);
    formikContext.setFieldValue("autocompletedDireccion", newAddress.routeName);
    formikContext.setFieldTouched("Direccion");

    formikContext.setFieldValue("Numero", newAddress.streetNumber);
    formikContext.setFieldValue("autocompletedNumero", newAddress.streetNumber);
    formikContext.setFieldTouched("Numero");

    formikContext.setFieldValue("Provincia", {
      value: newAddress.province,
      label: newAddress.province,
    });

    if (newCityOptions[0]) {
      formikContext.setFieldValue("Ciudad", newCityOptions[0]);
      formikContext.setFieldValue(
        "autocompletedCiudad",
        newCityOptions[0].value
      );
    } else {
      formikContext.setFieldValue("Ciudad", "");
      formikContext.setFieldValue("autocompletedCiudad", "");
    }
    formikContext.setFieldTouched("Ciudad");

    formikContext.setFieldValue("Codigo_postal", newAddress.postalCode);
    formikContext.setFieldValue(
      "autocompletedCodigoPostal",
      newAddress.postalCode
    );
    formikContext.setFieldTouched("Codigo_postal");

    formikContext.setFieldValue("Piso", "");

    if (newAddress.lat) {
      formikContext.setFieldValue("latitud", newAddress.lat);
    } else {
      formikContext.setFieldValue("latitud", "");
    }
    if (newAddress.lng) {
      formikContext.setFieldValue("longitud", newAddress.lat);
    } else {
      formikContext.setFieldValue("longitud", "");
    }
  };

  const postalCodeChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value.replace(/\D/g, "");
    if (e.target.value.length > 5) {
      e.target.value = e.target.value.substring(0, 5);
    }
    const postalCode = e.target.value;

    if (postalCode.length === 5) {
      const code = postalCode.substring(0, 2);
      const prov = provincias.find((item: any) => {
        return item.code === code && item.businessCountry === "es";
      });
      if (prov) {
        formikContext.setFieldValue("Provincia", {
          value: prov.value,
          label: prov.value,
        });
      }

      const newCityOptions = await cityOptionsForPostalCode(postalCode);
      setCityOptions(newCityOptions);
      if (newCityOptions.length === 0) {
        formikContext.setFieldValue("Ciudad", "");
      }
      if (newCityOptions[0]) {
        formikContext.setFieldValue("Ciudad", newCityOptions[0]);
      }
      formikContext.setFieldTouched("Ciudad");
    }
  };

  const dniChangeStart = (payload: IEmployeeChangeDNIPayload) => {};

  const dniChangeSuccess = (payload: IEmployeeChangeDNIPayload) => {
    formikContext.setFieldValue("DNI_NIE", payload.DNI_NIE);
  };

  const dniChangeError = (payload: IEmployeeChangeDNIPayload) => {};

  return {
    formikContext,
    cityOptions,
    timeSlotOptions,
    formActions: {
      changeAddressStreet,
      selectAddress,
      postalCodeChange,
      dniChangeError,
      dniChangeStart,
      dniChangeSuccess,
    },
  };
}
