import {
  Button,
  Dialog,
  IconButton,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import styled from "styled-components";
import { snackErrorMessage, snackSuccessMessage } from "../../../actions";
import cuideoTheme from "../../../containers/themes/defaultTheme";
import { apiEmpleo } from "../../../util/ApiEmpleo";
import { decorateContratosWithNumbers } from "../../../util/Contrato/util";
import {
  VALORACION_PRESENCIAL,
  VALORACION_SIN_VALORAR,
  VALORACION_TELEFONICA,
} from "../../../util/defaults/valoracion";
import IntlMessages from "../../../util/IntlMessages";
import ContratoTransferRow from "./ContratoTransferRow";
import TransferFilters from "./TransferFilters";

const StyledAssignButton = styled(Button)`
  text-transform: none;
  font-size: 0.875rem;
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    min-width: 56rem;
    min-height: 20rem;
    max-height: 75vh;
  }
`;

const StyledHeader = styled.div`
  background-color: #cdd5d8;
  color: ${cuideoTheme.palette.primary.main};
  padding: 0.75rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledTitle = styled(Typography)`
  font-weight: 400;
  font-size: 1.25rem;
`;

const StyledIconButton = styled(IconButton)``;

const StyledTitleIcon = styled.div``;
const StyledLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledContent = styled.div``;

const StyledListWrapper = styled.div`
  overflow-y: scroll;
  height: 100%;
  max-height: calc(75vh - 12rem);
  box-sizing: border-box;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 72, 120, 0.25);
    width: 0.5rem;
    border-radius: 0.25rem;
  }
`;

const StyledActions = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: row;
`;

const StyledSelect = styled(Select)`
  margin: 0 0.5rem;
`;

const StyledButton = styled(Button)`
  margin: 0 0.5rem;
`;

const StyledProgressDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: 2rem 2rem 3rem;
    min-width: 20rem;
  }
`;

const StyledProgressLabel = styled(Typography)`
  text-align: center;
  margin: 1rem 0;
  color: ${cuideoTheme.palette.primary.main};
  font-weight: 700;
`;

const StyledLinearProgress = styled(LinearProgress)``;

const AssignCandidatoButton = (props: any) => {
  const {
    label,
    candidatos,
    transferFilters,
    handleChange,
    color,
    snackErrorMessage,
    snackSuccessMessage,
  } = props;
  const { formatMessage } = useIntl();

  const [open, setOpen] = useState(false);
  const [contratos, setContratos] = useState([] as any[]);
  const [selectedContratos, setSelectedContratos] = useState([] as any[]);
  const [transferTo, setTransferTo] = useState(VALORACION_SIN_VALORAR);
  const [transferring, setTransferring] = useState(false);
  const [transfersDone, setTransfersDone] = useState(0);

  const getContratos = useCallback(
    async (params: any) => {
      params.itemsPerPage = 25;
      if (transferFilters.estado.length) {
        params.estado = transferFilters.estado;
      } else {
        delete params.estado;
      }
      if (transferFilters.tipoDeTrato.length) {
        params.tipoDeTrato = transferFilters.tipoDeTrato;
      } else {
        delete params.tipoDeTrato;
      }
      if (transferFilters.owner !== "") {
        params.owner = transferFilters.owner;
      }
      if (transferFilters.search !== "") {
        params.searchFor = transferFilters.search;
      }
      params["order[createdAt]"] = "desc";

      let res = await apiEmpleo.get("offers", {
        params,
      });

      const contratos = decorateContratosWithNumbers(res["hydra:member"]);
      setContratos(contratos);
      setSelectedContratos([]);
    },
    [transferFilters]
  );

  useEffect(() => {
    if (open) {
      if (transferFilters.owner === "owner") {
        return;
      }

      const reqParams = {} as any;
      reqParams.itemsPerPage = 10;

      getContratos(reqParams);
    }
  }, [transferFilters, getContratos, open]);

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const toggleCandidatoSelection = (candidatoId: string) => {
    if (selectedContratos.includes(candidatoId)) {
      const filtered = selectedContratos.filter(
        (item: any) => item !== candidatoId
      );
      setSelectedContratos([...filtered]);
    } else {
      setSelectedContratos([...selectedContratos, candidatoId]);
    }
  };

  const handleTransfer = (candidatos: any, contratos: any) => {
    const sendData = async (candidatos: any[], contratos: any[]) => {
      let transfersDone = 0;
      let transfersIncomplete = 0;
      try {
        setTransferring(true);
        const transfersToBeDone = candidatos.length * contratos.length;
        let done = 0;
        setTransfersDone(0);
        for (let ca = 0; ca < candidatos.length; ca++) {
          for (let co = 0; co < contratos.length; co++) {
            try {
              await apiEmpleo.post(`valoraciones`, {
                contrato: `/api/contratos/${contratos[co]}`,
                candidato: `/api/candidatos/${candidatos[ca].id}`,
                Estado_en_proceso: transferTo,
              });
              done++;
              transfersDone++;
              setTransfersDone(Math.ceil((done / transfersToBeDone) * 100));
            } catch (e: any) {
              if (
                e.response &&
                e.response.data &&
                e.response.data["hydra:description"] ===
                  "Candidato already inscribed"
              ) {
                transfersIncomplete++;
              }
            }
          }
        }
        setTransferring(false);
        setSelectedContratos([]);
        handleChange();
        if (transfersDone === 0 && transfersIncomplete === 1) {
          snackErrorMessage(formatMessage({ id: "transferir.yaasignado" }));
        } else if (transfersDone === 1 && transfersIncomplete === 0) {
          snackSuccessMessage(formatMessage({ id: "transferir.ok" }));
        } else if (transfersDone > 1 && transfersIncomplete === 0) {
          snackSuccessMessage(
            formatMessage(
              {
                id: "transferir.multipleok",
                defaultMessage: "{numCandidatos} candidatos agregados",
              },
              { numCandidatos: candidatos.length }
            )
          );
        } else if (transfersDone === 0 && transfersIncomplete > 0) {
          snackErrorMessage(
            formatMessage(
              {
                id: "transferir.multiplefail",
                defaultMessage:
                  "No se ha podido asignar ninguno de los {numCandidatos} candidatos por estar ya inscritos",
              },
              { numCandidatos: candidatos.length }
            )
          );
        } else if (transfersDone > 0 && transfersDone > 0) {
          snackSuccessMessage(
            formatMessage(
              {
                id: "transferir.mixedresult",
                defaultMessage:
                  "Se han agregado {numOK} candidatos y no se han podido agregar {numKO} candidatos por estar ya agregados",
              },
              { numOK: transfersDone, numKO: transfersIncomplete }
            )
          );
        }
      } catch (e) {
        console.log("Error", e);
      }
    };

    setOpen(false);
    sendData(candidatos, contratos);
  };

  return (
    <Fragment>
      <StyledAssignButton
        className="MuiCuideo-AssignButton"
        color={color || "primary"}
        variant="contained"
        onClick={handleDialogOpen}
      >
        <ExitToAppIcon />
        {label}
      </StyledAssignButton>
      <StyledDialog open={open} onClose={handleDialogClose}>
        <StyledHeader>
          <StyledLeft>
            <StyledTitleIcon>
              <ExitToAppIcon />
            </StyledTitleIcon>
            <StyledTitle color="primary">
              {candidatos.length === 1 ? (
                <FormattedMessage
                  id="transferir.candidato"
                  defaultMessage="Asignando a {candidatoName} a otros contratos"
                  values={{
                    candidatoName: (
                      <b>{`${candidatos[0].Nombre} ${candidatos[0].Apellidos}`}</b>
                    ),
                  }}
                />
              ) : (
                <FormattedMessage
                  id="transferir.varios"
                  defaultMessage="Asignando {numCandidatos} candidatos a otros contratos"
                  values={{
                    numCandidatos: candidatos.length,
                  }}
                />
              )}
            </StyledTitle>
          </StyledLeft>
          <StyledIconButton color="primary" onClick={handleDialogClose}>
            <CloseIcon />
          </StyledIconButton>
        </StyledHeader>
        <StyledContent>
          <TransferFilters />
          <StyledListWrapper>
            {contratos &&
              contratos.length > 0 &&
              contratos.map((contrato: any) => {
                return (
                  <ContratoTransferRow
                    key={contrato.id}
                    contrato={contrato}
                    toggleRowSelection={toggleCandidatoSelection}
                    selected={selectedContratos.includes(contrato.id)}
                  />
                );
              })}
          </StyledListWrapper>
        </StyledContent>
        <StyledActions>
          <StyledSelect
            fullWidth
            value={transferTo}
            variant="outlined"
            onChange={(e: any) => {
              setTransferTo(e.target.value);
            }}
          >
            <MenuItem value={VALORACION_SIN_VALORAR}>
              <IntlMessages id={VALORACION_SIN_VALORAR} />
            </MenuItem>
            <MenuItem value={VALORACION_TELEFONICA}>
              <IntlMessages id={VALORACION_TELEFONICA} />
            </MenuItem>
            <MenuItem value={VALORACION_PRESENCIAL}>
              <IntlMessages id={VALORACION_PRESENCIAL} />
            </MenuItem>
            {/* <MenuItem value={VALORACION_CVCLIENTE}>
              <IntlMessages id={VALORACION_CVCLIENTE} />
            </MenuItem>
            <MenuItem value={VALORACION_PRESENCIAL_CLIENTE}>
              <IntlMessages id={VALORACION_PRESENCIAL_CLIENTE} />
            </MenuItem> */}
          </StyledSelect>
          <StyledButton
            color="primary"
            fullWidth
            variant="contained"
            disabled={selectedContratos.length === 0}
            onClick={() => {
              handleTransfer(candidatos, selectedContratos);
            }}
          >
            <FormattedMessage
              id="AssignButton.Assign"
              defaultMessage="Asignar"
            />
          </StyledButton>
        </StyledActions>
      </StyledDialog>
      <StyledProgressDialog open={transferring}>
        <StyledProgressLabel>
          <FormattedMessage
            id="AssignButton.Assigning"
            defaultMessage="Asignando..."
          />
        </StyledProgressLabel>
        <StyledLinearProgress
          variant="determinate"
          value={transfersDone}
          color="primary"
        ></StyledLinearProgress>
      </StyledProgressDialog>
    </Fragment>
  );
};

const mapStateToProps = ({ filters }: { filters: any }) => {
  const { transferFilters } = filters;

  return { transferFilters };
};

export default connect(mapStateToProps, {
  snackSuccessMessage,
  snackErrorMessage,
})(AssignCandidatoButton);
