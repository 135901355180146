import { Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import {
  employeeFullName,
  TCommunicationIntentEmployee,
} from "../CreateCommunicationIntentsButton.types";

const StyledWrapper = styled.div``;

const StyledFullName = styled(Typography)`
  font-size: 1rem;
  font-weight: 600;
  color: #444;
  line-height: 1.35;
`;

const StyledDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledCrmIdName = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 600;
  color: #777;
  line-height: 1.35;
  margin-right: 0.5rem;
`;

const StyledWhatsapp = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 600;
  color: #777;
  line-height: 1.35;
`;

interface IEmployeeIdentificationProps {
  employee: Pick<
    TCommunicationIntentEmployee,
    "id" | "Name" | "Phone" | "Nombre" | "Apellidos"
  >;
}

const EmployeeIdentification: React.FC<IEmployeeIdentificationProps> = ({
  employee,
}) => {
  return (
    <StyledWrapper>
      <StyledFullName>
        {employeeFullName(employee.Nombre, employee.Apellidos)}
      </StyledFullName>
      <StyledDetails>
        <StyledCrmIdName>{employee.Name}</StyledCrmIdName>
        <StyledWhatsapp>{employee.Phone}</StyledWhatsapp>
      </StyledDetails>
    </StyledWrapper>
  );
};

export default EmployeeIdentification;
