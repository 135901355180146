import {
  TEmployeeExperience,
  TEmployeeFelizVitaStatus,
  TEmployeeStatus,
  TGetEmployeeItemUserFavorite,
} from "../employee/employee.types";
import { TServicePlan } from "../service/service.types";
import { TServiceHolderSource } from "../serviceHolder/types";
import { TValuationStatus } from "../valuation/valuation.types";

export const CONTRACT_PHASE = {
  RECRUIT: "recruit",
  PROMOTE: "promote",
  RECRUIT_AND_PROMOTE: "recruit_and_promote",
} as const;

export type TContractPhase = typeof CONTRACT_PHASE[keyof typeof CONTRACT_PHASE];

export const CONTRACT_CANDIDATE_SORT_ORDER = {
  APPROPIATED: "apropiadas",
  MORE_RECENT: "recientes",
  LESS_RECENT: "antiguas",
  LAST_CONTRACT_END_DATE: "fechaFinUltimoContrato"
}

export type TContractCandidateSortOrder = typeof CONTRACT_CANDIDATE_SORT_ORDER[keyof typeof CONTRACT_CANDIDATE_SORT_ORDER];

export const CONTRACT_STATE = {
  PENDING_SIGN_UP: "Alta pendiente",
  AWAITING: "En espera",
  PAY_AND_RESERVE: "Paga y reserva",
  CV_CLIENT: "CV cliente",
  FACE_TO_FACE_CLIENT: "Presencial cliente",
  CANCELLED_LOST_IN_SELECTION: "Cancelado - perdido en selección",
  CONTRACT_END: "Fin de contrato",
  SELECTED: "Seleccionado",
  ACTIVE: "Activo",
} as const;

export type TContractState = typeof CONTRACT_STATE[keyof typeof CONTRACT_STATE];

export type TContractShortDuration = "Si" | "No";

export const CONTRACT_DEAL_TYPE = {
  INTERNAL_DURING_WEEK: "Interna entre semana",
  INTERNAL_DURING_WEEKEND: "Interna fin de semana",
  EXTERNAL_DURING_WEEK: "Externa entre semana",
  EXTERNAL_DURING_WEEKEND: "Externa fin de semana",
  EXTERNAL_MORE_20_WEEKLY_HOURS: "Externa mas de 20 horas semanales",
  EXTERNAL_LESS_20_WEEKLY_HOURS: "Externa menos de 20 horas semanales",
} as const;

export type TContractDealType =
  typeof CONTRACT_DEAL_TYPE[keyof typeof CONTRACT_DEAL_TYPE];

export const CONTRACT_JOB_TYPE = {
  INTERNAL_DURING_WEEK: "Interna entre semana",
  INTERNAL_DURING_WEEKEND: "Interna fin de semana",
  EXTERNAL_DURING_WEEK: "Externa entre semana",
  EXTERNAL_DURING_WEEKEND: "Externa fin de semana",
} as const;

export type TContractJobType =
  typeof CONTRACT_JOB_TYPE[keyof typeof CONTRACT_JOB_TYPE];

export const CONTRACT_SALARY_TYPE = {
  MONTHLY: "Mensual",
  DAILY: "Diario",
} as const;

export type TContractSalaryType =
  typeof CONTRACT_SALARY_TYPE[keyof typeof CONTRACT_SALARY_TYPE];

export type TContractPublished = "Si" | "No";

export type TContractContact = {
  id: string;
  Email?: string;
  Lead_Source?: string;
  Phone?: string;
};

export const CONTRACT_NOTIFICATION_TYPE = {
  REQUIRES_RECRUITER_WORK: "requires_recruiter_work",
  RETURNED_BY_RECRUITER: "returned_by_recruiter",
} as const;

export type TContractNotificationType =
  typeof CONTRACT_NOTIFICATION_TYPE[keyof typeof CONTRACT_NOTIFICATION_TYPE];

export type TContractNotification = {
  id: string;
  createdAt: string;
  type: TContractNotificationType;
  viewed: boolean;
};

export const CONTRACT_REPLACEMENT_REASON = {
  HOLIDAYS: "Vacaciones",
  FESTIVITY: "Festivos",
  ILLNESS: "Baja AT/IT",
} as const;

export type TContractReplacementReason =
  typeof CONTRACT_REPLACEMENT_REASON[keyof typeof CONTRACT_REPLACEMENT_REASON];

export const CONTRACT_TYPE = {
  FULL_INDEFINITE: "Indefinido completa",
  PARTIAL_INDEFINITE: "Indefinido Parcial",
  FULL_INTERIM: "Interinidad completa",
  PARTIAL_INTERIM: "Interinidad parcial",
  FULL_EVENTUAL: "Eventual-Completa",
  PARTIAL_EVENTUAL: "Eventual-Parcial"
} as const;

export type TContractType =
  typeof CONTRACT_TYPE[keyof typeof CONTRACT_TYPE];

export const CONTRACT_OVERNIGHT_HOURS = {
  YES: "Si",
  NO: "No"
} as const;

export type TContractOvernightHours =
  typeof CONTRACT_OVERNIGHT_HOURS[keyof typeof CONTRACT_OVERNIGHT_HOURS];

export const CONTRACT_OVERNIGHT_NIGHTS = {
  ZERO: "0",
  ONE: "1",
  TWO: "2",
  THREE: "3",
  FOUR: "4",
  FIVE: "5",
  SIX: "6",
  SEVEN: "7"
} as const;

export type TContractOvernightNights =
  typeof CONTRACT_OVERNIGHT_NIGHTS[keyof typeof CONTRACT_OVERNIGHT_NIGHTS];

export const CONTRACT_PRESENCE_HOURS = {
  YES: "Si",
  NO: "No"
} as const;

export type TContractPresenceHours =
  typeof CONTRACT_PRESENCE_HOURS[keyof typeof CONTRACT_PRESENCE_HOURS];

export const CONTRACT_NUM_PAYMENTS = {
  TWELVE: "12",
  TRHIRTEEN: "13",
  FOURTEEN: "14"
} as const;

export type TContractNumPayments =
  typeof CONTRACT_NUM_PAYMENTS[keyof typeof CONTRACT_NUM_PAYMENTS];

export type TGetContractsCollectionItem = {
  id: string;
  Ciudad_oferta?: string;
  Ciudad_servicio?: string;
  Cl_usulas_especiales?: string;
  C_digo_Postal_Oferta?: string;
  Codigo_postal_servicio?: string;
  Cvclientedatetime?: string;
  Detalle_incentivos?: string;
  Domicilio_servicio?: string;
  Email_seguimiento_cv?: boolean;
  Email_intento_de_contacto_seguimiento_cv?: boolean;
  Estado?: TContractState;
  Fecha_inicio_contrato?: string;
  Fecha_fin_contrato?: string;
  Fecha_cv_cliente?: string;
  Fecha_de_inicio_real?: string;
  Fecha_objetivo?: string;
  Fecha_publicacion_oferta?: string;
  Horario_Servicio?: string;
  Horas_jornada_semanal?: string;
  Horas_de_pernocta?: TContractOvernightHours;
  Horas_de_presencia?: TContractPresenceHours;
  N_de_horas_de_presencia_a_la_semana1?: number;
  Latitud?: string;
  Longitud?: string;
  Llamada_segumiento_cv?: boolean;
  Motivo_reemplazo?: TContractReplacementReason;
  Name?: string;
  Noches_de_pernocta?: TContractOvernightNights;
  Notas_importantes?: string;
  Numero_de_pagas?: TContractNumPayments;
  Origen?: string;
  Patolog_as_tratadas_previamente: string[];
  Per_odo_de_prueba?: string;
  Promoci_n_Indeed?: string;
  Provincia_oferta?: string;
  Provincia_servicio?: string;
  Publicar?: TContractPublished;
  Que_buscamos?: string;
  Requisitos?: string;
  Salario_Bruto_Mensual?: number;
  Seguimiento_cv_realizado?: boolean;
  Servicio_Express?: boolean;
  Servicio?: {
    id: string;
    Name?: string;
    Plan?: TServicePlan;
    Referente_B2B?: {
      id: string;
      Name?: string;
    };
    Fecha_objetivo_entrevistas?: string;
    promoterNotes?: string;
    ownerName?: string;
    Zona_sin_base: boolean;
  };
  Servicio_corta_duraci_n?: TContractShortDuration;
  Squad?: string;
  T_tulo?: string;
  Tareas_solicitadas?: string[];
  Tipo?: TContractJobType;
  Tipo_de_contrato?: TContractType;
  Tipo_de_Trato?: TContractDealType;
  Tipo_de_salario?: TContractSalaryType;
  Trabajador?: {
    id: string;
    Name?: string;
    Nombre?: string;
    Apellidos?: string;
    avatarUrl?: string;
  };
  Usuario?: {
    id: string;
    Name?: string;
    Nombre?: string;
    Primer_Apellido?: string;
  };
  _Nombre_del_cliente?: string;
  _Tel_fono_cliente?: string;
  createdAt?: string;
  customer?: TContractContact;
  customerId?: string;
  faceToFaceWarnAt?: string;
  location_error_margin: number;
  nextCvFollowUpAt?: string;
  notifications?: TContractNotification[];
  ownerId?: string;
  ownerName?: string;
  priority: number;
  zohoId?: string;

  numCommunicationIntents: number;
  numContratado?: number;
  numPresencialCliente?: number;
  numSuccessfulCommunicationIntents?: number;
  numCVCliente?: number;

  phase?: TContractPhase;
  Oferta_FV?: boolean;
  Oferta_Fake?: boolean;
};

export type TGetContractsNumbersItemResponse = {
  id: string;
  numContracted: number;
  numCvClient: number;
  numDiscarded: number;
  numFaceToFace: number;
  numFaceToFaceClient: number;
  numPhone: number;
  numSuggestions: number;
  numSuggestionsAccepted: number;
  numSuggestionsRejected: number;
  numWithoutVal: number;
};

export type TGetContractsItemValuationCandidate = {
  id: string;
  A_os_de_experiencia?: TEmployeeExperience;
  Apellidos?: string;
  Carnet_de_conducir: string;
  Company?: string;
  Estado_Cuideo?: TEmployeeStatus;
  Estado_FV?: TEmployeeFelizVitaStatus;
  Name?: string;
  Nombre?: string;
  Titulaci_n_oficial_SAD: string[];
  Veh_culo_propio: string;
  Vencimiento_NIE?: string;
  age?: number;
  avatarUrl?: string;
  createdAt?: string;
  employeeScore?: number;
  favoritedBy?: TGetEmployeeItemUserFavorite[];
  interviewStatus: string;
  numContractsWorked?: number;
  numDaysWorked?: number;
  numOpenValuationProcesses: number;
  numOpenCommunicationIntents: number;
  lastContractEndDate?: string;
};

export type TGetContractsItemValuation = {
  id: string;
  Candidato_Web?: string;
  Descripci_n?: string;
  Descripcion_Web?: string;
  Estado_en_proceso?: TValuationStatus;
  Name?: string;
  Source?: string;
  candidato?: TGetContractsItemValuationCandidate;
  createdAt?: string;
  statusUpdatedAt?: string;
  suggestionScore?: number;
  updatedAt?: string;
  zohoId?: string;
};

export type TGetContractsItem = TGetContractsCollectionItem & {
  Servicio?: {
    id: string;
    Otras_notas_valoracion?: string;
    Source?: string;
    Fecha_objetivo_entrevistas?: string;
    Informe_general_inicial?: string;
    Rutinas?: string;
    Sexo_trabajador?: string;
    Tareas_solicitadas?: string[];
    Tipo_de_salario?: TContractSalaryType;
    Titulaci_n_oficial?: string;
    Usuario1?: {
      id: string;
      Actividades_y_aficiones_U1?: string;
      Actividades_y_aficiones_U2?: string;
      Adaptaci_n_de_cuidados_U1?: string;
      Adaptaci_n_de_cuidados_U2?: string;
      Altura_U1?: number;
      Altura_U2?: number;
      Aseo_U1?: string[];
      Aseo_U2?: string[];
      Calidad_del_sue_o_U1?: string;
      Calidad_del_sue_o_U2?: string;
      Caracteristicas_de_la_vivienda?: string[];
      Ciudad?: string;
      Codigo_postal?: string;
      Comentarios_estado_emocional_y_caracter_U1?: string;
      Comentarios_estado_emocional_y_caracter_U2?: string;
      Comentarios_movilidad_U1?: string;
      Comentarios_movilidad_U2?: string;
      Comentarios_patologias_U1?: string;
      Comentarios_patologias_U2?: string;
      Comunicacion_con_transporte_publico?: string;
      Dieta?: string;
      Dieta_U2?: string;
      Direccion?: string;
      Estado?: string;
      Estado_U1?: string;
      Estado_U2?: string;
      Estado_emocional_y_caracter_U1?: string[];
      Estado_emocional_y_caracter_U2?: string[];
      Fecha_de_nacimiento_U1?: string;
      Fecha_de_nacimiento_U2?: string;
      Grado_Ley_de_dependencia_U1?: string;
      Grado_Ley_de_dependencia_U2?: string;
      Habla_expresion_U1?: string;
      Habla_expresion_U2?: string;
      Indicaciones_del_aseo_U1?: string;
      Indicaciones_del_aseo_U2?: string;
      Indicaciones_del_sueno_U1?: string;
      Indicaciones_del_sueno_U2?: string;
      Medicacion_U1?: string;
      Medicacion_U2?: string;
      Movilidad_U1?: string[];
      Movilidad_U2?: string[];
      Name?: string;
      Nombre_U1?: string;
      Nombre_U2?: string;
      Patologias_U1?: string[];
      Patologias_U2?: string[];
      Peso_U1?: number;
      Peso_U2?: number;
      Primer_apellido_U1?: string;
      Primer_apellido_U2?: string;
      Region?: string;
      Source?: TServiceHolderSource;
      Tipo_de_vivienda?: string;
      Unidad_de_convivencia?: string;
      Usuarios_a_cuidar?: number;
      Notas_coordinadora?: string;
    };
    ownerName: string;
  };

  valoraciones: TGetContractsItemValuation[];
  numWithoutVal: number;
  numPhone: number;
  numFaceToFace: number;
  numCvClient: number;
  numFaceToFaceClient: number;
  numContracted: number;
};
