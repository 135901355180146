import { Box, Typography } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const StyledErrorWrapper = styled(Box)`
  .CMuiErrorBox {
    background-color: rgb(253, 236, 234);
    color: rgb(97, 26, 21);
    padding: 1rem;
    max-width: 16rem;
    margin: 20vh auto;

    .MuiSvgIcon-root {
      display: block;
      margin: 0 auto 1rem;
      width: 2rem;
      height: 2rem;
    }

    .CMuiErrorBoxMessage {
      font-size: 1.25rem;
      line-height: 1.35;
      text-align: center;
    }
  }
`;

const ErrorBox = () => {
  return (
    <StyledErrorWrapper className="CMuiErrorBoxWrapper">
      <Box className="CMuiErrorBox">
        <ErrorOutlineIcon />
        <Typography className="CMuiErrorBoxMessage">
          <FormattedMessage
            id="ContractsPage.QueryError"
            defaultMessage="Ha habido un error al acceder a los datos"
          />
        </Typography>
      </Box>
    </StyledErrorWrapper>
  );
};

export default ErrorBox;
