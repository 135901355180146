import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import FlipMove from "react-flip-move";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import cuideoTheme from "../../../containers/themes/defaultTheme";
import { TOnContractValuationChangeFn } from "../features/contract/types/contract-valuation.types";
import { ValuationCard } from "../features/valuation";
import { TGetContractsItemValuation } from "../../../api/contract/contract.types";

const StyledWrapper = styled.div`
  height: 100%;
`;

const StyledValoracionesHeader = styled.div`
  background-color: rgba(255, 255, 255, 0.85);
  position: absolute;
  top: 0;
  left: 0;
  right: 0.5rem;
  padding: 0.5rem 1rem;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledSelectAll = styled(Typography)``;

const StyledTotalValoraciones = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-size: 0.875rem;
  line-height: 1.25;
  text-align: right;
`;

const StyledSelectAllFormContolLabel = styled(FormControlLabel)`
  padding: 0 1rem;
  .MuiButtonBase-root {
    padding: 0.25rem;
  }
  .MuiFormControlLabel-label {
    font-size: 0.875rem;
    color: ${cuideoTheme.palette.primary.main};
  }
  .MuiSvgIcon-root {
    width: 1.25rem;
    height: 1.25rem;
    color: ${cuideoTheme.palette.primary.main};
  }
`;

const StyledValoracionesContainer = styled.div`
  padding: 2rem 1rem 1rem;
  background-color: ${cuideoTheme.palette.common.white};
  width: 28rem;
  overflow-y: scroll;
  height: 100%;
  box-sizing: border-box;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 72, 120, 0.25);
    width: 0.5rem;
    border-radius: 0.25rem;
  }
`;

const StyledValoracionWrapper = styled.div`
  margin: 1rem 0;
`;

const StyledPagination = styled(Pagination)`
  .MuiPagination-ul {
    justify-content: center;
  }
`;

interface ValuationsListProps {
  isLoading: boolean;
  valoraciones: any[];
  totalValoraciones: number;
  selectedValoracion: any;
  selectedValoraciones: any[];
  selectedAll: boolean;
  onToggleSelectAll: () => void;
  onValoracionSelect: (valoracion: any) => void;
  onValuationChange: TOnContractValuationChangeFn;
  onToggleValoracionMultiSelect: (valoracion: any) => void;
  page: number;
  onPageChange: (event: any, newPage: number) => void;
  itemsPerPage: number;
  hoveredValoracion?: any;
  onValoracionHover?: (valoracion: any) => void;
  onTempChange?: (action: string, valoraciones: any[]) => void;
  valoracionesBlocked?: boolean;
  addMode?: boolean;
  canAdvanceMoreValuations: boolean;
  isOutstanding: boolean;
  onValuationContracted: (valoracion: TGetContractsItemValuation, data: any, successMsg: any, errorMsg: any) => void;
}

const ValuationsList = (props: ValuationsListProps) => {
  const {
    isLoading,
    valoraciones,
    totalValoraciones,
    selectedValoraciones,
    selectedValoracion,
    selectedAll,
    onToggleSelectAll,
    onValuationChange,
    onValoracionSelect,
    onToggleValoracionMultiSelect,
    page,
    onPageChange,
    itemsPerPage,
    hoveredValoracion,
    onValoracionHover,
    valoracionesBlocked,
    addMode,
    canAdvanceMoreValuations,
    isOutstanding,
    onValuationContracted
  } = props;

  return (
    <StyledWrapper>
      {valoraciones.length > 0 && (
        <StyledValoracionesHeader>
          <StyledSelectAll>
            {!valoracionesBlocked && (
              <StyledSelectAllFormContolLabel
                control={
                  <Checkbox
                    checked={selectedAll}
                    onChange={onToggleSelectAll}
                    color="primary"
                  />
                }
                label={
                  <FormattedMessage
                    id="ValuationsList.SelectAll"
                    defaultMessage="Seleccionar todos"
                  />
                }
              />
            )}
          </StyledSelectAll>
          <StyledTotalValoraciones>
            <FormattedMessage
              id="ValuationsList.NumCandidates"
              defaultMessage="{numValoraciones} candidatos"
              values={{
                numValoraciones: totalValoraciones,
              }}
            />
          </StyledTotalValoraciones>
        </StyledValoracionesHeader>
      )}
      <StyledValoracionesContainer>
        <FlipMove
          duration={250}
          staggerDelayBy={20}
          enterAnimation="fade"
          leaveAnimation={false} // If not, we can have a nasty bug
        >
          {valoraciones.map((valoracion: any) => {
            return (
              <StyledValoracionWrapper key={valoracion.id}>
                <ValuationCard
                  valuation={valoracion}
                  isSelected={
                    !!selectedValoracion &&
                    selectedValoracion.id === valoracion.id
                  }
                  onSelect={onValoracionSelect}
                  isDisabled={
                    !!valoracion.status && valoracion.status === "mutating"
                  }
                  onChange={onValuationChange}
                  isMultiSelected={Boolean(
                    selectedValoraciones.filter(
                      (selVal: any) => selVal.id === valoracion.id
                    ).length
                  )}
                  onMultiSelect={onToggleValoracionMultiSelect}
                  onMouseEnter={() => {
                    onValoracionHover && onValoracionHover(valoracion);
                  }}
                  onMouseLeave={() => {
                    onValoracionHover && onValoracionHover(null);
                  }}
                  isHovered={
                    !!hoveredValoracion &&
                    hoveredValoracion.id === valoracion.id
                  }
                  isConcealed={
                    !!selectedValoracion &&
                    selectedValoracion.id !== valoracion.id
                  }
                  isBlocked={!!valoracionesBlocked}
                  canAdvanceMoreValuations={canAdvanceMoreValuations}
                  isOutstanding={isOutstanding}
                  onContracted={onValuationContracted}
                />
              </StyledValoracionWrapper>
            );
          })}
        </FlipMove>
        {totalValoraciones > itemsPerPage && (
          <StyledPagination
            count={Math.ceil(totalValoraciones / itemsPerPage)}
            page={page}
            onChange={onPageChange}
            color="primary"
          />
        )}
        {addMode && !isLoading && totalValoraciones === 0 && (
          <Typography color="primary">
            <FormattedMessage
              id="ValoracionesList.NoSuggestions"
              defaultMessage="No hay candidatos en esta zona con los criterios establecidos. Pruebe moviendo el mapa o cambiando los filtros."
            />
          </Typography>
        )}
        {!addMode && !isLoading && totalValoraciones === 0 && (
          <Typography color="primary">
            <FormattedMessage
              id="ValoracionesList.NoVals"
              defaultMessage="No hay valoraciones con estos criterios en este contrato. Pruebe con otros criterios."
            />
          </Typography>
        )}
      </StyledValoracionesContainer>
    </StyledWrapper>
  );
};

export default ValuationsList;
