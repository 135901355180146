import { AxiosResponse } from "axios";
import { TApiPaginatedResponse } from "../api-corev3.types";
import { apiCoreV3 } from "../apiCoreV3";
import { TConversationResponse } from "./apiConversation.types";

const defaultParameters = {
  page: 1,
  pageSize: 10,
};

export interface IListConversationsParams {
  page?: number;
  pageSize?: number;
  contactId?: string;
  unread?: boolean;
  interestedId?: string;
  order?: "desc" | "asc";
  orderBy?: "updatedAt" | "lastReceivedAt";
}

const apiListConversations = async (params: IListConversationsParams = {}) => {
  const res: AxiosResponse<TApiPaginatedResponse<TConversationResponse>> =
    await apiCoreV3.get(`/communication/conversations`, {
      params: {
        ...defaultParameters,
        ...params,
      },
    });

  return res.data;
};

export { apiListConversations };
