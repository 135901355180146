import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import StarIcon from "@material-ui/icons/Star";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import styled from "styled-components";
import cuideoTheme from "../../containers/themes/defaultTheme";
import { JOB_SIGN_IN } from "../../constants/routes";

const StyledCard = styled(Card)`
  background-color: #f5e180;
  padding: 2rem 1rem;
  margin-bottom: 1rem;
  position: relative;

  @media all and (min-width: ${cuideoTheme.breakpoints.values.sm}px) {
    margin-bottom: 3rem;
  }
`;

const StyledStarIcon = styled(StarIcon)`
  color: #ebc200;
  display: block;
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    width: 3rem;
    height: 3rem;
    margin: 0 auto 0 auto;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    width: 3rem;
    height: 3rem;
    margin: 0 0.5rem 0 auto;
  }
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }
`;

const StyledTypography = styled(Typography)`
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    text-align: center;
    line-height: 1.25;
    font-size: 1rem;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 1.25rem;
    line-height: 1.2;
  }
`;

const StyledSmallerTypography = styled(Typography)`
  font-size: 1rem;
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    text-align: center;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
  }
`;

const StyledButton = styled(Button)``;

const StyledLink = styled(Link)`
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    max-width: 12.1875rem;
    display: block;
    margin: 0 auto;
  }
`;

const InteresaAlgunaOferta = () => {
  const [hideInteresaAlgunaOferta, setHideInteresaAlgunaOferta] = useState(
    sessionStorage.getItem("hideInteresaAlgunaOferta")
  );

  if (hideInteresaAlgunaOferta) {
    return <div></div>;
  }

  const handleClose = (e: React.MouseEvent) => {
    sessionStorage.setItem("hideInteresaAlgunaOferta", "hide");
    setHideInteresaAlgunaOferta("hide");
  };

  return (
    <StyledCard elevation={0}>
      <StyledIconButton onClick={handleClose}>
        <CloseIcon color="primary" />
      </StyledIconButton>
      <Grid container spacing={8} alignItems="center">
        <Grid item xs={12} md={1}>
          <StyledStarIcon />
        </Grid>
        <Grid item xs={12} md={5}>
          <StyledTypography color="primary">
            <b>
              <FormattedMessage
                id="OfferInteres.DoYouHaveInterest"
                defaultMessage="¿Te interesa alguna oferta?"
              />
            </b>
          </StyledTypography>
          <StyledTypography color="primary">
            <FormattedMessage
              id="OfferInterest.SignInOrSignUp"
              defaultMessage="Inicia sesión o regístrate en Cuideo para poder aplicar fácilmente a todas las ofertas que quieras."
            />
          </StyledTypography>
        </Grid>
        <Grid item xs={12} md={5}>
          <Grid container spacing={10} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box mb={4}>
                <StyledSmallerTypography color="primary">
                  <FormattedMessage
                    id="OfferInterest.AlreadySigned"
                    defaultMessage="Si ya estás registrada/o:"
                  />
                </StyledSmallerTypography>
              </Box>
              <StyledLink to={JOB_SIGN_IN} style={{ textDecoration: "none" }}>
                <StyledButton
                  color="primary"
                  variant="outlined"
                  size="medium"
                  fullWidth
                  style={{ textTransform: "none" }}
                >
                  <FormattedMessage
                    id="OfferInterest.SignIn"
                    defaultMessage="Iniciar sesión"
                  />
                </StyledButton>
              </StyledLink>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mb={4}>
                <StyledSmallerTypography color="primary">
                  <FormattedMessage
                    id="OfferInterest.AreYouNew"
                    defaultMessage="¿Eres nueva/o en Cuideo?"
                  />
                </StyledSmallerTypography>
              </Box>
              <StyledLink
                to="/registroCuidador"
                style={{ textDecoration: "none" }}
              >
                <StyledButton
                  color="primary"
                  variant="contained"
                  size="medium"
                  fullWidth
                  style={{ textTransform: "none" }}
                >
                  <FormattedMessage
                    id="OfferInterest.SignUp"
                    defaultMessage="Registrate"
                  />
                </StyledButton>
              </StyledLink>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledCard>
  );
};

export default InteresaAlgunaOferta;
