import { Box, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const StyledBox = styled(Box)`
  .MuiTypography-root {
    font-size: 1rem;
    line-height: 1.35;
  }
`;

const ContractsTabHelp = () => {
  return (
    <StyledBox>
      <Typography>
        En este listado se muestran los contratos para los que el candidato/a ha
        sido contratado y que están en estado Activo, Seleccionado o Fin de
        Contrato
      </Typography>
    </StyledBox>
  );
};

export default ContractsTabHelp;
