import React from "react";
import EmployeeProfileFR from "./EmployeeProfileFR";
import EmployeeProfileES from "./EmployeeProfileES";

const EmployeeProfile = (props: any) => {
  const { candidato } = props;

  if (candidato.Business_Country === "fr") {
    return <EmployeeProfileFR employee={candidato} />;
  } else {
    return <EmployeeProfileES employee={candidato} />;
  }
};

export default EmployeeProfile;
