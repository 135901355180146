import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userSignUp, userSignUpResetError } from "../../../actions/Auth";
import {
  TCandidateSignUp,
  TStepSubmitFn,
} from "../models/CandidateSignUp.model";

export default function useCandidateSignUpState() {
  const [state, setState] = useState({
    step: 1,
    dialogOpen: false,
    sendStatus: null,
    data: null as Partial<TCandidateSignUp> | null,
  });

  const { utmSource, utmMedium } = useSelector(
    (state: any) => state.commonData as any
  );
  const dispatch = useDispatch();

  const sourceFromInitParams = () => {
    let source = "Portal Cuideo";

    if (utmSource) {
      switch (utmSource.toLowerCase()) {
        case "google_ads":
          source = "Google Ads";
          break;
        case "indeed":
          source = "Indeed";
          if (utmMedium && utmMedium === "cpc") {
            source = "Indeed cpc";
          }
          if (utmMedium && utmMedium === "organic") {
            source = "Indeed organic";
          }
          break;
        case "jobatus":
          source = "Jobatus";
          break;
        case "jooble":
          source = "Jooble";
          break;
        case "iberempleos":
          source = "Iberempleos";
          break;
        case "jobrapido":
          source = "Jobrapido";
          break;
        case "facebook":
          source = "Facebook";
          break;
        case "domestiko":
          source = "Domestiko";
          break;
        case "cronoshare":
          source = "Cronoshare";
          break;
        case "monster":
          source = "Monster";
          break;
        case "trabajos":
          source = "Trabajos";
          break;
        case "milanuncios":
          source = "Milanuncios";
          break;
        default:
          break;
      }
    }

    return source;
  };

  const stepSubmit: TStepSubmitFn = ({ step, sendStatus, data }) => {
    const newData = { ...state.data, ...data };

    if (sendStatus === "send") {
      const source = sourceFromInitParams();

      const payload: Partial<TCandidateSignUp> = {
        username: newData.DNI_NIE,
        password: newData.password,
        Nombre: newData.Nombre,
        Apellidos: newData.Apellidos,
        Apellidos_2: newData.Apellidos_2,
        DNI_NIE: newData.DNI_NIE,
        Email: newData.Email,
        Phone: newData.Phone ? newData.Phone.replace(/\s+/g, "") : "",
        Fecha_de_nacimiento: newData.fechaNacimiento,
        Sexo: newData.sexo,
        Lugar_de_nacimiento: newData.paisNacimiento
          ? newData.paisNacimiento.value
          : "",
        Ciudad: newData.ciudad,
        avatar: newData.avatar,
        Codigo_postal: newData.codigoPostal,
        calle: newData.calle,
        bloque: newData.bloque,
        numero: newData.numero,
        piso: newData.piso,
        Carnet_de_conducir: newData.permisoConducir,
        Veh_culo_propio: newData.cochePropio,
        A_os_de_experiencia: newData.A_os_de_experiencia,
        Latitud: newData.latitud,
        Longitud: newData.longitud,
        Provincia: newData.provincia,
        // Direccion: data.calle + " " + data.numero + " " + data.piso,
        Direccion: newData.calle,
        Numero: newData.numero,
        Piso: newData.piso,
        Interna_entre_semana: newData.Interna_entre_semana,
        Interna_fin_de_semana: newData.Interna_fin_de_semana,
        Externa_entre_semana: newData.Externa_entre_semana,
        Externa_fin_de_semana: newData.Externa_fin_de_semana,
        R_gimen_de_trabajo: newData.R_gimen_de_trabajo,
        Otros_Idiomas: newData.idiomas,
        Titulaci_n_oficial_SAD: newData.titulaciones,
        Qu_tareas_quieres_o_puedes_realizar: newData.tipoTareas,
        No_iniciar_bot_hasta: newData.No_iniciar_bot_hasta,
        Acepto_las_condiciones_de_uso: true,
        Consiento_el_env_o_de_comunicaciones: true,
        Fuente: source,
        cif_obverse: newData.cif_obverse,
        cif_back: newData.cif_back,
        cv: newData.cv,
        Franja_horaria: newData.Franja_horaria
      };

      dispatch(userSignUp(payload));
    }

    setState({
      ...state,
      data: newData,
      step: !!step ? step : state.step,
    });
  };

  const dialogClose = () => {
    setState((prevState) => ({
      ...prevState,
      dialogOpen: false,
    }));
  };

  const signUpReset = () => {
    dispatch(userSignUpResetError());
  };

  return {
    state,
    actions: {
      stepSubmit,
      dialogClose,
      signUpReset,
    },
  };
}
