import { AxiosResponse } from "axios";
import { apiCoreV3 } from "../apiCoreV3";

export type TContractResponse = {
  id: string;
  customer_id: string;
}

export const apiGetCoreV3ContractByCrmId = async (contractCrmId: string) => {
  const res: AxiosResponse<TContractResponse> =
    await apiCoreV3.get(
      `contract/crm_id/${contractCrmId}`
    );

  return res.data;
}

export const apiGetCoreV3ContractById = async (contractId: string) => {
  const res: AxiosResponse<TContractResponse> =
    await apiCoreV3.get(
      `contract/${contractId}`
    );

  return res.data;
}
