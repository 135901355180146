import {
  Box,
  Fab,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import "moment/locale/es";
import React, { Fragment, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";
import uuidv4 from "uuid/v4";
import cuideoTheme from "../../../containers/themes/defaultTheme";
import useLocalStorage from "../../../util/useLocalStorage";
import CircularProgressBox from "../../CircularProgressBox/CircularProgressBox";
import ConfirmDialog from "../../ConfirmDialog";
import Note from "./Note";
import NotesForm from "./NotesForm";
import useAddNoteMutation from "./useAddNoteMutation";
import useEmployeeNotesQuery from "./useEmployeeNotesQuery";
import useRemoveNoteMutation from "./useRemoveNoteMutation";

const StyledTitle = styled(Box)`
  .CMuiNotesTableTitle {
    color: ${cuideoTheme.palette.primary.main};
    font-size: 1.25rem;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .CMuiNotesTableSortSelect {
    height: 1.75rem;
    font-size: 0.875rem;
    background-color: #fff;
    margin: 0.25rem 0;

    .MuiSelect-select {
      padding: 0.5rem 2rem 0.5rem 0.75rem;
    }
  }
`;

const StyledHeadTableWrapper = styled(Box)`
  background-color: #ccdbe2;
  padding-right: 0.5rem;
`;

const StyledBodyTableWrapper = styled(Box)`
  background-color: #fff;
  max-height: 30vh;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 72, 120, 0.25);
    width: 0.5rem;
    border-radius: 0.25rem;
  }
`;

const StyledTable = styled(Table)`
  table-layout: fixed;

  .MuiTableCell-root:nth-child(1) {
  }
  .MuiTableCell-root:nth-child(2) {
    width: 3rem;
    max-width: 3rem;
  }

  .MuiTableCell-head {
    background-color: #ccdbe2;
    color: ${cuideoTheme.palette.primary.light};
    font-size: 0.75rem;
    line-height: normal;
    font-weight: 700;
    padding: 0.75rem 0.75rem;
  }

  .MuiTableCell-body {
    font-size: 0.875rem;
    color: ${cuideoTheme.palette.primary.main};
    line-height: 1.25;
    padding: 0.75rem 0.75rem;
  }

  .MuiTableBody-root {
    .MuiTableRow-root {
      background-color: #fff;
    }
  }

  .CMuiNotesTableEmptyMessage {
    text-align: center;
    font-size: 1rem;
    padding: 0.5rem 0;
    color: rgba(0, 0, 0, 0.87);
    }
  }

  .CMuiNotesTableNoteContent {
    margin: 0 0 0.75rem;
  }

  .CMuiNotesTableNoteDetails {
    font-size: 0.875rem;
    opacity: 0.75;
    line-height: 1;
  }
`;

const StyledIconButton = styled(IconButton)`
  border: 1px solid #c2d1da;
  margin: 0.125rem;
  color: ${cuideoTheme.palette.primary.light};
`;

let container: any;

export type TNote = {
  id: string;
  createdAt?: string;
  author?: string;
  note: string;
};

interface INotesTableProps {
  parentId: string;
  moduleName: string;
}

const NotesTable = (props: INotesTableProps) => {
  const { parentId, moduleName } = props;

  const currentUser = useSelector((state: any) => state.auth.authUser);
  const [notes, setNotes] = useState<TNote[]>([]);
  const [state, setState] = useState({
    open: false,
    mode: "add" as "add" | "edit",
  });
  const [deleteId, setDeleteId] = useState<string | null>(null);

  const notesQuery = useEmployeeNotesQuery(parentId, moduleName);
  const addNoteMutation = useAddNoteMutation(parentId, moduleName);
  const removeNoteMutation = useRemoveNoteMutation(parentId, moduleName);
  const [notesOrder, setNotesOrder] = useLocalStorage("notes_order", "ASC");

  const staffMentions = useSelector(
    (state: any) => state.usuariosState.staffMentions
  );
  const activeStaffMentions = staffMentions.filter(
    (sm: any) => !!sm.active && !sm.id.includes("undefined")
  );

  useEffect(() => {
    const parsedNotes = [] as TNote[];

    if (notesQuery.data) {
      notesQuery.data.forEach((item: any) => {
        if (item.Note_Content) {
          parsedNotes.push({
            id: item.id,
            createdAt: item.createdAt,
            author: item.ownerName,
            note: item.Note_Content,
          });
        }
      });
    }
    const sortedNotes = parsedNotes.sort((a, b) => {
      if (moment(a.createdAt).isBefore(moment(b.createdAt))) {
        return notesOrder === "ASC" ? -1 : 1;
      }
      return notesOrder === "ASC" ? 1 : -1;
    });
    setNotes(sortedNotes);
  }, [notesQuery.data, notesOrder]);

  const handleAddNote = () => {
    setState({
      open: true,
      mode: "add",
    });
  };

  const handleFormCancel = () => {
    setState({
      open: false,
      mode: "add",
    });
  };

  const handleFormSubmit = (note: string) => {
    if (!note) {
      return;
    }

    addNoteMutation.mutate({
      id: uuidv4(),
      Note_Content: note,
      parentId: parentId,
      zohoModule: moduleName,
    });

    setState({
      open: false,
      mode: "add",
    });
  };

  const handleDeleteConfirm = (noteId: string) => {
    if (noteId) {
      removeNoteMutation.mutate(noteId);
    }

    setDeleteId(null);
  };

  return (
    <Fragment>
      <div
        id="suggestionPortal"
        style={{
          height: "2px",
        }}
        ref={(el) => {
          container = el;
        }}
      ></div>
      <StyledTitle>
        <Grid container spacing={4}>
          <Grid item style={{ flexGrow: 1 }}>
            <Typography className="CMuiNotesTableTitle" variant="h4">
              <FormattedMessage
                id="NotesTable.ZohoTitle"
                defaultMessage="Notas Zoho"
              />
            </Typography>
          </Grid>
          <Grid item>
            <Select
              className="CMuiNotesTableSortSelect"
              value={notesOrder}
              variant="outlined"
              onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                setNotesOrder(e.target.value);
              }}
            >
              <MenuItem value="ASC">
                <FormattedMessage
                  id="NotesTable.SortAsc"
                  defaultMessage="Más antiguas antes"
                />
              </MenuItem>
              <MenuItem value="DESC">
                <FormattedMessage
                  id="NotesTable.SortDesc"
                  defaultMessage="Más recientes antes"
                />
              </MenuItem>
            </Select>
          </Grid>
          <Grid item>
            <Fab
              variant="extended"
              size="small"
              color="primary"
              onClick={handleAddNote}
            >
              <AddIcon />
            </Fab>
          </Grid>
        </Grid>
      </StyledTitle>
      <StyledHeadTableWrapper>
        <StyledTable>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage id="NotesTable.Note" defaultMessage="Nota" />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  id="NotesTable.Actions"
                  defaultMessage="Acciones"
                />
              </TableCell>
            </TableRow>
          </TableHead>
        </StyledTable>
      </StyledHeadTableWrapper>
      <StyledBodyTableWrapper>
        <StyledTable>
          <TableBody>
            {state.open && notesOrder !== "ASC" && (
              <TableRow>
                <TableCell>
                  <NotesForm
                    onFormSubmit={handleFormSubmit}
                    onFormCancel={handleFormCancel}
                    container={container}
                    mentionsData={activeStaffMentions}
                  />
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            )}
            {state.open || notes.length > 0 ? (
              <Fragment>
                {notes.map((note, index) => {
                  let userCanDeleteNote = false;
                  if (currentUser.userFullName === note.author) {
                    const noteMoment = moment(
                      note.createdAt,
                      "DD-MM-YYYY - HH:mm"
                    );
                    if (moment().subtract(1, "hour").isBefore(noteMoment)) {
                      userCanDeleteNote = true;
                    }
                  }
                  const canDeleteNote =
                    currentUser.userRoles.includes("ROLE_ADMIN") ||
                    userCanDeleteNote;
                  return (
                    <TableRow key={`conot-${index}`}>
                      <TableCell style={{ whiteSpace: "pre-wrap" }}>
                        <Box className="CMuiNotesTableNoteContent">
                          <Note
                            noteId={note.id}
                            value={note.note}
                            mentionsData={staffMentions}
                          />
                        </Box>
                        <Typography className="CMuiNotesTableNoteDetails">
                          <FormattedMessage
                            id="NotesTable.NoteDetails"
                            defaultMessage="Escrito por {author} el {date}"
                            values={{
                              author: note.author,
                              date: note.createdAt
                                ? moment(note.createdAt).format(
                                    "DD-MM-YYYY - HH:mm"
                                  )
                                : "",
                            }}
                          />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Eliminar">
                          <StyledIconButton
                            aria-label="eliminar"
                            color="primary"
                            disabled={!canDeleteNote}
                            onClick={() => {
                              setDeleteId(note.id);
                            }}
                          >
                            <DeleteIcon fontSize="small" />
                          </StyledIconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {state.open && notesOrder === "ASC" && (
                  <TableRow>
                    <TableCell>
                      <NotesForm
                        onFormSubmit={handleFormSubmit}
                        onFormCancel={handleFormCancel}
                        container={container}
                        mentionsData={activeStaffMentions}
                      />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )}
              </Fragment>
            ) : (
              <TableRow>
                <TableCell colSpan={4}>
                  {notesQuery.isLoading ? (
                    <CircularProgressBox />
                  ) : notesQuery.error ? (
                    <Typography className="CMuiNotesTableErrorMessage">
                      <FormattedMessage
                        id="NotesTable.Error"
                        defaultMessage="Ha habido un error"
                      />
                    </Typography>
                  ) : (
                    <Typography className="CMuiNotesTableEmptyMessage">
                      <FormattedMessage
                        id="NotesTable.Empty"
                        defaultMessage="No hay ninguna nota"
                      />
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </StyledTable>
      </StyledBodyTableWrapper>
      <ConfirmDialog
        open={deleteId !== null}
        title={
          <FormattedMessage
            id="NotesTable.DeleteTitle"
            defaultMessage="Eliminar nota"
          />
        }
        label={
          <FormattedMessage
            id="NotesTable.DeleteLabel"
            defaultMessage="¿Estás seguro que deseas eliminar la nota indicada?"
          />
        }
        onConfirm={() => {
          if (deleteId !== null) {
            handleDeleteConfirm(deleteId);
          }
        }}
        onCancel={() => {
          setDeleteId(null);
        }}
      />
    </Fragment>
  );
};

export default React.memo(
  (props: INotesTableProps) => {
    return <NotesTable {...props} />;
  },
  (prevProps, nextProps) => {
    if (prevProps.parentId !== nextProps.parentId) {
      return false;
    }
    return true;
  }
);
