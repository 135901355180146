import { apiEmpleo } from "../../util/ApiEmpleo";

interface IUpdateContractCvFollowUpRequest {
  Email_seguimiento_cv?: boolean;
  Llamada_segumiento_cv?: boolean;
  Email_intento_de_contacto_seguimiento_cv?: boolean;
  Seguimiento_cv_realizado?: boolean;
  nextCvFollowUpAt?: string;
}

const apiUpdateContractCvFollowUp = async (
  contractId: string,
  payload: IUpdateContractCvFollowUpRequest
) => {
  const res = await apiEmpleo.patch(
    `contracts/${contractId}/cv-follow-up`,
    payload,
    {
      headers: {
        "Content-Type": "application/merge-patch+json",
      },
    }
  );

  return res;
};

export { apiUpdateContractCvFollowUp };
