import moment from "moment";
import { MESSAGE_STATUS } from "../../../../../api/waMessage/waMessage.types";
import { TMessageResponse } from "../../../../../apiCoreV3/message/apiMessage.types";
import {
  BOT_CONTACT_ID,
  MESSAGE_DIRECTION,
  TMessage,
  TMessageContent,
} from "../types/message.type";
import uuidv4 from "uuid/v4";
import { HSM_TEMPLATE } from "../types/templateAction.types";
import { TMessageContextualOption } from "../types/messageContextualOption";

export const convertMessageResponsesToMessages = (
  messages: TMessageResponse[]
  // remoteContactId: string
): TMessage[] => {
  const newMessages: TMessage[] = [];
  for (let i = 0; i < messages.length; i++) {
    const newMessage: TMessage = {
      ...messages[i],
      content: JSON.parse(messages[i].content),
      author_full_name:
        messages[i].from === BOT_CONTACT_ID
          ? "🤖  Bot de Cuideo"
          : messages[i].author_full_name,
      direction:
        messages[i].status === MESSAGE_STATUS.RECEIVED ? "received" : "sent",
      // direction: messages[i].from === remoteContactId ? "received" : "sent",
      showAuthor: messageShouldShowAuthor(
        messages[i],
        i === 0 ? null : messages[i - 1]
      ),
      marginBottom: messageShouldHaveBottomMargin(
        messages[i],
        i === messages.length ? null : messages[i + 1]
      ),
    };

    newMessages.push(newMessage);
  }

  return newMessages;
};

export const messageShouldShowAuthor = (
  message: TMessageResponse | TMessage,
  prevMessage: TMessageResponse | TMessage | null
): boolean => {
  if (!prevMessage) {
    return true;
  }

  if (!message.author_full_name) {
    return false;
  }

  if (prevMessage.author_full_name === message.author_full_name) {
    const prevMessageCreatedMom = moment(prevMessage.created_at);
    const messageCreatedMom = moment(message.created_at);
    if (prevMessageCreatedMom.isValid() && messageCreatedMom.isValid()) {
      prevMessageCreatedMom.add(2, "hours");
      if (prevMessageCreatedMom.isAfter(messageCreatedMom)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  return true;
};

export const messageShouldHaveBottomMargin = (
  message: TMessageResponse | TMessage,
  nextMessage: TMessageResponse | TMessage | null
): boolean => {
  if (!nextMessage) {
    return true;
  }

  if (nextMessage.author_full_name === message.author_full_name) {
    const nextMessageCreatedMom = moment(nextMessage.created_at);
    const messageCreatedMom = moment(message.created_at);
    if (nextMessageCreatedMom.isValid() && messageCreatedMom.isValid()) {
      messageCreatedMom.add(2, "hours");
      if (messageCreatedMom.isAfter(nextMessageCreatedMom)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  return true;
};

export const hasReceivedMessagesInlLast24Hours = (
  messages: TMessage[],
  currentTime: Date
): boolean => {
  const receivedByBotLast24Hours = messages.filter((message) => {
    if (message.direction !== MESSAGE_DIRECTION.RECEIVED) {
      return false;
    }

    const last24hMom = moment(currentTime).subtract(24, "hours");
    const messageMom = moment(message.created_at);
    if (messageMom.isBefore(last24hMom)) {
      return false;
    }

    return true;
  });

  return receivedByBotLast24Hours.length !== 0;
};

export const createNewPendingMessage = (
  content: TMessageContent,
  authorUserId: string = "",
  authorFullName: string = ""
): TMessage => {
  return {
    id: uuidv4(),
    channel_id: "",
    content,
    conversation_id: "",
    direction: MESSAGE_DIRECTION.SENT,
    from: authorUserId,
    to: "",
    author_full_name: authorFullName,
    read: [],
    unread: [],
    status: "sent",
    showAuthor: false,
    marginBottom: true,
    created_at: moment().toISOString(),
    updated_at: moment().toISOString(),
  };
};

export const hasSentTemplatesInLast24Hours = (
  messages: TMessage[],
  currentTime: Date
): boolean => {
  const templateSentLast24Hours = messages.filter((message) => {
    if (message.direction !== MESSAGE_DIRECTION.SENT) {
      return false;
    }
    if (!message.content.hsm) {
      return false;
    }

    const last24hMom = moment(currentTime).subtract(24, "hours");
    const messageMom = moment(message.created_at);
    if (messageMom.isBefore(last24hMom)) {
      return false;
    }

    return true;
  });

  return templateSentLast24Hours.length !== 0;
};

export const createMessageContentWithAuthor = (
  content: string,
  author: string | null
) => {
  let messageStr = "";
  if (author !== null) {
    messageStr = "*" + author + ":*\n";
  }
  messageStr += content;

  return {
    text: messageStr,
  };
};

export const removeFirstLineWithAuthor = (messageString: string): string => {
  return messageString.replace(/^\*.*:\*\r?\n/, "");
};

export const conversationMessageText = (
  message: Pick<TMessage, "content">
): string => {
  const content = message.content;

  if (content.text) {
    return content.text;
  }

  if (content.hsm) {
    if (content.hsm.templateName === HSM_TEMPLATE.ES_JOB_INTEREST4) {
      const name: string = content.hsm.params[0].default;
      const city: string = content.hsm.params[1].default;
      const salary: string = content.hsm.params[2].default;
      const schedule: string = content.hsm.params[3].default;

      return `👋 Hola ${name},
      ¡Soy el robot 🤖 automático de Cuideo y ayudo al equipo de selección a encontrar candidatos para nuestras ofertas de empleo!
      Tenemos un servicio que coincide con tus preferencias:
      Servicio para trabajar en **${city}** como cuidador/a de mayores a domicilio. El salario bruto es de **${salary}** y el horario es el siguiente: **${schedule}**.
      **¿Te interesa?**
      Selecciona una de las opciones👇`;
    }

    if (content.hsm.templateName === HSM_TEMPLATE.ES_CONTINUE_CONVERSATION) {
      return "¡Hola! Nos gustaría hablar de tu proceso y continuar nuestra conversación anterior.";
    }

    if (content.hsm.templateName === HSM_TEMPLATE.ES_CLIENT_ANSWER) {
      return "¡Hola! soy de Cuideo y me gustaría hablar sobre tu candidatura en la oferta de empleo.";
    }
  }

  if (content.interactive) {
    if (content.interactive.type === "button") {
      return content.interactive.body?.text ?? "";
    }
    if (content.interactive.type === "button_reply") {
      return content.interactive.reply?.text ?? "";
    }
  }

  if (content.image) {
    return "imagen";
  }

  if (content.audio) {
    return "audio";
  }

  return "";
};

export const conversationMessageTextFromJsonString = (jsonString: string) => {
  try {
    const message = JSON.parse(jsonString);
    return conversationMessageText({ content: message });
  } catch (err) {
    // console.warn("Cannot parse message content");
    return jsonString;
  }
};

export const fileNameFromUrl = (url: string): string => {
  return url.split("/").pop() ?? url;
};

export const updateAuthorAndMarginInWaMessagesArray = (
  messages: TMessage[]
): TMessage[] => {
  const newMessages: TMessage[] = [];
  for (let i = 0; i < messages.length; i++) {
    const newMessage: TMessage = {
      ...messages[i],
      showAuthor: messageShouldShowAuthor(
        messages[i],
        i === 0 ? null : messages[i - 1]
      ),
      marginBottom: messageShouldHaveBottomMargin(
        messages[i],
        i === messages.length ? null : messages[i + 1]
      ),
    };

    newMessages.push(newMessage);
  }

  return newMessages;
};

export const messageOptionsForMessage = (
  message: TMessage
): TMessageContextualOption[] => {
  if (!!message.content.image) {
    return [
      {
        type: "download_image",
        url: message.content.image.url,
      },
    ];
  }

  if (!!message.content.video) {
    return [
      {
        type: "download_video",
        url: message.content.video.url,
      },
    ];
  }

  if (!!message.content.audio) {
    return [
      {
        type: "download_image",
        url: message.content.audio.url,
      },
    ];
  }

  if (!!message.content.file) {
    return [
      {
        type: "download_file",
        url: message.content.file.url,
      },
    ];
  }

  return [];
};

export const anyUnreadMessagesForUser = (
  messages: TMessage[],
  interestedId: string
): boolean => {
  for (let i = 0; i < messages.length; i++) {
    const stakeholderForUser = messages[i].unread.find(
      (stakeholder) => stakeholder.interested_id === interestedId
    );

    if (stakeholderForUser) {
      if (!stakeholderForUser.has_been_read) {
        return true;
      }
    }
  }

  return false;
};
