import { Typography } from "@material-ui/core";
import { connect, getIn } from "formik";
import * as React from "react";
import {} from "react-select";
import styled from "styled-components";
import cuideoTheme from "../../containers/themes/defaultTheme";

const StyledField = styled("div")<{
  fullWidth: boolean;
  margin: string;
  size: string;
}>`
  ${({ fullWidth }) => fullWidth && `width: 100%;`}
  padding-top: 0;
  padding-bottom: 0;

  ${({ margin }) => {
    if (margin === "normal") {
      return `
      margin: 1rem 0;
      `;
    }
    if (margin === "nomargin") {
      return ``;
    }
  }}

  &:last-child {
    border-bottom: none;
  }
`;

const StyledLabel = styled(Typography)<{ error: boolean; size: string }>`
  color: ${({ error }) =>
    error ? cuideoTheme.palette.error.main : cuideoTheme.palette.primary.light};
  white-space: wrap;

  ${({ size }) => {
    if (size === "medium") {
      return `
        font-size: 1rem;
        padding: 0.2rem 0 0.2rem 0;
      `;
    }
    if (size === "small") {
      return `
        font-size: 0.8rem;
        padding: 0.2rem 0 0.2rem 0;

        @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
          font-size: 0.8rem;
        }
      `;
    }
  }}
`;

const StyledError = styled(Typography)<{ size: string }>`
  color: ${cuideoTheme.palette.error.main};

  ${({ size }) => {
    if (size === "medium") {
      return `
        font-size: 0.8rem;
      `;
    }
    if (size === "small") {
      return `
        font-size: 0.8rem;

      `;
    }
  }}
`;

interface Props {
  name: string;
  variant?: "standard" | "outlined" | "filled" | "compact";
  size?: "medium" | "small";
  margin?: "nomargin" | "normal";
  label?: any;
  forceComponentUpdate?: boolean;
  isMulti?: boolean;
  fullWidth?: boolean;
}

interface State {}

class FormikFieldWrapper extends React.Component<any, State> {
  shouldComponentUpdate(nextProps: any, nextState: any) {
    if (nextProps.forceComponentUpdate) {
      return true;
    }
    const next = nextProps.formik;
    const cur = this.props.formik;
    const name = this.props.name;
    if (
      getIn(next.values, name) !== getIn(cur.values, name) ||
      getIn(next.errors, name) !== getIn(cur.errors, name)
    ) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    this.props.formik.registerField(this.props.name, {
      validate: this.props.validate,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.name !== prevProps.name) {
      this.props.formik.unregisterField(prevProps.name);
      this.props.formik.registerField(this.props.name, {
        validate: this.props.validate,
      });
    }

    if (this.props.validate !== prevProps.validate) {
      this.props.formik.registerField(this.props.name, {
        validate: this.props.validate,
      });
    }
  }

  componentWillUnmount() {
    this.props.formik.unregisterField(this.props.name);
  }

  render() {
    const { name, label, margin, size, children } = this.props;
    const formik = this.props.formik;

    const error = getIn(formik.errors, name);
    const touched = !!getIn(formik.touched, name);

    return (
      <StyledField
        fullWidth
        margin={margin || "normal"}
        size={size || "medium"}
      >
        {label && (
          <StyledLabel error={!!error && touched} size={size || "medium"}>
            {label}
          </StyledLabel>
        )}
        {children}
        {!!error && touched && (
          <StyledError size={size || "medium"}>{error}</StyledError>
        )}
      </StyledField>
    );
  }
}

export default connect<Props, State>(FormikFieldWrapper as any);
