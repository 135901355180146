import { Typography } from "@material-ui/core";
import styled from "styled-components";
import cuideoTheme from "../../containers/themes/defaultTheme";

const StyledHelpText = styled(Typography)`
  font-size: 1rem;
  color: ${cuideoTheme.palette.grey[600]};
  margin: 0.5rem 0 1rem;
`;

export default StyledHelpText;
