import { Box } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import backImg from "../../assets/images/cuideo_bg_hero3.jpg";
import logo from "../../assets/images/logo.svg";
import cuideoTheme from "../../containers/themes/defaultTheme";

const StyledBackground = styled(Box)`
  background-color: ${cuideoTheme.palette.primary.main};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const StyledBackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledLogo = styled.img`
  position: absolute;
  bottom: 5%;
  right: 5%;
  width: 16rem;
`;

const AffinitySignInBackground = (props: {}) => {
  return (
    <StyledBackground>
      <StyledBackgroundImage src={backImg} />
      <StyledLogo src={logo} />
    </StyledBackground>
  );
};

export default AffinitySignInBackground;
