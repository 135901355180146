export const ROUTE_CONTRACTS = "/contratos";
export const ROUTE_CONTRACT = (contractId: string) => `/contrato/${contractId}`;
export const ROUTE_ACTIVE_CONTRACT = (contractId: string) =>
  `/active-contract/${contractId}`;

// export const ROUTE_SERVICES = "/services";

export const ROUTE_CANDIDATES = "/candidatos";

export const ROUTE_COMMUNICATIONS = "/communications";
export const ROUTE_COMMUNICATIONS_ME = "/communications?createdById=me";

export const ROUTE_SETTINGS_PAGE = "/settings/account";

export const ROUTE_AFFINITY_SIGN_IN = "/affinitySignIn";

// Jobsite routes
export const JOB_MY_PROFILE = "/empleo/miPerfil";
export const JOB_SIGN_IN = "/empleoSignin";
export const JOB_OFFERS = "/empleo/ofertas";
