import _ from "lodash";
import { store } from "../store";
import { replace } from "connected-react-router";

export function changeURLWithoutLoading(newUrlPath: string) {
  window.history.pushState("", document.title, newUrlPath);
}

export function createURL(pathname: string, params: any, hash: string | null) {
  let url = pathname;
  let str = "";
  if (!_.isEmpty(params)) {
    for (let key in params) {
      // eslint-disable-next-line
      if (str != "") {
        str += "&";
      }
      str += key + "=" + encodeURIComponent(params[key]);
    }
    url += "?" + str;
  }
  if (hash) {
    url += "#" + hash;
  }

  return url;
}

export function urlParamsToObject(search: string) {
  const cleanSearch = search.replace(/^\?/, "");
  const obj = {} as any;
  cleanSearch.replace(
    /([^=&]+)=([^&]*)/g,
    function (m: any, key: any, value: any) {
      let decodedValue: string | string[] = decodeURIComponent(value);
      if (decodedValue.indexOf(",") !== -1) {
        decodedValue = decodedValue.split(",");
      }
      obj[decodeURIComponent(key)] = decodedValue;
      return "";
    }
  );
  return obj;
}

// This version supports "null" -> null value
export function urlParamsToObjectV2(search: string) {
  const cleanSearch = search.replace(/^\?/, "");
  const obj = {} as any;
  cleanSearch.replace(
    /([^=&]+)=([^&]*)/g,
    function (m: any, key: any, value: any) {
      let decodedValue: string | string[] = decodeURIComponent(value);
      if (decodedValue.indexOf(",") !== -1) {
        decodedValue = decodedValue.split(",");
      }
      if (decodedValue === "null") {
        obj[decodeURIComponent(key)] = null;
      } else {
        obj[decodeURIComponent(key)] = decodedValue;
      }
      return "";
    }
  );
  return obj;
}

export const updateURLWithNewSearchParams = (newParams: any) => {
  const params = urlParamsToObject(window.location.search);
  const tempParams = { ...params, ...newParams };
  for (let propName in tempParams) {
    if (tempParams[propName] === "") {
      delete tempParams[propName];
    }
  }

  const newUrl = createURL(window.location.pathname, tempParams, null);
  const path = window.location.pathname + window.location.search;

  if (newUrl !== path) {
    store.dispatch(replace(newUrl));
  }
};

export const updateURLParams = (newParams: any) => {
  const tempParams = { ...newParams };
  for (let propName in tempParams) {
    if (tempParams[propName] === "") {
      delete tempParams[propName];
    }
  }

  const newUrl = createURL(window.location.pathname, tempParams, null);
  const path = window.location.pathname + window.location.search;

  if (newUrl !== path) {
    window.history.replaceState("", window.document.title, newUrl);
  }
};

export function getPathnameFromPath(pathname: string) {}

export const objectToURLParams = (object: any) => {
  const params = new URLSearchParams();
  for (const key in object) {
    if (Array.isArray(object[key])) {
      for (const item in object[key]) {
        params.append(`${key}[]`, object[key][item]);
      }
    } else if (typeof object[key] === "object") {
      Object.entries(object[key]).forEach(([objKey, objValue]) => {
        params.append(
          `${key}[${objKey}]`,
          typeof objValue === "string" ? objValue : ""
        );
      });
    } else {
      params.append(key, object[key]);
    }
  }
  return params.toString();
};
