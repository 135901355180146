import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { MESSAGE_MODULE } from "../../../../api/message/message.types";
import {
  COLOR_BG_SOFT_GRAY,
  cuideoTheme,
} from "../../../../containers/themes/defaultTheme";
import ConversationMessage from "./ConversationMessage";
import useConversationState from "./hooks/useConversationState";
import InputMessage from "./InputMessage";

const StyledAccordion = styled(Accordion)`
  .MuiAccordionSummary-root {
    background-color: ${cuideoTheme.palette.primary.main};
    color: #fff;
    min-height: unset;

    .MuiAccordionSummary-content {
      margin: 0;
      padding: 0 0 0 1rem;
    }

    .MuiTypography-body1 {
      font-weight: 700;
      font-size: 0.9375rem;
      margin: 0;
    }

    .MuiIconButton-root {
      color: #fff;
    }

    .MuiIconButton-edgeEnd {
      margin-right: -0.375rem;
    }
  }

  &.Mui-expanded {
    margin: 0;
  }

  .MuiAccordionDetails-root {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const StyledMessagesContainer = styled.div`
  background-color: ${COLOR_BG_SOFT_GRAY};
  padding: 0 1rem;

  max-height: 16rem;
  overflow-y: scroll;
  box-sizing: border-box;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 72, 120, 0.25);
    width: 0.5rem;
    border-radius: 0.25rem;
  }
`;

const StyledTitle = styled(Typography)``;

const StyledMessageWrapper = styled.div``;

const StyledBadgeWrapper = styled.div`
  position: relative;
`;

const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    color: #fff;
    background-color: #cc1016;
    font-weight: 600;
    left: 0.125rem;
  }
`;

interface IConversationProps {
  contractId: string;
}

const Conversation: React.FC<IConversationProps> = (props) => {
  const { contractId } = props;
  const {
    bottomRef,
    state: { messages, expanded, numUnreadMessages },
    actions,
  } = useConversationState(contractId);

  const staffMentions = useSelector(
    (state: any) => state.usuariosState.staffMentions
  );
  const activeStaffMentions = staffMentions.filter(
    (sm: any) => !!sm.active && !sm.id.includes("undefined")
  );

  const handleSendMessage = (content: string) => {
    actions.addMessage({
      content,
      module: MESSAGE_MODULE.CONTRACT,
      parentId: contractId,
    });
  };

  return (
    <StyledAccordion expanded={expanded} onChange={actions.toggleExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <StyledTitle>
          <FormattedMessage
            id="Conversation.Notas Contrato"
            defaultMessage="Notas Contrato"
          />
        </StyledTitle>
        <StyledBadgeWrapper>
          <StyledBadge
            badgeContent={!!expanded ? 0 : numUnreadMessages}
            max={9}
          />
        </StyledBadgeWrapper>
      </AccordionSummary>
      <AccordionDetails>
        <StyledMessagesContainer className="CMuiConversationMessagesContainer">
          {messages &&
            !!(messages.length > 0) &&
            messages.map((message) => (
              <StyledMessageWrapper key={message.id}>
                <ConversationMessage message={message} />
              </StyledMessageWrapper>
            ))}
          <div ref={bottomRef} />
        </StyledMessagesContainer>
        <InputMessage
          onSendMessage={handleSendMessage}
          mentionsData={activeStaffMentions}
        />
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default Conversation;
