import { useEffect } from "react";

function usePageTitle(title: string) {
  useEffect(() => {
    const suffix =
      process.env.REACT_APP_CLIENT_ID === "AFFINITY"
        ? " - Affinity | Cuideo"
        : " - Empleo | Cuideo";
    document.title = title + suffix;
  }, [title]);
}

export default usePageTitle;
