import { useMutation } from "react-query";
import {
  apiUpdateCoordinationGroup,
  TApiUpdateUserGroupsRequest,
} from "../../../../../api/user/apiUpdateCoordinationGroup";
import { MutationConfig, queryClient } from "../../../../../lib/react-query";
import { userKeys } from "../util/user.query-keys";

type TUpdateUserGroupsPayload = TApiUpdateUserGroupsRequest & {
  userId: string;
};

export const updateUserGroups = ({
  userId,
  ...payload
}: TUpdateUserGroupsPayload) => {
  return apiUpdateCoordinationGroup(userId, payload);
};

type TUseUpdateUserGroups = {
  config?: MutationConfig<typeof updateUserGroups>;
};

export const useUpdateUserGroups = ({ config }: TUseUpdateUserGroups = {}) => {
  return useMutation({
    onMutate: async (payload: TUpdateUserGroupsPayload) => {
      await queryClient.cancelQueries(userKeys.all);
    },
    onSettled: () => {
      queryClient.invalidateQueries(userKeys.all);
    },
    ...config,
    mutationFn: updateUserGroups,
  });
};
