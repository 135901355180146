import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { TUserGroupAssignations } from "../../../../../recruitersManager/types/UserGroupAssignations.types";
import { groupName } from "../../types/StaffAssignationsGroup";
import CoordinatorAssignationsList from "./CoordinatorAssignationsList";

export const WITHOUT_VALUATION_COLOR = "#004b7b";
export const PENDING_SIGNUP_COLOR = "#004b7b";
export const ADVANCED_VALUATION_COLOR = "#4caf50";

const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  border-radius: 0;

  .MuiAccordionSummary-root {
    padding: 0;
  }

  .MuiAccordionSummary-content {
    margin: 0;
    display: block;
    padding: 0;
    border-bottom: 0.0625rem solid #eee;
  }
  .MuiAccordionDetails-root {
    display: block;
    padding: 0;
  }
`;

const StyledBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 4rem;
  user-select: none;
  width: 100%;
  background-color: rgb(248, 248, 248);

  &.CMuiCoordinatorAssignationsItem-inactive {
    opacity: 0.5;
  }

  &:hover {
    cursor: pointer;
    background-color: rgb(241, 241, 241);
  }
`;

const StyledLeft = styled.div`
  padding: 0.25rem 0 0.25rem 0.5rem;
`;

const StyledRight = styled.div`
  padding: 0.25rem 0.5rem 0.25rem 0;
  flex: 1 1 auto;
`;

const StyledRightTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledRightTopLeft = styled.div`
  flex: 1 1 auto;
`;

const StyledRightTopRight = styled.div`
  min-width: 1.5rem;
  width: 1.125rem;
  text-align: right;
`;

const StyledGroupName = styled(Typography)`
  line-height: 1.25;
  font-size: 1.125rem;
  font-weight: 700;
  color: #222;
`;

const StyledBarContainer = styled.div`
  margin: 0 0.5rem 0 0.25rem;
  height: 1.125rem;
  width: 100%;
  position: relative;
`;

const StyledBarsWrapper = styled.div`
  height: 1.125rem;
  display: flex;
  flex-direction: row;
`;

const StyledServiceBarWrapper = styled.div`
  height: 1rem;
  width: 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

const StyledContractBarsWrapper = styled.div`
  width: 50%;
`;

const StyledServicesNumber = styled(Typography)`
  font-weight: 700;
  font-size: 0.875rem;
`;

const StyledContractsNumber = styled(Typography)`
  font-weight: 700;
  font-size: 0.75rem;
  width: 1rem;
  text-align: right;
`;

const StyledBar = styled.div`
  border-radius: 0 0.1875rem 0.1875rem 0;
  height: 0.5rem;
  top: 0.3125rem;
  position: absolute;
`;

const StyledContractBarWrapper = styled.div`
  height: 0.625rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

const StyledThinnerBar = styled.div`
  border-radius: 0 0.1875rem 0.1875rem 0;
  height: 0.375rem;
  top: 0.375rem;
  position: absolute;
`;

const StyledTotalNumber = styled(Typography)`
  font-weight: 700;
  font-size: 1.125rem;
`;

const StyledRightBottom = styled.div``;

const StyledOnlineCoordinators = styled(Typography)`
  font-size: 0.75rem;
  line-height: 1;
  margin: 0.125rem 0;
  color: #666;
`;

interface IGroupAssignationsItemProps {
  group: TUserGroupAssignations;
  onSelectOwner: (ownerId: string | null) => void;
  selectedOwnerId: string | null;
  maxServices: number;
  maxContracts: number;
}

const GroupAssignationsItem = (props: IGroupAssignationsItemProps) => {
  const { group, onSelectOwner, selectedOwnerId, maxServices, maxContracts } =
    props;

  const withoutValuationBarWidth =
    (group.serviceStats.withoutValuation.total / (maxServices + 1)) * 100;

  const awaitingContractsBarWidth =
    (group.contractStats.awaiting.total / (maxContracts + 1)) * 100;

  const advancedContractsBarWidth =
    ((group.contractStats.cvClient.total +
      group.contractStats.clientFaceToFace.total) /
      (maxContracts + 1)) *
    100;

  const totalNumber =
    group.serviceStats.total +
    group.contractStats.awaiting.total +
    group.contractStats.clientFaceToFace.total +
    group.contractStats.cvClient.total;

  return (
    <StyledAccordion>
      <AccordionSummary>
        <StyledBox>
          <StyledLeft></StyledLeft>
          <StyledRight>
            <StyledRightTop>
              <StyledRightTopLeft>
                <StyledGroupName>{groupName(group.id)}</StyledGroupName>
                <StyledBarsWrapper>
                  <StyledServiceBarWrapper>
                    <StyledServicesNumber>
                      {group.serviceStats.total}
                    </StyledServicesNumber>
                    <StyledBarContainer>
                      <StyledBar
                        style={{
                          backgroundColor: WITHOUT_VALUATION_COLOR,
                          width: `calc(${withoutValuationBarWidth}% + 0.1875rem)`,
                        }}
                      />
                    </StyledBarContainer>
                  </StyledServiceBarWrapper>
                  <StyledContractBarsWrapper>
                    <StyledContractBarWrapper>
                      <StyledContractsNumber>
                        {group.contractStats.awaiting.total}
                      </StyledContractsNumber>
                      <StyledBarContainer>
                        <StyledThinnerBar
                          style={{
                            backgroundColor: PENDING_SIGNUP_COLOR,
                            width: `calc(${awaitingContractsBarWidth}% + 0.1875rem)`,
                            zIndex: 2,
                          }}
                        />
                      </StyledBarContainer>
                    </StyledContractBarWrapper>
                    <StyledContractBarWrapper>
                      <StyledContractsNumber>
                        {group.contractStats.clientFaceToFace.total +
                          group.contractStats.cvClient.total}
                      </StyledContractsNumber>
                      <StyledBarContainer>
                        <StyledThinnerBar
                          style={{
                            backgroundColor: ADVANCED_VALUATION_COLOR,
                            width: `calc(${advancedContractsBarWidth}% + 0.1875rem)`,
                            zIndex: 1,
                          }}
                        />
                      </StyledBarContainer>
                    </StyledContractBarWrapper>
                  </StyledContractBarsWrapper>
                </StyledBarsWrapper>
              </StyledRightTopLeft>
              <StyledRightTopRight>
                <StyledTotalNumber>{totalNumber}</StyledTotalNumber>
              </StyledRightTopRight>
            </StyledRightTop>
            <StyledRightBottom></StyledRightBottom>
            <StyledOnlineCoordinators>
              {group.users.length === 0 ? (
                <FormattedMessage
                  id="GroupAssignationsItem.Grupo sin coordinadores"
                  defaultMessage="Grupo sin coordinadores"
                />
              ) : group.users.length === 1 ? (
                <FormattedMessage
                  id="GroupAssignationsItem.1 coordinador/a"
                  defaultMessage="1 coordinador/a"
                />
              ) : (
                <FormattedMessage
                  id="GroupAssignationsItem.{numCoordinators} coordinadores/as"
                  defaultMessage="{numCoordinators} coordinadores/as"
                  values={{
                    numCoordinators: group.users.length,
                  }}
                />
              )}
            </StyledOnlineCoordinators>
          </StyledRight>
        </StyledBox>
      </AccordionSummary>
      <AccordionDetails>
        <CoordinatorAssignationsList
          coordinators={group.users}
          maxContracts={group.maxContractsAssigned}
          maxServices={group.maxServicesAssigned}
          onSelectOwner={onSelectOwner}
          selectedOwnerId={selectedOwnerId}
        />
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default GroupAssignationsItem;
