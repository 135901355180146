import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { TCommunicationIntentEmployee } from "../CreateCommunicationIntentsButton.types";
import EmployeeCommunicationIntentResult from "./EmployeeCommunicationIntentResult";
import EmployeeIdentification from "./EmployeeIdentification";
import EmployeeValidationIssues from "./EmployeeValidationIssues";
import classNames from "classnames";
import { COLOR_ERROR_ALERT_BG } from "../../../../../containers/themes/defaultTheme";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const StyledAccordion = styled(Accordion)`
  .MuiAccordionSummary-root {
    min-height: 1.5rem;
    background-color: #fafafa;
  }

  .MuiAccordionSummary-content {
    margin: 0.125rem 0;
    justify-content: center;
    align-items: center;
    color: #222;
    opacity: 0.5;
  }

  &:before {
    display: none;
  }
`;

const StyledMoreInfoText = styled(Typography)`
  color: inherit;
  font-size: 0.75rem;
`;

const StyledTableContainer = styled(TableContainer)`
  margin: 0;
  max-height: 18rem;
`;

const StyledTable = styled(Table)`
  .MuiTableCell-head {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
    opacity: 0.75;
  }

  .MuiTableCell-body {
    padding: 0.25rem 0.5rem;
    font-size: 0.9375rem;
  }

  .MuiTableRow-root.CMui-error {
    background-color: ${COLOR_ERROR_ALERT_BG};
  }
`;

interface IEmployeesAvailabilityTableProps {
  employees: TCommunicationIntentEmployee[];
}

const EmployeesAvailabilityTable: React.FC<
  IEmployeesAvailabilityTableProps
> = ({ employees }) => {
  const [state, setState] = useState({
    detailsExpanded: false,
  });

  const handleAccordionToggle = () => {
    setState((prevState) => ({
      detailsExpanded: !prevState.detailsExpanded,
    }));
  };

  return (
    <StyledAccordion
      elevation={0}
      expanded={state.detailsExpanded}
      onChange={handleAccordionToggle}
    >
      <AccordionSummary>
        <StyledMoreInfoText>
          {state.detailsExpanded ? (
            <FormattedMessage
              id="EmployeesAvailabilityTable.Ocultar detalles"
              defaultMessage="Ocultar detalles"
            />
          ) : (
            <FormattedMessage
              id="EmployeesAvailabilityTable.Mostrar detalles"
              defaultMessage="Mostrar detalles"
            />
          )}
        </StyledMoreInfoText>
        <ExpandMoreIcon
          style={{
            transform: state.detailsExpanded ? "rotate(180deg)" : undefined,
          }}
        />
      </AccordionSummary>
      <AccordionDetails>
        <StyledTableContainer>
          <StyledTable stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage
                    id="EmployeesAvailabilityTable.Candidato"
                    defaultMessage="Candidato"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="EmployeesAvailabilityTable.Disponible"
                    defaultMessage="Disponible"
                  />
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employees.map((employee) => (
                <TableRow
                  key={employee.id}
                  className={classNames({
                    "CMui-error": employee.validationErrors.length !== 0,
                  })}
                >
                  <TableCell>
                    <EmployeeIdentification employee={employee} />
                  </TableCell>
                  <TableCell>
                    <EmployeeValidationIssues
                      employeeId={employee.id}
                      validationErrors={employee.validationErrors}
                    />
                  </TableCell>
                  <TableCell>
                    <EmployeeCommunicationIntentResult
                      result={employee.result}
                      errorMessage={employee.errorMessage}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default EmployeesAvailabilityTable;
