import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import {
  COORDINATION_GROUP,
  TCoordinationGroup,
  TStaffUserResponse,
} from "../../../../../../api/user/apiListStaffUsers";
import PersonalCareImg from "../../../../../../assets/images/personal-care.svg";
import { groupName } from "../../../promotersManager/routes/UnratedServicesPage/types/StaffAssignationsGroup";
import SelectStyledWrapper from "../../../promotersManager/routes/UnratedServicesPage/components/SelectStyledWrapper";
import { TUserGroupAssignations } from "../../../recruitersManager/types/UserGroupAssignations.types";

const StyledDialog = styled(Dialog)``;

const StyledText = styled(Typography)`
  font-size: 0.9375rem;
  line-height: 1.35;
  margin-bottom: 0.5rem;
`;

const StyledContentWithImage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1rem 0 1rem;
`;

const StyledImage = styled.img`
  height: 5rem;
  width: auto;
`;

const StyledRight = styled.div``;

const StyledConfirmButton = styled(Button)`
  width: 7.25rem;
  height: 2.5rem;

  .MuiCircularProgress-root {
    color: #999;
  }
`;

interface IUserGroupSelector {
  groups: TUserGroupAssignations[];
  currentStaffUser: TStaffUserResponse;
  onGroupChange: (group: TCoordinationGroup, ownerId: string) => Promise<void>;
  showSquadPrefix?: boolean;
}

const UserGroupSelector = (props: IUserGroupSelector) => {
  const {
    groups,
    currentStaffUser,
    onGroupChange,
    showSquadPrefix = true,
  } = props;

  const [state, setState] = useState({
    dialogOpen: false,
    newGroup: null as TCoordinationGroup | null,
    newGroupName: null as string | null,
    inProcess: false,
  });
  const { formatMessage } = useIntl();

  const currentGroup =
    currentStaffUser.coordinationGroups?.length &&
    currentStaffUser.coordinationGroups[0] !== COORDINATION_GROUP.OTHER
      ? currentStaffUser.coordinationGroups[0]
      : null;
  const currentGroupOption = currentGroup
    ? {
        label: showSquadPrefix
          ? `Squad: ${groupName(currentGroup)}`
          : groupName(currentGroup),
        value: currentGroup,
      }
    : null;

  const options = groups
    .filter((group) => group.id !== COORDINATION_GROUP.OTHER)
    .map((group) => ({
      label: groupName(group.id),
      value: group.id,
    }));
  const handleGroupChange = (option: any) => {
    let group: TCoordinationGroup = option.value;
    if (option.value === currentGroup) {
      return;
    }

    setState((prevState) => ({
      ...prevState,
      dialogOpen: true,
      newGroup: group,
      newGroupName: groupName(group),
    }));
  };

  const handleConfirmGroupChange = async () => {
    if (null === state.newGroup) {
      return;
    }
    setState((prevState) => ({ ...prevState, inProcess: true }));
    let newGroup = state.newGroup;
    await onGroupChange(newGroup, currentStaffUser.id);
    setState((prevState) => ({
      ...prevState,
      inProcess: false,
      dialogOpen: false,
    }));
  };

  const handleCancelDialog = () => {
    setState((prevState) => ({
      ...prevState,
      dialogOpen: false,
    }));
  };

  return (
    <>
      <SelectStyledWrapper
        options={options}
        value={currentGroupOption}
        onChange={handleGroupChange}
        classNamePrefix="CMuiUserGroupSelect"
        placeholder={formatMessage({
          id: "UserGroupSelector.Asignar a grupo...",
          defaultMessage: "Asignar a grupo...",
        })}
      />
      <StyledDialog open={state.dialogOpen}>
        <DialogTitle>
          <FormattedMessage
            id="UserGroupSelector.Confirmar cambio de grupo"
            defaultMessage="Confirmar cambio de grupo"
          />
        </DialogTitle>
        <StyledContentWithImage>
          <StyledImage src={PersonalCareImg} />
          <StyledRight>
            <DialogContent>
              <StyledText>
                {currentGroup ? (
                  <FormattedMessage
                    id="UserGroupSelector.Vas a cambiar el grupo de {coordinatorName} de {currentGroup} a {newGroup}"
                    defaultMessage="Vas a cambiar el grupo de {coordinatorName} de {currentGroup} a {newGroup}"
                    values={{
                      coordinatorName: <b>{currentStaffUser.fullname}</b>,
                      currentGroup: <b>{groupName(currentGroup)}</b>,
                      newGroup: <b>{state.newGroupName}</b>,
                    }}
                  />
                ) : (
                  <FormattedMessage
                    id="UserGroupSelector.Vas a asignar a {coordinatorName} al grupo {newGroup}"
                    defaultMessage="Vas a asignar a {coordinatorName} al grupo {newGroup}"
                    values={{
                      coordinatorName: <b>{currentStaffUser.fullname}</b>,
                      newGroup: <b>{state.newGroupName}</b>,
                    }}
                  />
                )}
              </StyledText>
              <StyledText>
                <FormattedMessage
                  id="UserGroupSelector.¿Seguro que quieres continuar?"
                  defaultMessage="¿Seguro que quieres continuar?"
                />
              </StyledText>
            </DialogContent>
          </StyledRight>
        </StyledContentWithImage>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleCancelDialog}
            disabled={state.inProcess}
          >
            <FormattedMessage
              id="UserGroupSelector.Cancelar"
              defaultMessage="Cancelar"
            />
          </Button>
          <StyledConfirmButton
            variant="contained"
            color="primary"
            onClick={handleConfirmGroupChange}
            disabled={state.inProcess}
          >
            {state.inProcess ? (
              <CircularProgress size={19} thickness={5} />
            ) : (
              <FormattedMessage
                id="UserGroupSelector.Confirmar"
                defaultMessage="Confirmar"
              />
            )}
          </StyledConfirmButton>
        </DialogActions>
      </StyledDialog>
    </>
  );
};

export default UserGroupSelector;
