import {
  REPORT_CLIENT_EVENT,
  UPDATE_CLIENT_EVENT_STATE,
} from "../constants/ActionTypes";

const INIT_STATE = {
  isSending: false,
  pendingEvents: [] as any,
  lastReported: 0,
};

// eslint-disable-next-line
export default (state = INIT_STATE, action: { type: string; payload: any }) => {
  switch (action.type) {
    case REPORT_CLIENT_EVENT:
      return state;
    case UPDATE_CLIENT_EVENT_STATE:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};
