import { useSelector } from "react-redux";
import { CONVERSATION_STATUS } from "../../../../../../../apiCoreV3/conversation/apiConversation.types";
import { TSendMessageRequestContext } from "../../../../../../../apiCoreV3/message/apiSendMessage";
import { TAuthenticatedUser } from "../../../../../../../reducers/Auth";
import { sleep } from "../../../../../../../util/sleep";
import { BOT_CHANNEL_ID } from "../../../../communications/constants/bot.constants";
import { useCreateConversationMessage } from "../../../api/createConversationMessage";
import { useConversation } from "../../../api/getConversation";
import { useConversationMessages } from "../../../api/getConversationMessages";
import { TMessage } from "../../../types/message.type";
import {
  hasReceivedMessagesInlLast24Hours,
  hasSentTemplatesInLast24Hours,
} from "../../../util/message.util";

const REFETCH_INTERVAL_IN_MS = 15000;
const DELAY_AFTER_CREATE_CONVERSATION_IN_MS = 5000;

export type TConversationState = {
  messages: TMessage[];
  totalMessages: number;
  canLoadMoreMessages: boolean;
  hasReceivedMessagesInLast24Hours: boolean;
  canSendMessage: boolean;
  canSendTemplate: boolean;
};

export default function useConversationState(
  conversationId: string | null,
  // conversation: TConversation | null,
  remoteContactId?: string,
  currentTime: Date | undefined = new Date(),
  context: TSendMessageRequestContext | undefined = undefined
) {
  // const [state, setState] = useState<TConversationState>({
  //   messages: [],
  //   totalMessages: 0,
  //   canLoadMoreMessages: false,
  //   hasReceivedMessagesInLast24Hours: false,
  //   canSendMessage: false,
  //   canSendTemplate: false,
  // });
  const authUser = useSelector(
    (state: any) => state.auth.authUser as TAuthenticatedUser
  );
  const conversationQuery = useConversation({
    conversationId: conversationId as string,
    config: {
      enabled: !!conversationId,
    },
  });
  const messagesQuery = useConversationMessages({
    conversationId,
    // remoteContactId,
    // remoteContactId: conversation?.contact_id,
    params: {
      pageSize: 50,
    },
    config: {
      enabled: !!conversationId,
      refetchInterval: REFETCH_INTERVAL_IN_MS,
    },
  });
  const createMessageMutation = useCreateConversationMessage();

  const sendMessage = (encodedContent: string) => {
    if (!conversationQuery.data) {
      console.error("No adequate conversation");
      return;
    }

    createMessageMutation.mutate({
      conversation_id: conversationId ?? null,
      author_full_name: authUser.userFullName,
      channel_id: BOT_CHANNEL_ID,
      content: encodedContent,
      from: authUser.userId,
      // to: conversationRemoteContactId(conversation),
      to: conversationQuery.data.contact_id,
      context,
    });
  };

  const asyncSendMessage = async (encodedContent: string) => {
    if (!conversationQuery.data) {
      console.error("No adequate conversation");
      return;
    }

    await createMessageMutation.mutateAsync({
      conversation_id: conversationId ?? null,
      author_full_name: authUser.userFullName,
      channel_id: BOT_CHANNEL_ID,
      content: encodedContent,
      from: authUser.userId,
      to: conversationQuery.data.contact_id,
      context,
    });
  };

  const asyncSendStartConversationMessage = async (encodedContent: string) => {
    if (!remoteContactId) {
      console.error("No adequate remote_contact_id");
      return;
    }

    await createMessageMutation.mutateAsync({
      conversation_id: conversationId ?? null,
      author_full_name: authUser.userFullName,
      channel_id: BOT_CHANNEL_ID,
      content: encodedContent,
      from: authUser.userId,
      // to: conversationQuery.data.contact_id,
      to: remoteContactId,
      context,
    });

    // Include a Timeout so we are giving the Backend some time to create
    // the conversation. There is a small time-lapse when the server state
    // should not be correct.
    await sleep(DELAY_AFTER_CREATE_CONVERSATION_IN_MS);
  };

  const hasReceivedMessagesInLast24Hours = hasReceivedMessagesInlLast24Hours(
    messagesQuery.data?.messages ?? [],
    currentTime
  );

  const isConversationBlocked =
    conversationQuery.data?.status === CONVERSATION_STATUS.EXPIRED;

  const canSendMessage =
    hasReceivedMessagesInLast24Hours && !isConversationBlocked;

  const hasSentTemplatesInLast24h = hasSentTemplatesInLast24Hours(
    messagesQuery.data?.messages ?? [],
    currentTime
  );

  const canSendTemplate = !canSendMessage && !hasSentTemplatesInLast24h;

  return {
    state: {
      isLoading: messagesQuery.isLoading,
      conversation: conversationQuery.data ?? undefined,
      messages: messagesQuery.data?.messages ?? [],
      hasReceivedMessagesInLast24Hours,
      hasSentTemplatesInLast24Hours: hasSentTemplatesInLast24h,
      canSendMessage,
      canSendTemplate,
      isConversationBlocked,
    },
    actions: {
      sendMessage,
      asyncSendMessage,
      asyncSendStartConversationMessage,
    },
  };
}
