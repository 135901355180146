import { useEffect } from "react";
import { changeFooter } from "../actions/Layout";
import { store } from "../store";
import { FooterInterface } from "../reducers/Layout";

function useFooter({ type }: FooterInterface) {
  const storeState = store.getState();
  const footer = storeState.layout.footer;

  useEffect(() => {
    if (footer.type !== type) {
      store.dispatch(
        changeFooter({
          type,
        })
      );
    }
    // eslint-disable-next-line
  }, []);

  return null;
}

export default useFooter;
