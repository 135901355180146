import { CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useRef } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import IntersectionPlaceholder from "../../../../../../components/IntersectionPlaceholder";
import { useAuthUser } from "../../../../../../reducers/hooks/useAuthUser";
import { useMarkConversationAsRead } from "../../api/markConversationAsRead";
import { TConversation } from "../../types/conversation.types";
import { TMessage } from "../../types/message.type";
import { THsm } from "../../types/templateAction.types";
import { anyUnreadMessagesForUser } from "../../util/message.util";
import ConversationMessage from "./ConversationMessage";
import DummyConversation from "./DummyConversation";
import useScrollToBottom from "./hooks/useScrollToBottom";
import InputBox from "./InputBox";
import TemplateActionButtons from "./TemplateActionButtons";

export const StyledWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledLoadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const StyledConversationContainerWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  height: calc(100% - 4rem);
  flex: 1 1 auto;
  overflow: hidden;
`;

export const StyledConversationContainer = styled.div`
  height: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: border-box;
  background-color: #e5ddd5;
  padding: 0 1rem;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 72, 120, 0.25);
    width: 0.5rem;
    border-radius: 0.25rem;
  }
`;

const StyledInputContainer = styled.div`
  background-color: rgb(245, 245, 245);
  min-height: 3.75rem;
`;

const StyledMessageWrapper = styled.div``;

// const StyledFlyingNotification = styled.div`
//   margin: 0 auto;
//   width: fit-content;
//   box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
// `;

// const StyledAlert = styled(Alert)`
//   font-size: 0.9375rem;
// `;

export const StyledFloatingActions = styled.div`
  padding: 1rem;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
`;

const StyledTemplateSpace = styled.div`
  height: 4.5rem;
`;

export const StyledEventAlert = styled(Alert)`
  font-size: 0.875rem;
  font-weight: 500;
  max-width: 65%;
  margin: 0.5rem auto;
  text-align: center;
  padding: 0.125rem 1rem;
`;

// const flyingNotificationVariants = {
//   hidden: {
//     y: -50,
//     opacity: 0,
//   },
//   visible: {
//     y: 10,
//     opacity: 1,
//     transition: { delay: 0.5 },
//   },
// };

interface IConversationProps {
  conversationId: string | null;
  conversation?: TConversation;
  messages: TMessage[];
  isLoading: boolean;
  canSendMessage: boolean;
  canSendTemplate: boolean;
  isConversationBlocked: boolean;
  showTemplateAlreadySent: boolean;
  show24HoursNotification: boolean;
  onSendMessage: (message: string) => void;
  onSendHsm: (hsm: THsm) => void;
  currentTime?: Date;
}

const Conversation: React.FC<IConversationProps> = (props) => {
  const {
    conversationId,
    messages,
    isLoading,
    canSendMessage,
    canSendTemplate,
    isConversationBlocked,
    showTemplateAlreadySent,
    show24HoursNotification,
    onSendMessage,
    onSendHsm,
  } = props;
  const currentTime = props.currentTime || new Date();

  const timerRef: React.MutableRefObject<ReturnType<typeof setTimeout> | null> =
    useRef<ReturnType<typeof setTimeout>>(null);
  const { bottomRef } = useScrollToBottom(messages);

  const { authUser } = useAuthUser();
  const markConversationAsReadMutation = useMarkConversationAsRead();

  const handleBottomVisible = () => {
    if (!!timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (!conversationId) {
      return;
    }

    timerRef.current = setTimeout(() => {
      markConversationAsReadMutation.mutate({ conversationId: conversationId });
    }, 1000);
  };

  const anyUnreadMessages = !!authUser.coreV3Id
    ? anyUnreadMessagesForUser(messages, authUser.coreV3Id)
    : false;

  if (isLoading) {
    return (
      <StyledWrapper style={{ justifyContent: "center" }}>
        <StyledLoadingWrapper>
          <CircularProgress thickness={5} size={24} />
        </StyledLoadingWrapper>
      </StyledWrapper>
    );
  }

  if (!conversationId) {
    return <DummyConversation onSendHsm={onSendHsm} />;
  }

  return (
    <StyledWrapper>
      <StyledConversationContainerWrapper>
        {/* <motion.div
          animate={show24HoursNotification ? "visible" : "hidden"}
          initial="hidden"
          variants={flyingNotificationVariants}
          style={{
            position: "absolute",
            zIndex: 1,
            width: "100%",
          }}
        >
          <StyledFlyingNotification>
            <StyledAlert severity="warning">
              <FormattedMessage
                id="Conversation.No puedes enviar mensajes si el Candidato/a no ha escrito en las últimas 24 horas"
                defaultMessage="No puedes enviar mensajes si el Candidato/a no ha escrito en las últimas 24 horas"
              />
            </StyledAlert>
          </StyledFlyingNotification>
        </motion.div> */}
        <StyledConversationContainer>
          {messages && !!(messages.length > 0) && (
            <>
              {messages.map((message) => (
                <StyledMessageWrapper key={message.id}>
                  <ConversationMessage
                    message={message}
                    currentTime={currentTime}
                  />
                </StyledMessageWrapper>
              ))}
              {anyUnreadMessages && (
                <IntersectionPlaceholder
                  onVisibleOnScreen={handleBottomVisible}
                />
              )}
            </>
          )}

          {showTemplateAlreadySent && (
            <StyledEventAlert severity="warning">
              <FormattedMessage
                id="Conversation.Se ha enviado una plantilla en las últimas 24 horas. No se puede enviar ningún mensaje hasta que el Candidato/a no haya escrito."
                defaultMessage="Se ha enviado una plantilla en las últimas 24 horas. No se puede enviar ningún mensaje hasta que el Candidato/a no haya escrito."
              />
            </StyledEventAlert>
          )}

          {show24HoursNotification && (
            <StyledEventAlert severity="warning">
              <FormattedMessage
                id="Conversation.No puedes enviar mensajes si el Candidato/a no ha escrito en las últimas 24 horas. Puedes enviar una plantilla para intentar contactar con él, pero si no contesta no podrás escribir mensajes."
                defaultMessage="No puedes enviar mensajes si el Candidato/a no ha escrito en las últimas 24 horas. Puedes enviar una plantilla para intentar contactar con él, pero si no contesta no podrás escribir mensajes."
              />
            </StyledEventAlert>
          )}

          {isConversationBlocked &&
            !show24HoursNotification &&
            !showTemplateAlreadySent && (
              <StyledEventAlert severity="warning">
                <FormattedMessage
                  id="Conversation.El candidato ha cambiado de teléfono y la conversación se ha cerrado, tendrás que esperar a que responda o enviarle una plantilla."
                  defaultMessage="El candidato ha cambiado de teléfono y la conversación se ha cerrado, tendrás que esperar a que responda o enviarle una plantilla."
                />
              </StyledEventAlert>
            )}

          {canSendTemplate && (
            <StyledFloatingActions key={`conversation_fabs_${conversationId}`}>
              <TemplateActionButtons onSendHsm={onSendHsm} />
            </StyledFloatingActions>
          )}

          {canSendTemplate && <StyledTemplateSpace />}

          <div ref={bottomRef} style={{ clear: "both" }} />
        </StyledConversationContainer>
      </StyledConversationContainerWrapper>
      <StyledInputContainer>
        <InputBox onSendMessage={onSendMessage} disabled={!canSendMessage} />
      </StyledInputContainer>
    </StyledWrapper>
  );
};

export default Conversation;
