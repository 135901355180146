import { useMutation } from "react-query";
import { TStaffUserResponse } from "../../../../../../../api/user/apiListStaffUsers";
import { apiUpdateStaffUser } from "../../../../../../../api/user/apiUpdateStaffUser";
import { queryClient } from "../../../../../../../lib/react-query";

type TChangeUserEnabledPayload = {
  id: string;
  enabled: boolean;
};

export default function useUserEnabledMutation() {
  const changeUserEnabled = async (payload: TChangeUserEnabledPayload) => {
    await apiUpdateStaffUser(payload.id, { enabled: payload.enabled });
  };

  return useMutation(changeUserEnabled, {
    onMutate: async (payload: any) => {
      queryClient.cancelQueries(["current_coordinator", payload.id]);

      const previousStaff: TStaffUserResponse | undefined =
        queryClient.getQueryData(["current_coordinator", payload.id]);

      if (!previousStaff) {
        return undefined;
      }

      queryClient.setQueryData(["current_coordinator", payload.id], {
        ...previousStaff,
        enabled: payload.enabled,
      });

      return () => {
        queryClient.setQueryData(
          ["current_coordinator", payload.id],
          previousStaff
        );
      };
    },
    onError: (err, payload, rollback: any) => {
      rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries([]);
    },
  });
}
