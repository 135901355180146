import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Form, useFormikContext } from "formik";
import { FormattedMessage } from "react-intl";
import StyledHelpText from "../../BasicComponents/StyledHelpText";
import { FormikTextField } from "../../FormikFields";
import { StyledFormWrapper, StyledSaveButton } from "./ChangeDNIButtonSC";

interface IChangeDNIButtonFormProps {
  onClose: () => void;
}

const ChangeDNIButtonForm = (props: IChangeDNIButtonFormProps) => {
  const { onClose } = props;
  const { dirty, isValid, isSubmitting } =
    useFormikContext<{
      DNI_NIE: string;
    }>();

  return (
    <Form>
      <DialogTitle>
        <FormattedMessage id="cambioDNI.cambiar" defaultMessage="Cambiar DNI" />
      </DialogTitle>
      <DialogContent>
        <StyledHelpText>
          <FormattedMessage
            id="cambioDNI.help"
            defaultMessage="Estás a punto de cambiar el DNI del Candidato. Ten en cuenta que este cambio afecta al usuario que verá cambiado su login de acceso al usar el nuevo DNI"
          />
        </StyledHelpText>
        <StyledFormWrapper>
          <FormikTextField
            name="DNI_NIE"
            variant="outlined"
            label={<FormattedMessage id="DNI" defaultMessage="DNI" />}
            margin="normal"
            fullWidth
            uppercase
          />
        </StyledFormWrapper>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          <FormattedMessage id="Cancelar" defaultMessage="Cancelar" />
        </Button>
        <StyledSaveButton
          color="primary"
          variant="contained"
          type="submit"
          disabled={!dirty || !isValid || isSubmitting}
        >
          {isSubmitting ? (
            <CircularProgress size={18} thickness={5} />
          ) : (
            <FormattedMessage
              id="ChangeDNIButton.Guardar"
              defaultMessage="Guardar"
            />
          )}
        </StyledSaveButton>
      </DialogActions>
    </Form>
  );
};

export default ChangeDNIButtonForm;
