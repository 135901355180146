import { apiEmpleo } from "../../util/ApiEmpleo";

export type TUpdateContractImportantNotesRequest = {
  Notas_importantes: string | null;
};

const apiUpdateContractImportantNotes = async (
  contractId: string,
  payload: TUpdateContractImportantNotesRequest
) => {
  const res = await apiEmpleo.patch(
    `contracts/${contractId}/update-important-notes`,
    payload,
    {
      "Content-Type": "application/merge-patch+json",
    }
  );

  return res;
};

export { apiUpdateContractImportantNotes };
