import React from "react";
import styled from "styled-components";
import { COORDINATION_GROUP } from "../../../../../../api/user/apiListStaffUsers";
import { TUserGroupAssignations } from "../../types/UserGroupAssignations.types";
import GroupAssignationsItem from "./GroupAssignationsItem";

const StyledWrapper = styled.div``;

const StyledItemWrapper = styled.div`
  border-bottom: 1px solid rgb(239, 239, 239);

  &.CMuiSelected {
    .CMuiCoordinatorAssignationsItem {
      background-color: rgb(235, 235, 235);
    }
  }
`;

interface IGroupAssignationsListProps {
  groups: TUserGroupAssignations[];
  isLoading: boolean;
  maxGroupContracts: number;
  selectedOwnerId: string | null;
  onSelectedOwnerChange: (ownerId: string | null) => void;
}

const GroupAssignationsList: React.FC<IGroupAssignationsListProps> = (
  props
) => {
  const {
    groups,
    isLoading,
    maxGroupContracts,
    selectedOwnerId,
    onSelectedOwnerChange,
  } = props;

  return (
    <StyledWrapper>
      {groups
        .filter(
          (group) =>
            group.id !== COORDINATION_GROUP.ON_SHIFT ||
            group.maxContractsAssigned !== 0
        )
        .map((group) => (
          <StyledItemWrapper key={`as_gr_${group.id}`}>
            <GroupAssignationsItem
              group={group}
              isLoading={isLoading}
              onSelectedOwnerChange={onSelectedOwnerChange}
              selectedOwnerId={selectedOwnerId}
              maxContracts={maxGroupContracts}
            />
          </StyledItemWrapper>
        ))}
    </StyledWrapper>
  );
};

export default GroupAssignationsList;
