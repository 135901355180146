import moment from "moment";
import "moment/locale/es";
import { TGetContractsItem } from "../../api/contract/contract.types";
import { candidateFavoriteOfUser } from "../../apps/affinity/features/candidate/util/candidate.util";
import { TContractValuation } from "../../apps/affinity/features/valuation/types/valuation.types";
import {
  TIPO_TRATO_INTERNA_ENTRE_SEMANA,
  TIPO_TRATO_INTERNA_FIN_DE_SEMANA,
} from "../defaults/contrato";
import { EMPLOYEE_STATUS } from "../../api/employee/employee.types";

export const decorateValoracionWithScore = (
  valuation: TContractValuation,
  contract: TGetContractsItem,
  userId: string
) => {
  if (valuation.suggestionScore) {
    valuation.score = valuation.suggestionScore;

    return valuation;
  }

  if (
    valuation.candidato &&
    typeof valuation.candidato.employeeScore !== "undefined"
  ) {
    valuation.score = valuation.candidato.employeeScore;

    if (contract.Tipo_de_Trato === TIPO_TRATO_INTERNA_ENTRE_SEMANA) {
      if (valuation.candidato.Estado_Cuideo === EMPLOYEE_STATUS.ACTIVE) {
        valuation.score = valuation.score * 0.2;
      }
    }

    if (contract.Tipo_de_Trato === TIPO_TRATO_INTERNA_FIN_DE_SEMANA) {
      if (valuation.candidato.Estado_Cuideo === EMPLOYEE_STATUS.ACTIVE) {
        valuation.score = valuation.score * 0.4;
      }
    }

    if (!valuation.Candidato_Web) {
      valuation.score += 100;
    }

    const favorite = valuation.candidato
      ? candidateFavoriteOfUser(valuation.candidato, userId)
      : null;

    if (!!favorite) {
      valuation.score += 1000;
    }

    return valuation;
  }

  let score = 0;

  if (valuation.candidato && valuation.candidato.Estado_Cuideo) {
    switch (valuation.candidato.Estado_Cuideo) {
      case EMPLOYEE_STATUS.VALIDATED:
        score += 20;
        break;
      case EMPLOYEE_STATUS.VALIDATED_PLUS:
        score += 50;
        break;
      case EMPLOYEE_STATUS.DISCARDED:
        score -= 50;
        break;
      case EMPLOYEE_STATUS.ACTIVE:
        score += 30;
        break;
      default:
        break;
    }
  }

  valuation.score = score;
  if (!valuation.Candidato_Web) {
    valuation.score += 100;
  }
  return valuation;
};

export const sortValoracionesByScore = (a: any, b: any) => {
  if (a.score < b.score) {
    return 1;
  }
  if (a.score > b.score) {
    return -1;
  }
  return 0;
};

export const sortValoracionesByRecientes = (a: any, b: any) => {
  if (moment(a.createdAt) < moment(b.createdAt)) {
    return 1;
  }
  if (moment(a.createdAt) > moment(b.createdAt)) {
    return -1;
  }
  return 0;
};
export const sortValoracionesByAntiguas = (a: any, b: any) => {
  if (moment(a.createdAt) < moment(b.createdAt)) {
    return -1;
  }
  if (moment(a.createdAt) > moment(b.createdAt)) {
    return 1;
  }
  return 0;
};

export const sortValoracionesByFechaEntrevistaReciente = (a: any, b: any) => {
  if (a.Fecha_y_hora_entrevista && b.Fecha_y_hora_entrevista) {
    if (moment(a.Fecha_y_hora_entrevista) < moment(b.Fecha_y_hora_entrevista)) {
      return 1;
    }
    if (moment(a.Fecha_y_hora_entrevista) > moment(b.Fecha_y_hora_entrevista)) {
      return -1;
    }
    return 0;
  }
  if (a.Fecha_y_hora_entrevista) {
    return -1;
  }
  if (b.Fecha_y_hora_entrevista) {
    return 1;
  }
  return 0;
};

export const sortValoracionesByFechaFinUltimoContrato = (a: any, b: any) => {
  if (a.candidato?.lastContractEndDate && b.candidato?.lastContractEndDate) {
    if (moment(a.candidato?.lastContractEndDate) < moment(b.candidato?.lastContractEndDate)) {
      return 1;
    }
    if (moment(a.candidato?.lastContractEndDate) > moment(b.candidato?.lastContractEndDate)) {
      return -1;
    }
    return 0;
  }
  if (a.candidato?.lastContractEndDate) {
    return -1;
  }
  if (b.candidato?.lastContractEndDate) {
    return 1;
  }
  return 0;
};

export const decorateValoracionesWithScore = (
  valoracionesResponse: any,
  contrato: any,
  userId: string
) => {
  const valoraciones = valoracionesResponse.map((valoracion: any) => {
    return decorateValoracionWithScore(valoracion, contrato, userId);
  });

  return valoraciones;
};
