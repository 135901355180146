import { InputBase, Paper } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import cuideoTheme from "../../containers/themes/defaultTheme";

const StyledPaper = styled(Paper)`
  background-color: #e5edf1;
  border-radius: ${cuideoTheme.shape.borderRadius}px;
  padding: 0 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledInputBase = styled(InputBase)`
  height: 2.5rem;
  min-width: 12rem;
  font-size: 1rem;
  color: ${cuideoTheme.palette.primary.main};
  font-weight: 600;
`;

const StyledSearchIcon = styled(SearchIcon)`
  padding-right: 0.5rem;
`;

interface ICuideoSearchFieldProps {
  onChange: any;
  value: string;
  placeholder?: string;
}

const CuideoSearchField = (props: ICuideoSearchFieldProps) => {
  const { onChange, value, placeholder } = props;
  const { formatMessage } = useIntl();

  return (
    <StyledPaper className="Mui-CuideoSearch" elevation={0}>
      <StyledSearchIcon color="primary" />

      <StyledInputBase
        placeholder={
          placeholder ||
          formatMessage({
            id: "CuideoSearchField.DefaultSearch",
            defaultMessage: "Buscar por DNI, Teléfono o Nombre.",
          })
        }
        onChange={onChange}
        value={value}
      />
    </StyledPaper>
  );
};

export default CuideoSearchField;
