import { AxiosResponse } from "axios";
import { apiCoreV3BasicClient } from "../apiCoreV3BasicClient";

export interface IListMunicipalitiesParams {
  postal_code?: string;
}

export type TMunicipalityResponse = {
  id: string;
  postal_code: string;
  municipality_code: string;
  name: string;
  province: string;
  tier: number | null;
};

export const apiListMunicipalities = async (
  queryParams: IListMunicipalitiesParams
) => {
  const params = {
    hst: process.env.REACT_APP_COREV3_HASH,
    postal_code: queryParams.postal_code ?? undefined,
  };
  let res: AxiosResponse<{ municipalities: TMunicipalityResponse[] }> =
    await apiCoreV3BasicClient.get("municipalities", { params });

  return res.data;
};
