import moment, { Moment } from "moment";
import {
  APP_INITIALIZE,
  APP_INITIALIZED,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_COUNTRIES,
  GET_EDUCATION,
  GET_JOB_TASKS,
  GET_PROVINCES,
  GET_SPEAKS,
  HIDE_BACKDROP,
  HIDE_SNACK_MESSAGE,
  SET_COMMON_OPTIONS,
  SHOW_BACKDROP,
  SHOW_SNACK_MESSAGE,
  UPLOAD_ERROR,
  UPLOAD_START,
  UPLOAD_SUCCESS,
} from "../constants/ActionTypes";
import defaultProvincias from "../util/defaults/provincias";

interface SyncOp {
  opId: string;
  op: string;
  opType: string;
  opTime: Moment;
}

export type TCommonState = {
  isLoading: boolean;
  isUpdating: boolean;
  syncError: boolean;
  syncOps: SyncOp[];
  isInitialized: boolean;
  provinces: typeof defaultProvincias;
  speaks: any[];
  tasks: any[];
  countries: any[];
  education: any[];
  snackMessage: null | any;
  showBackdrop: boolean;
  locations: any[];
  prevLocation: any;
  paisesOptions: any[];
  provinciasOptions: any[];
  diasOptions: any[];
  mesesOptions: any[];
  anyosOptions: any[];
  idiomasOptions: any[];
  titulacionesOptions: any[];
  tareasOptions: any[];
  educacionOptions: any[];
  experienceYearsOptions: any[];
  patologiasOptions: any[];
  utmSource: null | string;
  utmMedium: null | string;
  utmCampaign: null | string;
  timeSlotOptions: any[];
  contractTypeOptions: any[];
  contractOvernightHoursOptions: any[];
  contractPresenceHoursOptions: any[];
  contractSalaryTypeOptions: any[];
};

const INIT_STATE: TCommonState = {
  isLoading: false,
  isUpdating: false,
  syncError: false,
  syncOps: [],
  isInitialized: false,
  provinces: defaultProvincias,
  speaks: [],
  tasks: [],
  countries: [],
  education: [],
  snackMessage: null,
  showBackdrop: false,
  locations: [],
  prevLocation: null,
  paisesOptions: [],
  provinciasOptions: [],
  diasOptions: [],
  mesesOptions: [],
  anyosOptions: [],
  idiomasOptions: [],
  titulacionesOptions: [],
  tareasOptions: [],
  educacionOptions: [],
  experienceYearsOptions: [],
  patologiasOptions: [],
  utmSource: null,
  utmMedium: null,
  utmCampaign: null,
  timeSlotOptions: [],
  contractTypeOptions: [],
  contractOvernightHoursOptions: [],
  contractPresenceHoursOptions: [],
  contractSalaryTypeOptions: []
};

const urlString = window.location.href;

let utmSource = null;
let utmMedium = null;
let utmCampaign = null;

if (sessionStorage) {
  if (sessionStorage.getItem("utm_source")) {
    utmSource = sessionStorage.getItem("utm_source");
  }
  if (sessionStorage.getItem("utm_medium")) {
    utmMedium = sessionStorage.getItem("utm_medium");
  }
  if (sessionStorage.getItem("utm_campaign")) {
    utmCampaign = sessionStorage.getItem("utm_campaign");
  }
}

if ("URL" in window) {
  const url = new URL(urlString);

  if (url.searchParams) {
    utmSource = url.searchParams.get("utm_source")
      ? url.searchParams.get("utm_source")
      : utmSource;
    utmMedium = url.searchParams.get("utm_medium")
      ? url.searchParams.get("utm_medium")
      : utmMedium;
    utmCampaign = url.searchParams.get("utm_campaign")
      ? url.searchParams.get("utm_campaign")
      : utmCampaign;
  }
}

INIT_STATE.utmSource = utmSource;
INIT_STATE.utmMedium = utmMedium;
INIT_STATE.utmCampaign = utmCampaign;
if (sessionStorage) {
  if (utmSource) {
    sessionStorage.setItem("utm_source", utmSource);
  }
  if (utmMedium) {
    sessionStorage.setItem("utm_medium", utmMedium);
  }
  if (utmCampaign) {
    sessionStorage.setItem("utm_campaign", utmCampaign);
  }
}

// eslint-disable-next-line
export default (state = INIT_STATE, action: { type: string; payload: any }) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE":
      return {
        ...state,
        locations: [...state.locations, action.payload.location],
        prevLocation:
          state.locations.length > 0
            ? state.locations[state.locations.length - 1]
            : null,
      };
    case FETCH_START: {
      return {
        ...state,
        isLoading: true,
        syncOps: [
          ...state.syncOps,
          {
            opId: action.payload.opId,
            op: action.payload.op,
            opType: "fetch",
            opTime: moment(),
          },
        ],
      };
    }
    case FETCH_SUCCESS: {
      const opId = action.payload.opId;
      const newSyncOps = state.syncOps.filter((item: SyncOp) => {
        return opId !== item.opId;
      });
      const fetchOp = newSyncOps.find((item: SyncOp) => {
        return item.opType === "fetch";
      });
      const newIsLoading = !!fetchOp;
      const uploadOp = newSyncOps.find((item: SyncOp) => {
        return item.opType === "fetch";
      });
      const newIsUpdating = !!uploadOp;
      return {
        ...state,
        isLoading: newIsLoading,
        syncOps: newSyncOps,
        isUpdating: newIsUpdating,
      };
    }
    case FETCH_ERROR: {
      return { ...state, error: action.payload, message: "", isLoading: false };
    }
    case UPLOAD_START: {
      return {
        ...state,
        isUpdating: true,
        syncOps: [
          ...state.syncOps,
          {
            opId: action.payload.opId,
            op: action.payload.op,
            opType: "update",
            opTime: moment(),
          },
        ],
      };
    }
    case UPLOAD_SUCCESS: {
      const opId = action.payload.opId;
      const newSyncOps = state.syncOps.filter((item: SyncOp) => {
        return opId !== item.opId;
      });
      const fetchOp = newSyncOps.find((item: SyncOp) => {
        return item.opType === "fetch";
      });
      const newIsLoading = !!fetchOp;
      const uploadOp = newSyncOps.find((item: SyncOp) => {
        return item.opType === "fetch";
      });
      const newIsUpdating = !!uploadOp;
      return {
        ...state,
        isLoading: newIsLoading,
        syncOps: newSyncOps,
        isUpdating: newIsUpdating,
      };
    }
    case UPLOAD_ERROR: {
      return {
        ...state,
        error: action.payload,
        message: "",
        isUpdating: false,
      };
    }
    // case SHOW_MESSAGE: {
    //   return { ...state, error: "", message: action.payload, loading: false };
    // }
    // case HIDE_MESSAGE: {
    //   return { ...state, loading: false, error: "", message: "" };
    // }
    case SHOW_SNACK_MESSAGE: {
      return { ...state, snackMessage: action.payload };
    }
    case HIDE_SNACK_MESSAGE: {
      return { ...state, snackMessage: action.payload };
    }
    case GET_JOB_TASKS: {
      return { ...state, tasks: action.payload };
    }
    case GET_COUNTRIES: {
      return { ...state, countries: action.payload };
    }
    case GET_EDUCATION: {
      return { ...state, education: action.payload };
    }
    case GET_PROVINCES: {
      return { ...state, provinces: action.payload };
    }
    case GET_SPEAKS: {
      return { ...state, speaks: action.payload };
    }
    case APP_INITIALIZE: {
      return { ...state, isInitialized: false };
    }
    case APP_INITIALIZED: {
      return { ...state, isInitialized: true };
    }
    case SET_COMMON_OPTIONS: {
      const {
        paisesOptions,
        provinciasOptions,
        diasOptions,
        mesesOptions,
        anyosOptions,
        idiomasOptions,
        titulacionesOptions,
        tareasOptions,
        educacionOptions,
        patologiasOptions,
        experienceYearsOptions,
        timeSlotOptions,
        contractTypeOptions,
        contractOvernightHoursOptions,
        contractPresenceHoursOptions,
        contractSalaryTypeOptions
      } = action.payload;
      return {
        ...state,
        paisesOptions,
        provinciasOptions,
        diasOptions,
        mesesOptions,
        anyosOptions,
        idiomasOptions,
        titulacionesOptions,
        tareasOptions,
        educacionOptions,
        patologiasOptions,
        experienceYearsOptions,
        timeSlotOptions,
        contractTypeOptions,
        contractOvernightHoursOptions,
        contractPresenceHoursOptions,
        contractSalaryTypeOptions
      };
    }
    case SHOW_BACKDROP:
      return {
        ...state,
        showBackdrop: true,
      };
    case HIDE_BACKDROP:
      return {
        ...state,
        showBackdrop: false,
      };
    default:
      return state;
  }
};
