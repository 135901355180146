import { AxiosResponse } from "axios";
import { apiCoreV3 } from "../apiCoreV3";
import { TConversationResponse } from "./apiConversation.types";

const apiGetConversation = async (conversationId: string) => {
  const res: AxiosResponse<TConversationResponse> = await apiCoreV3.get(
    `/communication/conversations/${conversationId}`
  );

  return res.data;
};

export { apiGetConversation };
