import {
  // FILTERS_SET_CONTRATOS,
  FILTERS_SET_CONTRATO,
  // FILTERS_SET_CANDIDATOS,
  FILTERS_SET_FAVORITE_CANDIDATOS,
  FILTERS_SET_TRANSFER,
  // FILTERS_RESET_CONTRATOS,
  // FILTERS_RESET_CANDIDATOS,
  FILTERS_RESET_CONTRATO,
  FILTERS_RESET_CONTRATO_SUGGESTIONS,
} from "../constants/ActionTypes";

// export const filtersSetContratos = (contratosFilters: any) => {
//   // Sanitize a bit
//   if (contratosFilters.page) {
//     contratosFilters.page = parseInt(contratosFilters.page);
//   }

//   return {
//     type: FILTERS_SET_CONTRATOS,
//     payload: contratosFilters,
//   };
// };

// export const filtersResetContratos = () => {
//   return {
//     type: FILTERS_RESET_CONTRATOS,
//   };
// };

export const filtersSetTransfer = (transferFilters: any) => {
  return {
    type: FILTERS_SET_TRANSFER,
    payload: transferFilters,
  };
};

export const filtersSetContrato = (contratoFilters: any) => {
  // Sanitize a bit
  if (contratoFilters.page) {
    contratoFilters.page = parseInt(contratoFilters.page);
  }
  return {
    type: FILTERS_SET_CONTRATO,
    payload: contratoFilters,
  };
};

export const filtersResetContrato = () => {
  return {
    type: FILTERS_RESET_CONTRATO,
  };
};

export const filtersResetContratoSuggestions = () => {
  return {
    type: FILTERS_RESET_CONTRATO_SUGGESTIONS,
  };
};

// export const filtersSetCandidatos = (candidatosFilters: any) => {
//   // Sanitize a bit
//   if (candidatosFilters.page) {
//     candidatosFilters.page = parseInt(candidatosFilters.page);
//   }
//   return {
//     type: FILTERS_SET_CANDIDATOS,
//     payload: candidatosFilters,
//   };
// };

// export const filtersResetCandidatos = () => {
//   return {
//     type: FILTERS_RESET_CANDIDATOS,
//   };
// };

export const filtersSetFavoriteCandidatos = (
  favoriteCandidatosFilters: any
) => {
  // Sanitize a bit
  if (favoriteCandidatosFilters.page) {
    favoriteCandidatosFilters.page = parseInt(favoriteCandidatosFilters.page);
  }
  return {
    type: FILTERS_SET_FAVORITE_CANDIDATOS,
    payload: favoriteCandidatosFilters,
  };
};
