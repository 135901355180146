import { apiEmpleo } from "../../util/ApiEmpleo";
import { TApiCollectionResponse } from "../apiCoreV2.types";
import { TGetMessagesCollectionItem } from "./message.types";

interface IApiListMessagesParams {
  page?: number;
  itemsPerPage?: number;
  parentId?: string[];
  module?: string[];
  "order[createdAt]"?: "desc" | "asc";
}

const apiListMessages = async (params: IApiListMessagesParams) => {
  const res: TApiCollectionResponse<TGetMessagesCollectionItem> =
    await apiEmpleo.get("messages", { params });

  return res;
};

export { apiListMessages };
