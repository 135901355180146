import React from "react";
import AllContractsView from "../../components/AllContractsView";
import ContractAside from "../../components/ContractAside";
import RecruiterAside from "../../components/RecruiterAside";
import RecruiterAssignations from "../../components/RecruiterAssignations";
import RecruiterManagerLayout from "../../components/RecruiterManagerLayout";
import RecruiterView from "../../components/RecruiterView";
import {
  TChangeOrderFn,
  TRecruiterManagerRouteFilters,
} from "./hooks/useRecruiterManagerRouteFilters";

interface IRecruiterManagerRouteProps {
  filters: TRecruiterManagerRouteFilters;
  onSelectedOwnerChange: (ownerId: string | null) => void;
  onSelectedContractChange: (contractId: string) => void;
  onChangeTableOrder: TChangeOrderFn;
}

const RecruiterManagerRoute: React.FC<IRecruiterManagerRouteProps> = (
  props
) => {
  const {
    filters,
    onSelectedOwnerChange,
    onSelectedContractChange,
    onChangeTableOrder,
  } = props;

  return (
    <RecruiterManagerLayout
      leftAside={
        <RecruiterAssignations
          selectedOwnerId={filters.selectedOwnerId}
          onSelectedOwnerChange={onSelectedOwnerChange}
        />
      }
      main={
        filters.selectedOwnerId ? (
          <RecruiterView
            recruiterId={filters.selectedOwnerId}
            filters={filters}
            onSelectedContractChange={onSelectedContractChange}
            onChangeTableOrder={onChangeTableOrder}
          />
        ) : (
          <AllContractsView
            filters={filters}
            onSelectedContractChange={onSelectedContractChange}
            onChangeTableOrder={onChangeTableOrder}
          />
        )
      }
      rightAside={
        !!filters.selectedContractId ? (
          <ContractAside contractId={filters.selectedContractId} />
        ) : filters.selectedOwnerId ? (
          <RecruiterAside recruiterId={filters.selectedOwnerId} />
        ) : undefined
      }
    />
  );
};

export default RecruiterManagerRoute;
