import React from "react";
import styled from "styled-components";
import CheckIcon from "@material-ui/icons/Check";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 0.125rem;

  .MuiSvgIcon-root {
    width: 1rem;
    height: 1rem;
  }
`;

interface IMessageDoubleCheckProps {
  isRead: boolean;
}

const MessageDoubleCheck = (props: IMessageDoubleCheckProps) => {
  const { isRead } = props;

  return (
    <StyledWrapper>
      <CheckIcon
        style={{
          color: isRead ? "#4fc3f7" : "#666",
          fontSize: "1rem",
        }}
      />
      <CheckIcon
        style={{
          marginLeft: "-0.625rem",
          color: isRead ? "#4fc3f7" : "#666",
          fontSize: "1rem",
        }}
      />
    </StyledWrapper>
  );
};

export default MessageDoubleCheck;
