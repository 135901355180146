export const CONVERSATION_STATUS = {
  ACTIVE: "active",
  EXPIRED: "expired",
  ERROR: "error",
} as const;

type TConversationStatus =
  typeof CONVERSATION_STATUS[keyof typeof CONVERSATION_STATUS];

export const CONVERSATION_STAKEHOLDER_TYPE = {
  USER: "user",
} as const;

type TConversationStakeHolderType =
  typeof CONVERSATION_STAKEHOLDER_TYPE[keyof typeof CONVERSATION_STAKEHOLDER_TYPE];

export type TConversationStakeHolder = {
  id: string;
  created_at: string;
  interested_id: string;
  type: TConversationStakeHolderType;
  unread_messages: number;
  updated_at: string;
};

export type TConversationResponse = {
  id: string;
  channel_ids: string[];
  contact_id: string;
  contact_phone: string | null;
  conversation_stake_holder: TConversationStakeHolder[];
  created_at: string;
  last_received_at?: string | null;
  module_id: string;
  status: TConversationStatus;
  type: string;
  updated_at: string;
};
