import { CircularProgress, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { VALUATION_STATUS } from "../../../../../../api/valuation/valuation.types";
import { cuideoTheme } from "../../../../../../containers/themes/defaultTheme";
import {
  CONTRATO_ESTADO_ACTIVO,
  CONTRATO_ESTADO_FIN_DE_CONTRATO,
  CONTRATO_ESTADO_SELECCIONADO,
} from "../../../../../../util/defaults/contrato";
import { useCandidateValuations } from "../../../valuation";
import { daysInValuation } from "../../../valuation/util/valuation.util";

const StyledWrapper = styled.div``;

const StyledLoadingWrapper = styled.div``;

const StyledErrorWrapper = styled.div``;

const StyledRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.25rem 0.125rem;

  border-top: 1px solid ${cuideoTheme.palette.divider};
  &:last-child {
    border-bottom: 1px solid ${cuideoTheme.palette.divider};
  }
`;

const StyledRowName = styled(Typography)`
  flex: 1 1 auto;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25rem;
  text-align: left;
`;

const StyledRowDuration = styled(Typography)`
  width: 5rem;
  font-family: "Open Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
`;

const StyledRowValuation = styled(Typography)`
  width: 6rem;
  font-family: "Open Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  text-align: right;
`;

interface ICandidateContractsDetailProps {
  candidateId: string;
}

const CandidateContractsDetail: React.FC<ICandidateContractsDetailProps> = (
  props
) => {
  const { candidateId } = props;

  const valuationsQuery = useCandidateValuations({
    params: {
      candidateId,
      estadoEnProceso: VALUATION_STATUS.CONTRACTED,
      "contrato.estado": [
        CONTRATO_ESTADO_ACTIVO,
        CONTRATO_ESTADO_SELECCIONADO,
        CONTRATO_ESTADO_FIN_DE_CONTRATO,
      ],
    },
  });

  return (
    <StyledWrapper>
      {valuationsQuery.status === "loading" && (
        <StyledLoadingWrapper>
          <CircularProgress thickness={5} size={18} />
        </StyledLoadingWrapper>
      )}
      {valuationsQuery.status === "error" && (
        <StyledErrorWrapper>Error</StyledErrorWrapper>
      )}
      {valuationsQuery.status === "success" && valuationsQuery.data && (
        <>
          {valuationsQuery.data["hydra:member"].map((valuation) => (
            <StyledRowWrapper key={valuation.id}>
              <StyledRowName>{valuation.contrato?.Name}</StyledRowName>
              <StyledRowDuration>
                {daysInValuation(valuation)} dias
              </StyledRowDuration>
              <StyledRowValuation>
                {valuation.contrato?.Valoracion_Cuideo_Candidata}
              </StyledRowValuation>
            </StyledRowWrapper>
          ))}
        </>
      )}
    </StyledWrapper>
  );
};

export default CandidateContractsDetail;
