import React from "react";
import styled from "styled-components";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;

  .MuiSvgIcon-root {
    width: 1rem;
    height: 1rem;
    margin-left: 0.125rem;
  }
`;

interface IMessagePendingProps {}

const MessagePending: React.FC<IMessagePendingProps> = (props) => {
  return (
    <StyledWrapper>
      <AccessTimeIcon
        style={{
          color: "#aaa",
        }}
      />
    </StyledWrapper>
  );
};

export default MessagePending;
