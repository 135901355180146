import toast from "react-hot-toast";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import {
  apiUpdateContract,
  TUpdateContractPayload,
} from "../../../../../../../api/contract/apiUpdateContract";
import { queryClient } from "../../../../../../../lib/react-query";
import { TContractPartialWithNumbers } from "../../../types/contract.types";
import { contractKeys } from "../../../util/contract.query-keys";
import { TContractsPageFilters } from "./useContractsPageURLParams";

export type TUpdateContractPartial = TUpdateContractPayload & { id: string };

export default function useOffersMutation(
  contractsPageFilters: TContractsPageFilters
) {
  const { formatMessage } = useIntl();

  const updateContract = async ({ id, ...payload }: TUpdateContractPartial) => {
    await apiUpdateContract(id, payload);
  };

  return useMutation(updateContract, {
    onMutate: ({ id, ...payload }) => {
      const queryKey = contractKeys.listForContractsPage(contractsPageFilters);
      queryClient.cancelQueries(queryKey);

      const prevQuery:
        | { contracts: TContractPartialWithNumbers[] }
        | undefined = queryClient.getQueryData(queryKey);

      if (!prevQuery) {
        return;
      }

      const newContracts: TContractPartialWithNumbers[] =
        prevQuery.contracts.map((contract) => {
          if (contract.id !== id) {
            return contract;
          }

          return {
            ...contract,
            ...payload,
          };
        });

      queryClient.setQueryData(queryKey, {
        ...prevQuery,
        contracts: newContracts,
      });

      return {
        prevQuery,
      };
    },
    onError: (err, newContract, context: any) => {
      queryClient.setQueryData(
        contractKeys.listForContractsPage(contractsPageFilters),
        context.prevQuery
      );
    },
    onSettled: (data, error, payload) => {
      queryClient.invalidateQueries(contractKeys.all);

      if (error) {
        toast.error(
          formatMessage({
            id: "Contract.Ha habido un error al intentar modificar el contrato",
            defaultMessage:
              "Ha habido un error al intentar modificar el contrato",
          })
        );

        return;
      }
    },
  });
}
