import React from "react";
import styled from "styled-components";

const StyledLayout = styled.div`
  margin: 0;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

const StyledLeftAside = styled.aside`
  min-width: 20rem;
  max-width: 20rem;
  background-color: #f5f5f5;
  border-right: 0.125rem solid #ddd;
`;

const StyledMain = styled.main`
  flex-grow: 1;
  padding: 1rem;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  height: 100%;
  box-sizing: border-box;

  ::-webkit-scrollbar {
    width: 0.25rem;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 72, 120, 0.25);
    width: 0.25rem;
    border-radius: 0.125rem;
  }
`;

const StyledRightAside = styled.aside`
  background-color: #f7f9fc;
  min-width: 0;
  max-width: 0;
  transition: all 0.25s ease-in-out;
  padding: 0;
  overflow-y: scroll;
  border-left: 0.125rem solid #ddd;

  ::-webkit-scrollbar {
    width: 0.25rem;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 72, 120, 0.25);
    width: 0.25rem;
    border-radius: 0.125rem;
  }
`;

// const StyledRightDrawer = styled(Drawer)`
//   .MuiDrawer-paper {
//     max-width: 20rem;
//     min-width: 20rem;
//   }
// `;

// const StyledButtonWrapper = styled(Box)`
//   position: absolute;
//   left: -0.625rem;
//   top: 1.5rem;
//   z-index: 1;
//   transition: opacity 0.125s ease-in-out;
// `;

// const StyledReduceButton = styled(IconButton)`
//   background-color: #fff;
//   border-radius: 50%;
//   border: 1px solid #c0c0c0;
//   width: 1.5rem;
//   height: 1.5rem;
//   box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.07);
//   transition: all 0.125s ease-in-out;

//   .MuiSvgIcon-root {
//     width: 1.25rem;
//     height: 1.25rem;
//   }

//   &:hover,
//   &:focus {
//     background-color: rgba(0, 72, 120, 1);
//     color: #fff;
//   }
// `;

// const StyledExpandButton = styled(IconButton)`
//   background-color: #fff;
//   border-radius: 50%;
//   border: 1px solid #c0c0c0;
//   width: 1.5rem;
//   height: 1.5rem;
//   box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.07);
//   font-size: 1rem;
//   transition: all 0.125s ease-in-out;

//   .MuiSvgIcon-root {
//     width: 1.25rem;
//     height: 1.25rem;
//   }

//   &:hover,
//   &:focus {
//     background-color: rgba(0, 72, 120, 1);
//     color: #fff;
//   }
// `;

interface IRecruiterManagerLayoutProps {
  leftAside: React.ReactNode;
  main: React.ReactNode;
  rightAside: React.ReactNode;
}

const RecruiterManagerLayout: React.VFC<IRecruiterManagerLayoutProps> = (
  props
) => {
  // const [state, setState] = useState({
  //   rightOpen: true,
  //   rightHover: false,
  // });

  // const handleMouseEnter = () => {
  //   console.log("ENTER");
  //   setState((s) => ({ ...s, rightHover: true }));
  // };

  // const handleMouseLeave = () => {
  //   console.log("LEAVE");
  //   setState((s) => ({ ...s, rightHover: false }));
  // };

  // const handleReduceClick = () => {
  //   setState((s) => ({ ...s, rightOpen: false }));
  //   setTimeout(() => {
  //     window.dispatchEvent(new Event("resize"));
  //   }, 500);
  // };

  // const handleExpandClick = () => {
  //   setState((s) => ({ ...s, rightOpen: true }));
  //   setTimeout(() => {
  //     window.dispatchEvent(new Event("resize"));
  //   }, 500);
  // };

  return (
    <StyledLayout>
      <StyledLeftAside>{props.leftAside}</StyledLeftAside>
      <StyledMain>{props.main}</StyledMain>
      {/* <StyledRightDrawer
        open={state.rightOpen}
        anchor="right"
        variant="persistent"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <StyledButtonWrapper
          style={{
            opacity: !state.rightOpen || state.rightHover ? 1 : 0,
          }}
        >
          {state.rightOpen ? (
            <StyledReduceButton onClick={handleReduceClick}>
              <ChevronRightIcon />
            </StyledReduceButton>
          ) : (
            <StyledExpandButton onClick={handleExpandClick}>
              <ChevronLeftIcon />
            </StyledExpandButton>
          )}
        </StyledButtonWrapper>

        {props.rightAside}
      </StyledRightDrawer> */}

      <StyledRightAside
        style={{
          minWidth: !!props.rightAside ? "20rem" : 0,
          maxWidth: !!props.rightAside ? "20rem" : 0,
        }}
      >
        {props.rightAside}
      </StyledRightAside>
    </StyledLayout>
  );
};

export default RecruiterManagerLayout;
