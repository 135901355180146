import { useQuery } from "react-query";
import { apiGetOffersProvinceCities } from "../../../api/offers/apiGetOffers";
import { useState } from "react";
import { CityType } from "../../../types/CityType";

export const useCitiesQuery = (province: string) => {
  const [cities, setCities] = useState<CityType[]>([]);

  const getCities = async () => {
    const response = await apiGetOffersProvinceCities(province);
    setCities(response);
    return {
      cities: response,
    };
  };

  const query = useQuery({
    queryKey: ["empleo-cities", province],
    queryFn: () => getCities(),
  });

  return {
    cities,
    ...query,
  };
};
