const inputStyle = {
  control: {
    backgroundColor: "#d1d7db",
    fontSize: 15,
    fontWeight: "normal",
  },

  "&multiLine": {
    control: {
      fontFamily: "'Source Sans Pro', Helvetica, Arial, sans-serif",
      minHeight: 32,
    },
    highlighter: {
      padding: "0.5rem 1rem",
      border: "1px solid transparent",
    },
    input: {
      padding: "0.5rem 1rem",
      border: "1px solid silver",
      borderRadius: 10,
      backgroundColor: "#ffffff",
    },
  },

  "&singleLine": {
    display: "inline-block",
    width: 180,

    highlighter: {
      padding: 1,
      border: "2px inset transparent",
    },
    input: {
      padding: 1,
      border: "2px inset",
    },
  },

  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontFamily: "'Source Sans Pro', Helvetica, Arial, sans-serif",
      fontSize: 14,
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#cee4e5",
      },
    },
  },
};

export default inputStyle;
