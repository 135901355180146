import { useSessionStorage } from "react-use";

export type TReminderStorage = {
  id: string;
  until: string;
};

export default function useDelayedServicesList() {
  const [delayedServices, setDelayedServicesState] = useSessionStorage<
    TReminderStorage[] | undefined
  >("delayed-reminder-services");

  const addDelayedService = (delayedService: TReminderStorage) => {
    if (delayedServices) {
      const newList = delayedServices.filter(
        (storedDelayedService) => storedDelayedService.id !== delayedService.id
      );
      setDelayedServicesState([...newList, delayedService]);
    } else {
      setDelayedServicesState([delayedService]);
    }
  };

  return {
    delayedServices,
    addDelayedService,
  };
}
