import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { GET_USUARIOS, GET_USUARIO, GET_STAFF } from "../constants/ActionTypes";
import { fetchStart, fetchSuccess } from "../actions/Common";
import axios from "../util/ApiEmpleo";
import {
  getUsuariosSuccess,
  getUsuarioSuccess,
  getStaffSuccess,
} from "../actions/Usuarios";
import uuidv4 from "uuid/v4";

const getUsuariosRequest = async (payload: any) => {
  const params = payload;
  let res = await axios.get("users", {
    params: params,
  });
  if (res.data) {
    return res.data;
  }
};

function* getUsuarios({ payload }: { payload: any }) {
  try {
    const opId = uuidv4();
    yield put(fetchStart(opId, "getUsuarios"));
    // @ts-ignore
    const usuariosRequest = yield call(getUsuariosRequest, payload);
    if (usuariosRequest) {
      const partialUsuarios = usuariosRequest["hydra:member"];
      const numUsuarios = usuariosRequest["hydra:totalItems"];
      yield put(
        getUsuariosSuccess({
          partialUsuarios,
          numUsuarios,
        })
      );
      yield put(fetchSuccess(opId));
    }
  } catch (error) {
    console.log("Error", error);
  }
}

const getUsuarioRequest = async (payload: any) => {
  let res = await axios.get("users/" + payload);
  if (res.data) {
    return [res.data];
  }
};

function* getUsuario({ payload }: { payload: any }) {
  try {
    const opId = uuidv4();
    yield put(fetchStart(opId, "getUsuario"));
    // @ts-ignore
    const usuariosRequest = yield call(getUsuarioRequest, payload);
    if (usuariosRequest) {
      const partialUsuarios = usuariosRequest;
      yield put(
        getUsuarioSuccess({
          partialUsuarios,
        })
      );
      yield put(fetchSuccess(opId));
    }
  } catch (error) {
    console.log("Error", error);
  }
}

const getStaffRequest = async (payload: any) => {
  const params = payload;
  params.roles = "ROLE_STAFF";
  params.active = true;
  let res = await axios.get("staff", {
    params: params,
  });
  if (res.data) {
    return res.data;
  }
};

function* getStaff({ payload }: { payload: any }) {
  try {
    const opId = uuidv4();
    yield put(fetchStart(opId, "getStaff"));
    // @ts-ignore
    const usuariosRequest = yield call(getStaffRequest, payload);
    if (usuariosRequest) {
      const partialUsuarios = usuariosRequest["hydra:member"];
      yield put(
        getStaffSuccess({
          partialUsuarios,
        })
      );
      yield put(fetchSuccess(opId));
    }
  } catch (error) {
    console.log("Error", error);
  }
}

export function* getUsuariosSaga() {
  yield takeEvery(GET_USUARIOS as any, getUsuarios);
}

export function* getUsuarioSaga() {
  yield takeEvery(GET_USUARIO as any, getUsuario);
}

export function* getStaffSafa() {
  yield takeEvery(GET_STAFF as any, getStaff);
}

export default function* rootSaga() {
  yield all([fork(getUsuariosSaga), fork(getUsuarioSaga), fork(getStaffSafa)]);
}
