import styled from "styled-components";
import cuideoTheme from "../../../containers/themes/defaultTheme";
import { Paper, Typography } from "@material-ui/core";

export const StyledEmployeeProfile = styled(Paper)`
  box-shadow: 0 2px 4px 0 #a2c4d8;
`;

export const StyledHeader = styled.div`
  background-color: ${cuideoTheme.palette.primary.main};

  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column-reverse;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    padding: 2rem 3rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const StyledEmployeeWrapper = styled.div`
  flex-grow: 1;
`;

export const StyledCuideoLogo = styled.img`
  height: auto;
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    width: 6rem;
    display: block;
    margin: 0 0 1rem auto;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    width: 12rem;
  }
`;

export const StyledBody = styled.div`
  padding: 1rem 2rem;
`;

export const StyledBigGridCell = styled.div`
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    padding: 0 1rem;
  }
`;

export const StyledSection = styled.div`
  font-size: 0.9375rem;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  color: ${cuideoTheme.palette.primary.main};
  line-height: 1.4;
  margin: 2rem 0;
`;

export const StyledDescriptionWrapper = styled.div`
  font-size: 0.9375rem;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  color: ${cuideoTheme.palette.primary.main};
  line-height: 1.4;

  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    padding: 1rem;
  }
`;

export const StyledDescriptionDetailWrapper = styled.div`
  padding: 0.5rem 1rem;
  margin: 0 0 1rem;
  background-color: #f5f7fc;

  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    padding: 0.5rem 1rem;
    margin: 0 1rem 1rem;
  }
`;

export const StyledDataItem = styled.div`
  margin: 0.375rem 0;
`;

export const StyledLabel = styled(Typography)`
  font-size: 0.75rem;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  color: ${cuideoTheme.palette.primary.main};
  opacity: 0.5;
  line-height: 1.4;
`;

export const StyledData = styled(Typography)`
  font-size: 0.9375rem;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  color: ${cuideoTheme.palette.primary.main};
  line-height: 1.4;
`;

export const StyledSectionTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${cuideoTheme.palette.primary.main};
`;

export const StyledSectionLabel = styled(Typography)`
  font-family: "Lora", Georgia, serif;
  font-size: 1.25rem;
  line-height: 1.4;
  font-weight: 700;
  letter-spacing: -0.5px;
  border-bottom: 2px solid ${cuideoTheme.palette.primary.main};
  flex-grow: 1;
  padding: 0.375rem 0;
`;

export const StyledSectionIcon = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  margin-left: 1rem;
  padding: 0.75rem;
  border-radius: 50%;
  background-color: #e4ebef;
`;

export const StyledIcon = styled.img`
  width: 100%;
  height: 100%;
`;

export const StyledTask = styled.div`
  border-bottom: 2px solid rgba(0, 72, 120, 0.25);
  padding: 0.25rem 0;
  margin: 0.25rem 0;
  line-height: 1.25;
  font-size: 0.9375rem;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  color: ${cuideoTheme.palette.primary.main};
`;

export const StyledFormation = styled.div`
  line-height: normal;
  border-bottom: 2px solid rgba(0, 72, 120, 0.25);
  padding: 0.5rem 0;
  margin: 0.25rem 0;
`;

export const StyledFormationTitle = styled(Typography)`
  font-weight: 700;
  font-size: 1rem;
`;

export const StyledFormationDescription = styled(Typography)`
  font-size: 0.9375rem;
`;

export const StyledFormationValid = styled(Typography)`
  font-size: 0.75rem;
  opacity: 0.5;
`;

export const StyledExperience = styled.div`
  line-height: normal;
  border-bottom: 2px solid rgba(0, 72, 120, 0.25);
  padding: 0.5rem 0;
  margin: 0.25rem 0;
`;

export const StyledExperienceTitle = styled(Typography)`
  font-weight: 700;
  font-size: 1.125rem;
  line-height: normal;
  margin: 0.75rem 0;
`;

export const StyledExperienceDescription = styled(Typography)`
  font-size: 0.9375rem;
  line-height: normal;
  margin: 0.5rem 0;
`;

export const StyledPathologiesTitle = styled(Typography)`
  font-size: 1rem;
  opacity: 0.5;
  font-weight: 700;
  margin: 0.75rem 0 0;
`;

export const StyledPathology = styled(Typography)`
  font-size: 0.875rem;
  line-height: normal;
  margin: 0.25rem 0;
`;

export const StyledCurve = styled.svg`
  height: 3rem;
  width: 100%;
`;

export const StyledExperiencesSection = styled.div`
  font-size: 0.9375rem;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  color: ${cuideoTheme.palette.primary.main};
  line-height: 1.4;
  margin: -2rem 0 2rem;
`;

export const StyledEmployeeAvatar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    .MuiAvatar-root {
      width: 4rem;
      height: 4rem;
      border: 2px solid #fff;
    }
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    margin-bottom: -2rem;

    .MuiAvatar-root {
      width: 7rem;
      height: 7rem;
      border: 2px solid #fff;
    }
  }
`;

export const StyledEmployeeTitle = styled.div`
  margin-left: 1rem;
  flex-grow: 1;
`;

export const StyledSexText = styled(Typography)`
  color: #fff;
  text-transform: uppercase;
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    letter-spacing: 1px;
    font-size: 1rem;
    margin-bottom: 0.25rem;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    letter-spacing: 2px;
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
  }
`;

export const StyledEmployeeName = styled(Typography)`
  color: #fff;
  font-weight: 700;
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 1.25rem;
    line-height: 1.15;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 2rem;
    line-height: 1.15;
  }
`;

export const StyledBottomWrapper = styled.div`
  padding: 0;

  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    padding: 0 1rem;
  }
`;

export const StyledSvgWrapper = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  margin-left: 1rem;
  padding: 0.75rem;
  border-radius: 50%;
  background-color: #e4ebef;

  .MuiSvgIcon-root {
    width: 2.5rem;
    height: 2.5rem;
  }
`;

export const StyledReferenceText = styled(Typography)`
  font-size: 1rem;
  font-style: italic;
  text-align: center;
`;
