import { Box, IconButton, Menu, MenuItem, Typography } from "@material-ui/core";
import classNames from "classnames";
import moment from "moment";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import MessageAuthor from "./MessageAuthor";
import MessageDoubleCheck from "./MessageDoubleCheck";
import MessageFailed from "./MessageFailed";
import MessageMediaContent from "./MessageMediaContent";
import MessagePending from "./MessagePending";
import MessageSingleCheck from "./MessageSingleCheck";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { MESSAGE_DIRECTION, TMessage } from "../../types/message.type";
import {
  conversationMessageText,
  messageOptionsForMessage,
  removeFirstLineWithAuthor,
} from "../../util/message.util";

const StyledMessageWrapper = styled.div`
  clear: both;
`;

const StyledMessage = styled(Box)`
  margin: 0.25rem 0 0.25rem auto;
  padding: 0.5rem 1rem;
  background-color: #e1ffc7;
  border-radius: 0.25rem 0 0.25rem 0.25rem;
  max-width: 85%;
  width: fit-content;
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);

  .CMuiMessageContextual {
    background-color: #e1ffc7;
  }

  .CMuiMessageAuthor {
    text-align: right;
    font-size: 0.9375rem;
    font-weight: 600;
    line-height: 1.35;
    margin: -0.125rem 0 0.125rem;
  }

  .CMuiMarkdown p {
    margin: 0;
  }

  .CMuiMarkdown code {
    font-size: 0.875rem;
  }

  &.CMuiMessage-withBottomMargin {
    margin: 0.25rem 0 0.75rem auto;
  }

  &:after {
    border-width: 0 0 0.5rem 0.5rem;
    border-color: transparent transparent transparent #e1ffc7;
    top: 0;
    right: -0.5rem;
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
  }

  &.CMuiMessage-in {
    margin: 0.25rem auto 0.25rem 0;
    background-color: #fff;
    border-radius: 0 0.25rem 0.25rem 0.25rem;

    .CMuiMessageContextual {
      background-color: #fff;
    }

    &:after {
      border-width: 0 0.5rem 0.5rem 0;
      border-color: transparent #fff transparent transparent;
      right: unset;
      left: -0.5rem;
    }
  }

  &.CMuiMessage-media {
    padding: 0.25rem 0.25rem;
    cursor: pointer;

    .CMuiMessageContextual {
      padding-left: 4rem;
      padding-bottom: 1rem;
      right: 0.125rem;

      background-color: rgba(0, 0, 0, 0.5);
      background: linear-gradient(
        15deg,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0) 50%,
        rgba(0, 0, 0, 0.35) 70%,
        rgba(0, 0, 0, 0.35)
      );

      .CMuiMessageContextualButton {
        color: #e0e0e0;
        margin: 0.125rem 0.25rem 0 0;
      }
    }

    .CMuiMessageMetadata {
      margin: -1rem 0.25rem -0.375rem 0.375rem;
      padding: 0 0.25rem;
      background-color: rgba(0, 0, 0, 0.5);
      color: #eee;
    }
  }

  &.CMui-over {
    .CMuiMessageContextual {
      opacity: 1;
      pointer-events: auto;
      right: 0.375rem;
    }
  }
`;

const StyledMessageTextWrapper = styled.div`
  position: relative;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  display: flex;
  flex-direction: row;
`;

const StyledMessageText = styled.span`
  font-family: "Open Sans Pro", Helvetica, Arial, sans-serif;
  font-size: 0.9375rem;
  color: #333;
`;

// const StyledMessageText = styled(Typography)`
//   font-size: 1rem;
//   line-height: 1.35;
// `;

const StyledMetadata = styled(Box)`
  position: relative;
  float: right;
  margin: -0.75rem -0.25rem -0.375rem 0.25rem;
  color: #666;
`;

const StyledInnerMetadata = styled(Box)`
  display: flex;
  flex-direction: row;
`;

const StyledContextualWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 0.25rem;
  right: 0.5rem;
  transition: all 0.1s ease-in-out;
  opacity: 0;
  pointer-events: none;
`;

const StyledContextualIconButton = styled(IconButton)`
  width: 1.25rem;
  height: 1.25rem;
`;

const StyledMenu = styled(Menu)`
  .MuiMenuItem-root {
    font-size: 0.9375rem;
  }
`;

const StyledMessageTime = styled(Typography)`
  font-size: 0.625rem;
`;

const StyledErrorDescription = styled(Box)`
  margin: 0.5rem auto;
  padding: 0.5rem 1rem;
  background-color: rgb(225, 245, 254);
  border-radius: 0.25rem;
  max-width: fit-content;
  position: relative;
  clear: both;
  text-align: center;

  .CMuiMarkdown p {
    margin: 0;
  }
`;

const StyledErrorDescriptionText = styled(Typography)`
  font-size: 0.875rem;
  line-height: 1.35;
`;

interface IConversationMessageProps {
  message: TMessage;
  seed?: string;
  currentTime?: Date;
}

const ConversationMessage: React.FC<IConversationMessageProps> = (props) => {
  const { message, seed } = props;
  const currentTime = props.currentTime || new Date();

  const [state, setState] = useState({
    anchorEl: null as null | HTMLElement,
    mouseOver: false,
    menuOpen: false,
  });

  // if (message.type === MESSAGE_TYPE.EVENT) {
  //   return null;
  // }

  const handleMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
    setState((prevState) => ({
      ...prevState,
      anchorEl: e.currentTarget,
      menuOpen: true,
    }));
  };

  const handleMenuClose = () => {
    setState((prevState) => ({
      ...prevState,
      menuOpen: false,
    }));
  };

  const handleMouseEnter = () => {
    setState((prevState) => ({
      ...prevState,
      mouseOver: true,
    }));
  };

  const handleMouseLeave = () => {
    setState((prevState) => ({
      ...prevState,
      mouseOver: false,
    }));
  };

  const handleDownload = (url: string) => {
    const a = document.createElement("a");
    a.href = url;
    a.download = url.split("/").pop() ?? "download";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    setState((prevState) => ({
      ...prevState,
      menuOpen: false,
    }));
  };

  const hoursAgo = moment(currentTime).diff(
    moment(message.created_at),
    "hours"
  );
  let separatorString =
    hoursAgo > 24
      ? "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
      : "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
  if (message.direction === MESSAGE_DIRECTION.SENT) {
    separatorString += "&nbsp;&nbsp;&nbsp;";
  }
  const messageMedia =
    !!message.content.image ||
    !!message.content.video ||
    !!message.content.file ||
    !!message.content.audio;
  const messageOptions = messageOptionsForMessage(message);

  return (
    <StyledMessageWrapper className="CMuiMessageWrapper">
      <StyledMessage
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={classNames({
          CMuiMessage: true,
          "CMuiMessage-in": message.direction === MESSAGE_DIRECTION.RECEIVED,
          "CMuiMessage-out": message.direction !== MESSAGE_DIRECTION.RECEIVED,
          "CMuiMessage-withAuthor": message.showAuthor,
          "CMuiMessage-withBottomMargin": message.marginBottom,
          "CMuiMessage-media": messageMedia,
          "CMui-over": state.mouseOver,
        })}
      >
        {messageOptions.length !== 0 && (
          <StyledContextualWrapper className="CMuiMessageContextual">
            <StyledContextualIconButton
              className="CMuiMessageContextualButton"
              onClick={handleMenuOpen}
            >
              <ExpandMoreIcon />
            </StyledContextualIconButton>
            <StyledMenu
              anchorEl={state.anchorEl}
              elevation={2}
              getContentAnchorEl={null}
              open={state.menuOpen}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              {messageOptions.map((option) => {
                if (option.type === "download_image") {
                  return (
                    <MenuItem
                      key={`${message.id}__ctxm__di`}
                      onClick={() => {
                        handleDownload(option.url);
                      }}
                    >
                      <FormattedMessage
                        id="Message.Descargar imagen"
                        defaultMessage="Descargar imagen"
                      />
                    </MenuItem>
                  );
                }

                if (option.type === "download_video") {
                  return (
                    <MenuItem
                      key={`${message.id}__ctxm__dv`}
                      onClick={() => {
                        handleDownload(option.url);
                      }}
                    >
                      <FormattedMessage
                        id="Message.Descargar vídeo"
                        defaultMessage="Descargar vídeo"
                      />
                    </MenuItem>
                  );
                }

                if (option.type === "download_file") {
                  return (
                    <MenuItem
                      key={`${message.id}__ctxm__df`}
                      onClick={() => {
                        handleDownload(option.url);
                      }}
                    >
                      <FormattedMessage
                        id="Message.Descargar archivo"
                        defaultMessage="Descargar archivo"
                      />
                    </MenuItem>
                  );
                }

                if (option.type === "download_audio") {
                  return (
                    <MenuItem
                      key={`${message.id}__ctxm__da`}
                      onClick={() => {
                        handleDownload(option.url);
                      }}
                    >
                      <FormattedMessage
                        id="Message.Descargar audio"
                        defaultMessage="Descargar audio"
                      />
                    </MenuItem>
                  );
                }
                return null;
              })}
            </StyledMenu>
          </StyledContextualWrapper>
        )}
        {message.direction !== MESSAGE_DIRECTION.RECEIVED &&
          !!message.author_full_name &&
          message.showAuthor && (
            <MessageAuthor author={message.author_full_name} seed={seed} />
          )}
        {!!messageMedia ? (
          <MessageMediaContent content={message.content as any} />
        ) : (
          <StyledMessageTextWrapper>
            <StyledMessageText>
              <ReactMarkdown
                className="CMuiMarkdown"
                components={{
                  em: ({ node, ...props }) => <b {...props} />,
                }}
              >
                {message.direction === MESSAGE_DIRECTION.RECEIVED
                  ? conversationMessageText(message) + separatorString
                  : removeFirstLineWithAuthor(
                      conversationMessageText(message) + separatorString
                    )}
              </ReactMarkdown>
            </StyledMessageText>
          </StyledMessageTextWrapper>
        )}
        <StyledMetadata className="CMuiMessageMetadata">
          <StyledInnerMetadata>
            {hoursAgo > 24 ? (
              <StyledMessageTime>
                {moment(message.created_at).format("DD/MM/YYYY - HH:mm")}
              </StyledMessageTime>
            ) : (
              <StyledMessageTime>
                {moment(message.created_at).format("HH:mm")}
              </StyledMessageTime>
            )}
            {message.status === "delivered" && (
              <MessageDoubleCheck isRead={false} />
            )}
            {message.status === "read" && <MessageDoubleCheck isRead={true} />}
            {message.status === "sent" && <MessageSingleCheck />}
            {message.status === "transmitted" && <MessageSingleCheck />}
            {message.status === "failed" && <MessageFailed />}
            {message.status === "rejected" && <MessageFailed />}
            {message.status === "pending" && <MessagePending />}
          </StyledInnerMetadata>
        </StyledMetadata>
        {message.status === "rejected" && message.error?.code === 302 && (
          <StyledErrorDescription>
            <StyledErrorDescriptionText>
              <FormattedMessage
                id="ConversationMessage.El contacto no está registrado en WhatsApp"
                defaultMessage="El contacto no está registrado en WhatsApp"
              />
            </StyledErrorDescriptionText>
          </StyledErrorDescription>
        )}
        {message.status === "failed" && message.error?.code === 470 && (
          <StyledErrorDescription>
            <StyledErrorDescriptionText>
              <FormattedMessage
                id="ConversationMessage.No se ha podido entregar el mensaje por estar fuera de la ventana temporal de envío (cód. 470)"
                defaultMessage="No se ha podido entregar el mensaje por estar fuera de la ventana temporal de envío (cód. 470)"
              />
            </StyledErrorDescriptionText>
          </StyledErrorDescription>
        )}
      </StyledMessage>
    </StyledMessageWrapper>
  );
};

export default ConversationMessage;
