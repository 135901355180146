import { IListConversationsParams } from "../../../../../apiCoreV3/conversation/apiListConversations";

export const conversationKeys = {
  all: ["conversations"],
  list: (params: IListConversationsParams) => {
    return [
      ...conversationKeys.all,
      "list",
      hashForListConversationsParams(params),
    ];
  },
  item: (conversationId: string) => {
    return [...conversationKeys.all, "item", conversationId];
  },
};

const hashForListConversationsParams = (params: IListConversationsParams) => {
  let hash = "";

  hash += params.page ?? "";
  hash += ":" + params.pageSize ?? "";
  hash += ":" + params.contactId ?? "";
  hash += ":" + params.interestedId ?? "";
  hash += ":" + params.unread ?? "";

  return hash;
};
