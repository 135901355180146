export const EMPLOYEE_WA_STATUS = {
  UNKNOWN: "unknown",
  ERROR_UNREGISTERED: "error_unregistered",
  SENT_DELIVERED: "sent_delivered",
  SENT_READ: "sent_read",
  SENT_AND_ANSWER: "sent_and_answer",
} as const;

export type TEmployeeWaStatus =
  typeof EMPLOYEE_WA_STATUS[keyof typeof EMPLOYEE_WA_STATUS];
