export const TIPO_INTERNA_ENTRE_SEMANA = "Interna entre semana";
export const TIPO_INTERNA_FIN_DE_SEMANA = "Interna fin de semana";
export const TIPO_EXTERNA_ENTRE_SEMANA = "Externa entre semana";
export const TIPO_EXTERNA_FIN_DE_SEMANA = "Externa fin de semana";
export const TIPO_EXTERNA = "Externa";

export const TIPO_TRATO_INTERNA_ENTRE_SEMANA = "Interna entre semana";
export const TIPO_TRATO_INTERNA_FIN_DE_SEMANA = "Interna fin de semana";
export const TIPO_TRATO_EXTERNA_MAS_20_HORAS =
  "Externa mas de 20 horas semanales";
export const TIPO_TRATO_EXTERNA_MENOS_20_HORAS =
  "Externa menos de 20 horas semanales";
export const TIPO_TRATO_INTERNA_ENTRE_SEMANA_SIN_SUST =
  "Interna entre semana (sin sustituciones)";
export const TIPO_TRATO_INTERNA_FIN_DE_SEMANA_SIN_SUST =
  "Interna fin de semana (sin sustituciones)";
export const TIPO_TRATO_EXTERNA_MAS_20_HORAS_SIN_SUST =
  "Externa mas de 20 horas semanales (sin sustituciones)";
export const TIPO_TRATO_EXTERNA_MENOS_20_HORAS_SIN_SUST =
  "Externa menos de 20 horas semanales (sin sustituciones)";
export const TIPO_TRATO_PROFESIONAL_POR_HORAS = "Profesional por Horas";

export const CONTRATO_ESTADO_ALTA_PENDIENTE = "Alta pendiente";
export const CONTRATO_ESTADO_EN_ESPERA = "En espera";
export const CONTRATO_ESTADO_PAGA_Y_RESERVA = "Paga y reserva";
export const CONTRATO_ESTADO_CV_CLIENTE = "CV cliente";
export const CONTRATO_ESTADO_PRESENCIAL_CLIENTE = "Presencial cliente";
export const CONTRATO_ESTADO_CANCELADO_PERDIDO_SELECCION =
  "Cancelado - perdido en selección";
export const CONTRATO_ESTADO_FIN_DE_CONTRATO = "Fin de contrato";
export const CONTRATO_ESTADO_SELECCIONADO = "Seleccionado";
export const CONTRATO_ESTADO_ACTIVO = "Activo";

export const CONTRACT_INDEED_ASK_FOR_PROMO = "Solicitar promoción";
export const CONTRACT_INDEED_NO_PROMO = "Sin Promoción";

export const CONTRACT_ORIGIN_WARRANTY_ZERO = "Garantia 0";
export const CONTRACT_ORIGIN_WARRANTY_NINETY = "Garantía 90";
export const CONTRACT_ORIGIN_SALE = "Venta";
export const CONTRACT_ORIGIN_FV = "FV";
