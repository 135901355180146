import { Button, ButtonGroup, CircularProgress } from "@material-ui/core";
import ListIcon from "@material-ui/icons/List";
import MapIcon from "@material-ui/icons/Map";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import CuideoSearchField from "../../../../components/CuideoFields/CuideoSearchField";
import cuideoTheme from "../../../../containers/themes/defaultTheme";
import {
  EMPLOYEE_VIDEOINTERVIEW_STATUS_COMPLETED,
  EMPLOYEE_VIDEOINTERVIEW_STATUS_FINALIST,
  EMPLOYEE_VIDEOINTERVIEW_STATUS_PENDING,
  EMPLOYEE_YEARS_OF_EXPERIENCE_LESS_1_YEAR,
  EMPLOYEE_YEARS_OF_EXPERIENCE_LESS_2_YEARS,
  EMPLOYEE_YEARS_OF_EXPERIENCE_LESS_3_YEARS,
  EMPLOYEE_YEARS_OF_EXPERIENCE_MORE_10_YEARS,
  EMPLOYEE_YEARS_OF_EXPERIENCE_MORE_3_YEARS,
  EMPLOYEE_YEARS_OF_EXPERIENCE_MORE_5_YEARS,
  EMPLOYEE_YEARS_OF_EXPERIENCE_NO_EXPERIENCE,
} from "../../../../util/defaults/candidato";
import { mostUsedCountriesOptionsEs } from "../../../../util/defaults/paises";
import {
  TIPO_EXTERNA_ENTRE_SEMANA,
  TIPO_EXTERNA_FIN_DE_SEMANA,
  TIPO_INTERNA_ENTRE_SEMANA,
  TIPO_INTERNA_FIN_DE_SEMANA,
} from "../../../../util/defaults/contrato";
import { pathologiesList } from "../../../../util/defaults/patologias";
import { tasksList } from "../../../../util/defaults/tareas";
import useDebounce from "../../../../util/useDebounce";
import { initialState } from "../../pages/ContractPage/useContractPageUrlParams";
import FilterButton from "../FilterButton";
import FiltersButton, { Filter } from "../FiltersButton/FiltersButton";
import { languagesList } from "../../../../util/defaults/idiomas";
import useFormatters from "../../../../components/FormattedFields/useFormatters";
import {
  EMPLOYEE_AVAILABILITY,
  EMPLOYEE_FELIZ_VITA_STATUS,
  EMPLOYEE_STATUS,
  EMPLOYEE_WORK_REGIME,
} from "../../../../api/employee/employee.types";
import { valuationStatusIsOutstanding, valuationStatusIsSearch } from "./ContractFiltersHelper";
import { CONTRACT_CANDIDATE_SORT_ORDER } from "../../../../api/contract/contract.types";

const StyledWrapper = styled.div`
  background-color: ${cuideoTheme.palette.common.white};
  padding: 0.5rem 0 0.5rem 0;
  border-bottom: 1px solid #dfdfdf;
`;

const StyledContainer = styled.div`
  margin: 0 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledSearchWrapper = styled.div`
  .Mui-CuideoSearch {
    height: 1.75rem;
    background-color: transparent;
    border: 1px solid #ccc;
  }
`;

const StyledButtonWrapper = styled.div`
  margin: 0 0 0 0.5rem;
  .MuiButtonBase-root {
    height: 1.75rem;
  }
`;

const StyledButtonGroup = styled(ButtonGroup)``;

const StyledButton = styled(Button)<{ active?: string }>`
  text-transform: none;
  font-weight: 400;
  height: 1.75rem;
  padding: 0 0.5rem;
  font-size: 0.9375rem;

  .MuiSvgIcon-root {
    font-size: 1.25rem;
    margin-right: 0.25rem;
  }
  ${({ active }) => {
    if (active) {
      return `
        font-weight: 600;
        background-color: #cdd5d8;
      `;
    }
  }}
`;

const StyledCircularProgress = styled(CircularProgress)`
  margin-left: 0.5rem;
`;

interface IContractFiltersProps {
  filters: any;
  setFilters: Function;
  showViewOptions?: boolean;
  showSourceOptions?: boolean;
  isLoading?: boolean;
}

const ContractFilters = (props: IContractFiltersProps) => {
  const {
    filters,
    setFilters,
    showViewOptions,
    showSourceOptions,
    isLoading,
  } = props;
  const [searchTerm, setSearchTerm] = useState(filters.search);
  const [isCandidateSearch, setIsCandidateSearch] = useState<boolean>(false);

  useEffect(() => {
    if (filters) {
      setIsCandidateSearch(valuationStatusIsSearch(filters) || valuationStatusIsOutstanding(filters));
    }
  }, [filters]);

  const { formatMessage } = useIntl();
  const { formatLanguage } = useFormatters();

  const handleApply = (filterName: string, value: any) => {
    const newFilters = { ...filters, [filterName]: value, page: 1 };
    if (!_.isEqual(filters, newFilters)) {
      setFilters(newFilters);
    }
  };

  const handleReset = (
    filterName: string,
    resetOptions = "" as string | string[]
  ) => {
    setFilters({ ...filters, [filterName]: resetOptions, page: 1 });
  };

  const handleSearchChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  useEffect(() => {
    const newFilters = { ...filters, search: debouncedSearchTerm };
    if (!_.isEqual(filters, newFilters)) {
      setFilters({ ...newFilters, page: 1 });
    }
  }, [debouncedSearchTerm, filters, setFilters]);

  const handleFiltersButtonChange = (filtersValues: any) => {
    const newFilters = {
      ...filters,
      titulacion: filtersValues.titulacion.isActive
        ? filtersValues.titulacion.value
        : "",
      vehiculo: filtersValues.vehiculo.isActive
        ? filtersValues.vehiculo.value
        : "",
      carnet: filtersValues.carnet.isActive ? filtersValues.carnet.value : "",
      sexo: filtersValues.sexo.isActive ? filtersValues.sexo.value : "",
      edad: filtersValues.edad.isActive ? filtersValues.edad.value : [],
      lugarDeNacimiento: filtersValues.lugarDeNacimiento.value
        ? filtersValues.lugarDeNacimiento.value
        : "",
      inOpenProcess: filtersValues.inOpenProcess.isActive
        ? filtersValues.inOpenProcess.value
        : "",
      botAvailable: filtersValues.botAvailable.isActive
        ? filtersValues.botAvailable.value
        : "",
      inRecentProcesses: filtersValues.inRecentProcesses.isActive
        ? filtersValues.inRecentProcesses.value
        : "",
      origen: filtersValues.origen.isActive ? filtersValues.origen.value : "",
      estado: filtersValues.estado.isActive ? filtersValues.estado.value : [],
      tipo: filtersValues.tipo.isActive ? filtersValues.tipo.value : [],
      tasks: filtersValues.tasks.isActive ? filtersValues.tasks.value : [],
      pathologies: filtersValues.pathologies.isActive
        ? filtersValues.pathologies.value
        : [],
      languages: filtersValues.languages.isActive
        ? filtersValues.languages.value
        : [],
      experience: filtersValues.experience.isActive
        ? filtersValues.experience.value
        : [],
      interviewStatus: filtersValues.interviewStatus.isActive
        ? filtersValues.interviewStatus.value
        : [],
      workRegime: filtersValues.workRegime.isActive
        ? filtersValues.workRegime.value
        : [],
      company: filtersValues.company.isActive
        ? filtersValues.company.value
        : "",
      availability: filtersValues.availability.isActive
        ? filtersValues.availability.value
        : [],
      page: 1,
    };
    if (!_.isEqual(filters, newFilters)) {
      setFilters(newFilters);
    }
  };

  const buttonFilters = [] as Filter[];
  buttonFilters.push({
    name: "titulacion",
    filterLabel: formatMessage({
      id: "ContractFilters.OfficialDegree",
      defaultMessage: "Titulación oficial",
    }),
    type: "select",
    options: [
      {
        value: "",
        label: formatMessage({
          id: "ContractFilters.ChooseAnOption",
          defaultMessage: "Selecciona una opción",
        }),
      },
      {
        value: "si",
        label: formatMessage({
          id: "ContractFilters.OfficialDegree.si",
          defaultMessage: "Con titulación oficial",
        }),
      },
      {
        value: "no",
        label: formatMessage({
          id: "ContractFilters.OfficialDegree.no",
          defaultMessage: "Sin titulación oficial",
        }),
      },
    ],
  });
  buttonFilters.push({
    name: "carnet",
    filterLabel: formatMessage({
      id: "ContractFilters.DrivingLicense",
      defaultMessage: "Carnet de conducir",
    }),
    type: "select",
    options: [
      {
        value: "",
        label: formatMessage({
          id: "ContractFilters.ChooseAnOption",
          defaultMessage: "Selecciona una opción",
        }),
      },
      {
        value: "SI",
        label: formatMessage({
          id: "ContractFilters.DrivingLicense.SI",
          defaultMessage: "Tiene carnet de conducir",
        }),
      },
      {
        value: "NO",
        label: formatMessage({
          id: "ContractFilters.DrivingLicense.NO",
          defaultMessage: "No tiene carnet de conducir",
        }),
      },
    ],
  });
  buttonFilters.push({
    name: "vehiculo",
    filterLabel: formatMessage({
      id: "ContractFilters.OwnsVehicle",
      defaultMessage: "Vehículo propio",
    }),
    type: "select",
    options: [
      {
        value: "",
        label: formatMessage({
          id: "ContractFilters.ChooseAnOption",
          defaultMessage: "Selecciona una opción",
        }),
      },
      {
        value: "Si",
        label: formatMessage({
          id: "ContractFilters.OwnsVehicle.Si",
          defaultMessage: "Tiene vehículo propio",
        }),
      },
      {
        value: "No",
        label: formatMessage({
          id: "ContractFilters.OwnsVehicle.No",
          defaultMessage: "No tiene vehículo propio",
        }),
      },
    ],
  });
  buttonFilters.push({
    name: "sexo",
    filterLabel: formatMessage({
      id: "ContractFilters.Sex",
      defaultMessage: "Sexo del Candidato",
    }),
    type: "select",
    options: [
      {
        value: "",
        label: formatMessage({
          id: "ContractFilters.ChooseAnOption",
          defaultMessage: "Selecciona una opción",
        }),
      },
      {
        value: "Mujer",
        label: formatMessage({
          id: "ContractFilters.Sex.Mujer",
          defaultMessage: "Mujer",
        }),
      },
      {
        value: "Hombre",
        label: formatMessage({
          id: "ContractFilters.Sex.Hombre",
          defaultMessage: "Hombre",
        }),
      },
    ],
  });
  buttonFilters.push({
    name: "edad",
    filterLabel: formatMessage({
      id: "ContractFilters.Age",
      defaultMessage: "Edad",
    }),
    type: "multi",
    options: [
      {
        value: "18-25",
        label: formatMessage({
          id: "ContractFilters.Age.18-25",
          defaultMessage: "18-25 años",
        }),
      },
      {
        value: "26-35",
        label: formatMessage({
          id: "ContractFilters.Age.26-35",
          defaultMessage: "26-35 años",
        }),
      },
      {
        value: "36-45",
        label: formatMessage({
          id: "ContractFilters.Age.36-45",
          defaultMessage: "36-45 años",
        }),
      },
      {
        value: "46-55",
        label: formatMessage({
          id: "ContractFilters.Age.46-55",
          defaultMessage: "46-55 años",
        }),
      },
      {
        value: "56-65",
        label: formatMessage({
          id: "ContractFilters.Age.56-65",
          defaultMessage: "56-65 años",
        }),
      },
      {
        value: "+65",
        label: formatMessage({
          id: "ContractFilters.Age.+65",
          defaultMessage: "+65 años",
        }),
      },
    ],
  });
  if (showSourceOptions) {
    buttonFilters.push({
      name: "origen",
      filterLabel: formatMessage({
        id: "ContractFilters.Source",
        defaultMessage: "Origen",
      }),
      type: "select",
      options: [
        {
          value: "",
          label: formatMessage({
            id: "ContractFilters.ChooseAnOption",
            defaultMessage: "Selecciona una opción",
          }),
        },
        {
          value: "web",
          label: formatMessage({
            id: "ContractFilters.Source.Jobsite",
            defaultMessage: "Portal de empleo",
          }),
        },
        {
          value: "cuideo",
          label: formatMessage({
            id: "ContractFilters.Source.Internal",
            defaultMessage: "Interno",
          }),
        },
      ],
    });
  }
  buttonFilters.push({
    name: "estado",
    filterLabel: formatMessage({
      id: "ContractFilters.EmployeeStatus",
      defaultMessage: "Estado del Candidato",
    }),
    type: "multi",
    options: [
      {
        value: EMPLOYEE_STATUS.NOT_VALIDATED,
        label: formatMessage({
          id: "ContractFilters.WithoutValidation",
          defaultMessage: "Sin Validar",
        }),
      },
      {
        value: EMPLOYEE_STATUS.VALIDATED,
        label: formatMessage({
          id: "ContractFilters.Validated",
          defaultMessage: "Validado",
        }),
      },
      {
        value: EMPLOYEE_STATUS.ACTIVE,
        label: formatMessage({
          id: "ContractFilters.Active",
          defaultMessage: "Activo",
        }),
      },
      {
        value: EMPLOYEE_STATUS.VALIDATED_PLUS,
        label: formatMessage({
          id: "ContractFilters.ValidatedPlus",
          defaultMessage: "Validado Plus",
        }),
      },
      {
        value: EMPLOYEE_STATUS.DISCARDED,
        label: formatMessage({
          id: "ContractFilters.Discarded",
          defaultMessage: "Descartado",
        }),
      },
    ],
  });
  buttonFilters.push({
    name: "felizVitaStatus",
    filterLabel: formatMessage({
      id: "ContractFilters.EmployeeFelizVitaStatus",
      defaultMessage: "Estado FV del Candidato",
    }),
    type: "multi",
    options: [
      {
        value: EMPLOYEE_FELIZ_VITA_STATUS.NOT_VALIDATED,
        label: formatMessage({
          id: "ContractFilters.WithoutValidation",
          defaultMessage: "Sin Validar",
        }),
      },
      {
        value: EMPLOYEE_FELIZ_VITA_STATUS.VALIDATED,
        label: formatMessage({
          id: "ContractFilters.Validated",
          defaultMessage: "Validado",
        }),
      },
      {
        value: EMPLOYEE_FELIZ_VITA_STATUS.ACTIVE,
        label: formatMessage({
          id: "ContractFilters.Active",
          defaultMessage: "Activo",
        }),
      },
      {
        value: EMPLOYEE_FELIZ_VITA_STATUS.VALIDATED_PLUS,
        label: formatMessage({
          id: "ContractFilters.ValidatedPlus",
          defaultMessage: "Validado Plus",
        }),
      },
      {
        value: EMPLOYEE_FELIZ_VITA_STATUS.DISCARDED,
        label: formatMessage({
          id: "ContractFilters.Discarded",
          defaultMessage: "Descartado",
        }),
      },
      {
        value: EMPLOYEE_FELIZ_VITA_STATUS.IN_PROCESS,
        label: formatMessage({
          id: "ContractFilters.InProcess",
          defaultMessage: "En Proceso",
        }),
      },
    ],
  });
  buttonFilters.push({
    name: "tipo",
    filterLabel: formatMessage({
      id: "ContractFilters.EmployeeAvailability",
      defaultMessage: "Disponibilidad del candidato",
    }),
    type: "multi",
    options: [
      {
        value: TIPO_INTERNA_ENTRE_SEMANA,
        label: formatMessage({
          id: "ContractFilters.Availability.InternalWeek",
          defaultMessage: "Interna entre semana",
        }),
      },
      {
        value: TIPO_INTERNA_FIN_DE_SEMANA,
        label: formatMessage({
          id: "ContractFilters.Availability.InternalWeekend",
          defaultMessage: "Interna fin de semana",
        }),
      },
      {
        value: TIPO_EXTERNA_ENTRE_SEMANA,
        label: formatMessage({
          id: "ContractFilters.Availability.ExternalWeek",
          defaultMessage: "Externa entre semana",
        }),
      },
      {
        value: TIPO_EXTERNA_FIN_DE_SEMANA,
        label: formatMessage({
          id: "ContractFilters.Availability.ExternalWeekend",
          defaultMessage: "Externa fin de semana",
        }),
      },
    ],
  });
  buttonFilters.push({
    name: "inOpenProcess",
    filterLabel: formatMessage({
      id: "EmployeesFilters.InOpenProcess",
      defaultMessage: "En procesos abiertos",
    }),
    type: "select",
    options: [
      {
        value: "",
        label: formatMessage({
          id: "EmployeesFilters.ChooseAnOption",
          defaultMessage: "Selecciona una opción",
        }),
      },
      {
        value: "yes",
        label: formatMessage({
          id: "EmployeesFilters.InOpenProcess.yes",
          defaultMessage: "En procesos abiertos",
        }),
      },
      {
        value: "no",
        label: formatMessage({
          id: "EmployeesFilters.InOpenProcess.no",
          defaultMessage: "Sin procesos abiertos",
        }),
      },
    ],
  });
  buttonFilters.push({
    name: "botAvailable",
    filterLabel: formatMessage({
      id: "ContractFilters.Bot disponible",
      defaultMessage: "Bot disponible",
    }),
    type: "select",
    options: [
      {
        value: "",
        label: formatMessage({
          id: "ContractFilters.ChooseAnOption",
          defaultMessage: "Selecciona una opción",
        }),
      },
      {
        value: "yes",
        label: formatMessage({
          id: "ContractFilters.BotAvailable.yes",
          defaultMessage: "Bot disponible",
        }),
      },
      {
        value: "no",
        label: formatMessage({
          id: "ContractFilters.BotAvailable.no",
          defaultMessage: "Bot no disponible",
        }),
      },
    ],
  });
  buttonFilters.push({
    name: "interviewStatus",
    filterLabel: formatMessage({
      id: "EmployeesFilters.InterviewStatus",
      defaultMessage: "Videoentrevista",
    }),
    type: "multi",
    options: [
      {
        value: EMPLOYEE_VIDEOINTERVIEW_STATUS_PENDING,
        label: formatMessage({
          id: "ContractFilters.InterviewStatus.Pending",
          defaultMessage: "Pendiente",
        }),
      },
      {
        value: EMPLOYEE_VIDEOINTERVIEW_STATUS_COMPLETED,
        label: formatMessage({
          id: "ContractFilters.InterviewStatus.Completed",
          defaultMessage: "Completada",
        }),
      },
      {
        value: EMPLOYEE_VIDEOINTERVIEW_STATUS_FINALIST,
        label: formatMessage({
          id: "ContractFilters.InterviewStatus.Finalist",
          defaultMessage: "Finalista",
        }),
      },
    ],
  });
  buttonFilters.push({
    name: "pathologies",
    filterLabel: formatMessage({
      id: "ContractFilters.Pathologies",
      defaultMessage: "Patologías",
    }),
    type: "multi",
    options: pathologiesList().map((pathology) => {
      return {
        value: pathology.value,
        label: pathology.label,
      };
    }),
  });
  buttonFilters.push({
    name: "tasks",
    filterLabel: formatMessage({
      id: "ContractFilters.Tasks",
      defaultMessage: "Tareas",
    }),
    type: "multi",
    options: tasksList().map((task) => {
      return {
        value: task.value,
        label: task.label,
      };
    }),
  });
  buttonFilters.push({
    name: "languages",
    filterLabel: formatMessage({
      id: "ContractFilters.Languages",
      defaultMessage: "Idiomas",
    }),
    type: "multi",
    options: languagesList().map((language) => {
      return {
        value: language.value,
        label: formatLanguage(language.value),
      };
    }),
  });
  buttonFilters.push({
    name: "experience",
    filterLabel: formatMessage({
      id: "ContractFilters.ExperienceYears",
      defaultMessage: "Años de experiencia",
    }),
    type: "multi",
    options: [
      {
        value: EMPLOYEE_YEARS_OF_EXPERIENCE_NO_EXPERIENCE,
        label: formatMessage({
          id: `filterExp.NoExperience`,
          defaultMessage: "Sin experiencia",
        }),
      },
      {
        value: EMPLOYEE_YEARS_OF_EXPERIENCE_LESS_1_YEAR,
        label: formatMessage({
          id: `filterExp.Less1Year`,
          defaultMessage: "Menos de 1 año",
        }),
      },
      {
        value: EMPLOYEE_YEARS_OF_EXPERIENCE_LESS_2_YEARS,
        label: formatMessage({
          id: `filterExp.Less2Years`,
          defaultMessage: "Menos de 2 años",
        }),
      },
      {
        value: EMPLOYEE_YEARS_OF_EXPERIENCE_LESS_3_YEARS,
        label: formatMessage({
          id: `filterExp.Less3Years`,
          defaultMessage: "Menos de 3 años",
        }),
      },
      {
        value: EMPLOYEE_YEARS_OF_EXPERIENCE_MORE_3_YEARS,
        label: formatMessage({
          id: `filterExp.More3Years`,
          defaultMessage: "Más de 3 años",
        }),
      },
      {
        value: EMPLOYEE_YEARS_OF_EXPERIENCE_MORE_5_YEARS,
        label: formatMessage({
          id: `filterExp.More5Years`,
          defaultMessage: "Más de 5 años",
        }),
      },
      {
        value: EMPLOYEE_YEARS_OF_EXPERIENCE_MORE_10_YEARS,
        label: formatMessage({
          id: `filterExp.More10Years`,
          defaultMessage: "Más de 10 años",
        }),
      },
    ],
  });
  buttonFilters.push({
    name: "workRegime",
    filterLabel: formatMessage({
      id: "ContractFilters.Régimen de trabajo",
      defaultMessage: "Régimen de trabajo",
    }),
    type: "multi",
    options: [
      {
        value: EMPLOYEE_WORK_REGIME.REEH,
        label: formatMessage({
          id: "ContractFilters.REEH",
          defaultMessage: "REEH",
        }),
      },
      {
        value: EMPLOYEE_WORK_REGIME.SAD,
        label: formatMessage({
          id: "ContractFilters.SAD",
          defaultMessage: "SAD",
        }),
      },
    ],
  });
  buttonFilters.push({
    name: "lugarDeNacimiento",
    filterLabel: formatMessage({
      id: "ContractFilters.Birthplace",
      defaultMessage: "Lugar de nacimiento",
    }),
    type: "select",
    options: [
      {
        value: "",
        label: formatMessage({
          id: "ContractFilters.ChooseAnOption",
          defaultMessage: "Selecciona una opción",
        }),
      },
      ...mostUsedCountriesOptionsEs.map((country) => {
        return {
          value: country.value,
          label: country.label,
        };
      }),
    ],
  });
  buttonFilters.push({
    name: "company",
    filterLabel: formatMessage({
      id: "ContractFilters.Empresa",
      defaultMessage: "Empresa",
    }),
    type: "select",
    options: [
      {
        value: "",
        label: formatMessage({
          id: "ContractFilters.ChooseAnOption",
          defaultMessage: "Selecciona una opción",
        }),
      },
      {
        value: "Cuideo",
        label: formatMessage({
          id: "ContractFilters.Company.Cuideo",
          defaultMessage: "Cuideo",
        }),
      },
      {
        value: "FV",
        label: formatMessage({
          id: "ContractFilters.Company.FelizVita",
          defaultMessage: "FelizVita",
        }),
      },
    ],
  });
  buttonFilters.push({
    name: "availability",
    filterLabel: formatMessage({
      id: "ContractFilters.WorkAvailability",
      defaultMessage: "Disponibilidad",
    }),
    type: "multi",
    options: [
      {
        value: EMPLOYEE_AVAILABILITY.AVAILABLE,
        label: formatMessage({
          id: "ContractFilters.WorkAvailability.Available",
          defaultMessage: "Disponible",
        }),
      },
      {
        value: EMPLOYEE_AVAILABILITY.WORKING,
        label: formatMessage({
          id: "ContractFilters.WorkAvailability.Working",
          defaultMessage: "Trabajando",
        }),
      },
    ],
  });

  return (
    <StyledWrapper>
      <StyledContainer>
        <StyledLeft>
          <StyledSearchWrapper>
            <CuideoSearchField
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder={formatMessage({
                id: "ContractFilters.Search",
                defaultMessage: "Buscar por nombre, DNI o código",
              })}
            />
          </StyledSearchWrapper>
          <StyledButtonWrapper>
            <FiltersButton
              filters={buttonFilters}
              filtersValues={{
                titulacion: {
                  value: filters.titulacion,
                },
                carnet: {
                  value: filters.carnet,
                },
                vehiculo: {
                  value: filters.vehiculo,
                },
                sexo: {
                  value: filters.sexo,
                },
                edad: {
                  value: filters.edad,
                },
                origen: {
                  value: filters.origen,
                },
                interviewStatus: {
                  value: filters.interviewStatus,
                },
                tipo: {
                  value: filters.tipo,
                },
                inOpenProcess: {
                  value: filters.inOpenProcess,
                },
                inRecentProcesses: {
                  value: filters.inRecentProcesses,
                },
                company: {
                  value: filters.company,
                },
                botAvailable: {
                  value: filters.botAvailable,
                },
                estado: {
                  value: filters.estado,
                },
                felizVitaStatus: {
                  value: filters.felizVitaStatus,
                },
                experience: {
                  value: filters.experience,
                },
                pathologies: {
                  value: filters.pathologies,
                },
                tasks: {
                  value: filters.tasks,
                },
                languages: {
                  value: filters.languages,
                },
                lugarDeNacimiento: {
                  value: filters.lugarDeNacimiento,
                },
                workRegime: {
                  value: filters.workRegime,
                },
                availability: {
                  value: filters.availability,
                },
              }}
              resetOptions={{
                titulacion: {
                  value: initialState.titulacion,
                },
                carnet: {
                  value: initialState.carnet,
                },
                vehiculo: {
                  value: initialState.vehiculo,
                },
                sexo: {
                  value: initialState.sexo,
                },
                edad: {
                  value: initialState.edad,
                },
                origen: {
                  value: initialState.origen,
                },
                interviewStatus: {
                  value: initialState.interviewStatus,
                },
                inOpenProcess: {
                  value: initialState.inOpenProcess,
                },
                inRecentProcesses: {
                  value: initialState.inRecentProcesses,
                },
                company: {
                  value: initialState.company,
                },
                botAvailable: {
                  value: initialState.botAvailable,
                },
                tipo: {
                  value: initialState.tipo,
                },
                estado: {
                  value: initialState.estado,
                },
                felizVitaStatus: {
                  value: initialState.felizVitaStatus,
                },
                experience: {
                  value: initialState.experience,
                },
                pathologies: {
                  value: initialState.pathologies,
                },
                tasks: {
                  value: initialState.tasks,
                },
                languages: {
                  value: initialState.languages,
                },
                lugarDeNacimiento: {
                  value: initialState.lugarDeNacimiento,
                },
                workRegime: {
                  value: initialState.workRegime,
                },
                availability: {
                  value: initialState.availability,
                },
              }}
              onFiltersChange={handleFiltersButtonChange}
            />
          </StyledButtonWrapper>
          <StyledButtonWrapper>
            <FilterButton
              defaultButtonText={
                <FormattedMessage
                  id="ContractFilters.Sort"
                  defaultMessage="Ordenar"
                />
              }
              options={isCandidateSearch
                ? [
                  {
                    value: CONTRACT_CANDIDATE_SORT_ORDER.LAST_CONTRACT_END_DATE,
                    label: formatMessage({
                      id: "ContractFilters.Sort.LastContractEndDate",
                      defaultMessage: "Fin útimo contrato",
                    }),
                  },
                  {
                    value: "divider",
                  },
                  {
                    value: CONTRACT_CANDIDATE_SORT_ORDER.APPROPIATED,
                    label: formatMessage({
                      id: "ContractFilters.Sort.Appropiated",
                      defaultMessage: "Más apropiadas",
                    }),
                  }
                ]
                : [
                  {
                    value: CONTRACT_CANDIDATE_SORT_ORDER.APPROPIATED,
                    label: formatMessage({
                      id: "ContractFilters.Sort.Appropiated",
                      defaultMessage: "Más apropiadas",
                    }),
                  },
                  {
                    value: "divider",
                  },
                  {
                    value: CONTRACT_CANDIDATE_SORT_ORDER.MORE_RECENT,
                    label: formatMessage({
                      id: "ContractFilters.Sort.MoreRecent",
                      defaultMessage: "Más recientes",
                    }),
                  },
                  {
                    value: CONTRACT_CANDIDATE_SORT_ORDER.LESS_RECENT,
                    label: formatMessage({
                      id: "ContractFilters.Sort.LessRecent",
                      defaultMessage: "Menos recientes",
                    }),
                  },
                ]
              }
              initialValue={filters.orden}
              handleApply={handleApply}
              handleReset={handleReset}
              filterName="orden"
              inUse={filters.orden !== ""}
              resetOptions={isCandidateSearch
                ? CONTRACT_CANDIDATE_SORT_ORDER.LAST_CONTRACT_END_DATE
                : CONTRACT_CANDIDATE_SORT_ORDER.APPROPIATED
              }
            />
          </StyledButtonWrapper>
          {isLoading && <StyledCircularProgress size="1.5rem" />}
        </StyledLeft>
        <StyledRight>
          {showViewOptions && (
            <StyledButtonGroup>
              <StyledButton
                color="primary"
                active={filters.vista === "mapa" ? "active" : undefined}
                onClick={() => {
                  if (filters.vista !== "mapa") {
                    const newFilters = { ...filters, vista: "mapa" };
                    if (!_.isEqual(filters, newFilters)) {
                      setFilters(newFilters);
                    }
                  }
                }}
              >
                <MapIcon />
                <FormattedMessage
                  id="ContractFilters.Map"
                  defaultMessage="Mapa"
                />
              </StyledButton>
              <StyledButton
                color="primary"
                active={filters.vista === "lista" ? "active" : undefined}
                onClick={() => {
                  if (filters.vista !== "lista") {
                    const newFilters = { ...filters, vista: "lista" };
                    if (!_.isEqual(filters, newFilters)) {
                      setFilters(newFilters);
                    }
                  }
                }}
              >
                <ListIcon />
                <FormattedMessage
                  id="ContractFilters.List"
                  defaultMessage="Lista"
                />
              </StyledButton>
            </StyledButtonGroup>
          )}
        </StyledRight>
      </StyledContainer>
    </StyledWrapper>
  );
};

export default React.memo(
  (props: IContractFiltersProps) => {
    return <ContractFilters {...props} />;
  },
  (prevProps, nextProps) => {
    if (
      prevProps.filters !== nextProps.filters ||
      prevProps.setFilters !== nextProps.setFilters ||
      prevProps.isLoading !== nextProps.isLoading ||
      prevProps.showSourceOptions !== nextProps.showSourceOptions ||
      prevProps.showViewOptions !== nextProps.showViewOptions
    ) {
      return false;
    }
    return true;
  }
);

// export default ContractFilters;
