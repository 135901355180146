import { Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import ClockSvg from "../../../../../../../../assets/images/clock.svg";
import {
  COLOR_ERROR_ALERT_BG,
  COLOR_ERROR_ALERT_FG,
  COLOR_WARNING_ALERT_BG,
  COLOR_WARNING_ALERT_FG,
} from "../../../../../../../../containers/themes/defaultTheme";

const POSTPONED_COLOR_BG = COLOR_WARNING_ALERT_BG;
const POSTPONED_COLOR_FG = COLOR_WARNING_ALERT_FG;
const POSTPONED_EXPIRED_COLOR_BG = COLOR_ERROR_ALERT_BG;
const POSTPONED_EXPIRED_COLOR_FG = COLOR_ERROR_ALERT_FG;

const StyledChip = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  color: #fff;
`;

const StyledIcon = styled.img`
  margin-right: 0.5rem;

  width: 1.5rem;
  height: 1.5rem;
`;

const StyledLabelWrapper = styled.div``;

const StyledLabel = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25;
`;

const StyledReason = styled(Typography)`
  font-size: 0.8275rem;
  font-weight: 600;
  line-height: 1.25;
`;

interface IServicePostponedChip {
  delayedDate: string;
  reason: string;
}

const ServicePostponedChip = (props: IServicePostponedChip) => {
  const { delayedDate, reason } = props;

  const formattedTime = moment(delayedDate).format("DD/MM/YYYY");

  const delayedDateMoment = delayedDate ? moment(delayedDate) : null;

  const now = moment();
  const outDatedDelay =
    delayedDateMoment && delayedDateMoment.isBefore(now) ? true : false;

  return (
    <StyledChip
      style={{
        backgroundColor: outDatedDelay
          ? POSTPONED_EXPIRED_COLOR_BG
          : POSTPONED_COLOR_BG,
        color: outDatedDelay ? POSTPONED_EXPIRED_COLOR_FG : POSTPONED_COLOR_FG,
      }}
    >
      <StyledIcon src={ClockSvg} />
      <StyledLabelWrapper>
        <StyledLabel>
          <FormattedMessage
            id="ServicePostponedChip.Pospuesto hasta {date}"
            defaultMessage="Pospuesto hasta {date}"
            values={{
              date: formattedTime,
            }}
          />
        </StyledLabel>
        <StyledReason>{reason}</StyledReason>
      </StyledLabelWrapper>
    </StyledChip>
  );
};

export default ServicePostponedChip;
