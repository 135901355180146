import _ from "lodash";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";
import CuideoSearchField from "../../../../../../../../components/CuideoFields/CuideoSearchField";
import cuideoTheme from "../../../../../../../../containers/themes/defaultTheme";
import {
  CONTRACT_ORIGIN_SALE,
  CONTRACT_ORIGIN_WARRANTY_NINETY,
  CONTRACT_ORIGIN_WARRANTY_ZERO,
  CONTRATO_ESTADO_ACTIVO,
  CONTRATO_ESTADO_ALTA_PENDIENTE,
  CONTRATO_ESTADO_CANCELADO_PERDIDO_SELECCION,
  CONTRATO_ESTADO_CV_CLIENTE,
  CONTRATO_ESTADO_EN_ESPERA,
  CONTRATO_ESTADO_FIN_DE_CONTRATO,
  CONTRATO_ESTADO_PAGA_Y_RESERVA,
  CONTRATO_ESTADO_PRESENCIAL_CLIENTE,
  CONTRATO_ESTADO_SELECCIONADO,
  TIPO_TRATO_EXTERNA_MAS_20_HORAS,
  TIPO_TRATO_EXTERNA_MENOS_20_HORAS,
  TIPO_TRATO_INTERNA_ENTRE_SEMANA,
  TIPO_TRATO_INTERNA_FIN_DE_SEMANA,
} from "../../../../../../../../util/defaults/contrato";
import useDebounce from "../../../../../../../../util/useDebounce";
import FilterButton from "../../../../../../components/FilterButton";
import FiltersButton, {
  Filter,
} from "../../../../../../components/FiltersButton/FiltersButton";
import { initialState, resetState } from "../../hooks/useContractsPageURLParams";
import { USER_ROLES } from "../../../../../../../../api/security";
import { TStaffUserResponse } from "../../../../../../../../api/user/apiListStaffUsers";
import { Button } from "@material-ui/core";
import { isReplacementContractActive } from "./ContractsFiltersHelper";

const StyledWrapper = styled.div`
  background-color: ${cuideoTheme.palette.common.white};
  padding: 0.5rem 0 0.5rem 4rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  box-shadow: 0 2px 4px 0 rgba(0, 72, 120, 0.25);
`;

const StyledContainer = styled.div`
  margin: 0 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledButtonWrapper = styled.div`
  margin: 0 0 0 0.5rem;
  .MuiButtonBase-root {
    line-height: 1;
  }
`;

export const StyledButton = styled(Button)<{ active?: string }>`
  text-transform: none;
  font-weight: 400;
  height: 2rem;
  padding: 0 0.875rem;
  font-size: 0.9375rem;
  line-height: 1;
  border: 1px solid rgba(0, 75, 123, 0.5);

  &:hover {
    border: 1px solid #004b7b;
  }

  ${({ active }) => {
    if (active) {
      return `
        font-weight: 600;
        background-color: #cdd5d8;

        &:hover {
          background-color: #cdd5d8;
        }
      `;
    }
  }}
`;

const StyledLeft = styled.div``;

const StyledRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface IContractsFiltersProps {
  contractsFilters: any;
  contractsSetFilters: Function;
}

const ContractsFilters = (props: IContractsFiltersProps) => {
  const { contractsFilters, contractsSetFilters } = props;
  const [searchTerm, setSearchTerm] = useState(contractsFilters.search);
  const [staff, setStaff] = useState<any>([]);
  const staffState = useSelector((state: any) => state.usuariosState.staff);
  const provinciasOptions = useSelector(
    (state: any) => state.commonData.provinciasOptions
  );

  const { formatMessage } = useIntl();

  const [isReplacementContractFilterActive, setIsReplacementContractActive] =
    useState<boolean>(false);

  useEffect(() => {
    if (contractsFilters) {
      setIsReplacementContractActive(isReplacementContractActive(contractsFilters));
    }
  }, [contractsFilters]);

  const handleReplacementContractFilterClick = () => {
    if (!isReplacementContractFilterActive) {
      contractsSetFilters({
        ...contractsFilters,
        origen: [CONTRACT_ORIGIN_WARRANTY_ZERO]
      });
    } else {
      contractsSetFilters({
        ...contractsFilters,
        origen: initialState.origen
      });
    }
  }

  const handleApply = (filterName: string, value: any) => {
    const newFilters = { ...contractsFilters, [filterName]: value, page: 1 };
    if (!_.isEqual(contractsFilters, newFilters)) {
      contractsSetFilters(newFilters);
    }
  };

  const handleReset = (filterName: string) => {
    const newFilters = { ...contractsFilters, [filterName]: "", page: 1 };
    if (!_.isEqual(contractsFilters, newFilters)) {
      contractsSetFilters(newFilters);
    }
  };

  const handleSearchChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const newStaffOptions = staffState
      .filter(
        (s: TStaffUserResponse) =>
          s.numOwnedContracts > 0 ||
          s.numOwnedServices > 0 ||
          s.roles.indexOf(USER_ROLES.ROLE_ES_RECRUITER) !== -1 ||
          s.roles.indexOf(USER_ROLES.ROLE_ES_PROMOTER) !== -1
      )
      .map((s: TStaffUserResponse) => {
        return {
          value: s.id,
          label: s.fullname,
        };
      });
    const sortedStaffOptions = newStaffOptions.sort(
      (
        a: { value: string; label: string },
        b: { value: string; label: string }
      ) => {
        if (a.label < b.label) {
          return -1;
        }
        return 1;
      }
    );
    setStaff(sortedStaffOptions);
  }, [staffState, setStaff]);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    const newFilters = { ...contractsFilters, search: debouncedSearchTerm };
    if (!_.isEqual(contractsFilters, newFilters)) {
      contractsSetFilters({ ...newFilters, page: 1 });
    }
  }, [contractsFilters, contractsSetFilters, debouncedSearchTerm]);

  const handleFiltersButtonChange = (filtersValues: any) => {
    const newFilters = {
      ...contractsFilters,
      origen: filtersValues.origen.isActive ? filtersValues.origen.value : [],
      publicar: filtersValues.publicar.isActive
        ? filtersValues.publicar.value
        : "",
      estado: filtersValues.estado.isActive ? filtersValues.estado.value : [],
      botLaunched: filtersValues.botLaunched.isActive
        ? filtersValues.botLaunched.value
        : [],
      tipoDeTrato: filtersValues.tipoDeTrato.isActive
        ? filtersValues.tipoDeTrato.value
        : [],
      provinciaOferta: filtersValues.provinciaOferta.value
        ? filtersValues.provinciaOferta.value
        : "",
      owner: filtersValues.owner.isActive ? filtersValues.owner.value : [],
      "service.owner": filtersValues["service.owner"].isActive
        ? filtersValues["service.owner"].value
        : [],
      page: 1,
    };
    if (!_.isEqual(contractsFilters, newFilters)) {
      contractsSetFilters(newFilters);
    }
  };

  const staffOptions = staff;

  const filters = [] as Filter[];
  filters.push({
    name: "origen",
    filterLabel: formatMessage({
      id: "ContractsFilters.Source",
      defaultMessage: "Origen",
    }),
    type: "multi",
    options: [
      {
        value: CONTRACT_ORIGIN_SALE,
        label: formatMessage({
          id: "ContractFilters.Source.Venta",
          defaultMessage: "Nuevos servicios",
        }),
      },
      {
        value: CONTRACT_ORIGIN_WARRANTY_ZERO,
        label: formatMessage({
          id: "ContractFilters.Source.Garantia 0",
          defaultMessage: "Servicio garantía 0",
        }),
      },
      {
        value: CONTRACT_ORIGIN_WARRANTY_NINETY,
        label: formatMessage({
          id: "ContractFilters.Source.Garantia 90",
          defaultMessage: "Servicio garantía 90",
        }),
      },
    ],
  });
  filters.push({
    name: "publicar",
    filterLabel: "Oferta publicada",
    type: "select",
    options: [
      {
        value: "",
        label: formatMessage({
          id: "ContractsFilters.ChooseOne",
          defaultMessage: "Selecciona una",
        }),
      },
      {
        value: "Si",
        label: formatMessage({
          id: "ContractsFilters.Published.Yes",
          defaultMessage: "Publicadas",
        }),
      },
      {
        value: "No",
        label: formatMessage({
          id: "ContractsFilters.Published.No",
          defaultMessage: "Despublicadas",
        }),
      },
      {
        value: "null",
        label: formatMessage({
          id: "ContractsFilters.Published.Null",
          defaultMessage: "Sin Publicar",
        }),
      },
    ],
  });
  filters.push({
    name: "estado",
    filterLabel: "Estado del Contrato",
    type: "multi",
    options: [
      {
        value: CONTRATO_ESTADO_ALTA_PENDIENTE,
        label: formatMessage({
          id: `ContractsFilters.Status.PendingSignUp`,
          defaultMessage: "Alta pendiente",
        }),
      },
      {
        value: CONTRATO_ESTADO_CV_CLIENTE,
        label: formatMessage({
          id: `ContractsFilters.Status.CVClient`,
          defaultMessage: "CV Cliente",
        }),
      },
      {
        value: CONTRATO_ESTADO_PRESENCIAL_CLIENTE,
        label: formatMessage({
          id: `ContractsFilters.Status.FaceToFaceClient`,
          defaultMessage: "Presencial cliente",
        }),
      },
      {
        value: CONTRATO_ESTADO_EN_ESPERA,
        label: formatMessage({
          id: `ContractsFilters.Status.Awaiting`,
          defaultMessage: "En espera",
        }),
      },
      {
        value: CONTRATO_ESTADO_PAGA_Y_RESERVA,
        label: formatMessage({
          id: `ContractsFilters.Status.PayAndReserve`,
          defaultMessage: "Paga y reserva",
        }),
      },
      {
        value: CONTRATO_ESTADO_SELECCIONADO,
        label: formatMessage({
          id: `ContractsFilters.Status.Selected`,
          defaultMessage: "Seleccionado",
        }),
      },
      {
        value: CONTRATO_ESTADO_ACTIVO,
        label: formatMessage({
          id: `ContractsFilters.Status.Active`,
          defaultMessage: "Active",
        }),
      },
      {
        value: CONTRATO_ESTADO_FIN_DE_CONTRATO,
        label: formatMessage({
          id: `ContractsFilters.Status.Ended`,
          defaultMessage: "Fin de contrato",
        }),
      },
      {
        value: CONTRATO_ESTADO_CANCELADO_PERDIDO_SELECCION,
        label: formatMessage({
          id: `ContractsFilters.Status.CancelledSelection`,
          defaultMessage: "Cancelado - perdido selección",
        }),
      },
    ],
  });
  filters.push({
    name: "tipoDeTrato",
    filterLabel: "Tipo de Trato",
    type: "multi",
    options: [
      {
        value: TIPO_TRATO_INTERNA_ENTRE_SEMANA,
        label: formatMessage({
          id: `ContractsFilters.DealType.InternalWeek`,
          defaultMessage: "Interna entre semana",
        }),
      },
      {
        value: TIPO_TRATO_INTERNA_FIN_DE_SEMANA,
        label: formatMessage({
          id: `ContractsFilters.DealType.InternalWeekend`,
          defaultMessage: "Interna fin de semana",
        }),
      },
      {
        value: TIPO_TRATO_EXTERNA_MAS_20_HORAS,
        label: formatMessage({
          id: `ContractsFilters.DealType.ExternalMore20Hours`,
          defaultMessage: "Externa más de 20 horas",
        }),
      },
      {
        value: TIPO_TRATO_EXTERNA_MENOS_20_HORAS,
        label: formatMessage({
          id: `ContractsFilters.DealType.ExternalLess20Hours`,
          defaultMessage: "Externa menos de 20 horas",
        }),
      },
    ],
  });
  filters.push({
    name: "botLaunched",
    filterLabel: formatMessage({
      id: "ContractsFilters.Bot usado",
      defaultMessage: "Bot usado",
    }),
    type: "select",
    options: [
      {
        value: "",
        label: formatMessage({
          id: "ContractsFilters.ChooseAnOption",
          defaultMessage: "Selecciona una opción",
        }),
      },
      {
        value: "yes",
        label: formatMessage({
          id: "ContractsFilters.BotUsed.Con el bot usado",
          defaultMessage: "Con el bot usado",
        }),
      },
      {
        value: "no",
        label: formatMessage({
          id: "ContractsFilters.BotUsed.Sin usar el Bot",
          defaultMessage: "Sin usar el Bot",
        }),
      },
    ],
  });
  filters.push({
    name: "provinciaOferta",
    filterLabel: "Provincia",
    type: "select",
    options: [
      { value: "", label: "Selecciona provincia" },
      ...provinciasOptions,
    ],
  });

  filters.push({
    name: "owner",
    filterLabel: "Propietario contrato",
    type: "multi",
    options: [...staffOptions],
  });

  filters.push({
    name: "service.owner",
    filterLabel: "Propietario servicio",
    type: "multi",
    options: [...staffOptions],
  });

  return (
    <StyledWrapper>
      <StyledContainer>
        <StyledLeft>
          <CuideoSearchField
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder={formatMessage({
              id: "ContractsFilters.Search",
              defaultMessage: "Buscar por id o nombre",
            })}
          />
        </StyledLeft>
        <StyledRight>
          <StyledButtonWrapper>
            <FiltersButton
              filters={filters}
              filtersValues={{
                origen: {
                  value: contractsFilters.origen,
                },
                publicar: {
                  value: contractsFilters.publicar,
                },
                estado: {
                  value: contractsFilters.estado,
                },
                tipoDeTrato: {
                  value: contractsFilters.tipoDeTrato,
                },
                botLaunched: {
                  value: contractsFilters.botLaunched,
                },
                provinciaOferta: {
                  value: contractsFilters.provinciaOferta,
                },
                owner: {
                  value: contractsFilters.owner,
                },
                "service.owner": {
                  value: contractsFilters["service.owner"],
                },
              }}
              onFiltersChange={handleFiltersButtonChange}
              resetOptions={{
                origen: { value: resetState.origen },
                publicar: { value: resetState.publicar },
                estado: { value: resetState.estado },
                tipoDeTrato: { value: resetState.tipoDeTrato },
                botLaunched: { value: resetState.botLaunched },
                provinciaOferta: { value: resetState.provinciaOferta },
                owner: { value: resetState.owner },
                "service.owner": { value: resetState["service.owner"] },
              }}
            />
          </StyledButtonWrapper>
          <StyledButtonWrapper>
            <FilterButton
              defaultButtonText={
                <FormattedMessage
                  id="ContractsFilters.Sort"
                  defaultMessage="Ordenar"
                />
              }
              options={[
                {
                  value: "cvDate",
                  label: formatMessage({
                    id: "ContractsFilters.cvDate",
                    defaultMessage: "Fecha CV",
                  }),
                },
                {
                  value: "divider",
                },
                {
                  value: "priority",
                  label: formatMessage({
                    id: "ContractsFilters.Prioritarias",
                    defaultMessage: "Prioritarias",
                  }),
                },
                {
                  value: "newest",
                  label: formatMessage({
                    id: "ContractsFilters.Más recientes",
                    defaultMessage: "Más recientes",
                  }),
                },
                {
                  value: "oldest",
                  label: formatMessage({
                    id: "ContractsFilters.Menos recientes",
                    defaultMessage: "Menos recientes",
                  }),
                },
              ]}
              initialValue={contractsFilters.orden}
              handleApply={handleApply}
              handleReset={handleReset}
              filterName="orden"
              inUse={contractsFilters.orden !== ""}
              resetOptions={"cvDate"}
            />
          </StyledButtonWrapper>
          <StyledButtonWrapper>
            <StyledButton
              color="primary"
              active={isReplacementContractFilterActive ? "active" : undefined}
              onClick={() => handleReplacementContractFilterClick()}
            >
              Contratos de sustitución
            </StyledButton>
          </StyledButtonWrapper>
        </StyledRight>
      </StyledContainer>
    </StyledWrapper>
  );
};

export default React.memo(
  (props: IContractsFiltersProps) => {
    return <ContractsFilters {...props} />;
  },
  (prevProps, nextProps) => {
    if (
      prevProps.contractsFilters !== nextProps.contractsFilters ||
      prevProps.contractsSetFilters !== nextProps.contractsSetFilters
    ) {
      return false;
    }
    return true;
  }
);
