import { useMutation } from "react-query";
import { TStaffUserResponse } from "../../../../../api/user/apiListStaffUsers";
import {
  apiUpdateStaffUser,
  TUpdateStaffUserRequest,
} from "../../../../../api/user/apiUpdateStaffUser";
import { MutationConfig, queryClient } from "../../../../../lib/react-query";
import { userKeys } from "../util/user.query-keys";

type TUpdateUserPayload = TUpdateStaffUserRequest & {
  userId: string;
};

export const updateUser = ({ userId, ...payload }: TUpdateUserPayload) => {
  return apiUpdateStaffUser(userId, payload);
};

type TUseUpdateUser = {
  config?: MutationConfig<typeof updateUser>;
};

export const useUpdateUser = ({ config }: TUseUpdateUser = {}) => {
  return useMutation({
    onMutate: async (payload: TUpdateUserPayload) => {
      await queryClient.cancelQueries(userKeys.all);

      const previousData: TStaffUserResponse | undefined =
        queryClient.getQueryData(userKeys.item(payload.userId));

      if (!previousData) {
        return undefined;
      }

      queryClient.setQueryData(userKeys.item(payload.userId), {
        ...previousData,
        enabled: payload.enabled,
      });

      return () => {
        queryClient.setQueryData(userKeys.item(payload.userId), previousData);
      };
    },
    onError: (err, payload, rollback: any) => {
      rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries(userKeys.all);
    },
    ...config,
    mutationFn: updateUser,
  });
};
