import { Box, Grid, Typography } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { motion, Variants } from "framer-motion";
import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  getCandidato,
  getCandidatos,
  getCandidatoSuccess,
  modalShow,
  setBackground,
} from "../../../actions";
import PerfilCard from "../../../components/Candidato/PerfilCard";
import cuideoTheme from "../../../containers/themes/defaultTheme";
import { apiEmpleo } from "../../../util/ApiEmpleo";
import IntlMessages from "../../../util/IntlMessages";
import useHeader from "../../../util/useHeader";
import useWindowWidth from "../../../util/useWindowWidth";
import AvailabilityCard from "../components/AvailabilityCard";
import InterestDataCard from "../components/InterestDataCard";
import PersonalInformationCard from "../components/PersonalInformationCard";

const StyledPageContainer = styled.div<{ appMarginTop: number }>`
  ${({ appMarginTop }) => {
    return `
    margin: ${appMarginTop}rem auto 0;
  `;
  }}

  @media all and (max-width: ${cuideoTheme.breakpoints.values.sm}px) {
    padding: 1rem;
    background-color: ${cuideoTheme.palette.common.white};
  }

  @media all and (min-width: ${cuideoTheme.breakpoints.values
      .md}px) and (max-width: ${cuideoTheme.breakpoints.values.lg}px) {
    margin: ${({ appMarginTop }) => appMarginTop + 3}rem 1rem 1rem;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.lg}px) {
    margin: ${({ appMarginTop }) => appMarginTop + 3}rem 0 1rem;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const StyledLink = styled(Link)`
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  text-decoration: none;
  color: ${cuideoTheme.palette.primary.main};
  opacity: 0.5;
`;

const StyledArrowBackIosIcon = styled(ArrowBackIosIcon)`
  font-size: 0.8rem;
`;

const cardVariants: Variants = {
  entry: {
    opacity: 1,
    y: 0,
    transition: { delay: 0.25 },
  },
  change: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: 15,
  },
  exitChange: {
    opacity: 1,
    y: 0,
  },
};

const linkVariants: Variants = {
  entry: {
    opacity: 1,
    x: 0,
    transition: { delay: 0.25 },
  },
  change: {
    opacity: 1,
    x: 0,
  },
  exit: {
    opacity: 0,
    x: 25,
  },
};

const StyledPageTitle = styled(Typography)`
  font-weight: 700;
  color: ${cuideoTheme.palette.primary.main};

  @media all and (max-width: ${cuideoTheme.breakpoints.values.sm}px) {
    font-size: 1.375rem;
    margin-bottom: 0.625rem;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.sm}px) {
    font-size: 1.625rem;
    margin-bottom: 1.4rem;
  }
`;

const groupVariants: Variants = {
  enter: {
    opacity: 1,
    transition: {
      staggerChildren: 0.15,
      delay: 0.1,
    },
  },
  exit: {
    opacity: 0,
  },
};

const itemVariants: Variants = {
  enter: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.25 },
  },
  exit: {
    opacity: 0,
    y: 15,
    transition: { duration: 0.1 },
  },
};

interface Props {
  candidatos: any;
  match: any;
  getCandidatos: Function;
  getCandidato: Function;
  getCandidatoSuccess: Function;
  router: any;
  history: any;
  appMarginTop: number;
  setBackground: Function;
  prevLocation: any;
  authUser: any;
  modalShow: Function;
}

const PaginaMiPerfil = (props: Props) => {
  const {
    candidatos,
    match,
    appMarginTop,
    setBackground,
    modalShow,
    getCandidatoSuccess,
    getCandidato,
  } = props;

  const width = useWindowWidth();
  useHeader({
    type: "normal",
    showHelpLink: true,
  });

  const candidato = candidatos.find(
    (item: any) => item.id === match.params.candidatoId
  );

  useEffect(() => {
    if (!candidato) {
      // Load and set state synchronously in order to capture 404s
      const getCandidatoRequest = async (id: string) => {
        let url = "candidatos/" + id;
        try {
          let res = await apiEmpleo.get(url);
          getCandidatoSuccess({ partialCandidatos: [res] });
        } catch (e: any) {
          if (e.response && e.response.status === 404) {
            modalShow({
              open: true,
              type: "error",
              message: "Parece que el candidato que buscas no existe",
            });
          } else {
            modalShow({
              open: true,
              type: "error",
              message: "Ha habido un error inesperado",
            });
          }
        }
      };
      getCandidatoRequest(match.params.candidatoId);
    } else {
      getCandidato(match.params.candidatoId);
    }
    setBackground("cuideo");
    // eslint-disable-next-line
  }, []);

  // This page only reachable if ROLE_STAFF

  return (
    <StyledPageContainer appMarginTop={appMarginTop}>
      {width >= 960 && (
        <Fragment>
          <Grid container>
            <Grid item xs={12}></Grid>
          </Grid>
          <Box mb={8}>
            <motion.div initial="exit" animate="entry" variants={linkVariants}>
              <StyledLink
                to="/empleo/candidatos"
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  window.history.back();
                }}
              >
                <StyledArrowBackIosIcon />
                <IntlMessages id="Volver" />
              </StyledLink>
            </motion.div>
          </Box>
        </Fragment>
      )}
      <Grid container spacing={8}>
        <Grid item xs={12} md={10} lg={9}>
          <StyledPageTitle>
            {candidato && candidato.Sexo === "Hombre" ? (
              <IntlMessages id="Perfil de candidato" />
            ) : (
              <IntlMessages id="Perfil de candidata" />
            )}
          </StyledPageTitle>
          <motion.div animate="entry" variants={cardVariants}>
            {candidato && (
              <Fragment>
                <motion.div
                  initial="exit"
                  animate="enter"
                  variants={groupVariants}
                >
                  <motion.div variants={itemVariants}>
                    <PerfilCard candidato={candidato} />
                  </motion.div>
                  <motion.div variants={itemVariants}>
                    <PersonalInformationCard candidato={candidato} />
                  </motion.div>
                  <motion.div variants={itemVariants}>
                    <InterestDataCard candidato={candidato} />
                  </motion.div>
                  <motion.div variants={itemVariants}>
                    <AvailabilityCard candidato={candidato} />
                  </motion.div>
                </motion.div>
              </Fragment>
            )}
          </motion.div>
        </Grid>
      </Grid>
    </StyledPageContainer>
  );
};

const mapStateToProps = ({
  candidatosState,
  layout,
  router,
  commonData,
  auth,
}: {
  candidatosState: { candidatos: any };
  layout: { appMarginTop: number; width: number };
  router: any;
  commonData: any;
  auth: { authUser: any };
}) => {
  const { candidatos } = candidatosState;
  const { prevLocation } = commonData;
  const { appMarginTop } = layout;
  const { authUser } = auth;

  return { candidatos, router, appMarginTop, prevLocation, authUser };
};

export default connect(mapStateToProps, {
  getCandidatos,
  getCandidato,
  getCandidatoSuccess,
  setBackground,
  modalShow,
})(PaginaMiPerfil);
