import {
  CircularProgress,
  Dialog,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import cuideoTheme from "../../../../../../containers/themes/defaultTheme";
import { TContractFull } from "../../types/contract.types";
import ContractEditInnerModal from "./ContractEditInnerModal";

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    min-width: 36rem;
    min-height: 20rem;
  }
`;

const StyledHeader = styled.div`
  background-color: #cdd5d8;
  color: ${cuideoTheme.palette.primary.main};
  padding: 0.75rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledTitle = styled(Typography)`
  font-weight: 700;
  font-size: 1.25rem;
`;

const StyledIconButton = styled(IconButton)``;

const StyledLoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 18rem;
`;

interface IContractEditModalProps {
  status: "loading" | "idle" | "success" | "error";
  contract?: TContractFull;
  open: boolean;
  onClose: () => void;
  onChange: (payload: any) => void;
}

const ContractEditModal: React.FC<IContractEditModalProps> = (props) => {
  const { status, contract, open, onClose, onChange } = props;

  return (
    <StyledDialog open={open} onClose={onClose}>
      <StyledHeader>
        <StyledTitle color="primary">
          <FormattedMessage
            id="ContractEditModal.Editar oferta"
            defaultMessage="Editar oferta"
          />
        </StyledTitle>
        <StyledIconButton color="primary" onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>
      </StyledHeader>
      {status === "loading" ? (
        <StyledLoadingWrapper>
          <CircularProgress thickness={5} size={24} />
        </StyledLoadingWrapper>
      ) : (
        !!contract && (
          <ContractEditInnerModal
            contract={contract}
            onClose={onClose}
            onChange={onChange}
          />
        )
      )}
    </StyledDialog>
  );
};

export default ContractEditModal;
