import React, { useState, useEffect } from "react";
import styled from "styled-components";
import cuideoTheme from "../../../../containers/themes/defaultTheme";
import {
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { apiEmpleo } from "../../../../util/ApiEmpleo";
import moment from "moment";
import "moment/locale/es";
import Pagination from "@material-ui/lab/Pagination";
import ContratoTooltip from "../../../../components/Contrato/ContratoTooltip";
import {
  CONTRATO_ESTADO_EN_ESPERA,
  CONTRATO_ESTADO_ALTA_PENDIENTE,
  CONTRATO_ESTADO_CV_CLIENTE,
  CONTRATO_ESTADO_PRESENCIAL_CLIENTE,
  CONTRATO_ESTADO_PAGA_Y_RESERVA,
} from "../../../../util/defaults/contrato";
import { FormattedMessage } from "react-intl";
import HelperDialog from "../../../../components/HelperDialog/HelperDialog";
import InscriptionsTabHelp from "./InscriptionsTabHelp";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import FormattedValuationStatus from "../../../../components/Valoraciones/FormattedValuationStatus";
import FormattedContractStatus from "../../../../components/Contrato/FormattedContractStatus";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import {
  VALORACION_CONTRATADO,
  VALORACION_CVCLIENTE,
  VALORACION_PRESENCIAL,
  VALORACION_PRESENCIAL_CLIENTE,
  VALORACION_TELEFONICA,
} from "../../../../util/defaults/valoracion";

const StyledWrapper = styled.div`
  margin: 1rem;
  max-width: 58.75rem;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2rem 0 1rem;
  justify-content: space-between;
`;

const StyledActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledTitle = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-size: 1.875rem;
  font-weight: 700;
`;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  background-color: #fff;
  padding: 0.125rem;
  border-radius: 0.75rem;

  .MuiToggleButton-root {
    color: ${cuideoTheme.palette.primary.main};
    border-radius: 50%;
    border: none;
    box-shadow: none;
    font-size: 0.5rem;
    padding: 0.125rem;

    .MuiSvgIcon-root {
      width: 1rem;
      height: 1rem;
    }

    &:last-child {
      margin-left: 0.125rem;
    }

    &.Mui-selected {
      background-color: ${cuideoTheme.palette.primary.main};
      color: #fff;
    }
  }
`;

const StyledContent = styled.div``;

const StyledTable = styled(Table)``;

const StyledTableHead = styled(TableHead)``;

const StyledTableRow = styled(TableRow)``;

const StyledBodyRow = styled(TableRow)`
  background-color: #fff;
`;

const StyledHeadCell = styled(TableCell)`
  background-color: rgba(0, 72, 120, 0.1);
  color: ${cuideoTheme.palette.primary.light};
  font-size: 0.75rem;
  line-height: normal;
  font-weight: 700;
`;

const StyledBodyCell = styled(TableCell)`
  font-size: 0.875rem;
  color: ${cuideoTheme.palette.primary.main};
`;

const StyledTableBody = styled(TableBody)``;

const StyledPagination = styled(Pagination)`
  margin: 1rem 0 0;
  .MuiPagination-ul {
    justify-content: center;
  }
`;

const StyledHelpIconButton = styled(IconButton)`
  .MuiSvgIcon-root {
    width: 1.125rem;
    height: 1.125rem;
  }
`;

const StyledCenteredText = styled(Typography)`
  text-align: center;
  font-size: 0.875rem;
`;

const InscripcionesTab = (props: any) => {
  const { candidato } = props;
  const [valoraciones, setValoraciones] = useState([] as any);
  const [totalItems, setTotalItems] = useState(0);
  const [filter, setFilter] = useState("all" as "all" | "advanced");
  const [page, setPage] = useState(1);
  const [helpOpen, setHelpOpen] = useState(false);

  const getContratos = async (inputParams: {
    page: number;
    filter: "all" | "advanced";
  }) => {
    const params = {
      page,
      itemsPerPage: 10,
      "contrato.estado": [
        CONTRATO_ESTADO_ALTA_PENDIENTE,
        CONTRATO_ESTADO_EN_ESPERA,
        CONTRATO_ESTADO_PAGA_Y_RESERVA,
        CONTRATO_ESTADO_CV_CLIENTE,
        CONTRATO_ESTADO_PRESENCIAL_CLIENTE,
      ],
      estadoEnProceso:
        filter === "advanced"
          ? [
              VALORACION_TELEFONICA,
              VALORACION_PRESENCIAL,
              VALORACION_CVCLIENTE,
              VALORACION_PRESENCIAL_CLIENTE,
              VALORACION_CONTRATADO,
            ]
          : undefined,
      "order[statusUpdatedAt]": "desc",
    };
    let res = await apiEmpleo.get(`candidatos/${candidato.id}/valoraciones`, {
      params,
    });
    setValoraciones(res["hydra:member"]);
    setTotalItems(res["hydra:totalItems"]);
  };

  useEffect(() => {
    getContratos({ page, filter });
    // eslint-disable-next-line
  }, [page, candidato, filter]);

  const handlePageChange = (event: any, value: any) => setPage(value);

  const handleHelpOpen = () => {
    setHelpOpen(true);
  };

  const handleHelpClose = () => {
    setHelpOpen(false);
  };

  const handleToggleChange = (
    event: React.MouseEvent<HTMLElement>,
    newFilter: "all" | "advanced" | null
  ) => {
    if (null !== newFilter) {
      setFilter(newFilter);
    }
  };

  return (
    <StyledWrapper>
      <StyledHeader>
        <StyledTitle>
          <FormattedMessage
            id="InscriptionsTab.Title"
            defaultMessage="Ofertas Inscritas"
          />
        </StyledTitle>
        <StyledActionsWrapper>
          <StyledToggleButtonGroup
            size="small"
            value={filter}
            exclusive
            onChange={handleToggleChange}
          >
            <Tooltip
              title={
                <FormattedMessage
                  id="InscriptionsTab.Ver todas las inscripciones"
                  defaultMessage="Ver todas las inscripciones"
                />
              }
            >
              <ToggleButton value="all" selected={filter === "all"}>
                <AllInclusiveIcon fontSize="small" />
              </ToggleButton>
            </Tooltip>
            <Tooltip
              title={
                <FormattedMessage
                  id="InscriptionsTab.Ver sólo inscripciones avanzadas"
                  defaultMessage="Ver sólo inscripciones avanzadas"
                />
              }
            >
              <ToggleButton value="advanced" selected={filter === "advanced"}>
                <TrendingUpIcon fontSize="small" />
              </ToggleButton>
            </Tooltip>
          </StyledToggleButtonGroup>
          <StyledHelpIconButton color="primary" onClick={handleHelpOpen}>
            <HelpOutlineIcon />
          </StyledHelpIconButton>
        </StyledActionsWrapper>
      </StyledHeader>
      <StyledContent>
        <StyledTable>
          <StyledTableHead>
            <StyledTableRow>
              <StyledHeadCell>
                <FormattedMessage
                  id="InscriptionsTab.Actualización de la valoración"
                  defaultMessage="Actualización de la valoración"
                />
              </StyledHeadCell>
              <StyledHeadCell>
                <FormattedMessage
                  id="InscriptionsTab.Contract"
                  defaultMessage="Contrato"
                />
              </StyledHeadCell>
              <StyledHeadCell>
                <FormattedMessage
                  id="InscriptionsTab.ContractType"
                  defaultMessage="Tipo de trato"
                />
              </StyledHeadCell>
              <StyledHeadCell>
                <FormattedMessage
                  id="InscriptionsTab.Customer"
                  defaultMessage="Cliente"
                />
              </StyledHeadCell>
              <StyledHeadCell>
                <FormattedMessage
                  id="InscriptionsTab.Status"
                  defaultMessage="Estado candidatura"
                />
              </StyledHeadCell>
              <StyledHeadCell>
                <FormattedMessage
                  id="InscriptionsTab.ContractStatus"
                  defaultMessage="Estado contrato"
                />
              </StyledHeadCell>
              <StyledHeadCell>
                <FormattedMessage
                  id="InscriptionsTab.Owner"
                  defaultMessage="Owner"
                />
              </StyledHeadCell>
            </StyledTableRow>
          </StyledTableHead>
          <StyledTableBody>
            {totalItems === 0 && (
              <StyledBodyRow>
                <StyledBodyCell colSpan={7}>
                  <StyledCenteredText>
                    <FormattedMessage
                      id="InscriptionsTab.No hay ofertas inscritas"
                      defaultMessage="No hay ofertas inscritas"
                    />
                  </StyledCenteredText>
                </StyledBodyCell>
              </StyledBodyRow>
            )}
            {valoraciones.map((valoracion: any) => {
              return (
                <StyledBodyRow key={valoracion.id}>
                  <StyledBodyCell>
                    {moment(valoracion.statusUpdatedAt).format(
                      "DD/MM/YYYY HH:mm"
                    )}
                  </StyledBodyCell>
                  <StyledBodyCell>
                    {valoracion.contrato ? (
                      <ContratoTooltip contrato={valoracion.contrato} />
                    ) : (
                      "-"
                    )}
                  </StyledBodyCell>
                  <StyledBodyCell>
                    {valoracion.contrato && valoracion.contrato.Tipo_de_Trato
                      ? valoracion.contrato.Tipo_de_Trato
                      : "-"}
                  </StyledBodyCell>
                  <StyledBodyCell>
                    {valoracion.contrato &&
                    valoracion.contrato._Nombre_del_cliente
                      ? valoracion.contrato._Nombre_del_cliente
                      : "-"}
                  </StyledBodyCell>
                  <StyledBodyCell>
                    {valoracion.Estado_en_proceso && (
                      <FormattedValuationStatus
                        status={valoracion.Estado_en_proceso}
                      />
                    )}
                  </StyledBodyCell>
                  <StyledBodyCell>
                    {valoracion.contrato && valoracion.contrato.Estado ? (
                      <FormattedContractStatus
                        status={valoracion.contrato.Estado}
                      />
                    ) : (
                      "-"
                    )}
                  </StyledBodyCell>
                  <StyledBodyCell>
                    {valoracion.contrato && valoracion.contrato.ownerName
                      ? valoracion.contrato.ownerName
                      : "-"}
                  </StyledBodyCell>
                </StyledBodyRow>
              );
            })}
          </StyledTableBody>
        </StyledTable>
        {totalItems > 0 && (
          <StyledPagination
            count={Math.ceil(totalItems / 10)}
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        )}
      </StyledContent>
      <HelperDialog
        open={helpOpen}
        onClose={handleHelpClose}
        text={<InscriptionsTabHelp />}
        title={
          <FormattedMessage id="ContractsTab.Help" defaultMessage="Ayuda" />
        }
        okLabel={
          <FormattedMessage id="ContractsTab.Accept" defaultMessage="Aceptar" />
        }
      />
    </StyledWrapper>
  );
};

export default InscripcionesTab;
