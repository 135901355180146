import React from "react";
import { FormattedMessage } from "react-intl";
import {
  SUGGESTION_TYPE_AFFINITY,
  SUGGESTION_TYPE_VALIDATED_PLUS,
  SUGGESTION_TYPE_WAITING_ROOM,
  TSuggestionType,
} from "../../../../../../types/SuggestionType";

interface ISuggestionTypeProps {
  type: TSuggestionType;
}

const SuggestionType = (props: ISuggestionTypeProps) => {
  const { type } = props;

  if (type === SUGGESTION_TYPE_VALIDATED_PLUS) {
    return (
      <FormattedMessage
        id="ValuationCard.Suggestion.validated_plus"
        defaultMessage="Sugerido/a Plus"
      />
    );
  }
  if (type === SUGGESTION_TYPE_WAITING_ROOM) {
    return (
      <FormattedMessage
        id="ValuationCard.Suggestion.waiting_room"
        defaultMessage="Sugerida/a Sala Espera"
      />
    );
  }
  if (type === SUGGESTION_TYPE_AFFINITY) {
    return (
      <FormattedMessage
        id="ValuationCard.Suggestion.affinity"
        defaultMessage="Sugerido/a por Afinidad"
      />
    );
  }

  return null;
};

export default SuggestionType;
