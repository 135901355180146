import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Auth from "./Auth";
import Common from "./Common";
import Settings from "./Settings";
import Layout from "./Layout";
import Empleo from "./Empleo";
import Candidatos from "./Candidatos";
import Actividades from "./Actividades";
import Statistics from "./Statistics";
import Valoraciones from "./Valoraciones";
import Usuarios from "./Usuarios";
import Registro from "./Registro";
import Modal from "./Modal";
import Filters from "./Filters";
import ClientEvent from "./ClientEvent";

const rootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    commonData: Common,
    layout: Layout,
    empleo: Empleo,
    candidatosState: Candidatos,
    actividadesState: Actividades,
    valoracionesState: Valoraciones,
    usuariosState: Usuarios,
    statistics: Statistics,
    registroState: Registro,
    modal: Modal,
    filters: Filters,
    clientEvent: ClientEvent,
  });

export default rootReducer;
