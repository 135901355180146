import { Typography } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { connect, FieldValidator, FormikContextType, getIn } from "formik";
import React from "react";
import styled from "styled-components";
import cuideoTheme from "../../containers/themes/defaultTheme";
import { StyledError } from "./StyledComponents";

const StyledField = styled("div")<{
  fullWidth: boolean;
  margin: string;
  size: string;
}>`
  ${({ fullWidth }) => fullWidth && `width: 100%;`}
  padding-top: 0;
  padding-bottom: 0;
  ${({ margin }) => {
    if (margin === "normal") {
      return `
      margin: 1rem 0;
      `;
    }
    if (margin === "normalX") {
      return `
      margin: 0 1rem;
      `;
    }
    if (margin === "nomargin") {
      return ``;
    }
  }}

  .CMuiFormHelper-error {
    margin: 0.25rem;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const StyledLabel = styled(Typography)<{ error?: string; size?: string }>`
  white-space: wrap;
  color: ${({ error }) =>
    error ? cuideoTheme.palette.error.main : cuideoTheme.palette.primary.light};

  ${({ size }) => {
    if (size === "medium") {
      return `
        font-size: 1rem;
        padding: 0.2rem 0 0.2rem 0;
      `;
    }
    if (size === "small") {
      return `
        font-size: 0.8rem;
        padding: 0.2rem 0 0.2rem 0;

        @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
          font-size: 0.8rem;
        }
      `;
    }
  }}
`;

const StyledKeyboardDatePicker = styled(KeyboardDatePicker)<{
  size: string;
  error: boolean;
  disabled: boolean;
}>`
  .MuiInputBase-root {
    font-weight: 600;
    background-color: ${({ error, disabled }) =>
      error
        ? "#fef5f7"
        : disabled
        ? "#ededed"
        : cuideoTheme.palette.common.white};
    border-radius: ${cuideoTheme.shape.borderRadius}px;
    color: ${cuideoTheme.palette.primary.main};

    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
  }
  .MuiSvgIcon-root {
    color: ${cuideoTheme.palette.primary.main};
  }

  .MuiOutlinedInput-notchedOutline {
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  }

  .MuiPickersClockNumber-clockNumber {
    left: calc(50% - 9px);
  }

  ${({ size }) => {
    if (size === "small") {
      return `
        .MuiInputBase-root {
          height: 2.375rem;
        }
        .MuiSvgIcon-root {
          width: 1.25rem;
          height: 1.25rem;
        }
        .MuiFormHelperText-contained {
          color: red;
        }
      `;
    }
  }}
`;

export interface FormikDatePickerProps<Values = {}> {
  name: string;
  // name: string;
  size?: "medium" | "small";
  disabled?: boolean;
  margin?: "nomargin" | "normal" | "normalX";
  // variant?: "standard" | "outlined" | "filled";
  label?: React.ReactNode;
  // helperText?: any;
  fullWidth?: boolean;
  // placeholder?: string;
  required?: boolean;
  // endAdornment?: any;
  // forceComponentUpdate?: boolean;
  onChange?: Function;
  onBlur?: Function;
  // onKeyDown?: Function;
  // autoComplete?: any;
  // role?: string;
  validate?: FieldValidator;
  // disabled?: boolean;
  formik: FormikContextType<Values>;
  disableToolbar?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
}

interface State {}

class FormikDatePicker extends React.Component<FormikDatePickerProps, State> {
  shouldComponentUpdate(nextProps: any, nextState: any) {
    if (nextProps.forceComponentUpdate) {
      return true;
    }
    const next = nextProps.formik;
    const cur = this.props.formik;
    const name = this.props.name;
    if (
      getIn(next.values, name) !== getIn(cur.values, name) ||
      getIn(next.errors, name) !== getIn(cur.errors, name) ||
      getIn(next.touched, name) !== getIn(cur.touched, name)
    ) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    this.props.formik.registerField(this.props.name, {
      validate: this.props.validate,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.name !== prevProps.name) {
      this.props.formik.unregisterField(prevProps.name);
      this.props.formik.registerField(this.props.name, {
        validate: this.props.validate,
      });
    }

    if (this.props.validate !== prevProps.validate) {
      this.props.formik.registerField(this.props.name, {
        validate: this.props.validate,
      });
    }
  }

  componentWillUnmount() {
    this.props.formik.unregisterField(this.props.name);
  }

  handleChange(e: any) {
    if (this.props.onChange) {
      this.props.onChange(e);
    }

    // this.props.formik.handleChange(e);
    this.props.formik.setFieldValue(this.props.name, e);
  }

  handleBlur(e: any) {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }

    this.props.formik.setFieldTouched(this.props.name, true, false);
  }

  render() {
    const {
      name,
      label,
      margin,
      size,
      required,
      disabled,
      fullWidth = false,
      disableToolbar = false,
      disablePast = false,
      disableFuture = false,
    } = this.props;
    const formik = this.props.formik;

    const field = {
      value: getIn(formik.values, name) ?? null,
      name,
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
    };
    field.onChange = this.handleChange.bind(this);
    field.onBlur = this.handleBlur.bind(this);

    const error = getIn(formik.errors, name);
    const touched = !!getIn(formik.touched, name);

    return (
      <StyledField
        fullWidth={fullWidth}
        margin={margin || "normal"}
        size={size || "small"}
      >
        {label && (
          <StyledLabel
            error={touched && !!error ? "error" : undefined}
            size={size || "small"}
          >
            {label} {required && " *"}
          </StyledLabel>
        )}
        <StyledKeyboardDatePicker
          autoOk
          fullWidth={fullWidth}
          inputVariant="outlined"
          variant="inline"
          format="dd/MM/yyyy"
          margin="none"
          value={field.value}
          onChange={this.handleChange.bind(this)}
          onBlur={this.handleBlur.bind(this)}
          size={size || "small"}
          disabled={disabled ?? false}
          error={touched && !!error}
          invalidDateMessage=""
          disableToolbar={disableToolbar}
          disablePast={disablePast}
          disableFuture={disableFuture}
        />
        {touched && error && (
          <StyledError size={size || "medium"} className="CMuiFormHelper-error">
            {error.value ? error.value : error}
          </StyledError>
        )}
      </StyledField>
    );
  }
}

export default connect<Omit<FormikDatePickerProps, "formik">, State>(
  FormikDatePicker as any
);
