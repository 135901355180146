import {
  GET_APPLIED_OFFERS,
  GET_OFFER,
  GET_OFFERS,
  GET_OFFERS_SUCCESS,
  GET_OFFER_SUCCESS,
  HIDE_WELCOME_POPUP,
  RESET_OFFERS,
  SET_NUM_OFERTAS_VISIBLES_HOME,
  SET_OFERTAS_FILTERS,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
} from "../constants/ActionTypes";
import { evaluateOffer } from "./EvaluateOffer";

const initialEmpleo = {
  ofertas: [],
  candidatos: [],
  ofertasLoadingState: "unloaded",
  filtrosBusqueda: {
    provincia: null,
    ciudades: [] as string[],
    disponibilidad: [] as string[],
  },
  numOfertasVisiblesHome: 10,
  appliedOffers: [],
  appliedOffersLoaded: false,
  hideWelcomePopup: false,
};

let filtros = sessionStorage.getItem("filtrosBusqueda");
if (filtros) {
  initialEmpleo.filtrosBusqueda = JSON.parse(filtros);
}
let hidePopup = sessionStorage.getItem("hideWelcomePopup");
if (hidePopup) {
  initialEmpleo.hideWelcomePopup = JSON.parse(hidePopup);
}

interface EmpleoActionTypes {
  type: string;
  payload: any;
}

const empleo = (state = initialEmpleo, action: EmpleoActionTypes) => {
  switch (action.type) {
    case GET_OFFERS:
      return {
        ...state,
        ofertasLoadingState: "loading",
      };
    case GET_OFFER:
      return state;
    case GET_OFFERS_SUCCESS:
      const newOffers = action.payload.offers;
      let offers: any = [...state.ofertas];
      newOffers.forEach((newOffer: any) => {
        let o: any = state.ofertas.find((o) => (o as any).id === newOffer.id);
        if (!o) {
          offers.push(
            Object.assign({}, evaluateOffer(newOffer, action.payload.user))
          );
        }
      });
      return {
        ...state,
        ofertas: offers,
        ofertasLoadingState: offers.length ? "loaded" : "empty",
      };
    case RESET_OFFERS:
      return {
        ...state,
        ofertas: [],
        ofertasLoadingState: "unloaded",
      };
    case GET_OFFER_SUCCESS:
      // En este caso tenemos más datos y sobreescribimos
      const newOffer = action.payload.offer;
      let oldOffers: any = [...state.ofertas];
      const newOffersArray: any = oldOffers.filter((offer: any) => {
        return offer.id !== newOffer.id;
      });
      newOffer.fullLoaded = true;
      newOffersArray.push(evaluateOffer(newOffer, action.payload.user));
      return {
        ...state,
        ofertas: newOffersArray,
      };
    case GET_APPLIED_OFFERS:
      return {
        ...state,
        appliedOffers: action.payload,
        appliedOffersLoaded: true,
      };
    case SET_OFERTAS_FILTERS:
      // Register events in GA (it is a sync op from our perspective)
      if ((window as any).gtag && process.env.REACT_APP_ANALYTICS_ID) {
        if (state.filtrosBusqueda.provincia !== action.payload.provincia) {
          (window as any).gtag("event", "search_provincia", {
            event_label: action.payload.provincia,
            event_category: "search",
          });
        }
        if (
          state.filtrosBusqueda.ciudades.length < action.payload.ciudades.length
        ) {
          action.payload.ciudades.forEach((item: string) => {
            if (!state.filtrosBusqueda.ciudades.includes(item)) {
              (window as any).gtag("event", "search_ciudad", {
                event_label: action.payload.ciudad,
                event_category: "search",
              });
            }
          });
        }
        if (
          state.filtrosBusqueda.disponibilidad.length <
          action.payload.disponibilidad.length
        ) {
          action.payload.disponibilidad.forEach((item: string) => {
            if (!state.filtrosBusqueda.disponibilidad.includes(item)) {
              (window as any).gtag("event", "search_disponibilidad", {
                event_label: action.payload.disponibilidad,
                event_category: "search",
              });
            }
          });
        }
      }
      return {
        ...state,
        filtrosBusqueda: {
          provincia: action.payload.provincia,
          ciudades: [...action.payload.ciudades],
          disponibilidad: [...action.payload.disponibilidad],
        },
      };
    case SIGNIN_USER_SUCCESS:
      return {
        ...initialEmpleo,
      };
    case SIGNUP_USER_SUCCESS:
      return {
        ...initialEmpleo,
      };
    case SIGNOUT_USER_SUCCESS:
      return {
        ...initialEmpleo,
      };
    case HIDE_WELCOME_POPUP:
      return {
        ...state,
        hideWelcomePopup: true,
      };
    case SET_NUM_OFERTAS_VISIBLES_HOME:
      return {
        ...state,
        numOfertasVisiblesHome: action.payload,
      };
    default:
      return state;
  }
};

export default empleo;
