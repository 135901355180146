import { apiEmpleo } from "../../../util/ApiEmpleo";
import { useMutation } from "react-query";
import { queryClient } from "../../../lib/react-query";

export default function useRemoveNoteMutation(
  parentId: string,
  moduleName: string
) {
  const removeNote = async (noteId: string) => {
    await apiEmpleo.delete(`notes/${noteId}`);
  };

  return useMutation(removeNote as any, {
    onMutate: (noteId: string) => {
      queryClient.cancelQueries([`notes`, parentId, moduleName]);

      const previousNotes: any = queryClient.getQueryData([
        "notes",
        parentId,
        moduleName,
      ]);

      if (previousNotes && noteId) {
        const newNotes = previousNotes.filter(
          (item: any) => item.id !== noteId
        );

        queryClient.setQueryData(
          ["notes", parentId, moduleName],
          [...newNotes]
        );
      }

      return () =>
        queryClient.setQueryData(
          ["notes", parentId, moduleName],
          previousNotes
        );
    },
    onError: (err, newNotes, rollback: any) => {
      rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries(["notes", parentId, moduleName]);
    },
  });
}
