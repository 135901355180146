import { Grid, Tab, Tabs, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import styled from "styled-components";
import { getOffer, getOffers, setBackground } from "../../../actions";
import ValoracionesActivas from "../../../components/Valoraciones/ValoracionesActivas";
import ValoracionesPasadas from "../../../components/Valoraciones/ValoracionesPasadas";
import cuideoTheme from "../../../containers/themes/defaultTheme";
import { getOfertasRecomendadas } from "../../../util/Contrato/util";
import IntlMessages from "../../../util/IntlMessages";
import useFooter from "../../../util/useFooter";
import useHeader from "../../../util/useHeader";
import usePageTitle from "../../../util/usePageTitle";
import OfertaCardList from "../components/OfertaCardList";

const StyledPageContainer = styled.div<{ appMarginTop: number }>`
  ${({ appMarginTop }) => {
    return `
    margin: ${appMarginTop}rem auto 0;
  `;
  }}

  @media all and (min-width: ${cuideoTheme.breakpoints.values
    .md}px) and (max-width: ${cuideoTheme.breakpoints.values.lg}px) {
    margin: ${({ appMarginTop }) => appMarginTop + 2}rem 0 0;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.lg}px) {
    margin: ${({ appMarginTop }) => appMarginTop + 2}rem 0 0;
  }
`;

const StyledSimilares = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;

  @media all and (min-width: ${cuideoTheme.breakpoints.values.sm}px) {
    margin-left: 0;
    margin-right: 0;
  }
`;

const StyledSimilaresHeader = styled(Typography)`
  font-size: 1.25rem;
  font-weight: 700;
  color: ${cuideoTheme.palette.primary.main};
  text-align: left;
  margin: 1rem;

  @media all and (max-width: ${cuideoTheme.breakpoints.values.sm}px) {
    text-align: center;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    margin: 4rem 1rem 2rem;
  }
`;

const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    height: 3px;
  }
`;

const StyledTab = styled(Tab)`
  text-transform: none;
  font-size: 1.125rem;
  color: #c5d3dc;
  margin-bottom: 3px;

  &:hover {
    background-color: ${(cuideoTheme.palette.primary as any)[50]};
  }

  @media all and (max-width: ${cuideoTheme.breakpoints.values.sm}px) {
    width: 50%;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
  }
`;

const StyledUpSection = styled.div`
  position: relative;
  :after {
    position: absolute;
    content: " ";
    display: block;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 3px;
    background-color: #c2d1da;
    z-index: -1;
  }
`;

const StyledContainer = styled.div`
  max-width: 1140px;
  margin: 0 auto;
`;

const StyledOfertasSection = styled.div`
  background-color: #e5edf1;
  margin-top: 6px;
`;

const StyledOtrasSection = styled.div`
  background-color: #e5edf1;
`;

const StyledCardHeader = styled.h5`
  padding: 1rem 1rem 0.5rem 1rem;
  margin: 0;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-weight: 700;
`;

const StyledHeaderTitle = styled.span`
  color: ${cuideoTheme.palette.primary.main};
  @media all and (max-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 1.375rem;
  }
  @media all and (min-width: ${cuideoTheme.breakpoints.values.md}px) {
    font-size: 1.625rem;
    line-height: 2rem;
    margin-right: 0.5rem;
  }
`;

interface Props {
  ofertas: any;
  match: any;
  from: any;
  getOffers: Function;
  getOffer: Function;
  appMarginTop: number;
  authUser: any;
  appliedOffers: any;
  router: any;
  history: any;
  setBackground: Function;
  ofertasLoadingState: string;
}

const PaginaMisOfertas = (props: Props) => {
  const {
    ofertas,
    appMarginTop,
    authUser,
    appliedOffers,
    history,
    setBackground,
    ofertasLoadingState,
    getOffers,
  } = props;
  const initialIndex =
    history.location.pathname === "/empleo/misOfertas/pasadas" ? 1 : 0;
  const [tabIndex, setTabIndex] = useState(initialIndex);
  const [ofertasSimilares, setOfertasSimilares] = useState([] as any[]);
  const intl = useIntl();

  useHeader({
    type: "normal",
  });
  useFooter({
    type: "normal",
  });
  usePageTitle(intl.formatMessage({ id: "MyOffersPage.MyOffers" }));

  useEffect(() => {
    setBackground("white");

    if (ofertasLoadingState === "unloaded") {
      getOffers(props.authUser);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setOfertasSimilares(
      getOfertasRecomendadas(ofertas, appliedOffers, authUser)
    );
  }, [ofertas, appliedOffers, authUser]);

  // const ofertasSimilares = getOfertasRecomendadas(
  //   ofertas,
  //   appliedOffers,
  //   authUser
  // );

  return (
    <StyledPageContainer appMarginTop={appMarginTop}>
      <StyledUpSection>
        <StyledContainer>
          <StyledCardHeader>
            <StyledHeaderTitle>
              <IntlMessages id="Mis Ofertas" />
            </StyledHeaderTitle>
          </StyledCardHeader>
          <StyledTabs
            variant="scrollable"
            value={tabIndex}
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, index) => {
              if (index === 0) {
                history.push("/empleo/misOfertas/activas");
              }
              if (index === 1) {
                history.push("/empleo/misOfertas/pasadas");
              }
              setTabIndex(index);
            }}
          >
            <StyledTab label={<IntlMessages id="Activas" />} />
            <StyledTab label={<IntlMessages id="Caducadas" />} />
          </StyledTabs>
        </StyledContainer>
      </StyledUpSection>
      <StyledOfertasSection>
        <StyledContainer>
          <Grid container spacing={8}>
            <Grid item xs={12} md={10} lg={9}>
              {initialIndex === 0 && (
                <ValoracionesActivas candidato={authUser.candidato} />
              )}
              {initialIndex === 1 && (
                <ValoracionesPasadas candidato={authUser.candidato} />
              )}
            </Grid>
          </Grid>
        </StyledContainer>
      </StyledOfertasSection>

      {ofertasSimilares.length > 0 && (
        <StyledOtrasSection>
          <StyledContainer>
            <Grid container spacing={8}>
              <Grid item xs={12} md={10} lg={9}>
                <StyledSimilares>
                  <StyledSimilaresHeader>
                    {authUser ? (
                      <IntlMessages id="Otras Ofertas interesantes para ti:" />
                    ) : (
                      <IntlMessages id="Ofertas similares:" />
                    )}
                  </StyledSimilaresHeader>
                  <OfertaCardList ofertas={ofertasSimilares} />
                </StyledSimilares>
              </Grid>
            </Grid>
          </StyledContainer>
        </StyledOtrasSection>
      )}
    </StyledPageContainer>
  );
};

const mapStateToProps = ({
  empleo,
  layout,
  auth,
  valoracionesState,
  router,
}: {
  empleo: { ofertas: any; ofertasLoadingState: string };
  layout: { appMarginTop: number };
  auth: { authUser: any; appliedOffers: any };
  valoracionesState: {
    misValoraciones: any;
    misValoracionesLoadingState: string;
  };
  router: any;
}) => {
  const { ofertas, ofertasLoadingState } = empleo;
  const { appMarginTop } = layout;
  const { authUser, appliedOffers } = auth;

  return {
    ofertas,
    appMarginTop,
    authUser,
    appliedOffers,
    router,
    ofertasLoadingState,
  };
};

export default connect(mapStateToProps, { getOffers, getOffer, setBackground })(
  PaginaMisOfertas
);
