import { JOB_OFFERS, JOB_SIGN_IN } from "../../constants/routes";

export const APP_SIGNIN_PAGE = JOB_SIGN_IN;
export const APP_START_PAGE =
  process.env.REACT_APP_EMPLEO === "true"
    ? JOB_OFFERS
    : process.env.REACT_APP_AFFINITY === "true"
    ? "/affinitySignIn"
    : JOB_OFFERS;
export const APP_AFTER_LOGOUT_PAGE =
  process.env.REACT_APP_EMPLEO === "true"
    ? JOB_OFFERS
    : process.env.REACT_APP_AFFINITY === "true"
    ? JOB_SIGN_IN
    : JOB_OFFERS;
