import { LinearProgress } from "@material-ui/core";
import React, { Fragment } from "react";
import styled from "styled-components";
import IntersectionPlaceholder from "../../../../../../components/IntersectionPlaceholder";
import { useInfiniteConversations } from "../../api/getConversationsInfinite";
import ConversationsListItem from "./ConversationsListItem";

const StyledWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const StyledInnerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0.25rem;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 72, 120, 0.25);
    width: 0.25rem;
    border-radius: 0.125rem;
  }
`;

const StyledLinearProgress = styled(LinearProgress)`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
`;

const StyledItemWrapper = styled.div`
  &.CMuiSelected {
    .CMuiCommunicationIntentItem {
      background-color: rgb(235, 235, 235);
    }
  }
`;

interface IConversationsListProps {
  selectedConversationId: string | null;
  onSelectConversation: (conversationId: string | null) => void;
}

const ConversationsList: React.FC<IConversationsListProps> = (props) => {
  const { selectedConversationId, onSelectConversation } = props;

  const conversationsQuery = useInfiniteConversations({
    config: {
      getNextPageParam: (lastPage, pages) => pages.length + 1,
    },
    params: {
      pageSize: 20,
    },
  });

  const handleFetchMore = () => {
    conversationsQuery.fetchNextPage();
  };

  return (
    <StyledWrapper>
      {(conversationsQuery.isLoading ||
        conversationsQuery.isFetchingNextPage) && <StyledLinearProgress />}
      <StyledInnerWrapper>
        {conversationsQuery.data?.pages.map((group, i) => (
          <Fragment key={i}>
            {group.conversations.map((conversation, i2) => (
              <Fragment
                key={`${conversation.id}_${conversation.last_received_at}`}
              >
                {i === conversationsQuery.data.pages.length - 1 &&
                  i2 === group.conversations.length - 3 && (
                    <IntersectionPlaceholder
                      onVisibleOnScreen={handleFetchMore}
                    />
                  )}
                <StyledItemWrapper
                  className={
                    conversation.id === selectedConversationId
                      ? "CMuiSelected"
                      : ""
                  }
                >
                  <ConversationsListItem
                    conversation={conversation}
                    onClick={onSelectConversation}
                  />
                </StyledItemWrapper>
              </Fragment>
            ))}
          </Fragment>
        ))}
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};

export default ConversationsList;
